import React, { useState, useCallback, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { withRouter } from "react-router-dom";
import Grid from "@mui/material/Grid";
import axios from "axios";
import Select from "react-select";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import moment from "moment";
import { useTheme } from "@mui/material";
import "moment-duration-format";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
    },
    appBar: {
        position: "relative",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.text.secondary,
        backgroundColor: "#f6f6bc",
        marginBottom: "15px",
    },
    list: {
        margin: theme.spacing(1),
        marginLeft: "0",
        paddingInlineStart: "0",
        listStyleType: "none",
        listStylePosition: "outside",
    },
    headlineTitle: {
        color: "#444",
        fontSize: "1.5rem",
        marginBottom: "12px",
    },
    meta: {
        color: "#000",
        fontSize: ".75rem",
        marginTop: "0",
    },
    missing: {
        color: "red",
        fontSize: ".75rem",
        marginTop: "0",
    },
    nospace: {
        fontSize: ".65rem",
        marginTop: "0",
    },
    button: {
        fontSize: ".65rem",
    },
    projects: {
        listStyleType: "none",
        borderBottom: "1px solid #D5D5D5",
        marginTop: "1rem",
    },
    buttonLink: {
        color: "#3c8864",
        textAlign: "left",
        padding: "0",
        textDecoration: "none",
        fontSize: "1rem",
        lineHeight: "1.25rem",
        fontWeight: "normal",
        textTransform: "none",
        minWidth: "auto",
        margin: "0",
    },
    margin: {
        margin: theme.spacing(1),
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function AttachToProject(props) {
    const _state = props.state_manager;
    const open = props.open;
    // props = props.props;
    const theme = useTheme();
    const duration = moment().subtract(90, "days");
    const classes = useStyles();
    // const [open, setOpen] = useState(props.open);
    const [attached, setAttachedDialog] = useState(false);
    const [activeBtn, setActiveBtn] = useState(true);
    const [projects, setProjects] = useState([]);
    const [all, setAllProjects] = useState([]);
    const [strategic, setRecruitment] = useState([]);
    const { match, history, user } = props;
    const [projectlist, setProjectsDisplay] = useState("block");
    const [tagsdisplay, setTagsDisplay] = useState("none");
    const [data, setData] = useState({
        projects: [],
    });
    // const [bulkTags, setBulkTags] = useState({
    //     tags: [],
    //     people: _state.get.selected,
    // });
    const [selectedProject, setProjectData] = useState({
        id: "",
        isdeleted: false,
        title: "",
        description: "",
        email_copy: "",
        project_not_received_by_email: true,
        client_id: "",
        client_name: "",
        primary_client_contact: "",
        additional_client_contacts: [],
        expert_ids: [],
        primary_project_manager: [],
        additional_project_managers: [],
        status: "1",
        type: "",
        priority: "4",
        case_code: "",
        calls_planned: "",
        industry: "",
        specialty: [],
        tags: [],
    });

    const fetchProjectById = useCallback(async (id) => {
        const pData = await axios.get(`/api/project/find?id=${id}`);
        if (pData.status === 200 && pData.data && pData.data.data) {
            if (pData.data.data.projectData.length === 0) return;
            const project = pData.data.data.projectData[0];
            const tags = pData.data.data.tags.map((tag) => {
                return { label: tag.name, value: tag.tag_id };
            });
            const specialty = pData.data.data.specialty.map((s) => {
                return { label: s.name, value: s.specialty_id };
            });
            const pcc = pData.data.data.clientContacts.filter((item) => item.isprimary == true);
            const acc = pData.data.data.clientContacts.filter((item) => item.isprimary !== true);
            const apms = pData.data.data.additionalProjectManagers;

            console.log("FETCHED PROJECT DATA: ", pData);
            setProjectData({
                ...selectedProject,
                id: project.id,
                isdeleted: project.isdeleted,
                title: project.title,
                description: project.description,
                email_copy: project.email_copy,
                project_not_received_by_email: project.project_not_received_by_email,
                client_id: project.client_id,
                client_name: project.client_name,
                primary_client_contact: pcc[0],
                additional_client_contacts: acc,
                expert_ids: project.expert_ids,
                primary_project_manager: project.project_manager_fname + " " + project.project_manager_lname,
                additional_project_managers: apms,
                status: project.project_status,
                type: project.project_type,
                priority: project.project_priority,
                case_code: project.case_code,
                calls_planned: project.calls_planned,
                industry: project.industry,
                specialty,
                tags,
            });
            setProjectsDisplay("none");
            setTagsDisplay("block");
        }
    }, []);

    const handleClose = () => {
        setOpen(false);
        setAttachedDialog(false);
        setProjectsDisplay("block");
        setTagsDisplay("none");
        // console.log("PROPS: ", props.location);
        // Solves deselection issue, but also refreshes the search
        window.location.href = `${props.location.pathname}${props.location.search}`;
    };
    const handleOpen = (cc) => {
        if (_state.get.selected.length > 0) {
            setOpen(true);
            console.log("OPEN PROJECTS MODAL: ", cc);
            console.log("Selected Experts: ", _state.get.selected);
            _state.set.bulkTags({
                ..._state.get.bulkTags,
                people: _state.get.selected,
            });
        } else {
            alert("Please select at least 1 expert");
        }
    };
    const navigateToProject = (id) => {
        history.push(`/project/view/${id}`);
        setAttachedDialog(false);
        window.location.reload();
    };

    const attachExpertToProject = (pid) => {
        const url = `/api/project/attachexperts`;
        const experts = [];

        const arr = [];
        for (const i in _state.get.selected) {
            if (_state.get.selected.length > 0) arr.push(_state.get.selected[i].id);
        }
        arr.forEach((expert_id) => {
            const expertObj = { expert_id, pid, rmid: user.id };
            experts.push(expertObj);
        });
        console.log("ARRAY of EXPERT OBJECTS? ", experts);
        axios
            .post(url, experts, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    // setOpen(false);
                    // Show dialog with option to stay on page
                    setAttachedDialog(true);
                    _state.switch.attachToProjectModal();
                    _state.flash.success("Expert(s) added to project!");
                    // TODO: Remove this eventually
                    // navigateToProject(id);
                }
            })
            .catch(() => {
                // setOpen(false);
                alert("Something went wrong while trying to attach. Please try again or contact tech team.");
                console.log(`API not done yet, 404 Error: ${JSON.stringify(experts)}`);
            });
    };

    function bulkTagsAdd() {
        const arr = [];
        for (const i in _state.get.selected) {
            if (_state.get.selected.length > 0) arr.push(_state.get.selected[i].id);
        }
        const dataObj = { ..._state.get.bulkTags, people: arr };

        console.log("Bulk Tag Object Before POST: ", dataObj);

        const url = "/api/expert/addtags";
        axios
            .post(url, dataObj, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    console.log("TAGS OBJECT AFTER POST?: ", result);
                    // TODO: Add to project if successful
                    attachExpertToProject(selectedProject.id);
                } else {
                    console.log(`Good POST, but something went wrong: ${JSON.stringify(_state.get.bulkTags)}`);
                }
            })
            .catch((result) => {
                console.log("API not done yet, 404 Error:", result);
            });
    }

    function handleProjects(array) {
        if (typeof array === "object") {
            console.log(array.value);
            fetchProjectById(array.value);
        }
    }
    function handleCheckbox(event) {
        const { target } = event;
        const { value } = target;
        if (event.target.checked) {
            _state.get.bulkTags.tags.push(value);
            setActiveBtn(false);
        } else {
            const newArr = _state.get.bulkTags.tags.filter(function (item) {
                return item !== value;
            });
            _state.get.bulkTags.tags = newArr;
            _state.get.bulkTags.tags.length < 1 ? setActiveBtn(true) : setActiveBtn(false);
        }
        console.log(_state.get.bulkTags.tags);
    }

    useEffect(() => {
        axios
            .get("/api/project/list", {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    // console.log("SKINNY LIST: ", result.data.data);
                    // Set for Autocomplete
                    const projectsObject = result.data.data.map((item) => {
                        return {
                            value: item.id,
                            label: item.title,
                        };
                    });
                    setAllProjects(projectsObject);
                    const openProjectsData = result.data.data
                        .filter(
                            (item) =>
                                item.project_status_id === 1 &&
                                item.project_type_id !== 3 &&
                                moment(item.created_on) > duration
                        )
                        .sort((a, b) => moment(b.created_on) - moment(a.created_on));
                    const strategicRecruitmentProjects = result.data.data
                        .filter((item) => item.project_type_id === 3 && moment(item.created_on) > duration)
                        .sort((a, b) => moment(b.created_on) - moment(a.created_on));
                    setProjects(openProjectsData);
                    setRecruitment(strategicRecruitmentProjects);
                }
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    }, []);
    // If coming from project page to search, skip the project select view and just offer up tags and add view
    useEffect(() => {
        if (props.project_id) {
            fetchProjectById(props.project_id);
        }
    }, [props]);

    return (
        <div>
            {/* <Button
                disabled={props.disabled}
                fullWidth={props.setFullWidth}
                size="small"
                variant="contained"
                color="primary"
                sx={{fontSize: ".65rem"}}
                onClick={handleOpen}
            >
                {props.btnText}
            </Button> */}
            <Dialog
                fullScreen
                open={open}
                onClose={_state.switch.attachToProjectModal}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: "relative" }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={_state.switch.attachToProjectModal}
                            aria-label="close"
                            size="large"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" sx={{ marginLeft: theme.spacing(2), flex: 1 }}>
                            Select a Project to Attach To
                        </Typography>
                        <Button color="inherit" onClick={_state.switch.attachToProjectModal}>
                            Cancel
                        </Button>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Box display={tagsdisplay}>
                        <Box component={"h3"} sx={{ color: "#444", fontSize: "1.5rem", marginBottom: "12px" }}>
                            {selectedProject.title} (optional)
                        </Box>
                        <Typography sx={{ color: "#000", fontSize: ".75rem", marginTop: "0" }}>
                            These tags are associated with this project. By group tagging these experts before
                            attaching, you get the benefits of future segmentation (Angles).
                        </Typography>
                        {selectedProject.tags.length < 1 && (
                            <Typography sx={{ color: "red", fontSize: ".75rem", marginTop: "0" }}>
                                This project has no tags associated with it.
                            </Typography>
                        )}
                        {selectedProject.tags.map(function (t, index) {
                            return (
                                <p key={t.value}>
                                    <Checkbox name="tag" onChange={handleCheckbox} value={t.value} /> {t.label}
                                </p>
                            );
                        })}
                        <Button
                            disabled={activeBtn}
                            sx={{ margin: theme.spacing(1) }}
                            color="primary"
                            onClick={bulkTagsAdd}
                            variant="contained"
                        >
                            Add Tags &amp; Attach
                        </Button>
                        <Button
                            color="secondary"
                            sx={{ margin: theme.spacing(1) }}
                            onClick={() => attachExpertToProject(selectedProject.id)}
                            variant="contained"
                        >
                            Attach Without Tagging
                        </Button>
                    </Box>
                    <Box display={projectlist}>
                        <Grid container spacing={2}>
                            <Grid item sm={4} xs={12}>
                                <h3>Search</h3>
                                <Select name="projects" onChange={handleProjects} options={all} value={data.projects} />
                            </Grid>
                            <Grid item sm={4} xs={12}>
                                <h3>Open</h3>
                                {projects.map(function (p, idx) {
                                    return (
                                        <Box
                                            key={p.id}
                                            sx={{
                                                listStyleType: "none",
                                                borderBottom: "1px solid #D5D5D5",
                                                marginTop: "1rem",
                                            }}
                                        >
                                            <p>
                                                <Button
                                                    sx={{
                                                        color: "#3c8864",
                                                        textAlign: "left",
                                                        padding: "0",
                                                        textDecoration: "none",
                                                        fontSize: "1rem",
                                                        lineHeight: "1.25rem",
                                                        fontWeight: "normal",
                                                        textTransform: "none",
                                                        minWidth: "auto",
                                                        margin: "0",
                                                    }}
                                                    onClick={() => fetchProjectById(p.id)}
                                                >
                                                    {p.title}
                                                </Button>
                                            </p>
                                        </Box>
                                    );
                                })}
                            </Grid>
                            <Grid item sm={4} xs={12}>
                                <h3>Strategic</h3>
                                {strategic.map(function (p, idx) {
                                    return (
                                        <Box
                                            key={p.id}
                                            sx={{
                                                listStyleType: "none",
                                                borderBottom: "1px solid #D5D5D5",
                                                marginTop: "1rem",
                                            }}
                                        >
                                            <p>
                                                <Button
                                                    sx={{
                                                        color: "#3c8864",
                                                        textAlign: "left",
                                                        padding: "0",
                                                        textDecoration: "none",
                                                        fontSize: "1rem",
                                                        lineHeight: "1.25rem",
                                                        fontWeight: "normal",
                                                        textTransform: "none",
                                                        minWidth: "auto",
                                                        margin: "0",
                                                    }}
                                                    onClick={() => fetchProjectById(p.id)}
                                                >
                                                    {p.title}
                                                </Button>
                                            </p>
                                        </Box>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
            <Dialog
                open={attached}
                onClose={() => {
                    setAttachedDialog(false);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <Typography id="alert-dialog-description">
                        Experts successfully attached to {selectedProject.title}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setAttachedDialog(false);
                        }}
                        color="primary"
                        autoFocus
                    >
                        Close
                    </Button>
                    <Button
                        onClick={() => navigateToProject(selectedProject.id)}
                        sx={{ color: "#19857b", textDecoration: "none", fontSize: ".65rem" }}
                    >
                        Continue to Project
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default withRouter(AttachToProject);
