/* eslint-disable indent */
import React, { useContext, useState } from "react";
import { Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import "moment-duration-format";
import { authContext } from "../context/auth";
import useSWR from "swr";
import MUIDataTable from "mui-datatables";
import Grid from "@mui/material/Grid";
import { Stack, Container, Paper, Avatar, Switch, FormControlLabel } from "@mui/material";
import { Link } from "@mui/material";
import moment from "moment";
import { Box } from "@mui/system";
import { useTheme } from "@mui/material";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import Warning from "@mui/icons-material/ErrorOutline";
import ExpertNameRow from "../components/ExpertNameRow";

const fetcher = (...args) => fetch(...args).then((res) => res.json());

export default function ExpertList() {
    const { auth } = useContext(authContext);
    const theme = useTheme();

    const [rmId, setRmId] = useState(auth.data.id);

    const findPersonalizedExpertsUrl = `/api/expert/findPersonalizedExperts?rmId=${rmId}`;
    const { data, error } = useSWR(findPersonalizedExpertsUrl, fetcher);

    if (error) return <div>failed to load</div>;
    if (!data) {
        return (
            <Stack sx={{ color: "grey.500" }} spacing={2} direction="row">
                <CircularProgress color="primary" />
            </Stack>
        );
    }

    const options = {
        filter: true,
        pagination: true,
        print: false,
        viewColumns: false,
        download: false,
        selectableRows: "none",
    };

    const columns = [
        {
            name: "avatar",
            label: " ",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <Container>
                            {data.data[dataIndex].expert_status === "Expert" ? (
                                <VerifiedUserIcon sx={{ fontSize: "2.75rem" }} />
                            ) : (
                                <Avatar
                                    sx={{
                                        color: theme.palette.primary.contrastText,
                                        backgroundColor: "#999",
                                        paddingLeft: "0",
                                        paddingRight: "0",
                                    }}
                                >
                                    {createInitialForAvatar(data.data[dataIndex].fname, data.data[dataIndex].lname)}
                                </Avatar>
                            )}
                        </Container>
                    );
                },
            },
        },
        {
            name: "fname",
            label: "Name",
            options: {
                filter: false,
                customBodyRenderLite: (dataIndex) => {
                    return <ExpertNameRow data={data} dataIndex={dataIndex} />;
                },
            },
        },
        {
            name: "created_on",
            label: "Added",
            options: {
                filter: false,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <Typography sx={{ color: "#000", fontSize: ".65rem", marginTop: "0" }}>
                            {formatDateString(data.data[dataIndex].created_on)}
                        </Typography>
                    );
                },
            },
        },
        {
            name: "details",
            label: "Details",
            options: {
                sort: false,
                filter: false,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <Container sx={{ mx: "auto", padding: "5px" }}>
                            {data.data[dataIndex].disclosures_form_review_required && (
                                <Box
                                    component={"div"}
                                    sx={{
                                        backgroundColor: "red",
                                        color: "#fff",
                                        fontSize: ".75rem",
                                        fontWeight: "bold",
                                        padding: "5px",
                                        borderRadius: "3px",
                                        textAlign: "center",
                                    }}
                                >
                                    <Warning sx={{ fontSize: "1rem" }} /> COMPLIANCE ALERT
                                </Box>
                            )}
                            {data.data[dataIndex].consultation_rate && data.data[dataIndex].dnc.length === 0 ? (
                                <Typography
                                    sx={{
                                        color: "#325C55",
                                        fontWeight: "bold",
                                        fontSize: ".75rem",
                                        marginTop: "5px",
                                        marginBottom: "8px",
                                        textTransform: "uppercase",
                                    }}
                                >
                                    Consultation Rate: {data.data[dataIndex].consultation_rate}{" "}
                                    {data.data[dataIndex].consultation_rate_currency}
                                </Typography>
                            ) : (
                                <Typography
                                    sx={{
                                        color: "#325C55",
                                        fontWeight: "bold",
                                        fontSize: ".85rem",
                                        marginTop: "5px",
                                        marginBottom: "8px",
                                        textTransform: "uppercase",
                                    }}
                                >
                                    {data.data[dataIndex].dnc || data.data[dataIndex].opted_out ? (
                                        <Box
                                            component={"strong"}
                                            sx={{
                                                color: "red",
                                                fontWeight: "bold",
                                                fontSize: ".85rem",
                                                marginTop: "5px",
                                                marginBottom: "8px",
                                                textTransform: "uppercase",
                                            }}
                                        >
                                            Do Not Contact
                                        </Box>
                                    ) : (
                                        <Link
                                            href={`/expert/edit/${data.data[dataIndex].id}`}
                                            sx={{
                                                color: theme.palette.primary.main,
                                                textDecoration: "none",
                                                fontSize: ".75rem",
                                            }}
                                        >
                                            Set Rate Now
                                        </Link>
                                    )}
                                </Typography>
                            )}
                            <Typography sx={{ color: "#000", fontSize: ".75rem", marginTop: "0" }}>
                                Last Modified: {formatDateString(data.data[dataIndex].updated_on)}
                            </Typography>

                            <Typography sx={{ color: "#000", fontSize: ".75rem", marginTop: "0" }}>
                                Email:
                                {(
                                    <Link
                                        sx={{ color: theme.palette.action.main }}
                                        href={`mailto:${data.data[dataIndex].mail}`}
                                    >
                                        {" "}
                                        {data.data[dataIndex].mail}
                                    </Link>
                                ) || "N/A"}
                            </Typography>
                            <Typography sx={{ color: "#000", fontSize: ".75rem", marginTop: "0" }}>
                                Phone:{" "}
                                {(
                                    <Link
                                        sx={{ color: theme.palette.action.main }}
                                        href={`tel:${data.data[dataIndex].number}`}
                                    >
                                        {" "}
                                        {data.data[dataIndex].number}
                                    </Link>
                                ) || "N/A"}
                            </Typography>
                        </Container>
                    );
                },
            },
        },
        {
            name: "created_by_name",
            label: "RM/RA",
            options: {
                filter: true,
                filterType: "multiselect",
            },
        },
    ];

    // Helper Functions

    const handleChange = (event) => {
        if (event.target.checked) {
            setRmId(auth.data.id);
        } else {
            setRmId(0);
        }
    };

    // eslint-disable-next-line no-extend-native
    String.prototype.trunc =
        String.prototype.trunc ||
        function (n) {
            return this.length > n ? `${this.substr(0, n - 1)}...more` : this;
        };

    function createInitialForAvatar(fname, lname) {
        if (fname && lname) {
            return `${fname.charAt(0)}${lname.charAt(0)}`;
        } else {
            return `??`;
        }
    }

    function formatDateString(str) {
        return moment(str).format("MMM Do, YYYY");
    }

    return (
        <Container maxWidth={false} disableGutters>
            <Grid container spacing={3} sx={{ p: 1 }}>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={<Switch checked={rmId === 0 ? false : true} color="primary" onChange={handleChange} />}
                        label={rmId !== 0 ? "Only Me" : "Everyone"}
                    />
                    <Paper
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "1rem",
                        }}
                    >
                        <MUIDataTable title={"Experts"} data={data.data} columns={columns} options={options} />
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}
