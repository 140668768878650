import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import StatusCheckboxes from "./StatusCheckboxes";
import axios from "axios";
import { useTheme } from "@mui/material";

export default function ExpertStatusChange(props) {
    const theme = useTheme();
    const _state = props.state_manager;
    const open = props.open;
    const data = props.data;
    const error = props.error;

    const { match, history } = props.props;
    const [saveSuccess, setSaveStatusDisable] = useState(false);

    // Helpers

    function submitFilters() {
        const url = "/api/project/filterExperts";
        const postObj = {
            id: parseInt(match.params.id, 10),
            statuses: _state.get.statuses.selected,
        };
        console.log("POST OBJECT BEFORE: ", postObj);

        axios
            .post(url, postObj, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    console.log("POST OBJECT AFTER: ", `${JSON.stringify(postObj)}`);
                    const filteredResults = result.data.data;
                    console.log("POST RESULTS: ", filteredResults.experts);
                    // Set Filtered List of EXPERTS
                    // _state.set.projectState({
                    //     ..._state.get.projectState,
                    //     experts: filteredResults.experts,
                    // });
                    _state.set.filterResults({
                        ..._state.get.filter,
                        experts: filteredResults.experts,
                    });
                    _state.set.expertsData(filteredResults.experts);
                    // setFilterResults({
                    //     ...filter,
                    //     experts: filteredResults.experts,
                    // });
                    // handleBoolFiltersOnLoad(filteredResults.experts);
                    // Now update menu of experts in statuses dialog
                    // setSelectedExperts(filteredResults.experts);
                    // Close left filter menu

                    // setState({ ...state, left: false });
                } else {
                    console.log(`Good POST, but something went wrong: ${JSON.stringify(postObj)}`);
                }
            })
            .catch((result) => {
                console.log("API not done yet, 404 Error:", result);
                setState({ ...state, left: false });
            });
    }
    function saveExpertStatuses(event) {
        event.preventDefault();
        // console.log(status);
        const url = `/api/project/saveExpertStatus/${_state.get.projectState.id}`;
        const expert = _state.get.expert;
        const status = _state.get.status;
        const formData = {
            id: expert.id,
            created_by: expert.created_by,
            call_duration: expert.call_duration,
            is_dirty: status.is_dirty,
            prescreen: [
                {
                    id: 21,
                    prescreen_sent: expert.prescreen_sent,
                    is_dirty: status.prescreen_sent_dirty,
                },
            ],
            terms: [
                {
                    id: 14,
                    tnc_not_signed: expert.tnc_not_signed,
                    is_dirty: status.tnc_not_signed_dirty,
                },
                { id: 15, tnc_sent: expert.tnc_sent, is_dirty: status.tnc_sent_dirty },
                {
                    id: 16,
                    tnc_signed: expert.tnc_signed,
                    is_dirty: status.tnc_signed_dirty,
                },
            ],
            expertResponse: [
                { id: 3, invited: expert.invited, is_dirty: status.invited_dirty },
                { id: 4, accepted: expert.accepted, is_dirty: status.accepted_dirty },
                { id: 5, declined: expert.declined, is_dirty: status.declined_dirty },
            ],
            clientResponse: [
                {
                    id: 6,
                    sent_to_client: expert.sent_to_client,
                    is_dirty: status.sent_to_client_dirty,
                },
                {
                    id: 7,
                    selected_by_client: expert.selected_by_client,
                    is_dirty: status.selected_by_client_dirty,
                },
                {
                    id: 8,
                    rejected_by_client: expert.rejected_by_client,
                    is_dirty: status.rejected_by_client_dirty,
                },
            ],
            complianceResponse: [
                {
                    id: 9,
                    sent_to_compliance: expert.sent_to_compliance,
                    is_dirty: status.sent_to_compliance_dirty,
                },
                {
                    id: 10,
                    compliance_approved: expert.compliance_approved,
                    is_dirty: status.compliance_approved_dirty,
                },
                {
                    id: 11,
                    compliance_rejected: expert.compliance_rejected,
                    is_dirty: status.compliance_rejected_dirty,
                },
            ],
            schedulingResponse: [
                {
                    id: 12,
                    needs_scheduling: expert.needs_scheduling,
                    is_dirty: status.needs_scheduling_dirty,
                },
                {
                    id: 13,
                    scheduled: expert.scheduled,
                    is_dirty: status.scheduled_dirty,
                },
                {
                    id: 20,
                    call_complete: expert.call_complete,
                    is_dirty: status.call_complete_dirty,
                },
            ],
            payment: [
                {
                    id: 17,
                    needs_payment: expert.needs_payment,
                    is_dirty: status.needs_payment_dirty,
                },
                {
                    id: 18,
                    payment_requested: expert.payment_requested,
                    is_dirty: status.payment_requested_dirty,
                },
                { id: 19, paid: expert.paid, is_dirty: status.paid_dirty },
            ],
            screened: [
                {
                    id: 28,
                    expert_screened: expert.expert_screened,
                    is_dirty: status.expert_screened_dirty,
                },
                {
                    id: 31,
                    expert_on_hold: expert.expert_on_hold,
                    is_dirty: status.expert_on_hold_dirty,
                },
            ],
        };
        console.log("Submit DATA: ", JSON.stringify(formData));
        axios
            .post(url, formData, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    const response = result.data.data;
                    // setSuccess(true);
                    _state.flash.success("Expert updated!");
                    console.log("RESULT: ", response);
                    // Update the list of experts.
                    submitFilters();
                    // If multiple selected or single save
                    if (_state.get.selected.length > 0) {
                        setSaveStatusDisable(true);
                        const selectedExperts = _state.get.filter.experts.filter(
                            (e) => _state.get.selected.includes(e.id)
                            // e.id === response.id
                        );
                        console.log("SELECTED: ", selectedExperts);
                        if (selectedExperts.length > 0) _state.set.selectedExperts(selectedExperts);
                    } else {
                        // handleClose();
                        _state.switch.expertStatusModal();
                    }
                    console.log("Experts Updated Successfully: ", result);
                } else {
                    console.log(`Good POST, but something went wrong: ${JSON.stringify(formData)}`);
                    // alert("There was an issue saving this status change. Please contact the tech team.");
                    _state.flash.error("There was an issue saving this status change. Please contact the tech team.");
                }
            })
            .catch((result) => {
                console.log(result);
                console.log("API not done yet, 404 Error:", result);
                // alert("There was an issue saving this status change. Please contact the tech team.");
                _state.flash.error("There was an issue saving this status change. Please contact the tech team.");
            });
    }

    function handleChange(event) {
        const { target } = event;
        const { value, name } = target;

        _state.set.expert({
            ..._state.get.expert,
            [name]: value,
        });
    }

    function handleCheckbox(event) {
        const { target } = event;
        const { name } = target;
        const dirty = `${name}_dirty`;
        console.log("Status Name: ", event.target.dataset.statusType);
        _state.set.expert({ ..._state.get.expert, [name]: event.target.checked });
        // Let DB know which checkbox was touched and which array was modified
        _state.set.status({ ..._state.get.status, [dirty]: true });
        if (!_state.get.status.is_dirty.includes(event.target.dataset.statusType)) {
            _state.get.status.is_dirty.push(event.target.dataset.statusType);
        }
        // Any click of a checkbox enables save status button
        setSaveStatusDisable(false);
    }

    const handleRadioBtns = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const dirty = `${value}_dirty`;
        const newExpert = {
            ..._state.get.expert,
            accepted: false,
            declined: false,
            invited: false,
            [value]: true,
        };
        _state.set.expert(newExpert);
        // Let DB know which checkbox was touched and which array was modified
        _state.set.status({ ..._state.get.status, [dirty]: true });
        if (!_state.get.status.is_dirty.includes(name)) {
            _state.get.status.is_dirty.push(name);
        }
    };
    return (
        <Dialog
            onClose={_state.switch.expertStatusModal}
            aria-labelledby="customized-dialog-title"
            fullWidth
            maxWidth="md"
            open={open}
        >
            <DialogTitle id="customized-dialog-title" onClose={_state.switch.expertStatusModal}>
                Change Statuses
                <IconButton
                    aria-label="close"
                    sx={{
                        position: "absolute",
                        right: theme.spacing(1),
                        top: theme.spacing(1),
                        color: theme.palette.grey[500],
                    }}
                    onClick={_state.switch.expertStatusModal}
                    size="large"
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <TextField
                    autoFocus
                    fullWidth
                    disabled={_state.get.expert.tnc_sent || _state.get.expert.tnc_signed}
                    id="token"
                    label="Terms + Conditions Link (Copy/Paste)"
                    margin="normal"
                    name="token"
                    onFocus={(event) => {
                        event.target.select();
                    }}
                    value={_state.get.onboardLink}
                />
                <StatusCheckboxes
                    project={_state.get.projectState}
                    expert={_state.get.expert}
                    handleChange={handleChange}
                    handleCheckbox={handleCheckbox}
                    handleRadioBtns={handleRadioBtns}
                    sent={_state.get.sent}
                />
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={saveExpertStatuses} color="primary" disabled={saveSuccess}>
                    Save changes
                </Button>
            </DialogActions>
        </Dialog>
    );
}
