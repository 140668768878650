import React, { useState, useRef, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FlashMessage from "./FlashMessage";
import Portal from "@mui/material/Portal";
import { stripHtml } from "../Utils.js";

const SnippetDialog = (props) => {
    // console.log(props, " :: props snippetModal");
    const [scroll, setScroll] = useState("paper");
    const [expertInfo, setExpertInfo] = useState("");
    const [openSuccessFM, setOpenSuccessFM] = useState(false);
    const [openErrorFM, setOpenErrorFM] = useState(false);
    const descriptionElementRef = useRef(null);

    useEffect(() => {
        setExpertInfo(createExpertSnippets(props.expertData));
    }, [props.expertData]);

    const handleClose = () => {
        props.handleDialogClose();
    };

    const handleCopy = () => {
        // console.log(expertInfo, " :: WHAT HAPPENED?");
        navigator.clipboard.writeText(expertInfo).then(
            () => {
                console.log("Copy successful!!!!!");
                setOpenSuccessFM(true);
            },
            (err) => {
                console.error("Copy broken", err);
                setOpenErrorFM(true);
            }
        );
    };

    const createExpertSnippets = (expertList) => {
        let html = "";
        expertList.forEach((expert) => {
            console.log(expert, " :: expert");
            html += `${expert.fname} ${expert.lname}`;
            html += expert.suffix ? `, ${expert.suffix}` : "";
            html += "\n";
            html += `Current Title: ${expert.position}\n`;
            html += `Current Employer: ${expert.company}\n`;
            html += `Duration: ${expert.duration}\n`;
            html += `About ${expert.fname}: `;
            html += expert.biography ? `${stripHtml(expert.biography)}\n\n` : "-- [ENTER BIO] --\n\n";
        });
        return html;
    };

    useEffect(() => {
        if (props.openDialog) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [props.openDialog]);

    const renderDialog = () => {
        return (
            <div>
                <Dialog
                    open={props.openDialog}
                    onClose={handleClose}
                    scroll={scroll}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <DialogTitle id="scroll-dialog-title">Expert Snippet</DialogTitle>
                    <DialogContent dividers={scroll === "paper"}>
                        <DialogContentText
                            id="scroll-dialog-description"
                            ref={descriptionElementRef}
                            tabIndex={-1}
                            style={{ whiteSpace: "pre-line" }}
                        >
                            {expertInfo}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleCopy} color="primary">
                            Copy
                        </Button>
                    </DialogActions>
                </Dialog>
                <Portal>
                    <FlashMessage
                        openFlashMessage={openSuccessFM}
                        setOpenFlashMessage={setOpenSuccessFM}
                        message={"Copy Successful"}
                        severity={"success"}
                    />
                    <FlashMessage
                        openFlashMessage={openErrorFM}
                        setOpenFlashMessage={setOpenErrorFM}
                        message={"Error Copying"}
                        severity={"error"}
                    />
                </Portal>
            </div>
        );
    };

    return <div>{renderDialog()}</div>;
};

export default SnippetDialog;
