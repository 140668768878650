import * as React from "react";

// MUI STYLES
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

// NPM
import axios from "axios";

export default function SchedulingOneOffPayment(props) {
    // props => expertData, handleClose, authData, handlerFuncs ({ handleCallDuration, handleCustomExpCharge })
    console.log(props, " :: props");

    /**
     * CONSTANTS
     */

    const DEFAULT_ERROR_STATE = { status: false, targetElement: "", msg: "" };

    /**
     * STATE
     */

    const [reasonList, setReasonList] = React.useState([]);
    const [error, setError] = React.useState(DEFAULT_ERROR_STATE);

    // refs
    const reasonRef = React.useRef(null);
    const notesRef = React.useRef(null);
    const amountRef = React.useRef(null);

    /**
     * PAGE LOAD
     */
    async function customExpertChargeReasonList() {
        await axios
            .get("/api/project/schedule/customExpertChargeReasonList")
            .then((res) => {
                const result = res?.data?.data;
                console.log(result, " :: result");
                if (result) {
                    setReasonList(result);
                }
            })
            .catch((err) => {
                console.log(err, " :: err");
            });
    }

    React.useEffect(() => {
        customExpertChargeReasonList();
    }, []);

    /**
     * HANDLE FUNCS
     */
    function handleCloseInternal() {
        setError(DEFAULT_ERROR_STATE);
        props.handleClose();
    }

    function handleBack() {
        props.handlerFuncs.handleCallDuration(props.expertData);
    }

    async function handleSave() {
        const reason = reasonRef.current.value;
        const notes = notesRef.current.value;
        const amount = amountRef.current.value;

        console.log(reason, " :: reason");
        console.log(notes, " :: notes");
        console.log(amount, " :: amount");

        // VALIDATION

        // reason
        if (!reason) {
            setError({ status: true, targetElement: "reason", msg: "Please select a reason" });
            return;
        }

        // notes
        if (!notes) {
            setError({ status: true, targetElement: "details-and-notes", msg: "Notes can't be empty" });
            return;
        }

        // amount
        if (!amount) {
            setError({ status: true, targetElement: "amount", msg: "Amount can't be empty" });
            return;
        }
        if (!parseFloat(amount)) {
            setError({ status: true, targetElement: "amount", msg: "Amount must be a number" });
            return;
        }

        const customChargeData = {
            user_id: props?.user_id,
            scheduling_id: props?.expertData?.ppId,
            custom_expert_charge_reason_id: reason,
            custom_expert_charge_notes: notes,
            custom_expert_charge_amount: amount,
        };

        console.log(customChargeData, " :: customChargeData");
        await axios
            .post("/api/project/schedule/upsertCustomExpertCharge", customChargeData)
            .then((res) => {
                console.log(res, " :: res");
                handleCloseInternal();
            })
            .catch((err) => {
                console.log(err, " :: err");
            });
    }

    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                gap: 1rem;
                min-width: 100%;
                & > div > input,
                & > div > select,
                & > div > textarea {
                    padding: 0.5rem;
                    font-size: 1.25rem;
                }
                & > div > label {
                    font-size: 1rem;
                }
            `}
        >
            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                `}
            >
                <label htmlFor="reason">Reason</label>
                <select
                    ref={reasonRef}
                    name="reason"
                    css={css`
                        width: 100%;
                        border: ${error.status && error.targetElement === "reason" ? "1px solid red" : "1px solid"};
                    `}
                >
                    {reasonList.map((reason) => {
                        return (
                            <option value={reason.id} key={reason.id}>
                                {reason.name}
                            </option>
                        );
                    })}
                </select>
                {error.status && error.targetElement === "reason" && (
                    <p
                        css={css`
                            color: red;
                        `}
                    >
                        {error.msg}
                    </p>
                )}
            </div>
            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                `}
            >
                <label htmlFor="details-and-notes">Details / Notes</label>
                <textarea
                    css={css`
                        border: ${error.status && error.targetElement === "details-and-notes"
                            ? "1px solid red"
                            : "1px solid"};
                    `}
                    ref={notesRef}
                    rows={5}
                    name="details-and-notes"
                />
                {error.status && error.targetElement === "details-and-notes" && (
                    <p
                        css={css`
                            color: red;
                        `}
                    >
                        {error.msg}
                    </p>
                )}
            </div>

            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                `}
            >
                <label htmlFor="amount">Amount</label>
                <input
                    css={css`
                        border: ${error.status && error.targetElement === "amount" ? "1px solid red" : "1px solid"};
                    `}
                    name="amount"
                    ref={amountRef}
                />
                {error.status && error.targetElement === "amount" && (
                    <p
                        css={css`
                            color: red;
                        `}
                    >
                        {error.msg}
                    </p>
                )}
            </div>

            <div
                css={css`
                    display: flex;
                    gap: 1rem;
                    align-self: flex-end;
                    & > button {
                        font-size: 1rem;
                        border: none;
                        background-color: #fff;
                        padding: 0.5rem 1rem;
                        border-radius: 0.2rem;
                        color: #4c1d95;
                        font-weight: 500;
                    }
                    & > button:hover {
                        background-color: #fafafa;
                    }
                `}
            >
                <button onClick={handleBack}>Back</button>
                <button onClick={handleCloseInternal}>Cancel</button>
                <button onClick={handleSave}>Save</button>
            </div>
        </div>
    );
}
