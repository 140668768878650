import React, { useState, useEffect } from "react";
// import axios from "axios";
import useWindowSize from "@rooks/use-window-size";
import Confetti from "react-confetti";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    banner: {
        padding: "8px",
        backgroundColor: "#f27dca",
        fontSize: "1.25rem",
        color: "#fff",
        textAlign: "center",
    },
    typography: {
        padding: theme.spacing(2),
    },
}));
function Banner(props) {
    const { width, height } = useWindowSize();
    const classes = useStyles();
    const [confetti, setConfetti] = useState(true);

    let bannerMessage = "";
    if (localStorage.getItem("Total_Experts") !== null) {
        bannerMessage += "Congratulations Research Team! " + localStorage.getItem("Total_Experts") + " ";
    }
    if (localStorage.getItem("name") !== null) {
        bannerMessage += localStorage.getItem("name");
    }

    function drawStar(ctx) {
        const numPoints = 5;
        const outerRadius = this.w;
        const innerRadius = outerRadius / 2;
        ctx.beginPath();
        ctx.moveTo(0, 0 - outerRadius);

        for (let n = 1; n < numPoints * 2; n++) {
            const radius = n % 2 === 0 ? outerRadius : innerRadius;
            const x = radius * Math.sin((n * Math.PI) / numPoints);
            const y = -1 * radius * Math.cos((n * Math.PI) / numPoints);
            ctx.lineTo(x, y);
        }
        ctx.fill();
        ctx.closePath();
    }
    function stopConfetti() {
        localStorage.setItem("Confetti", true);
    }
    useEffect(() => {
        setTimeout(function () {
            setConfetti(false);
        }, 10000);
    }, [props]);
    return (
        <div>
            {localStorage.getItem("Confetti") === null && bannerMessage !== "" && (
                <Confetti
                    width={width}
                    height={height}
                    numberOfPieces={200}
                    recycle={confetti}
                    drawShape={drawStar}
                    onConfettiComplete={stopConfetti}
                />
            )}
            {bannerMessage !== "" && <div className={classes.banner}>{bannerMessage}</div>}
        </div>
    );
}
export default Banner;
