import React, { useState, useContext, useCallback } from "react";
import { authContext } from "../context/auth";
import axios from "axios";
import { Dialog, DialogTitle, DialogContent, Tabs, Tab, Grid, Box, Typography, Button, TextField } from "@mui/material";
// import TabPanel from "./TabPanel";
import PropTypes from "prop-types";

import StrategicRecruitmentTemplate from "./StrategicRecruitmentTemplate";
import OnboardTemplate from "./OnboardTemplate";
import SendScreenerTemplate from "./SendScreenerTemplate";

export default function SendExpertEmail(props) {
    const _state = props.state_manager;
    const open = props.open;
    const data = props.data;
    const error = props.error;
    const { auth } = useContext(authContext);
    const { history, match, user } = props.props;
    // props = props.props;

    const [tabValue, setTabValue] = useState(0);

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <Typography
                component="div"
                role="tabpanel"
                hidden={value !== index}
                id={`scrollable-auto-tabpanel-${index}`}
                aria-labelledby={`scrollable-auto-tab-${index}`}
                {...other}
            >
                <Box p={3}>{children}</Box>
            </Typography>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };

    function handleTabChange(event, newValue) {
        setTabValue(newValue);
    }

    if (!data) return <div></div>;

    // console.log(data)

    function closeEvent() {
        _state.set.selectedSurvey({
            ..._state.get.selectedSurvey,
            id: "",
            token: "",
        });
        _state.switch.sendExpertEmailModal();
        _state.set.onboardLink("");
        setTabValue(0);

        // _state.flash.success("Sent!");
    }
    function clearOnboardToken() {
        // Clear the text field so that we can generate a new token with screeners
        _state.set.onboardLink("");
    }

    function handleSuccessfulSingleSend(responseData) {
        // getProjectData("", "project");
        _state.set.emailSentConfirmation(true);
        console.log(responseData);
        _state.set.singleEmailPostView({
            ..._state.get.postView,
            expert_fname: responseData.expert_fname,
            expert_lname: responseData.expert_lname,
            expert_email: responseData.expert_email.trim(),
            project_id: responseData.project_id,
            user_id: responseData.user_id,
            user_fname: responseData.user_fname,
            user_lname: responseData.user_lname,
            from_email: responseData.from_email,
            email_subject: responseData.email_subject,
            email_body: responseData.email_body,
        });
        _state.switch.emailConfirmation();
    }

    const generateToken = useCallback(async (id, sid) => {
        const url = "/api/expert/generateTokenById";
        const postData = {
            id,
            survey_id: sid ? sid : "",
            pid: match.params.id ? match.params.id : "",
            rmid: auth.data ? auth.data.id : "",
            rmemail: auth.data ? auth.data.mail : "",
            rmfname: auth.data ? auth.data.fname : "",
            rmlname: auth.data ? auth.data.lname : "",
            rmphone: auth.data ? formatPhoneNumber(auth.data.number) : "",
        };
        console.log("POST INDIVIDUAL TOKEN DATA: ", postData);
        await axios
            .post(url, postData, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    console.log("Token generated? ", result.data.data);
                    const tncLink = `https://firstthought.io/onboard/${result.data.data}`;
                    _state.set.onboardLink(tncLink);
                    _state.set.token(result.data.data);
                    console.log("Token generated: result.data.data");
                }
            })
            .catch((result) => {
                console.log("Something went wrong, API Error, 404 Error:", result);
            });
    }, []);
    const generateSurveyToken = useCallback(async (expert_id, survey_id) => {
        const url = "/api/survey/generatetokenurl";
        const postData = {
            expert_id,
            survey_id,
            project_id: match.params.id ? parseInt(match.params.id, 10) : "",
            rmid: auth.data ? auth.data.id : "",
            rmemail: auth.data ? auth.data.mail : "",
            rmfname: auth.data ? auth.data.fname : "",
            rmlname: auth.data ? auth.data.lname : "",
            rmphone: auth.data ? formatPhoneNumber(auth.data.number) : "",
        };
        console.log("POST INDIVIDUAL TOKEN DATA: ", postData);
        await axios
            .post(url, postData, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    console.log("Survey Token Generated? ", result.data.data);
                    const surveyLink = `${result.data.data}`;
                    _state.set.selectedSurvey({
                        id: survey_id,
                        token: surveyLink,
                    });
                    _state.flash.success("Token generated");
                    // setSuccess(true); TODO: Add flash message with _state.flash.success("Message?")
                }
            })
            .catch((result) => {
                console.log("Something went wrong, API Error, 404 Error:", result);
                _state.flash.error("Something went wrong when generating token");
            });
    }, []);

    function addExpertToSurvey(eid, sid) {
        const surveyObject = {
            project_id: parseInt(match.params.id, 10),
            survey_id: sid,
            created_by: auth.data.id,
            experts: [eid],
        };
        console.log("Add Expert to Survey POST: ", `${JSON.stringify(surveyObject)}`);
        const url = "/api/survey/addExperts";
        axios
            .post(url, surveyObject, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200 && result.data) {
                    console.log("Expert ADDED? ", result.statusText);
                    generateSurveyToken(eid, sid);
                    // Generate Onboard Token as well
                    generateToken(eid, sid);
                } else {
                    console.log(`Good POST, but something went wrong: ${JSON.stringify(surveyObject)}`);
                }
            })
            .catch((result) => {
                console.log("API not done yet, 404 Error:", result);
            });
    }

    function formatPhoneNumber(str) {
        const cleaned = `${str}`.replace(/\D/g, "");
        const formatted = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (formatted) {
            return `(${formatted[1]}) ${formatted[2]}-${formatted[3]}`;
        }
        return str;
    }

    function copyLink(e) {
        const copied = e.target;
        copied.select();
        document.execCommand("copy", false);
        alert(`SUCCESSFULLY COPIED!" ${copied.value}`);
    }

    return (
        <Dialog open={open} onClose={closeEvent} fullWidth maxWidth="lg" aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                Send {_state.get.expertTnc.fname} {_state.get.expertTnc.lname} an email
            </DialogTitle>
            <DialogContent>
                <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
                    <Tab label="Outreach" />
                    <Tab label="Onboarding" />
                    <Tab label="Screener" onClick={clearOnboardToken} />
                </Tabs>
                <TabPanel value={tabValue} index={0}>
                    <StrategicRecruitmentTemplate
                        state_manager={_state}
                        onClose={closeEvent}
                        updateEvent={handleSuccessfulSingleSend}
                        expert={_state.get.expertTnc}
                        project={data}
                        user={auth.data}
                        type="outreach"
                        privateToken={_state.get.token}
                        closeEvent={closeEvent}
                    />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <OnboardTemplate
                        state_manager={_state}
                        onClose={closeEvent}
                        updateEvent={handleSuccessfulSingleSend}
                        expert={_state.get.expertTnc}
                        project={data}
                        user={auth.data}
                        type="outreach"
                        privateToken={_state.get.token}
                        closeEvent={closeEvent}
                    />
                </TabPanel>

                <TabPanel value={tabValue} index={2}>
                    <Grid container spacing={2}>
                        <Grid item sm={4} xs={12}>
                            <Typography sx={{ fontSize: "1.25rem", flexBasis: "75%", flexShrink: 0 }}>
                                <strong>First choose a screener survey:</strong>
                            </Typography>
                            <Typography sx={{ fontSize: ".75rem", color: "#333", textAlign: "left" }}>
                                By selecting a screener, you are saving {_state.get.expertTnc.fname} to the screener.
                                This also creates a unique screener url for {_state.get.expertTnc.fname} ONLY, which is
                                represented as <strong>SCREENER_LINK</strong> in the email editor. PLEASE NOTE: This
                                unique url will only be active for one month after creating it.
                            </Typography>
                            <br />
                            {data.data.projectData.surveys.length < 1 && (
                                <Typography sx={{ color: "red", fontSize: ".75rem", marginTop: "0" }}>
                                    This project has no surveys associated with it.
                                </Typography>
                            )}
                            {data.data.projectData.surveys
                                .filter((survey) => survey.published === true)
                                .map(function (s, idx) {
                                    return (
                                        <Button
                                            key={s.id}
                                            sx={
                                                _state.get.selectedSurvey.id === s.id
                                                    ? {
                                                          color: "#000",
                                                          padding: "0",
                                                          textDecoration: "none",
                                                          fontSize: "1rem",
                                                          fontWeight: "bold",
                                                          textTransform: "none",
                                                          minWidth: "auto",
                                                          textAlign: "left",
                                                          display: "block",
                                                          lineHeight: "120%",
                                                          marginBottom: "15px",
                                                      }
                                                    : {
                                                          color: "#523197",
                                                          padding: "0",
                                                          textDecoration: "none",
                                                          fontSize: "1rem",
                                                          fontWeight: "normal",
                                                          textTransform: "none",
                                                          minWidth: "auto",
                                                          textAlign: "left",
                                                          display: "block",
                                                          lineHeight: "120%",
                                                          marginBottom: "15px",
                                                      }
                                            }
                                            onClick={() => addExpertToSurvey(_state.get.expertTnc.id, s.id)}
                                        >
                                            {s.title}
                                        </Button>
                                    );
                                })}
                        </Grid>
                        <Grid item sm={8} xs={12}>
                            <TextField
                                fullWidth
                                onClick={copyLink}
                                id="individual_onboarding_url"
                                name="individual_onboarding_url"
                                label="Onboarding Link and Screener"
                                margin="normal"
                                value={_state.get.onboardLink}
                            />
                            <TextField
                                fullWidth
                                onClick={copyLink}
                                id="individual_screener_url"
                                name="individual_screener_url"
                                label="Screener Link"
                                margin="normal"
                                value={_state.get.selectedSurvey.token}
                            />
                            <SendScreenerTemplate
                                survey={_state.get.selectedSurvey}
                                closeEvent={closeEvent}
                                updateEvent={handleSuccessfulSingleSend}
                                expert={_state.get.expertTnc}
                                project={_state.get.projectState}
                                user={auth.data}
                                type="screener"
                                emailContent={_state.templates.screenerTemplate}
                                privateToken={_state.get.selectedSurvey.token}
                            />
                        </Grid>
                    </Grid>
                </TabPanel>
            </DialogContent>
        </Dialog>
    );
}
