import * as React from "react";
import axios from "axios";

// mui
import Autocomplete from "@mui/material/Autocomplete";

// MUI - style imports
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { TextField } from "@mui/material";
import AddTags from "../../AddTags";

// ======
// STYLES
// ======

const addTagStyles = css`
    background-color: #dec9ff;
`;

// ===========
// MUI HELPERS
// ===========

export default function ProjectPageTagManager(props) {
    // PROPS
    const { changeEvent, incomingTags } = props;

    // STATE
    const [value, setValue] = React.useState([...incomingTags]);
    const [inputValue, setInputValue] = React.useState("");
    const [options, setOptions] = React.useState([]);

    async function loadTags() {
        if (!inputValue || inputValue.length < 3) return;

        await axios
            .get(`/api/project/now/findTags?searchText=${inputValue}`)
            .then((response) => {
                const [items, [{ does_tag_already_exist }]] = response?.data?.data;
                if (items) {
                    const newOptions = items.map((item) => {
                        return {
                            value: item.id,
                            label: item.name,
                        };
                    });
                    if (does_tag_already_exist) {
                        setOptions([...value, ...newOptions]);
                    } else {
                        setOptions([...value, ...newOptions, { value: 0, label: `${inputValue}` }]);
                    }
                }
            })
            .catch((e) => {
                console.log(e, " :: e in loadTags");
            });
    }

    async function createTag(tagName) {
        return await axios
            .post("/api/project/now/createTag", { tagName })
            .then((r) => {
                console.log(r, " :: r in createTag");
                const tagId = r?.data?.data?.tag_id;
                if (tagId) return tagId;
            })
            .catch((e) => {
                console.log(e, " :: e in createTag");
            });
    }

    // PAGE LOAD
    React.useEffect(() => {
        // console.log("====== in useEffect ======");
        loadTags();
    }, [value, inputValue]);

    return (
        <div>
            <Autocomplete
                multiple
                id="multiple-limit-tags"
                options={options}
                getOptionLabel={(option) => option.label}
                filterOptions={(x) => x}
                filterSelectedOptions
                includeInputInList
                autoComplete
                disableClearable
                value={value}
                clearOnBlur={false}
                inputValue={inputValue}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                noOptionsText="Enter at least 3 characters to search for tags."
                onChange={async (event, newArr, actionStatus, actionItem) => {
                    // removeOption | selectOption
                    const addOrRemove = actionStatus === "removeOption" ? "remove" : "add";

                    // if add && id = 0 then it's a new tag
                    if (addOrRemove === "add" && actionItem.option.value === 0) {
                        // const tagName = actionItem.option.name.slice(actionItem.option.name.indexOf(":") + 1).trim();

                        if (
                            confirm(
                                `Are you sure you want to create a new tag and add to the project named: ${actionItem.option.label}`
                            )
                        ) {
                            const tagId = await createTag(actionItem.option.label);
                            if (tagId) {
                                const newTag = { value: tagId, label: actionItem.option.label };
                                const updatedArr = newArr.filter((t) => parseInt(t.value) !== 0);
                                updatedArr.push(newTag);
                                changeEvent(updatedArr);
                                setOptions(updatedArr);
                                setValue(updatedArr);
                            }
                        }
                        // otherwise its an existing tag needing to be added to project
                    } else if (addOrRemove === "add") {
                        changeEvent(newArr);
                        setValue(newArr);
                        // here we're removing from the project
                    } else {
                        changeEvent(newArr);
                        setValue(newArr);
                    }
                }}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                    <TextField {...params} label="Add or Remove Tags:" placeholder="Search Tags..." />
                )}
                renderOption={(props, option) => {
                    return (
                        <li {...props} key={props.id} css={option.value === 0 ? addTagStyles : null}>
                            {option.value === 0 ? `Create new tag: ${option.label}` : option.label}
                        </li>
                    );
                }}
            />
            <AddTags tagLoadEvent={loadTags} />
        </div>
    );
}
