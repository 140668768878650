import React, { useState, useEffect, useCallback, useContext } from "react";
import update from "immutability-helper";
// import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import axios from "axios";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Dialog from "@mui/material/Dialog";
import FlashMessagev2 from "./FlashMessagev2";
import { Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
// import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
// import DialogActions from "@mui/material/DialogActions";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CopyIcon from "@mui/icons-material/FileCopy";
import Slide from "@mui/material/Slide";
import Paper from "@mui/material/Paper";
import Warning from "@mui/icons-material/Warning";
import Sort from "@mui/icons-material/UnfoldMore";
import Save from "@mui/icons-material/Save";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import ArrowDown from "@mui/icons-material/GetApp";
import moment from "moment";
import Question from "./Question";
import FreeText from "./question_types/FreeText";
// import Likert from "./question_types/Likeart";
import Multiple from "./question_types/Multiple";
import "moment-duration-format";
import { extractNameString, extractIndex, truncateString } from "../Utils";

const SurveyTypes = [
    { value: 1, label: "Regular Screening Questions" },
    { value: 2, label: "Follow Up Screening Questions" },
    { value: 3, label: "Compliance Questions" },
    { value: 4, label: "Written Follow Up" },
];

const QuestionTypes = [
    { value: 1, type: "freetext", label: "Free Text" },
    { value: 2, type: "multiple", label: "Multiple" },
    { value: 3, type: "likert", label: "Likert" },
];

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "left",
        color: theme.palette.text.secondary,
        marginBottom: "20px",
    },
    heading: {
        color: "#325C55",
        fontSize: "1.15rem",
        fontWeight: "bold",
        textTransform: "uppercase",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    appBar: {
        position: "relative",
    },
    table: {
        width: "100%",
    },
    dataBox: {
        backgroundColor: "#f5f5f5",
        borderBottom: "4px solid #eaeaea",
        borderRadius: "3px",
        padding: "20px",
    },
    tabBackground: {
        background: "linear-gradient(60deg, #ffffff, #fafafa, #f5f5f5, #f2f2f2)",
        overflow: "hidden",
        padding: "15px",
        borderRadius: "3px",
    },
    surveyTitle: {
        color: "#3c8864",
        fontSize: "1.25rem",
    },
    surveyType: {
        color: "#444",
        fontSize: ".75rem",
        textTransform: "uppercase",
    },
    link: {
        color: "#3c8864",
        textDecoration: "none",
    },
    meta: {
        color: "#444",
        fontSize: ".65rem",
        marginBottom: "5px",
        textTransform: "uppercase",
    },
    type: {
        color: "#3c8864",
        fontSize: ".65rem",
        marginBottom: "5px",
        textTransform: "uppercase",
    },
    previewTextField: {
        fontSize: ".75rem",
        marginBottom: "8px",
    },
    likert: {
        display: "inline",
    },
    question: {
        color: "#000",
        fontSize: "1rem",
        marginBottom: "5px",
        display: "block",
        clear: "both",
    },
    questionActions: {
        float: "right",
    },
    saveSurvey: {
        marginBottom: "5px",
    },
    submit: {
        backgroundColor: "#4CAB7D",
    },
    saveBtn: {
        textAlign: "center",
        margin: "0 auto",
    },
    centeredContainer: {
        margin: "0 auto",
        textAlign: "center",
    },
    arrowDown: {
        color: "#eaeaea",
        fontSize: "4em",
    },
    buttonGeneric: {
        marginBottom: "20px",
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function SurveyCanvas(props) {
    const { match, history, version } = props;
    // const { id } = match.params;
    const classes = useStyles();
    const [openLoader, setOpenLoader] = useState(true);
    const [tabValue, setTabValue] = useState(0);
    const [questionsTab, setQuestionsTabValue] = useState(0);
    const [preview, setPreview] = useState(false);
    const [questions, setSort] = useState([]);
    const [drafts, setDrafts] = useState(JSON.parse(window.localStorage.getItem("screener_drafts")) || {});
    const [draftList, setDraftList] = useState([]);
    const [deleteDrafts, setDeleteDrafts] = useState(false);
    const pointer = version == "Create" ? props.project.id : props.survey.id;

    const timeout = 600000; // ms
    const errorMessageTimeout = 5000; //
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const [data, setData] = useState({
        created_by: props.survey.created_by,
        created_on: props.survey.created_on,
        updated_by: props.user.id,
        updated_on: props.survey.updated_on,
        id: props.survey.id,
        type: props.survey.type,
        title: props.survey.title,
        published: props.survey.published,
        lock: props.survey.lock,
        project_id: props.survey.project_id,
        // associations: props.survey.associations,
        questions: props.survey.questions,
        respondents: props.survey.respondents,
        tags: props.survey.tags,
    });
    const [question, setQuestion] = useState({
        order: "", // Are we storing this? Or is the array index adequate?
        id: "", // For purposes of editing and identification
        question_id: "",
        survey_id: "", // This question belongs to survey parent id
        question_type_id: 1, // Type of question ie. Freetext or Likert?
        question_type: "Free Text",
        text: "", // Actual Question asked
        scale: "", // Likert scale default 5
        is_dirty: false, // For local editing purposes only
        comments: "", // Additional comment field
        multiple: false, // True means multiple choice checkboxes
        disqualifier: false, // Forces RM to select a disqualifying answer if true
        answers: [], // Additional labels for the question text, for multiple answers
        // questions: [] // conditional sub questions array
        // skip: false, // V2 Feature
        // branch: false, // V2 Feature
        // break: false, // V2 Feature
        // associations: { project_id: "", survey_id: "", question_id: "" } // Associated project/survey? Follow up screener from a different screener/question?
    });
    const [tabType, disableTabs] = useState({
        freetext: false,
        multiple: false,
        likert: false,
    });
    const followup = {
        order: 1,
        id: "",
        question_id: "",
        survey_id: props.survey.id ? props.survey.id : "",
        question_type_id: 1,
        question_type: "Free Text",
        text: "",
        scale: null,
        is_dirty: false,
        multiple: false,
        disqualifier: false,
        answers: [
            {
                id: "",
                label: "",
                user_answer: "",
                parentquestion: "",
                showfollowup: false,
                followupquestion: [],
                disqualifier: false,
            },
        ],
    };

    const availabilityQuestion = {
        order: "",
        id: "",
        question_id: "",
        survey_id: "",
        question_type_id: 1,
        question_type: "Free Text",
        text: "<p>Please provide a few dates and times (in your local time zone) that you would be available to schedule a phone consultation with our client:</p>",
        scale: "",
        is_dirty: false,
        comments: "",
        multiple: false,
        disqualifier: false,
        answers: [],
    };

    function deleteDraftOnSave(key) {
        setDeleteDrafts(true);
        setDrafts({});
        setDraftList([]);
        const currentDrafts =
            window.localStorage.getItem("screener_drafts") !== null
                ? JSON.parse(window.localStorage.getItem("screener_drafts"))
                : {};
        const removedKeyDrafts = {};
        for (const i in currentDrafts) {
            if (i !== key) removedKeyDrafts[i] = currentDrafts[i];
        }
        window.localStorage.setItem("screener_drafts", JSON.stringify(removedKeyDrafts));
        setTimeout(() => {
            setDeleteDrafts(false);
        }, 10000);
    }

    function autosave(newData, action, manual = false) {
        if (pointer.length == 0) setData(newData);
        const projectDrafts = drafts[pointer + "_" + version] || [];
        if (projectDrafts.length > 0 && !manual) {
            const lastTime = new Date().getTime() - projectDrafts[0].draft_time;
            const half = timeout / 2;
            if (lastTime < half) {
                setData(newData);

                return;
            }
        }
        if (newData.title == "") {
            setData(newData);

            // Modal
            const msg = manual
                ? "Draft cannot be saved without a title"
                : "Draft skipped, add title to enable autosaving";
            setErrorMsg(msg);
            if (!error) {
                setTimeout(() => {
                    setError(false);
                    setErrorMsg("");
                }, errorMessageTimeout);
            }
            setError(true);

            return;
        }

        // const newDrafts = projectDrafts;
        projectDrafts.unshift({ ...newData, draft_time: new Date().getTime(), action });
        if (projectDrafts.length > 10) projectDrafts.pop();
        const updatedDrafts = drafts;
        updatedDrafts[pointer + "_" + version] = projectDrafts;
        window.localStorage.setItem(`screener_drafts`, JSON.stringify(updatedDrafts));
        setDrafts(updatedDrafts);
        setData(projectDrafts[0]);
        setDraftList(projectDrafts);

        // Modal
        setErrorMsg(`Draft Saved: ${action}`);
        if (!error) {
            setTimeout(() => {
                setError(false);
                setErrorMsg("");
            }, errorMessageTimeout);
        }
        setError(true);
    }
    function populateOnLoad() {
        setData({
            ...data,
            created_by: props.survey.created_by,
            created_on: props.survey.created_on,
            updated_by: props.user.id,
            updated_on: props.survey.updated_on,
            id: props.survey.id,
            type: props.survey.type,
            title: props.survey.title ? props.survey.title : props.project.title,
            published: props.survey.published,
            lock: props.survey.lock,
            project_id: props.survey.project_id,
            questions: props.survey.questions,
            respondents: props.survey.respondents,
            tags: props.survey.tags,
        });
        setSort(props.survey.questions);
    }

    useEffect(() => {
        if (parseInt(props.params_id, 10) === parseInt(props.survey.id, 10)) {
            console.log("LOADING SURVEY DATA...");
            populateOnLoad();
        } else {
            // Preset the project title
            console.log(props.project.title);
            setData({
                ...data,
                title: props.project.title,
            });
            if (props.project.surveys.length > 0) {
                props.project.surveys.forEach((s) => {
                    if (s.title.trim() === props.project.title.trim()) {
                        setData({
                            ...data,
                            title: "",
                        });
                        return;
                    }
                });
            }
        }
        setOpenLoader(false);
        // Handle Autosave
        if (drafts && drafts[pointer + "_" + version] && drafts[pointer + "_" + version].length > 0) {
            const projectDrafts =
                window.localStorage.getItem("screener_drafts") !== null
                    ? JSON.parse(window.localStorage.getItem("screener_drafts"))[pointer + "_" + version]
                    : {};
            setDraftList(projectDrafts);
            setData(projectDrafts[0]);
            if (projectDrafts[0].questions) setSort(projectDrafts[0].questions);

            // Modal
            setErrorMsg("Draft loaded");
            if (!error) {
                setTimeout(() => {
                    setError(false);
                    setErrorMsg("");
                }, errorMessageTimeout);
            }
            setError(true);
        }
        setInterval(() => {
            setData((newData) => {
                autosave(newData, "Auto");
            });
        }, timeout);

        console.log(deleteDrafts);
        return () => {
            if (!deleteDrafts && draftList.length > 0) {
                const lastTime = draftList.length > 0 ? new Date().getTime() - draftList[0].draft_time : 0;
                const half = timeout / 2;
                if (lastTime < half)
                    setData((newData) => {
                        autosave(newData, "Exit", true);
                    });
            }
        };
    }, [props.project, props.params_id, props.survey]);

    function handleClose() {
        setPreview(false);
    }

    function validateTitle(event) {
        const { target } = event;
        const { value, name } = target;
        console.log("ONBLUR: ", value);
        console.log("PROJECT SCREENERS: ", props.project.surveys);
        let found = false;
        if (props.project.surveys.length > 0) {
            props.project.surveys.forEach((s) => {
                if (s.title.trim() === value.trim()) {
                    alert("This title already exists. Please rename your screener.");
                    found = true;
                    setData({
                        ...data,
                        title: "",
                    });
                }
            });
        }

        if (!found) {
            setData((newData) => {
                autosave(newData, "Title Change");
            });
        }
    }

    function handleChange(event) {
        const { target } = event;
        const { value, name } = target;
        setData({
            ...data,
            [name]: value,
        });
    }
    function handleQuestionChange(value) {
        const name = "text";
        // Text Editor is pure string
        setQuestion({
            ...question,
            [name]: value,
        });
    }
    function handleScaleString(event) {
        const { target } = event;
        const { value, name } = target;
        setQuestion({
            ...question,
            [name]: value,
        });
    }
    function handleCheckboxSettings(event) {
        const { target } = event;
        const { name } = target;
        setQuestion({
            ...question,
            [name]: event.target.checked,
        });
    }

    function handleLikertCheckbox(event) {
        const { target } = event;
        const { name } = target;
        setQuestion({
            ...question,
            [name]: event.target.checked ? 3 : 1,
        });
    }

    function mergeEditNewLabels(newArr) {
        const answerLabels = [...question.answers];
        // TODO: Combine two arrays, existing answer labels with any new ones added
        const combinedArr = answerLabels.concat(newArr);
        console.log("EXISTING: ", answerLabels);
        console.log("NEW: ", newArr);
        console.log("COMBINED: ", combinedArr);
        return combinedArr;
    }

    function handleLabelCheckbox(event) {
        const { target } = event;
        const { name } = target;
        const idx = extractIndex(name);
        const nameString = extractNameString(name);

        const answerLabels = [...question.answers];
        answerLabels[idx][nameString] = event.target.checked;
        setQuestion({
            ...question,
            answers: answerLabels,
        });
    }

    function handleFollowupQuestion(event) {
        const { target } = event;
        const { value, name } = target;
        const idx = extractIndex(name);
        const nameString = extractNameString(name);

        const answerLabels = [...question.answers];
        // First check if followup object exists...
        if (answerLabels[idx][nameString].length > 0) {
            answerLabels[idx][nameString][0].text = value;
        } else {
            // Create and Add it if not...then set it
            answerLabels[idx][nameString].push(followup);
            answerLabels[idx][nameString][0].text = value;
        }
        console.log("FOLLOW UP QUESTION: ", answerLabels);
        setQuestion({
            ...question,
            answers: answerLabels,
        });
    }

    function handleLabelChange(event) {
        const { target } = event;
        const { value, name } = target;
        const idx = extractIndex(name);
        const nameString = extractNameString(name);

        const answerLabels = [...question.answers];
        answerLabels[idx][nameString] = value;
        setQuestion({
            ...question,
            answers: answerLabels,
        });
    }

    function handleLabelDelete(q, idx) {
        const answerLabels = [...question.answers];
        // TODO: Find and remove label object
        const newLabelsArray = answerLabels.filter((a, i) => i !== idx);
        console.log("NEW ARRAY WITHOUT DELETED QUESTION: ", newLabelsArray);
        setQuestion({
            ...question,
            answers: newLabelsArray,
        });
    }

    function handleSurveyChange(array) {
        if (typeof array === "object") {
            // TODO: Sort the questions?
            setSort(array);
            data.questions = array;
            // Enable all tabs again...
            disableTabs({
                freetext: false,
                yesno: false,
                multiple: false,
                likert: false,
            });
        }
    }

    function editQuestion(q, idx) {
        const qTypeVal = parseInt(q.question_type_id, 10);
        const qStringType = QuestionTypes.find((t) => t.value === qTypeVal).label;
        setPreview(false);
        // Set tab active to whichever question type the question is...
        switch (q.question_type_id) {
            case 1:
                setTabValue(0);
                break;
            case 2:
                setTabValue(1);
                break;
            case 3:
                setTabValue(0);
                break;
            default:
                setTabValue(0);
        }
        // Disable all tabs, enable current question type tab
        console.log("QUESTION TYPE: ", qStringType);
        tabType.freetext = true;
        tabType.multiple = true;
        tabType.likert = true;
        disableTabs({
            ...tabType,
            [qStringType]: false,
        });

        console.log("EDIT THIS QUESTION", q);
        const answers = [...q.answers];
        // const empty = { ...followup };
        // answers.forEach(function(a, i){
        //     console.log("Answer:", a);
        //     a.followupquestion.push(empty);
        // });
        // console.log("ANSWERS W/ FOLLOWUP: ", answers);
        // TODO: Load "q" into question useState
        setQuestion({
            order: q.order,
            id: q.id,
            survey_id: q.survey_id,
            question_type_id: q.question_type_id,
            question_type: q.question_type,
            text: q.text,
            is_dirty: true,
            comments: q.comments,
            multiple: q.multiple,
            scale: q.scale,
            disqualifier: q.disqualifier,
            answers,
            questions: q.questions,
            // skip: q.skip,
            // break: q.break,
            // branch: q.branch,
            // associations: q.associations
        });
    }

    function copyQuestion(questionObject) {
        console.log("QUESTION OBJECT TO COPY: ", questionObject);
        const cleanQuestion = { ...questionObject };
        const cleanQuestions = [...questions];
        const cleanAnswers = [...cleanQuestion.answers];
        const newQuestion = {
            order: cleanQuestions.length + 1,
            id: "",
            question_id: "",
            survey_id: cleanQuestion.survey_id,
            question_type_id: cleanQuestion.question_type_id,
            question_type: cleanQuestion.question_type,
            text: cleanQuestion.text,
            scale: cleanQuestion.scale,
            is_dirty: cleanQuestion.is_dirty,
            multiple: cleanQuestion.multiple,
            disqualifier: cleanQuestion.disqualifier,
            answers: cleanAnswers,
        };
        cleanQuestions.push(newQuestion);
        console.log("NEW QUESTION ARRAY: ", cleanQuestions);
        setSort(cleanQuestions);
    }

    function deleteQuestion(idx) {
        console.log("INDEX OF Question TO DELETE: ", idx);
        const filtered = questions.filter((item, i) => i !== idx);
        // Loop through each question and reset order
        for (let i = 0; i < filtered.length; i++) {
            filtered[i].order = i + 1;
        }
        console.log("FINAL FILTERED ARRAY: ", filtered);
        handleSurveyChange(filtered);
    }

    function resetQuestion() {
        console.log("RESET");
        setQuestion({
            order: "",
            id: "",
            survey_id: "",
            question_type_id: 1,
            question_type: "Free Text",
            text: "",
            is_dirty: false,
            comments: "",
            multiple: false,
            scale: "",
            disqualifier: false,
            answers: [],
            questions: [],
            // skip: false,
            // branch: false,
            // break: false,
            // associations: { project_id: "", survey_id: "", question_id: "" }
        });
        // Enable all tabs again...
        disableTabs({
            freetext: false,
            multiple: false,
            likert: false,
        });
    }

    function reorderQuestions() {
        const questionsCopy = [...questions];
        // Loop through each question and reset order
        for (let i = 0; i < questionsCopy.length; i++) {
            questionsCopy[i].order = i + 1;
        }
        // Set both useStates with new question order...setSort is separate for drag and drop purposes.
        setSort(questionsCopy);
        setData({
            ...data,
            questions: questionsCopy,
        });
        console.log("FINAL SORT ORDER TO POST:", questionsCopy);
    }

    function handleTabChange(event, newValue) {
        setTabValue(newValue);
    }
    function handleQuestionsTabChange(event, newValue) {
        setQuestionsTabValue(newValue);
    }

    function handlePreview() {
        setData((newData) => {
            autosave(newData, "Preview", true);
        });
        if (data.title !== "") {
            reorderQuestions();
            setPreview(true);
        } else {
            alert("Your survey must have a title!");
        }
    }

    function handleSaveEdit() {
        setPreview(false);
        setOpenLoader(true);
        console.log("FINAL OBJECT POST: ", `${JSON.stringify(data)}`);
        axios
            .post("/api/survey/update", data, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200 && result.data.message === "Success") {
                    console.log("SAVE EDIT RESPONSE: ", result.data.data);
                    history.push(`/survey/view/${result.data.data}?save=true`);
                    deleteDraftOnSave(`${pointer}_${version}`);
                } else {
                    // TODO: REMOVE THIS...TESTING ONLY
                    history.push(`/survey/view/${data.id}?save=true`);
                }
            })
            .catch((e) => {
                console.log(e);
                alert("Something went wrong trying to save your survey. Please try again.");
            });
    }

    function handleSave() {
        const questionsCopy = [...questions];
        setPreview(false);
        setOpenLoader(true);
        const lastQuestionIndex = questionsCopy.length + 1;
        availabilityQuestion.order = parseInt(lastQuestionIndex, 10);
        // Add availability question to the end...
        questionsCopy.push(availabilityQuestion);
        data.questions = questionsCopy;
        console.log("FINAL OBJECT POST: ", `${JSON.stringify(data)}`);
        axios
            .post("/api/survey/add", data, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200 && result.data.message === "Success") {
                    console.log("SURVEY RESPONSE: ", result.data.data);
                    history.push(`/survey/view/${result.data.data}?save=true`);
                    deleteDraftOnSave(`${pointer}_${version}`);
                } else {
                    alert("Something went wrong trying to save your survey. Please try again.");
                }
            })
            .catch((e) => {
                console.log(e);
                alert("Something went wrong trying to save your survey. Please try again.");
            });
    }

    function validateFreeTextLikertQuestion(answers) {
        const errorsArray = [];
        const emptyLabels = answers.length > 0 ? answers.filter((a) => a.label === "") : [];
        // First check if there is a question
        if (question.text === "") {
            errorsArray.push("Please enter a question.");
        }
        // Next check if there are any labels without an actual question
        if (emptyLabels.length > 0) {
            errorsArray.push("You can not have any empty answer labels");
        }

        if (errorsArray.length == 0)
            setData((newData) => {
                autosave(newData, "Question Added", true);
            });
        return errorsArray;
    }

    function validateMultipleChoiceQuestion(answers) {
        const errorsArray = [];
        const emptyLabels = answers.length > 0 ? answers.filter((a) => a.label === "") : [];
        const disqualifyingLabels = answers.length > 0 ? answers.filter((a) => a.disqualifier === true) : [];
        console.log("Question? ", question);
        console.log("Answers? ", answers);
        // First check if there is a question
        if (question.text === "") {
            errorsArray.push("Please enter a question.");
        }
        // Next check there are multiple choices...
        if (answers.length < 2) {
            errorsArray.push("This type of question requires a minimum of two answer options.");
        }
        // Next check if there are any labels without an actual question
        if (emptyLabels.length > 0) {
            errorsArray.push("You can not have any empty answer labels");
        }
        // Finally, check if it is a disqualifying question
        if (question.disqualifier && disqualifyingLabels.length < 1) {
            console.log("DISQUALIFY? ", question.disqualifier);
            errorsArray.push("A disqualifying question requires at least one disqualifing answer to be assigned.");
        }
        return errorsArray;
    }

    const moveQuestion = useCallback(
        (dragIndex, hoverIndex) => {
            const dragQuestion = questions[dragIndex];
            setSort(
                update(questions, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragQuestion],
                    ],
                })
            );
        },
        [questions]
    );
    const renderQuestion = (q, index) => {
        return (
            <Question
                key={q.order}
                index={index}
                id={q.order}
                text={q.text}
                questionsArray={questions}
                moveQuestion={moveQuestion}
                sortEvent={reorderQuestions}
            />
        );
    };

    return (
        <div>
            <Backdrop
                // transitionDuration={500}
                className={classes.backdrop}
                open={openLoader}
                onClick={() => {
                    setOpenLoader(false);
                }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container spacing={1}>
                <Grid item sm={12} xs={12}>
                    <Grid container spacing={2}>
                        <Grid item sm={12} xs={12}>
                            <h2 className={classes.heading}>Step 1: Type</h2>
                            <TextField
                                fullWidth
                                id="type"
                                name="type"
                                select
                                label="Select the type of survey (REQUIRED)"
                                value={data.type}
                                onChange={handleChange}
                                margin="normal"
                            >
                                {SurveyTypes.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <h2 className={classes.heading}>Step 2: Title</h2>
                            <TextField
                                fullWidth
                                id="title"
                                label="Enter a survey title (REQUIRED)"
                                margin="normal"
                                name="title"
                                onChange={handleChange}
                                onBlur={validateTitle}
                                value={data.title}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={8} xs={12}>
                    <h2 className={classes.heading}>Step 3: Questions</h2>
                    <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
                        <Tab label="Free Text / Likert" disabled={tabType.freetext} />
                        <Tab label="Multiple Choice" disabled={tabType.multiple} />
                        <Tab label={<Box>Saved Drafts</Box>} />
                    </Tabs>
                    <TabPanel value={tabValue} index={0}>
                        <FreeText
                            user={props.user}
                            data={question}
                            survey={questions}
                            surveyEvent={handleSurveyChange}
                            handleScaleString={handleScaleString}
                            changeEvent={handleQuestionChange}
                            settingsEvent={handleLikertCheckbox}
                            labelChangeEvent={handleLabelChange}
                            labelCheckboxEvent={handleLabelCheckbox}
                            labelDeleteEvent={handleLabelDelete}
                            labelMergeEvent={mergeEditNewLabels}
                            resetQuestionEvent={resetQuestion}
                            validateQuestionEvent={validateFreeTextLikertQuestion}
                        />
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <Multiple
                            user={props.user}
                            data={question}
                            survey={questions}
                            surveyEvent={handleSurveyChange}
                            changeEvent={handleQuestionChange}
                            settingsEvent={handleCheckboxSettings}
                            labelChangeEvent={handleLabelChange}
                            followupChangeEvent={handleFollowupQuestion}
                            labelCheckboxEvent={handleLabelCheckbox}
                            labelDeleteEvent={handleLabelDelete}
                            labelMergeEvent={mergeEditNewLabels}
                            resetQuestionEvent={resetQuestion}
                            validateQuestionEvent={validateMultipleChoiceQuestion}
                        />
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={(e) => {
                                e.stopPropagation();
                                setData((newData) => {
                                    autosave(newData, "Manual", true);
                                });
                            }}
                            aria-label="close"
                            size="small"
                            className={classes.buttonGeneric}
                        >
                            Manually Save A Draft
                        </Button>
                        {draftList.map((d) => {
                            let found = false;
                            let index = 0;
                            for (const i in draftList) {
                                if (draftList[i].title == d.title && !found) {
                                    if (draftList[i].draft_time == d.draft_time) {
                                        found = true;
                                    } else {
                                        index++;
                                    }
                                }
                            }
                            let newTitle = null;
                            if (index > 0) {
                                newTitle = `${d.title} (${index})`;
                            }
                            return (
                                <Box key={d.draft_time} sx={{ width: "100%" }}>
                                    <Button
                                        sx={{
                                            width: "80%",
                                            paddingLeft: "0px",
                                            marginLeft: 0,
                                            marginBottom: "10px",
                                            fontSize: ".75rem",
                                            justifyContent: "left",
                                        }}
                                        onClick={() => {
                                            if (
                                                draftList !==
                                                JSON.parse(window.localStorage.getItem("screener_drafts"))[
                                                    pointer + "_" + version
                                                ]
                                            )
                                                setDraftList(
                                                    JSON.parse(window.localStorage.getItem("screener_drafts"))[
                                                        pointer + "_" + version
                                                    ]
                                                );
                                            setData(d);
                                            setSort(d.questions || []);
                                        }}
                                    >
                                        <Typography sx={{ textAlign: "left", textTransform: "capitalize" }}>
                                            <strong>{d.action}</strong>:{" "}
                                            {newTitle ? truncateString(newTitle, 30) : truncateString(d.title, 30)}{" "}
                                            {"@"} {new Date(d.draft_time).toLocaleString()}
                                        </Typography>
                                    </Button>
                                    <Tooltip title="Delete draft">
                                        <IconButton
                                            edge="start"
                                            color="inherit"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                const projectDrafts = drafts[pointer + "_" + version] || [];
                                                const removedDrafts = projectDrafts.filter(
                                                    (dr) => dr.draft_time !== d.draft_time
                                                );
                                                const updatedDrafts = drafts;
                                                updatedDrafts[pointer + "_" + version] = removedDrafts;
                                                window.localStorage.setItem(
                                                    `screener_drafts`,
                                                    JSON.stringify(updatedDrafts)
                                                );
                                                setDrafts(updatedDrafts);
                                                setDraftList(removedDrafts);
                                            }}
                                            sx={{ color: "#CC0000" }}
                                            aria-label="close"
                                            size="small"
                                        >
                                            <Delete />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            );
                        })}
                    </TabPanel>
                </Grid>
                <Grid item sm={4} xs={12}>
                    <div className={classes.saveSurvey}>
                        <Button
                            // disabled={questions.length < 1 && true}
                            fullWidth
                            onClick={handlePreview}
                            size="large"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Preview &amp; Save
                        </Button>
                    </div>
                    <div className={classes.centeredContainer}>
                        <ArrowDown className={classes.arrowDown} />
                    </div>
                    <h3 className={classes.surveyType}>
                        TYPE: <strong>{SurveyTypes.find((s) => s.value === parseInt(data.type, 10).label)}</strong>
                    </h3>
                    <h2 className={classes.surveyTitle}>{data.title}</h2>
                    <Tabs
                        value={questionsTab}
                        onChange={handleQuestionsTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab label="Edit" />
                        <Tab label="Sort" />
                    </Tabs>
                    <TabPanel value={questionsTab} index={0}>
                        <div className={classes.tabBackground}>
                            {questions.length < 1 && (
                                <Typography className={classes.question}>
                                    <Warning /> Missing questions
                                </Typography>
                            )}
                            {questions.map(function (q, idx) {
                                const key = `key_${idx}`;
                                return (
                                    <Paper className={classes.paper} key={key}>
                                        <div className={classes.questionActions}>
                                            <Button onClick={() => editQuestion(q, idx)}>
                                                <Edit />
                                            </Button>{" "}
                                            <Button onClick={() => copyQuestion(q, idx)}>
                                                <CopyIcon />
                                            </Button>{" "}
                                            <Button onClick={() => deleteQuestion(idx)}>
                                                <Delete />
                                            </Button>
                                        </div>
                                        <p className={classes.type}>
                                            {q.order}:
                                            {
                                                QuestionTypes.find((t) => t.value === parseInt(q.question_type_id, 10))
                                                    .label
                                            }
                                        </p>
                                        <div
                                            className={classes.question}
                                            dangerouslySetInnerHTML={{
                                                __html: q.text,
                                            }}
                                        />
                                    </Paper>
                                );
                            })}
                        </div>
                    </TabPanel>
                    <TabPanel value={questionsTab} index={1}>
                        <div className={classes.tabBackground}>
                            <Typography className={classes.question}>
                                <Sort /> Drag and Drop to sort
                            </Typography>
                            <div>{questions.map((q, i) => renderQuestion(q, i))}</div>
                        </div>
                    </TabPanel>
                </Grid>
            </Grid>
            <Dialog fullScreen open={preview} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" size="large">
                            <CloseIcon />
                        </IconButton>
                        {version === "Create" ? (
                            <div className={classes.saveBtn}>
                                <Button
                                    size="large"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={handleSave}
                                >
                                    <Save /> SAVE SURVEY
                                </Button>
                            </div>
                        ) : (
                            <div className={classes.saveBtn}>
                                <Button
                                    size="large"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={handleSaveEdit}
                                >
                                    <Save /> SAVE EDITS
                                </Button>
                            </div>
                        )}
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item sm={12} xs={12}>
                            <div className={classes.tabBackground}>
                                {questions.map(function (q, idx) {
                                    const key = `key_${idx}`;
                                    return (
                                        <Paper className={classes.paper} key={key}>
                                            <div className={classes.questionActions}>
                                                <Button onClick={() => editQuestion(q, idx)}>Edit</Button> |{" "}
                                                <Button onClick={() => deleteQuestion(idx)}>Delete</Button>
                                            </div>
                                            <p className={classes.type}>
                                                <strong>Question {q.order}:</strong>{" "}
                                                {
                                                    QuestionTypes.find(
                                                        (t) => t.value === parseInt(q.question_type_id, 10)
                                                    ).label
                                                }
                                            </p>
                                            <div
                                                className={classes.question}
                                                dangerouslySetInnerHTML={{
                                                    __html: q.text,
                                                }}
                                            />
                                            {parseInt(q.question_type_id, 10) === 1 &&
                                                q.answers.length > 0 &&
                                                q.answers.map((l, i) => {
                                                    const k = `answer_${i}`;
                                                    return (
                                                        <TextField
                                                            disabled
                                                            fullWidth
                                                            className={classes.previewTextField}
                                                            label={`${l.label}`}
                                                            key={k}
                                                            value="Text Answer"
                                                        />
                                                    );
                                                })}
                                            {parseInt(q.question_type_id, 10) === 3 &&
                                                q.answers.length > 0 &&
                                                q.answers.map((l, i) => {
                                                    const k = `answer_${i}`;
                                                    return (
                                                        <div key={k}>
                                                            <Typography className={classes.label}>{l.label}</Typography>
                                                            <FormControl
                                                                component="fieldset"
                                                                className={classes.formControl}
                                                            >
                                                                <RadioGroup className={classes.likert}>
                                                                    {q.scale &&
                                                                    q.scale.length > 1 &&
                                                                    q.scale.indexOf(",") !== -1
                                                                        ? q.scale.split(",").map((s, j) => {
                                                                              const key = `key_${j}`;
                                                                              return (
                                                                                  <FormControlLabel
                                                                                      key={key}
                                                                                      value={s}
                                                                                      control={<Radio />}
                                                                                      label={`${s}`}
                                                                                  />
                                                                              );
                                                                          })
                                                                        : [1, 2, 3, 4, 5].map((s, j) => {
                                                                              const key = `key_${j}`;
                                                                              return (
                                                                                  <FormControlLabel
                                                                                      key={key}
                                                                                      value={s}
                                                                                      control={<Radio />}
                                                                                      label={`${s}`}
                                                                                  />
                                                                              );
                                                                          })}
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </div>
                                                    );
                                                })}
                                            {parseInt(q.question_type_id, 10) === 2 && (
                                                <FormControl component="fieldset" className={classes.formControl}>
                                                    <RadioGroup>
                                                        {q.answers.length > 0 &&
                                                            q.answers.map((l, i) => {
                                                                const k = `answer_${i}`;
                                                                return (
                                                                    <div key={k}>
                                                                        <FormControlLabel
                                                                            disabled
                                                                            value=""
                                                                            control={
                                                                                q.multiple ? <Checkbox /> : <Radio />
                                                                            }
                                                                            label={`${l.label}`}
                                                                        />
                                                                        {l.disqualifier && l.disqualifier !== null && (
                                                                            <p>
                                                                                <em>
                                                                                    This answer is set as a
                                                                                    disqualifier!
                                                                                </em>
                                                                            </p>
                                                                        )}
                                                                        {l.followupquestion.length > 0 &&
                                                                            l.followupquestion !== null &&
                                                                            l.followupquestion[0].text !== "" && (
                                                                                <p>
                                                                                    Follow up question:{" "}
                                                                                    <strong>
                                                                                        {l.followupquestion[0].text}
                                                                                    </strong>
                                                                                </p>
                                                                            )}
                                                                    </div>
                                                                );
                                                            })}
                                                    </RadioGroup>
                                                </FormControl>
                                            )}
                                        </Paper>
                                    );
                                })}
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            {error && (
                <FlashMessagev2
                    error={error}
                    severity={"info"}
                    message={errorMsg}
                    duration={errorMessageTimeout}
                    vertical={"bottom"}
                />
            )}
        </div>
    );
}

SurveyCanvas.propTypes = {
    match: PropTypes.object,
};
SurveyCanvas.defaultProps = {
    match: null,
};

export default SurveyCanvas;
