import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import makeStyles from "@mui/styles/makeStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { authContext } from "../../context/auth";
import CheckOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import VerifiedIcon from "@mui/icons-material/Verified";
import ReportIcon from "@mui/icons-material/Report";
import Paper from "@mui/material/Paper";
import { basicDateTimeStamp, truncateString } from "../../Utils";
// MUI - style imports
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import AutoOutreachCounts from "./AutoOutreachCounts";
import ExpertPreviewWrapperNext from "../../components/wrappers/ExpertPreviewWrapperNext";

// ======
// STYLES
// ======

const linkStyle = css`
    color: #8966ad;
    font-size: 1rem;
    font-weight: bold;
    padding: 0;
    text-align: left;
    line-height: 1.25rem;
`;

const stopButtonStyle = css`
    border-radius: 4px;
    border: 0;
    padding: 0.5rem 1rem;
    background-color: #8966ad;
    color: white;
    font-weight: 800;
    &:hover {
        background-color: #ff949d;
        color: black;
    }
`;
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(2),
        overflowX: "auto",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

export default function AutomatedLeads(props) {
    // =========
    // CONSTANTS
    // =========
    const classes = useStyles();
    const { project_id } = props;

    // =====
    // STATE
    // =====

    const [data, setData] = useState([]);
    const { auth } = useContext(authContext);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [currentExpert, setCurrentExpert] = useState(null);

    // =========
    // PAGE LOAD
    // =========

    async function loadAutomatedLeads() {
        await axios
            .get(`/api/project/now/findAutomatedExpertList/${project_id}`, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((response) => {
                if (response.data && response.data.data.data) {
                    const result = response.data.data.data;
                    setData(result);
                    setLoading(false);
                    console.log("DID THE AUTO-STATUS TABLE LOAD DATA? ", result);
                }
            });
    }

    useEffect(() => {
        loadAutomatedLeads();
    }, []);

    // =============
    // UTILITY FUNCS
    // =============

    function openExpertPreview(expertId) {
        setCurrentExpert(expertId);
        setOpen(true);
    }

    // ============
    // HANDLE FUNCS
    // ============

    async function handleStopOutreach({ expert_id }) {
        await axios
            .post(`/api/project/now/stopOutreach`, {
                user_id: auth.data.id,
                project_id,
                expert_id,
                auto_outreach_status: 5,
            })
            .then((response) => {
                console.log(response, " :: response");
                loadAutomatedLeads();
            })
            .catch((error) => {
                console.log(error, " :: error");
            });
    }
    if (loading) {
        return (
            <Backdrop
                className={classes.backdrop}
                open={loading}
                onClick={() => {
                    setLoading(false);
                }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    } else {
        return (
            <>
                <h2 style={{ fontSize: "1.25rem" }}>Total: {data.length}</h2>
                <p>
                    <strong>FILTER:</strong> ALL leads scraped in or manually attached to a project with a screener
                    selected which triggered automated outreach and followups.
                    <br />
                    <strong>RECOMMENDATION:</strong> Track the automation flow. The results of this process will fall
                    into the other tabs. You can stop the automation flow if the lead contacts you directly, otherwise
                    there is nothing else to do with this table.
                </p>
                <AutoOutreachCounts project_id={project_id} />
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow sx={{ backgroundColor: "#444" }}>
                                <TableCell sx={{ color: "#fff" }}>Scraped Lead</TableCell>
                                <TableCell align="left" sx={{ color: "#fff" }}>
                                    Impersonation
                                </TableCell>
                                <TableCell align="right" sx={{ color: "#fff" }}>
                                    Initial
                                </TableCell>
                                <TableCell align="right" sx={{ color: "#fff" }}>
                                    F-1
                                </TableCell>
                                <TableCell align="right" sx={{ color: "#fff" }}>
                                    F-2
                                </TableCell>
                                <TableCell align="right" sx={{ color: "#fff" }}>
                                    F-3
                                </TableCell>
                                <TableCell align="right" sx={{ color: "#fff" }}>
                                    <ReportIcon />
                                </TableCell>
                                <TableCell align="right" sx={{ color: "#fff" }}>
                                    Status
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, index) => (
                                <TableRow
                                    key={`${row.expert_id}_${index}`}
                                    sx={{
                                        "&:last-child td, &:last-child th": { border: 0 },
                                        backgroundColor: `${index % 2 === 0 ? "#f5f5f5" : "#fff"}`,
                                    }}
                                >
                                    <TableCell align="left">
                                        <Button css={linkStyle} onClick={() => openExpertPreview(row.expert_id)}>
                                            {row.fname} {row.lname}
                                            {row.suffix ? `, ${row.suffix}` : ""}
                                            {row.has_signed_terms && row.disclosures && (
                                                <VerifiedIcon style={{ color: "#523197", paddingLeft: "5px" }} />
                                            )}
                                        </Button>
                                        {row.empHistData && row.empHistData.length > 0 ? (
                                            <div>{`${truncateString(
                                                row.empHistData[0].position,
                                                25
                                            )} at ${truncateString(row.empHistData[0].company, 25)}`}</div>
                                        ) : (
                                            <div>{truncateString(row.headline, 25)}</div>
                                        )}
                                        {row.expert_email && <div>Email: {row.expert_email}</div>}
                                        <a
                                            href={`https://www.firstthought.io/onboard/${row.token}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Onboard Link
                                        </a>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {basicDateTimeStamp(row.created_on)}
                                        <div>{`By ${row.rm_fname} ${row.rm_lname} **`}</div>
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="right">
                                        {row.initial_outreach ? <CheckOutlineIcon /> : "----"}
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="right">
                                        {row.followup_1 ? <CheckOutlineIcon /> : "----"}
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="right">
                                        {row.followup_2 ? <CheckOutlineIcon /> : "----"}
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="right">
                                        {row.followup_3 ? <CheckOutlineIcon /> : "----"}
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="right">
                                        {row.stop_outreach ? (
                                            "----"
                                        ) : (
                                            <button
                                                onClick={() => handleStopOutreach({ expert_id: row.expert_id })}
                                                css={stopButtonStyle}
                                            >
                                                STOP
                                            </button>
                                        )}
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="right">
                                        {row.followup_3 ? (
                                            <>
                                                <strong>NO RESPONSE</strong>
                                                <br />
                                                <span>{basicDateTimeStamp(row.followup_3_time)}</span>
                                            </>
                                        ) : (
                                            <>
                                                {row.stop_outreach ? (
                                                    <>
                                                        <strong style={{ color: "orange" }}>ENDED</strong>
                                                        <br />
                                                        <span>{basicDateTimeStamp(row.stopped_time)}</span>
                                                    </>
                                                ) : (
                                                    <strong style={{ color: "green" }}>IN PROGRESS...</strong>
                                                )}
                                            </>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {open && (
                    <ExpertPreviewWrapperNext
                        open={open}
                        setOpen={setOpen}
                        expert_id={currentExpert}
                        project_id={project_id}
                    />
                )}
            </>
        );
    }
}
