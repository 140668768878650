import * as React from "react";

// MUI
import MUIDataTable from "mui-datatables";
// general
import { Box, Grid, Typography } from "@mui/material";
// icons
import { Speed, SentimentVeryDissatisfied as Notification } from "@mui/icons-material";
// loader
import CircularProgress from "@mui/material/CircularProgress";

// utils
import { basicDateTimeStamp, calculatePercentage } from "../Utils";

// npm
import moment from "moment";
import "moment-duration-format";
import axios from "axios";

// custom
import useClasses from "../custom-hooks/useClasses";

const styles = (theme) => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(2),
        overflowX: "auto",
    },
    kdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    button: {
        margin: theme.spacing(1),
    },
    friendlyDate: {
        color: "#444",
        fontSize: ".85rem",
        textTransform: "uppercase",
    },
    addedToday: {
        color: "#4CAB7D",
        fontWeight: "bold",
    },
    h3: {
        color: "#000",
        fontSize: "1.15rem",
    },
    h4: {
        color: "#000",
        fontSize: ".65rem",
        textTransform: "uppercase",
        fontWeight: "bold",
    },
    flex: {
        display: "flex",
    },
    meta: {
        color: "#000",
        fontSize: ".8rem",
    },
    link: {
        color: "#8966ad",
        textDecoration: "none",
        fontSize: ".75rem",
    },
    linkColor: {
        color: "#8966ad",
        textDecoration: "none",
    },
    openProjects: {
        background: "linear-gradient(45deg, #ffffff, #fafafa, #f5f5f5, #f2f2f2)",
        border: "1px solid #eaeaea",
        borderRadius: "4px",
        padding: "15px",
    },
    metricPercentageWarning: {
        float: "",
        fontSize: "2.15rem",
        color: "#fff",
        backgroundColor: theme.palette.secondary.main,
        marginRight: "10px",
        padding: "10px",
        border: "2px solid #ccc",
        borderRadius: "50%",
    },
    metricPercentage: {
        float: "left",
        fontSize: "2.15rem",
        color: "#fff",
        backgroundColor: "#523197",
        marginRight: "10px",
        padding: "10px",
        border: "2px solid #ccc",
        borderRadius: "50%",
    },
    metricNumber: {
        float: "left",
        fontSize: "4.4rem",
        fontWeight: "bold",
        color: "#523197",
        marginRight: "10px",
        lineHeight: "100%",
        padding: "0",
    },
    metricNumberWarning: {
        float: "left",
        fontSize: "4.4rem",
        fontWeight: "bold",
        color: theme.palette.secondary.main,
        marginRight: "10px",
        lineHeight: "100%",
        padding: "0",
    },
    statsNumberFocus: {
        fontSize: "4.4rem",
        fontWeight: "bold",
        color: theme.palette.secondary.main,
        marginRight: "10px",
        lineHeight: "100%",
        padding: "0",
    },
    total: {
        color: "#000",
        textDecoration: "none",
        fontSize: ".85rem",
        textAlign: "center",
        marginTop: "15px",
        textTransform: "uppercase",
    },
    heading: {
        color: "#444",
        fontSize: ".85rem",
        marginTop: "0",
        marginBottom: "5px",
        borderBottom: "2px solid #eaeaea",
    },
    table: {
        color: "#444",
        fontSize: ".85rem",
        marginTop: "20px",
        marginBottom: "20px",
        border: "1px solid #eaeaea",
        padding: "15px",
        backgroundColor: "#fafafa",
    },
    tableStyles: {
        width: "100%",
    },
    th: {
        borderBottom: "2px solid #CCC",
        paddingTop: "15px",
        textTransform: "uppercase",
        fontSize: ".5rem",
        color: "#999",
    },
    thInteger: {
        borderBottom: "2px solid #CCC",
        paddingTop: "15px",
        textAlign: "right",
        textTransform: "uppercase",
        fontSize: ".5rem",
        color: "#999",
    },
    row: {
        borderBottom: "1px solid #eaeaea",
        padding: "5px",
        backgroundColor: "#fff",
    },
    tdString: {
        textAlign: "left",
        padding: "3px",
    },
    tdInteger: {
        textAlign: "right",
        padding: "3px",
    },
    notification: {
        color: theme.palette.secondary.main,
    },
    check: {
        color: "green",
    },
    held: {
        color: theme.palette.secondary.main,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    graphContainer: {
        margin: "1.5rem 0",
    },
    accordionSummary: {
        backgroundColor: "#f5f5f5",
    },
    accordionSummaryTypography: {
        fontSize: "1.25rem",
        fontWeight: "700",
    },
    boldText: {
        fontWeight: "700",
    },
    listItem: {
        listStyle: "none",
    },
    largerLiText: {
        fontWeight: "700",
        fontSize: "1.25rem",
    },
    sectionDividerText: {
        fontSize: "1.25rem",
        marginBottom: "2rem",
        borderBottom: "2px solid #8966ad",
        width: "fit-content",
        fontWeight: "500",
        color: "#8966ad",
    },
});

export default function UserMetricsNext(props) {
    // console.log(props, " :: props");

    /**
     * CONSTANTS
     */

    const hourCutoff = 24;
    const start = new Date();
    const today = moment(start.date);
    const classes = useClasses(styles);

    /**
     * STATE
     */

    const [rmUserStats, setRmUserStats] = React.useState({});
    const [raUserStats, setRaUserStats] = React.useState({});
    const [loadingRmStats, setLoadingRmStats] = React.useState(true);
    const [loadingRaStats, setLoadingRaStats] = React.useState(true);

    /**
     * PAGE LOAD
     */

    async function getRmStats() {
        await axios
            .get(`/api/rm/findRmStats/${props?.currentUser?.id}`)
            .then((res) => {
                // console.log(res, " :: res in findUserStats");
                const result = res?.data?.data;
                if (result) {
                    // console.log(result, " :: result getRmStats");
                    setRmUserStats(result);
                    setLoadingRmStats(false);
                }
            })
            .catch((err) => {
                console.log(err, " :: err in findRmStats");
            });
    }

    async function getRaStats() {
        await axios
            .get(`/api/rm/findRaStats/${props?.currentUser?.id}`)
            .then((res) => {
                // console.log(res, " :: res in findUserStats");
                const result = res?.data?.data;
                if (result) {
                    // console.log(result, " :: result getRaStats");
                    setRaUserStats(result);
                    setLoadingRaStats(false);
                }
            })
            .catch((err) => {
                console.log(err, " :: err in findRaStats");
            });
    }

    async function findUserStats() {
        // IF RM
        if (props?.currentUser?.isRmOrRa === "RM") {
            getRmStats();
        } else if (props?.currentUser?.isRmOrRa === "RA") {
            getRaStats();
        } else if (props?.currentUser?.isRmOrRa === "HYBRID") {
            getRaStats();
            getRmStats();
        }
    }

    React.useEffect(() => {
        findUserStats();
    }, [props?.currentUser?.id]);

    /**
     * HELPER FUNCS
     */

    const daysToHours = (days, hours) => {
        const daysToHours = days * 24;
        return daysToHours + hours;
    };

    function formatDateString(str) {
        let dtstamp = "-----";
        if (str !== "" && str !== null) {
            dtstamp = moment(str).format("MMM D, YYYY h:mm a");
        }
        return dtstamp;
    }
    function friendlyDateString(str) {
        const dtstamp = moment(str).format("dddd, MMMM Do YYYY");
        return dtstamp;
    }

    function displayTurnaroundIcon(num) {
        if (num === null) {
            return <Notification className={classes.notification} />;
        }

        const days = num.days === undefined ? 0 : num.days;
        const hours = num.hours === undefined ? 0 : num.hours;
        const minutes = num.minutes === undefined ? 0 : num.minutes;

        if (hourCutoff >= daysToHours(days, hours)) {
            return <Speed className={classes.check} />;
        }
        return `${days}d ${hours}h ${minutes}m`;
    }

    /**
     * TABLE
     */

    const optionsTable1 = {
        print: false,
        rowsPerPage: 100,
        viewColumns: false,
        download: true,
        selectableRows: "none",
        fixedHeader: true,
    };

    const columnsTable1 = [
        {
            name: "title",
            label: "Project",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { title, id, project_status_id } = rmUserStats?.scheduledVsPlanned?.[dataIndex];

                    return (
                        <>
                            <a className={classes.linkColor} href={`/project/view/${id}`}>
                                {title}
                            </a>
                            {project_status_id === 6 && <em className={classes.held}>(On Hold)</em>}
                        </>
                    );
                },
            },
        },
        {
            name: "deadline",
            label: "Deadline",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { deadline } = rmUserStats?.scheduledVsPlanned?.[dataIndex];

                    return <>{formatDateString(deadline)}</>;
                },
            },
        },
        {
            name: "calls_planned",
            label: "Planned",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { calls_planned } = rmUserStats?.scheduledVsPlanned?.[dataIndex];

                    return <>{calls_planned}</>;
                },
            },
        },
        {
            name: "calls_scheduled",
            label: "Scheduled",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { calls_scheduled } = rmUserStats?.scheduledVsPlanned?.[dataIndex];

                    return <>{calls_scheduled}</>;
                },
            },
        },
        {
            name: "",
            label: "% Goal",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    const { calls_scheduled, calls_planned } = rmUserStats?.scheduledVsPlanned?.[dataIndex];

                    return (
                        <span>
                            {calculatePercentage(calls_scheduled, calls_planned) === "0.0" ? (
                                <Notification className={classes.notification} />
                            ) : (
                                <strong>{calculatePercentage(calls_scheduled, calls_planned)}%</strong>
                            )}
                        </span>
                    );
                },
            },
        },
    ];

    const optionsTable2 = {
        print: false,
        rowsPerPage: 100,
        viewColumns: false,
        download: true,
        selectableRows: "none",
        fixedHeader: true,
    };

    const columnsTable2 = [
        {
            name: "project_title",
            label: "Project",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { project_title, project_id, project_status_id } =
                        rmUserStats?.createdVsSentToClient?.[dataIndex];

                    return (
                        <>
                            <a className={classes.linkColor} href={`/project/view/${project_id}`}>
                                {project_title}
                            </a>
                            {project_status_id === 6 && <em className={classes.held}>(On Hold)</em>}
                        </>
                    );
                },
            },
        },
        {
            name: "project_deadline",
            label: "Deadline",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { project_deadline } = rmUserStats?.createdVsSentToClient?.[dataIndex];

                    return <>{formatDateString(project_deadline)}</>;
                },
            },
        },
        {
            name: "project_created_on",
            label: "Created",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { project_created_on } = rmUserStats?.createdVsSentToClient?.[dataIndex];

                    return <>{formatDateString(project_created_on)}</>;
                },
            },
        },
        {
            name: "sent_to_client_on",
            label: "Sent to Client",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { sent_to_client_on } = rmUserStats?.createdVsSentToClient?.[dataIndex];

                    return <>{formatDateString(sent_to_client_on)}</>;
                },
            },
        },
        {
            name: "total_turnaround",
            label: "Turnaround",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    const { total_turnaround } = rmUserStats?.createdVsSentToClient?.[dataIndex];

                    return <>{displayTurnaroundIcon(total_turnaround)}</>;
                },
            },
        },
    ];

    const optionsTable3 = {
        print: false,
        rowsPerPage: 100,
        viewColumns: false,
        download: true,
        selectableRows: "none",
        fixedHeader: true,
    };

    const columnsTable3 = [
        {
            name: "fname",
            label: "Expert",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { fname, lname, expert_id } = raUserStats?.convertedLeadsInfo?.[dataIndex];

                    return (
                        <>
                            <a className={classes.linkColor} href={`/expert/view/${expert_id}`}>
                                {fname} {lname}
                            </a>
                        </>
                    );
                },
            },
        },
        {
            name: "deadline",
            label: "Deadline",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { deadline } = raUserStats?.convertedLeadsInfo?.[dataIndex];

                    return <>{formatDateString(deadline)}</>;
                },
            },
        },
        {
            name: "created_on",
            label: "Converted / Scheduled On",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { created_on } = raUserStats?.convertedLeadsInfo?.[dataIndex];

                    return <>{basicDateTimeStamp(created_on)}</>;
                },
            },
        },
        {
            name: "title",
            label: "Project",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { title, id, project_status_id } = raUserStats?.convertedLeadsInfo?.[dataIndex];

                    return (
                        <>
                            <a className={classes.linkColor} href={`/project/view/${id}`}>
                                {title}
                            </a>
                            {project_status_id === 6 && <em className={classes.held}>(On Hold)</em>}
                        </>
                    );
                },
            },
        },
    ];

    const optionsTable4 = {
        print: false,
        rowsPerPage: 100,
        viewColumns: false,
        download: true,
        selectableRows: "none",
        fixedHeader: true,
    };

    const columnsTable4 = [
        {
            name: "title",
            label: "Project",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { title, id, project_status_id } = raUserStats?.scheduledVsPlannedRa?.[dataIndex];

                    return (
                        <>
                            <a className={classes.linkColor} href={`/project/view/${id}`}>
                                {title}
                            </a>
                            {project_status_id === 6 && <em className={classes.held}>(On Hold)</em>}
                        </>
                    );
                },
            },
        },
        {
            name: "deadline",
            label: "Deadline",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { deadline } = raUserStats?.scheduledVsPlannedRa?.[dataIndex];

                    return <>{formatDateString(deadline)}</>;
                },
            },
        },
        {
            name: "calls_planned",
            label: "Planned",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { calls_planned } = raUserStats?.scheduledVsPlannedRa?.[dataIndex];

                    return <>{calls_planned}</>;
                },
            },
        },
        {
            name: "calls_scheduled",
            label: "Scheduled",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { calls_scheduled } = raUserStats?.scheduledVsPlannedRa?.[dataIndex];

                    return <>{calls_scheduled}</>;
                },
            },
        },
    ];

    /**
     * RENDER FUNCS
     */

    function renderRmView(rmUserStats) {
        // console.log(rmUserStats, " :: rmUserStats");
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <h2 className={classes.friendlyDate}>{friendlyDateString(today)}</h2>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <div className={classes.openProjects}>
                        <h4 className={classes.h4}>Calls Remaining (Open Projects):</h4>

                        <div className={classes.metricNumberWarning}>
                            {/* TODO: make this green if 0 */}
                            {parseInt(rmUserStats?.totalOpportunitiesOpen) - parseInt(rmUserStats?.totalCompletedOpen) <
                            0
                                ? 0
                                : parseInt(rmUserStats?.totalOpportunitiesOpen) -
                                  parseInt(rmUserStats?.totalCompletedOpen)}
                        </div>

                        <Typography className={classes.meta}>
                            Planned: <strong>{parseInt(rmUserStats?.totalOpportunitiesOpen)}</strong>
                        </Typography>
                        <Typography className={classes.meta}>
                            Scheduled: <strong>{parseInt(rmUserStats?.totalCompletedOpen)}</strong>
                        </Typography>
                        <Typography className={classes.meta}>
                            Completed:{" "}
                            <strong>
                                {calculatePercentage(
                                    parseInt(rmUserStats?.totalCompletedOpen),
                                    parseInt(rmUserStats?.totalOpportunitiesOpen)
                                )}
                                %
                            </strong>
                        </Typography>
                    </div>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <div className={classes.openProjects}>
                        <h4 className={classes.h4}>Calls Remaining (On Hold Projects):</h4>

                        {/* TODO: make this green if 0 */}
                        <div className={classes.metricNumberWarning}>
                            {parseInt(rmUserStats?.totalOpportunitiesHold) - parseInt(rmUserStats?.totalCompletedHold) <
                            0
                                ? 0
                                : parseInt(rmUserStats?.totalOpportunitiesHold) -
                                  parseInt(rmUserStats?.totalCompletedHold)}
                        </div>

                        <Typography className={classes.meta}>
                            Planned: <strong>{parseInt(rmUserStats?.totalOpportunitiesHold)}</strong>
                        </Typography>
                        <Typography className={classes.meta}>
                            Scheduled: <strong>{parseInt(rmUserStats?.totalCompletedHold)}</strong>
                        </Typography>
                        <Typography className={classes.meta}>
                            Completed:{" "}
                            <strong>
                                {calculatePercentage(
                                    parseInt(rmUserStats?.totalCompletedHold),
                                    parseInt(rmUserStats?.totalOpportunitiesHold)
                                )}
                                %
                            </strong>
                        </Typography>
                    </div>
                </Grid>

                <Grid item xs={12} sm={12}>
                    {rmUserStats?.scheduledVsPlanned?.length > 0 && (
                        <MUIDataTable
                            title={"Project Execution Metrics"}
                            data={rmUserStats?.scheduledVsPlanned}
                            options={optionsTable1}
                            columns={columnsTable1}
                        />
                    )}
                    <br />
                    <br />
                    {rmUserStats?.createdVsSentToClient?.length > 0 && (
                        <MUIDataTable
                            title={"Project Turnaround Time"}
                            data={rmUserStats?.createdVsSentToClient}
                            options={optionsTable2}
                            columns={columnsTable2}
                        />
                    )}
                    <br />
                    <br />
                </Grid>
            </Grid>
        );
    }

    function renderRaView(raUserStats) {
        // console.log(raUserStats, " :: raUserStats");
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <h2 className={classes.friendlyDate}>{friendlyDateString(today)}</h2>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <div className={classes.openProjects}>
                        <h4 className={classes.h4}>Credited Calls (Daily):</h4>

                        <div className={classes.flex}>
                            <div className={classes.statsNumberFocus}>{parseInt(raUserStats?.creditedDaily)}</div>

                            <div className={classes.meta}>
                                Monthly: <strong>{parseInt(raUserStats?.creditedMonthly)}</strong>
                            </div>
                        </div>
                    </div>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <div className={classes.openProjects}>
                        <h4 className={classes.h4}>Phone Logs (Daily):</h4>

                        <div className={classes.flex}>
                            <div className={classes.statsNumberFocus}>{parseInt(raUserStats?.phoneLogsDaily)}</div>

                            <div className={classes.meta}>
                                Monthly: <strong>{parseInt(raUserStats?.phoneLogsMonthly)}</strong>
                            </div>
                        </div>
                    </div>
                </Grid>

                <Grid item xs={12} sm={12}>
                    {raUserStats?.scheduledVsPlannedRa?.length > 0 && (
                        <MUIDataTable
                            title={"My Projects"}
                            data={raUserStats?.scheduledVsPlannedRa}
                            options={optionsTable4}
                            columns={columnsTable4}
                        />
                    )}
                </Grid>

                <Grid item xs={12} sm={12}>
                    {raUserStats?.convertedLeadsInfo?.length > 0 && (
                        <MUIDataTable
                            title={"Converted Leads"}
                            data={raUserStats?.convertedLeadsInfo}
                            options={optionsTable3}
                            columns={columnsTable3}
                        />
                    )}
                </Grid>
            </Grid>
        );
    }

    if (props?.currentUser?.isRmOrRa === "RM") {
        if (loadingRmStats) {
            return (
                <Box display="flex" justifyContent="center">
                    <CircularProgress />
                </Box>
            );
        } else {
            return renderRmView(rmUserStats);
        }
    } else if (props?.currentUser?.isRmOrRa === "RA") {
        if (loadingRaStats) {
            return (
                <Box display="flex" justifyContent="center">
                    <CircularProgress />
                </Box>
            );
        } else {
            return renderRaView(raUserStats);
        }
    } else if (props?.currentUser?.isRmOrRa === "HYBRID") {
        if (loadingRaStats) {
            return (
                <Box display="flex" justifyContent="center">
                    <CircularProgress />
                </Box>
            );
        } else if (loadingRmStats) {
            return (
                <Box display="flex" justifyContent="center">
                    <CircularProgress />
                </Box>
            );
        } else {
            return (
                <>
                    <div className={classes.sectionDividerText}>RM PROJECTS</div>
                    {renderRmView(rmUserStats)}
                    <hr />
                    <div className={classes.sectionDividerText}>RA PROJECTS</div>
                    {renderRaView(raUserStats)}
                </>
            );
        }
    } else {
        <div>There was a problem loading the stats...</div>;
    }
}
