// /* eslint-disable no-extend-native */
/* eslint-disable indent */
import React, { useState } from "react";
import { Typography } from "@mui/material";
import "moment-duration-format";
import { Container } from "@mui/material";
import { Link } from "@mui/material";
import moment from "moment";
import { Box } from "@mui/system";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "@mui/material";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/material/IconButton";
import { stripHtml } from "../Utils";

// eslint-disable-next-line no-extend-native
String.prototype.trunc =
    String.prototype.trunc ||
    function (n) {
        return this.length > n ? `${this.substr(0, n - 1)}...more` : this;
    };

export default function ExpertNameRow(props) {
    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const data = props.data;
    const dataIndex = props.dataIndex;

    // Init Stepper Settings
    const getSteps = () => {
        return [
            {
                label: "Responded",
                confirmed:
                    data.data[dataIndex].disclosure_steps &&
                    data.data[dataIndex].disclosure_steps.length > 0 &&
                    data.data[dataIndex].disclosure_steps.find((s) => s.activity === "DECLINED") !== undefined,
            },
            {
                label: "Contact",
                confirmed:
                    data.data[dataIndex].disclosure_steps &&
                    data.data[dataIndex].disclosure_steps.length > 0 &&
                    data.data[dataIndex].disclosure_steps.find((s) => s.activity === "CONTACT_INFO") !== undefined,
            },
            {
                label: "Terms",
                confirmed:
                    data.data[dataIndex].disclosure_steps &&
                    data.data[dataIndex].disclosure_steps.length > 0 &&
                    data.data[dataIndex].disclosure_steps.find((s) => s.activity === "TERMS_SIGNED") !== undefined,
            },
            {
                label: "Disclosures",
                confirmed:
                    data.data[dataIndex].disclosure_steps &&
                    data.data[dataIndex].disclosure_steps.length > 0 &&
                    data.data[dataIndex].disclosure_steps.find((s) => s.activity === "DISCLOSURES") !== undefined,
            },
            {
                label: "Employment",
                confirmed:
                    data.data[dataIndex].disclosure_steps &&
                    data.data[dataIndex].disclosure_steps.length > 0 &&
                    data.data[dataIndex].disclosure_steps.find((s) => s.activity === "EMPLOYMENT") !== undefined,
            },
        ];
    };

    const steps = getSteps();

    // Helper Functions

    function formatDateString(str) {
        return moment(str).format("MMM Do, YYYY");
    }

    const formatActivity = (str) => {
        if (str === "Attached") {
            return "Attached To Project";
        } else if (str === "Scheduled") {
            return "Call Scheduled";
        }
    };
    return (
        <Container>
            <Link
                sx={{
                    color: theme.palette.primary.main,
                    textDecoration: "none",
                    fontSize: ".85rem",
                }}
                href={`/expert/view/${data.data[dataIndex].id}`}
            >
                {data.data[dataIndex].fname} {data.data[dataIndex].lname}
            </Link>

            {data.data[dataIndex].headline ? (
                <Typography
                    sx={{
                        color: "#444",
                        fontSize: ".75rem",
                        marginTop: "0",
                        marginBottom: "5px",
                        textDecoration: "none",
                    }}
                >
                    {data.data[dataIndex].headline}
                </Typography>
            ) : (
                <br />
            )}

            {data.data[dataIndex].biography ? (
                <Typography sx={{ color: "#666", fontSize: ".85rem", marginTop: "0" }}>
                    {stripHtml(data.data[dataIndex].biography.trunc(500))}
                </Typography>
            ) : (
                <Typography
                    sx={{
                        color: theme.palette.error.light,
                        fontSize: ".75rem",
                        marginTop: "0",
                    }}
                >
                    * No Biography
                </Typography>
            )}

            {data.data[dataIndex].tags && (
                <Typography sx={{ color: "#666", fontSize: ".85rem", marginTop: "0" }}>
                    Tags:
                    {data.data[dataIndex].tags.map(function (t, idx) {
                        return (
                            <strong key={idx}>
                                {" "}
                                {t.name}
                                {idx < data.data[dataIndex].tags.length - 1 && ", "}
                            </strong>
                        );
                    })}
                </Typography>
            )}
            {data.data[dataIndex].mail === "" && (
                <Box component={"span"} sx={{ color: "#CC0000", fontSize: ".85rem" }}>
                    * No Email{" "}
                </Box>
            )}

            {data.data[dataIndex].activities && data.data[dataIndex].activities.length > 0 ? (
                <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            ) : (
                ""
            )}
            {data.data[dataIndex].activities && data.data[dataIndex].activities.length > 0 ? (
                <Box margin={1}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {data.data[dataIndex].disclosure_steps && data.data[dataIndex].disclosure_steps.length > 0 && (
                            <Box display="flex" justifyContent="flex-start" alignItems="center">
                                <Stepper sx={{ width: "90%", padding: "0", paddingTop: "1rem" }} alternativeLabel>
                                    {steps.map((label) => (
                                        <Step
                                            key={label.label}
                                            completed={label.confirmed}
                                            style={{ paddingLeft: ".8rem" }}
                                        >
                                            <StepLabel>{label.label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Box>
                        )}
                        <Box
                            component={"div"}
                            sx={{
                                marginBottom: ".5rem",
                                color: "#444",
                                fontSize: ".85rem",
                                marginTop: "0",
                            }}
                        >
                            Activities:{" "}
                        </Box>
                        <List dense>
                            {data.data[dataIndex].activities.map((activity, index) => {
                                return (
                                    <ListItem key={index} disablePadding>
                                        <ListItemButton component="a" href={`/project/view/${activity.project_id}`}>
                                            <ListItemText
                                                disableTypography
                                                sx={{
                                                    color: "#666",
                                                    fontSize: ".85rem",
                                                    marginTop: "0",
                                                }}
                                                primary={
                                                    <Typography>
                                                        <Box
                                                            component="span"
                                                            sx={{ fontSize: ".85rem" }}
                                                            fontWeight="fontWeightLight"
                                                        >
                                                            {formatActivity(activity.action)}
                                                        </Box>{" "}
                                                        -{" "}
                                                        <Box
                                                            component="span"
                                                            sx={{
                                                                color: "#666",
                                                                fontSize: ".85rem",
                                                                marginTop: "0",
                                                                textDecoration: "underline",
                                                            }}
                                                        >
                                                            {activity.title}
                                                        </Box>{" "}
                                                        -{" "}
                                                        <Box
                                                            component="span"
                                                            sx={{
                                                                color: "#000",
                                                                fontSize: ".85rem",
                                                                marginTop: "0",
                                                            }}
                                                        >
                                                            {formatDateString(activity.created_on)}
                                                        </Box>
                                                    </Typography>
                                                }
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Collapse>
                </Box>
            ) : (
                ""
            )}
        </Container>
    );
}
