import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import axios from "axios";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import AddSpecialty from "./AddSpecialty";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    label: {
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(0.5),
        fontSize: "1rem",
    },
}));

function SpecialtyField(props) {
    const classes = useStyles();
    const [specialty, setSpecialty] = useState([]);
    const { changeEvent, selectedSpecialties } = props;

    async function loadSpecialty() {
        await axios
            .get("/api/specialty", {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((response) => {
                const items = response.data.data;
                const options = items.map((item) => {
                    return {
                        value: item.id,
                        label: item.name,
                    };
                });
                setSpecialty(options);
            });
    }

    useEffect(() => {
        loadSpecialty();
    }, []);
    return (
        <div>
            <Grid container spacing={3}>
                <Grid item sm={12} xs={12}>
                    <div className={classes.label}>Specialty</div>
                    <Select
                        isMulti
                        name="specialty"
                        onChange={changeEvent}
                        options={specialty}
                        value={selectedSpecialties}
                        isDisabled={props.isReadOnly}
                    />
                </Grid>
                {props.isReadOnly ? null : (
                    <Grid item sm={12} xs={12}>
                        <AddSpecialty specialtyLoadEvent={loadSpecialty} />
                    </Grid>
                )}
            </Grid>
        </div>
    );
}

SpecialtyField.propTypes = {
    changeEvent: PropTypes.func,
    selectedSpecialties: PropTypes.array,
};

SpecialtyField.defaultProps = {
    changeEvent: PropTypes.func,
    selectedSpecialties: [],
};

export default SpecialtyField;
