import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import axios from "axios";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
    },
    chip: {
        margin: theme.spacing(1),
    },
    list: {
        margin: theme.spacing(1),
        marginLeft: "0",
        paddingInlineStart: "0",
        listStyleType: "none",
        listStylePosition: "outside",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: "75%",
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    errorMessaging: {
        color: "red",
    },
    successMessaging: {
        color: "green",
    },
    margin: {
        margin: theme.spacing(1),
    },
}));

function AddSpecialty(props) {
    const classes = useStyles();
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [specialty, setSpecialty] = useState({
        name: "",
        group_name: "",
    });

    const handleExpand = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    function handleChange(event) {
        const target = event.target;
        const value = target.value
            .split(" ")
            .map((w) => w.substring(0, 1).toUpperCase() + w.substring(1))
            .join(" ");
        const name = target.name;
        setError(false);
        setSuccess(false);
        setSpecialty({
            ...specialty,
            [name]: value,
        });
    }

    function checkForDupes() {
        if (specialty.name.trim() !== "") {
            axios
                .get("/api/specialty", {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                })
                .then(function (response) {
                    const items = response.data.data;
                    const currentSpecialty = items.map(function (item) {
                        return item.name.toLowerCase().trim();
                    });
                    if (currentSpecialty.indexOf(specialty.name.toLowerCase().trim()) !== -1) {
                        setError(true);
                        setSuccess(false);
                    } else {
                        setError(false);
                        setSuccess(true);
                        addSpecialty();
                    }
                });
        }
    }

    function addSpecialty() {
        const params = specialty;
        axios
            .post("/api/specialty/createSpecialty", params, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    setSuccess(true);
                    // Load all the specialties again to contain new tag
                    props.specialtyLoadEvent();
                    console.log(JSON.stringify(result.data));
                } else {
                    setError(true);
                    console.log("Error trying to save the tag.");
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    return (
        <Accordion expanded={expanded === "panel1"} onChange={handleExpand("panel1")}>
            <AccordionSummary aria-controls="panel1bh-content" expandIcon={<ExpandMoreIcon />} id="panel1bh-header">
                <Typography className={classes.heading}>Create A New Specialty</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={1}>
                    <Grid item sm={12} xs={12}>
                        {error && (
                            <p className={classes.errorMessaging}>
                                The specialty name: "{specialty.name}" already exists.
                            </p>
                        )}
                        {success && (
                            <p className={classes.successMessaging}>
                                The specialty name: "{specialty.name}" is now available for use.
                            </p>
                        )}
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <TextField
                            fullWidth
                            label="Enter a new specialty here..."
                            margin="normal"
                            name="name"
                            onChange={handleChange}
                            value={specialty.name}
                        />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <TextField
                            fullWidth
                            label="Group (optional)"
                            margin="normal"
                            name="group_name"
                            onChange={handleChange}
                            value={specialty.group_name}
                        />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <Fab
                            aria-label="add"
                            className={classes.margin}
                            color="secondary"
                            onClick={checkForDupes}
                            size="medium"
                        >
                            <AddIcon />
                        </Fab>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}

export default AddSpecialty;
