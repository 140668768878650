import React, { useState, useCallback, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { withRouter } from "react-router-dom";
import Grid from "@mui/material/Grid";
import axios from "axios";
import Select from "react-select";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import moment from "moment";
import "moment-duration-format";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
    },
    appBar: {
        position: "relative",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.text.secondary,
        backgroundColor: "#f6f6bc",
        marginBottom: "15px",
    },
    list: {
        margin: theme.spacing(1),
        marginLeft: "0",
        paddingInlineStart: "0",
        listStyleType: "none",
        listStylePosition: "outside",
    },
    headlineTitle: {
        color: "#444",
        fontSize: "1.5rem",
        marginBottom: "12px",
    },
    meta: {
        color: "#000",
        fontSize: ".75rem",
        marginTop: "0",
    },
    missing: {
        color: "red",
        fontSize: ".75rem",
        marginTop: "0",
    },
    nospace: {
        fontSize: ".65rem",
        marginTop: "0",
    },
    button: {
        fontSize: ".65rem",
    },
    projects: {
        listStyleType: "none",
        borderBottom: "1px solid #D5D5D5",
        marginTop: "1rem",
    },
    buttonLink: {
        color: "#3c8864",
        textAlign: "left",
        padding: "0",
        textDecoration: "none",
        fontSize: "1rem",
        lineHeight: "1.25rem",
        fontWeight: "normal",
        textTransform: "none",
        minWidth: "auto",
        margin: "0",
    },
    margin: {
        margin: theme.spacing(1),
    },
    no_screener: {
        backgroundColor: "#fafafa",
        borderRadius: "4px",
        textAlign: "center",
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function AttachToProject(props) {
    const { project_id, selected, btnText, disabled, setFullWidth, user } = props;

    const duration = moment().subtract(90, "days");
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [attached, setAttachedDialog] = useState(false);
    const [activeBtn, setActiveBtn] = useState(true);
    const [saveAttachBtn, setSaveAttachBtn] = useState(true);
    const [projects, setProjects] = useState([]);
    const [all, setAllProjects] = useState([]);
    const { match, history } = props;
    const [projectlist, setProjectsDisplay] = useState("block");
    const [tagsdisplay, setTagsDisplay] = useState("none");
    const [screenersdisplay, setScreenersDisplay] = useState("none");
    const [data, setData] = useState({
        projects: [],
    });
    const [bulkTags, setBulkTags] = useState({
        tags: [],
        people: selected,
    });
    const [screener, setSelectedScreener] = useState({
        id: "",
        people: selected,
    });
    const [selectedProject, setProjectData] = useState({
        id: project_id ? project_id : "",
        title: "",
        tags: [],
        screeners: [],
    });

    const fetchProjectScreeners = useCallback(async (id) => {
        const response = await axios.get(`/api/project/findProjectScreeners/${id}`);
        // console.log("LOAD PROJECT SCREENERS: ", response.data.data);
        if (response.status === 200 && response.data.data) {
            const screeners = response.data.data.map((s) => {
                return { label: s.title, value: s.survey_id };
            });
            // console.log("LOAD PROJECT SCREENERS: ", screeners);
            setProjectData({
                ...selectedProject,
                id,
                screeners,
            });
            setProjectsDisplay("none");
            setTagsDisplay("none");
            setScreenersDisplay("block");
        }
    }, []);

    const fetchProjectTags = useCallback(async (id) => {
        const response = await axios.get(`/api/project/findProjectTags/${id}`);
        if (response.status === 200 && response.data.data) {
            const tags = response.data.data
                .filter((t) => t.name.indexOf("screener") == -1)
                .map((t) => {
                    return { label: t.name, value: t.tag_id };
                });
            console.log("LOAD PROJECT TAGS: ", tags);
            setProjectData({
                ...selectedProject,
                id,
                tags,
            });
            setProjectsDisplay("none");
            setScreenersDisplay("none");
            setTagsDisplay("block");
        }
    }, []);

    const handleClose = () => {
        setOpen(false);
        setAttachedDialog(false);
        setProjectsDisplay("block");
        setTagsDisplay("none");
        // console.log("PROPS: ", props.location);
        // Solves deselection issue, but also refreshes the search
        window.location.href = `${props.location.pathname}${props.location.search}`;
    };
    const handleOpen = (cc) => {
        if (selected.length > 0) {
            setOpen(true);
            // console.log("OPEN PROJECTS MODAL: ", cc);
            // console.log("Selected Experts: ", selected);
            setBulkTags({
                ...bulkTags,
                people: selected,
            });
        } else {
            alert("Please select at least 1 expert");
        }
    };
    const navigateToProject = (id) => {
        props.history.push(`/project/view/${id}`);
        setAttachedDialog(false);
        window.location.reload();
    };

    const attachToProjectEvent = (pid) => {
        const url = `/api/project/attachexperts`;
        const experts = [];
        selected.forEach((expert_id) => {
            const expertObj = {
                expert_id,
                pid,
                rmid: user.id,
                survey_id: screener.id ? parseInt(screener.id, 10) : null,
            };
            experts.push(expertObj);
        });
        // console.log("ARRAY of EXPERT OBJECTS? ", experts);
        axios
            .post(url, experts, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    setOpen(false);
                    // Show dialog with option to stay on page
                    setAttachedDialog(true);
                    // TODO: Remove this eventually
                    // navigateToProject(id);
                }
            })
            .catch(() => {
                setOpen(false);
                alert("Something went wrong while trying to attach. Please try again or contact tech team.");
                console.log(`API not done yet, 404 Error: ${JSON.stringify(experts)}`);
            });
    };

    const attachExpertToProject = (pid) => {
        // Warn user if no screener is selected
        if (!screener.id) {
            // console.log("No screener selected!");
            if (window.confirm("You have not selected a screener. Are you sure you want to continue?")) {
                attachToProjectEvent(pid);
            }
            return false;
        }
        attachToProjectEvent(pid);
    };

    function bulkTagsAdd() {
        // console.log("Bulk Tag Object Before POST: ", bulkTags);
        const url = "/api/expert/addtags";
        axios
            .post(url, bulkTags, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    // console.log("TAGS OBJECT AFTER POST?: ", result);
                    fetchProjectScreeners(selectedProject.id);
                    // TODO: Add to project if successful
                    // attachExpertToProject(selectedProject.id);
                } else {
                    console.log(`Good POST, but something went wrong: ${JSON.stringify(bulkTags)}`);
                }
            })
            .catch((result) => {
                console.log("API not done yet, 404 Error:", result);
            });
    }

    function handleProjects(array) {
        if (typeof array === "object") {
            // console.log(array.value);
            fetchProjectTags(array.value);
        }
    }
    function handleCheckbox(event) {
        const { target } = event;
        const { value } = target;
        if (event.target.checked) {
            bulkTags.tags.push(value);
            setActiveBtn(false);
        } else {
            const newArr = bulkTags.tags.filter(function (item) {
                return item !== value;
            });
            bulkTags.tags = newArr;
            bulkTags.tags.length < 1 ? setActiveBtn(true) : setActiveBtn(false);
        }
        // console.log(bulkTags.tags);
    }

    function handleRadio(event) {
        const { target } = event;
        const { value } = target;
        if (event.target.checked) {
            screener.id = value;
            setSaveAttachBtn(false);
        }
        // console.log("SELECTED SCREENER: ", screener.id);
    }

    function handleNoScreenerSelect(event) {
        if (event.target.checked) {
            if (confirm("Are you sure you want to attach without selecting a screener?") == true) {
                setSaveAttachBtn(false);
            } else {
                setSaveAttachBtn(true);
                handleClose();
            }
        } else {
            setSaveAttachBtn(true);
        }
    }

    useEffect(() => {
        axios
            .get("/api/project/recentlist", {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    // console.log("SKINNY LIST: ", result.data.data);
                    // Set for Autocomplete
                    const projectsObject = result.data.data.map((item) => {
                        return {
                            value: item.id,
                            label: item.title,
                            type: item.project_type_id,
                        };
                    });
                    console.log("SKINNY LIST DATA: ", projectsObject);
                    setAllProjects(projectsObject);
                    const openProjectsData = result.data.data
                        .filter(
                            (item) =>
                                item.project_status_id === 1 &&
                                item.project_type_id !== 3 &&
                                moment(item.created_on) > duration
                        )
                        .sort((a, b) => moment(b.created_on) - moment(a.created_on));
                    setProjects(openProjectsData);
                }
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    }, []);
    // If coming from project page to search, skip the project select view and just offer up tags and add view
    useEffect(() => {
        if (project_id) {
            fetchProjectTags(project_id);
        }
    }, [props]);

    return (
        <>
            {setFullWidth ? (
                <Button
                    disabled={disabled}
                    fullWidth={setFullWidth}
                    size="small"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={handleOpen}
                >
                    {btnText}
                </Button>
            ) : (
                <Button onClick={handleOpen}>{btnText}</Button>
            )}

            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" size="large">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Select a Project to Attach To
                        </Typography>
                        <Button color="inherit" onClick={handleClose}>
                            Cancel
                        </Button>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Box display={screenersdisplay}>
                        <h3 className={classes.headlineTitle}>Select a Screener (REQUIRED)</h3>
                        <p className={classes.meta}>
                            These screeners are associated with this project. If you would like your initial outreach
                            and followups to be automated you will need to select a screener.
                        </p>
                        {selectedProject.screeners.length < 1 && (
                            <p className={classes.missing}>This project has no screeners associated with it.</p>
                        )}
                        <FormControl>
                            <RadioGroup name="screener" onChange={handleRadio}>
                                {selectedProject.screeners.map(function (t, index) {
                                    return (
                                        <FormControlLabel
                                            value={t.value}
                                            control={<Radio />}
                                            label={`${t.value} - ${t.label}`}
                                            key={t.value}
                                        />
                                    );
                                })}
                            </RadioGroup>
                        </FormControl>
                        <br />
                        <br />
                        <div>
                            <Button
                                disabled={saveAttachBtn}
                                className={classes.margin}
                                fullWidth
                                size="large"
                                color="primary"
                                onClick={() => attachExpertToProject(selectedProject.id)}
                                variant="contained"
                            >
                                Save & Attach To Project
                            </Button>
                        </div>
                        <br />
                        <div className={classes.no_screener}>
                            <Checkbox name="no_screener" onChange={handleNoScreenerSelect} value={0} /> No Screener
                            Necessary - Survey Project
                        </div>
                    </Box>
                    <Box display={tagsdisplay}>
                        <h3 className={classes.headlineTitle}>Required - select at least one tag.</h3>
                        <p className={classes.meta}>
                            These tags are associated with this project. By group tagging these experts before
                            attaching, you get the benefits of future segmentation (Angles).
                        </p>
                        <Button
                            disabled={activeBtn}
                            className={classes.margin}
                            color="primary"
                            onClick={bulkTagsAdd}
                            variant="contained"
                        >
                            Continue To Next Step
                        </Button>
                        <br />
                        <br />
                        {selectedProject.tags.length < 1 && (
                            <p className={classes.missing}>This project has no tags associated with it.</p>
                        )}
                        {selectedProject.tags.map(function (t, index) {
                            return (
                                <p key={t.value}>
                                    <Checkbox name="tag" onChange={handleCheckbox} value={t.value} /> {t.label}
                                </p>
                            );
                        })}
                        <Button
                            disabled={activeBtn}
                            className={classes.margin}
                            color="primary"
                            onClick={bulkTagsAdd}
                            variant="contained"
                        >
                            Continue To Next Step
                        </Button>
                        {/* <Button
                            color="secondary"
                            className={classes.margin}
                            onClick={() => attachExpertToProject(selectedProject.id)}
                            variant="contained"
                        >
                            Attach Without Tagging
                        </Button> */}
                    </Box>
                    <Box display={projectlist}>
                        <Grid container spacing={2}>
                            <Grid item sm={4} xs={12}>
                                <h3>Search</h3>
                                <Select name="projects" onChange={handleProjects} options={all} value={data.projects} />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <h3>Open</h3>
                                {projects.map(function (p, idx) {
                                    return (
                                        <div key={p.id} className={classes.projects}>
                                            <p>
                                                <Button
                                                    className={classes.buttonLink}
                                                    onClick={() => fetchProjectTags(p.id)}
                                                >
                                                    {p.title}
                                                </Button>
                                            </p>
                                            {/* {p.client_contacts.map(
                                                (e) =>
                                                    e.isprimary && (
                                                        <p className={classes.nospace} key={e.id}>
                                                            Client Contact:{" "}
                                                            <strong>
                                                                {`${e.firstname} ${e.lastname}`}, {p.client_name}
                                                            </strong>
                                                        </p>
                                                    )
                                            )} */}
                                        </div>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
            <Dialog
                open={attached}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <Typography id="alert-dialog-description">
                        Experts successfully attached to {selectedProject.title}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Close
                    </Button>
                    <Button onClick={() => navigateToProject(selectedProject.id)} className={classes.link}>
                        Continue to Project
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default withRouter(AttachToProject);
