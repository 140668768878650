import * as React from "react";
import { Button } from "@mui/material";
import { CSVDownload } from "react-csv";
import axios from "axios";

export default function DownloadCsvButton({ toolbarButtonStyles, children, project_id }) {
    // CONSTANTS
    const headers = [
        { label: "Primary Phone", key: "number" },
        { label: "ID", key: "expert_id" },
        { label: "First Name", key: "fname" },
        { label: "Last Name", key: "lname" },
        { label: "Email", key: "expert_email" },
        { label: "City", key: "city" },
        { label: "State", key: "state" },
        { label: "Country", key: "country" },
        { label: "Attached", key: "attached_on" },
        { label: "Attached By", key: "attaching_rm_name" },
        { label: "Onboard Link", key: "onboarding_link" },
        { label: "Position 1", key: "position_1" },
        { label: "Company 1", key: "company_1" },
        { label: "Position 2", key: "position_2" },
        { label: "Company 2", key: "company_2" },
        { label: "Position 3", key: "position_3" },
        { label: "Company 3", key: "company_3" },
    ];

    // STATE
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    // UTIL FUNCS

    // HANDLE
    async function handleClick() {
        setLoading(true);
        await axios
            .get(`/api/project/now/generateCsv/${project_id}`)
            .then((res) => {
                const results = res?.data?.data?.data;
                if (results && results.length > 0) {
                    setData(results);
                    setLoading(false);
                }
            })
            .catch((e) => {
                console.log(e, " :: e in handleClick in DownloadCsvButton.jsx");
            });
    }

    return (
        <>
            <Button
                sx={toolbarButtonStyles}
                fullWidth
                size="small"
                variant="contained"
                color="primary"
                onClick={handleClick}
            >
                {children}
            </Button>
            {!loading && <CSVDownload data={data} target="_blank" headers={headers} filename="experts.csv" />}
        </>
    );
}
