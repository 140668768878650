import React, { useState, useEffect } from "react";

// mui imports
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Backdrop from "@mui/material/Backdrop";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        minWidth: "30vw",
    },
    callLengthInput: {
        maxwidth: "100%",
    },
    btnContainer: {
        marginTop: "1.5rem",
    },
    modalBtn: {
        marginRight: ".5em",
        marginLeft: ".5em",
    },
    accentText: {
        fontWeight: 700,
        letterSpacing: 1.1,
        fontSize: ".6rem",
    },
    dropdownBox: {
        marginTop: "0",
        marginBottom: "1.5rem",
        minWidth: "100%",
    },
    modalInput: {
        minWidth: "100%",
    },
    centerText: {
        textAlign: "center",
    },
    changeBtn: {
        marginLeft: ".5rem",
    },
}));

function OnHoldModal(props) {
    const { onHoldReason, handleModalOpenClose, openModal, updateProjectData, projectData } = props;
    const [openModalInput, setOpenModalInput] = useState(false);
    const [modalInput, setModalInput] = useState("");
    const [modalInputErr, setModalInputErr] = useState(false);
    const [modalErrMsg, setModalErrMsg] = useState("Error");
    const [tempProjectData, setTempProjectData] = useState({});
    const [tempDataFlag, setTempDataFlag] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        // setTempProjectData({ ...projectData });
        setTempProjectData({
            ...projectData,
            onHoldReason:
                Object.entries(projectData.onHoldReason).length === 0
                    ? { ...onHoldReason[0] }
                    : projectData.onHoldReason,
        });
        setTempDataFlag(true);
    }, [projectData]);

    function handleModalChange(event) {
        console.log("modal change");
        const { value, name } = event.target;

        if (value === "5") {
            setOpenModalInput(true);
        } else {
            setOpenModalInput(false);
            setModalInput("");
        }

        const onHoldObj = {
            ...onHoldReason.filter((reason) => {
                return reason.value === value;
            })[0],
        };

        setTempProjectData({
            ...tempProjectData,
            [name]: onHoldObj,
        });

        // updateProjectData(updatedProjectData);
    }

    const handleModalInput = (event) => {
        setModalInput(event.currentTarget.value);
    };

    const handleModalBtn = (event) => {
        const btnValue = event.currentTarget.value;
        if (btnValue === "cancel") {
            resetModalFields();
        } else if (btnValue === "confirm") {
            console.log(`confirm clicked ${modalInput}`);
            if (tempProjectData.onHoldReason.value === "5") {
                if (modalInput === "") {
                    setModalErrMsg("Must Have Text");
                    setModalInputErr(true);
                } else if (modalInput.length < 10) {
                    setModalErrMsg("Must Be At Least 10 Characters");
                    setModalInputErr(true);
                } else {
                    console.log("in else block");
                    const updatedState = { ...tempProjectData };
                    updatedState.onHoldReason.userReason = `${updatedState.onHoldReason.label} - ${modalInput}`;
                    updateProjectData(updatedState);
                    resetModalFields();
                }
            } else {
                updateProjectData(tempProjectData);
                resetModalFields();
            }
        }
    };

    const resetModalFields = () => {
        // figure out the cancel button logic
        setModalInput("");
        setModalInputErr(false);
        setOpenModalInput(false);
        handleModalOpenClose();
    };

    return (
        <Modal
            className={classes.modal}
            open={openModal}
            // onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={openModal}>
                <div className={classes.modalPaper}>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <h3 className={classes.modalTitle}>Hold Reason:</h3>
                        <TextField
                            variant="outlined"
                            autoComplete="onHoldReason"
                            fullWidth
                            id="onHoldReason"
                            name="onHoldReason"
                            select
                            label="On Hold Reason"
                            value={tempDataFlag ? tempProjectData.onHoldReason.value : projectData.onHoldReason.value}
                            onChange={handleModalChange}
                            margin="normal"
                            className={classes.dropdownBox}
                        >
                            {onHoldReason.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                        {openModalInput && (
                            <TextField
                                variant="outlined"
                                label="Please Explain Reason"
                                className={classes.modalInput}
                                value={modalInput}
                                error={modalInputErr}
                                onChange={handleModalInput}
                                helperText={modalInputErr ? modalErrMsg : ""}
                            />
                        )}
                        <Box className={classes.btnContainer} display="flex" flexDirection="row">
                            <Button
                                className={classes.modalBtn}
                                variant="contained"
                                color="primary"
                                value="confirm"
                                onClick={handleModalBtn}
                            >
                                Confirm
                            </Button>
                            <Button
                                className={classes.modalBtn}
                                variant="contained"
                                color="primary"
                                value="cancel"
                                onClick={handleModalBtn}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                </div>
            </Fade>
        </Modal>
    );
}

export default OnHoldModal;
