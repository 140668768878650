import React, { useState, useEffect, useContext } from "react";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import queryString from "query-string";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import axios from "axios";
import Drawer from "@mui/material/Drawer";
import moment from "moment";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "react-select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { authContext } from "../context/auth";
import Comments from "../components/Comments";
import OnHoldModal from "../components/OnHoldModal";
import SpecialtyField from "../components/SpecialtyField";
import TwilioBridgeSettings from "../components/TwilioBridgeSettings";
import ExternalBridgeSettings from "../components/ExternalBridgeSettings";
import Messaging from "../components/Messaging";
import Badge from "@mui/material/Badge";
import { stripNonNumericChars, stripHtml } from "../Utils";
import { DesktopDateTimePicker as DateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// date formatters
// import { format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import premiumOptions from "../data/premiumOptions";
import durationOptions from "../data/durationOptions";
import AddClientDialog from "../components/AddClientDialog";
import RefreshIcon from "@mui/icons-material/Refresh";
import FlashMessagev2 from "../components/FlashMessagev2";
import Impersonator from "../components/impersonation/Impersonator";
import RequiresUpdateTagManager from "../components/project/utils/RequiresUpdateTagManager";
import Outreach from "../components/auto_outreach/Outreach";

// default timezone
const DEFAULT_TIMEZONE = {
    value: 16,
    label: "(UTC-05:00) Eastern Time (US & Canada)",
    abbr: "EDT",
    offset: "-04:00",
};

const projectStatus = [
    { value: "1", label: "Open" },
    { value: "2", label: "Closed" },
    { value: "3", label: "Requested" },
    { value: "4", label: "Vetting Call" },
    // { value: "5", label: "Deleted" },
    { value: "6", label: "On Hold" },
    { value: "7", label: "Pre-Launch" },
];

const onHoldReason = [
    { value: "1", label: "Waiting on scheduled calls to occur" },
    { value: "2", label: "Client revisiting angles" },
    { value: "3", label: "Waiting on feedback from client" },
    { value: "4", label: "Paused until client meets with sponsor" },
    { value: "5", label: "Other", userReason: "" },
];

const projectType = [
    { value: "1", label: "Regular Phone Consultation" },
    { value: "2", label: "Follow Up Phone Consultation" },
    { value: "3", label: "Strategic Recruiting" },
    { value: "4", label: "Vetting Call" },
    { value: "5", label: "Follow Up Written Communication" },
    { value: "6", label: "Group Teleconference" },
    { value: "7", label: "Group In-person Meeting" },
    { value: "8", label: "Survey" },
    { value: "9", label: "Direct Engagement" },
    { value: "10", label: "Advisory Board Placement" },
    { value: "11", label: "1x1 In-Person Meeting" },
    { value: "12", label: "Focus Group" },
];
const projectPriority = [
    { value: "1", label: "Urgent" },
    { value: "2", label: "Very High" },
    { value: "3", label: "High" },
    { value: "4", label: "Medium" },
    { value: "5", label: "Low" },
];

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    adminButton: {
        float: "right",
    },
    label: {
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(0.5),
        fontSize: "1rem",
    },
    strong: {
        color: theme.palette.text.primary,
        marginBottom: theme.spacing(0.5),
        fontSize: "1rem",
    },
    margin: {
        margin: theme.spacing(1),
    },
    row: {
        marginTop: theme.spacing(2),
    },
    pushRight: {
        textAlign: "right",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    clientDiv: {
        marginTop: "15px",
    },
    clientContactDiv: {
        color: "#000",
        marginBottom: "12px",
        fontSize: ".70rem",
        backgroundColor: "#FAFAFA",
        borderBottom: "2px solid #CCC",
        padding: "8px",
    },
    leftBorder: {
        paddingLeft: "12px",
        borderLeft: "2px solid #EAEAEA",
    },
    settingsBoxTop: {
        paddingTop: "20px",
        borderTop: "5px solid #EAEAEA",
        overflow: "hidden",
    },
    settingsBox: {
        paddingBottom: "20px",
        borderBottom: "5px solid #EAEAEA",
        overflow: "hidden",
    },
    contactLabel: {
        marginBottom: "5px",
        marginTop: "0",
        padding: "0",
    },
    link: {
        color: "#3c8864",
        fontSize: "1rem",
        textDecoration: "none",
    },
    linkSmall: {
        color: "#3c8864",
        fontSize: ".75rem",
        textDecoration: "none",
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    editorWrapper: {
        border: "1px solid #EAEAEA",
        borderRadius: "2px",
        padding: "12px",
    },
    selectBox: {
        width: "100%",
    },
    refreshIcon: {
        cursor: "pointer",
        marginLeft: ".75rem",
        "&:hover": {
            transition: "250ms",
            color: "#523197",
        },
    },
    checkboxes: {
        marginLeft: "2rem",
        marginBottom: "2rem",
    },
    caution: {
        color: "orange",
        marginBottom: theme.spacing(0.5),
        fontSize: "1rem",
    },
    centerText: {
        textAlign: "center",
    },
    changeBtn: {
        marginLeft: ".5rem",
    },
}));
function formatDateString(str) {
    return moment(str).format("MMM. Do, YYYY, h:mma");
}

function Project(props) {
    // console.log(props.location, " :: props.location");

    // we're checking to see if the project page is being edited
    // we can only load the impersonation component after the project is created
    const pathArr = props.location.pathname.split("/");
    const isEditing = pathArr.includes("edit");
    const DEFAULT_PUBLIC_DESCRIPTION =
        "Our client at a [type] firm is conducting a market research study on [topic]. To inform their perspective, they hope to speak with [expert type]s who have experience [doing, researching, or managing topic]. Please note that no confidential, proprietary, or otherwise non-public information may be discussed in the context of this market research study.";

    // console.log(isEditing, " :: isEditing");
    const pageUrl = props.location.search;
    const params = queryString.parse(pageUrl);
    const { match, history } = props;
    const { setAuthData, auth } = useContext(authContext);
    const classes = useStyles();
    const [editor, setEditorState] = useState(EditorState.createEmpty());
    const [emailCopy, setEmailCopyEditorState] = useState(EditorState.createEmpty());
    const [org, setOrg] = useState({
        name: [],
    });
    const [disable, setDisableSubmit] = useState(true);
    const [fields, setFields] = useState(false);
    const [showCC, setShowClientContacts] = useState(false);
    const [open, setOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [isError, setIsError] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    // const [openModalInput, setOpenModalInput] = useState(false);
    // const [modalInput, setModalInput] = useState("");
    // const [modalInputErr, setModalInputErr] = useState(false);
    // const [modalErrMsg, setModalErrMsg] = useState("Error");
    // // on load set to true if its coming from the DB
    // const [reasonSelected, setReasonSelected] = useState(false);
    const [twilioDisplay, setTwilio] = useState(true);
    const [externalDisplay, setExternal] = useState(false);
    const [manualDisplay, setManual] = useState(false);
    const [clients, setClients] = useState();
    const [cc, setClientsContacts] = useState();
    const [pm, setProjectManagers] = useState();
    const [openLoader, setOpenLoader] = useState(false);
    const [loadingProjectData, setLoadingProjectData] = useState(true);
    const [premium, disablePremium] = useState(true);
    const [survey, displaySurveyfields] = useState(false);
    const [ptSurvey, projectTypeSurveyModal] = useState(false);
    const [ptFocusGroup, projectTypeFocusGroupModal] = useState(false);
    const [outreachConfirmation, setOutreachConfirmation] = useState(false);
    const [costplus, setCostPlus] = useState(false);
    const errorMessageTimeout = 5000;

    // Set Up Error Handling
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [data, setData] = useState({
        id: "",
        isdeleted: false,
        title: "",
        description: "",
        email_copy: "",
        project_not_received_by_email: true,
        client_id: "",
        primary_client_contact: "",
        additional_client_contacts: [],
        expert_ids: [],
        primary_project_manager: [],
        additional_project_managers: [],
        status: "1",
        onHoldReason: {},
        type: "",
        priority: "4",
        case_code: "",
        calls_planned: "",
        price_per_unit: 1000,
        industry: "",
        conference_bridge_setting: "1",
        twilio_bridge_setting: "1",
        external_bridge_numbers: [],
        notifications: [],
        specialty: [],
        tags: [],
        comments: [],
        responses: [],
        scheduled_calls: [],
        completed_calls: [],
        original_status: "",
        minimum_bonus: "1",
        effort_index: "0.00",
        premium_pricing_model: premiumOptions[0],
        grace_period: 3,
        duration_model: durationOptions[0],
        fair_market_value: 0,
        budget: 0,
        project_fee: 0,
        unit_multiplier: 1.0,
        transaction_fee: 0,
        expert_flat_rate: 0,
        // client_flat_charge: 0,
        custom_charge: 0,
        custom_hourly_rate: 1.0,
        outreach_templates: [],
        angles: [],
        deadline: null,
        topic: "",
    });
    const [csvSettings, setCsvSettings] = useState({
        enable_csv_download: false,
        blind_csv: false,
    });
    const [clientIdForCsv, setClientIdForCsv] = useState(null);

    // IMPERSONATOR
    // we need to restrict which users are allowed to create impersonations
    // so we grab the rm id list and only allow the component to load if the user is in that list
    // const [loadingRmIdList, setLoadingRmIdList] = useState(false);
    // const [isUserAuthed, setIsUserAuthed] = useState(false);

    // console.log(data, " :: data rerender");

    const getClientPricingInfo = async (clientId) => {
        // const isCreate = props.location.pathname.split("/").includes("create");
        if (props.location.pathname.indexOf("create") !== -1) {
            const result = await axios.get(`/api/client/getClientPricingInfo?clientId=${clientId}`);
            const {
                custom_charge,
                custom_hourly_rate,
                grace_period,
                price_per_unit,
                premium_pricing_model_id,
                duration_model_id,
            } = result.data.clientInfo[0];

            console.log("getClientPricingInfo: ", {
                custom_charge,
                custom_hourly_rate,
                grace_period,
                price_per_unit,
                premium_pricing_model_id,
                duration_model_id,
            });

            return {
                custom_charge,
                custom_hourly_rate,
                grace_period,
                price_per_unit,
                premium_pricing_model:
                    premiumOptions.filter((option) => option.value === premium_pricing_model_id).length === 1
                        ? premiumOptions[premium_pricing_model_id - 1]
                        : premiumOptions[0],
                duration_model:
                    durationOptions.filter((option) => option.value === duration_model_id).length === 1
                        ? durationOptions[duration_model_id - 1]
                        : durationOptions[0],
            };
        }
    };

    const pageTitle = match.params.id ? `EDIT: ${data.title}` : "Create Project";

    function validateRequiredFields() {
        console.log("Validate Fields: ", data);
        setError(false);
        if (
            data.title !== "" &&
            data.topic !== "" &&
            data.type !== "" &&
            data.primary_project_manager.label &&
            data.primary_client_contact !== ""
        ) {
            setDisableSubmit(false);
            console.log("ALL FIELDS VALID!");
        } else {
            console.log("INVALID!", data);
            setDisableSubmit(true);
        }
        if (data.type == 8) {
            displaySurveyfields(true);
            console.log("This is a survey project", type);
        }
    }

    function setBridgeDisplay(option) {
        console.log("Selected Bridge Setting: ", option);
        switch (option) {
            case "1":
                setTwilio(true);
                setExternal(false);
                setManual(false);
                break;
            case "2":
                setTwilio(false);
                setExternal(true);
                setManual(false);
                break;
            case "3":
                setTwilio(false);
                setExternal(false);
                setManual(true);
                break;
            default:
                setTwilio(true);
                setExternal(false);
                setManual(false);
        }
    }

    function onEmailCopyEditorStateChange(emailCopy) {
        setEmailCopyEditorState(emailCopy);
        const jsonHtml = convertToRaw(emailCopy.getCurrentContent());
        const html = draftToHtml(jsonHtml);
        // const editorHtml = htmlToDraft(html);
        setData({
            ...data,
            email_copy: html,
        });
        validateRequiredFields();
    }

    function onEditorStateChange(editor) {
        setEditorState(editor);
        const jsonHtml = convertToRaw(editor.getCurrentContent());
        const html = draftToHtml(jsonHtml);
        // const editorHtml = htmlToDraft(html);
        setData({
            ...data,
            description: html,
        });
        validateRequiredFields();
    }

    async function getClientContactBridgeSettings(ccid, returnObj = false) {
        console.log("Selected Client Contact ID: ", ccid);
        const contact = await axios.get(`/api/contact/findById?id=${ccid}`);
        if (contact.status === 200 && contact.data && contact.data.data) {
            console.log("Async Call Success: ", contact.data.data);
            const cdata = contact.data.data[0][0];
            const bridgeSettings = contact.data.data[3] || [];
            const primary = bridgeSettings.filter((item) => item.isprimary !== false);
            let selectedTypeID = "1";
            let selectedTwilio = "1";
            let extNumbers = [];
            // First check to see if array is empty or not
            if (primary.length > 0) {
                const primaryData = primary[0];
                selectedTypeID = primaryData.type_id.toString();
                if (primary[0].data && primary[0].data.value !== undefined) {
                    selectedTwilio = primary[0].data.value;
                }
                if (Array.isArray(primary[0].data) && primary[0].data.length > 0) {
                    extNumbers = primary[0].data;
                }
            }

            // set client id to grab
            setClientIdForCsv(cdata.client_id);

            // Fetch inherited pricing data from client org...
            const clientPricingInfoObj = await getClientPricingInfo(cdata.client_id);
            // Have to create the object for the select field for primary client contact first
            const pcc = {
                value: cdata.id,
                label: cdata.name,
                client_id: cdata.client_id,
                clientname: cdata.clientname,
            };
            setBridgeDisplay(selectedTypeID);
            if (returnObj) {
                return {
                    ...clientPricingInfoObj,
                    primary_client_contact: pcc,
                    conference_bridge_setting: selectedTypeID,
                    twilio_bridge_setting: selectedTwilio,
                    external_bridge_numbers: extNumbers,
                };
            } else {
                setData({
                    ...data,
                    ...clientPricingInfoObj,
                    primary_client_contact: pcc,
                    conference_bridge_setting: selectedTypeID,
                    twilio_bridge_setting: selectedTwilio,
                    external_bridge_numbers: extNumbers,
                });
            }
        }
        console.log("Selected Client Contact DATA: ", contact);
    }

    const handleCsvCheckbox = (event) => {
        const { checked, name } = event.currentTarget;

        setCsvSettings({ ...csvSettings, [name]: checked });
        validateRequiredFields();
    };

    function handleSpecialtyEvent(spArray) {
        if (typeof spArray === "object") {
            setData({
                ...data,
                specialty: spArray,
            });
            validateRequiredFields();
        }
    }
    function handleTagEvent(tagsArray) {
        if (typeof tagsArray === "object") {
            setData({
                ...data,
                tags: tagsArray,
            });
            validateRequiredFields();
        }
    }
    function handleBridgeChange(event) {
        setData({
            ...data,
            conference_bridge_setting: event.target.value,
        });
        setBridgeDisplay(event.target.value);
        validateRequiredFields();
    }

    const handlePremiums = (arr) => {
        console.log("PREMIUM SELECTED: ", arr);
        if (typeof arr === "object") {
            setData({
                ...data,
                premium_pricing_model: arr,
            });
            validateRequiredFields();
        }
    };

    const handleDurationModel = (arr) => {
        console.log("DURATION MODEL SELECTED: ", arr);
        if (typeof arr === "object") {
            setData({
                ...data,
                duration_model: arr,
            });
            validateRequiredFields();
        }
    };

    const handlePricePerUnitField = (event) => {
        const { target } = event;
        const { value, name } = target;
        const num = stripNonNumericChars(value);
        if (num.length > 0 && num > 0) {
            disablePremium(false);
        } else {
            disablePremium(true);
            // data.premium_pricing_model =
            //     premiumOptions.filter((option) => option.value === 1).length === 1
            //         ? premiumOptions[data.premium_pricing_model_id - 1]
            //         : premiumOptions[0];
            data.premium_pricing_model = premiumOptions[0];
        }
        setData({
            ...data,
            [name]: num,
        });
        validateRequiredFields();
    };

    function handleTwilioChange(event) {
        setData({
            ...data,
            twilio_bridge_setting: event.target.value,
        });
        validateRequiredFields();
    }

    function handleExternalBridgeSettings(settingsArray) {
        if (typeof settingsArray === "object") {
            setData({
                ...data,
                external_bridge_numbers: settingsArray,
            });
            validateRequiredFields();
        }
    }

    function getClientOrgList(id) {
        // console.log(id, " :: id being used for additional contacts");
        axios
            .get(`/api/client/find?id=${id}`, {
                email: "aanand@email.com",
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    const items = result.data.data[3];
                    console.log("getClientOrgList: ", items);
                    const options = items.map((item) => {
                        return {
                            value: item.id,
                            label: item.name,
                            client_id: item.client_id,
                            email: item.email,
                            phone: item.phone,
                        };
                    });
                    // console.log(options, " :: options, additional client, getClientOrgList");
                    setClientsContacts(options);
                    setShowClientContacts(true);
                }
            });
    }

    const handlePrimaryClientContactEvent = async (contactsArray) => {
        if (typeof contactsArray === "object" && contactsArray !== null) {
            console.log("PRIMARY CLIENT selected: ", contactsArray);
            const clientPricingInfoObj = await getClientPricingInfo(contactsArray.client_id);
            // setData({
            //     ...data,
            //     primary_client_contact: contactsArray,
            // });
            // Load Up contacts from selected Client Org
            getClientOrgList(contactsArray.client_id);
            // Make call to get Client Contact Bridge Settings
            const contactBridgeObj = await getClientContactBridgeSettings(contactsArray.value, true);
            console.log(clientPricingInfoObj, " :: clientPricingInfoObj");
            setData({
                ...data,
                ...clientPricingInfoObj,
                ...contactBridgeObj,
                primary_client_contact: contactsArray,
            });
            if (clientPricingInfoObj.custom_charge > 0 || parseFloat(clientPricingInfoObj.custom_hourly_rate) > 1) {
                console.log(
                    "COST PLUS???? ",
                    clientPricingInfoObj.custom_charge,
                    clientPricingInfoObj.custom_hourly_rate
                );
                setCostPlus(true);
            } else {
                setCostPlus(false);
            }
        }
        validateRequiredFields();
    };

    const refreshAddtlClients = () => {
        if (data.primary_client_contact.client_id) {
            // console.log(":: calling getClientOrgList");
            getClientOrgList(data.primary_client_contact.client_id);
        }
    };

    function handleClientContactEvent(contactsArray) {
        if (typeof contactsArray === "object" && contactsArray !== null) {
            console.log("OTHER CLIENT selected: ", contactsArray);
            setData({
                ...data,
                additional_client_contacts: contactsArray,
            });
            validateRequiredFields();
        }
    }

    function handleProjectManagerEvent(pmArray) {
        if (typeof pmArray === "object") {
            setData({
                ...data,
                primary_project_manager: pmArray,
            });
        }
        validateRequiredFields();
    }

    function setCommentsArray(arr) {
        if (typeof arr === "object") {
            setData({
                ...data,
                comments: arr,
            });
        }
    }
    function handleAdditionalPMEvent(pmArray) {
        if (typeof pmArray === "object") {
            setData({
                ...data,
                additional_project_managers: pmArray,
            });
            validateRequiredFields();
        }
    }

    function handleProjectTypeChange(event) {
        const { target } = event;
        const { value, name } = target;
        const projectType = parseInt(value);
        switch (projectType) {
            case 8:
                // Survey
                data.project_fee = 0;
                data.unit_multiplier = 1;
                data.transaction_fee = 0;
                data.custom_hourly_rate = 1;
                data.custom_charge = 0;
                displaySurveyfields(true);
                projectTypeSurveyModal(true);
                break;
            case 12:
                // Focus Group (DYAD)
                console.log("Entered Focus Group (DYAD)");
                data.expert_flat_rate = 0;
                data.transaction_fee = 0;
                displaySurveyfields(false);
                if (costplus) {
                    console.log("Cost Plus Exists!");
                    projectTypeFocusGroupModal(false);
                } else {
                    console.log("NOT Cost Plus!");
                    projectTypeFocusGroupModal(true);
                }
                break;
            default:
                displaySurveyfields(false);
                data.project_fee = 0;
                data.unit_multiplier = 1;
                data.expert_flat_rate = 0;
        }
        setData({
            ...data,
            [name]: value,
        });
    }

    function handleNumberChange(event) {
        const { target } = event;
        const { value, name } = target;
        const reg = new RegExp("^\\d+$");
        // Only allow numbers or empty string
        if (reg.test(value) || value === "") {
            setData({
                ...data,
                [name]: value,
            });
            validateRequiredFields();
        }
    }

    function handleChange(event) {
        const { target } = event;
        const { value, name } = target;
        if (value.length > 0 || value !== "undefined") {
            setIsError(false);
        } else {
            setIsError(true);
        }

        if (name === "status" && value !== "6") {
            setData({
                ...data,
                [name]: value,
                onHoldReason: {},
            });
        } else if (name === "status" && value === "6") {
            setData({
                ...data,
                [name]: value,
                onHoldReason: { ...onHoldReason[0] },
            });
            setOpenModal(true);
        } else {
            setData({
                ...data,
                [name]: value,
            });
        }
        validateRequiredFields();
    }

    const handleModalOpenClose = () => {
        setOpenModal(!openModal);
    };

    function handleClose() {
        setOpen(false);
        projectTypeSurveyModal(false);
        projectTypeFocusGroupModal(false);
        setOutreachConfirmation(false);
    }

    const handleChangeBtn = () => {
        setOpenModal(true);
        validateRequiredFields();
    };

    const updateProjectData = (projData) => {
        setData(projData);
    };

    const handleAddContact = () => {
        setOpenDialog(true);
    };

    const generateContactList = () => {
        // console.log(" :: generateContactList called");
        axios
            .get("/api/contact/findAll", {
                email: "aanand@email.com",
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    const items = result.data.data;
                    const options = items.map((item) => {
                        return {
                            value: item.id,
                            label: `${item.name} at ${item.clientname}`,
                            client_id: item.client_id,
                            clientname: item.clientname,
                            email: item.email,
                            phone: item.phone,
                        };
                    });
                    // console.log(options, " :: options, primary contact list, generateContactList");
                    setClients(options);
                    if (params.id) {
                        // Grab contact ID param to pre-select as primary contact.
                        setPrimaryContactFields(options, params.id);
                    }
                }
            });
    };

    function createDateTimeStringForDTPicker(dateObj, offset) {
        return formatInTimeZone(dateObj, offset, "yyyy-MM-dd'T'HH:mm:ss");
    }

    // get rm id list
    // async function getRmIdList() {
    //     await axios
    //         .get("/api/rm/getRmIdList")
    //         .then((result) => {
    //             const rmIdList = result?.data?.data;
    //             rmIdList.push(67291); // TODO: remove me from list (possibly sort out the access lists)
    //             const isAuthed = rmIdList.includes(auth.data.id);
    //             if (isAuthed) {
    //                 setIsUserAuthed(true);
    //             }
    //             setLoadingRmIdList(false);
    //         })
    //         .catch((err) => {
    //             console.log(err, " :: err, getRmIdList");
    //         });
    // }

    async function handleSubmit(event) {
        event.preventDefault();
        const now = new Date().toISOString();
        const projectData = {
            id: data.id,
            isdeleted: data.isdeleted,
            title: data.title,
            description: data.description,
            email_copy: data.email_copy,
            project_not_received_by_email: data.project_not_received_by_email,
            primary_client_contact: data.primary_client_contact,
            client_id: data.primary_client_contact ? data.primary_client_contact.client_id : 0,
            additional_client_contacts: data.additional_client_contacts,
            expert_ids: data.expert_ids,
            primary_project_manager: data.primary_project_manager,
            additional_project_managers: data.additional_project_managers,
            status: data.status,
            onHoldReason: data.onHoldReason,
            updated_by: auth.data.id,
            type: data.type,
            priority: data.priority,
            case_code: data.case_code,
            calls_planned: data.calls_planned,
            industry: data.industry,
            conference_bridge_setting: data.conference_bridge_setting,
            twilio_bridge_setting: data.twilio_bridge_setting,
            external_bridge_numbers: data.external_bridge_numbers,
            notifications: data.notifications,
            specialty: data.specialty,
            tags: data.tags,
            comments: data.comments,
            responses: data.responses,
            scheduled_calls: data.scheduled_calls,
            completed_calls: data.completed_calls,
            going_off_hold: data.original_status == "6" && data.status != "6" ? true : false,
            minimum_bonus: data.minimum_bonus ? data.minimum_bonus : 1,
            effort_index: data.effort_index ? data.effort_index : 0,
            premium_pricing_model_id: data.premium_pricing_model.value,
            price_per_unit: parseInt(data.price_per_unit, 10),
            grace_period: parseInt(data.grace_period, 10),
            duration_model_id: data.duration_model.value,
            enable_csv_download: csvSettings.enable_csv_download,
            blind_csv: csvSettings.blind_csv,
            created_by: auth.data.id,
            updated_by: auth.data.id,
            fair_market_value: data.fair_market_value,
            budget: data.budget,
            project_fee: parseInt(data.project_fee, 10),
            unit_multiplier: parseFloat(data.unit_multiplier),
            transaction_fee: parseInt(data.transaction_fee, 10),
            expert_flat_rate: parseInt(data.expert_flat_rate, 10),
            // client_flat_charge: parseInt(data.client_flat_charge, 10),
            custom_charge: parseInt(data.custom_charge, 10),
            custom_hourly_rate: parseFloat(data.custom_hourly_rate),
            outreach_templates: JSON.stringify(data.outreach_templates),
            angles: JSON.stringify(data.angles),
            deadline: data?.deadline ? new Date(data.deadline).toISOString() : null,
            topic: data.topic,
        };

        if (
            projectData.title !== "" &&
            projectData.topic !== "" &&
            projectData.type !== "" &&
            projectData.primary_client_contact !== "" &&
            projectData.primary_project_manager !== "" &&
            projectData.tags.length > 0 &&
            projectData.price_per_unit > 0
        ) {
            setDisableSubmit(true);
            setOpenLoader(true);
            console.log("Project Submit Data", JSON.stringify(projectData));

            let url = "/api/project/create";
            if (props.match.params.id) {
                url = "/api/project/update";
                projectData.id = props.match.params.id;
                projectData.updated_on = now;
            }
            await axios
                .post(url, projectData, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                })
                .then((result) => {
                    setOpenLoader(false);
                    if (result.status === 200) {
                        console.log(`Successful POST: ${JSON.stringify(result.data)}`);
                        if (result.data.project) {
                            setData({
                                ...data,
                                id: result.data.project.id,
                            });
                        }
                        setOpen(true);
                        setDisableSubmit(false);
                    } else {
                        console.log(`Good POST, but something went wrong: ${JSON.stringify(projectData)}`);
                    }
                })
                .catch((error) => {
                    console.log(`API not done yet, 404 Error: ${JSON.stringify(projectData)}`);
                    if (error.request.response) {
                        const responseObject = JSON.parse(error.request.response);
                        console.log(responseObject);
                        setErrorMsg(responseObject.message);
                        if (!error) {
                            setTimeout(() => {
                                setError(false);
                                setErrorMsg("");
                            }, errorMessageTimeout);
                        }
                        setError(true);
                        setOpenLoader(false);
                    }
                });
        } else {
            console.log("Why won't this submit?");
            setIsError(true);

            let troubleshootingMsg = "";

            if (projectData.title == "") troubleshootingMsg += "Include title. ";
            if (projectData.type == "") troubleshootingMsg += "Select type of project. ";
            if (projectData.primary_client_contact == "") troubleshootingMsg += "Add primary client contact. ";
            if (projectData.primary_project_manager == "") troubleshootingMsg += "Add primary project manager. ";
            if (projectData.tags.length == 0) troubleshootingMsg += "Add at least one tag. ";
            if (projectData.price_per_unit === "" || projectData.price_per_unit == 0)
                troubleshootingMsg += "Price per unit is required. ";
            if (projectData.premium_pricing_model_id == 1) troubleshootingMsg += "Select a pricing model. ";
            setErrorMsg(troubleshootingMsg);
            if (!error) {
                setTimeout(() => {
                    setError(false);
                    setErrorMsg("");
                }, errorMessageTimeout);
            }
            setError(true);
        }
    }
    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const toggleDrawer = (side, open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setState({ ...state, [side]: open });
    };

    function navigateToView() {
        if (props.match.params.id) {
            history.push(`/project/view/${props.match.params.id}`);
        } else {
            // history.push(`/client/add`);
            window.location.href = "/project";
        }
    }

    function handleOutreachEvent(arr) {
        console.log("Outreach Event", arr);
        setData({
            ...data,
            outreach_templates: arr,
        });
    }

    function handleDateChange(event) {
        console.log("Date Change: ", event);
        if (event) {
            const formattedDate = createDateTimeStringForDTPicker(event, DEFAULT_TIMEZONE.offset);
            setData({ ...data, deadline: formattedDate });
        } else {
            setData({ ...data, deadline: null });
        }
    }

    function setPrimaryContactFields(options, pid) {
        const index = options.findIndex(({ value }) => value === parseInt(pid, 10));
        if (index !== -1) {
            setData({
                ...data,
                primary_client_contact: options[index],
            });
            getClientOrgList(options[index].client_id);
            // TODO: Make call to grab Client Contact Conference Bridge settings
            // Because project bridge settings will default to Client Contact settings
            // Pass Client Contact ID
            getClientContactBridgeSettings(options[index].value);
        }
    }

    function reactivateExperts(event) {
        const url = "/api/project/reactivateExperts";
        const postData = {
            projectId: props.match.params.id,
        };
        axios
            .post(url, postData, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                setOpenLoader(false);
                if (result.status === 200) {
                    console.log(`Successful POST: ${JSON.stringify(result.data)}`);
                    history.push(`/project/view/${props.match.params.id}`);
                } else {
                    console.log(`Good POST, but something went wrong: ${JSON.stringify(postData)}`);
                }
            })
            .catch(() => {
                console.log(`API not done yet, 404 Error: ${JSON.stringify(postData)}`);
            });
    }
    useEffect(() => {
        generateContactList();
    }, []);

    // useEffect(() => {
    //     getRmIdList();
    // }, []);

    const getCsvSettings = async (clientOrProjectSettings, id) => {
        console.log("getCsvSettings");

        let axiosUrl;

        if (clientOrProjectSettings === "client") {
            axiosUrl = `/api/project/getCsvSettings?settingsFrom=client&id=${id}`;
        } else if (clientOrProjectSettings === "project") {
            axiosUrl = `/api/project/getCsvSettings?settingsFrom=project&id=${id}`;
        }

        const result = await axios.get(axiosUrl);

        const { enable_csv_download, blind_csv } = result?.data?.data?.[0];

        console.log(enable_csv_download, blind_csv, " :: csvSettings");

        if (clientOrProjectSettings === "client") {
            setCsvSettings({ ...csvSettings, enable_csv_download });
        } else if (clientOrProjectSettings === "project") {
            setCsvSettings({ ...csvSettings, enable_csv_download, blind_csv });
        }
    };

    useEffect(() => {
        console.log("In useEffect");

        const projectId = props.match.params.id;

        if (projectId) {
            console.log("in projectId branch");
            getCsvSettings("project", projectId);
        } else if (clientIdForCsv) {
            console.log("in clientId branch");
            getCsvSettings("client", clientIdForCsv);
        }
    }, [clientIdForCsv]);

    useEffect(() => {
        axios
            .get("/api/person/employees", {
                email: "aanand@email.com",
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    const users = result.data.data.filter((u) => u.isactive);
                    const options = users.map((item) => {
                        return {
                            value: item.id,
                            label: item.name,
                        };
                    });
                    setProjectManagers(options);
                }
            });
    }, []);

    useEffect(() => {
        async function fetchProjectById(id) {
            const project = await axios.get(`/api/project/find?id=${id}&experts=false`);
            if (project.status === 200 && project.data && project.data.data) {
                const { projectData } = project.data.data;
                console.log("PROJECT DATA: ", projectData);
                if (projectData.length === 0) return;
                const specialty = project.data.data.specialty.map((s) => {
                    return { label: s.name, value: s.specialty_id };
                });
                const tags = project.data.data.tags.map((tag) => {
                    return { label: tag.name, value: tag.tag_id };
                });
                const { comments } = project.data.data;
                const { clientContacts } = project.data.data;
                const settings = project.data.data.confBridgeSettings;
                const primary = settings.filter((item) => item.isprimary !== false);
                const secondary = settings.filter((item) => item.isprimary !== true);
                let selectedTypeID = "1";
                let selectedTwilio = "1";
                let extNumbers = [];
                // First check to see if array is empty or not
                if (primary.length > 0) {
                    const primaryData = primary[0];
                    selectedTypeID = primaryData.type_id.toString();
                    if (primaryData.data && primaryData.data.value !== undefined) {
                        selectedTwilio = primaryData.data.value;
                    }
                    if (Array.isArray(primaryData.data) && primaryData.data.length > 0) {
                        extNumbers = primaryData.data;
                    }
                }
                // If previously saved, render saved external bridge numbers
                secondary.forEach(function (s, index) {
                    if (s.type_id === 2 && Array.isArray(s.data) && s.data.length > 0) {
                        extNumbers = s.data;
                    }
                });
                setBridgeDisplay(selectedTypeID);

                // Set Primary Client Contact
                const pcc = clientContacts.filter((item) => item.isprimary === true);
                const acc = clientContacts.filter((item) => item.isprimary !== true);
                const options = pcc.map((item) => {
                    return {
                        value: item.client_contact_id,
                        label: `${item.firstname} ${item.lastname}`,
                        client_id: projectData[0].client_id,
                        clientname: projectData[0].client_name,
                    };
                });
                // Set Additional Client Contacts
                const additional_contacts = acc.map((item) => {
                    return {
                        value: item.client_contact_id,
                        label: `${item.firstname} ${item.lastname}`,
                        client_id: projectData[0].client_id,
                        clientname: projectData[0].client_name,
                    };
                });
                const index = options.findIndex(({ value }) => value === pcc[0].client_contact_id);
                let primaryClientContact = {};
                if (index !== -1) {
                    primaryClientContact = options[index];
                    getClientOrgList(projectData[0].client_id);
                    console.log("Client ID: ", options[index].client_id);
                }

                // Set Primary Project Manager
                const ppm = {
                    value: projectData[0].primary_project_manager,
                    label: `${projectData[0].project_manager_fname} ${projectData[0].project_manager_lname}`,
                };

                // Set Additional Project Managers
                const addpm = project.data.data.additionalProjectManagers.map((item) => {
                    return {
                        value: item.id,
                        label: `${item.fname} ${item.lname}`,
                    };
                });

                // console.log("Radio Bridge Setting Selected: ", selectedTypeID);
                // console.log("RETURN DATA", project.data.data);
                // console.log("External Numbers ARRAY: ", extNumbers);
                // console.log("Client Contacts: ", clientContacts);

                setOrg({
                    name: {
                        value: projectData[0].client_id,
                        label: projectData[0].client_name,
                    },
                });
                setFields(true);
                // Description is saved as a string of HTML
                // We need to convert it back to a JSON object that the editor can read.
                // And then set the editorState with that new object.
                const emailCopyBlock = htmlToDraft(projectData[0].email_copy);
                if (emailCopyBlock) {
                    const contentEmailCopyState = ContentState.createFromBlockArray(emailCopyBlock.contentBlocks);
                    setEmailCopyEditorState(EditorState.createWithContent(contentEmailCopyState));
                }
                const contentBlock = htmlToDraft(projectData[0].description);
                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    setEditorState(EditorState.createWithContent(contentState));
                }
                let projectOnHoldData;
                const onHoldReasonFromDb = projectData[0].on_hold_reason;
                const projectStatusId = projectData[0].project_status_id;
                if (projectStatusId === 6) {
                    if (!onHoldReasonFromDb) {
                        projectOnHoldData = { ...onHoldReason[0] };
                    } else {
                        const oneThruFour = onHoldReason.find((reason) => {
                            return reason.label === onHoldReasonFromDb;
                        });

                        if (oneThruFour !== undefined) {
                            projectOnHoldData = { ...oneThruFour };
                        } else {
                            // onHoldReasonFromDb = onHoldReasonFromDb.slice(onHoldReasonFromDb.indexOf("-"));
                            projectOnHoldData = { value: "5", label: "Other", userReason: onHoldReasonFromDb };
                        }
                    }
                } else {
                    projectOnHoldData = {};
                }
                const premium_pricing_model =
                    premiumOptions.filter((option) => option.value === projectData[0].premium_pricing_model_id)
                        .length === 1
                        ? premiumOptions[projectData[0].premium_pricing_model_id - 1]
                        : premiumOptions[0];
                const price_per_unit =
                    projectData[0].price_per_unit && projectData[0].price_per_unit > 0
                        ? projectData[0].price_per_unit
                        : 0;
                const grace_period = projectData[0].grace_period ? parseInt(projectData[0].grace_period, 10) : 3;
                const duration_model =
                    durationOptions.filter((option) => option.value === projectData[0].duration_model_id).length === 1
                        ? durationOptions[projectData[0].duration_model_id - 1]
                        : durationOptions[0];
                const fair_market_value = projectData[0].fair_market_value;
                const budget = projectData[0].budget;
                const project_fee = projectData[0].project_fee;
                const unit_multiplier = projectData[0].unit_multiplier;
                const transaction_fee = projectData[0].transaction_fee;
                const expert_flat_rate = projectData[0].expert_flat_rate;
                // const client_flat_charge = projectData[0].client_flat_charge;
                const type = projectData[0].project_type_id;
                const custom_charge = parseInt(projectData[0].custom_charge, 10);
                const custom_hourly_rate = parseFloat(projectData[0].custom_hourly_rate);
                if (custom_charge > 0 || custom_hourly_rate > 1) {
                    // set this project as Cost Plus if both fields are filled
                    setCostPlus(true);
                }
                if (type == 8) {
                    displaySurveyfields(true);
                    console.log("This is a survey project", type);
                }
                // Enable premium field if a ppu is present
                if (price_per_unit > 0) {
                    disablePremium(false);
                }
                let outreach_templates = [];
                if (projectData[0]?.outreach_templates && projectData[0].outreach_templates.length > 0) {
                    outreach_templates = projectData[0].outreach_templates;
                    console.log("WE FOUND OUTREACH TEMPLATES: ", outreach_templates);
                } else {
                    outreach_templates = [
                        {
                            index: 0,
                            sendgrid_id: "d-82be0b8a719347f689cde93cc27df2bd",
                            type: "outreach",
                            subjectline: `FirstThought.io | Market Research Engagement Request: ${
                                projectData[0].topic ? projectData[0].topic : projectData[0].title
                            } (ID-${projectData[0].id})`,
                            description: `${stripHtml(projectData[0].description)}`,
                            question: `Would you be interested in participating in this paid, 60-minute market research engagement?`,
                            updated: "",
                        },
                        {
                            index: 1,
                            sendgrid_id: "d-f439f340838e47c5953ce9290095b4b3",
                            type: "followup",
                            subjectline: `Following Up: FirstThought.io | Market Research Engagement Request: ${
                                projectData[0].topic ? projectData[0].topic : projectData[0].title
                            } (ID-${projectData[0].id})`,
                            description: `I hope you're doing well! I am reaching out from FirstThought, LLC, a life-sciences and healthcare-focused expert network, to gauge your possible interest in participating in a market research study which I believe will be well-suited to your professional background. Our client is very eager to hear your insight and we'd be happy to pay you an hourly honorarium for your time. ${stripHtml(
                                projectData[0].description
                            )}`,
                            question:
                                "If you're interested, please let me know so we can begin connecting you with our client. Please don't hesitate to reach out to me if you have any questions.",
                            updated: "",
                        },
                        {
                            index: 2,
                            sendgrid_id: "d-e116c8a037e74b1c92afa92b28fcc597",
                            type: "followup",
                            subjectline: `RE: Following Up: FirstThought.io | Market Research Engagement Request: ${
                                projectData[0].topic ? projectData[0].topic : projectData[0].title
                            } (ID-${projectData[0].id})`,
                            description: `Hello again! I am following up once more to make sure you were able to participate in the market research engagement I wrote to you about below. I wanted also to share that my client is willing to compensate [RATE]/h for your time and expertise, though I welcome you to set your rate wherever you feel is most appropriate. Please do note that this market research will be gathering general perspectives only, and that no confidential or proprietary information of any kind may be shared. ${stripHtml(
                                projectData[0].description
                            )}`,
                            question:
                                "My client is hoping to have a call with a(n) [TYPE] expert in the next week or two, so if you would like to participate, please do reply to my note at your earliest convenience!",
                            updated: "",
                        },
                        {
                            index: 3,
                            sendgrid_id: "d-f6f2369058164ba4b06e04de1880bedf",
                            type: "followup",
                            subjectline: `Final days: FirstThought.io | Market Research Engagement Request: ${
                                projectData[0].topic ? projectData[0].topic : projectData[0].title
                            } (ID-${projectData[0].id})`,
                            description: `I wanted to send over one final note regarding this market research engagement! We are still actively seeking to engage professionals to assist our client in gaining information regarding ${stripHtml(
                                projectData[0].description
                            )}`,
                            question: "",
                            updated: "",
                        },
                    ];
                    console.log("USE DEFAULT OUTREACH TEMPLATES: ", outreach_templates);
                }
                // Check to see if all outreach templates have been updated or not...
                outreach_templates.every((template) => {
                    if (template.updated === "") {
                        setOutreachConfirmation(true);
                        return false;
                    }
                    return true;
                });
                setData({
                    ...data,
                    id: projectData[0].id,
                    isdeleted: projectData[0].isdeleted,
                    title: params.pid ? "" : projectData[0].title,
                    description: projectData[0].description,
                    email_copy: projectData[0].email_copy,
                    project_not_received_by_email: projectData[0].project_not_received_by_email,
                    client_id: projectData[0].client_id,
                    primary_client_contact: primaryClientContact,
                    additional_client_contacts: additional_contacts,
                    expert_ids: [],
                    primary_project_manager: ppm,
                    additional_project_managers: addpm,
                    status: projectData[0].project_status_id,
                    original_status: projectData[0].project_status_id,
                    onHoldReason: projectOnHoldData,
                    type,
                    priority: projectData[0].project_priority_id,
                    case_code: projectData[0].case_code,
                    calls_planned: projectData[0].calls_planned,
                    price_per_unit: projectData[0].price_per_unit,
                    industry: projectData[0].industry,
                    conference_bridge_setting: selectedTypeID,
                    twilio_bridge_setting: selectedTwilio,
                    external_bridge_numbers: extNumbers,
                    notifications: [],
                    responses: [],
                    scheduled_calls: [],
                    completed_calls: [],
                    specialty,
                    tags,
                    comments: params.pid ? [] : comments,
                    minimum_bonus: projectData[0].minimum_bonus ? parseInt(projectData[0].minimum_bonus, 10) : "",
                    effort_index: projectData[0].effort_index ? parseFloat(projectData[0].effort_index, 10) : "",
                    premium_pricing_model,
                    grace_period,
                    duration_model,
                    fair_market_value,
                    budget,
                    project_fee,
                    unit_multiplier,
                    transaction_fee,
                    expert_flat_rate,
                    // client_flat_charge,
                    custom_charge,
                    custom_hourly_rate,
                    outreach_templates,
                    angles: projectData[0].angles,
                    deadline: projectData[0].deadline,
                    topic: projectData[0].topic,
                });
                setOpenLoader(false);
                setLoadingProjectData(false);
            }
        }

        if (props.match.params.id) {
            setOpenLoader(true);
            fetchProjectById(props.match.params.id);
            setDisableSubmit(false);
        }

        if (params.pid) {
            fetchProjectById(params.pid);
        }
    }, []);

    const renderOnHoldReason = () => {
        if (Object.entries(data.onHoldReason).length > 0) {
            if (data.onHoldReason.value === "5") {
                return `Reason: ${data.onHoldReason.userReason}`;
            } else {
                return `Reason: ${data.onHoldReason.label}`;
            }
        } else {
            return "";
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Drawer anchor="right" open={state.right} onClose={toggleDrawer("right", false)}>
                    <div className={classes.list} role="presentation">
                        <Comments
                            user={auth.data}
                            object_type="Project"
                            object_id={props.match.params.id}
                            formatDate={formatDateString}
                            setComments={setCommentsArray}
                            comments={data.comments}
                            height="auto"
                        />
                    </div>
                </Drawer>

                <h1>{pageTitle}</h1>
                <Backdrop
                    // transitionDuration={500}
                    className={classes.backdrop}
                    open={openLoader}
                    onClick={() => {
                        setOpenLoader(false);
                    }}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Grid container spacing={3}>
                    {[1, 2, 3, 4, 5, 13401].includes(auth.data.id) && (
                        <Grid item sm={12} xs={12}>
                            <Button className={classes.adminButton} onClick={reactivateExperts}>
                                Reactivate All Experts
                            </Button>
                        </Grid>
                    )}
                    <Grid item sm={12} xs={12}>
                        <TextField
                            autoComplete="title"
                            autoFocus
                            error={data.title === ""}
                            fullWidth
                            id="title"
                            label="Project Title"
                            margin="normal"
                            name="title"
                            onChange={handleChange}
                            required
                            value={data.title}
                        />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <TextField
                            fullWidth
                            error={data.topic === ""}
                            id="topic"
                            label="Project Topic (Required - Expert Facing)"
                            margin="normal"
                            name="topic"
                            onChange={handleChange}
                            value={data.topic}
                            required
                        />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <div className={classes.label}>Primary Client Contact *</div>
                        <Select
                            name="primary_client_contact"
                            onChange={handlePrimaryClientContactEvent}
                            options={clients}
                            value={data.primary_client_contact}
                            required
                        />
                        <Button onClick={handleAddContact}>Add Contact</Button>
                        {data.primary_client_contact && (
                            <div className={classes.clientDiv}>
                                <div key={data.primary_client_contact.value} className={classes.clientContactDiv}>
                                    <Link
                                        className={classes.link}
                                        to={`/contact/view/${data.primary_client_contact.value}`}
                                    >
                                        {data.primary_client_contact.label}
                                    </Link>
                                    <p className={classes.contactLabel}>
                                        <Link
                                            className={classes.linkSmall}
                                            to={`/client/view/${data.primary_client_contact.client_id}`}
                                        >
                                            {data.primary_client_contact.clientname}
                                        </Link>
                                    </p>
                                    <p className={classes.contactLabel}>{data.primary_client_contact.email}</p>
                                    <em>Notification Preferences: (TBBL)</em>
                                </div>
                            </div>
                        )}
                    </Grid>
                    {showCC && (
                        <Grid item sm={12} xs={12}>
                            <div className={classes.label}>Additional Client Contacts</div>
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <Select
                                    fullWidth
                                    isMulti
                                    name="additional_client_contacts"
                                    onChange={handleClientContactEvent}
                                    options={cc}
                                    value={data.additional_client_contacts}
                                    className={classes.selectBox}
                                />
                                <RefreshIcon onClick={refreshAddtlClients} className={classes.refreshIcon} />
                            </Box>
                            {/* <Button onClick={handleAddContact}>Add Contact</Button> */}
                            {data.additional_client_contacts && (
                                <div className={classes.clientDiv}>
                                    {data.additional_client_contacts.map((c) => (
                                        <div key={c.value} className={classes.clientContactDiv}>
                                            <Link className={classes.link} to={`/contact/view/${c.value}`}>
                                                {c.label}
                                            </Link>
                                            <p className={classes.contactLabel}>{c.email}</p>
                                            <em>Notification Preferences: (TBBL)</em>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </Grid>
                    )}
                    <Grid item sm={12} xs={12}>
                        <div className={classes.label}>Primary Project Manager *</div>
                        <Select
                            name="primary_project_manager"
                            onChange={handleProjectManagerEvent}
                            options={pm}
                            value={data.primary_project_manager}
                            required
                        />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <div className={classes.label}>Additional Managers</div>
                        <Select
                            isMulti
                            name="additional_project_managers"
                            onChange={handleAdditionalPMEvent}
                            options={pm}
                            value={data.additional_project_managers}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                                fullWidth
                                format="MM/dd/yyyy @ h:mma"
                                variant="inline"
                                label="Project Deadline"
                                value={data.deadline}
                                onChange={handleDateChange}
                                renderInput={(params) => (
                                    <TextField
                                        margin="dense"
                                        sx={{ width: "100%", marginBottom: "1rem", backgroundColor: "#fff" }}
                                        {...params}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <em style={{ color: "#ccc" }}>Setting a project deadline is optional.</em>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <TextField
                            error={data.type === ""}
                            autoComplete="type"
                            fullWidth
                            id="type"
                            name="type"
                            select
                            label="Type"
                            value={data.type}
                            onChange={handleProjectTypeChange}
                            margin="normal"
                            required
                        >
                            {projectType.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <Box display="flex" flexDirection="column" justifyContent="center">
                            <TextField
                                autoComplete="status"
                                fullWidth
                                id="status"
                                name="status"
                                select
                                label="Status"
                                value={data.status}
                                onChange={handleChange}
                                margin="normal"
                            >
                                {projectStatus.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                                <div className={classes.centerText}>{`${renderOnHoldReason()}`}</div>
                                {Object.entries(data.onHoldReason).length > 0 && (
                                    <Button
                                        onClick={handleChangeBtn}
                                        className={classes.changeBtn}
                                        color="primary"
                                        variant="contained"
                                        size="small"
                                    >
                                        change
                                    </Button>
                                )}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <TextField
                            autoComplete="industry"
                            fullWidth
                            id="industry"
                            label="Industry"
                            margin="normal"
                            name="industry"
                            onChange={handleChange}
                            value={data.industry}
                        />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <TextField
                            autoComplete="calls_planned"
                            fullWidth
                            id="calls_planned"
                            label="Total Calls Planned (Must be a number)"
                            margin="normal"
                            name="calls_planned"
                            onChange={handleNumberChange}
                            value={data.calls_planned}
                        />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <TextField
                            autoComplete="case_code"
                            fullWidth
                            id="case_code"
                            label="Case Code"
                            margin="normal"
                            name="case_code"
                            onChange={handleChange}
                            value={data.case_code}
                        />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <TextField
                            autoComplete="priority"
                            fullWidth
                            id="priority"
                            name="priority"
                            select
                            label="Priority"
                            value={data.priority}
                            onChange={handleChange}
                            margin="normal"
                        >
                            {projectPriority.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <TextField
                            autoComplete="grace_period"
                            fullWidth
                            id="grace_period"
                            label="Grace Period in Minutes (Integer Only)"
                            margin="normal"
                            name="grace_period"
                            onChange={handlePricePerUnitField}
                            value={data.grace_period}
                        />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <div className={classes.label}>Duration Model</div>
                        <Select
                            name="duration_model"
                            onChange={handleDurationModel}
                            options={durationOptions}
                            value={data.duration_model}
                        />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <TextField
                            error={data.price_per_unit === ""}
                            autoComplete="price_per_unit"
                            fullWidth
                            id="price_per_unit"
                            label="Price Per Unit"
                            margin="normal"
                            name="price_per_unit"
                            onChange={handlePricePerUnitField}
                            value={data.price_per_unit}
                            // required
                        />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <div className={classes.label}>Premium Pricing</div>
                        <Select
                            isDisabled={premium}
                            name="premium_pricing_model"
                            onChange={handlePremiums}
                            options={premiumOptions}
                            value={data.premium_pricing_model}
                        />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <TextField
                            fullWidth
                            id="fair_market_value"
                            label="Fair Market Value (Numbers Only)"
                            margin="normal"
                            name="fair_market_value"
                            onChange={handleChange}
                            value={data.fair_market_value}
                        />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <TextField
                            fullWidth
                            id="budget"
                            label="Budget (For Corporate Clients)"
                            margin="normal"
                            name="budget"
                            onChange={handleChange}
                            value={data.budget}
                        />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <TextField
                            fullWidth
                            id="project_fee"
                            label="Project Fee (If applicable)"
                            margin="normal"
                            name="project_fee"
                            onChange={handleChange}
                            value={data.project_fee}
                        />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <TextField
                            fullWidth
                            id="unit_multiplier"
                            label="Unit Mutliplier (Focus Groups Only)"
                            margin="normal"
                            name="unit_multiplier"
                            onChange={handleChange}
                            value={data.unit_multiplier}
                        />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <TextField
                            fullWidth
                            id="transaction_fee"
                            label="Transaction Fee (ie. Translator)"
                            margin="normal"
                            name="transaction_fee"
                            onChange={handleChange}
                            value={data.transaction_fee}
                        />
                    </Grid>
                    {survey && (
                        <>
                            <Grid item sm={12} xs={12}>
                                <h5>Surveys Only</h5>
                            </Grid>
                            <Grid item sm={4} xs={12}>
                                <TextField
                                    disabled={!survey}
                                    fullWidth
                                    id="expert_flat_rate"
                                    label="Expert Flat Rate (Survey Only)"
                                    margin="normal"
                                    name="expert_flat_rate"
                                    onChange={handleChange}
                                    value={data.expert_flat_rate}
                                />
                            </Grid>
                            {/* <Grid item sm={4} xs={12}>
                                <TextField
                                    disabled={!survey}
                                    fullWidth
                                    id="client_flat_charge"
                                    label="Client Flat Charge (Survey Only)"
                                    margin="normal"
                                    name="client_flat_charge"
                                    onChange={handleChange}
                                    value={data.client_flat_charge}
                                />
                            </Grid> */}
                            <Grid item sm={8} xs={12}>
                                &nbsp;
                            </Grid>
                        </>
                    )}
                    {costplus && (
                        <>
                            <Grid item sm={12} xs={12}>
                                <h5>Cost Plus Model</h5>
                            </Grid>
                            <Grid item sm={4} xs={12}>
                                <TextField
                                    disabled
                                    fullWidth
                                    id="custom_charge"
                                    label="Custom Charge (Cost Plus Only)"
                                    margin="normal"
                                    name="custom_charge"
                                    onChange={handleChange}
                                    value={data.custom_charge}
                                />
                            </Grid>
                            <Grid item sm={4} xs={12}>
                                <TextField
                                    disabled
                                    fullWidth
                                    id="custom_hourly_rate"
                                    label="Hourly Multiplier (Cost Plus Only)"
                                    margin="normal"
                                    name="custom_hourly_rate"
                                    onChange={handleChange}
                                    value={data.custom_hourly_rate}
                                />
                            </Grid>
                            <Grid item sm={4} xs={12}>
                                &nbsp;
                            </Grid>
                        </>
                    )}
                    <Grid item sm={12} xs={12}>
                        <label className={classes.label}>
                            Private Description/Copy of Email (Mandatory/Internal Use Only)
                        </label>
                        <br />
                        <Button className={classes.right} onClick={toggleDrawer("right", true)}>
                            <Badge badgeContent={data.comments.length} color="secondary" />
                            Add/View Comments
                        </Button>
                        <Editor
                            editorState={emailCopy}
                            spellCheck
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName={classes.editorWrapper}
                            onEditorStateChange={onEmailCopyEditorStateChange}
                        />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <label className={classes.label}>Project Description (Customer Facing)</label>
                        <Editor
                            editorState={editor}
                            spellCheck
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName={classes.editorWrapper}
                            onEditorStateChange={onEditorStateChange}
                        />
                    </Grid>
                    {/* <Grid item sm={12} xs={12}>
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={data.project_not_received_by_email}
                            name="project_not_received_by_email"
                            onChange={handleCheckbox}
                            value={data.project_not_received_by_email}
                        />
                        }
                        label="Project Not Received via Email"
                    />
                </Grid> */}
                    <Grid item sm={12} xs={12}>
                        <SpecialtyField
                            changeEvent={handleSpecialtyEvent}
                            value={data.specialty}
                            selectedSpecialties={data.specialty}
                        />
                    </Grid>
                    {/* <Grid item sm={12} xs={12}>
                        <TagField changeEvent={handleTagEvent} value={data.tags} selectedTags={data.tags} />
                    </Grid> */}
                    <Grid item sm={12} xs={12}>
                        {(!loadingProjectData || !match.params.id) && (
                            <RequiresUpdateTagManager changeEvent={handleTagEvent} incomingTags={data.tags} />
                        )}
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <h2 className={classes.section}>Impersonation Settings</h2>
                        {isEditing && (
                            <div className={classes.settingsBox}>
                                <Impersonator projectId={props.match.params.id} />
                            </div>
                        )}
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <h2 className={classes.section}>Customize Auto-Outreach:</h2>
                        <div className={classes.settingsBox}>
                            <Outreach
                                data={data.outreach_templates}
                                user={auth.data}
                                changeEvent={handleOutreachEvent}
                            />
                        </div>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <h2 className={classes.section}>Conference Bridge Settings</h2>
                    </Grid>
                    <Grid item sm={3} xs={12}>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <RadioGroup
                                name="conference_bridge_setting"
                                value={data.conference_bridge_setting}
                                onChange={handleBridgeChange}
                            >
                                <FormControlLabel value="1" control={<Radio />} label="Twilio" labelPlacement="start" />
                                <FormControlLabel
                                    value="2"
                                    control={<Radio />}
                                    label="External"
                                    labelPlacement="start"
                                />
                                <FormControlLabel value="3" control={<Radio />} label="Manual" labelPlacement="start" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item sm={9} xs={12} className={classes.leftBorder}>
                        {twilioDisplay && (
                            <Grid item sm={12} xs={12}>
                                <TwilioBridgeSettings
                                    changeEvent={handleTwilioChange}
                                    twilio_bridge_setting={data.twilio_bridge_setting}
                                />
                            </Grid>
                        )}
                        {externalDisplay && (
                            <Grid item sm={12} xs={12}>
                                <ExternalBridgeSettings
                                    changeEvent={handleExternalBridgeSettings}
                                    external_bridge_numbers={data.external_bridge_numbers}
                                />
                            </Grid>
                        )}
                        {manualDisplay && (
                            <Grid item sm={12} xs={12}>
                                <TextField
                                    fullWidth
                                    id="expert_phone"
                                    label="Manually Send Expert's Phone Number"
                                    margin="normal"
                                    disabled
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <div className={classes.settingsBoxTop}>
                            <h2 className={classes.section}>CSV Download Settings</h2>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.settingsBox}>
                            <FormGroup className={classes.checkboxes}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={handleCsvCheckbox}
                                            checked={csvSettings.enable_csv_download}
                                            name="enable_csv_download"
                                        />
                                    }
                                    label="CSV Download Enabled"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={handleCsvCheckbox}
                                            checked={csvSettings.blind_csv}
                                            name="blind_csv"
                                        />
                                    }
                                    label="Blind Experts For CSV Download"
                                />
                            </FormGroup>
                        </div>
                    </Grid>
                    <Grid item sm={9} xs={12}>
                        <Button
                            disabled={disable}
                            className={useStyles.submit}
                            color="primary"
                            fullWidth
                            onClick={handleSubmit}
                            variant="contained"
                        >
                            Save Project
                        </Button>
                    </Grid>
                    <Grid item sm={3} xs={12}>
                        <Button className={useStyles.submit} fullWidth onClick={navigateToView} variant="contained">
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
                <div>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            <Messaging user={auth.data} type="Project" />
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Project &quot;{data.title}&quot; has successfully been updated/created.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary" autoFocus>
                                Close
                            </Button>
                            <Link
                                to={
                                    props.match.params.id
                                        ? `/project/view/${props.match.params.id}`
                                        : `/project/view/${data.id}`
                                }
                                className={classes.link}
                            >
                                View Project
                            </Link>
                            {params.pid ? (
                                <span>
                                    <Link to={`/project/view/${params.pid}`} className={classes.link}>
                                        Add Previous Experts
                                    </Link>
                                    <Link to={`/expert?pid=${data.id}`} className={classes.link}>
                                        Attach New Experts
                                    </Link>
                                </span>
                            ) : (
                                <Link to={`/expert?pid=${data.id}`} className={classes.link}>
                                    Attach Experts
                                </Link>
                            )}
                        </DialogActions>
                    </Dialog>
                </div>
                {error && (
                    <FlashMessagev2
                        error={error}
                        severity={"error"}
                        message={errorMsg}
                        duration={10000}
                        vertical={"top"}
                    />
                )}
                <OnHoldModal
                    projectData={data}
                    onHoldReason={onHoldReason}
                    handleModalOpenClose={handleModalOpenClose}
                    openModal={openModal}
                    updateProjectData={updateProjectData}
                />
            </form>
            {openDialog && (
                <AddClientDialog
                    openDialog={openDialog}
                    setOpenDialog={setOpenDialog}
                    generateContactList={generateContactList}
                    clients={clients}
                    // getClientOrgList={() => getClientOrgList(id)}
                />
                // <AddClientDialog openDialog={openDialog} setOpenDialog={setOpenDialog} contactInfo={contactInfo} />
            )}
            <Dialog
                open={ptSurvey}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Please set the following required fields for Survey project type
                </DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        label="Expert Flat Rate"
                        margin="normal"
                        name="expert_flat_rate"
                        onChange={handleChange}
                        value={data.expert_flat_rate}
                    />
                    <TextField
                        fullWidth
                        label="Client Flat Rate (PPU)"
                        margin="normal"
                        name="price_per_unit"
                        onChange={handlePricePerUnitField}
                        value={data.price_per_unit}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={ptFocusGroup}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Please set the following required fields for Focus Group(DYAD) project type
                </DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        label="Project Fee (One-time)"
                        margin="normal"
                        name="project_fee"
                        onChange={handleChange}
                        value={data.project_fee}
                    />
                    <TextField
                        fullWidth
                        label="Unit Mutliplier (Per Unit)"
                        margin="normal"
                        name="unit_multiplier"
                        onChange={handleChange}
                        value={data.unit_multiplier}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={outreachConfirmation}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <strong className={classes.caution}>CAUTION:</strong> You have <strong>NOT</strong> updated all the
                    outreach templates. Please update or confirm <strong>ALL</strong> the outreach templates before you
                    save this project.
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        OK, I UNDERSTAND
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

Project.propTypes = {
    match: PropTypes.object,
};
Project.defaultProps = {
    match: null,
};

export default Project;
