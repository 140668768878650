import React, { useState, useEffect } from "react";
// MUI
// general
import { TextField, Button, Box, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
// dialog
import { Dialog, DialogActions, DialogContent } from "@mui/material";
// icons
import { Help as HelpIcon, Search as SearchIcon, Delete as DeleteIcon } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
    gavel: {
        cursor: "pointer",
        marginLeft: ".5rem",
        "&:hover": {
            color: "#FFD580",
        },
    },
    heading: {
        fontWeight: "700",
        fontSize: "1.5rem",
    },
    emphasizedTxt: {
        fontWeight: "700",
    },
}));

const TextSearchInputField = (props) => {
    // console.log(props, " :: TextSearchInputField props");
    const { search, setSearch, fetchSearchResults, setHammerTime } = props;
    const [textInput, setTextInput] = useState(search.searchFields.dsl);
    // console.log(textInput, " :: textInput");
    const [error, setError] = useState(false);
    const [instructions, setInstructions] = useState(false);
    const classes = useStyles();

    // useeffect to reset the text imput if things change
    useEffect(() => {
        setTextInput(search.searchFields.dsl);
    }, [props]);

    // DSL STUFF (experimental)
    // validation functions
    const validateInput = (input) => {
        if (input.length === 0) {
            setError(true);
            return false;
        } else {
            setError(false);
            return true;
        }
    };

    const handleValidateDSL = () => {
        parseInput(textInput);
    };

    // DSL validation regex
    const pattern = /(AND|OR|NOT)(?:\s?)\[(.*?)(?:\]|\s(AND|OR|NOT))/g;

    // consolidate so that if mulitple AND / OR / NOT's are entered
    // it condenses them into 1 single string for each `type`
    const consolidateDslArraysIntoInputs = (matchArray) => {
        console.log(matchArray);
        const andArray = [];
        const orArray = [];
        const notArray = [];

        matchArray.forEach((row) => {
            if (row[1] === "AND") {
                andArray.push(row[2]);
            } else if (row[1] === "OR") {
                orArray.push(row[2]);
            } else if (row[1] === "NOT") {
                notArray.push(row[2]);
            }
        });

        // console.log(orArray, " :: orArray");

        let newInputs = search.searchFields.string.keywords;
        const newDslInputArr = [];

        if (andArray.length > 0) {
            const andString = andArray.join(",");
            newDslInputArr.push(`AND [${andString}]`);
            newInputs = newInputs.map((input) => {
                if (input.boolType === "and") {
                    return { ...input, active: true, value: andString };
                } else {
                    return input;
                }
            });
            // console.log(newInputs, " :: inside first if");
        }

        // console.log(newInputs, " :: newInputs after andArray");

        if (orArray.length > 0) {
            const orString = orArray.join(",");
            newDslInputArr.push(`OR [${orString}]`);
            newInputs = newInputs.map((input) => {
                if (input.boolType === "or") {
                    return { ...input, active: true, value: orString };
                } else {
                    return input;
                }
            });
        }

        // console.log(newInputs, " :: newInputs after orArray");

        if (notArray.length > 0) {
            const notString = notArray.join(",");
            newDslInputArr.push(`NOT [${notString}]`);
            newInputs = newInputs.map((input) => {
                if (input.boolType === "not") {
                    return { ...input, active: true, value: notString };
                } else {
                    return input;
                }
            });
        }

        return {
            newInputs,
            newDslInputString: newDslInputArr.join(" "),
        };
    };

    // parse input into matchArray then run through consolidation function
    const parseInput = (inputString) => {
        // console.log("INPUT STRING: ", inputString);
        const matchArray = [...inputString.matchAll(pattern)];
        if (!validateInput(matchArray)) {
            setError(true);
        } else {
            const consolidatedObj = consolidateDslArraysIntoInputs(matchArray);

            // console.log(consolidatedObj, " :: consolidatedObj");

            const updatedSearchObj = {
                ...search,
                searchFields: {
                    ...search.searchFields,
                    string: {
                        ...search.searchFields.string,
                        keywords: consolidatedObj.newInputs,
                    },
                    dsl: consolidatedObj.newDslInputString,
                },
            };

            // console.log(updatedSearchObj, " :: updatedSearchObj");
            setSearch(updatedSearchObj);
            fetchSearchResults(updatedSearchObj);
        }
    };

    // HANDLE FUNCS
    const handleInput = (event) => {
        const { name, value } = event.target;
        setTextInput(value);
    };

    const handleClose = () => {
        setInstructions(false);
    };

    const handleInstructions = () => {
        setInstructions(true);
    };

    const handleClearDSL = () => {
        const updatedSearchObj = {
            ...search,
            searchFields: {
                ...search.searchFields,
                dsl: "",
            },
        };

        setSearch(updatedSearchObj);
        setHammerTime(false);
    };

    // RENDER LOGIC
    return (
        <div>
            <Box display="flex" flexDirection="row" alignItems="center">
                <TextField
                    fullWidth
                    label={props.label}
                    margin="normal"
                    name={props.name}
                    onChange={handleInput}
                    value={textInput}
                    size="small"
                    error={error}
                    helperText={error ? "Incorrect Syntax" : ""}
                    // variant="outlined"
                />
                <IconButton
                    type="submit"
                    onClick={handleValidateDSL}
                    className={classes.iconButton}
                    aria-label="search"
                    size="large"
                >
                    <SearchIcon />
                </IconButton>
                <IconButton type="delete" onClick={handleClearDSL} size="large">
                    <DeleteIcon />
                </IconButton>
                <HelpIcon className={classes.gavel} onClick={handleInstructions} />
            </Box>
            <Dialog
                open={instructions}
                onClose={handleClose}
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <span className={classes.heading}>{`Overview`}</span>
                    <br />
                    {`To use the string based search you can structure your query using this format:  `}
                    <br />
                    <span
                        className={classes.emphasizedTxt}
                    >{`OPERATOR [ thing1, thing2, thing3 ] OPTIONAL SECOND OPERATOR [ thing4, thing5 ] OPTIONAL THIRD OPERATOR [ thing6, thing7 ]`}</span>
                    <br />
                    <br />
                    <span className={classes.heading}>Example</span>
                    <br />
                    {`AND [ medical director, ceo ] NOT [ coo ] OR [ hawaii, california ]`}
                    <br />
                    <br />
                    <span className={classes.heading}>Rules</span>
                    <br />
                    {` - Can only use each operator one time`}
                    <br />
                    {` - Operators must be in all uppercase`}
                    <br />
                    {` - Spaces don't matter, they all get removed, so format it however it's easy to read for you`}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default TextSearchInputField;
