import { Box, Button, Typography } from "@mui/material";
import { Checkbox, Link, Tooltip } from "@mui/material";
/* eslint-disable guard-for-in */
import React, { useContext, useEffect, useCallback, useState } from "react";

import CachedIcon from "@mui/icons-material/Cached";
import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory";
import CheckBoxIcon from "@mui/icons-material/Done";
import Container from "@mui/material/Container";
import EventAvailableIcon from "@mui/icons-material/CalendarToday";
import FilterListIcon from "@mui/icons-material/FilterList";
import HighlightOffIcon from "@mui/icons-material/Close";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import LinearLoader from "./LinearLoader";
import MUIDataTable from "mui-datatables";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import SendIcon from "@mui/icons-material/Send";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import TagFilter from "./TagFilter";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import WarningIcon from "@mui/icons-material/ErrorOutline";
import { authContext } from "../context/auth";
import axios from "axios";
import { debounceSearchRender } from "mui-datatables";
import moment from "moment";
import { useTheme } from "@emotion/react";
import { IconButton } from "@mui/material";
import { renderOptedOutEmojis } from "../Utils";

let selectedIndexes = [];
let selectedState = [];
let shiftHeld = false;
let firstId = 0;

function isInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}
function ExpertDataTable(props) {
    // console.log(props, " :: props");
    const _state = props.state_manager;
    // props = props.props;
    const { match } = props.props;

    const theme = useTheme();
    const { auth } = useContext(authContext);
    // const [selectedIndexes, setSelectedIndexes] = useState([]);

    // Shift Select handler
    // const [shiftHeld, setShiftHeld] = useState(false);
    // const [firstId, setFirstId] = useState(null);
    // const [endId, setEndId] = useState(null);

    function downHandler({ key }) {
        if (key === "Shift") {
            shiftHeld = true;
        }
    }

    function upHandler({ key }) {
        if (key === "Shift") {
            shiftHeld = false;
        }
    }

    useEffect(() => {
        window.addEventListener("keydown", downHandler);
        window.addEventListener("keyup", upHandler);
        return () => {
            window.removeEventListener("keydown", downHandler);
            window.removeEventListener("keyup", upHandler);
        };
    }, []);

    function formatPhoneNumber(str) {
        const cleaned = `${str}`.replace(/\D/g, "");
        const formatted = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (formatted) {
            return `(${formatted[1]}) ${formatted[2]}-${formatted[3]}`;
        }
        return str;
    }

    const generateToken = useCallback(async (id, sid) => {
        const url = "/api/expert/generateTokenById";
        const postData = {
            id,
            survey_id: sid ? sid : "",
            pid: match.params.id ? match.params.id : "",
            rmid: auth.data ? auth.data.id : "",
            rmemail: auth.data ? auth.data.mail : "",
            rmfname: auth.data ? auth.data.fname : "",
            rmlname: auth.data ? auth.data.lname : "",
            rmphone: auth.data ? formatPhoneNumber(auth.data.number) : "",
        };
        // console.log("POST INDIVIDUAL TOKEN DATA: ", postData);
        return await axios
            .post(url, postData, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    // console.log("Token generated? ", result.data.data);
                    const tncLink = `https://firstthought.io/onboard/${result.data.data}`;
                    // setOnboardLink(tncLink);
                    // setToken(result.data.data);
                    return result.data.data;
                }
            })
            .catch((result) => {
                console.log("Something went wrong, API Error, 404 Error:", result);
            });
    }, []);
    const projectData = {
        id: match.params.id,
        survey_id: "",
        pid: match.params.id ? match.params.id : "",
        rmid: auth.data ? auth.data.id : "",
        rmemail: auth.data ? auth.data.mail : "",
        rmfname: auth.data ? auth.data.fname : "",
        rmlname: auth.data ? auth.data.lname : "",
        rmphone: auth.data ? formatPhoneNumber(auth.data.number) : "",
        use: "project", // Staticly assigned for this table
    };

    function matchSelectToFilter() {
        const checkboxes = Array.from(document.getElementsByClassName("expert-select-checkbox"));
        const selectedIds = selectedState.map((e) => e.person_id);

        for (const i in checkboxes) {
            const expertId = parseInt(checkboxes[i].children[0].id);
            if (selectedIds.includes(expertId)) {
                if (!checkboxes[i].children[0].checked) checkboxes[i].children[0].click();
            } else {
                if (checkboxes[i].children[0].checked) checkboxes[i].children[0].click();
            }
        }
    }

    // const modifiedExperts = props?.data?.data?.projectData?.experts?.map((expert) => {
    //     if (!!expert.consultation_rate) {
    //         return { ...expert, consultation_rate: parseInt(expert.consultation_rate) };
    //     } else {
    //         return { ...expert, consultation_rate: 0 };
    //     }
    // });

    const data = props.data;
    // data.data.projectData.experts = modifiedExperts;
    const error = props.error;

    useEffect(() => {
        // console.log("load");
        selectedIndexes = [];
        try {
            if (data && !_state.get.expertsData) _state.set.expertsData(data.data.projectData.experts);
        } catch (error) {
            _state.flash.error(
                "Project not found. If you are seeing this message in error reach out to the engineering team"
            );
        }

        document.querySelector("html").style.overflow = "hidden";
    }, [data]);

    if (error) return <div>failed to load</div>;
    if (!data || !_state.get.expertsData) {
        return <LinearLoader />;
    } else {
        const options = {
            filter: false,
            pagination: true,
            print: false,
            viewColumns: false,
            download: false,
            selectableRows: "none",
            rowsPerPage: 250,
            rowsPerPageOptions: [5, 10, 25, 50, 75, 100, 250, 500],
            rowHover: false,
            responsive: "standard",
            fixedHeader: true,
            fixedSelectColumn: true,
            // tableBodyHeight: "800px",
            // fixedHeader: true,
            searchAlwaysOpen: true,
            sortOrder: {
                name: "attached_date",
                direction: "desc",
            },
            jumpToPage: true,
            setRowProps: (row, dataIndex) => {
                return {
                    style: {
                        backgroundColor: _state.get.expertsData[dataIndex].opted_out_info.currentlyOptedOut
                            ? "#facfcf"
                            : null,
                    },
                };
            },
            customSearchRender: debounceSearchRender(500),
            onColumnSortChange: () => {
                // Clean checkboxes as columns are sorted
                matchSelectToFilter();
            },
            onSearchChange: () => {
                // Clean checkboxes as search is made
                matchSelectToFilter();
            },
            onChangePage: () => {
                // Clean checkboxes as page is changed
                matchSelectToFilter();
            },
            customToolbar: () => {
                return (
                    <Container sx={{ paddingTop: "10px" }}>
                        {/* <Typography sx={{ fontSize: "1.45rem", flexBasis: "75%", flexShrink: 0 }}>Experts</Typography> */}
                        <TagFilter state_manager={_state} selectedState={selectedState} />
                        <Tooltip arrow title={"Filters"}>
                            <IconButton
                                onClick={() => {
                                    _state.switch.filterModal();
                                }}
                            >
                                <FilterListIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow title={"Clear & Refresh"}>
                            <Button
                                onClick={async () => {
                                    _state.set.expertsData(false);
                                    _state.set.selected([]);
                                    const refreshed_experts = await axios.get(
                                        `/api/project/getProjectData?id=${projectData.id}&survey_id=${projectData.survey_id}&pid=${projectData.pid}&rmid=${projectData.rmid}&rmemail=${projectData.rmemail}&rmfname=${projectData.rmfname}&rmlname=${projectData.rmlname}&rmphone=${projectData.rmphone}&use=${projectData.use}`
                                    );
                                    // console.log(refreshed_experts);
                                    _state.set.expertsData(refreshed_experts.data.data.projectData.experts);
                                    _state.set.menuFilters({
                                        ..._state.get.statuses,
                                        selected: [],
                                    });

                                    // console.log("remove local storage called");
                                    localStorage.removeItem(_state.props.storageKey);
                                    localStorage.removeItem("filter");

                                    _state.set.boolFilter("or");
                                    _state.set.filterResults({
                                        ..._state.get.filter,
                                        id: 0,
                                        selected: [],
                                        experts: _state.get.expertsData,
                                    });
                                }}
                            >
                                <CachedIcon />
                            </Button>
                        </Tooltip>
                    </Container>
                );
            },
        };
        const columns = [
            {
                name: "select",
                label: " ",
                options: {
                    sort: false,
                    // Expert Checkboxes
                    customBodyRenderLite: (dataIndex) => {
                        if (_state.get.expertsData[dataIndex].opted_out_info.currentlyOptedOut) {
                            return <></>;
                        } else {
                            return (
                                <Checkbox
                                    // disabled={_state.get.expertsData[dataIndex].opted_out_info.currentlyOptedOut}
                                    id={String(_state.get.expertsData[dataIndex].person_id)}
                                    className="expert-select-checkbox"
                                    onChange={(event, isChecked) => {
                                        // Handle Shift Select
                                        const checkboxes = Array.from(
                                            document.getElementsByClassName("expert-select-checkbox")
                                        );

                                        // setFirstId(_state.get.expertsData[dataIndex].person_id);

                                        if (shiftHeld && firstId) {
                                            const thisId = _state.get.expertsData[dataIndex].person_id;

                                            const arrayOfBoxes = checkboxes.map((c) => parseInt(c.children[0].id));

                                            const endIndex = arrayOfBoxes.indexOf(thisId);
                                            const startIndex = arrayOfBoxes.indexOf(firstId);

                                            // console.log(startIndex, endIndex);

                                            shiftHeld = false;

                                            // let checked = false;

                                            if (startIndex < endIndex) {
                                                for (let i = startIndex; i < endIndex; i++) {
                                                    // console.log("iterator", i);
                                                    // console.log("current", checkboxes[i].children[0].checked);
                                                    // console.log("start", checkboxes[startIndex].children[0].checked);
                                                    if (checkboxes[startIndex].children[0].checked) {
                                                        if (checkboxes[i].children[0].checked)
                                                            checkboxes[i].children[0].click();
                                                    } else {
                                                        if (!checkboxes[i].children[0].checked)
                                                            checkboxes[i].children[0].click();
                                                    }
                                                }
                                            } else {
                                                for (let i = endIndex; i < startIndex; i++) {
                                                    // console.log(i);

                                                    if (checkboxes[startIndex].children[0].checked) {
                                                        if (checkboxes[i].children[0].checked)
                                                            checkboxes[i].children[0].click();
                                                    } else {
                                                        if (!checkboxes[i].children[0].checked)
                                                            checkboxes[i].children[0].click();
                                                    }
                                                }
                                            }

                                            checkboxes[startIndex].children[0].click();
                                        }

                                        // End Shift Select

                                        firstId = _state.get.expertsData[dataIndex].person_id;

                                        if (isChecked) {
                                            const currExpert = _state.get.expertsData[dataIndex];
                                            if (!selectedIndexes.includes(currExpert)) selectedIndexes.push(currExpert);
                                            // selectedIndexes = [...selectedIndexes, _state.get.expertsData[dataIndex]];
                                            // console.log(selectedIndexes, " :: selectedIndexes");
                                        } else {
                                            const removeCheck = selectedIndexes.filter(
                                                (e) => e !== _state.get.expertsData[dataIndex]
                                            );
                                            selectedIndexes = removeCheck;
                                        }

                                        let checked = false;
                                        // eslint-disable-next-line guard-for-in
                                        for (const i in checkboxes) {
                                            if (checkboxes[i].children[0].checked) checked = true;
                                        }

                                        if (!checked) {
                                            _state.set.selected([]);
                                            if (_state.get.selectedExperts.length > 0) _state.set.selectedExperts([]);

                                            const selectAllBox =
                                                document.getElementsByClassName("select_all_checkbox")[0].children[0];
                                            if (selectAllBox.checked) selectAllBox.click();
                                        } else {
                                            _state.set.selected(selectedIndexes);
                                            selectedState = selectedIndexes;
                                        }

                                        // _state.set.selected(selectedIndexes);
                                    }}
                                />
                            );
                        }
                    },
                    // Select All Checkbox
                    customHeadLabelRender: (columnMeta) => {
                        return (
                            <Checkbox
                                className={"select_all_checkbox"}
                                sx={{ position: "-webkit-sticky", position: "sticky" }}
                                onChange={(event, isChecked) => {
                                    const checkboxes = Array.from(
                                        document.getElementsByClassName("expert-select-checkbox")
                                    );
                                    for (const i in checkboxes) {
                                        if (isChecked) {
                                            if (!checkboxes[i].children[0].checked) checkboxes[i].children[0].click();
                                        } else {
                                            if (checkboxes[i].children[0].checked) checkboxes[i].children[0].click();
                                        }
                                    }
                                }}
                            />
                        );
                    },
                },
            },
            {
                name: "segment",
                label: "Segment",
                options: {
                    filter: true,
                    sort: false,
                    customBodyRenderLite: (dataIndex) => {
                        return (
                            <Box>
                                {_state.get.expertsData[dataIndex].tags.length > 0 && (
                                    <div>
                                        {_state.get.expertsData[dataIndex].tags.map(function (t, i) {
                                            return (
                                                <Box
                                                    component={"span"}
                                                    sx={
                                                        _state.get.filter.selected.includes(t.id)
                                                            ? {
                                                                  display: "block",
                                                                  textAlign: "left",
                                                                  fontSize: ".5rem",
                                                                  fontWeight: "bold",
                                                                  textDecoration: "underline",
                                                                  color: "#162835",
                                                                  textTransform: "uppercase",
                                                                  marginTop: "2px",
                                                                  marginBottom: "2px",
                                                              }
                                                            : {
                                                                  display: "block",
                                                                  textAlign: "left",
                                                                  fontSize: ".35rem",
                                                                  color: "#999",
                                                                  margin: "0px",
                                                                  padding: "0px",
                                                              }
                                                    }
                                                    key={t.id}
                                                >
                                                    {_state.get.projectState.tags.find((tag) => tag.value === t.id) &&
                                                        t.name}{" "}
                                                </Box>
                                            );
                                        })}
                                    </div>
                                )}
                            </Box>
                        );
                    },
                },
            },
            {
                name: "consultation_rate",
                label: "Rate",
                options: {
                    filter: false,
                    sort: true,
                    customBodyRenderLite: (dataIndex) => {
                        return (
                            <Box>
                                {_state.get.expertsData[dataIndex]?.consultation_rate &&
                                _state.get.expertsData[dataIndex]?.consultation_rate > 0 &&
                                _state.get.expertsData[dataIndex]?.dnc?.length === 0 ? (
                                    <Box
                                        sx={{
                                            marginTop: "5px",
                                            fontSize: ".65rem",
                                            textTransform: "uppercase",
                                            fontWeight: "bold",
                                            color: "#325C55",
                                        }}
                                    >
                                        Rate: {_state.get.expertsData[dataIndex].consultation_rate}{" "}
                                        {_state.get.expertsData[dataIndex].consultation_rate_currency}
                                        {_state.get.expertsData[dataIndex].minimum_hourly > 0 && (
                                            <Box component={"span"}>
                                                {" | "} Min: {_state.get.expertsData[dataIndex].minimum_hourly} minutes
                                            </Box>
                                        )}
                                        {/* TODO - Extrapolate .premium_pricing_model_id */}
                                        {/* TODO - Add back in premium_pricing_model to data */}
                                        {_state.get.projectState.premium_pricing_model_id &&
                                            _state.get.expertsData[dataIndex].pricingUnit && (
                                                <strong>
                                                    {" | "}UNIT: {_state.get.expertsData[dataIndex].pricingUnit}
                                                </strong>
                                            )}
                                    </Box>
                                ) : (
                                    <Box
                                        sx={{
                                            marginTop: "5px",
                                            fontSize: ".65rem",
                                            textTransform: "uppercase",
                                            fontWeight: "bold",
                                            color: "#325C55",
                                        }}
                                    >
                                        {_state.get.expertsData[dataIndex].dnc ? (
                                            <Box
                                                component={"span"}
                                                sx={{
                                                    color: "red",
                                                    fontWeight: "bold",
                                                    fontSize: ".65rem",
                                                    marginTop: "5px",
                                                    textTransform: "uppercase",
                                                }}
                                            >
                                                Do Not Contact
                                            </Box>
                                        ) : (
                                            <Link
                                                href={`/expert/edit/${_state.get.expertsData[dataIndex].id}?pid=${_state.get.projectState.id}`}
                                                sx={{ color: theme.palette.secondary.main, textDecoration: "none" }}
                                            >
                                                Set A Rate
                                            </Link>
                                        )}
                                    </Box>
                                )}
                                {_state.get.expertsData[dataIndex].dnc ||
                                _state.get.expertsData[dataIndex].opted_out ? (
                                    <NotInterestedIcon sx={{ color: "#f6f6f6" }} />
                                ) : (
                                    <Tooltip arrow title={"Send Email"}>
                                        <Button>
                                            <SendIcon
                                                sx={{ color: theme.palette.secondary.main }}
                                                onClick={(event) =>
                                                    openTermsEmailDialog(
                                                        event,
                                                        _state.get.expertsData[dataIndex].id,
                                                        false
                                                    )
                                                }
                                            />
                                        </Button>
                                    </Tooltip>
                                )}
                            </Box>
                        );
                    },
                },
            },
            {
                name: "fullname",
                label: "Expert Name",
                options: {
                    filter: false,
                    sort: true,
                    customBodyRenderLite: (dataIndex) => {
                        return (
                            <Box sx={{ float: "left" }}>
                                {_state.get.expertsData[dataIndex].disclosures_form_review_required && (
                                    <Box
                                        sx={{
                                            backgroundColor: "red",
                                            color: "#fff",
                                            fontSize: ".65rem",
                                            fontWeight: "bold",
                                            padding: "5px",
                                            borderRadius: "3px",
                                            textAlign: "center",
                                            marginBottom: "8px",
                                        }}
                                    >
                                        <WarningIcon sx={{ fontSize: ".75rem" }} /> COMPLIANCE ALERT: PLEASE REVIEW!
                                    </Box>
                                )}
                                <Tooltip arrow title="Preview Expert">
                                    <Button
                                        key={_state.get.expertsData[dataIndex].id}
                                        sx={{
                                            color: "#523197",
                                            padding: "0",
                                            textDecoration: "none",
                                            fontSize: "1rem",
                                            fontWeight: "normal",
                                            textTransform: "none",
                                            minWidth: "auto",
                                            textAlign: "left",
                                            display: "block",
                                            lineHeight: "120%",
                                            marginBottom: "15px",
                                        }}
                                        onClick={() => {
                                            // _state.switch.expertStatusModal(event, _state.get.expertsData[dataIndex].id);
                                            _state.switch.expertPreviewModal(
                                                event,
                                                _state.get.expertsData[dataIndex].id
                                            );
                                        }}
                                    >
                                        {_state.get.expertsData[dataIndex].has_signed_terms &&
                                        _state.get.expertsData[dataIndex].disclosures ? (
                                            <VerifiedUserIcon title="Onboarded" />
                                        ) : (
                                            ""
                                        )}{" "}
                                        {_state.get.expertsData[dataIndex].fname}{" "}
                                        {_state.get.expertsData[dataIndex].lname}
                                        {_state.get.expertsData[dataIndex].suffix &&
                                            ", " + _state.get.expertsData[dataIndex].suffix}
                                    </Button>
                                </Tooltip>
                                {_state.get.expertsData[dataIndex].employment.length > 0 &&
                                _state.get.expertsData[dataIndex].employment.filter(
                                    (job) => job.present === true
                                )[0] ? (
                                    <div>
                                        {
                                            _state.get.expertsData[dataIndex].employment.filter(
                                                (job) => job.present === true
                                            )[0].position
                                        }{" "}
                                        at{" "}
                                        {
                                            _state.get.expertsData[dataIndex].employment.filter(
                                                (job) => job.present === true
                                            )[0].company
                                        }
                                    </div>
                                ) : (
                                    <div>
                                        <Box
                                            component={"span"}
                                            sx={{ color: theme.palette.secondary.main, textDecoration: "none" }}
                                        >
                                            Employment needs updating...
                                        </Box>
                                    </div>
                                )}
                                {_state.get.expertsData[dataIndex].opted_out && (
                                    <div>
                                        <Box
                                            display="flex"
                                            flexDirection="row"
                                            justifyContent="center"
                                            component={"strong"}
                                            sx={{
                                                color: "red",
                                                fontWeight: "bold",
                                                fontSize: "1.5rem",
                                                marginTop: ".75rem",
                                                textTransform: "uppercase",
                                            }}
                                        >
                                            {renderOptedOutEmojis(_state.get.expertsData[dataIndex])}
                                        </Box>
                                    </div>
                                )}
                                {(_state.get.expertsData[dataIndex].mail === "" ||
                                    _state.get.expertsData[dataIndex].mail === null) && (
                                    <Box
                                        sx={{
                                            marginTop: "5px",
                                            fontSize: ".65rem",
                                            textTransform: "uppercase",
                                            fontWeight: "bold",
                                            color: "#325C55",
                                        }}
                                    >
                                        <Link
                                            href={`/expert/edit/${_state.get.expertsData[dataIndex].id}?pid=${_state.get.projectState.id}`}
                                            sx={{ color: theme.palette.secondary.main, textDecoration: "none" }}
                                        >
                                            Add An Email
                                        </Link>
                                    </Box>
                                )}
                            </Box>
                        );
                    },
                },
            },
            {
                name: "attached_date",
                label: "Attached",
                options: {
                    filter: false,
                    sort: true,
                    customBodyRenderLite: (dataIndex) => {
                        return (
                            <Box>
                                <p>{formatDateString(_state.get.expertsData[dataIndex].attached_date)}</p>
                            </Box>
                        );
                    },
                },
            },
            {
                name: "attached_by_fname",
                label: "By",
                options: {
                    filter: false,
                    sort: true,
                    customBodyRenderLite: (dataIndex) => {
                        return (
                            <Box>
                                <p>
                                    {_state.get.expertsData[dataIndex].attached_by_fname}{" "}
                                    {_state.get.expertsData[dataIndex].attached_by_lname}
                                </p>
                            </Box>
                        );
                    },
                },
            },
            {
                name: "t&c",
                label: "T&C",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRenderLite: (dataIndex) => {
                        return (
                            <Box sx={{ float: "left", paddingLeft: 0 }}>
                                {_state.get.expertsData[dataIndex].dnc ||
                                _state.get.expertsData[dataIndex].opted_out ? (
                                    <NotInterestedIcon sx={{ color: "#f6f6f6" }} />
                                ) : (
                                    statusIconRender(
                                        _state.get.expertsData[dataIndex].terms,
                                        _state.get.expertsData[dataIndex].id,
                                        "terms"
                                    )
                                )}
                            </Box>
                        );
                    },
                },
            },
            {
                name: "accepted_sort",
                label: "Accepted",
                options: {
                    filter: false,
                    sort: true,
                    customBodyRenderLite: (dataIndex) => {
                        return (
                            <Box>
                                {_state.get.expertsData[dataIndex].dnc ||
                                _state.get.expertsData[dataIndex].opted_out ? (
                                    <NotInterestedIcon sx={{ color: "#f6f6f6" }} />
                                ) : (
                                    statusIconRender(
                                        _state.get.expertsData[dataIndex].expertResponse,
                                        _state.get.expertsData[dataIndex].id,
                                        "expertResponse"
                                    )
                                )}
                            </Box>
                        );
                    },
                },
            },
            {
                name: "screen",
                label: "Screen",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRenderLite: (dataIndex) => {
                        return (
                            <Box>
                                {_state.get.expertsData[dataIndex].dnc ||
                                _state.get.expertsData[dataIndex].opted_out ? (
                                    <NotInterestedIcon sx={{ color: "#f6f6f6" }} />
                                ) : (
                                    statusIconRender(
                                        _state.get.expertsData[dataIndex].screened,
                                        _state.get.expertsData[dataIndex].id,
                                        "screened"
                                    )
                                )}
                            </Box>
                        );
                    },
                },
            },
            {
                name: "client",
                label: "Client",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRenderLite: (dataIndex) => {
                        return (
                            <Box>
                                {_state.get.expertsData[dataIndex].dnc ||
                                _state.get.expertsData[dataIndex].opted_out ? (
                                    <NotInterestedIcon sx={{ color: "#f6f6f6" }} />
                                ) : (
                                    statusIconRender(
                                        _state.get.expertsData[dataIndex].clientResponse,
                                        _state.get.expertsData[dataIndex].id,
                                        "clientResponse"
                                    )
                                )}
                            </Box>
                        );
                    },
                },
            },
            {
                name: "compliance",
                label: "Compliance",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRenderLite: (dataIndex) => {
                        return (
                            <Box>
                                {_state.get.expertsData[dataIndex].dnc ||
                                _state.get.expertsData[dataIndex].opted_out ? (
                                    <NotInterestedIcon sx={{ color: "#f6f6f6" }} />
                                ) : (
                                    statusIconRender(
                                        _state.get.expertsData[dataIndex].complianceResponse,
                                        _state.get.expertsData[dataIndex].id,
                                        "complianceResponse"
                                    )
                                )}
                            </Box>
                        );
                    },
                },
            },
            {
                name: "scheduling",
                label: "Scheduling",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRenderLite: (dataIndex) => {
                        return (
                            <Box>
                                {_state.get.expertsData[dataIndex].dnc ||
                                _state.get.expertsData[dataIndex].opted_out ? (
                                    <NotInterestedIcon sx={{ color: "#f6f6f6" }} />
                                ) : (
                                    statusIconRender(
                                        _state.get.expertsData[dataIndex].schedulingResponse,
                                        _state.get.expertsData[dataIndex].id,
                                        "schedulingResponse"
                                    )
                                )}
                            </Box>
                        );
                    },
                },
            },
            {
                name: "payment",
                label: "Payment",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRenderLite: (dataIndex) => {
                        return (
                            <Box>
                                {_state.get.expertsData[dataIndex].dnc ||
                                _state.get.expertsData[dataIndex].opted_out ? (
                                    <NotInterestedIcon sx={{ color: "#f6f6f6" }} />
                                ) : (
                                    statusIconRender(
                                        _state.get.expertsData[dataIndex].payment,
                                        _state.get.expertsData[dataIndex].id,
                                        "payment"
                                    )
                                )}
                            </Box>
                        );
                    },
                },
            },
        ];

        // Helper Functions
        // function formatPhoneNumber(str) {
        //     const cleaned = `${str}`.replace(/\D/g, "");
        //     const formatted = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        //     if (formatted) {
        //         return `(${formatted[1]}) ${formatted[2]}-${formatted[3]}`;
        //     }
        //     return str;
        // }

        function formatDateString(str) {
            return moment(str).format("MMM. Do, YYYY, h:mma");
        }

        async function openTermsEmailDialog(evt, id, bool) {
            const expObj = _state.get.expertsData.filter((e) => e.id === id);
            if (expObj[0].mail !== "" && expObj[0].mail !== null) {
                // console.log("Let's onboard this guy!", expObj[0]);

                const expert_token = await generateToken(id, null);
                _state.set.expertEmailData({
                    ..._state.get.expertTnc,
                    id: expObj[0].id,
                    prefix: expObj[0].prefix,
                    fname: expObj[0].fname,
                    lname: expObj[0].lname,
                    suffix: expObj[0].suffix,
                    mail: expObj[0].mail ? expObj[0].mail.replace(/ /g, "") : expObj[0].mail,
                    token: expert_token,
                    tnc_sent: bool,
                });
                _state.switch.sendExpertEmailModal();
                _state.set.token(expert_token);
                const link = `https://firstthought.io/onboard/${expert_token}`;
                // console.log(link);
            } else {
                alert(
                    "Email missing for this expert. You must provide an email in order to send the Terms & Conditions."
                );
            }
        }

        function statusIconRender(status, id, type) {
            // console.log(status, id)
            try {
                // console.log(status, id, type, " :: status, id, type");
                let st;
                if (status && status.length > 0 && status[0].length > 0) {
                    if (type === "expertResponse") {
                        if (status[0].length === 1) {
                            st = status[0][0].id;
                        } else if (status[0].length === 2) {
                            if (status[0][0].id === 3) {
                                st = status[0][1].id;
                            } else {
                                st = 4;
                            }
                        } else if (status[0].length === 3) {
                            st = 4;
                        }
                    } else {
                        if (status[0].length === 1) {
                            st = status[0][0].id;
                        } else if (status[0].length === 2) {
                            st = status[0][1].id;
                        } else if (status[0].length === 3) {
                            st = status[0][2].id;
                        } else {
                            st = 0;
                        }
                    }
                } else if (status && status.length > 0 && status.length === 1) {
                    // TERMS SENT
                    st = status[0].id;
                } else if (status && status.length > 0 && status.length === 2) {
                    // TERMS SIGNED
                    st = status[1].id;
                } else if (status && status.length > 0 && status.length === 3) {
                    // TERMS ALSO SIGNED
                    st = status[2].id;
                    // } else if (status.length < 1 && type === "terms") {
                    //   // SEND ICON
                    //   st = 99;
                } else {
                    st = 0;
                }

                // TODO - Change handleClickOpen to open changeStatus modal for unique expert
                switch (st) {
                    case 21:
                    case 15:
                    case 6:
                    case 3:
                    case 9:
                        return (
                            <Tooltip arrow title={"Change Status"}>
                                <IconButton
                                    sx={{ float: "left", padding: "0px", margin: "0px" }}
                                    onClick={(event) => {
                                        _state.switch.expertStatusModal(event, id);
                                    }}
                                >
                                    <MailOutlineIcon sx={{ float: "left", color: "#444" }} />
                                </IconButton>
                            </Tooltip>
                        );
                    // break;
                    case 4:
                    case 7:
                    case 10:
                    case 16:
                    case 19:
                    case 20:
                        return (
                            <Tooltip arrow title={"Change Status"}>
                                <IconButton
                                    sx={{ float: "left", padding: "0px", margin: "0px" }}
                                    onClick={(event) => {
                                        _state.switch.expertStatusModal(event, id);
                                    }}
                                >
                                    <CheckBoxIcon sx={{ float: "left", color: "green" }} />
                                </IconButton>
                            </Tooltip>
                        );
                    // break;
                    case 5:
                    case 8:
                    case 11:
                    case 14:
                        return (
                            <Tooltip arrow title={"Change Status"}>
                                <IconButton
                                    sx={{ float: "left", padding: "0px", margin: "0px" }}
                                    onClick={(event) => {
                                        _state.switch.expertStatusModal(event, id);
                                    }}
                                >
                                    <HighlightOffIcon sx={{ float: "left", color: "red" }} />
                                </IconButton>
                            </Tooltip>
                        );
                    // break;
                    case 12:
                        return (
                            <Tooltip arrow title={"Change Status"}>
                                <IconButton
                                    sx={{ float: "left", padding: "0px", margin: "0px" }}
                                    onClick={(event) => {
                                        _state.switch.expertStatusModal(event, id);
                                    }}
                                >
                                    <WarningIcon sx={{ float: "left", color: theme.palette.secondary.main }} />
                                </IconButton>
                            </Tooltip>
                        );
                    // break;
                    case 13:
                        return (
                            <Tooltip arrow title={"Change Status"}>
                                <IconButton
                                    sx={{ float: "left", padding: "0px", margin: "0px" }}
                                    onClick={(event) => {
                                        _state.switch.expertStatusModal(event, id);
                                    }}
                                >
                                    <EventAvailableIcon sx={{ float: "left" }} />
                                </IconButton>
                            </Tooltip>
                        );
                    // break;
                    case 17:
                        return (
                            <Tooltip arrow title={"Change Status"}>
                                <IconButton
                                    sx={{ float: "left", padding: "0px", margin: "0px" }}
                                    onClick={(event) => {
                                        _state.switch.expertStatusModal(event, id);
                                    }}
                                >
                                    <WarningIcon sx={{ float: "left", color: theme.palette.secondary.main }} />
                                </IconButton>
                            </Tooltip>
                        );
                    // break;
                    case 18:
                        return (
                            <Tooltip arrow title={"Change Status"}>
                                <IconButton
                                    sx={{ float: "left", padding: "0px", margin: "0px" }}
                                    onClick={(event) => {
                                        _state.switch.expertStatusModal(event, id);
                                    }}
                                >
                                    <ChangeHistoryIcon sx={{ float: "left", color: theme.palette.secondary.main }} />
                                </IconButton>
                            </Tooltip>
                        );
                    // break;
                    case 28:
                        return (
                            <Tooltip arrow title={"Change Status"}>
                                <IconButton
                                    sx={{ float: "left", padding: "0px", margin: "0px" }}
                                    onClick={(event) => {
                                        _state.switch.expertStatusModal(event, id);
                                    }}
                                >
                                    <SpeakerNotesIcon sx={{ float: "left", color: "green" }} />
                                </IconButton>
                            </Tooltip>
                        );
                    case 31:
                        return (
                            <Tooltip arrow title={"Change Status"}>
                                <IconButton
                                    sx={{ float: "left", padding: "0px", margin: "0px" }}
                                    onClick={(event) => {
                                        _state.switch.expertStatusModal(event, id);
                                    }}
                                >
                                    <PauseCircleFilledIcon
                                        sx={{ float: "left", color: theme.palette.secondary.main, cursor: "pointer" }}
                                    />
                                </IconButton>
                            </Tooltip>
                        );
                    case 99:
                        // TODO: Delete this case, as will be inline
                        return (
                            <Tooltip arrow title={"Change Status"}>
                                <IconButton
                                    sx={{ float: "left", padding: "0px", margin: "0px" }}
                                    onClick={(event) => openTermsEmailDialog(event, id, false)}
                                >
                                    <SendIcon
                                        sx={{ float: "left", color: theme.palette.secondary.main, cursor: "pointer" }}
                                    />
                                </IconButton>
                            </Tooltip>
                        );
                    // break;
                    default:
                        return (
                            <Tooltip arrow title={"Change Status"}>
                                <IconButton
                                    sx={{ float: "left", padding: "0px", color: "#f6f6f6" }}
                                    onClick={(event) => {
                                        _state.switch.expertStatusModal(event, id);
                                    }}
                                >
                                    <IndeterminateCheckBoxIcon sx={{ float: "left" }} />
                                </IconButton>
                            </Tooltip>
                        );
                }
            } catch (error) {
                console.error(error);
            }
        }

        return (
            <div className="expert_table">
                <MUIDataTable
                    id={"muidatatable"}
                    title={"Experts"}
                    data={_state.get.expertsData || []} // TODO
                    columns={columns}
                    options={options}
                />
            </div>
        );
    }
}

export default React.memo(ExpertDataTable, (prevProps, nextProps) => {
    if (prevProps.state_manager.get.expertsData === nextProps.state_manager.get.expertsData) {
        return true; // props are equal
    }
    return false; // props are not equal -> update the component
});
