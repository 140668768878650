import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import TextField from "@mui/material/TextField";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: "75%",
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    margin: {
        margin: theme.spacing(1),
    },
}));

function PhoneField(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <TextField
                fullWidth
                id="phone"
                label="Primary Phone"
                margin="normal"
                name="phone"
                onChange={props.changeEvent}
                value={props.value}
            />
        </div>
    );
}
export default PhoneField;
