import React, { useState, useEffect, useCallback } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import AddIcon from "@mui/icons-material/Add";
import Forward from "@mui/icons-material/Forward";
import Check from "@mui/icons-material/CheckCircleOutline";
import Warning from "@mui/icons-material/SentimentVeryDissatisfied";
import Visibility from "@mui/icons-material/Visibility";
import { extractNameString, extractIndex } from "../../Utils";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    margin: {
        margin: theme.spacing(1),
    },
    questionContainer: {
        background: "linear-gradient(-60deg, #ffffff, #fafafa, #f5f5f5, #f2f2f2)",
        // border: "1px solid #f1f1f1",
        borderRadius: "4px",
        padding: "20px",
    },
    instructions: {
        marginBottom: "15px",
        color: "#325C55",
        fontSize: ".85rem",
    },
    errorBox: {
        backgroundColor: "#fff4e5",
        border: "1px solid #663c00",
        borderRadius: "4px",
        padding: "8px",
        marginBottom: "20px",
        color: "#663c00",
        fontSize: ".85rem",
    },
    errorHeading: {
        color: "#663c00",
        textAlign: "center",
        fontSize: "1.25rem",
    },
    stepHeading: {
        backgroundColor: "#eaeaea",
        borderRadius: "3px",
        color: "#444",
        padding: "5px 10px",
        fontSize: ".8rem",
    },
    answerField: {
        backgroundColor: "#ffffff",
        border: "1px solid #eaeaea",
        borderRadius: "3px",
        color: "#000",
        padding: "20px",
        fontSize: "1rem",
        marginTop: "15px",
        marginBottom: "15px",
    },
    followupInput: {
        backgroundColor: "#f1f1f1",
    },
    remove: {
        color: "#3c8864",
        textDecoration: "none",
        fontSize: ".75rem",
        fontWeight: "bold",
        background: "none",
        float: "right",
    },
    actionButtons: {
        borderTop: "4px solid #eaeaea",
        marginTop: "40px",
        paddingTop: "20px",
    },
    add: {
        marginRight: "15px",
    },
    editorWrapper: {
        border: "1px solid #EAEAEA",
        borderRadius: "2px",
        padding: "12px",
        backgroundColor: "#fff",
    },
}));

function Multiple(props) {
    const { match, history } = props;
    const classes = useStyles();
    const questionLabels = [...props.data.answers];
    const [data, setData] = useState(questionLabels);
    const [disqualifier, setDisqualifier] = useState(true);
    const [yesno, setYesNoDisable] = useState(false);
    const [errorBox, setErrorDisplay] = useState("none");
    const [errors, setErrors] = useState([]);
    const [editor, setEditorState] = useState(EditorState.createEmpty());
    const [options, setOptions] = useState({
        multiple: false, // Radio or checkboxes
        disqualifier: false, // Indicator
    });
    const followup = {
        order: 1,
        id: "",
        question_id: "",
        survey_id: props.data.id ? props.data.id : "",
        question_type_id: 1,
        question_type: "Free Text",
        text: "",
        scale: null,
        is_dirty: false,
        multiple: false,
        disqualifier: false,
        answers: [
            {
                id: "",
                label: "",
                user_answer: "",
                parentquestion: "",
                showfollowup: false,
                followupquestion: [],
                disqualifier: false,
            },
        ],
    };

    const loadQuestionIntoEditor = useCallback(() => {
        const contentBlock = htmlToDraft(props.data.text);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorContent = EditorState.createWithContent(contentState);
            setEditorState(editorContent);
        }
    }, [props.data.text]);

    useEffect(() => {
        console.log("UseEffect?");
        if (props.data.is_dirty) {
            console.log("Editable?");
            loadQuestionIntoEditor();
        } else {
            setEditorState(EditorState.createEmpty());
        }
    }, [props.data.is_dirty]);

    function onEditorStateChange(editor) {
        setEditorState(editor);
        const jsonHtml = convertToRaw(editor.getCurrentContent());
        const html = draftToHtml(jsonHtml);
        // console.log(html);
        props.changeEvent(html);
    }

    function handleMultipleCheckbox(event) {
        const { target } = event;
        const { name } = target;
        // Set locally so we can set page interactions
        setOptions({ ...options, [name]: event.target.checked });
        // Then set optional settings on SurveyCanvas...
        props.settingsEvent(event);
    }

    function handleDisqualifierCheckbox(event) {
        const { target } = event;
        const { name } = target;
        // Set locally so we can set page interactions
        setOptions({ ...options, [name]: event.target.checked });
        if (event.target.checked) {
            setDisqualifier(false);
        } else {
            setDisqualifier(true);
        }
        // Then set optional settings on SurveyCanvas...
        props.settingsEvent(event);
    }

    function handleNestedCheckbox(event) {
        const { target } = event;
        const { name } = target;
        const idx = extractIndex(name);
        const nameString = extractNameString(name);

        const answerLabels = [...data];
        answerLabels[idx][nameString] = event.target.checked;
        setData(answerLabels);
    }

    function handleFollowupQuestion(event) {
        const { target } = event;
        const { value, name } = target;
        const idx = extractIndex(name);
        const nameString = extractNameString(name);
        const answerLabels = [...data];
        answerLabels[idx][nameString][0].text = value;
        // console.log("Labels Index: ", idx);
        // console.log("Labels Array: ", answerLabels);
        // console.log("Labels: ", answerLabels[idx]);
        setData(answerLabels);
    }

    function handleChange(event) {
        const { target } = event;
        const { value, name } = target;
        const idx = extractIndex(name);
        const nameString = extractNameString(name);

        const answerLabels = [...data];
        answerLabels[idx][nameString] = value;

        setData(answerLabels);
    }

    function addCannotDiscuss() {
        const temp = [...data];
        temp.push({
            id: "",
            label: "Can not discuss",
            user_answer: "",
            parentquestion: "",
            showfollowup: false,
            followupquestion: [followup],
            disqualifier: false,
        });
        console.log("array: ", temp);
        setData(temp);
        // setYesNoDisable(true);
        // Disable all nested checkboxes disqualifier is NOT checked
        if (!options.disqualifier) {
            setDisqualifier(true);
        }
    }

    function handleAdd() {
        const temp = [...data];
        temp.push({
            id: "",
            label: "",
            user_answer: "",
            parentquestion: "",
            showfollowup: false,
            followupquestion: [followup],
            disqualifier: false,
        });
        console.log("array: ", temp);
        setData(temp);
        setYesNoDisable(true);
        // Disable all nested checkboxes disqualifier is NOT checked
        if (!options.disqualifier) {
            setDisqualifier(true);
        }
    }

    function addNoLabel(str, arr) {
        // Create duplicates so handleEvents are separate
        const temp = [...arr];
        const followupTemp = { ...followup };
        temp.push({
            id: "",
            label: str,
            user_answer: "",
            parentquestion: "",
            showfollowup: false,
            followupquestion: [followupTemp],
            disqualifier: false,
        });
        setData(temp);
    }

    function addYesLabel() {
        const temp = [...data];
        temp.push({
            id: "",
            label: "Yes",
            user_answer: "",
            parentquestion: "",
            showfollowup: false,
            followupquestion: [followup],
            disqualifier: false,
        });
        setData(temp);
        // New function for NO label due to nested event handler issues...
        addNoLabel("No", temp);
        setYesNoDisable(true);
    }

    function handleReset() {
        // Reset checkboxes
        setOptions({
            multiple: false,
            disqualifier: false,
        });
        // Reset all fields on the question
        props.resetQuestionEvent();
        setData([]);
        setErrors([]);
        setErrorDisplay("none");
        setYesNoDisable(false);
        setEditorState(EditorState.createEmpty());
    }

    function handleDelete(idx) {
        console.log("INDEX OF Label Answer TO DELETE: ", idx);
        console.log("How many labels? ", data.length);
        // Check for a minimum of two labels for multiple choice
        const filtered = data.filter((item, i) => i !== idx);
        console.log("FINAL FILTERED ARRAY: ", filtered);
        setData(filtered);
        if (filtered.length < 1) {
            setYesNoDisable(false);
        }
    }

    function handleNext() {
        const questions = props.survey;
        const errorsArray = props.validateQuestionEvent(data);
        if (errorsArray.length < 1) {
            // console.log("QUESTION: ", props.data.answers);
            setErrors([]);
            setErrorDisplay("none");
            props.data.answers = data;
            props.data.question_type_id = 2;
            props.data.question_type = "Multiple";
            // Loop through each question and reset order
            for (let i = 0; i < questions.length; i++) {
                questions[i].order = i + 1;
            }
            // Set the order of the new question and add it to the end of the array
            props.data.order = questions.length > 0 ? questions.length + 1 : 1;
            questions.push(props.data);
            console.log("ADD QUESTION: ", questions);
            // Add this question object to the Survey question array/object
            props.surveyEvent(questions);
            console.log("SURVEY OBJECT: ", props.survey);
            // Reset all fields on the question
            handleReset();
        } else {
            // Set error display
            setErrors(errorsArray);
            setErrorDisplay("block");
        }
    }
    function handleEdit() {
        // Create copy of questions array
        const questionsCopy = [...props.survey];
        console.log("Answer Labels to Edit: ", props.data.answers);
        // const errorsArray = props.validateQuestionEvent(props.data.answers);
        // TODO: Fix validation...
        const errorsArray = [];
        if (errorsArray.length < 1) {
            // FIND the object by "order" attr and REPLACE it with new updated props.data
            questionsCopy.forEach((element, index) => {
                if (element.order === props.data.order) {
                    questionsCopy[index] = props.data;
                    // Return merged array
                    questionsCopy[index].answers = props.labelMergeEvent(data);
                }
            });
            // TODO: Reset the question order...just like handleNext()
            console.log("REPLACE QUESTION WITH THIS: ", questionsCopy);
            props.surveyEvent(questionsCopy);

            // Reset all fields on the question
            handleReset();
        } else {
            // Set error display
            setErrors(errorsArray);
            setErrorDisplay("block");
        }
    }

    return (
        <div className={classes.questionContainer}>
            <Grid container spacing={1}>
                <Grid item sm={12} xs={12}>
                    <Box display={errorBox} className={classes.errorBox}>
                        <Typography className={classes.errorHeading}>
                            <Warning /> C&apos;mon {props.user.fname}, you can do better!
                        </Typography>
                        <ul>
                            {errors.map((e, idx) => {
                                const key = `key_${idx}`;
                                return <li key={key}>{e}</li>;
                            })}
                        </ul>
                    </Box>
                </Grid>
                <Grid item sm={12} xs={12}>
                    <Typography className={classes.instructions}>
                        Create a multiple choice question in 3 easy steps, then click <strong>NEXT</strong>.<br />
                        Please <strong>NOTE</strong>: By default, label options will be single choice radio buttons
                        unless you actively check the "Allow more than one answer" checkbox, which will convert the
                        options to multiple selection checkboxes.
                    </Typography>
                    <Typography className={classes.stepHeading}>
                        <strong>STEP 1</strong>: <em>Ask the question (REQUIRED)</em>
                    </Typography>
                </Grid>
                <Grid item sm={12} xs={12}>
                    {/* <TextField
                        fullWidth
                        multiline
                        rows="3"
                        label="Question Text"
                        margin="normal"
                        name="text"
                        onChange={props.changeEvent}
                        value={props.data.text}
                    /> */}
                    <Editor
                        editorState={editor}
                        spellCheck
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName={classes.editorWrapper}
                        onEditorStateChange={onEditorStateChange}
                    />
                </Grid>
                <Grid item sm={12} xs={12}>
                    <Typography className={classes.stepHeading}>
                        <strong>STEP 2</strong>: <em>Optional settings</em>
                    </Typography>
                </Grid>
                <Grid item sm={12} xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={props.data.disqualifier}
                                inputProps={{
                                    "aria-label": "Make this a disqualifying question",
                                }}
                                name="disqualifier"
                                onChange={handleDisqualifierCheckbox}
                                value={options.disqualifier}
                            />
                        }
                        label="Make this a disqualifying question"
                    />
                </Grid>
                <Grid item sm={12} xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={props.data.multiple}
                                inputProps={{
                                    "aria-label": "Allow more than one answer",
                                }}
                                name="multiple"
                                onChange={handleMultipleCheckbox}
                                value={options.multiple}
                            />
                        }
                        label="Allow more than one answer (checkbox vs radio button)"
                    />
                </Grid>
                <Grid item sm={12} xs={12}>
                    <Typography className={classes.stepHeading}>
                        <strong>STEP 3</strong>: <em>Add at least two answer options (REQUIRED)</em>
                    </Typography>
                    {props.data.answers.map((f, idx) => {
                        const key = `key_${idx}`;
                        return (
                            <Grid container spacing={1} key={key} className={classes.answerField}>
                                <Grid item sm={12} xs={12}>
                                    <Button
                                        size="small"
                                        className={classes.remove}
                                        onClick={() => props.labelDeleteEvent(f, idx)}
                                    >
                                        Remove
                                    </Button>
                                    <TextField
                                        fullWidth
                                        label="Label for Answer Field"
                                        margin="normal"
                                        name={`label[${idx}]`}
                                        onChange={props.labelChangeEvent}
                                        value={f.label}
                                        size="small"
                                    />
                                </Grid>
                                <Grid item sm={12} xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={f.disqualifier}
                                                inputProps={{
                                                    "aria-label": "Disqualify",
                                                }}
                                                name={`disqualifier[${idx}]`}
                                                onChange={props.labelCheckboxEvent} // TODO: CREATE SPECIFIC EVENT???
                                                value={
                                                    f.disqualifier && f.disqualifier !== null ? f.disqualifier : false
                                                }
                                            />
                                        }
                                        label="Disqualify"
                                    />
                                </Grid>
                                <Grid item sm={12} xs={12}>
                                    <TextField
                                        fullWidth
                                        className={classes.followupInput}
                                        label="Follow up question"
                                        margin="normal"
                                        name={`followupquestion[${idx}]`}
                                        onChange={props.followupChangeEvent}
                                        value={
                                            f.followupquestion !== null && f.followupquestion.length > 0
                                                ? f.followupquestion[0].text
                                                : ""
                                        }
                                        size="small"
                                    />
                                </Grid>
                            </Grid>
                        );
                    })}
                    {data.map((j, idx) => {
                        const key = `key_${idx}`;
                        return (
                            <Grid container spacing={1} key={key} className={classes.answerField}>
                                <Grid item sm={12} xs={12}>
                                    <Button size="small" className={classes.remove} onClick={() => handleDelete(idx)}>
                                        Remove
                                    </Button>
                                    <TextField
                                        fullWidth
                                        label="Label for Answer Field (REQUIRED)"
                                        margin="normal"
                                        name={`label[${idx}]`}
                                        onChange={handleChange}
                                        value={data[idx].label}
                                        size="small"
                                    />
                                </Grid>
                                <Grid item sm={12} xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                disabled={disqualifier}
                                                checked={data[idx].disqualifier}
                                                inputProps={{
                                                    "aria-label": "Disqualify",
                                                }}
                                                name={`disqualifier[${idx}]`}
                                                onChange={handleNestedCheckbox}
                                                value={
                                                    data[idx].disqualifier && data[idx].disqualifier !== null
                                                        ? data[idx].disqualifier
                                                        : false
                                                }
                                            />
                                        }
                                        label="Disqualify"
                                    />
                                </Grid>
                                <Grid item sm={12} xs={12}>
                                    <TextField
                                        fullWidth
                                        className={classes.followupInput}
                                        label="Follow up question (OPTIONAL)"
                                        margin="normal"
                                        name={`followupquestion[${idx}]`}
                                        onChange={handleFollowupQuestion}
                                        value={data[idx].followupquestion[0] ? data[idx].followupquestion[0].text : ""}
                                        size="small"
                                    />
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
                <Grid item sm={12} xs={12}>
                    <Button
                        size="small"
                        variant="contained"
                        color="secondary"
                        className={classes.add}
                        endIcon={<AddIcon />}
                        onClick={handleAdd}
                    >
                        Add Blank Field
                    </Button>
                    <Button
                        disabled={yesno}
                        size="small"
                        variant="contained"
                        color="secondary"
                        className={classes.add}
                        endIcon={<AddIcon />}
                        onClick={addYesLabel}
                    >
                        Add Yes / No
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        color="secondary"
                        className={classes.add}
                        endIcon={<AddIcon />}
                        onClick={addCannotDiscuss}
                    >
                        Cannot Discuss
                    </Button>
                </Grid>
                <Grid item sm={12} xs={12}>
                    <div className={classes.actionButtons}>
                        <Grid container spacing={1}>
                            <Grid item sm={4} xs={12}>
                                <Button
                                    fullWidth
                                    size="large"
                                    variant="contained"
                                    className={classes.submit}
                                    onClick={handleReset}
                                >
                                    Clear
                                </Button>
                            </Grid>
                            <Grid item sm={8} xs={12}>
                                {props.data.is_dirty ? (
                                    <Button
                                        fullWidth
                                        size="large"
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                        onClick={handleEdit}
                                    >
                                        Apply Changes <Check />
                                    </Button>
                                ) : (
                                    <Button
                                        fullWidth
                                        size="large"
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                        onClick={handleNext}
                                    >
                                        Next <Forward />
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}
export default Multiple;
