import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import Checkbox from "@mui/material/Checkbox";
import { TablePagination } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Button from "@mui/material/Button";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import AddTags from "../components/AddTags";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(2),
        overflowX: "auto",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    table: {
        minWidth: 750,
    },
    button: {
        margin: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(3),
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
}));

function Tags() {
    const classes = useStyles();
    const [openLoader, setOpenLoader] = useState(true);
    const [isError, setIsError] = useState(false);
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [openMerge, setOpenMerge] = useState(false);
    const [activeBtn, setActiveBtn] = useState(true);
    const [selected, setSelected] = useState([]);
    const [merge, setMerge] = useState([]);
    const [tag, setTag] = useState({
        id: "",
        name: "",
        group_name: "",
    });
    const [value, setValue] = useState(0);
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("name");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    function desc(a, b, oBy) {
        if (b[oBy] < a[oBy]) {
            return -1;
        }
        if (b[oBy] > a[oBy]) {
            return 1;
        }
        return 0;
    }

    function stableSort(array, cmp) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const tOrder = cmp(a[0], b[0]);
            if (order !== 0) return tOrder;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    function getSorting(o, oBy) {
        return o === "desc" ? (a, b) => desc(a, b, oBy) : (a, b) => -desc(a, b, oBy);
    }

    const headCells = [
        { id: "name", numeric: false, disablePadding: true, label: "Tag Name" },
        { id: "id", numeric: false, disablePadding: true, label: "Tag ID" },
        { id: "group", numeric: false, disablePadding: true, label: "Group Name" },
    ];

    function EnhancedTableHead(props) {
        const { onSelectAllClick, numSelected, rowCount } = props;
        const createSortHandler = (property) => (event) => {
            props.onRequestSort(event, property);
        };
        return (
            <TableHead>
                <TableRow>
                    <TableCell width="1%" className={classes.checkBoxCell}>
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{ "aria-label": "select all" }}
                        />
                    </TableCell>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? "right" : "left"}
                            padding={headCell.disablePadding ? "none" : "normal"}
                            sortDirection={props.orderBy === headCell.id ? props.order : false}
                        >
                            <TableSortLabel
                                active={props.orderBy === headCell.id}
                                direction={props.order}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {props.orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {props.order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                    <TableCell>&nbsp;</TableCell>
                </TableRow>
            </TableHead>
        );
    }
    const isSelected = (name) => selected.indexOf(name) !== -1;
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = data.map((n) => n.id);
            setSelected(newSelecteds);
            setActiveBtn(false);
            return;
        }
        setSelected([]);
        setActiveBtn(true);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        // useState Array: 'selected' to merge
        setSelected(newSelected);
        // Enable/Disable Buttons
        if (event.target.checked) {
            newSelected.length > 1 ? setActiveBtn(false) : setActiveBtn(true);
        } else {
            newSelected.length < 2 ? setActiveBtn(true) : setActiveBtn(false);
        }
    };

    const handleRadioChange = (event) => {
        const tagId = parseInt(event.target.value, 10);
        setValue(tagId);
        console.log("MASTER TAG: ", tagId);
    };

    function handleChange(event) {
        const { target } = event;
        const { value, name } = target;

        if (value.length > 0 || value !== "undefined") {
            setIsError(false);
        } else {
            setIsError(true);
        }
        setTag({
            ...tag,
            [name]: value,
        });
    }
    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    async function loadTags() {
        await axios
            .get("/api/tags", {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((response) => {
                const items = response.data.data;
                setData(items);
                setSelected([]);
                setActiveBtn(true);
                setOpenLoader(false);
            });
    }

    useEffect(() => {
        loadTags();
    }, []);

    const handleClose = () => {
        setOpen(false);
        setOpenMerge(false);
    };

    const handleSaveTag = () => {
        if (tag.name !== "") {
            axios
                .post("/api/tags/updateTag", tag, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                })
                .then((result) => {
                    if (result.status === 200) {
                        setOpen(false);
                        loadTags();
                    } else {
                        setIsError(true);
                        console.log("Error trying to save the tag.");
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    };

    const editTagEvent = (t) => {
        setOpen(true);
        setTag({
            id: t.id,
            name: t.name,
            group_name: t.group_name,
        });
    };

    function mergeTags() {
        // Grab selected tags for merge
        const toMerge = data.filter((t) => selected.includes(t.id));
        setValue(toMerge[0].id);
        setMerge(toMerge);
        setOpenMerge(true);
        console.log(toMerge);
    }

    function saveMergeTags() {
        // Remove master tagId from selected tags
        const tags = selected.filter((t) => t !== value);
        const params = { master: value, tags };
        if (window.confirm("Are You Sure You Want To Merge?")) {
            setOpenLoader(true);
            console.log(params);
            axios
                .post("/api/tags/merge", params, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                })
                .then((result) => {
                    if (result.status === 200 && result.data.data) {
                        setOpenMerge(false);
                        loadTags();
                    } else {
                        setIsError(true);
                        alert("Error trying to merge the tags.");
                    }
                })
                .catch((e) => {
                    console.log(e);
                    alert("Error trying to merge the tags.");
                });
        }
    }

    EnhancedTableHead.propTypes = {
        classes: PropTypes.object.isRequired,
        numSelected: PropTypes.number.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        onSelectAllClick: PropTypes.func.isRequired,
        order: PropTypes.oneOf(["asc", "desc"]).isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired,
    };

    return (
        <div>
            <Backdrop
                // transitionDuration={500}
                className={classes.backdrop}
                open={openLoader}
                onClick={() => {
                    setOpenLoader(false);
                }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog open={openMerge} onClose={handleClose} fullWidth aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Merge Tags</DialogTitle>
                <DialogContent>
                    <FormControl component="fieldset" className={classes.formControl}>
                        <FormLabel component="legend">Select The Master Tag:</FormLabel>
                        <RadioGroup aria-label="master" name="master" value={value} onChange={handleRadioChange}>
                            {merge.map(function (t, index) {
                                return <FormControlLabel key={t.id} value={t.id} control={<Radio />} label={t.name} />;
                            })}
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={saveMergeTags} color="primary">
                        Merge
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={open} onClose={handleClose} fullWidth aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Edit This Tag</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        onChange={handleChange}
                        error={isError}
                        margin="dense"
                        name="name"
                        label="Tag Name"
                        type="text"
                        fullWidth
                        value={tag.name}
                    />
                    <TextField
                        autoFocus
                        onChange={handleChange}
                        margin="dense"
                        name="group_name"
                        label="Group Name (Optional)"
                        type="text"
                        fullWidth
                        value={tag.group_name}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSaveTag} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid container spacing={2}>
                <h1>{data.length} Total Tags</h1>
                <Grid item xs={12} sm={12}>
                    <AddTags tagLoadEvent={loadTags} />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Button
                        disabled={activeBtn}
                        fullWidth
                        onClick={mergeTags}
                        size="small"
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                    >
                        Merge Tags
                    </Button>
                </Grid>
                <Grid item xs={12} sm={9}>
                    &nbsp;
                </Grid>
            </Grid>
            <br />
            <TablePagination
                rowsPerPageOptions={[25, 50, 75, 100, 150]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                    "aria-label": "previous page",
                }}
                nextIconButtonProps={{
                    "aria-label": "next page",
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Table className={classes.table} size="small">
                <EnhancedTableHead
                    classes={classes}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={data.length}
                />
                <TableBody>
                    {stableSort(data, getSorting(order, orderBy))
                        .map((row, index) => {
                            const isItemSelected = isSelected(row.id);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                                <TableRow
                                    hover
                                    onClick={(event) => handleClick(event, row.id)}
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.id}
                                    selected={isItemSelected}
                                >
                                    <TableCell className={classes.checkBoxCell}>
                                        <Checkbox
                                            checked={isItemSelected}
                                            inputProps={{ "aria-labelledby": labelId }}
                                        />
                                    </TableCell>
                                    <TableCell align="left">{row.name}</TableCell>
                                    <TableCell align="left">{row.id}</TableCell>
                                    <TableCell align="left">{row.group_name}</TableCell>
                                    <TableCell align="left">
                                        <Button
                                            size="small"
                                            fullWidth
                                            onClick={() => editTagEvent(row)}
                                            variant="contained"
                                        >
                                            Edit
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            );
                        })
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[25, 50, 75, 100, 150]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                    "aria-label": "previous page",
                }}
                nextIconButtonProps={{
                    "aria-label": "next page",
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                    <Button
                        disabled={activeBtn}
                        fullWidth
                        onClick={mergeTags}
                        size="small"
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                    >
                        Merge Tags
                    </Button>
                </Grid>
                <Grid item xs={12} sm={9}>
                    &nbsp;
                </Grid>
            </Grid>
        </div>
    );
}
export default Tags;
