// react imports
import React from "react";

// MUI - general
import { Button, Box } from "@mui/material";

// MUI - styles
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

// icons
import { EventAvailable as EventAvailableIcon, Warning as WarningIcon } from "@mui/icons-material";

// data
import { callTypes } from "./utils/data";

// utils
import {
    createDateTimeStringForClient,
    isScheduledDateOneDayAway,
    stringToDateOnly,
    stringToFormatDateTime,
    numberFormatToCurrencyType,
} from "./utils/utils";

// STYLES

// inline css
const expertScheduled = css`
    border-top: 4px solid #c7dbce;
    margin-bottom: 5px;
    padding-top: 8px;
    color: "#999";
    line-height: 110% !important;
    overflow: "hidden";
`;

const completed = css`
    background-color: #325c55;
    padding: 4px;
    border-radius: 3px;
    font-size: 0.65rem;
    color: #fff;
    font-weight: bold;
    text-align: center;
    margin-bottom: 0.25rem;
    width: 100%;
    text-align: center;
`;

const importantDate = css`
    color: #cc0000;
    font-weight: bold;
`;

const complianceFlag = css`
    background-color: red;
    color: #fff;
    font-size: 0.65rem;
    font-weight: bold;
    padding: 4px;
    border-radius: 3px;
    text-align: center;
    margin-bottom: 0.25rem;
`;

const iconSmall = css`
    font-size: 0.95rem;
`;

const topRight = css`
    float: right;
`;

const scheduledIcon = css`
    font-size: 0.6rem;
    color: green;
`;

const scheduled = css`
    font-size: 0.55rem;
    color: green;
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 0;
`;

const expertNameSmall = css`
    font-size: 0.85rem;
    color: #325c55;
    text-align: left;
    font-weight: normal;
    margin: 0;
    padding: 0;
`;

const expertRate = css`
    font-size: 0.7rem;
    color: #325c55;
    text-align: left;
    font-weight: bold;
    margin-bottom: 8px;
`;

const expertUnit = css`
    font-size: 0.65rem;
    color: #666;
    text-align: left;
    font-weight: bold;
    margin-bottom: 8px;
`;

const expertTitleSmall = css`
    font-size: 0.65rem;
    color: #666;
    text-align: left;
    font-weight: normal;
    margin: 0;
    line-height: 100%;
`;

const created = css`
    color: #000;
    font-size: 0.65rem;
    margin-top: 4px;
    margin-bottom: 8px;
    line-height: 150%;
`;

const updated = css`
    color: #555;
    font-size: 0.5rem;
    margin-top: 0;
    margin-bottom: 0;
`;
const durationLog = css`
    background-color: #fcfbf2;
    border: 1px solid #f7f6de;
    border-radius: 3px;
    padding: 12px;
    font-size: 0.75rem;
    color: #000;
    margin-bottom: 8px;
    line-height: 150%;
`;
const actionLinks = css`
    font-size: 0.5rem;
    color: #523197;
    text-align: left;
    text-transform: uppercase;
    font-weight: bold;
`;
const boldTxt = css`
    font-weight: 700;
`;

const CallSchedulingCard = (props) => {
    // props
    const {
        expertInfo,
        isUserAuthorized,
        handlerFuncs: [
            editScheduledCall,
            deleteScheduledCall,
            handleCallDuration,
            renderPinDialog,
            renderMeetingInviteDialog,
            handleCallEventsDialog,
            displayExpertPreview,
            requestPaymentDialog,
        ],
        projectData,
        timezones,
    } = props;

    const {
        id: ppId,
        duration,
        expert_id: expertId,
        prefix,
        fname,
        lname,
        suffix,
        eh_data: { position, company },
        biography,
        consultation_rate: hourlyRate,
        pricingUnit,
        consultation_rate_currency: currencyType,
        timezone_id: timezoneId,
        scheduled_datetime: scheduledDateTime,
        scheduled_timezone: scheduledTimezone,
        needs_recording: needsRecording,
        call_type: callType,
        created_by: createdBy,
        created_on: createdOn,
        updated_by: updatedBy,
        updated_on: updatedOn,
        reason,
        durationupdatedbyfname,
        durationupdatedbylname,
        durationupdatedon,
        client_duration: clientDuration,
        expert_duration: expertDuration,
        custom_hourly_rate: customHourlyRate,
        isCallUpcoming,
        call_complete: callCompleteFromDb,
        created_by_name: createdByName,
        updated_by_name: updatedByName,
        scheduling_credit_to: schedulingCreditTo,
        scheduling_credit_name: schedulingCreditName,
        options,
        pin_data: pinData,
        payment_details: paymentDetails,
        is_paid: isPaid,
        is_custom_charge,
    } = expertInfo;

    const isCallComplete = callCompleteFromDb || expertDuration > 0 || (expertDuration > 0 && clientDuration > 0);
    // console.log(isCallComplete, " :: isCallComplete");

    // ==============
    // UTIL FUNCTIONS
    // ==============

    // figure out which callType we're dealing with (1 for twilio, 2 & 3 for the rest)
    const isTwilio = () => {
        return callType == 1;
    };

    // ================
    // RENDER FUNCTIONS
    // ================

    // LOGICAL ELEMENTS

    // rendering call complet/incomplete banners
    const renderPastCallBanner = () => {
        if (isCallComplete) {
            return (
                <div>
                    <div css={completed}>CALL COMPLETED</div>
                    <Button
                        onClick={() => requestPaymentDialog({ ppId, paymentDetails, expert_id: expertId })}
                        sx={{ display: "block", width: "100%", fontSize: "0.65rem" }}
                        variant="contained"
                        disabled={isPaid || is_custom_charge}
                    >
                        {renderPaymentRequestBtnText()}
                    </Button>
                </div>
            );
        } else {
            return (
                <div>
                    <div css={complianceFlag}>
                        <WarningIcon css={iconSmall} /> INCOMPLETE CALL
                    </div>
                    <Button
                        onClick={() => requestPaymentDialog({ ppId, paymentDetails, expert_id: expertId })}
                        sx={{ display: "block", width: "100%", fontSize: "0.65rem" }}
                        variant="contained"
                        disabled={isPaid || is_custom_charge}
                    >
                        {renderPaymentRequestBtnText()}
                    </Button>
                </div>
            );
        }
    };

    // render admin or edit buttons
    const renderAdminOrEditBtn = () => {
        if (isCallUpcoming && !is_custom_charge) {
            return (
                <>
                    {duration > 0 ? (
                        ""
                    ) : (
                        <Button
                            size="small"
                            css={topRight}
                            onClick={() =>
                                editScheduledCall({
                                    expert_id: expertId,
                                    name: `${fname} ${lname}`,
                                    ppId,
                                    editOrDeleteSpecificData: {
                                        timezoneId,
                                        reason,
                                        scheduledDateTime,
                                        callType,
                                        schedulingCreditTo,
                                        schedulingCreditName,
                                        options,
                                    },
                                })
                            }
                        >
                            Edit
                        </Button>
                    )}
                </>
            );
        } else {
            return (
                <>
                    {isUserAuthorized && !is_custom_charge && (
                        <Button
                            disabled={expertInfo.call_complete}
                            size="small"
                            css={topRight}
                            onClick={() =>
                                editScheduledCall({
                                    expert_id: expertId,
                                    name: `${fname} ${lname}`,
                                    ppId,
                                    editOrDeleteSpecificData: {
                                        timezoneId,
                                        reason,
                                        scheduledDateTime,
                                        callType,
                                        schedulingCreditTo,
                                        schedulingCreditName,
                                        options,
                                    },
                                })
                            }
                        >
                            Admin
                        </Button>
                    )}
                </>
            );
        }
    };

    const renderDeleteBtn = () => {
        if (isCallUpcoming) {
            return (
                <Button
                    size="small"
                    css={topRight}
                    onClick={() =>
                        deleteScheduledCall({
                            expert_id: expertId,
                            name: `${fname} ${lname}`,
                            ppId,
                            editOrDeleteSpecificData: {
                                timezoneId,
                                reason,
                                scheduledDateTime,
                                callType,
                                schedulingCreditTo,
                                schedulingCreditName,
                                options,
                            },
                        })
                    }
                >
                    Delete
                </Button>
            );
        }
    };

    // STRUCTURAL ELEMENTS

    function renderPaymentRequestBtnText() {
        if (isPaid) {
            return "Payment Complete";
        } else if (is_custom_charge) {
            return "Custom Charge Requested";
        } else if (paymentDetails) {
            return "Update Payment Request";
        } else {
            return "Request Payment";
        }
    }

    const renderCallCellText = () => {
        return (
            <div key={ppId} css={expertScheduled}>
                {renderDeleteBtn()}
                {renderAdminOrEditBtn()}
                {/* render scheduled icon for calls not completed */}
                {isCallUpcoming && (
                    <>
                        <EventAvailableIcon css={scheduledIcon} />
                        <span css={scheduled}>Scheduled</span>
                        <br />
                    </>
                )}
                <Button onClick={() => displayExpertPreview(expertId)} css={expertNameSmall}>
                    {fname} {lname}
                    {suffix && ", " + suffix}
                </Button>
                <br />
                {hourlyRate && (
                    <div>
                        <span css={expertRate}>{`PROJECT RATE: ${hourlyRate} ${currencyType}`}</span>
                        {pricingUnit && <span css={expertUnit}>{` | UNITS: ${pricingUnit}`}</span>}
                    </div>
                )}
                {projectData.project_type_id === 8 && (
                    <div css={expertRate}>{`SURVEY RATE: $${projectData.price_per_unit} USD`}</div>
                )}
                <span css={expertTitleSmall}>
                    {"* "}
                    <span css={isScheduledDateOneDayAway(scheduledDateTime) ? importantDate : null}>
                        {scheduledDateTime
                            ? createDateTimeStringForClient(scheduledDateTime, timezoneId)
                            : "N/A - BAD DATA"}
                    </span>{" "}
                    ({scheduledTimezone}) via {callTypes.find((t) => t.value === callType)?.label}
                </span>
                {isCallUpcoming && needsRecording && (
                    <p css={created}>
                        <strong>* PLEASE NOTE: THIS CALL WILL BE RECORDED *</strong>
                    </p>
                )}
                {createdBy && (
                    <p css={created}>
                        {`Scheduled by: ${createdByName ? createdByName : "Unknown"} on ${stringToDateOnly(createdOn)}`}
                    </p>
                )}
                {/* here */}
                <p css={created}>
                    {schedulingCreditTo ? (
                        <>
                            {`Credit Given To: `}
                            <span css={boldTxt}>{`${schedulingCreditName}`}</span>
                        </>
                    ) : (
                        "No Credit Given For This Call"
                    )}
                </p>
                {isCallUpcoming && updatedBy && (
                    <p css={updated}>
                        {`Updated by: ${updatedByName} on ${stringToDateOnly(updatedOn)}`}
                        <br />
                        <span>{`Reason: ${reason ? reason : "No reason provided"}`}</span>
                    </p>
                )}
                {!isCallUpcoming && <>{renderPastCallBanner()}</>}
            </div>
        );
    };

    const renderCallDurationLog = () => {
        if (is_custom_charge) {
            return <div css={durationLog}>A custom charge was entered for this expert.</div>;
        } else if (durationupdatedbyfname && durationupdatedbylname && durationupdatedon) {
            return (
                <div css={durationLog}>
                    Modified by{" "}
                    <strong>
                        {durationupdatedbyfname} {durationupdatedbylname}
                    </strong>
                    <br />
                    On <span>{stringToFormatDateTime(durationupdatedon)}</span>
                    {/* On <span css={accentText}>{basicDateTimeStamp(durationupdatedon)}</span> */}
                    <br />
                    <strong>Client</strong> duration:{" "}
                    <strong>{clientDuration ? `${clientDuration} minutes` : "Incomplete"}</strong>
                    <br />
                    <strong>Expert</strong> duration:{" "}
                    <strong>{expertDuration ? `${expertDuration} minutes` : "Incomplete"}</strong>
                </div>
            );
        } else {
            return <p css={created}>No call details available...</p>;
        }
    };

    const renderBottomButtons = () => {
        // different combos

        // scheduled
        //  - twilio          -> call duration, meeting invite, see pins, event log
        //  - direct/external -> call duration, meeting invite

        // completed
        //  - twilio          -> call duration, call event log
        //  - direct/external -> call duration

        // Set Up Array
        const jsxArray = [];

        // Handle always
        jsxArray.push(
            <Button
                key="1"
                css={actionLinks}
                disabled={is_custom_charge}
                onClick={() =>
                    handleCallDuration({
                        ppId,
                        expert_id: expertId,
                        durationupdatedbyfname,
                        durationupdatedbylname,
                        durationupdatedon,
                        clientDuration,
                        expertDuration,
                    })
                }
            >
                Enter Call Duration
            </Button>
        );

        // Handle both
        if (isCallUpcoming && isTwilio())
            jsxArray.push(
                <Button key="2" css={actionLinks} onClick={() => renderPinDialog(pinData)}>
                    See Pins
                </Button>
            );
        // Handle case one
        if (isCallUpcoming)
            jsxArray.push(
                <Button
                    key="3"
                    css={actionLinks}
                    onClick={() =>
                        renderMeetingInviteDialog({
                            expert_id: expertId,
                            call_type: callType,
                            pin_data: pinData,
                            timezone_id: timezoneId,
                            scheduled_datetime: scheduledDateTime,
                            scheduled_timezone: scheduledTimezone,
                            prefix,
                            fname,
                            lname,
                            suffix,
                            position,
                            company,
                            biography,
                        })
                    }
                >
                    Meeting Invite
                </Button>
            );
        // Handle case two
        if (isTwilio())
            jsxArray.push(
                <Button key="4" css={actionLinks} onClick={() => handleCallEventsDialog({ ppId })}>
                    Event Log
                </Button>
            );

        return jsxArray;
    };

    return (
        <div>
            {renderCallCellText()}
            {renderCallDurationLog()}
            {renderBottomButtons()}
        </div>
    );
};

export default CallSchedulingCard;
