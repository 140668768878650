// react
import React, { useState, useEffect } from "react";

// Utils
import { basicDateTimeStamp } from "../../Utils";
import axios from "axios";

// MUI
// mui
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

// style imports
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

// icons
import CloseIcon from "@mui/icons-material/Close";
import StarIcon from "@mui/icons-material/StarRate";
// styles
const addButton = css`
    float: left;
    &:hover {
        color: #6619ff;
        cursor: pointer;
    }
`;

const angleStyle = {
    backgroundColor: "#e5e0f3",
    color: "#523197",
    padding: "10px 15px",
    marginBottom: "5px",
    borderRadius: "5px",
    fontSize: "1rem",
    cursor: "pointer",
};

const templateIcon = css`
    border: 4px solid #8966ad;
    margin-right: 0.75rem;
    margin-bottom: 0.75rem;
    color: #523197;
    font-weight: 800;
    background-color: #f5f5f5;
    padding: 0.5rem;
    width: 100px;
    border-radius: 5px;
    font-size: 2rem;
    letter-spacing: 1.1px;
    text-align: center;
`;

const templateIconDone = css`
    border: 4px solid #cccccc;
    margin-right: 0.75rem;
    margin-bottom: 0.75rem;
    color: #999999;
    font-weight: 800;
    background-color: #f5f5f5;
    padding: 0.5rem;
    width: 100px;
    border-radius: 5px;
    font-size: 2rem;
    letter-spacing: 1.1px;
    text-align: center;
`;

const templateTitle = css`
    font-size: 0.75rem;
    margin-bottom: 0rem;
`;
const dialogTitle = css`
    font-size: 1.5rem;
    text-transform: uppercase;
    color: #523197;
    text-align: center;
`;

const empty_defaults = [
    {
        index: 0,
        sendgrid_id: "d-82be0b8a719347f689cde93cc27df2bd",
        type: "outreach",
        subjectline: "Your Expertise regarding...",
        description: "I am looking for someone with expertise in...",
        question: `Would you be interested in participating in this paid 30-60 minute phone consultation?`,
        updated: "",
    },
    {
        index: 1,
        sendgrid_id: "d-f439f340838e47c5953ce9290095b4b3",
        type: "followup",
        subjectline: "RE: Following up on your Expertise regarding...",
        description: "I am following up on my previous email...",
        question: "",
        updated: "",
    },
    {
        index: 2,
        sendgrid_id: "d-e116c8a037e74b1c92afa92b28fcc597",
        type: "followup",
        subjectline: "RE: Following up on your Expertise regarding...",
        description: "I am following up again on my previous email...",
        question: "",
        updated: "",
    },
    {
        index: 3,
        sendgrid_id: "d-f6f2369058164ba4b06e04de1880bedf",
        type: "followup",
        subjectline: "RE: Following up on your Expertise regarding...",
        description: "I am following up again on my previous email...",
        question: "",
        updated: "",
    },
];

const confirmed = [];

function AnglesCreationTool(props) {
    // =====
    // PROPS
    // =====

    const {
        user,
        open,
        closeEvent,
        successEvent,
        tags,
        screeners,
        angles,
        templates,
        project_id,
        screener_id,
        screener_title,
    } = props;

    const anglesArray = [...angles];
    // =====
    // STATE
    // =====
    const [angle, setAngle] = useState({
        project_id: project_id ? project_id : "",
        screener_id: screener_id ? screener_id : "",
        screener_title: screener_title ? screener_title : "",
        screener_tag_id: "",
        screener_tag_name: "",
        tag_id: "",
        tag_name: "",
        outreach_templates: [],
    });
    const [tagSelectDisplay, setTagSelectDisplay] = useState("block");
    const [screenerSelectDisplay, setScreenerSelectDisplay] = useState("none");
    const [confirmTemplatesDisplay, setConfirmTemplatesDisplay] = useState("none");
    const [editableTemplateDisplay, setEditableTemplateDisplay] = useState("none");
    const existingAngleTags = angles.length > 0 ? angles.map((item) => parseInt(item.tag_id, 10)) : [];
    const existingAnglesScreeners = angles.length > 0 ? angles.map((item) => parseInt(item.screener_id, 10)) : [];
    // const existingAngleTags = [125]; TESTING PURPOSES ONLY
    const [tagSelectBtn, setTagSelectBtn] = useState(true);
    const [saveBtn, setSaveBtn] = useState(true);
    const [backBtn, setBackBtn] = useState(true);

    const [active, setActive] = useState({
        index: 0,
        sendgrid_id: "",
        type: "",
        subjectline: "",
        description: "",
        question: "",
        updated: "",
    });
    const [modified, setIcons] = useState({
        outreach_0: false,
        followup_1: false,
        followup_2: false,
        followup_3: false,
    });

    // ============
    // HANDLE FUNCS
    // ============

    const handleCloseDialog = () => {
        setTagSelectBtn(true);
        setBackBtn(true);
        setSaveBtn(true);
        setTagSelectDisplay("block");
        setScreenerSelectDisplay("none");
        setConfirmTemplatesDisplay("none");
        setEditableTemplateDisplay("none");
        setAngle({
            project_id: "",
            screener_id: "",
            screener_title: "",
            screener_tag_id: "",
            screener_tag_name: "",
            tag_id: "",
            tag_name: "",
            outreach_templates: templates.length > 0 ? templates : [],
        });
        setIcons({
            outreach_0: false,
            followup_1: false,
            followup_2: false,
            followup_3: false,
        });
        closeEvent(false);
    };

    const handleTagRadio = (event) => {
        const { target } = event;
        const { name, value } = target;
        if (event.target.checked) {
            console.log("SELECTED TAG: ", { value, name });
            setAngle({ ...angle, tag_id: parseInt(value, 10), tag_name: name });
            setTagSelectBtn(false);
        }
    };

    const handleScreenerRadio = (event) => {
        const { target } = event;
        const { name, value } = target;
        if (event.target.checked) {
            console.log("SELECTED SCREENER: ", { value, name });
            setAngle({ ...angle, screener_id: parseInt(value, 10), screener_title: name });
            setScreenerSelectDisplay("none");
            setConfirmTemplatesDisplay("block");
        }
    };

    const handleNextStep = () => {
        if (screener_id && screener_title) {
            console.log("NEXT STEP: ", { screener_id, screener_title, templates });
            setAngle({ ...angle, screener_id, screener_title });
            setScreenerSelectDisplay("none");
            setConfirmTemplatesDisplay("block");
        } else {
            setConfirmTemplatesDisplay("none");
            setScreenerSelectDisplay("block");
        }
        setTagSelectDisplay("none");
        setTagSelectBtn(true);
        setBackBtn(false);
    };

    const handleAddClick = (name, idx) => {
        // Grab the right template from the saved array of project templates...
        let template = {};
        if (templates.length > 0) {
            template = templates.find((template) => parseInt(template.index, 10) === parseInt(idx, 10));
        } else {
            template = empty_defaults.find((template) => parseInt(template.index, 10) === parseInt(idx, 10));
        }
        console.log("Set this template: ", { name, idx, template });
        setActive({
            index: template.index,
            sendgrid_id: template.sendgrid_id,
            type: template.type,
            subjectline: template.subjectline,
            description: template.description,
            question: template.question,
            updated: template.updated,
        });
        console.log("Which template is active: ", template);
        // Display TextFields...
        setEditableTemplateDisplay("block");
    };

    const handleChange = (evt) => {
        const { target } = evt;
        const { value, name } = target;
        setActive({
            ...active,
            [name]: value,
        });
    };

    const handleSaveButton = () => {
        if (confirmed.length > 3) {
            // Enable Save Button when all templates have been confirmed...
            setSaveBtn(false);
        }
    };

    const confirmTemplateEvent = () => {
        const loggedInUser = `${user.fname} ${user.lname}`;
        const dtStamp = new Date().toISOString();
        console.log("Did we get templates? ", templates);
        // Find the template in the array and update it with the active state object...
        templates.every((item) => {
            if (parseInt(item.index, 10) === parseInt(active.index, 10)) {
                // item.index = active.index;
                item.sendgrid_id = active.sendgrid_id;
                item.subjectline = active.subjectline;
                item.description = active.description;
                item.question = active.question;
                item.updated = `${basicDateTimeStamp(dtStamp)} by ${loggedInUser}`;
                console.log("We Found The Template: ", item);
                // Gray out the icon to indicate it's been edited...
                const name = `${item.type}_${item.index}`;
                console.log("Selected ICON: ", name);
                setIcons({
                    ...modified,
                    [name]: true,
                });
                confirmed.push(item);
                return false;
            }
            console.log("Each Template: ", item);
            return true;
        });
        setAngle({ ...angle, project_id: parseInt(project_id, 10), outreach_templates: templates });
        setEditableTemplateDisplay("none");
        console.log("MODIFIED/CONFIRMED: ", { templates });
        // TODO: function to enable save button...
        handleSaveButton();
    };

    const handleBackToTagSelection = () => {
        setTagSelectDisplay("block");
        setScreenerSelectDisplay("none");
        setConfirmTemplatesDisplay("none");
        setEditableTemplateDisplay("none");
        setTagSelectBtn(false);
        setBackBtn(true);
    };

    async function saveProjectAngles() {
        anglesArray.push(angle);
        console.log("Saving the anglesArray: ", anglesArray);
        await axios
            .post("/api/project/now/saveProjectAngles", { project_id, angles: JSON.stringify(anglesArray) })
            .then((result) => {
                console.log(
                    "ProjectNext.js => AnglesCreationTool.js :: saveProjectAngles :: post response :: ",
                    result
                );
                // Publish After Saving Angles...
                successEvent();
                handleCloseDialog();
            })
            .catch((e) => {
                console.log(e, " :: e in saveProjectAngles");
            });
    }

    // =========
    // PAGE LOAD
    // =========

    useEffect(() => {
        console.log("load all tags", tags);
        console.log("existing angle tags", existingAngleTags);
        console.log("load all screeners", screeners);
        console.log("load all outreach templates", templates);
        console.log("existing angle screeners", existingAnglesScreeners);
        console.log("What is the Project ID? ", project_id);
        console.log("What is the Screener ID? ", screener_id);
    }, [project_id, screener_id, tags, screeners, angles, templates]);

    // ============
    // RENDER FUNCS
    // ============

    // ================
    // COMPONENT RENDER
    // ================

    return (
        <>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="md"
            >
                <DialogTitle id="alert-dialog-title" css={dialogTitle}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleCloseDialog}
                        sx={{ float: "right" }}
                        aria-label="close"
                        size="lg"
                    >
                        <CloseIcon />
                    </IconButton>
                    {screener_title}
                </DialogTitle>
                <DialogContent>
                    <Box display={tagSelectDisplay}>
                        <div style={{ fontSize: "1.25rem", color: "#523197" }}>
                            <strong>REQUIRED:</strong> Select a project tag/angle to use.
                        </div>
                        <FormControl>
                            <RadioGroup name="tags" onChange={handleTagRadio}>
                                {tags
                                    .filter((t) => t.name.indexOf("screener") == -1)
                                    .map(function (t, index) {
                                        return (
                                            <FormControlLabel
                                                value={t.id}
                                                name={t.name}
                                                control={<Radio />}
                                                label={
                                                    existingAngleTags.includes(t.id)
                                                        ? `${t.name} (Already Used)`
                                                        : t.name
                                                }
                                                key={t.id}
                                                disabled={existingAngleTags.includes(t.id) ? true : false}
                                            />
                                        );
                                    })}
                            </RadioGroup>
                        </FormControl>
                    </Box>
                    <Box display={screenerSelectDisplay}>
                        <div style={{ fontSize: "1.25rem", color: "#523197" }}>
                            <strong>REQUIRED:</strong> Select a project screener to associate with this angle
                        </div>
                        <FormControl>
                            <RadioGroup name="screeners" onChange={handleScreenerRadio}>
                                {screeners.map(function (s, index) {
                                    return (
                                        <FormControlLabel
                                            value={s.id}
                                            name={s.title}
                                            control={<Radio />}
                                            label={
                                                existingAnglesScreeners.includes(s.id)
                                                    ? `${s.title} (Already Used)`
                                                    : s.title
                                            }
                                            key={s.id}
                                            disabled={existingAnglesScreeners.includes(s.id) ? true : false}
                                        />
                                    );
                                })}
                            </RadioGroup>
                        </FormControl>
                    </Box>
                    <Box display={confirmTemplatesDisplay}>
                        <div style={{ fontSize: "1.25rem", color: "#523197" }}>
                            <strong>REQUIRED:</strong> Confirm the automated templates to use for this screener
                        </div>
                        <br />
                        <br />
                        <div css={addButton} onClick={() => handleAddClick("outreach", 0)}>
                            <div css={modified.outreach_0 ? templateIconDone : templateIcon}>
                                <h6 css={templateTitle}>outreach</h6>
                                <div>
                                    <StarIcon />
                                </div>
                            </div>
                        </div>
                        <div css={addButton} onClick={() => handleAddClick("followup", 1)}>
                            <div css={modified.followup_1 ? templateIconDone : templateIcon}>
                                <h6 css={templateTitle}>followup</h6>
                                <div>1</div>
                            </div>
                        </div>
                        <div css={addButton} onClick={() => handleAddClick("followup", 2)}>
                            <div css={modified.followup_2 ? templateIconDone : templateIcon}>
                                <h6 css={templateTitle}>followup</h6>
                                <div>2</div>
                            </div>
                        </div>
                        <div css={addButton} onClick={() => handleAddClick("followup", 3)}>
                            <div css={modified.followup_3 ? templateIconDone : templateIcon}>
                                <h6 css={templateTitle}>followup</h6>
                                <div>3</div>
                            </div>
                        </div>
                    </Box>
                    <Box display={editableTemplateDisplay} style={{ clear: "both" }}>
                        <div style={{ fontSize: "1.25rem", color: "#523197" }}>
                            Below are your editable areas. The rest of the email such as the greeting, links and
                            signature are standard templating that cannot be modified.
                        </div>
                        <div style={{ fontSize: "1rem", color: "green" }}>
                            {active.updated
                                ? `Last updated: ${active.updated}`
                                : "PLEASE NOTE: This template has not been modified"}
                        </div>
                        <br />
                        <br />
                        <div>
                            <div style={{ marginBottom: "1.25rem" }}>
                                <TextField
                                    label="Subject Line (required)"
                                    name="subjectline"
                                    value={active.subjectline}
                                    sx={{ width: "100%" }}
                                    margin="dense"
                                    onChange={handleChange}
                                />
                            </div>
                            <div style={{ marginBottom: "1.25rem" }}>
                                <TextField
                                    multiline
                                    label="Description (required)"
                                    name="description"
                                    value={active.description}
                                    sx={{ width: "100%" }}
                                    rows={4}
                                    margin="dense"
                                    onChange={handleChange}
                                />
                            </div>
                            <div style={{ marginBottom: "1.25rem" }}>
                                <TextField
                                    label="Question (optional)"
                                    name="question"
                                    value={active.question}
                                    sx={{ width: "100%" }}
                                    margin="dense"
                                    onChange={handleChange}
                                />
                            </div>
                            <div>
                                <div style={{ fontSize: "1.15rem", color: "#666666", textAlign: "center" }}>
                                    <Button
                                        onClick={confirmTemplateEvent}
                                        size="large"
                                        variant="contained"
                                        color="primary"
                                    >
                                        Confirm This {`${active.type} ${active.index === 0 ? "Initial" : active.index}`}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Box>
                </DialogContent>
                <DialogActions
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingLeft: "1.5rem",
                        paddingRight: "1rem",
                    }}
                >
                    <div>
                        <Button onClick={handleBackToTagSelection} disabled={backBtn}>
                            Back
                        </Button>
                        <Button onClick={handleNextStep} disabled={tagSelectBtn}>
                            Next Step
                        </Button>
                        <Button onClick={saveProjectAngles} disabled={saveBtn}>
                            Save All
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default AnglesCreationTool;
