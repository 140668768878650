import React, { useState, useEffect, useCallback, useContext } from "react";
// import update from "immutability-helper";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import axios from "axios";
// import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import ArrowDown from "@mui/icons-material/GetApp";
import moment from "moment";
import SurveyCanvas from "../components/SurveyCanvas";
import PageTitle from "../components/PageTitle";
import "moment-duration-format";
import { authContext } from "../context/auth";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "left",
        color: theme.palette.text.secondary,
        marginBottom: "20px",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    appBar: {
        position: "relative",
    },
    table: {
        width: "100%",
    },
    projectBox: {
        backgroundColor: "#f5f5f5",
        borderBottom: "4px solid #eaeaea",
        borderRadius: "3px",
        padding: "20px",
        marginBottom: "30px",
    },
    heading: {
        color: "#325C55",
        fontSize: "1.5rem",
        fontWeight: "normal",
        textTransform: "uppercase",
    },
    instructions: {
        fontSize: ".75rem",
        color: "#325C55",
    },
    surveyTitle: {
        color: "#3c8864",
        fontSize: "1.25rem",
    },
    surveyType: {
        color: "#444",
        fontSize: ".75rem",
        textTransform: "uppercase",
    },
    link: {
        color: "#3c8864",
        textDecoration: "none",
    },
    projectMeta: {
        color: "#444",
        fontSize: ".65rem",
        marginBottom: "5px",
        textTransform: "uppercase",
    },
    type: {
        color: "#3c8864",
        fontSize: ".5rem",
        marginBottom: "5px",
        textTransform: "uppercase",
    },
    arrowContainer: {
        margin: "0 auto",
        textAlign: "center",
    },
    arrowDown: {
        color: "#eaeaea",
        fontSize: "4em",
    },
}));

function Survey(props) {
    const { match, history } = props;
    // const { id } = match.params;
    const classes = useStyles();
    const { setAuthData, auth } = useContext(authContext);
    const [openLoader, setOpenLoader] = useState(true);
    const [project, setProject] = useState({
        id: props.match.params.id,
        title: "",
        description: "",
        primary_project_manager: "",
        client_id: "",
        client_name: "",
        status: "",
        type: "",
        case_code: "",
        tags: [],
        surveys: [], // All questions ordered within this survey
    });
    const [data, setData] = useState({
        created_by: auth.data.id,
        updated_by: auth.data.id,
        id: "",
        type: "1",
        title: "",
        published: false,
        lock: false,
        project_id: props.match.params.id,
        questions: [], // All questions ordered within this survey
        respondents: [], // All responses to this survey
        tags: [], // All models should have tags
    });
    // TODO: LOAD SURVEY OBJECT IF NECESSARY
    // INTO setData();
    // LOAD PROJECT
    const fetchProjectById = useCallback(async (id) => {
        const pData = await axios.get(`/api/project/findSkinnyById?id=${id}`);
        if (pData.status === 200 && pData.data && pData.data.data) {
            if (pData.data.data.length === 0) return;
            const proj = pData.data.data[0];
            setProject({
                ...project,
                id: proj.id,
                title: proj.title,
                description: proj.description,
                primary_project_manager: `${proj.project_manager_fname} ${proj.project_manager_lname}`,
                client_id: proj.client_id,
                client_name: proj.client_name,
                status: proj.project_status,
                type: proj.project_type,
                case_code: proj.case_code,
            });
            setOpenLoader(false);
        }
    }, []);

    const fetchSurveyByID = useCallback(async (id) => {
        const response = await axios.get(`/api/survey/${id}`);
        if (response.status === 200 && response.data && response.data.data) {
            const survey = response.data.data;
            const sortedQuestions = survey.questions.sort((a, b) => a.order - b.order);
            console.log("FETCHED SURVEY RESPONSE: ", survey);
            console.log("FETCHED SURVEY QUESTION ORDER: ", sortedQuestions);
            fetchProjectById(survey.project_id);
            setData({
                ...data,
                created_by: parseInt(survey.created_by, 10),
                created_on: survey.created_on,
                updated_by: parseInt(survey.updated_by, 10),
                updated_on: survey.updated_on,
                id: parseInt(survey.id, 10),
                type: parseInt(survey.type, 10),
                title: survey.title,
                published: survey.published,
                project_id: survey.project_id,
                questions: sortedQuestions,
                lock: false,
                respondents: [],
                tags: [],
            });
            setOpenLoader(false);
            // Clear localStorage Drafts on page load...
            window.localStorage.removeItem("screener_drafts");
        }
    }, []);

    useEffect(() => {
        if (props.match.params.id) {
            console.log("DID WE CLEAR LOCAL STORAGE?", window.localStorage.getItem("screener_drafts"));
            fetchSurveyByID(props.match.params.id);
        }
    }, [props]);

    return (
        <div>
            <PageTitle title={data.title} />
            <Backdrop
                // transitionDuration={500}
                className={classes.backdrop}
                open={openLoader}
                onClick={() => {
                    setOpenLoader(false);
                }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container spacing={2}>
                <Grid item sm={4} xs={12}>
                    <h3 className={classes.heading}>
                        Survey Canvas: <strong>Edit</strong>
                    </h3>
                    <Typography className={classes.instructions}>
                        <strong>* Requirements include:</strong> Select the survey type, create a title and attach at
                        least one question. Also make sure the survey is directly associated with an open project.
                    </Typography>
                </Grid>
                <Grid item sm={8} xs={12}>
                    <div className={classes.projectBox}>
                        <Typography className={classes.projectMeta}>
                            {project.type} | {project.case_code} | <strong>{project.status}</strong>
                        </Typography>
                        <h1>
                            <Link to={`/project/view/${project.id}`} className={classes.link}>
                                {project.title}
                            </Link>
                        </h1>
                        <Typography className={classes.surveyType}>
                            CLIENT: <strong>{project.client_name}</strong> | PRIMARY MANAGER:{" "}
                            <strong>{project.primary_project_manager}</strong>
                        </Typography>
                    </div>
                </Grid>
                <Grid item sm={12} xs={12}>
                    <SurveyCanvas
                        project={project}
                        survey={data}
                        user={auth.data}
                        history={history}
                        params_id={props.match.params.id}
                        version="Edit"
                    />
                </Grid>
            </Grid>
        </div>
    );
}

Survey.propTypes = {
    match: PropTypes.object,
};
Survey.defaultProps = {
    match: null,
};

export default Survey;
