// Query parser, takes the query URL and returns a fully constructed object out of it

// data
import CountryListData from "../data/countries.json";
import StateListData from "../data/us_states.json";

// searchObj factory
import { getDefaultSearchObj } from "./searchFactory";

// modify data for object based inputs
const countries = CountryListData.countries.map((country) => {
    return {
        value: country.code,
        label: country.name,
    };
});

const states = StateListData.states.map((state) => {
    return {
        value: state.abbreviation,
        label: state.name,
    };
});

// npm
import queryString from "query-string";
import axios from "axios";

export const queryParser = async (queryUrlString, userId) => {
    // console.log(" :: inside query parser");
    // searchObj
    const defaultSearchObj = getDefaultSearchObj(userId);

    // console.log(queryUrlString, " :: queryUrlString");
    const parsedQueryStringIntoObj = queryString.parse(queryUrlString);
    // console.log(parsedQueryStringIntoObj, " :: queryString.parse(queryUrlString)");

    // options
    let checkbox = defaultSearchObj.options.checkbox;
    let radio = defaultSearchObj.options.radio;
    // searchFields
    // string based
    let keywords = defaultSearchObj.searchFields.string.keywords;
    let position = defaultSearchObj.searchFields.string.keywords;
    let companies = defaultSearchObj.searchFields.string.keywords;
    // object based
    let tags = defaultSearchObj.searchFields.object.tags;
    let state = defaultSearchObj.searchFields.object.state;
    let country = defaultSearchObj.searchFields.object.country;
    // dsl
    let dsl = defaultSearchObj.searchFields.dsl;
    // filters
    let expertRateSlider = defaultSearchObj.filters.expertRateSlider;
    // metaData
    let projectId = defaultSearchObj.metaData.projectId;

    // for each param, we're going to run the 'name' through a switch statement that builds that portion of the search object
    for (const [name, value] of Object.entries(parsedQueryStringIntoObj)) {
        // console.log(name, value, " :: name, value");
        switch (name) {
            // checkbox options
            case "current_employment": {
                checkbox = checkbox.map((option) => {
                    if (name === option.name) {
                        return { label: "Current Employment", name, value: !!value };
                    } else {
                        return option;
                    }
                });
                break;
            }
            case "former_employment": {
                checkbox = checkbox.map((option) => {
                    if (name === option.name) {
                        return { label: "Former Employment", name, value: !!value };
                    } else {
                        return option;
                    }
                });
                break;
            }
            case "terms_signed": {
                checkbox = checkbox.map((option) => {
                    if (name === option.name) {
                        return { label: "Terms Signed", name, value: !!value };
                    } else {
                        return option;
                    }
                });
                break;
            }
            case "disclosures": {
                checkbox = checkbox.map((option) => {
                    if (name === option.name) {
                        return { label: "Disclosures", name, value: !!value };
                    } else {
                        return option;
                    }
                });
                break;
            }
            case "remove_proj_experts": {
                checkbox = checkbox.map((option) => {
                    if (name === option.name) {
                        return { label: "Remove Added Experts", name, value: !!value };
                    } else {
                        return option;
                    }
                });
                break;
            }
            // radio options
            case "added_by": {
                radio = radio.map((option) => {
                    if (name === option.name) {
                        return {
                            name,
                            label: "Added By",
                            currentValue: value,
                            items: [
                                {
                                    label: "Only Me",
                                    value: userId,
                                },
                                {
                                    label: "Anyone",
                                    value: "0",
                                },
                            ],
                        };
                    } else {
                        return option;
                    }
                });
                break;
            }
            case "onboarded_by": {
                radio = radio.map((option) => {
                    if (name === option.name) {
                        return {
                            name,
                            label: "Onboarded By",
                            currentValue: value,
                            items: [
                                {
                                    label: "Only Me",
                                    value: userId,
                                },
                                {
                                    label: "Anyone",
                                    value: "0",
                                },
                            ],
                        };
                    } else {
                        return option;
                    }
                });
                break;
            }
            // keywords
            case "and_keywords": {
                keywords = keywords.map((input) => {
                    const { boolType } = input;
                    if (boolType === "and") {
                        return { ...input, active: true, value };
                    } else {
                        return input;
                    }
                });
                break;
            }
            case "or_keywords": {
                keywords = keywords.map((input) => {
                    const { boolType } = input;
                    if (boolType === "or") {
                        return { ...input, active: true, value };
                    } else {
                        return input;
                    }
                });
                break;
            }
            case "not_keywords": {
                keywords = keywords.map((input) => {
                    const { boolType } = input;
                    if (boolType === "not") {
                        return { ...input, active: true, value };
                    } else {
                        return input;
                    }
                });
                break;
                // position
            }
            case "and_position": {
                position = position.map((input) => {
                    const { boolType } = input;
                    if (boolType === "and") {
                        return { ...input, active: true, value };
                    } else {
                        return input;
                    }
                });
                break;
            }
            case "or_position": {
                position = position.map((input) => {
                    const { boolType } = input;
                    if (boolType === "or") {
                        return { ...input, active: true, value };
                    } else {
                        return input;
                    }
                });
                break;
            }
            case "not_position": {
                position = position.map((input) => {
                    const { boolType } = input;
                    if (boolType === "not") {
                        return { ...input, active: true, value };
                    } else {
                        return input;
                    }
                });
                break;
            }
            // companies
            case "and_companies": {
                companies = companies.map((input) => {
                    const { boolType } = input;
                    if (boolType === "and") {
                        return { ...input, active: true, value };
                    } else {
                        return input;
                    }
                });
                break;
            }
            case "or_companies": {
                companies = companies.map((input) => {
                    const { boolType } = input;
                    if (boolType === "or") {
                        return { ...input, active: true, value };
                    } else {
                        return input;
                    }
                });
                break;
            }
            case "not_companies": {
                companies = companies.map((input) => {
                    const { boolType } = input;
                    if (boolType === "not") {
                        return { ...input, active: true, value };
                    } else {
                        return input;
                    }
                });
                break;
            }
            // OBJECTS
            // tags
            case "and_tags": {
                if (value) {
                    const tagIdArray = value.split(",");

                    await axios
                        .post("/api/tags/find", { id: tagIdArray })
                        .then((result) => {
                            if (result.data.data && result.data.data.length > 0) {
                                const tagArray = result.data.data.map((tag) => {
                                    const { id, name } = tag;
                                    return { value: id, label: name };
                                });

                                tags = tags.map((input) => {
                                    const { boolType } = input;
                                    if (boolType === "and") {
                                        return { ...input, active: true, value: tagArray };
                                    } else {
                                        return input;
                                    }
                                });
                            }
                        })
                        .catch((err) => {
                            console.log(err, " :: error fetching tags");
                        });
                }

                break;
            }
            case "or_tags": {
                if (value) {
                    const tagIdArray = value.split(",");

                    await axios
                        .post("/api/tags/find", { id: tagIdArray })
                        .then((result) => {
                            if (result.data.data && result.data.data.length > 0) {
                                const tagArray = result.data.data.map((tag) => {
                                    const { id, name } = tag;
                                    return { value: id, label: name };
                                });

                                tags = tags.map((input) => {
                                    const { boolType } = input;
                                    if (boolType === "or") {
                                        return { ...input, active: true, value: tagArray };
                                    } else {
                                        return input;
                                    }
                                });
                            }
                        })
                        .catch((err) => {
                            console.log(err, " :: error fetching tags");
                        });
                }

                break;
            }
            case "not_tags": {
                if (value) {
                    const tagIdArray = value.split(",");

                    await axios
                        .post("/api/tags/find", { id: tagIdArray })
                        .then((result) => {
                            if (result.data.data && result.data.data.length > 0) {
                                const tagArray = result.data.data.map((tag) => {
                                    const { id, name } = tag;
                                    return { value: id, label: name };
                                });

                                tags = tags.map((input) => {
                                    const { boolType } = input;
                                    if (boolType === "not") {
                                        return { ...input, active: true, value: tagArray };
                                    } else {
                                        return input;
                                    }
                                });
                            }
                        })
                        .catch((err) => {
                            console.log(err, " :: error fetching tags");
                        });
                }

                break;
            }
            // states
            case "and_state": {
                if (value) {
                    const stateIdArray = value.split(",");
                    const stateArray = [];

                    stateIdArray.forEach((stateId) => {
                        const stateObject = states.find((state) => state.value === stateId);
                        stateArray.push(stateObject);
                    });

                    state = state.map((input) => {
                        const { boolType } = input;

                        if (boolType === "and") {
                            return { ...input, active: true, value: stateArray };
                        } else {
                            return input;
                        }
                    });
                }

                break;
            }
            case "or_state": {
                if (value) {
                    const stateIdArray = value.split(",");
                    const stateArray = [];

                    stateIdArray.forEach((stateId) => {
                        const stateObject = states.find((state) => state.value === stateId);
                        stateArray.push(stateObject);
                    });

                    state = state.map((input) => {
                        const { boolType } = input;

                        if (boolType === "or") {
                            return { ...input, active: true, value: stateArray };
                        } else {
                            return input;
                        }
                    });
                }

                break;
            }
            case "not_state": {
                if (value) {
                    const stateIdArray = value.split(",");
                    const stateArray = [];

                    stateIdArray.forEach((stateId) => {
                        const stateObject = states.find((state) => state.value === stateId);
                        stateArray.push(stateObject);
                    });

                    state = state.map((input) => {
                        const { boolType } = input;

                        if (boolType === "not") {
                            return { ...input, active: true, value: stateArray };
                        } else {
                            return input;
                        }
                    });
                }

                break;
            }
            case "and_country": {
                if (value) {
                    const countryIdArray = value.split(",");
                    const countryArray = [];

                    countryIdArray.forEach((countryId) => {
                        const countryObject = countries.find((country) => country.value === countryId);
                        countryArray.push(countryObject);
                    });

                    country = country.map((input) => {
                        const { boolType } = input;
                        if (boolType === "and") {
                            return { ...input, active: true, value: countryArray };
                        } else {
                            return input;
                        }
                    });
                }

                break;
            }
            case "or_country": {
                if (value) {
                    const countryIdArray = value.split(",");
                    const countryArray = [];

                    countryIdArray.forEach((countryId) => {
                        const countryObject = countries.find((country) => country.value === countryId);
                        countryArray.push(countryObject);
                    });

                    country = country.map((input) => {
                        const { boolType } = input;
                        if (boolType === "or") {
                            return { ...input, active: true, value: countryArray };
                        } else {
                            return input;
                        }
                    });
                }

                break;
            }
            case "not_country": {
                if (value) {
                    const countryIdArray = value.split(",");
                    const countryArray = [];

                    countryIdArray.forEach((countryId) => {
                        const countryObject = countries.find((country) => country.value === countryId);
                        countryArray.push(countryObject);
                    });

                    country = country.map((input) => {
                        const { boolType } = input;
                        if (boolType === "not") {
                            return { ...input, active: true, value: countryArray };
                        } else {
                            return input;
                        }
                    });
                }

                break;
            }
            // country case for backwards compatibility
            case "country": {
                if (value) {
                    const countryIdArray = value.split(",");
                    const countryArray = [];

                    countryIdArray.forEach((countryId) => {
                        const countryObject = countries.find((country) => country.value === countryId);
                        countryArray.push(countryObject);
                    });

                    country = country.map((input) => {
                        const { boolType } = input;
                        if (boolType === "and") {
                            return { ...input, active: true, value: countryArray };
                        } else {
                            return input;
                        }
                    });
                }

                break;
            }
            // dsl searchFields
            case "dsl": {
                dsl = value;
                break;
            }
            // FILTERS
            // expertRateSlider
            case "expertRateSlider": {
                const sliderArrayKeyValPairs = value.split(",");

                sliderArrayKeyValPairs.forEach((keyValPair) => {
                    const [key, value] = keyValPair.split("_");
                    if (key === "active") {
                        expertRateSlider = { ...expertRateSlider, [key]: value };
                    } else {
                        expertRateSlider = { ...expertRateSlider, [key]: parseInt(value) };
                    }
                });

                break;
            }
            // METADATA
            // projectId
            case "projectId": {
                projectId = value;

                break;
            }
        }
    }

    // spread all the update sections back into a final object
    const updatedSearchObj = {
        ...defaultSearchObj,
        options: {
            ...defaultSearchObj.options,
            checkbox,
            radio,
        },
        searchFields: {
            ...defaultSearchObj.searchFields,
            string: {
                keywords,
                position,
                companies,
            },
            object: {
                tags,
                state,
                country,
            },
            dsl,
        },
        filters: {
            ...defaultSearchObj.filters,
            expertRateSlider,
        },
        metaData: {
            ...defaultSearchObj.metaData,
            projectId,
        },
    };

    // console.log(updatedSearchObj, " :: updatedSearchObj in stringbuilder");
    return updatedSearchObj;
};
