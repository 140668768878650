// react
import React, { useState, useEffect, useContext } from "react";
import { CSVLink, CSVDownload } from "react-csv";
// npm
import axios from "axios";
import MUIDataTable, { ExpandButton } from "mui-datatables";
import { Link } from "react-router-dom";

// MUIDataTable requirements
import { TableRow, TableCell } from "@mui/material";

// MUI - general
import { Button } from "@mui/material";

// MUI - tabs
import { Tabs, Tab, Box } from "@mui/material";

// MUI - style imports
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

// utils
import { formatForConfirmation } from "../components/scheduling/utils/utils";
import { truncateString, basicDateTimeStamp, numberWithCommas, basicDateStamp } from "../Utils";

// custom
import LargeTextBoxDialog from "../components/payment-request/LargeTextBoxDialog";
import AccountDetailsDialog from "../components/payment-request/AccountDetailsDialog";
import PaidAmountDialog from "../components/payment-request/PaidAmountDialog";
import ExpertDataTable from "../components/payment-request/ExpertDataTable";

import DownloadIcon from "@mui/icons-material/Download";

// auth
import { authContext } from "../context/auth";
const PAYMENT_ACCESS_LIST = [3, 67291, 153383, 95647, 309841, 67281, 340269, 309842];
// 3 - dan c., 67291 - michale f., 153383 - victoria, 95647 - felix b., 309841 - Anjali, 67281 - Joe Riggio, 340269 - Brandon Orr, 309842 - Jon Krais
const APPROVAL_ACCESS_LIST = [3, 67291, 153383, 95647, 309841, 67281, 67281, 340269, 309842];
// 3 - dan c., 67291 - michale f., 153383 - victoria, 95647 - felix b., 309841 - Anjali, 67281 - Joe Riggio, 340269 - Brandon Orr, 309842 - Jon Krais

// styles
const header = css`
    font-size: 2rem;
    margin-bottom: 2rem;
`;

const nameStyle = css`
    font-size: 1rem;
    font-weight: 700;
    text-decoration: none;
    color: #523197;
    &:hover {
        color: #8966ad;
    }
`;

const approvedStyle = css`
    font-size: 0.85rem;
    font-weight: 600;
    text-decoration: none;
    color: #4fab6a;
`;

const warningStyle = css`
    font-size: 0.85rem;
    font-weight: 700;
    text-decoration: none;
    color: #ffffff;
    background-color: #cc0000;
    padding: 3px;
    border-radius: 3px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 8px;
`;

// const emphasisStyle = css`
//     font-size: 0.75rem;
//     text-decoration: none;
//     color: #666;
// `;

const projStyle = css`
    text-decoration: none;
    color: #542295;
`;

const buttonThatLooksLikeLink = css`
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    margin-top: 0.25rem;
    font-size: 0.75rem;
    font-weight: 300;
    color: #542295;
    &:hover {
        color: blue;
    }
`;

// =========
// MUI FUNCS
// =========

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const PaymentRequest = (props) => {
    // =====
    // STATE
    // =====

    // loading
    const [refresh, setRefresh] = useState(true);
    const [loading, setLoading] = useState(true);

    // data for invoicing table
    const [needsInvoicingData, setNeedsInvoicingData] = useState([]);
    const [invoicesSentData, setInvoicesSentData] = useState([]);

    // the data for the two different tables
    const [needsPaymentInfo, setNeedsPaymentInfo] = useState([]);
    const [hasPaidInfo, setHasPaidInfo] = useState([]);
    const [surveyPaymentsNeedsInfo, setSurveyPaymentsNeedsInfo] = useState([]);
    const [surveyPaymentsApprovedInfo, setSurveyPaymentsApproved] = useState([]);

    // tab panel state
    const [value, setValue] = useState(0);

    // payment details dialog
    const [openLargeTextBoxDialog, setOpenLargeTextBoxDialog] = useState(false);
    const [largeText, setLargeText] = useState("");

    // current expert being clicked on
    const [currentExpert, setCurrentExpert] = useState({});

    // PAID DIALOG STATE
    const [openPaidAmntDialog, setOpenPaidAmntDialog] = useState(false);
    const [openSurveyPaidAmntDialog, setOpenSurveyPaidAmntDialog] = useState(false);
    // paid dialog default values
    const DEFAULT_DROPDOWN_VALUE = JSON.stringify({
        value: 0,
        label: "Wire",
    });

    // paid dialog state
    const [dropdownState, setDropdownState] = useState(DEFAULT_DROPDOWN_VALUE);
    const [paidAmntError, setPaidAmntError] = useState(false);

    // account details
    const [openAccountDetailsDialog, setOpenAccountDetailsDialog] = useState(false);

    // auth
    const { auth } = useContext(authContext);

    // ========
    // PAGE LOAD
    // ========

    const endpoints = [
        "/api/finance/getInvoicingData",
        "/api/expert/findPaymentDetails",
        "/api/finance/surveyPaymentRequests",
        "/api/finance/getInvoiceSentData",
    ];

    useEffect(async () => {
        axios.all(endpoints.map((endpoint) => axios.get(endpoint))).then(
            axios.spread(
                (
                    { data: invoicingAxiosData },
                    { data: paymentAxiosData },
                    { data: surveyPaymentRequestsData },
                    { data: invoiceSentAxiosData }
                ) => {
                    const invoicingData = invoicingAxiosData.data[0];
                    const invoiceSentData = invoiceSentAxiosData.data[0];
                    console.log("USE EFFECT :: GET INVOICING DATA :: ", invoicingData);
                    console.log("USE EFFECT :: GET INVOICES SENT DATA :: ", invoiceSentData);
                    let invoicingDataWithTotal = [];
                    let invoiceSentDataWithTotal = [];
                    if (invoicingData.length > 0) {
                        // Need to add up all the client charge rows...
                        invoicingDataWithTotal = invoicingData.map((invoice) => {
                            if (invoice.expert_data) {
                                let amountDue = 0;
                                for (const expert of invoice.expert_data) {
                                    amountDue += expert.client_charge_amount;
                                }
                                invoice.amount_due = amountDue;
                            } else {
                                invoice.amount_due = 0;
                            }
                            return invoice;
                        });
                    }
                    if (invoiceSentData.length > 0) {
                        invoiceSentDataWithTotal = invoiceSentData.map((invoice) => {
                            if (invoice.expert_data) {
                                let amountDue = 0;
                                for (const expert of invoice.expert_data) {
                                    amountDue += expert.client_charge_amount;
                                }
                                invoice.amount_due = amountDue;
                            } else {
                                invoice.amount_due = 0;
                            }
                            return invoice;
                        });
                    }
                    // console.log(invoicingDataWithTotal, " :: invoicingDataWithTotal");
                    setNeedsInvoicingData(invoicingDataWithTotal);
                    setInvoicesSentData(invoiceSentDataWithTotal);
                    setLoading(false);
                    const [needsPayment, hasPaid] = paymentAxiosData;

                    if (needsPayment && hasPaid) {
                        setNeedsPaymentInfo(needsPayment);
                        setHasPaidInfo(hasPaid);
                        setLoading(false);
                    }

                    const [surveyPaymentsNeedsApproval, surveyPaymentsApproved] = surveyPaymentRequestsData;
                    if (surveyPaymentsNeedsApproval && surveyPaymentsApproved) {
                        setSurveyPaymentsNeedsInfo(surveyPaymentsNeedsApproval);
                        setSurveyPaymentsApproved(surveyPaymentsApproved);
                    }
                    setRefresh(false);
                }
            )
        );
    }, [refresh]);

    // ============
    // HANDLE FUNCS
    // ============

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function handleOpenSurveyPaidAmntDialog(expert) {
        setCurrentExpert(expert);
        setOpenSurveyPaidAmntDialog(true);
    }

    function handleOpenPaidAmntDialog(expert) {
        setCurrentExpert(expert);
        setOpenPaidAmntDialog(true);
    }

    function handleOpenAccountDetailsDialog(expert) {
        setCurrentExpert(expert);
        setOpenAccountDetailsDialog(true);
    }

    function confirmPaidAmnt(dialogData) {
        // console.log(dialogData, " :: dialogData");

        if (!dialogData.paidAmnt) {
            setPaidAmntError(true);
            return;
        }

        // pop relavent info into the expert object
        const expert = currentExpert;

        // add the stuff from the dialog to the expert object
        expert.paid_amount = dialogData.paidAmnt;
        expert.payment_notes = dialogData.paymentNotes;
        expert.payment_type = JSON.parse(dialogData.dropdownState).label;
        expert.user_id = auth.data.id;

        // console.log(expert, " :: expert");

        axios
            .post("/api/expert/confirmPayment", { expert })
            .then((res) => {
                handleClosePaidAmntDialog();
                setRefresh(true);
            })
            .catch((err) => {
                handleClosePaidAmntDialog();
            });
    }

    function confirmSurveyPaidAmnt(dialogData) {
        console.log(dialogData, "confirmSurveyPaidAmnt :: dialogData");

        if (!dialogData.paidAmnt) {
            setPaidAmntError(true);
            return;
        }

        // // pop relavent info into the expert object
        const expert = currentExpert;

        // // add the stuff from the dialog to the expert object
        expert.paid_amount = dialogData.paidAmnt;
        expert.payment_notes = dialogData.paymentNotes;
        expert.payment_type = JSON.parse(dialogData.dropdownState).label;
        expert.user_id = auth.data.id;

        console.log(expert, " :: expert");

        axios
            .post("/api/finance/saveSurveyPayment", { expert })
            .then((res) => {
                console.log(res, " :: res");
                handleCloseSurveyPaidAmntDialog();
                setRefresh(true);
            })
            .catch((err) => {
                console.log(err, " :: err");
                handleCloseSurveyPaidAmntDialog();
            });
    }

    const markInvoiceAsSent = async (invoice_id, email_info) => {
        const loggedin_user = `${auth.data.fname} ${auth.data.lname}`;
        await axios
            .post("/api/finance/updateInvoiceAsSent", {
                invoice_id,
                user_id: auth.data.id,
                user_name: loggedin_user,
                email_info,
            })
            .then((res) => {
                console.log(res, " :: markInvoiceAsSent API Response");
                setRefresh(true);
            })
            .catch((err) => {
                console.log(err, " :: Mark Invoice As Sent ERROR");
            });
    };

    const handleClosePaidAmntDialog = () => {
        setOpenPaidAmntDialog(false);
        setDropdownState(DEFAULT_DROPDOWN_VALUE);
        setPaidAmntError(false);
    };

    const handleCloseSurveyPaidAmntDialog = () => {
        setOpenSurveyPaidAmntDialog(false);
        setDropdownState(DEFAULT_DROPDOWN_VALUE);
        setPaidAmntError(false);
    };

    function handleLargeTextBoxDialog(str) {
        setLargeText(str);
        setOpenLargeTextBoxDialog(true);
    }

    function navigateToApprovalPage(project_id) {
        props.history.push(`/project/pricing/${project_id}`);
    }

    // ============
    // RENDER FUNCS
    // ============

    function renderExpertRow(row) {
        const { expert_data } = row;
        if (expert_data) {
            const data = expert_data.map((expert) => {
                return {
                    expert_id: expert.expert_id,
                    expert_name: expert.expert_name,
                    price_per_unit: expert.price_per_unit,
                    unit_charge_amount: expert.unit_charge_amount,
                    expert_hourly_to_client: expert.expert_hourly_to_client,
                    client_charge_amount: expert.client_charge_amount,
                    client_duration: expert.client_duration,
                    approved_by_name: expert.approved_by_name,
                    approved_on: basicDateTimeStamp(expert.approved_on),
                    current_employment: expert.current_employment,
                    invoicing_notes: expert.invoicing_notes,
                };
            });

            return <ExpertDataTable rows={data} />;
        } else {
            return (
                <div>
                    <b>ERROR:</b> No Experts Associated With This Invoice ⚠️
                </div>
            );
        }
    }

    function renderLargeTextBoxOnTable(str) {
        if (str && str.length > 35) {
            return (
                <>
                    <div>{truncateString(str, 35)}</div>
                    <button css={buttonThatLooksLikeLink} onClick={() => handleLargeTextBoxDialog(str)}>
                        (See More)
                    </button>
                </>
            );
        } else {
            return <div>{str}</div>;
        }
    }

    function renderAccountDetails(accessList, expert) {
        // console.log(expert, " :: expert");
        // console.log("renderAccountDetails");
        const userHasAccess = accessList.includes(auth.data.id);

        if (expert.account_info_exists) {
            if (userHasAccess) {
                return (
                    <button css={buttonThatLooksLikeLink} onClick={() => handleOpenAccountDetailsDialog(expert)}>
                        {expert.isdomesticaccount
                            ? "See Account Details"
                            : "See Account Details (International Account 🌎)"}
                    </button>
                );
            } else {
                return <div>Account Info In System ✅</div>;
            }
        } else {
            return (
                <div>
                    Account Info <b>NOT</b> In System ❌
                </div>
            );
        }
        if (userHasAccess && expert.account_info_exists) {
        }
    }

    // ====
    // DATA
    // ====

    // TABLE OPTIONS

    const options = {
        print: false,
        rowsPerPage: 100,
        viewColumns: false,
        selectableRows: "none",
        tableBodyHeight: "100%",
        customToolbarSelect: () => {},
        download: true,
    };

    const expandableOptions = {
        print: false,
        rowsPerPage: 100,
        viewColumns: false,
        selectableRows: "none",
        expandableRows: true,
        expandableRowsHeader: false,
        expandableRowsOnClick: true,
        download: false,
        // customToolbarSelect: () => {},
        renderExpandableRow: (rowData, { dataIndex, rowIndex }) => {
            const row = needsInvoicingData[dataIndex];
            const colSpan = rowData.length + 1;
            return (
                <TableRow>
                    <TableCell colSpan={colSpan}>{renderExpertRow(row)}</TableCell>
                </TableRow>
            );
        },
        // onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) =>
        //     console.log(curExpanded, allExpanded, rowsExpanded),
        // onTableChange: (action, tableState) => {
        //     console.log(action, tableState, " :: action, tableState");
        // },
    };

    const expandableOptionsInvoicesSent = {
        print: false,
        rowsPerPage: 100,
        viewColumns: false,
        selectableRows: "none",
        expandableRows: true,
        expandableRowsHeader: false,
        expandableRowsOnClick: true,
        download: false,
        renderExpandableRow: (rowData, { dataIndex, rowIndex }) => {
            const row = invoicesSentData[dataIndex];
            const colSpan = rowData.length + 1;
            return (
                <TableRow>
                    <TableCell colSpan={colSpan}>{renderExpertRow(row)}</TableCell>
                </TableRow>
            );
        },
    };

    const components = {
        ExpandButton: function (props) {
            // if (props.dataIndex === 3 || props.dataIndex === 4) return <div style={{ width: "24px" }} />;
            return <ExpandButton {...props} />;
        },
    };

    // COLUMNS

    const needsInvoicingColumns = [
        {
            name: "download_invoice",
            label: "CSV",
            options: {
                filter: false,
                sort: false,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const row = needsInvoicingData[tableMeta.rowIndex];
                    // console.log(row, " :: row");
                    const csvData = row.expert_data.map((expert) => {
                        return {
                            ExternalID: `FTINV${row.invoice_id}`,
                            Customer: `C00001 FT - 1315 Capital`,
                            Date: basicDateStamp(expert.scheduling_date),
                            // Qty: expert.unit_charge_amount,
                            // Rate: expert.expert_hourly_to_client,
                            Amount: expert.client_charge_amount,
                            LineDescription: `${row.project_title} for ${expert.expert_name}- ${
                                expert.client_duration
                            } minutes ${basicDateTimeStamp(expert.scheduled_datetime)} - ${
                                expert.unit_charge_amount
                            } units @ ${expert.expert_hourly_to_client} rate per unit`,
                            Item: `Phone Consultation: ${row.project_type}`,
                            LineID: "",
                            Memo: "",
                            PO: row.case_code,
                        };
                    });
                    // console.log(csvData, " :: csvData");
                    return (
                        <div>
                            <CSVLink data={csvData} filename={`FTINV${row.invoice_id}`}>
                                {<DownloadIcon />}
                            </CSVLink>
                        </div>
                    );
                },
            },
        },

        {
            name: "invoice_created_on",
            label: "Invoice Date",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { invoice_created_on, invoice_created_by_name, invoice_updated_on, invoice_updated_by_name } =
                        needsInvoicingData?.[dataIndex];

                    return (
                        <div>
                            <strong>Invoice Created: </strong>
                            {basicDateTimeStamp(invoice_created_on)} <br />
                            <b>by</b> {invoice_created_by_name}
                            <br />
                            {invoice_updated_on ? (
                                <span css={approvedStyle}>
                                    <strong>Invoice Updated On: </strong>
                                    {basicDateTimeStamp(invoice_updated_on)} <br /> <b>by</b> {invoice_updated_by_name}
                                </span>
                            ) : (
                                ""
                            )}
                        </div>
                    );
                },
            },
        },
        {
            name: "project_title",
            label: "Project",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    const { project_title, project_id, project_type } = needsInvoicingData?.[dataIndex];

                    return (
                        <div>
                            <Link css={nameStyle} to={`/project/pricing/${project_id}`}>
                                {project_title}
                            </Link>
                            <br />
                            {project_type}
                        </div>
                    );
                },
            },
        },
        {
            name: "case_code",
            label: "Code",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { case_code } = needsInvoicingData?.[dataIndex];

                    return <div>{case_code}</div>;
                },
            },
        },
        {
            name: "client_name",
            label: "Client",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const {
                        client_name,
                        client_id,
                        primary_client_contact_name,
                        primary_client_contact_email,
                        additional_client_emails,
                    } = needsInvoicingData?.[dataIndex];

                    return (
                        <div>
                            <Link css={nameStyle} to={`/client/view/${client_id}`}>
                                {client_name}
                            </Link>
                            <br />
                            Contact: {primary_client_contact_name}
                            <br />
                            Email: {primary_client_contact_email}
                            {additional_client_emails &&
                                additional_client_emails.split(",").map((email, index) => {
                                    if (index === 0) {
                                        return <div key={index}>Additional Emails: {email}</div>;
                                    } else {
                                        return <div key={index}>{email}</div>;
                                    }
                                })}
                        </div>
                    );
                },
            },
        },
        {
            name: "amount_due",
            label: "Amount Due",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    const { amount_due } = needsInvoicingData?.[dataIndex];

                    return (
                        <div>
                            <strong>${numberWithCommas(amount_due)}</strong>
                        </div>
                    );
                },
            },
        },
        {
            name: "is_sent",
            label: "Action",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    // TODO: RUN => ALTER TABLE FINANCE.CLIENT_INVOICE
                    // console.log(needsInvoicingData?.[dataIndex], " :: needsInvoicingData?.[dataIndex]");
                    const {
                        invoice_id,
                        is_sent,
                        invoice_sent_on,
                        invoice_sent_by_name,
                        invoice_created_by_id,
                        invoice_created_on,
                        case_code,
                        project_title,
                    } = needsInvoicingData?.[dataIndex];
                    return (
                        <>
                            {is_sent ? (
                                <div>
                                    Sent on {basicDateTimeStamp(invoice_sent_on)}
                                    <br />
                                    by {invoice_sent_by_name}
                                </div>
                            ) : (
                                <Button
                                    variant="contained"
                                    disabled={false}
                                    size="small"
                                    onClick={() =>
                                        markInvoiceAsSent(invoice_id, {
                                            invoice_id,
                                            invoice_created_by_id,
                                            invoice_created_on: basicDateTimeStamp(invoice_created_on),
                                            case_code,
                                            project_title,
                                        })
                                    }
                                >
                                    Mark As Sent
                                </Button>
                            )}
                        </>
                    );
                },
            },
        },
    ];

    const invoicesSentColumns = [
        {
            name: "invoice_created_on",
            label: "Invoice Date",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { invoice_created_on, invoice_created_by_name, invoice_updated_on, invoice_updated_by_name } =
                        invoicesSentData?.[dataIndex];

                    return (
                        <div>
                            <strong>Invoice Created: </strong>
                            {basicDateTimeStamp(invoice_created_on)} <br />
                            <b>by</b> {invoice_created_by_name}
                            <br />
                            {invoice_updated_on ? (
                                <span css={approvedStyle}>
                                    <strong>Invoice Updated On: </strong>
                                    {basicDateTimeStamp(invoice_updated_on)} <br /> <b>by</b> {invoice_updated_by_name}
                                </span>
                            ) : (
                                ""
                            )}
                        </div>
                    );
                },
            },
        },
        {
            name: "project_title",
            label: "Project",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    const { project_title, project_id, project_type } = invoicesSentData?.[dataIndex];

                    return (
                        <div>
                            <Link css={nameStyle} to={`/project/pricing/${project_id}`}>
                                {project_title}
                            </Link>
                            <br />
                            {project_type}
                        </div>
                    );
                },
            },
        },
        {
            name: "case_code",
            label: "Code",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { case_code } = invoicesSentData?.[dataIndex];

                    return <div>{case_code}</div>;
                },
            },
        },
        {
            name: "client_name",
            label: "Client",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const {
                        client_name,
                        client_id,
                        primary_client_contact_name,
                        primary_client_contact_email,
                        additional_client_emails,
                    } = invoicesSentData?.[dataIndex];

                    return (
                        <div>
                            <Link css={nameStyle} to={`/client/view/${client_id}`}>
                                {client_name}
                            </Link>
                            <br />
                            Contact: {primary_client_contact_name}
                            <br />
                            Email: {primary_client_contact_email}
                            {additional_client_emails &&
                                additional_client_emails.split(",").map((email, index) => {
                                    if (index === 0) {
                                        return <div key={index}>Additional Emails: {email}</div>;
                                    } else {
                                        return <div key={index}>{email}</div>;
                                    }
                                })}
                        </div>
                    );
                },
            },
        },
        {
            name: "is_sent",
            label: "Invoiced",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    // TODO: RUN => ALTER TABLE FINANCE.CLIENT_INVOICE
                    const { amount_due, is_sent, invoice_sent_on, invoice_sent_by_name } =
                        invoicesSentData?.[dataIndex];
                    return (
                        <>
                            {is_sent && (
                                <div>
                                    Invoice Total: <strong css={approvedStyle}>${numberWithCommas(amount_due)}</strong>
                                    <br />
                                    Sent on {basicDateTimeStamp(invoice_sent_on)}
                                    <br />
                                    by {invoice_sent_by_name}
                                </div>
                            )}
                        </>
                    );
                },
            },
        },
    ];

    const needsPaymentColumns = [
        {
            name: "expert_name",
            label: "Expert",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    const {
                        expert_name,
                        expert_id,
                        project_title,
                        project_id,
                        consultation_rate,
                        consultation_rate_currency,
                    } = needsPaymentInfo?.[dataIndex];

                    return (
                        <div>
                            <Link css={nameStyle} to={`/expert/view/${expert_id}`}>
                                {expert_name}
                            </Link>
                            <div>
                                {consultation_rate
                                    ? `Rate: ${numberWithCommas(consultation_rate)} ${consultation_rate_currency}`
                                    : "Rate: N/A"}
                            </div>
                            <Link css={projStyle} to={`/project/view/${project_id}`}>
                                {project_title}
                            </Link>
                        </div>
                    );
                },
            },
        },
        {
            name: "payment_req_requested_by",
            label: "Requested By",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { user_name } = needsPaymentInfo?.[dataIndex];

                    return <div>{user_name ? `${user_name}` : "N/A"}</div>;
                },
            },
        },
        {
            name: "payment_req_created_on",
            label: "Requested On",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { user_name, payment_req_created_on, payment_req_updated_on, scheduled_datetime } =
                        needsPaymentInfo?.[dataIndex];

                    return (
                        <>
                            <div>
                                <strong>{formatForConfirmation(payment_req_created_on)}</strong>{" "}
                            </div>
                            <div>
                                {payment_req_updated_on ? (
                                    <span>
                                        <strong>Updated:</strong>
                                        {formatForConfirmation(payment_req_updated_on)}
                                    </span>
                                ) : null}
                            </div>
                            <div>
                                <strong>Scheduled:</strong> {`${formatForConfirmation(scheduled_datetime)}`}
                            </div>
                        </>
                    );
                },
            },
        },
        {
            name: "payment_details",
            label: "Payment Details",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    const {
                        payment_details,
                        approved_by_name,
                        approved_on,
                        approved,
                        custom_expert_charge_reason_id,
                        custom_expert_charge_reason_name,
                        custom_expert_charge_notes,
                        payment_req_created_by_name,
                        payment_req_updated_by_name,
                    } = needsPaymentInfo?.[dataIndex];

                    if (custom_expert_charge_reason_id) {
                        return (
                            <>
                                <div>
                                    <strong css={approvedStyle}>
                                        {`🚨CUSTOM CHARGE (${custom_expert_charge_reason_name})🚨`}
                                        <br />
                                        {`By ${
                                            payment_req_updated_by_name.trim()
                                                ? payment_req_updated_by_name
                                                : payment_req_created_by_name
                                        }`}
                                    </strong>
                                </div>
                                <br />
                                <div>{renderLargeTextBoxOnTable(custom_expert_charge_notes)}</div>
                            </>
                        );
                    } else {
                        return (
                            <>
                                {approved ? (
                                    <>
                                        <div>
                                            <strong css={approvedStyle}>
                                                {`APPROVED: ${approved_by_name ? approved_by_name : "N/A"}`}{" "}
                                                {`on ${approved_on ? formatForConfirmation(approved_on) : "N/A"}`}
                                            </strong>
                                        </div>
                                        <br />
                                    </>
                                ) : (
                                    <div css={warningStyle}>Payment Not Yet Approved</div>
                                )}
                                <div>{renderLargeTextBoxOnTable(payment_details)}</div>
                            </>
                        );
                    }
                },
            },
        },
        {
            name: "expert_duration",
            label: "Duration",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    const { expert_duration } = needsPaymentInfo?.[dataIndex];

                    return <div>{expert_duration ? `${expert_duration} mins` : "N/A"}</div>;
                },
            },
        },
        {
            name: "expert_charge_amount",
            label: "Amount Due",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    const expert = needsPaymentInfo?.[dataIndex];

                    if (expert.custom_expert_charge_reason_id) {
                        return (
                            <>
                                <div>{numberWithCommas(expert.custom_expert_charge_amount)}</div>
                                <div>---</div>
                                {renderAccountDetails(PAYMENT_ACCESS_LIST, expert)}
                            </>
                        );
                    } else {
                        return (
                            <>
                                <div>
                                    {expert.expert_charge_amount
                                        ? `${numberWithCommas(expert.expert_charge_amount)} ${
                                              expert.consultation_rate_currency
                                          }`
                                        : "N/A"}
                                </div>
                                <div>---</div>
                                {renderAccountDetails(PAYMENT_ACCESS_LIST, expert)}
                            </>
                        );
                    }
                },
            },
        },
        {
            name: "is_paid",
            label: "Action",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    const expert = needsPaymentInfo?.[dataIndex];

                    if (expert.custom_expert_charge_reason_id) {
                        return (
                            <Button
                                disabled={!PAYMENT_ACCESS_LIST.includes(auth.data.id)}
                                variant="contained"
                                onClick={() => handleOpenPaidAmntDialog(expert)}
                            >
                                {"Paid"}
                            </Button>
                        );
                    } else {
                        return (
                            <>
                                {expert.approved ? (
                                    <Button
                                        disabled={!PAYMENT_ACCESS_LIST.includes(auth.data.id)}
                                        variant="contained"
                                        onClick={() => handleOpenPaidAmntDialog(expert)}
                                    >
                                        {"Paid"}
                                    </Button>
                                ) : (
                                    <Button
                                        disabled={!APPROVAL_ACCESS_LIST.includes(auth.data.id)}
                                        css={projStyle}
                                        onClick={() => navigateToApprovalPage(expert.project_id)}
                                    >
                                        APPROVE
                                    </Button>
                                )}
                            </>
                        );
                    }
                },
            },
        },
    ];

    const hasPaidColumns = [
        {
            name: "expert_name",
            label: "Expert",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    const {
                        expert_name,
                        expert_id,
                        project_title,
                        project_id,
                        consultation_rate,
                        consultation_rate_currency,
                    } = hasPaidInfo?.[dataIndex];

                    return (
                        <div>
                            <Link css={nameStyle} to={`/expert/view/${expert_id}`}>
                                {expert_name}
                            </Link>
                            <div>
                                {consultation_rate
                                    ? `Rate: ${numberWithCommas(consultation_rate)} ${consultation_rate_currency}`
                                    : "Rate: N/A"}
                            </div>
                            <Link css={projStyle} to={`/project/pricing/${project_id}`}>
                                {project_title}
                            </Link>
                        </div>
                    );
                },
            },
        },
        {
            name: "payment_req_created_on",
            label: "Requested On",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { user_name, payment_req_created_on, payment_req_updated_on, scheduled_datetime } =
                        hasPaidInfo?.[dataIndex];

                    return (
                        <>
                            <div>
                                <b>Requested: </b>{" "}
                                {`By ${user_name} on ${formatForConfirmation(payment_req_created_on)}`}
                            </div>
                            <div>
                                {payment_req_updated_on ? (
                                    <span>
                                        <b>Updated: </b>
                                        {formatForConfirmation(payment_req_updated_on)}
                                    </span>
                                ) : null}
                            </div>
                            <div>
                                <strong>Scheduled:</strong> {`${formatForConfirmation(scheduled_datetime)}`}
                            </div>
                        </>
                    );
                },
            },
        },
        {
            name: "payment_details",
            label: "Payment Details",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    const { payment_details, payment_notes, payment_type } = hasPaidInfo?.[dataIndex];

                    return (
                        <>
                            <div>
                                <b>Payment Type: </b> {payment_type ? payment_type : "N/A"}
                            </div>
                            <div>
                                <b>Payment Notes: </b>
                                {payment_notes ? renderLargeTextBoxOnTable(payment_notes) : "N/A"}
                            </div>
                            <div>
                                <b>Request Notes: </b>
                                {payment_details ? renderLargeTextBoxOnTable(payment_details) : "N/A"}
                            </div>
                        </>
                    );
                },
            },
        },
        {
            name: "expert_duration",
            label: "Duration",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    const { expert_duration } = hasPaidInfo?.[dataIndex];

                    return <div>{expert_duration ? `${expert_duration} mins` : "N/A"}</div>;
                },
            },
        },
        {
            name: "paid_amount",
            label: "Payment Amount",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    const { paid_on, paid_by_name, paid_amount } = hasPaidInfo?.[dataIndex];

                    return (
                        <div>
                            Payment: <strong css={approvedStyle}>${paid_amount}</strong>
                            <br />
                            <em>
                                {`By: ${paid_by_name ? paid_by_name : "N/A"}`}{" "}
                                {`on ${paid_on ? formatForConfirmation(paid_on) : "N/A"}`}
                            </em>
                        </div>
                    );
                },
            },
        },
    ];

    const surveyPaymentsNeedsColumns = [
        {
            name: "expert_name",
            label: "Expert",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    const { expert_name, expert_id, project_title, project_id } = surveyPaymentsNeedsInfo?.[dataIndex];

                    return (
                        <>
                            <div>
                                <Link css={nameStyle} to={`/expert/view/${expert_id}`}>
                                    {expert_name}
                                </Link>
                            </div>
                            <div>
                                <Link css={projStyle} to={`/project/view/${project_id}`}>
                                    {project_title}
                                </Link>
                            </div>
                        </>
                    );
                },
            },
        },
        {
            name: "payment_req_requested_by",
            label: "Requested By",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { user_name } = surveyPaymentsNeedsInfo?.[dataIndex];

                    return <div>{user_name ? `${user_name}` : "N/A"}</div>;
                },
            },
        },
        {
            name: "payment_req_created_on",
            label: "Requested On",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { payment_req_created_on, payment_req_updated_on } = surveyPaymentsNeedsInfo?.[dataIndex];

                    return (
                        <>
                            <div>
                                <strong>{formatForConfirmation(payment_req_created_on)}</strong>{" "}
                            </div>
                            <div>
                                {payment_req_updated_on ? (
                                    <span>
                                        <strong>Updated:</strong>
                                        {formatForConfirmation(payment_req_updated_on)}
                                    </span>
                                ) : null}
                            </div>
                        </>
                    );
                },
            },
        },
        {
            name: "payment_details",
            label: "Payment Details",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    const { payment_details } = surveyPaymentsNeedsInfo?.[dataIndex];

                    return (
                        <>
                            <div>{renderLargeTextBoxOnTable(payment_details)}</div>
                        </>
                    );
                },
            },
        },
        {
            name: "expert_charge_amount",
            label: "Amount Due",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    const expert = surveyPaymentsNeedsInfo?.[dataIndex];

                    return (
                        <>
                            <div>
                                {expert.expert_charge_amount
                                    ? `${numberWithCommas(expert.expert_charge_amount)} USD`
                                    : "N/A"}
                            </div>
                            <div>---</div>
                            {renderAccountDetails(PAYMENT_ACCESS_LIST, expert)}
                        </>
                    );
                },
            },
        },
        {
            name: "is_paid",
            label: "Action",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    const expert = surveyPaymentsNeedsInfo?.[dataIndex];

                    return (
                        <>
                            <Button
                                disabled={!PAYMENT_ACCESS_LIST.includes(auth.data.id)}
                                variant="contained"
                                onClick={() => handleOpenSurveyPaidAmntDialog(expert)}
                            >
                                {"Paid"}
                            </Button>
                        </>
                    );
                },
            },
        },
    ];

    const surveyPaymentsApprovedColumns = [
        {
            name: "expert_name",
            label: "Expert",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    const { expert_name, expert_id, project_title, project_id } =
                        surveyPaymentsApprovedInfo?.[dataIndex];

                    return (
                        <>
                            <div>
                                <Link css={nameStyle} to={`/expert/view/${expert_id}`}>
                                    {expert_name}
                                </Link>
                            </div>
                            <div>
                                <Link css={projStyle} to={`/project/pricing/${project_id}`}>
                                    {project_title}
                                </Link>
                            </div>
                        </>
                    );
                },
            },
        },
        {
            name: "payment_req_created_on",
            label: "Requested On",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { user_name, payment_req_created_on, payment_req_updated_on } =
                        surveyPaymentsApprovedInfo?.[dataIndex];

                    return (
                        <>
                            <div>
                                <b>Requested: </b>{" "}
                                {`By ${user_name} on ${formatForConfirmation(payment_req_created_on)}`}
                            </div>
                            <div>
                                {payment_req_updated_on ? (
                                    <span>
                                        <b>Updated: </b>
                                        {formatForConfirmation(payment_req_updated_on)}
                                    </span>
                                ) : null}
                            </div>
                        </>
                    );
                },
            },
        },
        {
            name: "payment_details",
            label: "Payment Details",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    const { payment_details, payment_notes, payment_type } = surveyPaymentsApprovedInfo?.[dataIndex];

                    return (
                        <>
                            <div>
                                <b>Payment Type: </b> {payment_type ? payment_type : "N/A"}
                            </div>
                            <div>
                                <b>Payment Notes: </b>
                                {payment_notes ? renderLargeTextBoxOnTable(payment_notes) : "N/A"}
                            </div>
                            <div>
                                <b>Request Notes: </b>
                                {payment_details ? renderLargeTextBoxOnTable(payment_details) : "N/A"}
                            </div>
                        </>
                    );
                },
            },
        },
        {
            name: "paid_amount",
            label: "Payment Amount",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    const { paid_on, paid_by_name, paid_amount } = surveyPaymentsApprovedInfo?.[dataIndex];

                    return (
                        <div>
                            Payment: <strong css={approvedStyle}>${paid_amount}</strong>
                            <br />
                            <em>
                                {`By: ${paid_by_name ? paid_by_name : "N/A"}`}{" "}
                                {`on ${paid_on ? formatForConfirmation(paid_on) : "N/A"}`}
                            </em>
                        </div>
                    );
                },
            },
        },
    ];

    if (loading) {
        return <h1>Loading...</h1>;
    } else {
        return (
            <>
                <h1 css={header}>Expert Payments and Client Invoicing</h1>
                <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Payments Needed" {...a11yProps(0)} />
                            <Tab label="Payments Completed" {...a11yProps(1)} />
                            <Tab label="Survey Payments Needed" {...a11yProps(2)} />
                            <Tab label="Survey Payments Completed" {...a11yProps(3)} />
                            <Tab label="Needs Invoicing" {...a11yProps(4)} />
                            <Tab label="Invoices Sent" {...a11yProps(5)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <MUIDataTable data={needsPaymentInfo} columns={needsPaymentColumns} options={options} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <MUIDataTable data={hasPaidInfo} columns={hasPaidColumns} options={options} />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <MUIDataTable
                            data={surveyPaymentsNeedsInfo}
                            columns={surveyPaymentsNeedsColumns}
                            options={options}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <MUIDataTable
                            data={surveyPaymentsApprovedInfo}
                            columns={surveyPaymentsApprovedColumns}
                            options={options}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        <MUIDataTable
                            data={needsInvoicingData}
                            columns={needsInvoicingColumns}
                            options={expandableOptions}
                            components={components}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={5}>
                        <MUIDataTable
                            data={invoicesSentData}
                            columns={invoicesSentColumns}
                            options={expandableOptionsInvoicesSent}
                            components={components}
                        />
                    </TabPanel>
                </Box>
                {openLargeTextBoxDialog && (
                    <LargeTextBoxDialog
                        largeText={largeText}
                        openLargeTextBoxDialog={openLargeTextBoxDialog}
                        setOpenLargeTextBoxDialog={setOpenLargeTextBoxDialog}
                    />
                )}
                {openPaidAmntDialog && (
                    <PaidAmountDialog
                        openPaidAmntDialog={openPaidAmntDialog}
                        setOpenPaidAmntDialog={setOpenPaidAmntDialog}
                        dropdownState={dropdownState}
                        setDropdownState={setDropdownState}
                        paidAmntError={paidAmntError}
                        setPaidAmntError={setPaidAmntError}
                        confirmPaidAmnt={confirmPaidAmnt}
                        handleClosePaidAmntDialog={handleClosePaidAmntDialog}
                    />
                )}
                {openSurveyPaidAmntDialog && (
                    <PaidAmountDialog
                        openPaidAmntDialog={openSurveyPaidAmntDialog}
                        setOpenPaidAmntDialog={setOpenSurveyPaidAmntDialog}
                        dropdownState={dropdownState}
                        setDropdownState={setDropdownState}
                        paidAmntError={paidAmntError}
                        setPaidAmntError={setPaidAmntError}
                        confirmPaidAmnt={confirmSurveyPaidAmnt}
                        handleClosePaidAmntDialog={handleCloseSurveyPaidAmntDialog}
                    />
                )}
                {openAccountDetailsDialog && (
                    <AccountDetailsDialog
                        openAccountDetailsDialog={openAccountDetailsDialog}
                        setOpenAccountDetailsDialog={setOpenAccountDetailsDialog}
                        currentExpert={currentExpert}
                    />
                )}
            </>
        );
    }
};

export default PaymentRequest;
