import React, { useEffect } from "react";

// MUI - general
import { TextField } from "@mui/material";

// MUI - styles
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const authObj = {
    67291: true,
    3: true,
    5: true,
    95647: true,
    153383: true,
    309841: true,
    67281: true,
    340269: true,
    309842: true,
};

const SchedulingDuration = (props) => {
    // props
    const { callData, expertData, setCallData, error, user_id, handlerFuncs } = props;

    // destructuring
    const { expertDuration, clientDuration } = callData;

    // =========
    // PAGE LOAD
    // =========

    useEffect(() => {
        setCallData({
            ...callData,
            expertDuration: expertData.expertDuration,
            clientDuration: expertData.clientDuration,
        });
    }, [expertData]);

    // ============
    // HANDLE FUNCS
    // ============

    function handleCustomCharge(event) {
        console.log("====== helloooooo ======");
        handlerFuncs.handleCustomExpCharge(expertData);
    }

    function handleDurationChange(event) {
        const { name, value } = event.target;
        // validate input to remove decimals and make sure they can't start with a zero
        if (!value || (value[value.length - 1].match("[0-9]") && value[0].match("[1-9]"))) {
            setCallData({ ...callData, [name]: value });
        }
    }

    return (
        <>
            <TextField
                sx={{ width: "100%", marginBottom: "1rem", backgroundColor: "#fff" }}
                variant="outlined"
                fullWidth
                margin="normal"
                name="clientDuration"
                onChange={handleDurationChange}
                error={error.error && error.element === "clientDuration"}
                helperText={error.element === "clientDuration" ? error.msg : ""}
                label={"Client (Minutes)"}
                value={clientDuration}
            />

            <TextField
                sx={{ width: "100%", marginBottom: "1rem", backgroundColor: "#fff" }}
                variant="outlined"
                fullWidth
                margin="normal"
                name="expertDuration"
                onChange={handleDurationChange}
                error={error.error && error.element === "expertDuration"}
                helperText={error.element === "expertDuration" ? error.msg : ""}
                label={"Expert (Minutes)"}
                value={expertDuration}
            />

            {user_id in authObj && (
                <div
                    onClick={handleCustomCharge}
                    css={css`
                        font-size: 1rem;
                        font-weight: 500;
                        color: #8966ad;
                        &:hover {
                            cursor: pointer;
                            color: #7c3aed;
                        }
                    `}
                >{`Make a 'One-Off Payment' to this expert`}</div>
            )}
        </>
    );
};

export default SchedulingDuration;
