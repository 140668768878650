export const getDefaultSearchObj = (userId) => {
    // console.log(userId, " :: userId");
    const defaultSearchObj = {
        options: {
            checkbox: [
                {
                    label: "Current Employment",
                    name: "current_employment",
                    value: false,
                },
                {
                    label: "Former Employment",
                    name: "former_employment",
                    value: false,
                },
                {
                    label: "Terms Signed",
                    name: "terms_signed",
                    value: false,
                },
                {
                    label: "Disclosures",
                    name: "disclosures",
                    value: false,
                },
                {
                    label: "Remove Attached Experts",
                    name: "remove_proj_experts",
                    value: false,
                },
            ],
            radio: [
                {
                    name: "added_by",
                    label: "Added By",
                    currentValue: "0",
                    items: [
                        {
                            label: "Only Me",
                            value: userId,
                        },
                        {
                            label: "Anyone",
                            value: "0",
                        },
                    ],
                },
                {
                    name: "onboarded_by",
                    label: "Onboarded By",
                    currentValue: "0",
                    items: [
                        {
                            label: "Only Me",
                            value: userId,
                        },
                        {
                            label: "Anyone",
                            value: "0",
                        },
                    ],
                },
            ],
        },
        searchFields: {
            string: {
                keywords: [
                    { boolType: "and", active: false, value: "" },
                    { boolType: "or", active: false, value: "" },
                    { boolType: "not", active: false, value: "" },
                ],
                position: [
                    { boolType: "and", active: false, value: "" },
                    { boolType: "or", active: false, value: "" },
                    { boolType: "not", active: false, value: "" },
                ],
                companies: [
                    { boolType: "and", active: false, value: "" },
                    { boolType: "or", active: false, value: "" },
                    { boolType: "not", active: false, value: "" },
                ],
            },
            object: {
                tags: [
                    { boolType: "and", active: false, value: [] },
                    { boolType: "or", active: false, value: [] },
                    { boolType: "not", active: false, value: [] },
                ],
                state: [
                    { boolType: "and", active: false, value: [] },
                    { boolType: "or", active: false, value: [] },
                    { boolType: "not", active: false, value: [] },
                ],
                country: [
                    { boolType: "and", active: false, value: [] },
                    { boolType: "or", active: false, value: [] },
                    { boolType: "not", active: false, value: [] },
                ],
            },
            dsl: "",
        },
        filters: {
            expertRateSlider: {
                active: false,
                min: 1,
                currentMin: 200,
                max: 3000,
                currentMax: 800,
            },
        },
        metaData: {
            userId: userId,
            projectId: null,
        },
    };

    // console.log(defaultSearchObj, " :: defaultSearchObj");

    return defaultSearchObj;
};
