import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { authContext } from "../context/auth";
import makeStyles from "@mui/styles/makeStyles";
import axios from "axios";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
// import Slide from "@mui/material/Slide";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Warning from "@mui/icons-material/Warning";
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogTitle from "@mui/material/DialogTitle";
// import IconButton from "@mui/material/IconButton";

// style imports
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
// import { css } from "@emotion/react";
// icons
// import CloseIcon from "@mui/icons-material/Close";

import AutomatedLeads from "../components/project/AutomatedLeads";
import Comments from "../components/Comments";
import premiumOptions from "../data/premiumOptions";
import durationOptions from "../data/durationOptions";
import { basicDateTimeStamp, numberWithCommas } from "../Utils";
import Outreach from "../components/project/Outreach";
import OnboardScreen from "../components/project/OnboardScreen";
import Interested from "../components/project/Interested";
import Send from "../components/project/Send";
import Schedule from "../components/project/Schedule";
import AllAttached from "../components/project/AllAttached";
import AllCallLogs from "../components/project/AllCallLogs";
import DownloadCsvButton from "../components/project/utils/DownloadCsvButton";
import Impersonator from "../components/impersonation/Impersonator";
import OutreachTemplates from "../components/auto_outreach/Outreach";
import RealTimeUpdateTagManager from "../components/project/utils/RealTimeUpdateTagManager";
import AnglesCreationTool from "../components/project/AnglesCreationTool";
// import { now } from "moment";

// ======
// STYLES
// ======

const toolbarButtonStyles = {
    float: "left",
    marginRight: "10px",
    marginBottom: "10px",
    marginLeft: "0px",
    width: "13%",
    height: "3vh",
    fontSize: ".65rem",
};
const buttonStyle = {
    fontSize: ".75rem",
};
const reminderStyle = {
    backgroundColor: "#e5e0f3",
    color: "#523197",
    padding: "10px 15px",
    marginBottom: "5px",
    borderRadius: "5px",
    fontSize: "1rem",
};

const angleStyle = {
    backgroundColor: "#e5e0f3",
    color: "#523197",
    padding: "10px 15px",
    marginBottom: "5px",
    borderRadius: "5px",
    fontSize: "1rem",
    cursor: "pointer",
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(2),
        overflowX: "auto",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    paper: {
        padding: theme.spacing(3),
        backgroundColor: "#fafafa",
        textAlign: "left",
        color: theme.palette.text.secondary,
        fontSize: ".85rem",
        marginBottom: "1rem",
    },
    paperData: {
        color: "#000",
    },
    muted: {
        color: "#999",
    },
    headerContainer: {
        borderBottom: "5px solid #b6acd4",
        marginBottom: "0",
    },
    eyebrow: {
        fontSize: ".75rem",
        color: "#444",
        display: "block",
        textTransform: "uppercase",
    },
    beta: {
        color: "orange",
    },
    subtitle: {
        fontSize: "1rem",
        display: "block",
        marginBottom: ".75rem",
    },
    warning: {
        fontSize: "1rem",
        color: "orange",
    },
    heading: {
        fontSize: "1rem",
        color: "#523197",
    },
    headingSmall: {
        fontSize: ".85rem",
        color: "#523197",
    },
    openStatus: {
        color: "green",
    },
    metadata: {
        fontSize: ".85rem",
        color: "#444",
        marginBottom: ".25rem",
    },
    tagName: {
        fontSize: ".85rem",
        color: "#000",
        fontStyle: "italic",
    },
    metadataDescription: {
        fontSize: ".85rem",
        color: "#333",
        marginTop: "1rem",
        marginBottom: ".25rem",
    },
    descriptionBox: {
        backgroundColor: "#fff",
        padding: "1rem",
        borderRadius: "5px",
        border: "1px solid #ccc",
    },
    title: {
        fontSize: "2.25rem",
        padding: "0 0 .5rem 0",
        textTransform: "capitalize",
        display: "block",
        textAlign: "left",
        lineHeight: "1.4",
    },
    table: {
        minWidth: 750,
    },
    button: {
        margin: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(3),
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
}));

// ===========
// MUI HELPERS
// ===========

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function ProjectNext(props) {
    console.log("====== highest level render ======");
    // props
    const { history, match } = props;

    // =====
    // STATE
    // =====

    // constants
    const project_id = match.params.id;
    const DEFAULT_TAB_STATES = {
        automated: false,
        onboardscreener: false,
        outreach: false,
        interested: false,
        send: false,
        schedule: false,
        all: false,
        logs: false,
    };
    // access list
    const APPROVAL_ACCESS_LIST = [3, 67291, 153383, 95647, 309841, 67281, 340269, 309842];
    // 3 - dan c., 67291 - michale f., 153383 - victoria, 95647 - felix b., 309841 - Anjali, 67281 - Joe Riggio, 340269 - Brandon Orr, 309842 - Jon Krais

    // utils
    const classes = useStyles();
    const { auth } = useContext(authContext);
    const [todolist, setTodolistDisplay] = useState("none");

    // rest of state
    const [tabValue, setTabValue] = useState(0);
    const [calldownCount, setCalldownCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [load, setTableLoading] = useState(DEFAULT_TAB_STATES);
    const [automatedRecording, setAutomatedRecording] = useState("");
    const [data, setData] = useState({
        projectData: [],
        additionalProjectManagers: [],
        clientData: [],
        expertCount: [],
        projectTags: [],
        expertsCompleted: [],
        expertsScheduled: [],
        projectComments: [],
        surveyData: [],
        impersonationData: [],
    });
    const [selectedAngle, setSelectedAngle] = useState({});
    const [angleOutreachDisplay, setAngleOutreachDisplay] = useState("none");

    const [impersonatedUser, setImpersonatedUserData] = useState({
        impersonatedUser: {},
        isUserBeingImpersonated: false,
    });
    const [onboardScreenReminder, setOnboardScreenReminder] = useState({
        display: false,
        count: 0,
        message: "attached & ready for onboard/screening.",
    });
    const [outreachReminder, setOutreachReminder] = useState({
        display: false,
        count: 0,
        message: "invited that have not responded at all.",
    });
    const [interestedReminder, setInterestedReminder] = useState({
        display: false,
        count: 0,
        message: "invited that clicked interested but stopped.",
    });
    const [sendReminder, setSendReminder] = useState({
        display: false,
        count: 0,
        message: "fully screened & ready for your review.",
    });
    const [scheduleReminder, setScheduleReminder] = useState({
        display: false,
        count: 0,
        message: "experts waiting to be scheduled.",
    });
    const [anglesModal, setAnglesModal] = useState(false);

    // ============
    // HELPER FUNCS
    // ============

    const closeModals = () => {
        setAnglesModal(false);
    };

    const editProjectEvent = (id) => {
        props.history.push(`/project/edit/${project_id}`);
    };

    const setCommentsArray = (arr) => {
        if (typeof arr === "object") {
            setData({
                ...data,
                projectComments: arr,
            });
        }
    };

    const isPastDeadline = (deadline) => {
        const now = new Date();
        const deadlineDate = new Date(deadline);
        if (deadlineDate < now) {
            return true;
        }
        return false;
    };

    const handleOpenOpportunityCount = (count) => {
        console.log("HOW MANY OPEN OPPORTUNITIES? ", count);
        setCalldownCount(count);
    };

    const calculateCallsRemaining = (p, s) => {
        let callsRemaining = 0;
        const planned = p ? parseInt(p, 10) : 0;
        const scheduled = s ? parseInt(s.experts_scheduled, 10) : 0;

        if (planned > 0 && planned > scheduled) {
            callsRemaining = planned - scheduled;
        }
        return callsRemaining;
    };

    const parseImpersonationData = (impersonationData) => {
        for (const person of impersonationData) {
            if (person.person_id_of_impersonator === auth.data.id) {
                setImpersonatedUserData({ impersonatedUser: person, isUserBeingImpersonated: true });
            }
        }
    };

    const generateSnippet = (selected, responseEvent) => {
        console.log(
            selected.map((item) => item.id),
            " :: selected in generateSnippet"
        );
        axios
            .post(
                "/api/expert/expertSnippet",
                { expertList: selected.map((item) => item.id) },
                {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            )
            .then((result) => {
                if (result.data.data.length > 0) {
                    console.log(result.data.data, " :: /api/expert/expertSnippet RESPONSE DATA");
                    responseEvent(result.data.data);
                }
            })
            .catch((err) => {
                console.log(err, " :: something wrong with expertSnippet API call");
            });
    };

    const removeExpert = (selected, reloadEvent) => {
        const url = `/api/project/removeExpert/${project_id}`;
        const postData = {
            expert_ids: selected.map((item) => item.id),
            user_id: auth.data.id,
        };
        if (selected.length > 0) {
            console.log("WHO IS ACTUALLY READY TO DELETE: ", postData);
            if (window.confirm("Are you sure you want remove this person from the project?")) {
                axios
                    .post(url, postData, {
                        headers: {
                            "Content-Type": "application/json;charset=UTF-8",
                            "Access-Control-Allow-Origin": "*",
                        },
                    })
                    .then((result) => {
                        if (result.status === 200) {
                            console.log("Experts Removed? ", result);
                            reloadEvent(project_id);
                        } else {
                            console.log(`Good POST, but something went wrong: ${JSON.stringify(formData)}`);
                        }
                    })
                    .catch((result) => {
                        console.log("Something went wrong, API Error, 404 Error:", result);
                    });
            }
        } else {
            alert("You have not selected anyone to remove from the project.");
        }
    };

    // ============
    // HANDLE FUNCS
    // ============

    const handleTabChange = (event, newValue) => {
        // console.log("NEW VALUE: ", newValue);
        setTabValue(newValue);
    };

    const handleTableLoadingEvent = (name, value) => {
        setTableLoading({ ...DEFAULT_TAB_STATES, [name]: value });
    };

    async function saveAngles(anglesArray) {
        await axios
            .post("/api/project/now/saveProjectAngles", { project_id, angles: JSON.stringify(anglesArray) })
            .then((result) => {
                console.log("ProjectNext.js => saveAngles Function :: post response :: ", result);
                // Publish After Saving Angles...
                loadProject();
            })
            .catch((e) => {
                console.log(e, " :: e in saveProjectAngles");
            });
    }

    const confirmAngleTemplate = (outreachTemplates) => {
        const anglesArray = data.projectData.angles;
        if (
            window.confirm(
                "Are you sure you want to save this change? This will change all future outreach email templates associated with this screener."
            )
        ) {
            console.log("DID WE GET THE RIGHT SET OF TEMPLATES? ", anglesArray);
            // Convert this is a full ANGLE OBJECT before saving...
            // We need screener_id, tag_id, and outreach_templates...
            // Find the template in the array and update it with the selected state object...
            anglesArray.every((item) => {
                if (
                    parseInt(item.screener_id, 10) === parseInt(selectedAngle.screener_id, 10) &&
                    parseInt(item.tag_id, 10) === parseInt(selectedAngle.tag_id, 10)
                ) {
                    item.outreach_templates = outreachTemplates;
                    console.log("STEP 1 - Find and set item with modified templates: ", item);
                    return false;
                }
                return true;
            });
            saveAngles(anglesArray);
        }
    };

    const removeAngle = (tag_id, screener_id, angle_name) => {
        console.log("REMOVE THIS ANGLE: ", tag_id, screener_id);
        if (
            window.confirm(
                `Are you sure you want to remove the ${angle_name} Angle? This will remove all custom outreach email templates associate with the screener.`
            )
        ) {
            const anglesArray = data.projectData.angles.filter(
                (angle) =>
                    parseInt(angle.screener_id, 10) !== parseInt(screener_id, 10) &&
                    parseInt(angle.tag_id, 10) !== parseInt(tag_id, 10)
            );
            console.log("DID IT REMOVE? ", anglesArray);
            saveAngles(anglesArray);
            setAngleOutreachDisplay("none");
        }
    };

    // =========
    // PAGE LOAD
    // =========

    async function loadTabCounts() {
        setLoading(true);
        await axios
            .get(`/api/project/now/findProjectCounts/${project_id}`, {
                email: "aanand@email.com",
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    const data = result.data.data;
                    console.log("TAB COUNTS: ", data);
                    if (data) {
                        setLoading(false);
                        if (data.onboardScreenerCount > 0) {
                            setOnboardScreenReminder({
                                ...onboardScreenReminder,
                                count: data.onboardScreenerCount,
                                display: true,
                            });
                        }
                        if (data.outreachCount > 0) {
                            setOutreachReminder({
                                ...outreachReminder,
                                count: data.outreachCount,
                                display: true,
                            });
                        }
                        if (data.interestedCount > 0) {
                            setInterestedReminder({
                                ...interestedReminder,
                                count: data.interestedCount,
                                display: true,
                            });
                        }
                        if (data.sendCount > 0) {
                            setSendReminder({
                                ...sendReminder,
                                count: data.sendCount,
                                display: true,
                            });
                        }
                        if (data.scheduleCount > 0) {
                            setScheduleReminder({
                                ...scheduleReminder,
                                count: data.scheduleCount,
                                display: true,
                            });
                        }
                        setTodolistDisplay("block");
                    }
                }
            });
    }

    async function loadProject() {
        await axios
            .get(`/api/project/now/findProjectSpecificData/${project_id}`, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((response) => {
                if (response.data && response.data.data && response.data.data.data.length > 0) {
                    const projectData = response.data.data.data[0].projectData[0];
                    const additionalProjectManagers = response.data.data.data[0].additionalProjectManagers;
                    const clientData = response.data.data.data[0].clientData[0];
                    const expertCount = response.data.data.data[0].expertCount[0];
                    const projectTags = response.data.data.data[0].projectTags;
                    const expertsCompleted = response.data.data.data[0].expertsCompleted[0];
                    const expertsScheduled = response.data.data.data[0].expertsScheduled[0];
                    const projectComments = response.data.data.data[0].projectComments;
                    const surveyData = response.data.data.data[0].surveyData;
                    const impersonationData = response.data.data.data[0].impersonationData;
                    console.log("PAGE LOAD DATA: ", response.data.data.data[0]);

                    console.log(impersonationData, " :: impersonationData");
                    parseImpersonationData(impersonationData);

                    setData({
                        projectData,
                        additionalProjectManagers,
                        clientData,
                        expertCount,
                        projectTags,
                        expertsCompleted,
                        expertsScheduled,
                        projectComments,
                        surveyData,
                        impersonationData,
                    });
                    setLoading(false);
                    setAngleOutreachDisplay("none");
                    // Load onClick instead
                    // loadTabCounts();
                }
            });
    }

    async function saveOutreachTemplates(templates) {
        console.log("STEP 3 - Did we enter the save function with the right array? ", templates);
        const outreach_templates = JSON.stringify(templates);
        await axios
            .post("/api/project/now/saveOutreachTemplates", { project_id, outreach_templates })
            .then((result) => {
                console.log("STEP 4 - Successful post result :: ", result);
                loadProject();
            })
            .catch((e) => {
                console.log(e, " :: e in saveOutreachTemplates");
            });
    }

    const openCreateAnglesModal = () => {
        setAnglesModal(true);
    };

    const selectAngleToEdit = (tag_id, screener_id) => {
        const selected = data.projectData.angles.find(
            (a) =>
                parseInt(a.tag_id, 10) === parseInt(tag_id, 10) &&
                parseInt(a.screener_id, 10) === parseInt(screener_id, 10)
        );
        console.log("Edit this Angle: ", selected);
        // Find the angle in the array and update it with the active state object...
        setSelectedAngle(selected);
        setAngleOutreachDisplay("block");
    };

    useEffect(() => {
        loadProject();
    }, []);

    return (
        <div>
            <Backdrop
                // transitionDuration={500}
                className={classes.backdrop}
                open={loading}
                onClick={() => {
                    setLoading(false);
                }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Button
                        sx={toolbarButtonStyles}
                        // onClick={goToSurveyCreate}
                        href={`/project/survey/new/${project_id}`}
                        fullWidth
                        size="small"
                        variant="contained"
                        color="primary"
                    >
                        Build Screener
                    </Button>
                    <Button
                        sx={toolbarButtonStyles}
                        // onClick={searchExperts}
                        href={`/search?pid=${project_id}`}
                        fullWidth
                        size="small"
                        variant="contained"
                        color="primary"
                    >
                        Search
                    </Button>

                    <Button
                        sx={toolbarButtonStyles}
                        // onClick={duplicateProject}
                        href={`/project/create?pid=${project_id}`}
                        fullWidth
                        size="small"
                        variant="contained"
                        color="primary"
                    >
                        Copy This
                    </Button>

                    <Button
                        sx={toolbarButtonStyles}
                        // onClick={goToScheduling}
                        href={`/project/schedule/${project_id}`}
                        fullWidth
                        size="small"
                        variant="contained"
                        color="primary"
                    >
                        Schedule
                    </Button>

                    <Button
                        sx={toolbarButtonStyles}
                        // onClick={goToEmailDashboard}
                        href={`/project/emails/${project_id}`}
                        fullWidth
                        size="small"
                        variant="contained"
                        color="primary"
                    >
                        Emails
                    </Button>

                    <Button
                        sx={toolbarButtonStyles}
                        // onClick={goToRespondentView}
                        href={`/project/respondents/${project_id}`}
                        fullWidth
                        size="small"
                        variant="contained"
                        color="primary"
                    >
                        Screeners
                    </Button>

                    <Button
                        sx={toolbarButtonStyles}
                        // onClick={goToPricing}
                        href={`/project/pricing/${project_id}`}
                        fullWidth
                        size="small"
                        variant="contained"
                        color="primary"
                    >
                        Payments
                    </Button>

                    <Button
                        sx={toolbarButtonStyles}
                        onClick={async () => {
                            const toggle = automatedRecording == "On" ? false : true;
                            const url = `/api/project/toggleSendRecordings?id=${project_id}&toggle=${toggle}`;
                            await axios
                                .get(url)
                                .then((res) => {
                                    const msg = toggle
                                        ? "Client will recieve transcription automatically after calls"
                                        : "Client will not recieve transcription after calls";
                                    setAutomatedRecording(toggle ? "On" : "Off");
                                })
                                .catch((err) => {
                                    console.log(err, ":: toggleSendRecordings Error");
                                });
                        }}
                        fullWidth
                        size="small"
                        variant="contained"
                        color="primary"
                    >
                        Transcripts: &nbsp;
                        {automatedRecording == "On" ? (
                            <Box sx={{ color: "#00C9AB" }}>{automatedRecording}</Box>
                        ) : (
                            <Box sx={{ color: "#FF9774" }}>{automatedRecording}</Box>
                        )}
                    </Button>
                    <DownloadCsvButton toolbarButtonStyles={toolbarButtonStyles} project_id={project_id}>
                        Export CSV
                    </DownloadCsvButton>
                    <Button
                        sx={toolbarButtonStyles}
                        // onClick={goToLegacyProjectView}
                        href={`/project/legacy/${project_id}`}
                        fullWidth
                        size="small"
                        variant="contained"
                        color="primary"
                    >
                        Legacy
                    </Button>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <div className={classes.headerContainer}>
                        <strong className={classes.eyebrow}>
                            {data.projectData.deadline && !isPastDeadline(data.projectData.deadline) ? (
                                <>
                                    DEADLINE:{" "}
                                    <span style={{ color: "green" }}>
                                        {basicDateTimeStamp(data.projectData.deadline)}
                                    </span>
                                </>
                            ) : (
                                <>
                                    <span style={{ color: "red" }}>
                                        {data.projectData.deadline
                                            ? `PAST DEADLINE: ${basicDateTimeStamp(data.projectData.deadline)}`
                                            : "DEADLINE NOT SET"}
                                    </span>
                                </>
                            )}{" "}
                            || {data.projectData.project_type} || STATUS:{" "}
                            {data.projectData.project_status_id == 1 ? (
                                <span className={classes.openStatus}>{data.projectData.project_status}</span>
                            ) : (
                                <span>{data.projectData.project_status}</span>
                            )}{" "}
                        </strong>
                        <Button onClick={editProjectEvent} className={classes.title}>
                            {data.projectData.title}
                        </Button>
                        {data.projectData.topic && (
                            <div style={{ fontSize: "1.25rem", color: "#8966AD", marginBottom: "0.75rem" }}>
                                Project Topic: <strong>{data.projectData.topic}</strong>
                            </div>
                        )}
                        <div className={classes.subtitle}>
                            ID: <strong className={classes.paperData}>{project_id}</strong> | Client:{" "}
                            <strong className={classes.paperData}>{`${data.projectData.client_name}`}</strong> | Code:{" "}
                            <strong className={classes.paperData}>{data.projectData.case_code}</strong> | Planned:{" "}
                            <strong className={classes.paperData}>{data.projectData.calls_planned}</strong> | Attached:{" "}
                            <strong className={classes.paperData}>
                                {data.expertCount.experts_attached_to_project}
                            </strong>{" "}
                            | Scheduled:{" "}
                            <strong className={classes.paperData}>
                                {data.expertsScheduled ? data.expertsScheduled.experts_scheduled : 0}
                            </strong>{" "}
                            | Completed:{" "}
                            <strong className={classes.paperData}>
                                {data.expertsCompleted ? data.expertsCompleted.experts_completed : 0}
                            </strong>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Tabs
                        // className={classes.projBar}
                        value={tabValue}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab label="Details" key={0} />
                        <Tab label="Auto-Status" onClick={() => handleTableLoadingEvent("automated", true)} key={1} />
                        <Tab onClick={() => handleTableLoadingEvent("onboardscreener", true)} label="Invite" key={2} />
                        <Tab onClick={() => handleTableLoadingEvent("outreach", true)} label="Follow-Up" key={3} />
                        <Tab
                            label={
                                <Badge color="action" badgeContent={calldownCount}>
                                    Call Down
                                </Badge>
                            }
                            onClick={() => handleTableLoadingEvent("interested", true)}
                            key={4}
                        />
                        <Tab onClick={() => handleTableLoadingEvent("send", true)} label="Review" key={5} />
                        <Tab onClick={() => handleTableLoadingEvent("schedule", true)} label="Schedule" key={6} />
                        <Tab onClick={() => handleTableLoadingEvent("all", true)} label="Find" key={7} />
                        <Tab onClick={() => handleTableLoadingEvent("logs", true)} label="Call Logs" key={8} />
                    </Tabs>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <AnglesCreationTool
                        user={auth.data}
                        project_id={project_id}
                        open={anglesModal}
                        closeEvent={closeModals}
                        tags={data.projectTags ? data.projectTags : []}
                        screeners={data.surveyData ? data.surveyData : []}
                        templates={data.projectData.outreach_templates ? data.projectData.outreach_templates : []}
                        angles={data.projectData.angles ? data.projectData.angles : []}
                        successEvent={loadProject}
                    />
                    <TabPanel value={tabValue} index={0}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <Paper className={classes.paper}>
                                    <strong className={classes.heading}>
                                        Hi {auth.data.fname}, please check what requires your immediate attention:
                                    </strong>
                                    <br />
                                    <br />
                                    <Button
                                        sx={buttonStyle}
                                        onClick={loadTabCounts}
                                        fullWidth
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                    >
                                        Fetch Project TO-DO List
                                    </Button>
                                    <br />
                                    <br />
                                    <Box display={todolist}>
                                        {loading ? (
                                            <Backdrop
                                                className={classes.backdrop}
                                                open={loading}
                                                onClick={() => {
                                                    setLoading(false);
                                                }}
                                            >
                                                <CircularProgress color="inherit" />
                                            </Backdrop>
                                        ) : (
                                            <>
                                                {calculateCallsRemaining(
                                                    data.projectData.calls_planned,
                                                    data.expertsScheduled
                                                ) > 0 ? (
                                                    <>
                                                        <div style={reminderStyle}>
                                                            <Warning />{" "}
                                                            {`${calculateCallsRemaining(
                                                                data.projectData.calls_planned,
                                                                data.expertsScheduled
                                                            )} available calls remain for this project.`}
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div style={reminderStyle}>
                                                            <Warning /> Please follow-up with the client for invoicing.
                                                        </div>
                                                        <div style={reminderStyle}>
                                                            <Warning /> Please follow-up with experts for payments.
                                                        </div>
                                                    </>
                                                )}
                                                {onboardScreenReminder.display && (
                                                    <>
                                                        <div style={reminderStyle}>
                                                            <Warning />{" "}
                                                            {`${onboardScreenReminder.count} ${onboardScreenReminder.message}`}{" "}
                                                            {/* <Button
                                                                onClick={() =>
                                                                    handleTableLoadingEvent("onboardscreener", true)
                                                                }
                                                            >
                                                                Click Here
                                                            </Button> */}
                                                        </div>
                                                    </>
                                                )}
                                                {outreachReminder.display && (
                                                    <>
                                                        <div style={reminderStyle}>
                                                            <Warning />{" "}
                                                            {`${outreachReminder.count} ${outreachReminder.message}`}
                                                        </div>
                                                    </>
                                                )}
                                                {interestedReminder.display && (
                                                    <>
                                                        <div style={reminderStyle}>
                                                            <Warning />{" "}
                                                            {`${interestedReminder.count} ${interestedReminder.message}`}
                                                        </div>
                                                    </>
                                                )}
                                                {sendReminder.display && (
                                                    <>
                                                        <div style={reminderStyle}>
                                                            <Warning />{" "}
                                                            {`${sendReminder.count} ${sendReminder.message}`}
                                                        </div>
                                                    </>
                                                )}
                                                {scheduleReminder.display && (
                                                    <>
                                                        <div style={reminderStyle}>
                                                            <Warning />{" "}
                                                            {`${scheduleReminder.count} ${scheduleReminder.message}`}
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </Box>
                                </Paper>
                                <Paper className={classes.paper}>
                                    <strong className={classes.heading}>Project Specifications:</strong>
                                    <br />
                                    <br />
                                    {data.projectData.price_per_unit > 0 && data.projectData.project_type_id !== 8 && (
                                        <div className={classes.metadata}>
                                            Price Per Unit:{" "}
                                            <strong className={classes.paperData}>
                                                ${numberWithCommas(data.projectData.price_per_unit)}
                                            </strong>
                                        </div>
                                    )}
                                    {data.projectData.expert_flat_rate > 0 && data.projectData.project_type_id === 8 && (
                                        <div className={classes.metadata}>
                                            Survey Flat Fee:{" "}
                                            <strong className={classes.paperData}>
                                                ${numberWithCommas(data.projectData.expert_flat_rate)}
                                            </strong>
                                        </div>
                                    )}
                                    <div className={classes.metadata}>
                                        Primary Client Contact:{" "}
                                        <strong
                                            className={classes.paperData}
                                        >{`${data.clientData.firstname} ${data.clientData.lastname}`}</strong>
                                    </div>
                                    <div className={classes.metadata}>
                                        Created:{" "}
                                        <strong className={classes.paperData}>
                                            {basicDateTimeStamp(data.projectData.created_on)}
                                            <span className={classes.muted}>
                                                {" "}
                                                (UPDATED: {basicDateTimeStamp(data.projectData.updated_on)})
                                            </span>
                                        </strong>
                                    </div>
                                    <div className={classes.metadata}>
                                        Project Manager:{" "}
                                        <strong
                                            className={classes.paperData}
                                        >{`${data.projectData.project_manager_fname} ${data.projectData.project_manager_lname}`}</strong>
                                    </div>
                                    {data.additionalProjectManagers.length > 0 && (
                                        <div className={classes.metadata}>
                                            Additional PMs:{" "}
                                            {data.additionalProjectManagers.map((item, index) => {
                                                return (
                                                    <span key={index}>
                                                        <strong
                                                            className={classes.paperData}
                                                        >{`${item.fname} ${item.lname}`}</strong>
                                                        <span>
                                                            {index < data.additionalProjectManagers.length - 1 && ", "}
                                                        </span>
                                                    </span>
                                                );
                                            })}
                                        </div>
                                    )}
                                    <div className={classes.metadata}>
                                        Priority:{" "}
                                        <strong className={classes.paperData}>
                                            {data.projectData.project_priority}
                                        </strong>
                                    </div>
                                    {data.projectData.custom_charge > 0 && (
                                        <div className={classes.metadata}>
                                            Custom Charge (Cost Plus):{" "}
                                            <strong className={classes.paperData}>
                                                {data.projectData.custom_charge} USD /{" "}
                                                {data.projectData.custom_hourly_rate}
                                            </strong>
                                        </div>
                                    )}
                                    {parseFloat(data.projectData.unit_multiplier) > 1.0 && (
                                        <div className={classes.metadata}>
                                            Unit Multiplier (Cost Plus):{" "}
                                            <strong className={classes.paperData}>
                                                {data.projectData.unit_multiplier}
                                            </strong>
                                        </div>
                                    )}
                                    {data.projectData.custom_hourly_rate > 1.0 && (
                                        <div className={classes.metadata}>
                                            Custom Hourly Multiplier:{" "}
                                            <strong className={classes.paperData}>
                                                {data.projectData.custom_hourly_rate}
                                            </strong>
                                        </div>
                                    )}
                                    {data.projectData.grace_period && data.projectData.grace_period > 0 && (
                                        <div className={classes.metadata}>
                                            Grace Period:{" "}
                                            <strong className={classes.paperData}>
                                                {data.projectData.grace_period}
                                            </strong>
                                        </div>
                                    )}
                                    {data.projectData.premium_pricing_model_id &&
                                        data.projectData.premium_pricing_model_id > 0 && (
                                            <div className={classes.metadata}>
                                                Price Model:{" "}
                                                <strong className={classes.paperData}>
                                                    {
                                                        premiumOptions.find(
                                                            (p) => p.value === data.projectData.premium_pricing_model_id
                                                        ).label
                                                    }
                                                </strong>
                                            </div>
                                        )}
                                    {data.projectData.duration_model_id && data.projectData.duration_model_id > 0 && (
                                        <div className={classes.metadata}>
                                            Duration Model:{" "}
                                            <strong className={classes.paperData}>
                                                {
                                                    durationOptions.find(
                                                        (p) => p.value === data.projectData.duration_model_id
                                                    ).label
                                                }
                                            </strong>
                                        </div>
                                    )}
                                    {data.projectData.fair_market_value > 0 && (
                                        <div className={classes.metadata}>
                                            Fair Market Value Cap:{" "}
                                            <strong className={classes.paperData}>
                                                ${numberWithCommas(data.projectData.fair_market_value)}
                                            </strong>
                                        </div>
                                    )}
                                    {data.projectData.budget > 0 && (
                                        <div className={classes.metadata}>
                                            Corporate Budget:{" "}
                                            <strong className={classes.paperData}>
                                                ${numberWithCommas(data.projectData.budget)}
                                            </strong>
                                        </div>
                                    )}
                                    {data.projectData.description && (
                                        <div className={classes.metadataDescription}>
                                            Description (HTML Formatted/Customer facing): <br />
                                            <br />
                                            <div
                                                className={classes.descriptionBox}
                                                dangerouslySetInnerHTML={{
                                                    __html: data.projectData.description,
                                                }}
                                            />
                                        </div>
                                    )}
                                    <br />
                                    <Button variant="contained" onClick={editProjectEvent}>
                                        EDIT PROJECT DETAILS
                                    </Button>
                                </Paper>
                                <Paper className={classes.paper}>
                                    <strong className={classes.heading}>Project Comments:</strong>
                                    <br />
                                    <br />
                                    <Comments
                                        // state_manager={_state}
                                        user={auth.data}
                                        object_type="Project"
                                        object_id={project_id}
                                        formatDate={basicDateTimeStamp}
                                        setComments={setCommentsArray}
                                        comments={data.projectComments}
                                        height="auto"
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Paper className={classes.paper}>
                                    {data.projectTags.length > 0 && (
                                        <div className={classes.metadata}>
                                            <strong className={classes.heading}>Project Tags:</strong>
                                            <br />
                                            <br />
                                            {!loading && (
                                                <RealTimeUpdateTagManager
                                                    projectTags={data.projectTags}
                                                    project_id={project_id}
                                                />
                                            )}
                                            {/* {data.projectTags.map((tag, index) => {
                                                return (
                                                    <span key={`${tag.id}_${index}`}>
                                                        <span className={classes.tagName}>{`${tag.name}`}</span>
                                                        <span>{index < data.projectTags.length - 1 && ", "}</span>
                                                    </span>
                                                );
                                            })} */}
                                        </div>
                                    )}
                                </Paper>
                                <Paper className={classes.paper}>
                                    {data.surveyData.length > 0 ? (
                                        <div className={classes.metadata}>
                                            <strong className={classes.heading}>Project Screeners:</strong>
                                            <br />
                                            <br />
                                            {data.surveyData.map((s, index) => {
                                                return (
                                                    <div key={`${s.id}_${index}`}>
                                                        {!s.published ? (
                                                            <Link
                                                                style={{ color: "#8966AD" }}
                                                                to={`/survey/view/${s.id}`}
                                                            >{`${s.id} - ${s.title}`}</Link>
                                                        ) : (
                                                            <span style={{ color: "#999" }}>
                                                                {`${s.id} - ${s.title}`} (published)
                                                            </span>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ) : (
                                        <>
                                            <strong className={classes.warning}>
                                                WARNING: No screeners created yet
                                            </strong>
                                            <p className={classes.metadata}>
                                                Please note: Automated outreach will not work until at least one
                                                screener is created and published.
                                            </p>
                                        </>
                                    )}
                                </Paper>
                                <Paper className={classes.paper}>
                                    <div className={classes.metadata}>
                                        <strong className={classes.heading}>Project Angles:</strong>
                                        <br />
                                        <br />
                                        {data.projectTags.length > 1 && data.surveyData.length > 1 && (
                                            <Button onClick={openCreateAnglesModal}>Add New</Button>
                                        )}
                                        {data.projectData.angles && data.projectData.angles.length > 0 && (
                                            <>
                                                {data.projectData.angles.map((item, index) => {
                                                    return (
                                                        <div
                                                            key={`${item.tag_id}_${item.screener_id}`}
                                                            onClick={() =>
                                                                selectAngleToEdit(item.tag_id, item.screener_id)
                                                            }
                                                            style={angleStyle}
                                                        >
                                                            {item.tag_name} (w/ Screener - {item.screener_id})
                                                        </div>
                                                    );
                                                })}
                                                <Box
                                                    display={angleOutreachDisplay}
                                                    style={{ clear: "both", overflow: "hidden", paddingTop: "20px" }}
                                                >
                                                    <OutreachTemplates
                                                        user={auth.data}
                                                        angle={selectedAngle}
                                                        data={selectedAngle.outreach_templates}
                                                        changeEvent={confirmAngleTemplate}
                                                    />
                                                    {APPROVAL_ACCESS_LIST.includes(auth.data.id) && (
                                                        <Button
                                                            onClick={() =>
                                                                removeAngle(
                                                                    selectedAngle.tag_id,
                                                                    selectedAngle.screener_id,
                                                                    selectedAngle.tag_name
                                                                )
                                                            }
                                                        >
                                                            Delete this Angle
                                                        </Button>
                                                    )}
                                                </Box>
                                            </>
                                        )}
                                    </div>
                                </Paper>
                                <Paper className={classes.paper} style={{ overflow: "hidden" }}>
                                    <strong className={classes.heading}>Impersonation/Automation:</strong>
                                    <br />
                                    <br />
                                    <Impersonator projectId={project_id} />
                                    {/* {data.impersonationData.length > 0 ? (
                                        <div className={classes.metadata}>
                                            <strong className={classes.heading}>Impersonation/Automation:</strong>
                                            <br />
                                            <br />
                                            {data.impersonationData.map((item, index) => {
                                                return (
                                                    <div key={`${item.person_id_of_impersonator}_${index}`}>
                                                        <span style={{ color: "#000" }}>
                                                            {`*** ${item.impersonator_name}'s outreach will send as: ${item.impersonated_name} ***`}
                                                        </span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ) : (
                                        <>
                                            <strong className={classes.warning}>
                                                WARNING: No impersonation pairs set up yet
                                            </strong>
                                            <p className={classes.metadata}>
                                                Please note: Automated outreach will not work unless impersonation pairs
                                                are set up.
                                            </p>
                                            <Impersonator projectId={project_id} />
                                        </>
                                    )} */}
                                    <br />
                                    <h3 className={classes.headingSmall}>OUTREACH TEMPLATES:</h3>
                                    {data.projectData.outreach_templates &&
                                        data.projectData.outreach_templates.length > 0 && (
                                            <>
                                                {data.projectData.outreach_templates.map((t) => {
                                                    return (
                                                        <div key={`${t.type}_${t.index}`}>
                                                            <strong
                                                                className={classes.headingSmall}
                                                            >{`${t.type} ${t.index}:`}</strong>{" "}
                                                            {t.updated !== "" ? (
                                                                <span>CONFIRMED</span>
                                                            ) : (
                                                                <strong className={classes.beta}>
                                                                    *** Not Yet Confirmed ***
                                                                </strong>
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                                <br />
                                                <OutreachTemplates
                                                    data={data.projectData.outreach_templates}
                                                    user={auth.data}
                                                    changeEvent={saveOutreachTemplates}
                                                />
                                            </>
                                        )}
                                </Paper>
                                <Paper className={classes.paper}>
                                    {data.projectData.email_copy ? (
                                        <div className={classes.metadata}>
                                            <strong className={classes.heading}>Private Description/Email Copy:</strong>
                                            <br />
                                            <br />
                                            <div
                                                className={classes.descriptionBox}
                                                dangerouslySetInnerHTML={{
                                                    __html: data.projectData.email_copy,
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            <strong className={classes.warning}>
                                                MISSING: No private description/email copy available
                                            </strong>
                                            <p className={classes.metadata}>
                                                Please add the project details/email copy to this project.
                                            </p>
                                        </>
                                    )}
                                </Paper>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                {load.automated && <AutomatedLeads project_id={project_id} history={history} />}
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                {load.onboardscreener && (
                                    <OnboardScreen
                                        generateSnippetEvent={generateSnippet}
                                        project_id={project_id}
                                        // handleReminder={handleOnboardScreenerReminder}
                                        projectData={data.projectData}
                                        projectTags={data.projectTags}
                                        projectScreeners={data.surveyData}
                                        impersonationData={impersonatedUser}
                                        history={history}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={tabValue} index={3}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                {load.outreach && (
                                    <Outreach
                                        generateSnippetEvent={generateSnippet}
                                        project_id={project_id}
                                        // handleReminder={handleOutreachReminder}
                                        projectData={data.projectData}
                                        projectTags={data.projectTags}
                                        projectScreeners={data.surveyData}
                                        impersonationData={impersonatedUser}
                                        history={history}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={tabValue} index={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <Interested
                                    generateSnippetEvent={generateSnippet}
                                    project_id={project_id}
                                    handleReminder={handleOpenOpportunityCount}
                                    projectTags={data.projectTags}
                                    projectScreeners={data.surveyData}
                                    history={history}
                                />
                                {/* {load.interested && (
                                    <Interested
                                        generateSnippetEvent={generateSnippet}
                                        project_id={project_id}
                                        projectTags={data.projectTags}
                                        projectScreeners={data.surveyData}
                                        history={history}
                                    />
                                )} */}
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={tabValue} index={5}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                {load.send && (
                                    <Send
                                        project_id={project_id}
                                        // handleReminder={handleSendReminder}
                                        projectTags={data.projectTags}
                                        projectScreeners={data.surveyData}
                                        history={history}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={tabValue} index={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                {load.schedule && (
                                    <Schedule
                                        generateSnippetEvent={generateSnippet}
                                        project_id={project_id}
                                        // handleReminder={handleScheduleReminder}
                                        projectTags={data.projectTags}
                                        history={history}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={tabValue} index={7}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                {load.all && (
                                    <AllAttached
                                        generateSnippetEvent={generateSnippet}
                                        project_id={project_id}
                                        projectTags={data.projectTags}
                                        projectScreeners={data.surveyData}
                                        history={history}
                                        removeExpertsEvent={removeExpert}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={tabValue} index={8}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                {load.logs && <AllCallLogs project_id={project_id} />}
                            </Grid>
                        </Grid>
                    </TabPanel>
                </Grid>
            </Grid>
        </div>
    );
}
export default ProjectNext;
