import React from "react";

function Messaging(props) {
    function randomSelect(items) {
        return items[Math.floor(Math.random() * items.length)];
    }

    const confirmations = [
        "Slappa-da-bayyse! It Saved!",
        "EEEOOOWWW! It Saved!",
        "Oh my God, the click and save, works every time!",
        "Good Save! And May the Odds Be Ever in Your Favor.",
        `${props.user.fname}, I think we are really starting to have a connection. A Successful CRM connection that is!`,
        `${props.user.fname}, there is one more rose left. If you want it, you gotta keep saving!`,
        "In This Game, A Good Save Represents Your Life. When It Doesn't Save, You're Gone, Luckily it SAVED!",
        "Saved! The Tribe Has Spoken",
        "Congratulations! You're going to hollywood!",
        "SUCCESS! I'm in a glass case of emotion!",
        "BOOM! The Dishes are Dooonnnee!",
        `Get in ${props.user.fname}, We're going Shopping! Because It Saved!`,
        "Success! Whatever, I'm getting cheese fries",
        "It Saved! Did we just become best friends?",
        "If you save it, he will come. It just saved.",
        "Ob-la-di, Ob-la-da, Oh-oh-oh-oh-oh it Saved!",
        `Oh my god, ${props.user.fname} ${props.user.lname}! I love your work!`,
        "It's so fetch! Saved!",
        "Successful AF!!!",
        "So Woke! So Saved!",
        "Groovy like a drive-in movie! Successful Save!",
        "BooYah!!! Saved!",
        "Totes Awesome, Saved!",
        "Joy To The World!",
        "A Raging Success!",
        "A Wonderfully Epic Save!",
        "Straight Fire! Save Successful!",
        "SAVED! p.s. Love your shoes!",
        "Pure Butter! Smooth Success!",
        "A Yuge Success!",
        "Holy Crap, It Worked!",
        `The ${props.type} Has Been Successfully Saved/Updated.`,
        "It Feels Great Baby! Saved!",
        "Boom Shaka-Laka! You're on Fire!",
        `Steak Sauce! ${props.type} Saved!`,
        "Hells Bells! You did it!",
        "Hip Hop hooray, hey, ho! Saved!",
        "Hooray! Let's Celebrate With Some Chick Fil-A!",
        "SAVE up jump the boogie to the rhythm of the boogie the beat!",
        `More ${props.type}s! More ${props.type}s! We Need More ${props.type}s!`,
        `A ${props.type} Saved, Is A ${props.type} Earned!`,
        `Koo-koo-ka-choo, ${props.user.fname} ${props.user.lname}! Successful Save!`,
        `Keep Up The Good Work ${props.user.fname}!`,
        `Congratulations ${props.user.fname}! You won 1 million dollars! Just kiddin, keep workin!`,
        `Crushing It ${props.user.fname}! Another ${props.type} Saved!`,
        `I'm not like a regular ${props.type}. I'm a cool ${props.type}.`,
        `${props.user.fname}, Will you accept this rose? JK, this ain't the Bachelor...you'll have to settle for ${props.type} Saved!`,
    ];

    return <span>{randomSelect(confirmations)}</span>;
}
export default Messaging;
