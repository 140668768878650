import React, { useState, useEffect, useCallback } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import AddIcon from "@mui/icons-material/Add";
import Forward from "@mui/icons-material/Forward";
import Check from "@mui/icons-material/CheckCircleOutline";
import Warning from "@mui/icons-material/SentimentVeryDissatisfied";
import Visibility from "@mui/icons-material/Visibility";
import { extractNameString, extractIndex } from "../../Utils";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    margin: {
        margin: theme.spacing(1),
    },
    questionContainer: {
        background: "linear-gradient(-60deg, #ffffff, #fafafa, #f5f5f5, #f2f2f2)",
        // border: "1px solid #f1f1f1",
        borderRadius: "4px",
        padding: "20px",
    },
    instructions: {
        marginBottom: "15px",
        color: "#325C55",
        fontSize: ".85rem",
    },
    errorBox: {
        backgroundColor: "#fff4e5",
        border: "1px solid #663c00",
        borderRadius: "4px",
        padding: "8px",
        marginBottom: "20px",
        color: "#663c00",
        fontSize: ".85rem",
    },
    errorHeading: {
        color: "#663c00",
        textAlign: "center",
        fontSize: "1.25rem",
    },
    stepHeading: {
        backgroundColor: "#eaeaea",
        borderRadius: "3px",
        color: "#444",
        padding: "5px 10px",
        fontSize: ".8rem",
    },
    answerField: {
        backgroundColor: "#ffffff",
        border: "1px solid #eaeaea",
        borderRadius: "3px",
        color: "#000",
        padding: "20px",
        fontSize: "1rem",
        marginTop: "15px",
        marginBottom: "15px",
    },
    remove: {
        color: "#3c8864",
        textDecoration: "none",
        fontSize: ".75rem",
        fontWeight: "bold",
        background: "none",
        float: "right",
    },
    actionButtons: {
        borderTop: "4px solid #eaeaea",
        marginTop: "80px",
        paddingTop: "20px",
    },
    editorWrapper: {
        border: "1px solid #EAEAEA",
        borderRadius: "2px",
        padding: "12px",
        backgroundColor: "#fff",
    },
}));

function FreeText(props) {
    const { match, history } = props;
    const classes = useStyles();
    const questionLabels = [...props.data.answers];
    const [data, setData] = useState(questionLabels);
    const [errorBox, setErrorDisplay] = useState("none");
    const [scaleBox, setScaleBoxDisplay] = useState("none");
    const [errors, setErrors] = useState([]);
    const [likert, setLikert] = useState(false);
    const [editor, setEditorState] = useState(EditorState.createEmpty());

    const loadQuestionIntoEditor = useCallback(() => {
        const contentBlock = htmlToDraft(props.data.text);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorContent = EditorState.createWithContent(contentState);
            setEditorState(editorContent);
        }
    }, [props.data.text]);

    useEffect(() => {
        console.log("UseEffect?");
        if (props.data.is_dirty) {
            console.log("Editable?");
            loadQuestionIntoEditor();
        } else {
            setEditorState(EditorState.createEmpty());
        }
    }, [props.data.is_dirty]);

    function handleChange(event) {
        const { target } = event;
        const { value, name } = target;
        const idx = extractIndex(name);
        const nameString = extractNameString(name);

        const answerLabels = [...data];
        answerLabels[idx][nameString] = value;

        setData(answerLabels);
    }

    function onEditorStateChange(editor) {
        setEditorState(editor);
        const jsonHtml = convertToRaw(editor.getCurrentContent());
        const html = draftToHtml(jsonHtml);
        // console.log(html);
        props.changeEvent(html);
    }

    // function handleNestedCheckbox(event) {
    //   const { target } = event;
    //   const { name } = target;
    //   const idx = extractIndex(name);
    //   const nameString = extractNameString(name);

    //   const answerLabels = [...data];
    //   answerLabels[idx][nameString] = event.target.checked;
    //   setData(answerLabels);
    // }

    function handleLikertCheckbox(event) {
        // Set locally so we can set page interactions
        setLikert(event.target.checked);
        // Then set optional settings on SurveyCanvas...
        props.settingsEvent(event);
        if (event.target.checked) {
            setScaleBoxDisplay("block");
            handleAdd();
        } else {
            setScaleBoxDisplay("none");
            handleDelete();
        }
    }

    function handleAdd() {
        const temp = [...data];
        temp.push({
            id: "",
            label: "",
            user_answer: "",
            parentquestion: "",
            showfollowup: false,
            followupquestion: [],
            disqualifier: false,
        });
        setData(temp);
    }

    function handleReset() {
        // Reset all fields on the question
        props.resetQuestionEvent();
        setData([]);
        setErrors([]);
        setErrorDisplay("none");
        setEditorState(EditorState.createEmpty());
    }

    function handleDelete(idx) {
        console.log("INDEX OF Label Answer TO DELETE: ", idx);
        const filtered = data.filter((item, i) => i !== idx);
        console.log("FINAL FILTERED ARRAY: ", filtered);
        setData(filtered);
    }

    function handleNext() {
        const questions = props.survey;
        const errorsArray = props.validateQuestionEvent(data);

        if (errorsArray.length < 1) {
            setErrors([]);
            setErrorDisplay("none");

            props.data.answers = data;
            props.data.question_type_id = likert ? 3 : 1;
            props.data.question_type = likert ? "Likert" : "Free Text";
            // TODO: Remove likert scale
            // props.data.scale = likert ? scale : null;
            // Loop through each question and reset order
            for (let i = 0; i < questions.length; i++) {
                questions[i].order = i + 1;
            }
            // Set the order of the new question and add it to the end of the array
            props.data.order = questions.length > 0 ? questions.length + 1 : 1;
            questions.push(props.data);
            console.log("ADD QUESTION: ", questions);
            // Add this question object to the Survey question array/object
            props.surveyEvent(questions);
            console.log("SURVEY OBJECT: ", props.survey);
            // Reset all fields on the question
            setLikert(false);
            handleReset();
        } else {
            // Set error display
            setErrors(errorsArray);
            setErrorDisplay("block");
        }
    }
    function handleEdit() {
        const errorsArray = props.validateQuestionEvent(props.data.answers);

        if (errorsArray.length < 1) {
            // Create copy of questions array
            const questionsCopy = [...props.survey];
            // FIND the object by "order" attr and REPLACE it with new updated props.data
            questionsCopy.forEach((element, index) => {
                if (element.order === props.data.order) {
                    questionsCopy[index] = props.data;
                    // Return merged array
                    questionsCopy[index].answers = props.labelMergeEvent(data);
                }
            });
            // TODO: Reset the question order...just like handleNext()
            console.log("REPLACE QUESTION WITH THIS: ", questionsCopy);
            props.surveyEvent(questionsCopy);

            // Reset all fields on the question
            handleReset();
        } else {
            // Set error display
            setErrors(errorsArray);
            setErrorDisplay("block");
        }
    }

    return (
        <div className={classes.questionContainer}>
            <Grid container spacing={1}>
                <Grid item sm={12} xs={12}>
                    <Box display={errorBox} className={classes.errorBox}>
                        <Typography className={classes.errorHeading}>
                            <Warning /> OMG {props.user.fname}, Help Me...Help You!
                        </Typography>
                        <ul>
                            {errors.map((e, idx) => {
                                const key = `key_${idx}`;
                                return <li key={key}>{e}</li>;
                            })}
                        </ul>
                    </Box>
                </Grid>
                <Grid item sm={12} xs={12}>
                    <Typography className={classes.instructions}>
                        Create a free text or likert question, then click <strong>NEXT</strong>.
                    </Typography>
                </Grid>
                <Grid item sm={12} xs={12}>
                    <Typography className={classes.stepHeading}>
                        <strong>STEP 1</strong>: <em>Ask the question (REQUIRED)</em>
                    </Typography>
                </Grid>
                <Grid item sm={12} xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={props.data.question_type_id === 3 && true}
                                inputProps={{
                                    "aria-label": "Make This A Likert Question",
                                }}
                                name="question_type_id"
                                onChange={handleLikertCheckbox}
                                value={likert}
                            />
                        }
                        label="Make This A Likert Question"
                    />
                </Grid>
                <Grid item sm={12} xs={12}>
                    <Box display={props.data.scale ? "block" : scaleBox}>
                        <TextField
                            fullWidth
                            multiline
                            rows="2"
                            label="Customize Scale Labels (Comma delimited)"
                            margin="normal"
                            name="scale"
                            onChange={props.handleScaleString}
                            value={props.data.scale ? props.data.scale : ""}
                        />
                    </Box>
                </Grid>
                <Grid item sm={12} xs={12}>
                    <Editor
                        editorState={editor}
                        spellCheck
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName={classes.editorWrapper}
                        onEditorStateChange={onEditorStateChange}
                    />
                </Grid>
                <Grid item sm={12} xs={12}>
                    <Typography className={classes.stepHeading}>
                        <strong>STEP 2</strong>:{" "}
                        <em>Add answer fields to further segment the answers received. (Required)</em>
                    </Typography>
                </Grid>
                <Grid item sm={12} xs={12}>
                    {props.data.answers.map((f, idx) => {
                        const key = `key_${idx}`;
                        return (
                            <Grid container spacing={1} key={key} className={classes.answerField}>
                                <Grid item sm={12} xs={12}>
                                    <Button
                                        size="small"
                                        className={classes.remove}
                                        onClick={() => props.labelDeleteEvent(f, idx)}
                                    >
                                        Remove
                                    </Button>
                                    <TextField
                                        fullWidth
                                        label="Label for Answer Field"
                                        margin="normal"
                                        name={`label[${idx}]`}
                                        onChange={props.labelChangeEvent}
                                        value={f.label}
                                        size="small"
                                    />
                                </Grid>
                                {/* <Grid item sm={12} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={f.likert}
                        inputProps={{
                          "aria-label": "Make Likert"
                        }}
                        name={`likert[${idx}]`}
                        onChange={props.labelCheckboxEvent} // TODO: CREATE SPECIFIC EVENT???
                        value={f.likert}
                      />
                    }
                    label="Make Likert"
                  />
                </Grid> */}
                            </Grid>
                        );
                    })}
                    {data.map((j, idx) => {
                        const key = `key_${idx}`;
                        return (
                            <Grid container spacing={1} key={key} className={classes.answerField}>
                                <Grid item sm={12} xs={12}>
                                    <Button size="small" className={classes.remove} onClick={() => handleDelete(idx)}>
                                        Remove
                                    </Button>
                                    <TextField
                                        fullWidth
                                        label="Label for Answer Field"
                                        margin="normal"
                                        name={`label[${idx}]`}
                                        onChange={handleChange}
                                        value={data[idx].label}
                                        size="small"
                                    />
                                </Grid>
                                {/* <Grid item sm={12} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={data[idx].likert}
                        inputProps={{
                          "aria-label": "Make Likert"
                        }}
                        name={`likert[${idx}]`}
                        onChange={handleNestedCheckbox}
                        value={data[idx].likert}
                      />
                    }
                    label="Make Likert"
                  />
                </Grid> */}
                            </Grid>
                        );
                    })}
                </Grid>
                <Grid item sm={12} xs={12}>
                    <Button
                        size="small"
                        variant="contained"
                        color="secondary"
                        className={classes.submit}
                        endIcon={<AddIcon />}
                        onClick={handleAdd}
                    >
                        Add Answer Fields
                    </Button>
                </Grid>
                <Grid item sm={12} xs={12}>
                    <div className={classes.actionButtons}>
                        <Grid container spacing={1}>
                            <Grid item sm={4} xs={12}>
                                <Button
                                    fullWidth
                                    size="large"
                                    variant="contained"
                                    className={classes.submit}
                                    onClick={handleReset}
                                >
                                    Clear
                                </Button>
                            </Grid>
                            <Grid item sm={8} xs={12}>
                                {props.data.is_dirty ? (
                                    <Button
                                        fullWidth
                                        size="large"
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                        onClick={handleEdit}
                                    >
                                        Apply Changes <Check />
                                    </Button>
                                ) : (
                                    <Button
                                        fullWidth
                                        size="large"
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                        onClick={handleNext}
                                    >
                                        Next <Forward />
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}
export default FreeText;
