// REACT
import React, { useContext } from "react";

// MUI
import { Box, Button, Grid, Paper } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

// MUI STYLES
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

// NPM
import axios from "axios";
import { basicDateTimeStamp } from "../../Utils";
// - EDITOR
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Comments from "../../components/Comments";

// UTILS
import { isValidEmail } from "../../Utils";

// HELPER DATA
import CountryData from "../../data/countries.json";
import { authContext } from "../../context/auth";

/**
 * STYLES
 */

const onboardingStyle = css`
    color: #523197;
    margin-bottom: 0.5rem;
    font-size: 0.75rem;
    text-align: left;
    font-weight: normal;
    text-transform: uppercase;
`;

const labelStyle = css`
    color: #523197;
    margin-bottom: 0.5rem;
    font-size: 0.75rem;
    text-align: left;
    font-weight: bold;
    text-transform: uppercase;
`;

const paperStyle = css`
    padding: 1rem;
    text-align: left;
    color: #000000;
    margin-bottom: 1.5rem;
`;

const contactStyle = css`
    color: #444;
    margin-bottom: 0.5rem;
    font-size: 0.75rem;
    text-align: left;
    font-weight: normal;
`;

const incompleteStyle = css`
    color: red;
`;

const alternateStyles = css`
    display: block;
    color: #444;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-style: normal;
`;

const linkStyle = css`
    color: #8966ad;
    text-decoration: none;
`;

const disclosureWarningStyle = css`
    margin-top: 1rem;
    background-color: #f6d1d1;
    width: 100%;
    border: 3px solid #990000;
    border-radius: 3px;
    color: #ffffff;
    padding: 10px;
    text-align: left;
    font-size: 1rem;
`;

const disclosureStyle = css`
    margin-top: 1rem;
    background-color: #fafafa;
    width: 100%;
    border: 1px solid #f1f1f1;
    border-radius: 3px;
    color: #000000;
    padding: 10px;
    text-align: left;
    font-size: 1rem;
`;

const warningStyle = css`
    color: orange;
    font-size: 1.25rem;
    text-align: left;
    font-weight: normal;
    paddin: 0;
    margin: 0;
`;

const disclosureHeadingStyle = css`
    color: #523197;
    font-size: 0.75rem;
    text-align: left;
    font-weight: normal;
    text-transform: uppercase;
`;

const answerStyle = css`
    color: #000;
    font-size: 0.85rem;
    text-align: left;
`;

const questionStyle = css`
    margin-top: 0;
    margin-bottom: 10px;
    color: #444;
    font-size: 0.85rem;
    text-align: left;
`;

const editorWrapperStyle = css`
    border: 1px solid #eaeaea;
    border-radius: 2px;
    padding: 12px;
`;

const bioStyle = css`
    margin-bottom: 0.5rem;
    color: #444;
    font-size: 0.9rem;
    text-align: left;
`;

const currentJobStyle = css`
    margin-bottom: 1rem;
    padding: 1rem;
    color: #000;
    font-size: 0.9rem;
    text-align: left;
    background-color: #e3d8ef;
    border-radius: 3px;
`;

const jobStyle = css`
    margin-bottom: 0.5rem;
    color: #444;
    font-size: 0.9rem;
    text-align: left;
`;

const positionStyle = css`
    color: #000;
    font-size: 0.9rem;
    text-align: left;
`;

// end of styles

export default function ExpertDisplay(props) {
    /**
     * PROPS
     */

    const { data, setData } = props;

    /**
     * CONSTANTS
     */

    const DEFAULT_ERROR_STATE = { status: false, msg: "", origin: "" };

    const countryCodes = CountryData.countries.map((country, index) => {
        return {
            index,
            value: country.dial_code,
            label: country.dial_code,
        };
    });

    const { auth } = useContext(authContext);

    /**
     * STATE
     */

    const [error, setError] = React.useState(DEFAULT_ERROR_STATE);

    // EDITOR STATE
    const [editorDisplay, setEditorDisplay] = React.useState("none");
    const [editor, setEditorState] = React.useState(EditorState.createEmpty());
    const [bioData, setBioData] = React.useState("");
    const [bioDisplay, setBioDisplay] = React.useState("block");
    const [blindedBioData, setBlindedBioData] = React.useState("");
    const [blinded, setBlindedEditorState] = React.useState(EditorState.createEmpty());
    const [blindedDisplay, setBlindedDisplay] = React.useState("block");
    const [blindedEditorDisplay, setBlindedEditorDisplay] = React.useState("none");

    // REF STATE
    const [isEditingEmail, setIsEditingEmail] = React.useState(false);
    const [isEditingPhone, setIsEditingPhone] = React.useState(false);

    // REFS
    const emailRef = React.useRef(null);
    const phoneRef = React.useRef(null);
    const codeRef = React.useRef(null);

    /**
     * PAGE LOAD
     */

    React.useEffect(() => {
        const contentBlock = htmlToDraft(data?.expert?.biography);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            setEditorState(EditorState.createWithContent(contentState));
        }
        let blindedBio = "";
        if (data?.expert?.blinded_bio !== null && data?.expert?.blinded_bio !== "") {
            blindedBio = data?.expert?.blinded_bio;
        } else {
            blindedBio = "Blinded Bio Missing";
        }
        const blindedContentBlock = htmlToDraft(blindedBio);
        if (blindedContentBlock) {
            const blindedContentState = ContentState.createFromBlockArray(blindedContentBlock.contentBlocks);
            setBlindedEditorState(EditorState.createWithContent(blindedContentState));
        }
    }, [props]);

    /**
     * HANDLE FUNCS
     */

    function setCommentsArray(arr) {
        if (typeof arr === "object") {
            setData({
                ...data,
                comments: arr,
            });
        }
    }
    function handleEmailClick() {
        emailRef.current.value = data?.primaryEmails?.[0]?.email;
        setIsEditingEmail(true);
    }

    function handlePhoneClick() {
        phoneRef.current.value = data?.primaryPhones?.[0]?.phone;
        codeRef.current.value = data?.primaryPhones?.[0]?.code;
        setIsEditingPhone(true);
    }

    async function handleSaveEmailClick() {
        const newEmail = emailRef.current.value;
        console.log(newEmail, " :: newEmail");

        if (!isValidEmail(newEmail)) {
            setError({ status: true, msg: "Please enter a valid email address", origin: "email" });
        }

        const newData = { ...data };
        const [oldEmail, ...rest] = newData?.primaryEmails;
        const updatedEmail = { ...oldEmail, email: newEmail };
        newData.primaryEmails = [updatedEmail, ...rest];

        await axios
            .post(`/api/expert/updateEmail`, {
                mail: newEmail,
                person_id: newData?.expert?.person_id,
                isPrimary: true,
                id: "email",
            })
            .then((r) => {
                console.log(r, " :: r");
                if (r?.data?.data) {
                    console.log("====== in if ======");
                    setData(newData);
                    setError(DEFAULT_ERROR_STATE);
                    setIsEditingEmail(false);
                }
            })
            .catch((e) => {
                console.log(e, " :: error in handleSaveHeadlineClick");
            });
    }

    function handleCloseEmail() {
        setError(DEFAULT_ERROR_STATE);
        setIsEditingEmail(false);
    }

    async function handleSavePhoneClick() {
        const newPhone = phoneRef.current.value;
        console.log(newPhone, " :: newPhone");
        const newCode = codeRef.current.value;
        console.log(newCode, " :: newCode");

        const newData = { ...data };
        newData.primaryPhones.phone = newPhone;
        newData.primaryPhones.code = newCode;

        console.log(newData, " :: newData");
        const [oldPhone, ...rest] = newData?.primaryPhones;
        const updatedPhone = { ...oldPhone, phone: newPhone, code: newCode };
        newData.primaryPhones = [updatedPhone, ...rest];

        await axios
            .post(`/api/expert/updatePhoneNum`, {
                code: newCode,
                number: newPhone,
                person_id: newData?.expert?.person_id,
                isPrimary: true,
                id: "phoneNum",
            })
            .then((r) => {
                if (r?.data?.data) {
                    setData(newData);
                    setError(DEFAULT_ERROR_STATE);
                    setIsEditingPhone(false);
                }
            })
            .catch((e) => {
                console.log(e, " :: error in handleSaveHeadlineClick");
            });
    }

    function handleClosePhone() {
        setError(DEFAULT_ERROR_STATE);
        setIsEditingPhone(false);
    }

    /**
     * HELPER FUNCS
     */

    function showEditor() {
        setBioDisplay("none");
        setEditorDisplay("block");
    }

    function showBlindedEditor() {
        setBlindedDisplay("none");
        setBlindedEditorDisplay("block");
    }

    function onEditorStateChange(editor) {
        setEditorState(editor);
        const jsonHtml = convertToRaw(editor.getCurrentContent());
        const html = draftToHtml(jsonHtml);
        setBioData(html);
    }

    function onBlindedEditorStateChange(blinded) {
        setBlindedEditorState(blinded);
        const jsonHtml = convertToRaw(blinded.getCurrentContent());
        const html = draftToHtml(jsonHtml);
        setBlindedBioData(html);
    }

    async function submitBioEvent() {
        console.log(bioData, " :: bioData");
        if (bioData) {
            await axios
                .post(`/api/expert/updateBio`, { id: data?.expert?.expert_id, biography: bioData })
                .then((r) => {
                    const result = r?.data?.data?.biography;
                    if (result) {
                        setBioDisplay("block");
                        setEditorDisplay("none");
                        const newData = { ...data };
                        newData.expert.biography = bioData;
                        setData(newData);
                    }
                })
                .catch((e) => {
                    console.log(e, " :: e in submitBioEvent");
                });
        }
    }

    async function submitBlindedBioEvent() {
        console.log(blindedBioData, " :: blindedBioData");
        if (blindedBioData) {
            await axios
                .post(`/api/expert/updateBlindedBio`, { id: data?.expert?.expert_id, blinded_bio: blindedBioData })
                .then((r) => {
                    const result = r?.data?.data?.blinded_bio;
                    if (result) {
                        setBlindedDisplay("block");
                        setBlindedEditorDisplay("none");
                        const newData = { ...data };
                        newData.expert.blinded_bio = blindedBioData;
                        setData(newData);
                    }
                })
                .catch((e) => {
                    console.log(e, " :: e in submitBlindedBioEvent");
                });
        }
    }

    function formatPhoneNumber(str, usa) {
        const cleaned = `${str}`.replace(/\D/g, "");
        const formatted = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if ((formatted && usa) || (formatted && usa === "+1")) {
            return `(${formatted[1]}) ${formatted[2]}-${formatted[3]}`;
        }
        return str;
    }

    return (
        <>
            <Grid container spacing={3}>
                <Grid item sm={6} xs={12}>
                    <Paper css={paperStyle}>
                        <strong css={labelStyle}>Contact Information:</strong>
                        <div
                            css={css`
                                ${contactStyle}
                            `}
                        >
                            <div>*** PRIMARY - CLICK TO EDIT ***</div>

                            {/* EMAIL PHONE EDIT - START */}
                            <div
                                css={css`
                                    display: ${isEditingEmail ? "none" : "block"};
                                `}
                            >
                                {data?.primaryEmails?.length > 0 && data?.optoutData?.length === 0 ? (
                                    <div
                                        onClick={handleEmailClick}
                                        css={css`
                                            font-size: 1.5rem;
                                            text-align: left;
                                            font-weight: 700;
                                            paddin: 0;
                                            margin: 0;
                                            & > span {
                                                font-weight: 300;
                                                font-size: 1.25rem;
                                                display: inline-block;
                                                margin-left: 0.25rem;
                                            }
                                            &:hover {
                                                color: hsl(270, 90%, 50%);
                                                cursor: pointer;
                                            }
                                        `}
                                    >
                                        <span>{`${data?.primaryEmails?.[0]?.email}`}</span>
                                    </div>
                                ) : (
                                    <div
                                        onClick={handleEmailClick}
                                        css={css`
                                            &:hover {
                                                color: hsl(270, 90%, 50%);
                                                cursor: pointer;
                                            }
                                        `}
                                    >
                                        <span css={incompleteStyle}>* email unavailable</span>
                                    </div>
                                )}
                            </div>

                            <div
                                css={css`
                                    display: ${isEditingEmail ? "flex" : "none"};
                                    flex-direction: row;
                                    align-items: center;
                                    font-size: 1.25rem;
                                    width: 100%;
                                    border: 1px solid
                                        ${error.status && error.origin === "email" ? "#f87171" : "#a8a29e"};
                                    border-radius: 0.2rem;
                                    justify-content: space-between;
                                    padding: 0.25rem 1rem;
                                    gap: 1rem;
                                `}
                            >
                                <input
                                    css={css`
                                        display: block;
                                        border: none;
                                        width: 100%;
                                        &:focus,
                                        &:active {
                                            outline: none;
                                        }
                                    `}
                                    ref={emailRef}
                                />
                                <div
                                    css={css`
                                        display: flex;
                                        gap: 0.5rem;
                                    `}
                                >
                                    <CheckIcon
                                        onClick={handleSaveEmailClick}
                                        css={css`
                                            color: #bbf7d0;
                                            &:hover {
                                                cursor: pointer;
                                                color: #16a34a;
                                            }
                                        `}
                                    />
                                    <CloseIcon
                                        onClick={handleCloseEmail}
                                        css={css`
                                            color: #fecaca;
                                            &:hover {
                                                cursor: pointer;
                                                color: #dc2626;
                                            }
                                        `}
                                    />
                                </div>
                            </div>
                            {error.status && error.origin === "email" && (
                                <p
                                    css={css`
                                        color: #f87171;
                                        margin-top: 0.25rem;
                                        font-weight: 500;
                                    `}
                                >
                                    {error.msg}
                                </p>
                            )}
                            {/* EMAIL PHONE EDIT - END */}

                            {/* PRIMARY PHONE - END */}
                            <div
                                css={css`
                                    display: ${isEditingPhone ? "none" : "block"};
                                `}
                            >
                                {data?.primaryPhones?.length > 0 &&
                                data?.primaryPhones?.[0]?.phone?.length > 8 &&
                                data?.optoutData?.length === 0 ? (
                                    <div
                                        onClick={handlePhoneClick}
                                        css={css`
                                            font-size: 1.5rem;
                                            text-align: left;
                                            font-weight: 700;
                                            paddin: 0;
                                            margin: 0;
                                            & > span {
                                                font-weight: 300;
                                                font-size: 1.25rem;
                                                display: inline-block;
                                                margin-left: 0.25rem;
                                            }
                                            &:hover {
                                                color: hsl(270, 90%, 50%);
                                                cursor: pointer;
                                            }
                                        `}
                                    >
                                        <span>
                                            {`${data?.primaryPhones?.[0]?.code} ${formatPhoneNumber(
                                                data?.primaryPhones?.[0]?.phone,
                                                data?.primaryPhones?.[0]?.code === "+1"
                                            )}`}
                                        </span>
                                    </div>
                                ) : (
                                    <div
                                        onClick={handlePhoneClick}
                                        css={css`
                                            &:hover {
                                                color: hsl(270, 90%, 50%);
                                                cursor: pointer;
                                            }
                                        `}
                                    >
                                        {" "}
                                        <span css={incompleteStyle}>* phone unavailable</span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div
                            css={css`
                                display: ${isEditingPhone ? "flex" : "none"};
                                flex-direction: row;
                                align-items: center;
                                font-size: 1.25rem;
                                width: 100%;
                                border: 1px solid ${error.status && error.origin === "phone" ? "#f87171" : "#a8a29e"};
                                border-radius: 0.2rem;
                                justify-content: space-between;
                                padding: 0.25rem 1rem;
                                gap: 1rem;
                            `}
                        >
                            <select
                                css={css`
                                    display: block;
                                    border: none;
                                    cursor: pointer;
                                    background-color: #f5f5f5;
                                    border-radius: 0.2rem;
                                    padding: 0.25rem;
                                    &:hover,
                                    &:focus,
                                    &:active {
                                        outline: none;
                                    }
                                `}
                                ref={codeRef}
                            >
                                {countryCodes.map((c, i) => (
                                    <option key={`${c.value}-${i}`} value={c.value}>
                                        {c.label}
                                    </option>
                                ))}
                            </select>
                            <input
                                css={css`
                                    display: block;
                                    border: none;
                                    width: 100%;
                                    &:focus,
                                    &:active {
                                        outline: none;
                                    }
                                `}
                                ref={phoneRef}
                            />
                            <div
                                css={css`
                                    display: flex;
                                    gap: 0.5rem;
                                `}
                            >
                                <CheckIcon
                                    onClick={handleSavePhoneClick}
                                    css={css`
                                        color: #bbf7d0;
                                        &:hover {
                                            cursor: pointer;
                                            color: #16a34a;
                                        }
                                    `}
                                />
                                <CloseIcon
                                    onClick={handleClosePhone}
                                    css={css`
                                        color: #fecaca;
                                        &:hover {
                                            cursor: pointer;
                                            color: #dc2626;
                                        }
                                    `}
                                />
                            </div>
                        </div>
                        {error.status && error.origin === "phone" && (
                            <p
                                css={css`
                                    color: #f87171;
                                    margin-top: 0.25rem;
                                    font-weight: 500;
                                `}
                            >
                                {error.msg}
                            </p>
                        )}
                        {/* PRIMARY PHONE - END */}

                        {data?.alternatePhones?.length > 0 && data?.optoutData?.length === 0 && (
                            <div css={contactStyle}>
                                <span>*** ADDITIONAL NUMBERS ***</span>{" "}
                                {data?.alternatePhones.map((phone, index) => {
                                    return (
                                        <div key={index} css={alternateStyles}>
                                            <a href={`tel:${phone?.code}${phone?.phone}`}>
                                                {`${phone?.code} ${formatPhoneNumber(phone?.phone, phone?.code)}`}
                                            </a>
                                            <em> {phone?.type ? `(${phone?.type})` : "(Unknown Type)"}</em>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                        {data?.alternateEmails?.length > 0 && data?.optoutData?.length === 0 && (
                            <div css={contactStyle}>
                                <span>*** ADDITIONAL EMAILS ***</span>{" "}
                                {data?.alternateEmails.map(function (email, index) {
                                    return (
                                        <em css={alternateStyles} key={index}>
                                            {email?.email}
                                        </em>
                                    );
                                })}
                            </div>
                        )}
                    </Paper>
                    <Paper css={paperStyle}>
                        <strong css={labelStyle}>Employment:</strong>
                        <br />
                        <br />
                        {data.empHistData.length > 0 && (
                            <>
                                {data.empHistData
                                    .filter((e) => e.present)
                                    .map(function (job, idx) {
                                        const key = `key_${idx}`;
                                        return (
                                            <div key={key} css={currentJobStyle}>
                                                <em>** Current Job **</em>
                                                <br />
                                                <span css={positionStyle}>
                                                    <strong>{job.position}</strong>, {job.company}
                                                </span>
                                                <br />
                                                <span>
                                                    {job.from_month} {job.from_year} -{" "}
                                                    {job.present ? "Present" : job.to_month}{" "}
                                                    {job.present ? "" : job.to_year}
                                                    {" ("}
                                                    {job.duration}
                                                    {")"}
                                                </span>
                                            </div>
                                        );
                                    })}
                                {data.empHistData
                                    .filter((e) => !e.present)
                                    .map(function (job, idx) {
                                        const key = `key_${idx}`;
                                        return (
                                            <div key={key} css={jobStyle}>
                                                <span css={positionStyle}>
                                                    <strong>{job.position}</strong>, {job.company}
                                                </span>
                                                <br />
                                                <span>
                                                    {job.from_month} {job.from_year} -{" "}
                                                    {job.present ? "Present" : job.to_month}{" "}
                                                    {job.present ? "" : job.to_year}
                                                    {" ("}
                                                    {job.duration}
                                                    {")"}
                                                </span>
                                            </div>
                                        );
                                    })}
                            </>
                        )}
                    </Paper>

                    <Paper css={paperStyle}>
                        <label css={labelStyle}>BIOGRAPHY</label>
                        <Box display={editorDisplay}>
                            <Editor
                                editorState={editor}
                                spellCheck
                                toolbarcss="toolbarcss"
                                wrappercss="wrappercss"
                                editorcss={editorWrapperStyle}
                                onEditorStateChange={onEditorStateChange}
                            />
                            <Button css={linkStyle} onClick={submitBioEvent}>
                                Save
                            </Button>
                        </Box>
                        <Box display={bioDisplay}>
                            <div
                                css={bioStyle}
                                dangerouslySetInnerHTML={{
                                    __html: data?.expert?.biography,
                                }}
                            />
                            <Button css={linkStyle} onClick={showEditor}>
                                Enter/Edit Bio
                            </Button>
                        </Box>
                    </Paper>

                    <Paper css={paperStyle}>
                        <label css={labelStyle}>BLINDED BIOGRAPHY</label>
                        <Box display={blindedEditorDisplay}>
                            <Editor
                                editorState={blinded}
                                spellCheck
                                toolbarcss="toolbarcss"
                                wrappercss="wrappercss"
                                editorcss={editorWrapperStyle}
                                onEditorStateChange={onBlindedEditorStateChange}
                            />
                            <Button css={linkStyle} onClick={submitBlindedBioEvent}>
                                Save
                            </Button>
                        </Box>
                        <Box display={blindedDisplay}>
                            <div
                                css={bioStyle}
                                dangerouslySetInnerHTML={{
                                    __html: data?.expert?.blinded_bio,
                                }}
                            />
                            <Button css={linkStyle} onClick={showBlindedEditor}>
                                Edit Blinded Bio
                            </Button>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <Paper css={paperStyle}>
                        <strong css={labelStyle}>Onboarding Information:</strong>
                        <br />
                        <br />
                        {data.onboarding.length > 0 ? (
                            <>
                                {data.onboarding.map((o, idx) => (
                                    <div key={idx}>
                                        {o.activity === "CONTACT_INFO" && (
                                            <div css={onboardingStyle}>
                                                <strong>Step 1 - Confirmed Contact Info on:</strong>{" "}
                                                {basicDateTimeStamp(o.email_onboarding_created_on)}
                                            </div>
                                        )}
                                        {o.activity === "TERMS_SIGNED" && (
                                            <div css={onboardingStyle}>
                                                <strong>Step 2 - Signed Terms & Conditions on:</strong>{" "}
                                                {basicDateTimeStamp(o.email_onboarding_created_on)}
                                            </div>
                                        )}
                                        {o.activity === "DISCLOSURES" && (
                                            <div css={onboardingStyle}>
                                                <strong>Step 3 - Completed Disclosures on:</strong>{" "}
                                                {basicDateTimeStamp(o.email_onboarding_created_on)}
                                            </div>
                                        )}
                                        {o.activity === "EMPLOYMENT" && (
                                            <div css={onboardingStyle}>
                                                <strong>Step 4 - Confirmed Employment on:</strong>{" "}
                                                {basicDateTimeStamp(o.email_onboarding_created_on)}
                                            </div>
                                        )}
                                    </div>
                                ))}
                                {data?.expert?.disclosures && (
                                    <div
                                        css={
                                            data?.expert?.disclosures_form_review_required
                                                ? disclosureWarningStyle
                                                : disclosureStyle
                                        }
                                    >
                                        <span css={disclosureHeadingStyle}>COMPLETED Disclosure Question/Answers</span>
                                        {data?.disclosureQuestionnaire?.[0]?.question_data?.length > 0 &&
                                            data?.disclosureQuestionnaire?.[0]?.question_data.map((q, index) => {
                                                const key = `q_${index}`;
                                                return (
                                                    <div key={key}>
                                                        <div css={questionStyle}>
                                                            {`QUESTION ${index + 1}:`}
                                                            <br />
                                                            {q.label}{" "}
                                                            <strong css={answerStyle}>
                                                                {q.value === "true" ? "YES" : "NO"}
                                                            </strong>
                                                        </div>
                                                        {q?.value === "true" &&
                                                            q?.branch.map((b, i) => {
                                                                const bkey = `q_${i}`;
                                                                return (
                                                                    <div css={questionStyle} key={bkey}>
                                                                        {b.label}{" "}
                                                                        <strong css={answerStyle}>{b.value}</strong>
                                                                    </div>
                                                                );
                                                            })}
                                                    </div>
                                                );
                                            })}
                                    </div>
                                )}
                            </>
                        ) : (
                            <div css={warningStyle}>*** NOT ONBOARDED YET ***</div>
                        )}
                    </Paper>
                    <Paper css={paperStyle}>
                        <Comments
                            // parentPage="ExpertShowNext.js"
                            // activities={data.activities}
                            user={auth.data}
                            object_type="Expert"
                            object_id={data?.expert?.expert_id}
                            formatDate={basicDateTimeStamp}
                            setComments={setCommentsArray}
                            comments={data.comments}
                            height="auto"
                        />
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}
