// this builds the URL string that gets saved into the DB
// OPTIONS
const buildCheckboxOptions = (checkboxOptions) => {
    const checkboxOptionArray = [];

    checkboxOptions.forEach((checkboxOption) => {
        const { name, value } = checkboxOption;
        if (value) {
            checkboxOptionArray.push(`${name}=${value}`);
        }
    });

    return checkboxOptionArray;
};

const buildRadioOptions = (radioOptions) => {
    const radioOptionArray = [];

    radioOptions.forEach((radioOption) => {
        const { name, currentValue } = radioOption;
        radioOptionArray.push(`${name}=${currentValue}`);
    });

    return radioOptionArray;
};

const buildOptionsQuery = (options) => {
    const checkboxStringArr = buildCheckboxOptions(options.checkbox).flat();
    const radioStringArr = buildRadioOptions(options.radio).flat();

    return [...checkboxStringArr, ...radioStringArr];
};

// SEARCH FIELDS
// string search fields
const buildStringSearchFields = (stringSearchFields) => {
    const stringSearchFieldArr = [];

    for (const [searchFieldName, inputs] of Object.entries(stringSearchFields)) {
        inputs.forEach((input) => {
            const { boolType, active, value } = input;
            if (active && value) {
                stringSearchFieldArr.push(`${boolType}_${searchFieldName}=${encodeURIComponent(value)}`);
            }
        });
    }

    return stringSearchFieldArr;
};

// object search fields
const buildObjectSearchFields = (objectSearchFields) => {
    const objectSearchFieldArr = [];

    for (const [searchFieldName, inputs] of Object.entries(objectSearchFields)) {
        inputs.forEach((input) => {
            const { boolType, active, value: values } = input;
            if (active && values.length > 0) {
                const onlyValuesArr = values.map((value) => {
                    return value.value;
                });
                objectSearchFieldArr.push(`${boolType}_${searchFieldName}=${onlyValuesArr.join(",")}`);
            }
        });
    }

    return objectSearchFieldArr;
};

const dslQueryBuilder = (dsl) => {
    const dslArray = [];

    if (dsl.length > 0) {
        dslArray.push(`dsl=${encodeURIComponent(dsl)}`);
    }

    return dslArray;
};

const buildSearchFieldsQuery = (searchFields) => {
    const stringSearchFieldArr = buildStringSearchFields(searchFields.string).flat();
    const objectSearchFieldArr = buildObjectSearchFields(searchFields.object).flat();
    const dslSearchFieldArr = dslQueryBuilder(searchFields.dsl);

    return [...stringSearchFieldArr, ...objectSearchFieldArr, ...dslSearchFieldArr];
};

// FILTERS
const buildFilterQuery = (filters) => {
    const { expertRateSlider } = filters;
    const { active, min, currentMin, max, currentMax } = expertRateSlider;
    const sliderArray = [];

    if (active) {
        sliderArray.push(
            `expertRateSlider=active_${active},min_${min},currentMin_${currentMin},max_${max},currentMax_${currentMax}`
        );
    }

    return sliderArray;
};

// METADATA
const buildMetaDataQuery = (metaData) => {
    const { projectId } = metaData;
    const projectIdArray = [];

    if (projectId) {
        projectIdArray.push(`projectId=${metaData.projectId}`);
    }

    return [...projectIdArray];
};

// Util Funcs
const finalUrlString = (finalArray) => {
    return `?${finalArray.join("&")}`;
};

export const queryBuilder = (searchObj, userId) => {
    const { options, searchFields, filters, metaData } = searchObj;
    const optionsArray = buildOptionsQuery(options);
    const searchFieldsArray = buildSearchFieldsQuery(searchFields);
    const filtersArray = buildFilterQuery(filters);
    const metaDataArray = buildMetaDataQuery(metaData);

    return finalUrlString([...optionsArray, ...searchFieldsArray, ...filtersArray, ...metaDataArray]);
};
