import React, { useState, useEffect } from "react";
import axios from "axios";
import { Dialog, DialogTitle, IconButton, DialogContent, DialogActions, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/system";
import linkedinIcon from "../../img/icon-linkedin-240.png";
import CountryListData from "../../data/countries.json";
const countries = CountryListData.countries.map((country) => {
    return {
        value: country.dial_code,
        label: country.code,
    };
});

export default function PhoneSearchModal(props) {
    const { modal, closeEvent, person, project_id, reloadEvent } = props;
    const [data, setData] = useState([]);
    const [lusha, setLushaData] = useState({});
    const [contactout, setContactOutData] = useState({});
    const [resultsDisplay, setResultsDisplay] = useState("block");
    const [detailsDisplay, setDetailsDisplay] = useState("none");
    const [emailsDisplay, setEmailsDisplay] = useState("none");
    const [phoneData, setPhoneData] = useState({});
    const [backbutton, setBackButton] = useState(true);
    const [emailsBtn, setFindEmailsButton] = useState(false);
    // Helpers

    function closeModalEvent() {
        closeEvent(false);
    }

    function goBackToResults() {
        setResultsDisplay("block");
        setDetailsDisplay("none");
        setEmailsDisplay("none");
        setFindEmailsButton(false);
        setBackButton(true);
    }

    async function saveEmailToProfile(email) {
        const postObject = {
            mail: email,
            person_id: person.id,
            isPrimary: true,
            id: "email",
        };
        await axios
            .post("/api/expert/updateEmail", postObject, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    console.log("post worked! result ->", result);
                    closeModalEvent();
                    reloadEvent(project_id);
                }
            })
            .catch((result) => {
                console.log("Something went wrong, API Error, 404 Error:", result);
            });
    }

    async function savePhoneToProfile(number, code) {
        const dial_code = countries.find((country) => country.label === code).value;
        const postObject = {
            number: number,
            person_id: person.id,
            code: dial_code,
        };
        console.log("POST OBJECT: ", postObject);
        await axios
            .post("/api/expert/updatePhoneNum", postObject, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    console.log("post worked! result ->", result);
                    closeModalEvent();
                    reloadEvent(project_id);
                }
            })
            .catch((result) => {
                alert("Sorry, we are unable to save the phone to the profile.");
                console.log("Something went wrong, API Error, 404 Error:", result);
            });
    }

    async function lookupPhoneEvent(phone) {
        const params = { number: phone };
        console.log("PHONE TO VERIFY: ", params);
        await axios
            .get("/api/expert/phonelookup", {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                params: params,
            })
            .then((response) => {
                console.log("TWILIO PHONE LOOKUP RESPONSE RESULT: ", response);
                if (response.status === 200 && response.data.data) {
                    setResultsDisplay("none");
                    setDetailsDisplay("block");
                    setPhoneData(response.data.data);
                    console.log("TWILIO PHONE LOOKUP DATA RESULT: ", response.data.data);
                    setBackButton(false);
                }
            })
            .catch((err) => {
                console.log(err, " :: err");
            });
    }

    async function checkRocketReachResponseStatus(id) {
        const profile = { id };
        console.log("Is there a Resonse ID? ", id);
        await axios
            .get("/api/expert/rocketreach_checkstatus", {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                params: profile,
            })
            .then((response) => {
                console.log("ROCKETREACH CHECK STATUS RESPONSE RESULT: ", response);
                switch (response.data.data[0].status) {
                    case "complete":
                        setData(response.data.data);
                        break;
                    default:
                        if (
                            response.data.data[0].phones.length > 0 &&
                            response.data.data[0].phones.filter((phone) => phone.recommended).length > 0
                        ) {
                            // Stop the loop checking when we find a good phone number...
                            setData(response.data.data);
                            console.log(
                                "ROCKETREACH CHECK STATUS...FOUND A GOOD EMAIL... ",
                                response.data.data[0].phones.filter((phone) => phone.recommended)
                            );
                            break;
                        } else {
                            setTimeout(function () {
                                console.log("ROCKETREACH CHECK STATUS...AGAIN... ", response.data.data[0].status);
                                checkRocketReachResponseStatus(response.data.data[0].id);
                            }, 5000);
                        }
                }
            })
            .catch((err) => {
                console.log(err, " :: err");
            });
    }

    async function fetchProfileDataViaRocketReach() {
        const profile = { name: person.name, li_url: person.linkedin_url };
        console.log("Is there a LinkedIn URL: ", profile);
        // TODO: Replace with Lusha API call...
        await axios
            .get("/api/expert/rocketreach_lookup", {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                params: profile,
            })
            .then((response) => {
                console.log("ROCKETREACH LOOKUP DIRECT RESPONSE RESULT: ", response);
                if (typeof response !== undefined && response.status == 200) {
                    const result = response?.data;
                    console.log("ROCKETREACH LOOKUP SUCCESSFUL 200 STATUS RESPONSE: ", result);
                    if (result.message === "Success" && result.data) {
                        console.log("ROCKETREACH LOOKUP RESPONSE FULL DATA RESULT: ", result.data);
                        switch (result.data.status) {
                            case "complete":
                                setData([result.data]);
                                break;
                            case "waiting":
                            case "searching":
                            case "progress":
                            case "not queued":
                                console.log("ROCKETREACH STATUS PENDING :: ", result.data);
                                checkRocketReachResponseStatus(result.data.id);
                                break;
                            case "failed":
                                console.log("ROCKETREACH SEARCH FAILED");
                                setData([]);
                                break;
                            default:
                                console.log("ROCKETREACH DEFAULT");
                                setData([]);
                                closeModalEvent();
                        }
                    } else {
                        console.log("ROCKETREACH was not able to find any data for this person.");
                    }
                } else {
                    alert("Problem with ROCKETREACH LOOKUP API. Likely missing a LinkedIn URL.");
                }
            })
            .catch((err) => {
                console.log("TRY CATCH ERROR: ", err);
                setData([]);
                closeModalEvent();
            });
    }

    async function searchPhoneNumberViaLusha() {
        const profile = {
            linkedinUrl: person.linkedin_url,
            firstName: person.fname,
            lastName: person.lname,
            company: person.company ? person.company : data[0].current_employer,
            property: "phoneNumbers",
        };
        console.log("Attributes to pass to Lusha API: ", profile);
        if (profile.linkedinUrl && profile.firstName && profile.lastName && profile.company) {
            await axios
                .get("/api/expert/lusha_lookup", {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                    params: profile,
                })
                .then((response) => {
                    if (response?.data) {
                        console.log("LUSHA LOOKUP RESPONSE RESULT: ", response);
                        const result = response?.data || [];
                        if (result && result.message === "Success" && result.data.data) {
                            console.log("LUSHA SUCCESSFUL RESPONSE RESULT: ", result.data.data);
                            setLushaData(result.data.data);
                        } else {
                            const noData = { data: [], message: result.data.errors.message };
                            console.log("LUSHA LOOKUP NO RESULT: ", noData);
                            setLushaData(noData);
                        }
                    } else {
                        alert("We encountered an error with Lusha API. Please try again later.");
                    }
                })
                .catch((err) => {
                    console.log(err, " :: err");
                });
        } else {
            alert("Problem with Lusha API. Likely missing company name.");
        }
    }

    async function searchEmailsViaContactOut() {
        const profile = { profile: person.linkedin_url };
        console.log("Is there a LinkedIn URL: ", profile);
        await axios
            .get("/api/expert/linkedin", {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                params: profile,
            })
            .then((response) => {
                if (response?.data) {
                    const result = response?.data || [];
                    if (result.message === "Success" && result.data.status_code == 200) {
                        console.log("CONTACT OUT RESPONSE RESULT: ", result.data.profile);
                        setContactOutData(result.data.profile);
                        setResultsDisplay("none");
                        setDetailsDisplay("none");
                        setEmailsDisplay("block");
                        setBackButton(false);
                        setFindEmailsButton(true);
                    } else {
                        console.log("ContactOut was not able to find an email address for this person.");
                        setResultsDisplay("none");
                        setDetailsDisplay("none");
                        setEmailsDisplay("block");
                        setBackButton(false);
                        setFindEmailsButton(true);
                    }
                } else {
                    alert("Problem with ContactOut API. Likely missing a LinkedIn URL.");
                }
            })
            .catch((err) => {
                console.log(err, " :: err");
            });
    }

    useEffect(() => {
        console.log("LinkedIn URL: ", person.linkedin_url);
        if (person.linkedin_url) {
            fetchProfileDataViaRocketReach();
        }
    }, []);

    if (!data) {
        return <div>Searching...</div>;
    } else {
        return (
            <Dialog open={modal} onClose={closeModalEvent} fullWidth maxWidth="sm" aria-labelledby="form-dialog-title">
                <DialogTitle>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={closeModalEvent}
                        sx={{ float: "right" }}
                        aria-label="close"
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                    {person.name}: Contact Profile Search
                </DialogTitle>
                <DialogContent>
                    <Box display={emailsDisplay}>
                        {contactout && contactout?.email ? (
                            <>
                                {contactout.email && contactout.email.length > 0 && (
                                    <>
                                        <strong>Recommended:</strong>
                                        {contactout.email.map((item, index) => {
                                            return (
                                                <p style={{ margin: "0" }} key={`${item}_${index}`}>
                                                    {item}{" "}
                                                    <Button onClick={() => saveEmailToProfile(item)}>
                                                        Save to Profile
                                                    </Button>
                                                </p>
                                            );
                                        })}
                                        <br />
                                    </>
                                )}
                                {contactout.personal_email && contactout.personal_email.length > 0 && (
                                    <>
                                        <strong>Personal Emails:</strong>
                                        {contactout.personal_email.map((item, index) => {
                                            return (
                                                <p style={{ margin: "0" }} key={`${item}_${index}`}>
                                                    {item}{" "}
                                                    <Button onClick={() => saveEmailToProfile(item)}>
                                                        Save to Profile
                                                    </Button>
                                                </p>
                                            );
                                        })}
                                        <br />
                                    </>
                                )}
                                {contactout.work_email && contactout.work_email.length > 0 && (
                                    <>
                                        <strong>Professional Emails:</strong>
                                        {contactout.work_email.map((item, index) => {
                                            return (
                                                <p style={{ margin: "0" }} key={`${item}_${index}`}>
                                                    {item}{" "}
                                                    <Button onClick={() => saveEmailToProfile(item)}>
                                                        Save to Profile
                                                    </Button>
                                                </p>
                                            );
                                        })}
                                    </>
                                )}
                            </>
                        ) : (
                            <p>
                                Sorry, we were unable to find an email address for this person based on their LinkedIn
                                profile. Feel free to use the ContactOut Chrome extension or web application for a more
                                comprehensive search.
                            </p>
                        )}
                    </Box>
                    <Box display={detailsDisplay}>
                        {phoneData && (
                            <>
                                <p style={{ margin: "0" }}>
                                    Name:{" "}
                                    <strong>
                                        {phoneData.callerName && phoneData.callerName.caller_name
                                            ? phoneData.callerName.caller_name
                                            : "Unavailable"}
                                    </strong>
                                </p>
                                <p style={{ margin: "0" }}>
                                    Verified:{" "}
                                    <strong>
                                        {phoneData.nationalFormat} ({phoneData.countryCode && phoneData.countryCode} |{" "}
                                        {phoneData.carrier && phoneData.carrier.type}{" "}
                                        {phoneData.carrier && phoneData.carrier.name})
                                    </strong>
                                </p>
                                <br />
                                <Button
                                    onClick={() => savePhoneToProfile(phoneData.phoneNumber, phoneData.countryCode)}
                                    fullWidth
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                >
                                    Save {phoneData.phoneNumber} to Profile
                                </Button>
                            </>
                        )}
                    </Box>
                    <Box display={resultsDisplay}>
                        {data.length > 0 && data[0] ? (
                            <>
                                {data[0].profile_pic && (
                                    <img
                                        src={data[0].profile_pic}
                                        border="0"
                                        style={{
                                            borderRadius: "50%",
                                            height: "125px",
                                            float: "left",
                                            marginRight: "15px",
                                            marginBottom: "15px",
                                        }}
                                    />
                                )}
                                {data[0].linkedin_url ? (
                                    <>
                                        <img src={linkedinIcon} height="20" alt="LinkedIn" />{" "}
                                        <a href={data[0].linkedin_url} target="_blank" rel="noreferrer">
                                            Send InMail to {person.name}
                                        </a>
                                    </>
                                ) : (
                                    "Not Available"
                                )}
                                <p style={{ margin: "0", fontSize: "0.85rem" }}>
                                    Currently:{" "}
                                    <strong>{data[0].current_title ? data[0].current_title : "Not Available"}</strong>,{" "}
                                    <strong>
                                        {data[0].current_employer ? data[0].current_employer : "Not Available"}
                                    </strong>
                                </p>
                                <p style={{ margin: "0", fontSize: "0.85rem" }}>
                                    Location: <strong>{data[0].location ? data[0].location : "Not Available"}</strong>
                                </p>
                                <p style={{ margin: "0", fontSize: "0.85rem" }}>
                                    Birth Year:{" "}
                                    <strong>{data[0].birth_year ? data[0].birth_year : "Not Available"}</strong>
                                </p>
                                <div style={{ marginTop: "15px", clear: "both" }}>
                                    {data[0].phones && data[0].phones.length > 0 ? (
                                        <>
                                            <strong style={{ fontSize: "0.85rem" }}>
                                                Phone Results Found: (click to verify and save)
                                            </strong>
                                            <ul>
                                                {data[0].phones
                                                    .filter((p) => p.recommended)
                                                    .map((item, index) => {
                                                        return (
                                                            <li style={{ margin: "0" }} key={`${item.number}`}>
                                                                <Button onClick={() => lookupPhoneEvent(item.number)}>
                                                                    {item.number} - type: {item.type}
                                                                    {item.validity === "valid" ? " (RECOMMENDED)" : ""}
                                                                </Button>
                                                            </li>
                                                        );
                                                    })}
                                            </ul>
                                            <br />
                                        </>
                                    ) : (
                                        <>
                                            <p>RocketReach did not find any valid mobile numbers for this person.</p>
                                            <Button onClick={searchPhoneNumberViaLusha}>Run Lusha Phone Lookup</Button>
                                            <br />
                                            {lusha && lusha.phoneNumbers && lusha.phoneNumbers.length > 0 ? (
                                                <>
                                                    <strong style={{ fontSize: "0.85rem" }}>
                                                        Lusha Results Found: (click to verify and save)
                                                    </strong>
                                                    <ul>
                                                        {lusha.phoneNumbers.map((item, index) => {
                                                            return (
                                                                <li style={{ margin: "0" }} key={`${item.number}`}>
                                                                    <Button
                                                                        onClick={() => lookupPhoneEvent(item.number)}
                                                                    >
                                                                        {item.localizedNumber}
                                                                    </Button>
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </>
                                            ) : (
                                                <>{lusha.message && <p>{`Lusha Result: ${lusha.message}`}</p>}</>
                                            )}
                                        </>
                                    )}
                                </div>
                            </>
                        ) : (
                            <CircularProgress style={{ display: "block", margin: "0 auto" }} />
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={searchEmailsViaContactOut} disabled={emailsBtn}>
                        Find Emails for {person.name}
                    </Button>
                    <Button onClick={goBackToResults} disabled={backbutton}>
                        Back
                    </Button>
                    <Button onClick={closeModalEvent}>Close</Button>
                </DialogActions>
            </Dialog>
        );
    }
}
