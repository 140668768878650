import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import EmailField from "./EmailField";
import PhoneField from "./PhoneField";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
}));

function ContactFields(props) {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={2}>
                <TextField
                    autoComplete="prefix"
                    autoFocus
                    fullWidth
                    label="Prefix"
                    margin="normal"
                    name="prefix"
                    onChange={props.changeEvent}
                    value={props.prefix}
                    disabled={props.isReadOnly}
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <TextField
                    autoComplete="fname"
                    autoFocus
                    error={props.fname === ""}
                    fullWidth
                    id="fname"
                    label="First Name"
                    margin="normal"
                    name="fname"
                    onChange={props.changeEvent}
                    required
                    value={props.fname}
                    disabled={props.isReadOnly}
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <TextField
                    autoComplete="lname"
                    error={props.lname === ""}
                    fullWidth
                    id="lname"
                    label="Last Name"
                    margin="normal"
                    name="lname"
                    onChange={props.changeEvent}
                    required
                    value={props.lname}
                    disabled={props.isReadOnly}
                />
            </Grid>
            <Grid item xs={12} sm={2}>
                <TextField
                    autoComplete="mname"
                    autoFocus
                    fullWidth
                    id="mname"
                    label="Middle"
                    margin="normal"
                    name="mname"
                    onChange={props.changeEvent}
                    value={props.mname}
                    disabled={props.isReadOnly}
                />
            </Grid>
            <Grid item xs={12} sm={2}>
                <TextField
                    autoComplete="suffix"
                    autoFocus
                    fullWidth
                    label="Suffix"
                    margin="normal"
                    name="suffix"
                    onChange={props.changeEvent}
                    value={props.suffix}
                    disabled={props.isReadOnly}
                />
            </Grid>
            <Grid item xs={12} sm={5}>
                <EmailField changeEvent={props.changeEvent} value={props.email} isReadOnly={props.isReadOnly} />
            </Grid>
            <Grid item xs={12} sm={2}>
                <TextField
                    className={useStyles.textField}
                    disabled={props.isReadOnly}
                    fullWidth
                    label="Code"
                    margin="normal"
                    name="country_code"
                    onChange={props.changeEvent}
                    select
                    value={props.country_code ? props.country_code : ""}
                >
                    {props.countries.map((code, index) => (
                        <MenuItem key={index} value={code.dial_code}>
                            {code.name} ({code.dial_code})
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12} sm={3}>
                <PhoneField
                    changeEvent={props.phoneChangeEvent}
                    errorBoolean={props.phone === ""}
                    value={props.phone}
                    isReadOnly={props.isReadOnly}
                />
            </Grid>
            <Grid item xs={12} sm={2}>
                <TextField
                    autoComplete="extension"
                    autoFocus
                    fullWidth
                    label="Extension"
                    margin="normal"
                    name="extension"
                    onChange={props.changeEvent}
                    value={props.extension || ""}
                    disabled={props.isReadOnly}
                />
            </Grid>
        </Grid>
    );
}
export default ContactFields;
