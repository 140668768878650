import React from "react";
import moment from "moment";
import SuffixData from "./data/suffixes.json";
import { add, intervalToDuration } from "date-fns";

const Suffixes = SuffixData.suffixes;

export const doctorNameFormat = (pre, fn, ln, suf) => {
    const doctor = ["DR", "DR."];
    let suffix = [];
    // Create a suffix array from suffix string
    if (suf.indexOf(",") !== -1) {
        suffix = suf.split(",");
    } else {
        suffix.push(suf);
    }
    // Clean the suffix array
    const cleanSuffixes = suffix.map((s) => s.toString().toUpperCase().trim().replace(/\s+/g, ""));
    // Check for a Dr. prefix OR if anything in the suffix array matches the Dr. Suffix list
    if ((pre !== "" && doctor.includes(pre.toUpperCase())) || cleanSuffixes.some((r) => Suffixes.includes(r))) {
        return `Dear Dr. ${ln}`;
    }
    return `Dear ${fn}`;
};

export const extractIndex = (str) => {
    if (str !== "" && str !== "undefined") {
        return parseInt(str.match(/(\d+)/)[0], 10);
    }
    return "Undefined";
};
export const extractNameString = (str) => {
    if (str !== "" && str !== "undefined") {
        return str.match(/[a-zA-Z\_]+/g)[0];
    }
    return "Undefined";
};

export const truncateString = (str, num) => {
    // If the length of str is less than or equal to num
    // just return str--don't truncate it.
    if (str.length <= num) {
        return str;
    }
    // Return str truncated with '...' concatenated to the end of str.
    return str.slice(0, num) + "...";
};

export const basicDateTimeStamp = (str) => {
    return moment(str).format("MMM. Do, YYYY, h:mm A");
};

export const basicDateStamp = (str) => {
    return moment(str).format("MM/DD/YYYY");
};

export const moreBasicDateTimeStamp = (str) => {
    return moment(str).format("MM/DD/YYYY h:mmA");
};

export const isValidEmail = (email) => {
    const regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
};

export const trimString = (str) => {
    return str.replaceAll(/\s/g, "");
};

export const formatPhoneNumber = (str) => {
    const trimmed_str = str.replaceAll(/\s/g, "");
    if (
        trimmed_str !== "" &&
        trimmed_str !== null &&
        trimmed_str !== "undefined" &&
        trimmed_str.length > 9 &&
        trimmed_str.length < 11
    ) {
        // console.log("Utils :: formatPhoneNumber :: ", str);
        const cleaned = `${str}`.replace(/\D/g, "");
        const formatted = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        return `(${formatted[1]}) ${formatted[2]}-${formatted[3]}`;
    }
    return trimmed_str;
};

export const stripHtml = (str) => {
    const div = document.createElement("div");
    div.innerHTML = str;
    const clean = div.textContent || div.innerText || "";
    return clean;
};

export const stripAllSpaces = (str) => {
    const trimmedString = str.replaceAll(/\s/g, "");
    return trimmedString;
};

export const isNumber = (str) => {
    let num = "";
    const numberStr = stripAllSpaces(str);
    if (numberStr !== "") {
        num = parseInt(numberStr, 10);
    }
    return Number.isInteger(num);
};

export const numberFormat = (val, decimals) => {
    // Parse the value as a float value
    const num = parseFloat(val);
    // Format the value w/ the specified number
    // of decimal places and return it.
    return num.toFixed(decimals);
};

export const numberWithCommas = (num) => {
    if (num) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
        return "";
    }
};

export const calculatePercentage = (num1, num2) => {
    let percentage = 0;
    if (parseInt(num2, 10) > 0) {
        percentage = (parseInt(num1, 10) / parseInt(num2, 10)) * 100;
    }
    return numberFormat(percentage, 1);
};

export const calculateAverage = (num1, num2) => {
    let average = 0;
    if (parseInt(num2, 10) > 0) {
        average = parseInt(num2, 10) / parseInt(num1, 10);
    }
    return numberFormat(average, 3);
};

export const remainingNumber = (large, small) => {
    return Math.floor(large - small);
};

export const stripNonNumericChars = (string) => {
    return string.replace(/\D/g, "");
};

export const string2ArrBuffer = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
};

export const removeSpacesBeforeAfterCommas = (v) => {
    const str = v.replace(/\s*,\s*/g, ",");
    return str;
};

export const removeNewLines = (str) => {
    return str.replace(/\n/g, "");
};

export const capitalizeFirstLetter = (str) => {
    return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
};

export const isEmptyOrSpaces = (str) => {
    return str === null || str.match(/^ *$/) !== null;
};

export const generateOptedOutData = (optedOutArray, projectId = null) => {
    if (optedOutArray?.length > 0) {
        const optoutData = {
            isPermanent: false,
            mostRecentSnooze: null,
            isOptedOutOfAnyProject: false,
            isOptedOutOfThisProject: false,
            projectTitles: new Set(),
            stillSnoozing: false,
            currentlyOptedOut: false,
        };

        for (const optout of optedOutArray) {
            const { optout_type, start_datetime, project_title } = optout;

            if (optout_type === 1) {
                // easy option, permanent optout
                optoutData.isPermanent = true;
            } else if (optout_type === 2) {
                // figure out the most recent snooze
                if (start_datetime > optoutData.mostRecentSnooze || !optoutData.mostRecentSnooze)
                    optoutData.mostRecentSnooze = start_datetime;
            } else if (optout_type === 3) {
                // more complicated because ExpertPreview isn't always on a project level
                // so we either need to see if this is a project level optpout
                // or we add all opted out project titles to the array
                optoutData.isOptedOutOfAnyProject = true;
                if (projectId && projectId === optout.project_id) {
                    // check if we're dealing w/ the project level
                    optoutData.isOptedOutOfThisProject = true;
                }

                // otherwise we list all project tilles they're opted out of
                optoutData.projectTitles.add(project_title);
            }
        }

        // checking to see if the expert is still snoozing
        const endOfSnooze = add(new Date(optoutData.mostRecentSnooze), { months: 1 });
        optoutData.stillSnoozing = endOfSnooze > new Date();

        // this is how we know if an expert is currently opted out or not
        optoutData.currentlyOptedOut =
            optoutData.isPermanent ||
            optoutData.isOptedOutOfAnyProject ||
            (optoutData.stillSnoozing && optoutData.mostRecentSnooze);

        return optoutData;
    }

    return {};
};

export const renderOptedOutText = (expert) => {
    const {
        isOptedOutOfThisProject,
        isOptedOutOfAnyProject,
        projectTitles,
        isPermanent,
        stillSnoozing,
        mostRecentSnooze,
    } = expert.opted_out_info;

    const { days: daysLeft } = intervalToDuration({
        start: add(new Date(mostRecentSnooze), { days: 30 }),
        end: new Date(),
    });

    const returnArr = [];

    if (isPermanent) {
        returnArr.push(
            <div key={returnArr.length} style={{ marginBottom: "0.5rem" }}>
                Opted out permanently
            </div>
        );
    }

    if (isOptedOutOfThisProject) {
        returnArr.push(
            <div key={returnArr.length} style={{ marginBottom: "0.5rem" }}>
                Opted out of **THIS** project
            </div>
        );
    }

    if (isOptedOutOfAnyProject) {
        // turn set values into an array
        const projectTitlesArr = Array.from(projectTitles);

        returnArr.push(
            <div key={returnArr.length} style={{ marginBottom: "0.5rem" }}>{`DNC Project Names: ${projectTitlesArr.join(
                ", "
            )}`}</div>
        );
    }

    if (stillSnoozing) {
        returnArr.push(<div key={returnArr.length}>{`DNC for: ${daysLeft} more days`}</div>);
    }

    return returnArr;
};

export const renderOptedOutEmojis = (expert) => {
    const { isPermanent, isOptedOutOfThisProject, stillSnoozing } = expert.opted_out_info;

    if (isPermanent) {
        return "😡";
    } else if (isOptedOutOfThisProject) {
        return "🤔";
    } else if (stillSnoozing) {
        return "😴";
    }
};

export default {
    doctorNameFormat,
    isValidEmail,
    formatPhoneNumber,
    stripHtml,
    numberFormat,
    numberWithCommas,
    calculatePercentage,
    calculateAverage,
    stripAllSpaces,
    isNumber,
    remainingNumber,
    basicDateTimeStamp,
    extractIndex,
    extractNameString,
    truncateString,
    stripNonNumericChars,
    string2ArrBuffer,
    removeSpacesBeforeAfterCommas,
    removeNewLines,
    capitalizeFirstLetter,
    isEmptyOrSpaces,
    generateOptedOutData,
    renderOptedOutText,
    renderOptedOutEmojis,
    moreBasicDateTimeStamp,
};
