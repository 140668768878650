import React, { useState, useContext, useEffect, useCallback, memo } from "react";
import Container from "@mui/material/Container";
import useSWR from "swr";
import axios from "axios";
// import { useTheme } from "@emotion/react";
import { authContext } from "../context/auth";
import { Grid, Typography, Box } from "@mui/material";
import ExpertDataTable from "../components/ExpertDataTable";
import ProjectDescription from "../components/ProjectDescription";
import ProjectDescriptionHeader from "../components/ProjectDescriptionHeader";
import { Drawer } from "@mui/material";
import ProjectExpertFilterList from "../components/ProjectExpertFilterList";
import EditSurveyModal from "../components/EditSurveyModal";
import ProjectCommentsModal from "../components/ProjectCommentsModal";
import ExpertStatusChange from "../components/ExpertStatusChange";
import BulkOutReachEmails from "../components/BulkOutReachEmails";
import SendExpertEmail from "../components/SendExpertEmail";
import ChangeMultipleStatuses from "../components/ChangeMultipleStatuses";
import DefaultOutreachEmails from "../components/DefaultOutreachEmails";
import SendScreener from "../components/SendScreener";
import AddRemoveTags from "../components/AddRemoveTags";
import AttachToProjectv2 from "../components/AttachToProjectv2";
import FlashMessagev2 from "../components/FlashMessagev2";
import ExpertPreviewModal from "../components/ExpertPreviewModal";
import ExpertDataTableToolbar from "../components/ExpertDataTableToolbar";
import LinearLoader from "../components/LinearLoader";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import EmailConfirmation from "../components/EmailConfirmation";
import { generateOptedOutData } from "../Utils";

const fetcher = (...args) => fetch(...args).then((res) => res.json());
const now = new Date().toISOString();

export default function ProjectShow(props) {
    // const theme = useTheme();
    const { auth } = useContext(authContext);
    const storageKey = "default_" + props.match.params.id;

    // DEBUG START
    // TODO: TEMPORARY DATA FETCHING FOR MODALS, MOVE TO DYNAMIC ROUTE PATTERN INSIDE MODAL

    // TODO - pull correct data for description
    // DEBUG START
    const projectData = {
        id: props.match.params.id,
        survey_id: "", // TODO - In bulkAddScreenerEvent an ID is passed for this to call. Handle
        pid: props.match.params.id ? props.match.params.id : "",
        rmid: auth.data ? auth.data.id : "",
        rmemail: auth.data ? auth.data.mail : "",
        rmfname: auth.data ? auth.data.fname : "",
        rmlname: auth.data ? auth.data.lname : "",
        rmphone: auth.data ? formatPhoneNumber(auth.data.number) : "",
        use: "both", // Staticly assigned for this table
    };

    console.log(projectData, " :: projectData");

    // added the userId to be passed to the parse CSV function in the projectRoute file
    // the reason is, because we need the id to parse the imperosnation data
    const { data, error } = useSWR(
        `/api/project/getProjectData?id=${projectData.id}&survey_id=${projectData.survey_id}&pid=${projectData.pid}&rmid=${projectData.rmid}&rmemail=${projectData.rmemail}&rmfname=${projectData.rmfname}&rmlname=${projectData.rmlname}&rmphone=${projectData.rmphone}&use=${projectData.use}&userId=${auth.data.id}`,
        fetcher,
        {
            revalidateOnFocus: false,
            revalidateIfStale: false,
        }
    );

    // DEBUG END

    // Modal States
    const [filterModal, setfilterModal] = useState(false);
    const [editSurveyModal, setEditSurveyModal] = useState(false);
    const [commentsModal, setCommentsModal] = useState(false);
    const [expertStatusModal, setExpertStatusModal] = useState(false);
    const [outreachModal, setOutreachModal] = useState(false);
    const [sendExpertEmailModal, setSendExpertEmailModal] = useState(false);
    const [changeMultipleStatusesModal, setChangeMultipleStatusesModal] = useState(false);
    const [defaultOutreachEmailsModal, setDefaultOutreachEmailsModal] = useState(false);
    const [sendScreenerModal, setSendScreenerModal] = useState(false);
    const [addRemoveTagsModal, setAddRemoveTagsModal] = useState(false);
    const [attachToProjectModal, setAttachToProjectModal] = useState(false);
    const [expertPreviewModal, setExpertPreviewModal] = useState(false);
    const [debugModal, setDebugModal] = useState(false);
    const [emailConfirmationModal, setEmailConfirmationModal] = useState(false);
    const [circularLoaderOpen, setCircularLoaderOpen] = useState(false);

    // Data States
    const [expertsData, setExpertsData] = useState(false);
    const [preFilterExpertsData, setPreFilterExpertsData] = useState(false);

    // Filter State
    const [statuses, setMenuFilters] = useState({
        prescreen_sent: false,
        terms_sent: false,
        terms_signed: false,
        terms_not_signed: false,
        invited: false,
        accepted: false,
        declined: false,
        sent_to_client: false,
        selected_by_client: false,
        rejected_by_client: false,
        compliance_sent: false,
        compliance_approved: false,
        compliance_rejected: false,
        scheduling_needed: false,
        scheduled: false,
        call_completed: false,
        payment_needed: false,
        payment_requested: false,
        paid: false,
        selected: [],
    });

    // Expert State
    const [expert, setExpert] = useState({
        created_by: auth.data.id,
        id: "",
        name: "",
        call_duration: 0,
        consultation_rate: "",
        consultation_rate_currency: "",
        prescreen_sent: false,
        attached_lead: true,
        attached_expert: false,
        tnc_sent: false,
        tnc_signed: false,
        tnc_not_signed: false,
        invited: false,
        accepted: false,
        declined: false,
        sent_to_client: false,
        selected_by_client: false,
        rejected_by_client: false,
        sent_to_compliance: false,
        compliance_approved: false,
        compliance_rejected: false,
        needs_scheduling: false,
        scheduled: false,
        call_complete: false,
        needs_payment: false,
        payment_requested: false,
        paid: false,
        terms: false,
        expertResponse: false,
        clientResponse: false,
        complianceResponse: false,
        schedulingResponse: false,
        payment: false,
        token: "",
    });

    const [status, setStatus] = useState({
        prescreen_sent_dirty: false,
        attached_lead_dirty: false,
        attached_expert_dirty: false,
        tnc_sent_dirty: false,
        tnc_signed_dirty: false,
        tnc_not_signed_dirty: false,
        invited_dirty: false,
        accepted_dirty: false,
        declined_dirty: false,
        sent_to_client_dirty: false,
        selected_by_client_dirty: false,
        rejected_by_client_dirty: false,
        sent_to_compliance_dirty: false,
        compliance_approved_dirty: false,
        compliance_rejected_dirty: false,
        needs_scheduling_dirty: false,
        scheduled_dirty: false,
        call_complete: false,
        needs_payment_dirty: false,
        payment_requested_dirty: false,
        paid_dirty: false,
        is_dirty: [],
    });

    const [sent, setEmailSentDate] = useState({
        first: false,
        date: now,
    });

    const [expertTnc, setExpertEmailData] = useState({
        id: "",
        prefix: "",
        fname: "",
        lname: "",
        suffix: "",
        email: "",
        token: "",
        tnc_sent: false,
    });

    const [csv, setCSV] = useState({
        project: [],
        all_experts: [],
        selected_experts: [],
    });

    const [filterActive, setFilterActive] = useState(false);

    // Data Table Selected Experts
    const [selectedExperts, setSelectedExperts] = useState([]);

    // Project data
    const [projectState, setProjectState] = useState({
        id: "",
        isdeleted: false,
        title: "",
        description: "",
        email_copy: "",
        project_not_received_by_email: true,
        client_id: "",
        client_name: "",
        primary_client_contact: "",
        additional_client_contacts: [],
        expert_ids: [],
        primary_project_manager: [],
        additional_project_managers: [],
        status: "1",
        status_id: 1,
        type: "",
        priority: "4",
        case_code: "",
        calls_planned: "",
        price_per_unit: "",
        created_on: "",
        industry: "",
        conference_bridge_setting: "1",
        twilio_bridge_setting: "1",
        external_bridge_numbers: [],
        notifications: [],
        specialty: [],
        tags: [],
        comments: [],
        responses: [],
        scheduled_calls: [],
        completed_calls: [],
        experts: [],
        surveys: [],
        unpublishedSurveys: [],
        custom_charge: 0,
        custom_hourly_rate: 1.0,
        minimum_bonus: 1,
        effort_index: 0.1,
        premium_pricing_model_id: 0,
        grace_period: 3,
        duration_model: 0,
        fair_market_value: 0,
        budget: 0,
        experts_to_be_outreached: [],
    });

    const [template, setTemplate] = useState("outreach");
    const [emailContent, setEmailContent] = useState("");
    const [selectedSurvey, setSelectedSurvey] = useState({
        id: "",
        token: "",
    });

    const [strategic, setIsStrategic] = useState(false);
    const [selected, setSelected] = useState([]);
    const [filter, setFilterResults] = useState({
        id: 0,
        selected: [],
        experts: [],
    });

    const [bulkTags, setBulkTags] = useState({
        tags: [],
        people: [],
    });

    // const [postview, setSingleEmailPostview] = useState({
    //     expert_id: 0,
    //     expert_fname: "",
    //     expert_lname: "",
    //     expert_email: "",
    //     project_id: 0,
    //     user_id: 0,
    //     user_fname: "",
    //     user_lname: "",
    //     from_email: "",
    //     email_subject: "",
    //     email_body: "",
    // });
    const [token, setToken] = useState("");
    const [onboardLink, setOnboardLink] = useState("");
    const [saveSuccess, setSaveStatusDisable] = useState(false);
    const [bulkOutreach, setOutreachTemplate] = useState(false);
    const [success, setSuccess] = useState(false);
    const [emailTemplate, setEmailTemplate] = useState(false);
    const [boolFilter, setBoolFilter] = useState(false);
    const [singleConfirmation, setEmailSentConfirmation] = useState(false);
    const [postView, setSingleEmailPostView] = useState({
        expert_id: 0,
        expert_fname: "",
        expert_lname: "",
        expert_email: "",
        project_id: 0,
        user_id: 0,
        user_fname: "",
        user_lname: "",
        from_email: "",
        email_subject: "",
        email_body: "",
    });
    // Expert Preview
    const [expertIndex, setExpertIndex] = useState(0);
    const [expertPreviewData, setExpertPreviewData] = useState({
        id: "",
        prefix: "",
        fname: "",
        mname: "",
        lname: "",
        headline: "",
        biography: "",
        suffix: "",
        country: "",
        state: [],
        city: [],
        address: "",
        zipcode: "",
        timezone: [],
        us: false,
        country_code: "",
        phone: "",
        extension: "",
        alternate_phones: [],
        alternate_emails: [],
        email: "",
        linkedin_url: "",
        employment: [],
        current_employment: [],
        past_employment: [],
        education: [],
        industry: "Healthcare",
        specialty: [],
        terms_signed: false,
        terms_version: "",
        terms_date: "",
        terms_origin: "",
        disclosures: false,
        tutorial_completed: false,
        tutorial_date: "",
        status: "Lead",
        dnc: "",
        created_by: 0,
        created_by_name: "",
        created_on: "",
        updated_on: "",
        tags: [],
        selectedCountry: null,
        minimum_hourly: 0,
        consultation_rate: "",
        consultation_rate_currency: "USD",
        group_meeting_rate: "",
        group_meeting_rate_currency: "USD",
        point_person_id: "",
        background_check: false,
        comments: [],
        person_id: 0,
        notes: "",
        blinded_bio: "",
        opted_out: false,
        disclosure_questions: [],
        onboarding: [],
    });
    const [selectedExpertId, setSelectedExpertId] = useState(0);
    // Error Handling
    const [errorModal, setErrorModal] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const errorMessageTimeout = 10000; // ms

    // Success Handling
    const [successModal, setSuccessModal] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");
    const successMessageTimeout = 5000; // ms

    // Comment Data
    const [commentData, setCommentData] = useState({
        comments: [],
    });

    const [selectedIndexes, setSelectedIndexes] = useState([]);
    // Compile States

    const _state = {
        get: {
            statuses: statuses,
            expertsData: expertsData,
            preFilterExpertsData: preFilterExpertsData,
            expert: expert,
            status: status,
            sent: sent,
            expertTnc: expertTnc,
            csv: csv,
            selectedExperts: selectedExperts,
            projectState: projectState,
            template: template,
            emailContent: emailContent,
            selectedSurvey: selectedSurvey,
            strategic: strategic,
            selected: selected,
            filter: filter,
            onboardLink: onboardLink,
            bulkOutreach: bulkOutreach,
            success: success,
            bulkTags: bulkTags,
            filterActive: filterActive,
            expertIndex: expertIndex,
            expertPreviewData: expertPreviewData,
            selectedExpertId: selectedExpertId,
            commentData: commentData,
            emailTemplate: emailTemplate,
            boolFilter: boolFilter,
            singleConfirmation: singleConfirmation,
            postView: postView,
            token: token,
            selectedIndexes: selectedIndexes,
            commentsModal: commentsModal,
        },

        set: {
            expertsData: (data) => {
                function setAcceptedSort(exp) {
                    let order = 0;

                    if (exp.dnc || exp.opted_out) order = 1;

                    // eslint-disable-next-line guard-for-in
                    for (const i in exp.expertResponse[0]) {
                        // console.log(exp.expertResponse[i].id)
                        if (exp.expertResponse[0][i].id == 3) order = 3; // Sent response to client
                        if (exp.expertResponse[0][i].id == 4) order = 4; // Accepted
                        if (exp.expertResponse[0][i].id == 5) order = 2; // Declined
                    }

                    return order;
                }
                // eslint-disable-next-line guard-for-in
                for (const i in data) {
                    data[i] = {
                        ...data[i],
                        fullname: `${data[i].fname} ${data[i].lname}`,
                        fullattachedbyname: `${data[i].attached_by_fname} ${data[i].attached_by_lname}`,
                        accepted_sort: setAcceptedSort(data[i]),
                        converted_sort: data[i].expert_status === "Expert" ? 1 : 0,
                    };

                    // console.log(data[i]);
                }

                for (const expert of data) {
                    expert.opted_out_info = generateOptedOutData(expert.optouts, expert.project_id);
                }

                setExpertsData(data);
            },

            preFilterExpertsData: (data) => {
                setPreFilterExpertsData(data);
            },

            menuFilters: (data) => {
                setMenuFilters(data);
            },

            expert: (data) => {
                setExpert(data);
            },

            status: (data) => {
                setStatus(data);
            },

            emailSentDate: (data) => {
                setEmailSentDate(data);
            },
            expertEmailData: (data) => {
                setExpertEmailData(data);
            },

            csv: (data) => {
                setCSV(data);
            },
            selectedExperts: (data) => {
                setSelectedExperts(data);
            },
            projectState: (data) => {
                setProjectState(data);
            },
            template: (data) => {
                setTemplate(data);
            },
            emailContent: (data) => {
                setEmailContent(data);
            },
            selectedSurvey: (data) => {
                setSelectedSurvey(data);
            },
            strategic: (data) => {
                setIsStrategic(data);
            },
            selected: async (data) => {
                setSelected(data);
            },
            filterResults: async (data) => {
                setFilterResults(data);
                console.log("filter results set:", data);
            },

            onboardLink: (data) => {
                setOnboardLink(data);
            },
            outreachTemplate: (data) => {
                setOutreachTemplate(data);
            },
            success: (data) => {
                setSuccess(data);
            },
            bulkTags: (data) => {
                setBulkTags(data);
            },

            errorMsg: (data) => {
                setErrorMsg(data);
            },
            filterActive: (data) => {
                setFilterActive(data);
            },
            expertIndex: (data) => {
                setExpertIndex(data);
            },
            expertPreviewData: (data) => {
                setExpertPreviewData(data);
            },
            selectedExpertId: (data) => {
                setSelectedExpertId(data);
            },
            commentData: (data) => {
                setCommentData(data);
            },
            emailTemplate: (data) => {
                setEmailTemplate(data);
            },
            boolFilter: (data) => {
                setBoolFilter(data);
            },
            emailSentConfirmation: (data) => {
                setEmailSentConfirmation(data);
            },
            singleEmailPostView: (data) => {
                setSingleEmailPostView(data);
            },
            token: (data) => {
                setToken(data);
            },
            selectedIndexes: (data) => {
                setSelectedIndexes(data);
            },
            openCircularLoader: (boolean) => {
                setCircularLoaderOpen(boolean);
            },
        },
        switch: {
            emailConfirmation: () => {
                setEmailConfirmationModal(!emailConfirmationModal);
            },
            filterModal: () => {
                setfilterModal(!filterModal);
            },
            editSurveyModal: () => {
                setEditSurveyModal(!editSurveyModal);
            },

            commentsModal: () => {
                setCommentsModal(!commentsModal);
            },

            expertStatusModal: (event, eid) => {
                setExpertStatusModal(!expertStatusModal);
                console.log("Expert ID: ", eid);
                const exp = expertsData.filter((item) => item.id === eid);
                generateToken(eid, null);
                console.log(exp[0]);
                populateExpertStatuses(exp[0]);
            },

            sendScreenerModal: () => {
                setSendScreenerModal(!sendScreenerModal);
                // console.log(sendScreenerModal);
            },

            outreachModal: () => {
                setOutreachModal(!outreachModal);
            },

            addRemoveTagsModal: () => {
                setAddRemoveTagsModal(!addRemoveTagsModal);
            },

            attachToProjectModal: () => {
                setAttachToProjectModal(!attachToProjectModal);
            },

            debugModal: () => {
                setDebugModal(!debugModal);
            },

            sendExpertEmailModal: () => {
                setSendExpertEmailModal(!sendExpertEmailModal);
            },

            changeMultipleStatusesModal: () => {
                setChangeMultipleStatusesModal(!changeMultipleStatusesModal);
            },

            defaultOutreachEmailsModal: () => {
                setDefaultOutreachEmailsModal(!defaultOutreachEmailsModal);
            },

            errorModal: () => {
                setErrorModal(!errorModal);
            },
            expertPreviewModal: (event, eid) => {
                if (!expertPreviewModal) {
                    console.log("Expert ID: ", eid);
                    const exp = expertsData.filter((item) => item.id === eid);
                    generateToken(eid, null);
                    console.log(exp[0]);
                    populateExpertStatuses(exp[0]);
                    setSelectedExpertId(eid);
                    setExpertPreviewModal(true);
                } else {
                    setExpertPreviewModal(false);
                }
            },
        },
        props: {
            storageKey: storageKey,
        },

        templates: {
            screenerTemplate: `<br /><p>Thank you for your interest in this consultation. Our client is from a/an [insert investment firm, boutique consulting firm, health policy firm, health care research firm, equity research, pharmaceutical company] and they are conducting research to learn more about <strong>${projectState.description}</strong>. This will be a 30-60 minute paid phone consultation.<br /><br />I have included the next steps to get started on this consultation. The <strong>{{SCREENER_LINK}}</strong> will take you through a few questions that will give our client a better idea of your experience and give you a better idea of what they will be interested in discussing during the phone consultation.</p>`,
        },

        flash: {
            error: (message) => {
                const newMsg = message + errorMsg;
                setErrorMsg(newMsg);

                if (!errorModal) {
                    setErrorModal(true);
                    setTimeout(() => {
                        setErrorModal(false);
                        setErrorMsg("");
                    }, errorMessageTimeout);
                }
            },
            success: (message) => {
                const newMsg = message + successMsg;
                setSuccessMsg(newMsg);

                if (!successModal) {
                    setSuccessModal(true);
                    setTimeout(() => {
                        setSuccessModal(false);
                        setSuccessMsg("");
                    }, successMessageTimeout);
                }
            },
        },
    };

    function formatPhoneNumber(str) {
        const cleaned = `${str}`.replace(/\D/g, "");
        const formatted = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (formatted) {
            return `(${formatted[1]}) ${formatted[2]}-${formatted[3]}`;
        }
        return str;
    }

    // TODO: Move parseCSVData to route
    const parseCSVData = (expertData) => {
        console.log("====== in parseCSVData ======");
        if (expertData.data && expertData.data.csvData) {
            const { experts } = expertData.data.csvData;
            if (experts.length === 0) return;
            setCSV({
                ...csv,
                all_experts: experts.length === 0 ? [] : experts,
            });
            console.log("All Experts for CSV: ", expertData);
        }
    };

    const parseProjectData = (pData) => {
        console.log("PROJECT DATA: ", pData);
        if (pData.message && pData.data && pData.data.projectData) {
            if (pData.data.projectData.length === 0) return;
            const expertCount =
                pData.data.projectData.expertCount.length > 0
                    ? pData.data.projectData.expertCount[0].experts_count
                    : "0";
            const expertsAccepted =
                pData.data.projectData.expertsAccepted.length > 0
                    ? pData.data.projectData.expertsAccepted[0].experts_accepted
                    : "0";
            const screenedCount =
                pData.data.projectData.screenedCount.length > 0
                    ? pData.data.projectData.screenedCount[0].num_experts_screened
                    : "0";
            const onHoldCount =
                pData.data.projectData.onHoldCount.length > 0
                    ? pData.data.projectData.onHoldCount[0].num_experts_on_hold
                    : "0";
            const expertsScheduled =
                pData.data.projectData.expertsScheduled.length > 0
                    ? pData.data.projectData.expertsScheduled[0].experts_scheduled
                    : "0";
            const expertsCompleted =
                pData.data.projectData.expertsCompleted.length > 0
                    ? pData.data.projectData.expertsCompleted[0].experts_completed
                    : "0";
            const sentToClient =
                pData.data.projectData.sentToClient.length > 0
                    ? pData.data.projectData.sentToClient[0].sent_to_client
                    : "0";
            const clientSelected =
                pData.data.projectData.clientSelected.length > 0
                    ? pData.data.projectData.clientSelected[0].client_selected
                    : "0";
            const sentToCompliance =
                pData.data.projectData.sentToCompliance.length > 0
                    ? pData.data.projectData.sentToCompliance[0].sent_to_compliance
                    : "0";
            const project = pData.data.projectData.projectData[0];
            const tags = pData.data.projectData.tags.map((tag) => {
                return { label: tag.name, value: tag.tag_id };
            });
            const specialty = pData.data.projectData.specialty.map((s) => {
                return { label: s.name, value: s.specialty_id };
            });
            const pcc = pData.data.projectData.clientContacts.filter((item) => item.isprimary == true);
            const acc = pData.data.projectData.clientContacts.filter((item) => item.isprimary !== true);
            const apms = pData.data.projectData.additionalProjectManagers;
            const { comments } = pData.data.projectData;

            const settings = pData.data.projectData.confBridgeSettings;
            const primary = settings.filter((item) => item.isprimary !== false);
            let selectedTypeID = "1";
            let selectedTwilio = "1";
            let extNumbers = [];
            if (primary.length > 0) {
                const primaryData = primary[0];
                selectedTypeID = primaryData.type_id.toString();
                if (primaryData.data && primaryData.data.value !== undefined) {
                    selectedTwilio = primaryData.data.value;
                }
                if (Array.isArray(primaryData.data) && primaryData.data.length > 0) {
                    extNumbers = primaryData.data;
                }
            }
            const allSurveys = pData.data.projectData.surveys;
            const surveys = allSurveys.filter((s) => s.published);
            const unpublishedSurveys = allSurveys.filter((s) => !s.published);
            const attachedExperts = pData.data.projectData.experts;
            // console.log("Project DATA: ", project);
            const custom_charge = project.custom_charge;
            const custom_hourly_rate = project.custom_hourly_rate;
            const minimum_bonus = project.minimum_bonus;
            const effort_index = project.effort_index;
            const premium_pricing_model_id = project.premium_pricing_model_id;
            const grace_period = project.grace_period;
            const duration_model_id = project.duration_model_id;
            const fair_market_value = project.fair_market_value;
            const budget = project.budget;

            setProjectState({
                ...data,
                id: project.id,
                isdeleted: project.isdeleted,
                title: project.title,
                description: project.description,
                email_copy: project.email_copy,
                project_not_received_by_email: project.project_not_received_by_email,
                client_id: project.client_id,
                client_name: project.client_name,
                primary_client_contact: pcc[0],
                additional_client_contacts: acc,
                expert_ids: project.expert_ids,
                primary_project_manager: `${project.project_manager_fname} ${project.project_manager_lname}`,
                additional_project_managers: apms,
                status: project.project_status,
                status_id: project.project_status_id,
                type: project.project_type,
                priority: project.project_priority,
                case_code: project.case_code,
                calls_planned: project.calls_planned,
                price_per_unit: project.price_per_unit,
                created_on: project.created_on,
                industry: project.industry,
                conference_bridge_setting: selectedTypeID,
                twilio_bridge_setting: selectedTwilio,
                external_bridge_numbers: extNumbers,
                notifications: [],
                specialty,
                tags,
                comments,
                responses: project.responses,
                scheduled_calls: project.scheduled_calls,
                completed_calls: project.completed_calls,
                experts: attachedExperts,
                unpublishedSurveys,
                surveys,
                expertCount,
                expertsAccepted,
                expertsScheduled,
                expertsCompleted,
                sentToClient,
                clientSelected,
                sentToCompliance,
                custom_charge,
                custom_hourly_rate,
                minimum_bonus,
                effort_index,
                premium_pricing_model_id,
                screenedCount,
                onHoldCount,
                grace_period,
                duration_model_id,
                fair_market_value,
                budget,
            });
            setFilterResults({
                ...filter,
                experts: attachedExperts,
            });
            // re-evaluate selected experts for button state on re-load/fetch
            if (selected.length < 1) {
                // setActiveBtn(true);
                // setStickyActions("none");
            } else {
                // setActiveBtn(false);
                // setStickyActions("block");
            }

            // if (localStorage.getItem(storageKey) !== null) {
            //     console.log("LocalStorage Valid? ", localStorage.getItem(storageKey));
            //     const tagArray = localStorage
            //         .getItem(storageKey)
            //         .split(", ")
            //         .map((tagId) => {
            //             return parseInt(tagId);
            //         });
            //     const localBoolFilter = localStorage.getItem("filter");
            //     console.log(localBoolFilter);
            //     setBoolFilter(localBoolFilter);
            //     // filterResultsOnLoad(tagArray, localBoolFilter, attachedExperts);
            // }
            // Create new expert array of object to populate the expertlist autocomplete...
            attachedExperts.forEach(function (e) {
                const expertObj = {
                    value: e.id,
                    label: `${e.fname} ${e.lname}`,
                };
                // autocompleteExpertsArray.push(expertObj);
            });
            // setSearchableExperts(autocompleteExpertsArray);
            // console.log("EXPERTS FOR AUTOCOMPLETE: ", autocompleteExpertsArray);
        }
    };

    const generateToken = useCallback(async (id, sid) => {
        const url = "/api/expert/generateTokenById";
        const postData = {
            id,
            survey_id: sid ? sid : "",
            pid: props.match.params.id ? props.match.params.id : "",
            rmid: auth.data ? auth.data.id : "",
            rmemail: auth.data ? auth.data.mail : "",
            rmfname: auth.data ? auth.data.fname : "",
            rmlname: auth.data ? auth.data.lname : "",
            rmphone: auth.data ? formatPhoneNumber(auth.data.number) : "",
        };
        // console.log("POST INDIVIDUAL TOKEN DATA: ", postData);
        return await axios
            .post(url, postData, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    // console.log("Token generated? ", result.data.data);
                    const tncLink = `https://firstthought.io/onboard/${result.data.data}`;
                    setOnboardLink(tncLink);
                    setToken(result.data.data);
                    return result.data.data;
                }
            })
            .catch((result) => {
                console.log("Something went wrong, API Error, 404 Error:", result);
            });
    }, []);

    const filterOneResponse = (expertResponseId) => {
        if (expertResponseId === 3) {
            return {
                invited: true,
                accepted: false,
                declined: false,
            };
        } else if (expertResponseId === 4) {
            return {
                invited: false,
                accepted: true,
                declined: false,
            };
        } else if (expertResponseId === 5) {
            return {
                invited: false,
                accepted: false,
                declined: true,
            };
        }
    };

    const filterTwoResponses = (expertResponses) => {
        if (expertResponses[0].id === 3) {
            if (expertResponses[1].id === 4) {
                return {
                    invited: false,
                    accepted: true,
                    declined: false,
                };
            } else {
                return {
                    invited: false,
                    accepted: false,
                    declined: true,
                };
            }
        } else {
            return {
                invited: false,
                accepted: true,
                declined: false,
            };
        }
    };

    const filterThreeResponses = () => {
        return {
            invited: false,
            accepted: true,
            declined: false,
        };
    };

    const filterExpertResponses = (expert) => {
        if (expert.expertResponse.length > 0) {
            if (expert.expertResponse[0].length === 1) {
                return filterOneResponse(expert.expertResponse[0][0].id);
            } else if (expert.expertResponse[0].length === 2) {
                return filterTwoResponses(expert.expertResponse[0]);
            } else if (expert.expertResponse[0].length === 3) {
                return filterThreeResponses();
            }
        }
    };

    function populateExpertStatuses(exp) {
        if (!exp) return;
        setSaveStatusDisable(false);
        console.log("EXPERT CLICKED", exp);
        const expertName = `${exp.fname} ${exp.lname}`;
        const expertResponse = filterExpertResponses(exp);
        // added invited, accepted, declined to always be false to reset radio buttons
        // put expertResponse at bottom to override defaults
        if (expertResponse) {
            setExpert({
                ...expert,
                id: exp.id,
                name: expertName,
                call_duration: exp.call_duration,
                attached_lead: true,
                attached_expert: false,
                invited: false,
                accepted: false,
                declined: false,
                prescreen_sent: exp.prescreen.length > 0 && exp.prescreen[0].filter((e) => e.id === 21).length > 0,
                tnc_sent: exp.terms.length > 0 && exp.terms.filter((e) => e.id === 15).length > 0,
                tnc_signed: exp.terms.length > 0 && exp.terms.filter((e) => e.id === 16).length > 0,
                tnc_not_signed: exp.terms.length > 0 && exp.terms.filter((e) => e.id === 14).length > 0,
                sent_to_client:
                    exp.clientResponse.length > 0 && exp.clientResponse[0].filter((e) => e.id === 6).length > 0,
                selected_by_client:
                    exp.clientResponse.length > 0 && exp.clientResponse[0].filter((e) => e.id === 7).length > 0,
                rejected_by_client:
                    exp.clientResponse.length > 0 && exp.clientResponse[0].filter((e) => e.id === 8).length > 0,
                sent_to_compliance:
                    exp.complianceResponse.length > 0 && exp.complianceResponse[0].filter((e) => e.id === 9).length > 0,
                compliance_approved:
                    exp.complianceResponse.length > 0 &&
                    exp.complianceResponse[0].filter((e) => e.id === 10).length > 0,
                compliance_rejected:
                    exp.complianceResponse.length > 0 &&
                    exp.complianceResponse[0].filter((e) => e.id === 11).length > 0,
                needs_scheduling:
                    exp.schedulingResponse.length > 0 &&
                    exp.schedulingResponse[0].filter((e) => e.id === 12).length > 0,
                scheduled:
                    exp.schedulingResponse.length > 0 &&
                    exp.schedulingResponse[0].filter((e) => e.id === 13).length > 0,
                call_complete:
                    exp.schedulingResponse.length > 0 &&
                    exp.schedulingResponse[0].filter((e) => e.id === 20).length > 0,
                needs_payment: exp.payment.length > 0 && exp.payment[0].filter((e) => e.id === 17).length > 0,
                payment_requested: exp.payment.length > 0 && exp.payment[0].filter((e) => e.id === 18).length > 0,
                paid: exp.payment.length > 0 && exp.payment[0].filter((e) => e.id === 19).length > 0,
                expert_screened: exp.screened.length > 0 && exp.screened[0].filter((e) => e.id === 28).length > 0,
                expert_on_hold: exp.screened.length > 0 && exp.screened[0].filter((e) => e.id === 31).length > 0,
                ...expertResponse,
            });
        } else {
            setExpert({
                ...expert,
                id: exp.id,
                name: expertName,
                call_duration: exp.call_duration,
                attached_lead: true,
                attached_expert: false,
                invited: false,
                accepted: false,
                declined: false,
                prescreen_sent: exp.prescreen.length > 0 && exp.prescreen[0].filter((e) => e.id === 21).length > 0,
                tnc_sent: exp.terms.length > 0 && exp.terms.filter((e) => e.id === 15).length > 0,
                tnc_signed: exp.terms.length > 0 && exp.terms.filter((e) => e.id === 16).length > 0,
                tnc_not_signed: exp.terms.length > 0 && exp.terms.filter((e) => e.id === 14).length > 0,
                sent_to_client:
                    exp.clientResponse.length > 0 && exp.clientResponse[0].filter((e) => e.id === 6).length > 0,
                selected_by_client:
                    exp.clientResponse.length > 0 && exp.clientResponse[0].filter((e) => e.id === 7).length > 0,
                rejected_by_client:
                    exp.clientResponse.length > 0 && exp.clientResponse[0].filter((e) => e.id === 8).length > 0,
                sent_to_compliance:
                    exp.complianceResponse.length > 0 && exp.complianceResponse[0].filter((e) => e.id === 9).length > 0,
                compliance_approved:
                    exp.complianceResponse.length > 0 &&
                    exp.complianceResponse[0].filter((e) => e.id === 10).length > 0,
                compliance_rejected:
                    exp.complianceResponse.length > 0 &&
                    exp.complianceResponse[0].filter((e) => e.id === 11).length > 0,
                needs_scheduling:
                    exp.schedulingResponse.length > 0 &&
                    exp.schedulingResponse[0].filter((e) => e.id === 12).length > 0,
                scheduled:
                    exp.schedulingResponse.length > 0 &&
                    exp.schedulingResponse[0].filter((e) => e.id === 13).length > 0,
                call_complete:
                    exp.schedulingResponse.length > 0 &&
                    exp.schedulingResponse[0].filter((e) => e.id === 20).length > 0,
                needs_payment: exp.payment.length > 0 && exp.payment[0].filter((e) => e.id === 17).length > 0,
                payment_requested: exp.payment.length > 0 && exp.payment[0].filter((e) => e.id === 18).length > 0,
                paid: exp.payment.length > 0 && exp.payment[0].filter((e) => e.id === 19).length > 0,
                expert_screened: exp.screened.length > 0 && exp.screened[0].filter((e) => e.id === 28).length > 0,
                expert_on_hold: exp.screened.length > 0 && exp.screened[0].filter((e) => e.id === 31).length > 0,
            });
        }
        // Check to see if TnC was sent, if so capture date
        if (exp.terms.length > 0 && exp.terms.filter((e) => e.id === 15).length > 0) {
            const termsSent = exp.terms.filter((e) => e.id === 15);
            console.log("TERMS SENT: ", termsSent[0]);
            setEmailSentDate({
                first: true,
                date: termsSent[0].email_sent ? formatDateString(termsSent[0].email_sent) : null,
            });
        }
    }

    // TODO: Move filtering to route
    useEffect(() => {
        if (data) {
            parseCSVData(data);
            parseProjectData(data);
        }
    }, [data]);
    return (
        <Container sx={{ overflow: "scroll", marginLeft: "10px" }} maxWidth={false} disableGutters>
            {/* Modals that are supplying their own data */}
            <ProjectCommentsModal open={commentsModal} state_manager={_state} data={data} error={error} props={props} />
            {/* Modals relying on data fetched inside of ProjectShow*/}
            {projectState.id !== "" && (
                <div>
                    <ProjectExpertFilterList open={filterModal} state_manager={_state} props={props} />
                    <EditSurveyModal
                        open={editSurveyModal}
                        state_manager={_state}
                        data={data}
                        error={error}
                        props={props}
                    />

                    <ExpertStatusChange
                        open={expertStatusModal}
                        state_manager={_state}
                        data={data}
                        error={error}
                        props={props}
                    />
                    <SendExpertEmail
                        open={sendExpertEmailModal}
                        state_manager={_state}
                        data={data}
                        error={error}
                        props={props}
                    />
                    <ChangeMultipleStatuses
                        open={changeMultipleStatusesModal}
                        state_manager={_state}
                        data={data}
                        error={error}
                        props={props}
                        func={{ filter: filterExpertResponses, populate: populateExpertStatuses }}
                    />
                    <BulkOutReachEmails
                        open={outreachModal}
                        state_manager={_state}
                        data={data}
                        error={error}
                        props={props}
                    />
                    <DefaultOutreachEmails
                        open={defaultOutreachEmailsModal}
                        state_manager={_state}
                        data={data}
                        error={error}
                        props={props}
                        isStrategic={strategic}
                    />
                    <SendScreener
                        open={sendScreenerModal}
                        state_manager={_state}
                        data={data}
                        error={error}
                        props={props}
                    />
                    <AddRemoveTags
                        open={addRemoveTagsModal}
                        state_manager={_state}
                        data={data}
                        error={error}
                        props={props}
                    />

                    <AttachToProjectv2
                        open={attachToProjectModal}
                        state_manager={_state}
                        data={data}
                        error={error}
                        props={props}
                        user={auth.data}
                    />
                    <ExpertPreviewModal
                        open={expertPreviewModal}
                        state_manager={_state}
                        data={data}
                        error={error}
                        props={props}
                    />
                </div>
            )}
            <EmailConfirmation state_manager={_state} selected={selected} props={props} open={emailConfirmationModal} />
            {selected.length > 0 && <ExpertDataTableToolbar state_manager={_state} selected={selected} props={props} />}

            {/* Flash Messages */}
            {errorModal && (
                <FlashMessagev2
                    error={errorModal}
                    severity={"error"}
                    message={errorMsg}
                    duration={errorMessageTimeout}
                    vertical={"top"}
                    onClose={() => {
                        setErrorModal(false);
                    }}
                />
            )}
            {successModal && (
                <FlashMessagev2
                    error={successModal}
                    severity={"success"}
                    message={successMsg}
                    duration={successMessageTimeout}
                    vertical={"top"}
                    onClose={() => {
                        setSuccessModal(false);
                    }}
                />
            )}
            {/* Initial Paint */}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ProjectDescriptionHeader state_manager={_state} props={props} experts={filter.experts} />
                </Grid>
                <Grid item xs={12}>
                    <ProjectDescription state_manager={_state} props={props} data={data} error={error} />
                </Grid>
                <Grid item xs={12}>
                    <Box
                        sx={
                            selected.length > 0
                                ? {
                                      marginBottom: "125px",
                                  }
                                : {}
                        }
                    >
                        {data ? (
                            <ExpertDataTable state_manager={_state} props={props} data={data} error={error} />
                        ) : (
                            <LinearLoader />
                        )}
                    </Box>
                </Grid>
            </Grid>
            {circularLoaderOpen && (
                <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={circularLoaderOpen}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
        </Container>
    );
}
