export const callTypes = [
    {
        value: 1,
        label: "Twilio",
    },
    {
        value: 2,
        label: "External Bridge",
    },
    {
        value: 3,
        label: "Direct/Manual",
    },
];

export const cancelReasons = [
    {
        value: 1,
        label: "Client no longer researching this topic",
    },
    {
        value: 2,
        label: "Client no longer available at scheduled time",
    },
    {
        value: 3,
        label: "Client putting this project on hold",
    },
    {
        value: 4,
        label: "Expert no longer available at scheduled time",
    },
    {
        value: 5,
        label: "Expert no longer interested in participating",
    },
    {
        value: 6,
        label: "Expert screened out post scheduling",
    },
    {
        value: 7,
        label: "Time zone issue",
    },
    {
        value: 8,
        label: "Scheduling mistake",
    },
    {
        value: 9,
        label: "Override Scheduling Credit",
    },
    {
        value: 10,
        label: "Other, please explain",
    },
];

export const entityTypes = [
    {
        value: 1,
        label: "Expert",
    },
    {
        value: 2,
        label: "Client",
    },
    {
        value: 3,
        label: "Moderator",
    },
    {
        value: 4,
        label: "Translator",
    },
    {
        value: 5,
        label: "Compliance",
    },
    {
        value: 6,
        label: "Client Contact",
    },
];
