import React, { useState } from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import { EditorState, Modifier } from "draft-js";
import ShareIcon from "@mui/icons-material/Share";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    buttonWrapper: {
        border: "1px solid #F1F1F1",
        padding: "5px",
        minWidth: "25px",
        height: "20px",
        borderRadius: "2px",
        margin: "0 4px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        background: "white",
        textTransform: "capitalize",
    },
}));

function CustomShareLink(props) {
    const { match, history } = props;
    const classes = useStyles();

    function addStreamShare() {
        const { editorState, onChange } = props;
        const contentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            "{{SHARE_LINK}}",
            editorState.getCurrentInlineStyle()
        );
        onChange(EditorState.push(editorState, contentState, "insert-characters"));
    }

    return (
        <div onClick={addStreamShare} className={classes.buttonWrapper} title="Stream Share Link">
            <ShareIcon />
        </div>
    );
}

CustomShareLink.propTypes = {
    match: PropTypes.object,
    onChange: PropTypes.func,
    editorState: PropTypes.object,
};
CustomShareLink.defaultProps = {
    match: null,
};

export default CustomShareLink;
