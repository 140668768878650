import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import MUIDataTable from "mui-datatables";
import { authContext } from "../../context/auth";
// import { Link } from "react-router-dom";
import { basicDateTimeStamp, truncateString, formatPhoneNumber } from "../../Utils";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import VerifiedIcon from "@mui/icons-material/Verified";
import StatusIcon from "@mui/icons-material/EditAttributes";
import Warning from "@mui/icons-material/Warning";
// import Delete from "@mui/icons-material/Delete";
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
// date-fns stuff
// import { add } from "date-fns";
import SendOnboardScreenerNow from "./SendOnboardScreenerNow";
import SendBulkOutreachNow from "./SendBulkOutreachNow";
import SnippetDialog from "../SnippetDialog";
// import ExpertPreviewWrapper from "../../components/wrappers/ExpertPreviewWrapper";
import ExpertPreviewWrapperNext from "../../components/wrappers/ExpertPreviewWrapperNext";
import GenerateIndividualTokens from "./GenerateIndividualTokens";
import ChangeStatus from "./SetExpertResponseStatus";
import ContactSearchModal from "./ContactSearchModal";
import PhoneLogsModal from "./PhoneLogsModal";
import { Chip } from "@mui/material";

// ======
// STYLES
// ======

const linkStyle = css`
    color: #8966ad;
    font-size: 1rem;
    font-weight: bold;
    padding: 0;
    text-align: left;
    line-height: 1.25rem;
`;

const smallLinkStyle = css`
    color: #8966ad;
    font-size: 0.85rem;
    font-weight: normal;
    padding: 0;
    text-align: left;
    line-height: 1.25rem;
    text-transform: Capitalize;
`;

const expiredTimeStyle = css`
    background-color: green;
    border-radius: 4px;
    color: #fff;
    font-size: 0.75rem;
    font-weight: bold;
    padding: 3px 5px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
`;

const resetButtonStyle = css`
    border-radius: 4px;
    color: #444;
    font-size: 0.85rem;
    background-color: #f1f1f1;
    border: 0;
    padding: 5px 12px;
    margin-right: 8px;
    margin-bottom: 12px;
`;

const angleButtonStyle = css`
    border-radius: 4px;
    color: #8966ad;
    font-size: 0.85rem;
    font-weight: bold;
    background-color: #e5e0f3;
    border: 0;
    padding: 5px 12px;
    margin-right: 8px;
    margin-bottom: 12px;
`;

const highlightAngleButtonStyle = css`
    border-radius: 4px;
    color: #fff;
    font-size: 0.85rem;
    font-weight: bold;
    background-color: #444;
    border: 0;
    padding: 5px 12px;
    margin-right: 8px;
    margin-bottom: 12px;
`;

const highlightTag = css`
    font-size: 0.9rem;
    font-weight: bold;
    background-color: #fcfcd4;
`;

const backdropStyle = css`
    z-index: 9999;
    color: "#fff";
`;

export default function Outreach(props) {
    // =========
    // CONSTANTS
    // =========

    const {
        project_id,
        projectData,
        // handleReminder,
        projectTags,
        projectScreeners,
        generateSnippetEvent,
        history,
    } = props;
    // =====
    // STATE
    // =====
    const { auth } = useContext(authContext);
    const [[stableData, filteredData], setData] = useState([[], []]); // data is going to have original set of data as first element and the filtered data as the second
    const [open, setOpen] = useState(false);
    const [currentExpertId, setCurrentExpertId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [screenerModal, setSendScreenerOnlyModal] = useState(false);
    const [onboardScreenerModal, setSendOnboardScreenerModal] = useState(false);
    const [outreach, setOutreachModal] = useState(false);
    const [selected, setSelectedRows] = useState([]);
    const [expertData, setExpertData] = useState([]);
    const [snippet, setSnippetModal] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const [tokenModal, setTokenModal] = useState(false);
    const [statusModal, setStatusModal] = useState(false);
    const [openPhoneLogs, setOpenPhoneLogs] = useState(false);
    const [currentExpertIdForLogs, setCurrentExpertIdForLogs] = useState(null);
    const [phone, setPhoneNumber] = useState("");
    const [expert, setExpert] = useState({
        id: "",
        name: "",
        screener_id: "",
        statuses: [],
    });
    const [everyone, setEveryone] = useState({
        experts: [],
        leads: [],
    });
    const [phoneModal, setPhoneModal] = useState(false);
    const [person, setPerson] = useState({
        id: "",
        name: "",
        fname: "",
        lname: "",
        company: "",
        linkedin_url: "",
        contact_search: false,
    });
    const [template, setTemplate] = useState("");
    // =========
    // PAGE LOAD
    // =========

    async function loadOutreachLeads(projectId) {
        await axios
            .post(`/api/project/now/findOutreachExpertList`, { project_id: projectId, auth: auth.data })
            .then((response) => {
                if (response?.data?.data?.data) {
                    const result = response?.data?.data?.data || [];
                    console.log("DID OUTREACH TAB DATA LOAD? ", result);
                    setData([result, result]);
                    setLoading(false);
                    const allLeadsOnly = result
                        .filter((lead) => !lead.has_signed_terms || !lead.disclosures)
                        .map((item) => {
                            return {
                                fname: item.fname,
                                id: item.expert_id,
                                lname: item.lname,
                                mail: item.expert_email,
                                prefix: item.prefix,
                                suffix: item.suffix,
                            };
                        });
                    const allExpertsOnly = result
                        .filter((lead) => lead.has_signed_terms && lead.disclosures)
                        .map((item) => {
                            return {
                                fname: item.fname,
                                id: item.expert_id,
                                lname: item.lname,
                                mail: item.expert_email,
                                prefix: item.prefix,
                                suffix: item.suffix,
                            };
                        });
                    setEveryone({
                        experts: allExpertsOnly,
                        leads: allLeadsOnly,
                    });
                }
            })
            .catch((err) => {
                console.log(err, " :: err");
            });
    }

    useEffect(() => {
        loadOutreachLeads(project_id);
    }, []);

    // ============
    // HANDLE FUNCS
    // ============

    const showExpertPreview = (expertId) => {
        setCurrentExpertId(expertId);
        setOpen(true);
    };
    const navigateToExpert = (expertId) => {
        history.push(`/expert/view/${expertId}`);
    };
    const reloadPageTagSearch = (tagId) => {
        history.push(`/search?added_by=0&and_tags=${tagId}`);
        window.location.reload();
    };

    // function handleNumberClick(number) {
    //     navigator.clipboard.writeText(number);
    // }

    const matchAllItemsInArray = (arr, target) => {
        return target.every((v) => arr.includes(v));
    };

    const handleTagDeselectClick = (tagId) => {
        const newFilteredData = [];
        const selectedTagFilters = [...selectedTags];
        const remainderTagsArray = selectedTagFilters.filter((id) => id !== tagId);
        console.log("DESELECTED TAG ID: ", tagId);
        console.log("REMAINDER TAGS ARRAY: ", remainderTagsArray);
        setSelectedTags(remainderTagsArray);
        for (const expert of stableData) {
            const { tagFilterData } = expert;
            // Convert tagFilterData object to array of tag ids
            const expertTagIdsArray = Object.entries(tagFilterData).map(([key, value]) => {
                return parseInt(key, 10);
            });
            // Check to see if all selected tags are in the expert's tag array
            if (matchAllItemsInArray(expertTagIdsArray, remainderTagsArray)) {
                newFilteredData.push(expert);
            }
        }
        console.log("De-Selection Filtered Results: ", newFilteredData);
        setData([[...stableData], newFilteredData]);
    };

    const handleTagClick = (tagId) => {
        const newFilteredData = [];
        const selectedTagFilters = [...selectedTags];
        // Add tagId to global selected tags array
        selectedTagFilters.push(tagId);
        console.log("SELECTED TAG FILTERS: ", selectedTagFilters);
        for (const expert of stableData) {
            const { tagFilterData } = expert;
            // Convert tagFilterData object to array of tag ids
            const expertTagIdsArray = Object.entries(tagFilterData).map(([key, value]) => {
                return parseInt(key, 10);
            });
            // Check to see if all selected tags are in the expert's tag array
            if (matchAllItemsInArray(expertTagIdsArray, selectedTagFilters)) {
                newFilteredData.push(expert);
            }
        }
        setSelectedTags(selectedTagFilters);
        console.log("Select Tag Filtered Results: ", newFilteredData);
        setData([[...stableData], newFilteredData]);
    };

    const handleResetTags = () => {
        setSelectedTags([]);
        setData([[...stableData], [...stableData]]);
    };

    const onRowSelectionChange = (currentSelect, allSelected) => {
        console.log("SELECTED ITEMS: ", allSelected);
        const result = allSelected.map((item) => {
            return filteredData?.at(item.dataIndex);
        });
        const selectedExperts = result.map((item) => {
            // console.log("SELECTED ITEM: ", item);
            const expert = {
                id: item.expert_id,
                fname: item.fname,
                lname: item.lname,
                prefix: item.prefix,
                suffix: item.suffix,
                mail: item.expert_email,
            };
            return expert;
        });
        console.log("WHICH ROWS SELECTED? ", selectedExperts);
        setSelectedRows(selectedExperts);
    };

    const onColumnSortChange = (changedColumn, direction) => {
        console.log("WHAT COLUMN IS SORTED? ", changedColumn);
        console.log("WHAT DIRECTION IS SORTED? ", direction);
    };

    const closeModal = () => {
        setSendOnboardScreenerModal(false);
        setSendScreenerOnlyModal(false);
        setOutreachModal(false);
        setSnippetModal(false);
        setTokenModal(false);
        setStatusModal(false);
        setPhoneModal(false);
        setExpert({
            id: "",
            name: "",
            screener_id: "",
            statuses: [],
        });
        setPerson({
            ...person,
            id: "",
            name: "",
            linkedin_url: "",
            contact_search: false,
        });
        setSelectedTags([]);
    };

    const handleOnboardScreenerEvent = () => {
        console.log("SENDING EMAIL TO: ", selected);
        if (selected.length > 0) {
            setTemplate("onboard_screener");
            setSendOnboardScreenerModal(true);
        } else {
            alert("Please select at least one expert to send the screener to.");
        }
    };

    const handleOutreachEvent = () => {
        console.log("SENDING EMAIL TO: ", selected);
        if (selected.length > 0) {
            setTemplate("outreach");
            setSendScreenerOnlyModal(false);
            setOutreachModal(true);
        } else {
            alert("Please select at least one expert to send the screener to.");
        }
    };

    const handleExpertSnippet = (response) => {
        console.log("RESPONSE FROM API CALL: ", response);
        setExpertData(response);
        setSnippetModal(true);
    };

    const handleExpertToken = (eid, name, sid) => {
        console.log("EXPERT ID: ", { eid, name });
        setTokenModal(true);
        setExpert({
            ...expert,
            id: eid,
            name,
            screener_id: sid ? sid : "",
        });
    };

    const handleExpertStatus = (eid, name, statuses) => {
        console.log("EXPERT ID: ", { eid, name });
        setStatusModal(true);
        setExpert({
            ...expert,
            id: eid,
            name,
            statuses,
        });
    };

    const sendEmailToLeadsOnly = () => {
        console.log("SENDING EMAIL TO LEADS ONLY: ", everyone.experts);
        setSelectedRows(everyone.leads);
        setSendScreenerOnlyModal(false);
        setOutreachModal(true);
        setTemplate("outreach");
    };

    const sendEmailToExpertsOnly = () => {
        console.log("SENDING EMAIL TO ALL THESE EXPERTS: ", everyone.experts);
        setSelectedRows(everyone.experts);
        setSendScreenerOnlyModal(true);
        setTemplate("screener");
        // setOutreachModal(true);
    };

    function handlePhoneLogs(expertId, phoneNum) {
        console.log("handlePhoneLogs: ", { expertId, phoneNum });
        setCurrentExpertIdForLogs(expertId);
        setPhoneNumber(phoneNum);
        setOpenPhoneLogs(true);
    }

    const searchPhoneViaApi = (linkedin_url, fname, lname, name, id, company) => {
        setPerson({
            ...person,
            id,
            fname,
            lname,
            name,
            linkedin_url,
            company,
            contact_search: true,
        });
        if (linkedin_url) {
            setPhoneModal(true);
        } else {
            alert("No linkedin url found for this person. We can't run a search without it.");
        }
    };

    // =====
    // TABLE
    // =====

    const options = {
        print: false,
        rowsPerPage: 250,
        viewColumns: false,
        download: true,
        onRowSelectionChange,
        onColumnSortChange,
    };

    const columns = [
        {
            name: "has_signed_terms",
            label: "In",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { has_signed_terms, disclosures, is_auto_outreach, stopped_time } = filteredData?.[dataIndex];

                    return (
                        <>
                            <div style={{ marginBottom: "1rem" }}>
                                {has_signed_terms && disclosures ? (
                                    <VerifiedIcon style={{ color: "#523197" }} />
                                ) : (
                                    <strong style={{ color: "#ccc" }}>***</strong>
                                )}
                            </div>
                            <div>
                                {is_auto_outreach ? (
                                    <>
                                        <Chip
                                            sx={{
                                                color: "hsl(130, 100%, 30%)",
                                                backgroundColor: "hsl(130, 100%, 85%)",
                                                fontWeight: "700",
                                                marginBottom: "0.5rem",
                                            }}
                                            label="Auto Outreach"
                                        />
                                        {stopped_time && (
                                            <Chip
                                                sx={{
                                                    color: "hsl(130, 100%, 30%)",
                                                    backgroundColor: "hsl(130, 100%, 85%)",
                                                    fontWeight: "700",
                                                }}
                                                label="Completed"
                                            />
                                        )}
                                    </>
                                ) : (
                                    <Chip
                                        sx={{
                                            color: "hsl(0, 100%, 30%)",
                                            backgroundColor: "hsl(0, 100%, 85%)",
                                            fontWeight: "700",
                                        }}
                                        label="Manual Outreach"
                                    />
                                )}
                            </div>
                        </>
                    );
                },
            },
        },
        {
            name: "expert_name",
            label: "Name",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const {
                        fname,
                        lname,
                        suffix,
                        expert_id,
                        expert_email,
                        empHistData,
                        headline,
                        has_signed_terms,
                        disclosures,
                        expertStatuses,
                    } = filteredData?.[dataIndex];

                    return (
                        <div>
                            <Button css={linkStyle} onClick={() => showExpertPreview(expert_id)}>
                                {fname} {lname}
                                {suffix ? `, ${suffix}` : ""}
                            </Button>
                            {empHistData && empHistData.length > 0 ? (
                                <div>{`${truncateString(empHistData[0].position, 25)} at ${truncateString(
                                    empHistData[0].company,
                                    25
                                )}`}</div>
                            ) : (
                                <div>{truncateString(headline, 25)}</div>
                            )}
                            {expert_email && (
                                <div>
                                    <a href={`mailto:${expert_email}`} style={{ color: "#8966ad" }}>
                                        {expert_email}
                                    </a>
                                </div>
                            )}
                            {expertStatuses && (
                                <div style={{ marginTop: "8px" }}>
                                    {Object.entries(expertStatuses).map(([key, value], i) => (
                                        <span key={`${key}_${i}`} style={{ color: "green", fontSize: ".75rem" }}>
                                            {[
                                                "3",
                                                "4",
                                                "5",
                                                "6",
                                                "7",
                                                "8",
                                                "12",
                                                "13",
                                                "17",
                                                "18",
                                                "19",
                                                "20",
                                                "28",
                                            ].includes(key) && value + " > "}
                                        </span>
                                    ))}
                                </div>
                            )}
                        </div>
                    );
                },
            },
        },
        {
            name: "",
            label: "",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    const {
                        expert_id,
                        person_id,
                        linkedin_url,
                        fname,
                        lname,
                        expert_name,
                        empHistData,
                        number,
                        phoneLogsPresent,
                    } = filteredData?.[dataIndex];

                    return (
                        <div>
                            {number ? (
                                <div>
                                    <a style={{ display: "inline-block" }} href={`tel:${number}`}>
                                        {number.length === 10 ? formatPhoneNumber(number) : number}
                                    </a>
                                    <Button
                                        style={{
                                            display: "block",
                                            marginTop: "10px",
                                            padding: "0",
                                            fontSize: ".75rem",
                                        }}
                                        onClick={() => handlePhoneLogs(expert_id, number)}
                                    >
                                        {phoneLogsPresent ? "View Call Log" : "Log Status"}
                                    </Button>
                                </div>
                            ) : (
                                <>
                                    {linkedin_url && (
                                        <Button
                                            style={{ padding: "0", fontSize: ".75rem" }}
                                            onClick={() =>
                                                searchPhoneViaApi(
                                                    linkedin_url,
                                                    fname,
                                                    lname,
                                                    expert_name,
                                                    person_id,
                                                    empHistData && empHistData.length > 0 ? empHistData[0].company : ""
                                                )
                                            }
                                        >
                                            Find Number
                                        </Button>
                                    )}
                                </>
                            )}
                        </div>
                    );
                },
            },
        },
        {
            name: "calculated_consultation_rate",
            label: "Rate",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { calculated_consultation_rate } = filteredData?.[dataIndex];

                    return (
                        <div>
                            {parseInt(calculated_consultation_rate, 10) > 0
                                ? `$${calculated_consultation_rate}`
                                : "----"}
                        </div>
                    );
                },
            },
        },
        {
            name: "stopped_time",
            label: "Last Sent",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { stopped_time, phoneLogsPresent, number } = filteredData?.[dataIndex];
                    // const now = new Date();
                    // const lastSentDate = new Date(stopped_time);
                    // const expiryTime = add(lastSentDate, { hours: 3 });
                    return <div>{stopped_time ? basicDateTimeStamp(stopped_time) : "NA"}</div>;
                },
            },
        },
        {
            name: "attaching_rm_name",
            label: "By",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { attaching_rm_name, attached_on } = filteredData?.[dataIndex];

                    return (
                        <>
                            <div>{attaching_rm_name}</div>
                            <div>{basicDateTimeStamp(attached_on)}</div>
                        </>
                    );
                },
            },
        },
        {
            name: "tags",
            label: "Tags",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    const { tags, surveys, fname, lname, suffix, expert_id, expertStatuses } =
                        filteredData?.[dataIndex];
                    const fullName = `${fname} ${lname}${suffix ? `, ${suffix}` : ""}`;
                    return (
                        <>
                            {expertStatuses && (
                                <div style={{ marginBottom: "8px" }}>
                                    {Object.entries(expertStatuses).map(([key, value], i) => (
                                        <strong key={`${key}_${i}`} style={{ color: "orange", fontSize: "0.9rem" }}>
                                            {key === "12" && (
                                                <span>
                                                    <Warning style={{ fontSize: "1rem" }} /> Waiting to be scheduled...
                                                </span>
                                            )}
                                        </strong>
                                    ))}
                                </div>
                            )}
                            <div>
                                {tags.length > 0 && (
                                    <>
                                        {tags.map((item, index) => {
                                            return (
                                                <span key={index}>
                                                    <em
                                                        css={selectedTags.includes(item.tag_id) && highlightTag}
                                                    >{`${item.tag_name}`}</em>
                                                    <span>{index < tags.length - 1 && ", "}</span>
                                                </span>
                                            );
                                        })}
                                    </>
                                )}
                            </div>
                            <div style={{ marginTop: "12px" }}>
                                <strong>Help screen on behalf of {fname}:</strong>
                                {Object.entries(surveys).map(([key, value], i) => (
                                    <p key={`${key}_${i}`} style={{ margin: 0 }}>
                                        {
                                            <Button
                                                css={smallLinkStyle}
                                                // onClick={() => handleExpertToken(expert_id, fullName, null)}
                                                onClick={() => handleExpertToken(expert_id, fullName, value.survey_id)}
                                            >
                                                {value.survey_name}
                                            </Button>
                                        }
                                    </p>
                                ))}
                                {Object.entries(surveys).length === 0 && (
                                    <div>
                                        <Button
                                            css={smallLinkStyle}
                                            onClick={() => handleExpertToken(expert_id, fullName, null)}
                                        >
                                            Select a Screener
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </>
                    );
                },
            },
        },
        {
            name: "",
            label: "",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    const { expert_id, fname, lname, suffix, expertStatuses } = filteredData?.[dataIndex];
                    const fullName = `${fname} ${lname}${suffix ? `, ${suffix}` : ""}`;
                    return (
                        <>
                            <div>
                                <StatusIcon
                                    style={{ color: "#666", cursor: "pointer" }}
                                    onClick={() => handleExpertStatus(expert_id, fullName, expertStatuses)}
                                />
                            </div>
                            {/* <br />
                            <div>
                                <Delete
                                    style={{ color: "#666", cursor: "pointer" }}
                                    onClick={() => removeExpertsEvent([expert_id], loadOutreachLeads)}
                                />
                            </div> */}
                        </>
                    );
                },
            },
        },
    ];

    if (loading) {
        return (
            <Backdrop
                css={backdropStyle}
                open={loading}
                onClick={() => {
                    setLoading(false);
                }}
            >
                <CircularProgress style={{ color: "#fff" }} />
            </Backdrop>
        );
    } else {
        return (
            <>
                <h2 style={{ fontSize: "1.25rem" }}>Total: {filteredData?.length}</h2>
                <div style={{ marginBottom: "15px" }}>
                    <strong>FILTER:</strong> Anyone who has been sent outreach already, but has NOT declined or
                    screened. (accepted is now included)
                    <br />
                    <strong>RECOMMENDATION:</strong> Send another outreach without any links, and/or call down the list.
                    <br />
                    <br />
                    {everyone.leads.length > 0 && (
                        <Button onClick={sendEmailToLeadsOnly}>
                            Send Email To All Leads Only ({everyone.leads.length})
                        </Button>
                    )}
                    {everyone.experts.length > 0 && (
                        <Button onClick={sendEmailToExpertsOnly}>
                            Send Email To All Experts Only ({everyone.experts.length})
                        </Button>
                    )}
                    <br />
                    {selected.length > 0 && (
                        <div
                            style={{
                                zIndex: "999",
                                position: "fixed",
                                bottom: "0",
                                height: "10vh",
                                width: "100%",
                                backgroundColor: "#FAFAFA",
                                left: "72px",
                                borderTop: "1px solid #ccc",
                                padding: "30px 0 0 60px",
                            }}
                        >
                            <Button onClick={handleOutreachEvent}>Send Outreach</Button>
                            <Button onClick={handleOnboardScreenerEvent}>Send Onboard/Screener</Button>
                            <Button onClick={() => generateSnippetEvent(selected, handleExpertSnippet)}>Snippet</Button>
                        </div>
                    )}
                </div>
                <div>
                    <strong>TAGS (ANGLES):</strong>
                    <br />
                    {projectTags.length > 0 && (
                        <button css={resetButtonStyle} onClick={handleResetTags}>
                            Reset Tags
                        </button>
                    )}
                    {projectTags.map((t, i) => {
                        return (
                            <span key={`${t.id}_${i}`}>
                                {selectedTags.includes(t.id) ? (
                                    <button
                                        css={highlightAngleButtonStyle}
                                        onClick={() => handleTagDeselectClick(t.id)}
                                    >
                                        {t.name}
                                    </button>
                                ) : (
                                    <button css={angleButtonStyle} onClick={() => handleTagClick(t.id)}>
                                        {t.name}
                                    </button>
                                )}
                            </span>
                        );
                    })}
                </div>
                {person.contact_search && (
                    <ContactSearchModal
                        project_id={project_id}
                        person={person}
                        modal={phoneModal}
                        reloadEvent={loadOutreachLeads}
                        closeEvent={closeModal}
                    />
                )}
                <ChangeStatus
                    reloadEvent={loadOutreachLeads}
                    user={auth}
                    openDialog={statusModal}
                    expert={expert}
                    project_id={project_id}
                    closeEvent={closeModal}
                />
                <GenerateIndividualTokens
                    openDialog={tokenModal}
                    closeEvent={closeModal}
                    project_id={project_id}
                    expert={expert}
                    data={projectScreeners}
                />
                <SnippetDialog openDialog={snippet} handleDialogClose={closeModal} expertData={expertData} />
                <SendOnboardScreenerNow
                    reloadEvent={loadOutreachLeads}
                    open={onboardScreenerModal}
                    closeModal={closeModal}
                    data={selected}
                    project_id={project_id}
                    projectData={projectData}
                    screeners={projectScreeners}
                    template="template"
                />
                <SendBulkOutreachNow
                    reloadEvent={loadOutreachLeads}
                    screenerModal={screenerModal}
                    setOutreachModal={setOutreachModal}
                    outreach={outreach}
                    closeModal={closeModal}
                    data={selected}
                    project_id={project_id}
                    projectData={projectData}
                    screeners={projectScreeners}
                    template={template}
                />
                <MUIDataTable data={filteredData} options={options} columns={columns} />
                {open && (
                    <ExpertPreviewWrapperNext
                        open={open}
                        setOpen={setOpen}
                        expert_id={currentExpertId}
                        project_id={project_id}
                    />
                )}
                {openPhoneLogs && (
                    <PhoneLogsModal
                        openPhoneLogs={openPhoneLogs}
                        setOpenPhoneLogs={setOpenPhoneLogs}
                        userInfo={auth.data}
                        project_id={project_id}
                        expert_id={currentExpertIdForLogs}
                        number={phone}
                        reloadEvent={loadOutreachLeads}
                    />
                )}
            </>
        );
    }
}
