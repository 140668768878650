import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import axios from "axios";
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "react-select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Save from "@mui/icons-material/Save";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import moment from "moment";
import "moment-duration-format";
import { DesktopDatePicker as DatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// date formatters
// import { format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { authContext } from "../context/auth";
import PhoneField from "../components/PhoneField";
import TwilioBridgeSettings from "../components/TwilioBridgeSettings";
import ExternalBridgeSettings from "../components/ExternalBridgeSettings";
import Messaging from "../components/Messaging";
import { stripNonNumericChars } from "../Utils";
import premiumOptions from "../data/premiumOptions";
import durationOptions from "../data/durationOptions";
import RequiresUpdateTagManager from "../components/project/utils/RequiresUpdateTagManager";

// default timezone
const DEFAULT_TIMEZONE = {
    value: 16,
    label: "(UTC-05:00) Eastern Time (US & Canada)",
    abbr: "EDT",
    offset: "-04:00",
};

const expertNetworks = [
    { value: "Alphasights", label: "Alphasights" },
    { value: "Atheneum", label: "Atheneum" },
    { value: "Coleman", label: "Coleman" },
    { value: "DeepBench", label: "DeepBench" },
    { value: "ExpertConnect", label: "ExpertConnect" },
    { value: "GLG", label: "GLG" },
    { value: "Guidepoint", label: "Guidepoint" },
    { value: "NewtonX", label: "NewtonX" },
    { value: "Pride", label: "Pride" },
    { value: "Primary Insight", label: "Primary Insight" },
    { value: "Techspert", label: "Techspert" },
    { value: "Third Bridge", label: "Third Bridge" },
    { value: "Dialectica", label: "Dialectica" },
    { value: "Other", label: "Other" },
];
const clientTypes = [
    { value: "Hedge Fund", label: "Hedge Fund" },
    { value: "Consulting", label: "Consulting" },
    { value: "Private Equity", label: "Private Equity" },
    { value: "Venture Capital", label: "Venture Capital" },
    { value: "Sell Side", label: "Sell Side" },
    { value: "Asset Management", label: "Asset Management" },
    { value: "Investment Bank", label: "Investment Bank" },
    { value: "Private/Family Office", label: "Private/Family Office" },
    { value: "Corporate", label: "Corporate" },
    { value: "Market Research", label: "Market Research" },
    { value: "Internal", label: "Internal" },
];
const statusTypes = [
    {
        value: "Paid Client - Contract Signed",
        label: "Paid Client - Contract Signed",
    },
    {
        value: "Paid Client - Contract Pending",
        label: "Paid Client - Contract Pending",
    },
    {
        value: "Paid Trial - Contract Signed",
        label: "Paid Trial - Contract Signed",
    },
    {
        value: "Paid Trial - Contract Pending",
        label: "Paid Trial - Contract Pending",
    },
    { value: "Former Client", label: "Former Client" },
    { value: "Former Trial", label: "Former Trial" },
    { value: "Prospect", label: "Prospect" },
];
// const contractTypes = [
//     { value: 1, label: "Trial" },
//     { value: 2, label: "Subscription" },
//     { value: 3, label: "Pay Go" },
// ];
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    label: {
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(0.5),
        fontSize: "1rem",
    },
    strong: {
        color: theme.palette.text.primary,
        marginBottom: theme.spacing(0.5),
        fontSize: "1rem",
    },
    meta: {
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(0.5),
        fontSize: ".75rem",
    },
    spacing: {
        marginRight: theme.spacing(1),
    },
    margin: {
        margin: theme.spacing(1),
    },
    row: {
        marginTop: theme.spacing(2),
    },
    pushRight: {
        textAlign: "right",
    },
    leftBorder: {
        paddingLeft: "12px",
        borderLeft: "2px solid #EAEAEA",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    link: {
        color: "#3c8864",
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function Client(props) {
    const { match, history } = props;
    const { id } = match.params;
    const classes = useStyles();
    const [isError, setIsError] = useState(false);
    const [billing, setBilling] = useState(false);
    const [open, setOpen] = useState(false);
    const [twilioDisplay, setTwilio] = useState(true);
    const [externalDisplay, setExternal] = useState(false);
    const [manualDisplay, setManual] = useState(false);
    const { setAuthData, auth } = useContext(authContext);
    const [premium, disablePremium] = useState(true);
    const [loadingClient, setLoadingClient] = useState(true);
    const [data, setData] = useState({
        id: "",
        salesforceid: "",
        isdeleted: false,
        name: "",
        status: "",
        type: "",
        parentid: "",
        billingstreet: "",
        billingcity: "",
        billingstate: "",
        billingpostalcode: "",
        billingcountry: "",
        shippingstreet: "",
        shippingcity: "",
        shippingstate: "",
        shippingpostalcode: "",
        shippingcountry: "",
        phone: "",
        fax: "",
        website: "",
        photourl: "",
        industry: "",
        annualrevenue: "",
        numberofemployees: "",
        description: "",
        ownerid: "",
        createddate: "",
        createdbyid: "",
        lastmodifieddate: "",
        lastmodifiedbyid: "",
        lastvieweddate: "",
        accountsource: "",
        sicdesc: "",
        client_organization_type__c: "",
        client_organization_status__c: "",
        account_owner_email__c: "",
        current_expert_network_usage__c: [],
        compliance_workflow_requirements__c: "",
        project_manager_name__c: "",
        project_manager_email__c: "",
        current_lifesci__c: "",
        referred_by__c: "",
        referred_by_email_address__c: "",
        conference_bridge_setting: "1",
        twilio_bridge_setting: "1",
        external_bridge_numbers: [],
        notifications: [],
        tags: [],
        comments: [],
        custom_charge: 0,
        custom_hourly_rate: 1.0,
        premium_pricing_model: premiumOptions[0],
        price_per_unit: 0,
        grace_period: 3,
        duration_model: durationOptions[0],
        enable_csv_download: false,
        // contract_type: "",
        // contract_start_date: "",
        // contract_end_date: "",
        // total_units: 0.0,
        // available_units: 0.0,
    });
    const pageTitle = `Edit ${data.name}`;

    function handleClose() {
        setOpen(false);
    }

    function handleCheckbox(event) {
        // const { target } = event;
        // const { name } = target;
        setBilling(event.target.checked);
    }

    const handleCsvCheckbox = (event) => {
        console.log(event.target.checked, " :: handling Csv Checkbox");
        setData({
            ...data,
            enable_csv_download: event.target.checked,
        });
    };

    function handleTagEvent(tagsArray) {
        if (typeof tagsArray === "object") {
            setData({
                ...data,
                tags: tagsArray,
            });
        }
    }

    function handleNetworksEvent(networkArray) {
        if (typeof networkArray === "object") {
            setData({
                ...data,
                current_expert_network_usage__c: networkArray,
            });
        }
    }
    function handlePremiums(arr) {
        console.log("PREMIUM SELECTED: ", arr);
        if (typeof arr === "object") {
            setData({
                ...data,
                premium_pricing_model: arr,
            });
        }
    }
    function handleDurationModel(arr) {
        console.log("DURATION MODEL SELECTED: ", arr);
        if (typeof arr === "object") {
            setData({
                ...data,
                duration_model: arr,
            });
        }
    }

    function handlePricePerUnitField(event) {
        console.log(data, " :: data in handlePriceperUnitField");
        const { target } = event;
        const { value, name } = target;
        const num = stripNonNumericChars(value);
        if (num.length > 0 && num > 0) {
            disablePremium(false);
        } else {
            disablePremium(true);
            data.premium_pricing_model = premiumOptions[0];
        }
        setData({
            ...data,
            [name]: num,
        });
    }

    function handlePhoneEvent(event) {
        const { target } = event;
        const { value, name } = target;
        const phone = value.replace(/[^0-9]/g, "");

        if (phone.length > 9) {
            setIsError(false);
        } else {
            setIsError(true);
        }
        setData({
            ...data,
            [name]: phone,
        });
    }

    function setBridgeDisplay(option) {
        switch (option) {
            case "1":
                setTwilio(true);
                setExternal(false);
                setManual(false);
                break;
            case "2":
                setTwilio(false);
                setExternal(true);
                setManual(false);
                break;
            case "3":
                setTwilio(false);
                setExternal(false);
                setManual(true);
                break;
            default:
                setTwilio(true);
                setExternal(false);
                setManual(false);
        }
    }

    function handleBridgeChange(event) {
        setData({
            ...data,
            conference_bridge_setting: event.target.value,
        });
        setBridgeDisplay(event.target.value);
    }

    function handleTwilioChange(event) {
        setData({
            ...data,
            twilio_bridge_setting: event.target.value,
        });
    }

    function handleExternalBridgeSettings(settingsArray) {
        if (typeof settingsArray === "object") {
            setData({
                ...data,
                external_bridge_numbers: settingsArray,
            });
        }
    }

    function handleChange(event) {
        const { target } = event;
        const { value, name } = target;

        if (value.length > 0 || value !== "undefined") {
            setIsError(false);
        } else {
            setIsError(true);
        }
        setData({
            ...data,
            [name]: value,
        });
    }

    // function createDateTimeStringForDTPicker(dateObj, offset) {
    //     return formatInTimeZone(dateObj, offset, "yyyy-MM-dd'T'HH:mm:ss");
    // }

    // function handleContractStartDateChange(event) {
    //     console.log("Contract Start Date Change: ", event);
    //     const formattedDate = createDateTimeStringForDTPicker(event, DEFAULT_TIMEZONE.offset);
    //     setData({
    //         ...data,
    //         contract_start_date: formattedDate,
    //     });
    // }
    // function handleContractEndDateChange(event) {
    //     console.log("Contract End Date Change: ", event);
    //     const formattedDate = createDateTimeStringForDTPicker(event, DEFAULT_TIMEZONE.offset);
    //     setData({
    //         ...data,
    //         contract_end_date: formattedDate,
    //     });
    // }

    function handleSubmit(event) {
        event.preventDefault();
        const now = new Date().toISOString();
        const clientData = {
            id: data.id,
            salesforceid: data.salesforceid,
            isdeleted: data.isdeleted,
            name: data.name,
            status: data.status,
            type: data.type,
            parentid: data.parentid,
            billingstreet: data.billingstreet,
            billingcity: data.billingcity,
            billingstate: data.billingstate,
            billingpostalcode: data.billingpostalcode,
            billingcountry: data.billingcountry,
            shippingstreet: billing ? data.billingstreet : data.shippingstreet,
            shippingcity: billing ? data.billingcity : data.shippingcity,
            shippingstate: billing ? data.billingstate : data.shippingstate,
            shippingpostalcode: billing ? data.billingpostalcode : data.shippingpostalcode,
            shippingcountry: billing ? data.billingcountry : data.shippingcountry,
            phone: data.phone,
            fax: data.fax,
            website: data.website,
            photourl: data.photourl,
            industry: data.industry,
            annualrevenue: data.annualrevenue,
            numberofemployees: data.numberofemployees,
            description: data.description,
            ownerid: data.ownerid,
            createddate: data.createddate,
            createdbyid: data.createdbyid,
            lastmodifieddate: now,
            lastmodifiedbyid: data.lastmodifiedbyid,
            lastvieweddate: data.lastvieweddate,
            accountsource: data.accountsource,
            sicdesc: data.sicdesc,
            client_organization_type__c: data.client_organization_type__c,
            client_organization_status__c: data.client_organization_status__c,
            account_owner_email__c: data.account_owner_email__c,
            current_expert_network_usage__c: data.current_expert_network_usage__c,
            compliance_workflow_requirements__c: data.compliance_workflow_requirements__c,
            project_manager_name__c: data.project_manager_name__c,
            project_manager_email__c: data.project_manager_email__c,
            current_lifesci__c: data.current_lifesci__c,
            referred_by__c: data.referred_by__c,
            referred_by_email_address__c: data.referred_by_email_address__c,
            conference_bridge_setting: data.conference_bridge_setting,
            twilio_bridge_setting: data.twilio_bridge_setting,
            external_bridge_numbers: data.external_bridge_numbers,
            notifications: data.notifications,
            tags: data.tags,
            comments: data.comments,
            custom_charge: parseInt(data.custom_charge, 10),
            custom_hourly_rate: parseFloat(data.custom_hourly_rate),
            premium_pricing_model_id: data.premium_pricing_model.value,
            price_per_unit: parseInt(data.price_per_unit, 10),
            grace_period: parseInt(data.grace_period, 10),
            duration_model_id: data.duration_model.value,
            enable_csv_download: data.enable_csv_download,
            // contract_type: data.contract_type,
            // contract_start_date: data.contract_start_date,
            // contract_end_date: data.contract_end_date,
            // total_units: data.total_units,
            // available_units: data.available_units,
        };

        if (clientData.name !== "") {
            console.log("Client Submit Data", JSON.stringify(clientData));

            const url = "/api/client/update";
            clientData.id = props.match.params.id;
            console.log(clientData, " :: clientData");

            axios
                .post(url, clientData, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                })
                .then((result) => {
                    if (result.status === 200) {
                        console.log(`Successful POST: ${JSON.stringify(result.data)}`);
                        setOpen(true);
                    } else {
                        console.log(`Good POST, but something went wrong: ${JSON.stringify(clientData)}`);
                    }
                })
                .catch(() => {
                    console.log(`API not done yet, 404 Error: ${JSON.stringify(clientData)}`);
                });
        } else {
            setIsError(true);
        }
    }

    useEffect(() => {
        async function fetchClientById(id) {
            // Client #169 is great for client contacts
            const clientCall = await axios.get(`/api/client/find?id=${id}`);
            if (clientCall.status === 200 && clientCall.data && clientCall.data.data) {
                if (clientCall.data.data[0].length === 0) return;
                const client = clientCall.data.data[0][0];
                const settings = clientCall.data.data[4] || [];
                const tags = clientCall.data.data[1].map((tag) => {
                    return { label: tag.name, value: tag.id };
                });
                const comments = clientCall.data.data[2].map((comment) => {
                    return { label: comment.comment, value: comment.id };
                });
                const primary = settings.filter((item) => item.isprimary !== false);
                let selectedTypeID = "1";
                let selectedTwilio = "1";
                let extNumbers = [];
                // First check to see if array is empty or not
                if (primary.length > 0) {
                    const primaryData = primary[0];
                    selectedTypeID = primaryData.type_id.toString();
                    if (primary[0].data && primary[0].data.value !== undefined) {
                        selectedTwilio = primary[0].data.value;
                    }
                    if (Array.isArray(primary[0].data) && primary[0].data.length > 0) {
                        extNumbers = primary[0].data;
                    }
                }
                const custom_charge = client.custom_charge;
                const custom_hourly_rate = client.custom_hourly_rate;
                console.log(client, " :: client");
                const premium_pricing_model =
                    premiumOptions.filter((option) => option.value === client.premium_pricing_model_id).length === 1
                        ? premiumOptions[client.premium_pricing_model_id - 1]
                        : premiumOptions[0];
                const price_per_unit = client.price_per_unit;
                const grace_period = client.grace_period ? parseInt(client.grace_period, 10) : 3;
                const duration_model =
                    durationOptions.filter((option) => option.value === client.duration_model_id).length === 1
                        ? durationOptions[client.duration_model_id - 1]
                        : durationOptions[0];
                // Enable premium field if a ppu is present
                if (price_per_unit > 0) {
                    disablePremium(false);
                }

                setBridgeDisplay(selectedTypeID);
                console.log(primary);
                console.log("Return DATA", clientCall.data);

                setData({
                    id: client.id,
                    salesforceid: client.salesforceid,
                    isdeleted: client.isdeleted,
                    name: client.name,
                    status: client.status,
                    type: client.type,
                    parentid: client.parentid,
                    billingstreet: client.billingstreet,
                    billingcity: client.billingcity,
                    billingstate: client.billingstate,
                    billingpostalcode: client.billingpostalcode,
                    billingcountry: client.billingcountry,
                    shippingstreet: client.shippingstreet,
                    shippingcity: client.shippingcity,
                    shippingstate: client.shippingstate,
                    shippingpostalcode: client.shippingpostalcode,
                    shippingcountry: client.shippingcountry,
                    phone: client.phone,
                    fax: client.fax,
                    website: client.website,
                    photourl: client.photourl,
                    industry: client.industry,
                    annualrevenue: client.annualrevenue,
                    numberofemployees: client.numberofemployees,
                    description: client.description,
                    ownerid: client.ownerid,
                    createddate: client.createddate,
                    createdbyid: client.createdbyid,
                    lastmodifieddate: client.lastmodifieddate,
                    lastmodifiedbyid: client.lastmodifiedbyid,
                    lastvieweddate: client.lastvieweddate,
                    accountsource: client.accountsource,
                    sicdesc: client.sicdesc,
                    client_organization_type__c: client.client_organization_type__c,
                    client_organization_status__c: client.client_organization_status__c,
                    account_owner_email__c: client.account_owner_email__c,
                    current_expert_network_usage__c: client.current_expert_network_usage__c,
                    compliance_workflow_requirements__c: client.compliance_workflow_requirements__c,
                    project_manager_name__c: client.project_manager_name__c,
                    project_manager_email__c: client.project_manager_email__c,
                    current_lifesci__c: client.current_lifesci__c,
                    referred_by__c: client.referred_by__c,
                    referred_by_email_address__c: client.referred_by_email_address__c,
                    conference_bridge_setting: selectedTypeID,
                    twilio_bridge_setting: selectedTwilio,
                    external_bridge_numbers: extNumbers,
                    notifications: client.notifications || [],
                    tags,
                    comments,
                    custom_charge,
                    custom_hourly_rate,
                    premium_pricing_model,
                    price_per_unit,
                    grace_period,
                    duration_model,
                    enable_csv_download: client.enable_csv_download,
                    // contract_type: client.contract_type,
                    // contract_start_date: client.contract_start_date,
                    // contract_end_date: client.contract_end_date,
                    // total_units: client.total_units,
                    // available_units: client.available_units,
                });
            }
            setLoadingClient(false);
        }
        if (id) {
            fetchClientById(id);
        }
    }, [id]);

    function navigateToView() {
        if (props.match.params.id) {
            history.push(`/client/view/${props.match.params.id}`);
        } else {
            window.location.href = "/client";
        }
    }

    function formatDateString(str) {
        return moment(str).format("MMM. Do, YYYY, h:mma");
    }

    return (
        <form onSubmit={handleSubmit}>
            <p className={classes.meta}>
                {data.salesforceid && (
                    <span className={classes.spacing}>
                        Salesforce ID: <strong>{data.salesforceid}</strong>
                    </span>
                )}
                {data.parentid && (
                    <span className={classes.spacing}>
                        Parent ID: <strong>{data.parentid}</strong>
                    </span>
                )}
                {data.ownerid && (
                    <span className={classes.spacing}>
                        Owner ID: <strong>{data.ownerid}</strong>
                    </span>
                )}
                {data.createdbyid && (
                    <span className={classes.spacing}>
                        Created By: <strong>{data.createdbyid}</strong>
                    </span>
                )}
                {data.createddate && (
                    <span className={classes.spacing}>
                        Created On: <strong>{formatDateString(data.createddate)}</strong>
                    </span>
                )}
                {data.lastmodifiedbyid && (
                    <span className={classes.spacing}>
                        Last Modified By: <strong>{data.lastmodifiedbyid}</strong>
                    </span>
                )}
                {data.lastmodifieddate && (
                    <span className={classes.spacing}>
                        Last Modified On: <strong>{formatDateString(data.lastmodifieddate)}</strong>
                    </span>
                )}
                {data.lastvieweddate && (
                    <span className={classes.spacing}>
                        Last Viewed: <strong>{formatDateString(data.lastvieweddate)}</strong>
                    </span>
                )}
                {data.lastactivitydate && (
                    <span className={classes.spacing}>
                        Last Activity: <strong>{formatDateString(data.lastactivitydate)}</strong>
                    </span>
                )}
            </p>
            <h1>{pageTitle}</h1>
            <Grid container spacing={3}>
                <Grid item sm={12} xs={12}>
                    <div className={classes.pushRight}>
                        <Badge badgeContent={data.comments.length} color="secondary" showZero>
                            <Typography className={classes.padding}>Comments</Typography>
                        </Badge>
                    </div>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item sm={12} xs={12}>
                    <TextField
                        autoComplete="name"
                        autoFocus
                        error={data.name === ""}
                        fullWidth
                        id="name"
                        label="Name"
                        margin="normal"
                        name="name"
                        onChange={handleChange}
                        required
                        value={data.name}
                    />
                </Grid>
                {/* <Grid item sm={6} xs={12}>
                    <TextField
                        autoComplete="contract_type"
                        fullWidth
                        id="contract_type"
                        name="contract_type"
                        select
                        label="Contract Type"
                        value={data.contract_type}
                        onChange={handleChange}
                        margin="normal"
                    >
                        {contractTypes.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid> */}
                <Grid item sm={12} xs={12}>
                    <TextField
                        autoComplete="client_organization_status__c"
                        fullWidth
                        id="client_organization_status__c"
                        name="client_organization_status__c"
                        select
                        label="Client Contract Status"
                        value={data.client_organization_status__c}
                        onChange={handleChange}
                        margin="normal"
                    >
                        {statusTypes.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                {/* <Grid item sm={6} xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            fullWidth
                            format="MM/dd/yyyy"
                            variant="inline"
                            label="Contract Start Date"
                            value={data.contract_start_date}
                            onChange={handleContractStartDateChange}
                            renderInput={(params) => (
                                <TextField
                                    margin="dense"
                                    sx={{ width: "100%", marginBottom: "1rem", backgroundColor: "#fff" }}
                                    {...params}
                                />
                            )}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            fullWidth
                            format="MM/dd/yyyy"
                            variant="inline"
                            label="Contract End Date"
                            value={data.contract_end_date}
                            onChange={handleContractEndDateChange}
                            renderInput={(params) => (
                                <TextField
                                    margin="dense"
                                    sx={{ width: "100%", marginBottom: "1rem", backgroundColor: "#fff" }}
                                    {...params}
                                />
                            )}
                        />
                    </LocalizationProvider>
                </Grid> */}
                <Grid item sm={12} xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={data.enable_csv_download}
                                inputProps={{
                                    "aria-label": "Check To Enable 'Download CSV' Button In 'Sent To Client' Email",
                                }}
                                name="enable_csv_download"
                                onChange={handleCsvCheckbox}
                                value={data.enable_csv_download}
                            />
                        }
                        label="Check To Enable 'Download CSV' Button In 'Sent To Client' Email"
                    />
                </Grid>

                <Grid item sm={12} xs={12}>
                    <TextField
                        autoComplete="client_organization_type__c"
                        fullWidth
                        id="client_organization_type__c"
                        name="client_organization_type__c"
                        select
                        label="Client Organization Type"
                        value={data.client_organization_type__c}
                        onChange={handleChange}
                        margin="normal"
                    >
                        {clientTypes.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item sm={12} xs={12}>
                    <TextField
                        autoComplete="project_manager_name__c"
                        fullWidth
                        id="project_manager_name__c"
                        label="Project Manager Name"
                        margin="normal"
                        name="project_manager_name__c"
                        onChange={handleChange}
                        value={data.project_manager_name__c}
                    />
                </Grid>

                <Grid item sm={12} xs={12}>
                    <TextField
                        autoComplete="project_manager_email__c"
                        fullWidth
                        id="project_manager_email__c"
                        label="Project Manager Email"
                        margin="normal"
                        name="project_manager_email__c"
                        onChange={handleChange}
                        value={data.project_manager_email__c}
                    />
                </Grid>

                <Grid item sm={12} xs={12}>
                    <TextField
                        autoComplete="account_owner_email__c"
                        fullWidth
                        id="account_owner_email__c"
                        label="Account Owner Email"
                        margin="normal"
                        name="account_owner_email__c"
                        onChange={handleChange}
                        value={data.account_owner_email__c}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <TextField
                        autoComplete="annualrevenue"
                        fullWidth
                        id="annualrevenue"
                        label="Annual Revenue"
                        margin="normal"
                        name="annualrevenue"
                        onChange={handleChange}
                        value={data.annualrevenue}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <TextField
                        autoComplete="numberofemployees"
                        fullWidth
                        id="numberofemployees"
                        label="# of Employees"
                        margin="normal"
                        name="numberofemployees"
                        onChange={handleChange}
                        value={data.numberofemployees}
                    />
                </Grid>
                {/* <Grid item sm={6} xs={12}>
                    <TextField
                        autoComplete="total_units"
                        fullWidth
                        id="total_units"
                        label="Total Units (Sold)"
                        margin="normal"
                        name="total_units"
                        onChange={handleChange}
                        value={data.total_units}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <TextField
                        disabled={true}
                        fullWidth
                        id="available_units"
                        label="Available Units (Remaining)"
                        margin="normal"
                        name="available_units"
                        // onChange={handleChange}
                        value={data.available_units}
                    />
                </Grid> */}
                <Grid item sm={6} xs={12}>
                    <TextField
                        autoComplete="grace_period"
                        fullWidth
                        id="grace_period"
                        label="Grace Period in Minutes (Integer Only)"
                        margin="normal"
                        name="grace_period"
                        onChange={handlePricePerUnitField}
                        value={data.grace_period}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <div className={classes.label}>Duration Model</div>
                    <Select
                        name="duration_model"
                        onChange={handleDurationModel}
                        options={durationOptions}
                        value={data.duration_model}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <TextField
                        autoComplete="price_per_unit"
                        fullWidth
                        id="price_per_unit"
                        label="Price/Unit (Required - Integer Only)"
                        margin="normal"
                        name="price_per_unit"
                        onChange={handlePricePerUnitField}
                        value={data.price_per_unit}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <div className={classes.label}>Premium Pricing</div>
                    <Select
                        isDisabled={premium}
                        name="premium_pricing_model"
                        onChange={handlePremiums}
                        options={premiumOptions}
                        value={data.premium_pricing_model}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <TextField
                        autoComplete="custom_hourly_rate"
                        fullWidth
                        id="custom_hourly_rate"
                        label="Hourly Multiplier (hourly x 1.25)"
                        margin="normal"
                        name="custom_hourly_rate"
                        onChange={handleChange}
                        value={data.custom_hourly_rate}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <TextField
                        autoComplete="custom_charge"
                        fullWidth
                        id="custom_charge"
                        label="Custom Charge (ex: LEK=275)"
                        margin="normal"
                        name="custom_charge"
                        onChange={handleChange}
                        value={data.custom_charge}
                    />
                </Grid>
                <Grid item sm={12} xs={12}>
                    <TextField
                        autoComplete="industry"
                        fullWidth
                        id="industry"
                        label="Sector/Industry"
                        margin="normal"
                        name="industry"
                        onChange={handleChange}
                        value={data.industry}
                    />
                </Grid>
                <Grid item sm={12} xs={12}>
                    <div className={classes.label}>Current Expert Network Usage</div>
                    <Select
                        isMulti
                        name="current_expert_network_usage__c"
                        onChange={handleNetworksEvent}
                        options={expertNetworks}
                        value={data.current_expert_network_usage__c}
                    />
                </Grid>
                <Grid item sm={12} xs={12}>
                    <TextField
                        autoComplete="compliance_workflow_requirements__c"
                        fullWidth
                        id="compliance_workflow_requirements__c"
                        label="Compliance Workflow Requirements"
                        margin="normal"
                        name="compliance_workflow_requirements__c"
                        onChange={handleChange}
                        value={data.compliance_workflow_requirements__c}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <PhoneField changeEvent={handlePhoneEvent} value={data.phone} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        autoComplete="fax"
                        fullWidth
                        id="fax"
                        label="Fax"
                        margin="normal"
                        name="fax"
                        onChange={handleChange}
                        value={data.fax}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <TextField
                        autoComplete="type"
                        fullWidth
                        id="type"
                        label="Type"
                        margin="normal"
                        name="type"
                        onChange={handleChange}
                        value={data.type}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <TextField
                        autoComplete="website"
                        fullWidth
                        id="website"
                        label="Website URL"
                        margin="normal"
                        name="website"
                        onChange={handleChange}
                        value={data.website}
                    />
                </Grid>
                <Grid item sm={12} xs={12}>
                    <TextField
                        autoComplete="photourl"
                        fullWidth
                        id="photourl"
                        label="Photo URL"
                        margin="normal"
                        name="photourl"
                        onChange={handleChange}
                        value={data.photourl}
                        disabled
                    />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <TextField
                        autoComplete="current_lifesci__c"
                        fullWidth
                        id="current_lifesci__c"
                        label="Current LifeSci"
                        margin="normal"
                        name="current_lifesci__c"
                        onChange={handleChange}
                        value={data.current_lifesci__c}
                    />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <TextField
                        autoComplete="referred_by__c"
                        fullWidth
                        id="referred_by__c"
                        label="Referred By"
                        margin="normal"
                        name="referred_by__c"
                        onChange={handleChange}
                        value={data.referred_by__c}
                    />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <TextField
                        autoComplete="referred_by_email_address__c"
                        fullWidth
                        id="referred_by_email_address__c"
                        label="Referred By Email"
                        margin="normal"
                        name="referred_by_email_address__c"
                        onChange={handleChange}
                        value={data.referred_by_email_address__c}
                    />
                </Grid>
                <Grid item sm={12} xs={12}>
                    <TextField
                        autoComplete="description"
                        fullWidth
                        id="description"
                        label="Description"
                        margin="normal"
                        multiline
                        name="description"
                        onChange={handleChange}
                        rows="6"
                        value={data.description}
                    />
                </Grid>

                <Grid item sm={6} xs={12}>
                    <TextField
                        autoComplete="accountsource"
                        fullWidth
                        id="accountsource"
                        label="Account Source"
                        margin="normal"
                        name="accountsource"
                        onChange={handleChange}
                        value={data.accountsource}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <TextField
                        autoComplete="sicdesc"
                        fullWidth
                        id="sicdesc"
                        label="SICDESC"
                        margin="normal"
                        name="sicdesc"
                        onChange={handleChange}
                        value={data.sicdesc}
                    />
                </Grid>
                <Grid item sm={7} xs={12}>
                    <TextField
                        autoComplete="billingstreet"
                        fullWidth
                        id="billingstreet"
                        label="Billing Street"
                        margin="normal"
                        name="billingstreet"
                        onChange={handleChange}
                        value={data.billingstreet}
                    />
                </Grid>

                <Grid item sm={5} xs={12}>
                    <TextField
                        autoComplete="billingcity"
                        fullWidth
                        id="billingcity"
                        label="Billing City"
                        margin="normal"
                        name="billingcity"
                        onChange={handleChange}
                        value={data.billingcity}
                    />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <TextField
                        autoComplete="billingstate"
                        fullWidth
                        id="billingstate"
                        label="Billing State"
                        margin="normal"
                        name="billingstate"
                        onChange={handleChange}
                        value={data.billingstate}
                    />
                </Grid>
                <Grid item sm={5} xs={12}>
                    <TextField
                        autoComplete="billingcountry"
                        fullWidth
                        id="billingcountry"
                        label="Billing Country"
                        margin="normal"
                        name="billingcountry"
                        onChange={handleChange}
                        value={data.billingcountry}
                    />
                </Grid>
                <Grid item sm={3} xs={12}>
                    <TextField
                        autoComplete="billingpostalcode"
                        fullWidth
                        id="billingpostalcode"
                        label="Billing Postal Code"
                        margin="normal"
                        name="billingpostalcode"
                        onChange={handleChange}
                        value={data.billingpostalcode}
                    />
                </Grid>

                <Grid item sm={12} xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={data.same_as_address}
                                inputProps={{
                                    "aria-label": "Shipping Address Same As Billing",
                                }}
                                name="tutorial_completed"
                                onChange={handleCheckbox}
                                value={data.same_as_address}
                            />
                        }
                        label="Shipping Address Same As Billing"
                    />
                </Grid>
                <Grid item sm={7} xs={12}>
                    <TextField
                        autoComplete="shippingstreet"
                        fullWidth
                        id="shippingstreet"
                        label="Shipping Street"
                        margin="normal"
                        name="shippingstreet"
                        onChange={handleChange}
                        value={data.shippingstreet}
                    />
                </Grid>
                <Grid item sm={5} xs={12}>
                    <TextField
                        autoComplete="shippingcity"
                        fullWidth
                        id="shippingcity"
                        label="Shipping City"
                        margin="normal"
                        name="shippingcity"
                        onChange={handleChange}
                        value={data.shippingcity}
                    />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <TextField
                        autoComplete="shippingstate"
                        fullWidth
                        id="shippingstate"
                        label="Shipping State"
                        margin="normal"
                        name="shippingstate"
                        onChange={handleChange}
                        value={data.shippingstate}
                    />
                </Grid>
                <Grid item sm={5} xs={12}>
                    <TextField
                        autoComplete="shippingcountry"
                        fullWidth
                        id="shippingcountry"
                        label="Shipping Country"
                        margin="normal"
                        name="shippingcountry"
                        onChange={handleChange}
                        value={data.shippingcountry}
                    />
                </Grid>
                <Grid item sm={3} xs={12}>
                    <TextField
                        autoComplete="shippingpostalcode"
                        fullWidth
                        id="shippingpostalcode"
                        label="Shipping Postal Code"
                        margin="normal"
                        name="shippingpostalcode"
                        onChange={handleChange}
                        value={data.shippingpostalcode}
                    />
                </Grid>
                <Grid item sm={12} xs={12}>
                    {!loadingClient && (
                        <RequiresUpdateTagManager changeEvent={handleTagEvent} incomingTags={data.tags} />
                    )}
                </Grid>
                <Grid item sm={12} xs={12}>
                    <h2 className={classes.section}>Conference Bridge Settings</h2>
                </Grid>
                <Grid item sm={3} xs={12}>
                    <FormControl component="fieldset" className={classes.formControl}>
                        <RadioGroup
                            name="conference_bridge_setting"
                            value={data.conference_bridge_setting}
                            onChange={handleBridgeChange}
                        >
                            <FormControlLabel value="1" control={<Radio />} label="Twilio" labelPlacement="start" />
                            <FormControlLabel value="2" control={<Radio />} label="External" labelPlacement="start" />
                            <FormControlLabel value="3" control={<Radio />} label="Manual" labelPlacement="start" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item sm={9} xs={12} className={classes.leftBorder}>
                    {twilioDisplay && (
                        <Grid item sm={12} xs={12}>
                            <TwilioBridgeSettings
                                changeEvent={handleTwilioChange}
                                twilio_bridge_setting={data.twilio_bridge_setting}
                            />
                        </Grid>
                    )}
                    {externalDisplay && (
                        <Grid item sm={12} xs={12}>
                            <ExternalBridgeSettings
                                changeEvent={handleExternalBridgeSettings}
                                external_bridge_numbers={data.external_bridge_numbers}
                            />
                        </Grid>
                    )}
                    {manualDisplay && (
                        <Grid item sm={12} xs={12}>
                            <TextField
                                fullWidth
                                id="expert_phone"
                                label="Manually Send Expert's Phone Number"
                                margin="normal"
                                disabled
                            />
                        </Grid>
                    )}
                </Grid>
                <Grid item sm={9} xs={12}>
                    <Button
                        className={useStyles.submit}
                        color="primary"
                        fullWidth
                        onClick={handleSubmit}
                        variant="contained"
                    >
                        <Save />
                        Save
                    </Button>
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Button className={useStyles.submit} fullWidth onClick={navigateToView} variant="contained">
                        Cancel
                    </Button>
                </Grid>
            </Grid>
            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        <Messaging user={auth.data} type="Client" />
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {data.firstname} {data.lastname} has successfully been updated. However, please allow 10
                            minutes to sync with Salesforce.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" autoFocus>
                            Close
                        </Button>
                        <Link to={`/client/view/${props.match.params.id}`} className={classes.link}>
                            Back to Profile View
                        </Link>
                    </DialogActions>
                </Dialog>
            </div>
        </form>
    );
}

Client.propTypes = {
    match: PropTypes.object,
};
Client.defaultProps = {
    match: null,
};

export default Client;
