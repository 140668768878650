import React, { useState, useEffect, useContext } from "react";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import axios from "axios";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Slide from "@mui/material/Slide";
import Paper from "@mui/material/Paper";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
// import Attachment from "@mui/icons-material/Attachment";
import moment from "moment";
import "moment-duration-format";
import Comments from "../components/Comments";
import ClientContactForm from "../components/ClientContactForm";
import premiumOptions from "../data/premiumOptions";
import durationOptions from "../data/durationOptions";
import { authContext } from "../context/auth";
import { stripNonNumericChars, basicDateTimeStamp } from "../Utils";

// const contractTypes = [
//     { value: 1, label: "Trial" },
//     { value: 2, label: "Subscription" },
//     { value: 3, label: "Pay Go" },
// ];
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    appBar: {
        position: "relative",
    },
    table: {
        width: "100%",
    },
    hcell: {
        textAlign: "left",
        borderBottom: "1px solid #CCC",
        fontSize: ".35rem",
    },
    tcell: {
        textAlign: "left",
        borderBottom: "1px solid #F1F1F1",
        padding: "5px",
        verticalAlign: "top",
    },
    actioncell: {
        textAlign: "right",
        borderBottom: "1px solid #F1F1F1",
        padding: "5px",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    label: {
        color: "#8aa694",
        marginBottom: theme.spacing(0.25),
        fontSize: ".65rem",
        textAlign: "left",
        fontWeight: "normal",
        textTransform: "uppercase",
    },
    name: {
        color: "#3d4a42",
        marginBottom: theme.spacing(0.25),
        fontSize: "2rem",
        textAlign: "left",
        fontWeight: "bold",
        fontFamily: "Serif",
    },
    position: {
        color: "#444",
        marginTop: "0",
        marginBottom: theme.spacing(0.25),
        fontSize: "1rem",
        textAlign: "left",
        fontWeight: "normal",
    },
    bio: {
        color: "#444",
        marginBottom: theme.spacing(0.25),
        fontSize: "1rem",
        textAlign: "left",
        fontWeight: "normal",
    },
    contacts: {
        color: "#444",
        marginBottom: theme.spacing(0.25),
        fontSize: ".85rem",
        textAlign: "left",
        fontWeight: "normal",
    },
    contact: {
        color: "#444",
        marginBottom: theme.spacing(0.25),
        fontSize: ".75rem",
        textAlign: "left",
        fontWeight: "normal",
    },
    customizations: {
        color: "#000000",
        marginTop: "15px",
        marginBottom: theme.spacing(0.5),
        fontSize: ".75rem",
        textAlign: "left",
        fontWeight: "bold",
    },
    dates: {
        color: "#444",
        marginBottom: theme.spacing(0.25),
        fontSize: ".75rem",
        textAlign: "left",
        fontWeight: "normal",
        fontStyle: "italic",
    },
    strong: {
        color: "#888",
        marginBottom: theme.spacing(0.5),
        fontSize: "inherit",
    },
    tags: {
        color: "#607467",
        marginBottom: theme.spacing(0.5),
        fontSize: ".75rem",
    },
    current: {
        backgroundColor: "#ecf8f0",
        borderRadius: "2px",
        color: "#000000",
        padding: "2px 8px",
    },
    link: {
        color: "#3c8864",
    },
    appBarName: {
        color: "#b7ddd9",
    },
    margin: {
        margin: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    button: {
        fontSize: ".65rem",
    },
    callPhone: {
        display: "block",
        backgroundColor: "#4CAB7D",
        borderRadius: "3px",
        color: "#fff",
        padding: "8px 20px",
        textTransform: "uppercase",
        marginBottom: "10px",
        marginTop: "5px",
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function ClientShow(props) {
    const { match, history } = props;
    const { id } = match.params;
    const classes = useStyles();
    const { setAuthData, auth } = useContext(authContext);
    const [expanded, setExpanded] = useState("panel1");
    const [open, setOpen] = useState(false);
    const [openContact, setOpenContact] = useState(false);
    const [data, setData] = useState({
        id: "",
        salesforceid: "",
        isdeleted: false,
        name: "",
        status: "",
        type: "",
        parentid: "",
        billingstreet: "",
        billingcity: "",
        billingstate: "",
        billingpostalcode: "",
        billingcountry: "",
        shippingstreet: "",
        shippingcity: "",
        shippingstate: "",
        shippingpostalcode: "",
        shippingcountry: "",
        phone: "",
        fax: "",
        website: "",
        photourl: "",
        industry: "",
        annualrevenue: "",
        numberofemployees: "",
        description: "",
        ownerid: "",
        createddate: "",
        createdbyid: "",
        lastmodifieddate: "",
        lastmodifiedbyid: "",
        lastvieweddate: "",
        accountsource: "",
        sicdesc: "",
        client_organization_type__c: "",
        client_organization_status__c: "",
        account_owner_email__c: "",
        current_expert_network_usage__c: [],
        compliance_workflow_requirements__c: "",
        project_manager_name__c: "",
        project_manager_email__c: "",
        current_lifesci__c: "",
        referred_by__c: "",
        referred_by_email_address__c: "",
        conference_bridge_setting: "1",
        twilio_bridge_setting: "1",
        external_bridge_numbers: [],
        notifications: [],
        tags: [],
        comments: [],
        contacts: [],
        custom_charge: 0,
        custom_hourly_rate: 1.0,
        // contract_type: "",
        // contract_start_date: "",
        // contract_end_date: "",
        // total_units: 0.0,
        // available_units: 0.0,
    });

    const [contact, setContact] = useState({
        accountid: data.salesforceid,
        assistantname: "",
        assistantphone: "",
        birthdate: "",
        clientid: data.id,
        client_id: data.id,
        clientname: "",
        created_on: "",
        createdbyid: "",
        createddate: "",
        department: "",
        description: "",
        email: "",
        emailbounceddate: "",
        emailbouncedreason: "",
        fax: "",
        firstname: "",
        homephone: "",
        id: "",
        include_in_org_chart__c: false,
        isdeleted: false,
        isemailbounced: false,
        lastactivitydate: "",
        lastmodifiedbyid: "",
        lastmodifieddate: "",
        lastname: "",
        lastvieweddate: "",
        leadsource: "",
        mailingcity: "",
        mailingcountry: "",
        mailingpostalcode: "",
        mailingstate: "",
        mailingstreet: "",
        mobilephone: "",
        name: "",
        otherphone: "",
        ownerid: "",
        phone: "",
        photourl: "",
        picture__c: "",
        referred_by__c: "",
        referred_by_email_address__c: "",
        reportstoid: "",
        salesforceid: "", // ID of existing user
        salutation: "",
        systemmodstamp: "",
        title: "",
        conference_bridge_setting: "1",
        twilio_bridge_setting: "1",
        external_bridge_numbers: [],
        notifications: [],
        project_bridge_settings: [],
        tags: [],
        comments: [],
        custom_charge: 0,
        custom_hourly_rate: 1.0,
        premium_pricing_model: {},
        price_per_unit: 0,
        grace_period: 3,
        duration_model: {},
        enable_csv_download: false,
    });
    const [projects, setProjects] = useState([]);

    const handleClickOpenContact = (cc) => {
        setContact({
            ...contact,
            accountid: cc.salesforceid,
            assistantname: cc.assistantname,
            assistantphone: cc.assistantphone,
            birthdate: cc.birthdate,
            clientid: cc.clientid,
            client_id: cc.client_id,
            clientname: cc.clientname,
            created_on: cc.created_on,
            createdbyid: cc.createdbyid,
            createddate: cc.createddate,
            department: cc.department,
            description: cc.description,
            email: cc.email,
            emailbounceddate: cc.emailbounceddate,
            emailbouncedreason: cc.emailbouncedreason,
            fax: cc.fax,
            firstname: cc.firstname,
            homephone: cc.homephone,
            id: cc.id,
            include_in_org_chart__c: cc.include_in_org_chart__c,
            isdeleted: cc.isdeleted,
            isemailbounced: cc.isemailbounced,
            lastactivitydate: cc.lastactivitydate,
            lastmodifiedbyid: cc.lastmodifiedbyid,
            lastmodifieddate: cc.lastmodifieddate,
            lastname: cc.lastname,
            lastvieweddate: cc.lastvieweddate,
            leadsource: cc.leadsource,
            mailingcity: cc.mailingcity,
            mailingcountry: cc.mailingcountry,
            mailingpostalcode: cc.mailingpostalcode,
            mailingstate: cc.mailingstate,
            mailingstreet: cc.mailingstreet,
            mobilephone: cc.mobilephone,
            name: cc.name,
            otherphone: cc.otherphone,
            ownerid: cc.ownerid,
            phone: cc.phone,
            photourl: cc.photourl,
            picture__c: cc.picture__c,
            referred_by__c: cc.referred_by__c,
            referred_by_email_address__c: cc.referred_by_email_address__c,
            reportstoid: cc.reportstoid,
            salesforceid: cc.salesforceid, // ID of existing user
            salutation: cc.salutation,
            systemmodstamp: cc.systemmodstamp,
            title: cc.title,
            conference_bridge_setting: cc.conference_bridge_setting,
            twilio_bridge_setting: cc.twilio_bridge_setting,
            external_bridge_numbers: cc.external_bridge_numbers,
            notifications: cc.notifications,
            project_bridge_settings: cc.project_bridge_settings,
            tags: cc.tags,
            comments: cc.comments,
        });
        setOpenContact(true);
        console.log("OPEN Client Contact MODAL: ", cc);
    };

    const handlePanelChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleBack = () => {
        history.goBack();
    };

    const handleCloseContact = () => {
        setOpenContact(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function handleChange(event) {
        const { target } = event;
        const { value, name } = target;
        setData({
            ...data,
            [name]: value,
        });
    }

    function handleChangeContact(event) {
        const { target } = event;
        const { value, name } = target;
        setContact({
            ...contact,
            [name]: value,
        });
    }

    function setCommentsArray(arr) {
        if (typeof arr === "object") {
            setData({
                ...data,
                comments: arr,
            });
        }
    }

    useEffect(() => {
        async function fetchClientById(id) {
            // Client #169 is great for client contacts
            const clientCall = await axios.get(`/api/client/find?id=${id}`);
            if (clientCall.status === 200 && clientCall.data && clientCall.data.data) {
                if (clientCall.data.data[0].length === 0) return;
                const client = clientCall.data.data[0][0];
                const { custom_charge, custom_hourly_rate, price_per_unit, grace_period, enable_csv_download } = client;
                const tags = clientCall.data.data[1].map((tag) => {
                    return { label: tag.name, value: tag.id };
                });
                const premium_pricing_model_id = premiumOptions.find(
                    (option) => option.value === parseInt(client.premium_pricing_model_id, 10)
                ).label;
                const duration_model_id = durationOptions.find(
                    (option) => option.value === parseInt(client.duration_model_id, 10)
                ).label;
                console.log("Return DATA", clientCall);

                setData({
                    ...data,
                    id: client.id,
                    salesforceid: client.salesforceid,
                    isdeleted: client.isdeleted,
                    name: client.name,
                    status: client.status,
                    type: client.type,
                    parentid: client.parentid,
                    billingstreet: client.billingstreet,
                    billingcity: client.billingcity,
                    billingstate: client.billingstate,
                    billingpostalcode: client.billingpostalcode,
                    billingcountry: client.billingcountry,
                    shippingstreet: client.shippingstreet,
                    shippingcity: client.shippingcity,
                    shippingstate: client.shippingstate,
                    shippingpostalcode: client.shippingpostalcode,
                    shippingcountry: client.shippingcountry,
                    phone: client.phone,
                    fax: client.fax,
                    website: client.website,
                    photourl: client.photourl,
                    industry: client.industry,
                    annualrevenue: client.annualrevenue,
                    numberofemployees: client.numberofemployees,
                    description: client.description,
                    ownerid: client.ownerid,
                    createddate: client.createddate,
                    createdbyid: client.createdbyid,
                    lastmodifieddate: client.lastmodifieddate,
                    lastmodifiedbyid: client.lastmodifiedbyid,
                    lastvieweddate: client.lastvieweddate,
                    accountsource: client.accountsource,
                    sicdesc: client.sicdesc,
                    client_organization_type__c: client.client_organization_type__c,
                    client_organization_status__c: client.client_organization_status__c,
                    account_owner_email__c: client.account_owner_email__c,
                    current_expert_network_usage__c: client.current_expert_network_usage__c,
                    compliance_workflow_requirements__c: client.compliance_workflow_requirements__c,
                    project_manager_name__c: client.project_manager_name__c,
                    project_manager_email__c: client.project_manager_email__c,
                    current_lifesci__c: client.current_lifesci__c,
                    referred_by__c: client.referred_by__c,
                    referred_by_email_address__c: client.referred_by_email_address__c,
                    conference_bridge_setting: client.conference_bridge_setting,
                    twilio_bridge_setting: client.twilio_bridge_setting,
                    external_bridge_numbers: client.external_bridge_numbers,
                    notifications: client.notifications,
                    tags,
                    comments: clientCall.data.data[2],
                    contacts: clientCall.data.data[3],
                    custom_charge,
                    custom_hourly_rate,
                    premium_pricing_model_id,
                    price_per_unit,
                    grace_period,
                    duration_model_id,
                    enable_csv_download,
                    // contract_type: client.contract_type,
                    // contract_start_date: client.contract_start_date,
                    // contract_end_date: client.contract_end_date,
                    // total_units: client.total_units,
                    // available_units: client.available_units,
                });
            }
        }
        if (id) {
            fetchClientById(id);
        }
    }, [id]);

    function navigateToEdit() {
        history.push(`/client/edit/${id}`);
    }

    function formatDateString(str) {
        return moment(str).format("MMM. Do, YYYY, h:mma");
    }

    function createProject(cc) {
        history.push(`/project/create?id=${cc.id}`);
    }

    function addClient() {
        console.log("Add Client Contact Clicked!");
        history.push(`/contact/add?id=${data.id}`);
    }

    function submitDescriptionEvent() {
        data.id = id;
        data.lastmodifieddate = new Date().toISOString();
        const url = "/api/client/update";
        if (data.description !== "") {
            axios
                .post(url, data, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                })
                .then((result) => {
                    if (result.status === 200) {
                        console.log(`Successful POST: ${JSON.stringify(result.data.data)}`);
                        setOpen(false);
                    } else {
                        console.log(`Good POST, but something went wrong: ${JSON.stringify(data)}`);
                    }
                })
                .catch(() => {
                    setOpen(false);
                    console.log(`API not done yet, 404 Error: ${JSON.stringify(data)}`);
                });
        }
    }

    function submitClientContactEvent() {
        let url = "/api/contact/create";
        if (contact.salesforceid !== "") {
            url = "/api/contact/update";
        }
        if (contact.firstname !== "" && contact.lastname !== "" && contact.email !== "") {
            axios
                .post(url, contact, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                })
                .then((result) => {
                    if (result.status === 200) {
                        console.log(`Successful POST: ${JSON.stringify(result.data.data)}`);
                        // Find and update the client data array with modified contacts
                        // Find the contact obj in the array by ID
                        const index = data.contacts.findIndex(({ id }) => id === result.data.data.id);
                        if (index !== -1) {
                            data.contacts[index] = result.data.data;
                        }
                        // SET PARENT CLIENT STATE
                        setData({
                            ...data,
                            contacts: data.contacts,
                        });
                        // Close the MODAL
                        setOpenContact(false);
                    } else {
                        console.log(`Good POST, but something went wrong: ${JSON.stringify(data)}`);
                    }
                })
                .catch(() => {
                    setOpenContact(false);
                    console.log(`API not done yet, 404 Error: ${JSON.stringify(data)}`);
                });
        }
    }

    useEffect(() => {
        axios
            .get("/api/project/findAll", {
                email: "aanand@email.com",
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    console.log("Projects", result.data.data);
                    // Find Only project under this client
                    const projectsData = result.data.data
                        .filter((item) => item.client_id === parseInt(props.match.params.id, 10))
                        .sort((a, b) => moment(b.created_on) - moment(a.created_on));
                    console.log("Filtered Projects: ", projectsData);
                    setProjects(projectsData);
                }
            });
    }, []);

    return (
        <div>
            <Dialog fullScreen open={openContact} onClose={handleCloseContact} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleCloseContact}
                            aria-label="close"
                            size="large"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            <span className={classes.appBarName}>{data.name}</span> Add/Edit Contact
                        </Typography>
                        <Button color="inherit" onClick={submitClientContactEvent}>
                            Save
                        </Button>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <ClientContactForm
                        id="contact"
                        margin="normal"
                        name="contact"
                        changeEvent={handleChangeContact}
                        data={contact}
                    />
                </DialogContent>
            </Dialog>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" size="large">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            <span className={classes.appBarName}>
                                {data.firstname} {data.lastname}
                            </span>{" "}
                            Description
                        </Typography>
                        <Button color="inherit" onClick={submitDescriptionEvent}>
                            Save
                        </Button>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <TextField
                        autoComplete="description"
                        autoFocus
                        fullWidth
                        id="description"
                        label="Description"
                        margin="normal"
                        multiline
                        name="description"
                        onChange={handleChange}
                        rows="40"
                        value={data.description}
                    />
                </DialogContent>
            </Dialog>
            <Grid container spacing={3}>
                <Grid item sm={9} xs={12}>
                    <Grid container spacing={3}>
                        <Grid item sm={2} xs={12}>
                            <Button
                                className={classes.button}
                                size="small"
                                fullWidth
                                onClick={handleBack}
                                variant="contained"
                            >
                                Back
                            </Button>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <Button
                                className={classes.button}
                                size="small"
                                color="primary"
                                fullWidth
                                onClick={navigateToEdit}
                                variant="contained"
                            >
                                Edit This Client
                            </Button>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <Button
                                className={classes.button}
                                size="small"
                                color="primary"
                                fullWidth
                                onClick={addClient}
                                variant="contained"
                            >
                                Add Contact
                            </Button>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <Paper className={classes.paper}>
                                <p className={classes.label}>
                                    {/* {data.contract_type && (
                                        <>
                                            Contract:{" "}
                                            <strong className={classes.strong}>
                                                {
                                                    contractTypes.find(
                                                        (c) =>
                                                            parseInt(c.value, 10) === parseInt(data.contract_type, 10)
                                                    ).label
                                                }
                                            </strong>
                                            {", "}
                                        </>
                                    )} */}
                                    Type: <strong className={classes.strong}>{data.client_organization_type__c}</strong>
                                    {", "}
                                    Status:{" "}
                                    <strong className={classes.strong}>{data.client_organization_status__c}</strong>
                                    {", "}
                                    Salesforce ID: <strong className={classes.strong}>{data.salesforceid}</strong>
                                    {", "}
                                    Last Modified:{" "}
                                    <strong className={classes.strong}>
                                        {formatDateString(data.lastmodifieddate)}
                                    </strong>
                                </p>
                                <h1 className={classes.name}>{data.name}</h1>
                                <h2 className={classes.position}>
                                    Sector/Industry: <strong>{data.industry ? data.industry : "---"}</strong>, Revenue:{" "}
                                    <strong>{data.annualrevenue ? data.annualrevenue : "---"}</strong>, Employees:{" "}
                                    <strong>{data.numberofemployees ? data.numberofemployees : "---"}</strong>
                                    {data.custom_hourly_rate > 1 && (
                                        <>
                                            <span>, Hourly Multiplier: </span>
                                            <strong>{data.custom_hourly_rate}</strong>
                                        </>
                                    )}
                                    {data.custom_charge > 0 && (
                                        <>
                                            <span>, Custom Fee Charge: </span>
                                            <strong>{data.custom_charge}</strong>
                                        </>
                                    )}
                                </h2>
                                {data.project_manager_name__c && (
                                    <h4 className={classes.contact}>
                                        Project Manager: {data.project_manager_name__c}, {data.project_manager_email__c}
                                    </h4>
                                )}
                                {data.account_owner_email__c && (
                                    <h3 className={classes.contact}>Account Owner: {data.account_owner_email__c}</h3>
                                )}
                                {data.compliance_workflow_requirements__c && (
                                    <h3 className={classes.contact}>
                                        Compliance Requirements: {data.compliance_workflow_requirements__c}
                                    </h3>
                                )}
                                {/* {data.contract_start_date && data.contract_end_date && (
                                    <>
                                        <h3 className={classes.contact}>
                                            Contract Start: {basicDateTimeStamp(data.contract_start_date)}
                                        </h3>
                                        <h3 className={classes.contact}>
                                            Contract End: {basicDateTimeStamp(data.contract_end_date)}
                                        </h3>
                                    </>
                                )} */}
                                {data.phone && (
                                    <>
                                        <a
                                            href={`tel:${stripNonNumericChars(data.phone)}`}
                                            className={classes.callPhone}
                                        >
                                            Call Now: {`${data.phone}`}
                                        </a>
                                    </>
                                )}
                                {data.billingcity && (
                                    <h4 className={classes.contact}>
                                        {data.billingstreet} {data.billingcity},{" "}
                                        {data.billingstate && data.billingstate}{" "}
                                        {data.billingcountry && data.billingcountry}
                                    </h4>
                                )}
                                {data.current_expert_network_usage__c && (
                                    <h4 className={classes.contact}>
                                        Current Expert Network Usage:{" "}
                                        {data.current_expert_network_usage__c.map(function (u, idx) {
                                            return (
                                                <strong className={classes.tags} key={idx}>
                                                    {" "}
                                                    {u.label};{" "}
                                                </strong>
                                            );
                                        })}
                                    </h4>
                                )}
                                {data.tags.length > 0 && (
                                    <h5 className={classes.contact}>
                                        <strong>Tags:</strong>
                                        {data.tags.map(function (t, idx) {
                                            return (
                                                <em className={classes.tags} key={idx}>
                                                    {" "}
                                                    {t.label}
                                                    {idx < data.tags.length - 1 && ", "}
                                                </em>
                                            );
                                        })}
                                    </h5>
                                )}
                                {data.website && (
                                    <p className={classes.contact}>
                                        Visit:{" "}
                                        <a
                                            href={data.website}
                                            className={classes.link}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {data.website}
                                        </a>
                                    </p>
                                )}
                                <h4 className={classes.customizations}>* PRICING SETTINGS *</h4>
                                <p className={classes.contact}>
                                    Price Per Unit: <strong>{data.price_per_unit}</strong>
                                </p>
                                <p className={classes.contact}>
                                    Custom Charge: <strong>{data.custom_charge}</strong>
                                </p>
                                <p className={classes.contact}>
                                    Hourly Multiplier: <strong>{data.custom_hourly_rate}</strong>
                                </p>
                                <p className={classes.contact}>
                                    Premium Pricing Model: <strong>{data.premium_pricing_model_id}</strong>
                                </p>
                                <p className={classes.contact}>
                                    Duration Model: <strong>{data.duration_model_id}</strong>
                                </p>
                                <p className={classes.contact}>
                                    Grace Period: <strong>{data.grace_period}</strong>
                                </p>
                            </Paper>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <Accordion square expanded={expanded === "panel1"} onChange={handlePanelChange("panel1")}>
                                <AccordionSummary
                                    aria-controls="panel1d-content"
                                    id="panel1d-header"
                                    expandIcon={<ExpandMoreIcon />}
                                    className={classes.accordionSummary}
                                >
                                    <Typography>PROJECT HISTORY</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {projects.length > 0 && (
                                        <Paper className={classes.paper}>
                                            <table className={classes.table} cellSpacing={0}>
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className={classes.hcell}>
                                                            STATUS
                                                        </th>
                                                        <th scope="col" className={classes.hcell}>
                                                            TITLE
                                                        </th>
                                                        <th scope="col" className={classes.hcell}>
                                                            CONTACT
                                                        </th>
                                                        <th scope="col" className={classes.hcell}>
                                                            CREATED
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {projects.map(function (c, idx) {
                                                        return (
                                                            <tr className={classes.contacts} key={c.id}>
                                                                <td scope="col" className={classes.tcell}>
                                                                    {c.project_status}
                                                                </td>
                                                                <td scope="col" className={classes.tcell}>
                                                                    <Link
                                                                        className={classes.link}
                                                                        to={`/project/view/${c.id}`}
                                                                    >
                                                                        {c.title}
                                                                    </Link>
                                                                    <br />
                                                                    PM:{" "}
                                                                    <strong>
                                                                        {c.project_manager_fname}{" "}
                                                                        {c.project_manager_lname}
                                                                    </strong>{" "}
                                                                    | CODE: {c.case_code}
                                                                    <br />
                                                                    {c.experts_count} Attached / {c.experts_scheduled}{" "}
                                                                    Scheduled
                                                                </td>
                                                                <td className={classes.tcell}>
                                                                    {c.client_contacts.map(
                                                                        (e) =>
                                                                            e.isprimary && (
                                                                                <Link
                                                                                    className={classes.link}
                                                                                    key={e.client_contact_id}
                                                                                    to={`/contact/view/${e.client_contact_id}`}
                                                                                >
                                                                                    {`${e.firstname} ${e.lastname}`}
                                                                                </Link>
                                                                            )
                                                                    )}
                                                                    <br />
                                                                    {c.project_type}
                                                                </td>
                                                                <td className={classes.tcell}>
                                                                    {formatDateString(c.created_on)}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </Paper>
                                    )}
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        {data.contacts.length > 0 && (
                            <Grid item sm={12} xs={12}>
                                <Paper className={classes.paper}>
                                    <label className={classes.label}>CONTACTS</label>
                                    <table className={classes.table} cellSpacing={0}>
                                        <thead>
                                            <tr>
                                                <th scope="col" className={classes.hcell}>
                                                    NAME
                                                </th>
                                                <th scope="col" className={classes.hcell}>
                                                    PHONE
                                                </th>
                                                <th scope="col" className={classes.hcell}>
                                                    EMAIL
                                                </th>
                                                <th scope="col" className={classes.hcell}>
                                                    &nbsp;
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.contacts.map(function (c, idx) {
                                                return (
                                                    <tr className={classes.contacts} key={c.id}>
                                                        <td scope="col" className={classes.tcell}>
                                                            <Link className={classes.link} to={`/contact/view/${c.id}`}>
                                                                {c.firstname} {c.lastname}
                                                            </Link>
                                                        </td>
                                                        <td scope="col" className={classes.tcell}>
                                                            {c.mobilephone && (
                                                                <a
                                                                    href={`tel:${stripNonNumericChars(c.mobilephone)}`}
                                                                    className={classes.callPhone}
                                                                >
                                                                    {`${c.mobilephone}`}
                                                                </a>
                                                            )}
                                                            {c.phone && (
                                                                <a
                                                                    href={`tel:${stripNonNumericChars(c.phone)}`}
                                                                    className={classes.callPhone}
                                                                >
                                                                    {`${c.phone}`}
                                                                </a>
                                                            )}
                                                        </td>
                                                        <td scope="col" className={classes.tcell}>
                                                            {c.email ? c.email : "Not Available"}
                                                        </td>
                                                        <td className={classes.actioncell}>
                                                            <Button
                                                                className={classes.link}
                                                                onClick={() => createProject(c)}
                                                                variant="contained"
                                                            >
                                                                Start Project
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </Paper>
                            </Grid>
                        )}
                        {data.description ? (
                            <Grid item sm={12} xs={12}>
                                <Paper className={classes.paper}>
                                    <label className={classes.label}>DESCRIPTION</label>
                                    <p className={classes.bio}>{data.description}</p>
                                    <Button className={classes.link} onClick={handleClickOpen}>
                                        Edit Description
                                    </Button>
                                </Paper>
                            </Grid>
                        ) : (
                            <Grid item sm={12} xs={12}>
                                <Paper className={classes.paper}>
                                    <label className={classes.label}>DESCRIPTION</label>
                                    <p className={classes.bio}>
                                        No description available.{" "}
                                        <Button className={classes.link} onClick={handleClickOpen}>
                                            Add one now
                                        </Button>
                                    </p>
                                </Paper>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Comments
                        user={auth.data}
                        object_type="Client"
                        object_id={props.match.params.id}
                        formatDate={formatDateString}
                        setComments={setCommentsArray}
                        comments={data.comments}
                        height="auto"
                    />
                </Grid>
            </Grid>
        </div>
    );
}

ClientShow.propTypes = {
    match: PropTypes.object,
};
ClientShow.defaultProps = {
    match: null,
};

export default ClientShow;
