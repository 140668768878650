import React, { useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    section: {
        backgroundColor: "#add0b9",
        color: "#FFFFFF",
        padding: "8px",
        fontSize: "1.25rem",
        borderRadius: "2px",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: "75%",
        flexShrink: 0,
        fontWeight: "normal",
    },
    label: {
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(0.5),
        fontSize: "1rem",
    },
    strong: {
        color: theme.palette.text.primary,
        marginBottom: theme.spacing(0.5),
        fontSize: "1rem",
    },
    dates: {
        fontSize: "1rem",
        color: "#738b7b",
    },
    margin: {
        margin: theme.spacing(1),
    },
    school: {
        padding: "0px",
        backgroundColor: "#ecf8f0",
        border: "1px solid #add0b9",
        borderRadius: "3px",
        color: "#000000",
        fontSize: "1rem",
        marginBottom: theme.spacing(1),
        cursor: "pointer",
    },
    body: {
        padding: "12px",
    },
    handle: {
        backgroundColor: "#add0b9",
        color: "#FFFFFF",
        padding: "5px",
        textAlign: "center",
        fontSize: ".75rem",
    },
}));

function EducationFields(props) {
    const classes = useStyles();
    const [schoolIndex, setIndex] = useState("");
    const [isEdit, setEdit] = useState(false);

    const [education, setEducation] = useState({
        school: "",
        degree: "",
        dates: "",
    });
    const [data, setData] = useState({
        schools: [],
    });

    useEffect(() => {
        setData({ schools: props.education });
    }, [props]);

    function emptyCurrentEducationState() {
        setEducation({
            ...education,
            school: "",
            degree: "",
            dates: "",
        });
        setEdit(false);
    }
    function handleChange(event) {
        const { target } = event;
        const { value, name } = target;
        setEducation({
            ...education,
            [name]: value,
        });
    }
    function saveSchoolEdit() {
        const ed = data.schools[schoolIndex];
        ed.school = education.school;
        ed.degree = education.degree;
        ed.dates = education.dates;

        props.setEducationHistory(data.schools);
        emptyCurrentEducationState();
        setIndex("");
    }
    function handleAddSchool(event) {
        if (education.school !== "") {
            data.schools.push(education);
            props.setEducationHistory(data.schools);
            emptyCurrentEducationState();
        }
    }
    function editSchoolClickEvent(index) {
        props.anchorPoint.current.scrollIntoView({ behavior: "smooth", block: "start" });
        const school = data.schools[index];
        setEdit(true);
        setIndex(index);
        setEducation({
            ...education,
            school: school.school,
            degree: school.degree,
            dates: school.dates,
        });
    }
    function deleteSchool(event) {
        if (window.confirm("Are you sure you want to delete this school?")) {
            const school = data.schools[schoolIndex];
            if (school !== "undefined") {
                data.schools = data.schools.filter((item) => item !== school);
                // Set master expert state education object
                props.setEducationHistory(data.schools);
                emptyCurrentEducationState();
            }
        }
    }
    return (
        <Grid container spacing={3}>
            <Grid item sm={12} xs={12}>
                <h2 className={classes.section}>Education</h2>
            </Grid>
            <Grid item sm={12} xs={12}>
                <TextField
                    autoComplete="school"
                    fullWidth
                    id="school"
                    label="School/Institution"
                    margin="normal"
                    name="school"
                    onChange={handleChange}
                    value={education.school}
                />
            </Grid>
            <Grid item sm={6} xs={12}>
                <TextField
                    autoComplete="degree"
                    fullWidth
                    id="degree"
                    label="Degree"
                    margin="normal"
                    name="degree"
                    onChange={handleChange}
                    value={education.degree}
                />
            </Grid>
            <Grid item sm={6} xs={12}>
                <TextField
                    autoComplete="dates"
                    fullWidth
                    id="dates"
                    label="Dates"
                    margin="normal"
                    name="dates"
                    onChange={handleChange}
                    value={education.dates}
                />
            </Grid>
            {isEdit ? (
                <Grid container spacing={2}>
                    <Grid item sm={9} xs={12}>
                        <Button
                            className={useStyles.submit}
                            color="secondary"
                            fullWidth
                            onClick={saveSchoolEdit}
                            variant="contained"
                        >
                            Save/Edit Education
                        </Button>
                    </Grid>
                    <Grid item sm={3} xs={12}>
                        <Button className={useStyles.submit} fullWidth onClick={deleteSchool} variant="contained">
                            Delete
                        </Button>
                    </Grid>
                </Grid>
            ) : (
                <Button className={useStyles.submit} fullWidth onClick={handleAddSchool} variant="contained">
                    Add New Education
                </Button>
            )}
            <Grid item sm={12} xs={12}>
                <div id="Education">
                    {data.schools.map((school, index) => (
                        <div className={classes.school} key={index} onClick={() => editSchoolClickEvent(index)}>
                            <div className={classes.handle}>Click to edit this education item</div>
                            <div className={classes.body}>
                                <h2 className={classes.heading}>
                                    <strong>{school.school}</strong>
                                    {school.degree === "" ? null : ", " + school.degree}
                                </h2>
                                <em className={classes.dates}>{school.dates}</em>
                            </div>
                        </div>
                    ))}
                </div>
            </Grid>
        </Grid>
    );
}

export default EducationFields;
