import React, { useState, useEffect, useContext } from "react";

// mui
import { Grid, Typography, Skeleton, Stack } from "@mui/material";

// npm imports
import queryString from "query-string";
import axios from "axios";

// date-fns stuff
import { add } from "date-fns";

// custom components
import FlashMessagev3 from "../components/FlashMessagev3";
import SchedulingTable from "../components/scheduling/SchedulingTable";
import ScheduledCallsTabPanel from "../components/scheduling/ScheduledCallsTabPanel";
import SchedulingDialog from "../components/scheduling/dialog/SchedulingDialog";
import ExpertPreviewWrapperNext from "../components/wrappers/ExpertPreviewWrapperNext";

// utils
import { getTimezones } from "../components/scheduling/utils/utils";

// auth
import { authContext } from "../context/auth";

// grab timezones using helper func
const timezones = getTimezones();

const ProjectSchedule = (props) => {
    // navigation
    const { history } = props;

    // =========
    // CONSTANTS
    // =========

    const projectId = props.match.params.id;
    const ADDITIONAL_MANAGER_ACCESS = [3, 67291, 153383, 95647, 309841, 67281, 340269, 309842];
    // 3 - dan c., 67291 - michale f., 153383 - victoria, 95647 - felix b., 309841 - Anjali, 67281 - Joe Riggio, 340269 - Brandon Orr, 309842 - Jon Krais

    // =====
    // STATE
    // =====

    // error state
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    // scheduled / completed expert and calls
    const [completedCalls, setCompletedCalls] = useState([]);
    const [scheduledCalls, setScheduledCalls] = useState([]);

    // expert data
    const [projectData, setProjectData] = useState([]);
    const [expertsNeedingScheduling, setExpertsNeedingScheduling] = useState([]);

    // authentication
    const [isUserAuthorized, setIsUserAuthorized] = useState(false);
    // const [userAccessList, setUserAccessList] = useState([]);
    const { auth } = useContext(authContext);

    // scheduling dialog
    const [schedulingDialogData, setSchedulingDialogData] = useState({
        showDialog: false,
        expertId: null,
        status: "loading",
        dialogWidth: "sm",
    });

    // expert wrapper
    const [open, setOpen] = useState(false);
    const [currentExpertId, setCurrentExpertId] = useState(null);

    // render main component function
    const [render, rerender] = useState(false);

    // loading
    const [loadingTabPanel, setLoadingTabPanel] = useState(true);

    // ===========
    // FETCH FUNCS
    // ===========

    // these are all the completed / scheduled calls that populate the tab panel on the right
    const fetchScheduledExperts = async (projectId) => {
        setLoadingTabPanel(true);
        const scheduled = await axios.get(`/api/project/scheduled/${projectId}`);

        console.log("SCHEDULED CALLS DATA: ", scheduled);

        if (!scheduled?.data?.data) {
            // set error states
            setErrorMsg("Problem Fetching Scheduled Experts");
            setError(true);
        } else {
            const { data: experts } = scheduled?.data;

            // Only show upcoming scheduled calls...
            const scheduledList = experts.filter((expert) => {
                const { scheduled_datetime: scheduledDateTime } = expert;

                // create new date
                const now = new Date();
                // take the scheduled date from the DB
                const scheduledDT = new Date(scheduledDateTime);
                // add 23 hours to it
                const scheduledDTLater = add(scheduledDT, { hours: 23 });

                // then compare if that is later than now
                return scheduledDTLater > now;
            });

            const completedList = experts.filter((expert) => {
                const { scheduled_datetime: scheduledDateTime } = expert;

                // create new date
                const now = new Date();
                // take the scheduled date from the DB
                const scheduledDT = new Date(scheduledDateTime);
                // add 23 hours to it
                const scheduledDTLater = add(scheduledDT, { hours: 23 });

                // then compare if that is earlier than now
                return scheduledDTLater < now;
            });

            const dateTimeSortFunction = (a, b) => {
                return new Date(a.scheduled_datetime) - new Date(b.scheduled_datetime);
            };

            const updateOptionsSwitch = (options, pinType) => {
                // update options depending on the pin array that comes from the DB
                switch (pinType.entity_type) {
                    // moderator
                    case 3: {
                        options["moderator"] = true;
                        break;
                    }
                    // translator
                    case 4: {
                        options["translator"] = true;
                        break;
                    }
                    // compliance
                    case 5: {
                        options["compliance"] = true;
                    }
                }
            };

            const updateOptions = (sortedExpList) => {
                return sortedExpList.map((expert) => {
                    // create the options object here so that we can use it later
                    // when we click edit call to prepopulate the dialog
                    const { pin_data: pinData, needs_recording: needsRecording } = expert;
                    const options = {
                        moderator: false,
                        translator: false,
                        compliance: false,
                        record: needsRecording,
                    };

                    pinData.forEach((pinType) => {
                        updateOptionsSwitch(options, pinType);
                    });

                    return { ...expert, options };
                });
            };

            // updated version of checkCallComplete that can process a batch
            // and doesn't have to go to the backend for each person
            async function checkCallCompletev2(completedCallArray) {
                const ppIdArr = completedCallArray.map((projectParticipant) => {
                    return projectParticipant.id;
                });

                return await axios
                    .get(`/api/project/scheduled/calls/batch?ppIdArr=${ppIdArr.join(",")}`)
                    .then((result) => {
                        let { uniqueConferences } = result?.data;

                        uniqueConferences = uniqueConferences.flat();

                        const updatedCallArray = completedCallArray.map((caller) => {
                            const completedCall = uniqueConferences.filter((completedCall) => {
                                return completedCall.id === caller.id;
                            });

                            let call_complete = false;

                            if (completedCall?.length > 0) {
                                const [{ event_start, event_end, duration, hasClientJoined, hasExpertJoined }] =
                                    completedCall;
                                if (event_start && event_end && duration && hasClientJoined && hasExpertJoined) {
                                    call_complete = true;
                                }
                            }

                            return { ...caller, call_complete };
                        });

                        return updatedCallArray;
                        // }
                    })
                    .catch((error) => {
                        console.log(error, " :: error");
                    });
            }

            // check if call completed
            const listWithCallCompleted = await checkCallCompletev2(completedList);

            // ... then sort by most recent
            const sortedScheduled = scheduledList.sort(dateTimeSortFunction);
            const sortedCompleted = listWithCallCompleted.sort(dateTimeSortFunction);

            const sortedScheduledWithUpdatedOptions = updateOptions(sortedScheduled);
            const sortedCompletedWithUpdatedOptions = updateOptions(sortedCompleted);

            setScheduledCalls(sortedScheduledWithUpdatedOptions);
            setCompletedCalls(sortedCompletedWithUpdatedOptions);

            setLoadingTabPanel(false);
            console.log("CALLS - Scheduled", sortedScheduledWithUpdatedOptions);
            console.log("CALLS - Completed", sortedCompletedWithUpdatedOptions);
        }
    };

    // this is the combined api call
    const fetchDataForScheduling = async (projectId, expertId, isExpertPresent) => {
        // isExpertPresent is the expert that might be in the URL in some cases
        const results = await axios.get(
            `/api/project/fetchDataForScheduling?projectId=${projectId}&expertId=${expertId}&isExpertPresent=${isExpertPresent}`
        );

        if (!results?.data?.projectData || !results?.data?.expertData) return;
        console.log("PAGE LOAD: ", results.data);
        setProjectData(results?.data?.projectData);
        setExpertsNeedingScheduling(results?.data?.expertData);
    };

    // ==============
    // AUTHENTICATION
    // ==============

    // get list of all RM's so we can manage access to different
    // elements on the page
    const getRmAccessList = async () => {
        const result = await axios.get("/api/rm/getRmIdList");
        let rmList = result?.data?.data;

        // adding additional managers
        ADDITIONAL_MANAGER_ACCESS.forEach((managerId) => {
            rmList.push(managerId);
        });

        rmList = rmList.concat(ADDITIONAL_MANAGER_ACCESS);

        const isUserAuthed = rmList.includes(auth.data.id);

        setIsUserAuthorized(isUserAuthed);
    };

    // ============
    // HANDLE FUNCS
    // ============

    const handleRowClick = (event, expertData) => {
        setSchedulingDialogData({ showDialog: true, expertData, status: "scheduling" });
    };

    // ==============
    // UTIL FUNCTIONS
    // ==============

    const editScheduledCall = (expertData) => {
        setSchedulingDialogData({ showDialog: true, expertData, status: "editing" });
    };

    const deleteScheduledCall = (expertData) => {
        setSchedulingDialogData({ showDialog: true, expertData, status: "deleting" });
    };

    const handleCallDuration = (expertData) => {
        setSchedulingDialogData({ showDialog: true, expertData, status: "duration" });
    };

    const handleCustomExpCharge = (expertData) => {
        setSchedulingDialogData({ showDialog: true, expertData, status: "custom-expert-charge", dialogWidth: "sm" });
    };

    const renderPinDialog = (pinData) => {
        setSchedulingDialogData({ showDialog: true, expertData: pinData, status: "pins" });
    };

    const renderMeetingInviteDialog = (expertData) => {
        setSchedulingDialogData({ showDialog: true, expertData, status: "invite", dialogWidth: "lg" });
    };

    const handleCallEventsDialog = (expertData) => {
        setSchedulingDialogData({ showDialog: true, expertData, status: "events" });
    };

    const requestPaymentDialog = (expertData) => {
        setSchedulingDialogData({ showDialog: true, expertData, status: "request-payment", dialogWidth: "xl" });
    };

    const displayExpertPreview = (expertId) => {
        setCurrentExpertId(expertId);
        setOpen(true);
    };

    const resetUrl = () => {
        history.push(`/project/schedule/${projectId}`);
    };

    // =========
    // PAGE LOAD
    // =========

    // scheduled and needing to be scheduled expert effects
    useEffect(() => {
        // parse if there's an expertId in the url string
        const { eid: parsedExpertId } = queryString.parse(location.search);

        if (parsedExpertId) {
            fetchDataForScheduling(projectId, parsedExpertId, true);
        } else {
            fetchDataForScheduling(projectId, parsedExpertId, false);
        }
    }, [render]);

    // in either case we have to make this query because there might be experts scheduled the other way
    useEffect(() => {
        fetchScheduledExperts(projectId);
    }, [render]);

    // authentication effects
    useEffect(() => {
        getRmAccessList();
    }, [auth]);

    // ===========
    // PAGE RENDER
    // ===========

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography
                        sx={{
                            color: "#4d1b8f",
                            fontSize: "2rem",
                            marginBottom: ".7525rem",
                            width: "fit-content",
                            "&:hover": { cursor: "pointer", color: "#d8c7ff" },
                        }}
                        onClick={() => history.push(`/project/view/${projectId}`)}
                    >
                        {projectData.title}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <SchedulingTable
                        projectData={projectData}
                        rows={expertsNeedingScheduling}
                        handleRowClick={handleRowClick}
                        displayExpertPreview={displayExpertPreview}
                    />
                </Grid>
                <Grid item xs={6}>
                    {loadingTabPanel ? (
                        <Stack spacing={1}>
                            <Skeleton variant="text" sx={{ marginBottom: "1rem" }} />
                            <Skeleton variant="text" />
                            <Skeleton variant="rectangle" height={200} />
                            <Skeleton variant="text" />
                            <Skeleton variant="rectangle" height={200} />
                            <Skeleton variant="text" />
                            <Skeleton variant="rectangle" height={200} />
                            <Skeleton variant="text" />
                            <Skeleton variant="rectangle" height={200} />
                        </Stack>
                    ) : (
                        <ScheduledCallsTabPanel
                            projectData={projectData}
                            scheduledCalls={scheduledCalls}
                            completedCalls={completedCalls}
                            isUserAuthorized={isUserAuthorized}
                            timezones={timezones}
                            handlerFuncs={[
                                editScheduledCall,
                                deleteScheduledCall,
                                handleCallDuration,
                                renderPinDialog,
                                renderMeetingInviteDialog,
                                handleCallEventsDialog,
                                displayExpertPreview,
                                requestPaymentDialog,
                            ]}
                        />
                    )}
                </Grid>
                {schedulingDialogData.showDialog && (
                    <SchedulingDialog
                        isUserAuthorized={isUserAuthorized}
                        timezones={timezones}
                        projectData={projectData}
                        schedulingDialogData={schedulingDialogData}
                        setSchedulingDialogData={setSchedulingDialogData}
                        render={render}
                        rerender={rerender}
                        resetUrl={resetUrl}
                        handlerFuncs={{ handleCallDuration, handleCustomExpCharge }}
                    />
                )}
                {error && (
                    <FlashMessagev3
                        openSnackbar={error}
                        setOpenSnackbar={setError}
                        severity="error"
                        message={errorMsg}
                    />
                )}
                {open && (
                    <ExpertPreviewWrapperNext
                        open={open}
                        setOpen={setOpen}
                        expert_id={currentExpertId}
                        project_id={projectId}
                    />
                )}
            </Grid>
        </>
    );
};

export default ProjectSchedule;
