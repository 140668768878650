import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InvoicePreviewTable from "./InvoicePreviewTable";

export default function InvoicePreviewModal({
    openInvoicePreviewModal,
    setOpenInvoicePreviewModal,
    invoicePreviewData,
}) {
    const handleClose = () => {
        setOpenInvoicePreviewModal(false);
    };

    const { expertData, projectData } = invoicePreviewData;

    // calculate amount due
    let amount_due = 0;

    for (const expert of expertData) {
        amount_due += expert.client_charge_amount;
    }

    const formattedPreviewData = [
        {
            ...projectData,
            expert_data: expertData,
            invoice_id: 999999999,
            amount_due,
        },
    ];

    return (
        <div>
            <Dialog
                open={openInvoicePreviewModal}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="2xl"
                PaperProps={{ style: { backgroundColor: "#f8f8f8" } }}
            >
                <DialogTitle id="alert-dialog-title">Invoice Preview</DialogTitle>
                <DialogContent>
                    <InvoicePreviewTable invoicePreviewData={formattedPreviewData} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
