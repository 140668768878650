import * as React from "react";

// MUI
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

// MUI STYLES
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { moreBasicDateTimeStamp } from "../../Utils";

export default function Carousel({ statuses, project_id: key, currentStatus, styleObj }) {
    /**
     * STATE
     */

    const [count, setCount] = React.useState(0);

    /**
     * CONSTANTS
     */

    const statusArray = statuses?.[currentStatus];

    /**
     * HANDLE FUNCS
     */

    function handleClick(leftOrRightClick) {
        if (leftOrRightClick === "left") {
            if (count === 0) {
                setCount(statusArray?.length - 1);
            } else {
                setCount(count - 1);
            }
        }

        if (leftOrRightClick === "right") {
            if (count === statusArray?.length - 1) {
                setCount(0);
            } else {
                setCount(count + 1);
            }
        }
    }

    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 0.5rem;
            `}
        >
            <div
                css={css`
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                `}
            >
                {currentStatus !== 0 && statusArray?.length > 1 && (
                    <button
                        css={css`
                            position: absolute;
                            left: 0;
                            transform: translateX(-75%) scale(0.7);
                            aspect-ratio: 1;
                            border-radius: 50%;
                            border: 1px solid #a8a29e;
                            background-color: #e7e5e4;
                            &:hover {
                                background-color: #78716c;
                            }
                            & .chevron-icon-left {
                                color: #78716c;
                            }
                            &:hover .chevron-icon-left {
                                color: #e7e5e4;
                            }
                        `}
                        onClick={() => handleClick("left")}
                    >
                        <ChevronLeftIcon className="chevron-icon-left" />
                    </button>
                )}
                <div>
                    <div
                        key={key}
                        style={{
                            display: "grid",
                            placeItems: "center",
                            padding: "1rem 1.5rem",
                            borderRadius: "0.2em",
                            minWidth: "12rem",
                            ...styleObj?.top,
                        }}
                    >
                        {currentStatus !== 0 && (
                            <div
                                style={{
                                    fontWeight: "500",
                                    ...styleObj?.name,
                                }}
                            >
                                {statusArray?.[count]?.expert_status_name === "Accepted"
                                    ? "Expert Accepted Project"
                                    : statusArray?.[count]?.expert_status_name}
                            </div>
                        )}
                        {currentStatus !== 0 && (
                            <div
                                style={{
                                    fontWeight: "300",
                                    fontSize: "0.8rem",
                                    ...styleObj?.date,
                                }}
                            >
                                {moreBasicDateTimeStamp(statusArray?.[count]?.status_created_on)}
                            </div>
                        )}
                    </div>
                </div>
                {currentStatus !== 0 && statusArray?.length > 1 && (
                    <button
                        css={css`
                            position: absolute;
                            right: 0;
                            transform: translateX(75%) scale(0.7);
                            aspect-ratio: 1;
                            border-radius: 50%;
                            border: 1px solid #a8a29e;
                            background-color: #e7e5e4;
                            &:hover {
                                background-color: #78716c;
                            }
                            & .chevron-icon-right {
                                color: #78716c;
                            }
                            &:hover .chevron-icon-right {
                                color: #e7e5e4;
                            }
                        `}
                        onClick={() => handleClick("right")}
                    >
                        <ChevronRightIcon className="chevron-icon-right" />
                    </button>
                )}
            </div>
            <div>
                {currentStatus !== 0 && statusArray?.length > 1 && (
                    <div
                        css={css`
                            display: flex;
                            flex-direction: row;
                        `}
                    >
                        {statusArray.map((_, i) => {
                            return (
                                <button
                                    onClick={() => setCount(i)}
                                    css={css`
                                        display: block;
                                        margin-right: 0.25rem;
                                        border: none;
                                        aspect-ratio: 1;
                                        border-radius: 50%;
                                        background-color: ${i === count ? "#a8a29e" : "#e7e5e4"};
                                        &:hover {
                                            background-color: #a8a29e;
                                        }
                                    `}
                                    key={i}
                                ></button>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
}
