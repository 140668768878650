// react imports
import React from "react";

// MUI - styles
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    custom: {
        color: "#000",
        fontSize: ".95rem",
        marginBottom: "3px",
    },
    note: {
        color: "#444",
        fontSize: ".85rem",
        marginBottom: "0",
    },
    override: {
        backgroundColor: "#dfdae9",
        padding: "10px",
        marginBottom: "12px",
        borderRadius: "3px",
    },
    currentCharges: {
        color: "#000000",
        fontWeight: "bold",
        marginBottom: "0",
        fontSize: "1.25rem",
    },
    missingData: {
        color: "red",
        fontWeight: "bold",
        marginBottom: "0",
    },
    strike: {
        color: "#888",
        fontWeight: "normal",
        textDecoration: "line-through",
    },
}));

const ClientChargeCell = (props) => {
    // console.log(props, " :: props FinalUnitCell");
    const classes = useStyles();
    const { projectType, overrideType, row, numberWithCommas } = props;

    let clientCharge;
    let amount;

    if (overrideType === "unit") {
        clientCharge = `${row.unitClientChargeString} UNITS`;
        amount = row.unit_price_override;
    } else if (overrideType === "dollar") {
        clientCharge = row.dollarClientChargeString && `${numberWithCommas(row.dollarClientChargeString)} USD`;
        amount = row.dollar_price_override && numberWithCommas(row.dollar_price_override);
    }

    if (amount !== null) {
        return (
            <p className={classes.currentCharges}>
                {clientCharge ? (
                    <strong>
                        <span className={classes.strike}>
                            {clientCharge && clientCharge !== "0" ? clientCharge : ""}
                        </span>{" "}
                        {`${amount}`}
                    </strong>
                ) : (
                    <strong>{clientCharge}</strong>
                )}
            </p>
        );
    }
    if (!clientCharge || clientCharge === null) {
        return <p className={classes.missingData}>{"--- MISSING DATA ---"}</p>;
    } else {
        if (overrideType === "unit" && projectType === 8) {
            return "";
        }
        return <p className={classes.currentCharges}>{clientCharge}</p>;
    }
};

export default ClientChargeCell;
