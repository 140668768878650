import React, { useState, useEffect } from "react";

// material ui stuff
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Backdrop from "@mui/material/Backdrop";
import Select from "react-select";
import makeStyles from "@mui/styles/makeStyles";
import axios from "axios";

// random data
import CountryData from "../data/countries.json";
const countryCodes = CountryData.countries.map((country, index) => {
    return {
        index,
        value: country.dial_code,
        label: `${country.dial_code} - ${country.name}`,
    };
});
const currencies = [
    {
        value: "USD",
        label: "$ - USD",
    },
    {
        value: "EUR",
        label: "€ - EUR",
    },
    {
        value: "JPY",
        label: "¥ - JPY",
    },
    {
        value: "GBP",
        label: "£ - GBP",
    },
];

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        minWidth: "30vw",
    },
    modalTitle: {
        marginBottom: "1rem",
    },
    modalInput: {
        maxwidth: "100%",
        width: "100%",
        // height: "5vh",
        "& .MuiOutlinedInput-notchedOutline legend": {
            display: "none",
        },
    },
    btnContainer: {
        marginTop: "1.5rem",
    },
    modalBtn: {
        marginRight: ".5em",
        marginLeft: ".5em",
    },
    bottomSpacing: {
        marginBottom: "1.5rem",
    },
    inputLabel: {
        fontSize: "1.15rem",
        fontWeight: "700",
        marginBottom: "1.25rem",
        textAlign: "left",
    },
}));

const AddEditModal = (props) => {
    console.log(props, " :: props (AddEditModal.js)");
    const {
        openModal,
        closeModal,
        isModalOpen,
        modalData: incomingModalData,
        setModalData: setIncomingModalData,
    } = props;
    const classes = useStyles();
    const [inputError, setInputError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("Error");
    const [modalData, setModalData] = useState({});
    const [loading, setIsLoading] = useState(true);

    useEffect(() => {
        if (incomingModalData.iconName === "phoneNum") {
            let countryCodeData;
            // if there's a country code of +1 default it to US.
            // Whether it's US or Canada doesn't effect use just UI
            if (incomingModalData.supplementalData !== null) {
                const { name, value } = incomingModalData.supplementalData;
                if (name === "countryCode" && value === "+1") {
                    countryCodeData = countryCodes.filter((country) => {
                        return country.value === value;
                    })[1];
                    // checking to see if there is actually a country code
                } else if (name === "countryCode" && value !== null) {
                    countryCodeData = countryCodes.filter((country) => {
                        return country.value === value;
                    })[0];
                }
            } else {
                countryCodeData = countryCodes[181];
            }
            setModalData({
                ...incomingModalData,
                countryCodeData,
            });
            setIsLoading(false);
        } else if (incomingModalData.iconName === "expertRate") {
            let currencyTypeData;
            if (incomingModalData.supplementalData !== null) {
                const { name, value } = incomingModalData.supplementalData;
                currencyTypeData = currencies.filter((currencyType) => {
                    return currencyType.value === value;
                })[0];
            } else {
                currencyTypeData = currencies[0];
            }
            setModalData({
                ...incomingModalData,
                currencyTypeData,
            });
            setIsLoading(false);
        } else {
            setModalData({ ...incomingModalData });
            setIsLoading(false);
        }
    }, []);

    const handleChange = (event) => {
        const { name } = event.target;
        let { value } = event.target;
        if (modalData.iconName === "phoneNum") {
            value = value.replace(/[^0-9]/g, "");
        } else if (modalData.iconName === "email") {
            value = value.trim();
        } else if (modalData.iconName === "expertRate") {
            // const regexp = /^[0-9]*\.?[0-9]{0,2}$/;
            const regexp = /^[0-9]+$/;
            const doesPassValidation = regexp.test(value);
            if (!doesPassValidation) {
                return;
            }
        }

        setModalData({
            ...modalData,
            [name]: value,
        });
    };

    const handleCountryChange = (countryObject) => {
        setModalData({
            ...modalData,
            countryCodeData: countryObject,
        });
    };

    const handleCurrencyTypeChange = (currencyTypeObject) => {
        setModalData({
            ...modalData,
            currencyTypeData: currencyTypeObject,
        });
    };

    const handleModalBtn = (event) => {
        const btnValue = event.currentTarget.value;
        if (btnValue === "cancel") {
            resetModalFields();
        } else if (btnValue === "confirm") {
            const validationObj = { allowSubmit: false, url: "" };
            validateInputFields(validationObj);
            updateDatabase(buildPostObject(), validationObj);
        }
    };

    const isValidEmail = (email) => {
        const regex =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(String(email).toLowerCase());
    };

    const validateInputFields = (validationObject) => {
        if (modalData.iconName === "phoneNum") {
            if (modalData.data === "") {
                setErrorMsg("Number can't be empty");
                setInputError(true);
            } else if (modalData.data.length <= 8) {
                setErrorMsg("Number too short");
                setInputError(true);
            } else {
                setErrorMsg("Error");
                setInputError(false);
                validationObject.allowSubmit = true;
                validationObject.url = `/api/expert/updatePhoneNum`;
            }
        } else if (modalData.iconName === "email") {
            if (modalData.data === "") {
                setErrorMsg("Email can't be empty");
                setInputError(true);
            } else if (!isValidEmail(modalData.data)) {
                setErrorMsg("Invalid Email Format");
                setInputError(true);
            } else {
                setErrorMsg("Error");
                setInputError(false);
                validationObject.allowSubmit = true;
                validationObject.url = `/api/expert/updateEmail`;
            }
        } else if (modalData.iconName === "expertRate") {
            if (modalData.data === "") {
                setErrorMsg("Rate can't be empty");
                setInputError(true);
            } else {
                setErrorMsg("Error");
                setInputError(false);
                validationObject.allowSubmit = true;
                validationObject.url = `/api/expert/updateExpertRate`;
            }
        }
    };

    const buildPostObject = () => {
        let postObject = {};
        if (modalData.iconName === "phoneNum") {
            postObject = {
                code: modalData.countryCodeData.value,
                number: modalData.data,
                person_id: modalData.person_id,
                isPrimary: true,
                id: "phoneNum",
            };
        } else if (modalData.iconName === "email") {
            postObject = {
                mail: modalData.data,
                person_id: modalData.person_id,
                isPrimary: true,
                id: "email",
            };
        } else if (modalData.iconName === "expertRate") {
            postObject = {
                consultation_rate: modalData.data,
                consultation_rate_currency: modalData.currencyTypeData.value,
                person_id: modalData.person_id,
                id: "expertRate",
            };
        }
        return postObject;
    };

    const updateDatabase = async (postObject, validationObject) => {
        if (validationObject.allowSubmit) {
            // make the api call
            await axios
                .post(validationObject.url, postObject, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                })
                .then((result) => {
                    if (result.status === 200) {
                        console.log("post worked! result ->", result);
                    }
                })
                .catch((result) => {
                    console.log("Something went wrong, API Error, 404 Error:", result);
                });
            if (postObject.id === "email") {
                const newExpertPreviewObj = {
                    ...props.data,
                    email: postObject.mail,
                };
                props.setData({
                    ...newExpertPreviewObj,
                });
            } else if (postObject.id === "phoneNum") {
                const newExpertPreviewObj = {
                    ...props.data,
                    country_code: postObject.code,
                    phone: postObject.number,
                };
                props.setData({
                    ...newExpertPreviewObj,
                });
            } else if (postObject.id === "expertRate") {
                if (props.filter) {
                    let findExpert = props.filter.experts.filter((expert) => {
                        return postObject.person_id === expert.person_id;
                    });
                    if (findExpert.length > 0) {
                        findExpert = findExpert[0];
                    }
                    const updatedExpert = {
                        ...findExpert,
                        consultation_rate: postObject.consultation_rate,
                        consultation_rate_currency: postObject.consultation_rate_currency,
                    };
                    const newExpertArray = props.filter.experts.filter((expert) => {
                        return expert.person_id !== postObject.person_id;
                    });
                    console.log(updatedExpert, " :: updated Expert");
                    console.log(newExpertArray, " :: newExpertArray");
                    newExpertArray.push(updatedExpert);
                    console.log(newExpertArray, " :: newExpertArray wish push");
                    const newFilterObj = {
                        ...props.filter,
                        experts: newExpertArray,
                    };
                    console.log(newFilterObj, " :: newFilterObj");
                    props.setFilterResults({
                        ...newFilterObj,
                    });
                }
                const newExpertPreviewObj = {
                    ...props.data,
                    consultation_rate: postObject.consultation_rate,
                    consultation_rate_currency: postObject.consultation_rate_currency,
                };
                props.setData({
                    ...newExpertPreviewObj,
                });
            }
            closeModal();
        }
    };

    const resetModalFields = () => {
        setInputError(false);
        closeModal();
    };

    const handleClose = () => {
        resetModalFields();
    };

    const renderModal = () => {
        return (
            <Modal className={classes.modal} open={isModalOpen} onClose={handleClose} closeAfterTransition>
                <Fade in={isModalOpen}>
                    <div className={classes.modalPaper}>
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                            <h3 className={classes.modalTitle}>{`${modalData.modalTitle}`}</h3>
                            {modalData.iconName === "phoneNum" && (
                                <>
                                    <div className={classes.inputLabel}>{`Country Code`}</div>
                                    <Select
                                        placeholder="Find Country Code"
                                        name="countryCodeData"
                                        value={modalData.countryCodeData}
                                        options={countryCodes}
                                        onChange={handleCountryChange}
                                        className={`${classes.modalInput} ${classes.bottomSpacing}`}
                                    />
                                </>
                            )}
                            {modalData.iconName === "expertRate" && (
                                <>
                                    <div className={classes.inputLabel}>{`Currency Type`}</div>
                                    <Select
                                        placeholder="Currency Types"
                                        name="currencyTypeData"
                                        value={modalData.currencyTypeData}
                                        options={currencies}
                                        onChange={handleCurrencyTypeChange}
                                        className={`${classes.modalInput} ${classes.bottomSpacing}`}
                                    />
                                </>
                            )}
                            <div className={classes.inputLabel}>{`${modalData.modalLabel}`}</div>
                            <TextField
                                error={inputError}
                                className={classes.modalInput}
                                helperText={inputError ? errorMsg : ""}
                                onChange={handleChange}
                                name="data"
                                variant="outlined"
                                value={modalData.data}
                                placeholder={`${modalData.placeholder}`}
                            />
                            <Box className={classes.btnContainer} display="flex" flexDirection="row">
                                <Button
                                    className={classes.modalBtn}
                                    variant="contained"
                                    color="primary"
                                    value="confirm"
                                    onClick={handleModalBtn}
                                >
                                    Confirm
                                </Button>
                                <Button
                                    className={classes.modalBtn}
                                    variant="contained"
                                    color="primary"
                                    value="cancel"
                                    onClick={handleModalBtn}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    </div>
                </Fade>
            </Modal>
        );
    };

    return <div>{!loading && renderModal()}</div>;
};

export default AddEditModal;
