import React, { useContext } from "react";
import { Drawer, Box } from "@mui/material";
import Comments from "./Comments";
import moment from "moment";
import useSWR from "swr";

import { authContext } from "../context/auth";

const fetcher = (...args) => fetch(...args).then((res) => res.json());

export default function ProjectCommentsModal(props) {
    const { auth } = useContext(authContext);
    const _state = props.state_manager;
    const { open } = props;
    props = props.props;

    // TODO - Add comment data
    const findProjectComments = `/api/project/findProject?id=${props.match.params.id}&comments=true`;
    const { data, error } = useSWR(findProjectComments, fetcher, {
        revalidateOnFocus: false,
        revalidateIfStale: false,
        // revalidateOnReconnect: false, // personally, I didn't need this one
    });

    // if (data) console.log(data);
    // Helper Functions
    function formatDateString(str) {
        return moment(str).format("MMM. Do, YYYY, h:mma");
    }
    function setCommentsArray(arr) {
        if (typeof arr === "object") {
            _state.set.projectState({
                ..._state.get.projectState,
                comments: arr,
            });

            data.data.comments = arr;
        }
    }

    if (!data) {
        return <div></div>;
    }

    if (error) {
        return <div>Failed to load</div>;
    }

    return (
        <Drawer anchor="right" open={open} onClose={_state.switch.commentsModal}>
            <Box sx={{ marginTop: "90px", width: 400, padding: "10px" }} role="presentation">
                <Comments
                    state_manager={_state}
                    user={auth.data}
                    object_type="Project"
                    object_id={props.match.params.id}
                    formatDate={formatDateString}
                    setComments={setCommentsArray}
                    comments={data.data.comments} // TODO - Pass comments in data fetching
                    height="auto"
                />
            </Box>
        </Drawer>
    );
}
