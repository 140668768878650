import React, { useState, useContext } from "react";

// react router
import { Link } from "react-router-dom";

// MUI - general imports
import { Typography, Button } from "@mui/material";

// MUI - styles
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { styled } from "@mui/system";

// custom
import ExpertPreviewWrapperNext from "../wrappers/ExpertPreviewWrapperNext";

// utils
import { stripHtml } from "../../Utils";

// ======
// STYLES
// ======

// styled components
const StyledLink = styled(Link)({
    border: "2px solid #5b3aa2 !important",
    borderRadius: "3px",
    color: "#5b3aa2",
    backgroundColor: "#d8c7ff",
    display: "inline-block",
    marginBottom: "1rem",
    padding: ".75rem 1.5rem",
    fontSize: "1.25rem",
    textDecoration: "none",
    "&:hover": {
        border: "2px solid #d8c7ff !important",
        color: "#d8c7ff",
        cursor: "pointer",
        backgroundColor: "#5b3aa2 !important",
    },
});

// css
const profileBox = css`
    background-color: #ddcdff;
    border-bottom: 4px solid #5b3aa2;
    text-align: left;
    padding: 1rem 2rem;
`;

const respondentCurrent = css`
    color: #444;
    font-size: 1rem;
    font-weight: normal;
    font-style: italic;
`;

const disqualifier = css`
    font-size: 0.95rem;
    font-weight: bold;
    color: red;
`;

const respondentContact = css`
    color: #000;
    font-size: 0.85rem;
    font-weight: normal;
    margin-bottom: 15px;
`;

const respondentName = css`
    color: #000;
    font-size: 1.5rem;
    font-weight: bold;
    padding: 0;
`;

const questionWrapper = css`
    background-color: #f5f5f5;
    border-radius: 3px;
    padding: 0.75rem 2rem;
`;

const questionOrder = css`
    color: #325c55;
    font-size: 0.75rem;
    font-weight: normal;
    text-transform: uppercase;
`;

const questionText = css`
    color: #000;
    font-size: 1rem;
    margin-bottom: 0 !important;
`;

const questionAnswer = css`
    color: #325c55;
    font-size: 1rem;
    font-weight: normal;
    padding: 0 2rem;
`;

const answerWrapper = css`
    padding: 0.75rem 0;
`;

const followupText = css`
    font-size: 0.95rem;
    font-weight: bold;
    background-color: #f5f5f5;
    color: black;
    padding: 0 2rem;
`;

const followupAnswer = css`
    font-size: 0.95rem;
    font-weight: normal;
    padding: 0 2rem 0.75rem 2rem;
    display: block;
`;

const commentText = css`
    font-size: 0.95rem;
    font-weight: bold;
    color: black;
`;

const commentWrapper = css`
    font-size: 0.95rem;
    font-weight: normal;
    display: block;
    color: black;
    padding: 0 2rem;
    padding-bottom: 0.75rem;
`;

const cardBorder = css`
    border: 2px solid black;
    border-radius: 2px;
    margin-bottom: 1.5rem;
`;

const ExpertSurveyAnswers = (props) => {
    // =====
    // PROPS
    // =====

    const { expertSurveyArray, projectId } = props;

    // =====
    // STATE
    // =====

    // expert wrapper
    const [open, setOpen] = useState(false);
    const [currentExpertId, setCurrentExpertId] = useState(null);

    // ============
    // HANDLE FUNCS
    // ============

    const openExpertPreview = (expertId) => {
        setCurrentExpertId(expertId);
        setOpen(false);
    };

    return (
        <>
            <StyledLink to={`/project/respondents/${projectId}`}>Go To Respondents Page</StyledLink>
            {expertSurveyArray.map((expert) => {
                // console.log(expert, " :: expert");
                const [expertData, surveyData] = expert;

                const { fname, lname, id: expertId, headline, pricingUnit } = expertData;

                // console.log(expertData, " :: expertData");
                // console.log(pricingUnit, " :: pricingUnit");
                // console.log(surveyData, " :: surveyData");

                const sortedQuestions = surveyData.sort((a, b) => a.order - b.order);

                let disqualifiedAnswer = false;
                const disqualifierQuestions = sortedQuestions.filter((q) => q.disqualifier);

                if (disqualifierQuestions?.length > 0) {
                    disqualifiedAnswer = disqualifierQuestions.find((q) => {
                        q.answers.find((a) => a.expert_id !== null && a.disqualifier);
                    });
                }

                return (
                    <div css={cardBorder} key={expertId}>
                        <div css={profileBox}>
                            {disqualifiedAnswer && <Typography css={disqualifier}>DISQUALIFIED</Typography>}
                            <Button key={expertId} css={respondentName} onClick={() => openExpertPreview(expertId)}>
                                {fname} {lname}
                            </Button>

                            <Typography css={respondentCurrent}>{headline && `${headline}`}</Typography>
                            {pricingUnit && (
                                <Typography css={respondentContact}>
                                    <strong>{` | UNITS: ${pricingUnit}`}</strong>
                                </Typography>
                            )}
                        </div>
                        {surveyData.length > 0 &&
                            surveyData.map((question) => (
                                <div key={question.id}>
                                    <div css={questionWrapper}>
                                        <Typography css={questionOrder}>Question {question.order}</Typography>
                                        <Typography css={questionText}>{stripHtml(question.text)}</Typography>
                                    </div>
                                    <div css={answerWrapper}>
                                        {question.answers.map(function (a, idx) {
                                            return (
                                                <div key={a.id}>
                                                    {a.user_answer && (
                                                        <div css={questionAnswer}>
                                                            <strong>{a.label && a.label}</strong>{" "}
                                                            {a.user_answer &&
                                                                a.user_answer !== "true" &&
                                                                `${a.user_answer}`}{" "}
                                                            {question.disqualifier && a.disqualifier && (
                                                                <span css={disqualifier}>DISQUALIFIED</span>
                                                            )}
                                                        </div>
                                                    )}
                                                    {a.user_answer &&
                                                        a.followupquestion &&
                                                        a.followupquestion.length > 0 &&
                                                        a.followupquestion[0]?.text !== "" && (
                                                            <div css={followupText}>
                                                                {a.followupquestion[0]?.text}{" "}
                                                                {a.followupquestion[0]?.answers.length > 0 && (
                                                                    <span css={followupAnswer}>
                                                                        {a.followupquestion[0]?.answers[0].user_answer}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {question.comments && question.comments !== "" && (
                                        <div css={commentWrapper}>
                                            <div css={commentText}>Comments:</div> {question.comments}
                                        </div>
                                    )}
                                </div>
                            ))}
                    </div>
                );
            })}
            {open && (
                <ExpertPreviewWrapperNext
                    open={open}
                    setOpen={setOpen}
                    expert_id={currentExpertId}
                    project_id={projectId}
                />
            )}
        </>
    );
};

export default ExpertSurveyAnswers;
