import React, { useState, useCallback } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { TablePagination } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import FiberNewOutlinedIcon from "@mui/icons-material/FiberNewOutlined";
import axios from "axios";
import Button from "@mui/material/Button";
import { basicDateTimeStamp } from "../Utils";
import "moment-duration-format";
import ExpertShowNext from "../pages/ExpertShowNext";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(2),
        overflowX: "auto",
    },
    appBar: {
        position: "relative",
    },
    tableHeader: {
        textTransform: "uppercase",
        fontSize: "1.5rem",
        color: "#325C55",
        textAlign: "center",
    },
    evenRow: {
        background: "linear-gradient(-45deg, #fafafa, #fafafa, #ffffff, #f5f5f5)",
    },
    oddRow: {
        backgroundColor: "#ffffff",
    },
    newRow: {
        color: theme.palette.secondary.main,
        fontSize: "1.5rem",
    },
    tableCellFirst: {
        paddingLeft: "15px",
    },
    tableCell: {
        paddingLeft: "5px",
        fontSize: ".75rem",
    },
    projectLink: {
        color: "#8966ad",
    },
    nameButton: {
        color: "#8966ad",
        padding: "0",
        textDecoration: "none",
        fontSize: ".95rem",
        fontWeight: "normal",
        textTransform: "none",
        minWidth: "auto",
    },
    nameButtonBold: {
        color: "#523197",
        padding: "0",
        textDecoration: "none",
        fontSize: "1.1rem",
        fontWeight: "bold",
        textTransform: "none",
        minWidth: "auto",
    },
    headline: {
        color: "#666",
        fontSize: ".75rem",
    },
    callPhone: {
        display: "inline-block",
        backgroundColor: "#4CAB7D",
        borderRadius: "3px",
        color: "#fff",
        padding: "8px 12px",
        textTransform: "uppercase",
        marginBottom: "3px",
        marginTop: "2px",
    },
    question: {
        borderBottom: "1px solid #CCC",
        marginBottom: "20px",
        paddingBottom: "10px",
    },
    questionOrder: {
        marginBottom: "1px",
        color: "#325C55",
        fontSize: ".75rem",
        fontWeight: "normal",
        textTransform: "uppercase",
    },
    questionText: {
        color: "#000",
        fontSize: "1rem",
        marginBottom: "1px",
    },
    questionWrapper: {
        borderBottom: "1px solid #325C55",
        backgroundColor: "#f5f5f5",
        borderRadius: "3px",
        padding: "2px",
    },
    questionAnswer: {
        color: "#325C55",
        fontSize: "1.25rem",
        fontWeight: "normal",
        marginBottom: "8px",
        marginLeft: "30px",
    },
    followupText: {
        fontSize: ".95rem",
        fontWeight: "bold",
        marginLeft: "30px",
        backgroundColor: "#f5f5f5",
        padding: "2px",
        color: "black",
    },
    commentText: {
        fontSize: ".95rem",
        fontWeight: "bold",
        marginLeft: "-15px",
        color: "black",
        padding: "2px",
    },
    comment: {
        fontSize: ".95rem",
        fontWeight: "normal",
        marginBottom: "8px",
        marginLeft: "45px",
        display: "block",
        color: "black",
    },
    followupAnswer: {
        fontSize: ".95rem",
        fontWeight: "normal",
        marginBottom: "8px",
        marginLeft: "30px",
        display: "block",
    },
    disqualifier: {
        fontSize: ".95rem",
        fontWeight: "bold",
        color: "red",
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function ScreenedExperts(props) {
    const classes = useStyles();
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("name");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [modal, setPreviewModal] = useState(false);
    const [currentExpert, setCurrentExpert] = useState({});
    const [disqualified, setDisqualified] = useState(false);
    const [previewBox, setPreviewBox] = useState("block");
    const [answerBox, setAnswerBox] = useState("none");
    const [answers, setRespondentAnswers] = useState([]);

    const fetchRespondentAnswers = useCallback(async (project_id, survey_id, expert_id) => {
        const postData = {
            expert_id,
            survey_id,
            project_id,
        };
        const url = "/api/survey/getExpertAnswers";
        axios
            .post(url, postData, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200 && result.data) {
                    if (result.data.data && result.data.data.length > 0) {
                        const [expertData, response] = result.data.data;
                        console.log(expertData, response, " :: expertData, response");
                        const sortedQuestions = response.sort((a, b) => a.order - b.order);
                        console.log("RESPONSE RESULTS: ", response);

                        // CHECK DISQUALIFIED
                        let disqualifiedAnswer = false;
                        const disqualifierQuestions = sortedQuestions.filter((q) => q.disqualifier);
                        disqualifierQuestions.length > 0 &&
                            (disqualifiedAnswer = disqualifierQuestions.find((q) =>
                                q.answers.find((a) => a.expert_id !== null && a.disqualifier)
                            ));
                        setDisqualified(disqualifiedAnswer);
                        setRespondentAnswers(sortedQuestions);
                    } else {
                        console.log(" :: expert data not coming back correctly");
                    }
                } else {
                    console.log(`Good POST, but something went wrong: ${JSON.stringify(postData)}`);
                    setRespondentAnswers([]);
                }
            })
            .catch((result) => {
                console.log("API not done yet, 404 Error:", result);
                setRespondentAnswers([]);
            });
    }, []);

    function desc(a, b, oBy) {
        if (b[oBy] < a[oBy]) {
            return -1;
        }
        if (b[oBy] > a[oBy]) {
            return 1;
        }
        return 0;
    }

    function stableSort(array, cmp) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const tOrder = cmp(a[0], b[0]);
            if (order !== 0) return tOrder;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    function getSorting(o, oBy) {
        return o === "desc" ? (a, b) => desc(a, b, oBy) : (a, b) => -desc(a, b, oBy);
    }

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    function handleClose() {
        setPreviewModal(false);
        props.keepPolling(true);
    }

    function showScreeners(id) {
        setPreviewBox("none");
        setAnswerBox("block");
        props.addToViewedLocalStorage(id);
    }

    function showPreview() {
        setPreviewBox("block");
        setAnswerBox("none");
    }

    function displayExpertPreview(pid, sid, eid) {
        showPreview();
        // fetchExpertById(eid, pid);
        setCurrentExpert({ expert_id: eid, project_id: pid });
        setPreviewModal(true);
        fetchRespondentAnswers(pid, sid, eid);
    }

    function navigateToResponses(id) {
        props.history.push(`/project/respondents/${id}`);
    }

    function navigateToFullProfile() {
        props.history.push(`/expert/view/${currentExpert?.expert_id}`);
    }

    function navigateToExpertEdit() {
        props.history.push(`/expert/edit/${currentExpert?.expert_id}`);
    }

    function highlightRow(eid) {
        const localStorageViewed =
            localStorage.getItem("viewedScreenedExperts") !== null
                ? localStorage.getItem("viewedScreenedExperts").split(",")
                : [];
        const viewed = localStorageViewed.map((item) => parseInt(item, 10));
        // console.log("viewedScreenedExperts: ", viewed);
        if (viewed.includes(eid)) {
            return false;
        } else {
            return true;
        }
    }

    const ScreenedHeadCells = [
        { id: "expert_fname", numeric: false, disablePadding: true, label: "Expert" },
        { id: "project_title", numeric: false, disablePadding: true, label: "Project" },
        { id: "time_stamp", numeric: false, disablePadding: true, label: "Screened" },
        { id: "current_status_id", numeric: false, disablePadding: true, label: "Status" },
    ];

    function ScreenedTableHead(props) {
        const { rowCount } = props;
        const createSortHandler = (property) => (event) => {
            props.onRequestSort(event, property);
        };
        return (
            <TableHead>
                <TableRow>
                    {ScreenedHeadCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? "right" : "left"}
                            padding={headCell.disablePadding ? "none" : "normal"}
                            sortDirection={props.orderBy === headCell.id ? props.order : false}
                        >
                            <TableSortLabel
                                active={props.orderBy === headCell.id}
                                direction={props.order}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {props.orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {props.order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    return (
        <>
            <Dialog fullScreen open={modal} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" size="large">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            <Button color="inherit" onClick={navigateToExpertEdit}>
                                Edit Profile
                            </Button>
                            <Button color="inherit" onClick={navigateToFullProfile}>
                                Full Profile
                            </Button>
                            <Button color="inherit" onClick={() => showScreeners(currentExpert?.expert_id)}>
                                Screener Answers
                            </Button>
                            <Button color="inherit" onClick={() => navigateToResponses(currentExpert?.project_id)}>
                                Respondents Page
                            </Button>
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item sm={9} xs={12}>
                            <Box display={previewBox}>
                                <ExpertShowNext expert_id={currentExpert?.expert_id} />
                            </Box>
                            <Box display={answerBox}>
                                {answers &&
                                    answers.length > 0 &&
                                    answers.map((question) => (
                                        <div className={classes.question} key={question.id}>
                                            <div className={classes.questionWrapper}>
                                                <Typography className={classes.questionOrder}>
                                                    Question {question.order}
                                                </Typography>
                                                <div
                                                    className={classes.questionText}
                                                    dangerouslySetInnerHTML={{
                                                        __html: question.text,
                                                    }}
                                                />
                                            </div>
                                            {question.answers.map(function (a, idx) {
                                                return (
                                                    <div key={a.id}>
                                                        {a.user_answer && (
                                                            <div className={classes.questionAnswer}>
                                                                <strong>{a.label && a.label}</strong>{" "}
                                                                {a.user_answer &&
                                                                    a.user_answer !== "true" &&
                                                                    `${a.user_answer}`}{" "}
                                                                {question.disqualifier && a.disqualifier && (
                                                                    <span className={classes.disqualifier}>
                                                                        DISQUALIFIED
                                                                    </span>
                                                                )}
                                                            </div>
                                                        )}
                                                        {a.user_answer &&
                                                            a.followupquestion &&
                                                            a.followupquestion.length > 0 &&
                                                            a.followupquestion[0].text !== "" && (
                                                                <div className={classes.followupText}>
                                                                    {a.followupquestion[0].text}{" "}
                                                                    {a.followupquestion[0].answers.length > 0 && (
                                                                        <span className={classes.followupAnswer}>
                                                                            {
                                                                                a.followupquestion[0].answers[0]
                                                                                    .user_answer
                                                                            }
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            )}
                                                    </div>
                                                );
                                            })}
                                            {question.comments && question.comments !== "" && (
                                                <div className={classes.comment}>
                                                    <div className={classes.commentText}>Comments:</div>{" "}
                                                    {question.comments}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <Table size="small">
                        <ScreenedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={props.data.length}
                        />
                        <TableBody>
                            {stableSort(props.data, getSorting(order, orderBy))
                                .map((row, index) => {
                                    const key = `${row.expert_id}_${index}`;
                                    return (
                                        <TableRow
                                            key={key}
                                            className={index % 2 === 0 ? classes.evenRow : classes.oddRow}
                                        >
                                            <TableCell align="left" className={classes.tableCellFirst}>
                                                {highlightRow(row.expert_id) ? (
                                                    <Button
                                                        className={classes.nameButtonBold}
                                                        onClick={() =>
                                                            displayExpertPreview(
                                                                row.project_id,
                                                                row.survey_id,
                                                                row.expert_id
                                                            )
                                                        }
                                                    >
                                                        {row.expert_fname} {row.expert_lname}
                                                        {row.expert_suffix && `, ${row.expert_suffix}`}
                                                        <FiberNewOutlinedIcon className={classes.newRow} />
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        className={classes.nameButton}
                                                        onClick={() =>
                                                            displayExpertPreview(
                                                                row.project_id,
                                                                row.survey_id,
                                                                row.expert_id
                                                            )
                                                        }
                                                    >
                                                        {row.expert_fname} {row.expert_lname}
                                                        {row.expert_suffix && `, ${row.expert_suffix}`}
                                                    </Button>
                                                )}
                                                <p className={classes.headline}>{row.expert_headline}</p>
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableCell}>
                                                <strong>Project:</strong>{" "}
                                                <a
                                                    href={`/project/view/${row.project_id}`}
                                                    className={classes.projectLink}
                                                >
                                                    {row.project_title}
                                                </a>{" "}
                                                ({row.project_status})
                                                <br />
                                                <strong>Client:</strong>{" "}
                                                <a
                                                    href={`/client/view/${row.client_id}`}
                                                    className={classes.projectLink}
                                                >
                                                    {row.client_name}
                                                </a>
                                                <br />
                                                <strong>Screener:</strong>{" "}
                                                <a
                                                    href={`/project/respondents/${row.project_id}`}
                                                    className={classes.projectLink}
                                                >
                                                    {row.survey_title}
                                                </a>
                                                <br />
                                                <strong>Screener Sent By:</strong>{" "}
                                                {row.sent_by_name ? row.sent_by_name : "Not Available"}
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableCell}>
                                                {basicDateTimeStamp(row.time_stamp)}
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableCell}>
                                                {row.current_status_name}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[25, 50, 75, 100, 150]}
                        component="div"
                        count={props.data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            "aria-label": "previous page",
                        }}
                        nextIconButtonProps={{
                            "aria-label": "next page",
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Grid>
            </Grid>
        </>
    );
}

export default ScreenedExperts;
