import React, { useState, useEffect, useCallback } from "react";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import makeStyles from "@mui/styles/makeStyles";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Warning from "@mui/icons-material/ErrorOutline";
import EditIcon from "@mui/icons-material/Edit";
import AddEditModal from "../components/AddEditModal";
import axios from "axios";
import moment from "moment";
import "moment-duration-format";
import { renderOptedOutText } from "../Utils";

import ExpertSnippet from "../components/ExpertSnippet";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    onboarding: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: "#ccc",
        marginBottom: "15px",
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    paperNotes: {
        padding: theme.spacing(2),
        textAlign: "center",
        backgroundColor: "#fdfdf4",
    },
    table: {
        width: "100%",
    },
    hcell: {
        textAlign: "left",
        borderBottom: "1px solid #CCC",
        fontSize: ".35rem",
    },
    tcell: {
        textAlign: "left",
        borderBottom: "1px solid #F1F1F1",
        padding: "8px",
        fontSize: ".85em",
    },
    actioncell: {
        textAlign: "right",
        borderBottom: "1px solid #F1F1F1",
        padding: "5px",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    appBar: {
        position: "relative",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    label: {
        color: "#D1C0E2",
        marginBottom: theme.spacing(0.25),
        fontSize: ".65rem",
        textAlign: "left",
        fontWeight: "normal",
        textTransform: "uppercase",
    },
    group_rate: {
        clear: "both",
        color: "#8aa694",
        float: "right",
        fontSize: ".85rem",
        fontWeight: "bold",
        marginTop: "0",
        marginBottom: "12px",
        display: "block",
    },
    rate: {
        color: "#8aa694",
        fontSize: "1.75rem",
        fontWeight: "bold",
        marginBottom: "8px",
    },
    min_hourly: {
        display: "block",
        color: "#000",
        fontSize: ".75rem",
        fontWeight: "normal",
        textTransform: "uppercase",
    },
    name: {
        color: "#3d4a42",
        marginBottom: theme.spacing(0.25),
        fontSize: "2rem",
        textAlign: "left",
        fontWeight: "bold",
        fontFamily: "Serif",
    },
    position: {
        color: "#444",
        marginTop: "0",
        marginBottom: theme.spacing(0.25),
        fontSize: "1rem",
        textAlign: "left",
        fontWeight: "normal",
    },
    bio: {
        marginBottom: theme.spacing(0.25),
        color: "#444",
        fontSize: ".9rem",
        textAlign: "left",
    },
    university: {
        color: "#444",
        fontSize: ".9rem",
        textAlign: "left",
        fontWeight: "normal",
        // paddingBottom: "10px",
        // borderBottom: "4px solid #F5F5F5",
        // marginBottom: "20px",
    },
    incomplete: {
        color: "red",
    },
    dnc: {
        textAlign: "left",
        color: "red",
        fontWeight: "bold",
        fontSize: ".75rem",
        marginTop: "5px",
        marginBottom: "8px",
        textTransform: "uppercase",
    },
    education: {
        paddingBottom: "10px",
        borderBottom: "1px solid #F0F0F0",
        marginBottom: "12px",
    },
    contact: {
        color: "#444",
        marginBottom: theme.spacing(0.25),
        fontSize: ".75rem",
        textAlign: "left",
        fontWeight: "normal",
    },
    dates: {
        color: "#444",
        marginBottom: theme.spacing(0.25),
        fontSize: ".75rem",
        textAlign: "left",
        fontWeight: "normal",
        fontStyle: "italic",
    },
    strong: {
        color: "#888",
        marginBottom: theme.spacing(0.5),
        fontSize: "inherit",
    },
    alternates: {
        display: "block",
        color: "#444",
        marginBottom: theme.spacing(0.5),
        fontSize: ".75rem",
        fontStyle: "normal",
    },
    tags: {
        display: "inline",
        fontSize: ".75rem",
        color: "#8966ad",
        padding: "0",
        margin: "0",
        textTransform: "capitalize",
        fontWeight: "normal",
        lineHeight: "130%",
        minWidth: "auto",
        textDecoration: "none",
    },
    complianceFlag: {
        backgroundColor: "red",
        color: "#fff",
        fontSize: "1rem",
        fontWeight: "bold",
        padding: "5px",
    },
    warningIcon: {
        fontSize: "1.25rem",
    },
    disclosuresHeading: {
        margin: "0",
        fontSize: ".75rem",
        textAlign: "left",
    },
    disclosures: {
        backgroundColor: "#fafafa",
        width: "100%",
        border: "1px solid #f1f1f1",
        borderRadius: "2px",
        color: "#000000",
        padding: "8px",
        textAlign: "left",
    },
    questions: {
        marginTop: "0",
        marginBottom: "10px",
        color: "#444",
        fontSize: ".5rem",
        textAlign: "left",
    },
    answers: {
        color: "#000",
        fontSize: ".55rem",
        textAlign: "left",
    },
    confirmed: {
        color: "#000000",
        marginBottom: "6px",
        fontSize: ".5rem",
        textTransform: "uppercase",
    },
    current: {
        backgroundColor: "#e1d7ec",
        borderRadius: "2px",
        color: "#000000",
        padding: "12px 8px 4px 12px",
        marginBottom: "15px",
    },
    job: {
        padding: "12px 8px 4px 12px",
        marginBottom: "10px",
    },
    link: {
        color: "#8966ad",
        textDecoration: "none",
    },
    appBarName: {
        color: "#b7ddd9",
    },
    margin: {
        margin: theme.spacing(1),
    },
    submit: {
        color: "#fff",
        margin: theme.spacing(3, 0, 2),
    },
    nospace: {
        fontSize: ".65rem",
        marginTop: "0",
    },
    buttonLink: {
        color: "#8966ad",
        textDecoration: "none",
        fontSize: "1rem",
        padding: "0",
        margin: "0",
    },
    projects: {
        listStyleType: "none",
        borderBottom: "1px solid #D5D5D5",
    },
    editorWrapper: {
        border: "1px solid #EAEAEA",
        borderRadius: "2px",
        padding: "12px",
    },
    addEditIcon: {
        marginLeft: ".25rem",
        marginRight: ".25rem",
    },
    expertTopContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingBottom: "10px",
        borderBottom: "4px solid #F5F5F5",
        marginBottom: "20px",
    },
    rateWrapper: {
        paddingLeft: ".5rem",
    },
    pointyFinger: {
        cursor: "pointer",
        transition: "200ms",
        "&:hover, &:focus": {
            color: "#3c8864",
        },
    },
    rateBtn: {
        fontSize: ".85rem",
        color: "#fff",
        marginLeft: ".75rem",
        backgroundColor: "#8966ad",
        transition: "200ms",
        "&:hover, &:focus": {
            backgroundColor: "#523197",
        },
    },
    optedOutContainer: {
        width: "fit-content",
        border: "3px solid #8c5429",
        padding: "1rem",
        backgroundColor: "#f7dfc1",
        fontSize: ".75rem",
        color: "#6d42ed",
        marginBottom: ".5rem",
    },
}));

function ExpertPreview(props) {
    console.log(props, " :: props (ExpertPreview.js)");
    const { expertsData } = props;
    const classes = useStyles();
    const { match, history } = props;
    const [token, setToken] = useState("");
    const [headline, setHeadline] = useState("block");
    const [headlineEdit, setHeadlineEdit] = useState("none");
    const [bioDisplay, setBioDisplay] = useState("block");
    const [editorDisplay, setEditorDisplay] = useState("none");
    const [blindedDisplay, setBlindedDisplay] = useState("block");
    const [blindedEditorDisplay, setBlindedEditorDisplay] = useState("none");
    const [editor, setEditorState] = useState(EditorState.createEmpty());
    const [blinded, setBlindedEditorState] = useState(EditorState.createEmpty());
    const [data, setData] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState({});
    const [hasDataLoaded, setHasDataLoaded] = useState(false);
    const getSteps = () => {
        return [
            {
                id: 1,
                label: "Responded",
                confirmed:
                    props.data.onboarding.length > 0 &&
                    props.data.onboarding.find((s) => s.activity === "DECLINED" || s.activity === "ACCEPTED") !==
                        undefined,
            },
            {
                id: 2,
                label: "Contact Data",
                confirmed:
                    props.data.onboarding.length > 0 &&
                    props.data.onboarding.find((s) => s.activity === "CONTACT_INFO") !== undefined,
            },
            {
                id: 3,
                label: "Signed Terms",
                confirmed:
                    props.data.onboarding.length > 0 &&
                    props.data.onboarding.find((s) => s.activity === "TERMS_SIGNED") !== undefined,
            },
            {
                id: 4,
                label: "Disclosures",
                confirmed:
                    props.data.onboarding.length > 0 &&
                    props.data.onboarding.find((s) => s.activity === "DISCLOSURES") !== undefined,
            },
            {
                id: 5,
                label: "Employment",
                confirmed:
                    props.data.onboarding.length > 0 &&
                    props.data.onboarding.find((s) => s.activity === "EMPLOYMENT") !== undefined,
            },
        ];
    };
    const steps = getSteps();

    const updateHeadlineUrl = "/api/expert/updateHeadline";
    const updateBioUrl = "/api/expert/updateBio";
    const updateBlindedBioUrl = "/api/expert/updateBlindedBio";

    function formatDateString(str) {
        return moment(str).format("MMM. Do, YYYY, h:mma");
    }

    function formatPhoneNumber(str, usa) {
        const cleaned = `${str}`.replace(/\D/g, "");
        const formatted = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if ((formatted && usa) || (formatted && usa === "+1")) {
            return `(${formatted[1]}) ${formatted[2]}-${formatted[3]}`;
        }
        return str;
    }

    useEffect(() => {
        // console.log("STEPS: ", steps);
    }, [data]);

    useEffect(() => {
        console.log("====== useEffect ======");
        setData({
            ...props.data,
            id: props.data.id,
            headline: props.data.headline,
            biography: props.data.biography,
            blinded_bio: props.data.blinded_bio,
        });
        const contentBlock = htmlToDraft(props.data.biography);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            setEditorState(EditorState.createWithContent(contentState));
        }
        let blindedBio = "";
        if (props.data.blinded_bio !== null && props.data.blinded_bio !== "") {
            blindedBio = props.data.blinded_bio;
        } else {
            blindedBio = "Blinded Bio Missing";
        }
        const blindedContentBlock = htmlToDraft(blindedBio);
        if (blindedContentBlock) {
            const blindedContentState = ContentState.createFromBlockArray(blindedContentBlock.contentBlocks);
            setBlindedEditorState(EditorState.createWithContent(blindedContentState));
        }
        setHasDataLoaded(true);
    }, [props]);

    // function optedOutTextWrapper(data) {
    //     const fullExpertData = expertsData.find((e) => e.id === data.id);
    //     return renderOptedOutText(fullExpertData);
    // }

    function showEditor() {
        setBioDisplay("none");
        setEditorDisplay("block");
    }

    function showBlindedEditor() {
        setBlindedDisplay("none");
        setBlindedEditorDisplay("block");
    }

    function onEditorStateChange(editor) {
        setEditorState(editor);
        const jsonHtml = convertToRaw(editor.getCurrentContent());
        const html = draftToHtml(jsonHtml);
        setData({
            ...data,
            biography: html,
        });
    }

    function onBlindedEditorStateChange(blinded) {
        setBlindedEditorState(blinded);
        const jsonHtml = convertToRaw(blinded.getCurrentContent());
        const html = draftToHtml(jsonHtml);
        setData({
            ...data,
            blinded_bio: html,
        });
    }

    function handleChange(event) {
        const { target } = event;
        const { value } = target;
        setData({
            ...data,
            headline: value,
        });
        // console.log("HEADLINE: ", value);
    }

    const handleIconClick = (event, iconName, supplementalData, data) => {
        let modalDataObj = {
            iconName,
            supplementalData,
            person_id: props.data.person_id,
            data: data === null ? "" : data,
        };
        if (iconName === "email") {
            modalDataObj = {
                ...modalDataObj,
                modalTitle: "Please Enter Email",
                modalLabel: "Email",
                placeholder: "Enter Email...",
            };
        } else if (iconName === "phoneNum") {
            modalDataObj = {
                ...modalDataObj,
                modalTitle: "Please Enter Number",
                modalLabel: "Phone Number",
                placeholder: "Enter Phone Num...",
            };
        } else if (iconName === "expertRate") {
            modalDataObj = {
                ...modalDataObj,
                modalTitle: "Please Enter Expert Rate",
                modalLabel: "Rate",
                placeholder: "Enter Rate...",
            };
        }
        setModalData(modalDataObj);
        openModal();
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const generateToken = async (id) => {
        const url = "/api/expert/generatetokenurl";
        // const url = "/api/expert/generateTokenById";
        const postData = {
            id,
            pid: props.project ? props.project.id : "",
            rmid: props.user.id,
            rmemail: props.user.mail,
            rmfname: props.user.fname,
            rmlname: props.user.lname,
            rmphone: formatPhoneNumber(props.user.number, "+4234"),
        };

        console.log(postData, " :: postData");
        // console.log("POST TOKEN DATA: ", postData);
        await axios
            .post(url, postData, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200 && result.data) {
                    // console.log("Token generated? ", result.data.data);
                    // const onboardLink = `https://firstthought.io/onboard/${result.data.data}`;
                    // const tncLink = `https://firstthought.io/terms/${result.data.data}`;
                    // const disclosuresLink = `https://firstthought.io/disclosures/${result.data.data}`;
                    setToken(result.data.data);
                }
            })
            .catch((result) => {
                console.log("Something went wrong, API Error, 404 Error:", result);
            });
    };

    function copyLink(e) {
        const link = e.target;
        link.select();
        document.execCommand("copy", false);
        setToken("");
        alert(`SUCCESSFULLY COPIED!" ${link.value}`);
    }

    function editHeadline() {
        setHeadline("none");
        setHeadlineEdit("block");
    }

    function submitBioEvent() {
        if (data.biography !== "") {
            axios
                .post(updateBioUrl, data, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                })
                .then((result) => {
                    if (result.status === 200) {
                        setBioDisplay("block");
                        setEditorDisplay("none");
                        setData({
                            ...data,
                            biography: result.data.data.biography,
                        });
                        // console.log("BIO: ", result.data.data.biography);
                        console.log(`Successful Return POST: ${JSON.stringify(result.data.data)}`);
                    } else {
                        console.log(`Good POST, but something went wrong: ${JSON.stringify(data)}`);
                    }
                })
                .catch(() => {
                    console.log(`API not done yet, 404 Error: ${JSON.stringify(data)}`);
                });
        }
    }
    function submitBlindedBioEvent() {
        if (data.blinded_bio !== "") {
            // console.log("SUBMIT DATA: ", data);
            axios
                .post(updateBlindedBioUrl, data, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                })
                .then((result) => {
                    if (result.status === 200) {
                        setBlindedDisplay("block");
                        setBlindedEditorDisplay("none");
                        setData({
                            ...data,
                            blinded_bio: result.data.data.blinded_bio,
                        });
                        console.log(`Successful Return POST: ${JSON.stringify(result.data.data)}`);
                    } else {
                        console.log(`Good POST, but something went wrong: ${JSON.stringify(data)}`);
                    }
                })
                .catch(() => {
                    console.log(`API not done yet, 404 Error: ${JSON.stringify(data)}`);
                });
        }
    }
    function submitHeadline() {
        if (data.headline !== "") {
            axios
                .post(updateHeadlineUrl, data, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                })
                .then((result) => {
                    if (result.status === 200) {
                        setHeadline("block");
                        setHeadlineEdit("none");
                        setData({
                            ...data,
                            headline: result.data.data.headline,
                        });
                        console.log(`Successful Return POST: ${JSON.stringify(result.data.data)}`);
                    } else {
                        console.log(`Good POST, but something went wrong: ${JSON.stringify(data)}`);
                    }
                })
                .catch(() => {
                    console.log(`API not done yet, 404 Error: ${JSON.stringify(data)}`);
                });
        }
    }

    function resubscribeExpert(expertId) {
        const url = "/api/mail/resubscribe";
        const postData = {
            expertId,
        };
        axios
            .post(url, postData, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    console.log("Successfully Re-subscribed");
                } else {
                    console.log(`Good POST, but something went wrong: ${JSON.stringify(data)}`);
                }
            })
            .catch(() => {
                console.log(`API not done yet, 404 Error: ${JSON.stringify(data)}`);
            });
    }

    if (hasDataLoaded) {
        return (
            <div className={classes.root}>
                <Grid container spacing={2}>
                    <Grid item sm={12} xs={12}>
                        {props.data.onboarding.length > 0 && (
                            <Paper className={classes.onboarding}>
                                <label className={classes.label}>EXPERT ONBOARDED AFTER OCTOBER 15, 2020</label>
                                <Stepper alternativeLabel>
                                    {steps.map((label) => (
                                        <Step key={label.id} completed={label.confirmed}>
                                            <StepLabel>{label.label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Paper>
                        )}
                        <Paper className={classes.paper}>
                            <div className={classes.expertTopContainer}>
                                <Box display="flex" flexDirection="column" justifyContent="flex-start">
                                    {data.created_on !== "" ? (
                                        <p className={classes.label}>
                                            Status: <strong className={classes.strong}>{data.status}</strong>, Created
                                            By: <strong className={classes.strong}>{data.created_by_name}</strong> on{" "}
                                            <strong className={classes.strong}>
                                                {formatDateString(data.created_on)}
                                            </strong>
                                            , Disclosures:{" "}
                                            <strong className={classes.strong}>
                                                {data.disclosures ? "Yes" : "No"}
                                            </strong>
                                            , Terms:{" "}
                                            <strong className={classes.strong}>
                                                {data.terms_signed ? "Yes" : "No"}
                                            </strong>
                                            , Background:{" "}
                                            <strong className={classes.strong}>
                                                {data.background_check ? "Yes" : "No"}
                                            </strong>
                                        </p>
                                    ) : (
                                        <p className={classes.label}>
                                            Status: <strong className={classes.strong}>{data.status}</strong>
                                        </p>
                                    )}
                                    <h1 className={classes.name}>
                                        {data.prefix && data.prefix} {data.fname} {data.mname && data.mname}{" "}
                                        {data.lname}
                                        {data.suffix && `, ${data.suffix}`}
                                    </h1>
                                    <Box display={headline}>
                                        <h2 className={classes.university} onClick={editHeadline}>
                                            {data.headline}
                                        </h2>
                                    </Box>
                                    <Box display={headlineEdit}>
                                        <TextField
                                            fullWidth
                                            id="headline"
                                            margin="normal"
                                            name="headline"
                                            onChange={handleChange}
                                            value={data.headline}
                                        />
                                        <Button onClick={submitHeadline}>Save</Button>
                                    </Box>
                                </Box>
                                <Box display="flex" flexDirection="column" justifyContent="flex-start">
                                    {data.consultation_rate ? (
                                        <Box
                                            className={classes.rateWrapper}
                                            display="flex"
                                            flexDirection="row"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <h4 className={classes.rate}>
                                                {data.consultation_rate} {data.consultation_rate_currency}
                                                {data.minimum_hourly > 0 && (
                                                    <em className={classes.min_hourly}>
                                                        Minimum: {data.minimum_hourly} mins
                                                    </em>
                                                )}
                                            </h4>
                                            <EditIcon
                                                className={`${classes.addEditIcon} ${classes.pointyFinger}`}
                                                onClick={(event) =>
                                                    handleIconClick(
                                                        event,
                                                        "expertRate",
                                                        {
                                                            name: "currencyType",
                                                            value: data.consultation_rate_currency,
                                                        },
                                                        data.consultation_rate
                                                    )
                                                }
                                                fontSize="small"
                                            />
                                        </Box>
                                    ) : (
                                        // <Box
                                        //     display="flex"
                                        //     flexDirection="row"
                                        //     justifyContent="center"
                                        //     alignItems="center"
                                        //     className={`${classes.rateWrapper} ${classes.pointyFinger}`}
                                        // onClick={(event) => handleIconClick(event, "expertRate", null, null)}
                                        // >
                                        //     <div className={classes.rateText}>Add Expert Rate</div>
                                        //     <AddCircleOutlineIcon
                                        //         // onClick={(event) => handleIconClick(event, "expertRate", null, null)}
                                        //         className={`${classes.addEditIcon}`}
                                        //     />
                                        // </Box>
                                        <Button
                                            onClick={(event) => handleIconClick(event, "expertRate", null, null)}
                                            className={classes.rateBtn}
                                        >
                                            Add Rate
                                        </Button>
                                    )}
                                    {data.group_meeting_rate && (
                                        <h4 className={classes.group_rate}>
                                            GROUP RATE: {data.group_meeting_rate} {data.group_meeting_rate_currency}
                                        </h4>
                                    )}
                                </Box>
                            </div>
                            {/* {props.data.education.length > 0 && (
              <h2 className={classes.university}>
                {
                  props.data.education.map(function(e, idx) {
                    return e.school;
                  })[0]
                }
              </h2>
            )} */}
                            {data.dnc && <h3 className={classes.dnc}>{data.dnc && `Do Not Contact: ${data.dnc}`}</h3>}
                            {data.opted_out && (
                                // <h3 className={classes.dnc}>{data.opted_out && "Opted Out (Unsubscribed)"}</h3>
                                <h3 className={classes.dnc}>
                                    {data?.opted_out && data?.opted_out_info ? (
                                        <div className={classes.optedOutContainer}>{renderOptedOutText(data)}</div>
                                    ) : null}
                                </h3>
                            )}
                            <Grid container spacing={1}>
                                <Grid item sm={7} xs={12}>
                                    <h3 className={classes.contact}>
                                        <strong>Primary Contact:</strong>

                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            justifyContent="center"
                                            alignItems="flex-start"
                                        >
                                            {data.email && !data.opted_out_info.isPermament ? (
                                                <Box
                                                    display="flex"
                                                    flexDirection="row"
                                                    justifyContent="flex-start"
                                                    alignItems="center"
                                                >
                                                    <span>{`${data.email}`}</span>
                                                    <EditIcon
                                                        className={`${classes.addEditIcon} ${classes.pointyFinger}`}
                                                        onClick={(event) =>
                                                            handleIconClick(event, "email", null, data.email)
                                                        }
                                                        fontSize="small"
                                                    />
                                                </Box>
                                            ) : (
                                                <Box
                                                    display="flex"
                                                    flexDirection="row"
                                                    justifyContent="flex-start"
                                                    alignItems="center"
                                                >
                                                    <span className={classes.incomplete}>* email unavailable</span>
                                                    <EditIcon
                                                        className={`${classes.addEditIcon} ${classes.pointyFinger}`}
                                                        onClick={(event) => handleIconClick(event, "email", null, null)}
                                                        fontSize="small"
                                                    />
                                                </Box>
                                            )}
                                            {data.phone && data.phone.length > 8 && !data.opted_out_info.isPermament ? (
                                                <Box
                                                    display="flex"
                                                    flexDirection="row"
                                                    justifyContent="flex-start"
                                                    alignItems="center"
                                                >
                                                    <a href={`tel:${data.country_code}${data.phone}`}>
                                                        {`${data.country_code} ${formatPhoneNumber(
                                                            data.phone,
                                                            data.us
                                                        )}`}
                                                    </a>
                                                    <EditIcon
                                                        className={`${classes.addEditIcon} ${classes.pointyFinger}`}
                                                        onClick={(event) =>
                                                            handleIconClick(
                                                                event,
                                                                "phoneNum",
                                                                { name: "countryCode", value: data.country_code },
                                                                data.phone
                                                            )
                                                        }
                                                        fontSize="small"
                                                    />
                                                </Box>
                                            ) : (
                                                <Box
                                                    display="flex"
                                                    flexDirection="row"
                                                    justifyContent="flex-start"
                                                    alignItems="center"
                                                >
                                                    <span className={classes.incomplete}>* phone unavailable</span>
                                                    <EditIcon
                                                        className={`${classes.addEditIcon} ${classes.pointyFinger}`}
                                                        onClick={(event) =>
                                                            handleIconClick(event, "phoneNum", null, null)
                                                        }
                                                        fontSize="small"
                                                    />
                                                </Box>
                                            )}
                                        </Box>
                                    </h3>
                                    {data.alternate_phones.length > 0 && !data.opted_out && (
                                        <h3 className={classes.contact}>
                                            <strong>Alternate Phone Numbers:</strong>{" "}
                                            {data.alternate_phones.map(function (phone, idx) {
                                                return (
                                                    <div key={idx}>
                                                        <em className={classes.alternates}>
                                                            {phone.type !== "null" && phone.type !== ""
                                                                ? phone.type
                                                                : "Unknown"}
                                                            {": "}
                                                        </em>
                                                        <a href={`tel:${phone.code}${phone.number}`}>
                                                            {`${phone.code} ${formatPhoneNumber(
                                                                phone.number,
                                                                phone.code
                                                            )}`}
                                                        </a>
                                                    </div>
                                                );
                                            })}
                                        </h3>
                                    )}
                                    {data.alternate_emails.length > 0 && !data.opted_out && (
                                        <h3 className={classes.contact}>
                                            <strong>Alternate Emails:</strong>{" "}
                                            {data.alternate_emails.map(function (e, idx) {
                                                return (
                                                    <em className={classes.alternates} key={idx}>
                                                        {e.email}
                                                    </em>
                                                );
                                            })}
                                        </h3>
                                    )}
                                    <h4 className={classes.contact}>
                                        <strong>Location:</strong> {data.city && data.city !== "NA" && data.city + ", "}
                                        {data.state && data.state.label !== "NA" && data.state.label !== "" && (
                                            <span>
                                                {data.state.label}
                                                {", "}
                                            </span>
                                        )}
                                        {data.country && data.country.label}
                                    </h4>
                                    {data.group_meeting_rate && (
                                        <h4 className={classes.contact}>
                                            <strong>Group meeting rate:</strong> {data.group_meeting_rate}{" "}
                                            {data.group_meeting_rate_currency}
                                        </h4>
                                    )}
                                    {data.specialty.length > 0 && (
                                        <h5 className={classes.contact}>
                                            <strong>Specialty:</strong>
                                            {data.specialty.map(function (t, idx) {
                                                return (
                                                    <em className={classes.tags} key={idx}>
                                                        {" "}
                                                        {t.label}
                                                        {idx < data.specialty.length - 1 && ", "}
                                                    </em>
                                                );
                                            })}
                                        </h5>
                                    )}
                                    {data.linkedin_url && (
                                        <p className={classes.contact}>
                                            <strong>LinkedIn:</strong>{" "}
                                            <a
                                                href={data.linkedin_url}
                                                className={classes.link}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {data.linkedin_url}
                                            </a>
                                        </p>
                                    )}
                                    {data.tags.length > 0 && props.page !== "Results" ? (
                                        <h5 className={classes.contact}>
                                            <strong>Tags: </strong>
                                            {data.tags.map(function (t, idx) {
                                                return (
                                                    <Button
                                                        component={Link}
                                                        to={`/search?type=expert&and_tags=${t.value}`}
                                                        className={classes.tags}
                                                        key={idx}
                                                    >
                                                        {" "}
                                                        {t.label}
                                                        {idx < data.tags.length - 1 && ","}
                                                        <span>&nbsp;</span>
                                                    </Button>
                                                );
                                            })}
                                        </h5>
                                    ) : (
                                        <h5 className={classes.contact}>
                                            <strong>Tags: </strong>
                                            {data.tags.map(function (t, idx) {
                                                return (
                                                    <Button
                                                        onClick={() => props.tagSearchEvent(t.value)}
                                                        className={classes.tags}
                                                        key={idx}
                                                    >
                                                        {" "}
                                                        {t.label}
                                                        {idx < data.tags.length - 1 && ","}
                                                        <span>&nbsp;</span>
                                                    </Button>
                                                );
                                            })}
                                        </h5>
                                    )}
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                    >
                                        <ExpertSnippet
                                            expertList={[props.data.id]}
                                            size="medium"
                                            fullWidth={false}
                                            styles={{
                                                fontSize: ".65rem",
                                                color: "#3c8864",
                                                backgroundColor: "transparent",
                                                boxShadow: "none",
                                                margin: "0",
                                                marginTop: ".5rem",
                                                padding: "0",
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item sm={5} xs={12}>
                                    {data.disclosures_form_review_required && (
                                        <div className={classes.complianceFlag}>
                                            <Warning className={classes.warningIcon} /> PLEASE REVIEW
                                        </div>
                                    )}
                                    {data.disclosures && (
                                        <div className={classes.disclosures}>
                                            <h5 className={classes.disclosuresHeading}>Disclosure Question/Answers</h5>
                                            <em className={classes.answers}>
                                                Answered on {formatDateString(data.disclosure_questions[0].created_on)}
                                            </em>
                                            <br />
                                            <br />
                                            {data.disclosure_questions[0].question_data.length > 0 &&
                                                data.disclosure_questions[0].question_data.map((q, idx) => {
                                                    const key = `q_${idx}`;
                                                    return (
                                                        <div key={key}>
                                                            <p className={classes.questions}>
                                                                {q.label}{" "}
                                                                <strong className={classes.answers}>
                                                                    {q.value === "true" ? "YES" : "NO"}
                                                                </strong>
                                                            </p>
                                                            {q.value === "true" &&
                                                                q.branch.map((b, i) => {
                                                                    const bkey = `q_${i}`;
                                                                    return (
                                                                        <p className={classes.questions} key={bkey}>
                                                                            {b.label}{" "}
                                                                            <strong className={classes.answers}>
                                                                                {b.value}
                                                                            </strong>
                                                                        </p>
                                                                    );
                                                                })}
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    )}
                                </Grid>
                            </Grid>
                            {/* {props.data.tags.length > 1 && (
              <Button
                component={Link}
                to={`/search?type=expert&or_tags=${props.data.tags.map(t => t.value)}`}
                className={classes.link}>
                Search All Tags
              </Button>
            )} */}
                        </Paper>
                    </Grid>
                    {data.projects && data.projects.length > 0 && (
                        <Grid item sm={12} xs={12}>
                            <Paper className={classes.paper}>
                                <label className={classes.label}>PROJECT HISTORY</label>
                                <br />
                                <br />
                                <table className={classes.table} cellSpacing={0}>
                                    <thead>
                                        <tr>
                                            <th scope="col" className={classes.hcell}>
                                                STATUS
                                            </th>
                                            <th scope="col" className={classes.hcell}>
                                                TITLE
                                            </th>
                                            <th scope="col" className={classes.hcell}>
                                                TYPE
                                            </th>
                                            <th scope="col" className={classes.hcell}>
                                                CREATED
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.projects.map(function (c, idx) {
                                            const key = `${c.id}_${idx}`;
                                            return (
                                                <tr className={classes.contacts} key={key}>
                                                    <td scope="col" className={classes.tcell}>
                                                        {c.status}
                                                    </td>
                                                    <td scope="col" className={classes.tcell}>
                                                        <Link className={classes.link} to={`/project/view/${c.id}`}>
                                                            {c.title}
                                                        </Link>
                                                    </td>
                                                    <td className={classes.tcell}>{c.type}</td>
                                                    <td className={classes.tcell}>{formatDateString(c.updated)}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </Paper>
                        </Grid>
                    )}
                    <Grid item sm={12} xs={12}>
                        <Paper className={classes.paper}>
                            <label className={classes.label}>BIOGRAPHY</label>
                            <Box display={editorDisplay}>
                                <Editor
                                    editorState={editor}
                                    spellCheck
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName={classes.editorWrapper}
                                    onEditorStateChange={onEditorStateChange}
                                />
                                <Button className={classes.link} onClick={submitBioEvent}>
                                    Save
                                </Button>
                            </Box>
                            <Box display={bioDisplay}>
                                <div
                                    className={classes.bio}
                                    dangerouslySetInnerHTML={{
                                        __html: data.biography ? data.biography : data.biography,
                                    }}
                                />
                                <Button className={classes.link} onClick={showEditor}>
                                    Enter/Edit Bio
                                </Button>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <Paper className={classes.paper}>
                            <label className={classes.label}>BLINDED BIOGRAPHY</label>
                            <Box display={blindedEditorDisplay}>
                                <Editor
                                    editorState={blinded}
                                    spellCheck
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName={classes.editorWrapper}
                                    onEditorStateChange={onBlindedEditorStateChange}
                                />
                                <Button className={classes.link} onClick={submitBlindedBioEvent}>
                                    Save
                                </Button>
                            </Box>
                            <Box display={blindedDisplay}>
                                <div
                                    className={classes.bio}
                                    dangerouslySetInnerHTML={{
                                        __html: data.blinded_bio ? data.blinded_bio : data.blinded_bio,
                                    }}
                                />
                                <Button className={classes.link} onClick={showBlindedEditor}>
                                    Edit Blinded Bio
                                </Button>
                            </Box>
                        </Paper>
                    </Grid>
                    {data.notes && (
                        <Grid item sm={12} xs={12}>
                            <Paper className={classes.paperNotes}>
                                <label className={classes.label}>ADDITIONAL NOTES</label>
                                <p className={classes.bio}>{data.notes}</p>
                            </Paper>
                        </Grid>
                    )}
                    <Grid item sm={12} xs={12}>
                        <Paper className={classes.paper}>
                            {token ? (
                                <div>
                                    <TextField
                                        fullWidth
                                        id="onboard"
                                        label="Onboarding Link"
                                        margin="normal"
                                        name="onboard"
                                        onClick={copyLink}
                                        // onFocus={(event) => {
                                        //   event.target.select();
                                        // }}
                                        value={token}
                                    />
                                </div>
                            ) : (
                                <Button onClick={() => generateToken(data.id)} className={classes.link}>
                                    Generate Onboarding Links
                                </Button>
                            )}
                        </Paper>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <Paper className={classes.paper}>
                            <label className={classes.label}>EMPLOYMENT</label>
                            {data.current_employment.length > 0 && (
                                <div>
                                    {data.current_employment.map(function (job, idx) {
                                        const key = `key_${idx}`;
                                        return (
                                            <div key={key} className={classes.current}>
                                                {job.expert_confirmed && (
                                                    <h2 className={classes.confirmed}>
                                                        *** Confirmed By {data.fname} ***
                                                    </h2>
                                                )}
                                                <h2 className={classes.position}>
                                                    <strong>{job.position}</strong>, {job.company}
                                                </h2>
                                                <h3 className={classes.dates}>
                                                    {job.from_month} {job.from_year} -{" "}
                                                    {job.present ? "Present" : job.to_month}{" "}
                                                    {job.present ? "" : job.to_year}
                                                    {" ("}
                                                    {job.duration}
                                                    {")"}
                                                </h3>
                                                <p className={classes.contact}>{job.summary}</p>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                            {data.past_employment.length > 0 && (
                                <div>
                                    {data.past_employment.map(function (job, idx) {
                                        const key = `key_${idx}`;
                                        return (
                                            <div key={key} className={classes.job}>
                                                {job.expert_confirmed && (
                                                    <h2 className={classes.confirmed}>
                                                        *** Confirmed By {data.fname} ***
                                                    </h2>
                                                )}
                                                <h2 className={classes.position}>
                                                    <strong>{job.position}</strong>, {job.company}
                                                </h2>
                                                <h3 className={classes.dates}>
                                                    {job.from_month} {job.from_year} - {job.to_month} {job.to_year}
                                                    {" ("}
                                                    {job.duration}
                                                    {")"}
                                                </h3>
                                                <p className={classes.contact}>{job.summary}</p>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </Paper>
                    </Grid>
                    {data.education.length > 0 && (
                        <Grid item sm={12} xs={12}>
                            <Paper className={classes.paper}>
                                <label className={classes.label}>EDUCATION</label>
                                {data.education.map(function (e, idx) {
                                    return (
                                        <div key={idx} className={classes.education}>
                                            <h2 className={classes.position}>
                                                <strong>{e.school}</strong>, {e.degree}
                                            </h2>
                                            <h3 className={classes.dates}>{e.dates}</h3>
                                        </div>
                                    );
                                })}
                            </Paper>
                        </Grid>
                    )}
                    {data.email && (
                        <Grid item sm={12} xs={12}>
                            <Paper className={classes.paper}>
                                <TextField
                                    fullWidth
                                    id="unsubscribe"
                                    label="Unsubscribe Link"
                                    margin="normal"
                                    name="unsubscribe"
                                    onClick={copyLink}
                                    value={`https://www.firstthought.io/unsubscribe/${data.id}/${data.email}/tnc`}
                                />
                                {data.opted_out && [2, 3, 4, 5, 67291].includes(props.user.id) && (
                                    <Button onClick={() => resubscribeExpert(data.id)}>Re-subscribe this Person</Button>
                                )}
                            </Paper>
                        </Grid>
                    )}
                </Grid>
                {isModalOpen && (
                    <AddEditModal
                        isModalOpen={isModalOpen}
                        openModal={openModal}
                        closeModal={closeModal}
                        modalData={modalData}
                        setModalData={setModalData}
                        data={data}
                        setData={setData}
                        filter={props.filter}
                        setFilterResults={props.setFilterResults}
                    />
                )}
            </div>
        );
    } else {
        return <div>Loading...</div>;
    }
}
export default ExpertPreview;
