import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import { Link } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { TablePagination } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Button from "@mui/material/Button";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
// import Grid from "@mui/material/Grid";
import CreateIcon from "@mui/icons-material/Create";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import "moment-duration-format";
import { authContext } from "../context/auth";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(2),
        overflowX: "auto",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    table: {
        minWidth: 750,
    },
    button: {
        margin: "0",
        color: "#3c8864",
        cursor: "pointer",
    },
    nameButton: {
        color: "#3c8864",
        padding: "0",
        textDecoration: "none",
        fontSize: ".85rem",
        fontWeight: "normal",
        textTransform: "none",
        minWidth: "auto",
    },
    formControl: {
        margin: theme.spacing(3),
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    actioncell: {
        textAlign: "right",
    },
}));

function SaveSearch(props) {
    const { match, history } = props;
    const { setAuthData, auth } = useContext(authContext);
    const classes = useStyles();
    const [openLoader, setOpenLoader] = useState(true);
    const [data, setData] = useState([]);
    const [pdata, setPublicData] = useState([]);
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(0);
    const [ss, setSaveSearch] = useState({
        id: "",
        name: "",
        query: "",
        is_public: true,
        updated_by_id: auth.data.id,
    });
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("created_on");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    let allSearches = [];

    async function fetchSavedSearches() {
        await axios
            .get("/api/savesearch", {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((response) => {
                if (response.status === 200 && response.data.data) {
                    // global array to store all searches
                    allSearches = response.data.data;
                    const userSearches = allSearches.filter((e) => e.created_by_id === auth.data.id);
                    const publicSearches = allSearches.filter(
                        (e) => e.is_public === true && e.created_by_id !== auth.data.id
                    );
                    setData(userSearches);
                    setPublicData(publicSearches);
                    setOpenLoader(false);
                    console.log("Your Searches", userSearches);
                    console.log("Public Searches", publicSearches);
                }
            });
    }
    function handleTabChange(event, newValue) {
        setValue(newValue);
    }

    function formatDateString(str) {
        return moment(str).format("MMM. Do, YYYY, h:mma");
    }

    function desc(a, b, oBy) {
        if (b[oBy] < a[oBy]) {
            return -1;
        }
        if (b[oBy] > a[oBy]) {
            return 1;
        }
        return 0;
    }

    function stableSort(array, cmp) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const tOrder = cmp(a[0], b[0]);
            if (order !== 0) return tOrder;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    function getSorting(o, oBy) {
        return o === "desc" ? (a, b) => desc(a, b, oBy) : (a, b) => -desc(a, b, oBy);
    }

    const headCells = [
        { id: "name", numeric: false, disablePadding: false, label: "Name" },
        {
            id: "created_on",
            numeric: false,
            disablePadding: false,
            label: "Created On",
        },
        {
            id: "is_public",
            numeric: false,
            disablePadding: false,
            label: "Display",
        },
    ];

    function EnhancedTableHead(props) {
        const createSortHandler = (property) => (event) => {
            props.onRequestSort(event, property);
        };
        return (
            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? "right" : "left"}
                            padding={headCell.disablePadding ? "none" : "normal"}
                            sortDirection={props.orderBy === headCell.id ? props.order : false}
                        >
                            <TableSortLabel
                                active={props.orderBy === headCell.id}
                                direction={props.order}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {props.orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {props.order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                    <TableCell>&nbsp;</TableCell>
                </TableRow>
            </TableHead>
        );
    }
    function handleChange(event) {
        const { target } = event;
        const { value, name } = target;
        setSaveSearch({
            ...ss,
            [name]: value,
        });
    }
    function handleCheckbox(event) {
        const { target } = event;
        const { name } = target;
        setSaveSearch({ ...ss, [name]: event.target.checked });
    }

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        fetchSavedSearches();
    }, []);

    const handleClose = () => {
        setOpen(false);
    };

    const handleSaveSearch = () => {
        axios
            .post("/api/savesearch/update", ss, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    setOpen(false);
                    fetchSavedSearches();
                } else {
                    console.log("Error trying to save the search.");
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const editSaveSearchEvent = (s) => {
        setOpen(true);
        setSaveSearch({
            ...ss,
            id: s.id,
            name: s.name,
            query: s.query,
            is_public: s.is_public,
            updated_by_id: auth.data.id,
        });
    };

    EnhancedTableHead.propTypes = {
        classes: PropTypes.object.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        order: PropTypes.oneOf(["asc", "desc"]).isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired,
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <Typography
                component="div"
                role="tabpanel"
                hidden={value !== index}
                id={`scrollable-auto-tabpanel-${index}`}
                aria-labelledby={`scrollable-auto-tab-${index}`}
                {...other}
            >
                <Box p={2}>{children}</Box>
            </Typography>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };

    return (
        <div>
            <Backdrop
                // transitionDuration={500}
                className={classes.backdrop}
                open={openLoader}
                onClick={() => {
                    setOpenLoader(false);
                }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog open={open} onClose={handleClose} fullWidth aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Edit This Search</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        onChange={handleChange}
                        margin="dense"
                        name="name"
                        label="Name"
                        type="text"
                        fullWidth
                        value={ss.name}
                    />
                    <TextField
                        disabled
                        onChange={handleChange}
                        margin="dense"
                        name="query"
                        label="Search Query"
                        type="text"
                        fullWidth
                        value={ss.query}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={ss.is_public}
                                inputProps={{
                                    "aria-label": "Public",
                                }}
                                name="is_public"
                                onChange={handleCheckbox}
                                value={ss.is_public}
                            />
                        }
                        label="Public (Default Public)"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSaveSearch} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            <Tabs value={value} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
                <Tab label="Your Searches" />
                {pdata.length > 0 ? <Tab label="All Public Searches" /> : <Tab label="All Public Searches" disabled />}
            </Tabs>
            <TabPanel value={value} index={0}>
                <TablePagination
                    rowsPerPageOptions={[25, 50, 75, 100, 150]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        "aria-label": "previous page",
                    }}
                    nextIconButtonProps={{
                        "aria-label": "next page",
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <Table className={classes.table} size="small">
                    <EnhancedTableHead
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={data.length}
                    />
                    <TableBody>
                        {stableSort(data, getSorting(order, orderBy))
                            .map((row, index) => {
                                return (
                                    <TableRow hover tabIndex={-1} key={row.id}>
                                        <TableCell align="left">
                                            <Button
                                                component={Link}
                                                to={`/search${row.query}`}
                                                className={classes.nameButton}
                                                key={row.id}
                                            >
                                                {" "}
                                                {row.name}
                                            </Button>
                                        </TableCell>
                                        <TableCell align="left">{formatDateString(row.created_on)}</TableCell>
                                        <TableCell align="left">
                                            <div>{row.is_public ? "Public" : "PRIVATE"}</div>
                                        </TableCell>
                                        <TableCell align="left" className={classes.actioncell}>
                                            <CreateIcon
                                                className={classes.button}
                                                onClick={() => editSaveSearchEvent(row)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[25, 50, 75, 100, 150]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        "aria-label": "previous page",
                    }}
                    nextIconButtonProps={{
                        "aria-label": "next page",
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <TablePagination
                    rowsPerPageOptions={[25, 50, 75, 100, 150]}
                    component="div"
                    count={pdata.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        "aria-label": "previous page",
                    }}
                    nextIconButtonProps={{
                        "aria-label": "next page",
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <Table className={classes.table} size="small">
                    <EnhancedTableHead
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={pdata.length}
                    />
                    <TableBody>
                        {stableSort(pdata, getSorting(order, orderBy))
                            .map((row, index) => {
                                return (
                                    <TableRow hover tabIndex={-1} key={row.id}>
                                        <TableCell align="left">
                                            <Button
                                                component={Link}
                                                to={`/search${row.query}`}
                                                className={classes.nameButton}
                                                key={row.id}
                                            >
                                                {" "}
                                                {row.name}
                                            </Button>
                                        </TableCell>
                                        <TableCell align="left">{formatDateString(row.created_on)}</TableCell>
                                        <TableCell align="left">
                                            <div>{row.is_public && "Public"}</div>
                                        </TableCell>
                                        <TableCell align="left">&nbsp;</TableCell>
                                    </TableRow>
                                );
                            })
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[25, 50, 75, 100, 150]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        "aria-label": "previous page",
                    }}
                    nextIconButtonProps={{
                        "aria-label": "next page",
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TabPanel>
        </div>
    );
}
export default SaveSearch;
