import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { authContext } from "../../context/auth";
import MUIDataTable from "mui-datatables";
import { basicDateTimeStamp, truncateString, formatPhoneNumber, stripAllSpaces, isNumber } from "../../Utils";
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Button from "@mui/material/Button";
import StatusIcon from "@mui/icons-material/EditAttributes";
import VerifiedIcon from "@mui/icons-material/Verified";
import ExpertPreviewWrapper from "../../components/wrappers/ExpertPreviewWrapper";
import ExpertPreviewWrapperNext from "../../components/wrappers/ExpertPreviewWrapperNext";
import { Backdrop, CircularProgress } from "@mui/material";
import ChangeStatus from "./SetExpertResponseStatus";
import ContactSearchModal from "./ContactSearchModal";
import AttachToProject from "../AttachToProject";
import GenerateIndividualTokens from "./GenerateIndividualTokens";
import PhoneLogsModal from "./PhoneLogsModal";

// ======
// STYLES
// ======

const linkStyle = css`
    color: #8966ad;
    font-size: 1rem;
    font-weight: bold;
    padding: 0;
    text-align: left;
    line-height: 1.25rem;
`;
const smallLinkStyle = css`
    color: #8966ad;
    font-size: 0.85rem;
    font-weight: normal;
    padding: 0;
    text-align: left;
    line-height: 1.25rem;
    text-transform: Capitalize;
`;
const resetButtonStyle = css`
    border-radius: 4px;
    color: #444;
    font-size: 0.85rem;
    background-color: #f1f1f1;
    border: 0;
    padding: 5px 12px;
    margin-right: 8px;
    margin-bottom: 12px;
`;

const angleButtonStyle = css`
    border-radius: 4px;
    color: #8966ad;
    font-size: 0.85rem;
    font-weight: bold;
    background-color: #e5e0f3;
    border: 0;
    padding: 5px 12px;
    margin-right: 8px;
    margin-bottom: 12px;
`;

const highlightAngleButtonStyle = css`
    border-radius: 4px;
    color: #fff;
    font-size: 0.85rem;
    font-weight: bold;
    background-color: #444;
    border: 0;
    padding: 5px 12px;
    margin-right: 8px;
    margin-bottom: 12px;
`;
const highlightTag = css`
    font-size: 0.9rem;
    font-weight: bold;
    background-color: #fcfcd4;
`;

const backdropStyles = css`
    color: #fff;
    z-index: 1201;
`;

export default function AllAttached(props) {
    // =========
    // CONSTANTS
    // =========

    const { project_id, history, projectTags, projectScreeners, removeExpertsEvent } = props;

    // =====
    // STATE
    // =====

    const { auth } = useContext(authContext);
    const [[stableData, filteredData], setData] = useState([[], []]); // data is going to have original set of data as first element and the filtered data as the second
    const [loading, setLoading] = useState(true);
    const [missingEmails, setMissingEmails] = useState([]);
    const [missingPhones, setMissingPhones] = useState([]);
    const [statusModal, setStatusModal] = useState(false);
    const [phoneModal, setPhoneModal] = useState(false);
    const [tokenModal, setTokenModal] = useState(false);
    const [open, setOpen] = useState(false);
    const [currentExpert, setCurrentExpert] = useState(null);
    const [openPhoneLogs, setOpenPhoneLogs] = useState(false);
    const [currentExpertIdForLogs, setCurrentExpertIdForLogs] = useState(null);
    const [phone, setPhoneNumber] = useState("");
    const [person, setPerson] = useState({
        id: "",
        name: "",
        linkedin_url: "",
        fname: "",
        lname: "",
        company: "",
        contact_search: false,
    });
    const [expert, setExpert] = useState({
        id: "",
        name: "",
        screener_id: "",
        statuses: [],
    });
    const [selectedTags, setSelectedTags] = useState([]);
    const [selected, setSelectedRows] = useState([]);
    // =========
    // PAGE LOAD
    // =========

    async function loadAllAttached() {
        setLoading(true);
        await axios
            .post(`/api/project/now/findAllAttachedList`, { project_id, auth: auth.data })
            .then((response) => {
                if (response?.data?.data?.data) {
                    const result = response?.data?.data?.data || [];
                    const all = result.filter((e) => e.expert_email !== null && e.expert_email !== "");
                    const missing_emails = result.filter((e) => e.expert_email == null || e.expert_email === "");
                    const missing_phones = result.filter((e) => e.number == null || e.number === "");
                    setData([result, all]);
                    setMissingEmails(missing_emails);
                    setMissingPhones(missing_phones);
                    setLoading(false);
                    console.log("FIND ALL: ", all);
                    console.log("MISSING EMAILS: ", missing_emails.length);
                    console.log("MISSING PHONES: ", missing_phones.length);
                }
            })
            .catch((err) => {
                console.log(err, " :: err");
            });
    }

    const missingEmailsList = () => {
        setData([stableData, missingEmails]);
    };

    const missingPhonesList = () => {
        setData([stableData, missingPhones]);
    };

    const closeModal = () => {
        setStatusModal(false);
        setPhoneModal(false);
        setTokenModal(false);
        setPerson({
            id: "",
            name: "",
            fname: "",
            lname: "",
            company: "",
            linkedin_url: "",
            // email_search: false,
            contact_search: false,
        });
        setExpert({
            id: "",
            name: "",
            screener_id: "",
            statuses: [],
        });
    };

    function handlePhoneLogs(expertId, phoneNum) {
        console.log("handlePhoneLogs: ", { expertId, phoneNum });
        setCurrentExpertIdForLogs(expertId);
        setPhoneNumber(phoneNum);
        setOpenPhoneLogs(true);
    }

    const handleExpertToken = (eid, name, sid) => {
        console.log("EXPERT ID: ", { eid, name });
        setTokenModal(true);
        setExpert({
            ...expert,
            id: eid,
            name,
            screener_id: sid ? sid : "",
        });
    };

    const handleExpertStatus = (eid, name, statuses) => {
        console.log("EXPERT ID: ", { eid, name });
        setStatusModal(true);
        setExpert({
            ...expert,
            id: eid,
            name,
            statuses,
        });
    };

    const searchPhoneViaApi = (linkedin_url, fname, lname, name, id, company) => {
        setPerson({
            ...person,
            id,
            fname,
            lname,
            name,
            linkedin_url,
            company,
            contact_search: true,
        });
        if (linkedin_url) {
            setPhoneModal(true);
        } else {
            alert("No linkedin url found for this person. We can't run a search without it.");
        }
    };

    const matchAllItemsInArray = (arr, target) => {
        return target.every((v) => arr.includes(v));
    };

    const handleTagDeselectClick = (tagId) => {
        const newFilteredData = [];
        const selectedTagFilters = [...selectedTags];
        const remainderTagsArray = selectedTagFilters.filter((id) => id !== tagId);
        console.log("DESELECTED TAG ID: ", tagId);
        console.log("REMAINDER TAGS ARRAY: ", remainderTagsArray);
        setSelectedTags(remainderTagsArray);
        for (const expert of stableData) {
            const { tagFilterData } = expert;
            // Convert tagFilterData object to array of tag ids
            const expertTagIdsArray = Object.entries(tagFilterData).map(([key, value]) => {
                return parseInt(key, 10);
            });
            // Check to see if all selected tags are in the expert's tag array
            if (matchAllItemsInArray(expertTagIdsArray, remainderTagsArray)) {
                newFilteredData.push(expert);
            }
        }
        console.log("De-Selection Filtered Results: ", newFilteredData);
        setData([[...stableData], newFilteredData]);
    };

    const handleTagClick = (tagId) => {
        const newFilteredData = [];
        const selectedTagFilters = [...selectedTags];
        // Add tagId to global selected tags array
        selectedTagFilters.push(tagId);
        console.log("SELECTED TAG FILTERS: ", selectedTagFilters);
        for (const expert of stableData) {
            const { tagFilterData } = expert;
            // Convert tagFilterData object to array of tag ids
            const expertTagIdsArray = Object.entries(tagFilterData).map(([key, value]) => {
                return parseInt(key, 10);
            });
            // Check to see if all selected tags are in the expert's tag array
            if (matchAllItemsInArray(expertTagIdsArray, selectedTagFilters)) {
                newFilteredData.push(expert);
            }
        }
        setSelectedTags(selectedTagFilters);
        console.log("Select Tag Filtered Results: ", newFilteredData);
        setData([[...stableData], newFilteredData]);
    };

    const handleResetTags = () => {
        setSelectedTags([]);
        setData([[...stableData], [...stableData]]);
    };

    const onRowSelectionChange = (currentSelect, allSelected) => {
        console.log("SELECTED INDEXES: ", allSelected);
        const result = allSelected.map((item) => {
            return filteredData?.at(item.dataIndex);
        });
        const selectedExperts = result.map((item) => {
            // console.log("SELECTED ITEM: ", item);
            const expert = {
                id: item.expert_id,
                fname: item.fname,
                lname: item.lname,
                prefix: item.prefix,
                suffix: item.suffix,
                mail: item.expert_email,
            };
            return expert;
        });
        console.log("WHICH ROWS SELECTED? ", selectedExperts);
        setSelectedRows(selectedExperts);
    };

    useEffect(() => {
        loadAllAttached(project_id);
    }, []);

    // ============
    // HANDLE FUNCS
    // ============

    const openExpertPreview = (expertId) => {
        setCurrentExpert(expertId);
        setOpen(true);
    };

    const navigateToExpert = (expertId) => {
        history.push(`/expert/view/${expertId}`);
    };
    const reloadPageTagSearch = (tagId) => {
        history.push(`/search?added_by=0&and_tags=${tagId}`);
        window.location.reload();
    };

    // =====
    // TABLE
    // =====

    const options = {
        print: false,
        rowsPerPage: 100,
        viewColumns: false,
        download: true,
        onRowSelectionChange,
        // selectableRows: "none",
    };

    const columns = [
        {
            name: "has_signed_terms",
            label: "In",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { has_signed_terms, disclosures } = filteredData?.[dataIndex];

                    return (
                        <div>
                            {has_signed_terms && disclosures ? (
                                <VerifiedIcon style={{ color: "#523197" }} />
                            ) : (
                                <strong style={{ color: "#ccc" }}>***</strong>
                            )}
                        </div>
                    );
                },
            },
        },
        {
            name: "expert_name",
            label: "Name",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { fname, lname, suffix, expert_id, empHistData, expertStatuses, headline } =
                        filteredData?.[dataIndex];

                    return (
                        <div>
                            <Button css={linkStyle} onClick={() => openExpertPreview(expert_id)}>
                                {fname} {lname}
                                {suffix ? `, ${suffix}` : ""}
                            </Button>
                            {empHistData && empHistData.length > 0 ? (
                                <div>{`${truncateString(empHistData[0].position, 25)} at ${truncateString(
                                    empHistData[0].company,
                                    25
                                )}`}</div>
                            ) : (
                                <div>{truncateString(headline, 25)}</div>
                            )}
                            {expertStatuses && (
                                <div style={{ marginTop: "8px" }}>
                                    {Object.entries(expertStatuses).map(([key, value], i) => (
                                        <span key={`${key}_${i}`} style={{ color: "green", fontSize: ".75rem" }}>
                                            {[
                                                "3",
                                                "4",
                                                "5",
                                                "6",
                                                "7",
                                                "8",
                                                "12",
                                                "13",
                                                "17",
                                                "18",
                                                "19",
                                                "20",
                                                "28",
                                                "32",
                                            ].includes(key) && value + " > "}
                                        </span>
                                    ))}
                                </div>
                            )}
                        </div>
                    );
                },
            },
        },
        {
            name: "expert_email",
            label: "Email",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    const {
                        number,
                        expert_email,
                        expert_id,
                        linkedin_url,
                        fname,
                        lname,
                        expert_name,
                        empHistData,
                        person_id,
                        phoneLogsPresent,
                        expertStatuses,
                    } = filteredData?.[dataIndex];
                    return (
                        <>
                            {!Object.entries(expertStatuses).find(([key, value], i) => ["32"].includes(key)) ? (
                                <>
                                    {expert_email ? (
                                        <span style={{ color: "#523197" }}>{expert_email}</span>
                                    ) : (
                                        <>
                                            {linkedin_url ? (
                                                <Button
                                                    onClick={() =>
                                                        searchPhoneViaApi(
                                                            linkedin_url,
                                                            fname,
                                                            lname,
                                                            expert_name,
                                                            person_id,
                                                            empHistData && empHistData.length > 0
                                                                ? empHistData[0].company
                                                                : ""
                                                        )
                                                    }
                                                >
                                                    Search Email
                                                </Button>
                                            ) : (
                                                <span style={{ color: "#999" }}>-- NA --</span>
                                            )}
                                        </>
                                    )}
                                    <br />
                                    <br />
                                    {number ? (
                                        <div>
                                            {isNumber(number) && stripAllSpaces(number).length > 9 ? (
                                                <a style={{ display: "inline-block" }} href={`tel:${number}`}>
                                                    {formatPhoneNumber(number)}
                                                </a>
                                            ) : (
                                                `Invalid Number: ${number}`
                                            )}
                                            <Button
                                                style={{
                                                    display: "block",
                                                    marginTop: "10px",
                                                    padding: "0",
                                                    fontSize: ".75rem",
                                                }}
                                                onClick={() => handlePhoneLogs(expert_id, number)}
                                            >
                                                {phoneLogsPresent ? "View Call Log" : "Log Status"}
                                            </Button>
                                        </div>
                                    ) : (
                                        <>
                                            {linkedin_url ? (
                                                <Button
                                                    onClick={() =>
                                                        searchPhoneViaApi(
                                                            linkedin_url,
                                                            fname,
                                                            lname,
                                                            expert_name,
                                                            person_id,
                                                            empHistData && empHistData.length > 0
                                                                ? empHistData[0].company
                                                                : ""
                                                        )
                                                    }
                                                >
                                                    Search Phone
                                                </Button>
                                            ) : (
                                                <span style={{ color: "#999" }}>-- NA --</span>
                                            )}
                                        </>
                                    )}
                                </>
                            ) : (
                                <strong style={{ color: "red" }}>*** DO NOT RECRUIT ***</strong>
                            )}
                        </>
                    );
                },
            },
        },
        {
            name: "attached_on",
            label: "Attached",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { attached_on } = filteredData?.[dataIndex];

                    return <div>{basicDateTimeStamp(attached_on)}</div>;
                },
            },
        },
        {
            name: "attaching_rm_name",
            label: "By",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { attaching_rm_name } = filteredData?.[dataIndex];

                    return <div>{attaching_rm_name}</div>;
                },
            },
        },
        {
            name: "tags",
            label: "Tags",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    const { tags, surveys, fname, lname, suffix, expert_id, expertStatuses } =
                        filteredData?.[dataIndex];
                    const fullName = `${fname} ${lname}${suffix ? `, ${suffix}` : ""}`;
                    return (
                        <>
                            {!Object.entries(expertStatuses).find(([key, value], i) => ["32"].includes(key)) && (
                                <>
                                    <div style={{ marginTop: "12px" }}>
                                        <strong>Help screen on behalf of {fname}:</strong>
                                        {Object.entries(surveys).map(([key, value], i) => (
                                            <p key={`${key}_${i}`} style={{ margin: 0 }}>
                                                {
                                                    <Button
                                                        css={smallLinkStyle}
                                                        onClick={() =>
                                                            handleExpertToken(expert_id, fullName, value.survey_id)
                                                        }
                                                    >
                                                        {value.survey_name}
                                                    </Button>
                                                }
                                            </p>
                                        ))}
                                        {Object.entries(surveys).length === 0 && (
                                            <div>
                                                <Button
                                                    css={smallLinkStyle}
                                                    onClick={() => handleExpertToken(expert_id, fullName, null)}
                                                >
                                                    Select a Screener
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                    <br />
                                </>
                            )}
                            <div>
                                {tags.length > 0 && (
                                    <>
                                        {tags.map((item, index) => {
                                            return (
                                                <span key={index}>
                                                    <em
                                                        css={selectedTags.includes(item.tag_id) && highlightTag}
                                                    >{`${item.tag_name}`}</em>
                                                    <span>{index < tags.length - 1 && ", "}</span>
                                                </span>
                                            );
                                        })}
                                    </>
                                )}
                            </div>
                        </>
                    );
                },
            },
        },
        {
            name: "",
            label: "",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    const { expert_id, fname, lname, suffix, expertStatuses } = filteredData?.[dataIndex];
                    const fullName = `${fname} ${lname}${suffix ? `, ${suffix}` : ""}`;
                    return (
                        <>
                            <div>
                                <StatusIcon
                                    style={{ color: "#666", cursor: "pointer" }}
                                    onClick={() => handleExpertStatus(expert_id, fullName, expertStatuses)}
                                />
                            </div>
                        </>
                    );
                },
            },
        },
    ];

    if (loading) {
        return (
            <Backdrop
                css={backdropStyles}
                open={loading}
                onClick={() => {
                    setLoading(false);
                }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    } else {
        return (
            <>
                <h2 style={{ fontSize: "1.25rem" }}>Everyone: {filteredData?.length}</h2>
                <p>
                    <strong>PLEASE NOTE:</strong> The discrepancy between the total number of experts attached to this
                    project and this table count is due to filtering of all unsubscribes and missing emails.
                </p>
                <Button onClick={() => loadAllAttached(project_id)}>Reset</Button>
                <Button onClick={missingEmailsList}>Missing Emails</Button>
                <Button onClick={missingPhonesList}>Missing Phones</Button>
                {/* <AttachToProject
                    selected={filteredData.map((s) => s.expert_id)}
                    btnText="Add All To Another Project"
                    setFullWidth={false}
                    user={auth.data}
                    disabled={false}
                /> */}
                <br />
                <br />
                {selected.length > 0 && (
                    <div
                        style={{
                            zIndex: "999",
                            position: "fixed",
                            bottom: "0",
                            height: "10vh",
                            width: "100%",
                            backgroundColor: "#FAFAFA",
                            left: "72px",
                            borderTop: "1px solid #ccc",
                            padding: "30px 0 0 60px",
                        }}
                    >
                        <Button onClick={() => removeExpertsEvent(selected, loadAllAttached)}>Remove</Button>
                        <AttachToProject
                            selected={selected.map((s) => s.id)}
                            btnText="Add To"
                            setFullWidth={false}
                            user={auth.data}
                            disabled={false}
                        />
                    </div>
                )}
                <div>
                    <strong>TAGS (ANGLES):</strong>
                    <br />
                    {projectTags.length > 0 && (
                        <button css={resetButtonStyle} onClick={handleResetTags}>
                            Reset Tags
                        </button>
                    )}
                    {projectTags.map((t, i) => {
                        return (
                            <span key={`${t.id}_${i}`}>
                                {selectedTags.includes(t.id) ? (
                                    <button
                                        css={highlightAngleButtonStyle}
                                        onClick={() => handleTagDeselectClick(t.id)}
                                    >
                                        {t.name}
                                    </button>
                                ) : (
                                    <button css={angleButtonStyle} onClick={() => handleTagClick(t.id)}>
                                        {t.name}
                                    </button>
                                )}
                            </span>
                        );
                    })}
                </div>
                <GenerateIndividualTokens
                    openDialog={tokenModal}
                    closeEvent={closeModal}
                    project_id={project_id}
                    expert={expert}
                    data={projectScreeners}
                />
                <ChangeStatus
                    reloadEvent={loadAllAttached}
                    user={auth}
                    openDialog={statusModal}
                    expert={expert}
                    project_id={project_id}
                    closeEvent={closeModal}
                />
                {person.contact_search && (
                    <ContactSearchModal
                        project_id={project_id}
                        person={person}
                        modal={phoneModal}
                        reloadEvent={loadAllAttached}
                        closeEvent={closeModal}
                    />
                )}
                <MUIDataTable data={filteredData} options={options} columns={columns} />
                {open && (
                    <ExpertPreviewWrapperNext
                        open={open}
                        setOpen={setOpen}
                        expert_id={currentExpert}
                        project_id={project_id}
                    />
                )}
                {openPhoneLogs && (
                    <PhoneLogsModal
                        openPhoneLogs={openPhoneLogs}
                        setOpenPhoneLogs={setOpenPhoneLogs}
                        userInfo={auth.data}
                        project_id={project_id}
                        expert_id={currentExpertIdForLogs}
                        number={phone}
                        reloadEvent={loadAllAttached}
                    />
                )}
            </>
        );
    }
}
