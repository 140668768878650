import React, { useState } from "react";
import { Dialog, DialogTitle, IconButton, DialogContent, DialogActions, Button, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Checkbox } from "@mui/material";
import axios from "axios";
let bulkTags = [];

export default function TagManager(props) {
    const { data, modal, closeEvent, reloadEvent, tags } = props;

    const [addNowBtn, setAddNowBtn] = useState(true);
    // Helpers

    function closeModalEvent() {
        closeEvent(false);
    }

    function handleTagCheckbox(event) {
        const { target } = event;
        const { value } = target;
        if (event.target.checked) {
            console.log("CHECKED: ", { tag: value, bulkTags });
            bulkTags.push(value);
            setAddNowBtn(false);
        } else {
            const newArr = bulkTags.filter(function (item) {
                return item !== value;
            });
            bulkTags = newArr;
            bulkTags.length < 1 ? setAddNowBtn(true) : setAddNowBtn(false);
        }
    }

    function bulkRemoveTagsEvent() {
        const arr = [];
        for (const i in data) {
            if (data.length > 0) arr.push(data[i].id);
        }
        const dataObj = { tags: bulkTags, people: arr };
        const url = "/api/expert/removetags";
        console.log("POSTING: ", dataObj);
        // return;
        axios
            .post(url, dataObj, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    console.log("/api/expert/removetags POST RESULT: ", result);
                    closeModalEvent();
                    // Reload the table
                    reloadEvent();
                } else {
                    console.log(`Good POST, but something went wrong: ${JSON.stringify(tags)}`);
                }
            })
            .catch((result) => {
                console.log("API not done yet, 404 Error:", result);
                // TODO: Close Modal...
            });
    }

    function bulkAddTagsEvent() {
        const arr = [];
        for (const i in data) {
            if (data.length > 0) arr.push(data[i].id);
        }
        const dataObj = { tags: bulkTags, people: arr };
        console.log(dataObj);
        // return;
        const url = "/api/expert/addtags";
        axios
            .post(url, dataObj, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    console.log("/api/expert/addtags POST RESULT: ", result);
                    closeModalEvent();
                    // Reload the table
                    reloadEvent();
                } else {
                    console.log(`Good POST, but something went wrong: ${JSON.stringify(tags)}`);
                }
            })
            .catch((result) => {
                console.log("API not done yet, 404 Error:", result);
            });
    }

    if (!data) {
        return <div></div>;
    } else {
        return (
            <Dialog open={modal} onClose={closeModalEvent} fullWidth maxWidth="sm" aria-labelledby="form-dialog-title">
                <DialogTitle>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={closeModalEvent}
                        sx={{ float: "right" }}
                        aria-label="close"
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                    Add/Remove Tags
                </DialogTitle>
                <DialogContent>
                    {tags.length < 1 && (
                        <Typography sx={{ color: "red", fontSize: ".75rem", marginTop: "0" }}>
                            This project has no tags associated with it.
                        </Typography>
                    )}
                    {tags.map(function (t, index) {
                        return (
                            <p key={t.id}>
                                <Checkbox name="tag" onChange={handleTagCheckbox} value={t.id} /> {t.name}
                            </p>
                        );
                    })}
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeModalEvent}>Cancel</Button>
                    <Button disabled={addNowBtn} onClick={bulkRemoveTagsEvent} color="primary">
                        Remove
                    </Button>
                    <Button disabled={addNowBtn} onClick={bulkAddTagsEvent} color="primary">
                        Add Now
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
