import React, { useEffect, useMemo, useCallback } from "react";

// MUI - labs
import { DesktopDateTimePicker as DateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// MUI - general
import { TextField, MenuItem, Typography, Autocomplete } from "@mui/material";

// MUI - accordion
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";

// MUI - form stuff
import { FormControl, FormControlLabel, RadioGroup, Radio } from "@mui/material";

// MUI - icons
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";

// MUI - styles
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

// custom components
import SchedulingCredit from "./SchedulingCredit";
import SchedulingReason from "./SchedulingReason";

// utils / data
import { generateDropdown, createDateTimeStringForDTPicker } from "../utils/utils";
import { callTypes, cancelReasons } from "../utils/data";

const SchedulingOptions = (props) => {
    // PROPS
    const {
        schedulingDialogData: { expertData, status },
        timezones,
        error,
        callData,
        setCallData,
        projectData,
        isUserAuthorized,
        initialLoad,
    } = props;

    // CONSTANTS
    const DROPDOWN = generateDropdown(projectData);

    const DEFAULT_REASON = JSON.stringify({ value: 8, label: "Scheduling mistake" });

    // ========================
    // FILE SPECIFIC UTIL FUNCS
    // ========================

    // eslint-disable-next-line valid-jsdoc
    /**
     * this is a function that is called in useEffect that takes the expert and call data which
     * comes from the tab panel.  when you click edit / delete, the call data already existed in the
     * DB and we're just updating the callData state to reflect that stuff that was there so we can edit
     * or delete stuff
     */
    const buildBackCallData = (expertAndCallData) => {
        const {
            timezoneId,
            callType,
            scheduledDateTime,
            schedulingCreditTo,
            options,
            reason: reasonLabel,
        } = expertAndCallData?.editOrDeleteSpecificData;

        // find all the objects we need to populate edit box
        const timezoneObj = timezones.find((timezone) => timezone.value === timezoneId);
        const callTypeObj = callTypes.find((type) => type.value === callType);
        const timeObjForPicker = new Date(createDateTimeStringForDTPicker(scheduledDateTime, timezoneObj.offset));
        const reasonObj = cancelReasons.find((reason) => reason.label === reasonLabel);

        const schedulingCreditObj = DROPDOWN.find((user) => {
            const creditTo = schedulingCreditTo ? schedulingCreditTo : 0;
            return user.value === creditTo;
        });

        // return callData object and pass to state
        return {
            ...callData,
            editReason: reasonObj ? JSON.stringify(reasonObj) : DEFAULT_REASON,
            dateAndTime: timeObjForPicker,
            callType: callTypeObj,
            timezone: JSON.stringify(timezoneObj),
            schedulingCredit: JSON.stringify(schedulingCreditObj),
            options,
        };
    };

    // =========
    // PAGE LOAD
    // =========

    useEffect(() => {
        if (status === "editing" && initialLoad) {
            const prevCallData = buildBackCallData(expertData);
            setCallData(prevCallData);
        }
    }, []);

    // ============
    // HANDLE FUNCS
    // ============

    const handleDateChange = (event) => {
        setCallData({ ...callData, dateAndTime: event });
    };

    const handleCallTypeChange = (event) => {
        setCallData({ ...callData, callType: event.target.value });
    };

    const handleTimeZone = (event) => {
        setCallData({ ...callData, timezone: event.target.value });
    };

    // handle func for additional options
    const handleAddtlOptions = (event) => {
        const parseBool = (boolStr) => boolStr.toLowerCase() == "true".toLowerCase();

        const { name, value } = event.target;

        setCallData({ ...callData, options: { ...callData.options, [name]: parseBool(value) } });
    };

    // ======
    // RENDER
    // ======

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                    fullWidth
                    format="MM/dd/yyyy @ h:mma"
                    variant="inline"
                    label="Select Date and Time"
                    value={callData.dateAndTime}
                    onChange={handleDateChange}
                    renderInput={(params) => (
                        <TextField
                            margin="dense"
                            sx={{ width: "100%", marginBottom: "1rem", backgroundColor: "#fff" }}
                            {...params}
                        />
                    )}
                />
            </LocalizationProvider>

            {/* TODO: change background color for helper txt */}
            <TextField
                sx={{
                    width: "100%",
                    marginBottom: "1rem",
                    backgroundColor: "#fff",
                }}
                autoComplete="type"
                fullWidth
                select
                error={error.error && error.element === "callType"}
                helperText={error.element === "callType" ? error.msg : ""}
                label="Select Call Type"
                defaultValue=""
                onChange={handleCallTypeChange}
                value={callData.callType}
                margin="normal"
            >
                {callTypes.map((callType) => (
                    <MenuItem key={callType.value} value={callType}>
                        {callType.label}
                    </MenuItem>
                ))}
            </TextField>

            {/* TODO: change background color for helper txt */}
            <TextField
                sx={{ width: "100%", marginBottom: "1rem", backgroundColor: "#fff" }}
                autoComplete="type"
                fullWidth
                select
                error={error.error && error.element === "timezone"}
                helperText={error.element === "timezone" ? error.msg : ""}
                label="Select Time Zones"
                defaultValue=""
                onChange={handleTimeZone}
                value={callData.timezone}
                margin="normal"
            >
                {timezones.map((timezone) => (
                    <MenuItem key={timezone.value} value={JSON.stringify(timezone)}>
                        {timezone.label}
                    </MenuItem>
                ))}
            </TextField>

            <SchedulingCredit
                dropdown={DROPDOWN}
                expertId={expertData.expert_id}
                projectId={projectData.id}
                callData={callData}
                setCallData={setCallData}
                isUserAuthorized={isUserAuthorized}
                status={status}
            />

            {status === "editing" && <SchedulingReason callData={callData} setCallData={setCallData} />}

            <Typography align="center" sx={{ marginTop: "2rem", fontSize: "1.25rem", fontWeight: "700" }}>
                Additional Options
            </Typography>
            <Accordion sx={{ margin: ".5rem 0" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography>Does this call need a moderator?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <FormControl>
                        <RadioGroup value={callData.options.moderator} name="moderator" onChange={handleAddtlOptions}>
                            <FormControlLabel value="true" control={<Radio />} label="Yes" />
                            <FormControlLabel value="false" control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ margin: ".5rem 0" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography>Does this call need to be recorded?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <FormControl>
                        <RadioGroup name="record" onChange={handleAddtlOptions} value={callData.options.record}>
                            <FormControlLabel value="true" control={<Radio />} label="Yes" />
                            <FormControlLabel value="false" control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ margin: ".5rem 0" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography>Does this call require Compliance?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <FormControl>
                        <RadioGroup name="compliance" onChange={handleAddtlOptions} value={callData.options.compliance}>
                            <FormControlLabel value="true" control={<Radio />} label="Yes" />
                            <FormControlLabel value="false" control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ margin: ".5rem 0" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography>Does this call need a translator?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <FormControl>
                        <RadioGroup name="translator" onChange={handleAddtlOptions} value={callData.options.translator}>
                            <FormControlLabel value="true" control={<Radio />} label="Yes" />
                            <FormControlLabel value="false" control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default SchedulingOptions;
