import React, { useState } from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import { EditorState, Modifier } from "draft-js";
import MobileScreenShareIcon from "@mui/icons-material/MobileScreenShare";
import SurveyIcon from "@mui/icons-material/ListAlt";
import { Box } from "@mui/material";

function CustomScreenerLink(props) {
    const { match, history } = props;

    function addScreener() {
        const { editorState, onChange } = props;
        const contentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            "{{SCREENER_LINK}}",
            editorState.getCurrentInlineStyle()
        );
        onChange(EditorState.push(editorState, contentState, "insert-characters"));
    }

    return (
        <Box
            onClick={addScreener}
            sx={{
                border: "1px solid #F1F1F1",
                padding: "5px",
                minWidth: "25px",
                height: "20px",
                borderRadius: "2px",
                margin: "0 4px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                background: "white",
                textTransform: "capitalize",
            }}
            title="Screener Link"
        >
            <SurveyIcon />
        </Box>
    );
}

CustomScreenerLink.propTypes = {
    match: PropTypes.object,
    onChange: PropTypes.func,
    editorState: PropTypes.object,
};
CustomScreenerLink.defaultProps = {
    match: null,
};

export default CustomScreenerLink;
