import React from "react";

// data
import { entityTypes } from "../utils/data";

// MUI - general
import { Paper } from "@mui/material";

// MUI - styles
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const SchedulingPins = ({ pinData }) => {
    const heavyAccentText = css`
        font-weight: 700;
        font-size: 1rem;
        color: #6d42ed;
    `;

    const mediumText = css`
        font-weight: 500;
    `;

    const lineItemGap = css`
        margin-bottom: 0.5rem;
    `;

    const largeGap = css`
        margin-bottom: 1.5rem;
    `;

    function renderPinInfo(pinData) {
        return (
            <>
                <Paper sx={{ border: "3px solid #8c5429", padding: "1rem", backgroundColor: "#f7dfc1" }}>
                    <div css={lineItemGap}>
                        <span css={mediumText}>{`East Coast Conference DIAL-IN: `}</span>
                        <span css={heavyAccentText}>{`(516) 447-2943`}</span>
                    </div>
                    <div css={lineItemGap}>
                        <span css={mediumText}>{`West Coast Conference DIAL-IN :`}</span>
                        <span css={heavyAccentText}>{`(714) 360-0768`}</span>
                    </div>
                    <div css={lineItemGap}>
                        <span css={mediumText}>{`Great Britain (UK) Conference DIAL-IN :`}</span>
                        <span css={heavyAccentText}>{`+44 7723 364310`}</span>
                    </div>
                    <div css={lineItemGap}>
                        <span css={mediumText}>{`Toronto, ON Conference DIAL-IN :`}</span>
                        <span css={heavyAccentText}>{`+1 647 694 6230`}</span>
                    </div>
                    <div css={lineItemGap}>
                        <span css={mediumText}>{`Sweden (SE) Conference DIAL-IN :`}</span>
                        <span css={heavyAccentText}>{`+46 10 551 73 26 (National)`}</span>
                    </div>
                    <div css={largeGap}>
                        <span css={mediumText}>{`Israel Conference DIAL-IN :`}</span>
                        <span css={heavyAccentText}>{`+972 76 599 4604`}</span>
                    </div>
                    {pinData.map((pinObj) => {
                        const { id, pin, entity_type } = pinObj;
                        return (
                            <div css={lineItemGap} key={id}>
                                <span css={mediumText}>{`${
                                    entityTypes.find((entity) => entity.value === entity_type).label
                                } PIN#: `}</span>
                                <span css={heavyAccentText}>{`${pin}`}</span>
                            </div>
                        );
                    })}
                </Paper>
            </>
        );
    }
    return <div>{renderPinInfo(pinData)}</div>;
};

export default SchedulingPins;
