import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { authContext } from "../../context/auth";
import MUIDataTable from "mui-datatables";
// import { Link } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import VerifiedIcon from "@mui/icons-material/Verified";
// import Delete from "@mui/icons-material/Delete";
// import InsertLinkIcon from "@mui/icons-material/InsertLink";
// MUIDataTable requirements
import { basicDateTimeStamp, truncateString } from "../../Utils";
// MUI - style imports
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import GenerateIndividualTokens from "./GenerateIndividualTokens";
import SendOnboardScreenerNow from "./SendOnboardScreenerNow";
import SendBulkOutreachNow from "./SendBulkOutreachNow";
import TagManager from "./TagManager";
import SnippetDialog from "../SnippetDialog";
// import AttachToProject from "../AttachToProject";
import ExpertPreviewWrapperNext from "../../components/wrappers/ExpertPreviewWrapperNext";

// ======
// STYLES
// ======

const linkStyle = css`
    color: #8966ad;
    font-size: 1rem;
    font-weight: bold;
    padding: 0;
    text-align: left;
    line-height: 1.25rem;
`;

const smallLinkStyle = css`
    color: #8966ad;
    font-size: 0.85rem;
    font-weight: normal;
    padding: 0;
    text-align: left;
    line-height: 1.25rem;
    text-transform: Capitalize;
`;

const resetButtonStyle = css`
    border-radius: 4px;
    color: #444;
    font-size: 0.85rem;
    background-color: #f1f1f1;
    border: 0;
    padding: 5px 12px;
    margin-right: 8px;
    margin-bottom: 12px;
`;

const angleButtonStyle = css`
    border-radius: 4px;
    color: #8966ad;
    font-size: 0.85rem;
    font-weight: bold;
    background-color: #e5e0f3;
    border: 0;
    padding: 5px 12px;
    margin-right: 8px;
    margin-bottom: 12px;
`;

const highlightAngleButtonStyle = css`
    border-radius: 4px;
    color: #fff;
    font-size: 0.85rem;
    font-weight: bold;
    background-color: #444;
    border: 0;
    padding: 5px 12px;
    margin-right: 8px;
    margin-bottom: 12px;
`;
const highlightTag = css`
    font-size: 0.9rem;
    font-weight: bold;
    background-color: #fcfcd4;
`;

const backdropStyle = css`
    z-index: 9999;
    color: "#fff";
`;

export default function AddedLeads(props) {
    // =========
    // CONSTANTS
    // =========

    const { project_id, projectData, projectTags, projectScreeners, generateSnippetEvent, history } = props;

    // =====
    // STATE
    // =====
    const { auth } = useContext(authContext);
    const [[stableData, filteredData], setData] = useState([[], []]); // data is going to have original set of data as first element and the filtered data as the second
    const [selected, setSelectedRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [currentExpertId, setCurrentExpertId] = useState(null);
    const [onboardScreenerModal, setSendOnboardScreenerModal] = useState(false);
    const [screenerModal, setSendScreenerOnlyModal] = useState(false);
    const [outreach, setOutreachModal] = useState(false);
    const [tagsModal, setTagsModal] = useState(false);
    const [expertData, setExpertData] = useState([]);
    const [snippet, setSnippetModal] = useState(false);
    const [tokenModal, setTokenModal] = useState(false);
    const [expert, setExpert] = useState({
        id: "",
        name: "",
        screener_id: "",
    });
    const [selectedTags, setSelectedTags] = useState([]);
    // =========
    // PAGE LOAD
    // =========

    async function loadAddedLeads() {
        console.log("====== reloading data ======");
        await axios
            .get(`/api/project/now/findOnboardAndScreenExpertList/${project_id}`, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((response) => {
                if (response?.data?.data?.data) {
                    const result = response?.data?.data?.data || [];
                    setData([result, result]);
                    setLoading(false);
                    console.log("DID THE ONBOARD/SCREEN TAB LOAD DATA?? ", result);
                }
            })
            .catch((err) => {
                console.log(err, " :: err");
            });
    }

    useEffect(() => {
        loadAddedLeads();
    }, []);

    // ============
    // HANDLE FUNCS
    // ============
    const showExpertPreview = (expertId) => {
        // setExpertPreviewData({ display: true, expertId });
        setCurrentExpertId(expertId);
        setOpen(true);
    };
    const navigateToExpert = (expertId) => {
        history.push(`/expert/view/${expertId}`);
    };
    const reloadPageTagSearch = (tagId) => {
        history.push(`/search?added_by=0&and_tags=${tagId}`);
        window.location.reload();
    };

    const matchAllItemsInArray = (arr, target) => {
        return target.every((v) => arr.includes(v));
    };

    const handleTagDeselectClick = (tagId) => {
        const newFilteredData = [];
        const selectedTagFilters = [...selectedTags];
        const remainderTagsArray = selectedTagFilters.filter((id) => id !== tagId);
        console.log("DESELECTED TAG ID: ", tagId);
        console.log("REMAINDER TAGS ARRAY: ", remainderTagsArray);
        setSelectedTags(remainderTagsArray);
        for (const expert of stableData) {
            const { tagFilterData } = expert;
            // Convert tagFilterData object to array of tag ids
            const expertTagIdsArray = Object.entries(tagFilterData).map(([key, value]) => {
                return parseInt(key, 10);
            });
            // Check to see if all selected tags are in the expert's tag array
            if (matchAllItemsInArray(expertTagIdsArray, remainderTagsArray)) {
                newFilteredData.push(expert);
            }
        }
        console.log("De-Selection Filtered Results: ", newFilteredData);
        setData([[...stableData], newFilteredData]);
    };

    const handleTagClick = (tagId) => {
        const newFilteredData = [];
        const selectedTagFilters = [...selectedTags];
        // Add tagId to global selected tags array
        selectedTagFilters.push(tagId);
        console.log("SELECTED TAG FILTERS: ", selectedTagFilters);
        for (const expert of stableData) {
            const { tagFilterData } = expert;
            // Convert tagFilterData object to array of tag ids
            const expertTagIdsArray = Object.entries(tagFilterData).map(([key, value]) => {
                return parseInt(key, 10);
            });
            // Check to see if all selected tags are in the expert's tag array
            if (matchAllItemsInArray(expertTagIdsArray, selectedTagFilters)) {
                newFilteredData.push(expert);
            }
        }
        setSelectedTags(selectedTagFilters);
        console.log("Select Tag Filtered Results: ", newFilteredData);
        setData([[...stableData], newFilteredData]);
    };

    const handleResetTags = () => {
        setSelectedTags([]);
        setData([[...stableData], [...stableData]]);
    };

    const onRowSelectionChange = (currentSelect, allSelected) => {
        console.log("SELECTED INDEXES: ", allSelected);
        const result = allSelected.map((item) => {
            return filteredData?.at(item.dataIndex);
        });
        const selectedExperts = result.map((item) => {
            // console.log("SELECTED ITEM: ", item);
            const expert = {
                id: item.expert_id,
                fname: item.fname,
                lname: item.lname,
                prefix: item.prefix,
                suffix: item.suffix,
                mail: item.expert_email,
            };
            return expert;
        });
        console.log("WHICH ROWS SELECTED? ", selectedExperts);
        setSelectedRows(selectedExperts);
    };

    const handleOnboardScreenerEvent = () => {
        console.log("SENDING EMAIL TO: ", selected);
        if (selected.length > 0) {
            setSendOnboardScreenerModal(true);
        } else {
            alert("Please select at least one expert to send the screener to.");
        }
    };
    const handleScreenerOnlyEvent = () => {
        console.log("SENDING EMAIL TO: ", selected);
        if (selected.length > 0) {
            setSendScreenerOnlyModal(true);
        } else {
            alert("Please select at least one expert to send the screener to.");
        }
    };
    const closeModal = () => {
        setSendOnboardScreenerModal(false);
        setSendScreenerOnlyModal(false);
        setSnippetModal(false);
        setTokenModal(false);
        setExpert({
            id: "",
            name: "",
            screener_id: "",
        });
    };

    const handleExpertSnippet = (response) => {
        console.log("RESPONSE FROM API CALL: ", response);
        setExpertData(response);
        setSnippetModal(true);
    };

    const handleExpertToken = (eid, name, sid) => {
        console.log("EXPERT ID: ", { eid, name });
        setTokenModal(true);
        setExpert({
            ...expert,
            id: eid,
            name,
            screener_id: sid ? sid : "",
        });
    };

    // =====
    // TABLE
    // =====
    const options = {
        print: false,
        rowsPerPage: 100,
        viewColumns: false,
        download: true,
        onRowSelectionChange,
    };

    const dataColumns = [
        {
            name: "has_signed_terms",
            label: "In",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { has_signed_terms, disclosures } = filteredData?.[dataIndex];

                    return (
                        <div>
                            {has_signed_terms && disclosures ? (
                                <VerifiedIcon style={{ color: "#523197" }} />
                            ) : (
                                <strong style={{ color: "#ccc" }}>***</strong>
                            )}
                        </div>
                    );
                },
            },
        },
        {
            name: "expert_name",
            label: "Name",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { fname, lname, suffix, expert_id, empHistData, headline, has_signed_terms, disclosures } =
                        filteredData?.[dataIndex];
                    const fullName = `${fname} ${lname}${suffix ? `, ${suffix}` : ""}`;
                    return (
                        <div>
                            <Button css={linkStyle} onClick={() => showExpertPreview(expert_id)}>
                                {fullName}
                            </Button>
                            {empHistData && empHistData.length > 0 ? (
                                <div>{`${truncateString(empHistData[0].position, 25)} at ${truncateString(
                                    empHistData[0].company,
                                    25
                                )}`}</div>
                            ) : (
                                <div>{truncateString(headline, 25)}</div>
                            )}
                        </div>
                    );
                },
            },
        },
        {
            name: "calculated_consultation_rate",
            label: "Rate",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { calculated_consultation_rate } = filteredData?.[dataIndex];

                    return (
                        <div>
                            {parseInt(calculated_consultation_rate, 10) > 0
                                ? `$${calculated_consultation_rate}`
                                : "----"}
                        </div>
                    );
                },
            },
        },
        {
            name: "attached_on",
            label: "Attached",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { attached_on } = filteredData?.[dataIndex];

                    return <div>{basicDateTimeStamp(attached_on)}</div>;
                },
            },
        },
        {
            name: "attaching_rm_name",
            label: "By",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { attaching_rm_name } = filteredData?.[dataIndex];

                    return <div>{attaching_rm_name}</div>;
                },
            },
        },
        {
            name: "tags",
            label: "Tags",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    const { tags, surveys, fname, lname, suffix, expert_id } = filteredData?.[dataIndex];
                    const fullName = `${fname} ${lname}${suffix ? `, ${suffix}` : ""}`;
                    return (
                        <>
                            <div>
                                {tags.length > 0 && (
                                    <>
                                        {tags.map((item, index) => {
                                            return (
                                                <span key={index}>
                                                    <em
                                                        css={selectedTags.includes(item.tag_id) && highlightTag}
                                                    >{`${item.tag_name}`}</em>
                                                    <span>{index < tags.length - 1 && ", "}</span>
                                                </span>
                                            );
                                        })}
                                    </>
                                )}
                            </div>
                            <div style={{ marginTop: "12px" }}>
                                <strong>Help screen on behalf of {fname}:</strong>
                                {Object.entries(surveys).map(([key, value], i) => (
                                    <p key={`${key}_${i}`} style={{ margin: 0 }}>
                                        {
                                            <Button
                                                css={smallLinkStyle}
                                                onClick={() => handleExpertToken(expert_id, fullName, value.survey_id)}
                                            >
                                                {value.survey_name}
                                            </Button>
                                        }
                                    </p>
                                ))}
                                {Object.entries(surveys).length === 0 && (
                                    <div>
                                        <Button
                                            css={smallLinkStyle}
                                            onClick={() => handleExpertToken(expert_id, fullName, null)}
                                        >
                                            Select a Screener
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </>
                    );
                },
            },
        },
        // {
        //     name: "",
        //     label: "",
        //     options: {
        //         filter: false,
        //         sort: false,
        //         customBodyRenderLite: (dataIndex) => {
        //             const { expert_id } = filteredData?.[dataIndex];

        //             return (
        //                 <div>
        //                     <Delete
        //                         style={{ color: "#666", cursor: "pointer" }}
        //                         onClick={() => removeExpertsEvent([expert_id], loadAddedLeads)}
        //                     />
        //                 </div>
        //             );
        //         },
        //     },
        // },
    ];

    if (loading) {
        return (
            <Backdrop
                css={backdropStyle}
                open={loading}
                onClick={() => {
                    setLoading(false);
                }}
            >
                <CircularProgress style={{ color: "#fff" }} />
            </Backdrop>
        );
    } else {
        return (
            <>
                <h2 style={{ fontSize: "1.25rem" }}>Total: {filteredData?.length}</h2>
                <div style={{ marginBottom: "15px" }}>
                    <strong>FILTERED BY:</strong> Anyone attached without a screener. (automated not included)
                    <br />
                    <strong>RECOMMENDATION:</strong> Invite to project (onboard/screener or both).
                    <br />
                    {selected.length > 0 && (
                        <div
                            style={{
                                zIndex: "999",
                                position: "fixed",
                                bottom: "0",
                                height: "10vh",
                                width: "100%",
                                backgroundColor: "#FAFAFA",
                                left: "72px",
                                borderTop: "1px solid #ccc",
                                padding: "30px 0 0 60px",
                            }}
                        >
                            <Button onClick={handleOnboardScreenerEvent}>Send Onboard/Screener</Button>
                            <Button onClick={handleScreenerOnlyEvent}>Send Screener Only</Button>
                            <Button onClick={() => setTagsModal(true)}>+/- Tags</Button>
                            <Button onClick={() => generateSnippetEvent(selected, handleExpertSnippet)}>Snippet</Button>
                            {/* <AttachToProject
                                selected={selected.map((s) => s.id)}
                                btnText="Add To"
                                setFullWidth={false}
                                user={auth.data}
                                disabled={false}
                            /> */}
                        </div>
                    )}
                </div>
                <div>
                    <strong>TAGS (ANGLES):</strong>
                    <br />
                    {projectTags.length > 0 && (
                        <button css={resetButtonStyle} onClick={handleResetTags}>
                            Reset Tags
                        </button>
                    )}
                    {projectTags.map((t, i) => {
                        return (
                            <span key={`${t.id}_${i}`}>
                                {selectedTags.includes(t.id) ? (
                                    <button
                                        css={highlightAngleButtonStyle}
                                        onClick={() => handleTagDeselectClick(t.id)}
                                    >
                                        {t.name}
                                    </button>
                                ) : (
                                    <button css={angleButtonStyle} onClick={() => handleTagClick(t.id)}>
                                        {t.name}
                                    </button>
                                )}
                            </span>
                        );
                    })}
                </div>
                <GenerateIndividualTokens
                    openDialog={tokenModal}
                    closeEvent={closeModal}
                    project_id={project_id}
                    expert={expert}
                    data={projectScreeners}
                />
                <SnippetDialog openDialog={snippet} handleDialogClose={closeModal} expertData={expertData} />
                <TagManager
                    reloadEvent={loadAddedLeads}
                    modal={tagsModal}
                    closeEvent={setTagsModal}
                    data={selected}
                    tags={projectTags}
                />
                <SendOnboardScreenerNow
                    reloadEvent={loadAddedLeads}
                    open={onboardScreenerModal}
                    closeModal={closeModal}
                    data={selected}
                    project_id={project_id}
                    projectData={projectData}
                    screeners={projectScreeners}
                    template="onboard_screener"
                />
                <SendBulkOutreachNow
                    reloadEvent={loadAddedLeads}
                    screenerModal={screenerModal}
                    setOutreachModal={setOutreachModal}
                    outreach={outreach}
                    closeModal={closeModal}
                    data={selected}
                    project_id={project_id}
                    projectData={projectData}
                    screeners={projectScreeners}
                    template="screener"
                />
                <MUIDataTable data={filteredData} columns={dataColumns} options={options} />
                {open && (
                    <ExpertPreviewWrapperNext
                        open={open}
                        setOpen={setOpen}
                        expert_id={currentExpertId}
                        project_id={project_id}
                    />
                )}
            </>
        );
    }
}
