// react
import React from "react";

// MUI - general
import { Button } from "@mui/material";

const PaymentApprovalButton = (props) => {
    const { row, projectData, user, handleApprovalClick, expertOrClientApproval, accessList } = props;
    // console.log("ACCESS LIST", accessList);
    if (!(expertOrClientApproval === "expert") && !(expertOrClientApproval === "client")) {
        return (
            <Button variant="contained" disabled={true} fullWidth size="small">
                Invalid Approval Type
            </Button>
        );
    } else if (accessList !== undefined && accessList.length > 0 && !accessList.includes(user.id)) {
        return (
            <Button variant="contained" disabled={true} fullWidth size="small">
                You Do Not Have Approval Access
            </Button>
        );
    } else {
        const chargeType = expertOrClientApproval === "expert" ? "expert_charge_approved" : "client_charge_approved";
        return (
            <Button
                fullWidth
                size="small"
                disabled={row[chargeType]}
                variant="contained"
                onClick={(event) => {
                    handleApprovalClick(event, {
                        expertOrClientApproval,
                        schedule_id: row.project_participant_id,
                        expert_id: row.id,
                        client_id: projectData.client_id,
                        project_id: projectData.id,
                        scheduled_datetime: row.scheduled_date,
                        expert_name: `${row.fname} ${row.lname}`,
                        expert_rate: row.hourlyRateToBePaid,
                        expert_hourly_to_client: row.hourlyRateToClient,
                        expert_duration: row.expert_duration,
                        case_code: projectData.case_code,
                        primary_client_contact: row.primary_client_contact,
                        topic: row.topic,
                        project_type: projectData.project_type,
                        current_employment:
                            row.emp_hist.length > 0 && row.emp_hist.filter((e) => e.is_current === true).length > 0
                                ? `${row.emp_hist.filter((e) => e.is_current === true)[0].position} at ${
                                      row.emp_hist.filter((e) => e.is_current === true)[0].company
                                  }`
                                : "Unavailable",
                        price_per_unit: projectData.price_per_unit,
                        client_duration: row.client_duration,
                        client_name: projectData.client_name,
                        unit_charge_amount: row.unit_price_override
                            ? parseFloat(row.unit_price_override).toFixed(2)
                            : parseFloat(row.unitClientChargeString).toFixed(2),
                        expert_charge_amount: row.expert_payment_override
                            ? parseInt(row.expert_payment_override, 10)
                            : parseInt(row.expertPayment, 10), // CHECK ON THIS ONE
                        client_charge_amount: row.dollar_price_override
                            ? parseInt(row.dollar_price_override, 10)
                            : parseInt(row.dollarClientChargeString, 10), // CHECK ON THIS ONE
                        approved: true,
                        approved_by_id: user.id,
                        approved_by_name: `${user.fname} ${user.lname}`,
                        approved_on: new Date().toISOString(),
                    });
                }}
            >
                {row[chargeType] ? "Final" : "Approve"}
            </Button>
        );
    }
};

{
    /* <Button
    disabled={row.hasChargeBeenApproved}
    variant="contained"
    onClick={(event) =>
        handleApprovalClick(event, {
            schedule_id: row.callId,
            expert_id: row.expertId,
            client_id: projectData.client_id,
            project_id: projectId,
            scheduled_datetime: row.scheduledDate,
            expert_name: `${row.fname} ${row.lname}`,
            expert_rate: row.hourlyRateToBePaid, // CHECK ON THIS ONE
            expert_duration: row.expertDuration,
            case_code: projectData.case_code,
            price_per_unit: projectData.price_per_unit,
            client_duration: row.clientDuration,
            client_name: projectData.client_name,
            unit_charge_amount: row.unitClientChargeString,
            expert_charge_amount: row.paymentOverride ? row.paymentOverride : row.expertPayment, // CHECK ON THIS ONE
            client_charge_amount: row.dollarPriceOverride
                ? parseFloat(row.dollarPriceOverride)
                : parseFloat(row.dollarClientChargeString), // CHECK ON THIS ONE
            approved: true,
            approved_by_id: userId,
            approved_on: new Date().toISOString(),
        })
    }
>
    {row.hasChargeBeenApproved ? "Final" : "Approve"}
</Button> */
}

export default PaymentApprovalButton;
