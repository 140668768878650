import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { authContext } from "../../context/auth";
import MUIDataTable from "mui-datatables";
import VerifiedIcon from "@mui/icons-material/Verified";
// import { Link } from "react-router-dom";
import { basicDateTimeStamp, truncateString, formatPhoneNumber } from "../../Utils";
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Button from "@mui/material/Button";
import SnippetDialog from "../SnippetDialog";
// import ExpertPreviewWrapper from "../../components/wrappers/ExpertPreviewWrapper";
import ExpertPreviewWrapperNext from "../../components/wrappers/ExpertPreviewWrapperNext";
import { Backdrop, CircularProgress } from "@mui/material";
import PhoneLogsModal from "./PhoneLogsModal";
// ======
// STYLES
// ======

const linkStyle = css`
    color: #8966ad;
    font-size: 1rem;
    font-weight: bold;
    padding: 0;
    text-align: left;
    line-height: 1.25rem;
`;

const resetButtonStyle = css`
    border-radius: 4px;
    color: #444;
    font-size: 0.85rem;
    background-color: #f1f1f1;
    border: 0;
    padding: 5px 12px;
    margin-right: 8px;
    margin-bottom: 12px;
`;

const angleButtonStyle = css`
    border-radius: 4px;
    color: #8966ad;
    font-size: 0.85rem;
    font-weight: bold;
    background-color: #e5e0f3;
    border: 0;
    padding: 5px 12px;
    margin-right: 8px;
    margin-bottom: 12px;
`;

const highlightAngleButtonStyle = css`
    border-radius: 4px;
    color: #fff;
    font-size: 0.85rem;
    font-weight: bold;
    background-color: #444;
    border: 0;
    padding: 5px 12px;
    margin-right: 8px;
    margin-bottom: 12px;
`;

const highlightTag = css`
    font-size: 0.9rem;
    font-weight: bold;
    background-color: #fcfcd4;
`;

const backdropStyles = css`
    color: #fff;
    z-index: 1201;
`;

export default function Schedule(props) {
    // =========
    // CONSTANTS
    // =========

    const { project_id, generateSnippetEvent, history } = props;

    // =====
    // STATE
    // =====

    const { auth } = useContext(authContext);
    const [[stableData, filteredData], setData] = useState([[], []]); // data is going to have original set of data as first element and the filtered data as the second
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [currentExpertId, setCurrentExpertId] = useState(null);
    // const [rowArray, setRowArray] = useState([]);
    const [selected, setSelectedRows] = useState([]);
    const [expertData, setExpertData] = useState([]);
    const [snippet, setSnippetModal] = useState(false);
    const [openPhoneLogs, setOpenPhoneLogs] = useState(false);
    const [currentExpertIdForLogs, setCurrentExpertIdForLogs] = useState(null);
    const [phone, setPhoneNumber] = useState("");
    // =========
    // PAGE LOAD
    // =========

    async function loadScheduleLeads(projectId) {
        await axios
            .get(`/api/project/now/findScheduleExpertList/${projectId}`)
            .then((response) => {
                if (response?.data?.data?.data) {
                    const result = response?.data?.data?.data;
                    console.log("DID SEND SCHEDULE DATA LOAD? ", result);
                    setData([result, result]);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err, " :: err");
            });
    }

    const onRowSelectionChange = (currentSelect, allSelected) => {
        const result = allSelected.map((item) => {
            return filteredData?.at(item.dataIndex);
        });
        const selectedExperts = result.map((item) => {
            // console.log("SELECTED ITEM: ", item);
            const expert = {
                id: item.expert_id,
                fname: item.fname,
                lname: item.lname,
                prefix: item.prefix,
                suffix: item.suffix,
                mail: item.expert_email,
            };
            return expert;
        });
        console.log("WHICH ROWS SELECTED? ", selectedExperts);
        setSelectedRows(selectedExperts);
    };

    const handlePhoneLogs = (expertId, phoneNum) => {
        console.log("handlePhoneLogs: ", { expertId, phoneNum });
        setCurrentExpertIdForLogs(expertId);
        setPhoneNumber(phoneNum);
        setOpenPhoneLogs(true);
    };

    const handleExpertSnippet = (response) => {
        console.log("RESPONSE FROM API CALL: ", response);
        setExpertData(response);
        setSnippetModal(true);
    };

    const closeModal = () => {
        setSnippetModal(false);
    };

    useEffect(() => {
        loadScheduleLeads(project_id);
    }, []);

    // ============
    // HANDLE FUNCS
    // ============

    const openExpertPreview = (expertId) => {
        setCurrentExpertId(expertId);
        setOpen(true);
    };
    const navigateToExpert = (expertId) => {
        history.push(`/expert/view/${expertId}`);
    };
    const reloadPageTagSearch = (tagId) => {
        history.push(`/search?added_by=0&and_tags=${tagId}`);
        window.location.reload();
    };

    async function handleScheduleExperts() {
        if (selected.length > 0) {
            await axios
                .post("/api/expert/setExpertsAsNeedingScheduling", {
                    projectId: project_id,
                    personId: auth.data.id,
                    expertIdArray: selected.map((item) => item.id),
                })
                .then((res) => {
                    console.log(res, " :: /api/expert/setExpertsAsNeedingScheduling RESPONSE");
                    // open scheduling page in new tab
                    window.open(`/project/schedule/${project_id}`);
                    loadScheduleLeads(project_id);
                })
                .catch((err) => {
                    console.log(err, " :: err");
                });
        }
    }

    // =====
    // TABLE
    // =====

    const options = {
        print: false,
        rowsPerPage: 100,
        viewColumns: false,
        download: true,
        onRowSelectionChange,
    };

    const columns = [
        {
            name: "has_signed_terms",
            label: "In",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { has_signed_terms, disclosures } = filteredData?.[dataIndex];

                    return (
                        <div>
                            {has_signed_terms && disclosures ? (
                                <VerifiedIcon style={{ color: "#523197" }} />
                            ) : (
                                <strong style={{ color: "#ccc" }}>***</strong>
                            )}
                        </div>
                    );
                },
            },
        },
        {
            name: "expert_name",
            label: "Name",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { fname, lname, suffix, expert_id, empHistData, headline, number } =
                        filteredData?.[dataIndex];

                    return (
                        <div>
                            <Button css={linkStyle} onClick={() => openExpertPreview(expert_id)}>
                                {fname} {lname}
                                {suffix ? `, ${suffix}` : ""}
                            </Button>
                            {empHistData && empHistData.length > 0 ? (
                                <div>{`${truncateString(empHistData[0].position, 25)} at ${truncateString(
                                    empHistData[0].company,
                                    25
                                )}`}</div>
                            ) : (
                                <div>{truncateString(headline, 25)}</div>
                            )}
                        </div>
                    );
                },
            },
        },
        {
            name: "",
            label: "",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    const { expert_id, number, phoneLogsPresent } = filteredData?.[dataIndex];

                    return (
                        <div>
                            {number && (
                                <div>
                                    <a style={{ display: "inline-block" }} href={`tel:${number}`}>
                                        {number.length === 10 ? formatPhoneNumber(number) : number}
                                    </a>
                                    <Button
                                        style={{
                                            display: "block",
                                            marginTop: "10px",
                                            padding: "0",
                                            fontSize: ".75rem",
                                        }}
                                        onClick={() => handlePhoneLogs(expert_id, number)}
                                    >
                                        {phoneLogsPresent ? "View Call Log" : "Log Status"}
                                    </Button>
                                </div>
                            )}
                        </div>
                    );
                },
            },
        },
        {
            name: "calculated_consultation_rate",
            label: "Rate",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { calculated_consultation_rate } = filteredData?.[dataIndex];

                    return (
                        <div>
                            {parseInt(calculated_consultation_rate, 10) > 0
                                ? `$${calculated_consultation_rate}`
                                : "----"}
                        </div>
                    );
                },
            },
        },
        {
            name: "attached_on",
            label: "Attached",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { attached_on } = filteredData?.[dataIndex];

                    return <div>{basicDateTimeStamp(attached_on)}</div>;
                },
            },
        },
        {
            name: "attaching_rm_name",
            label: "By",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { attaching_rm_name } = filteredData?.[dataIndex];

                    return <div>{attaching_rm_name}</div>;
                },
            },
        },
    ];

    if (loading) {
        return (
            <Backdrop
                css={backdropStyles}
                open={loading}
                onClick={() => {
                    setLoading(false);
                }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    } else {
        return (
            <>
                <h2 style={{ fontSize: "1.25rem" }}>Ready To Schedule: {filteredData?.length}</h2>
                <div style={{ marginBottom: "15px" }}>
                    <strong>FILTER:</strong> ALL experts who have been sent to client OR selected by client AND not
                    scheduled yet.
                    <br />
                    <strong>ACTION:</strong> Schedule these experts ASAP!
                    <br />
                    {selected.length > 0 && (
                        <div
                            style={{
                                zIndex: "999",
                                position: "fixed",
                                bottom: "0",
                                height: "10vh",
                                width: "100%",
                                backgroundColor: "#FAFAFA",
                                left: "72px",
                                borderTop: "1px solid #ccc",
                                padding: "30px 0 0 60px",
                            }}
                        >
                            <Button onClick={handleScheduleExperts}>Schedule Experts</Button>
                            <Button onClick={() => generateSnippetEvent(selected, handleExpertSnippet)}>Snippet</Button>
                        </div>
                    )}
                </div>
                <SnippetDialog openDialog={snippet} handleDialogClose={closeModal} expertData={expertData} />
                <MUIDataTable data={filteredData} options={options} columns={columns} />
                {open && (
                    <ExpertPreviewWrapperNext
                        open={open}
                        setOpen={setOpen}
                        expert_id={currentExpertId}
                        project_id={project_id}
                    />
                )}
                {openPhoneLogs && (
                    <PhoneLogsModal
                        openPhoneLogs={openPhoneLogs}
                        setOpenPhoneLogs={setOpenPhoneLogs}
                        userInfo={auth.data}
                        project_id={project_id}
                        expert_id={currentExpertIdForLogs}
                        number={phone}
                        reloadEvent={loadScheduleLeads}
                    />
                )}
            </>
        );
    }
}
