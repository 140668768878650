import React from "react";
import Container from "@mui/material/Container";
import useSWR from "swr";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import CircularProgress from "@mui/material/CircularProgress";
import MUIDataTable from "mui-datatables";

const fetcher = (...args) => fetch(...args).then((res) => res.json());

export default function ClientList() {
    const { data, error } = useSWR("/api/client/findAll", fetcher);

    const columns = [
        {
            name: "name",
            label: "Name",
            options: {
                filter: true,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return (
                        <Link href={"/client/view/" + data.data[dataIndex].id} underline="none">
                            {data.data[dataIndex].name}
                        </Link>
                    );
                },
            },
        },
        { name: "account_owner_email__c", label: "owner" },
        { name: "created_on_date", label: "Created" },
        { name: "updated_on_date", label: "Updated" },
    ];

    const options = {
        filter: false,
        pagination: true,
        print: false,
        viewColumns: false,
        download: false,
    };

    if (error) return <div>failed to load</div>;
    if (!data)
        return (
            <Stack sx={{ color: "grey.500" }} spacing={2} direction="row">
                <CircularProgress color="primary" />
            </Stack>
        );

    return (
        <Container maxWidth={false} disableGutters>
            <Grid container spacing={3} sx={{ p: 1 }}>
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "1rem",
                        }}
                    >
                        <MUIDataTable title={"Clients List"} data={data.data} columns={columns} options={options} />
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}
