import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, IconButton, DialogContent, Grid, Button, DialogActions } from "@mui/material";
import StatusCheckboxes from "./StatusCheckboxes";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
export default function ChangeMultipleStatuses(props) {
    const _state = props.state_manager;
    const open = props.open;
    const data = props.data;
    const error = props.error;
    const { history, match } = props.props;
    // props = props.props;

    const [saveSuccess, setSaveStatusDisable] = useState(true);
    function submitFilters() {
        const url = "/api/project/filterExperts";
        const postObj = {
            id: parseInt(match.params.id, 10),
            statuses: _state.get.statuses.selected,
        };
        console.log("POST OBJECT BEFORE: ", postObj);

        axios
            .post(url, postObj, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    console.log("POST OBJECT AFTER: ", `${JSON.stringify(postObj)}`);
                    const filteredResults = result.data.data;
                    console.log("POST RESULTS: ", filteredResults.experts);
                    // Set Filtered List of EXPERTS
                    // _state.set.projectState({
                    //     ..._state.get.projectState,
                    //     experts: filteredResults.experts,
                    // });
                    _state.set.filterResults({
                        ..._state.get.filter,
                        experts: filteredResults.experts,
                    });
                    _state.set.expertsData(filteredResults.experts);

                    // setFilterResults({
                    //     ...filter,
                    //     experts: filteredResults.experts,
                    // });
                    // handleBoolFiltersOnLoad(filteredResults.experts);
                    // Now update menu of experts in statuses dialog
                    // setSelectedExperts(filteredResults.experts);
                    // Close left filter menu

                    // setState({ ...state, left: false });
                } else {
                    console.log(`Good POST, but something went wrong: ${JSON.stringify(postObj)}`);
                }
            })
            .catch((result) => {
                console.log("API not done yet, 404 Error:", result);
                setState({ ...state, left: false });
            });
    }
    function saveExpertStatuses(event) {
        event.preventDefault();
        // console.log(status);
        const url = `/api/project/saveExpertStatus/${_state.get.projectState.id}`;
        const expert = _state.get.expert;
        const status = _state.get.status;
        const formData = {
            id: expert.id,
            created_by: expert.created_by,
            call_duration: expert.call_duration,
            is_dirty: status.is_dirty,
            prescreen: [
                {
                    id: 21,
                    prescreen_sent: expert.prescreen_sent,
                    is_dirty: status.prescreen_sent_dirty,
                },
            ],
            terms: [
                {
                    id: 14,
                    tnc_not_signed: expert.tnc_not_signed,
                    is_dirty: status.tnc_not_signed_dirty,
                },
                { id: 15, tnc_sent: expert.tnc_sent, is_dirty: status.tnc_sent_dirty },
                {
                    id: 16,
                    tnc_signed: expert.tnc_signed,
                    is_dirty: status.tnc_signed_dirty,
                },
            ],
            expertResponse: [
                { id: 3, invited: expert.invited, is_dirty: status.invited_dirty },
                { id: 4, accepted: expert.accepted, is_dirty: status.accepted_dirty },
                { id: 5, declined: expert.declined, is_dirty: status.declined_dirty },
            ],
            clientResponse: [
                {
                    id: 6,
                    sent_to_client: expert.sent_to_client,
                    is_dirty: status.sent_to_client_dirty,
                },
                {
                    id: 7,
                    selected_by_client: expert.selected_by_client,
                    is_dirty: status.selected_by_client_dirty,
                },
                {
                    id: 8,
                    rejected_by_client: expert.rejected_by_client,
                    is_dirty: status.rejected_by_client_dirty,
                },
            ],
            complianceResponse: [
                {
                    id: 9,
                    sent_to_compliance: expert.sent_to_compliance,
                    is_dirty: status.sent_to_compliance_dirty,
                },
                {
                    id: 10,
                    compliance_approved: expert.compliance_approved,
                    is_dirty: status.compliance_approved_dirty,
                },
                {
                    id: 11,
                    compliance_rejected: expert.compliance_rejected,
                    is_dirty: status.compliance_rejected_dirty,
                },
            ],
            schedulingResponse: [
                {
                    id: 12,
                    needs_scheduling: expert.needs_scheduling,
                    is_dirty: status.needs_scheduling_dirty,
                },
                {
                    id: 13,
                    scheduled: expert.scheduled,
                    is_dirty: status.scheduled_dirty,
                },
                {
                    id: 20,
                    call_complete: expert.call_complete,
                    is_dirty: status.call_complete_dirty,
                },
            ],
            payment: [
                {
                    id: 17,
                    needs_payment: expert.needs_payment,
                    is_dirty: status.needs_payment_dirty,
                },
                {
                    id: 18,
                    payment_requested: expert.payment_requested,
                    is_dirty: status.payment_requested_dirty,
                },
                { id: 19, paid: expert.paid, is_dirty: status.paid_dirty },
            ],
            screened: [
                {
                    id: 28,
                    expert_screened: expert.expert_screened,
                    is_dirty: status.expert_screened_dirty,
                },
                {
                    id: 31,
                    expert_on_hold: expert.expert_on_hold,
                    is_dirty: status.expert_on_hold_dirty,
                },
            ],
        };
        console.log("Submit DATA: ", JSON.stringify(formData));
        axios
            .post(url, formData, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    const response = result.data.data;
                    // setSuccess(true);
                    _state.flash.success("Expert updated!");
                    console.log("RESULT: ", response);
                    // Update the list of experts.
                    submitFilters();
                    // If multiple selected or single save

                    const arr = [];
                    for (const i in _state.get.selected) {
                        if (_state.get.selected.length > 0) arr.push(_state.get.selected[i].id);
                    }
                    if (_state.get.selected.length > 0) {
                        setSaveStatusDisable(true);
                        // TODO: User needs to see the updated changes if they click back to expert
                        const selectedExperts = _state.get.expertsData.filter(
                            (e) => arr.includes(e.id)
                            // e.id === response.id
                        );
                        console.log("SELECTED: ", selectedExperts);
                        if (selectedExperts.length > 0) {
                            _state.set.selectedExperts(selectedExperts);
                            // Update expert data table
                            const newData = result.data.data;
                            const newExpertArr = _state.get.expertsData.map((e) => {
                                if (e.id == newData.id) {
                                    return { ...e, newData };
                                } else {
                                    return e;
                                }
                            });
                            // _state.set.expertsData(newExpertArr);
                        }
                    } else {
                        // handleClose();
                        closeEvent();
                    }
                    console.log("Experts Updated Successfully: ", result);
                } else {
                    console.log(`Good POST, but something went wrong: ${JSON.stringify(formData)}`);
                    // alert("There was an issue saving this status change. Please contact the tech team.");
                    _state.flash.error("There was an issue saving this status change. Please contact the tech team.");
                }
            })
            .catch((result) => {
                console.log(result);
                console.log("API not done yet, 404 Error:", result);
                // alert("There was an issue saving this status change. Please contact the tech team.");
                _state.flash.error("There was an issue saving this status change. Please contact the tech team.");
            });
    }

    function handleChange(event) {
        const { target } = event;
        const { value, name } = target;

        _state.set.expert({
            ..._state.get.expert,
            [name]: value,
        });
    }

    function handleCheckbox(event) {
        const { target } = event;
        const { name } = target;
        const dirty = `${name}_dirty`;
        console.log("Status Name: ", event.target.dataset.statusType);
        _state.set.expert({ ..._state.get.expert, [name]: event.target.checked });
        // Let DB know which checkbox was touched and which array was modified
        _state.set.status({ ..._state.get.status, [dirty]: true });
        if (!_state.get.status.is_dirty.includes(event.target.dataset.statusType)) {
            _state.get.status.is_dirty.push(event.target.dataset.statusType);
        }
        // Any click of a checkbox enables save status button
        setSaveStatusDisable(false);
    }

    const handleRadioBtns = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const dirty = `${value}_dirty`;
        const newExpert = {
            ..._state.get.expert,
            accepted: false,
            declined: false,
            invited: false,
            [value]: true,
        };
        _state.set.expert(newExpert);
        // Let DB know which checkbox was touched and which array was modified
        _state.set.status({ ..._state.get.status, [dirty]: true });
        if (!_state.get.status.is_dirty.includes(name)) {
            _state.get.status.is_dirty.push(name);
        }

        // Any click of a checkbox enables save status button
        setSaveStatusDisable(false);
    };

    function closeEvent() {
        _state.switch.changeMultipleStatusesModal();
        // _state.set.selected([]);
        _state.set.selectedExperts([]);

        setSaveStatusDisable(true);
    }
    useEffect(() => {
        if (open) props.func.populate(_state.get.selectedExperts[0]);
        // if (_state.get.selectedExperts.length > 0) console.log(_state.get.selectedExperts);
    }, [open]);
    return (
        <Dialog open={open} onClose={closeEvent} fullWidth maxWidth="lg" aria-labelledby="form-dialog-title">
            <DialogTitle>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={closeEvent}
                    sx={{ float: "right" }}
                    aria-label="close"
                    size="large"
                >
                    <CloseIcon />
                </IconButton>
                Change Multiple Expert Statuses
            </DialogTitle>
            {/* // TODO */}
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item sm={3} xs={12}>
                        {_state.get.selectedExperts.map(function (e, idx) {
                            return (
                                <Button
                                    key={e.id}
                                    sx={{
                                        color: "#8966ad",
                                        textDecoration: "none",
                                        fontSize: ".75rem",
                                        minWidth: "auto",
                                        textAlign: "left",
                                        display: "block",
                                    }}
                                    onClick={() => props.func.populate(e)}
                                >
                                    {e.fname} {e.lname}
                                    {e.suffix && `, ${e.suffix}`}
                                </Button>
                            );
                        })}
                    </Grid>
                    <Grid item sm={9} xs={12}>
                        <StatusCheckboxes
                            project={data}
                            expert={_state.get.expert}
                            handleChange={handleChange}
                            handleCheckbox={handleCheckbox}
                            handleRadioBtns={handleRadioBtns}
                            sent={_state.get.sent}
                        />
                        <Button
                            disabled={saveSuccess}
                            fullWidth
                            onClick={saveExpertStatuses}
                            color="primary"
                            variant="contained"
                        >
                            Save Status
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>&nbsp;</DialogActions>
        </Dialog>
    );
}
