import React, { useState, useEffect, useCallback } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Link } from "react-router-dom";
import queryString from "query-string";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    label: {
        color: "#999",
        fontSize: "1rem",
        textAlign: "left",
        fontWeight: "normal",
    },
    phone: {
        marginBottom: "8px",
    },
    result: {
        color: "#19857b",
        fontSize: "1rem",
        textAlign: "left",
    },
    heading: {
        color: "#19857b",
        fontSize: "1rem",
        textAlign: "left",
        fontWeight: "normal",
    },
    description: {
        color: "#666",
        fontSize: ".75rem",
        textAlign: "left",
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    submit: {
        marginBottom: "8px",
    },
    emailLookupContainer: {
        width: "66.66%",
        marginBottom: "4rem",
        border: "2px solid #8a61ad",
        borderRadius: "3px",
        padding: "1.5rem",
        backgroundColor: "#f5f5f5",
    },
    emailLookupHeadline: {
        fontWeight: "700",
        fontSize: "1.5rem",
        color: "#8a61ad",
        marginBottom: "1rem",
    },
    emailLookupInput: {
        width: "100%",
        marginBottom: ".5rem",
        backgroundColor: "#fff",
    },
    emailLookupButton: {
        width: "100%",
        marginBottom: "1.5rem",
    },
    emailLookupReturn: {
        fontStyle: "italic",
        fontSize: "1rem",
        letterSpacing: "1.1px",
    },
    emailLookupError: {
        color: "red",
        fontWeight: "700",
    },
    emailLookupLink: {
        textDecoration: "none",
        fontStyle: "initial",
        color: "#8a61ad",
        "&:hover": {
            color: "#7c15ff",
        },
    },
    linkText: {
        fontWeight: "700",
    },
}));
const HunterAPIKey = "bfdf14fa7f4f152a1db855049a08cebc1b9e6a60";
const RocketReachAPIKey = "45fd2dk9d6ae8f89530af293cdd92284b0d550f";

function EmailVerifier(props) {
    const classes = useStyles();
    const pageUrl = props.location.search;
    const params = queryString.parse(pageUrl);
    const [rocketreach, setRocketReachDisplay] = useState("none");
    const [phone, setPhone] = useState({
        number: "",
        callerName: "",
        countryCode: "",
        nationalFormat: "",
        carrierName: "",
        carrierType: "",
    });
    const [email, setEmail] = useState({
        email: "",
        score: "",
        result: "",
        regexp: false,
        gibberish: false,
        disposable: false,
        webmail: false,
        mx_records: false,
        smtp_server: false,
        smtp_check: false,
        accept_all: false,
        block: false,
    });
    const [search, setSearch] = useState({
        first_name: "",
        last_name: "",
        email: "",
        score: "",
        domain: "",
        position: "",
        twitter: "",
        linkedin_url: "",
        phone_number: "",
        company: "",
    });
    const [expert, setExpert] = useState({
        id: params.id,
        fname: "",
        lname: "",
        alternate_phones: [],
        alternate_emails: [],
    });

    const DEFAULT_EMAIL_SEARCH_MSG = "Search For Expert Emails...";

    const [emailAddress, setEmailAddress] = useState("");
    const [expertEmails, setExpertEmails] = useState([]);
    const [emailMsgFromServer, setEmailMsgFromServer] = useState(DEFAULT_EMAIL_SEARCH_MSG);
    const [emailSearchError, setEmailSearchError] = useState(false);

    function isValidEmail(email) {
        const regex =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(String(email).toLowerCase());
    }

    function handleEmailChange(event) {
        const { target } = event;
        const { value, name } = target;
        if (isValidEmail(value)) {
            setEmail({
                ...email,
                [name]: value,
            });
        } else {
            setEmail({
                email: value,
            });
        }
    }
    function handlePhoneChange(event) {
        const { target } = event;
        const { value, name } = target;
        if (value.length >= 9) {
            setPhone({
                ...phone,
                [name]: value,
            });
        } else {
            setPhone({
                number: value,
            });
        }
    }
    function handleChange(event) {
        const { target } = event;
        const { value, name } = target;
        setSearch({
            ...search,
            [name]: value,
        });
    }

    function handleEmailSearchBtn() {
        setExpertEmails([]);
        searchForExpertEmails(emailAddress);
    }

    function renderExpertEmails() {
        if (expertEmails?.length > 0) {
            return expertEmails.map((email, index) => {
                const { id: expertId, fname, lname, mail } = email;

                return (
                    <div key={expertId}>
                        <Link className={classes.emailLookupLink} target="_blank" to={`/expert/view/${expertId}`}>
                            <span className={classes.linkText}>{`NAME:`}</span> {`${fname} ${lname}`}{" "}
                            <span className={classes.linkText}>{`EMAIL: `}</span> {`${mail}`}
                        </Link>
                    </div>
                );
            });
        } else {
            return <div>{emailMsgFromServer}</div>;
        }
    }

    function searchForExpertEmails(email) {
        axios
            .post("/api/expert/searchEmail", { email })
            .then((res) => {
                console.log(res, " :: res");
                const { message, result } = res?.data;
                // console.log(message, " :: message");
                // console.log(result, " :: result");
                if (!result && !message) {
                    setEmailMsgFromServer("There was a technical problem with searching for expert emails.");
                    setEmailSearchError(true);
                    return;
                }
                if (!result && message) {
                    setEmailMsgFromServer(message);
                    setEmailSearchError(true);
                    return;
                }

                setExpertEmails(result);
                setEmailSearchError(false);
            })
            .catch((err) => {
                console.log(err, " :: err");
            });
    }

    function checkRocketReachStatus(id) {
        axios
            .get("https://api.rocketreach.co/v1/api/checkStatus", {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                },
                params: {
                    ids: id,
                    api_key: RocketReachAPIKey,
                },
            })
            .then(function (response) {
                console.log("Response: ", response);
                if (response.status === 200) {
                    const { data } = response;
                    switch (data[0].status) {
                        case "complete":
                            console.log("RocketReach FINAL Result: ", data[0]);
                            break;
                        default:
                            setTimeout(function () {
                                console.log("INTO TIMEOUT: ", data[0].status);
                                checkRocketReachStatus(data[0].id);
                            }, 1200);
                    }
                }
            });
    }

    function fetchDataViaRocketReach() {
        if (search.linkedin_url !== "" && search.first_name !== "" && search.last_name !== "") {
            axios
                .get("https://api.rocketreach.co/v1/api/lookupProfile", {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                    },
                    params: {
                        name: search.first_name + " " + search.last_name,
                        li_url: search.linkedin_url,
                        api_key: RocketReachAPIKey,
                    },
                })
                .then(function (response) {
                    console.log(response);
                    if (response.status === 200) {
                        const { data } = response;
                        switch (data[0].status) {
                            case "complete":
                                console.log("RocketReach FINAL RESULT: ", data[0]);
                                break;
                            case "waiting":
                            case "searching":
                            case "progress":
                            case "not queued":
                                console.log(data[0].status);
                                checkRocketReachStatus(data[0].id);
                                break;
                            case "failed":
                                console.log(data[0].status);
                                break;
                            default:
                                console.log(data[0].status);
                        }
                    }
                });
        }
    }

    function findEmail(search) {
        if (search.first_name !== "" && search.last_name !== "" && (search.domain !== "" || search.company !== "")) {
            axios
                .get("https://api.hunter.io/v2/email-finder", {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                    },
                    params: {
                        first_name: search.first_name,
                        last_name: search.last_name,
                        company: search.company,
                        domain: search.domain,
                        api_key: HunterAPIKey,
                    },
                })
                .then(function (response) {
                    const data = response.data.data;
                    if (data.first_name != null || data.last_name != null) {
                        setSearch({
                            ...search,
                            first_name: data.first_name,
                            last_name: data.last_name,
                            email: data.email,
                            score: data.score,
                            domain: data.domain,
                            phone_number: data.phone_number,
                            company: data.company,
                        });
                    }
                });
        }
    }

    function checkEmail(e) {
        if (e.email !== "" && isValidEmail(e.email)) {
            axios
                .get("https://api.hunter.io/v2/email-verifier", {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                    },
                    params: {
                        email: e.email,
                        api_key: HunterAPIKey,
                    },
                })
                .then(function (response) {
                    const { data } = response.data;

                    if (data) {
                        setEmail({
                            email: data.email,
                            score: data.score,
                            result: data.result,
                            regexp: data.regexp,
                            gibberish: data.gibberish,
                            disposable: data.disposable,
                            webmail: data.webmail,
                            mx_records: data.mx_records,
                            smtp_server: data.smtp_server,
                            smtp_check: data.smtp_check,
                            block: data.block,
                        });
                    }
                });
        }
    }

    function checkPhone(p) {
        if (p !== "" && p.length >= 9) {
            axios
                .get("/api/expert/phonelookup", {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                    },
                    params: {
                        number: p,
                    },
                })
                .then(function (response) {
                    const { data } = response.data;

                    if (data) {
                        setPhone({
                            number: data.phoneNumber,
                            callerName: data.callerName.caller_name,
                            countryCode: data.countryCode,
                            nationalFormat: data.nationalFormat,
                            carrierName: data.carrier.name,
                            carrierType: data.carrier.type,
                        });
                    }
                });
        }
    }
    const fetchExpertById = useCallback(async (id) => {
        const eid = await axios.get(`/api/expert/findbyid?id=${id}`);
        if (eid.status === 200 && eid.data && eid.data.data) {
            console.log("EXPERT DATA: ", eid.data.data);
            if (eid.data.data[0].length === 0) return;
            const person = eid.data.data[0][0];
            const emails = eid.data.data[1];
            const phoneNumbers = eid.data.data[2];
            const currentEmployment = eid.data.data[6].filter((item) => item.present === true);
            let currentCompany = "";
            let currentPosition = "";
            if (currentEmployment.length > 0) {
                currentPosition = `${currentEmployment[0].position}`;
                currentCompany = `${currentEmployment[0].company}`;
            }
            setExpert({
                ...expert,
                id: person.id,
                fname: person.fname,
                lname: person.lname,
                alternate_phones: phoneNumbers.filter((p) => !p.isprimary),
                alternate_emails: emails.filter((e) => !e.isprimary),
            });
            setPhone({
                ...phone,
                number:
                    phoneNumbers.length === 0
                        ? []
                        : phoneNumbers.filter((ph) => ph.isprimary).map((ph) => ph.number)[0],
            });
            setEmail({
                ...email,
                email: emails.filter((e) => e.isprimary).map((e) => e.email)[0],
            });
            setSearch({
                ...search,
                first_name: person.fname,
                last_name: person.lname,
                linkedin_url: person.linkedin_url,
                position: currentPosition,
                company: currentCompany,
            });
        }
    }, []);

    useEffect(() => {
        if (params.id) {
            console.log("EXPERT ID: ", params.id);
            fetchExpertById(params.id);
        }
    }, [props]);

    return (
        <div className={classes.root}>
            <div className={classes.emailLookupContainer}>
                <h1 className={classes.emailLookupHeadline}>Expert Email Lookup</h1>
                <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center">
                    <TextField
                        className={classes.emailLookupInput}
                        margin="dense"
                        label="Email Address"
                        value={emailAddress}
                        onChange={(e) => setEmailAddress(e.target.value)}
                    />
                    <Button onClick={handleEmailSearchBtn} variant="contained" className={classes.emailLookupButton}>
                        Search For Emails
                    </Button>
                </Box>
                <div className={`${classes.emailLookupReturn} ${emailSearchError && classes.emailLookupError}`}>
                    {renderExpertEmails()}
                </div>
            </div>
            <h1>Contact Information Verification</h1>
            {params.id && (
                <Grid container spacing={3}>
                    <Grid item sm={12} xs={12}>
                        <Link to={`/expert/edit/${expert.id}`} className={classes.heading}>
                            {expert.fname} {expert.lname}&apos;s Profile
                        </Link>
                    </Grid>
                </Grid>
            )}
            <Grid container spacing={3}>
                <Grid item sm={12} xs={12}>
                    <Box display={rocketreach}>
                        {search.linkedin_url && (
                            <Button
                                className={useStyles.submit}
                                color="secondary"
                                fullWidth
                                onClick={fetchDataViaRocketReach}
                                variant="contained"
                            >
                                RocketReach
                            </Button>
                        )}
                    </Box>
                </Grid>
                <Grid item sm={12} xs={12}>
                    <Grid container spacing={3}>
                        <Grid item sm={8} xs={12}>
                            <TextField
                                fullWidth
                                label="Enter Phone"
                                margin="normal"
                                name="number"
                                onChange={handlePhoneChange}
                                value={phone.number}
                            />
                            <Button
                                className={useStyles.submit}
                                color="secondary"
                                fullWidth
                                onClick={() => checkPhone(phone.number)}
                                variant="contained"
                            >
                                Verify Phone
                            </Button>
                            <Paper className={classes.paper}>
                                <p className={classes.label}>
                                    Name: <strong className={classes.result}>{phone.callerName}</strong>
                                </p>
                                <p className={classes.label}>
                                    Phone: <strong className={classes.result}>{phone.nationalFormat}</strong>
                                </p>
                                <p className={classes.label}>
                                    Country Code: <strong className={classes.result}>{phone.countryCode}</strong>
                                </p>
                                <p className={classes.label}>
                                    Carrier:{" "}
                                    <strong className={classes.result}>
                                        {phone.carrierName} {phone.carrierType && `(${phone.carrierType})`}
                                    </strong>
                                </p>
                            </Paper>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            {params.id && (
                                <div>
                                    <strong>
                                        {expert.fname} {expert.lname}&apos;s Alternate Numbers:
                                    </strong>
                                    {expert.alternate_phones.length > 0 &&
                                        expert.alternate_phones.map((p, idx) => {
                                            const key = `num_${idx}`;
                                            return (
                                                <Button
                                                    key={key}
                                                    className={classes.phone}
                                                    fullWidth
                                                    onClick={() => checkPhone(p.number)}
                                                    variant="contained"
                                                >
                                                    Verify: {p.number}
                                                </Button>
                                            );
                                        })}
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={8} xs={12}>
                    <TextField
                        fullWidth
                        label="Enter Email"
                        margin="normal"
                        name="email"
                        onChange={handleEmailChange}
                        value={email.email}
                    />
                    <Button
                        className={useStyles.submit}
                        color="secondary"
                        fullWidth
                        onClick={() => checkEmail(email)}
                        variant="contained"
                    >
                        Verify Email
                    </Button>
                    <Paper className={classes.paper}>
                        <p className={classes.label}>
                            Email: <strong className={classes.result}>{email.email}</strong>
                        </p>
                        <p className={classes.label}>
                            Score: <strong className={classes.result}>{email.score}</strong> (of 100)
                        </p>
                        <p className={classes.label}>
                            Result: <strong className={classes.result}>{email.result}</strong>
                        </p>
                        <p className={classes.label}>
                            Valid Email Format: <strong className={classes.result}>{email.regexp && "Yes"}</strong>
                        </p>
                        <p className={classes.label}>
                            Auto-Generated: <strong className={classes.result}>{email.gibberish && "Yes"}</strong>
                        </p>
                        <p className={classes.label}>
                            Disposable Email Service:{" "}
                            <strong className={classes.result}>{email.disposable && "Yes"}</strong>
                        </p>
                        <p className={classes.label}>
                            Webmail (ex: Gmail, Yahoo, Hotmail):{" "}
                            <strong className={classes.result}>{email.webmail && "Obviously"}</strong>
                        </p>
                        <p className={classes.label}>
                            Mail Exchange Records:{" "}
                            <strong className={classes.result}>{email.mx_records && "Yes"}</strong>
                        </p>
                        <p className={classes.label}>
                            SMTP Server Successful:{" "}
                            <strong className={classes.result}>{email.smtp_server && "Yes"}</strong>
                        </p>
                        <p className={classes.label}>
                            Bounced: <strong className={classes.result}>{email.smtp_check && "Yes"}</strong>
                        </p>
                        <p className={classes.label}>
                            Blocked: <strong className={classes.result}>{email.block && "Yes"}</strong>
                        </p>
                    </Paper>
                </Grid>
                <Grid item sm={4} xs={12}>
                    {params.id && (
                        <div>
                            <strong>
                                {expert.fname} {expert.lname}&apos;s Alternate Emails:
                            </strong>
                            {expert.alternate_emails.length > 0 &&
                                expert.alternate_emails.map((em, idx) => {
                                    const key = `email_${idx}`;
                                    return (
                                        <Button
                                            key={key}
                                            className={classes.phone}
                                            fullWidth
                                            onClick={() => checkEmail(em)}
                                            variant="contained"
                                        >
                                            Verify: {em.email}
                                        </Button>
                                    );
                                })}
                        </div>
                    )}
                    <Paper className={classes.paper}>
                        <h3 className={classes.heading}>Deliverable</h3>
                        <p className={classes.description}>
                            The checks for the format, type, sever status and email status passed. A deliverable email
                            can also provide sources where it can be found on the web.
                        </p>
                        <h3 className={classes.heading}>Risky</h3>
                        <p className={classes.description}>
                            When an email address is tagged "risky", it means the verification check seems to work, but
                            we have reason to believe the email may not be read by anyone. The most common cause for a
                            Risky email address are Accept-all domains. Due to this we mark them in the verification
                            section.
                        </p>
                        <h3 className={classes.heading}>Undeliverable or Invalid</h3>
                        <p className={classes.description}>
                            In this case both the server and email status failed for the email, meaning the email will
                            certainly bounce as it does not exist.
                        </p>
                    </Paper>
                </Grid>
                <h1>Find an Email</h1>
                <Grid item sm={12} xs={12}>
                    <TextField
                        fullWidth
                        label="First Name"
                        margin="normal"
                        name="first_name"
                        onChange={handleChange}
                        value={search.first_name ? search.first_name : ""}
                    />
                    <TextField
                        fullWidth
                        label="Last Name"
                        margin="normal"
                        name="last_name"
                        onChange={handleChange}
                        value={search.last_name ? search.last_name : ""}
                    />
                    <TextField
                        fullWidth
                        label="Company"
                        margin="normal"
                        name="company"
                        onChange={handleChange}
                        value={search.company ? search.company : ""}
                    />
                    <TextField
                        fullWidth
                        label="Domain (ex: lifesciadvisors.com)"
                        margin="normal"
                        name="domain"
                        onChange={handleChange}
                        value={search.domain ? search.domain : ""}
                    />
                </Grid>
                <Grid item sm={12} xs={12}>
                    <Button
                        className={useStyles.submit}
                        color="secondary"
                        fullWidth
                        onClick={() => findEmail(search)}
                        variant="contained"
                    >
                        Find Email
                    </Button>
                </Grid>
                <Grid item sm={12} xs={12}>
                    <Paper className={classes.paper}>
                        <p className={classes.label}>
                            Email: <strong className={classes.result}>{search.email && search.email}</strong>
                        </p>
                        <p className={classes.label}>
                            Full Name:{" "}
                            <strong className={classes.result}>
                                {search.first_name} {search.last_name}
                            </strong>
                        </p>
                        <p className={classes.label}>
                            Company: <strong className={classes.result}>{search.company && search.company}</strong>
                        </p>
                        <p className={classes.label}>
                            Domain: <strong className={classes.result}>{search.domain && search.domain}</strong>
                        </p>
                        <p className={classes.label}>
                            Score: <strong className={classes.result}>{search.score && search.score}</strong>
                        </p>
                        <p className={classes.label}>
                            Phone:{" "}
                            <strong className={classes.result}>{search.phone_number && search.phone_number}</strong>
                        </p>
                        <p className={classes.label}>
                            Position: <strong className={classes.result}>{search.position && search.position}</strong>
                        </p>
                        <p className={classes.label}>
                            LinkedIn:{" "}
                            <strong className={classes.result}>{search.linkedin_url && search.linkedin_url}</strong>
                        </p>
                        <p className={classes.label}>
                            Twitter: <strong className={classes.result}>{search.twitter && search.twitter}</strong>
                        </p>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}
export default EmailVerifier;
