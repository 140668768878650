import React from "react";
import { Dialog, DialogTitle, DialogContent, Box } from "@mui/material";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
export default function EmailConfirmation(props) {
    const _state = props.state_manager;

    return (
        <Dialog
            open={props.open}
            onClose={_state.switch.emailConfirmation}
            fullWidth
            maxWidth="md"
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={_state.switch.emailConfirmation}
                    sx={{ float: "right" }}
                    aria-label="close"
                    size="large"
                >
                    <CloseIcon />
                </IconButton>
                Your email has been sent successfully. Here are the details of what was sent:
            </DialogTitle>
            <DialogContent>
                <p>
                    <strong>FROM:</strong> {_state.get.postView.user_fname} {_state.get.postView.user_lname} (
                    {_state.get.postView.from_email})
                </p>
                <p>
                    <strong>TO:</strong> {_state.get.postView.expert_fname} {_state.get.postView.expert_lname} (
                    {_state.get.postView.expert_email})
                </p>
                <Box sx={{ borderBottom: "2px solid #444", paddingBottom: "5px", marginBottom: "10px" }}>
                    <strong>SUBJECT LINE:</strong> {_state.get.postView.email_subject}
                </Box>
                <div
                    dangerouslySetInnerHTML={{
                        __html: _state.get.postView.email_body,
                    }}
                />
            </DialogContent>
        </Dialog>
    );
}
