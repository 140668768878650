import React, { useState, useEffect, useCallback, useContext, useRef } from "react";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// import { EditorState, ContentState } from "draft-js";
// import htmlToDraft from "html-to-draftjs";
import queryString from "query-string";
import { stripHtml } from "../Utils";
import Checkbox from "@mui/material/Checkbox";
import { TablePagination, Tooltip } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Button from "@mui/material/Button";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import Warning from "@mui/icons-material/ErrorOutline";
import Slide from "@mui/material/Slide";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import moment from "moment";
import "moment-duration-format";
import TimeZoneData from "../data/timezones.json";
import Comments from "./Comments";
import ExpertPreviewWrapperNext from "./wrappers/ExpertPreviewWrapperNext";
import AttachToProject from "./AttachToProject";
import { authContext } from "../context/auth";
import ExpertSnippet from "../components/ExpertSnippet";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";

//  utils
import { generateOptedOutData } from "../Utils";

const timeZones = TimeZoneData.zones;
const MONTH = {
    JANUARY: 0,
    FEBRUARY: 1,
    MARCH: 2,
    APRIL: 3,
    MAY: 4,
    JUNE: 5,
    JULY: 6,
    AUGUST: 7,
    SEPTEMBER: 8,
    OCTOBER: 9,
    NOVEMBER: 10,
    DECEMBER: 11,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(2),
        overflowX: "auto",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    appBar: {
        position: "relative",
    },
    table: {
        width: "100%",
        minWidth: 750,
    },
    expert: {
        paddingLeft: "0",
        verticalAlign: "top",
    },
    avatarCell: {
        paddingLeft: "0",
        paddingRight: "0",
    },
    usernameAvatar: {
        color: "#FFF",
        backgroundColor: "#999",
    },
    checkBoxCellTop: {
        padding: "0",
        textAlign: "right",
    },
    checkBoxCellBody: {
        paddingTop: "0",
        paddingLeft: "0",
        paddingRight: "0",
        verticalAlign: "top",
        textAlign: "right",
    },
    verified: {
        fontSize: "2.75rem",
    },
    button: {
        margin: theme.spacing(1),
    },
    buttonLink: {
        color: "#3c8864",
        textDecoration: "none",
        fontSize: "1rem",
        padding: "0",
        margin: "0",
    },
    nameButton: {
        color: "#523197",
        padding: "0",
        textDecoration: "none",
        fontSize: "1.25rem",
        fontWeight: "normal",
        textTransform: "none",
        minWidth: "auto",
        marginTop: "5px",
        marginBottom: "5px",
        lineHeight: "100%",
    },
    buttonRight: {
        float: "right",
    },
    link: {
        color: "#3c8864",
        textDecoration: "none",
        fontSize: "1.25rem",
    },
    linkColor: {
        color: "#3c8864",
        textDecoration: "none",
    },
    linkColorWarning: {
        color: theme.palette.secondary.main,
        textDecoration: "none",
    },
    incomplete: {
        color: "red",
        fontSize: ".75rem",
    },
    tags: {
        display: "inline",
        fontSize: ".75rem",
        color: "#3c8864",
        padding: "0",
        margin: "0",
        textTransform: "capitalize",
        fontWeight: "normal",
        lineHeight: "130%",
        minWidth: "auto",
        textDecoration: "none",
    },
    headline: {
        color: "#444",
        fontSize: ".65rem",
        marginTop: "0",
        marginBottom: "12px",
        fontWeight: "700",
    },
    subHeadline: {
        color: "#444",
        fontSize: ".65rem",
        marginTop: "0",
        marginBottom: "12px",
    },
    company: {
        color: "#000",
        fontSize: ".85rem",
        marginTop: "0",
        marginBottom: "5px",
    },
    consultation_rate: {
        color: "#325C55",
        fontWeight: "bold",
        fontSize: ".75rem",
        marginTop: "5px",
        marginBottom: "8px",
        textTransform: "uppercase",
    },
    biography: {
        color: "#666",
        fontSize: ".75rem",
        marginTop: "0",
        marginBottom: "1.5rem",
    },
    readMoreLink: {
        cursor: "pointer",
    },
    meta: {
        color: "#000",
        fontSize: ".65rem",
        marginTop: "0",
    },
    contact: {
        color: "#000",
        fontSize: ".75rem",
        marginTop: "0",
    },
    dnc: {
        color: "red",
        fontWeight: "bold",
        fontSize: ".75rem",
        marginTop: "5px",
        marginBottom: "8px",
        textTransform: "uppercase",
    },
    nospace: {
        fontSize: ".65rem",
        marginTop: "0",
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    projects: {
        listStyleType: "none",
        borderBottom: "1px solid #D5D5D5",
    },
    projectDisplay: {
        fontSize: "1.25rem",
        paddingBottom: "8px",
        marginBottom: "10px",
        borderBottom: "2px solid #D5D5D5",
    },
    complianceFlag: {
        backgroundColor: "red",
        color: "#fff",
        fontSize: ".65rem",
        fontWeight: "bold",
        padding: "4px",
        borderRadius: "3px",
        textAlign: "center",
    },
    warningIcon: {
        fontSize: ".8rem",
    },
    addtoproject: {
        position: "sticky",
        top: "60px",
        // border: "10px solid red",
        background: "#fafafa",
        zIndex: "900",
        // boxShadow: "5px 3px 5px #ccc",
    },
    displayNone: {
        display: "none",
    },
    verUserIcon: {
        fontSize: "small",
        marginRight: ".3rem",
    },
    additionalMatches: {
        backgroundColor: "yellow",
        marginRight: "1.5rem",
    },
    individualTag: {
        width: "fit-content",
        textTransform: "capitalize",
        backgroundColor: "#b8d0db",
        fontSize: ".7rem",
        margin: ".1rem",
    },
    activities: {
        fontSize: ".7rem",
        color: "white",
        width: "fit-content",
        marginBottom: ".15rem",
        paddingLeft: ".4rem",
        paddingRight: ".4rem",
    },
    boldText: {
        fontWeight: "700",
    },
    accepted: {
        backgroundColor: "#4D5652",
    },
    declined: {
        backgroundColor: "#97737C",
    },
    scheduled: {
        backgroundColor: "#7a7a5d",
    },
    activitiesContainer: {
        marginTop: ".5rem",
        marginBottom: "1rem",
    },
    selectLabel: {
        marginBottom: "0",
        color: "#000",
        fontSize: ".875rem",
        fontWeight: "400",
    },
    selectBody: {
        marginLeft: ".5rem",
        width: "10vw",
        fontSize: ".875rem",
        fontWeight: "400",
    },
    locationInfo: {
        textAlign: "left",
        marginBottom: "0",
    },
    onboardedBy: {
        textAlign: "left",
    },
    switchContainer: {
        marginLeft: "0.5rem",
    },
    switchLabel: {
        fontSize: ".875rem",
        fontWeight: "400",
        textAlign: "center",
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

let filteredDataArray = [];
function ExpertResults(props) {
    // console.log(props, " :: props expert results");
    // console.log(" :: expert results rerendering");
    const [open, setOpen] = useState(false);
    const [currentExpert, setCurrentExpert] = useState(false);
    const classes = useStyles();
    const { match, history } = props;
    const { setAuthData, auth } = useContext(authContext);
    const pageUrl = props.url;
    const params = queryString.parse(pageUrl);
    const [expertData, setExpertData] = useState([]);
    const [zones, setTimeZone] = useState([]);
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("name");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [selected, setSelected] = useState([]);
    const [activeBtn, setActiveBtn] = useState(true);
    const [openPreview, setExpertPreview] = useState(false);
    const [selectInput, setSelectInput] = useState("default");
    const [isSwitchActive, setIsSwitchActive] = useState(false);
    const [listIndex, setListIndex] = useState(0);
    // added ref to zoom back to top of page on pagination
    const focusRefContainer = useRef(null);
    const [exp, setExpertPreviewData] = useState({
        id: "",
        prefix: "",
        fname: "",
        mname: "",
        lname: "",
        headline: "",
        biography: "",
        suffix: "",
        country: "",
        state: [],
        city: [],
        address: "",
        zipcode: "",
        timezone: [],
        us: false,
        country_code: "",
        phone: "",
        extension: "",
        alternate_phones: [],
        alternate_emails: [],
        email: "",
        linkedin_url: "",
        employment: [],
        current_employment: [],
        past_employment: [],
        education: [],
        industry: "Healthcare",
        specialty: [],
        terms_signed: false,
        terms_version: "",
        terms_date: "",
        terms_origin: "",
        disclosures: false,
        // disclosures_form_review_required: false,
        tutorial_completed: false,
        tutorial_date: "",
        status: "Lead",
        dnc: "",
        created_by: 0,
        created_by_name: "",
        created_on: "",
        updated_on: "",
        tags: [],
        projects: [],
        selectedCountry: null,
        minimum_hourly: 0,
        consultation_rate: "",
        consultation_rate_currency: "USD",
        group_meeting_rate: "",
        group_meeting_rate_currency: "USD",
        point_person_id: "",
        background_check: false,
        comments: [],
        person_id: 0,
        notes: "",
        blinded_bio: "",
        opted_out: false,
        disclosure_questions: [],
        onboarding: [],
    });

    const lastColumnStyle = {
        width: "20%",
        paddingLeft: "0",
        verticalAlign: "top",
    };

    useEffect(() => {
        setExpertData(props.data);
        console.log(props.data);
    }, [props.data]);

    // function to zoomo to top of page
    const focusTopPage = () => {
        console.log(focusRefContainer.current.offsetTop, "refOffsetTop");
        // window.scrollTo(0, 0);
        // window.scrollTo({
        //     top: focusRefContainer.current.offsetTop,
        //     behavior: "smooth",
        // });
        focusRefContainer.current.scrollIntoView({ behavior: "smooth", block: "start" });
    };

    const fetchExpertById = useCallback(async (id) => {
        const zonesObject = await timeZones.map((item) => {
            return {
                value: item.value,
                label: item.text,
            };
        });
        setTimeZone(zonesObject);
        const eid = await axios.get(`/api/expert/findbyid?id=${id}`);
        if (eid.status === 200 && eid.data && eid.data.data) {
            if (eid.data.data[0].length === 0) return;
            const person = eid.data.data[0][0];
            const emails = eid.data.data[1];
            const phoneNumbers = eid.data.data[2];
            const address = eid.data.data[3][0];
            const tagsArr = eid.data.data[4].map((tag) => {
                return { label: tag.name, value: tag.id };
            });
            const specialty = eid.data.data[5].map((s) => {
                return { label: s.name, value: s.id };
            });
            const tz = zones.filter((z) => {
                if (z.value === person.timezone) {
                    return z;
                }
            });
            const onboarding = eid.data.data[11];
            const countryCode = phoneNumbers.filter((phone) => phone.isprimary).map((phone) => phone.code)[0];
            // const contentBlock = htmlToDraft(person.biography);
            // if (contentBlock) {
            //   const contentState = ContentState.createFromBlockArray(
            //     contentBlock.contentBlocks
            //   );
            //   setEditorState(EditorState.createWithContent(contentState));
            // }
            const current_employment = eid.data.data[6].filter((item) => item.present === true);
            const past_employment = eid.data.data[6].filter((item) => item.present !== true);
            let mostRecentJob = "";
            if (current_employment.length > 0) {
                mostRecentJob = `${current_employment[0].position}, ${current_employment[0].company}`;
            }
            current_employment.sort(function (b, a) {
                return a.from_year - b.from_year || MONTH[a.from_month] - MONTH[b.from_month];
            });
            past_employment.sort(function (b, a) {
                return a.to_year - b.to_year || MONTH[a.to_month] - MONTH[b.to_month];
            });
            const projectData = eid.data.data[9];
            // Sort the array by updated_on
            const projectDataArray = [];
            for (let i = 0; i < projectData.length; i++) {
                const proj = {
                    id: projectData[i].project_id,
                    title: projectData[i].title,
                    status: projectData[i].project_status,
                    type: projectData[i].project_type,
                    updated: new Date(projectData[i].created_on),
                };
                projectDataArray.push(proj);
            }
            const projects = projectDataArray.slice().sort((a, b) => b.updated - a.updated);
            console.log("PROJECTS ATTACHED TO: ", projects);
            const disclosure_questions = eid.data.data[10].length > 0 ? eid.data.data[10].reverse() : [];

            // optout data
            const optoutInfo = generateOptedOutData(eid.data.data[13]);

            setExpertPreviewData({
                ...exp,
                id,
                prefix: person.prefix,
                fname: person.fname,
                mname: person.mname,
                lname: person.lname,
                headline: person.headline ? person.headline : mostRecentJob,
                biography: person.biography,
                suffix: person.suffix,
                us: address ? address.country_id === 180 : false,
                country: address ? { value: address.country_code, label: address.country } : "",
                state: address ? { value: address.abbreviation, label: address.state } : "",
                city: address ? address.city : "",
                address: address ? address.address1 : "",
                zipcode: address ? address.postal_code : "",
                timezone: tz.length > 0 ? tz[0] : { value: "" },
                country_code: countryCode || "+1",
                phone:
                    phoneNumbers.length === 0
                        ? []
                        : phoneNumbers.filter((phone) => phone.isprimary).map((phone) => phone.number)[0],
                email: emails.filter((email) => email.isprimary).map((email) => email.email)[0],
                alternate_phones: phoneNumbers.filter((phone) => !phone.isprimary),
                alternate_emails: emails.filter((email) => !email.isprimary),
                linkedin_url: person.linkedin_url,
                employment: eid.data.data[6],
                current_employment,
                past_employment,
                education: eid.data.data[7],
                specialty,
                industry: "Healthcare",
                terms_signed: person.has_signed_terms,
                terms_version: person.terms_version,
                terms_date: person.terms_date,
                terms_origin: person.terms_origin,
                disclosures: person.disclosures,
                // disclosures_form_review_required: person.disclosures_form_review_required,
                tutorial_completed: person.has_completed_tutorial,
                tutorial_date: person.tutorial_date,
                status: person.expert_status,
                dnc: person.dnc,
                created_by: person.created_by,
                created_by_name: person.created_by_name,
                created_on: person.created_on,
                updated_on: person.updated_on,
                tags: tagsArr,
                projects,
                minimum_hourly: person.minimum_hourly,
                consultation_rate: person.consultation_rate,
                consultation_rate_currency: person.consultation_rate_currency,
                group_meeting_rate: person.group_meeting_rate,
                group_meeting_rate_currency: person.group_meeting_rate_currency,
                point_person_id: person.point_person_id,
                background_check: person.background_check,
                comments: eid.data.data[8],
                person_id: person.person_id,
                notes: person.notes,
                blinded_bio: person.blinded_bio,
                opted_out: person.opted_out,
                disclosure_questions,
                onboarding,
                opted_out_info: optoutInfo,
            });
        }
    }, []);

    function formatDateString(str) {
        return moment(str).format("MMM Do, YYYY");
    }

    function formatPhoneNumber(str, usa) {
        const code = usa !== "" ? usa : "+1";
        const cleaned = `${str}`.replace(/\D/g, "");
        const formatted = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if ((formatted && code) || (formatted && code === "+1")) {
            return `(${formatted[1]}) ${formatted[2]}-${formatted[3]}`;
        }
        return str;
    }

    function handleClose() {
        setExpertPreview(false);
        document.getElementById(`${filteredDataArray[listIndex].id}_expert`).scrollIntoView({
            behavior: "smooth",
        });
    }

    const loadFullBio = (expertId) => {
        // console.log("full bio loaded");
        // console.log(expertId, " :: id");
        axios
            .post(
                "/api/expert/getFullBio",
                { expertId },
                {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            )
            .then((result) => {
                // console.log(result, " :: full Bio")
                if (result.data.fullBio.length > 0) {
                    const expertBio = result.data.fullBio[0].biography;
                    // console.log(expertData, " :: expertData");
                    const newExpertArr = expertData.map((exp) => {
                        if (exp.id === expertId) {
                            exp.fullBio = expertBio;
                        }
                        return exp;
                    });
                    // console.log(newExpertArr, " :: newExpArr");
                    setExpertData(newExpertArr);
                }
            })
            .catch((err) => {
                console.log(err, " :: there was an error fetching full expert bio");
            });
    };

    function createInitialForAvatar(fname, lname) {
        return `${fname.charAt(0)}${lname.charAt(0)}`;
    }

    function desc(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function stableSort(array, cmp) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const tOrder = cmp(a[0], b[0]);
            if (order !== 0) return tOrder;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    function getSorting(o, oBy) {
        return o === "desc" ? (a, b) => desc(a, b, oBy) : (a, b) => -desc(a, b, oBy);
    }
    function EnhancedTableHead(props) {
        const { onSelectAllClick, numSelected, rowCount } = props;
        const createSortHandler = (property) => (event) => {
            props.onRequestSort(event, property);
        };
        return (
            <TableHead>
                <TableRow>
                    <TableCell width="1%" className={classes.checkBoxCellTop}>
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{ "aria-label": "select all" }}
                        />
                    </TableCell>
                    <TableCell
                        width="25%"
                        key="lname"
                        align="left"
                        padding="none"
                        sortDirection={props.orderBy === "lname" ? props.order : false}
                    >
                        <TableSortLabel
                            active={props.orderBy === "lname"}
                            direction={props.order}
                            onClick={createSortHandler("lname")}
                        >
                            {`Expert Name`}
                            {props.orderBy === "lname" ? (
                                <span className={classes.visuallyHidden}>
                                    {props.order === "desc" ? "sorted descending" : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell colSpan={2}>
                        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                                <div style={{ fontWeight: "400" }}>{`${props.rowCount} Results Found`}</div>
                                {/* TODO: sort by responsiveness (need to review code) */}
                                {/* <div style={{ marginLeft: "1.5rem" }}>
                                    <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                                        <InputLabel className={classes.selectLabel}>Sort By:</InputLabel>
                                        <Select
                                            value={selectInput}
                                            className={classes.selectBody}
                                            onChange={handleSortingChange}
                                        >
                                            <MenuItem value={"default"}>Default</MenuItem>
                                            <MenuItem value={"responsive"}>Responsiveness</MenuItem>
                                        </Select>
                                    </Box>
                                </div> */}
                                {/* TODO: filter if in project */}
                                {/* <div>
                                    <Box
                                        className={classes.switchContainer}
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <div className={classes.switchLabel}>
                                            {`Remove Experts`}
                                            <br />
                                            {`In Project`}
                                        </div>
                                        <Switch checked={isSwitchActive} onChange={handleSwitch} color="primary" />
                                    </Box>
                                </div> */}
                            </Box>
                            <TablePagination
                                rowsPerPageOptions={[20, 50, 75, 100]}
                                component="div"
                                count={props.rowCount}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                backIconButtonProps={{
                                    "aria-label": "previous page",
                                }}
                                nextIconButtonProps={{
                                    "aria-label": "next page",
                                }}
                                onPageChange={handleChangeTopPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Box>
                    </TableCell>
                </TableRow>
            </TableHead>
        );
    }
    EnhancedTableHead.propTypes = {
        classes: PropTypes.object.isRequired,
        numSelected: PropTypes.number.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        onSelectAllClick: PropTypes.func.isRequired,
        order: PropTypes.oneOf(["asc", "desc"]).isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired,
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = props.data.map((n) => n.id);
            setSelected(newSelecteds);
            setActiveBtn(false);
            return;
        }
        setSelected([]);
        setActiveBtn(true);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        // useState Array: 'selected' to attach to project
        setSelected(newSelected);
        // Enable/Disable Buttons
        if (event.target.checked) {
            setActiveBtn(false);
        } else {
            newSelected.length < 1 ? setActiveBtn(true) : setActiveBtn(false);
        }
    };

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    };

    const handleChangeTopPage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeBottomPage = (event, newPage) => {
        setPage(newPage);
        focusTopPage();
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const formatStatusType = (status) => {
        if (status === 4) {
            return "Accepted";
        } else if (status === 5) {
            return "Declined";
        } else if (status === 13) {
            return "Scheduled";
        }
    };

    const returnCorrectClass = (status) => {
        if (status === 4) {
            return classes.accepted;
        } else if (status === 5) {
            return classes.declined;
        } else if (status === 13) {
            return classes.scheduled;
        }
    };

    const sortEmpHistory = (jobArray) => {
        return jobArray.sort((a, b) => {
            if (a.end_month === "Present") {
                return -1;
            } else {
                return 0;
            }
        });
    };

    const removeOldJobs = (jobArray) => {
        return jobArray.filter((job) => {
            return job.within_2_years === true;
        });
    };

    const renderLocationInfo = (city, state, country) => {
        // console.log(city, state, country, " :: city, state, country");
        const locationString = [];
        if (city) {
            locationString.push(city);
        }
        if (state) {
            locationString.push(state);
        }
        if (country) {
            locationString.push(state);
        }
        if (locationString.length > 0) {
            return locationString.join(", ");
        } else {
            return "Location N/A";
        }
    };

    const createResponsiveAttr = (dataArray) => {
        const newDataArray = dataArray.map((expert) => {
            if (expert.expert_status_list) {
                const latestDate = new Date(
                    Math.max(
                        ...expert.expert_status_list.map((status) => {
                            return new Date(status.created_on);
                        })
                    )
                );
                return { ...expert, responsiveness: latestDate };
            } else {
                return { ...expert, responsiveness: new Date(1970, 1, 1) };
            }
        });
        return newDataArray;
    };

    const responsiveSort = (dataArray) => {
        return dataArray.sort((a, b) => {
            return new Date(b.responsiveness) - new Date(a.responsiveness);
        });
    };

    const filterFunction = (dataArray) => {
        let newDataArray = responsiveFilter(dataArray);
        newDataArray = expertProjectFilter(newDataArray);
        return newDataArray;
    };

    const responsiveFilter = (dataArray) => {
        if (selectInput === "default") {
            return dataArray;
        } else if (selectInput === "responsive") {
            return responsiveSort(createResponsiveAttr(dataArray));
        }
    };

    const expertProjectFilter = (dataArray) => {
        if (isSwitchActive) {
            const projectId = props.project.id;
            return dataArray.filter((expert) => {
                if (expert.project_id_list) {
                    const isExpertInProj = expert.project_id_list.find((expProjId) => {
                        return expProjId === projectId;
                    });
                    if (isExpertInProj) {
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            });
        } else {
            return dataArray;
        }
    };

    const handleSortingChange = (event) => {
        console.log(event.target.value);
        const selection = event.target.value;
        if (selection === "default") {
            setSelectInput("default");
        } else if (selection === "responsive") {
            setSelectInput("responsive");
        }
    };

    const handleSwitch = () => {
        setIsSwitchActive(!isSwitchActive);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.data.length - page * rowsPerPage);

    const calculatePageNumber = (pageSize, itemIndex) => {
        return Math.ceil(++itemIndex / pageSize);
    };

    function attachExperts() {
        const url = `/api/project/attach/${props.project.id}`;
        const experts = { expert_id: selected, rmid: auth.data.id };
        axios
            .post(url, experts, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    // HAD to pass history as props to this component
                    props.history.push(`/project/view/${props.project.id}`);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    function hightlightSearchTerm(result) {
        const terms = params.term ? params.term.split(",") : [];
        const companies = params.company ? params.company.split(",,") : [];
        const positions = params.position ? params.position.split(",") : [];
        terms.forEach((item) => {
            const substring = new RegExp(item, "gi");
            result = result.replace(substring, (highlight) => `<span class="highlight">${highlight}</span>`);
        });
        positions.forEach((item) => {
            const substring = new RegExp(item, "gi");
            result = result.replace(substring, (highlight) => `<span class="highlight">${highlight}</span>`);
        });
        companies.forEach((item) => {
            const substring = new RegExp(item, "gi");
            result = result.replace(substring, (highlight) => `<span class="highlight">${highlight}</span>`);
        });
        return result;
    }

    // added this keyword count function that is part of the logic that builds the # of keywords
    // NOT being displayed in the truncated bio.
    const keywordCount = (inputText) => {
        const terms = params.term ? params.term.split(",") : [];
        const companies = params.company ? params.company.split(",") : [];
        const positions = params.position ? params.position.split(",") : [];
        let numMatches = 0;
        terms.forEach((item) => {
            const substring = new RegExp(item, "gi");
            if (inputText.match(substring)) {
                numMatches += inputText.match(substring).length;
            }
        });

        positions.forEach((item) => {
            const substring = new RegExp(item, "gi");
            if (inputText.match(substring)) {
                numMatches += inputText.match(substring).length;
            }
        });

        companies.forEach((item) => {
            const substring = new RegExp(item, "gi");
            if (inputText.match(substring)) {
                numMatches += inputText.match(substring).length;
            }
        });

        return numMatches;
    };

    // this is the other half of the keyword count and what actually renders the item
    const renderKeywordCount = (inputText) => {
        const numMatches = keywordCount(stripHtml(inputText)) - keywordCount(stripHtml(inputText.trunc(750)));
        if (numMatches > 0) {
            return (
                <Box display="flex" justifyContent="flex-end">
                    <div className={classes.additionalMatches}>{`AND ${numMatches} MORE MATCHE(S)`}</div>
                </Box>
            );
        }
    };

    function setExpertCommentsArray(arr) {
        if (typeof arr === "object") {
            setExpertPreviewData({
                ...exp,
                comments: arr,
            });
        }
    }

    function openExpertPreview(expertId) {
        setCurrentExpert(expertId);
        setOpen(true);
    }

    function navigateToExpert() {
        history.push(`/expert/view/${exp.id}`);
    }
    function navigateToExpertEdit() {
        history.push(`/expert/edit/${exp.id}`);
    }
    function reloadPageTagSearch(tid) {
        history.push(`/search?type=expert&and_tags=${tid}`);
        window.location.reload();
    }

    return (
        <div>
            {props.project.display && <div className={classes.projectDisplay}>{props.project.title}</div>}
            <Grid
                container
                spacing={2}
                className={`${classes.addtoproject} ${selected.length > 0 ? "" : classes.displayNone}`}
            >
                <Grid item>
                    {props.project.display ? (
                        <AttachToProject
                            selected={selected}
                            btnText="Add to Project"
                            setFullWidth
                            disabled={activeBtn}
                            project_id={props.project.id}
                            user={auth.data}
                        />
                    ) : (
                        <AttachToProject
                            selected={selected}
                            btnText="Add to Project"
                            setFullWidth
                            user={auth.data}
                            disabled={activeBtn}
                        />
                    )}
                </Grid>
                <Grid item>
                    <ExpertSnippet expertList={selected} size="small" fullWidth={true} />
                </Grid>
                <Grid item xs={12} sm={10}>
                    &nbsp;
                </Grid>
            </Grid>
            {/* <TablePagination
                rowsPerPageOptions={[20, 50, 75, 100]}
                component="div"
                count={props.data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                    "aria-label": "previous page",
                }}
                nextIconButtonProps={{
                    "aria-label": "next page",
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
            <Table className={classes.table} size="small" ref={focusRefContainer}>
                {/* changed table head quite a bit by moving stuff around and adding some tablecells */}
                <EnhancedTableHead
                    classes={classes}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={filterFunction(props.data).length}
                />
                <TableBody>
                    {filterFunction(stableSort(props.data, getSorting(order, orderBy)))
                        .map((row, index, arr) => {
                            // console.log(row, " :: row in ExpertResults");
                            const isItemSelected = isSelected(row.id);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            const key = `key_${row.id}_${index}`;
                            if (filteredDataArray !== arr) filteredDataArray = arr;
                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={key}
                                    selected={isItemSelected}
                                >
                                    <TableCell
                                        className={classes.checkBoxCellBody}
                                        onClick={(event) => handleClick(event, row.id)}
                                    >
                                        <Checkbox
                                            checked={isItemSelected}
                                            inputProps={{ "aria-labelledby": labelId }}
                                        />
                                    </TableCell>
                                    <TableCell align="left" className={classes.expert} id={`${row.id}_expert`}>
                                        {/* TODO: add back compliance flag */}
                                        {/* {row.disclosures_form_review_required && (
                                            <div className={classes.complianceFlag}>
                                                <Warning className={classes.warningIcon} /> COMPLIANCE ALERT: PLEASE
                                                REVIEW
                                            </div>
                                        )} */}
                                        <Button
                                            className={classes.nameButton}
                                            onClick={() => openExpertPreview(row.id)}
                                        >
                                            {row.has_signed_terms && row.disclosures && (
                                                <VerifiedUserIcon className={classes.verUserIcon} />
                                            )}{" "}
                                            {row.prefix && row.prefix} {row.fname} {row.mname && row.mname} {row.lname}
                                            {row.suffix && `, ${row.suffix}`}
                                        </Button>
                                        {row.consultation_rate && row.dnc.length === 0 ? (
                                            <p className={classes.consultation_rate}>
                                                Consultation Rate: {row.consultation_rate}{" "}
                                                {row.consultation_rate_currency}
                                            </p>
                                        ) : (
                                            <p className={classes.consultation_rate}>
                                                {row.dnc || row.opted_out ? (
                                                    <strong className={classes.dnc}>Do Not Contact</strong>
                                                ) : (
                                                    <Link
                                                        to={`/expert/edit/${row.id}`}
                                                        className={`${classes.linkColorWarning} ${classes.displayNone}`}
                                                    >
                                                        Set Rate Now
                                                    </Link>
                                                )}
                                            </p>
                                        )}
                                        <p
                                            className={classes.headline}
                                            dangerouslySetInnerHTML={{
                                                __html: hightlightSearchTerm(row.headline),
                                            }}
                                        />
                                        {/* TODO: need to dynamically either put the headline or 2 years employment history if it exists */}
                                        {row.emp_hist_list ? (
                                            sortEmpHistory(removeOldJobs(row.emp_hist_list)).map((job, index) => {
                                                return (
                                                    <div
                                                        className={`${classes.subHeadline} ${classes.ulPadding}`}
                                                        key={index}
                                                    >
                                                        <p
                                                            dangerouslySetInnerHTML={{
                                                                __html: hightlightSearchTerm(
                                                                    `${job.position}, ${job.company} <br /> ${job.start_month} ${job.start_year} - ${job.end_month} ${job.end_year} (${job.duration})`
                                                                ),
                                                            }}
                                                        />
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <div className={classes.subHeadline}>{`~ No Employment History ~`}</div>
                                        )}
                                    </TableCell>
                                    <TableCell align="left">
                                        {/* <p
                                            className={classes.biography}
                                            dangerouslySetInnerHTML={{
                                                __html: hightlightSearchTerm(stripHtml(row.biography.trunc(750))),
                                            }}
                                        /> */}
                                        {/* here's where they new keyword stuff goes */}
                                        {/* <div>{renderKeywordCount(row.biography)}</div>
                                        {row.biography === "" && (
                                            <span className={classes.incomplete}>* Biography Missing </span>
                                        )} */}
                                        {/* {console.log(row, " :: row")} */}
                                        {row.fullBio ? (
                                            <div className={classes.biography}>{`${stripHtml(row.fullBio)}`}</div>
                                        ) : (
                                            <div className={classes.biography}>
                                                {`${stripHtml(row.biography)}...`}
                                                <a
                                                    className={`${classes.biography} ${classes.readMoreLink}`}
                                                    onClick={() => loadFullBio(row.id)}
                                                >
                                                    Read More
                                                </a>
                                            </div>
                                        )}
                                        {/* TODO: once data comes through need to add activities */}
                                        {row.expert_status_list ? (
                                            <Grid container spacing={2} className={classes.activitiesContainer}>
                                                <Grid item xs={2}>
                                                    <div className={`${classes.activities} ${classes.accepted}`}>
                                                        {`ACCEPTED: `}
                                                        <span
                                                            className={classes.boldText}
                                                        >{`${row.expert_status_list.reduce((acc, currVal) => {
                                                            if (currVal.status_name === 4) {
                                                                return acc + 1;
                                                            } else {
                                                                return acc;
                                                            }
                                                        }, 0)}`}</span>
                                                    </div>
                                                </Grid>
                                                <Grid item xs>
                                                    {row.expert_status_list.map((status, index) => {
                                                        const key = `key_${index}`;
                                                        return (
                                                            <div
                                                                key={key}
                                                                className={`${classes.activities} ${returnCorrectClass(
                                                                    status.status_name
                                                                )}`}
                                                            >{`${formatStatusType(status.status_name).toUpperCase()}: ${
                                                                status.project_title
                                                            }`}</div>
                                                        );
                                                    })}
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            ""
                                        )}
                                    </TableCell>
                                    <TableCell align="left" style={lastColumnStyle}>
                                        {/* TODO:  add location info once data is present */}
                                        <div className={`${classes.headline} ${classes.locationInfo}`}>
                                            {renderLocationInfo(row.city, row.state, row.country)}
                                        </div>
                                        <div
                                            className={`${classes.headline} ${classes.onboardedBy} ${classes.locationInfo}`}
                                        >{`${
                                            row.onboarded_by_name ? `Onboarded By: ${row.onboarded_by_name}` : ""
                                        }`}</div>
                                        <div
                                            className={`${classes.headline} ${classes.onboardedBy} ${classes.locationInfo}`}
                                        >{`${row.email ? `Email: ${row.email}` : ""}`}</div>
                                        <div className={`${classes.headline} ${classes.onboardedBy}`}>{`${
                                            row.phone ? `Phone: ${row.phone}` : ""
                                        }`}</div>
                                        {/* TODO: need to add tag info */}
                                        <Grid container direction="row" justifyContent="flex-start">
                                            {row.tag_list
                                                ? row.tag_list.map((tag, index) => {
                                                      return (
                                                          <Grid item xs={6} key={index}>
                                                              <div className={classes.individualTag}>{tag}</div>
                                                          </Grid>
                                                      );
                                                  })
                                                : ""}
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                            );
                        })
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[20, 50, 75, 100]}
                component="div"
                count={props.data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                    "aria-label": "previous page",
                }}
                nextIconButtonProps={{
                    "aria-label": "next page",
                }}
                onPageChange={handleChangeBottomPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Dialog fullScreen open={openPreview} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" size="large">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            <span className={classes.appBarName}>Quick Preview </span>
                        </Typography>

                        <Button color="inherit" onClick={navigateToExpertEdit}>
                            Edit
                        </Button>
                        <Button color="inherit" onClick={navigateToExpert}>
                            Full Profile
                        </Button>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Grid container spacing={2}>
                        {/* <Grid item sm={1} xs={12}> */} {/* </Grid> */}
                        <Grid item sm={8} xs={12}>
                            {!exp.opted_out ? (
                                <AttachToProject
                                    selected={[exp.id]}
                                    btnText="Add to Project"
                                    setFullWidth
                                    user={auth.data}
                                    disabled={false}
                                />
                            ) : (
                                <AttachToProject
                                    selected={[exp.id]}
                                    btnText="Add to Project"
                                    setFullWidth
                                    user={auth.data}
                                    disabled={true}
                                />
                            )}
                            <br />
                            <Tooltip title="Previous expert">
                                <IconButton
                                    onClick={() => {
                                        if (listIndex == 0) return;
                                        const pageNumber = calculatePageNumber(rowsPerPage, listIndex - 1);
                                        setPage(pageNumber - 1);
                                        fetchExpertById(filteredDataArray[listIndex - 1].id);
                                        setListIndex((li) => li - 1);
                                    }}
                                >
                                    <KeyboardArrowLeftRoundedIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Next expert">
                                <IconButton
                                    sx={{ float: "right" }}
                                    onClick={() => {
                                        if (listIndex == expertData.length) return;
                                        const pageNumber = calculatePageNumber(rowsPerPage, listIndex + 1);
                                        setPage(pageNumber - 1);
                                        fetchExpertById(filteredDataArray[listIndex + 1].id);
                                        setListIndex((li) => li + 1);
                                    }}
                                >
                                    <KeyboardArrowRightRoundedIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <Comments
                                // reference={data.title}
                                user={auth.data}
                                object_type="Expert"
                                object_id={exp.id}
                                formatDate={formatDateString}
                                setComments={setExpertCommentsArray}
                                comments={exp.comments}
                                height="auto"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            {open && <ExpertPreviewWrapperNext expert_id={currentExpert} open={open} setOpen={setOpen} />}
        </div>
    );
}
export default ExpertResults;
