import React, { useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import TextField from "@mui/material/TextField";
import Select from "react-select";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import moment from "moment";
import "moment-duration-format";

const now = new Date();
const currentMonth = now.getMonth();
const currentYear = now.getFullYear();
const years = [];
for (let i = 1959; i < currentYear + 1; i++) {
    years.unshift({ value: i, label: i });
}

const selectMonths = [
    { value: "1", label: "January" },
    { value: "2", label: "February" },
    { value: "3", label: "March" },
    { value: "4", label: "April" },
    { value: "5", label: "May" },
    { value: "6", label: "June" },
    { value: "7", label: "July" },
    { value: "8", label: "August" },
    { value: "9", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
    { value: "Present", label: "Present" },
];

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    section: {
        backgroundColor: "#add0b9",
        color: "#FFFFFF",
        padding: "8px",
        fontSize: "1.25rem",
        borderRadius: "2px",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: "75%",
        flexShrink: 0,
        fontWeight: "normal",
    },
    label: {
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(0.5),
        fontSize: "1rem",
    },
    strong: {
        color: theme.palette.text.primary,
        marginBottom: theme.spacing(0.5),
        fontSize: "1rem",
    },
    dates: {
        fontSize: "1rem",
        color: "#738b7b",
    },
    margin: {
        margin: theme.spacing(1),
    },
    job: {
        padding: "0px",
        backgroundColor: "#ecf8f0",
        border: "1px solid #add0b9",
        borderRadius: "3px",
        color: "#000000",
        fontSize: "1rem",
        marginBottom: theme.spacing(1),
        cursor: "pointer",
    },
    body: {
        padding: "20px",
    },
    handle: {
        backgroundColor: "#add0b9",
        color: "#FFFFFF",
        padding: "5px",
        textAlign: "center",
        fontSize: ".75rem",
    },
}));

function EmploymentFields(props) {
    const classes = useStyles();
    const [jobIndex, setIndex] = useState("");
    const [isEdit, setEdit] = useState(false);
    const [dates, setEmploymentDates] = useState({
        from_month: "",
        from_year: "",
        to_month: "",
        to_year: "",
    });
    const [employment, setEmployment] = useState({
        display_order: "",
        position: "",
        company: "",
        from_month: "",
        from_year: "",
        to_month: "",
        to_year: "",
        duration: "",
        summary: "",
        present: false,
    });

    const [data, setData] = useState({
        jobs: props.employment || [],
    });

    useEffect(() => {
        setData({ jobs: props.employment || [] });
    }, [props]);

    function emptyCurrentEmploymentState() {
        setEmploymentDates({
            ...dates,
            from_month: "",
            from_year: "",
            to_month: "",
            to_year: "",
        });
        setEmployment({
            ...employment,
            display_order: "",
            position: "",
            company: "",
            from_month: "",
            from_year: "",
            to_month: "",
            to_year: "",
            duration: "",
            summary: "",
            present: false,
        });
        setEdit(false);
    }

    function calculateDuration(fmonth, fyear, tmonth, tyear) {
        if (tmonth === "Present") {
            const startDate = moment([fyear, fmonth - 1]);
            const endDate = moment([currentYear, currentMonth - 1]);
            const duration = moment.duration(startDate.diff(endDate)).format("Y [years], M [months]").replace("-", "");
            setEmployment({
                ...employment,
                duration,
            });
            employment.present = true;
            employment.duration = duration;
        } else {
            const startDate = moment([fyear, fmonth - 1]);
            const endDate = moment([tyear, tmonth - 1]);
            const duration = moment.duration(startDate.diff(endDate)).format("Y [years], M [months]").replace("-", "");
            setEmployment({
                ...employment,
                duration,
            });
            employment.duration = duration;
        }
    }

    function handleSelect(selectObj, field) {
        // Use placeholder state object to store select field value object
        setEmploymentDates({
            ...dates,
            [field.name]: selectObj,
        });
        // Set the final Employment Object to be used with single string instead of object
        setEmployment({
            ...employment,
            [field.name]: selectObj.label,
        });
    }

    function handleChange(event) {
        const { target } = event;
        const { value, name } = target;
        setEmployment({
            ...employment,
            [name]: value,
        });
    }
    function handleCheckbox(event) {
        const { target } = event;
        const { name } = target;
        setEmployment({ ...employment, [name]: event.target.checked });
        if (event.target.checked) {
            employment.present = true;
            setEmploymentDates({
                ...dates,
                to_month: { label: "Present", value: "Present" },
                to_year: { label: "", value: "" },
            });
            setEmployment({
                ...employment,
                to_month: "Present",
                to_year: "",
            });
        } else {
            employment.present = false;
        }
    }

    function handleAddJob(event) {
        if (
            employment.position !== "" &&
            employment.company !== "" &&
            dates.from_month !== "" &&
            dates.from_year !== "" &&
            dates.to_month !== ""
        ) {
            if (dates.to_month.value === "Present") {
                dates.to_month.value = currentMonth + 1;
                dates.to_year.value = currentYear;
            }
            calculateDuration(dates.from_month.value, dates.from_year.value, dates.to_month.value, dates.to_year.value);

            data.jobs.push(employment);
            props.setEmploymentHistory(data.jobs);
            emptyCurrentEmploymentState();
            setIndex("");
        }
    }

    function editJobClickEvent(index) {
        props.anchorPoint.current.scrollIntoView({ behavior: "smooth", block: "start" });
        const job = data.jobs[index];
        const fromMonth = selectMonths.find((f) => f.label === job.from_month);
        const toMonth = selectMonths.find((t) => t.label === job.to_month);

        setEdit(true);
        setIndex(index);
        setEmploymentDates({
            ...dates,
            from_month: { label: job.from_month, value: fromMonth.value },
            from_year: { label: job.from_year, value: job.from_year },
            to_month: { label: job.to_month, value: toMonth.value },
            to_year: { label: job.to_year, value: job.to_year },
        });
        setEmployment({
            ...employment,
            display_order: index,
            position: job.position,
            company: job.company,
            from_month: job.from_month,
            from_year: job.from_year,
            to_month: job.to_month,
            to_year: job.to_year,
            duration: job.duration,
            summary: job.summary,
            present: job.present,
        });
    }

    function saveJobEdit(event) {
        const expert = data.jobs[jobIndex];
        expert.display_order = employment.display_order;
        expert.position = employment.position.trim();
        expert.company = employment.company.trim();
        expert.summary = employment.summary;
        expert.present = employment.present;
        expert.from_month = employment.from_month;
        expert.from_year = employment.from_year;
        expert.to_month = employment.to_month;
        expert.to_year = employment.to_year;

        const fromMonth = selectMonths.find((f) => f.label === expert.from_month);
        const toMonth = selectMonths.find((t) => t.label === expert.to_month);
        calculateDuration(
            fromMonth.value,
            expert.from_year,
            toMonth.value === "Present" ? currentMonth + 1 : toMonth.value,
            toMonth.value === "Present" ? currentYear : expert.to_year
        );
        expert.duration = employment.duration;
        props.setEmploymentHistory(data.jobs);
        emptyCurrentEmploymentState();
    }

    function deleteJob(event) {
        if (window.confirm("Are you sure you want to delete this job?")) {
            const job = data.jobs[jobIndex];
            if (job !== "undefined") {
                data.jobs = data.jobs.filter((item) => item !== job);
                // Set master expert state employment object
                props.setEmploymentHistory(data.jobs);
                emptyCurrentEmploymentState();
            }
        }
    }

    return (
        <Grid container spacing={3}>
            <Grid item sm={12} xs={12}>
                <h2 className={classes.section}>Employment</h2>
            </Grid>
            <Grid item sm={6} xs={12}>
                <TextField
                    autoComplete="position"
                    fullWidth
                    id="position"
                    label="Position"
                    margin="normal"
                    name="position"
                    onChange={handleChange}
                    value={employment.position}
                />
            </Grid>
            <Grid item sm={6} xs={12}>
                <TextField
                    autoComplete="company"
                    fullWidth
                    id="company"
                    label="Company"
                    margin="normal"
                    name="company"
                    onChange={handleChange}
                    value={employment.company}
                />
            </Grid>
            <Grid item sm={3} xs={12}>
                <div className={useStyles.label}>From Month</div>
                <Select
                    name="from_month"
                    onChange={handleSelect}
                    options={selectMonths}
                    value={dates.from_month}
                    isDisabled={props.isReadOnly}
                />
            </Grid>
            <Grid item sm={3} xs={12}>
                <div className={useStyles.label}>From Year</div>
                <Select name="from_year" onChange={handleSelect} options={years} value={dates.from_year} />
            </Grid>
            <Grid item sm={3} xs={12}>
                <div className={useStyles.label}>To Month</div>
                <Select
                    name="to_month"
                    onChange={handleSelect}
                    options={selectMonths}
                    value={dates.to_month}
                    isDisabled={props.isReadOnly}
                />
            </Grid>
            <Grid item sm={3} xs={12}>
                <div className={useStyles.label}>To Year</div>
                <Select
                    name="to_year"
                    onChange={handleSelect}
                    options={years}
                    value={dates.to_year}
                    isDisabled={props.isReadOnly}
                />
            </Grid>
            <Grid item sm={6} xs={12}>
                <TextField
                    autoComplete="duration"
                    fullWidth
                    id="duration"
                    label="Duration"
                    margin="normal"
                    name="duration"
                    onChange={handleChange}
                    value={employment.duration}
                    disabled
                />
            </Grid>
            <Grid item sm={6} xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={employment.present}
                            name="present"
                            onChange={handleCheckbox}
                            value={employment.present}
                        />
                    }
                    label="Is Present?"
                />
            </Grid>
            <Grid item sm={12} xs={12}>
                <TextField
                    autoComplete="summary"
                    fullWidth
                    id="summary"
                    label="Summary"
                    margin="normal"
                    multiline
                    name="summary"
                    onChange={handleChange}
                    rows="4"
                    value={employment.summary}
                />
            </Grid>
            {isEdit ? (
                <Grid container spacing={2}>
                    <Grid item sm={9} xs={12}>
                        <Button
                            className={useStyles.submit}
                            color="secondary"
                            fullWidth
                            onClick={saveJobEdit}
                            variant="contained"
                        >
                            Save/Edit Employment
                        </Button>
                    </Grid>
                    <Grid item sm={3} xs={12}>
                        <Button className={useStyles.submit} fullWidth onClick={deleteJob} variant="contained">
                            Delete
                        </Button>
                    </Grid>
                </Grid>
            ) : (
                <Grid item sm={12} xs={12}>
                    <Button className={useStyles.submit} fullWidth onClick={handleAddJob} variant="contained">
                        Add New Employment
                    </Button>
                </Grid>
            )}
            <Grid item sm={12} xs={12}>
                <div id="Employment">
                    {data.jobs.map((job, index) => (
                        <div className={classes.job} key={index} onClick={() => editJobClickEvent(index)}>
                            <div className={classes.handle}>Click to edit this employment item</div>
                            <div className={classes.body}>
                                <h2 className={classes.heading}>
                                    {job.position}, <strong>{job.company}</strong>
                                </h2>
                                <em className={classes.dates}>
                                    {job.from_month} {job.from_year} - {job.to_month} {job.to_year} ({job.duration})
                                </em>
                                <p>{job.summary}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </Grid>
        </Grid>
    );
}

export default EmploymentFields;
