import React, { useState, useEffect, useCallback, useContext } from "react";
// import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import queryString from "query-string";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import axios from "axios";
import Box from "@mui/material/Box";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import "moment-duration-format";
import PageTitle from "../components/PageTitle";
// import loader from "../img/loader-simple.gif";
import { authContext } from "../context/auth";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    dtSegment: {
        backgroundColor: "#ecf8f0",
        border: "1px solid #e5e5e5",
        borderRadius: "3px",
        marginBottom: "20px",
        padding: "20px",
    },
    formFields: {
        border: "3px solid #f8f8f8",
        borderRadius: "2px",
        padding: "12px",
        marginTop: "20px",
        marginBottom: "30px",
    },
    timestamp: {
        fontSize: ".5rem",
        color: "#555",
        textAlign: "left",
        marginBottom: "5px",
    },
    label: {
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(0.5),
        fontSize: ".75rem",
    },
    heading: {
        fontSize: "1.25rem",
        color: "#444",
        textAlign: "left",
        fontWeight: "normal",
        margin: "0",
    },
    nameButton: {
        color: "#523197",
        padding: "0",
        textDecoration: "none",
        fontSize: "1.75rem",
        fontWeight: "normal",
        textTransform: "none",
        minWidth: "auto",
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function ProjectCalls(props) {
    const now = moment();
    const { match, history } = props;
    const pageUrl = props.location.search;
    const params = queryString.parse(pageUrl);
    const classes = useStyles();
    const { auth } = useContext(authContext);
    const [activeBtn, setActiveBtn] = useState(true);
    const [open, setOpen] = useState(false);
    const [openLoader, setOpenLoader] = useState(true);
    const [data, setData] = useState({
        id: props.match.params.id,
        isdeleted: false,
        title: "",
        description: "",
        email_copy: "",
        project_not_received_by_email: true,
        client_id: "",
        client_name: "",
        primary_client_contact: "",
        additional_client_contacts: [],
        expert_ids: [],
        primary_project_manager: [],
        additional_project_managers: [],
        status: "1",
        type: "",
        priority: "4",
        case_code: "",
        calls_planned: "",
        industry: "",
        conference_bridge_setting: "1",
        twilio_bridge_setting: "1",
        external_bridge_numbers: [],
        notifications: [],
        specialty: [],
        tags: [],
        comments: [],
        responses: [],
        scheduled_calls: [],
        completed_calls: [],
        experts: [],
    });
    const [completed, setCompleted] = useState([]);
    const [call, setCallSchedule] = useState({
        id: 0,
        expert_id: params.eid,
        project_id: props.match.params.id,
        dtstamp: "",
        datetime: "",
        starttime: "",
        timezone: "",
        type: 1,
        user_id: auth.data.id,
        reason: "",
        client_id: 0,
    });

    const [tabValue, setTabValue] = useState(0);

    function friendlyDateString(str) {
        return moment(str).format("dddd, MMMM Do, YYYY");
    }
    function friendlyDateTimeString(str) {
        return moment(str).format("dddd, MMMM Do, YYYY @ h:mm a");
    }
    function createdOnString(str) {
        return moment(str).format("MMMM DD, YYYY");
    }

    function handleTabChange(event, newValue) {
        setTabValue(newValue);
    }

    function handleClose() {
        setOpen(false);
    }

    function handleChange(event) {
        const { target } = event;
        const { value, name } = target;
        setCallSchedule({
            ...call,
            [name]: value,
        });
    }

    const fetchCompletedCalls = useCallback(async (id) => {
        const scheduled = await axios.get(`/api/project/completed/${id}`);
        if (scheduled.status === 200 && scheduled.data && scheduled.data.data) {
            const completedList = scheduled.data.data.filter((e) => moment(e.scheduled_datetime) < now);
            const sortedCompleted = completedList.sort(function (b, a) {
                return moment(b.scheduled_datetime) - moment(a.scheduled_datetime);
            });
            console.log("IN THE PAST OR COMPLETED: ", sortedCompleted);
            setCompleted(sortedCompleted);
        }
    }, []);

    const fetchProjectById = useCallback(async (id) => {
        const pData = await axios.get(`/api/project/find?id=${id}`);
        if (pData.status === 200 && pData.data && pData.data.data) {
            if (pData.data.data.projectData.length === 0) return;
            const project = pData.data.data.projectData[0];
            const tags = pData.data.data.tags.map((tag) => {
                return { label: tag.name, value: tag.tag_id };
            });
            const specialty = pData.data.data.specialty.map((s) => {
                return { label: s.name, value: s.specialty_id };
            });
            const pcc = pData.data.data.clientContacts.filter((item) => item.isprimary == true);
            const acc = pData.data.data.clientContacts.filter((item) => item.isprimary !== true);
            const apms = pData.data.data.additionalProjectManagers;
            const { comments } = pData.data.data;

            const settings = pData.data.data.confBridgeSettings;
            const primary = settings.filter((item) => item.isprimary !== false);
            let selectedTypeID = "1";
            let selectedTwilio = "1";
            let extNumbers = [];
            if (primary.length > 0) {
                const primaryData = primary[0];
                selectedTypeID = primaryData.type_id.toString();
                if (primaryData.data && primaryData.data.value !== undefined) {
                    selectedTwilio = primaryData.data.value;
                }
                if (Array.isArray(primaryData.data) && primaryData.data.length > 0) {
                    extNumbers = primaryData.data;
                }
            }
            console.log("FETCHED DATA: ", pData);
            // These are all experts
            const attachedExperts = pData.data.data.experts;
            console.log("EXPERTS ATTACHED: ", attachedExperts);
            setData({
                ...data,
                id: project.id,
                isdeleted: project.isdeleted,
                title: project.title,
                description: project.description,
                email_copy: project.email_copy,
                project_not_received_by_email: project.project_not_received_by_email,
                client_id: project.client_id,
                client_name: project.client_name,
                primary_client_contact: pcc[0],
                additional_client_contacts: acc,
                expert_ids: project.expert_ids,
                primary_project_manager: `${project.project_manager_fname} ${project.project_manager_lname}`,
                additional_project_managers: apms,
                status: project.project_status,
                type: project.project_type,
                priority: project.project_priority,
                case_code: project.case_code,
                calls_planned: project.calls_planned,
                industry: project.industry,
                conference_bridge_setting: selectedTypeID,
                twilio_bridge_setting: selectedTwilio,
                external_bridge_numbers: extNumbers,
                notifications: [],
                specialty,
                tags,
                comments,
                responses: project.responses,
                scheduled_calls: project.scheduled_calls,
                completed_calls: project.completed_calls,
                experts: attachedExperts,
            });
            setOpenLoader(false);
        }
    }, []);

    function navigateToProject() {
        history.push(`/project/view/${props.match.params.id}`);
    }

    useEffect(() => {
        if (props.match.params.id) {
            fetchProjectById(props.match.params.id);
        }
    }, [props]);

    return (
        <div>
            <PageTitle title={data.title} />
            <Backdrop
                // transitionDuration={500}
                className={classes.backdrop}
                open={openLoader}
                onClick={() => {
                    setOpenLoader(false);
                }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container spacing={2}>
                <Grid item sm={12} xs={12}>
                    <Button className={classes.nameButton} onClick={navigateToProject}>
                        {data.title}
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item sm={12} xs={12}>
                    Nothing yet...
                </Grid>
            </Grid>
        </div>
    );
}

ProjectCalls.propTypes = {
    match: PropTypes.object,
};
ProjectCalls.defaultProps = {
    match: null,
};

export default ProjectCalls;
