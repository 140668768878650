import * as React from "react";

// MUI
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

// MUI STYLES
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { moreBasicDateTimeStamp } from "../../Utils";

export default function Carousel({ calls }) {
    /**
     * STATE
     */

    const [count, setCount] = React.useState(0);

    /**
     * HANDLE FUNCS
     */

    function handleClick(leftOrRightClick) {
        if (leftOrRightClick === "left") {
            if (count === 0) {
                setCount(calls?.length - 1);
            } else {
                setCount(count - 1);
            }
        }

        if (leftOrRightClick === "right") {
            if (count === calls?.length - 1) {
                setCount(0);
            } else {
                setCount(count + 1);
            }
        }
    }

    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 0.5rem;
            `}
        >
            <div
                css={css`
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                `}
            >
                {calls?.length > 1 && (calls?.[count]?.is_payment_requested || calls?.[count]?.is_paid) && (
                    <button
                        css={css`
                            position: absolute;
                            left: 0;
                            transform: translateX(-75%) scale(0.7);
                            aspect-ratio: 1;
                            border-radius: 50%;
                            border: 1px solid #a8a29e;
                            background-color: #e7e5e4;
                            &:hover {
                                background-color: #78716c;
                            }
                            & .chevron-icon-left {
                                color: #78716c;
                            }
                            &:hover .chevron-icon-left {
                                color: #e7e5e4;
                            }
                        `}
                        onClick={() => handleClick("left")}
                    >
                        <ChevronLeftIcon className="chevron-icon-left" />
                    </button>
                )}
                <div>
                    <div
                        css={css`
                            letter-spacing: 0.9px;
                            line-height: 1.5;
                            background-color: #e7e5e4;
                            padding: 0.75rem;
                            border-radius: 4px;
                            font-size: 0.75rem;
                            min-width: 10rem;
                            & > div:not(:last-of-type) {
                                margin-bottom: 0.65rem;
                                font-size: 0.75rem;
                            }
                            & > div > span {
                                font-weight: 700;
                                font-size: 0.85rem;
                            }
                        `}
                    >
                        {calls?.[count]?.is_payment_requested && (
                            <div>
                                {moreBasicDateTimeStamp(calls?.[count]?.payment_requested_on)} -{" "}
                                <span>
                                    {calls?.[count]?.payment_requested_amount
                                        ? `$${calls?.[count]?.payment_requested_amount}`
                                        : "unknown amount"}
                                </span>{" "}
                                requested
                            </div>
                        )}

                        {calls?.[count]?.is_paid && (
                            <div>
                                {moreBasicDateTimeStamp(calls?.[count]?.approved_on)} - Payment approved by{" "}
                                <span>{calls?.[count]?.approved_by_name}</span>
                            </div>
                        )}

                        {calls?.[count]?.is_paid && (
                            <div>
                                Paid <span>${calls?.[count]?.expert_charge_amount}</span> at rate{" "}
                                <span>${calls?.[count]?.expert_rate_charge_table}</span> for{" "}
                                <span>{calls?.[count]?.expert_duration_charge_table}</span> minutes
                            </div>
                        )}
                    </div>
                </div>
                {calls?.length > 1 && (calls?.[count]?.is_payment_requested || calls?.[count]?.is_paid) && (
                    <button
                        css={css`
                            position: absolute;
                            right: 0;
                            transform: translateX(75%) scale(0.7);
                            aspect-ratio: 1;
                            border-radius: 50%;
                            border: 1px solid #a8a29e;
                            background-color: #e7e5e4;
                            &:hover {
                                background-color: #78716c;
                            }
                            & .chevron-icon-right {
                                color: #78716c;
                            }
                            &:hover .chevron-icon-right {
                                color: #e7e5e4;
                            }
                        `}
                        onClick={() => handleClick("right")}
                    >
                        <ChevronRightIcon className="chevron-icon-right" />
                    </button>
                )}
            </div>
            <div>
                {calls?.length > 1 && (calls?.[count]?.is_payment_requested || calls?.[count]?.is_paid) && (
                    <div
                        css={css`
                            display: flex;
                            flex-direction: row;
                        `}
                    >
                        {calls.map((_, i) => {
                            return (
                                <button
                                    onClick={() => setCount(i)}
                                    css={css`
                                        display: block;
                                        margin-right: 0.25rem;
                                        border: none;
                                        aspect-ratio: 1;
                                        border-radius: 50%;
                                        background-color: ${i === count ? "#a8a29e" : "#e7e5e4"};
                                        &:hover {
                                            background-color: #a8a29e;
                                        }
                                    `}
                                    key={i}
                                ></button>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
}
