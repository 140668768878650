import React, { useState, useEffect, useContext, useCallback } from "react";
import axios from "axios";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    appBar: {
        position: "relative",
    },
    dtSegment: {
        backgroundColor: "#ecf8f0",
        border: "1px solid #e5e5e5",
        borderRadius: "3px",
        marginBottom: "20px",
        padding: "20px",
    },
    segment: {
        backgroundColor: "#fafafa",
        border: "1px solid #e5e5e5",
        borderRadius: "3px",
        marginBottom: "15px",
        padding: "12px",
    },
    questionBox: {
        marginTop: "15px",
        width: "100%",
        padding: "8px",
    },
    formControl: {
        marginTop: "15px",
    },
    formFields: {
        border: "3px solid #f8f8f8",
        borderRadius: "2px",
        padding: "12px",
        marginTop: "0",
        marginBottom: "30px",
    },
    timestamp: {
        fontSize: ".5rem",
        color: "#555",
        textAlign: "left",
        marginBottom: "5px",
    },
    label: {
        color: theme.palette.text.secondary,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(0.5),
        fontSize: ".75rem",
    },
    columnHeading: {
        fontSize: ".75rem",
        color: "#000",
        textAlign: "left",
        marginTop: "0",
        marginBottom: "6px",
    },
    heading: {
        fontSize: "1.25rem",
        color: "#444",
        textAlign: "left",
        fontWeight: "bold",
        margin: "0",
        padding: "0",
    },
    title: {
        fontSize: ".75rem",
        color: "#999",
        textAlign: "left",
        fontWeight: "normal",
        marginTop: "0",
        marginBottom: "20px",
    },
    schedule: {
        backgroundColor: "#fff",
        border: "1px solid #e8e8e8",
        padding: "10px 5px",
        borderRadius: "3px",
        fontSize: ".85rem",
        color: "#999",
        textAlign: "center",
        fontWeight: "bold",
        marginTop: "0",
        marginBottom: "12px",
    },
    callData: {
        color: "#000",
    },
    expert: {
        cursor: "pointer",
        backgroundColor: "#fafafa",
        border: "1px solid #e8e8e8",
        borderRadius: "3px",
        marginBottom: "20px",
        padding: "15px",
        color: "#999",
    },
    expertCompleted: {
        borderTop: "4px solid #eaeaea",
        marginBottom: "5px",
        paddingTop: "8px",
        color: "#999",
        lineHeight: "110% !important",
    },
    expertNameComplete: {
        fontSize: ".8rem",
        color: "#666",
        textAlign: "left",
        fontWeight: "normal",
        margin: "0",
    },
    expertScheduled: {
        borderTop: "4px solid #c7dbce",
        marginBottom: "5px",
        paddingTop: "8px",
        color: "#999",
        lineHeight: "110% !important",
        overflow: "hidden",
    },
    expertName: {
        fontSize: "1rem",
        color: "#666",
        textAlign: "left",
        fontWeight: "normal",
        margin: "0",
    },
    expertNameSmall: {
        fontSize: ".85rem",
        color: "#325C55",
        textAlign: "left",
        fontWeight: "normal",
        margin: "0",
        padding: "0",
    },
    expertTitle: {
        fontSize: ".75rem",
        color: "#666",
        textAlign: "left",
        fontWeight: "normal",
        margin: "0",
    },
    expertTitleSmall: {
        fontSize: ".65rem",
        color: "#666",
        textAlign: "left",
        fontWeight: "normal",
        margin: "0",
        lineHeight: "100%",
    },
    text: {
        fontSize: ".75rem",
        color: "#333",
        textAlign: "left",
    },
    suggestion: {
        backgroundColor: "#fcfbf2",
        border: "1px solid #f7f6de",
        borderRadius: "3px",
        padding: "12px",
        fontSize: ".75rem",
        color: "#666",
        textAlign: "left",
        marginTop: "20px",
        marginBottom: "20px",
    },
    form: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "fit-content",
    },
    typography: {
        padding: theme.spacing(2),
    },
    nameButton: {
        color: "#3c8864",
        padding: "0",
        textDecoration: "none",
        fontSize: "1.5rem",
        fontWeight: "normal",
        textTransform: "none",
        minWidth: "auto",
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    callType: {
        fontSize: ".75rem",
        color: "#325C55",
        textAlign: "left",
        textTransform: "uppercase",
        marginTop: "0",
        marginBottom: "0",
    },
    scheduled: {
        fontSize: ".55rem",
        color: "green",
        textAlign: "left",
        textTransform: "uppercase",
        marginBottom: "0",
    },
    question: {
        fontSize: ".6rem",
        color: "#000",
        textAlign: "left",
        textTransform: "uppercase",
        fontWeight: "bold",
    },
    reminder: {
        fontSize: ".5rem",
        color: theme.palette.secondary.main,
        textAlign: "left",
        textTransform: "uppercase",
        fontWeight: "bold",
    },
    warningCopy: {
        color: "#CC0000",
        fontSize: ".65rem",
        fontWeight: "bold",
        marginTop: "0",
    },
    completed: {
        backgroundColor: "#325C55",
        padding: "2px",
        fontSize: ".65rem",
        color: "#fff",
        fontWeight: "bold",
        textAlign: "center",
    },
    incomplete: {
        backgroundColor: "#CC0000",
        padding: "2px",
        fontSize: ".65rem",
        color: "#fff",
        fontWeight: "bold",
        textAlign: "center",
    },
    sequence: {
        color: "#444",
        fontSize: ".65rem",
        borderBottom: "3px solid #e5e5e5",
        paddingBottom: "5px",
    },
    datestamp: {
        color: "#666",
        fontSize: ".65rem",
    },
    created: {
        color: "#000",
        fontSize: ".5rem",
        marginBottom: "0",
    },
    updated: {
        color: "#555",
        fontSize: ".5rem",
        marginTop: "0",
        marginBottom: "0",
    },
    iconSmall: {
        fontSize: ".95rem",
    },
    scheduledIcon: {
        fontSize: ".6rem",
        color: "green",
    },
    actions: {
        textAlign: "Center",
    },
    topRight: {
        float: "right",
    },
    importantDate: {
        color: "#CC0000",
        fontWeight: "bold",
    },
    Button: {
        display: "block",
        border: "1px solid #d5d5d5",
        borderRadius: "3px",
        marginRight: "5px",
        marginBottom: "5px",
        fontSize: ".75rem",
        color: "#162835",
    },
    complianceFlag: {
        backgroundColor: "red",
        color: "#fff",
        fontSize: ".65rem",
        fontWeight: "bold",
        padding: "4px",
        borderRadius: "3px",
        textAlign: "center",
        marginBottom: "8px",
    },
    warningIcon: {
        fontSize: ".8rem",
    },
}));

function CallApprovalDashboard() {
    const classes = useStyles();
    const [conferences, setConferences] = useState([]);
    const getConferences = async () => {
        try {
            const callData = await axios.get("/api/project/scheduled/calls");
            if (callData.status === 200 && callData.data && callData.data.uniqueConferences) {
                const conf = callData.data.uniqueConferences;
                setConferences(conf);
            }
        } catch (err) {
            console.error(err.message);
        }
    };

    useEffect(() => {
        getConferences();
    }, []); // includes empty dependency array

    function friendlyDateTimeString(str) {
        return moment(str).format("dddd, MMMM Do, YYYY @ h:mma");
    }
    function formatTimeString(str) {
        return moment(str).format("h:mma");
    }
    function convertSecondsToMinutes(seconds) {
        const minutes = Math.floor(seconds / 60) + " minutes";
        return minutes;
    }

    return (
        <div>
            {conferences.length > 0 ? (
                conferences.map(function (c, idx) {
                    const key = `key_${idx}`;
                    return (
                        <div key={c.conference_sid}>
                            <div className={classes.segment}>
                                <h5 className={classes.columnHeading}>
                                    Project - {c.title} | Conference :{c.conference_friendly_name}
                                </h5>
                                <p className={classes.sequence}>
                                    Call with Expert-{" "}
                                    <b>
                                        {c.fname} {c.lname}
                                    </b>{" "}
                                    | client- <b>{c.name}</b>
                                </p>
                                <p className={classes.sequence}>
                                    {c.event_start
                                        ? `${friendlyDateTimeString(c.event_start)} to `
                                        : "Incomplete Call: "}
                                    {c.event_end ? formatTimeString(c.event_end) : "In Progress"}
                                </p>
                                {c.hasClientJoined && c.hasExpertJoined && (
                                    <p className={classes.completed}>BOTH EXPERT AND CLIENT JOINED</p>
                                )}
                                {!c.hasClientJoined && <p className={classes.incomplete}>CLIENT NEVER JOINED</p>}
                                {!c.hasExpertJoined && <p className={classes.incomplete}>EXPERT NEVER JOINED</p>}
                                {c.calls.map(function (ce, i) {
                                    return (
                                        <div key={ce.sequence_number}>
                                            <p className={classes.datestamp}>
                                                {ce.event_end
                                                    ? formatTimeString(ce.event_end)
                                                    : formatTimeString(ce.event_start)}
                                                {" - "}
                                                <strong>
                                                    {ce.name} ({ce.type} {ce.activity_type})
                                                </strong>{" "}
                                                {ce.from_phone_number}
                                                {" | "}
                                                pin#: {ce.pin}
                                            </p>
                                        </div>
                                    );
                                })}
                                <h5 className={classes.scheduled}>
                                    {c.duration && (
                                        <span>
                                            Call Duration:{" "}
                                            {c.duration < 60
                                                ? `${c.duration} seconds`
                                                : convertSecondsToMinutes(c.duration)}
                                        </span>
                                    )}
                                </h5>
                            </div>
                        </div>
                    );
                })
            ) : (
                <h5 className={classes.warningCopy}>NO CALL EVENTS AVAILABLE</h5>
            )}
        </div>
    );
}
export default CallApprovalDashboard;
