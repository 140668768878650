import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
// import axios from "axios";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

// const HunterAPIKey = "bfdf14fa7f4f152a1db855049a08cebc1b9e6a60";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
    },
    chip: {
        margin: theme.spacing(1),
    },
    list: {
        margin: theme.spacing(1),
        marginLeft: "0",
        paddingInlineStart: "0",
        listStyleType: "none",
        listStylePosition: "outside",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: "75%",
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    margin: {
        margin: theme.spacing(1),
    },
}));

function AddEmails(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const [email, setEmail] = useState({
        email: "",
        score: "",
        result: "",
    });

    const handleExpand = (panel) => (event, isExpanded) => {
        console.log("isExpanded", isExpanded);
        setExpanded(isExpanded ? panel : false);
    };

    function handleChange(event) {
        const { target } = event;
        const { value, name } = target;

        console.log("value", value);
        console.log("name", name);
        setEmail({
            ...email,
            [name]: value,
        });
    }

    function addNewEmail() {
        if (email.email !== "") {
            console.log(email.email);
            setEmail({
                email: email.email,
                score: 0,
                result: "No Score",
            });
            const options = {
                email: email.email,
                score: 0,
                result: "No Score",
            };
            props.data.alternate_emails.push(options);
            setEmail({
                email: "",
                score: "",
                result: "",
            });
        }
    }

    function handleDelete(e) {
        console.log("Selected Email to Delete: ", e);
        if (window.confirm("Are you sure you want to delete this email?")) {
            const filtered = props.data.alternate_emails.filter((item) => item.email !== e);
            console.log("FILTERED EMAIL ARRAY: ", filtered);
            props.removeEvent(filtered);
            setEmail({
                email: "",
                score: "",
                result: "",
            });
        }
    }

    function handleClick(email, score) {
        if (window.confirm("Are you sure you want to make this the primary email?")) {
            props.clickEvent(email);
            // handleDelete(email);
        }
    }

    return (
        <Accordion
            expanded={expanded === "panel1" || props.data.alternate_emails.length > 0}
            onChange={handleExpand("panel1")}
        >
            <AccordionSummary aria-controls="panel1bh-content" expandIcon={<ExpandMoreIcon />} id="panel1bh-header">
                <Typography className={classes.heading}>Add/Check Emails</Typography>
                <Typography className={classes.secondaryHeading}>(Hunter.io)</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={1}>
                    <Grid item sm={12} xs={12}>
                        {props.data.alternate_emails.length > 0 && (
                            <p className={classes.secondaryHeading}>Select an email from the list to make it primary</p>
                        )}
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <div>
                            <ul className={classes.list}>
                                {props.data.alternate_emails.map((email, index) => (
                                    <li key={index}>
                                        <Chip
                                            className={classes.chip}
                                            label={`${email.email} - ${email.score} (${email.result})`}
                                            onClick={() => handleClick(email.email, email.score)}
                                            onDelete={() => handleDelete(email.email)}
                                        />
                                        <Fab
                                            aria-label="check"
                                            className={classes.margin}
                                            color="secondary"
                                            onClick={() => props.checkEmailEvent(email.email)}
                                            size="small"
                                        >
                                            <CheckCircleOutlineIcon />
                                        </Fab>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </Grid>
                    <Grid item sm={10} xs={12}>
                        <TextField
                            fullWidth
                            label="Enter Email"
                            margin="normal"
                            name="email"
                            onChange={handleChange}
                            value={email.email}
                        />
                    </Grid>
                    <Grid item sm={2} xs={12}>
                        <Fab
                            aria-label="add"
                            className={classes.margin}
                            color="secondary"
                            onClick={addNewEmail}
                            size="medium"
                        >
                            <AddIcon />
                        </Fab>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}

export default AddEmails;
