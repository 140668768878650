import React, { useContext, useState, useCallback, useEffect } from "react";
import { Dialog, AppBar, Toolbar, Typography, Button, DialogContent, Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ExpertPreview from "./ExpertPreview";
import { MenuItem, Box, TextField } from "@mui/material";
import Comments from "./Comments";
import { authContext } from "../context/auth";
import { Slide } from "@mui/material";
import axios from "axios";
import TimeZoneData from "../data/timezones.json";
import { useTheme } from "@mui/system";
import moment from "moment";
const timeZones = TimeZoneData.zones;
import htmlToDraft from "html-to-draftjs";
import { EditorState, ContentState } from "draft-js";

//  utils
import { generateOptedOutData } from "../Utils";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default function ExpertPreviewModal(props) {
    console.log("====== in ExpertPreviewModal ======");
    const { open } = props;
    const _state = props.state_manager;
    const { auth } = useContext(authContext);
    const { history, match } = props.props;
    const theme = useTheme();

    const [zones, setTimeZone] = useState([]);
    const [displayProj, displayProjectComments] = useState("block");
    const [displayExp, displayExpertComments] = useState("none");
    const [commentTarget, setCommentTarget] = useState("Project");
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const commentTypes = [
        {
            value: "Expert",
            label: "This Expert Only",
        },
        {
            value: "Project",
            label: "This Project Only",
        },
    ];

    const MONTH = {
        JANUARY: 0,
        FEBRUARY: 1,
        MARCH: 2,
        APRIL: 3,
        MAY: 4,
        JUNE: 5,
        JULY: 6,
        AUGUST: 7,
        SEPTEMBER: 8,
        OCTOBER: 9,
        NOVEMBER: 10,
        DECEMBER: 11,
    };
    // Helpers

    function setCommentsArray(arr) {
        if (typeof arr === "object") {
            _state.set.projectState({
                ..._state.get.projectState,
                comments: arr,
            });
        }
    }
    function setExpertCommentsArray(arr) {
        console.log("COMMENTS ARRAY", arr);
        if (typeof arr === "object") {
            _state.set.expertPreviewData({
                ..._state.get.expertPreviewData,
                comments: arr,
            });
        }
    }
    function formatDateString(str) {
        return moment(str).format("MMM. Do, YYYY, h:mma");
    }
    function handleCommentChange(event) {
        const { target } = event;
        const { value } = target;
        setCommentTarget(value);
        if (value === "Project") {
            displayExpertComments("none");
            displayProjectComments("block");
        } else {
            displayExpertComments("block");
            displayProjectComments("none");
        }
    }
    function navigateToExpert() {
        history.push(`/expert/view/${_state.get.expertPreviewData.id}?pid=${_state.get.projectState.id}`);
    }
    function navigateToExpertEdit() {
        history.push(`/expert/edit/${_state.get.expertPreviewData.id}?pid=${_state.get.projectState.id}`);
    }

    // this is the array that has been through Dan's sorting algorithm
    // it's then sliced so that the pagination matches the experts actually displayed on the
    // project page
    // eslint-disable-next-line prefer-const
    let sortedAndSlicedExpertArray = getSortedArray(_state.get.expertsData);

    // handles the scenario when the prev expert has been clicked
    const handlePrevExpert = () => {
        if (_state.get.expertIndex == 0) return;
        const currentIndex = _state.get.expertIndex;
        let prevIndex;
        // if we're at the beginning of the array
        if (currentIndex === 0) {
            prevIndex = sortedAndSlicedExpertArray.length - 1;
        } else {
            prevIndex = currentIndex - 1;
        }
        const prevExpertId = sortedAndSlicedExpertArray[prevIndex].id;
        displayExpertPreview(prevExpertId);
    };

    // handles the scenario when the next expert is clicked
    const handleNextExpert = () => {
        if (_state.get.expertIndex == sortedAndSlicedExpertArray.length - 1) return;
        const currentIndex = _state.get.expertIndex;
        console.log(currentIndex);

        let nextIndex;
        // if we're at the end of the array
        if (currentIndex === sortedAndSlicedExpertArray.length - 1) {
            nextIndex = 0;
        } else {
            nextIndex = currentIndex + 1;
        }
        const nextExpertId = sortedAndSlicedExpertArray[nextIndex].id;
        displayExpertPreview(nextExpertId);
    };

    const fetchExpertById = useCallback(async (id) => {
        const zonesObject = await timeZones.map((item) => {
            return {
                value: item.value,
                label: item.text,
            };
        });
        setTimeZone(zonesObject);
        const eid = await axios.get(`/api/expert/findbyid?id=${id}`);
        console.log(eid);
        if (eid.status === 200 && eid.data && eid.data.data) {
            console.log("fetching experts......");
            if (eid.data.data[0].length === 0) return;
            const person = eid.data.data[0][0];
            const emails = eid.data.data[1];
            const phoneNumbers = eid.data.data[2];
            const address = eid.data.data[3][0];
            const tags = eid.data.data[4].map((tag) => {
                return { label: tag.name, value: tag.id };
            });
            const specialty = eid.data.data[5].map((s) => {
                return { label: s.name, value: s.id };
            });
            const tz = zones.filter((z) => {
                if (z.value === person.timezone) {
                    return z;
                }
            });
            const onboarding = eid.data.data[11];
            const countryCode = phoneNumbers.filter((phone) => phone.isprimary).map((phone) => phone.code)[0];
            const contentBlock = htmlToDraft(person.biography);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                setEditorState(EditorState.createWithContent(contentState));
            }
            const current_employment = eid.data.data[6].filter((item) => item.present === true);
            const past_employment = eid.data.data[6].filter((item) => item.present !== true);
            let mostRecentJob = "";
            if (current_employment.length > 0) {
                mostRecentJob = `${current_employment[0].position}, ${current_employment[0].company}`;
            }
            current_employment.sort(function (b, a) {
                return a.from_year - b.from_year || MONTH[a.from_month] - MONTH[b.from_month];
            });
            past_employment.sort(function (b, a) {
                return a.to_year - b.to_year || MONTH[a.to_month] - MONTH[b.to_month];
            });
            const disclosure_questions = eid.data.data[10].length > 0 ? eid.data.data[10].reverse() : [];

            // optout data
            const optoutInfo = generateOptedOutData(eid.data.data[13], _state.get.projectState.id);

            _state.set.expertPreviewData({
                ..._state.get.expertPreviewData,
                id,
                prefix: person.prefix,
                fname: person.fname,
                mname: person.mname,
                lname: person.lname,
                headline: person.headline ? person.headline : mostRecentJob,
                biography: person.biography,
                suffix: person.suffix,
                us: address ? address.country_id === 180 : false,
                country: address ? { value: address.country_code, label: address.country } : "",
                state: address ? { value: address.abbreviation, label: address.state } : "",
                city: address ? address.city : "",
                address: address ? address.address1 : "",
                zipcode: address ? address.postal_code : "",
                timezone: tz.length > 0 ? tz[0] : { value: "" },
                country_code: countryCode || "+1",
                phone:
                    phoneNumbers.length === 0
                        ? []
                        : phoneNumbers.filter((phone) => phone.isprimary).map((phone) => phone.number)[0],
                email: emails.filter((email) => email.isprimary).map((email) => email.email)[0],
                alternate_phones: phoneNumbers.filter((phone) => !phone.isprimary),
                alternate_emails: emails.filter((email) => !email.isprimary),
                linkedin_url: person.linkedin_url,
                employment: eid.data.data[6],
                current_employment,
                past_employment,
                education: eid.data.data[7],
                specialty,
                industry: "Healthcare",
                terms_signed: person.has_signed_terms,
                terms_version: person.terms_version,
                terms_date: person.terms_date,
                terms_origin: person.terms_origin,
                disclosures: person.disclosures,
                tutorial_completed: person.has_completed_tutorial,
                tutorial_date: person.tutorial_date,
                status: person.expert_status,
                dnc: person.dnc,
                created_by: person.created_by,
                created_by_name: person.created_by_name,
                created_on: person.created_on,
                updated_on: person.updated_on,
                tags,
                minimum_hourly: person.minimum_hourly,
                consultation_rate: person.consultation_rate,
                consultation_rate_currency: person.consultation_rate_currency,
                group_meeting_rate: person.group_meeting_rate,
                group_meeting_rate_currency: person.group_meeting_rate_currency,
                point_person_id: person.point_person_id,
                background_check: person.background_check,
                comments: eid.data.data[8],
                person_id: person.person_id,
                notes: person.notes,
                blinded_bio: person.blinded_bio,
                opted_out: person.opted_out,
                disclosure_questions,
                onboarding,
                opted_out_info: optoutInfo,
            });
            // console.log(_state.get.expertPreviewData);
        }
    }, []);
    function findExpertsIndexInArray(arr, id) {
        return arr.findIndex((expert) => {
            return expert.id === id;
        });
    }
    function displayExpertPreview(id) {
        // setting the index of the current expert before modal pops up
        _state.set.expertIndex(findExpertsIndexInArray(sortedAndSlicedExpertArray, id));
        console.log("id:" + id);
        fetchExpertById(id);
        // setExpertPreview(true);
    }

    function getSortedArray(arr) {
        try {
            // Get accurate sort based on current table state
            const checkboxes = Array.from(document.getElementsByClassName("expert-select-checkbox"));
            const personIds = checkboxes.map((i) => parseInt(i.children[0].id));

            const newArr = arr.slice().sort(function (a, b) {
                const x = personIds.indexOf(a.person_id) - personIds.indexOf(b.person_id);
                return x;
            });

            return newArr;
        } catch (error) {
            // When reloading the page and dom elements are not available
            return arr;
        }
    }

    useEffect(() => {
        // sortedAndSlicedExpertArray = getSortedArray(sortedAndSlicedExpertArray);

        if (_state.get.selectedExpertId !== 0) displayExpertPreview(_state.get.selectedExpertId);
        // console.log(_state.get.expertPreviewData);

        return () => {
            _state.set.selectedExpertId(0);
        };
    }, [open]);
    return (
        <Dialog fullScreen open={open} onClose={_state.switch.expertPreviewModal} TransitionComponent={Transition}>
            <AppBar sx={{ position: "relative" }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={_state.switch.expertPreviewModal}
                        aria-label="close"
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" sx={{ marginLeft: theme.spacing(2), flex: 1 }}>
                        <Box component={"span"} sx={{ color: "#b7ddd9" }}>
                            {_state.get.projectState.title}
                        </Box>
                    </Typography>
                    <Button color="inherit" onClick={navigateToExpertEdit}>
                        Edit
                    </Button>
                    <Button color="inherit" onClick={navigateToExpert}>
                        Full Profile
                    </Button>
                </Toolbar>
            </AppBar>
            <DialogContent>
                <Grid container spacing={2}>
                    {/* created new container for prev / next buttons and spacted apart */}
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="previous expert"
                                onClick={handlePrevExpert}
                                size="large"
                            >
                                <ArrowBackIcon />
                                {"Previous"}
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="next expert"
                                onClick={handleNextExpert}
                                size="large"
                            >
                                {"Next"}
                                <ArrowForwardIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid item sm={9} xs={12}>
                        <ExpertPreview
                            data={_state.get.expertPreviewData}
                            history={props.props.history}
                            user={auth.data}
                            project={_state.get.projectState}
                            filter={_state.get.filter}
                            expertsData={_state.get.expertsData}
                            setFilterResults={_state.set.filterResults}
                        />
                    </Grid>
                    <Grid item sm={3} xs={12}>
                        <TextField
                            fullWidth
                            id="type"
                            name="type"
                            select
                            label="Add Comment To:"
                            value={commentTarget}
                            onChange={handleCommentChange}
                            margin="normal"
                        >
                            {commentTypes.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                        <Box display={displayExp}>
                            <Comments
                                reference={_state.get.projectState.title}
                                user={auth.data}
                                object_type="Expert"
                                object_id={_state.get.expertPreviewData.id}
                                formatDate={formatDateString}
                                setComments={setExpertCommentsArray}
                                comments={_state.get.expertPreviewData.comments}
                                height="auto"
                            />
                        </Box>
                        <Box display={displayProj}>
                            <Comments
                                reference={`${_state.get.expertPreviewData.fname} ${_state.get.expertPreviewData.lname}`}
                                user={auth.data}
                                object_type="Project"
                                object_id={match.params.id}
                                formatDate={formatDateString}
                                setComments={setCommentsArray}
                                comments={_state.get.projectState.comments}
                                height="auto"
                            />
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
