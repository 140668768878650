/* eslint-disable camelcase */
import React, { useState, useEffect, useContext } from "react";
import makeStyles from "@mui/styles/makeStyles";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import axios from "axios";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import ContactFields from "../components/ContactFields";
import CountryListData from "../data/countries.json";
import TimeZoneData from "../data/timezones.json";
import { authContext } from "../context/auth";

const countryCodes = CountryListData.countries;
const timeZones = TimeZoneData.zones;

const useStyles = makeStyles((theme) => ({
    container: {
        flexGrow: 1,
        marginLeft: theme.spacing(1),
    },
    expansionPanel: {
        // width: "500"
        marginTop: theme.spacing(5),
    },
    details: {
        flexDirection: "column",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    formControl: {
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(1)
        minWidth: 200,
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    submitButton: {
        marginTop: theme.spacing(3),
    },
}));

function User(props, id) {
    const classes = useStyles();
    const { setAuthData, auth } = useContext(authContext);
    console.log(auth, " :: auth");
    const [zones, setTimeZone] = useState([]);
    const [formData, setFormData] = useState({
        id: 0,
        suffix: "",
        prefix: "",
        fname: "",
        lname: "",
        mname: "",
        email: "",
        timezone: "Eastern Standard Time",
        phone: "",
        extension: "",
        locationId: 1,
        roleId: 1,
        isAdmin: false,
        isActive: true,
        pref_id: 0,
        dateFormat: "MM/DD/YYYY",
        slackMentions: true,
        slackAdvisorReplies: true,
        slackAdvisorNoshow: true,
        slackAdvisorNoshowDuration: 0,
        slackClientNoshow: true,
        slackClientNoshowDuration: 0,
        country_code: "",
        title: "",
    });
    const [location, setLocation] = useState([]);
    const [role, setRole] = useState([]);
    const [isError, setIsError] = useState(false);
    const { match } = props;
    const cardTitle = match.params.id ? "Edit user" : "Create User";

    useEffect(() => {
        async function fetchLocationData() {
            const zonesObject = await timeZones.map((item) => {
                return {
                    value: item.value,
                    label: item.text,
                };
            });
            // setCountry(countriesObject);
            // setUS(statesObject);
            setTimeZone(zonesObject);
        }

        fetchLocationData();
    }, []);

    useEffect(() => {
        axios
            .get("/api/common", {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    console.log("data", result.data);
                    if (result?.data?.data?.[0]) {
                        setRole(result.data.data[0]);
                    }
                    if (result?.data?.data?.[1]) {
                        setLocation(result.data.data[1]);
                    }
                }
            })
            .then(() => {
                if (props.match.params.id) {
                    axios
                        .get(`/api/person/${props.match.params.id}`, {
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8",
                                "Access-Control-Allow-Origin": "*",
                            },
                        })
                        .then((result) => {
                            if (result.status === 200 && result.data && result.data.data) {
                                console.log("data for get", result.data.data);

                                if (result.data.data.length > 0) {
                                    const person = result.data.data[0][0];
                                    // const tz = zones.filter((z) => {
                                    //     if (z.value === person.timezone) {
                                    //         return z;
                                    //     }
                                    // });
                                    const emails = result.data.data[1];
                                    const phoneNumbers = result.data.data[2];
                                    const prefs = result.data.data[4][0];
                                    let l_id;
                                    if (result.data.data[5].length > 0) {
                                        l_id = result.data.data[5][0].office_location_id;
                                    } else {
                                        l_id = 1;
                                    }
                                    let roleId = 0;
                                    if (result.data.data[6].length > 0) {
                                        roleId = result.data.data[6][0].role_id;
                                    }
                                    let country_code = phoneNumbers
                                        .filter((phone) => phone.isprimary)
                                        .map((phone) => phone.code)[0];
                                    country_code =
                                        country_code === undefined || country_code === null ? "+1" : country_code;
                                    const phone = [];
                                    if (phoneNumbers.length > 0) {
                                        const numbers = phoneNumbers.filter((p) => p.isprimary);
                                        console.log("NUMBERS: ", numbers);
                                        if (numbers && numbers.length > 0) {
                                            phone.push(numbers[0]);
                                        }
                                    }
                                    setFormData({
                                        id: person.id,
                                        suffix: person.suffix == null ? "" : person.suffix,
                                        prefix: person.prefix == null ? "" : person.prefix,
                                        fname: person.fname,
                                        lname: person.lname,
                                        mname: person.mname == null ? "" : person.mname,
                                        title: person.title == null ? "" : person.title,
                                        email: emails.filter((email) => email.isprimary).map((email) => email.email)[0],
                                        timezone: person.timezone,
                                        phone: phone.length > 0 ? phone[0].number : "",
                                        extension: phone.length > 0 ? phone[0].extension : "",
                                        country_code,
                                        locationId: l_id,
                                        roleId,
                                        isAdmin: person.isadmin,
                                        isActive: person.isactive,
                                        pref_id: prefs.id,
                                        dateFormat: prefs.date_format,
                                        slackMentions: prefs.slack_mentions,
                                        slackAdvisorReplies: prefs.slack_advisor_replies,
                                        slackAdvisorNoshow: prefs.slack_advisor_noshow,
                                        slackAdvisorNoshowDuration: prefs.slack_advisor_noshow_duration,
                                        slackClientNoshow: prefs.slack_client_noshow,
                                        slackClientNoshowDuration: prefs.slack_client_noshow_duration,
                                    });
                                }
                            }
                        });
                }
            });
    }, [props, zones]);

    const handleChange = (name) => (event) => {
        if (event.target && event.target.type === "checkbox") {
            setFormData({ ...formData, [name]: event.target.checked });
        } else {
            setFormData({ ...formData, [name]: event.target.value });
        }
    };

    function handleContactFieldsChange(event) {
        const { target } = event;
        const { value } = target;
        const { name } = target;
        setFormData({
            ...formData,
            [name]: value,
        });
    }

    function handlePhoneEvent(event) {
        const { target } = event;
        const { value } = target;
        const { name } = target;
        const phone = value.replace(/[^0-9]/g, "");

        phone.length > 9 ? setIsError(false) : setIsError(true);
        setFormData({
            ...formData,
            [name]: phone,
        });
    }

    function handleSelectChange(event) {
        console.log("Event.target", event.target);
        setFormData((oldFormData) => ({
            ...oldFormData,
            [event.target.name]: event.target.value,
        }));
    }

    function handleSubmit() {
        console.log("click", formData);
        const person = formData;
        if (
            person.fname === "" ||
            person.lname === "" ||
            person.email === "" ||
            // person.phone === "" ||
            person.roleId === 0 ||
            person.locationId === 0
        ) {
            setIsError(true);
            console.log("THis is the person", person);
            return;
        }
        person.userName = person.email;

        if (props.match.params.id) {
            console.log("edit", props.match.params.id);
            axios
                .post("/api/person/updateUser", person, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                })
                .then((result) => {
                    if (result.status === 200 && result.data && result.data.data != null) {
                        console.log("This is the result", result.data.data);
                        // console.log("auth.data", auth.data);
                        // setauth.data(result.data.data);
                        setAuthData(result.data.data);
                        // console.log("auth.data2", auth.data);
                        // setLoggedIn(true);
                        window.location.href = "/admin";
                    } else {
                        setIsError(true);
                    }
                })
                .catch(() => {
                    setIsError(true);
                });
        } else {
            console.log("create", props.match.params.id);
            person.password = "";
            person.timezone = { value: person.timezone };
            console.log("This is the PERSON", person);
            axios
                .post("/api/person/createUser", person, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                    },
                })
                .then((result) => {
                    if (result.status === 200 && result.data && result.data.data != null) {
                        console.log("This is the result", result);
                        // setauth.data(result.data);
                        // setLoggedIn(true);
                    } else {
                        setIsError(true);
                    }
                })
                .catch(() => {
                    setIsError(true);
                });
        }
    }
    if (auth.data.isadmin) {
        return (
            <form onSubmit={handleSubmit}>
                <h1>{cardTitle}</h1>
                {/* <ContactFields
        changeEvent={handleContactFieldsChange}
        countries={countryCodes}
        country_code={formData.country_code}
        email={formData.email}
        errorBoolean={isError}
        fname={formData.fname}
        lname={formData.lname}
        mname={formData.mname}
        phone={formData.phone}
        phoneChangeEvent={handlePhoneEvent}
        prefix={formData.prefix}
        suffix={formData.suffix}
      /> */}
                <ContactFields
                    changeEvent={handleContactFieldsChange}
                    countries={countryCodes}
                    country_code={formData.country_code}
                    email={formData.email}
                    errorBoolean={isError}
                    fname={formData.fname}
                    lname={formData.lname}
                    mname={formData.mname}
                    phone={formData.phone}
                    extension={formData.extension}
                    phoneChangeEvent={handlePhoneEvent}
                    prefix={formData.prefix}
                    suffix={formData.suffix}
                    isReadOnly={false}
                />
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <InputLabel htmlFor="age-simple">Timezone</InputLabel>
                        <Select
                            fullWidth
                            inputProps={{
                                id: "id",
                                name: "timezone",
                            }}
                            onChange={handleSelectChange}
                            value={formData.timezone}
                        >
                            <MenuItem value="Eastern Standard Time">Eastern Standard Time</MenuItem>
                            <MenuItem value="GMT Standard Time">(UTC) Edinburgh, London</MenuItem>
                            <MenuItem value="British Summer Time">(UTC+01:00) Edinburgh, London</MenuItem>
                            <MenuItem value="Israel Standard Time">(UTC+02:00) Jerusalem</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputLabel htmlFor="age-simple">Office Location *</InputLabel>
                        <Select
                            fullWidth
                            error={isError}
                            inputProps={{
                                id: "id",
                                name: "locationId",
                            }}
                            onChange={handleSelectChange}
                            value={formData.locationId}
                        >
                            {location.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputLabel htmlFor="role">Role *</InputLabel>
                        <Select
                            fullWidth
                            error={isError}
                            value={formData.roleId}
                            onChange={handleSelectChange}
                            inputProps={{
                                name: "roleId",
                                id: "id",
                            }}
                        >
                            {role.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formData.isAdmin}
                                    color="primary"
                                    onChange={handleChange("isAdmin")}
                                />
                            }
                            label="Admin"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <InputLabel htmlFor="role">Title (As it would appear on your email signature)</InputLabel>
                        <TextField
                            fullWidth
                            margin="normal"
                            name="title"
                            onChange={handleChange("title")}
                            value={formData.title}
                        />
                    </Grid>
                </Grid>
                <Accordion className={classes.expansionPanel}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>User Settings</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="role">Date Format</InputLabel>
                            <Select
                                value={formData.dateFormat}
                                onChange={handleSelectChange}
                                inputProps={{
                                    name: "dateFormat",
                                    id: "id",
                                }}
                            >
                                <MenuItem key={1} value="MM/DD/YYYY">
                                    <Typography>MM/DD/YYYY</Typography>
                                </MenuItem>
                                <MenuItem key={2} value="DD/MM/YYYY">
                                    <Typography>DD/MM/YYYY</Typography>
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </AccordionDetails>
                </Accordion>
                <Accordion className={classes.expansionPanel}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>Notifications</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.details}>
                        <Grid id="top-row" container spacing={10}>
                            <Grid item xs={6}>
                                <FormControl className={classes.formControl}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formData.slackMentions}
                                                onChange={handleChange("slackMentions")}
                                                value="slackMentions"
                                                color="primary"
                                            />
                                        }
                                        label="Slack mentions"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl className={classes.formControl}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formData.slackAdvisorReplies}
                                                onChange={handleChange("slackAdvisorReplies")}
                                                value="slackAdvisorReplies"
                                                color="primary"
                                            />
                                        }
                                        label="Slack Advisor replies"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid id="top-row" container spacing={10}>
                            <Grid item xs={6}>
                                <FormControl className={classes.formControl}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formData.slackAdvisorNoshow}
                                                onChange={handleChange("slackAdvisorNoshow")}
                                                value="slackAdvisorNoshow"
                                                color="primary"
                                            />
                                        }
                                        label="Slack Advisor noshow"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="fname"
                                    label="No show duration"
                                    className={classes.textField}
                                    value={formData.slackAdvisorNoshowDuration}
                                    onChange={handleChange("slackAdvisorNoshowDuration")}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                        <Grid id="top-row" container spacing={10}>
                            <Grid item xs={6}>
                                <FormControl className={classes.formControl}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formData.slackClientNoshow}
                                                onChange={handleChange("slackClientNoshow")}
                                                value="slackClientNoshow"
                                                color="primary"
                                            />
                                        }
                                        label="Slack client noshow"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="fname"
                                    label="Client No show duration"
                                    className={classes.textField}
                                    value={formData.slackClientNoshowDuration}
                                    onChange={handleChange("slackClientNoshowDuration")}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={classes.submitButton}
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            </form>
        );
    } else {
        return <div>Ask an admin if you need something here...</div>;
    }
}

export default User;
