import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { basicDateTimeStamp, formatPhoneNumber } from "../../Utils";

export default function BasicTable({ rows }) {
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
                <TableHead>
                    <TableRow>
                        <TableCell>Expert</TableCell>
                        <TableCell align="right">User</TableCell>
                        <TableCell align="right">Number</TableCell>
                        <TableCell align="right">Call Status</TableCell>
                        <TableCell align="right">Created On</TableCell>
                        <TableCell align="right">Details</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                            <TableCell component="th" scope="row">
                                {row.expert_name}
                            </TableCell>
                            <TableCell align="right">
                                {row.number_called && row.number_called.length === 10
                                    ? formatPhoneNumber(row.number_called)
                                    : row.number_called}
                            </TableCell>
                            <TableCell align="right">{row.user_name}</TableCell>
                            <TableCell align="right">{row.call_status_name}</TableCell>
                            <TableCell align="right">{basicDateTimeStamp(row.created_on)}</TableCell>
                            <TableCell align="right">{row.call_details}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
