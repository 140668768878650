import React, { useState, useEffect } from "react";

// mui imports
import { TextField, MenuItem } from "@mui/material";

/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

// npm imports
import axios from "axios";

// styles
const purpleText = css`
    color: #4d1b8f;
    font-weight: 700;
    font-style: italic;
    margin-bottom: 1rem;
`;

const SchedulingCredit = (props) => {
    const { status, isUserAuthorized, dropdown, projectId, expertId, callData, setCallData } = props;

    // =====
    // STATE
    // =====

    const [wasScreenerSent, setWasScreenerSent] = useState(false);
    const [userThatSentScreener, setUserThatSentScreener] = useState({});

    // ===============
    // FETCH FUNCTIONS
    // ===============

    // grab the actual user that sent the survey
    const getUserThatSentSurvey = async (projectId, expertId) => {
        const result = await axios.get(
            `/api/project/getUserThatSentSurvey?projectId=${projectId}&expertId=${expertId}`
        );

        if (result?.data?.data?.length > 0) {
            const [{ user_id: userIdThatSentSurvey }] = result?.data?.data;

            const [userAssociatedWithProj] = dropdown.filter((user) => {
                return user.value === userIdThatSentSurvey;
            });

            if (userAssociatedWithProj) {
                setUserThatSentScreener(userAssociatedWithProj);
                // if we're editing we only want the user that came from the DB
                // to be displayed, if we're scheduling for the first time
                // we want the user that sent the survye to be the one associated
                if (status === "scheduling") {
                    setCallData({ ...callData, schedulingCredit: JSON.stringify(userAssociatedWithProj) });
                }
                setWasScreenerSent(true);
            }
        }
    };

    // =========
    // PAGE LOAD
    // =========

    useEffect(() => {
        getUserThatSentSurvey(projectId, expertId);
    }, [projectId, expertId]);

    // ============
    // HANDLE FUNCS
    // ============

    const handleDropdown = (event) => {
        setCallData({ ...callData, schedulingCredit: event.target.value });
    };

    // ===========
    // PAGE RENDER
    // ===========

    return (
        <>
            <TextField
                sx={{ width: "100%", marginBottom: ".5rem", backgroundColor: "#fff" }}
                autoComplete="type"
                fullWidth
                select
                label="Select Scheduling Credit"
                defaultValue=""
                onChange={handleDropdown}
                value={callData.schedulingCredit}
                margin="normal"
                // only disable if editing and user isn't authed
                disabled={status === "editing" && !isUserAuthorized}
            >
                {dropdown.map((user) => {
                    return (
                        <MenuItem key={user.value} value={JSON.stringify(user)}>
                            {user.label}
                        </MenuItem>
                    );
                })}
            </TextField>
            {wasScreenerSent ? (
                <div css={purpleText}>{`FYI: ${userThatSentScreener.label} sent the survey`}</div>
            ) : (
                <div css={purpleText}>{`FYI: No record of who sent the survey`}</div>
            )}
        </>
    );
};

export default SchedulingCredit;
