// react imports
import React, { useState, useEffect } from "react";

// mui imports
import { Button } from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

// custom imports
import SentToClientExpTable from "./SentToClientExpTable";

// npm imports
import axios from "axios";

export default function MarkAsSentToClientDialog(props) {
    // console.log(props, " :: props");
    const { isDialogOpen, setIsDialogOpen, dialogData, personId, setOpenSnackbar, setSnackbarMessage } = props;

    // state
    // don't ask again overrides all previous states and doesn't render anything so that users
    // can copy and paste at will
    // page 1 asks if the user wants to set send exp to client in db
    // page 2 displays table w/ the experts and checkboxes to allow sent to client feature
    // after mark as sent to client is finalized, the 'dontAskAgain' state changes to true
    const [dialogPage, setDialogPage] = useState(1);

    // combined data structure
    const [expertAndSurveyData, setExpertAndSurveyData] = useState([]);

    // this controls loading state so that table doesn't load before
    // state has been grabbed from DB
    const [loadingExpertsTable, setLoadingExpertsTable] = useState(true);

    // PAGE LOAD
    useEffect(() => {
        if (dialogPage === 2) {
            (async () => {
                // expert survey object has a projectId, surveyAttached (boolean) and
                // key value mappigs of experts, with an array of surveyIds
                const { surveyAttached, experts } = dialogData;

                // turn expertIds into expert names
                // transform object key array (which is an array of strings)
                // into integers using the map
                const expertNamesArr = await fetchExpertNames(
                    Object.keys(experts).map((expertId) => parseInt(expertId))
                );

                // create maps to easily attach names to the new data structure
                const expertNamesMap = new Map();
                const surveyNamesMap = new Map();

                // iterate through the return array and pop into mapa
                // so that we can easily
                expertNamesArr.forEach((expert) => {
                    const { id, fname, lname } = expert;

                    // set info into maps
                    expertNamesMap.set(id, `${fname} ${lname}`);
                });

                let surveyNamesArr;

                // if there's a survey
                if (surveyAttached) {
                    // set to remove dup surveyIds for name fetch
                    const surveyIdSet = new Set();

                    // grab all surveyId arrays
                    Object.values(experts)
                        // flatten
                        .flat()
                        // add to set
                        .forEach((survey) => {
                            surveyIdSet.add(survey);
                        });

                    // turn surveyIds into survey names
                    // turn set into array before passing into func
                    surveyNamesArr = await fetchSurveyNames(Array.from(surveyIdSet));

                    surveyNamesArr.forEach((survey) => {
                        const { id, title } = survey;

                        // set info into maps
                        surveyNamesMap.set(id, title);
                    });
                }

                // array that will hold all experts / surveys (if exists)
                const expertSurveyArray = [];

                for (const [expertId, surveyIdArray] of Object.entries(experts)) {
                    // iterate through all the surveyIds associated w/ and expert
                    // and put the name and check mark state together onto the object
                    if (surveyIdArray?.length > 0) {
                        const surveyNameAndIdArray = surveyIdArray.map((surveyId) => {
                            return { surveyId, markedAsSent: false, surveyName: surveyNamesMap.get(surveyId) };
                        });

                        // then create the expert object with the attached surveys
                        expertSurveyArray.push({
                            expertId,
                            expertName: expertNamesMap.get(parseInt(expertId)),
                            markedAsSent: false,
                            surveys: surveyNameAndIdArray,
                        });
                    } else {
                        // create expert object without associated surveys
                        expertSurveyArray.push({
                            expertId,
                            expertName: expertNamesMap.get(parseInt(expertId)),
                            markedAsSent: false,
                        });
                    }
                }

                // console.log(expertSurveyArray, " :: expertSurveyArray");
                setExpertAndSurveyData(expertSurveyArray);
                setLoadingExpertsTable(false);
            })();
        }
    }, [dialogPage]);

    // FETCH/POST FUNCS
    // fetch survey names by surveyId
    const fetchSurveyNames = async (surveyIdArray) => {
        return await axios
            .get(`/api/survey/getSurveyNamesFromIds?surveyIds=${surveyIdArray.join(",")}`)
            .then((responseData) => {
                // grab survey names
                const { surveyNames } = responseData.data;

                return surveyNames;
            })
            .catch((error) => {
                console.log(error, " :: error in fetchSurveyNames");
            });
    };

    // fetch expert names by expertId
    const fetchExpertNames = async (expertIdArray) => {
        return await axios
            // turn expert set into array, the a string w/ join func
            .get(`/api/expert/getExpertNamesFromIds?expertIds=${expertIdArray.join(",")}`)
            .then((responseData) => {
                // grab expert names
                const { expertNames } = responseData.data;

                return expertNames;
                // allow component to load
                // setLoadingExpertsTable(false);
            })
            .catch((error) => {
                console.log(`There was an error with fetching expert names: `, error.response.data.message);
            });
    };

    const setExpertStatuses = async () => {
        // array of objects with expertId and surveyId array
        const expertArray = [];

        // iterate over the rows from the table
        expertAndSurveyData.forEach((expert) => {
            const { expertId, markedAsSent: expertMarkedAsSent, surveys } = expert;
            const surveyIdArray = [];

            // if an expert is marked
            if (expertMarkedAsSent) {
                // iterate through surveys and add id's to array
                if (surveys?.length > 0) {
                    surveys.forEach((survey) => {
                        const { markedAsSent: surveyMarkedAsSent, surveyId } = survey;
                        if (surveyMarkedAsSent) {
                            surveyIdArray.push(surveyId);
                        }
                    });
                }

                // set expertId and array into the obj
                expertArray.push({ expertId, surveys: surveyIdArray });
            }
        });

        const postObj = { projectId: dialogData.projectId, personId, experts: expertArray };

        console.log(postObj, " :: postObj");

        await axios
            .post("/api/expert/setExpertStatuses", postObj)
            .then((result) => {
                const { message } = result.data;
                console.log(message, " :: results from /setExpertStatuses");
                setSnackbarMessage("Successfully Updated Experts As Sent To Client");
                setOpenSnackbar(true);
                handleClose();
            })
            .catch((error) => {
                console.log(`There was an error with updating expert statuses: `, error.response.data.message);
            });
    };

    // PAGE HANDLERS
    const handlePageOneYes = () => {
        setDialogPage(2);
    };

    const handlePageTwoAccept = () => {
        setExpertStatuses();
        // setDontAskAgain(true);
    };

    const handleClose = () => {
        resetDialog();
    };

    // UTIL FUNCS
    const resetDialog = async () => {
        setIsDialogOpen(false);
        setDialogPage(1);
    };

    if (dialogPage === 1) {
        return (
            <div>
                <Dialog
                    fullWidth
                    maxWidth="sm"
                    open={isDialogOpen}
                    // onClose={handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">SENDING EXPERTS TO CLIENT?</DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{ color: "red", fontWeight: "700" }}>
                            If you are sending experts to the client OUTSIDE of the CRM and you would like those experts
                            to appear on the CSV. You MUST mark those experts AND their associated surveys as "sent".
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {/* <Button onClick={handleDontAskAgain} color="primary">
                            Don't Ask Again
                        </Button> */}
                        <Button onClick={handlePageOneYes} color="primary">
                            Continue
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    } else if (dialogPage === 2) {
        return (
            <div>
                <Dialog fullWidth maxWidth="md" open={isDialogOpen} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">EXPERT LIST</DialogTitle>
                    <DialogContent>
                        {/* <DialogContentText>Which Experts Need To Be Marked As Sent?</DialogContentText> */}
                        {!loadingExpertsTable && (
                            <SentToClientExpTable
                                rows={expertAndSurveyData}
                                setExpertAndSurveyData={setExpertAndSurveyData}
                            />
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={resetDialog} color="primary">
                            Ignore (Sending through CRM)
                        </Button>
                        <Button onClick={handlePageTwoAccept} color="primary">
                            Mark As Sent To Client
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}
