// react
import React from "react";

// custom components
import ExpertShowNext from "../../pages/ExpertShowNext";

// MUI - general
import { AppBar, Toolbar, IconButton, Typography, Button, Grid, Slide } from "@mui/material";

// MUI - dialog
import { Dialog, DialogContent } from "@mui/material";

// MUI - icons
import { Close as CloseIcon } from "@mui/icons-material";

// MUI - styles
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

// MUI - helper functions
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// NPM
import { useHistory } from "react-router-dom";

// styles
const appBar = css`
    position: relative;
`;

const title = css`
    font-size: 0.75rem;
    color: #999;
    text-align: left;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 10px;
`;

const ExpertPreviewWrapper = (props) => {
    console.log(props, " :: props wrapper");
    // PROPS
    const { expert_id, project_id, open, setOpen } = props;

    // NAVIGATION
    const history = useHistory();

    function navigateToExpert() {
        if (project_id) {
            history.push(`/expert/view/${expert_id}?pid=${project_id}`);
        } else {
            history.push(`/expert/view/${expert_id}`);
        }
    }

    return (
        <>
            <Dialog fullScreen open={open} onClose={() => setOpen(false)} TransitionComponent={Transition}>
                <AppBar css={appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => setOpen(false)}
                            aria-label="close"
                            size="large"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" css={title}>
                            <Button color="inherit" onClick={() => navigateToExpert(expert_id)}>
                                Full Profile
                            </Button>
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item sm={12} xs={12}>
                            <ExpertShowNext expert_id={expert_id} />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ExpertPreviewWrapper;
