import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        common: { black: "#000", white: "#fff" },
        background: { paper: "#fff", default: "#fafafa" },
        primary: {
            light: "#e1d7ec",
            main: "#8966AD",
            dark: "#523197",
            contrastText: "#fff",
        },
        secondary: {
            light: "#ffc246",
            main: "#ff9100",
            dark: "#523197",
            contrastText: "#fff",
        },
        error: { light: "#e57373", main: "#f44336", dark: "#d32f2f", contrastText: "#fff" },
        action: {
            main: "#008000",
            contrastText: "#fff",
        },
        text: {
            primary: "rgba(0, 0, 0, 0.87)",
            secondary: "rgba(0, 0, 0, 0.54)",
            disabled: "rgba(0, 0, 0, 0.38)",
            hint: "rgba(0, 0, 0, 0.38)",
        },
    },
});

export default theme;
