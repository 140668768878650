import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, TextField } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
// MUI - styles
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { isValidEmail } from "../../../Utils";

const newInputIcon = css`
    font-size: 2rem;
    color: #5c26ff;
    &:hover {
        color: #8c5429;
        cursor: pointer;
    }
`;

const closeIcon = css`
    margin-top: 0.45rem;
    margin-left: 0.35rem;
    border-radius: 50%;
    background-color: #a170fa;
    color: #ebe0ff;
    padding: 0.2rem;
    align-self: flex-start;
    &:hover {
        color: #5c26ff;
        cursor: pointer;
    }
`;

const paragraphStyles = css`
    border: 2px solid #8c5429;
    background-color: #f7dfc1;
    padding: 1rem;
    border-radius: 4px;
    font-size: 1.1rem;
`;

const plusButtonTxt = css`
    font-size: 1rem;
    font-style: italic;
    margin-right: 0.5rem;
`;

const inputContainer = css`
    margin-bottom: 2rem;
`;

export default function AddClientContactModal({
    openAddClientContactModal,
    setOpenAddClientContactModal,
    invoicePreviewData,
    generateInvoice,
}) {
    const { projectData } = invoicePreviewData;

    const [arr, setArr] = React.useState([]);
    const [counter, setCounter] = React.useState(0);

    const handleClose = () => {
        setOpenAddClientContactModal(false);
    };

    function validateEmails() {
        let isValid = true;
        const newArr = arr.map((input) => {
            if (!isValidEmail(input.value)) {
                isValid = false;
                return { ...input, error: true };
            }
            return { ...input, error: false };
        });

        setArr(newArr);
        return isValid;
    }

    async function handleConfirm() {
        const isValid = validateEmails();
        if (!isValid) return;
        const emailStr = arr.map((input) => input.value).join(",");
        await generateInvoice(emailStr);
    }

    function addInput() {
        setArr((s) => {
            return [
                ...s,
                {
                    type: "email",
                    id: counter,
                    value: "",
                    error: false,
                    errorMsg: "Invalid Email",
                },
            ];
        });

        setCounter((counter) => counter + 1);
    }

    function handleChange(event, index) {
        event.preventDefault();

        const newArr = arr.map((input) => {
            if (input.id === index) {
                input.value = event.target.value;
            }
            return input;
        });

        setArr(newArr);
    }

    function handleCloseInput(event, inputId) {
        const newArr = arr.filter((input) => {
            return input.id !== inputId;
        });

        setArr(newArr);
    }

    return (
        <div>
            <Dialog
                open={openAddClientContactModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Please Confirm Or Add Client Contact For Invoice</DialogTitle>
                <DialogContent>
                    <div>
                        <p css={paragraphStyles}>
                            Invoice will be sent to: <b>{projectData.primary_client_contact_email}</b>
                        </p>
                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            sx={{ marginBottom: "1.25rem" }}
                        >
                            <div css={plusButtonTxt}>(Click if you'd like to add additional email addresses) -></div>
                            <div>
                                <AddCircleOutlineIcon css={newInputIcon} onClick={addInput} />
                            </div>
                        </Box>
                        <div>
                            {arr.map((input, index) => {
                                return (
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        key={input.id}
                                    >
                                        <TextField
                                            sx={{
                                                width: "100%",
                                                "& legend": { display: "none" },
                                                "& fieldset": { top: 0 },
                                            }}
                                            variant="outlined"
                                            margin="dense"
                                            type={input.type}
                                            value={input.value}
                                            onChange={() => handleChange(event, input.id)}
                                            error={input.error}
                                            helperText={input.error ? input.errorMsg : ""}
                                        />
                                        <CloseIcon css={closeIcon} onClick={() => handleCloseInput(event, input.id)} />
                                    </Box>
                                );
                            })}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                    <Button onClick={handleConfirm} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
