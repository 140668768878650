import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import { Skeleton, TextField } from "@mui/material";
import PhoneLogsTable from "./PhoneLogsTable";

export default function AlertDialog(props) {
    // PROPS
    const { reloadEvent, openPhoneLogs, setOpenPhoneLogs, userInfo, project_id, expert_id, number } = props;

    // STATE
    const [value, setValue] = React.useState(1);
    const [statusList, setStatusList] = React.useState([]);
    const [loadingStatuses, setLoadingStatuses] = React.useState(true);
    const [phoneLogs, setPhoneLogs] = React.useState([]);
    const [loadingLogs, setLoadingLogs] = React.useState(true);
    const callDetailsRef = React.useRef(null);
    const [page, setPage] = React.useState(1);
    const [phone, setPhone] = React.useState(number);

    // HANDLE FUNCS
    const handleClose = () => {
        setOpenPhoneLogs(false);
    };

    function handleView() {
        setPage(2);
    }

    function handleSave() {
        console.log("Save Phone Logs");
        console.log(callDetailsRef?.current?.value, " :: callDetailsRef?.current?.value");
        const logData = {
            callDetails: callDetailsRef?.current?.value,
            callStatusId: value,
            project_id,
            expert_id,
            userId: userInfo?.id,
            phone,
        };

        console.log("Saving the Phone Log Data: ", logData);
        addPhoneLog(logData);
    }

    function handleChange(event) {
        const { target } = event;
        const { value, name } = target;
        console.log("DID WE CAPTURE THE PHONE PROPERLY? ", value);
        setPhone(value);
    }

    function handleStatusChange(event) {
        setValue(event.target.value);
    }

    function handleBack() {
        setPage(1);
    }

    // API CALLS
    async function findCallStatuses() {
        await axios
            .get("/api/project/now/findCallStatuses")
            .then((res) => {
                const incomingStatusList = res?.data?.data?.data;
                if (incomingStatusList) {
                    setStatusList(incomingStatusList);
                    setLoadingStatuses(false);
                }
            })
            .catch((err) => {
                console.log(err, " :: err findCallStatuses");
            });
    }

    async function addPhoneLog(logData) {
        await axios
            .post("/api/project/now/addPhoneLog", logData)
            .then((res) => {
                console.log(res, " :: res addPhoneLog");
                setOpenPhoneLogs(false);
                reloadEvent(project_id);
            })
            .catch((err) => {
                console.log(err, " :: err addPhoneLog");
            });
    }

    async function findUserPhoneLogs() {
        console.log("====== in findUserPhoneLogs ======");
        await axios
            .get(`/api/project/now/findUserPhoneLogs?project_id=${project_id}&expert_id=${expert_id}`)
            .then((res) => {
                const incomingLogs = res?.data?.data?.data;
                console.log(incomingLogs, " :: incomingLogs");
                if (incomingLogs) {
                    setPhoneLogs(incomingLogs);
                    setLoadingLogs(false);
                }
            })
            .catch((err) => {
                console.log(err, " :: err findCallStatuses");
            });
    }

    // PAGE LOAD
    React.useEffect(() => {
        console.log("====== in useEffect page 1 ======");

        if (page === 1) {
            findCallStatuses();
        }
    }, []);

    React.useEffect(() => {
        console.log("====== in useEffect page 2 ======");

        if (page === 2) {
            findUserPhoneLogs();
        }
    }, [page]);

    return (
        <div>
            <Dialog
                fullWidth
                maxWidth={page === 1 ? "sm" : "lg"}
                open={openPhoneLogs}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Call Status Log</DialogTitle>
                <DialogContent>
                    <div>
                        {page === 1 && (
                            <Box sx={{ minWidth: 120 }} display="flex" flexDirection="column">
                                <TextField
                                    sx={{ marginBottom: "2rem" }}
                                    value={phone}
                                    name="phone"
                                    margin="dense"
                                    onChange={handleChange}
                                    label="Phone Number"
                                    // disabled
                                />
                                {loadingStatuses ? (
                                    <Skeleton variant="rectangular" sx={{ marginBottom: "2rem" }} height={60} />
                                ) : (
                                    <TextField
                                        sx={{ marginBottom: "2rem" }}
                                        value={value}
                                        select
                                        margin="dense"
                                        onChange={handleStatusChange}
                                        label="Select A Call Outcome"
                                    >
                                        {statusList.map((s) => (
                                            <MenuItem key={s.id} value={s.id}>
                                                {s.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )}
                                <TextField
                                    inputRef={callDetailsRef}
                                    multiline
                                    rows={4}
                                    label="Please add any relevent details about the call..."
                                />
                            </Box>
                        )}
                        {page === 2 && !loadingLogs && <PhoneLogsTable rows={phoneLogs} />}
                    </div>
                </DialogContent>
                <DialogActions>
                    {page === 1 && (
                        <>
                            <Button onClick={handleSave} autoFocus>
                                Save Call Status
                            </Button>
                            <Button onClick={handleView}>View Call Log</Button>
                        </>
                    )}
                    {page === 2 && (
                        <Button onClick={handleBack} autoFocus>
                            Back
                        </Button>
                    )}
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
