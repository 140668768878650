// react
import React, { useState, useContext, useEffect } from "react";

// MUI - general
import { Button, Typography } from "@mui/material";

// MUI - dialog
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

// MUI - styles
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

// utils
import { createDateTimeStringForDb, legacyDateAndTime } from "../utils/utils";

// auth
import { authContext } from "../../../context/auth";

// custom imports
import SchedulingConfirmationBox from "./SchedulingConfirmationBox";
import SchedulingOptions from "./SchedulingOptions";
import SchedulingDelete from "./SchedulingDelete";
import SchedulingPins from "./SchedulingPins";
import SchedulingEventLog from "./SchedulingEventLog";
import SchedulingCallTemplate from "./SchedulingCallTemplate";
import SchedulingDuration from "./SchedulingDuration";
import SchedulingRequestPaymentDialog from "./SchedulingRequestPaymentDialog";
import SchedulingCustomExpertCharge from "./SchedulingCustomExpertCharge";

// npm imports
import axios from "axios";

// main function export
export default function SchedulingDialog(props) {
    const {
        isUserAuthorized,
        timezones,
        projectData,
        schedulingDialogData,
        setSchedulingDialogData,
        render,
        rerender,
        resetUrl,
        handlerFuncs,
    } = props;

    // DESTRUCTURES
    // destructure for later use
    const { expertData, showDialog, status, dialogWidth } = schedulingDialogData;

    const { expert_id, name, ppId } = expertData;

    const { client_id, id: projectId } = projectData;

    // =====
    // STATE
    // =====

    // CONSTANTS
    // dialog reset state
    const DEFAULT_DIALOG_STATE = { expertData: null, showDialog: false };

    // default reaoson
    const DEFAULT_REASON = JSON.stringify({ value: 8, label: "Scheduling mistake" });

    // error reset state
    const DEFAULT_ERROR_STATE = { error: false, element: null, msg: "" };

    // credit to user
    const DEFAULT_CREDIT_TO = JSON.stringify({ value: 0, label: "Nobody Gets Credit" });

    // default timezone
    const DEFAULT_TIMEZONE = JSON.stringify({
        value: 16,
        label: "(UTC-05:00) Eastern Time (US & Canada)",
        abbr: "EDT",
        offset: "-04:00",
    });

    // the number of the reason selected
    const CUSTOM_REASON_NUM = 10;

    // call data reset state
    const DEFAULT_CALLDATA_STATE = {
        dateAndTime: new Date(),
        ppId: null,
        callType: "",
        timezone: DEFAULT_TIMEZONE,
        // setting default value here
        schedulingCredit: DEFAULT_CREDIT_TO,
        editReason: DEFAULT_REASON,
        customReason: "",
        clientDuration: "",
        expertDuration: "",
        options: {
            moderator: false,
            record: true,
            compliance: false,
            translator: false,
        },
    };

    const DEFAULT_PAYMENT_DETAILS = expertData?.paymentDetails || "";

    // STATE VALUES
    // current page
    const [page, setPage] = useState("options");
    // call information
    const [callData, setCallData] = useState(DEFAULT_CALLDATA_STATE);
    // error data
    const [error, setError] = useState(DEFAULT_ERROR_STATE);
    // auth data
    const { auth } = useContext(authContext);
    // let's us know if we're on the initial load of the dialog
    // or if ppl are hitting prev, next, prev, next etc
    const [initialLoad, setInitialLoad] = useState(true);
    // request payment details
    const [paymentDetails, setPaymentDetails] = useState(DEFAULT_PAYMENT_DETAILS);

    // DESTRUCTURE CALL DATA
    const {
        editReason,
        customReason,
        dateAndTime,
        callType,
        user_id,
        clientDuration,
        expertDuration,
        timezone: unparsedTimezone,
        schedulingCredit,
        options: { moderator, record, compliance, translator },
    } = callData;

    const timezone = JSON.parse(unparsedTimezone);

    // init data
    const editReasonNumber = JSON.parse(editReason).value;

    // =========
    // PAGE LOAD
    // =========

    // set user
    useEffect(() => {
        setCallData({ ...callData, user_id: auth.data.id });
    }, [auth]);

    useEffect(() => {
        if (status === "deleting") {
            setPage("delete");
        } else if (status === "pins") {
            setPage("pins");
        } else if (status === "events") {
            setPage("events");
        } else if (status === "invite") {
            setPage("invite");
        } else if (status === "duration") {
            setPage("duration");
        } else if (status === "custom-expert-charge") {
            setPage("custom-expert-charge");
        } else if (status === "request-payment") {
            setPage("request-payment");
        }
    });

    // ==========
    // VALIDATION
    // ==========

    const validateInputs = (componentType) => {
        const { callType, timezone } = callData;

        setError(DEFAULT_ERROR_STATE);

        if (componentType === "options") {
            if (!callType) {
                setError({ error: true, element: "callType", msg: "Must Select A Call Type" });
                return;
            }
            if (!timezone) {
                setError({ error: true, element: "timezone", msg: "Must Select A Timezone" });
                return;
            }
        }

        if (componentType === "duration") {
            if (!clientDuration) {
                setError({ error: true, element: "clientDuration", msg: "Must Enter Client Duration (In Minutes)" });
                return;
            }
            if (clientDuration == 0) {
                setError({ error: true, element: "clientDuration", msg: "Duration Cannot Be Or Start With Zero" });
                return;
            }
            if (!expertDuration) {
                setError({ error: true, element: "expertDuration", msg: "Must Enter Expert Duration (In Minutes)" });
                return;
            }
            if (expertDuration == 0) {
                setError({ error: true, element: "expertDuration", msg: "Duration Cannot Be Zero" });
                return;
            }
        }

        if (componentType === "payment") {
            if (!paymentDetails) {
                setError({ error: true, element: "paymentDetails", msg: "Must Enter Payment Details" });
                return;
            }
        }

        return true;
    };

    // ============
    // HANDLE FUNCS
    // ============

    // close the dialog
    const handleClose = (cancelBtnPushed = false) => {
        setPaymentDetails(DEFAULT_PAYMENT_DETAILS);
        setError(DEFAULT_ERROR_STATE);
        setCallData(DEFAULT_CALLDATA_STATE);
        setSchedulingDialogData(DEFAULT_DIALOG_STATE);
        if (!cancelBtnPushed) {
            rerender(!render);
        }
    };

    // switch to next pago
    const handleNextPage = () => {
        let validated = false;

        validated = validateInputs("options");
        setInitialLoad(false);

        if (validated) {
            setPage("confirmation");
        }
    };

    const handlePrevPage = () => {
        setPage("options");
    };

    // ================
    // FOR CONFIRMATION
    // ================

    /**
     * EXAMPLE OF HOW TO CONVERT TO UTC AND BACK TO THE RIGHT LOCAL TIME USING OFFSET
     * ------------------------------------------------------------------------------
     * first create string manually inputting the correct offset
     * - this bypasses the default date object issue that it is always either in local time
     *   based on your browser or UTC. we need the object to be in some other timezone before
     *   we convert to UTC.
     * so call this func to create the object based on the date time picker ->
     *
     * const tzString = createDateTimeStringForDb(dateAndTime, timezone.offset);
     * console.log(tzString, " :: starting dt string");
     *
     * now we can convert that to UTC and it'll do the proper conversion necessary to convert from our custom timezone object
     * into the correct UTC time ->
     *
     * const date1 = new Date(tzString).toISOString();
     * console.log(date1, " :: date1");
     * now safe to save to DB
     *
     * to convert back to client
     * now we can convert the UTC back to whatever timezone using the offset which can then be plugged back into the DT picker
     * so that it sets the correct time during the edit phase ->
     *
     * const original = createDateTimeStringForDTPicker(dateObj, offset)
     * console.log(original, " :: original");
     * ( check the component specific utils file if you can't find the funcs )
     */

    const handleConfirm = () => {
        const text = () => {
            if (record) {
                return `Recording is ENABLED for this call.  PLEASE CONFIRM THIS IS CORRECT`;
            } else {
                return `Recording is DISABLED for this call.  PLEASE CONFIRM THIS IS CORRECT`;
            }
        };

        const confirmation = confirm(text());

        if (!confirmation) return;

        // modmify date string to properly save in the DB as described above
        const localTimeFormattedDateAndTime = createDateTimeStringForDb(dateAndTime, timezone.offset);
        const utcFormattedDateAndTime = new Date(localTimeFormattedDateAndTime).toISOString();

        // WORKING RIGHT HERE
        const postObj = {
            ppId,
            expert_id,
            project_id: projectId,
            scheduled_timezone: timezone.abbr,
            scheduled_datetime: utcFormattedDateAndTime,
            call_type: callType.value,
            created_by: user_id,
            timezone_id: timezone.value,
            needs_recording: record,
            scheduling_credit_to: JSON.parse(schedulingCredit).value,
            user_id,
            client_id,
            moderator,
            translator,
            compliance,
            // we either use the custom reason if that's the correct input
            // or we use whatever value is passed by the reason object
            reason: editReasonNumber === CUSTOM_REASON_NUM ? customReason : JSON.parse(editReason).label,
            /**
             * this takes the date and time from the
             * date time picker and spreads it into the
             * appropriate fields for this call to work
             * properly with the database. those attributes
             * arent really necessary but they're being called
             * from a bunch of different places and not worth
             * cleaning up for this project unless there's a
             * specific reason to do so
             */
            ...legacyDateAndTime(dateAndTime),
        };

        // console.log(postObj, " :: postObj");

        let url = "/api/project/schedule";

        if (status === "editing") {
            url = "/api/project/schedule/update";
        }

        axios
            .post(url, postObj)
            .then((result) => {
                console.log(result, " :: result");
                handleClose();
                resetUrl();
            })
            .catch((error) => {
                console.log(error, " :: error");
            });
    };

    // DELETE
    function handleDelete() {
        const postObj = {
            user_id,
            project_id: projectId,
            ppId,
            expert_id,
            reason: editReasonNumber === CUSTOM_REASON_NUM ? customReason : JSON.parse(editReason).label,
        };
        axios
            .post("/api/project/schedule/delete", postObj)
            .then((result) => {
                console.log(result, " :: result");
                handleClose();
            })
            .catch((error) => {
                console.log(error, " :: error");
            });
    }

    const handleCallDuration = async (event) => {
        let validated = false;
        validated = validateInputs("duration");

        if (validated) {
            const postObj = {
                call_id: ppId,
                client_call_duration: clientDuration,
                expert_call_duration: expertDuration,
                person_id: user_id,
                expert_id,
                project_id: projectId,
            };

            await axios
                .post("/api/project/call/updateDuration", postObj)
                .then((result) => {
                    console.log(result, " :: result");
                    handleClose();
                })
                .catch((error) => {
                    console.log(error, " :: error");
                });
        }
    };

    const handlePaymentRequestDetails = async () => {
        console.log(ppId, " :: ppId");

        let validated = false;
        validated = validateInputs("payment");

        if (validated) {
            await axios
                // TODO: move this to finance api
                .post("/api/expert/paymentDetailsUpsert", {
                    ppId,
                    paymentDetails,
                    userId: auth.data.id,
                    expert_id,
                    project_id: projectId,
                })
                .then((result) => {
                    console.log(result, " :: result");
                    handleClose();
                })
                .catch((error) => {
                    console.log(error, " :: error");
                });
        }
    };

    // ============
    // RENDER FUNCS
    // ============

    // render diff sections of the dialog
    // based on page #
    const renderDiffPageHeader = () => {
        if (page === "options") {
            return (
                <Typography align="center" sx={{ fontSize: "1.5rem", fontWeight: "700", marginBottom: "1rem" }}>
                    {`${status === "editing" ? "Update" : "Schedule"} ${name}`}
                </Typography>
            );
        } else if (page === "confirmation") {
            return (
                <Typography align="center" sx={{ fontSize: "1.5rem", fontWeight: "700", marginBottom: "1rem" }}>
                    {`Confirm ${status === "editing" ? "Updating" : "Scheduling"} of ${name}`}
                </Typography>
            );
        } else if (page === "delete") {
            return (
                <Typography align="center" sx={{ fontSize: "1.5rem", fontWeight: "700", marginBottom: "1rem" }}>
                    {`🚨⚠️ Delete ${name}? ⚠️🚨`}
                </Typography>
            );
        } else if (page === "pins") {
            return (
                <Typography align="center" sx={{ fontSize: "1.5rem", fontWeight: "700", marginBottom: "1rem" }}>
                    {`Pins`}
                </Typography>
            );
        } else if (page === "events") {
            return (
                <Typography align="center" sx={{ fontSize: "1.5rem", fontWeight: "700", marginBottom: "1rem" }}>
                    {`Event Log`}
                </Typography>
            );
        } else if (page === "invite") {
            return (
                <Typography align="center" sx={{ fontSize: "1.5rem", fontWeight: "700", marginBottom: "1rem" }}>
                    {`Meeting Invites`}
                </Typography>
            );
        } else if (page === "duration") {
            return (
                <>
                    <Typography align="center" sx={{ fontSize: "1.5rem", fontWeight: "700" }}>
                        {`Call Duration`}
                    </Typography>
                    <Typography
                        align="center"
                        sx={{ fontSize: "1rem", color: "red", fontWeight: "500", marginBottom: "1rem" }}
                    >
                        {`PLEASE NOTE: If this call should NOT be charged to the client, but we still need to pay the expert, use 'One Off Payment' option. If you don't have access, contact management.`}
                    </Typography>
                </>
            );
        } else if (page === "request-payment") {
            return (
                <Typography align="center" sx={{ fontSize: "1.5rem", fontWeight: "700", marginBottom: "1rem" }}>
                    {`Payment Details`}
                </Typography>
            );
        } else if (page === "custom-expert-charge") {
            return (
                <Typography align="center" sx={{ fontSize: "1.5rem", fontWeight: "700", marginBottom: "1rem" }}>
                    {`One Off Payment`}
                </Typography>
            );
        }
    };

    // render body based on page #
    const renderDiffPageBody = () => {
        if (page === "options") {
            return (
                <SchedulingOptions
                    timezones={timezones}
                    error={error}
                    callData={callData}
                    setCallData={setCallData}
                    schedulingDialogData={schedulingDialogData}
                    projectData={projectData}
                    isUserAuthorized={isUserAuthorized}
                    initialLoad={initialLoad}
                />
            );
        } else if (page === "confirmation") {
            return <SchedulingConfirmationBox callData={callData} name={name} status={status} />;
        } else if (page === "delete") {
            return <SchedulingDelete status={status} callData={callData} setCallData={setCallData} />;
        } else if (page === "pins") {
            return <SchedulingPins pinData={expertData} />;
        } else if (page === "events") {
            return <SchedulingEventLog expertData={expertData} />;
        } else if (page === "invite") {
            return <SchedulingCallTemplate projectData={projectData} expertData={expertData} authData={auth.data} />;
        } else if (page === "duration") {
            return (
                <SchedulingDuration
                    callData={callData}
                    setCallData={setCallData}
                    expertData={expertData}
                    user_id={auth.data.id}
                    error={error}
                    handlerFuncs={handlerFuncs}
                />
            );
        } else if (page === "request-payment") {
            return (
                <SchedulingRequestPaymentDialog
                    expertData={expertData}
                    paymentDetails={paymentDetails}
                    setPaymentDetails={setPaymentDetails}
                    error={error}
                />
            );
        } else if (page === "custom-expert-charge") {
            return (
                <SchedulingCustomExpertCharge
                    handleClose={handleClose}
                    expertData={expertData}
                    user_id={auth.data.id}
                    handlerFuncs={handlerFuncs}
                />
            );
        }
    };

    const renderDialogButtons = () => {
        if (page === "options") {
            return (
                <>
                    <Button onClick={() => handleClose(true)}>Cancel</Button>
                    <Button onClick={handleNextPage} autoFocus>
                        Next
                    </Button>
                </>
            );
        } else if (page === "confirmation") {
            return (
                <>
                    <Button onClick={() => handleClose(true)}>Cancel</Button>
                    <Button onClick={handlePrevPage}>Previous</Button>
                    <Button onClick={handleConfirm} autoFocus>
                        {`${status === "editing" ? "Update" : "Confirm"}`}
                    </Button>
                </>
            );
        } else if (page === "delete") {
            return (
                <>
                    <Button onClick={() => handleClose(true)}>Cancel</Button>
                    <Button onClick={handleDelete} autoFocus>
                        Delete
                    </Button>
                </>
            );
        } else if (page === "duration") {
            return (
                <>
                    <Button onClick={() => handleClose(true)}>Cancel</Button>
                    <Button onClick={handleCallDuration} autoFocus>
                        Save
                    </Button>
                </>
            );
        } else if (page === "request-payment") {
            return (
                <>
                    <Button onClick={() => handleClose(true)}>Cancel</Button>
                    <Button onClick={handlePaymentRequestDetails} autoFocus>
                        Save Payment Details
                    </Button>
                </>
            );
        } else if (page !== "custom-expert-charge") {
            return <Button onClick={() => handleClose(true)}>Cancel</Button>;
        }
    };

    // ===========
    // PAGE RENDER
    // ===========

    return (
        <div>
            {showDialog && (
                <Dialog
                    maxWidth={dialogWidth}
                    fullWidth
                    open={showDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{renderDiffPageHeader()}</DialogTitle>
                    <DialogContent>{renderDiffPageBody()}</DialogContent>
                    <DialogActions>{renderDialogButtons()}</DialogActions>
                </Dialog>
            )}
        </div>
    );
}
