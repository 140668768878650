import React, { useEffect, useContext } from "react";

import { Box } from "@mui/system";
import { Button, Paper } from "@mui/material";
import { authContext } from "../context/auth";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-typeahead/css/Typeahead.css";

import ExpertSnippet from "../components/ExpertSnippet";
import { CSVLink } from "react-csv";

export default function ExpertDataTableToolbar(props) {
    const _state = props.state_manager;


    const { auth } = useContext(authContext);
    const { history } = props.props;

    const strategicTemplate = `<br /><p>My name is ${auth.data.fname} ${auth.data.lname} and I am a ${auth.data.title} at FirstThought, a life sciences-focused expert network based in New York City that works with various clients in the healthcare consulting and investment industries to help them connect with experts for the purposes of completing their research.</p><p>Many of our <strong>clients conducting research [*** FILL IN TOPIC***], are frequently looking to speak with experts like yourself</strong> to get your thoughts on the treatments in development and the current standard of care in this field. After reviewing some of your experiences and therapeutic expertise, I am confident that you would be able to provide us some important insights into the treatment and management of <strong>[*** FILL IN TOPIC***]</strong>.</p><p>If you’re interested in participating in <strong>paid</strong> ad hoc phone consultations and surveys on this topic and others, please click on the link below to accept FirstThought’s Expert Agreement and join our network: <strong>{{ONBOARDING_LINK}}</strong>.</p><p>Thank you in advance for your consideration, and I look forward to hopefully hearing from you soon.</p>`;
    const screenerTemplate = `<br /><p>Thank you for your interest in this consultation. Our client is from a/an [insert investment firm, boutique consulting firm, health policy firm, health care research firm, equity research, pharmaceutical company] and they are conducting research to learn more about <strong>${_state.get.projectState.description}</strong>. This will be a 30-60 minute paid phone consultation.<br /><br />I have included the next steps to get started on this consultation. The <strong>{{SCREENER_LINK}}</strong> will take you through a few questions that will give our client a better idea of your experience and give you a better idea of what they will be interested in discussing during the phone consultation.</p>`;

    const toolbarButtonStyles = {
        float: "left",
        marginRight: "12px",
        marginBottom: "10px",
        width: "12%",
        fontSize: ".65rem",
    };

    useEffect(() => {
        return () => {
            // _state.set.selectedExperts([]);
            // if (_state.get.selectedExperts.length > 0) _state.set.selectedExperts([]);
        };
    }, [props]);

    // Helper

    function createBulkOutreach(templateName) {
        // Gather selected Experts
        getSelectedExpertsForCsv();
        _state.set.outreachTemplate(true);
        _state.set.template(templateName);
        _state.set.emailContent(strategicTemplate);
        console.log(_state.get.emailContent);
        console.log("Outreach Template? ", templateName);
        _state.set.selectedSurvey({
            ..._state.get.selectedSurvey,
            id: "",
            token: "",
        });
    }

    function checkForDNC() {
        let dnc = false;
        const selected = _state.get.selected;
        let dncList = 0;
        for (const i in selected) {
            if (selected[i].dnc || selected[i].opted_out) dncList++;
        }
        if (dncList === selected.length) dnc = true;
        if (dnc) _state.flash.error("All selected experts are DNC.");
        return dnc;
    }

    function sendExperts() {
        const paramsArray = [];
        _state.get.selected.forEach(function (item) {
            paramsArray.push(`[eid=${item.id}|sid=0|pricing_unit=${item.pricingUnit}]`);
        });
        history.push(
            `/project/experts/${_state.get.projectState.id}?send=${paramsArray.toString()}&templateType=prescreen`
        );
        _state.set.selected([]);
    }

    function removeExpert() {
        const url = `/api/project/removeExpert/${_state.get.projectState.id}`;
        const arr = [];
        for (const i in _state.get.selected) {
            if (_state.get.selected.length > 0) arr.push(_state.get.selected[i].id);
        }
        const formData = {
            expert_ids: arr,
            user_id: auth.data.id,
        };
        if (_state.get.selected.length > 0) {
            if (window.confirm("Are you damn sure you want to do this?")) {
                axios
                    .post(url, formData, {
                        headers: {
                            "Content-Type": "application/json;charset=UTF-8",
                            "Access-Control-Allow-Origin": "*",
                        },
                    })
                    .then((result) => {
                        if (result.status === 200) {
                            console.log("Experts Removed? ", result);
                            window.location.reload();
                        } else {
                            console.log(`Good POST, but something went wrong: ${JSON.stringify(formData)}`);
                        }
                    })
                    .catch((result) => {
                        console.log("Something went wrong, API Error, 404 Error:", result);
                    });
            }
        }
    }

    function getSelectedExpertsForCsv() {
        // First filter out ineligible opt outs and dncs
        const allExperts = _state.get.csv.all_experts.filter((e) => !e.dnc && !e.opted_out);
        console.log("FILTERED EXPERTS BEFORE SELECTED: ", allExperts);
        if (_state.get.selected.length > 0) {
            const csvArray = [];

            for (let i = 0; i < _state.get.selected.length; i++) {
                const expertObject = allExperts.find((item) => item.id === _state.get.selected[i].id);

                // If NOT undefined then add to array
                if (expertObject) {
                    csvArray.push(expertObject);
                }
            }
            _state.set.csv({
                ..._state.get.csv,
                selected_experts: csvArray,
            });
        }
    }

    const headers = [
        { label: "Primary Phone", key: "phone" },
        { label: "ID", key: "id" },
        { label: "First Name", key: "fname" },
        { label: "Last Name", key: "lname" },
        { label: "Email", key: "mail" },
        { label: "Attached", key: "attached_date" },
        { label: "Attached By", key: "attached_by" },
        { label: "Onboard Link", key: "onboard_link" },
        { label: "Position 1", key: "position_1" },
        { label: "Company 1", key: "company_1" },
        { label: "Position 2", key: "position_2" },
        { label: "Company 2", key: "company_2" },
        { label: "Position 3", key: "position_3" },
        { label: "Company 3", key: "company_3" },
    ];

    if (props.selected.length == 0) return <div></div>;
    return (
        <Paper
            sx={{
                zIndex: "5",
                position: "fixed",
                bottom: "0",
                height: "15vh",
                width: "100%",
                backgroundColor: "#FAFAFA",
                left: "72px",
                borderTop: "1px solid #ccc",
                padding: "30px 0 0 60px",
            }}
        >
            <Button
                onClick={() => {
                    _state.set.selectedExperts(_state.get.selected);
                    _state.switch.changeMultipleStatusesModal();
                }}
                sx={toolbarButtonStyles}
                fullWidth
                size="small"
                variant="contained"
                color="primary"
            >
                Statuses
            </Button>
            {/* <Button
                onClick={() => {
                    if (checkForDNC()) return;
                    createBulkOutreach("outreach");
                    _state.set.selectedExperts(props.selected);
                    _state.switch.outreachModal();
                    console.log(_state.get.emailContent);
                }}
                sx={toolbarButtonStyles}
                fullWidth
                size="small"
                variant="contained"
                color="primary"
            >
                Outreach
            </Button> */}
            {/* <Button
                onClick={() => {
                    if (checkForDNC()) return;
                    _state.set.selectedExperts(props.selected);
                    _state.set.strategic(true);
                    _state.switch.defaultOutreachEmailsModal();
                }}
                sx={toolbarButtonStyles}
                fullWidth
                size="small"
                variant="contained"
                color="primary"
            >
                Strategic
            </Button>
            <Button
                onClick={() => {
                    if (checkForDNC()) return;
                    _state.set.selectedExperts(props.selected);
                    _state.switch.sendScreenerModal();
                }}
                sx={toolbarButtonStyles}
                fullWidth
                size="small"
                variant="contained"
                color="primary"
            >
                Screeners
            </Button>
            <Button
                onClick={() => {
                    _state.set.selectedExperts(props.selected);
                    sendExperts();
                }}
                sx={toolbarButtonStyles}
                fullWidth
                size="small"
                variant="contained"
                color="primary"
            >
                To Client
            </Button> */}
            <Button
                onClick={() => {
                    _state.set.selectedExperts(props.selected);
                    _state.switch.addRemoveTagsModal();
                }}
                sx={toolbarButtonStyles}
                fullWidth
                size="small"
                variant="contained"
                color="primary"
            >
                A/R Tags
            </Button>
            {/* <Button
                onClick={() => {
                    _state.set.selectedExperts(props.selected);
                    _state.switch.attachToProjectModal();
                }}
                sx={toolbarButtonStyles}
                fullWidth
                size="small"
                variant="contained"
                color="primary"
            >
                Add To
            </Button> */}
            <Button
                onClick={() => {
                    _state.set.selectedExperts(props.selected);
                    removeExpert();
                }}
                sx={toolbarButtonStyles}
                fullWidth
                size="small"
                variant="contained"
                color="primary"
            >
                Remove
            </Button>
            {/* 
            <CSVLink
                data={_state.get.csv.selected_experts}
                headers={headers}
                filename="experts.csv"
                sx={{ color: "#fff", textDecoration: "none" }}
                target="_blank"
            >
                <Button
                    onClick={() => {
                        getSelectedExpertsForCsv();
                        _state.flash.success("Experts.csv downloaded!");
                    }}
                    sx={toolbarButtonStyles}
                    fullWidth
                    size="small"
                    variant="contained"
                    color="primary"
                >
                    <Box component={"span"} sx={{ color: "#fff", textDecoration: "none" }}>
                        Export
                    </Box>
                </Button>
            </CSVLink> */}
            {/* 
            <Box sx={toolbarButtonStyles}>
                <ExpertSnippet
                    expertList={props.selected.map((n) => n.id)}
                    size="small"
                    fullWidth={true}
                    styles={{ fontSize: ".65rem" }}
                />
            </Box> */}
        </Paper>
    );
}
