import React, { useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Slide from "@mui/material/Slide";
import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import FlashMessage from "./FlashMessage";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
    },
    appBar: {
        position: "relative",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.text.secondary,
        backgroundColor: "#f6f6bc",
        marginBottom: "15px",
    },
    list: {
        margin: theme.spacing(1),
        marginLeft: "0",
        paddingInlineStart: "0",
        listStyleType: "none",
        listStylePosition: "outside",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: "75%",
        flexShrink: 0,
    },
    comment: {
        marginBottom: "6px",
    },
    timestamp: {
        fontSize: ".5rem",
        color: "#555",
        textAlign: "left",
        marginBottom: "5px",
    },
    text: {
        fontSize: ".75rem",
        color: "#333",
        textAlign: "left",
    },
    textEdit: {
        fontSize: ".75rem",
        color: "#222",
        textAlign: "left",
        cursor: "pointer",
    },
    link: {
        color: "#19857b",
        textDecoration: "none",
        fontSize: ".65rem",
    },
    hidden: {
        display: "none",
        fontSize: ".75rem",
    },
    showTextField: {
        display: "block",
        fontSize: ".75rem",
    },
    margin: {
        margin: theme.spacing(1),
    },
    editorWrapper: {
        border: "1px solid #EAEAEA",
        borderRadius: "2px",
        padding: "12px",
    },
    button: {
        fontSize: ".65rem",
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    btnContainer: {
        marginTop: "2em",
    },
    modalBtn: {
        marginRight: ".5em",
        marginLeft: ".5em",
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Comments(props) {
    const customStyles = makeStyles((theme) => ({
        comments: {
            // padding: "8px",
            overflowY: "auto",
            overflowX: "hidden",
            height: props.height,
        },
    }));

    const theme = useTheme();
    const _state = props.state_manager;

    const [editorDisplay, setEditorDisplay] = useState("none");
    const [buttonDisplay, setButtonDisplay] = useState("block");
    const [isEdit, setIsEdit] = useState(false);
    const [editor, setEditorState] = useState(EditorState.createEmpty());
    const [open, setOpen] = useState(false);
    const [currentComment, setCurrentComment] = useState({});
    const [openFlashMessage, setOpenFlashMessage] = useState(false);
    const [flashMessage, setFlashMessage] = useState("");
    const [commentData, setCommentData] = useState({
        comments: [],
    });
    const [data, setComment] = useState({
        id: "",
        parent_id: "",
        reference: { id: props.object_id, type: props.object_type },
        created_by_id: props.user.id,
        author_name: props.user.fname + " " + props.user.lname,
        comment: "",
        children: [],
        created_on: "",
        updated_on: "",
        updated_by_id: props.user.id,
        isdeleted: false,
    });

    function setDefaultReferenceEditorState() {
        const html = "<p>Regarding <strong>" + props.reference + "</strong> 😀:</p>";
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            setEditorState(EditorState.createWithContent(contentState));
        }
    }

    function onEditorStateChange(editor) {
        const now = new Date();
        setEditorState(editor);
        const jsonHtml = convertToRaw(editor.getCurrentContent());
        const html = draftToHtml(jsonHtml);
        setComment({
            ...data,
            created_on: now,
            updated_on: now,
            comment: html,
        });
    }

    function resetCommentUseState() {
        setComment({
            id: "",
            parent_id: "",
            reference: { id: props.object_id, type: props.object_type },
            created_by_id: props.user.id,
            author_name: props.user.fname + " " + props.user.lname,
            comment: "",
            children: [],
            created_on: "",
            updated_on: "",
            updated_by_id: props.user.id,
            isdeleted: false,
        });
    }

    const handleClose = () => {
        setIsEdit(false);
        setEditorDisplay("none");
        setButtonDisplay("block");
    };

    const handleModalBtn = (event) => {
        const clickEventValue = event.currentTarget.value;
        if (clickEventValue === "confirm") {
            deleteComment(currentComment);
        } else if (clickEventValue === "cancel") {
            setOpen(false);
        }
    };

    const deleteComment = async (dataObj) => {
        await axios
            .post("/api/comments/delete", dataObj, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    // remove the comment from the UI
                    const commentRemoved = commentData.comments.filter((comment) => {
                        return comment.id !== dataObj.comment_id;
                    });
                    console.log(commentRemoved);
                    props.setComments(commentRemoved);
                    // props.comments = commentRemoved;
                    setOpen(false);
                    if (_state) _state.switch.commentsModal();
                    setCommentData({ comments: commentRemoved });

                    // console.log(commentData);
                }
            })
            .catch((error) => {
                console.log("Something went wrong with the delete, Error: ", error);
                setFlashMessage("There was an error deleting your comment!");
                setOpenFlashMessage(true);
            });
    };

    const handleDeleteComment = (data) => {
        console.log("handleDeleteComment");
        setOpen(true);
        const dataObj = {
            project_id: data.reference.id,
            comment_id: data.id,
            rm_id: data.created_by_id,
        };
        // console.log(dataObj);
        setCurrentComment(dataObj);
    };

    function editComment() {
        console.log("BEFORE CALL: ", commentData.comments);
        const params = data;
        if (data.comment !== "") {
            axios
                .post("/api/comments/update", params, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                })
                .then((result) => {
                    if (result.status === 200) {
                        const comment = result.data.data;
                        // Find the comment obj in the array by ID
                        const index = commentData.comments.findIndex(({ id }) => id === comment.id);
                        if (index !== -1) {
                            commentData.comments[index] = comment;
                        }
                        // SET MASTER COMMENTS STATE
                        props.setComments(commentData.comments);
                        setComment(comment);
                        setIsEdit(false);
                        setEditorDisplay("none");
                        setButtonDisplay("block");
                    }
                })
                .catch((e) => {
                    console.log(e, " :: catch block in update");
                    setFlashMessage("There was an error updating your comment!");
                    setOpenFlashMessage(true);
                    setIsEdit(false);
                });
        }
    }

    function addComment() {
        const params = data;
        if (data.comment !== "") {
            axios
                .post("/api/comments/create", params, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                })
                .then((result) => {
                    if (result.status === 200) {
                        // ID should ideally included within the data key
                        const comment = result.data.data;
                        const cid = comment.id.id;
                        comment.data.id = cid;
                        // console.log("SAVED NEW COMMENT: ", comment.data);
                        commentData.comments.unshift(comment.data);
                        props.setComments(commentData.comments);
                        setIsEdit(false);
                        setEditorDisplay("none");
                        setButtonDisplay("block");
                    }
                })
                .catch((e) => {
                    console.log(e, " :: in catch block");
                    setFlashMessage("There was an error saving your comment!");
                    setOpenFlashMessage(true);
                    setIsEdit(false);
                });
        }
    }

    function openCommentDialogEdit(c) {
        setIsEdit(true);
        setEditorDisplay("block");
        setButtonDisplay("none");
        const contentBlock = htmlToDraft(c.comment);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            setEditorState(EditorState.createWithContent(contentState));
        }

        setComment({
            ...data,
            id: c.id,
            comment: c.comment,
        });
    }

    function openCommentDialog(event) {
        setEditorState(EditorState.createEmpty());
        setIsEdit(false);
        setEditorDisplay("block");
        setButtonDisplay("none");
        resetCommentUseState();
        if (props.reference) {
            setDefaultReferenceEditorState();
        }
    }

    useEffect(() => {
        if (commentData.comments !== props.comments) setCommentData({ comments: props.comments });
    }, [props, commentData]);

    return (
        <div>
            <Box display={editorDisplay}>
                <Editor
                    editorState={editor}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName={{ border: "1px solid #EAEAEA", borderRadius: "2px", padding: "12px" }}
                    onEditorStateChange={onEditorStateChange}
                />
                <Button color="inherit" onClick={isEdit ? editComment : addComment}>
                    Save
                </Button>
                <Button color="inherit" onClick={handleClose} aria-label="close">
                    Cancel
                </Button>
            </Box>
            <Box display={buttonDisplay}>
                <Button
                    sx={{ fontSize: ".65rem" }}
                    size="small"
                    fullWidth
                    onClick={openCommentDialog}
                    variant="contained"
                >
                    Add Comment
                </Button>
            </Box>
            <Grid container spacing={3}>
                <Grid item sm={12} xs={12}>
                    {commentData.comments.length > 0 && (
                        <Box
                            sx={{
                                marginTop: "15px",
                                overflowY: "auto",
                                overflowX: "hidden",
                                height: props.height,
                            }}
                        >
                            {commentData.comments.map(function (comment, idx) {
                                return (
                                    <Paper
                                        sx={{
                                            padding: theme.spacing(1),
                                            textAlign: "center",
                                            color: theme.palette.text.secondary,
                                            backgroundColor: "#f6f6bc",
                                            marginBottom: "15px",
                                        }}
                                        key={idx}
                                    >
                                        <Box display="flex" justifyContent="space-between">
                                            <Box mt="2em">
                                                <Box key={idx} sx={{ marginBottom: "6px" }}>
                                                    <Typography
                                                        sx={{
                                                            fontSize: ".5rem",
                                                            color: "#555",
                                                            textAlign: "left",
                                                            marginBottom: "5px",
                                                        }}
                                                    >
                                                        {comment.author_name} wrote on{" "}
                                                        {props.formatDate(comment.created_on)}
                                                        {comment.updated_on > comment.created_on &&
                                                            " - Last updated: " + props.formatDate(comment.updated_on)}
                                                    </Typography>
                                                    {props.user.id === comment.created_by_id ? (
                                                        <Box
                                                            dangerouslySetInnerHTML={{ __html: comment.comment }}
                                                            sx={{
                                                                fontSize: ".75rem",
                                                                color: "#222",
                                                                textAlign: "left",
                                                                cursor: "pointer",
                                                            }}
                                                            id={comment.id}
                                                            onClick={() => openCommentDialogEdit(comment)}
                                                        />
                                                    ) : (
                                                        <Box
                                                            dangerouslySetInnerHTML={{ __html: comment.comment }}
                                                            sx={{
                                                                fontSize: ".75rem",
                                                                color: "#333",
                                                                textAlign: "left",
                                                            }}
                                                        />
                                                    )}
                                                </Box>
                                            </Box>
                                            <Box>
                                                <IconButton onClick={() => handleDeleteComment(comment)} size="large">
                                                    <Close fontSize="small" />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    </Paper>
                                );
                            })}
                        </Box>
                    )}
                </Grid>
            </Grid>
            <Modal
                sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box
                        sx={{
                            backgroundColor: theme.palette.background.paper,
                            border: "2px solid #000",
                            boxShadow: theme.shadows[5],
                            padding: theme.spacing(2, 4, 3),
                        }}
                    >
                        <h3>Delete Comment?</h3>
                        <Box sx={{ marginTop: "2em" }} display="flex" flexDirection="row" justifyContent="center">
                            <Button
                                sx={{ marginRight: ".5em" }}
                                variant="contained"
                                color="primary"
                                onClick={handleModalBtn}
                                value="confirm"
                            >
                                Confirm
                            </Button>
                            <Button
                                sx={{ marginLeft: ".5em" }}
                                variant="contained"
                                color="primary"
                                onClick={handleModalBtn}
                                value="cancel"
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
            <FlashMessage
                openFlashMessage={openFlashMessage}
                setOpenFlashMessage={setOpenFlashMessage}
                message={flashMessage}
                severity={"error"}
            />
        </div>
    );
}

export default Comments;
