import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import axios from "axios";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Paper from "@mui/material/Paper";
import moment from "moment";
import "moment-duration-format";
import Comments from "../components/Comments";
import ClientContactForm from "../components/ClientContactForm";
import { authContext } from "../context/auth";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    appBar: {
        position: "relative",
    },
    table: {
        width: "100%",
    },
    hcell: {
        textAlign: "left",
        borderBottom: "1px solid #CCC",
        fontSize: ".35rem",
    },
    tcell: {
        textAlign: "left",
        borderBottom: "1px solid #F1F1F1",
        padding: "8px",
        fontSize: ".85em",
    },
    actioncell: {
        textAlign: "right",
        borderBottom: "1px solid #F1F1F1",
        padding: "5px",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    label: {
        color: "#8aa694",
        marginBottom: theme.spacing(0.25),
        fontSize: ".65rem",
        textAlign: "left",
        fontWeight: "normal",
        textTransform: "uppercase",
    },
    name: {
        color: "#3d4a42",
        marginBottom: theme.spacing(0.25),
        fontSize: "2rem",
        textAlign: "left",
        fontWeight: "bold",
        fontFamily: "Serif",
    },
    position: {
        color: "#444",
        marginTop: "0",
        marginBottom: theme.spacing(0.25),
        fontSize: "1rem",
        textAlign: "left",
        fontWeight: "normal",
    },
    bio: {
        color: "#444",
        marginBottom: theme.spacing(0.25),
        fontSize: "1rem",
        textAlign: "left",
        fontWeight: "normal",
    },
    contacts: {
        color: "#444",
        marginBottom: theme.spacing(0.25),
        fontSize: ".85rem",
        textAlign: "left",
        fontWeight: "normal",
    },
    contact: {
        color: "#444",
        marginBottom: theme.spacing(0.25),
        fontSize: ".75rem",
        textAlign: "left",
        fontWeight: "normal",
    },
    dates: {
        color: "#444",
        marginBottom: theme.spacing(0.25),
        fontSize: ".75rem",
        textAlign: "left",
        fontWeight: "normal",
        fontStyle: "italic",
    },
    strong: {
        color: "#888",
        marginBottom: theme.spacing(0.5),
        fontSize: "inherit",
    },
    tags: {
        color: "#607467",
        marginBottom: theme.spacing(0.5),
        fontSize: ".75rem",
    },
    current: {
        backgroundColor: "#ecf8f0",
        borderRadius: "2px",
        color: "#000000",
        padding: "2px 8px",
    },
    link: {
        color: "#3c8864",
        textDecoration: "none",
    },
    appBarName: {
        color: "#b7ddd9",
    },
    margin: {
        margin: theme.spacing(1),
    },
    submit: {
        color: "#fff",
        margin: theme.spacing(3, 0, 2),
    },
    button: {
        fontSize: ".65rem",
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function ContactShow(props) {
    const { match, history } = props;
    const { id } = match.params;
    const classes = useStyles();
    const { setAuthData, auth } = useContext(authContext);
    // const [open, setOpen] = useState(false);
    const [openContact, setOpenContact] = useState(false);
    const [data, setData] = useState({
        accountid: "",
        assistantname: "",
        assistantphone: "",
        birthdate: "",
        client_id: "",
        clientid: "",
        clientname: "",
        created_on: "",
        createdbyid: "",
        createddate: "",
        department: "",
        description: "",
        email: "",
        emailbounceddate: "",
        emailbouncedreason: "",
        fax: "",
        firstname: "",
        homephone: "",
        id: "",
        include_in_org_chart__c: false,
        isdeleted: false,
        isemailbounced: false,
        lastactivitydate: "",
        lastmodifiedbyid: "",
        lastmodifieddate: "",
        lastname: "",
        lastvieweddate: "",
        leadsource: "",
        mailingcity: "",
        mailingcountry: "",
        mailingpostalcode: "",
        mailingstate: "",
        mailingstreet: "",
        mobilephone: "",
        name: "",
        otherphone: "",
        ownerid: "",
        phone: "",
        photourl: "",
        picture__c: "",
        projects: [],
        referred_by__c: "",
        referred_by_email_address__c: "",
        reportstoid: "",
        salesforceid: "",
        salutation: "",
        systemmodstamp: "",
        title: "",
        notifications: [],
        tags: [],
        comments: [],
    });

    function handleClickOpenContact() {
        setData({
            ...data,
            id: data.id,
            salutation: data.salutation,
            firstname: data.firstname,
            lastname: data.lastname,
            name: `${data.firstname} ${data.lastname}`,
            email: data.email,
            title: data.title,
            phone: data.phone,
            salesforceid: data.salesforceid,
        });
        setOpenContact(true);
        console.log("Client Contact: ", data);
    }

    const handleBack = () => {
        history.goBack();
    };

    const handleCloseContact = () => {
        setOpenContact(false);
    };

    function handleTagEvent(tagsArray) {
        if (typeof tagsArray === "object") {
            setData({
                ...data,
                tags: tagsArray,
            });
        }
    }

    function handleChangeContact(event) {
        const { target } = event;
        const { value, name } = target;
        setData({
            ...data,
            [name]: value,
        });
    }

    function setCommentsArray(arr) {
        if (typeof arr === "object") {
            setData({
                ...data,
                comments: arr,
            });
        }
    }

    useEffect(() => {
        async function fetchContactById(id) {
            // Client #169 is great for client contacts
            const clientData = await axios.get(`/api/contact/findById?id=${id}`);
            if (clientData.status === 200 && clientData.data && clientData.data.data) {
                if (clientData.data.data[0].length === 0) return;
                const client = clientData.data.data[0][0];
                const tags = clientData.data.data[1].map((tag) => {
                    return { label: tag.name, value: tag.id };
                });
                const projectData = clientData.data.data[4];
                // Sort the array by updated_on
                const projectDataArray = [];
                for (let i = 0; i < projectData.length; i++) {
                    const proj = {
                        id: projectData[i].project_id,
                        title: projectData[i].title,
                        status: projectData[i].project_status,
                        type: projectData[i].project_type,
                        updated: new Date(projectData[i].updated_on),
                    };
                    projectDataArray.push(proj);
                }
                const projects = projectDataArray.slice().sort((a, b) => b.updated - a.updated);
                console.log("Return DATA", clientData);

                setData({
                    ...data,
                    id: client.id,
                    accountid: client.accountid,
                    assistantname: client.assistantname,
                    assistantphone: client.assistantphone,
                    birthdate: client.birthdate,
                    client_id: client.client_id,
                    clientid: client.clientid,
                    clientname: client.clientname,
                    created_on: client.created_on,
                    createdbyid: client.createdbyid,
                    createddate: client.createddate,
                    department: client.department,
                    description: client.description,
                    email: client.email,
                    emailbounceddate: client.emailbounceddate,
                    emailbouncedreason: client.emailbouncedreason,
                    fax: client.fax,
                    firstname: client.firstname,
                    homephone: client.homephone,
                    include_in_org_chart__c: client.include_in_org_chart__c,
                    isdeleted: client.isdeleted,
                    isemailbounced: client.isemailbounced,
                    lastactivitydate: client.lastactivitydate,
                    lastmodifiedbyid: client.lastmodifiedbyid,
                    lastmodifieddate: client.lastmodifieddate,
                    lastname: client.lastname,
                    lastvieweddate: client.lastvieweddate,
                    leadsource: client.leadsource,
                    mailingcity: client.mailingcity,
                    mailingcountry: client.mailingcountry,
                    mailingpostalcode: client.mailingpostalcode,
                    mailingstate: client.mailingstate,
                    mailingstreet: client.mailingstreet,
                    mobilephone: client.mobilephone,
                    name: client.name,
                    otherphone: client.otherphone,
                    ownerid: client.ownerid,
                    phone: client.phone,
                    photourl: client.photourl,
                    picture__c: client.picture__c,
                    projects,
                    referred_by__c: client.referred_by__c,
                    referred_by_email_address__c: client.referred_by_email_address__c,
                    reportstoid: client.reportstoid,
                    salesforceid: client.salesforceid,
                    salutation: client.salutation,
                    systemmodstamp: client.systemmodstamp,
                    title: client.title,
                    tags,
                    comments: clientData.data.data[2],
                });
            }
        }
        if (id) {
            fetchContactById(id);
        }
    }, [id]);

    function formatDateString(str) {
        return moment(str).format("MMM. Do, YYYY, h:mma");
    }

    function createProject() {
        history.push(`/project/create?id=${data.id}`);
    }

    function navigateToEdit() {
        history.push(`/contact/edit/${id}`);
    }

    function submitClientContactEvent() {
        const url = "/api/contact/update";

        if (data.firstname !== "" && data.lastname !== "" && data.email !== "") {
            axios
                .post(url, data, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                })
                .then((result) => {
                    if (result.status === 200) {
                        console.log(`Successful POST: ${JSON.stringify(result.data.data)}`);
                        setOpenContact(false);
                    } else {
                        console.log(`Good POST, but something went wrong: ${JSON.stringify(data)}`);
                    }
                })
                .catch(() => {
                    setOpenContact(false);
                    console.log(`API not done yet, 404 Error: ${JSON.stringify(data)}`);
                });
        }
    }

    return (
        <div>
            <Dialog fullScreen open={openContact} onClose={handleCloseContact} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleCloseContact}
                            aria-label="close"
                            size="large"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Edit <span className={classes.appBarName}>{data.name}</span>
                        </Typography>
                        <Button color="inherit" onClick={submitClientContactEvent}>
                            Save
                        </Button>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <ClientContactForm
                        id="contact"
                        margin="normal"
                        name="contact"
                        changeEvent={handleChangeContact}
                        data={data}
                    />
                </DialogContent>
            </Dialog>
            <Grid container spacing={3}>
                <Grid item sm={9} xs={12}>
                    <Grid container spacing={3}>
                        <Grid item sm={2} xs={12}>
                            <Button
                                className={classes.button}
                                size="small"
                                fullWidth
                                onClick={handleBack}
                                variant="contained"
                            >
                                Back
                            </Button>
                        </Grid>
                        <Grid item sm={5} xs={12}>
                            <Button
                                className={classes.button}
                                size="small"
                                color="primary"
                                fullWidth
                                onClick={navigateToEdit}
                                variant="contained"
                            >
                                Edit this Contact
                            </Button>
                        </Grid>
                        <Grid item sm={5} xs={12}>
                            <Button
                                className={classes.button}
                                size="small"
                                color="primary"
                                fullWidth
                                onClick={createProject}
                                variant="contained"
                            >
                                Create Project
                            </Button>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <Paper className={classes.paper}>
                                <p className={classes.label}>
                                    Salesforce ID: <strong className={classes.strong}>{data.salesforceid}</strong>
                                    {", "}
                                    Last Modified:{" "}
                                    <strong className={classes.strong}>
                                        {formatDateString(data.lastmodifieddate)}
                                    </strong>
                                </p>
                                <h1 className={classes.name}>
                                    {data.firstname} {data.lastname}
                                </h1>
                                <h2 className={classes.position}>
                                    {data.title && `${data.title},`}{" "}
                                    <Link to={`/client/view/${data.client_id}`} className={classes.link}>
                                        {data.clientname}
                                    </Link>
                                </h2>

                                <Box display="flex" flexDirection="column">
                                    <h3 className={classes.contact}>
                                        <strong>Contact:</strong> {data.email ? data.email : "* email unavailable"}
                                        {/* {" | "}
                                    {data.phone ? data.phone : "* phone unavailable"} */}
                                    </h3>
                                    {data.phone && (
                                        <h3 className={classes.contact}>
                                            <strong>Main Phone:</strong> {data.phone}
                                        </h3>
                                    )}
                                    {data.mobilephone && (
                                        <h3 className={classes.contact}>
                                            <strong>Mobile Phone:</strong> {data.mobilephone}
                                        </h3>
                                    )}
                                    {data.homephone && (
                                        <h3 className={classes.contact}>
                                            <strong>Home Phone:</strong> {data.homephone}
                                        </h3>
                                    )}
                                    {data.otherphone && (
                                        <h3 className={classes.contact}>
                                            <strong>Other Phone:</strong> {data.otherphone}
                                        </h3>
                                    )}
                                    {data.assistantphone && (
                                        <h3 className={classes.contact}>
                                            <strong>Assistant Phone:</strong> {data.assistantphone}
                                        </h3>
                                    )}
                                </Box>
                                {data.billingcity && (
                                    <h4 className={classes.contact}>
                                        <strong>Location:</strong> {data.billingcity},{" "}
                                        {data.billingstate && data.billingstate}{" "}
                                        {data.billingcountry && data.billingcountry}
                                    </h4>
                                )}
                                {data.tags.length > 0 && (
                                    <h5 className={classes.contact}>
                                        <strong>Tags:</strong>
                                        {data.tags.map(function (t, idx) {
                                            return (
                                                <em className={classes.tags} key={idx}>
                                                    {" "}
                                                    {t.label}
                                                    {idx < data.tags.length - 1 && ", "}
                                                </em>
                                            );
                                        })}
                                    </h5>
                                )}
                                {data.website && (
                                    <p className={classes.contact}>
                                        Visit:{" "}
                                        <a
                                            href={data.website}
                                            className={classes.link}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {data.website}
                                        </a>
                                    </p>
                                )}
                            </Paper>
                        </Grid>
                        {data.projects.length > 0 && (
                            <Grid item sm={12} xs={12}>
                                <Paper className={classes.paper}>
                                    <label className={classes.label}>PROJECT HISTORY</label>
                                    <br />
                                    <br />
                                    <table className={classes.table} cellSpacing={0}>
                                        <thead>
                                            <tr>
                                                <th scope="col" className={classes.hcell}>
                                                    STATUS
                                                </th>
                                                <th scope="col" className={classes.hcell}>
                                                    TITLE
                                                </th>
                                                <th scope="col" className={classes.hcell}>
                                                    TYPE
                                                </th>
                                                <th scope="col" className={classes.hcell}>
                                                    LAST UPDATED
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.projects.map(function (c, idx) {
                                                return (
                                                    <tr className={classes.contacts} key={c.id}>
                                                        <td scope="col" className={classes.tcell}>
                                                            {c.status}
                                                        </td>
                                                        <td scope="col" className={classes.tcell}>
                                                            <Link className={classes.link} to={`/project/view/${c.id}`}>
                                                                {c.title}
                                                            </Link>
                                                        </td>
                                                        <td className={classes.tcell}>{c.type}</td>
                                                        <td className={classes.tcell}>{formatDateString(c.updated)}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </Paper>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Comments
                        user={auth.data}
                        object_type="Contact"
                        object_id={props.match.params.id}
                        formatDate={formatDateString}
                        setComments={setCommentsArray}
                        comments={data.comments}
                        height="auto"
                    />
                </Grid>
            </Grid>
        </div>
    );
}

ContactShow.propTypes = {
    match: PropTypes.object,
};
ContactShow.defaultProps = {
    match: null,
};

export default ContactShow;
