// REACT
import * as React from "react";

// MUI
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Button, Grid, Paper } from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";
import ExpertSnippetNext from "../components/expert/ExpertSnippetNext";
import { basicDateTimeStamp, numberWithCommas, formatPhoneNumber, truncateString } from "../Utils";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import ProjectSelectModal from "../components/expert/ProjectSelect";
import PhoneLogsModal from "../components/project/PhoneLogsModal";

// MUI STYLES
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

// CONTEXT
import { authContext } from "../context/auth";

// NPM
import axios from "axios";
import { useHistory } from "react-router-dom";
import queryString from "query-string";

// CUSTOM COMPONENTS
import AttachToProject from "../components/AttachToProject";
import ExpertDisplay from "../components/expert/ExpertDisplay";
import ExpertProjectDisplay from "../components/expert/ExpertProjectDisplay";
import FlashMessage from "../components/FlashMessagev3";
import RealTimeUpdateTagManagerExpert from "../components/expert/RealTimeUpdateTagManagerExpert";

// ======
// STYLES
// ======

const buttonStyle = css`
    font-size: 0.65rem;
`;

const unsubscribeStyle = css`
    color: red;
    font-size: 0.85rem;
    text-align: left;
    font-weight: normal;
    padding: 0;
    margin: 0;
`;

const dncStyle = css`
    text-align: left;
    color: red;
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
`;

const paperStyle = css`
    padding: 1rem;
    text-align: left;
    color: #000000;
    margin-bottom: 1.5rem;
`;

const labelStyle = css`
    color: #523197;
    margin-bottom: 0.5rem;
    font-size: 0.75rem;
    text-align: left;
    font-weight: bold;
    text-transform: uppercase;
`;

const locationStyle = css`
    color: #525252;
    font-size: 1rem;
    text-align: left;
    font-weight: 300;

    margin-bottom: 1.25rem;
`;

const warningStyle = css`
    color: #fb923c;
    font-weight: 500;
`;

// ===========
// MUI HELPERS
// ===========

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

// =========
// CONSTANTS
// =========

const currencies = [
    {
        value: "USD",
        label: "$ - USD",
    },
    {
        value: "EUR",
        label: "€ - EUR",
    },
    {
        value: "JPY",
        label: "¥ - JPY",
    },
    {
        value: "GBP",
        label: "£ - GBP",
    },
];

export default function ExpertShowNext(props) {
    // =====
    // PROPS
    // =====

    const expert_id = props?.match?.params?.id || props?.expert_id;
    if (!expert_id) throw new Error("Invalid Expert ID");

    const { pid: project_id } = queryString.parse(location.search);

    // access list
    const APPROVAL_ACCESS_LIST = [3, 67291, 153383, 95647, 309841, 67281, 340269, 309842];
    // 3 - dan c., 67291 - michale f., 153383 - victoria, 95647 - felix b., 309841 - Anjali, 67281 - Joe Riggio, 340269 - Brandon Orr, 309842 - Jon Krais

    // =====
    // STATE
    // =====

    const [data, setData] = React.useState({});
    const [loadingData, setLoadingData] = React.useState(true);
    const [value, setValue] = React.useState(0);
    const { auth } = React.useContext(authContext);
    const [openFlashMsg, setOpenFlashMsg] = React.useState(false);
    const [isEditingHeadline, setIsEditingHeadline] = React.useState(false);
    const [isEditingRate, setIsEditingRate] = React.useState(false);
    const [openPhoneLogs, setOpenPhoneLogs] = React.useState(false);
    const [phoneNumber, setPhoneCallNumber] = React.useState("");
    const [projectSelect, setProjectSelectModal] = React.useState(false);
    const [selected, setProjectID] = React.useState("");
    // REFS
    const headlineRef = React.useRef(null);
    const rateRef = React.useRef(null);
    const currencyRef = React.useRef(null);

    const history = useHistory();

    // ============
    // HANDLE FUNCS
    // ============
    async function resubscribeExpert(expertId) {
        const url = "/api/mail/resubscribe";
        const postData = {
            expertId,
        };
        await axios
            .post(url, postData, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    console.log("Successfully Re-subscribed");
                    setOpenFlashMsg(true);
                } else {
                    console.log(`Good POST, but something went wrong: ${JSON.stringify(data)}`);
                }
            })
            .catch(() => {
                console.log(`API not done yet, 404 Error: ${JSON.stringify(data)}`);
            });
    }

    async function handleSaveHeadlineClick() {
        console.log(headlineRef, " :: headlineRef");
        const newHeadline = headlineRef.current.value;
        console.log(newHeadline, " :: newHeadline");

        const newData = { ...data };
        newData.expert.headline = newHeadline;

        console.log(newData, " :: newData");

        await axios
            .post(`/api/expert/updateHeadline`, { headline: newHeadline, id: data?.expert?.expert_id })
            .then((r) => {
                console.log(r, " :: r");
                if (r?.data?.data) {
                    setData(newData);
                    setIsEditingHeadline(false);
                }
            })
            .catch((e) => {
                console.log(e, " :: error in handleSaveHeadlineClick");
            });
    }

    async function handleSaveRateClick() {
        console.log(rateRef, " :: rateRef");
        const newRate = rateRef.current.value;
        console.log(newRate, " :: newRate");
        const newCurrency = currencyRef.current.value;
        console.log(newCurrency, " :: newCurrency");

        const newData = { ...data };
        newData.expert.consultation_rate = newRate;
        newData.expert.consultation_rate_currency = newCurrency;

        console.log(newData, " :: newData");

        await axios
            .post(`/api/expert/updateExpertRate`, {
                person_id: newData?.expert?.person_id,
                id: newData?.expert?.expert_id,
                consultation_rate: newRate,
                consultation_rate_currency: newCurrency,
            })
            .then((r) => {
                if (r?.data?.data) {
                    setData(newData);
                    setIsEditingRate(false);
                }
            })
            .catch((e) => {
                console.log(e, " :: error in handleSaveHeadlineClick");
            });
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function handleTokenClick() {
        generateToken(data?.expert?.expert_id);
    }

    function handleExpertPortalLinkClick() {
        navigator.clipboard.writeText(`https://www.firstthoughtnetwork.io/signup/${data?.expert?.expert_id}`);
        setOpenFlashMsg(true);
    }

    function handleExpertUnsubscribeLinkClick() {
        navigator.clipboard.writeText(
            `https://www.firstthought.io/unsubscribe/${data?.expert?.expert_id}/${data?.primaryEmails?.[0]?.email}/tnc`
        );
        setOpenFlashMsg(true);
    }

    function handleRateClick() {
        console.log(headlineRef, " :: headlineRef");
        rateRef.current.value = data?.expert?.consultation_rate;
        currencyRef.current.value = data?.expert?.consultation_rate_currency;
        setIsEditingRate(true);
    }

    function handleHeadlineClick() {
        console.log(headlineRef, " :: headlineRef");
        headlineRef.current.value = data?.expert?.headline;
        setIsEditingHeadline(true);
    }

    function logPhoneCall(number) {
        console.log("logPhoneCall", number);
        setPhoneCallNumber(number);
        if (project_id) {
            console.log("Is there a project ID? ", project_id);
            // Open up log call modal
            setOpenPhoneLogs(true);
        } else {
            setProjectSelectModal(true);
            console.log("No project ID", project_id);
            // Open project selection modal
        }
    }

    function projectEvent(pid) {
        console.log("PROJECT ID: ", pid);
        setProjectID(pid);
        // Open up log call modal
        setOpenPhoneLogs(true);
    }

    function blankEvent(pid) {
        console.log("PROJECT ID FROM BLANK EVENT: ", pid);
    }

    // ============
    // HELPER FUNCS
    // ============

    async function generateToken(expertId) {
        console.log(auth.data, " :: auth.data");
        const postData = {
            id: expertId,
            pid: project_id ? project_id : "",
            rmid: auth.data.id,
            rmemail: auth.data.mail,
            rmfname: auth.data.fname,
            rmlname: auth.data.lname,
            rmphone: formatPhoneNumber(auth.data.number, "+4234"),
        };

        console.log(postData, " :: postData");
        await axios
            .post(`/api/expert/generatetokenurl`, postData)
            .then((result) => {
                if (result?.data) {
                    console.log(result?.data?.data, " :: result?.data?.data");
                    navigator.clipboard.writeText(result?.data?.data);
                    setOpenFlashMsg(true);
                }
            })
            .catch((error) => {
                console.log(error, " :: error in generateToken in ExpertShowNext");
            });
    }

    function renderCounts(statusType) {
        let attachedCount = 0;

        switch (statusType) {
            /**
             * using status of zero because on the backend we're essentially unioning on the EPR table
             * to figure out how many projects that expert has been attached to.  it seems like at the
             * moment there's a discrepency between the EPR table count and the status count.  I believe
             * the statuses aren't accounting for every place where an expert can be attached to.
             */
            case "ATTACHED": {
                if (data.statuses && "0" in data.statuses) {
                    attachedCount += data.statuses["0"].length;
                }

                return attachedCount;
            }
            case "ACCEPTED": {
                if (data.statuses && "4" in data.statuses) {
                    attachedCount += data.statuses["4"].length;
                }

                return attachedCount;
            }
            case "DECLINED": {
                if (data.statuses && "5" in data.statuses) {
                    attachedCount += data.statuses["5"].length;
                }

                return attachedCount;
            }
            case "SENT_TO_CLIENT": {
                if (data.statuses && "6" in data.statuses) {
                    attachedCount += data.statuses["6"].length;
                }

                return attachedCount;
            }
            case "REJECTED_BY_CLIENT": {
                if (data.statuses && "7" in data.statuses) {
                    attachedCount += data.statuses["7"].length;
                }

                return attachedCount;
            }
            case "SCHEDULED": {
                if (data.statuses && "13" in data.statuses) {
                    attachedCount += data.statuses["13"].length;
                }

                return attachedCount;
            }
            case "CALL_COMPLETE": {
                if (data.statuses && "20" in data.statuses) {
                    attachedCount += data.statuses["20"].length;
                }

                return attachedCount;
            }
            case "PAID": {
                if (data.statuses && "19" in data.statuses) {
                    attachedCount += data.statuses["19"].length;
                }

                return attachedCount;
            }
            case "SURVEY_COMPLETED": {
                if (data.statuses && "28" in data.statuses) {
                    attachedCount += data.statuses["28"].length;
                }

                return attachedCount;
            }
            default: {
                return 0;
            }
        }
    }

    function renderPaymentDetails() {
        if (data?.payment?.length === 0) {
            return (
                <div
                    css={css`
                        font-weight: 700;
                        color: #ef4444;
                    `}
                >
                    No Payment Information
                </div>
            );
        } else {
            return (
                <div
                    css={css`
                        & > div > span {
                            font-weight: 500;
                        }
                    `}
                >
                    <div>
                        <span>{data?.payment?.[0]?.isdomesticaccount ? "Domestic" : "International"}</span> account{" "}
                        <span>{data?.payment?.[0]?.account_name}</span> was created on{" "}
                        <span>{basicDateTimeStamp(data?.payment?.[0]?.created_on)}</span> by{" "}
                        <span>{data?.payment?.[0]?.payment_created_by}</span>
                    </div>
                    {data?.payment?.[0]?.updated_on && (
                        <div>
                            Updated on <span>{data?.payment?.[0]?.updated_on}</span> by{" "}
                            <span>{data?.payment?.[0]?.payment_updated_by}</span>
                        </div>
                    )}
                </div>
            );
        }
    }

    function navigateToEdit() {
        if (project_id) {
            history.push(`/expert/edit/${expert_id}?pid=${project_id}`);
        } else {
            history.push(`/expert/edit/${expert_id}`);
        }
    }

    function navigateToPayment() {
        history.push(`/expert/payment/add/${expert_id}`);
    }

    // =========
    // PAGE LOAD
    // =========

    async function findExpertData() {
        await axios
            .get(`/api/expert/next/findExpertById/${expert_id}`)
            .then((r) => {
                const result = r?.data?.data?.data;
                console.log(result, " :: result in ExpertShowNext.jsx");
                if (result) {
                    setData(result);
                    setLoadingData(false);
                }
            })
            .catch((e) => {
                console.log(e, " :: e");
            });
    }

    React.useEffect(() => {
        findExpertData();
    }, []);

    if (loadingData) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <Grid
                container
                columnSpacing={3}
                sx={{ marginBottom: "1rem", backgroundColor: "#fafafa", padding: "1.5rem", borderRadius: "0.2rem" }}
            >
                {/* TOP NAV BUTTONS - start */}
                <Grid
                    container
                    columnSpacing={2}
                    css={css`
                        margin-bottom: 1rem;
                        & button {
                            padding: 0.5rem !important;
                        }
                    `}
                >
                    <Grid item xs={4}>
                        <Button
                            css={buttonStyle}
                            size="small"
                            fullWidth
                            onClick={() => history.goBack()}
                            variant="contained"
                        >
                            Back
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <AttachToProject
                            selected={[expert_id]}
                            btnText="Add to Project"
                            setFullWidth
                            user={auth.data}
                            disabled={data?.optoutData && data?.optoutData?.length > 0}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        {data?.payment?.length === 0 ? (
                            <Button
                                css={buttonStyle}
                                size="small"
                                color="primary"
                                fullWidth
                                variant="contained"
                                onClick={navigateToPayment}
                            >
                                Add Payment Info
                            </Button>
                        ) : (
                            <Button
                                css={buttonStyle}
                                size="small"
                                color="secondary"
                                fullWidth
                                variant="contained"
                                onClick={navigateToPayment}
                                disabled={data?.payment?.length === 0}
                            >
                                updated by{"  "}
                                {data?.payment?.[0]?.payment_updated_by?.trim()
                                    ? data?.payment?.[0]?.payment_updated_by?.trim()
                                    : data?.payment?.[0]?.payment_created_by?.trim()}
                            </Button>
                        )}
                    </Grid>
                </Grid>
                {/* TOP NAV BUTTONS - end */}

                <Grid item xs={7}>
                    {/* UNSUBSCRIBED - start */}
                    {data?.optoutData && data?.optoutData?.length > 0 && (
                        <>
                            <p css={unsubscribeStyle}>{`Unsubscribed on ${basicDateTimeStamp(
                                data?.optoutData[0]?.created_on
                            )}`}</p>
                            <h3 css={dncStyle}>{data?.optoutData[0]?.reason && `${data?.optoutData[0]?.reason}`}</h3>
                        </>
                    )}
                    {/* UNSUBSCRIBED - end */}
                    {/* DNC - start */}
                    <div>
                        {data?.expert?.dnc && (
                            <h3 css={dncStyle}>{data?.expert?.dnc && `Do Not Contact: ${data?.expert?.dnc}`}</h3>
                        )}
                    </div>
                    {/* DNC - end */}

                    <div
                        css={css`
                            margin-bottom: 0.5rem;
                        `}
                    >
                        {/* EXPERT NAME - start */}
                        <div
                            css={css`
                                font-size: 1rem;
                                color: #000;
                            `}
                        >
                            ID: <strong>{data?.expert?.expert_id}</strong>
                            <span>
                                {" "}
                                - ADDED BY: <strong>{data?.expert?.created_by_name}</strong> on{" "}
                                <strong>{basicDateTimeStamp(data?.expert?.expert_created_on)}</strong>
                            </span>
                        </div>
                        <div
                            css={css`
                                display: flex;
                                flex-direction: row;
                                align-items: baseline;
                            `}
                        >
                            <a
                                onClick={navigateToEdit}
                                css={css`
                                    display: block;
                                    color: #4c1d95;
                                    font-size: 3rem;
                                    text-transform: capitalize;
                                    display: block;
                                    text-align: left;
                                    text-decoration: none;
                                    font-weight: 500;
                                    cursor: pointer;
                                    &:hover {
                                        color: hsl(270, 90%, 50%);
                                    }
                                    &:hover > .icon {
                                        color: hsl(270, 90%, 50%);
                                    }
                                `}
                            >
                                {data?.expert?.fname} {data.expert?.mname} {data?.expert?.lname}
                                {data.expert?.suffix && `, ${data.expert.suffix}`}
                                {data.expert.has_signed_terms && data.expert.disclosures && (
                                    <VerifiedIcon
                                        className="icon"
                                        css={css`
                                            display: inline-block;
                                            color: #4c1d95;
                                            font-size: 2.5rem;
                                            margin-left: 1rem;
                                            position: relative;
                                            bottom: 0.2rem;
                                        `}
                                    />
                                )}
                            </a>
                        </div>
                        {/* EXPERT NAME - end */}

                        {/* META INFO - start */}
                        <div
                            css={css`
                                display: flex;
                                flex-direction: column;
                                background-color: #f5f3ff;
                                padding: 0.5rem 1rem;
                                border-radius: 0.2rem;
                                gap: 0.5rem;
                            `}
                        >
                            <div
                                css={css`
                                    display: flex;
                                    align-items: center;
                                    gap: 3rem;
                                `}
                            >
                                {data?.primaryEmails?.length > 0 && data?.optoutData?.length === 0 ? (
                                    <div className="divider">
                                        <EmailIcon
                                            css={css`
                                                font-size: 1rem;
                                                position: relative;
                                                bottom: 0.1rem;
                                                margin-right: 0.75rem;
                                                color: hsla(0, 0%, 32%, 0.3);
                                            `}
                                        />
                                        <a
                                            href={`mailto:${data?.primaryEmails?.[0]?.email}`}
                                            title={`${data?.primaryEmails?.[0]?.email}`}
                                            css={css`
                                                font-weight: 500;
                                                letter-spacing: 1px;
                                                color: #525252;
                                                text-decoration: none;
                                                &:hover {
                                                    color: hsl(270, 90%, 50%);
                                                    cursor: pointer;
                                                }
                                            `}
                                        >
                                            {truncateString(data?.primaryEmails?.[0]?.email, 20)}
                                        </a>
                                    </div>
                                ) : (
                                    <div> *** Missing Email *** </div>
                                )}
                                <div className="divider">
                                    {data?.primaryPhones?.length > 0 &&
                                    data?.primaryPhones?.[0]?.phone?.length > 8 &&
                                    data?.optoutData?.length === 0 ? (
                                        <>
                                            <CallIcon
                                                css={css`
                                                    font-size: 1rem;
                                                    position: relative;
                                                    bottom: 0.1rem;
                                                    margin-right: 0.75rem;
                                                    color: hsla(0, 0%, 32%, 0.3);
                                                `}
                                            />
                                            <a
                                                href={`tel:${data?.primaryPhones?.[0]?.code}${data?.primaryPhones?.[0]?.phone}`}
                                                css={css`
                                                    font-weight: 500;
                                                    letter-spacing: 1px;
                                                    color: #525252;
                                                    text-decoration: none;
                                                    margin-right: 0.75rem;
                                                    &:hover {
                                                        color: hsl(270, 90%, 50%);
                                                        cursor: pointer;
                                                    }
                                                `}
                                            >
                                                {data?.primaryPhones?.[0]?.code}{" "}
                                                {formatPhoneNumber(
                                                    data?.primaryPhones?.[0]?.phone,
                                                    data?.primaryPhones?.[0]?.code === "+1"
                                                )}
                                            </a>
                                            <Button onClick={() => logPhoneCall(data?.primaryPhones?.[0]?.phone)}>
                                                <CheckIcon
                                                    css={css`
                                                        font-size: 1rem;
                                                        margin-right: 0.25rem;
                                                        color: hsla(0, 0%, 32%, 0.3);
                                                    `}
                                                />
                                                Log A Call
                                            </Button>
                                        </>
                                    ) : (
                                        <div>
                                            <em css={warningStyle}>*** Missing Phone ***</em>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* META INFO - end */}
                        </div>
                    </div>

                    <hr />

                    <div
                        css={css`
                            color: #525252;
                            font-size: 1.25rem;
                            text-align: left;
                            font-weight: normal;
                            column-gap: 0.75rem;
                            align-items: center;
                        `}
                    >
                        {/* HEADLINE - start */}
                        <div
                            onClick={handleHeadlineClick}
                            css={css`
                                display: ${isEditingHeadline ? "none" : "block"};
                                &:hover {
                                    color: hsl(270, 90%, 50%);
                                    cursor: pointer;
                                }
                            `}
                        >
                            {data?.expert?.headline ? data.expert.headline : "** Missing Headline **"}
                        </div>
                        <div
                            css={css`
                                display: ${isEditingHeadline ? "flex" : "none"};
                                flex-direction: row;
                                align-items: center;
                                width: 40vw;
                                border: 1px solid #a8a29e;
                                border-radius: 0.2rem;
                                justify-content: space-between;
                                padding: 0.25rem 1rem;
                            `}
                        >
                            <input
                                css={css`
                                    border: none;
                                    width: 100%;
                                    background-color: #fafafa;
                                    &:focus,
                                    &:active {
                                        outline: none;
                                    }
                                `}
                                ref={headlineRef}
                            />
                            <div
                                css={css`
                                    display: flex;
                                    gap: 0.5rem;
                                `}
                            >
                                <CheckIcon
                                    onClick={handleSaveHeadlineClick}
                                    css={css`
                                        color: #bbf7d0;
                                        &:hover {
                                            cursor: pointer;
                                            color: #16a34a;
                                        }
                                    `}
                                />
                                <CloseIcon
                                    onClick={() => setIsEditingHeadline(false)}
                                    css={css`
                                        color: #fecaca;
                                        &:hover {
                                            cursor: pointer;
                                            color: #dc2626;
                                        }
                                    `}
                                />
                            </div>
                        </div>
                        {/* HEADLINE - end */}
                        {/* LOCATION - start */}
                        <div
                            css={css`
                                display: block;
                                clear: both;
                            `}
                        >
                            <div css={locationStyle}>
                                <span>
                                    {data?.expert?.city_name ? data.expert.city_name : "** City **"}
                                    {", "}
                                    {data?.expert?.state_name ? data.expert.state_name : "** State **"}
                                    {", "}
                                    {data?.expert?.country_name ? data.expert.country_name : "** Country **"}{" "}
                                </span>
                            </div>
                        </div>
                        {/* LOCATION - end */}
                    </div>
                    <div
                        css={css`
                            margin-bottom: 0.35rem;
                        `}
                    >
                        {data?.expert?.linkedin_url ? (
                            <div>
                                <LinkedInIcon
                                    css={css`
                                        font-size: 1.25rem;
                                        position: relative;
                                        bottom: 0.1rem;
                                        margin-right: 0.25rem;
                                        color: hsla(0, 0%, 32%, 0.3);
                                    `}
                                />
                                <a
                                    href={data?.expert?.linkedin_url}
                                    css={css`
                                        font-weight: 500;
                                        letter-spacing: 1px;
                                        color: #525252;
                                        text-decoration: none;
                                        &:hover {
                                            color: hsl(270, 90%, 50%);
                                            cursor: pointer;
                                        }
                                    `}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {data?.expert?.linkedin_url}
                                </a>
                            </div>
                        ) : (
                            <div>
                                <em css={warningStyle}>*** Missing LinkedIn ***</em>
                            </div>
                        )}
                    </div>
                    {/* SPECIALTIES - start */}
                    {data?.specialties?.length > 0 && (
                        <div
                            css={css`
                                color: #525252;
                                font-size: 1;
                                text-align: left;
                                font-weight: normal;
                                & > .specialty-header {
                                    font-weight: 500;
                                }
                            `}
                        >
                            <span className="specialty-header">Specialty:</span>
                            {data?.specialties.map(function (specialty, index) {
                                return (
                                    <span
                                        css={css`
                                            font-size: 1rem;
                                            color: #5b21b6;
                                            text-transform: capitalize;
                                            font-weight: normal;
                                            font-style: italic;
                                            letter-spacing: 0.9px;
                                        `}
                                        key={index}
                                    >
                                        {" "}
                                        {specialty?.specialty_name}
                                        {index < data?.specialties?.length - 1 && ", "}
                                    </span>
                                );
                            })}
                        </div>
                    )}
                    {/* SPECIALTIES - end */}

                    {/* LINK BUTTONS - start */}
                    <div
                        css={css`
                            display: flex;
                            flex-wrap: wrap;
                            flex-direction: row;
                            align-items: center;
                            gap: 0.5rem;
                            margin-top: 2rem;
                        `}
                    >
                        <ExpertSnippetNext expertList={[data?.expert?.expert_id]} />
                        <div
                            css={css`
                                display: grid;
                                place-items: center;
                            `}
                        >
                            <button
                                onClick={handleTokenClick}
                                css={css`
                                    font-size: 0.75rem;
                                    font-weight: 500;
                                    color: #9a3412;
                                    margin: 0;
                                    padding: 0;
                                    border: none;
                                    text-transform: uppercase;
                                    background-color: #ffedd5;
                                    padding: 0.5rem 1rem;
                                    border-radius: 0.2rem;
                                    &:hover {
                                        background-color: #fed7aa;
                                    }
                                `}
                            >
                                Copy Onboarding Link
                            </button>
                        </div>
                        <div
                            css={css`
                                display: grid;
                                place-items: center;
                            `}
                        >
                            <button
                                onClick={handleExpertPortalLinkClick}
                                css={css`
                                    font-size: 0.75rem;
                                    font-weight: 500;
                                    color: #9a3412;
                                    margin: 0;
                                    padding: 0;
                                    border: none;
                                    text-transform: uppercase;
                                    background-color: #ffedd5;
                                    padding: 0.5rem 1rem;
                                    border-radius: 0.2rem;
                                    &:hover {
                                        background-color: #fed7aa;
                                    }
                                `}
                            >
                                Copy Expert Portal Link
                            </button>
                        </div>
                        <div
                            css={css`
                                display: grid;
                                place-items: center;
                            `}
                        >
                            <button
                                onClick={handleExpertUnsubscribeLinkClick}
                                css={css`
                                    font-size: 0.75rem;
                                    font-weight: 500;
                                    color: #9a3412;
                                    margin: 0;
                                    padding: 0;
                                    border: none;
                                    text-transform: uppercase;
                                    background-color: #ffedd5;
                                    padding: 0.5rem 1rem;
                                    border-radius: 0.2rem;
                                    &:hover {
                                        background-color: #fed7aa;
                                    }
                                `}
                            >
                                Copy Unsubscribe Link
                            </button>
                        </div>
                        {data?.optoutData &&
                            data?.optoutData?.length > 0 &&
                            APPROVAL_ACCESS_LIST.includes(auth.data.id) && (
                                <div
                                    css={css`
                                        display: grid;
                                        place-items: center;
                                    `}
                                >
                                    <Button
                                        css={css`
                                            font-size: 0.75rem;
                                            font-weight: 500;
                                            color: #9a3412;
                                            margin: 0;
                                            padding: 0;
                                            border: none;
                                            text-transform: uppercase;
                                            background-color: #ffedd5;
                                            padding: 0.5rem 1rem;
                                            border-radius: 0.2rem;
                                            &:hover {
                                                background-color: #fed7aa;
                                            }
                                        `}
                                        onClick={() => resubscribeExpert(data?.expert?.expert_id)}
                                    >
                                        Re-subscribe {data?.expert?.fname}
                                    </Button>
                                </div>
                            )}
                    </div>
                    {/* LINK BUTTONS - end */}
                </Grid>

                <Grid item xs={5}>
                    <>
                        {/* RATE - start */}
                        <div
                            css={css`
                                display: ${isEditingRate ? "none" : "block"};
                                background-color: #fff;
                                border: 1px solid #e0e0e0;
                                border-radius: 0.5rem;
                                padding: 1rem;
                            `}
                        >
                            {data?.expert?.consultation_rate ? (
                                <div
                                    onClick={handleRateClick}
                                    css={css`
                                        margin-top: 0.5rem;
                                        margin-bottom: 0.5rem;
                                        font-size: 4rem;
                                        color: #000;
                                        text-align: center;
                                        font-weight: 700;
                                        & > span {
                                            font-weight: 300;
                                            font-size: 1rem;
                                            display: inline-block;
                                            margin-left: 0.25rem;
                                        }
                                        &:hover {
                                            color: hsl(270, 90%, 50%);
                                            cursor: pointer;
                                        }
                                    `}
                                >
                                    {numberWithCommas(data.expert.consultation_rate)}
                                    <span>{data?.expert?.consultation_rate_currency}/HR</span>
                                    {data?.expert?.minimum_hourly > 0 && (
                                        <strong
                                            css={css`
                                                font-size: 0.75rem;
                                                color: #fb923c;
                                                letter-spacing: 1px;
                                                display: block;
                                            `}
                                        >
                                            {" "}
                                            (MIN: {data?.expert?.minimum_hourly} MINUTES)
                                        </strong>
                                    )}
                                </div>
                            ) : (
                                <div
                                    onClick={handleRateClick}
                                    css={css`
                                        &:hover {
                                            color: hsl(270, 90%, 50%);
                                            cursor: pointer;
                                        }
                                    `}
                                >{`** Missing Hourly Rate **`}</div>
                            )}
                        </div>
                        <div
                            css={css`
                                display: ${isEditingRate ? "flex" : "none"};
                                flex-direction: row;
                                align-items: center;
                                width: 18vw;
                                border: 1px solid #a8a29e;
                                border-radius: 0.2rem;
                                justify-content: space-between;
                                padding: 0.25rem 1rem;
                                gap: 1rem;
                            `}
                        >
                            <input
                                css={css`
                                    display: block;
                                    border: none;
                                    width: 100%;
                                    background-color: #fafafa;
                                    &:focus,
                                    &:active {
                                        outline: none;
                                    }
                                `}
                                ref={rateRef}
                            />
                            <select
                                css={css`
                                    display: block;
                                    border: none;
                                    cursor: pointer;
                                    background-color: #e5e5e5;
                                    border-radius: 0.2rem;
                                    padding: 0.25rem;
                                    &:hover,
                                    &:focus,
                                    &:active {
                                        outline: none;
                                    }
                                `}
                                ref={currencyRef}
                            >
                                {currencies.map((c) => (
                                    <option key={c.value} value={c.value}>
                                        {c.label}
                                    </option>
                                ))}
                            </select>
                            <div
                                css={css`
                                    display: flex;
                                    gap: 0.5rem;
                                `}
                            >
                                <CheckIcon
                                    onClick={handleSaveRateClick}
                                    css={css`
                                        color: #bbf7d0;
                                        &:hover {
                                            cursor: pointer;
                                            color: #16a34a;
                                        }
                                    `}
                                />
                                <CloseIcon
                                    onClick={() => setIsEditingRate(false)}
                                    css={css`
                                        color: #fecaca;
                                        &:hover {
                                            cursor: pointer;
                                            color: #dc2626;
                                        }
                                    `}
                                />
                            </div>
                        </div>
                        {/* RATE - end */}
                        {/* TAGS - start */}
                        <RealTimeUpdateTagManagerExpert person_id={data?.expert?.person_id} expertTags={data?.tags} />
                        {/* TAGS - end */}
                    </>
                </Grid>
            </Grid>
            <Grid container sx={{ backgroundColor: "#fafafa", padding: "1.5rem", borderRadius: "0.2rem" }}>
                <Grid item xs={12}>
                    <Tabs
                        // sx={{ backgroundColor: "#f5f3ff" }}
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                    >
                        <Tab label="Profile Details" {...a11yProps(0)} />
                        <Tab label={`${data?.expert?.fname}'s Project Activity`} {...a11yProps(1)} />
                    </Tabs>
                </Grid>
                <Grid item xs={12}>
                    <TabPanel value={value} index={0}>
                        <Paper css={paperStyle}>
                            <div css={labelStyle}>Quick Stats:</div>
                            <div
                                css={css`
                                    background-color: #f5f5f5;
                                    width: 100%;
                                    padding: 1rem 1.5rem;
                                    border-radius: 0.2rem;
                                    text-align: center;
                                `}
                            >
                                <div
                                    css={css`
                                        display: flex;
                                        margin-bottom: 0.5rem;
                                        & > div:not(:first-of-type) {
                                            margin-left: 0.5rem;
                                            padding-left: 0.5rem;
                                            border-left: 2px solid hsla(0, 0%, 32%, 0.3);
                                        }
                                        & > div > span {
                                            font-weight: 500;
                                        }
                                    `}
                                >
                                    <div>
                                        Attached: <span>{renderCounts("ATTACHED")}</span>
                                    </div>
                                    <div>
                                        Accepted: <span>{renderCounts("ACCEPTED")}</span>
                                    </div>
                                    <div>
                                        Sent: <span>{renderCounts("SENT_TO_CLIENT")}</span>
                                    </div>
                                    <div>
                                        Rejected: <span>{renderCounts("REJECTED_BY_CLIENT")}</span>
                                    </div>
                                    <div>
                                        Scheduled: <span>{renderCounts("SCHEDULED")}</span>
                                    </div>
                                    <div>
                                        Calls Completed: <span>{renderCounts("CALL_COMPLETE")}</span>
                                    </div>
                                    <div>
                                        Paid: <span>{renderCounts("PAID")}</span>
                                    </div>
                                    <div>
                                        Screeners Completed: <span>{renderCounts("SURVEY_COMPLETED")}</span>
                                    </div>
                                </div>
                                <div>{renderPaymentDetails()}</div>
                            </div>
                        </Paper>
                        {loadingData ? <div>Loading...</div> : <ExpertDisplay data={data} setData={setData} />}
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        {loadingData ? <div>Loading...</div> : <ExpertProjectDisplay data={data} />}
                    </TabPanel>
                </Grid>
            </Grid>
            {openFlashMsg && (
                <FlashMessage
                    openSnackbar={openFlashMsg}
                    setOpenSnackbar={setOpenFlashMsg}
                    severity={"success"}
                    message={"Copied Link"}
                />
            )}
            {phoneNumber && (
                <>
                    <PhoneLogsModal
                        openPhoneLogs={openPhoneLogs}
                        setOpenPhoneLogs={setOpenPhoneLogs}
                        userInfo={auth.data}
                        project_id={project_id ? project_id : selected}
                        expert_id={expert_id}
                        number={phoneNumber}
                        reloadEvent={blankEvent}
                    />
                    <ProjectSelectModal
                        open={projectSelect}
                        projects={data.projects}
                        closeModalEvent={setProjectSelectModal}
                        selectedProjectEvent={projectEvent}
                    />
                </>
            )}
        </>
    );
}
