import React, { useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
// import Select from "react-select";
import Grid from "@mui/material/Grid";
// import Checkbox from "@mui/material/Checkbox";
// import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";

const selectType = [
    { value: "contact", label: "Client Contact" },
    { value: "expert", label: "Expert" },
    { value: "compliance", label: "Compliance" },
];

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    section: {
        backgroundColor: "#add0b9",
        color: "#FFFFFF",
        padding: "8px",
        fontSize: "1.25rem",
        borderRadius: "2px",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: "75%",
        flexShrink: 0,
        fontWeight: "normal",
    },
    label: {
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(0.5),
        fontSize: "1rem",
    },
    strong: {
        color: theme.palette.text.primary,
        marginBottom: theme.spacing(0.5),
        fontSize: "1rem",
    },
    dates: {
        fontSize: "1rem",
        color: "#738b7b",
    },
    margin: {
        margin: theme.spacing(1),
    },
    bridgeNumbers: {
        padding: "10px",
        backgroundColor: "#ecf8f0",
        border: "1px solid #add0b9",
        borderRadius: "3px",
        marginBottom: theme.spacing(2),
        cursor: "pointer",
    },
    body: {
        padding: "20px",
    },
    dataLabel: {
        color: "#add0b9",
        textAlign: "left",
        paddingRight: "10px",
        fontSize: ".55rem",
        fontWeight: "bold",
    },
    dataPoint: {
        color: "#444",
        textAlign: "left",
        paddingRight: "20px",
        fontSize: ".75rem",
    },
}));

function ExternalBridgeSettings(props) {
    const classes = useStyles();
    const [jobIndex, setIndex] = useState("");
    const [isEdit, setEdit] = useState(false);
    const [external, setExternal] = useState({
        type: "",
        country: "",
        number: "",
        pin: "",
    });
    const [data, setData] = useState({
        numbers: props.external_bridge_numbers || [],
    });

    useEffect(() => {
        setData({ numbers: props.external_bridge_numbers || [] });
    }, [props]);

    function emptyCurrentSettingState() {
        setExternal({
            type: "",
            country: "",
            number: "",
            pin: "",
        });
        setEdit(false);
    }

    function handleChange(event) {
        const { target } = event;
        const { value, name } = target;
        setExternal({
            ...external,
            [name]: value,
        });
    }

    function handleAddNumber(event) {
        if (external.type !== "" && external.country !== "" && external.number !== "" && external.pin !== "") {
            if (Array.isArray(data.numbers)) {
                data.numbers.push(external);
            } else {
                console.log("The external bridge attribute MUST be an ARRAY", data.numbers);
            }
            // Finally send the Data array back to the parent page
            props.changeEvent(data.numbers);
            emptyCurrentSettingState();
            console.log("Add New: ", external);
            console.log("Current Data: ", data);
            setEdit(false);
        }
    }

    function editNumber(p) {
        // Remove from array
        data.numbers = data.numbers.filter((item) => item !== p);
        setExternal({
            type: p.type,
            country: p.country,
            number: p.number,
            pin: p.pin,
        });
        setEdit(true);
        console.log("Edit: ", external);
        console.log("Current Data: ", data);
    }

    function deleteNumber(event) {
        if (window.confirm("Are you sure you want to delete this number?")) {
            emptyCurrentSettingState();
            setEdit(false);
            console.log(data.numbers);
            // Send the Data array back to the parent page
            props.changeEvent(data.numbers);
        }
    }

    function renderGroupSettings(dataArray) {
        const contactsArray = dataArray.filter((i) => i.type === "contact");
        const expertsArray = dataArray.filter((i) => i.type === "expert");
        const complianceArray = dataArray.filter((i) => i.type === "compliance");
        return (
            <div>
                {contactsArray.length > 0 && (
                    <div>
                        <strong>Client Contact Dialin(s):</strong>
                        {contactsArray.map((n, index) => (
                            <div key={index} className={classes.bridgeNumbers} onClick={() => editNumber(n)}>
                                <span className={classes.dataLabel}>COUNTRY:</span>
                                <span className={classes.dataPoint}>{n.country}</span>
                                <span className={classes.dataLabel}>PHONE:</span>
                                <span className={classes.dataPoint}>{n.number}</span>
                                <span className={classes.dataLabel}>PIN:</span>
                                <span className={classes.dataPoint}>{n.pin}</span>
                            </div>
                        ))}
                    </div>
                )}
                {expertsArray.length > 0 && (
                    <div>
                        <strong>Expert Dialin(s):</strong>
                        {expertsArray.map((n, index) => (
                            <div key={index} className={classes.bridgeNumbers} onClick={() => editNumber(n)}>
                                <span className={classes.dataLabel}>COUNTRY:</span>
                                <span className={classes.dataPoint}>{n.country}</span>
                                <span className={classes.dataLabel}>PHONE:</span>
                                <span className={classes.dataPoint}>{n.number}</span>
                                <span className={classes.dataLabel}>PIN:</span>
                                <span className={classes.dataPoint}>{n.pin}</span>
                            </div>
                        ))}
                    </div>
                )}
                {complianceArray.length > 0 && (
                    <div>
                        <strong>Client Compliance Dialin(s):</strong>
                        {complianceArray.map((n, index) => (
                            <div key={index} className={classes.bridgeNumbers} onClick={() => editNumber(n)}>
                                <span className={classes.dataLabel}>COUNTRY:</span>
                                <span className={classes.dataPoint}>{n.country}</span>
                                <span className={classes.dataLabel}>PHONE:</span>
                                <span className={classes.dataPoint}>{n.number}</span>
                                <span className={classes.dataLabel}>PIN:</span>
                                <span className={classes.dataPoint}>{n.pin}</span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    }

    return (
        <Grid container spacing={3}>
            <Grid item sm={12} xs={12}>
                <div id="Settings">
                    {data.numbers && data.numbers.length > 0 && <div>{renderGroupSettings(data.numbers)}</div>}
                </div>
            </Grid>
            <Grid item sm={4} xs={12}>
                <TextField
                    autoComplete="type"
                    fullWidth
                    id="type"
                    name="type"
                    select
                    label="Type"
                    value={external.type}
                    onChange={handleChange}
                    margin="normal"
                >
                    {selectType.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item sm={8} xs={12}>
                <TextField
                    autoComplete="country"
                    fullWidth
                    id="country"
                    label="Country"
                    margin="normal"
                    name="country"
                    onChange={handleChange}
                    value={external.country}
                />
            </Grid>
            <Grid item sm={8} xs={12}>
                <TextField
                    autoComplete="number"
                    fullWidth
                    id="number"
                    label="Number"
                    margin="normal"
                    name="number"
                    onChange={handleChange}
                    value={external.number}
                />
            </Grid>
            <Grid item sm={4} xs={12}>
                <TextField
                    autoComplete="pin"
                    fullWidth
                    id="pin"
                    label="Pin"
                    margin="normal"
                    name="pin"
                    onChange={handleChange}
                    value={external.pin}
                />
            </Grid>

            {isEdit ? (
                <Grid item sm={12} xs={12}>
                    <Grid container spacing={3}>
                        <Grid item sm={9} xs={12}>
                            <Button
                                className={useStyles.submit}
                                color="secondary"
                                fullWidth
                                onClick={handleAddNumber}
                                variant="contained"
                            >
                                Save/Edit Number
                            </Button>
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <Button className={useStyles.submit} fullWidth onClick={deleteNumber} variant="contained">
                                Delete
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                <Grid item sm={12} xs={12}>
                    <Button className={useStyles.submit} fullWidth onClick={handleAddNumber} variant="contained">
                        Add New Number
                    </Button>
                </Grid>
            )}
        </Grid>
    );
}

export default ExternalBridgeSettings;
