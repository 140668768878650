import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { numberWithCommas } from "../../Utils";
import NoteDisplayModal from "./NoteDisplayModal";
// MUI - style imports
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export default function BasicTable(props) {
    const { rows } = props;
    // console.log(rows, " :: rows in expert list");
    const [openNoteModal, setOpenNoteModal] = React.useState(false);
    const [currentExpert, setCurrentExpert] = React.useState({});

    function handleNoteClick(event, currentExpertData) {
        setCurrentExpert(currentExpertData);
        setOpenNoteModal(true);
    }

    /** incoming data example ->
        expert_name,
        price_per_unit,
        unit_charge_amount,
        expert_hourly_to_client,
        client_charge_amount,
        client_duration,
        approved_by_name,
        approved_on: basicDateTimeStamp(approved_on),
        current_employment,
     */

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow sx={{ backgroundColor: "#444" }}>
                            <TableCell sx={{ color: "#fff" }}>Expert</TableCell>
                            <TableCell align="left" sx={{ color: "#fff" }}>
                                Employment
                            </TableCell>
                            <TableCell align="right" sx={{ color: "#fff" }}>
                                Hourly
                            </TableCell>
                            <TableCell align="right" sx={{ color: "#fff" }}>
                                Duration
                            </TableCell>
                            <TableCell align="right" sx={{ color: "#fff" }}>
                                PPU
                            </TableCell>
                            <TableCell align="right" sx={{ color: "#fff" }}>
                                Apprvd By
                            </TableCell>
                            <TableCell align="right" sx={{ color: "#fff" }}>
                                Apprvd On
                            </TableCell>
                            <TableCell align="right" sx={{ color: "#fff" }}>
                                Unit(s)
                            </TableCell>
                            <TableCell align="right" sx={{ color: "#fff" }}>
                                Charge Amt
                            </TableCell>
                            <TableCell align="right" sx={{ color: "#fff" }}>
                                Notes
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow
                                key={row.expert_id}
                                sx={{
                                    "&:last-child td, &:last-child th": { border: 0 },
                                    backgroundColor: `${index % 2 === 0 ? "#f5f5f5" : "#fff"}`,
                                }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.expert_name}
                                </TableCell>
                                <TableCell align="left">
                                    {row.current_employment !== "" ? row.current_employment : "Unavailable"}
                                </TableCell>
                                <TableCell align="right">{row.expert_hourly_to_client}</TableCell>
                                <TableCell align="right">{row.client_duration}</TableCell>
                                <TableCell align="right">{row.price_per_unit}</TableCell>
                                <TableCell align="right">{row.approved_by_name}</TableCell>
                                <TableCell align="right">{row.approved_on}</TableCell>
                                <TableCell align="right">{row.unit_charge_amount}</TableCell>
                                <TableCell align="right">
                                    <strong>${numberWithCommas(row.client_charge_amount)}</strong>
                                </TableCell>
                                <TableCell align="right">
                                    {row.invoicing_notes ? (
                                        <span
                                            onClick={() => handleNoteClick(event, row)}
                                            css={css`
                                                &:hover {
                                                    cursor: pointer;
                                                    color: #5c26ff;
                                                    font-weight: 700;
                                                }
                                            `}
                                        >
                                            {"Read"}
                                        </span>
                                    ) : (
                                        "N/A"
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {openNoteModal && (
                <NoteDisplayModal
                    openNoteModal={openNoteModal}
                    setOpenNoteModal={setOpenNoteModal}
                    currentExpert={currentExpert}
                />
            )}
        </>
    );
}
