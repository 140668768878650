import React, { useState } from "react";
import { Dialog, DialogTitle, IconButton, DialogContent, DialogActions, Button, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Checkbox } from "@mui/material";
import axios from "axios";

export default function AddRemoveTags(props) {
    const _state = props.state_manager;
    const open = props.open;
    let data = props.data;
    props = props.props;

    const [addNowBtn, setAddNowBtn] = useState(true);

    // Helpers

    function handleTagCheckbox(event) {
        const { target } = event;
        const { value } = target;
        if (event.target.checked) {
            _state.get.bulkTags.tags.push(value);
            setAddNowBtn(false);
        } else {
            const newArr = _state.get.bulkTags.tags.filter(function (item) {
                return item !== value;
            });
            _state.get.bulkTags.tags = newArr;
            _state.get.bulkTags.tags.length < 1 ? setAddNowBtn(true) : setAddNowBtn(false);
        }
    }

    function bulkRemoveTagsEvent() {
        const arr = [];
        for (const i in _state.get.selected) {
            if (_state.get.selected.length > 0) arr.push(_state.get.selected[i].id);
        }
        const dataObj = { ..._state.get.bulkTags, people: arr };
        _state.set.bulkTags(dataObj);
        const url = "/api/expert/removetags";
        console.log(dataObj);
        // return;
        axios
            .post(url, dataObj, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    console.log("REMOVE TAGS OBJECT AFTER POST?: ", result);
                    _state.switch.addRemoveTagsModal();
                    window.location.reload();
                } else {
                    console.log(`Good POST, but something went wrong: ${JSON.stringify(_state.get.bulkTags)}`);
                }
            })
            .catch((result) => {
                console.log("API not done yet, 404 Error:", result);
                _state.switch.addRemoveTagsModal();
                _state.flash.error("Unable to remove tags, try again or contact tech support. ");
            });
    }

    function bulkAddTagsEvent() {
        // _state.set.bulkTags.people = _state.get.selected;
        const arr = [];
        // eslint-disable-next-line guard-for-in
        for (const i in _state.get.selected) {
            arr.push(_state.get.selected[i].id);
        }

        const dataObj = { ..._state.get.bulkTags, people: arr };
        _state.set.bulkTags(dataObj);
        console.log(dataObj);
        // return;
        const url = "/api/expert/addtags";
        axios
            .post(url, dataObj, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    console.log("TAGS OBJECT AFTER POST?: ", result);
                    _state.switch.addRemoveTagsModal();
                    window.location.reload();
                } else {
                    console.log(`Good POST, but something went wrong: ${JSON.stringify(_state.get.bulkTags)}`);
                }
            })
            .catch((result) => {
                console.log("API not done yet, 404 Error:", result);
                _state.switch.addRemoveTagsModal();
                _state.flash.error("Unable to add tags, try again or contact tech support. ");
            });
    }

    if (!data) {
        return <div></div>;
    } else {
        data = parseProjectData(data);
        return (
            <Dialog
                open={open}
                onClose={_state.switch.addRemoveTagsModal}
                fullWidth
                maxWidth="sm"
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={_state.switch.addRemoveTagsModal}
                        sx={{ float: "right" }}
                        aria-label="close"
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                    Add/Remove Tags
                </DialogTitle>
                <DialogContent>
                    {data.tags.length < 1 && (
                        <Typography sx={{ color: "red", fontSize: ".75rem", marginTop: "0" }}>
                            This project has no tags associated with it.
                        </Typography>
                    )}
                    {data.tags.map(function (t, index) {
                        return (
                            <p key={t.value}>
                                <Checkbox name="tag" onChange={handleTagCheckbox} value={t.value} /> {t.label}
                            </p>
                        );
                    })}
                </DialogContent>
                <DialogActions>
                    <Button onClick={_state.switch.addRemoveTagsModal}>Cancel</Button>
                    <Button disabled={addNowBtn} onClick={bulkRemoveTagsEvent} color="primary">
                        Remove
                    </Button>
                    <Button disabled={addNowBtn} onClick={bulkAddTagsEvent} color="primary">
                        Add Now
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    // Helpers Debug

    // TODO - MOVE PARSEPROJECTDATA to backend
    function parseProjectData(pData) {
        if (pData.message && pData.data && pData.data.projectData) {
            // console.log("INside if");
            if (pData.data.projectData.length === 0) return;
            // console.log("FETCHED PROJECT DATA: ", pData.data.projectData);
            // console.log(pData.data.projectData.expertsAccepted, " :: pData.data.data.expertsAccepted");
            const expertCount =
                pData.data.projectData.expertCount.length > 0
                    ? pData.data.projectData.expertCount[0].experts_count
                    : "0";
            const expertsAccepted =
                pData.data.projectData.expertsAccepted.length > 0
                    ? pData.data.projectData.expertsAccepted[0].experts_accepted
                    : "0";
            const screenedCount =
                pData.data.projectData.screenedCount.length > 0
                    ? pData.data.projectData.screenedCount[0].num_experts_screened
                    : "0";
            const onHoldCount =
                pData.data.projectData.onHoldCount.length > 0
                    ? pData.data.projectData.onHoldCount[0].num_experts_on_hold
                    : "0";
            const expertsScheduled =
                pData.data.projectData.expertsScheduled.length > 0
                    ? pData.data.projectData.expertsScheduled[0].experts_scheduled
                    : "0";
            const expertsCompleted =
                pData.data.projectData.expertsCompleted.length > 0
                    ? pData.data.projectData.expertsCompleted[0].experts_completed
                    : "0";
            const sentToClient =
                pData.data.projectData.sentToClient.length > 0
                    ? pData.data.projectData.sentToClient[0].sent_to_client
                    : "0";
            const clientSelected =
                pData.data.projectData.clientSelected.length > 0
                    ? pData.data.projectData.clientSelected[0].client_selected
                    : "0";
            const sentToCompliance =
                pData.data.projectData.sentToCompliance.length > 0
                    ? pData.data.projectData.sentToCompliance[0].sent_to_compliance
                    : "0";
            const project = pData.data.projectData.projectData[0];
            const tags = pData.data.projectData.tags.map((tag) => {
                return { label: tag.name, value: tag.tag_id };
            });
            const specialty = pData.data.projectData.specialty.map((s) => {
                return { label: s.name, value: s.specialty_id };
            });
            const pcc = pData.data.projectData.clientContacts.filter((item) => item.isprimary == true);
            const acc = pData.data.projectData.clientContacts.filter((item) => item.isprimary !== true);
            const apms = pData.data.projectData.additionalProjectManagers;
            const { comments } = pData.data.projectData;

            const settings = pData.data.projectData.confBridgeSettings;
            const primary = settings.filter((item) => item.isprimary !== false);
            let selectedTypeID = "1";
            let selectedTwilio = "1";
            let extNumbers = [];
            if (primary.length > 0) {
                const primaryData = primary[0];
                selectedTypeID = primaryData.type_id.toString();
                if (primaryData.data && primaryData.data.value !== undefined) {
                    selectedTwilio = primaryData.data.value;
                }
                if (Array.isArray(primaryData.data) && primaryData.data.length > 0) {
                    extNumbers = primaryData.data;
                }
            }
            const allSurveys = pData.data.projectData.surveys;
            const surveys = allSurveys.filter((s) => s.published);
            const unpublishedSurveys = allSurveys.filter((s) => !s.published);
            const attachedExperts = pData.data.projectData.experts;
            // console.log("Project DATA: ", project);
            const custom_charge = project.custom_charge;
            const custom_hourly_rate = project.custom_hourly_rate;
            const minimum_bonus = project.minimum_bonus;
            const effort_index = project.effort_index;
            const premium_pricing_model_id = project.premium_pricing_model_id;
            const grace_period = project.grace_period;
            const duration_model_id = project.duration_model_id;
            return {
                ...data,
                id: project.id,
                isdeleted: project.isdeleted,
                title: project.title,
                description: project.description,
                email_copy: project.email_copy,
                project_not_received_by_email: project.project_not_received_by_email,
                client_id: project.client_id,
                client_name: project.client_name,
                primary_client_contact: pcc[0],
                additional_client_contacts: acc,
                expert_ids: project.expert_ids,
                primary_project_manager: `${project.project_manager_fname} ${project.project_manager_lname}`,
                additional_project_managers: apms,
                status: project.project_status,
                status_id: project.project_status_id,
                type: project.project_type,
                priority: project.project_priority,
                case_code: project.case_code,
                calls_planned: project.calls_planned,
                price_per_unit: project.price_per_unit,
                created_on: project.created_on,
                industry: project.industry,
                conference_bridge_setting: selectedTypeID,
                twilio_bridge_setting: selectedTwilio,
                external_bridge_numbers: extNumbers,
                notifications: [],
                specialty,
                tags,
                comments,
                responses: project.responses,
                scheduled_calls: project.scheduled_calls,
                completed_calls: project.completed_calls,
                experts: attachedExperts,
                unpublishedSurveys,
                surveys,
                expertCount,
                expertsAccepted,
                expertsScheduled,
                expertsCompleted,
                sentToClient,
                clientSelected,
                sentToCompliance,
                custom_charge,
                custom_hourly_rate,
                minimum_bonus,
                effort_index,
                premium_pricing_model_id,
                screenedCount,
                onHoldCount,
                grace_period,
                duration_model_id,
            };
        }
    }
}
