import React, { useState } from "react";
import { Grid, Box, Button, FormControlLabel, Checkbox, Drawer } from "@mui/material";
import { useTheme } from "@mui/system";
import axios from "axios";

export default function ProjectExpertFilterList(props) {
    const { open } = props;
    const _state = props.state_manager;
    props = props.props;
    const theme = useTheme();

    const expertFilters = [
        {
            value: 26,
            label: "Attached to Survey",
            type: "status",
            name: "attached_to_survey",
        },
        {
            value: 27,
            label: "Survey Sent",
            type: "status",
            name: "survey_sent",
        },
        {
            value: 28,
            label: "Survey Completed",
            type: "status",
            name: "survey_completed",
        },
        { value: 22, label: "Email Missing", type: "email", name: "email_missing" },
        { value: 23, label: "Rate Missing", type: "rate", name: "rate_missing" },
        {
            value: 24,
            label: "Terms NOT Sent",
            type: "status",
            name: "terms_not_sent",
        },
        {
            value: 25,
            label: "No Outreach Sent",
            type: "status",
            name: "outreach_not_sent",
        },
        {
            value: 21,
            label: "Prescreen Sent",
            type: "status",
            name: "prescreen_sent",
        },
        { value: 15, label: "Terms Sent", type: "status", name: "terms_sent" },
        { value: 16, label: "Terms Signed", type: "status", name: "terms_signed" },
        {
            value: 14,
            label: "Terms Not Signed",
            type: "status",
            name: "terms_not_signed",
        },
        { value: 3, label: "Invited", type: "status", name: "invited" },
        { value: 4, label: "Accepted", type: "status", name: "accepted" },
        { value: 5, label: "Declined", type: "status", name: "declined" },
        { value: 6, label: "Sent to Client", type: "status", name: "sent_to_client" },
        {
            value: 7,
            label: "Selected by Client",
            type: "status",
            name: "selected_by_client",
        },
        {
            value: 8,
            label: "Rejected by Client",
            type: "status",
            name: "rejected_by_client",
        },
        {
            value: 9,
            label: "Compliance Sent",
            type: "status",
            name: "compliance_sent",
        },
        {
            value: 10,
            label: "Compliance Approved",
            type: "status",
            name: "compliance_approved",
        },
        {
            value: 11,
            label: "Compliance Rejected",
            type: "status",
            name: "compliance_rejected",
        },
        {
            value: 12,
            label: "Schedule Needed",
            type: "status",
            name: "scheduling_needed",
        },
        { value: 13, label: "Scheduled", type: "status", name: "scheduled" },
        {
            value: 20,
            label: "Call Completed",
            type: "status",
            name: "call_completed",
        },
        {
            value: 17,
            label: "Payment Needed",
            type: "status",
            name: "payment_needed",
        },
        {
            value: 18,
            label: "Payment Requested",
            type: "status",
            name: "payment_requested",
        },
        { value: 19, label: "Paid", type: "status", name: "paid" },
    ];

    function handleFilterCheckbox(event) {
        const { target } = event;
        const { name, value } = target;
        // Create duplicate array
        let selectedFiltersArray = [..._state.get.statuses.selected];
        if (event.target.checked) {
            selectedFiltersArray.push(parseInt(value, 10));
        } else {
            selectedFiltersArray = selectedFiltersArray.filter((f) => f !== parseInt(value, 10));
        }
        // Set useState
        _state.set.menuFilters({
            ..._state.get.statuses,
            [name]: event.target.checked,
            selected: selectedFiltersArray,
        });

        console.log(_state.get.statuses);
    }

    function resetFilters() {
        // setFilterResults({
        //     ...filter,
        //     experts: data.experts,
        // });
        _state.set.menuFilters({
            ..._state.get.statuses,
            selected: [],
        });

        // Close left filter menu
        // setState({ ...state, left: false });
    }

    function submitFilters() {
        // _state.set.expertsData(false); // NOTE: this could cause a bug because we have no f'ing idea what it does!
        _state.set.openCircularLoader(true);
        const url = "/api/project/filterExperts"; // TODO: Change this to more efficent route, put filtering in client?
        const postObj = {
            id: parseInt(props.match.params.id, 10),
            statuses: _state.get.statuses.selected,
        };
        console.log("POST OBJECT BEFORE: ", postObj);

        _state.switch.filterModal();

        axios
            .post(url, postObj, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    console.log("POST OBJECT AFTER: ", `${JSON.stringify(postObj)}`);
                    const filteredResults = result.data.data;
                    console.log("POST RESULTS: ", filteredResults.experts);
                    _state.set.expertsData(filteredResults.experts);
                    _state.set.openCircularLoader(false);
                    // Set Filtered List of EXPERTS
                    // setData({ TODO: Does the parent data set need to be updated in paralell?
                    //     ...data,
                    //     experts: filteredResults.experts,
                    // });
                    // c

                    // handleBoolFiltersOnLoad(filteredResults.experts);
                    // Now update menu of experts in _state.get.statuses dialog
                    // setSelectedExperts(filteredResults.experts);
                    // Close left filter menu

                    // setState({ ...state, left: false });
                } else {
                    console.log(`Good POST, but something went wrong: ${JSON.stringify(postObj)}`);
                }
            })
            .catch((result) => {
                console.log("API not done yet, 404 Error:", result);
                _state.set.openCircularLoader(false);
                // setState({ ...state, left: false });
            });
    }

    // TODO - Have filter change ExpertTable state
    return (
        <Drawer open={open} onClose={_state.switch.filterModal}>
            <Box
                sx={{ marginTop: "90px", width: 400, padding: "10px" }}
                // onClick={toggleDrawer(side, false)}
                // onKeyDown={toggleDrawer(side, false)}
                // role="presentation"
            >
                <Grid container spacing={3}>
                    <Grid item sm={3} xs={12}>
                        <Button
                            size="small"
                            sx={{ color: "#fff", margin: theme.spacing(3, 0, 2) }}
                            fullWidth
                            onClick={resetFilters}
                            variant="contained"
                        >
                            Clear
                        </Button>
                    </Grid>
                    <Grid item sm={9} xs={12}>
                        <Button
                            size="small"
                            sx={{ color: "#fff", margin: theme.spacing(3, 0, 2) }}
                            color="primary"
                            fullWidth
                            defaultChecked={false}
                            onClick={submitFilters}
                            variant="contained"
                        >
                            Filter
                        </Button>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        {expertFilters.map((item) => (
                            <FormControlLabel
                                key={item.value}
                                sx={{ display: "block" }}
                                control={
                                    <Checkbox
                                        checked={_state.get.statuses.selected.includes(item.value) && true}
                                        name={item.name}
                                        onChange={handleFilterCheckbox}
                                        value={item.value}
                                    />
                                }
                                label={item.label}
                            />
                        ))}
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <Button
                            size="small"
                            sx={{ color: "#fff", margin: theme.spacing(3, 0, 2) }}
                            color="primary"
                            fullWidth
                            onClick={submitFilters}
                            variant="contained"
                        >
                            Filter
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Drawer>
    );
}
