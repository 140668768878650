// react imports
import React, { useState } from "react";
// MUI
// prop types
import PropTypes from "prop-types";

// general
import {
    Grid,
    ButtonGroup,
    Button,
    Typography,
    Radio,
    RadioGroup,
    FormControl,
    FormLabel,
    Checkbox,
    FormControlLabel,
    TextField,
    DialogActions,
    DialogContent,
    Box,
    Paper,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

// tabs
import { Tabs, Tab } from "@mui/material";

// Accordion
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

// npm imports
import { Typeahead, AsyncTypeahead } from "react-bootstrap-typeahead";
import axios from "axios";

// custom imports
import ExpertRateSlider from "./ExpertRateSlider";

// utils
import { capitalizeFirstLetter } from "../Utils";

// data
import CountryListData from "../data/countries.json";
import StateListData from "../data/us_states.json";

// modify data for selects
const countries = CountryListData.countries.map((country) => {
    return {
        value: country.code,
        label: country.name,
    };
});

const states = StateListData.states.map((state) => {
    return {
        value: state.abbreviation,
        label: state.name,
    };
});

// create an object that we can use to pipe into selects later on
const dataObj = {
    country: countries,
    state: states,
};

// styles
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    typography: {
        padding: theme.spacing(2),
    },
    andornot: {
        margin: theme.spacing(1),
    },
    remove: {
        float: "right",
    },
    hidden: {
        display: "none",
    },
    show: {
        display: "block",
    },
    selectContainer: {
        marginBottom: "1em",
    },
}));

// =============
// MUI FUNCTIONS
// =============

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function SearchBuilder(props) {
    // console.log(" :: searchbuilder rerender");
    const classes = useStyles();
    const [loading, isLoading] = useState(false);
    const [tags, setTags] = useState([]);

    // props destructuring
    const {
        search,
        setSearch,
        listSearch,
        setListSearch,
        handleClose,
        submitSearchQuery,
        submitListSearchQuery,
        resetSearch,
    } = props;
    const [componentLvlSearch, setComponentLvlSearch] = useState({ ...search });

    // tab state
    const [tabValue, setTabValue] = useState(0);

    // UTIL FUNCS
    // ASYNC TYPEAHEAD
    // this is the function that is called to search DB for tags
    const handleAsyncSearch = (query) => {
        loadTags(query);
    };

    // actual API call to grab the tag info
    // this is preferred over loading all tags up front because
    // all of the select components I tried break down with large lists
    const loadTags = async (query) => {
        const url = `/api/tags/search?searchtext=${query}`;
        await axios.get(url).then((response) => {
            const responseData = response.data.data;
            const tagList = responseData.map((item) => {
                return {
                    value: item.id,
                    label: item.name,
                };
            });
            setTags(tagList);
        });
    };

    // LOGIC FOR INPUTS
    const renderEmptyObject = (searchFieldType) => {
        switch (searchFieldType) {
            case "string":
                return "";
            case "object":
                return [];
        }
    };

    // this function adds or removes rows from the search build and is controlled by the search object
    // if remove is clicked, then the row is returned to default state
    // searchFieldType, searchFieldName, row.boolType, "add"
    const handleRow = (searchFieldType, searchFieldName, boolType) => {
        const inputs = componentLvlSearch.searchFields[searchFieldType][searchFieldName];
        const newInputs = inputs.map((input) => {
            if (input.boolType === boolType && input.active) {
                return { ...input, active: !input.active, value: renderEmptyObject(searchFieldType) };
            } else if (input.boolType === boolType && !input.active) {
                return { ...input, active: !input.active };
            } else {
                return input;
            }
        });

        const updatedSearchObj = {
            ...componentLvlSearch,
            searchFields: {
                ...componentLvlSearch.searchFields,
                [searchFieldType]: {
                    ...componentLvlSearch.searchFields[searchFieldType],
                    [searchFieldName]: newInputs,
                },
            },
        };

        setComponentLvlSearch(updatedSearchObj);
    };

    // this function handles the input when typing or creating an object
    // theres two types an object and a string, string is straightforward
    // the object just saves required info in an array of objects
    const handleInput = (event, searchFieldType, searchFieldName, boolType, arrayOfObjects) => {
        const inputs = componentLvlSearch.searchFields[searchFieldType][searchFieldName];

        const newInputs = inputs.map((input) => {
            if (input.boolType === boolType) {
                return { ...input, value: searchFieldType === "string" ? event.target.value : arrayOfObjects };
            } else {
                return input;
            }
        });

        const updatedSearchObj = {
            ...componentLvlSearch,
            searchFields: {
                ...componentLvlSearch.searchFields,
                [searchFieldType]: {
                    ...componentLvlSearch.searchFields[searchFieldType],
                    [searchFieldName]: newInputs,
                },
            },
        };

        setComponentLvlSearch(updatedSearchObj);
    };

    // RENDER INPUTS
    // this renders the and/or/not buttons and notice add is being set to handlerow here
    const renderAndOrNotBtns = (searchFieldType, searchFieldName, inputs) => {
        // console.log(searchFieldType, searchFieldName, inputs, " :: searchFieldType, searchFieldName, inputs");
        return (
            <ButtonGroup variant="contained">
                {inputs.map((row, index) => {
                    return (
                        <Button
                            key={index}
                            size="small"
                            color="secondary"
                            onClick={() => handleRow(searchFieldType, searchFieldName, row.boolType)}
                        >
                            {`${row.boolType.toUpperCase()}`}
                        </Button>
                    );
                })}
            </ButtonGroup>
        );
    };

    // OBJECT INPUT RENDERING
    // this controls all the inputs that require objects, there's two different
    // typeaheads, a sync one and async one.  i didn't use react-select just so we didn't have
    // to import both packages.  they basically do the same thing it seems and this one is better with larger data sets
    const renderObjectInputs = (searchFieldType, searchFieldName, inputs) => {
        return (
            <>
                {inputs.map((row, index) => {
                    // console.log(row, " :: object row");
                    return (
                        <div
                            key={index}
                            className={`${row.active ? classes.show : classes.hidden} ${classes.selectContainer}`}
                        >
                            <Button
                                size="small"
                                className={classes.remove}
                                onClick={() => handleRow(searchFieldType, searchFieldName, row.boolType)}
                            >
                                Remove
                            </Button>
                            <div className={classes.heading}>{`${row.boolType.toUpperCase()} ${searchFieldName}:`}</div>
                            {searchFieldName === "tags" ? (
                                <AsyncTypeahead
                                    multiple
                                    id="async Typeahead"
                                    isLoading={loading}
                                    labelKey={(option) => `${option.label}`}
                                    minLength={3}
                                    onChange={(selected, event) =>
                                        handleInput(event, searchFieldType, searchFieldName, row.boolType, selected)
                                    }
                                    onSearch={handleAsyncSearch}
                                    options={tags}
                                    placeholder="Start typing..."
                                    selected={row.value}
                                />
                            ) : (
                                <Typeahead
                                    multiple
                                    id="sync Typeahead"
                                    onChange={(selected, event) =>
                                        handleInput(event, searchFieldType, searchFieldName, row.boolType, selected)
                                    }
                                    options={dataObj[searchFieldName]}
                                    selected={row.value}
                                />
                            )}
                        </div>
                    );
                })}
            </>
        );
    };

    const renderObjectInputTypes = (searchFieldType, searchFieldName, inputs, index) => {
        return (
            <Paper sx={{ padding: "1.5rem", marginBottom: "1.5rem" }}>
                <Box display="flex" flexDirection="column" justifyContent="center">
                    <Typography sx={{ marginBottom: ".75rem" }}>{`Search By ${capitalizeFirstLetter(
                        searchFieldName
                    )}`}</Typography>
                    <Grid container>
                        <Grid item sm={12} xs={12} sx={{ marginBottom: "2rem" }}>
                            {renderAndOrNotBtns(searchFieldType, searchFieldName, inputs)}
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            {renderObjectInputs(searchFieldType, searchFieldName, inputs)}
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        );
    };

    // STRING INPUT RENDERING
    // render all the string inputs, notice the handlerow on the button takes "remove"
    const renderStringInputs = (searchFieldType, searchFieldName, inputs) => {
        return (
            <>
                {inputs.map((row, index) => {
                    return (
                        <div key={index} className={row.active ? classes.show : classes.hidden}>
                            <Button
                                size="small"
                                className={classes.remove}
                                onClick={() => handleRow(searchFieldType, searchFieldName, row.boolType)}
                            >
                                Remove
                            </Button>
                            <TextField
                                inputProps={{ maxLength: 1500 }}
                                fullWidth
                                label={`${row.boolType.toUpperCase()} ${searchFieldName}`}
                                margin="normal"
                                onChange={(event) => handleInput(event, searchFieldType, searchFieldName, row.boolType)}
                                value={row.value}
                                size="small"
                            />
                        </div>
                    );
                })}
            </>
        );
    };

    const renderStringInputTypes = (searchFieldType, searchFieldName, inputs, index) => {
        return (
            <Paper sx={{ padding: "1.5rem", marginBottom: "1.5rem" }}>
                <Box display="flex" flexDirection="column" justifyContent="center">
                    <Typography sx={{ marginBottom: ".75rem" }}>{`Search By ${capitalizeFirstLetter(
                        searchFieldName
                    )}`}</Typography>
                    <Grid container>
                        <Grid item sm={12} xs={12}>
                            {renderAndOrNotBtns(searchFieldType, searchFieldName, inputs)}
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            {renderStringInputs(searchFieldType, searchFieldName, inputs)}
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        );
    };

    // ALL INPUT RENDERING
    const splitAndRenderDiffInputTypes = () => {
        const { searchFields } = componentLvlSearch;
        const jsxArray = [];
        Object.entries(searchFields).forEach(([searchFieldType, searchFieldName], mainIndex) => {
            if (searchFieldType === "string") {
                // generate all string inputs
                Object.entries(searchFieldName).forEach(([name, inputs], secondaryIndex) => {
                    const newIndex = `${searchFieldName}_${mainIndex}_${secondaryIndex}`;
                    jsxArray.push(renderStringInputTypes(searchFieldType, name, inputs, newIndex));
                });
            } else if (searchFieldType === "object") {
                // generate all object inputs
                Object.entries(searchFieldName).forEach(([name, inputs], secondaryIndex) => {
                    const newIndex = `${searchFieldName}_${mainIndex}_${secondaryIndex}`;
                    jsxArray.push(renderObjectInputTypes(searchFieldType, name, inputs, newIndex));
                });
            }
        });
        return jsxArray;
    };

    // OPTION LOGIC
    // this handles the checkbox for checkbox based options soon to be others
    const handleCheckbox = (value, name) => {
        const { options } = componentLvlSearch;

        const updatedOptions = options.checkbox.map((input) => {
            if (input.name === name) {
                return { ...input, value: !value };
            } else {
                return input;
            }
        });

        const updatedSearchObj = {
            ...componentLvlSearch,
            options: {
                ...componentLvlSearch.options,
                checkbox: updatedOptions,
            },
        };

        // console.log(updatedSearchObj, " :: updatedSearchObj");

        setComponentLvlSearch(updatedSearchObj);
    };

    // this handles radio buttons for radio based options
    const handleRadioBtns = (event, name) => {
        const { options } = componentLvlSearch;
        // console.log(" :: handleRadioBtns being called");
        // console.log(event.target.value, " :: event.target.value");

        const updatedRadioBtnValues = options.radio.map((input) => {
            if (input.name === name) {
                return { ...input, currentValue: event.target.value };
            } else {
                return input;
            }
        });

        const updatedSearchObj = {
            ...componentLvlSearch,
            options: {
                ...componentLvlSearch.options,
                radio: updatedRadioBtnValues,
            },
        };

        setComponentLvlSearch(updatedSearchObj);
    };

    // RENDER OPTIONS
    // CHECKBOXES
    // this renders all the checkboxes for the options
    const renderCheckboxes = (inputs) => {
        // console.log(inputs, " :: inputs");
        const { projectId } = componentLvlSearch.metaData;

        return (
            <>
                {inputs.map((input, index) => {
                    const { label, name, value } = input;
                    return (
                        <Grid item sm={12} xs={12} key={index}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={value}
                                        name={name}
                                        onChange={() => handleCheckbox(value, name)}
                                        value={value}
                                        // only enables checkbox for this one checkbox name and if there's no pid
                                        disabled={name === "remove_proj_experts" && projectId === null}
                                    />
                                }
                                label={label}
                            />
                        </Grid>
                    );
                })}
            </>
        );
    };

    // RADIO BTN RENDERING
    // this renders the individual radio button options
    const renderRadioBtns = (items) => {
        return (
            <>
                {items.map((item, index) => {
                    const { label, value } = item;
                    return <FormControlLabel value={`${value}`} control={<Radio />} label={label} key={index} />;
                })}
            </>
        );
    };

    // this renders all the radio groups
    const renderRadioGroup = (inputs) => {
        return (
            <>
                {inputs.map((input, index) => {
                    // console.log(input, " :: input");
                    const { name, label, currentValue, items } = input;
                    return (
                        <Grid item sm={12} xs={12} key={index}>
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormLabel component="legend">{`${label}:`}</FormLabel>
                                <RadioGroup
                                    name={name}
                                    value={currentValue}
                                    onChange={(event) => handleRadioBtns(event, name)}
                                >
                                    {renderRadioBtns(items)}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    );
                })}
            </>
        );
    };

    // highest level options renderer
    const renderOptionsLayout = () => {
        const { options } = componentLvlSearch;

        return (
            <Paper sx={{ padding: "1.5rem", marginBottom: "1.5rem" }}>
                <Typography>Options</Typography>
                <Grid container>
                    {renderCheckboxes(options.checkbox)}
                    {renderRadioGroup(options.radio)}
                </Grid>
            </Paper>
        );
    };

    const renderExpertRateSlider = () => {
        const { filters } = componentLvlSearch;

        return (
            <Grid item sm={12} xs={12}>
                <ExpertRateSlider filters={filters} search={componentLvlSearch} setSearch={setComponentLvlSearch} />
            </Grid>
        );
    };

    const renderFiltersLayout = () => {
        return (
            <Paper sx={{ padding: "1.5rem" }}>
                <Typography>Filters</Typography>
                <Grid container spacing={3}>
                    {renderExpertRateSlider()}
                </Grid>
            </Paper>
        );
    };

    // Tabs
    const handleTabChange = (event, newValue) => {
        // console.log(newValue, " :: newValue");

        if (newValue === 0) {
            setListSearch("");
        } else if (newValue === 1) {
            setSearch(search);
        }

        setTabValue(newValue);
    };

    // BUTTONS
    // button logics
    const handleComponentClose = () => {
        setSearch(search);
        setListSearch("");
        handleClose();
    };

    const componentSubmitSearchQuery = () => {
        setSearch(componentLvlSearch);
        submitSearchQuery(componentLvlSearch);
    };

    // Text Area
    const handleTextArea = (event) => {
        setListSearch(event.target.value);
    };

    // render buttons
    const renderAdvSearchButtons = () => {
        return (
            <DialogActions>
                <Button onClick={handleComponentClose}>Cancel</Button>
                <Button onClick={componentSubmitSearchQuery} color="primary" autoFocus>
                    Search
                </Button>
            </DialogActions>
        );
    };

    const renderListSearchButtons = () => {
        return (
            <DialogActions>
                <Button onClick={handleComponentClose}>Cancel</Button>
                <Button onClick={submitListSearchQuery} color="primary" autoFocus>
                    Search
                </Button>
            </DialogActions>
        );
    };

    // FINAL RENDER
    const renderFinalComponent = () => {
        return (
            <>
                <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                            <Tab label="Advanced Search" {...a11yProps(0)} />
                            <Tab label="Long List Search" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={tabValue} index={0}>
                        <DialogContent>
                            <Grid container spacing={3}>
                                <Grid item sm={8} xs={12}>
                                    {splitAndRenderDiffInputTypes()}
                                </Grid>
                                <Grid item sm={4} xs={12}>
                                    {renderOptionsLayout()}
                                    {renderFiltersLayout()}
                                </Grid>
                            </Grid>
                        </DialogContent>
                        {renderAdvSearchButtons()}
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <DialogContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography align="left" sx={{ marginBottom: "1.5rem", fontSize: ".825rem" }}>
                                        <strong>Note:</strong> In order for the search to work properly, prefixes and
                                        suffixes should be removed and the input should be <strong>first name</strong>
                                        {`<space>`}
                                        <strong>last name</strong> separated by commas
                                    </Typography>
                                    <TextField
                                        sx={{ width: "100%" }}
                                        label="Enter Text"
                                        multiline
                                        rows={15}
                                        value={listSearch}
                                        onChange={handleTextArea}
                                    />
                                </Grid>
                                {/* <Grid item sm={4} xs={12}>
                                    Testing 2
                                </Grid> */}
                            </Grid>
                        </DialogContent>
                        {renderListSearchButtons()}
                    </TabPanel>
                </Box>
            </>
        );
    };

    return <>{renderFinalComponent()}</>;
}
export default SearchBuilder;
