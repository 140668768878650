import React, { useState } from "react";
import TextField from "@mui/material/TextField";

function EmailField(props) {
    const [validEmail, setValidEmail] = useState(false);
    const { value, changeEvent } = props;
    function isValidEmail(email) {
        const regex =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(String(email).toLowerCase());
    }

    function validateEmail(event) {
        const { target } = event;
        const { value } = target;
        if (!value || !isValidEmail(value)) {
            setValidEmail(true);
        } else {
            setValidEmail(false);
        }
    }
    return (
        <div>
            <TextField
                autoComplete="email"
                error={value === "" || validEmail}
                fullWidth
                helperText={validEmail ? "Please enter a valid Email" : " "}
                id="email"
                label="Primary Email"
                margin="normal"
                name="email"
                onBlur={validateEmail}
                onChange={changeEvent}
                // required
                value={value}
                disabled={props.isReadOnly}
            />
        </div>
    );
}

export default EmailField;
