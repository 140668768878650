// react
import React, { useState, useEffect, useCallback } from "react";

// custom components
import ExpertPreview from "../ExpertPreview";

// utils / data
import { getCountries, MONTHS } from "./utils";

// npm imports
import axios from "axios";

//  utils
import { generateOptedOutData } from "../../Utils";

// MUI - general
import { AppBar, Toolbar, IconButton, Typography, Button, Grid, Slide } from "@mui/material";

// MUI - dialog
import { Dialog, DialogContent } from "@mui/material";

// MUI - icons
import { Close as CloseIcon } from "@mui/icons-material";

// MUI - styles
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

// MUI - helper functions
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// styles
const appBar = css`
    position: relative;
`;

const title = css`
    font-size: 0.75rem;
    color: #999;
    text-align: left;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 10px;
`;

// data
const countries = getCountries();

const ExpertPreviewWrapper = (props) => {
    // console.log(props, " :: props");

    // =====
    // PROPS
    // =====

    const { expertPreviewData, setExpertPreviewData, DEFAULT_EXPERT_PREVIEW, navigateToExpert, reloadPageTagSearch } =
        props;

    // DESTRUCTURE
    const { auth, expertId } = expertPreviewData;

    // =====
    // STATE
    // =====

    // open modal
    const [loading, setLoading] = useState(true);
    // expert data
    const [expertData, setExpertData] = useState({});

    // ============
    // HANDLE FUNCS
    // ============

    function handleClose() {
        setExpertPreviewData(DEFAULT_EXPERT_PREVIEW);
    }

    // ===========
    // FETCH FUNCS
    // ===========

    const fetchExpertById = async (expertId) => {
        await axios
            .get(`/api/expert/findbyid?id=${expertId}`)
            .then((expert) => {
                if (expert.status === 200 && expert.data && expert.data.data) {
                    if (expert.data.data[0].length === 0) return;

                    // console.log(expert.data.data, " :: expert.data.data");

                    const person = expert.data.data[0][0];
                    const emails = expert.data.data[1];
                    const phoneNumbers = expert.data.data[2];
                    const address = expert.data.data[3][0];

                    const tags = expert.data.data[4].map((tag) => {
                        return { label: tag.name, value: tag.id };
                    });

                    const specialty = expert.data.data[5].map((s) => {
                        return { label: s.name, value: s.id };
                    });

                    const defaultDialCode = countries.find((o) => o.value === address?.country_code);
                    const disclosure_questions = expert.data.data[10].length > 0 ? expert.data.data[10].reverse() : [];
                    const onboarding = expert.data.data[11];
                    const dialCode = defaultDialCode ? defaultDialCode.dial_code : "";
                    const phone = [];

                    if (phoneNumbers.length > 0) {
                        const number = phoneNumbers.filter((p) => p.isprimary).map((p) => p.number)[0];
                        if (number && number.length > 0 && number.indexOf("{") === -1) {
                            phone.push(number);
                        }
                    }

                    const current_employment = expert.data.data[6].filter((item) => item.present === true);
                    const past_employment = expert.data.data[6].filter((item) => item.present !== true);

                    let mostRecentJob = "";

                    if (current_employment.length > 0) {
                        mostRecentJob = `${current_employment[0].position}, ${current_employment[0].company}`;
                    }

                    current_employment.sort(function (b, a) {
                        return a.from_year - b.from_year || MONTHS[a.from_month] - MONTHS[b.from_month];
                    });

                    past_employment.sort(function (b, a) {
                        return a.to_year - b.to_year || MONTHS[a.to_month] - MONTHS[b.to_month];
                    });

                    // optout data
                    const optoutInfo = generateOptedOutData(expert.data.data[13]);
                    console.log(optoutInfo, " :: optoutInfo");

                    setExpertData({
                        id: expertId,
                        prefix: person.prefix,
                        fname: person.fname,
                        mname: person.mname,
                        lname: person.lname,
                        headline: person.headline ? person.headline : mostRecentJob,
                        biography: person.biography,
                        suffix: person.suffix,
                        country: address ? { value: address.country_code, label: address.country } : "",
                        state: address ? { value: address.abbreviation, label: address.state } : "",
                        city: address ? address.city : "",
                        address: address ? address.address1 : "",
                        zipcode: address ? address.postal_code : "",
                        us: address ? address.country_id === 180 : false,
                        country_code:
                            phoneNumbers.length > 0
                                ? phoneNumbers.filter((phone) => phone.isprimary).map((phone) => phone.code)[0]
                                : dialCode,
                        phone: phone.length > 0 ? phone.pop() : "",
                        extension: person.extension,
                        alternate_phones: phoneNumbers.filter((phone) => !phone.isprimary),
                        alternate_emails: emails.filter((email) => !email.isprimary),
                        email: emails.filter((email) => email.isprimary).map((email) => email.email)[0],
                        linkedin_url: person.linkedin_url,
                        employment: expert.data.data[6],
                        current_employment,
                        past_employment,
                        education: expert.data.data[7],
                        industry: "Healthcare",
                        specialty,
                        terms_signed: person.has_signed_terms,
                        terms_version: "",
                        terms_date: "",
                        terms_origin: "",
                        tutorial_completed: person.has_completed_tutorial,
                        tutorial_date: "",
                        disclosures: person.disclosures,
                        disclosures_form_review_required: person.disclosures_form_review_required,
                        status: person.expert_status,
                        dnc: person.dnc,
                        created_by: person.created_by,
                        created_by_name: person.created_by_name,
                        created_on: person.created_on,
                        updated_on: "",
                        tags,
                        minimum_hourly: person.minimum_hourly,
                        consultation_rate: person.consultation_rate,
                        consultation_rate_currency: person.consultation_rate_currency,
                        group_meeting_rate: person.group_meeting_rate,
                        group_meeting_rate_currency: person.group_meeting_rate_currency,
                        point_person_id: person.point_person_id,
                        background_check: person.background_check,
                        comments: expert.data.data[8],
                        person_id: person.person_id,
                        notes: person.notes ? person.notes : "",
                        blinded_bio: person.blinded_bio,
                        opted_out: person.opted_out,
                        disclosure_questions,
                        onboarding,
                        opted_out_info: optoutInfo,
                    });
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.log(error, " :: error");
            });
    };

    // =========
    // PAGE LOAD
    // =========

    useEffect(() => {
        fetchExpertById(expertId);
    }, []);

    if (loading) {
        return <h1>Loading...</h1>;
    } else {
        return (
            <>
                <Dialog fullScreen open={true} onClose={handleClose} TransitionComponent={Transition}>
                    <AppBar css={appBar}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                                size="large"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" css={title}>
                                <Button color="inherit" onClick={() => navigateToExpert(expertId)}>
                                    Full Profile
                                </Button>
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item sm={12} xs={12}>
                                <ExpertPreview
                                    data={expertData}
                                    user={auth.data}
                                    page="Schedule"
                                    tagSearchEvent={reloadPageTagSearch}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </>
        );
    }
};

export default ExpertPreviewWrapper;
