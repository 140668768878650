// country data
import CountryListData from "../../data/countries.json";

// format data
const countryCodes = CountryListData.countries;

// get list of countries
export function getCountries() {
    const countries = countryCodes.map((item) => {
        return {
            value: item.code,
            label: item.name,
            dial_code: item.dial_code,
        };
    });

    return countries;
}

// list of months
export const MONTHS = {
    JANUARY: 0,
    FEBRUARY: 1,
    MARCH: 2,
    APRIL: 3,
    MAY: 4,
    JUNE: 5,
    JULY: 6,
    AUGUST: 7,
    SEPTEMBER: 8,
    OCTOBER: 9,
    NOVEMBER: 10,
    DECEMBER: 11,
};
