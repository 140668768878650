import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import { Link } from "@mui/material";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import DateRangeIcon from "@mui/icons-material/DateRange";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography } from "@mui/material";

function StatusCheckboxes(props) {
    console.log(props, " :: props");

    // console.log(props);
    return (
        <div>
            <Typography sx={{ fontSize: "1.75rem", fontWeight: "bold" }}>{props.expert.name}</Typography>
            <Box component={"table"} sx={{ width: "100%" }} cellPadding="5" cellSpacing="5" border="0">
                <thead>
                    <tr>
                        <Box
                            component={"th"}
                            scope="col"
                            sx={{
                                fontSize: ".75rem",
                                textAlign: "left",
                                fontWeight: "normal",
                                textTransform: "uppercase",
                                color: "#8aa694",
                            }}
                        >
                            Statuses
                        </Box>
                        <th scope="col">&nbsp;</th>
                        <th scope="col">&nbsp;</th>
                        <th scope="col">&nbsp;</th>
                        <th scope="col">&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {/* <tr>
                        <td>
                            <Box component={"strong"} sx={{fontSize: ".75rem",
        textAlign: "left",
        fontWeight: "normal",
        textTransform: "uppercase",
        color: "#8aa694",}}>1 - Pre-Screen</Box>
                        </td>
                        <td>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id={`${15}`}
                                        inputProps={{ "data-status-type": "prescreen" }}
                                        checked={props.expert.prescreen_sent}
                                        name="prescreen_sent"
                                        onChange={props.handleCheckbox}
                                        value={props.expert.prescreen_sent}
                                    />
                                }
                                label="Sent"
                            />
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                    </tr> */}

                    <tr>
                        <td>
                            <Box
                                component={"strong"}
                                sx={{
                                    fontSize: ".75rem",
                                    textAlign: "left",
                                    fontWeight: "normal",
                                    textTransform: "uppercase",
                                    color: "#8aa694",
                                }}
                            >
                                2 - Terms &amp; Conditions:
                            </Box>
                        </td>
                        <td>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={props.sent.first}
                                        id={`${15}`}
                                        inputProps={{ "data-status-type": "terms" }}
                                        checked={props.expert.tnc_sent}
                                        name="tnc_sent"
                                        onChange={props.handleCheckbox}
                                        value={props.expert.tnc_sent}
                                    />
                                }
                                label="Sent"
                            />
                        </td>
                        <td>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id={`${16}`}
                                        inputProps={{ "data-status-type": "terms" }}
                                        checked={props.expert.tnc_signed}
                                        name="tnc_signed"
                                        onChange={props.handleCheckbox}
                                        value={props.expert.tnc_signed}
                                    />
                                }
                                label="Signed"
                            />
                        </td>
                        <td>
                            {props.sent.first && !props.expert.tnc_signed && (
                                <div>
                                    <Box
                                        component={"em"}
                                        sx={{
                                            fontSize: ".5rem",
                                            color: "#555",
                                            textAlign: "left",
                                            marginBottom: "5px",
                                        }}
                                    >
                                        Last sent: {props.sent.date}
                                    </Box>
                                    <br />
                                    <Link
                                        sx={{ color: "#3c8864", textDecoration: "none", lineHeight: "150%" }}
                                        href={`/project/emails/${props.project.data.projectData.projectData[0].id}`}
                                    >
                                        Check Status
                                    </Link>
                                </div>
                            )}
                        </td>
                        <td>&nbsp;</td>
                    </tr>

                    <tr>
                        <td>
                            <Box
                                component={"strong"}
                                sx={{
                                    fontSize: ".75rem",
                                    textAlign: "left",
                                    fontWeight: "normal",
                                    textTransform: "uppercase",
                                    color: "#8aa694",
                                }}
                            >
                                3 - Expert Response:
                            </Box>
                        </td>
                        <td>
                            <FormControlLabel
                                control={
                                    <Radio
                                        checked={props.expert.invited}
                                        onClick={props.handleRadioBtns}
                                        value="invited"
                                        name="expertResponse"
                                    />
                                }
                                label="Invited"
                            />
                        </td>
                        <td>
                            <FormControlLabel
                                control={
                                    <Radio
                                        checked={props.expert.accepted}
                                        onClick={props.handleRadioBtns}
                                        value="accepted"
                                        name="expertResponse"
                                    />
                                }
                                label="Interested"
                            />
                        </td>
                        <td>
                            <FormControlLabel
                                control={
                                    <Radio
                                        checked={props.expert.declined}
                                        onClick={props.handleRadioBtns}
                                        value="declined"
                                        name="expertResponse"
                                    />
                                }
                                label="Declined"
                            />
                        </td>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            <Box
                                component={"strong"}
                                sx={{
                                    fontSize: ".75rem",
                                    textAlign: "left",
                                    fontWeight: "normal",
                                    textTransform: "uppercase",
                                    color: "#8aa694",
                                }}
                            >
                                4 - Screened:
                            </Box>
                        </td>
                        <td>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id={`${28}`}
                                        inputProps={{ "data-status-type": "screened" }}
                                        checked={props.expert.expert_screened}
                                        name="expert_screened"
                                        onChange={props.handleCheckbox}
                                        value={props.expert.expert_screened}
                                    />
                                }
                                label="Screened"
                            />
                        </td>
                        <td>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id={`${31}`}
                                        inputProps={{ "data-status-type": "screened" }}
                                        checked={props.expert.expert_on_hold}
                                        name="expert_on_hold"
                                        onChange={props.handleCheckbox}
                                        value={props.expert.expert_on_hold}
                                    />
                                }
                                label="On Hold"
                            />
                        </td>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            <Box
                                component={"strong"}
                                sx={{
                                    fontSize: ".75rem",
                                    textAlign: "left",
                                    fontWeight: "normal",
                                    textTransform: "uppercase",
                                    color: "#8aa694",
                                }}
                            >
                                5 - Client Response:
                            </Box>
                        </td>
                        <td>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id={`${6}`}
                                        inputProps={{ "data-status-type": "clientResponse" }}
                                        checked={props.expert.sent_to_client}
                                        name="sent_to_client"
                                        onChange={props.handleCheckbox}
                                        value={props.expert.sent_to_client}
                                    />
                                }
                                label="Sent"
                            />
                        </td>
                        <td>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id={`${7}`}
                                        inputProps={{ "data-status-type": "clientResponse" }}
                                        checked={props.expert.selected_by_client}
                                        name="selected_by_client"
                                        onChange={props.handleCheckbox}
                                        value={props.expert.selected_by_client}
                                    />
                                }
                                label="Selected"
                            />
                        </td>
                        <td>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id={`${8}`}
                                        inputProps={{ "data-status-type": "clientResponse" }}
                                        checked={props.expert.rejected_by_client}
                                        name="rejected_by_client"
                                        onChange={props.handleCheckbox}
                                        value={props.expert.rejected_by_client}
                                    />
                                }
                                label="Rejected"
                            />
                        </td>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            <Box
                                component={"strong"}
                                sx={{
                                    fontSize: ".75rem",
                                    textAlign: "left",
                                    fontWeight: "normal",
                                    textTransform: "uppercase",
                                    color: "#8aa694",
                                }}
                            >
                                6 - Compliance:
                            </Box>
                        </td>
                        <td>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id={`${9}`}
                                        inputProps={{
                                            "data-status-type": "complianceResponse",
                                        }}
                                        checked={props.expert.sent_to_compliance}
                                        name="sent_to_compliance"
                                        onChange={props.handleCheckbox}
                                        value={props.expert.sent_to_compliance}
                                    />
                                }
                                label="Sent"
                            />
                        </td>
                        <td>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id={`${10}`}
                                        inputProps={{
                                            "data-status-type": "complianceResponse",
                                        }}
                                        checked={props.expert.compliance_approved}
                                        name="compliance_approved"
                                        onChange={props.handleCheckbox}
                                        value={props.expert.compliance_approved}
                                    />
                                }
                                label="Approved"
                            />
                        </td>
                        <td>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id={`${11}`}
                                        inputProps={{
                                            "data-status-type": "complianceResponse",
                                        }}
                                        checked={props.expert.compliance_rejected}
                                        name="compliance_rejected"
                                        onChange={props.handleCheckbox}
                                        value={props.expert.compliance_rejected}
                                    />
                                }
                                label="Rejected"
                            />
                        </td>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            <Box
                                component={"strong"}
                                sx={{
                                    fontSize: ".75rem",
                                    textAlign: "left",
                                    fontWeight: "normal",
                                    textTransform: "uppercase",
                                    color: "#8aa694",
                                }}
                            >
                                7 - Scheduling:
                            </Box>
                        </td>
                        <td>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id={`${12}`}
                                        inputProps={{
                                            "data-status-type": "schedulingResponse",
                                        }}
                                        checked={props.expert.needs_scheduling}
                                        name="needs_scheduling"
                                        onChange={props.handleCheckbox}
                                        value={props.expert.needs_scheduling}
                                    />
                                }
                                label="Needed"
                            />
                        </td>
                        <td>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id={`${13}`}
                                        inputProps={{
                                            "data-status-type": "schedulingResponse",
                                        }}
                                        checked={props.expert.scheduled}
                                        name="scheduled"
                                        // onChange={props.handleCheckbox}
                                        value={props.expert.scheduled}
                                    />
                                }
                                label="Scheduled"
                            />
                        </td>
                        <td>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id={`${20}`}
                                        inputProps={{
                                            "data-status-type": "schedulingResponse",
                                        }}
                                        checked={props.expert.call_complete}
                                        name="call_complete"
                                        onChange={props.handleCheckbox}
                                        value={props.expert.call_complete}
                                    />
                                }
                                label="Complete"
                            />
                        </td>
                        <td>
                            {!props.expert.call_complete && (
                                <Link
                                    href={`/project/schedule/${props.project.data.projectData.projectData[0].id}?eid=${props.expert.id}`}
                                    sx={{ color: "#3c8864", textDecoration: "none", lineHeight: "150%" }}
                                >
                                    <DateRangeIcon sx={{ color: "inherit", fontSize: "inherit" }} /> Schedule{" "}
                                    {props.expert.name}
                                </Link>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Box
                                component={"strong"}
                                sx={{
                                    fontSize: ".75rem",
                                    textAlign: "left",
                                    fontWeight: "normal",
                                    textTransform: "uppercase",
                                    color: "#8aa694",
                                }}
                            >
                                8 - Payment:
                            </Box>
                        </td>
                        <td>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id={`${17}`}
                                        inputProps={{ "data-status-type": "payment" }}
                                        checked={props.expert.needs_payment}
                                        name="needs_payment"
                                        onChange={props.handleCheckbox}
                                        value={props.expert.needs_payment}
                                    />
                                }
                                label="Needed"
                            />
                        </td>
                        <td>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id={`${18}`}
                                        inputProps={{ "data-status-type": "payment" }}
                                        checked={props.expert.payment_requested}
                                        name="payment_requested"
                                        onChange={props.handleCheckbox}
                                        value={props.expert.payment_requested}
                                    />
                                }
                                label="Requested"
                            />
                        </td>
                        <td>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id={`${19}`}
                                        inputProps={{ "data-status-type": "payment" }}
                                        checked={props.expert.paid}
                                        name="paid"
                                        onChange={props.handleCheckbox}
                                        value={props.expert.paid}
                                    />
                                }
                                label="Paid"
                            />
                        </td>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            <Box
                                component={"strong"}
                                sx={{
                                    fontSize: ".75rem",
                                    textAlign: "left",
                                    fontWeight: "normal",
                                    textTransform: "uppercase",
                                    color: "#8aa694",
                                }}
                            >
                                Call Duration:
                            </Box>
                        </td>
                        <td>
                            <TextField
                                autoComplete="call_duration"
                                fullWidth
                                // disabled={!expert.scheduled}
                                id="call_duration"
                                label="Minutes"
                                margin="normal"
                                name="call_duration"
                                onChange={props.handleChange}
                                value={props.expert.call_duration}
                            />
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                    </tr>
                </tbody>
            </Box>
        </div>
    );
}
export default StatusCheckboxes;
