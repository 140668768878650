import React from "react";
import { Helmet } from "react-helmet";

function PageTitle(props) {
    const defaultTitle = "FirstThought CRM";
    const { title } = props;
    return (
        <Helmet>
            <title>{title || defaultTitle}</title>
        </Helmet>
    );
}

export default PageTitle;
