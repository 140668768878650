import React, { useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import axios from "axios";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    textInput: {
        width: "100%",
        marginBottom: "1em",
    },
    btnStyles: {
        marginLeft: "1em",
    },
    profileBox: {
        backgroundColor: "#f5f5f5",
        borderBottom: "4px solid #eaeaea",
        borderRadius: "3px",
        textAlign: "left",
        padding: "1.5em",
        marginBottom: "2em",
        width: "100%",
    },
    noteHeader: {
        color: "black",
        fontSize: "1.25rem",
        marginBottom: "1rem",
        fontWeight: "700",
    },
    noteText: {
        color: "black",
        fontSize: "1rem",
        marginBottom: "1.25rem",
        whiteSpace: "pre-line",
    },
    noteMetadata: {
        color: "black",
        fontStyle: "italic",
        fontSize: ".85rem",
    },
    boldText: {
        fontWeight: "700",
    },
}));

const RelevantNotes = (props) => {
    const classes = useStyles();
    const [isEditing, setIsEditing] = useState(false);
    const [originalNoteData, setOriginalNoteData] = useState("");
    const [noteData, setNoteData] = useState({
        note_updated_by: "",
        note_updated_on: "",
        relevant_notes: "",
    });
    // const [loading, setLoading] = useState(true);

    useEffect(() => {
        // console.log("useEffect being called");
        getNoteData();
    }, [noteData.value]);

    const getNoteData = () => {
        const postData = {
            expert_id: props.expertId,
            project_id: props.projectId,
            user_id: props.authId,
        };

        // console.log(postData, " :: postData");

        axios
            .post("/api/project/getRelevantNotes", postData, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                const { note_updated_by = "", note_updated_on = "" } = result?.data?.noteInfo;
                const relevant_notes = result.data.noteInfo.relevant_notes ?? "";
                setNoteData({
                    note_updated_by,
                    note_updated_on,
                    relevant_notes,
                });
                setOriginalNoteData(relevant_notes);
            })
            .catch((error) => {
                console.log(error, " :: there was an error with getNoteData");
            });
    };

    const submitNewNote = () => {
        props.setOpenLoader(true);
        if (props.expertId && props.projectId && props.authId) {
            const postData = {
                note_data: noteData.relevant_notes,
                expert_id: props.expertId,
                project_id: props.projectId,
                auth_id: props.authId,
            };

            axios
                .post("/api/project/updateRelevantNotes", postData, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                })
                .then((result) => {
                    if (result.status === 200) {
                        // console.log(result, " :: result");
                        // console.log(result.data.note_data, " :: result.data");
                        // console.log("note_data: ", result.data.note_data);
                        console.log(`Successful note Update Return POST: ${JSON.stringify(result.data)}`);
                        props.setOpenLoader(false);
                        getNoteData();
                        setIsEditing(false);
                    } else {
                        console.log(`Good POST, but something went wrong: ${JSON.stringify(result.data)}`);
                        props.setOpenLoader(false);
                        setIsEditing(false);
                    }
                })
                .catch((error) => {
                    console.log(`API not done yet, 404 Error: ${JSON.stringify(error)}`);
                    props.setOpenLoader(false);
                    setIsEditing(false);
                });
        }
    };

    const handleClick = (event) => {
        const btnValue = event.currentTarget.value;
        if (btnValue === "edit") {
            setIsEditing(true);
        } else if (btnValue === "save") {
            submitNewNote();
        } else if (btnValue === "cancel") {
            console.log("cancel");
            setNoteData({
                ...noteData,
                relevant_notes: originalNoteData,
            });
            setIsEditing(false);
        }
    };

    const handleChange = (event) => {
        setNoteData({
            ...noteData,
            relevant_notes: event.target.value,
        });
    };

    const renderNoteText = () => {
        if (noteData.relevant_notes) {
            return (
                <>
                    <div className={classes.noteText}>{`${noteData.relevant_notes}`}</div>
                    <div className={classes.noteMetadata}>
                        {`(updated on `}
                        <span className={classes.boldText}>{`${moment(noteData.note_updated_on).format(
                            "dddd, MMMM Do, YYYY @ h:mma"
                        )}`}</span>
                        {` by `}
                        <span className={classes.boldText}>{`${noteData.note_updated_by}`}</span>
                        {`)`}
                    </div>
                </>
            );
        } else {
            return <div className={classes.noteMetadata}>{"** Enter Note About Expert For This Project **"}</div>;
        }
    };

    return (
        <div>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                {!isEditing && (
                    <div className={classes.profileBox}>
                        <div className={classes.noteHeader}>Relevant Notes:</div>
                        <div>{renderNoteText()}</div>
                        <hr />
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                            <Button value="edit" variant="contained" color="primary" onClick={handleClick}>
                                Edit/Add Relevant Notes
                            </Button>
                        </Box>
                    </div>
                )}
                {isEditing && (
                    <div className={classes.profileBox}>
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                            <TextField
                                disabled={!isEditing}
                                variant="outlined"
                                className={classes.textInput}
                                label="Relevant Notes"
                                multiline
                                value={noteData.relevant_notes}
                                InputLabelProps={{ shrink: true }}
                                onChange={handleChange}
                            />
                            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                                <Button value="save" variant="contained" color="primary" onClick={handleClick}>
                                    Save
                                </Button>
                                <Button
                                    value="cancel"
                                    className={classes.btnStyles}
                                    variant="contained"
                                    color="primary"
                                    onClick={handleClick}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    </div>
                )}
            </Box>
        </div>
    );
};

export default RelevantNotes;
