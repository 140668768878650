import React, { useState, useEffect, useRef } from "react";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import "moment-duration-format";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import { green } from "@mui/material/colors";
import { formatPhoneNumber, stripHtml, doctorNameFormat } from "../Utils";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/system";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    typography: {
        padding: theme.spacing(2),
    },
    actions: {
        textAlign: "Center",
    },
    warningCopy: {
        color: "red",
        fontSize: "1.25rem",
        fontWeight: "bold",
        marginTop: "0",
    },
    uneditableHeader: {
        color: "red",
        fontSize: ".65rem",
        textTransform: "uppercase",
        textAlign: "center",
    },
    datestamp: {
        color: "#666",
        fontSize: ".65rem",
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    emailBorder: {
        border: "1px solid #e5e5e5",
        backgroundColor: "#f8f8f8",
        borderRadius: "2px",
        padding: "12px",
        fontSize: ".75rem",
    },
    emailOutline: {
        border: "1px solid #eaeaea",
        backgroundColor: "#ffffff",
        borderRadius: "2px",
        padding: "8px",
    },
    editorWrapper: {
        border: "1px solid #EAEAEA",
        borderRadius: "2px",
        padding: "12px",
    },
    iconSmall: {
        fontSize: ".95rem",
    },
    wrapper: {
        margin: theme.spacing(1),
        position: "relative",
    },
    buttonSuccess: {
        backgroundColor: green[500],
        "&:hover": {
            backgroundColor: green[700],
        },
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: 22,
    },
}));

function OnboardTemplate(props) {
    const _state = props.state_manager;
    const now = moment();
    const classes = useStyles();
    const theme = useTheme();
    const timer = useRef();
    const [confirmButton, setConfirmButtonDisplay] = useState("block");
    const [sendButton, setButtonDisplay] = useState("none");
    const [preview, setPreviewArea] = useState("none");
    const [editableAreas, setEditableArea] = useState("block");
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [template, setEmailTemplate] = useState({
        expert_id: props.expert.id,
        expert_prefix: props.expert.prefix,
        expert_fname: props.expert.fname,
        expert_lname: props.expert.lname,
        expert_suffix: props.expert.suffix,
        expert_email: props.expert.mail ? props.expert.mail.trim() : "",
        project_id: _state.get.projectState.id,
        user_id: props.user.id,
        user_fname: props.user.fname,
        user_lname: props.user.lname,
        from_email: props.user.mail,
        email_subject: props.expert.tnc_sent
            ? `Follow-up: Expertise Requested in ${_state.get.projectState.title} (ID-${_state.get.projectState.id})`
            : `Expertise Requested in ${_state.get.projectState.title} (ID-${_state.get.projectState.id})`,
        email_body: "",
        email_template: props.type,
    });

    const onboardingStatic = () => {
        let html = "<table border='0'><tr>";
        html +=
            "<td><a href='{{ACCEPT_LINK}}' style='background-color: #1c66d4; font-size: 18px; font-family: Helvetica, Arial, sans-serif; font-weight: bold; text-decoration: none; padding: 14px 20px; color: #ffffff; border-radius: 5px; display: inline-block; miso-padding-alt: 0;'>";
        html += "<!--[if mso]>";
        html += "<i style='letter-spacing: 25px; mso-font-width: -100%; mso-text-raise: 30pt;'>&nbsp;</i>";
        html += "<![endif]-->";
        html += "<span style='mso-text-raise: 15pt;'>Yes, I'm Interested</span>";
        html += "<!--[if mso]>";
        html += "<i style='letter-spacing: 25px; mso-font-width: -100%;'>&nbsp;</i>";
        html += "<![endif]-->";
        html += "</a></td>";
        html += "<td style='padding:5px;'>&nbsp;</td>";
        html +=
            "<td><a href='{{DECLINE_LINK}}' style='background-color: #fff; font-size: 18px; font-family: Helvetica, Arial, sans-serif; font-weight: bold; text-decoration: none; padding: 14px 20px; color: #1c66d4; border: 1px solid #1c66d4; border-radius: 5px; display: inline-block; miso-padding-alt: 0;'>";
        html += "<!--[if mso]>";
        html += "<i style='letter-spacing: 25px; mso-font-width: -100%; mso-text-raise: 30pt;'>&nbsp;</i>";
        html += "<![endif]-->";
        html += "<span style='mso-text-raise: 15pt;'>No Thank You</span>";
        html += "<!--[if mso]>";
        html += "<i style='letter-spacing: 25px; mso-font-width: -100%;'>&nbsp;</i>";
        html += "<![endif]-->";
        html += "</a></td>";
        html += "</tr></table><br />";
        return html;
    };

    const onboardingFooter = () => {
        let html = "<p>";
        html += `Best regards,<br />${props.user.fname}`;
        html += "</p>";
        html += "<br />";
        html += "<p>";
        html += `<em>${props.user.fname} ${props.user.lname}</em>`;
        html += "<br />";
        html += `<em>${props.user.title}</em>`;
        html += "<br />";
        html += `<em>${formatPhoneNumber(props.user.number)}</em>`;
        html += "<br />";
        html += `<em>${props.user.mail}</em>`;
        html += "</p>";
        html += "<br />";
        html += "<p style='text-align:center;font-size:10px;'>";
        html += "www.firstthought.io | new york | london | tel aviv";
        html += "<br />";
        html += `You may <a href="https://www.firstthought.io/unsubscribe/${template.expert_id}/${template.expert_email}/tnc">unsubscribe</a> from future emails, but please be aware this would drastically limit you from receiving important opportunities to share your expertise.`;
        html += "</p>";
        return html;
    };

    const [onboarding, setOnboardCopy] = useState({
        greeting: `${doctorNameFormat(
            template.expert_prefix,
            template.expert_fname,
            template.expert_lname,
            template.expert_suffix
        )}`,
        introduction: `My name is ${props.user.fname} and I am a ${props.user.title} at <a href="https://www.firstthought.io">FirstThought</a>, a life sciences and healthcare expert network based in New York City.`,
        description: `${stripHtml(_state.get.projectState.description)}`,
        question: "Would you be interested in participating in this paid 30-60 minute phone consultation?",
        static: onboardingStatic(),
        incentive:
            "Please note that we offer an <strong>hourly honorarium rate of your choosing</strong> for your time speaking with our clients. As background, our clients are healthcare consultants, investors and corporate institutions who engage with FirstThought’s network of subject matter experts like you for the purposes of completing their research.",
        contact: `If you would like more information about FirstThought, this topic, or network membership benefits, <a href="mailto:${props.user.mail}?Subject=I would like more information about {{SUBJECT_LINE}}">feel free to reply to me directly</a>.`,
        footer: onboardingFooter(),
    });

    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
    });

    function findReplaceLinks(body) {
        const onboardLink = `https://www.firstthought.io/onboard/${props.privateToken}`;
        const declineLink = `https://www.firstthought.io/decline/${props.privateToken}`;
        let newBody = "";
        if (body.indexOf("{{ACCEPT_LINK}}") !== -1) {
            newBody = body.replace("{{ACCEPT_LINK}}", onboardLink).replace("{{DECLINE_LINK}}", declineLink);
        } else {
            newBody = body;
        }
        return newBody;
    }
    function findReplaceSubjectLineVariable(body) {
        const subjectLine = `${template.email_subject}`;
        let str = "";
        if (body.indexOf("{{SUBJECT_LINE}}") !== -1) {
            str = body.replace("{{SUBJECT_LINE}}", subjectLine);
        } else {
            str = body;
        }
        return str;
    }

    function onboardingTemplate() {
        let html = "<p>";
        html += `${onboarding.greeting},`;
        html += "</p>";
        html += "<p>";
        html += `${onboarding.introduction}`;
        html += "</p>";
        html += "<p>";
        html += `${onboarding.description}`;
        html += "</p>";
        html += "<p>";
        html += `${onboarding.question}`;
        html += "</p>";
        html += `${findReplaceLinks(onboarding.static)}`;
        html += "<p>";
        html += `${onboarding.incentive}`;
        html += "</p>";
        html += "<p>";
        html += `${findReplaceSubjectLineVariable(onboarding.contact)}`;
        html += "</p>";
        html += `${onboarding.footer}`;
        return html;
    }

    function formatDateString(str) {
        return moment(str).format("dddd, MMMM. Do, YYYY, h:mma");
    }

    function handleChange(event) {
        const { target } = event;
        const { value, name } = target;
        setEmailTemplate({
            ...template,
            [name]: value,
        });
    }

    function handleOnboardingChange(event) {
        const { target } = event;
        const { value, name } = target;
        setOnboardCopy({
            ...onboarding,
            [name]: value,
        });
    }

    function sendEmail() {
        // Set the email body from edits
        template.email_body = onboardingTemplate();
        const url = "/api/mail/tnc";
        if (!loading) {
            setSuccess(false);
            setLoading(true);
        }
        console.log("POST: ", template);
        axios
            .post(url, template, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    console.log("Email Template Sent", template);
                    timer.current = setTimeout(() => {
                        setSuccess(true);
                        setLoading(false);
                        props.closeEvent();
                        props.updateEvent(result.data.data);
                    }, 3000);
                    // Reload the page to show new TnC status
                } else {
                    console.log(`POSTED, but NOT Status 200: ${JSON.stringify(template)}`);
                    alert("Oops, Something went wrong. Your email did not send. Please contact someone in Tech.");
                }
            })
            .catch((result) => {
                console.log("Something went wrong, API Error, 404 Error:", result);
                alert("Oops, Something went wrong. Your email did not send. Please contact someone in Tech.");
            });
    }

    function showPreview() {
        setEditableArea("none");
        setPreviewArea("block");
        setConfirmButtonDisplay("block");
        setButtonDisplay("none");
    }
    function editEmail() {
        setEditableArea("block");
        setPreviewArea("none");
        setConfirmButtonDisplay("block");
        setButtonDisplay("none");
    }
    function showSendEmailButton() {
        if (props.user.number !== "" && props.user.number.length > 9) {
            setButtonDisplay("block");
            setConfirmButtonDisplay("none");
            console.log(template);
        } else {
            alert("Please provide a phone number for yourself before sending any outreach.");
        }
    }

    useEffect(() => {
        // Set the main template
        setEmailTemplate({
            ...template,
            email_body: onboardingTemplate(),
        });
    }, [props]);

    return (
        <div>
            <Box display={preview}>
                <Box display={confirmButton}>
                    <Typography sx={{ color: "red", fontSize: "1.25rem", fontWeight: "bold", marginTop: "0" }}>
                        PREVIEW ONLY: Please review and confirm this is ready to send.
                    </Typography>
                </Box>
                <Box
                    sx={{
                        border: "1px solid #e5e5e5",
                        backgroundColor: "#f8f8f8",
                        borderRadius: "2px",
                        padding: "12px",
                        fontSize: ".75rem",
                    }}
                >
                    <Typography sx={{ color: "#666", fontSize: ".65rem" }}>{formatDateString(now)}</Typography>
                    <p>
                        <strong>FROM:</strong> {template.user_fname} {template.user_lname} ({template.from_email})
                    </p>
                    <p>
                        <strong>TO:</strong> {template.expert_fname} {template.expert_lname} ({template.expert_email})
                    </p>
                    <Typography sx={{ borderBottom: "2px solid #444", paddingBottom: "5px", marginBottom: "10px" }}>
                        <strong>SUBJECT LINE:</strong> {template.email_subject}
                    </Typography>
                    <Box
                        sx={{
                            border: "1px solid #eaeaea",
                            backgroundColor: "#ffffff",
                            borderRadius: "2px",
                            padding: "8px",
                        }}
                        dangerouslySetInnerHTML={{
                            __html: onboardingTemplate(),
                        }}
                    />
                </Box>
                <Box sx={{ textAlign: "Center" }}>
                    <Box sx={{ margin: theme.spacing(1), position: "relative" }}>
                        <Box display={confirmButton}>
                            <Button onClick={editEmail}>Edit this Email</Button>
                            <Button onClick={showSendEmailButton}>
                                <WhatshotIcon /> Confirmed, This Looks Fire!
                            </Button>
                        </Box>
                        <Box display={sendButton}>
                            <Button onClick={editEmail}>Back to Edit</Button>
                            <Button
                                variant="contained"
                                color="primary"
                                className={buttonClassname}
                                disabled={loading}
                                onClick={sendEmail}
                            >
                                Send Email Now
                            </Button>
                            {loading && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        color: green[500],
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        marginTop: -12,
                                        marginLeft: 22,
                                    }}
                                />
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box display={editableAreas}>
                <TextField
                    fullWidth
                    // id="email_subject"
                    label="Email Subject"
                    margin="normal"
                    onChange={handleChange}
                    name="email_subject"
                    value={template.email_subject}
                />
                <TextField
                    fullWidth
                    id="greeting"
                    label="Greeting"
                    margin="normal"
                    onChange={handleOnboardingChange}
                    name="greeting"
                    value={onboarding.greeting}
                />
                <TextField
                    fullWidth
                    multiline
                    rows="3"
                    id="introduction"
                    label="Introduce Yourself"
                    margin="normal"
                    onChange={handleOnboardingChange}
                    name="introduction"
                    value={onboarding.introduction}
                />
                <TextField
                    fullWidth
                    multiline
                    rows="8"
                    id="description"
                    label="Describe The Project"
                    margin="normal"
                    onChange={handleOnboardingChange}
                    name="description"
                    value={onboarding.description}
                />
                <TextField
                    fullWidth
                    id="question"
                    label="The YES OR NO Question"
                    margin="normal"
                    onChange={handleOnboardingChange}
                    name="question"
                    value={onboarding.question}
                />
                <Box
                    sx={{
                        border: "1px solid #eaeaea",
                        backgroundColor: "#ffffff",
                        borderRadius: "2px",
                        padding: "8px",
                    }}
                    dangerouslySetInnerHTML={{
                        __html: onboarding.static,
                    }}
                />
                <TextField
                    fullWidth
                    multiline
                    rows="3"
                    id="incentive"
                    label="What's In It For Them?"
                    margin="normal"
                    onChange={handleOnboardingChange}
                    name="incentive"
                    value={onboarding.incentive}
                />
                <TextField
                    fullWidth
                    multiline
                    rows="2"
                    id="contact"
                    label="Do They Need More Info?"
                    margin="normal"
                    onChange={handleOnboardingChange}
                    name="contact"
                    value={onboarding.contact}
                />
                <Box sx={{ textAlign: "Center" }}>
                    <Box sx={{ margin: theme.spacing(1), position: "relative" }}>
                        <Button onClick={props.closeEvent}>Cancel</Button>
                        <Button variant="contained" color="primary" className={buttonClassname} onClick={showPreview}>
                            Next
                        </Button>
                    </Box>
                </Box>
            </Box>
        </div>
    );
}
export default OnboardTemplate;
