/**
 * PROPS:
 * handleRowClick => handler function that causes a scheduling modal to open when clicked on
 * rows           => array of objects [...{ expert_id, fname, lname }] that populates the table with the experts
 */

import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";

// styles
import { styled } from "@mui/system";

/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#6f42c1",
        color: "white",
    },
}));

const StyledRow = styled(TableRow)({
    "&:hover": {
        cursor: "pointer",
        backgroundColor: "#d8c7ff !important",
    },
    "&:nth-of-type(odd)": {
        backgroundColor: "#f5f5f5",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
});

const StyledSpan = styled("span")({
    "&:hover": {
        cursor: "pointer",
        color: "#c5120a",
    },
});

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: "name",
        numeric: true,
        label: "Expert Name",
    },
    {
        id: "consultation_rate",
        numeric: true,
        label: "* Project Rate | Unit Pricing",
    },
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <StyledTableCell
                        key={headCell.id}
                        align={"left"}
                        padding={"normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable(props) {
    const { rows, handleRowClick, displayExpertPreview, projectData } = props;

    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("calories");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleExpertClick = (event, expertId) => {
        event.stopPropagation();
        displayExpertPreview(expertId);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    return (
        <Box sx={{ width: "100%" }}>
            <p
                css={css`
                    font-size: 0.9rem;
                    margin-bottom: 0.5rem;
                `}
            >
                * "Project Rate" factors in Fair Market Value Rate Caps and/or Cost Plus Multiplier/Charges. Survey
                projects use the price per unit as the project rate.
            </p>
            <Paper sx={{ width: "100%", mb: 2 }}>
                <TableContainer>
                    <Table sx={{ width: "100%" }} aria-labelledby="tableTitle">
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <StyledRow
                                            hover
                                            onClick={(event) => handleRowClick(event, row)}
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.expert_id}
                                        >
                                            <TableCell align="left">
                                                <div
                                                    css={css`
                                                        margin-bottom: 0.25rem;
                                                    `}
                                                >
                                                    <StyledSpan
                                                        css={css`
                                                            font-weight: 700;
                                                            font-size: 0.925rem;
                                                        `}
                                                        onClick={(event) => handleExpertClick(event, row.expert_id)}
                                                    >
                                                        {`${row.name}`}
                                                    </StyledSpan>
                                                </div>
                                                <div
                                                    css={css`
                                                        margin-bottom: 0.25rem;
                                                    `}
                                                >{`Location: ${row.address}`}</div>
                                                <div>
                                                    {`Compliance: `}
                                                    <span
                                                        css={css`
                                                            background-color: ${row.compliance_required
                                                                ? "#ffb8b8"
                                                                : "#c3ffb8"};
                                                        `}
                                                    >{`${row.compliance_required ? "Needs Review" : "OK"}`}</span>
                                                </div>
                                            </TableCell>
                                            <TableCell align="left">
                                                {projectData.project_type_id && projectData.project_type_id === 8 ? (
                                                    <div>{`Survey Rate: $${projectData.price_per_unit} USD`}</div>
                                                ) : (
                                                    <>
                                                        <div
                                                            css={css`
                                                                margin-bottom: 0.45rem;
                                                            `}
                                                        >
                                                            {`Project Rate: ${
                                                                row.consultation_rate ? row.consultation_rate : "N/A"
                                                            }`}
                                                        </div>
                                                        <div>
                                                            {`Unit Pricing: ${
                                                                row.unitPricing ? row.unitPricing : "N/A"
                                                            }`}
                                                        </div>
                                                    </>
                                                )}
                                            </TableCell>
                                        </StyledRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}
