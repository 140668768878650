// react
import React, { useState, useEffect } from "react";

// editor
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// MUI
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import { green } from "@mui/material/colors";

// utils
import { doctorNameFormat, formatPhoneNumber } from "../../../Utils";
import { createDateTimeStringForClient } from "../utils/utils";

// styles
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    typography: {
        padding: theme.spacing(2),
    },
    actions: {
        textAlign: "Center",
    },
    warningCopy: {
        color: "red",
        fontSize: "1.25rem",
        fontWeight: "bold",
        marginTop: "0",
    },
    datestamp: {
        color: "#666",
        fontSize: ".65rem",
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    emailBorder: {
        border: "1px solid #e5e5e5",
        backgroundColor: "#f8f8f8",
        borderRadius: "2px",
        padding: "12px",
        fontSize: ".75rem",
    },
    emailOutline: {
        border: "1px solid #eaeaea",
        backgroundColor: "#ffffff",
        borderRadius: "2px",
        padding: "8px",
    },
    editorWrapper: {
        border: "1px solid #EAEAEA",
        borderRadius: "2px",
        padding: "12px",
    },
    iconSmall: {
        fontSize: ".95rem",
    },
    wrapper: {
        margin: theme.spacing(1),
        position: "relative",
    },
    buttonSuccess: {
        backgroundColor: green[500],
        "&:hover": {
            backgroundColor: green[700],
        },
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: 22,
    },
}));

function SchedulingCallTemplate(props) {
    // props
    const { projectData, expertData, authData } = props;

    // destructuring prop data
    const { title, topic, client_name, client_id } = projectData;

    const {
        expert_id,
        call_type,
        pin_data,
        timezone_id,
        scheduled_datetime,
        scheduled_timezone,
        prefix,
        fname,
        lname,
        suffix,
        position,
        company,
        biography,
    } = expertData;

    const { fname: authFname, lname: authLname, number, mail, title: authTitle } = authData;

    const classes = useStyles();
    const [editor, setEditorState] = useState(EditorState.createEmpty());
    const [template, setEmailTemplate] = useState({
        email_body: "",
    });

    function onEditorStateChange(editor) {
        setEditorState(editor);
        const jsonHtml = convertToRaw(editor.getCurrentContent());
        const html = draftToHtml(jsonHtml);
        setEmailTemplate({
            ...template,
            email_body: html,
        });
    }

    function renderPinHTML(pinData, entity) {
        let conferencePins = "<p>";
        if (parseInt(call_type, 10) === 1) {
            conferencePins += "<em>East Coast Conference DIAL-IN: (516) 447-2943</em><br />";
            conferencePins += "<em>West Coast Conference DIAL-IN: (714) 360-0768</em><br />";
            conferencePins += "<em>Great Britain (UK) Conference DIAL-IN: +44 7723 364310</em><br />";
            conferencePins += "<em>Toronto, ON Conference DIAL-IN: +1 647 694 6230</em><br />";
            conferencePins += "<em>Sweden (SE) Conference DIAL-IN: +46 10 551 73 26 (National)</em><br />";
            conferencePins += "<em>Israel Conference DIAL-IN: +972 76 599 4604</em><br /><br />";
            for (let i = 0; i < pinData.length; i++) {
                if (pinData[i].entity_type === parseInt(entity, 10)) {
                    conferencePins += '<span style="background-color:#ffff00;">';
                    conferencePins += `Personal PIN#: ${pinData[i].pin}`;
                    conferencePins += "</span>";
                    conferencePins += "</p>";
                }
            }
            conferencePins +=
                '<p><span style="background-color:#ffff00;">If you are unable to keep this scheduled call, please reply to this email immediately and let us know some alternative days/times you are free to reschedule this call.</span></p>';
        } else {
            conferencePins += "<strong>|---------- PLEASE ADD EXTERNAL BRIDGING LINK ----------|</strong></p>";
            conferencePins +=
                '<br /><p><span style="background-color:#ffff00;">If you are unable to keep this scheduled call, please reply to this email immediately and let us know some alternative days/times you are free to reschedule this call.</span></p>';
        }
        return conferencePins;
    }

    function createOtherTokenLink(type) {
        const entity = parseInt(type, 10);
        const callObj = pin_data;
        const token = callObj.find((c) => c.entity_type === entity).emailToken;
        // console.log("TOKEN: ", token);
        const link = `<a href="https://firstthought.io/meet/${token}">Your Personal Meeting Link</a>`;
        return link;
    }
    function createOtherTokenUrl(type) {
        const entity = parseInt(type, 10);
        const callObj = pin_data;
        const token = callObj.find((c) => c.entity_type === entity).emailToken;
        const link = `https://firstthought.io/meet/${token}`;
        return link;
    }

    function createEmailTokenLink(id) {
        const callObj = pin_data;
        const token = callObj.find((c) => c.entity_id === id).emailToken;
        // console.log("TOKEN: ", token);
        const link = `<a href="https://firstthought.io/meet/${token}">Your Personal Meeting Link</a>`;
        return link;
    }

    function createEmailTokenUrl(id) {
        // console.log(id);
        const callObj = pin_data;
        const token = callObj.find((c) => c.entity_id === id).emailToken;
        const link = `https://firstthought.io/meet/${token}`;
        return link;
    }

    function scheduleComplianceDialOut() {
        let html = "<p>";
        html += "Dear Compliance Officer,";
        html += "</p>";
        html += "<p>";
        html += `Topic: ${topic ? topic : title}`;
        html += "</p>";
        html += "<p>";
        html += `<strong>Time: ${createDateTimeStringForClient(
            scheduled_datetime,
            timezone_id
        )} (${scheduled_timezone})</strong>`;
        html += "</p>";
        html += "<p>";
        html += `Please use the following secure link to join the call: <strong>${createOtherTokenLink(5)}</strong>`;
        html += "<br />";
        html += `Or copy/paste the following private URL into a web browser:<br /><br /><em>${createOtherTokenUrl(
            5
        )}</em>`;
        html += "</p>";
        html +=
            "<p>Your personal meeting link will prompt you to select the country where your number is based (e.g. US +1) followed by your phone number (e.g. 999 555 1212) and we will dial out to you immediately for this conference call.</p>";
        html += "<p>";
        html += `<strong>Please note:</strong> This conference will not be activated until 15 minutes before your meeting start time.  If you do not receive a call for this meeting please contact ${authFname} ${authLname} at ${formatPhoneNumber(
            number
        )} or ${mail}.`;
        html += "</p>";
        html +=
            "<p>* If you are based in the US, and would like to dial into the call instead. Please use the information below to call into the meeting no earlier than 15 minutes before the meeting start time. You will be prompted to enter your personal pin.</p>";
        html += `${renderPinHTML(pin_data, 5)}`;
        html += "<p>";
        html += `Best regards,<br />${authFname}`;
        html += "</p>";
        html += "<br />";
        html += "<p>";
        html += `<em>${fname} ${authLname}</em>`;
        html += "<br />";
        html += `<em>${authTitle}</em>`;
        html += "<br />";
        html += `<em>${formatPhoneNumber(number)}</em>`;
        html += "<br />";
        html += `<em>${mail} | www.firstthought.io</em>`;
        html += "</p>";
        return html;
    }

    function scheduleTranslatorDialOut() {
        let html = "<p>";
        html += "Dear Translator,";
        html += "</p>";
        html += "<p>";
        html += `Topic: ${topic ? topic : title}`;
        html += "</p>";
        html += "<p>";
        html += `<strong>Time: ${createDateTimeStringForClient(
            scheduled_datetime,
            timezone_id
        )} (${scheduled_timezone})</strong>`;
        html += "</p>";
        html += "<p>";
        html += `Please use the following secure link to join the call: <strong>${createOtherTokenLink(4)}</strong>`;
        html += "<br />";
        html += `Or copy/paste the following private URL into a web browser:<br /><br /><em>${createOtherTokenUrl(
            4
        )}</em>`;
        html += "</p>";
        html +=
            "<p>Your personal meeting link will prompt you to select the country where your number is based (e.g. US +1) followed by your phone number (e.g. 999 555 1212) and we will dial out to you immediately for this conference call.</p>";
        html += "<p>";
        html += `<strong>Please note:</strong> This conference will not be activated until 15 minutes before your meeting start time.  If you do not receive a call for this meeting please contact ${authFname} ${authLname} at ${formatPhoneNumber(
            number
        )} or ${mail}.`;
        html += "</p>";
        html +=
            "<p>* If you are based in the US, and would like to dial into the call instead. Please use the information below to call into the meeting no earlier than 15 minutes before the meeting start time. You will be prompted to enter your personal pin.</p>";
        html += `${renderPinHTML(pin_data, 4)}`;
        html += "<p>";
        html += `Best regards,<br />${authFname}`;
        html += "</p>";
        html += "<br />";
        html += "<p>";
        html += `<em>${authFname} ${authLname}</em>`;
        html += "<br />";
        html += `<em>${authTitle}</em>`;
        html += "<br />";
        html += `<em>${formatPhoneNumber(number)}</em>`;
        html += "<br />";
        html += `<em>${mail} | www.firstthought.io</em>`;
        html += "</p>";
        return html;
    }

    function scheduleModeratorDialOut() {
        let html = "<p>";
        html += "Dear Moderator,";
        html += "</p>";
        html += "<p>";
        html += `Topic: ${topic ? topic : title}`;
        html += "</p>";
        html += "<p>";
        html += `<strong>Time: ${createDateTimeStringForClient(
            scheduled_datetime,
            timezone_id
        )} (${scheduled_timezone})</strong>`;
        html += "</p>";
        html += "<p>";
        html += `Please use the following secure link to join the call: <strong>${createOtherTokenLink(3)}</strong>`;
        html += "<br />";
        html += `Or copy/paste the following private URL into a web browser:<br /><br /><em>${createOtherTokenUrl(
            3
        )}</em>`;
        html += "</p>";
        html +=
            "<p>Your personal meeting link will prompt you to select the country where your number is based (e.g. US +1) followed by your phone number (e.g. 999 555 1212) and we will dial out to you immediately for this conference call.</p>";
        html += "<p>";
        html += `<strong>Please note:</strong> This conference will not be activated until 15 minutes before your meeting start time.  If you do not receive a call for this meeting please contact ${authFname} ${authLname} at ${formatPhoneNumber(
            number
        )} or ${mail}.`;
        html += "</p>";
        html +=
            "<p>* If you are based in the US, and would like to dial into the call instead. Please use the information below to call into the meeting no earlier than 15 minutes before the meeting start time. You will be prompted to enter your personal pin.</p>";
        html += `${renderPinHTML(pin_data, 3)}`;
        html += "<p>";
        html += `Best regards,<br />${authFname}`;
        html += "</p>";
        html += "<br />";
        html += "<p>";
        html += `<em>${authFname} ${authLname}</em>`;
        html += "<br />";
        html += `<em>${authTitle}</em>`;
        html += "<br />";
        html += `<em>${formatPhoneNumber(number)}</em>`;
        html += "<br />";
        html += `<em>${mail} | www.firstthought.io</em>`;
        html += "</p>";
        return html;
    }

    function scheduleExpertDialIn() {
        let html = "";
        html += "<p>";
        html += `${doctorNameFormat(prefix, fname, lname, suffix)},`;
        html += "</p>";
        html += "<p>";
        html += `Topic: ${topic ? topic : title}`;
        html += "</p>";
        html += "<p>";
        html += `<strong>Time: ${createDateTimeStringForClient(
            scheduled_datetime,
            timezone_id
        )} (${scheduled_timezone})</strong>`;
        html += "</p>";
        html +=
            "<p>Please use the information below to call into the meeting no earlier than 15 minutes before the meeting start time. You will be prompted to enter your personal pin.</p>";
        html += `${renderPinHTML(pin_data, 1)}`;
        html += "<p>";
        html += `Best regards,<br />${authFname}`;
        html += "</p>";
        html += "<br />";
        html += "<p>";
        html += `<em>${authFname} ${authLname}</em>`;
        html += "<br />";
        html += `<em>${authTitle}</em>`;
        html += "<br />";
        html += `<em>${formatPhoneNumber(number)}</em>`;
        html += "<br />";
        html += `<em>${mail} | www.firstthought.io</em>`;
        html += "</p>";
        return html;
    }

    function scheduleExpertDialOut() {
        let html = "<p>";
        html += `${doctorNameFormat(prefix, fname, lname, suffix)},`;
        html += "</p>";
        html += "<p>";
        html += `Topic: ${topic ? topic : title}`;
        html += "</p>";
        html += "<p>";
        html += `<strong>Time: ${createDateTimeStringForClient(
            scheduled_datetime,
            timezone_id
        )} (${scheduled_timezone})</strong>`;
        html += "</p>";
        html += "<p>";
        html += `Please use the following secure link to join the call: <strong>${createEmailTokenLink(
            expert_id
        )}</strong>`;
        html += "<br />";
        html += `Or copy/paste the following private URL into a web browser:<br /><br /><em>${createEmailTokenUrl(
            expert_id
        )}</em>`;
        html += "</p>";
        html +=
            "<p>Your personal meeting link will prompt you to select the country where your number is based (e.g. US +1) followed by your phone number (e.g. 999 555 1212) and we will dial out to you immediately for this conference call.</p>";
        html += "<p>";
        html += `<strong>Please note:</strong> This conference will not be activated until 15 minutes before your meeting start time.  If you do not receive a call for this meeting please contact ${authFname} ${authLname} at ${formatPhoneNumber(
            number
        )} or ${mail}.`;
        html += "</p>";
        html +=
            "<p>* If you are based in the US, and would like to dial into the call instead. Please use the information below to call into the meeting no earlier than 15 minutes before the meeting start time. You will be prompted to enter your personal pin.</p>";
        html += `${renderPinHTML(pin_data, 1)}`;
        html += "<p>";
        html += `Best regards,<br />${authFname}`;
        html += "</p>";
        html += "<br />";
        html += "<p>";
        html += `<em>${authFname} ${authLname}</em>`;
        html += "<br />";
        html += `<em>${authTitle}</em>`;
        html += "<br />";
        html += `<em>${formatPhoneNumber(number)}</em>`;
        html += "<br />";
        html += `<em>${mail} | www.firstthought.io</em>`;
        html += "</p>";
        return html;
    }

    function scheduleClientDialIn() {
        let html = "";
        html += "<p>";
        html += `Dear ${client_name},`;
        html += "</p>";
        html += "<p>";
        html += `Topic: ${topic ? topic : title}`;
        html += "</p>";
        html += "<p>";
        html += `<strong>Time: ${createDateTimeStringForClient(
            scheduled_datetime,
            timezone_id
        )} (${scheduled_timezone})</strong>`;
        html += "</p>";
        html +=
            "<p>Please use the information below to call into the meeting no earlier than 15 minutes before the meeting start time. You will be prompted to enter your personal pin.</p>";
        html += `${renderPinHTML(pin_data, 2)}`;
        html += "<br />";
        html += "<p>";
        html += `<strong>${fname} ${lname}${suffix && `<span>, ${suffix}</span>`}</strong><br />`;
        html += `Current Title: ${position ? position : "Unavailable"}<br />`;
        html += `Current Employer: ${company ? company : "Unavailable"}`;
        html += "</p>";
        html += "<p>";
        html += "<span style='text-decoration:underline;'>Screening Question Responses:</span><br />";
        html += "--------------------[ INSERT RESPONSES HERE ]--------------------";
        html += "</p>";
        html += "<p>";
        html += `<em>About ${fname}:</em><br />`;
        html += `${biography ? biography : "Unavailable"}`;
        html += "</p>";
        html += "<br />";
        html += "<p>";
        html += `Best regards,<br />${authFname}`;
        html += "</p>";
        html += "<br />";
        html += "<p>";
        html += `<em>${authFname} ${authLname}</em>`;
        html += "<br />";
        html += `<em>${authTitle}</em>`;
        html += "<br />";
        html += `<em>${formatPhoneNumber(number)}</em>`;
        html += "<br />";
        html += `<em>${mail} | www.firstthought.io</em>`;
        html += "</p>";
        return html;
    }

    function scheduleClientDialOut() {
        let html = "<p style='color:red;font-weight:bold;'>** BETA FEATURE - NOT READY FOR USE YET **</p>";
        html += "<p>";
        html += `Dear ${client_name},`;
        html += "</p>";
        html += "<p>";
        html += `Topic: ${topic ? topic : title}`;
        html += "</p>";
        html += "<p>";
        html += `<strong>Time: ${createDateTimeStringForClient(
            scheduled_datetime,
            timezone_id
        )} (${scheduled_timezone})</strong>`;
        html += "</p>";
        html += "<p>";
        html += `Please use the following secure link to join the call: <strong>${createEmailTokenLink(
            client_id
        )}</strong>`;
        html += "<br />";
        html += `Or copy/paste the following private URL into a web browser:<br /><br /><em>${createEmailTokenUrl(
            client_id
        )}</em>`;
        html += "</p>";
        html +=
            "<p>Your personal meeting link will prompt you to select the country where your number is based (e.g. US +1) followed by your phone number (e.g. 999 555 1212) and we will dial out to you immediately for this conference call.</p>";
        html += "<p>";
        html += `<strong>Please note:</strong> This conference will not be activated until 15 minutes before your meeting start time.  If you do not receive a call for this meeting please contact ${authFname} ${authLname} at ${formatPhoneNumber(
            number
        )} or ${mail}.`;
        html += "</p>";
        html +=
            "<p>* If you are based in the US, and would like to dial into the call instead. Please use the information below to call into the meeting no earlier than 15 minutes before the meeting start time. You will be prompted to enter your personal pin.</p>";
        html += `${renderPinHTML(pin_data, 2)}`;
        html += "<br />";
        html += "<p>";
        html += `<strong>${fname} ${lname}${suffix && `<span>, ${suffix}</span>`}</strong><br />`;
        html += `Current Title: ${position ? position : "Unavailable"}<br />`;
        html += `Current Employer: ${company ? company : "Unavailable"}`;
        html += "</p>";
        html += "<p>";
        html += "<span style='text-decoration:underline;'>Screening Question Responses:</span><br />";
        html += "--------------------[ INSERT RESPONSES HERE ]--------------------";
        html += "</p>";
        html += "<p>";
        html += `<em>About ${fname}:</em><br />`;
        html += `${biography ? biography : "Unavailable"}`;
        html += "</p>";
        html += "<br />";
        html += "<p>";
        html += `Best regards,<br />${authFname}`;
        html += "</p>";
        html += "<br />";
        html += "<p>";
        html += `<em>${authFname} ${authLname}</em>`;
        html += "<br />";
        html += `<em>${authTitle}</em>`;
        html += "<br />";
        html += `<em>${formatPhoneNumber(number)}</em>`;
        html += "<br />";
        html += `<em>${mail} | www.firstthought.io</em>`;
        html += "</p>";
        return html;
    }

    function setDefaultReferenceEditorState() {
        const html = scheduleExpertDialIn();

        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            setEditorState(EditorState.createWithContent(contentState));
        }
        setEmailTemplate({
            ...template,
            email_body: html,
        });
    }

    function resetTranslatorEmail() {
        const defaultCopy = scheduleTranslatorDialOut();
        const contentBlock = htmlToDraft(defaultCopy);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            setEditorState(EditorState.createWithContent(contentState));
        }
    }

    function resetModeratorEmail() {
        const defaultCopy = scheduleModeratorDialOut();
        const contentBlock = htmlToDraft(defaultCopy);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            setEditorState(EditorState.createWithContent(contentState));
        }
    }

    function resetComplianceEmail() {
        const defaultCopy = scheduleComplianceDialOut();
        const contentBlock = htmlToDraft(defaultCopy);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            setEditorState(EditorState.createWithContent(contentState));
        }
    }

    function resetClientEmail() {
        const defaultCopy = scheduleClientDialOut();
        const contentBlock = htmlToDraft(defaultCopy);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            setEditorState(EditorState.createWithContent(contentState));
        }
    }

    function resetExpertEmail() {
        const defaultCopy = scheduleExpertDialOut();
        const contentBlock = htmlToDraft(defaultCopy);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            setEditorState(EditorState.createWithContent(contentState));
        }
    }

    function resetExpertDialInEmail() {
        const defaultCopy = scheduleExpertDialIn();
        const contentBlock = htmlToDraft(defaultCopy);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            setEditorState(EditorState.createWithContent(contentState));
        }
    }

    function resetClientDialInEmail() {
        const defaultCopy = scheduleClientDialIn();
        const contentBlock = htmlToDraft(defaultCopy);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            setEditorState(EditorState.createWithContent(contentState));
        }
    }

    useEffect(() => {
        setDefaultReferenceEditorState();
    }, [props]);

    return (
        <div>
            <Button onClick={resetExpertDialInEmail} color="secondary">
                Expert Dial-In
            </Button>
            <Button onClick={resetExpertEmail} color="secondary">
                Expert Dial-Out
            </Button>
            <Button onClick={resetClientDialInEmail} color="secondary">
                Client Dial-In
            </Button>
            <Button onClick={resetClientEmail} color="secondary">
                Client Dial-Out
            </Button>
            {pin_data.length > 2 && pin_data.find((p) => p.entity_type === 5) && (
                <Button onClick={resetComplianceEmail} color="secondary">
                    Compliance Dial-Out
                </Button>
            )}
            {pin_data.length > 2 && pin_data.find((p) => p.entity_type === 4) && (
                <Button onClick={resetTranslatorEmail} color="secondary">
                    Translator Dial-Out
                </Button>
            )}
            {pin_data.length > 2 && pin_data.find((p) => p.entity_type === 3) && (
                <Button onClick={resetModeratorEmail} color="secondary">
                    Moderator Dial-Out
                </Button>
            )}
            <Editor
                editorState={editor}
                spellCheck
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName={classes.editorWrapper}
                onEditorStateChange={onEditorStateChange}
            />
        </div>
    );
}
export default SchedulingCallTemplate;
