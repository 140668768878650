import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import queryString from "query-string";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Select from "react-select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
// import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import moment from "moment";
import "moment-duration-format";
import TwilioBridgeSettings from "../components/TwilioBridgeSettings";
import ExternalBridgeSettings from "../components/ExternalBridgeSettings";
import Messaging from "../components/Messaging";
import { authContext } from "../context/auth";
import RequiresUpdateTagManager from "../components/project/utils/RequiresUpdateTagManager";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    section: {
        backgroundColor: "#add0b9",
        color: "#FFFFFF",
        padding: "8px",
        fontSize: "1.25rem",
        borderRadius: "2px",
    },
    label: {
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(0.5),
        fontSize: "1rem",
    },
    meta: {
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(0.5),
        fontSize: ".75rem",
    },
    strong: {
        color: theme.palette.text.primary,
        marginBottom: theme.spacing(0.5),
        fontSize: "1rem",
    },
    spacing: {
        marginRight: theme.spacing(1),
    },
    margin: {
        margin: theme.spacing(1),
    },
    row: {
        marginTop: theme.spacing(2),
    },
    pushRight: {
        textAlign: "right",
    },
    leftBorder: {
        paddingLeft: "12px",
        borderLeft: "2px solid #EAEAEA",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    link: {
        color: "#3c8864",
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    actions: {
        marginTop: "50px",
    },
}));
function Contact(props) {
    const pageUrl = props.location.search;
    const params = queryString.parse(pageUrl);
    const { auth } = useContext(authContext);
    const { match, history } = props;
    const classes = useStyles();
    const [twilioDisplay, setTwilio] = useState(true);
    const [externalDisplay, setExternal] = useState(false);
    const [manualDisplay, setManual] = useState(false);
    const [fields, setFields] = useState(false);
    const [isError, setIsError] = useState(false);
    const [open, setOpen] = useState(false);
    const [disable, setDisableSubmit] = useState(false);
    const [btnViewProfile, setViewProfileButton] = useState(true);
    const [clients, setClientList] = useState([]);
    const [org, setOrg] = useState({
        name: [],
    });
    const [loadingContact, setLoadingContact] = useState(true);
    const [data, setData] = useState({
        accountid: "",
        assistantname: "",
        assistantphone: "",
        birthdate: "",
        client_id: "",
        clientid: "",
        clientname: "",
        created_on: "",
        createdbyid: auth.data.id,
        createddate: "",
        department: "",
        description: "",
        email: "",
        emailbounceddate: "",
        emailbouncedreason: "",
        fax: "",
        firstname: "",
        homephone: "",
        id: "",
        include_in_org_chart__c: false,
        isdeleted: false,
        isemailbounced: false,
        lastactivitydate: "",
        lastmodifiedbyid: "",
        lastmodifieddate: "",
        lastname: "",
        lastvieweddate: "",
        leadsource: "Internal CRM",
        mailingcity: "",
        mailingcountry: "",
        mailingpostalcode: "",
        mailingstate: "",
        mailingstreet: "",
        mobilephone: "",
        name: "",
        otherphone: "",
        ownerid: "",
        phone: "",
        photourl: "",
        picture__c: "",
        referred_by__c: "",
        referred_by_email_address__c: "",
        reportstoid: "",
        salesforceid: "",
        salutation: "",
        systemmodstamp: "",
        title: "",
        conference_bridge_setting: "1",
        twilio_bridge_setting: "1",
        external_bridge_numbers: [],
        notifications: [],
        project_bridge_settings: [],
        tags: [],
        comments: [],
    });

    const pageTitle = match.params.id ? `Edit ${data.name}` : "Create New Contact";

    function handleClose() {
        setOpen(false);
    }

    function handleTagEvent(tagsArray) {
        if (typeof tagsArray === "object") {
            setData({
                ...data,
                tags: tagsArray,
            });
        }
    }

    function setBridgeDisplay(option) {
        switch (option) {
            case "1":
                setTwilio(true);
                setExternal(false);
                setManual(false);
                break;
            case "2":
                setTwilio(false);
                setExternal(true);
                setManual(false);
                break;
            case "3":
                setTwilio(false);
                setExternal(false);
                setManual(true);
                break;
            default:
                setTwilio(true);
                setExternal(false);
                setManual(false);
        }
    }

    function handleBridgeChange(event) {
        setData({
            ...data,
            conference_bridge_setting: event.target.value,
        });
        setBridgeDisplay(event.target.value);
    }

    function handleTwilioChange(event) {
        setData({
            ...data,
            twilio_bridge_setting: event.target.value,
        });
    }

    function handleExternalBridgeSettings(settingsArray) {
        if (typeof settingsArray === "object") {
            setData({
                ...data,
                external_bridge_numbers: settingsArray,
            });
        }
    }

    function handleClientNameChange(clientArray) {
        if (typeof clientArray === "object") {
            setData({
                ...data,
                client_id: clientArray.value,
                clientid: clientArray.value,
                clientname: clientArray.label,
                accountid: clientArray.account,
                conference_bridge_setting: clientArray.conference_bridge_setting,
                twilio_bridge_setting: clientArray.twilio_bridge_setting,
                external_bridge_numbers: clientArray.external_bridge_numbers,
                notifications: clientArray.notifications,
            });
            setOrg({
                name: {
                    value: clientArray.value,
                    label: clientArray.label,
                },
            });
            setFields(true);
        }
        console.log("SELECTED CLIENT: ", clientArray);
        // TODO: Remove Hardcode, Set with actual data point
        console.log(clientArray.conference_bridge_setting);
        setBridgeDisplay(clientArray.conference_bridge_setting);
    }

    function handleChange(event) {
        const { target } = event;
        const { value, name } = target;

        if (value.length > 0 || value !== "undefined") {
            setIsError(false);
        } else {
            setIsError(true);
        }
        setData({
            ...data,
            [name]: value,
        });
    }

    function handleSubmit(event) {
        event.preventDefault();
        const now = new Date().toISOString();
        const contactData = {
            id: data.id,
            accountid: data.accountid,
            assistantname: data.assistantname,
            assistantphone: data.assistantphone,
            birthdate: data.birthdate,
            client_id: data.client_id,
            clientid: data.clientid,
            clientname: data.clientname,
            created_on: now,
            createdbyid: data.createdbyid,
            createddate: now,
            department: data.department,
            description: data.description,
            email: data.email,
            emailbounceddate: data.emailbounceddate,
            emailbouncedreason: data.emailbouncedreason,
            fax: data.fax,
            firstname: data.firstname,
            homephone: data.homephone,
            include_in_org_chart__c: data.include_in_org_chart__c,
            isdeleted: data.isdeleted,
            isemailbounced: data.isemailbounced,
            lastactivitydate: data.lastactivitydate,
            lastmodifiedbyid: data.lastmodifiedbyid,
            lastmodifieddate: data.lastmodifieddate,
            lastname: data.lastname,
            lastvieweddate: data.lastvieweddate,
            leadsource: data.leadsource,
            mailingcity: data.mailingcity,
            mailingcountry: data.mailingcountry,
            mailingpostalcode: data.mailingpostalcode,
            mailingstate: data.mailingstate,
            mailingstreet: data.mailingstreet,
            mobilephone: data.mobilephone,
            name: data.name ? data.name : `${data.firstname} ${data.lastname}`,
            otherphone: data.otherphone,
            ownerid: data.ownerid,
            phone: data.phone,
            photourl: data.photourl,
            picture__c: data.picture__c,
            referred_by__c: data.referred_by__c,
            referred_by_email_address__c: data.referred_by_email_address__c,
            reportstoid: data.reportstoid,
            salesforceid: data.salesforceid,
            salutation: data.salutation,
            systemmodstamp: data.systemmodstamp,
            title: data.title,
            conference_bridge_setting: data.conference_bridge_setting,
            twilio_bridge_setting: data.twilio_bridge_setting,
            external_bridge_numbers: data.external_bridge_numbers,
            notifications: data.notifications,
            project_bridge_settings: data.project_bridge_settings,
            tags: data.tags,
            comments: data.comments,
        };
        console.log("LOCAL USESTATE DATA: ", data);
        if (
            contactData.firstname !== "" &&
            contactData.lastname !== "" &&
            contactData.email !== "" &&
            contactData.clientname !== "" &&
            contactData.client_id !== ""
        ) {
            console.log("CONTACT Submitted Data", JSON.stringify(contactData));
            setDisableSubmit(true);
            let url = "/api/contact/create";
            if (props.match.params.id) {
                url = "/api/contact/update";
                contactData.id = props.match.params.id;
                contactData.updated_on = new Date().toISOString();
            }
            axios
                .post(url, contactData, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                })
                .then((result) => {
                    if (result.status === 200) {
                        console.log(`Successful POST: ${JSON.stringify(result.data)}`);
                        // console.log("New Contact ID: ", result.data.contact.id);
                        setData({
                            ...data,
                            id: result.data.contact ? result.data.contact.id : props.match.params.id,
                        });
                        setOpen(true);
                        setDisableSubmit(false);
                        setViewProfileButton(false);
                    } else {
                        console.log(`Good POST, but something went wrong: ${JSON.stringify(contactData)}`);
                    }
                })
                .catch((ex) => {
                    console.log(`API not done yet, 404 Error: ${JSON.stringify(contactData)}`);
                    console.log(ex);
                });
        } else {
            setIsError(true);
        }
    }

    useEffect(() => {
        async function fetchContactById(id) {
            const contact = await axios.get(`/api/contact/findById?id=${id}`);
            if (contact.status === 200 && contact.data && contact.data.data) {
                if (contact.data.data[0].length === 0) return;
                const tags = contact.data.data[1].map((tag) => {
                    return { label: tag.name, value: tag.id };
                });
                const comments = contact.data.data[2].map((comment) => {
                    return { label: comment.comment, value: comment.id };
                });
                const clientContact = contact.data.data[0][0];
                const settings = contact.data.data[3] || [];
                const primary = settings.filter((item) => item.isprimary !== false);
                const secondary = settings.filter((item) => item.isprimary !== true);
                let selectedTypeID = "1";
                let selectedTwilio = "1";
                let extNumbers = [];
                // First check to see if array is empty or not
                if (primary.length > 0) {
                    const primaryData = primary[0];
                    selectedTypeID = primaryData.type_id.toString();
                    if (primaryData.data && primaryData.data.value !== undefined) {
                        selectedTwilio = primaryData.data.value;
                    }
                    if (Array.isArray(primaryData.data) && primaryData.data.length > 0) {
                        extNumbers = primaryData.data;
                    }
                }
                // If previously saved, render saved external bridge numbers
                secondary.forEach(function (s, index) {
                    if (s.type_id === 2 && Array.isArray(s.data) && s.data.length > 0) {
                        extNumbers = s.data;
                    }
                });

                setBridgeDisplay(selectedTypeID);
                console.log("Radio Bridge Setting Selected: ", selectedTypeID);
                console.log("RETURN DATA", contact.data);
                console.log("External Numbers ARRAY: ", extNumbers);

                setOrg({
                    name: {
                        value: clientContact.client_id,
                        label: clientContact.clientname,
                    },
                });
                setFields(true);

                setData({
                    ...data,
                    id: clientContact.id,
                    accountid: clientContact.accountid,
                    assistantname: clientContact.assistantname,
                    assistantphone: clientContact.assistantphone,
                    birthdate: clientContact.birthdate,
                    client_id: clientContact.client_id,
                    clientid: clientContact.clientid,
                    clientname: clientContact.clientname,
                    created_on: clientContact.created_on,
                    createdbyid: clientContact.createdbyid,
                    createddate: clientContact.createddate,
                    department: clientContact.department,
                    description: clientContact.description,
                    email: clientContact.email,
                    emailbounceddate: clientContact.emailbounceddate,
                    emailbouncedreason: clientContact.emailbouncedreason,
                    fax: clientContact.fax,
                    firstname: clientContact.firstname,
                    homephone: clientContact.homephone,
                    include_in_org_chart__c: clientContact.include_in_org_chart__c,
                    isdeleted: clientContact.isdeleted,
                    isemailbounced: clientContact.isemailbounced,
                    lastactivitydate: clientContact.lastactivitydate,
                    lastmodifiedbyid: clientContact.lastmodifiedbyid,
                    lastmodifieddate: clientContact.lastmodifieddate,
                    lastname: clientContact.lastname,
                    lastvieweddate: clientContact.lastvieweddate,
                    leadsource: clientContact.leadsource,
                    mailingcity: clientContact.mailingcity,
                    mailingcountry: clientContact.mailingcountry,
                    mailingpostalcode: clientContact.mailingpostalcode,
                    mailingstate: clientContact.mailingstate,
                    mailingstreet: clientContact.mailingstreet,
                    mobilephone: clientContact.mobilephone,
                    name: clientContact.name,
                    otherphone: clientContact.otherphone,
                    ownerid: clientContact.ownerid,
                    phone: clientContact.phone,
                    photourl: clientContact.photourl,
                    picture__c: clientContact.picture__c,
                    referred_by__c: clientContact.referred_by__c,
                    referred_by_email_address__c: clientContact.referred_by_email_address__c,
                    reportstoid: clientContact.reportstoid,
                    salesforceid: clientContact.salesforceid,
                    salutation: clientContact.salutation,
                    systemmodstamp: clientContact.systemmodstamp,
                    title: clientContact.title,
                    conference_bridge_setting: selectedTypeID,
                    twilio_bridge_setting: selectedTwilio,
                    external_bridge_numbers: extNumbers,
                    notifications: clientContact.notifications,
                    project_bridge_settings: clientContact.project_bridge_settings,
                    tags,
                    comments,
                });
            }
            setLoadingContact(false);
        }
        if (props.match.params.id) {
            fetchContactById(props.match.params.id);
        }
    }, []);

    useEffect(() => {
        async function fetchClientList() {
            const clientObject = await axios.get("/api/client/findAll");
            console.log("Client API Call", clientObject);
            const clientList = clientObject.data.data.map((item) => {
                return {
                    value: item.id,
                    label: item.name,
                    account: item.salesforceid,
                    conference_bridge_setting: item.conference_setting_id ? item.conference_setting_id.toString() : "1",
                    twilio_bridge_setting:
                        item.conference_setting_id === 1 &&
                        item.conference_bridge &&
                        item.conference_bridge.value !== "undefined"
                            ? item.conference_bridge.value
                            : "1",
                    external_bridge_numbers:
                        item.conference_setting_id === 2 &&
                        Array.isArray(item.conference_bridge) &&
                        item.conference_bridge.length > 0
                            ? item.conference_bridge
                            : [],
                };
            });
            console.log("CLIENT LIST FETCHED: ", clientList);
            setClientList(clientList);
            // Check URL Param for Company Org ID
            if (params.id) {
                console.log("PARAM ID: ", params.id);
                const defaultOrg = clientList.filter((item) => item.value == params.id);
                console.log("MATCHING ORG ID FROM PARAMS: ", defaultOrg[0]);
                setData({
                    ...data,
                    client_id: defaultOrg[0].value,
                    clientid: defaultOrg[0].value,
                    clientname: defaultOrg[0].label,
                    accountid: defaultOrg[0].account,
                    conference_bridge_setting: defaultOrg[0].conference_bridge_setting,
                    twilio_bridge_setting: defaultOrg[0].twilio_bridge_setting,
                    external_bridge_numbers: defaultOrg[0].external_bridge_numbers,
                });
                setOrg({
                    name: {
                        value: defaultOrg[0].value,
                        label: defaultOrg[0].label,
                    },
                });
                setBridgeDisplay(defaultOrg[0].conference_bridge_setting);
                setFields(true);
            }
        }
        fetchClientList();
    }, []);

    function navigateToView() {
        if (props.match.params.id) {
            history.push(`/contact/view/${props.match.params.id}`);
        } else {
            history.push(`/contact/view/${data.id}`);
        }
    }

    function formatDateString(str) {
        return moment(str).format("MMM. Do, YYYY, h:mma");
    }

    return (
        <form onSubmit={handleSubmit}>
            <p className={classes.meta}>
                {data.createdbyid && (
                    <span className={classes.spacing}>
                        Created By: <strong>{data.createdbyid}</strong>
                    </span>
                )}
                {data.createddate && (
                    <span className={classes.spacing}>
                        Created On: <strong>{formatDateString(data.createddate)}</strong>
                    </span>
                )}
                {data.lastmodifiedbyid && (
                    <span className={classes.spacing}>
                        Last Modified By: <strong>{data.lastmodifiedbyid}</strong>
                    </span>
                )}
                {data.lastmodifieddate && (
                    <span className={classes.spacing}>
                        Last Modified On: <strong>{formatDateString(data.lastmodifieddate)}</strong>
                    </span>
                )}
                {data.lastvieweddate && (
                    <span className={classes.spacing}>
                        Last Viewed: <strong>{formatDateString(data.lastvieweddate)}</strong>
                    </span>
                )}
                {data.lastactivitydate && (
                    <span className={classes.spacing}>
                        Last Activity: <strong>{formatDateString(data.lastactivitydate)}</strong>
                    </span>
                )}
            </p>
            <h1>{pageTitle}</h1>
            <Grid container spacing={3}>
                <Grid item sm={12} xs={12}>
                    <div className={classes.label}>Required Client Organization *</div>
                    <Select name="clientlist" onChange={handleClientNameChange} options={clients} value={org.name} />
                </Grid>
            </Grid>
            {fields && (
                <Grid container spacing={3}>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            autoComplete="firstname"
                            fullWidth
                            autoFocus
                            error={isError}
                            id="firstname"
                            name="firstname"
                            label="First Name"
                            value={data.firstname}
                            onChange={handleChange}
                            margin="normal"
                            required
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            autoComplete="lastname"
                            fullWidth
                            error={isError}
                            id="lastname"
                            name="lastname"
                            label="Last Name"
                            value={data.lastname}
                            onChange={handleChange}
                            margin="normal"
                            required
                        />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <TextField
                            autoComplete="email"
                            fullWidth
                            error={isError}
                            id="email"
                            label="Email"
                            margin="normal"
                            name="email"
                            onChange={handleChange}
                            value={data.email}
                            required
                        />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <TextField
                            autoComplete="salutation"
                            fullWidth
                            id="salutation"
                            name="salutation"
                            label="Salutation"
                            value={data.salutation}
                            onChange={handleChange}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item sm={8} xs={12}>
                        <TextField
                            autoComplete="name"
                            fullWidth
                            id="name"
                            label="Name"
                            margin="normal"
                            name="name"
                            onChange={handleChange}
                            value={data.name}
                        />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <TextField
                            autoComplete="title"
                            fullWidth
                            id="title"
                            label="Title"
                            margin="normal"
                            name="title"
                            onChange={handleChange}
                            value={data.title}
                        />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <TextField
                            autoComplete="phone"
                            fullWidth
                            id="phone"
                            label="Phone"
                            margin="normal"
                            name="phone"
                            onChange={handleChange}
                            value={data.phone}
                        />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <TextField
                            autoComplete="mobilephone"
                            fullWidth
                            id="mobilephone"
                            label="Mobile Phone"
                            margin="normal"
                            name="mobilephone"
                            onChange={handleChange}
                            value={data.mobilephone}
                        />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <TextField
                            autoComplete="homephone"
                            fullWidth
                            id="homephone"
                            label="Home Phone"
                            margin="normal"
                            name="homephone"
                            onChange={handleChange}
                            value={data.homephone}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            autoComplete="otherphone"
                            fullWidth
                            id="otherphone"
                            label="Other Phone"
                            margin="normal"
                            name="otherphone"
                            onChange={handleChange}
                            value={data.otherphone}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            autoComplete="fax"
                            fullWidth
                            id="fax"
                            label="Fax"
                            margin="normal"
                            name="fax"
                            onChange={handleChange}
                            value={data.fax}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            autoComplete="assistantname"
                            fullWidth
                            id="assistantname"
                            label="Assistant Name"
                            margin="normal"
                            name="assistantname"
                            onChange={handleChange}
                            value={data.assistantname}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            autoComplete="assistantphone"
                            fullWidth
                            id="assistantphone"
                            label="Assistant Phone"
                            margin="normal"
                            name="assistantphone"
                            onChange={handleChange}
                            value={data.assistantphone}
                        />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <TextField
                            autoComplete="department"
                            fullWidth
                            id="department"
                            label="Department"
                            margin="normal"
                            name="department"
                            onChange={handleChange}
                            value={data.department}
                        />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <TextField
                            autoComplete="description"
                            fullWidth
                            id="description"
                            label="Description"
                            margin="normal"
                            multiline
                            name="description"
                            onChange={handleChange}
                            rows="6"
                            value={data.description}
                        />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <TextField
                            autoComplete="photourl"
                            fullWidth
                            id="photourl"
                            label="Photo URL"
                            margin="normal"
                            name="photourl"
                            onChange={handleChange}
                            value={data.photourl}
                            disabled
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            autoComplete="referred_by__c"
                            fullWidth
                            id="referred_by__c"
                            label="Referred By"
                            margin="normal"
                            name="referred_by__c"
                            onChange={handleChange}
                            value={data.referred_by__c}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            autoComplete="referred_by_email_address__c"
                            fullWidth
                            id="referred_by_email_address__c"
                            label="Referred By Email"
                            margin="normal"
                            name="referred_by_email_address__c"
                            onChange={handleChange}
                            value={data.referred_by_email_address__c}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            autoComplete="leadsource"
                            fullWidth
                            id="leadsource"
                            label="Lead Source"
                            margin="normal"
                            name="leadsource"
                            onChange={handleChange}
                            value={data.leadsource}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            autoComplete="picture__c"
                            fullWidth
                            id="picture__c"
                            label="Picture"
                            margin="normal"
                            name="picture__c"
                            onChange={handleChange}
                            value={data.picture__c}
                        />
                    </Grid>
                    <Grid item sm={7} xs={12}>
                        <TextField
                            autoComplete="mailingstreet"
                            fullWidth
                            id="mailingstreet"
                            label="Mailing Street"
                            margin="normal"
                            name="mailingstreet"
                            onChange={handleChange}
                            value={data.mailingstreet}
                        />
                    </Grid>
                    <Grid item sm={5} xs={12}>
                        <TextField
                            autoComplete="mailingcity"
                            fullWidth
                            id="mailingcity"
                            label="Mailing City"
                            margin="normal"
                            name="mailingcity"
                            onChange={handleChange}
                            value={data.mailingcity}
                        />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <TextField
                            autoComplete="mailingstate"
                            fullWidth
                            id="mailingstate"
                            label="Mailing State"
                            margin="normal"
                            name="mailingstate"
                            onChange={handleChange}
                            value={data.mailingstate}
                        />
                    </Grid>
                    <Grid item sm={5} xs={12}>
                        <TextField
                            autoComplete="mailingcountry"
                            fullWidth
                            id="mailingcountry"
                            label="Mailing Country"
                            margin="normal"
                            name="mailingcountry"
                            onChange={handleChange}
                            value={data.mailingcountry}
                        />
                    </Grid>
                    <Grid item sm={3} xs={12}>
                        <TextField
                            autoComplete="mailingpostalcode"
                            fullWidth
                            id="mailingpostalcode"
                            label="Mailing Postal Code"
                            margin="normal"
                            name="mailingpostalcode"
                            onChange={handleChange}
                            value={data.mailingpostalcode}
                        />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        {!loadingContact && (
                            <RequiresUpdateTagManager changeEvent={handleTagEvent} incomingTags={data.tags} />
                        )}
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <h2 className={classes.section}>Conference Bridge Settings</h2>
                    </Grid>
                    <Grid item sm={3} xs={12}>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <RadioGroup
                                name="conference_bridge_setting"
                                value={data.conference_bridge_setting}
                                onChange={handleBridgeChange}
                            >
                                <FormControlLabel value="1" control={<Radio />} label="Twilio" labelPlacement="start" />
                                <FormControlLabel
                                    value="2"
                                    control={<Radio />}
                                    label="External"
                                    labelPlacement="start"
                                />
                                <FormControlLabel value="3" control={<Radio />} label="Manual" labelPlacement="start" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item sm={9} xs={12} className={classes.leftBorder}>
                        {twilioDisplay && (
                            <Grid item sm={12} xs={12}>
                                <TwilioBridgeSettings
                                    changeEvent={handleTwilioChange}
                                    twilio_bridge_setting={data.twilio_bridge_setting}
                                />
                            </Grid>
                        )}
                        {externalDisplay && (
                            <Grid item sm={12} xs={12}>
                                <ExternalBridgeSettings
                                    changeEvent={handleExternalBridgeSettings}
                                    external_bridge_numbers={data.external_bridge_numbers}
                                />
                            </Grid>
                        )}
                        {manualDisplay && (
                            <Grid item sm={12} xs={12}>
                                <TextField
                                    fullWidth
                                    id="expert_phone"
                                    label="Manually Send Expert's Phone Number"
                                    margin="normal"
                                    disabled
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Grid container spacing={3} className={classes.actions}>
                        <Grid item sm={9} xs={12}>
                            <Button
                                disabled={disable}
                                className={useStyles.submit}
                                color="primary"
                                fullWidth
                                onClick={handleSubmit}
                                variant="contained"
                            >
                                Save/Edit Contact
                            </Button>
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <Button className={useStyles.submit} fullWidth onClick={navigateToView} variant="contained">
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        <Messaging user={auth.data} type="Contact" />
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {data.firstname} {data.lastname} has successfully been updated/created. However, please
                            allow 10 minutes to sync with Salesforce.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" autoFocus>
                            Close
                        </Button>
                        <Link
                            disabled={btnViewProfile}
                            to={
                                props.match.params.id
                                    ? `/contact/view/${props.match.params.id}`
                                    : `/contact/view/${data.id}`
                            }
                            className={classes.link}
                        >
                            View Profile
                        </Link>
                        <Link
                            to={
                                props.match.params.id
                                    ? `/project/create?id=${props.match.params.id}`
                                    : `/project/create?id=${data.id}`
                            }
                            className={classes.link}
                        >
                            Create a Project
                        </Link>
                    </DialogActions>
                </Dialog>
            </div>
        </form>
    );
}

Contact.propTypes = {
    match: PropTypes.object,
};
Contact.defaultProps = {
    match: null,
};

export default Contact;
