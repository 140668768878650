import * as React from "react";
import axios from "axios";

// mui
import Autocomplete from "@mui/material/Autocomplete";

// MUI - style imports
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Box, Grid, TextField, Typography } from "@mui/material";

// ======
// STYLES
// ======

const addTagStyles = css`
    background-color: #dec9ff;
`;

// ===========
// MUI HELPERS
// ===========

//     // HELPER FUNCS

//     // HANDLE FUNCS
//     async function handleCreate(tagArr) {
//         console.log(tagArr, " :: tagArr");
//         if (tagArr.length > selectedTags.length) {
//             console.log("====== added ======");
//         } else {
//             console.log("====== removed ======");
//         }
//         setSelectedTags(tagArr);
//     }

export default function LimitTags(props) {
    // PROPS
    const { projectTags, project_id } = props;

    // STATE
    const [value, setValue] = React.useState([...projectTags]);
    const [inputValue, setInputValue] = React.useState("");
    const [options, setOptions] = React.useState([]);

    // HELPERS
    // function renderHighlight(tagName, query) {
    //     return tagName.replace(new RegExp(query, "gi"), (match) => `<mark>${match}</mark>`);
    // }

    async function loadTags() {
        // console.log(inputValue, " :: inputValue");
        if (!inputValue || inputValue.length < 3) return;

        await axios
            .get(`/api/project/now/findTags?searchText=${inputValue}`)
            .then((response) => {
                const [items, [{ does_tag_already_exist }]] = response?.data?.data;
                // console.log(items, " :: items");
                if (items) {
                    const newOptions = items.map((item) => {
                        return {
                            id: item.id,
                            name: item.name,
                        };
                    });
                    // console.log("TYPEAHEAD RESPONSE: ", newOptions);
                    if (does_tag_already_exist) {
                        setOptions([...value, ...newOptions]);
                    } else {
                        setOptions([...value, ...newOptions, { id: 0, name: `${inputValue}` }]);
                    }
                }
            })
            .catch((e) => {
                console.log(e, " :: e in loadTags");
            });
    }

    async function removeTagFromProject(project_id, tag) {
        await axios
            .post("/api/project/now/removeTagFromProject", { project_id, tag })
            .then((r) => {
                console.log(r, " :: r in removeTagFromProject");
            })
            .catch((e) => {
                console.log(e, " :: e in removeTagFromProject");
            });
    }

    async function addTagToProject(project_id, tag) {
        await axios
            .post("/api/project/now/addTagToProject", { project_id, tag })
            .then((r) => {
                console.log(r, " :: r in addTagToProject");
            })
            .catch((e) => {
                console.log(e, " :: e in addTagToProject");
            });
    }

    async function createTagAndAddToProject(project_id, tagName) {
        await axios
            .post("/api/project/now/createTagAndAddToProject", { project_id, tagName })
            .then((r) => {
                console.log(r, " :: r in createTagAndAddToProject");
            })
            .catch((e) => {
                console.log(e, " :: e in createTagAndAddToProject");
            });
    }

    // PAGE LOAD
    React.useEffect(() => {
        // console.log("====== in useEffect ======");
        loadTags();
    }, [value, inputValue]);

    return (
        <Autocomplete
            multiple
            id="multiple-limit-tags"
            options={options}
            getOptionLabel={(option) => option.name}
            filterOptions={(x) => x}
            filterSelectedOptions
            includeInputInList
            autoComplete
            disableClearable
            value={value}
            clearOnBlur={false}
            inputValue={inputValue}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            noOptionsText="Enter at least 3 characters to search for tags."
            onChange={(event, newArr, actionStatus, actionItem) => {
                // console.log(newArr, " :: newValue onChange");
                // console.log(actionStatus, " :: action");
                // console.log(actionItem, " :: actionItem");

                // removeOption | selectOption
                const addOrRemove = actionStatus === "removeOption" ? "remove" : "add";

                // if add && id = 0 then it's a new tag
                if (addOrRemove === "add" && actionItem.option.id === 0) {
                    // const tagName = actionItem.option.name.slice(actionItem.option.name.indexOf(":") + 1).trim();

                    if (
                        confirm(
                            `Are you sure you want to create a new tag and add to the project named: ${actionItem.option.name}`
                        )
                    ) {
                        createTagAndAddToProject(project_id, actionItem.option.name);
                        setValue(newArr);
                    }
                    // otherwise its an existing tag needing to be added to project
                } else if (addOrRemove === "add") {
                    if (confirm(`Are you sure you want to add a new tag to the project: ${actionItem.option.name}`)) {
                        addTagToProject(project_id, actionItem.option);
                        setValue(newArr);
                    }
                    // here we're removing from the project
                } else {
                    if (confirm(`Are you sure you want to remove tag from the project: ${actionItem.option.name}`)) {
                        removeTagFromProject(project_id, actionItem.option);
                        setValue(newArr);
                    }
                }
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => <TextField {...params} label="Add or Remove Tags:" placeholder="Search Tags..." />}
            renderOption={(props, option) => {
                // const innerHtml =
                //     option.id === 0 ? `Create new tag: ${option.name}` : renderHighlight(option.name, inputValue);
                return (
                    <li
                        {...props}
                        key={props.id}
                        css={option.id === 0 ? addTagStyles : null}
                        // dangerouslySetInnerHTML={{ __html: innerHtml }}
                    >
                        {option.id === 0 ? `Create new tag: ${option.name}` : option.name}
                    </li>
                );
            }}
        />
    );
}
