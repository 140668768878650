import React, { useState, useEffect, useCallback, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import Grid from "@mui/material/Grid";
import axios from "axios";
import Select from "react-select";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TablePagination, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Backdrop from "@mui/material/Backdrop";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import moment from "moment";
import "moment-duration-format";
import PageTitle from "../components/PageTitle";
import { authContext } from "../context/auth";
import TermsFollowupEmail from "../components/TermsFollowupEmail";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import MuiSelect from "@mui/material/Select";
import Card from "@mui/material/Card";
import MUIDataTable from "mui-datatables";

const useStyles = makeStyles((theme) => ({
    cta: {
        border: "1px solid #ccc",
    },
    root: {
        flexGrow: 1,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    table: {
        width: "100%",
        minWidth: 750,
    },
    action: {
        float: "right",
        marginLeft: "5px",
    },
    title: {
        color: "#3c8864",
        textDecoration: "none",
        fontSize: ".75rem",
        fontWeight: "bold",
    },
    link: {
        color: "#3c8864",
        textDecoration: "none",
        fontSize: ".85rem",
    },
    timestamp: {
        fontSize: ".65rem",
        color: "#555",
        textAlign: "left",
        marginBottom: "5px",
    },
    meta: {
        fontSize: ".75rem",
        color: "#333",
        textAlign: "left",
    },
    text: {
        fontSize: ".75rem",
        color: "#333",
        textAlign: "left",
    },
    suggestion: {
        backgroundColor: "#fcfbf2",
        border: "1px solid #f7f6de",
        borderRadius: "3px",
        padding: "12px",
        fontSize: ".75rem",
        color: "#666",
        textAlign: "left",
    },
    form: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "fit-content",
    },
    typography: {
        padding: theme.spacing(2),
    },
    nameButton: {
        color: "#523197",
        padding: "0",
        textDecoration: "none",
        fontSize: "1.75rem",
        fontWeight: "normal",
        textTransform: "none",
        minWidth: "auto",
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    datestamp: {
        color: "#666",
        fontSize: ".65rem",
    },
    emailBorder: {
        border: "1px solid #e5e5e5",
        backgroundColor: "#f8f8f8",
        borderRadius: "2px",
        padding: "12px",
        fontSize: ".75rem",
    },
    emailOutline: {
        border: "1px solid #eaeaea",
        backgroundColor: "#ffffff",
        borderRadius: "2px",
        padding: "8px",
    },
    editorWrapper: {
        border: "1px solid #EAEAEA",
        borderRadius: "2px",
        padding: "12px",
    },
    emailField: {
        marginBottom: "8px",
    },
    iconSmall: {
        fontSize: ".95rem",
    },
    success: {
        color: "green",
    },
    opened: {
        color: "green",
    },
    pending: {
        color: theme.palette.secondary.main,
    },
    failure: {
        color: "red",
    },
    buttonSuccess: {
        backgroundColor: green[500],
        "&:hover": {
            backgroundColor: green[700],
        },
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: 22,
    },
    tablesort: {
        textDecoration: "underline",
    },
    formControl: {
        minWidth: 180,
    },
    filterBtn: {
        marginTop: 10,
    },
    card: {
        width: "fit-content",
        padding: "1em",
        marginTop: "1em",
        marginBottom: "1em",
    },
    receiptIcon: {
        marginRight: ".75rem",
    },
}));

function ProjectEmails(props) {
    const { match, history } = props;
    const timer = useRef();
    const classes = useStyles();
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("created_on");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(75);
    const { auth } = useContext(authContext);
    const [value, setValue] = useState(0);
    const [emailCopy, setEmailCopy] = useState(false);
    const [followup, setFollowup] = useState(false);
    const [openLoader, setOpenLoader] = useState(true);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [filteredEmails, setFilteredEmails] = useState([]);
    const [startDateFilter, setStartDateFilter] = useState(null);
    const [endDateFilter, setEndDateFilter] = useState(null);
    const [statusFilter, setStatusFilter] = useState("all");
    const [emailTemplateFilter, setEmailTemplateFilter] = useState("onboard_screener");
    const [delivered, setDelivered] = useState([]);
    const [bounced, setBounced] = useState([]);
    const [dropped, setDropped] = useState([]);
    const [deferred, setDeferred] = useState([]);
    const [alternates, setAlternateEmails] = useState([]);
    const [defaultEmail, setDefaultEmail] = useState({});
    const [data, setData] = useState({
        id: match.params.id,
        isdeleted: false,
        title: "",
        description: "",
        email_copy: "",
        project_not_received_by_email: true,
        client_id: "",
        client_name: "",
        primary_client_contact: "",
        additional_client_contacts: [],
        expert_ids: [],
        primary_project_manager: [],
        additional_project_managers: [],
        status: "1",
        type: "",
        priority: "4",
        case_code: "",
        calls_planned: "",
        industry: "",
        conference_bridge_setting: "1",
        twilio_bridge_setting: "1",
        external_bridge_numbers: [],
        notifications: [],
        specialty: [],
        tags: [],
        comments: [],
        responses: [],
        scheduled_calls: [],
        completed_calls: [],
        experts: [],
    });
    const [version, setEmailVersion] = useState({
        id: "",
        template_name: "",
        project_id: "",
        expert_id: "",
        user_id: "",
        mail_subject: "",
        mail_body: "",
        mail_to_name: "",
        mail_to: "",
        mail_from: "",
        mail_cc: "",
        mail_bcc: "",
        created_on: "",
        is_sent: false,
        error_message: "",
        is_delivered: false,
        is_opened: false,
        is_dropped: false,
        is_deferred: false,
        is_bounced: false,
    });
    const [template, setEmailTemplate] = useState({
        expert_id: "",
        expert_fname: "",
        expert_lname: "",
        expert_email: "",
        project_id: "",
        user_id: "",
        user_fname: "",
        user_lname: "",
        from_email: "",
        email_subject: "",
        email_body: "",
        email_template: "",
    });

    const headCells = [
        {
            id: "fname",
            numeric: false,
            disablePadding: false,
            label: "Recipient",
        },
        {
            id: "created_on",
            numeric: false,
            disablePadding: true,
            label: "Sent on",
        },
        {
            id: "mail_from",
            numeric: false,
            disablePadding: false,
            label: "Sent by",
        },
        {
            id: "template_name",
            numeric: false,
            disablePadding: false,
            label: "Preview",
        },
        {
            id: "is_opened",
            numeric: false,
            disablePadding: false,
            label: "Status",
        },
    ];

    const columns = [
        {
            name: "expertName",
            label: "Recipient",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { expert_id, expertName, mail_to, is_delivered, is_opened } = filteredEmails?.[dataIndex];

                    return (
                        <Box display="flex" flexDirection="row" alignItems="center" className={classes.meta}>
                            <div className={classes.receiptIcon}>
                                {is_delivered && is_opened ? (
                                    <CheckCircleOutlineIcon className={classes.success} />
                                ) : (
                                    <CheckCircleOutlineIcon className={classes.pending} />
                                )}
                            </div>
                            <div>
                                {expert_id ? (
                                    <Link className={classes.link} to={`/expert/view/${expert_id}`}>
                                        {expertName}
                                    </Link>
                                ) : (
                                    <span>{expertName}</span>
                                )}
                                <br />
                                {mail_to}
                            </div>
                        </Box>
                    );
                },
            },
        },
        {
            name: "created_on",
            label: "Sent on",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { created_on } = filteredEmails?.[dataIndex];

                    return <p className={classes.timestamp}>{formatDateString(created_on)}</p>;
                },
            },
        },
        {
            name: "mail_from",
            label: "Sent by",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { mail_from } = filteredEmails?.[dataIndex];

                    return <div className={classes.meta}>{mail_from}</div>;
                },
            },
        },
        {
            name: "template_name",
            label: "Preview",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const expertEmail = filteredEmails?.[dataIndex];
                    const { template_name } = expertEmail;

                    return (
                        <div className={classes.meta}>
                            <Button
                                className={classes.cta}
                                onClick={() => dialogEmailVersion(expertEmail)}
                                color="inherit"
                            >
                                Send {template_name}
                            </Button>
                        </div>
                    );
                },
            },
        },
        {
            name: "is_opened",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { is_delivered, is_opened, created_on } = filteredEmails?.[dataIndex];

                    return (
                        <div className={classes.meta}>
                            {is_delivered && is_opened ? (
                                <strong className={classes.success}>OPENED</strong>
                            ) : (
                                <strong className={classes.pending}>UNKNOWN...</strong>
                            )}{" "}
                            <br />
                            <em>{dateTimeDifference(created_on)}</em>
                        </div>
                    );
                },
            },
        },
    ];

    const iconColumnStyle = {
        width: "2%",
        paddingLeft: "0",
    };
    const dateColumnStyle = {
        width: "15%",
        paddingLeft: "0",
    };
    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
    });

    function formatDateString(str) {
        return moment(str).format("dddd, MMMM. Do, YYYY, h:mma");
    }
    function dateTimeDifference(dt1) {
        const now = moment();
        const then = moment(dt1);
        const ms = moment(now, "DD/MM/YYYY HH:mm:ss").diff(moment(then, "DD/MM/YYYY HH:mm:ss"));
        const d = moment.duration(ms);
        return `${d.days()} days, ${d.hours()} hours, ${d.minutes()} minutes ago`;
    }

    function handleTabChange(event, newValue) {
        setValue(newValue);
        setFilteredEmails(generateEmailType(newValue));
        resetFilterInputVals();
    }

    const resetFilterInputVals = () => {
        setStatusFilter("all");
        setStartDateFilter(null);
        setEndDateFilter(null);
        // setEmailTemplateFilter("all");
    };

    const handleClose = () => {
        setEmailCopy(false);
        setFollowup(false);
    };

    const handleStatusDropdown = (event) => {
        setStatusFilter(event.target.value);
    };

    const handleEmailTemplateDropdown = (event) => {
        setEmailTemplateFilter(event.target.value);
        fetchEmailsById(match.params.id, event.target.value);
    };

    const handleStartDateChange = (date) => {
        setStartDateFilter(date);
    };

    const handleEndDateChange = (date) => {
        setEndDateFilter(date);
    };

    const filterDates = (filteredArray) => {
        if (endDateFilter === null && startDateFilter === null) {
            return filteredArray;
        } else if (endDateFilter === null && startDateFilter) {
            const startDate = moment(startDateFilter);
            return filteredArray.filter((email) => {
                const createdOnDate = moment(email.created_on);
                return createdOnDate.isSameOrAfter(startDate);
            });
        } else if (endDateFilter && startDateFilter === null) {
            const endDate = moment(endDateFilter);
            return filteredArray.filter((email) => {
                const createdOnDate = moment(email.created_on);
                return createdOnDate.isSameOrBefore(endDate);
            });
        } else if (endDateFilter && startDateFilter) {
            const startDate = moment(startDateFilter);
            const endDate = moment(endDateFilter);
            return filteredArray.filter((email) => {
                const createdOnDate = moment(email.created_on);
                if (createdOnDate.isSameOrAfter(startDate) && createdOnDate.isSameOrBefore(endDate)) {
                    return email;
                }
            });
        } else {
            return filteredArray;
        }
    };

    const filterStatuses = () => {
        const typeOfEmail = generateEmailType(value);
        if (statusFilter === "all") {
            return typeOfEmail;
        } else if (statusFilter === "opened") {
            return typeOfEmail.filter((email) => {
                if (email.is_delivered && email.is_opened) {
                    return email;
                }
            });
        } else if (statusFilter === "unknown") {
            return typeOfEmail.filter((email) => {
                if (!email.is_delivered || !email.is_opened) {
                    return email;
                }
            });
        }
    };

    const filterTemplateTypes = (filteredArray) => {
        const filterTemplates = (emailTemplate) => {
            return filteredArray.filter((email) => {
                return email.template_name === emailTemplate;
            });
        };

        if (emailTemplateFilter === "onboard_screener") {
            return filteredArray;
        } else {
            return filterTemplates(emailTemplateFilter);
        }
    };

    const handleSubmitFilters = () => {
        const typeOfEmail = generateEmailType(value);
        let filteringArray = typeOfEmail;
        filteringArray = filterStatuses();
        filteringArray = filterTemplateTypes(filteringArray);
        filteringArray = filterDates(filteringArray);
        setFilteredEmails(filteringArray);
    };

    const handleResetFilters = () => {
        resetEmailFilters(generateEmailType(value));
        resetFilterInputVals();
    };

    const renderFilters = () => {
        return (
            <>
                <Card className={classes.card} variant="outlined">
                    <Box display="flex" flexDirection="row" flexWrap="wrap">
                        <Box p={0.5}>
                            <FormControl className={classes.formControl}>
                                <InputLabel>Status Type</InputLabel>
                                <MuiSelect value={statusFilter} onChange={handleStatusDropdown}>
                                    <MenuItem value={"all"}>All</MenuItem>
                                    <MenuItem value={"opened"}>Opened</MenuItem>
                                    <MenuItem value={"unknown"}>Unknown</MenuItem>
                                </MuiSelect>
                            </FormControl>
                        </Box>
                        <Box p={0.5}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    label="Start Date"
                                    format="MM/dd/yyyy"
                                    value={startDateFilter}
                                    onChange={handleStartDateChange}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Box>
                        <Box p={0.5}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    label="End Date"
                                    format="MM/dd/yyyy"
                                    value={endDateFilter}
                                    onChange={handleEndDateChange}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Box>
                        <Box p={1}>
                            <Button
                                className={classes.filterBtn}
                                variant="contained"
                                color="primary"
                                onClick={handleSubmitFilters}
                            >
                                Filter
                            </Button>
                        </Box>
                        <Box p={1}>
                            <Button
                                className={classes.filterBtn}
                                variant="contained"
                                color="primary"
                                onClick={handleResetFilters}
                            >
                                Reset
                            </Button>
                        </Box>
                    </Box>
                </Card>
            </>
        );
    };

    const generateEmailType = (emailIndexValue) => {
        if (emailIndexValue === 0) {
            return delivered;
        } else if (emailIndexValue === 1) {
            return deferred;
        } else if (emailIndexValue === 2) {
            return dropped;
        } else if (emailIndexValue === 3) {
            return bounced;
        }
    };

    const resetEmailFilters = (emailType) => {
        if (emailType === delivered) {
            setFilteredEmails(delivered);
        } else if (emailType === deferred) {
            setFilteredEmails(deferred);
        } else if (emailType === dropped) {
            setFilteredEmails(dropped);
        } else if (emailType === bounced) {
            setFilteredEmails(bounced);
        }
    };

    const renderTabPanel = () => {
        return (
            <TabPanel value={value} index={value}>
                <Typography className={classes.suggestion}>
                    When an email has been accepted at the receiving server, the delivered event fires. While this is
                    considered a successful send, this event does not guarantee that the email was actually placed in
                    the recipient’s inbox.
                    <br />
                    <strong>Suggested Actions: </strong>
                    <br />
                    1- If the email has been <strong className={classes.success}>OPENED</strong> (Success!) and the TNC
                    has not been signed within 2 hours, consider sending a follow-up email or calling them.
                    <br />
                    2- If the email has NOT been opened, even though delivered, it may have been deleted by the
                    recipient or never hit their inbox, consider re-sending the email.
                </Typography>
                <MUIDataTable
                    columns={columns}
                    data={filteredEmails}
                    options={{ rowsPerPage: 75, rowsPerPageOptions: [10, 25, 50, 75, 100] }}
                />
                {/* <TablePagination
                    rowsPerPageOptions={[20, 50, 75, 100]}
                    component="div"
                    count={filteredEmails.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        "aria-label": "previous page",
                    }}
                    nextIconButtonProps={{
                        "aria-label": "next page",
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
                {/* <Table className={classes.table} size="small">
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={filteredEmails.length}
                    />
                    <TableBody>
                        {stableSort(filteredEmails, getSorting(order, orderBy))
                            .map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell align="left" style={iconColumnStyle}>
                                        {row.is_delivered && row.is_opened ? (
                                            <CheckCircleOutlineIcon className={classes.success} />
                                        ) : (
                                            <CheckCircleOutlineIcon className={classes.pending} />
                                        )}
                                    </TableCell>
                                    <TableCell align="left" className={classes.meta}>
                                        {row.expert_id ? (
                                            <Link className={classes.link} to={`/expert/view/${row.expert_id}`}>
                                                {row.fname} {row.lname}
                                            </Link>
                                        ) : (
                                            <span>
                                                {row.fname} {row.lname}
                                            </span>
                                        )}
                                        <br />
                                        {row.mail_to}
                                    </TableCell>
                                    <TableCell align="left" style={dateColumnStyle}>
                                        <p className={classes.timestamp}>{formatDateString(row.created_on)}</p>
                                    </TableCell>
                                    <TableCell align="left" className={classes.meta}>
                                        {row.mail_from}
                                    </TableCell>

                                    <TableCell align="left" className={classes.meta}>
                                        <Button
                                            className={classes.cta}
                                            onClick={() => dialogEmailVersion(row)}
                                            color="inherit"
                                        >
                                            Send {row.template_name}
                                        </Button>
                                    </TableCell>
                                    <TableCell align="left" className={classes.meta}>
                                        {row.is_delivered && row.is_opened ? (
                                            <strong className={classes.success}>OPENED</strong>
                                        ) : (
                                            <strong className={classes.pending}>UNKNOWN...</strong>
                                        )}{" "}
                                        <br />
                                        <em>{dateTimeDifference(row.created_on)}</em>
                                    </TableCell>
                                </TableRow>
                            ))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                    </TableBody>
                </Table> */}
                {/* <TablePagination
                    rowsPerPageOptions={[20, 50, 75, 100]}
                    component="div"
                    count={delivered.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        "aria-label": "previous page",
                    }}
                    nextIconButtonProps={{
                        "aria-label": "next page",
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
            </TabPanel>
        );
    };

    // function desc(a, b, oBy) {
    //     if (b[oBy] < a[oBy]) {
    //         return -1;
    //     }
    //     if (b[oBy] > a[oBy]) {
    //         return 1;
    //     }
    //     return 0;
    // }

    // function stableSort(array, cmp) {
    //     const stabilizedThis = array.map((el, index) => [el, index]);
    //     stabilizedThis.sort((a, b) => {
    //         const tOrder = cmp(a[0], b[0]);
    //         if (order !== 0) return tOrder;
    //         return a[1] - b[1];
    //     });
    //     return stabilizedThis.map((el) => el[0]);
    // }

    // function getSorting(o, oby) {
    //     return o === "desc" ? (a, b) => desc(a, b, oby) : (a, b) => -desc(a, b, oby);
    // }
    // function EnhancedTableHead(props) {
    //     const createSortHandler = (property) => (event) => {
    //         props.onRequestSort(event, property);
    //     };
    //     return (
    //         <TableHead>
    //             <TableRow>
    //                 <TableCell></TableCell>
    //                 {headCells.map((headCell) => (
    //                     <TableCell
    //                         key={headCell.id}
    //                         align={headCell.numeric ? "right" : "left"}
    //                         padding={headCell.disablePadding ? "none" : "normal"}
    //                         sortDirection={props.orderBy === headCell.id ? props.order : false}
    //                     >
    //                         <TableSortLabel
    //                             className={classes.tablesort}
    //                             active={props.orderBy === headCell.id}
    //                             direction={props.order}
    //                             onClick={createSortHandler(headCell.id)}
    //                         >
    //                             {headCell.label}
    //                             {props.orderBy === headCell.id ? (
    //                                 <span className={classes.visuallyHidden}>
    //                                     {props.order === "desc" ? "sorted descending" : "sorted ascending"}
    //                                 </span>
    //                             ) : null}
    //                         </TableSortLabel>
    //                     </TableCell>
    //                 ))}
    //                 {/* <TableCell>Since sent</TableCell> */}
    //             </TableRow>
    //         </TableHead>
    //     );
    // }

    // const handleRequestSort = (event, property) => {
    //     const isDesc = orderBy === property && order === "desc";
    //     setOrder(isDesc ? "asc" : "desc");
    //     setOrderBy(property);
    // };

    // function handleChange(event) {
    //     const { target } = event;
    //     const { value, name } = target;
    //     setEmailTemplate({
    //         ...template,
    //         [name]: value,
    //     });
    // }

    // const handleChangePage = (event, newPage) => {
    //     setPage(newPage);
    // };

    // const handleChangeRowsPerPage = (event) => {
    //     setRowsPerPage(+event.target.value);
    //     setPage(0);
    // };

    function handleAlternateEmailsEvent(emailObj) {
        if (emailObj && typeof emailObj === "object") {
            console.log("SELECTED EMAIL: ", emailObj);
            // This sets the final template email...MOST IMPORTANT FOR SENDING
            setEmailTemplate({
                ...template,
                expert_email: emailObj.value,
            });
            // THIS sets the selection menu
            setDefaultEmail({ value: emailObj.value, label: emailObj.value });
        }
    }

    // EnhancedTableHead.propTypes = {
    //     onRequestSort: PropTypes.func.isRequired,
    //     order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    //     orderBy: PropTypes.string.isRequired,
    //     rowCount: PropTypes.number.isRequired,
    // };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <Typography
                component="div"
                role="tabpanel"
                hidden={value !== index}
                id={`scrollable-auto-tabpanel-${index}`}
                aria-labelledby={`scrollable-auto-tab-${index}`}
                {...other}
            >
                <Box p={3}>{children}</Box>
            </Typography>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };

    const fetchExpertById = async (id) => {
        const alternateEmailsArr = [];
        const expert = await axios.get(`/api/expert/findbyid?id=${id}`);
        if (expert.status === 200 && expert.data && expert.data.data) {
            if (expert.data.data[0].length === 0) return;
            const person = expert.data.data[0][0];
            // console.log("Fetched Expert DATA: ", expert.data.data);
            // WE ONLY NEED EXPERT EMAILS AT THE MOMENT
            const emails = expert.data.data[1];
            if (emails && emails.length > 0) {
                for (let i = 0; i < emails.length; i++) {
                    const item = {
                        value: emails[i].email,
                        label: emails[i].email,
                    };
                    alternateEmailsArr.push(item);
                }
                setAlternateEmails(alternateEmailsArr);
            }
            console.log("FETCHED: " + person.fname + " " + person.lname, emails);
        }
    };

    const fetchEmailsById = async (id, template) => {
        setOpenLoader(true);
        const postData = {
            project_id: id,
            template_name: template ? template : "onboard_screener",
        };
        const url = "/api/project/emaillogs";
        axios
            .post(url, postData, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200 && result.data && result.data.data) {
                    if (result.data.data.length === 0) {
                        setOpenLoader(false);
                        return;
                    }

                    const emailsData = result.data.data;
                    const deliveredEmails = emailsData
                        .filter((item) => (item.is_sent && item.is_delivered) || item.is_opened)
                        .map((expert) => ({ ...expert, expertName: `${expert.fname} ${expert.lname}` }));
                    const bouncedEmails = emailsData
                        .filter((item) => item.is_bounced)
                        .map((expert) => ({ ...expert, expertName: `${expert.fname} ${expert.lname}` }));
                    const droppedEmails = emailsData
                        .filter((item) => item.is_dropped)
                        .map((expert) => ({ ...expert, expertName: `${expert.fname} ${expert.lname}` }));
                    const deferredEmails = emailsData
                        .filter(
                            (item) => item.is_deferred && !item.is_delivered && !item.is_dropped && !item.is_bounced
                        )
                        .map((expert) => ({ ...expert, expertName: `${expert.fname} ${expert.lname}` }));

                    setDelivered(deliveredEmails);
                    setBounced(bouncedEmails);
                    setDropped(droppedEmails);
                    setDeferred(deferredEmails);

                    if (value === 0) {
                        setFilteredEmails(deliveredEmails);
                    } else if (value === 1) {
                        setFilteredEmails(deferredEmails);
                    } else if (value === 2) {
                        setFilteredEmails(droppedEmails);
                    } else if (value === 3) {
                        setFilteredEmails(bouncedEmails);
                    }

                    console.log("ALL EMAILS: ", emailsData);
                    console.log("DELIVERED EMAILS: ", deliveredEmails);
                    console.log("BOUNCED EMAILS: ", bouncedEmails);
                    console.log("DROPPED EMAILS: ", droppedEmails);
                    console.log("DEFERRED EMAILS: ", deferredEmails);

                    setOpenLoader(false);
                } else {
                    console.log(`POSTED, but NOT Status 200: ${JSON.stringify(template)}`);
                    alert("Oops, Something went wrong. Unable to load your emails");
                }
            })
            .catch((result) => {
                console.log("Something went wrong, API Error, 404 Error:", result);
                alert("Oops, Something went wrong. Unable to load your emails");
            });
    };

    const fetchProjectById = useCallback(async (id) => {
        const pData = await axios.get(`/api/project/find?id=${id}`);
        if (pData.status === 200 && pData.data && pData.data.data) {
            if (pData.data.data.projectData.length === 0) return;
            const project = pData.data.data.projectData[0];
            const tags = pData.data.data.tags.map((tag) => {
                return { label: tag.name, value: tag.id };
            });
            const specialty = pData.data.data.specialty.map((s) => {
                return { label: s.name, value: s.specialty_id };
            });
            const pcc = pData.data.data.clientContacts.filter((item) => item.isprimary == true);
            const acc = pData.data.data.clientContacts.filter((item) => item.isprimary !== true);
            const apms = pData.data.data.additionalProjectManagers;
            const { comments } = pData.data.data;

            const settings = pData.data.data.confBridgeSettings;
            const primary = settings.filter((item) => item.isprimary !== false);
            let selectedTypeID = "1";
            let selectedTwilio = "1";
            let extNumbers = [];
            if (primary.length > 0) {
                const primaryData = primary[0];
                selectedTypeID = primaryData.type_id.toString();
                if (primaryData.data && primaryData.data.value !== undefined) {
                    selectedTwilio = primaryData.data.value;
                }
                if (Array.isArray(primaryData.data) && primaryData.data.length > 0) {
                    extNumbers = primaryData.data;
                }
            }
            console.log("FETCHED DATA: ", pData);
            const attachedExperts = pData.data.data.experts;
            setData({
                ...data,
                id: project.id,
                isdeleted: project.isdeleted,
                title: project.title,
                description: project.description,
                email_copy: project.email_copy,
                project_not_received_by_email: project.project_not_received_by_email,
                client_id: project.client_id,
                client_name: project.client_name,
                primary_client_contact: pcc[0],
                additional_client_contacts: acc,
                expert_ids: project.expert_ids,
                primary_project_manager: project.project_manager_fname + " " + project.project_manager_lname,
                additional_project_managers: apms,
                status: project.project_status,
                type: project.project_type,
                priority: project.project_priority,
                case_code: project.case_code,
                calls_planned: project.calls_planned,
                industry: project.industry,
                conference_bridge_setting: selectedTypeID,
                twilio_bridge_setting: selectedTwilio,
                external_bridge_numbers: extNumbers,
                notifications: [],
                specialty,
                tags,
                comments,
                responses: project.responses,
                scheduled_calls: project.scheduled_calls,
                completed_calls: project.completed_calls,
                experts: attachedExperts,
            });
            setOpenLoader(false);
        }
    }, []);

    useEffect(() => {
        if (match.params.id) {
            fetchProjectById(match.params.id);
            fetchEmailsById(match.params.id);
        }
    }, [match.params.id]);

    // useEffect(() => {
    //     setFilteredEmails(generateEmailType(0));
    // }, []);

    function navigateBackToProject() {
        history.push(`/project/view/${data.id}`);
    }

    function containsParagraphTag(str) {
        if (str !== "" && str.indexOf("<p>") !== -1) {
            return true;
        }
        return false;
    }

    function renderPreviousEmailBody(template, body) {
        let markup = "";
        if (containsParagraphTag(body)) {
            // Nothing to do, just pass it along
            console.log("EMAIL BODY IS HTML");
            return body;
        } else {
            const jsonBody = JSON.parse(body);
            // Check to see if body is a valid JSON object, if so we need to build the template
            if (jsonBody !== null && typeof jsonBody === "object") {
                console.log("EMAIL TEMPLATE", template);
                // generate HTML template
                markup += `<p>${jsonBody.name},</p>`;
                if (template === "onboard_screener" || template === "onboard_screener_again") {
                    markup += `<p>My name is ${jsonBody.rm_name} and I am a ${jsonBody.rm_title} at FirstThought, a life sciences and healthcare expert network based in New York City.</p>`;
                } else {
                    markup +=
                        "<p>I hope all is well! I’m an associate at a start-up expert network, FirstThought, specifically focused on serving life science management consultancies and healthcare companies, and we’re still in the early stages of building our network. We’d love for you to join so we can begin sending over relevant project requests.</p>";
                }
                markup += `<p>${jsonBody.emailDescription}</p>`;
                markup += `<p>${jsonBody.email_question}</p>`;
                if (template === "onboard_screener") {
                    markup += `<p>You can accept by clicking <a href='${jsonBody.accept_link}'>Yes, I'm Interested</a>, or decline by clicking <a href='${jsonBody.decline_link}'>No Thanks</a>. `;
                    markup += `For more information, please feel free to email me directly at ${jsonBody.rm_email} or call me at ${jsonBody.rm_phone}.</p>`;
                } else {
                    markup += `<p>You can join our network by clicking <a href='${jsonBody.accept_link}'>Yes, I'm Interested</a>, or decline by clicking <a href='${jsonBody.decline_link}'>No Thanks</a>. `;
                    markup += `For more information, please feel free to email me directly at ${jsonBody.rm_email} or call me at ${jsonBody.rm_phone}.</p><br />`;
                }
                if (template === "onboard_screener") {
                    markup +=
                        "<p>We offer an hourly honorarium of your choosing for your time speaking with our clients. As background, our clients are healthcare consultants, investors and corporate institutions who engage with FirstThought’s network of experts for the purpose of completing their research.</p>";
                    markup +=
                        "<p>Thank you in advance for your consideration, and I look forward to hearing from you soon.</p><br />";
                }
                markup += "<p>";
                markup += "Best Regards,<br/>";
                markup += `${jsonBody.rm_name}<br/>`;
                markup += `${jsonBody.rm_title}<br/>`;
                markup += "FirstThought, LLC<br/>";
                markup += `${jsonBody.rm_email}<br/>`;
                markup += `M: ${jsonBody.rm_phone}<br/>`;
                markup += "New York | London | Tel Aviv";
                markup += "</p>";
                return markup;
            }
            return "<p>Unable to load the previous email version.</p>";
        }
    }

    const dialogEmailVersion = async (em) => {
        setOpenLoader(true);
        console.log("EMAIL ROW: ", em);
        const response = await axios.get(`/api/project/getmaildata?id=${em.id}`);
        if (response.status === 200 && response.data && response.data.data) {
            const emailData = response.data.data[0];
            console.log("FETCHED RESPONSE: ", emailData);
            setOpenLoader(false);
            setEmailVersion({
                ...version,
                id: em.id,
                template_name: em.template_name,
                project_id: em.project_id,
                expert_id: em.expert_id,
                user_id: em.user_id,
                mail_subject: emailData.mail_subject,
                mail_body: renderPreviousEmailBody(em.template_name, emailData.mail_body),
                mail_to_name: em.fname + " " + em.lname,
                mail_to: em.mail_to.trim(),
                mail_from: em.mail_from,
                mail_cc: em.mail_cc,
                mail_bcc: em.mail_bcc,
                created_on: em.created_on,
                is_sent: em.is_sent,
                error_message: em.error_message,
                is_delivered: em.is_delivered,
                is_opened: em.is_opened,
                is_dropped: em.is_dropped,
                is_deferred: em.is_deferred,
                is_bounced: em.is_bounced,
            });
            // Set Re-Send email template
            setEmailTemplate({
                ...template,
                expert_id: em.expert_id,
                expert_fname: em.fname,
                expert_lname: em.lname,
                expert_email: em.mail_to,
                project_id: em.project_id,
                user_id: em.user_id,
                user_fname: auth.data.fname,
                user_lname: auth.data.lname,
                from_email: auth.data.mail,
                email_subject: emailData.mail_subject,
                email_body: emailData.mail_body,
                email_template: em.template_name + "_again",
            });
            console.log("EMAIL TEMPLATE TO RESEND: ", template);
            setEmailCopy(true);
            setDefaultEmail({ value: em.mail_to, label: em.mail_to });
            // Fetch EXPERT ID
            fetchExpertById(em.expert_id);
        }
    };

    function openFollowupDialog() {
        setFollowup(true);
        setEmailCopy(false);
        console.log("TEMPLATE NAME: ", version.template_name);
    }
    function sendEmailAgain() {
        if (window.confirm(`Are you sure you want to re-send this email again?`)) {
            const url = "/api/mail/tnc";
            if (!loading) {
                setSuccess(false);
                setLoading(true);
            }
            axios
                .post(url, template, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                })
                .then((result) => {
                    if (result.status === 200) {
                        console.log("Email Template Sent", template);
                        timer.current = setTimeout(() => {
                            setSuccess(true);
                            setLoading(false);
                            handleClose();
                            fetchEmailsById(data.id);
                        }, 3000);
                    } else {
                        console.log(`POSTED, but NOT Status 200: ${JSON.stringify(template)}`);
                        alert("Oops, Something went wrong. Your email did not send. Please contact someone in Tech.");
                    }
                })
                .catch((result) => {
                    console.log("Something went wrong, API Error, 404 Error:", result);
                    alert("Oops, Something went wrong. Your email did not send. Please contact someone in Tech.");
                });
        }
    }
    const deliveredTab = `Delivered (${delivered.length})`;
    const bouncedTab = `Bounced (${bounced.length})`;
    const blockedTab = `Blocked (${dropped.length})`;
    const deferredTab = `Deferred (${deferred.length})`;

    return (
        <div>
            <PageTitle title={data.title} />
            <Backdrop
                // transitionDuration={500}
                className={classes.backdrop}
                open={openLoader}
                onClick={() => {
                    setOpenLoader(false);
                }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog open={followup} onClose={handleClose} fullWidth maxWidth="lg" aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Send A Follow Up Email</DialogTitle>
                <DialogContent>
                    <TermsFollowupEmail closeEvent={handleClose} previous={version} project={data} user={auth.data} />
                </DialogContent>
            </Dialog>
            <Dialog open={emailCopy} onClose={handleClose} fullWidth maxWidth="md" aria-labelledby="form-dialog-title">
                <DialogTitle>
                    <Button
                        variant="contained"
                        edge="start"
                        color="inherit"
                        className={classes.action}
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                    {[
                        "tnc",
                        "outreach",
                        "outreach_again",
                        "tnc_again",
                        "tnc_followup",
                        "tnc_followup2",
                        "onboard_screener",
                        "onboard_screener_again",
                        "screener",
                    ].includes(version.template_name) && (
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.action}
                            onClick={openFollowupDialog}
                        >
                            Follow Up
                        </Button>
                    )}
                </DialogTitle>
                <DialogContent>
                    {/* TODO: NOT OPENED ONLY */}
                    {!version.is_opened &&
                        ["tnc", "outreach", "onboard_screener", "screener"].includes(version.template_name) && (
                            <Box display={true} className={classes.emailField}>
                                <Select
                                    placeholder={"Select Recipient Email"}
                                    name="expert_email"
                                    value={defaultEmail}
                                    onChange={handleAlternateEmailsEvent}
                                    options={alternates}
                                />
                                {/* <TextField
                  fullWidth
                  id="expert_email"
                  label="Recipient Email"
                  margin="normal"
                  onChange={handleChange}
                  name="expert_email"
                  value={template.expert_email}
                /> */}
                                <br />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={buttonClassname}
                                    disabled={loading}
                                    onClick={sendEmailAgain}
                                >
                                    Send Again
                                </Button>
                                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </Box>
                        )}
                    <div className={classes.emailBorder}>
                        <p className={classes.datestamp}>{formatDateString(version.created_on)}</p>
                        <p>
                            <strong>FROM:</strong> {version.mail_from}
                        </p>
                        <p>
                            <strong>TO:</strong> {version.mail_to}
                        </p>
                        <p className={classes.subjectLine}>
                            <strong>SUBJECT LINE:</strong> {version.mail_subject}
                        </p>
                        <div
                            className={classes.emailOutline}
                            dangerouslySetInnerHTML={{
                                __html: version.mail_body,
                            }}
                        />
                    </div>
                </DialogContent>
            </Dialog>
            <Grid container spacing={2}>
                <Grid item sm={12} xs={12}>
                    <Button className={classes.nameButton} onClick={navigateBackToProject}>
                        {data.title}
                    </Button>
                </Grid>
                <Grid item sm={12} xs={12}>
                    <FormControl className={classes.formControl}>
                        <InputLabel>Email Template Type:</InputLabel>
                        <MuiSelect value={emailTemplateFilter} onChange={handleEmailTemplateDropdown}>
                            <MenuItem value={"onboard_screener"}>Onboard Screener</MenuItem>
                            <MenuItem value={"onboard_screener_again"}>Onboard Screener Follow-Up</MenuItem>
                            <MenuItem value={"screener"}>Screener</MenuItem>
                            <MenuItem value={"followup"}>Follow-Up</MenuItem>
                            <MenuItem value={"outreach"}>Outreach</MenuItem>
                            <MenuItem value={"outreach_again"}>Outreach Again</MenuItem>
                            <MenuItem value={"outreach_followup"}>Outreach Follow-Up</MenuItem>
                            <MenuItem value={"postscreen"}>Postscreen</MenuItem>
                            <MenuItem value={"prescreen"}>Prescreen</MenuItem>
                            <MenuItem value={"prescreen_angles"}>Prescreen Angles</MenuItem>
                            <MenuItem value={"tnc"}>TNC</MenuItem>
                            <MenuItem value={"tnc_again"}>TNC Again</MenuItem>
                            <MenuItem value={"tnc_followup"}>TNC Follow-Up</MenuItem>
                            <MenuItem value={"tnc_followup2"}>TNC Follow-Up 2</MenuItem>
                            <MenuItem value={"tnc_followup3"}>TNC Follow-Up 3</MenuItem>
                        </MuiSelect>
                    </FormControl>
                </Grid>
                <Grid item sm={12} xs={12}>
                    {renderFilters()}
                    <Tabs value={value} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
                        <Tab label={deliveredTab} />
                        <Tab label={deferredTab} />
                        <Tab label={blockedTab} />
                        <Tab label={bouncedTab} />
                    </Tabs>
                    {renderTabPanel(value)}
                </Grid>
            </Grid>
        </div>
    );
}

ProjectEmails.propTypes = {
    match: PropTypes.object,
};
ProjectEmails.defaultProps = {
    match: null,
};

export default ProjectEmails;
