import React, { useState, forwardRef } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert ref={ref} elevation={6} variant="filled" {...props} />;
});

export default function FlashMessagev2(props) {
    console.log("====== in flash message ======");
    const { error, severity, message } = props;

    const duration = props.duration || 3000; // Pass in ms
    const vertical = props.vertical || "bottom";
    const horizontal = props.horizontal || "center";

    const [open, setOpen] = useState(error);

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
        // props.onClose();
    };

    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                autoHideDuration={duration}
                onClose={handleClose}
            >
                <Alert sx={{ width: "100%" }} onClose={handleClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
}
