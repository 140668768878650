import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
    },
    chip: {
        margin: theme.spacing(1),
    },
    list: {
        margin: theme.spacing(1),
        marginLeft: "0",
        paddingInlineStart: "0",
        listStyleType: "none",
        listStylePosition: "outside",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: "75%",
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    margin: {
        margin: theme.spacing(1),
    },
}));

function AddPhones(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const [phone, setPhone] = useState({
        number: "",
        type: "null",
        code: "",
        extension: "",
        sms: false,
    });

    const handleExpand = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    function handleCheckbox(event) {
        const { target } = event;
        const { name } = target;
        setPhone({ ...phone, [name]: event.target.checked });
    }

    function handleChange(event) {
        const { target } = event;
        const { value } = target;
        const { name } = target;

        setPhone({
            ...phone,
            [name]: value,
        });
    }

    function addPhone() {
        if (phone.number.length > 0 && phone.type.length > 0 && phone.code.length > 0) {
            setPhone({
                number: phone.number,
                type: phone.type,
                code: phone.code,
                extension: phone.extension,
                sms: phone.sms,
            });
            const options = {
                number: phone.number,
                type: phone.type,
                code: phone.code,
                extension: phone.extension,
                sms: phone.sms,
            };
            props.data.alternate_phones.push(options);
            setPhone({
                number: "",
                type: "",
                code: "",
                extension: "",
                sms: false,
            });
        }
    }

    function handleDelete(e) {
        console.log("Selected Phone Number to Delete: ", e);
        if (window.confirm("Are you sure you want to delete this phone number?")) {
            const filtered = props.data.alternate_phones.filter((item) => item.number !== e);
            console.log("FILTERED PHONE ARRAY: ", filtered);
            props.removeEvent(filtered);
            setPhone({
                number: "",
                type: "",
                code: "",
                extension: "",
                sms: false,
            });
        }
    }

    function handleClick(phone, code) {
        if (window.confirm("Are you sure you want to make this the primary number?")) {
            props.clickEvent(phone, code);
            // handleDelete(phone);
        }
    }
    return (
        <Accordion
            expanded={expanded === "panel1" || props.data.alternate_phones.length > 0}
            onChange={handleExpand("panel1")}
        >
            <AccordionSummary aria-controls="panel1bh-content" expandIcon={<ExpandMoreIcon />} id="panel1bh-header">
                <Typography className={classes.heading}>Additional Phone Numbers</Typography>
                <Typography className={classes.secondaryHeading}>(Optional)</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={1}>
                    <Grid item sm={12} xs={12}>
                        {props.data.alternate_phones.length > 0 && (
                            <p className={classes.secondaryHeading}>
                                Select a number from the list to make it the primary phone
                            </p>
                        )}
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <div>
                            <ul className={classes.list}>
                                {props.data.alternate_phones.map((number, index) => (
                                    <li key={number.number}>
                                        <Chip
                                            avatar={number.sms ? <Avatar>sms</Avatar> : <Avatar>#</Avatar>}
                                            className={classes.chip}
                                            label={
                                                number.type !== "null"
                                                    ? `${number.type} : ${number.code} ${number.number}`
                                                    : `Unknown : ${number.code} ${number.number}`
                                            }
                                            onClick={() => handleClick(number.number, number.code)}
                                            onDelete={() => handleDelete(number.number)}
                                        />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </Grid>
                    <Grid item sm={3} xs={12}>
                        <TextField
                            fullWidth
                            label="Type"
                            margin="normal"
                            name="type"
                            onChange={handleChange}
                            select
                            value={phone.type}
                        >
                            <MenuItem value="Mobile">Mobile</MenuItem>
                            <MenuItem value="Home">Home</MenuItem>
                            <MenuItem value="Office">Office</MenuItem>
                            <MenuItem value="Fax">Fax</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item sm={5} xs={12}>
                        <TextField
                            fullWidth
                            label="Enter Phone"
                            margin="normal"
                            name="number"
                            onChange={handleChange}
                            value={phone.number}
                        />
                    </Grid>
                    <Grid item sm={2} xs={12}>
                        <TextField
                            fullWidth
                            label="Code"
                            margin="normal"
                            name="code"
                            onChange={handleChange}
                            select
                            value={phone.code}
                        >
                            {props.countries.map((code, index) => (
                                <MenuItem key={index} value={code.dial_code}>
                                    {code.name} ({code.dial_code})
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item sm={2} xs={12}>
                        <Fab
                            aria-label="add"
                            className={classes.margin}
                            color="secondary"
                            onClick={addPhone}
                            size="medium"
                        >
                            <AddIcon />
                        </Fab>
                    </Grid>
                    {phone.type === "Mobile" && (
                        <Grid item sm={12} xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={phone.sms}
                                        name="sms"
                                        onChange={handleCheckbox}
                                        value={phone.sms}
                                    />
                                }
                                label="Opt-in to SMS?"
                            />
                        </Grid>
                    )}
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}

export default AddPhones;
