import React, { useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import EmailField from "./EmailField";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
}));

function ClientContactForm(props) {
    const classes = useStyles();
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
                <TextField
                    autoComplete="salutation"
                    autoFocus
                    fullWidth
                    label="Prefix"
                    margin="normal"
                    name="salutation"
                    onChange={props.changeEvent}
                    value={props.data.salutation}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <TextField
                    autoComplete="firstname"
                    autoFocus
                    error={props.data.firstname === ""}
                    fullWidth
                    id="firstname"
                    label="First Name"
                    margin="normal"
                    name="firstname"
                    onChange={props.changeEvent}
                    required
                    value={props.data.firstname}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <TextField
                    autoComplete="lastname"
                    error={props.data.lastname === ""}
                    fullWidth
                    id="lastname"
                    label="Last Name"
                    margin="normal"
                    name="lastname"
                    onChange={props.changeEvent}
                    required
                    value={props.data.lastname}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <TextField
                    autoComplete="title"
                    fullWidth
                    id="title"
                    label="Title"
                    margin="normal"
                    name="title"
                    onChange={props.changeEvent}
                    value={props.data.title}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <EmailField changeEvent={props.changeEvent} value={props.data.email} />
            </Grid>
            <Grid item xs={12} sm={12}>
                <TextField
                    fullWidth
                    onChange={props.changeEvent}
                    id="phone"
                    label="Phone"
                    margin="normal"
                    name="phone"
                    value={props.data.phone}
                />
            </Grid>
        </Grid>
    );
}
export default ClientContactForm;
