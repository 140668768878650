import React from "react";
import { LinearProgress, Paper, Box } from "@mui/material";
export default function LinearLoader() {
    return (
        <Paper
            sx={{
                height: "40vh",
                width: "100%",
                verticalAlign: "middle",
                textAlign: "center",
            }}
        >
            <LinearProgress
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    top: "50%",
                    // right: "10%",
                    // left: "10%",
                    // marginTop: "10vh",
                    // marginBottom: "10vh",
                    // display: "inline-block",
                }}
                color="primary"
            />
        </Paper>
    );
}
