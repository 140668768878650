import React, { useState, useEffect, createRef, useContext } from "react";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import PropTypes, { func } from "prop-types";
import queryString from "query-string";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import axios from "axios";
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "react-select";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Save from "@mui/icons-material/Save";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FlashMessagev2 from "../components/FlashMessagev2";
import { authContext } from "../context/auth";

import ContactFields from "../components/ContactFields";
import AddPhones from "../components/AddPhones";
import AddEmails from "../components/AddEmails";
import SpecialtyField from "../components/SpecialtyField";
import EmploymentFields from "../components/EmploymentFields";
import EducationFields from "../components/EducationFields";
import Messaging from "../components/Messaging";

import CountryListData from "../data/countries.json";
import StateListData from "../data/us_states.json";
import TimeZoneData from "../data/timezones.json";
import SuffixData from "../data/suffixes.json";
import RequiresUpdateTagManager from "../components/project/utils/RequiresUpdateTagManager";

const countryCodes = CountryListData.countries;
const usStates = StateListData.states;
const timeZones = TimeZoneData.zones;

const Suffixes = SuffixData.normalized_suffixes;

const HunterAPIKey = "bfdf14fa7f4f152a1db855049a08cebc1b9e6a60";

const currencies = [
    {
        value: "USD",
        label: "$",
    },
    {
        value: "EUR",
        label: "€",
    },
    {
        value: "JPY",
        label: "¥",
    },
    {
        value: "GBP",
        label: "£",
    },
];
const minutes = [
    {
        value: 0,
        label: "None",
    },
    {
        value: 15,
        label: "15 Minutes",
    },
    {
        value: 30,
        label: "30 Minutes",
    },
    {
        value: 45,
        label: "45 Minutes",
    },
    {
        value: 60,
        label: "60 Minutes",
    },
];

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    label: {
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(0.5),
        fontSize: "1rem",
    },
    strong: {
        color: theme.palette.text.primary,
        marginBottom: theme.spacing(0.5),
        fontSize: "1rem",
    },
    margin: {
        margin: theme.spacing(1),
    },
    row: {
        marginTop: theme.spacing(2),
    },
    pushRight: {
        textAlign: "right",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    link: {
        color: "#3c8864",
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    editorWrapper: {
        border: "1px solid #EAEAEA",
        borderRadius: "2px",
        padding: "12px",
    },
}));

function Expert(props) {
    const pageUrl = props.location.search;
    const params = queryString.parse(pageUrl);
    const { match, history } = props;
    const { setAuthData, auth } = useContext(authContext);
    const classes = useStyles();
    const [zones, setTimeZone] = useState([]);
    const [usaState, setUS] = useState([]);
    const [country, setCountry] = useState([]);
    const [isError, setIsError] = useState(false);
    const [disable, setDisableSubmit] = useState(false);
    const [open, setOpen] = useState(false);
    const [loadingExpert, setLoadingExpert] = useState(true);
    const [blinded, setBlindedEditorState] = useState(EditorState.createEmpty());
    const [editor, setEditorState] = useState(EditorState.createEmpty());
    const EmploymentFieldsAnchor = createRef();
    const EducationFieldsAnchor = createRef();
    const viewPath = "/expert/view/:id";
    const [error, setError] = useState(false);
    const [suffixModal, setSuffixModal] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [data, setData] = useState({
        id: props.match.params.id ? props.match.params.id : "",
        prefix: "",
        fname: "",
        mname: "",
        lname: "",
        headline: "",
        biography: "",
        suffix: "",
        country: "",
        state: [],
        city: [],
        address: "",
        zipcode: "",
        timezone: [],
        us: false,
        country_code: "",
        phone: "",
        extension: "",
        alternate_phones: [],
        alternate_emails: [],
        email: "",
        linkedin_url: "",
        employment: [],
        education: [],
        industry: "Healthcare",
        specialty: [],
        terms_signed: false,
        terms_version: "",
        terms_date: "",
        terms_origin: "",
        disclosures: false,
        tutorial_completed: false,
        tutorial_date: "",
        status: "Lead",
        dnc: "",
        created_by: auth.data.id,
        created_by_name: `${auth.data.fname} ${auth.data.lname}`,
        created_on: "",
        updated_on: "",
        tags: [],
        selectedCountry: null,
        minimum_hourly: 0,
        consultation_rate: "",
        consultation_rate_currency: "USD",
        group_meeting_rate: "",
        group_meeting_rate_currency: "USD",
        point_person_id: 0,
        background_check: false,
        comments: [],
        person_id: 0,
        notes: "",
        blinded_bio: "",
        opted_out: false,
        has_approved_stream: false,
        has_seen_stream: false,
    });
    const pageTitle = match.params.id ? `Edit ${data.fname} ${data.lname}` : "Create Expert";
    const errorMessageTimeout = 5000;
    let selected_suffixes = [];

    function onBlindedEditorStateChange(blinded) {
        setBlindedEditorState(blinded);
        const jsonHtml = convertToRaw(blinded.getCurrentContent());
        const html = draftToHtml(jsonHtml);
        setData({
            ...data,
            blinded_bio: html,
        });
    }
    function onEditorStateChange(editor) {
        setEditorState(editor);
        const jsonHtml = convertToRaw(editor.getCurrentContent());
        const html = draftToHtml(jsonHtml);
        // const editorHtml = htmlToDraft(html);
        setData({
            ...data,
            biography: html,
        });
    }

    function formatMiddleName(mname) {
        if (mname.trim() !== "" && mname.trim().length === 1) {
            const formatted = `${mname.trim().toUpperCase()}.`;
            return formatted;
        }
        return mname;
    }

    function handleClose() {
        setOpen(false);
        setSuffixModal(false);
    }

    function addSuffixesAndSave(person_id) {
        const suffixes = selected_suffixes.toString();
        data.suffix = suffixes;
        data.person_id = person_id;
        console.log("Did we save the selected suffixes? ", { suffix: data.suffix, person_id: data.person_id });
        handleSubmit();
    }

    function setEmploymentHistory(jobsArray) {
        if (typeof jobsArray === "object") {
            setData({
                ...data,
                employment: jobsArray,
            });
        }
    }

    function setEducationHistory(schoolArray) {
        if (typeof schoolArray === "object") {
            setData({
                ...data,
                education: schoolArray,
            });
        }
    }

    function setPrimaryPhone(phoneString, codeString) {
        if (phoneString !== "" || codeString !== "") {
            setData({
                ...data,
                phone: phoneString,
                country_code: codeString,
            });
        }
    }

    function setPrimaryEmail(emailString) {
        if (emailString !== "") {
            setData({
                ...data,
                email: emailString.trim(),
            });
        }
    }

    function hunterEmailCheck(emailAddress) {
        if (emailAddress) {
            axios
                .get("https://api.hunter.io/v2/email-verifier", {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                    },
                    params: {
                        email: emailAddress,
                        api_key: HunterAPIKey,
                    },
                })
                .then(function (response) {
                    const responseData = response.data.data;
                    console.log("Response from API call to Hunter.io: ", responseData);
                    if (responseData) {
                        const emailResult = {
                            email: emailAddress,
                            score: responseData.score,
                            result: responseData.result,
                        };
                        const emailArr = data.alternate_emails.filter((e) => e.email !== emailAddress);
                        emailArr.push(emailResult);
                        console.log("NEW: ", emailArr);
                        setData({
                            ...data,
                            alternate_emails: emailArr,
                        });
                    }
                });
        }
    }

    function removeAlternateEmail(emailArray) {
        if (typeof emailArray === "object") {
            setData({
                ...data,
                alternate_emails: emailArray,
            });
        }
    }

    function removeAlternatePhone(phoneArray) {
        if (typeof phoneArray === "object") {
            setData({
                ...data,
                alternate_phones: phoneArray,
            });
        }
    }

    function handleCheckbox(event) {
        const { target } = event;
        const { name } = target;
        setData({ ...data, [name]: event.target.checked });
    }

    function handleSuffixCheckbox(event) {
        const { target } = event;
        const { name, value } = target;
        const suffixes = [...selected_suffixes];
        console.log("Suffix Checkbox: ", { name, value, target: target.checked });
        if (target.checked && !suffixes.includes(value)) {
            suffixes.push(value);
        } else if (!target.checked && suffixes.includes(value)) {
            const index = suffixes.indexOf(value);
            suffixes.splice(index, 1);
        }
        console.log("Suffixes Array: ", suffixes);
        selected_suffixes = suffixes;
    }

    function handleTagEvent(tagsArray) {
        if (typeof tagsArray === "object") {
            setData({
                ...data,
                tags: tagsArray,
            });
        }
    }

    function handleTimeZone(zoneArray) {
        if (typeof zoneArray === "object") {
            setData({
                ...data,
                timezone: zoneArray,
            });
        }
    }

    function handleSpecialty(specialtyArray) {
        if (typeof specialtyArray === "object") {
            setData({
                ...data,
                specialty: specialtyArray,
            });
        }
    }

    function handleCountry(countryArray) {
        if (typeof countryArray === "object") {
            setData({
                ...data,
                country: countryArray,
            });
            if (country.includes(countryArray)) {
                data.country_code = countryArray.dial_code;
            }
            if (countryArray.value === "US") {
                // Only show states field if country is US
                setData({
                    ...data,
                    us: true,
                    country: countryArray,
                });
            } else {
                setData({
                    ...data,
                    us: false,
                    country: countryArray,
                    state: [],
                });
            }
        }
    }

    function handleUSState(stateArray) {
        if (typeof stateArray === "object") {
            setData({
                ...data,
                state: stateArray,
            });
        }
    }

    function handlePhoneEvent(event) {
        const { target } = event;
        const { value, name } = target;
        const phone = value.replace(/[^0-9]/g, "");

        if (phone.length > 9) {
            setIsError(false);
        } else {
            setIsError(true);
        }
        setData({
            ...data,
            [name]: phone,
        });
    }

    function handleNumberChange(event) {
        const { target } = event;
        const { value, name } = target;
        const reg = new RegExp("^\\d+$");
        // Only allow numbers or empty string
        if (reg.test(value) || value === "") {
            setData({
                ...data,
                [name]: value,
            });
        }
    }

    function handleChange(event) {
        const { target } = event;
        const { value, name } = target;

        if (value.length > 0 || value !== "undefined") {
            setIsError(false);
        } else {
            setIsError(true);
        }
        setData({
            ...data,
            [name]: value,
        });
    }

    function handleSubmit() {
        // event.preventDefault();
        setErrorMsg("");
        const formData = {
            prefix: data.prefix,
            fname: data.fname.trim(),
            mname: formatMiddleName(data.mname),
            lname: data.lname.trim(),
            headline: data.headline,
            biography: data.biography,
            suffix: data.suffix,
            country: data.country,
            us: data.us,
            country_code: data.country_code,
            timezone: data.timezone,
            state: data.state,
            city: data.city,
            address: data.address,
            zipcode: data.zipcode,
            phone: data.phone,
            extension: data.extension,
            alternate_phones: data.alternate_phones,
            email: data.email ? data.email.trim() : data.email,
            alternate_emails: data.alternate_emails,
            linkedin_url: data.linkedin_url,
            employment: data.employment,
            education: data.education,
            industry: data.industry,
            specialty: data.specialty,
            terms_signed: data.terms_signed,
            terms_version: data.terms_version,
            terms_date: data.terms_date,
            terms_origin: data.terms_origin,
            disclosures: data.disclosures,
            tutorial_completed: data.tutorial_completed,
            tutorial_date: data.tutorial_date,
            status: data.status,
            dnc: data.dnc,
            created_by: data.created_by,
            created_by_name: data.created_by_name,
            created_on: data.created_on,
            tags: data.tags,
            minimum_hourly: data.minimum_hourly,
            consultation_rate: data.consultation_rate,
            consultation_rate_currency: data.consultation_rate_currency,
            group_meeting_rate: data.group_meeting_rate,
            group_meeting_rate_currency: data.group_meeting_rate_currency,
            point_person_id: data.point_person_id,
            background_check: data.background_check,
            comments: data.comments,
            person_id: data.person_id,
            notes: data.notes,
            blinded_bio: data.blinded_bio,
            opted_out: data.opted_out,
            has_approved_stream: data.has_approved_stream,
            has_seen_stream: data.has_seen_stream,
        };
        const person = formData;
        setError(false);
        if (
            person.fname !== "" &&
            person.lname !== "" &&
            person.tags.length > 0 &&
            (person.email !== "" || person.linkedin_url !== "") &&
            validateEmail(person.email)
        ) {
            setDisableSubmit(true);
            let url = "/api/expert/create";
            if (props.match.params.id) {
                url = "/api/expert/update";
                formData.id = props.match.params.id;
                formData.updated_on = new Date().toISOString();
            }
            axios
                .post(url, formData, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                })
                .then((result) => {
                    if (result.status === 200 && result.data) {
                        setSuffixModal(false);
                        const expertObj = result.data.data.id ? result.data.data : result.data.data.expert;
                        if (expertObj === "User already exists!") {
                            alert(expertObj);
                        } else {
                            console.log("Successful POST:", expertObj);
                            setData({
                                ...data,
                                id: props.match.params.id ? props.match.params.id : expertObj.id,
                                person_id: expertObj.person_id,
                            });
                            setOpen(true);
                            history.push(
                                `/expert/edit/${props.match.params.id ? props.match.params.id : expertObj.id}`
                            );
                            if (person.suffix === "") {
                                setOpen(false);
                                setSuffixModal(true);
                            }
                        }
                    } else {
                        console.log(`ELSE result.status 500 => ${result} `);
                        alert(`There was an issue saving this Expert: ${result.message}`);
                    }
                })
                .catch((error) => {
                    // alert(`Error ocurred saving this Expert: ${error.response.data.message}`);
                    console.log(
                        `Error occured creating an Expert. The server response is => ${JSON.stringify(
                            error.response.data.message
                        )} and the Error is =>${error.response} `
                    );

                    if (error.request.response) {
                        const responseObject = JSON.parse(error.request.response);
                        console.log(responseObject);
                        setErrorMsg(responseObject.message);
                        if (!error) {
                            setTimeout(() => {
                                setError(false);
                                setErrorMsg("");
                            }, errorMessageTimeout);
                        }
                        setError(true);
                        setOpenLoader(false);
                    }
                });
        } else {
            setIsError(true);
            let troubleshootingMsg = "";
            console.log(person);

            if (person.fname == "") troubleshootingMsg += "First Name Required.  ";
            if (person.lname == "") troubleshootingMsg += "Last Name Required. ";
            if (person.tags.length == 0) troubleshootingMsg += "At least one tag required. ";
            if (person.email == "" && person.linkedin_url == "") {
                // if (person.email == "") {
                troubleshootingMsg += "Attach either an email or Linkedin URL. ";
                // } else if (person.)
            } else {
                if (person.email !== "") {
                    if (!validateEmail(person.email)) troubleshootingMsg += "Enter a valid email. ";
                }
            }
            setErrorMsg(troubleshootingMsg);
            if (!error) {
                setTimeout(() => {
                    setError(false);
                    setErrorMsg("");
                }, errorMessageTimeout);
            }
            setError(true);
        }
    }

    useEffect(() => {
        async function fetchLocationData() {
            const countriesObject = await countryCodes.map((item) => {
                return {
                    value: item.code,
                    label: item.name,
                    dial_code: item.dial_code,
                };
            });
            const statesObject = await usStates.map((item) => {
                return {
                    value: item.abbreviation,
                    label: item.name,
                };
            });
            const zonesObject = await timeZones.map((item) => {
                return {
                    value: item.value,
                    label: item.text,
                };
            });
            setCountry(countriesObject);
            setUS(statesObject);
            setTimeZone(zonesObject);
        }

        fetchLocationData();
    }, []);

    function validateEmail(inputText) {
        const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (inputText.match(mailformat)) {
            return true;
        } else {
            // alert("You have entered an invalid email address!");
            return false;
        }
    }

    useEffect(() => {
        async function fetchExpertById(id) {
            const expert = await axios.get(`/api/expert/findbyid?id=${id}`);
            if (expert.status === 200 && expert.data && expert.data.data) {
                if (expert.data.data[0].length === 0) return;
                const person = expert.data.data[0][0];
                console.log("expert.data.data", expert.data.data);

                const emails = expert.data.data[1];
                const phoneNumbers = expert.data.data[2];
                const address = expert.data.data[3][0];
                const tags = expert.data.data[4].map((tag) => {
                    return { label: tag.name, value: tag.id };
                });
                const specialty = expert.data.data[5].map((s) => {
                    return { label: s.name, value: s.id };
                });
                const disclosures = expert.data.data[10];
                console.log("DISCLOSURES: ", disclosures);
                // console.log("Zones", zones);
                // console.log(person.timezone);
                const tz = zones.filter((z) => {
                    if (z.value === person.timezone) {
                        return z;
                    }
                });
                let defaultDialCode;
                // In case they forget to add a state, don't throw an error
                if (address) {
                    defaultDialCode = country.find((o) => o.value === address.country_code);
                }
                const dialCode = defaultDialCode ? defaultDialCode.dial_code : "";
                const phone = [];
                if (phoneNumbers.length > 0) {
                    const numbers = phoneNumbers.filter((p) => p.isprimary);
                    console.log("NUMBERS: ", numbers);
                    if (numbers && numbers.length > 0) {
                        phone.push(numbers[0]);
                    }
                }
                let blindedBio = "";
                if (person.blinded_bio !== null && person.blinded_bio !== "") {
                    blindedBio = person.blinded_bio;
                    console.log("BLINDED NOT NULL: ", blindedBio);
                } else {
                    blindedBio = "Blinded Bio Missing";
                    console.log("BLINDED USING BIO: ", blindedBio);
                }
                const blindedBlock = htmlToDraft(blindedBio);
                if (blindedBlock) {
                    const contentBlindBioState = ContentState.createFromBlockArray(blindedBlock.contentBlocks);
                    setBlindedEditorState(EditorState.createWithContent(contentBlindBioState));
                }
                const contentBlock = htmlToDraft(person.biography);
                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    setEditorState(EditorState.createWithContent(contentState));
                }
                const currentEmployment = expert.data.data[6].filter((item) => item.present === true);
                let mostRecentJob = "";
                if (currentEmployment.length > 0) {
                    mostRecentJob = `${currentEmployment[0].position}, ${currentEmployment[0].company}`;
                }
                setData({
                    id,
                    prefix: person.prefix,
                    fname: person.fname,
                    mname: person.mname,
                    lname: person.lname,
                    headline: person.headline ? person.headline : mostRecentJob,
                    biography: person.biography,
                    suffix: person.suffix,
                    country: address ? { value: address.country_code, label: address.country } : "",
                    state: address ? { value: address.abbreviation, label: address.state } : "",
                    city: address ? address.city : "",
                    address: address ? address.address1 : "",
                    zipcode: address ? address.postal_code : "",
                    timezone: tz.length > 0 ? tz[0] : { value: "" },
                    us: address ? address.country_id === 180 : false,
                    country_code:
                        phoneNumbers.length > 0
                            ? phoneNumbers.filter((phone) => phone.isprimary).map((phone) => phone.code)[0]
                            : dialCode,
                    phone: phone.length > 0 ? phone[0].number : "",
                    extension: phone.length > 0 ? phone[0].extension : "",
                    alternate_phones: phoneNumbers.filter((phone) => !phone.isprimary),
                    alternate_emails: emails.filter((email) => !email.isprimary),
                    email: emails.filter((email) => email.isprimary).map((email) => email.email)[0],
                    linkedin_url: person.linkedin_url,
                    employment: expert.data.data[6],
                    education: expert.data.data[7],
                    industry: person.industry,
                    specialty,
                    terms_signed: person.has_signed_terms,
                    terms_version: "",
                    terms_date: "",
                    terms_origin: "",
                    disclosures: person.disclosures,
                    tutorial_completed: person.has_completed_tutorial,
                    tutorial_date: "",
                    status: person.expert_status,
                    dnc: person.dnc,
                    created_by: person.created_by,
                    created_by_name: person.created_by_name,
                    created_on: person.created_on,
                    updated_on: "",
                    tags,
                    minimum_hourly: person.minimum_hourly,
                    consultation_rate: person.consultation_rate,
                    consultation_rate_currency: person.consultation_rate_currency,
                    group_meeting_rate: person.group_meeting_rate,
                    group_meeting_rate_currency: person.group_meeting_rate_currency,
                    point_person_id: person.point_person_id,
                    background_check: person.background_check,
                    comments: expert.data.data[8],
                    person_id: person.person_id,
                    notes: person.notes ? person.notes : "",
                    blinded_bio: person.blinded_bio ? person.blinded_bio : "Blinded Bio Missing",
                    opted_out: person.opted_out,
                    has_approved_stream: person.has_approved_stream ? person.has_approved_stream : false,
                    has_seen_stream: person.has_seen_stream ? person.has_seen_stream : false,
                });
                setLoadingExpert(false);
            }
        }
        if (props.match.params.id) {
            fetchExpertById(props.match.params.id);
        }
    }, [zones]);

    function navigateToView() {
        if (data.id) {
            history.push(`/expert/view/${data.id}`);
        } else {
            history.push("/expert/add");
        }
    }
    function navigateToProject() {
        history.push(`/project/view/${params.pid}`);
        window.location.reload();
    }

    function verifyData() {
        history.push(`/verifier?id=${data.id}`);
    }

    return (
        <form onSubmit={handleSubmit}>
            <h1>{pageTitle}</h1>
            <Grid container spacing={3}>
                <Grid item sm={3} xs={12}>
                    <Button
                        className={useStyles.submit}
                        color="primary"
                        fullWidth
                        onClick={handleSubmit}
                        variant="contained"
                    >
                        <Save />
                        Save
                    </Button>
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Button className={useStyles.submit} fullWidth onClick={navigateToView} variant="contained">
                        Profile
                    </Button>
                </Grid>
                <Grid item sm={3} xs={12}>
                    {props.match.params.id && (
                        <Button className={useStyles.submit} fullWidth onClick={verifyData} variant="contained">
                            Verify
                        </Button>
                    )}
                </Grid>
                <Grid item sm={3} xs={12}>
                    <div className={classes.pushRight}>
                        <Badge badgeContent={data.comments.length} color="secondary" showZero>
                            <Typography className={classes.padding}>Comments</Typography>
                        </Badge>
                    </div>
                </Grid>
            </Grid>
            <ContactFields
                changeEvent={handleChange}
                countries={countryCodes}
                country_code={data.country_code}
                email={data.email}
                errorBoolean={isError}
                fname={data.fname}
                lname={data.lname}
                mname={data.mname}
                phone={data.phone}
                phoneChangeEvent={handlePhoneEvent}
                prefix={data.prefix}
                suffix={data.suffix}
                extension={data.extension}
                isReadOnly={match.path === viewPath}
            />
            <Grid container spacing={3}>
                <Grid item sm={6} xs={12}>
                    {match.path === viewPath ? (
                        <List component="nav" aria-label="secondary mailbox folders">
                            {data.alternate_emails.map((e) => (
                                <ListItem>
                                    <ListItemText primary={`${e.email} - ${e.score} (${e.result})`} />
                                </ListItem>
                            ))}
                        </List>
                    ) : (
                        <AddEmails
                            checkEmailEvent={hunterEmailCheck}
                            removeEvent={removeAlternateEmail}
                            clickEvent={setPrimaryEmail}
                            data={data}
                        />
                    )}
                </Grid>
                <Grid item sm={6} xs={12}>
                    {match.path === viewPath ? (
                        <List component="nav" aria-label="secondary mailbox folders">
                            {data.alternate_phones.map((p) => (
                                <ListItem>
                                    <ListItemText primary={`${p.type} - ${p.code} (${p.number})`} />
                                </ListItem>
                            ))}
                        </List>
                    ) : (
                        <AddPhones
                            removeEvent={removeAlternatePhone}
                            clickEvent={setPrimaryPhone}
                            countries={countryCodes}
                            data={data}
                        />
                    )}
                </Grid>
                <Grid item sm={12} xs={12}>
                    <TextField
                        autoComplete="linkedin_url"
                        error={data.linkedin_url === ""}
                        fullWidth
                        id="linkedin_url"
                        label="LinkedIn URL"
                        margin="normal"
                        name="linkedin_url"
                        onChange={handleChange}
                        // required
                        value={data.linkedin_url}
                        disabled={match.path === viewPath}
                    />
                </Grid>
                <Grid item sm={5} xs={12}>
                    <TextField
                        autoComplete="address"
                        fullWidth
                        id="address"
                        label="Address"
                        margin="normal"
                        name="address"
                        onChange={handleChange}
                        value={data.address}
                        disabled={match.path === viewPath}
                    />
                </Grid>

                <Grid item sm={5} xs={12}>
                    <TextField
                        autoComplete="city"
                        fullWidth
                        id="city"
                        label="City"
                        margin="normal"
                        name="city"
                        onChange={handleChange}
                        value={data.city}
                        disabled={match.path === viewPath}
                    />
                </Grid>

                <Grid item sm={2} xs={12}>
                    <TextField
                        autoComplete="zipcode"
                        fullWidth
                        id="zipcode"
                        label="Zip Code"
                        margin="normal"
                        name="zipcode"
                        onChange={handleChange}
                        value={data.zipcode}
                        disabled={match.path === viewPath}
                    />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <div className={classes.label}>Time Zone</div>
                    <Select
                        name="timezone"
                        onChange={handleTimeZone}
                        options={zones}
                        value={data.timezone}
                        isDisabled={match.path === viewPath}
                    />
                </Grid>
                <Grid item sm={5} xs={12}>
                    <div className={classes.label}>Country</div>
                    <Select
                        name="country"
                        onChange={handleCountry}
                        options={country}
                        value={data.country}
                        isDisabled={match.path === viewPath}
                    />
                </Grid>
                <Grid item sm={3} xs={12}>
                    {data.us && (
                        <div>
                            <div className={classes.label}>State</div>
                            <Select
                                name="state"
                                onChange={handleUSState}
                                options={usaState}
                                value={data.state}
                                isDisabled={match.path === viewPath}
                            />
                        </div>
                    )}
                </Grid>
                <Grid item sm={12} xs={12}>
                    <TextField
                        autoComplete="industry"
                        error={data.industry === ""}
                        fullWidth
                        id="industry"
                        label="Industry"
                        margin="normal"
                        name="industry"
                        onChange={handleChange}
                        // required
                        value={data.industry}
                    />
                </Grid>
                <Grid item sm={12} xs={12}>
                    <SpecialtyField
                        changeEvent={handleSpecialty}
                        errorBoolean={data.specialty === ""}
                        value={data.specialty}
                        selectedSpecialties={data.specialty}
                        isReadOnly={match.path === viewPath}
                    />
                </Grid>
                <Grid item sm={12} xs={12}>
                    {(!loadingExpert || !match.params.id) && (
                        <RequiresUpdateTagManager changeEvent={handleTagEvent} incomingTags={data.tags} />
                    )}
                </Grid>
                <Grid item sm={12} xs={12}>
                    <TextField
                        autoComplete="headline"
                        fullWidth
                        id="headline"
                        label="Headline"
                        margin="normal"
                        name="headline"
                        onChange={handleChange}
                        value={data.headline}
                        disabled={match.path === viewPath}
                    />
                </Grid>
                <Grid item sm={12} xs={12}>
                    <label className={classes.label}>Biography</label>
                    <Editor
                        editorState={editor}
                        spellCheck
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName={classes.editorWrapper}
                        onEditorStateChange={onEditorStateChange}
                    />
                </Grid>
                <Grid item sm={12} xs={12}>
                    <label className={classes.label}>Blinded Bio (Client Facing)</label>
                    <Editor
                        editorState={blinded}
                        spellCheck
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName={classes.editorWrapper}
                        onEditorStateChange={onBlindedEditorStateChange}
                    />
                </Grid>
                <Grid item sm={12} xs={12}>
                    <TextField
                        autoComplete="notes"
                        fullWidth
                        id="notes"
                        label="Additional Notes"
                        margin="normal"
                        multiline
                        name="notes"
                        onChange={handleChange}
                        rows="6"
                        value={data.notes}
                    />
                </Grid>
                <Grid item sm={12} xs={12}>
                    <div ref={EmploymentFieldsAnchor}>&nbsp;</div>
                    <EmploymentFields
                        changeEvent={handleChange}
                        setEmploymentHistory={setEmploymentHistory}
                        employment={data.employment}
                        errorBoolean={isError}
                        anchorPoint={EmploymentFieldsAnchor}
                        isReadOnly={match.path === viewPath}
                    />
                </Grid>
                <Grid item sm={12} xs={12}>
                    <div ref={EducationFieldsAnchor}>&nbsp;</div>
                    <EducationFields
                        changeEvent={handleChange}
                        setEducationHistory={setEducationHistory}
                        education={data.education}
                        errorBoolean={isError}
                        anchorPoint={EducationFieldsAnchor}
                        isReadOnly={match.path === viewPath}
                    />
                </Grid>
                <Grid item sm={2} xs={12}>
                    <TextField
                        fullWidth
                        id="minimum_hourly"
                        name="minimum_hourly"
                        select
                        label="Minimum Hourly"
                        value={data.minimum_hourly}
                        onChange={handleChange}
                        margin="normal"
                    >
                        {minutes.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item sm={2} xs={12}>
                    <TextField
                        autoComplete="consultation_rate_currency"
                        fullWidth
                        id="consultation_rate_currency"
                        name="consultation_rate_currency"
                        select
                        label="Currency"
                        value={data.consultation_rate_currency}
                        onChange={handleChange}
                        margin="normal"
                    >
                        {currencies.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item sm={3} xs={12}>
                    <TextField
                        autoComplete="consultation_rate"
                        fullWidth
                        id="consultation_rate"
                        label="Consultation Rate"
                        margin="normal"
                        name="consultation_rate"
                        onChange={handleNumberChange}
                        value={data.consultation_rate}
                    />
                </Grid>
                <Grid item sm={2} xs={12}>
                    <TextField
                        autoComplete="group_meeting_rate_currency"
                        fullWidth
                        id="group_meeting_rate_currency"
                        name="group_meeting_rate_currency"
                        select
                        label="Currency"
                        value={data.group_meeting_rate_currency}
                        onChange={handleChange}
                        margin="normal"
                    >
                        {currencies.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item sm={3} xs={12}>
                    <TextField
                        autoComplete="group_meeting_rate"
                        fullWidth
                        id="group_meeting_rate"
                        label="Group Meeting Rate"
                        margin="normal"
                        name="group_meeting_rate"
                        onChange={handleNumberChange}
                        value={data.group_meeting_rate}
                    />
                </Grid>
                <Grid item sm={12} xs={12}>
                    <TextField
                        autoComplete="dnc"
                        fullWidth
                        id="dnc"
                        label="Do Not Contact?"
                        margin="normal"
                        name="dnc"
                        onChange={handleChange}
                        value={data.dnc}
                        disabled={match.path === viewPath}
                    />
                </Grid>
                <Grid item sm={3} xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={data.terms_signed}
                                inputProps={{
                                    "aria-label": "Terms Signed?",
                                }}
                                name="terms_signed"
                                onChange={handleCheckbox}
                                value={data.terms_signed}
                                disabled={data.terms_signed}
                            />
                        }
                        label="Terms Signed?"
                    />
                </Grid>
                <Grid item sm={3} xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={data.disclosures}
                                inputProps={{
                                    "aria-label": "Disclosures?",
                                }}
                                name="disclosures"
                                onChange={handleCheckbox}
                                value={data.disclosures}
                                disabled={data.disclosures}
                            />
                        }
                        label="Disclosures?"
                    />
                </Grid>
                <Grid item sm={3} xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={data.opted_out}
                                inputProps={{
                                    "aria-label": "Opted Out?",
                                }}
                                name="opted_out"
                                onChange={handleCheckbox}
                                value={data.opted_out}
                            />
                        }
                        label="Opted Out?"
                    />
                </Grid>
                <Grid item sm={3} xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={data.has_approved_stream}
                                inputProps={{
                                    "aria-label": "Share w/ Stream?",
                                }}
                                name="has_approved_stream"
                                onChange={handleCheckbox}
                                value={data.has_approved_stream}
                                disabled
                            />
                        }
                        label="Share w/ Stream?"
                    />
                </Grid>
                {!data.has_approved_stream && (
                    <Grid item sm={3} xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={data.has_seen_stream}
                                    inputProps={{
                                        "aria-label": "Show Stream Option?",
                                    }}
                                    name="has_seen_stream"
                                    onChange={handleCheckbox}
                                    value={data.has_seen_stream}
                                    disabled
                                />
                            }
                            label="Show Stream Option?"
                        />
                    </Grid>
                )}
                {props.match.params.id ? (
                    <Grid container spacing={3}>
                        <Grid item sm={9} xs={12}>
                            <Button
                                disabled={disable}
                                className={useStyles.submit}
                                color="primary"
                                fullWidth
                                onClick={handleSubmit}
                                variant="contained"
                            >
                                <Save />
                                Save
                            </Button>
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <Button className={useStyles.submit} fullWidth onClick={navigateToView} variant="contained">
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container spacing={3}>
                        <Grid item sm={9} xs={12}>
                            <Button
                                disabled={disable}
                                className={useStyles.submit}
                                color="primary"
                                fullWidth
                                onClick={handleSubmit}
                                variant="contained"
                            >
                                Add New
                            </Button>
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <Button className={useStyles.submit} fullWidth onClick={navigateToView} variant="contained">
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        <Messaging user={auth.data} type="Expert" />
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {data.fname} {data.lname} has successfully been edited/saved to the database.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" autoFocus>
                            Close
                        </Button>
                        {params.pid ? (
                            <Button onClick={navigateToProject} className={classes.link}>
                                Back to Project
                            </Button>
                        ) : (
                            <Button onClick={navigateToView} className={classes.link}>
                                Profile View
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={suffixModal}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Successfully Saved {data.fname} {data.lname}!
                    </DialogTitle>
                    <DialogContent>
                        <Button onClick={navigateToView} className={classes.link}>
                            View {data.fname} {data.lname}'s Profile
                        </Button>
                        <p>
                            We use the suffix for proper outreach greetings for doctors.
                            <br />
                            It's an important designation that doctors see as a sign of respect.
                        </p>
                        {Suffixes.map((suffix) => (
                            <div key={suffix}>
                                <FormControlLabel
                                    control={<Checkbox name="suffix" onChange={handleSuffixCheckbox} value={suffix} />}
                                    label={suffix}
                                />
                            </div>
                        ))}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" autoFocus>
                            Not Applicable
                        </Button>
                        <Button onClick={() => addSuffixesAndSave(data.person_id)} className={classes.link}>
                            Add Suffix to {data.fname}
                        </Button>
                    </DialogActions>
                </Dialog>
                {error && (
                    <FlashMessagev2
                        error={error}
                        severity={"error"}
                        message={errorMsg}
                        duration={errorMessageTimeout}
                        vertical={"top"}
                    />
                )}
            </div>
        </form>
    );
}

Expert.propTypes = {
    match: PropTypes.object,
};
Expert.defaultProps = {
    match: null,
};

export default Expert;
