import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { authContext } from "../../context/auth";
import MUIDataTable from "mui-datatables";
import { basicDateTimeStamp, formatPhoneNumber } from "../../Utils";
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
// import Button from "@mui/material/Button";
// import VerifiedIcon from "@mui/icons-material/Verified";
// import ExpertPreviewWrapper from "../../components/wrappers/ExpertPreviewWrapper";
import { Backdrop, CircularProgress } from "@mui/material";

// ======
// STYLES
// ======

const linkStyle = css`
    color: #8966ad;
    font-size: 1rem;
    font-weight: bold;
    padding: 0;
    text-align: left;
    line-height: 1.25rem;
`;

const backdropStyles = css`
    color: #fff;
    z-index: 1201;
`;

export default function AllCallLogs(props) {
    // =========
    // CONSTANTS
    // =========

    const { project_id } = props;

    // =====
    // STATE
    // =====

    const { auth } = useContext(authContext);
    const [[stableData, filteredData], setData] = useState([[], []]); // data is going to have original set of data as first element and the filtered data as the second
    const [loading, setLoading] = useState(true);

    // =========
    // PAGE LOAD
    // =========

    async function loadAllCallLogs() {
        await axios
            .post(`/api/project/now/findCallLogsList`, { project_id, auth: auth.data })
            .then((response) => {
                if (response?.data?.data?.data) {
                    const result = response?.data?.data?.data || [];
                    setData([result, result]);
                    setLoading(false);
                    console.log("ALL CALL LOGS: ", result);
                }
            })
            .catch((err) => {
                console.log(err, " :: err");
            });
    }

    useEffect(() => {
        loadAllCallLogs();
    }, []);

    // ============
    // HANDLE FUNCS
    // ============

    // =====
    // TABLE
    // =====

    const options = {
        print: false,
        rowsPerPage: 100,
        viewColumns: false,
        download: true,
        selectableRows: "none",
    };

    const columns = [
        {
            name: "expert_name",
            label: "Name",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { expert_id, expert_name } = filteredData?.[dataIndex];

                    return <div>{expert_name}</div>;
                },
            },
        },
        {
            name: "number_called",
            label: "Number",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { number_called } = filteredData?.[dataIndex];
                    return (
                        <div>
                            {number_called && number_called.length === 10
                                ? formatPhoneNumber(number_called)
                                : number_called}
                        </div>
                    );
                },
            },
        },
        {
            name: "call_status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { call_status } = filteredData?.[dataIndex];
                    return <div>{call_status}</div>;
                },
            },
        },
        {
            name: "call_details",
            label: "Details",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    const { call_details } = filteredData?.[dataIndex];
                    return <div>{call_details}</div>;
                },
            },
        },
        {
            name: "created_on",
            label: "Logged On",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { created_on } = filteredData?.[dataIndex];

                    return <div>{basicDateTimeStamp(created_on)}</div>;
                },
            },
        },
        {
            name: "user_name",
            label: "By",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { user_name } = filteredData?.[dataIndex];

                    return <div>{user_name}</div>;
                },
            },
        },
    ];

    if (loading) {
        return (
            <Backdrop
                css={backdropStyles}
                open={loading}
                onClick={() => {
                    setLoading(false);
                }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    } else {
        return (
            <>
                <h2 style={{ fontSize: "1.25rem" }}>Total: {filteredData?.length}</h2>
                <p>These are all the call logs for this project.</p>
                <MUIDataTable data={filteredData} options={options} columns={columns} />
            </>
        );
    }
}
