// with advanced search there are a lot of associated components:
// SearchBuilder.js - contains logic that maps the search object to the UI form elements
// TextSearchInputField.js - this has the logic and UI for the DSL component (the hammer time)
// searchFactory.js - the search object and builds it when necessary
// searchStringBuilder.js - builds the query string
// queryBuilder.js - builds the search string URL for saving to DB
// queryParser.js - deconstructs the URL back into the search object
// ExpertResults.js - display the search results
// query.js - is the file that does the backend querying

// Steps for modifying the search object
// -------------------------------------
// 1) make changes in searchFactory.js so the object looks like it should
// 2) make changes in search builder so UI and search object are synced
// 3) make changes in stringBuilder, queryBuilder, queryParser so those elements
//    also accurately reflect the updated search object
// 4) will either need to add to or modify query.js so that the backend searches for the new element
//    correctly

// react imports
import React, { useState, useEffect, useCallback, useContext } from "react";

// MUI
// general
import { Grid, Button, TextField, Backdrop, CircularProgress, Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
// dialog
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
// icons
import {
    Save as SaveIcon,
    CheckCircleOutline as CheckCircleOutlineIcon,
    FilterList as FilterListIcon,
    Refresh as RefreshIcon,
} from "@mui/icons-material";

// npm imports
import axios from "axios";

// custom components
import ExpertResults from "../components/ExpertResults";
import PageTitle from "../components/PageTitle";
import TextSearchInputField from "../components/TextSearchInputField.js";
import SearchBuilder from "../components/SearchBuilder-v2";
import { authContext } from "../context/auth";

// styles
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "../css/styles.css";

// utilities
import { stringBuilder } from "../utils/searchStringBuilder";
import { queryBuilder } from "../utils/queryBuilder";
import { queryParser } from "../utils/queryParser";
import { getDefaultSearchObj } from "../utils/searchFactory";

// assets
import Hammertime from "../img/hammertime.gif";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    list: {
        width: 650,
        padding: "15px",
    },
    fullList: {
        width: "auto",
    },
    accordionSummary: {
        backgroundColor: "#f2f2f2",
        borderBottom: "1px solid #eaeaea",
        fontWeight: "bold",
    },
    label: {
        color: "#999",
        fontSize: "1rem",
        textAlign: "left",
        fontWeight: "normal",
    },
    criteria: {
        color: "#325C55",
        fontSize: ".9rem",
        textAlign: "left",
        fontWeight: "normal",
        marginBottom: "0",
    },
    result: {
        color: "#19857b",
        fontSize: "1rem",
        textAlign: "left",
    },
    heading: {
        color: "#000",
        fontSize: "1rem",
        textAlign: "left",
        fontWeight: "normal",
        marginBottom: "15px",
    },
    description: {
        color: "#666",
        fontSize: ".75rem",
        textAlign: "left",
    },
    iconSmall: {
        fontSize: ".85rem",
    },
    tagSearchContainer: {
        overflow: "hidden",
        backgroundColor: "#FAFAFA",
        padding: "20px",
        border: "1px solid #F5F5F5",
        borderRadius: "3px",
    },
    zeroMargin: {
        margin: "0",
    },
    btnContainer: {
        marginBottom: "1.5rem",
    },
    hammerTime: {
        transition: "1.5s",
        opacity: "0",
        width: "auto",
        height: "2rem",
        cursor: "pointer",
    },
    hammerTimeHover: {
        transition: "1.5s",
        width: "auto",
        height: "2rem",
        opacity: "1",
        cursor: "pointer",
    },
    easterEggContainer: {
        border: "1px solid #FFD580",
        borderRadius: "3px",
        fontWeight: "bold",
        padding: theme.spacing(1),
        marginBottom: "20px",
    },
    easterEgg: {
        backgroundColor: "#FFD580",
        borderBottom: "1px solid #eaeaea",
        fontWeight: "bold",
        padding: theme.spacing(2),
    },
}));

function AdvancedSearchNext(props) {
    // console.log(" :: advanced search rerendering");
    const classes = useStyles();
    const { history } = props;
    const { auth } = useContext(authContext);
    // this is the data for expert results
    const [searchData, setSearchData] = useState([]);
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [activeBtn, setActiveBtn] = useState(true);
    const [hammerTimeHover, setHammerTimeHover] = useState(false);
    const [openSaveSearch, setOpenSaveSearch] = useState(false);
    const [openSearchBuilder, setOpenSearchBuilder] = useState(false);
    const [enableSearchOptions, setEnableSearchOptions] = useState(true);
    const [enableFilterBtn, setEnableFilterBtn] = useState(false);
    const [noResultsDisplay, setNoResultsDisplay] = useState("none");
    const [resultsDisplay, setResultsDisplay] = useState("none");
    const [saveSearchFieldDisplay, setSaveSearchFieldDisplay] = useState("block");
    const [saveSearchSuccessDisplay, setSaveSearchSuccessDisplay] = useState("none");
    const [isListSearch, setIsListSearch] = useState(false);
    const [project, setProject] = useState({
        id: "",
        title: "",
        display: false,
    });
    const [saved, setSaveSearch] = useState({
        name: "",
        query: props.location.search,
        created_by_id: auth.data.id,
        is_public: true,
    });
    // const [displayEasterEgg, setDisplayEasterEgg] = useState(hammerTime ? "block" : "none");

    const defaultSearchObj = getDefaultSearchObj(auth.data.id);
    // console.log(defaultSearchObj, " :: default obj before being set");

    // Create Empty Search Object to populate
    const [search, setSearch] = useState(defaultSearchObj);
    const [hammerTime, setHammerTime] = useState(defaultSearchObj.searchFields.dsl);

    // create list search
    const [listSearch, setListSearch] = useState("");

    // FETCH CALLS
    const fetchProjectById = useCallback(async (id) => {
        const pData = await axios.get(`/api/project/find?id=${id}`);
        if (pData.status === 200 && pData.data && pData.data.data) {
            if (pData.data.data.projectData.length === 0) return;
            const proj = pData.data.data.projectData[0];

            setProject({
                id: proj.id,
                title: proj.title,
                display: true,
            });
            // console.log("Project Title Fetched: ", proj.title);
            // console.log("Project ID Fetched: ", proj.id);
        }
    }, []);

    const fetchSearchResults = async (postData) => {
        // console.log(" :: in fetchSearchResults");
        setOpenBackdrop(true);
        // console.log("Right Before POST: ", JSON.stringify(postData));
        await axios
            .post("/api/query/search", postData)
            .then((result) => {
                // console.log("RESULT?: ", result.data);
                if (result?.data?.data?.experts) {
                    const { experts: results, listSearch } = result?.data?.data;

                    setIsListSearch(listSearch);

                    // console.log("YAY WE HAVE SEARCH RESULTS! ", results);
                    setSearchData(results);
                    // setHaveResultsLoaded(true);
                    setOpenSearchBuilder(false);
                    if (results.length > 0) {
                        setResultsDisplay("block");
                        setNoResultsDisplay("none");
                    } else {
                        setResultsDisplay("none");
                        setNoResultsDisplay("block");
                    }
                    setOpenBackdrop(false);
                    setEnableSearchOptions(false);
                    setEnableFilterBtn(false);
                    const urlString = queryBuilder(postData);
                    // console.log(urlString, " :: urlString");
                    setSaveSearch({
                        ...saved,
                        query: urlString,
                    });
                    history.push(props.location.pathname + urlString);
                } else {
                    setOpenBackdrop(false);
                    setResultsDisplay("none");
                    setSearchData(result.data.data.experts);
                    alert("There was an error in your query...please check your search");
                    setOpenSearchBuilder(true);
                }
            })
            .catch((result) => {
                console.log("API not done yet, 404 Error:", result);
            });
    };

    const fetchListSearchResults = async () => {
        setOpenBackdrop(true);

        await axios
            .post("/api/query/listSearch", { listSearch })
            .then((result) => {
                // console.log("RESULT?: ", result.data);
                if (result?.data?.data?.experts) {
                    const { experts: results, listSearch } = result?.data?.data;

                    setIsListSearch(listSearch);

                    setSearchData(results);
                    setOpenSearchBuilder(false);

                    if (results?.length > 0) {
                        setResultsDisplay("block");
                        setNoResultsDisplay("none");
                    } else {
                        setResultsDisplay("none");
                        setNoResultsDisplay("block");
                    }

                    setOpenBackdrop(false);
                    setEnableSearchOptions(false);
                    setEnableFilterBtn(false);

                    setSaveSearch({
                        ...saved,
                        query: "",
                    });

                    history.push(props.location.pathname);
                } else {
                    setOpenBackdrop(false);
                    setResultsDisplay("none");
                    // setSearchData(result.data.data.experts);
                    alert("There was an error in your query...please check your search");
                    setOpenSearchBuilder(true);
                }
            })
            .catch((result) => {
                console.log("API not done yet, 404 Error:", result);
            });
    };

    // get project info
    const getProjectDataIfPresent = (projectId) => {
        // console.log(" :: inside get project data if present");
        // console.log(search, " :: search");
        if (projectId) {
            // console.log("getting project data");
            fetchProjectById(projectId);
        }
    };

    // PAGE LOAD
    const deconstructIncomingUrlParams = async (dynamicUrlString) => {
        // console.log(" :: in deconstructUrlParams");
        // console.log(props.location.search, " :: props.location.search");
        const urlParams = new URLSearchParams(dynamicUrlString);
        // console.log(urlParams, " :: urlParams");
        // const paramEntries = urlParams.entries();
        // console.log(paramEntries, " :: paramEntries");
        // for (const [key, value] of paramEntries) {
        if (urlParams.has("pid")) {
            // console.log(":: inside pid");
            // do project id things
            const newSearchObj = {
                ...search,
                metaData: {
                    ...search.metaData,
                    projectId: urlParams.get("pid"),
                },
            };
            getProjectDataIfPresent(newSearchObj.metaData.projectId);
            setSearch(newSearchObj);
        } else if (props.location.search) {
            // console.log(" :: inside else block of deconstruct incoming URLS");
            const pageUrl = props.location.search;
            const formattedPageUrl = pageUrl.replace("?", "");
            try {
                const incomingSearchObj = await queryParser(formattedPageUrl, auth.data.id);
                setSearch(incomingSearchObj);
                setHammerTime(incomingSearchObj.searchFields.dsl);
                fetchSearchResults(incomingSearchObj);
                getProjectDataIfPresent(incomingSearchObj.metaData.projectId);
            } catch (err) {
                console.log(err, " :: error parsing incoming url");
            }
        }
    };

    // run search on query params
    useEffect(() => {
        // console.log(" :: useEffect being called");
        deconstructIncomingUrlParams(props.location.search);
    }, []);

    // Fun DSL stuff
    const handleMouseEnter = () => {
        setHammerTimeHover(true);
    };

    const handleMouseLeave = () => {
        setHammerTimeHover(false);
    };

    const handleHammerTime = () => {
        const newHammerTime = !hammerTime;
        setHammerTime(newHammerTime);
    };

    // HANDLE FUNCS
    const handleClose = () => {
        setOpenSaveSearch(false);
        setOpenSearchBuilder(false);
    };

    const handleChangeSaveSearch = (event) => {
        const { target } = event;
        const { value, name } = target;
        setSaveSearch({
            ...saved,
            [name]: value,
        });
        if (value.length > 3) {
            setActiveBtn(false);
        } else {
            setActiveBtn(true);
        }
    };

    const resetSearch = () => {
        history.push("/search");
        setProject({
            id: "",
            title: "",
            display: false,
        });
        setSearch(defaultSearchObj);
        setOpenBackdrop(false);
        setEnableSearchOptions(true);
        setNoResultsDisplay("block");
        setResultsDisplay("none");
        setOpenSearchBuilder(true);
    };

    const openSaveSearchDialog = () => {
        setActiveBtn(true);
        setSaveSearchFieldDisplay("block");
        setSaveSearchSuccessDisplay("none");
        setSaveSearch({
            ...saved,
            name: "",
        });
        setOpenSaveSearch(true);
    };

    const submitSavedSearch = () => {
        // ASYNC POST TO SAVE SEARCH OBJECT
        const url = "/api/savesearch/create";
        axios
            .post(url, saved)
            .then((result) => {
                if (result.status === 200) {
                    console.log("SAVE SEARCH POST DATA: ", saved);
                    setSaveSearchFieldDisplay("none");
                    setSaveSearchSuccessDisplay("block");
                    setTimeout(function () {
                        setOpenSaveSearch(false);
                    }, 2000);
                }
            })
            .catch(() => {
                console.log(`API not done yet, 404 Error: ${JSON.stringify(data)}`);
            });
    };

    const submitSearchQuery = (searchObj = search) => {
        fetchSearchResults(searchObj);
    };

    const submitListSearchQuery = () => {
        fetchListSearchResults();
    };

    // RENDER LOGIC
    return (
        <div className={classes.root}>
            <PageTitle title="Search" />
            <Backdrop
                transitionDuration={1500}
                className={classes.backdrop}
                open={openBackdrop}
                onClick={() => {
                    setOpenBackdrop(false);
                }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container spacing={3}>
                <Grid item sm={12} xs={12}>
                    <img
                        src={Hammertime}
                        onClick={handleHammerTime}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        className={hammerTimeHover || hammerTime ? classes.hammerTimeHover : classes.hammerTime}
                    />
                    <div className={classes.criteria}>
                        <p className={classes.zeroMargin}>
                            {isListSearch ? (
                                <span>LIST SEARCH</span>
                            ) : (
                                <span
                                    dangerouslySetInnerHTML={{ __html: `SEARCH CRITERIA - ${stringBuilder(search)}` }}
                                ></span>
                            )}
                        </p>
                    </div>
                </Grid>
                <Grid item sm={12} xs={12}>
                    <Box display={hammerTime ? "block" : "none"}>
                        <div className={classes.easterEggContainer}>
                            <div className={classes.easterEgg}>
                                <Typography>{`Are You Sure You're Ready For This?`}</Typography>
                            </div>
                            <TextSearchInputField
                                name="keywords"
                                search={search}
                                setSearch={setSearch}
                                setHammerTime={setHammerTime}
                                fetchSearchResults={fetchSearchResults}
                                label="BETA Feature: Use at your own peril"
                            />
                        </div>
                    </Box>
                </Grid>
                <Grid item sm={4} xs={12} className={classes.btnContainer}>
                    <Button
                        disabled={enableFilterBtn}
                        size="small"
                        className={useStyles.submit}
                        color="primary"
                        fullWidth
                        onClick={() => setOpenSearchBuilder(true)}
                        variant="contained"
                    >
                        <FilterListIcon className={classes.iconSmall} />
                        Filter/Edit
                    </Button>
                </Grid>
                <Grid item sm={4} xs={12} className={classes.btnContainer}>
                    <Button
                        disabled={enableSearchOptions || isListSearch}
                        size="small"
                        className={useStyles.submit}
                        color="primary"
                        fullWidth
                        onClick={openSaveSearchDialog}
                        variant="contained"
                    >
                        <SaveIcon className={classes.iconSmall} />
                        Save
                    </Button>
                </Grid>
                <Grid item sm={4} xs={12} className={classes.btnContainer}>
                    <Button
                        disabled={enableSearchOptions}
                        size="small"
                        className={useStyles.submit}
                        color="primary"
                        fullWidth
                        onClick={resetSearch}
                        variant="contained"
                    >
                        <RefreshIcon className={classes.iconSmall} />
                        Reset
                    </Button>
                </Grid>
            </Grid>
            <Box display={noResultsDisplay}>
                <p>NO RESULTS FOUND</p>
            </Box>
            <Box display={resultsDisplay}>
                <Grid container spacing={3}>
                    <Grid item sm={12} xs={12}>
                        <ExpertResults
                            data={searchData}
                            url={props.location.search}
                            project={project}
                            history={props.history}
                            isListSearch={isListSearch}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Dialog
                open={openSearchBuilder}
                maxWidth={"xl"}
                onClose={handleClose}
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                        <Typography>Build Your Search</Typography>
                    </Box>
                </DialogTitle>
                <SearchBuilder
                    user={auth.data}
                    search={search}
                    listSearch={listSearch}
                    setListSearch={setListSearch}
                    setSearch={setSearch}
                    // tags={tags}
                    handleClose={handleClose}
                    submitSearchQuery={submitSearchQuery}
                    resetSearch={resetSearch}
                    submitListSearchQuery={submitListSearchQuery}
                />
            </Dialog>
            <Dialog
                open={openSaveSearch}
                onClose={handleClose}
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{auth.data.fname}, you must name this search</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        onChange={handleChangeSaveSearch}
                        margin="dense"
                        name="name"
                        label="Name (Required)"
                        type="text"
                        fullWidth
                        value={saved.name}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Close
                    </Button>
                    <Box display={saveSearchFieldDisplay}>
                        <Button disabled={activeBtn} onClick={submitSavedSearch} className={classes.link}>
                            Save
                        </Button>
                    </Box>
                    <Box display={saveSearchSuccessDisplay}>
                        <Button disabled className={classes.link}>
                            <CheckCircleOutlineIcon /> Saved!
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </div>
    );
}
export default AdvancedSearchNext;
