// react
import * as React from "react";

// auth
import { authContext } from "../../context/auth";

// mui
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

// mui - icons
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

// MUI
// style imports
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

// npm
import axios from "axios";

// style
const impersonationUserRowStyles = css`
    border: 1px solid #8966ad;
    margin-bottom: 0.5rem;
    color: #ffffff;
    background-color: #8966ad;
    padding: 0.5rem 2rem;
    border-radius: 3px;
    font-size: 1rem;
    letter-spacing: 1.1px;
    width: fit-content;
`;

const errorMsgStyles = css`
    color: red;
    margin-top: 1.25rem;
`;

function ImpersonationDialog(props) {
    // =====
    // PROPS
    // =====

    // console.log(props, " :: props");
    const {
        openDialog,
        setOpenDialog,
        currentUser: { currentUser, status },
        impersonationData,
        projectId,
        loadImpersonationList,
    } = props;
    const { globalTeam, projectTeam, surveyData } = impersonationData;

    // ==========================
    // DERIVED STATE && CONSTANTS
    // ==========================

    // derived
    const globalTeamDropdown = [...globalTeam.map((p) => ({ value: p.user_id, label: p.user_name }))];

    // reshape data to work with dropdowns
    const projectTeamDropdown = [...projectTeam.map((person) => ({ value: person.user_id, label: person.user_name }))];

    const surveyDropdown = [...surveyData.map((s) => ({ value: s.survey_id, label: s.survey_name }))];

    // constants
    const DEFAULT_ERROR_STATE = {
        error: false,
        elementSet: new Set(),
        message: "Must Select User To Impersonate",
    };

    // =====
    // STATE
    // =====

    const [impersonatorId, setImpersonatorId] = React.useState("");
    const [impersonatedId, setImpersonatedId] = React.useState("");
    const [surveyId, setSurveyId] = React.useState("");
    const [error, setError] = React.useState(DEFAULT_ERROR_STATE);

    // auth
    const { auth } = React.useContext(authContext);

    // ============
    // HANDLE FUNCS
    // ============

    function handleCloseDialog() {
        setError(DEFAULT_ERROR_STATE);
        setOpenDialog(false);
    }

    function handleSaveImpersonator() {
        if (!impersonatorId) {
            const elementSet = error.elementSet;
            elementSet.add("impersonator");
            setError({ ...error, error: true, elementSet });
        }

        if (!impersonatedId) {
            const elementSet = error.elementSet;
            elementSet.add("impersonated");
            setError({ ...error, error: true, message: "Must Select User To Be Impersonated", elementSet });
        }

        if (!error.error) {
            const postObj = {
                projectId,
                idOfImpersonator: impersonatorId,
                idOfImpersonated: impersonatedId,
                surveyId,
                userId: auth.data.id,
            };

            axios
                .post("/api/project/addImpersonator", postObj)
                .then((res) => {
                    console.log(res, " :: res");
                    if (res?.data?.data?.status) {
                        if (res?.data?.data?.msg.includes("duplicate key")) {
                            error.elementSet.add("general");
                            setError({ ...error, error: true, message: "Impersonation Pairing Already Exists" });
                        } else {
                            error.elementSet.add("general");
                            setError({ ...error, error: true, message: "There was an error adding impersonators" });
                        }
                    } else {
                        handleCloseDialog();
                        loadImpersonationList();
                    }
                })
                .catch((err) => {
                    console.log(err, " :: err");
                });
        }
    }

    function handleImpersonatorChange(event) {
        setError(DEFAULT_ERROR_STATE);
        setImpersonatorId(event.target.value);
    }

    function handleImpersonatedChange(event) {
        setError(DEFAULT_ERROR_STATE);
        setImpersonatedId(event.target.value);
    }

    function handleSurveyChange(event) {
        setError(DEFAULT_ERROR_STATE);
        setSurveyId(event.target.value);
    }

    function handleDelete() {
        const didConfirm = confirm("Are you sure you want to delete this impersonation?");

        if (didConfirm) {
            axios
                .post("/api/project/deleteImpersonator", {
                    projectId,
                    idOfImpersonator: currentUser.person_id_of_impersonator,
                    idOfImpersonated: currentUser.person_id_of_impersonated,
                    surveyId: currentUser.survey_id,
                    userId: auth.data.id,
                })
                .then((res) => {
                    handleCloseDialog();
                    loadImpersonationList();
                })
                .catch((err) => {
                    console.log(err, " :: err");
                });
        }
    }

    // ======
    // RENDER
    // ======

    return (
        <div>
            <Dialog
                open={openDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle id="alert-dialog-title">{"Set Impersonating Pair"}</DialogTitle>
                <DialogContent>
                    {status === "add" && (
                        <div>
                            <div style={{ marginBottom: "1.25rem" }}>
                                <TextField
                                    select
                                    label="Sourcing Associate"
                                    value={impersonatorId}
                                    sx={{ width: "100%" }}
                                    margin="dense"
                                    error={error.elementSet.has("impersonator") && error.error}
                                    helperText={error.elementSet.has("impersonator") && error.message}
                                    onChange={handleImpersonatorChange}
                                >
                                    {globalTeamDropdown.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                            <div style={{ marginBottom: "1.25rem", fontWeight: "700" }}>
                                SHOULD SEND OUTREACH AS <ArrowDownwardIcon />
                            </div>
                            <div style={{ marginBottom: "1.25rem" }}>
                                <TextField
                                    select
                                    name="impersonated"
                                    label="Outreach Associate"
                                    value={impersonatedId}
                                    sx={{ width: "100%" }}
                                    margin="dense"
                                    error={error.elementSet.has("impersonated") && error.error}
                                    helperText={error.elementSet.has("impersonated") && error.message}
                                    onChange={handleImpersonatedChange}
                                >
                                    {projectTeamDropdown.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                            <div style={{ marginBottom: "1.25rem", fontWeight: "700" }}>
                                USING THIS SCREENER <ArrowDownwardIcon />
                            </div>
                            <div>
                                <TextField
                                    select
                                    name="survey"
                                    label="Survey"
                                    value={surveyId}
                                    sx={{ width: "100%" }}
                                    margin="dense"
                                    // error={error.elementSet.has("survey") && error.error}
                                    // helperText={error.elementSet.has("survey") && error.message}
                                    onChange={handleSurveyChange}
                                >
                                    {surveyDropdown.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {`${option.value}: ${option.label}`}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                {error.elementSet.has("general") && <div css={errorMsgStyles}>{error.message}</div>}
                            </div>
                        </div>
                    )}
                    {status === "view" && (
                        <div>
                            <div style={{ marginBottom: "1.25rem" }} css={impersonationUserRowStyles}>
                                {currentUser.impersonator_name}
                            </div>
                            <div style={{ marginBottom: "1.25rem", fontWeight: "700" }}>
                                IS SENDING OUTREACH AS <ArrowDownwardIcon />
                            </div>
                            <div style={{ marginBottom: "1.25rem" }} css={impersonationUserRowStyles}>
                                {currentUser.impersonated_name}
                            </div>
                            <div style={{ marginBottom: "1.25rem", fontWeight: "700" }}>
                                USING THIS SCREENER <ArrowDownwardIcon />
                            </div>
                            <div css={impersonationUserRowStyles}>
                                {currentUser.survey_name || "(No Survey Selected)"}
                            </div>
                        </div>
                    )}
                </DialogContent>
                <DialogActions
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingLeft: "1.5rem",
                        paddingRight: "1rem",
                    }}
                >
                    <div>
                        {status === "view" && (
                            <Button sx={{ color: "red" }} onClick={handleDelete}>
                                ⚠️ Delete ⚠️
                            </Button>
                        )}
                    </div>
                    <div>
                        <Button onClick={handleCloseDialog}>Close</Button>
                        {status === "add" && (
                            <Button onClick={handleSaveImpersonator} autoFocus>
                                Save
                            </Button>
                        )}
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ImpersonationDialog;
