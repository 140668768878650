// String builder takes the search object and spits out a string representing the 'active' elements on the search object

// STRING BUILDER
// OPTIONS
const checkboxStringBuilder = (checkboxInputs, queryDisplayArray, projectId) => {
    checkboxInputs.forEach((input) => {
        if (input.value) {
            queryDisplayArray.push(`${input.label.toUpperCase()}: Yes`);
            if (input.name === "remove_proj_experts") {
                queryDisplayArray.push(`Project ID: ${projectId}`);
            }
        }
    });
};

const radioBtnStringBuilder = (radioBtnInputs, queryDisplayArray) => {
    radioBtnInputs.forEach((input) => {
        const [activeItem] = input.items.filter((item) => {
            return item.value == input.currentValue;
        });
        queryDisplayArray.push(`${input.label.toUpperCase()}: ${activeItem.label}`);
    });
};

const optionsStringBuilder = (options, queryDisplayArray, projectId) => {
    checkboxStringBuilder(options.checkbox, queryDisplayArray, projectId);
    radioBtnStringBuilder(options.radio, queryDisplayArray);
};

// SEARCHFIELDS
const stringTypeInputStringBuilder = (stringFields, queryDisplayArray) => {
    for (const [name, inputs] of Object.entries(stringFields)) {
        inputs.forEach((input) => {
            if (input.active && input.value) {
                queryDisplayArray.push(`${name.toUpperCase()}(${input.boolType.toUpperCase()}): ${input.value}`);
            }
        });
    }
};

const objectTypeInputStringBuiler = (objectFields, queryDisplayArray) => {
    for (const [name, inputs] of Object.entries(objectFields)) {
        inputs.forEach((input) => {
            if (input.active && input.value) {
                const objectArray = [];
                input.value.forEach((object) => {
                    objectArray.push(object.value);
                });
                queryDisplayArray.push(
                    `${name.toUpperCase()}(${input.boolType.toUpperCase()}): ${objectArray.join(", ")}`
                );
            }
        });
    }
};

const inputStringBuilder = (searchFields, queryDisplayArray) => {
    stringTypeInputStringBuilder(searchFields.string, queryDisplayArray);
    objectTypeInputStringBuiler(searchFields.object, queryDisplayArray);
};

// FILTERS
const filterStringBuilder = (filters, queryDisplayArray) => {
    if (filters.expertRateSlider.active) {
        queryDisplayArray.push(`MIN: ${filters.expertRateSlider.currentMin}`);
        queryDisplayArray.push(`MAX: ${filters.expertRateSlider.currentMax}`);
    }
};

export const stringBuilder = (search) => {
    // console.log(" :: rendering stringBUilder");
    // console.log(search, " :: searchStringBuilder");
    const queryDisplayArray = [];
    const {
        options,
        searchFields,
        filters,
        metaData: { projectId },
    } = search;
    optionsStringBuilder(options, queryDisplayArray, projectId);
    inputStringBuilder(searchFields, queryDisplayArray);
    filterStringBuilder(filters, queryDisplayArray);
    return queryDisplayArray.join(" / ");
};
