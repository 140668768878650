import React, { useState, useContext, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, Button, DialogActions, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import axios from "axios";
import { authContext } from "../context/auth";
export default function SendScreener(props) {
    const _state = props.state_manager;
    const open = props.open;
    const { match } = props.props;
    const { auth } = useContext(authContext);

    const [disableScreenerButtons, setDisableScreenerButtons] = useState(true);

    // Helper Functions

    function bulkAddScreenerEvent(id) {
        // setSelectedSurvey({
        //     ...selectedSurvey,
        //     id,
        // });
        _state.set.selectedSurvey({
            ..._state.get.selectedSurvey,
            id,
        });
        _state.set.emailContent(_state.templates.screenerTemplate);
        // setTemplate("screener");
        // setEmailContent(_state.templates.screenerTemplate);
        const surveyObject = {
            project_id: parseInt(match.params.id, 10),
            survey_id: id,
            created_by: auth.data.id,
            experts: _state.get.selected.map((e) => e.id),
        };
        console.log("ADDED EXPERTS SURVEYS: ", surveyObject);
        // console.log("Did we capture all data for onboarding/screeners? ", `${JSON.stringify(surveyObject)}`);
        const url = "/api/survey/addExperts";
        axios
            .post(url, surveyObject, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200 && result.data) {
                    // console.log("Experts added to screener successful: ", result.statusText);
                    _state.set.success(true);
                    // Enable button options after successful screener select and attach
                    setDisableScreenerButtons(false);

                    // _state.set.selectedSurvey({
                    //     ..._state.get.selectedSurvey,
                    //     id: "",
                    //     token: "",
                    // });
                } else {
                    console.log(`Good POST, but something went wrong: ${JSON.stringify(surveyObject)}`);
                }
            })
            .catch((result) => {
                console.log("API not done yet, 404 Error:", result);
            });
    }

    function getSelectedExpertsForCsv() {
        // First filter out ineligible opt outs and dncs
        const allExperts = _state.get.csv.all_experts.filter((e) => !e.dnc && !e.opted_out);
        console.log("FILTERED EXPERTS BEFORE SELECTED: ", allExperts);
        if (_state.get.selected.length > 0) {
            const csvArray = [];
            for (let i = 0; i < _state.get.selected.length; i++) {
                const expertObject = allExperts.find((item) => item.id === _state.get.selected[i]);
                // If NOT undefined then add to array
                if (expertObject) {
                    csvArray.push(expertObject);
                }
            }
            // console.log("FINAL FILTERED EXPERTS", csvArray);
            _state.set.csv({
                ..._state.get.csv,
                selected_experts: csvArray,
            });
        }
    }

    function onboardWithScreener() {
        // Onboard with Screener selection Modal
        // Gather selected Experts
        // closeScreenerModal();
        _state.switch.sendScreenerModal();
        setDisableScreenerButtons(true);
        getSelectedExpertsForCsv();
        _state.switch.defaultOutreachEmailsModal();
        _state.set.template("onboard_screener");
        console.log("Screener Modal - Onboarding Flow: ", _state.get.selectedSurvey.id);
    }

    function createBulkScreenerSend(templateName) {
        // Gather selected Experts
        // closeScreenerModal();
        _state.switch.sendScreenerModal();
        setDisableScreenerButtons(true);
        getSelectedExpertsForCsv();
        // _state.set.outreachTemplate(true);
        _state.switch.outreachModal();
        _state.set.template(templateName);

        console.log("Screener Only Template? ", templateName);
    }

    function closeScreenerModal() {
        _state.set.selectedSurvey({
            ..._state.get.selectedSurvey,
            id: "",
        });
        _state.switch.sendScreenerModal();
        setDisableScreenerButtons(true);
    }

    // useEffect(() => {
    //     if (_state.get.selectedSurvey.id !== "")
    //         _state.set.selectedSurvey({
    //             ..._state.get.selectedSurvey,
    //             id: "",
    //         });
    // }, []);

    if (!props.data) {
        return <div></div>;
    } else {
        // console.log(props.data);
        const data = parseProjectData(props.data);

        return (
            <Dialog
                open={open}
                onClose={closeScreenerModal}
                fullWidth
                maxWidth="sm"
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={closeScreenerModal}
                        sx={{ float: "right" }}
                        aria-label="close"
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                    Select the screener to attach to the selected experts
                </DialogTitle>
                <DialogContent>
                    {data.surveys.length < 1 && (
                        <Typography sx={{ color: "red", fontSize: ".75rem", marginTop: "0" }}>
                            This project has no surveys associated with it.
                        </Typography>
                    )}
                    {data.surveys.map(function (s, idx) {
                        return (
                            <Button
                                key={s.id}
                                sx={
                                    _state.get.selectedSurvey.id === s.id
                                        ? {
                                              color: "#000",
                                              padding: "0",
                                              textDecoration: "none",
                                              fontSize: "1rem",
                                              fontWeight: "bold",
                                              textTransform: "none",
                                              minWidth: "auto",
                                              textAlign: "left",
                                              display: "block",
                                              lineHeight: "120%",
                                              marginBottom: "15px",
                                          }
                                        : {
                                              color: "#523197",
                                              padding: "0",
                                              textDecoration: "none",
                                              fontSize: "1rem",
                                              fontWeight: "normal",
                                              textTransform: "none",
                                              minWidth: "auto",
                                              textAlign: "left",
                                              display: "block",
                                              lineHeight: "120%",
                                              marginBottom: "15px",
                                          }
                                }
                                onClick={() => bulkAddScreenerEvent(s.id)}
                            >
                                {s.title}
                            </Button>
                        );
                    })}
                </DialogContent>
                <DialogActions>
                    <Button disabled={disableScreenerButtons} onClick={onboardWithScreener}>
                        Onboarding Flow
                    </Button>
                    <Button disabled={disableScreenerButtons} onClick={() => createBulkScreenerSend("screener")}>
                        Screener Only
                    </Button>
                    <Button onClick={closeScreenerModal}>Nevermind</Button>
                </DialogActions>
            </Dialog>
        );
    }

    // Helpers

    // TODO - MOVE PARSEPROJECTDATA to backend
    function parseProjectData(pData) {
        if (pData.message && pData.data && pData.data.projectData) {
            // console.log("INside if");
            if (pData.data.projectData.length === 0) return;
            // console.log("FETCHED PROJECT DATA: ", pData.data.projectData);
            // console.log(pData.data.projectData.expertsAccepted, " :: pData.data.data.expertsAccepted");
            const expertCount =
                pData.data.projectData.expertCount.length > 0
                    ? pData.data.projectData.expertCount[0].experts_count
                    : "0";
            const expertsAccepted =
                pData.data.projectData.expertsAccepted.length > 0
                    ? pData.data.projectData.expertsAccepted[0].experts_accepted
                    : "0";
            const screenedCount =
                pData.data.projectData.screenedCount.length > 0
                    ? pData.data.projectData.screenedCount[0].num_experts_screened
                    : "0";
            const onHoldCount =
                pData.data.projectData.onHoldCount.length > 0
                    ? pData.data.projectData.onHoldCount[0].num_experts_on_hold
                    : "0";
            const expertsScheduled =
                pData.data.projectData.expertsScheduled.length > 0
                    ? pData.data.projectData.expertsScheduled[0].experts_scheduled
                    : "0";
            const expertsCompleted =
                pData.data.projectData.expertsCompleted.length > 0
                    ? pData.data.projectData.expertsCompleted[0].experts_completed
                    : "0";
            const sentToClient =
                pData.data.projectData.sentToClient.length > 0
                    ? pData.data.projectData.sentToClient[0].sent_to_client
                    : "0";
            const clientSelected =
                pData.data.projectData.clientSelected.length > 0
                    ? pData.data.projectData.clientSelected[0].client_selected
                    : "0";
            const sentToCompliance =
                pData.data.projectData.sentToCompliance.length > 0
                    ? pData.data.projectData.sentToCompliance[0].sent_to_compliance
                    : "0";
            const project = pData.data.projectData.projectData[0];
            const tags = pData.data.projectData.tags.map((tag) => {
                return { label: tag.name, value: tag.tag_id };
            });
            const specialty = pData.data.projectData.specialty.map((s) => {
                return { label: s.name, value: s.specialty_id };
            });
            const pcc = pData.data.projectData.clientContacts.filter((item) => item.isprimary == true);
            const acc = pData.data.projectData.clientContacts.filter((item) => item.isprimary !== true);
            const apms = pData.data.projectData.additionalProjectManagers;
            const { comments } = pData.data.projectData;

            const settings = pData.data.projectData.confBridgeSettings;
            const primary = settings.filter((item) => item.isprimary !== false);
            let selectedTypeID = "1";
            let selectedTwilio = "1";
            let extNumbers = [];
            if (primary.length > 0) {
                const primaryData = primary[0];
                selectedTypeID = primaryData.type_id.toString();
                if (primaryData.data && primaryData.data.value !== undefined) {
                    selectedTwilio = primaryData.data.value;
                }
                if (Array.isArray(primaryData.data) && primaryData.data.length > 0) {
                    extNumbers = primaryData.data;
                }
            }
            const allSurveys = pData.data.projectData.surveys;
            const surveys = allSurveys.filter((s) => s.published);
            const unpublishedSurveys = allSurveys.filter((s) => !s.published);
            const attachedExperts = pData.data.projectData.experts;
            // console.log("Project DATA: ", project);
            const custom_charge = project.custom_charge;
            const custom_hourly_rate = project.custom_hourly_rate;
            const minimum_bonus = project.minimum_bonus;
            const effort_index = project.effort_index;
            const premium_pricing_model_id = project.premium_pricing_model_id;
            const grace_period = project.grace_period;
            const duration_model_id = project.duration_model_id;
            return {
                ...props.data,
                id: project.id,
                isdeleted: project.isdeleted,
                title: project.title,
                description: project.description,
                email_copy: project.email_copy,
                project_not_received_by_email: project.project_not_received_by_email,
                client_id: project.client_id,
                client_name: project.client_name,
                primary_client_contact: pcc[0],
                additional_client_contacts: acc,
                expert_ids: project.expert_ids,
                primary_project_manager: `${project.project_manager_fname} ${project.project_manager_lname}`,
                additional_project_managers: apms,
                status: project.project_status,
                status_id: project.project_status_id,
                type: project.project_type,
                priority: project.project_priority,
                case_code: project.case_code,
                calls_planned: project.calls_planned,
                price_per_unit: project.price_per_unit,
                created_on: project.created_on,
                industry: project.industry,
                conference_bridge_setting: selectedTypeID,
                twilio_bridge_setting: selectedTwilio,
                external_bridge_numbers: extNumbers,
                notifications: [],
                specialty,
                tags,
                comments,
                responses: project.responses,
                scheduled_calls: project.scheduled_calls,
                completed_calls: project.completed_calls,
                experts: attachedExperts,
                unpublishedSurveys,
                surveys,
                expertCount,
                expertsAccepted,
                expertsScheduled,
                expertsCompleted,
                sentToClient,
                clientSelected,
                sentToCompliance,
                custom_charge,
                custom_hourly_rate,
                minimum_bonus,
                effort_index,
                premium_pricing_model_id,
                screenedCount,
                onHoldCount,
                grace_period,
                duration_model_id,
            };
        }
    }
}
