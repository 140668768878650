export const paymentType = [
    {
        value: 0,
        label: "Wire",
    },
    {
        value: 1,
        label: "Check",
    },
    {
        value: 2,
        label: "International",
    },
    {
        value: 3,
        label: "PayPal",
    },
];
