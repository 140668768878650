// react
import React, { useState, useEffect } from "react";

// Utils
import { basicDateTimeStamp } from "../../Utils";

// MUI
// mui
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";

// style imports
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

// icons
import StarIcon from "@mui/icons-material/StarRate";
// import e from "cors";

// styles
const addButton = css`
    float: left;
    &:hover {
        color: #6619ff;
        cursor: pointer;
    }
`;

const header = css`
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 1rem;
`;

const templateIcon = css`
    border: 4px solid #8966ad;
    margin-right: 0.75rem;
    margin-bottom: 0.75rem;
    color: #523197;
    font-weight: 800;
    background-color: #f5f5f5;
    padding: 0.5rem;
    width: 100px;
    border-radius: 5px;
    font-size: 2rem;
    letter-spacing: 1.1px;
    text-align: center;
`;

const templateIconDone = css`
    border: 4px solid #cccccc;
    margin-right: 0.75rem;
    margin-bottom: 0.75rem;
    color: #999999;
    font-weight: 800;
    background-color: #f5f5f5;
    padding: 0.5rem;
    width: 100px;
    border-radius: 5px;
    font-size: 2rem;
    letter-spacing: 1.1px;
    text-align: center;
`;

const templateTitle = css`
    font-size: 0.75rem;
    margin-bottom: 0rem;
`;

const dialogTitle = css`
    font-size: 1.5rem;
    text-transform: uppercase;
    color: #523197;
`;

const empty_defaults = [
    {
        index: 0,
        sendgrid_id: "d-82be0b8a719347f689cde93cc27df2bd",
        type: "outreach",
        subjectline: "Your Expertise regarding...",
        description: "I am looking for someone with expertise in...",
        question: `Would you be interested in participating in this paid 30-60 minute phone consultation?`,
        updated: "",
    },
    {
        index: 1,
        sendgrid_id: "d-f439f340838e47c5953ce9290095b4b3",
        type: "followup",
        subjectline: "RE: Following up on your Expertise regarding...",
        description: "I am following up on my previous email...",
        question: "",
        updated: "",
    },
    {
        index: 2,
        sendgrid_id: "d-e116c8a037e74b1c92afa92b28fcc597",
        type: "followup",
        subjectline: "RE: Following up on your Expertise regarding...",
        description: "I am following up again on my previous email...",
        question: "",
        updated: "",
    },
    {
        index: 3,
        sendgrid_id: "d-f6f2369058164ba4b06e04de1880bedf",
        type: "followup",
        subjectline: "RE: Following up on your Expertise regarding...",
        description: "I am following up again on my previous email...",
        question: "",
        updated: "",
    },
    {
        index: 4,
        sendgrid_id: "d-145f1e1250544f09ab69ff960e399eb1",
        type: "followup",
        subjectline: "RE: Following up one last time regarding your Expertise regarding...",
        description: "I am following up one last time on my previous email...",
        question: "",
        updated: "",
    },
];

function Outreach(props) {
    // =====
    // PROPS
    // =====

    const { data, user, angle } = props;

    // =====
    // STATE
    // =====
    const [openDialog, setOpenDialog] = useState(false);
    // const [loading, setLoading] = useState(true);
    const [active, setActive] = useState({
        index: 0,
        sendgrid_id: "",
        type: "",
        subjectline: "",
        description: "",
        question: "",
        updated: "",
    });
    const [modified, setIcons] = useState({
        outreach_0: false,
        followup_1: false,
        followup_2: false,
        followup_3: false,
    });
    // const [templates, setTemplates] = useState(data);

    // ============
    // HANDLE FUNCS
    // ============

    function handleCloseDialog() {
        setOpenDialog(false);
    }

    function handleChange(evt) {
        const { target } = evt;
        const { value, name } = target;
        setActive({
            ...active,
            [name]: value,
        });
    }

    function handleSaveTemplate() {
        const loggedInUser = `${user.fname} ${user.lname}`;
        const dtStamp = new Date().toISOString();
        // Find the template in the array and update it with the active state object...
        data.every((item) => {
            if (parseInt(item.index, 10) === parseInt(active.index, 10)) {
                // item.index = active.index;
                item.sendgrid_id = active.sendgrid_id;
                item.subjectline = active.subjectline;
                item.description = active.description;
                item.question = active.question;
                item.updated = `${basicDateTimeStamp(dtStamp)} by ${loggedInUser}`;
                // Gray out the icon to indicate it's been edited...
                const name = `${item.type}_${item.index}`;
                // console.log("Selected ICON: ", name);
                setIcons({
                    ...modified,
                    [name]: true,
                });
                console.log("STEP 1 - Find and set item with active state object: ", item);
                return false;
            }
            return true;
        });
        console.log(
            "STEP 2 - Check to see if the full ARRAY was updated with active state object item before passing to changeEvent: ",
            data
        );
        // Pass modified array back to Project.js
        props.changeEvent(data);
        setOpenDialog(false);
    }

    function handleAddClick(name, idx) {
        // Grab the right template from the saved array of project templates...
        let template = {};
        if (data.length > 0) {
            console.log("PRESAVE: What does the original array look like? ", data);
            template = data.find((template) => parseInt(template.index, 10) === parseInt(idx, 10));
        } else {
            template = empty_defaults.find((template) => parseInt(template.index, 10) === parseInt(idx, 10));
        }
        console.log("PRESAVE: Which template did we select? ", { name, idx, template });
        setActive({
            index: template.index,
            sendgrid_id: template.sendgrid_id,
            type: template.type,
            subjectline: template.subjectline,
            description: template.description,
            question: template.question,
            updated: template.updated,
        });
        setOpenDialog(true);
    }

    // =========
    // PAGE LOAD
    // =========

    useEffect(() => {
        console.log("Outreach.jsx :: Inside useEffect :: ", data);
        if (angle) {
            setIcons({
                outreach_0: false,
                followup_1: false,
                followup_2: false,
                followup_3: false,
                followup_4: false,
            });
        }
    }, []);

    // ============
    // RENDER FUNCS
    // ============

    // ================
    // COMPONENT RENDER
    // ================

    return (
        <>
            <h2 css={header}>
                {angle
                    ? `TEMPLATES FOR: ${angle.tag_name} (w/ Screener - ${angle.screener_id})`
                    : "(Please Confirm Templates)"}
            </h2>
            <div css={addButton} onClick={() => handleAddClick("outreach", 0)}>
                <div css={modified.outreach_0 ? templateIconDone : templateIcon}>
                    <h6 css={templateTitle}>outreach</h6>
                    <div>
                        <StarIcon />
                    </div>
                </div>
            </div>
            <div css={addButton} onClick={() => handleAddClick("followup", 1)}>
                <div css={modified.followup_1 ? templateIconDone : templateIcon}>
                    <h6 css={templateTitle}>followup</h6>
                    <div>1</div>
                </div>
            </div>
            <div css={addButton} onClick={() => handleAddClick("followup", 2)}>
                <div css={modified.followup_2 ? templateIconDone : templateIcon}>
                    <h6 css={templateTitle}>followup</h6>
                    <div>2</div>
                </div>
            </div>
            <div css={addButton} onClick={() => handleAddClick("followup", 3)}>
                <div css={modified.followup_3 ? templateIconDone : templateIcon}>
                    <h6 css={templateTitle}>followup</h6>
                    <div>3</div>
                </div>
            </div>
            <Dialog
                open={openDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="lg"
            >
                <DialogTitle id="alert-dialog-title" css={dialogTitle}>{`Modify ${active.type} ${
                    active.index === 0 ? "Initial" : active.index
                }`}</DialogTitle>
                <DialogContent>
                    <p style={{ fontSize: "1.25rem", color: "#523197" }}>
                        Below are your editable areas. The rest of the email is standard templating that cannot be
                        modified.
                    </p>
                    <p style={{ fontSize: "1rem", color: "green" }}>
                        {active.updated
                            ? `Last updated: ${active.updated}`
                            : "PLEASE NOTE: This template has not been modified"}
                    </p>
                    <div>
                        <div style={{ marginBottom: "1.25rem" }}>
                            <TextField
                                label="Subject Line (required)"
                                name="subjectline"
                                value={active.subjectline}
                                sx={{ width: "100%" }}
                                margin="dense"
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <p style={{ fontSize: "1.15rem", color: "#666666", textAlign: "center" }}>
                                Auto-generated greeting & intro...Hello my name is...
                            </p>
                        </div>
                        <div style={{ marginBottom: "1.25rem" }}>
                            <TextField
                                multiline
                                label="Description (required)"
                                name="description"
                                value={active.description}
                                sx={{ width: "100%" }}
                                rows={4}
                                margin="dense"
                                onChange={handleChange}
                            />
                        </div>
                        <div style={{ marginBottom: "1.25rem" }}>
                            <TextField
                                label="Question (optional)"
                                name="question"
                                value={active.question}
                                sx={{ width: "100%" }}
                                margin="dense"
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <p style={{ fontSize: "1.15rem", color: "#666666", textAlign: "center" }}>
                                Auto-generated YES & NO links, closing signatures...
                            </p>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingLeft: "1.5rem",
                        paddingRight: "1rem",
                    }}
                >
                    <div>
                        <Button onClick={handleSaveTemplate} autoFocus>
                            Confirm This Template
                        </Button>
                        <Button onClick={handleCloseDialog}>Close</Button>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default Outreach;
