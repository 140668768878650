import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
// import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
    },
    appBar: {
        position: "relative",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.text.secondary,
        backgroundColor: "#f6f6bc",
        marginBottom: "15px",
    },
    list: {
        margin: theme.spacing(1),
        marginLeft: "0",
        paddingInlineStart: "0",
        listStyleType: "none",
        listStylePosition: "outside",
    },
    headlineTitle: {
        color: "#444",
        fontSize: "1.5rem",
        marginBottom: "12px",
    },
    meta: {
        color: "#000",
        fontSize: ".75rem",
        marginTop: "0",
    },
    missing: {
        color: "red",
        fontSize: ".75rem",
        marginTop: "0",
    },
    nospace: {
        fontSize: ".65rem",
        marginTop: "0",
    },
    button: {
        fontSize: ".65rem",
    },
    projects: {
        listStyleType: "none",
        borderBottom: "1px solid #D5D5D5",
        marginTop: "1rem",
    },
    buttonLink: {
        color: "#3c8864",
        textAlign: "left",
        padding: "0",
        textDecoration: "none",
        fontSize: "1.25rem",
        lineHeight: "1.25rem",
        fontWeight: "normal",
        textTransform: "none",
        minWidth: "auto",
        margin: "0",
    },
    margin: {
        margin: theme.spacing(1),
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
}));

export default function ProjectSelect(props) {
    const { open, projects, closeModalEvent, selectedProjectEvent } = props;

    const classes = useStyles();

    const selectProjectId = (id) => {
        console.log("SELECTED PROJECT ID: ", id);
        selectedProjectEvent(id);
        closeModalEvent(false);
    };

    const handleClose = () => {
        closeModalEvent(false);
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="md"
            >
                <DialogTitle id="alert-dialog-title">
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        sx={{ float: "right" }}
                        aria-label="close"
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                    Select A Project To Log Your Call Status
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item sm={12} xs={12}>
                            {projects.map(function (p, idx) {
                                return (
                                    <div key={p.project_id} className={classes.projects}>
                                        <p>
                                            <Button
                                                className={classes.buttonLink}
                                                onClick={() => selectProjectId(p.project_id)}
                                            >
                                                {p.project_title}
                                            </Button>
                                        </p>
                                    </div>
                                );
                            })}
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
}
