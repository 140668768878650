import React, { createContext, useState, useEffect } from "react";

export const authContext = createContext({});

const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({
        loading: true,
        data: JSON.parse(window.localStorage.getItem("authData")) || null,
    });

    const setAuthData = (data) => {
        setAuth({ data });
    };

    useEffect(() => {
        const storedData = JSON.parse(window.localStorage.getItem("authData"));
        if (storedData && storedData.expiration && new Date(storedData.expiration) > new Date()) {
            setAuth({
                loading: false,
                data: storedData,
            });
        } else {
            setAuth({ loading: false, data: null });
        }
    }, []);
    // 2. if object with key 'authData' exists in localStorage, we are putting its value in auth.data and we set loading to false.
    // This function will be executed every time component is mounted (every time the user refresh the page);

    useEffect(() => {
        window.localStorage.setItem("authData", JSON.stringify(auth.data));
    }, [auth.data]);
    // 1. when **auth.data** changes we are setting **auth.data** in localStorage with the key 'authData'.

    return <authContext.Provider value={{ auth, setAuthData }}>{children}</authContext.Provider>;
};

export default AuthProvider;
