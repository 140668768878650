import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
// import moment from "moment";
import { numberWithCommas, calculatePercentage } from "../Utils";
import "moment-duration-format";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(2),
        overflowX: "auto",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    h3: {
        color: "#000",
        fontSize: ".5rem",
        textTransform: "uppercase",
    },
    metricPercentage: {
        fontSize: "2rem",
        fontWeight: "bold",
        color: "#385469",
        lineHeight: "100%",
        padding: "0",
    },
    metricNumber: {
        fontSize: "2rem",
        fontWeight: "bold",
        color: "#385469",
        lineHeight: "100%",
        padding: "0",
    },
    metricNumberWarning: {
        fontSize: "2rem",
        fontWeight: "bold",
        color: theme.palette.secondary.main,
        lineHeight: "100%",
        padding: "0",
    },
    metricNumberUnimportant: {
        fontSize: "2rem",
        fontWeight: "bold",
        color: "#CCCCCC",
        lineHeight: "100%",
        padding: "0",
    },
    button: {
        margin: theme.spacing(1),
    },
    friendlyDate: {
        color: "#444",
        fontSize: ".85rem",
        textTransform: "uppercase",
    },
    addedToday: {
        color: "#4CAB7D",
        fontWeight: "bold",
    },
    stats: {
        clear: "both",
        overflow: "hidden",
        marginBottom: "20px",
    },
    statBox: {
        width: "18%",
        float: "left",
        marginRight: "10px",
        textAlign: "center",
        borderRight: "3px solid #eaeaea",
    },
    statBoxLast: {
        width: "18%",
        float: "left",
        marginRight: "10px",
        textAlign: "center",
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
}));

function OverallMetrics(props) {
    // console.log(props, " :: props in overall metrics");
    const classes = useStyles();
    // const start = new Date();
    // const today = moment(start.date);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
                <div className={classes.stats}>
                    <div className={classes.statBox}>
                        <h3 className={classes.h3}>Converted Leads</h3>
                        <div className={classes.metricNumber}>
                            <strong>{props.monthly.convertedLeads}</strong>
                        </div>
                    </div>
                    <div className={classes.statBox}>
                        <h3 className={classes.h3}>Conversion Rate</h3>
                        <div className={classes.metricPercentage}>
                            <strong>
                                {calculatePercentage(props.monthly.convertedLeads, props.monthly.monthlyLeadsAdded)}%
                            </strong>
                        </div>
                    </div>
                    <div className={classes.statBox}>
                        <h3 className={classes.h3}>Scheduled This/Next</h3>
                        <div>
                            <strong className={classes.metricNumberWarning}>{props.monthly.monthlyExternal}</strong>{" "}
                            <strong className={classes.metricNumberUnimportant}>
                                {" "}
                                / {props.monthly.nextMonthExternal}
                            </strong>
                        </div>
                    </div>
                    <div className={classes.statBox}>
                        <h3 className={classes.h3}>Today's Call Total</h3>
                        <div className={classes.metricNumberWarning}>
                            <strong>{props.monthly.dailyExternalScheduled}</strong>
                        </div>
                    </div>
                    <div className={classes.statBoxLast}>
                        <h3 className={classes.h3}>Leads Added</h3>
                        <div className={classes.metricNumber}>
                            <strong>{numberWithCommas(props.monthly.monthlyLeadsAdded)}</strong>
                        </div>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
}

export default OverallMetrics;
