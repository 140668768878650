import React, { useEffect, useState } from "react";

// MUI - general
import { TextField, Box, Button, Skeleton, Stack } from "@mui/material";

// MUI - styles
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

// utils
import { convertSecondsToMinutes, stringToFormatDateTime, stringToFormatTimeOnly } from "../utils/utils";

// npm imports
import axios from "axios";

// ======
// STYLES
// ======

const segment = css`
    background-color: #fafafa;
    border: 1px solid #e5e5e5;
    border-radius: 3px;
    margin-bottom: 15px;
    padding: 12px;
`;

const columnHeading = css`
    font-size: 0.75rem;
    color: #000;
    text-align: left;
    margin-top: 0;
    margin-bottom: 6px;
`;

const sequence = css`
    color: #444;
    font-size: 0.65rem;
    border-bottom: 3px solid #e5e5e5;
    padding-bottom: 5px;
`;

const completed = css`
    background-color: #325c55;
    padding: 4px;
    border-radius: 3px;
    font-size: 0.65rem;
    color: #fff;
    font-weight: bold;
    text-align: center;
    margin-bottom: 0.25rem;
`;

const incomplete = css`
    background-color: #cc0000;
    padding: 2px;
    font-size: 0.65rem;
    color: #fff;
    font-weight: bold;
    text-align: center;
`;

const datestamp = css`
    color: #666;
    font-size: 0.65rem;
`;
const scheduled = css`
    font-size: 0.55rem;
    color: green;
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 0;
`;

const warningCopy = css`
    color: #cc0000;
    font-size: 0.65rem;
    font-weight: bold;
    margin-top: 0;
`;

const skeletonSpacing = css`
    margin-bottom: 2rem;
`;

const SchedulingEventLog = ({ expertData }) => {
    // =====
    // PROPS
    // =====

    const { ppId } = expertData;

    // =====
    // STATE
    // =====

    const [callEvents, setCallEvents] = useState([]);
    const [loading, setLoading] = useState(true);

    // ===========
    // FETCH CALLS
    // ===========

    // call details api call
    const fetchCallDetails = async () => {
        const complete = await axios.get(`/api/project/scheduled/calls/${ppId}`);
        if (complete?.data?.uniqueConferences) {
            if (complete.data.uniqueConferences.length > 0) {
                const cevents = complete.data.uniqueConferences;
                setCallEvents(cevents);
                setLoading(false);
            } else {
                setCallEvents([]);
                setLoading(false);
            }
        }
    };

    // =========
    // PAGE LOAD
    // =========

    // load call event data
    useEffect(() => {
        fetchCallDetails();
    }, []);

    // ============
    // HANDLE FUNCS
    // ============
    function handleTranscriptBtnClick(id) {
        window.open("https://api.firstthought.io/api/twilio/tdata?id=" + id);
    }

    // WORKING ON: Brining in the utility funcs
    if (loading) {
        return (
            <Stack>
                <Skeleton variant="rectangle" width={550} height={200} />
                <div css={skeletonSpacing} />
                <Skeleton variant="rectangle" width={550} height={45} />
            </Stack>
        );
    } else {
        return (
            <div>
                {callEvents.length > 0 ? (
                    callEvents.map((callEvent, index) => {
                        const {
                            conference_sid,
                            conference_friendly_name,
                            event_start,
                            event_end,
                            hasClientJoined,
                            hasExpertJoined,
                            calls,
                            duration,
                            recordings,
                        } = callEvent;

                        const key = `key_${index}`;
                        return (
                            <div key={conference_sid}>
                                <div css={segment}>
                                    <h5 css={columnHeading}>{conference_friendly_name}</h5>
                                    <p css={sequence}>
                                        {event_start
                                            ? `${stringToFormatDateTime(event_start)} to `
                                            : "Incomplete Call: "}
                                        {event_end ? stringToFormatTimeOnly(event_end) : "In Progress"}
                                    </p>
                                    {hasClientJoined && hasExpertJoined && (
                                        <p css={completed}>BOTH EXPERT AND CLIENT JOINED</p>
                                    )}
                                    {!hasClientJoined && <p css={incomplete}>CLIENT NEVER JOINED</p>}
                                    {!hasExpertJoined && <p css={incomplete}>EXPERT NEVER JOINED</p>}
                                    {calls.map((call, index) => {
                                        const {
                                            sequence_number,
                                            type,
                                            name,
                                            activity_type,
                                            participantjoindate,
                                            participantleavedate,
                                            from_phone_number,
                                            pin,
                                        } = call;

                                        const key = `${sequence_number}_${index}`;
                                        return (
                                            <div key={key}>
                                                <p css={datestamp}>
                                                    <strong>
                                                        {name} ({type} {activity_type})
                                                    </strong>
                                                    {participantjoindate &&
                                                        ` joined at ${stringToFormatTimeOnly(participantjoindate)}`}
                                                    {participantleavedate &&
                                                        ` and left at ${stringToFormatTimeOnly(participantleavedate)}`}
                                                    {", using phone#: "}
                                                    {from_phone_number}
                                                    {" | "}
                                                    pin#: {pin}
                                                </p>
                                            </div>
                                        );
                                    })}
                                    <h5 css={scheduled}>
                                        {duration && (
                                            <span>
                                                Call Duration:{" "}
                                                {duration < 60
                                                    ? `${duration} seconds`
                                                    : `${convertSecondsToMinutes(duration)} minutes`}
                                            </span>
                                        )}
                                    </h5>
                                </div>
                                {recordings.length > 0 && (
                                    <div>
                                        <div css={columnHeading}>
                                            <TextField
                                                fullWidth
                                                id={key}
                                                label="Call Recording"
                                                margin="normal"
                                                name="recording_url"
                                                onFocus={(event) => {
                                                    event.target.select();
                                                }}
                                                value={recordings?.[0]?.recording_url}
                                            />
                                        </div>
                                        <Box textAlign="center">
                                            <Button
                                                color="primary"
                                                onClick={() => handleTranscriptBtnClick(recordings?.[0]?.recording_id)}
                                            >
                                                Download Transcription
                                            </Button>
                                        </Box>
                                    </div>
                                )}
                            </div>
                        );
                    })
                ) : (
                    <h5 css={warningCopy}>NO CALL EVENTS AVAILABLE</h5>
                )}
            </div>
        );
    }
};

export default SchedulingEventLog;
