import React, { useRef } from "react";

// MUI - general
import { Button, TextField, Box, MenuItem } from "@mui/material";

// MUI - dialog
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

// data
import { paymentType } from "./utils/data";

export default function AlertDialog(props) {
    const {
        openPaidAmntDialog,
        paidAmntError,
        setPaidAmntError,
        dropdownState,
        setDropdownState,
        confirmPaidAmnt,
        handleClosePaidAmntDialog,
    } = props;
    console.log("openPaidAmntDialog", openPaidAmntDialog);
    // refs
    const paymentNoteRef = useRef(null);
    const paidAmntRef = useRef(null);

    // handle funcs
    function handleDropdown(event) {
        setDropdownState(event.target.value);
    }

    const handleConfirm = () => {
        if (paidAmntError) {
            setPaidAmntError(false);
        }

        confirmPaidAmnt({
            dropdownState,
            paymentNotes: paymentNoteRef.current.value,
            paidAmnt: paidAmntRef.current.value,
        });
    };

    return (
        <div>
            <Dialog
                open={openPaidAmntDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">Payment Details</DialogTitle>
                <DialogContent sx={{ overflowWrap: "break-word" }}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <TextField
                            label="Payment Amount"
                            margin="dense"
                            error={paidAmntError}
                            helperText={paidAmntError ? "Value Cannot Be Empty" : null}
                            inputRef={paidAmntRef}
                            sx={{ marginBottom: "1rem", width: "100%" }}
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            select
                            label="Payment Type"
                            sx={{ width: "100%", marginBottom: "2rem" }}
                            value={dropdownState}
                            onChange={handleDropdown}
                            InputLabelProps={{ shrink: true }}
                        >
                            {paymentType.map((type) => (
                                <MenuItem key={type.value} value={JSON.stringify(type)}>
                                    {type.label}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            label="Notes"
                            multiline
                            rows={8}
                            sx={{ width: "100%" }}
                            inputRef={paymentNoteRef}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirm}>Confirm</Button>
                    <Button onClick={handleClosePaidAmntDialog}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
