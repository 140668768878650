import React, { useState } from "react";

import makeStyles from "@mui/styles/makeStyles";

// npm imports
import PropTypes from "prop-types";

// MUI
// Table imports
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
} from "@mui/material";

// general imports
import { Paper, FormControlLabel, Switch } from "@mui/material";
import moment from "moment";

// custom components
// import PricingOverride from "./PricingOverride";
import ClientChargeCell from "./ClientChargeCell";
import NoteCell from "./NoteCell";
import ExpertPaymentCell from "./ExpertPaymentCell";
import PaymentApprovalButton from "./PaymentApprovalButton";

/**
 * TABLE DATA
 */
// headcells, that show structure (also need replacing)
const headCells = [
    { id: "scheduled_date", label: "DATE", isSortable: true },
    { id: "fname", label: "EXPERT", isSortable: true },
    { id: "expertPayment", label: "PAYMENT", isSortable: false },
    { id: "client_duration", label: "CLIENT", isSortable: false },
    { id: "unitClientChargeString", label: "CHARGE", isSortable: false },
];

function friendlyDateString(str) {
    const dtstamp = moment(str).format("MMMM Do, YYYY");
    return dtstamp;
}
// SORTING FUNCS
// sorting functions
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

// ENHANCED TABLE HEAD
function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    // render the col as sortable or not
    const renderTableHeadCell = (headCell) => {
        if (headCell.isSortable) {
            return (
                <TableCell
                    key={headCell.id}
                    align={"left"}
                    padding={"normal"}
                    sortDirection={orderBy === headCell.id ? order : false}
                >
                    <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={createSortHandler(headCell.id)}
                        hideSortIcon={headCell.sortable}
                    >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                            <span className={classes.visuallyHidden}>
                                {order === "desc" ? "sorted descending" : "sorted ascending"}
                            </span>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
            );
        } else {
            return (
                <TableCell key={headCell.id} align={"left"}>
                    {headCell.label}
                </TableCell>
            );
        }
    };

    return (
        <TableHead>
            <TableRow>{headCells.map((headCell) => renderTableHeadCell(headCell))}</TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    duration: {
        margin: "0",
    },
    rate: {
        margin: "0",
    },
    note: {
        margin: "0",
        color: "#888",
    },
    fmv: {
        color: "green",
        fontWeight: "bold",
        marginBottom: "0",
    },
    approved: {
        color: "green",
        fontWeight: "bold",
        marginBottom: "0",
    },
    declined: {
        color: "red",
        fontWeight: "bold",
        marginBottom: "0",
    },
    expert: {
        color: "#000000",
        fontWeight: "bold",
        fontSize: "1rem",
    },
    missing: {
        color: "red",
        fontWeight: "bold",
    },
    minimum: {
        color: "orange",
        fontWeight: "bold",
        margin: "0",
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    badRowData: {
        backgroundColor: "#f5f5f5",
        "&:hover": {
            backgroundColor: "#efcaca !important",
            color: "white",
        },
    },
    approvalBtn: {
        marginBottom: "0.5rem",
    },
    signup: {
        padding: "0",
        marginTop: "5px",
    },
}));

// render styles funcs
const renderBadRowDataStyles = (row) => {
    if (
        (row.client_duration,
        row.currency_type,
        row.duration_unit,
        row.expert_duration,
        row.unitClientChargeString,
        row.dollarClientChargeString,
        row.fname,
        row.hourlyRateToBePaid,
        row.hourlyRateToClient,
        row.lname,
        row.pricingUnit)
    ) {
        return false;
    }
    return true;
};

export default function EnhancedTable(props) {
    // console.log(props, " :: props EnhancedTable");
    const {
        expertData: rows,
        projectId,
        user,
        getPricingInfo,
        numberWithCommas,
        projectData,
        handleApprovalClick,
        accessList,
    } = props;

    // console.log(accessList, " :: ACCESS LIST");
    const classes = useStyles();
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("calories");
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const displayChargeEquation = () => {
        // console.log("EQUATION:", projectData);
        if (parseFloat(projectData.unit_multiplier) > 1 && parseFloat(projectData.custom_hourly_rate) < 1.1) {
            // EQUATION: Group Teleconference, Focus Group or DYAD project type w/ multiplier
            return (
                <span className={classes.note}>
                    (Price Unit * Duration Unit) * Unit Multiplier = Final Unit * PPU = $Total
                </span>
            );
        } else if (projectData.project_type === 8 && parseInt(projectData.price_per_unit) > 0) {
            // EQUATION: Survey project type w/ PPU
            return <span className={classes.note}>PPU = $Total</span>;
        } else if (parseInt(projectData.custom_charge, 10) > 0 || parseFloat(projectData.custom_hourly_rate) > 1) {
            // EQUATION: Cost Plus Pricing Model => NOT UNIT BASED!
            if (parseInt(projectData.transaction_fee) > 0) {
                return (
                    <span className={classes.note}>
                        Expert Hourly * Cost Plus Multiplier = Cost Plus Rate * (Call Duration / 60) + Custom Charge +
                        Transaction Fee = $Total
                    </span>
                );
            }
            return (
                <span className={classes.note}>
                    Expert Hourly * Cost Plus Multiplier = Cost Plus Rate * (Call Duration / 60) + Custom Charge =
                    $Total
                </span>
            );
        } else {
            // EQUATION: Default
            if (parseInt(projectData.transaction_fee) > 0) {
                return (
                    <span className={classes.note}>
                        (Price Unit * Duration Unit) = Final Unit * PPU + Transaction Fee = $Total
                    </span>
                );
            }
            return <span className={classes.note}>(Price Unit * Duration Unit) = Final Unit * PPU = $Total</span>;
        }
    };

    // handle funcs
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const generatePortalSignupLink = (eid) => {
        const link = `https://firstthoughtnetwork.io/signup/${eid}`;
        navigator.clipboard.writeText(link).then(
            () => {
                console.log("Copy Successful: ", link);
                alert(`Copied! ${link}`);
            },
            (err) => {
                console.error("Copy broken", err);
            }
        );
    };

    // selected checkbox function
    const isSelected = (name) => selected.indexOf(name) !== -1;

    // pagination
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="pricing table"
                        size={dense ? "small" : "medium"}
                        aria-label="pricing table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.project_participant_id);
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={`${row.project_participant_id}_${index}`}
                                            selected={isItemSelected}
                                            className={renderBadRowDataStyles(row) ? classes.badRowData : null}
                                        >
                                            <TableCell padding="normal" align="left">
                                                <div
                                                    style={{
                                                        textAlign: "left",
                                                        marginBottom: ".75rem",
                                                        fontSize: ".75rem",
                                                    }}
                                                >
                                                    {friendlyDateString(row.scheduled_date)}
                                                    <br />
                                                    {row.is_active ? <em>IS ACTIVE</em> : <em>NOT ACTIVE</em>}
                                                </div>
                                            </TableCell>
                                            <TableCell padding="normal" align="left" style={{ width: "20%" }}>
                                                <strong className={classes.expert}>
                                                    {row.fname} {row.lname}
                                                    {row.suffix ? `, ${row.suffix}` : ""}
                                                </strong>
                                                <p
                                                    className={classes.duration}
                                                >{`Duration: ${row.expert_duration} minutes`}</p>
                                                {row.minimum_hourly > 0 && (
                                                    <p
                                                        className={classes.minimum}
                                                    >{`Minimum: ${row.minimum_hourly} minutes`}</p>
                                                )}
                                                {projectData.project_type === 8 && projectData.expert_flat_rate > 0 && (
                                                    <p>
                                                        {`Survey Fee: ${numberWithCommas(
                                                            projectData.expert_flat_rate
                                                        )}`}
                                                        {" USD"}
                                                    </p>
                                                )}
                                                {row.hourlyRateToBePaid && projectData.project_type !== 8 && (
                                                    <p className={classes.rate}>
                                                        {`Pay Rate: ${numberWithCommas(row.hourlyRateToBePaid)}`}{" "}
                                                        {`${row.hourlyRateToBePaid && row.currency_type}`}
                                                    </p>
                                                )}
                                                {projectData.project_type === 6 && row.group_meeting_rate > 0 && (
                                                    <>
                                                        {`Group Rate: ${numberWithCommas(row.group_meeting_rate)}`}{" "}
                                                        {`${row.group_meeting_rate && row.currency_type}`}
                                                        <p className={classes.note}>(Group Rate Used)</p>
                                                    </>
                                                )}
                                                {row.hourlyRateToClient &&
                                                    row.hourlyRateToClient > row.hourlyRateToBePaid && (
                                                        <p>
                                                            {`Cost Plus: ${numberWithCommas(row.hourlyRateToClient)}`}{" "}
                                                            {`${row.hourlyRateToClient && row.currency_type}`}
                                                        </p>
                                                    )}{" "}
                                                {projectData.fair_market_value > 0 && row.rate_cap_approved && (
                                                    <p className={classes.fmv}>FMV APPROVED *</p>
                                                )}
                                                {projectData.fair_market_value > 0 && !row.rate_cap_approved && (
                                                    <p className={classes.declined}>FMV DECLINED</p>
                                                )}
                                                <Button
                                                    className={classes.signup}
                                                    onClick={() => generatePortalSignupLink(row.id)}
                                                >
                                                    Payment Signup Link
                                                </Button>
                                            </TableCell>
                                            <TableCell padding="normal" align="left">
                                                <div className={classes.approvalBtn}>
                                                    <PaymentApprovalButton
                                                        row={row}
                                                        projectData={projectData}
                                                        user={user}
                                                        handleApprovalClick={handleApprovalClick}
                                                        expertOrClientApproval={"expert"}
                                                        accessList={accessList}
                                                    />
                                                </div>
                                                <ExpertPaymentCell
                                                    projectData={projectData}
                                                    projectId={projectId}
                                                    userId={user.id}
                                                    getPricingInfo={getPricingInfo}
                                                    row={row}
                                                />
                                            </TableCell>
                                            <TableCell padding="normal" align="left" style={{ width: "25%" }}>
                                                <strong>{projectData.client_name}</strong>
                                                <br />
                                                <span
                                                    className={classes.duration}
                                                >{`Duration: ${row.client_duration} minutes`}</span>
                                                <br />
                                                {`Price/Duration (UNITS): ${
                                                    row.pricingUnit ? row.pricingUnit : "---"
                                                } / ${row.durationUnit ? row.durationUnit : "---"}`}
                                                <br />
                                                Charge Equation:
                                                <br />
                                                {displayChargeEquation()}
                                            </TableCell>
                                            <TableCell padding="normal" align="left" style={{ width: "25%" }}>
                                                <div className={classes.approvalBtn}>
                                                    <PaymentApprovalButton
                                                        row={row}
                                                        projectData={projectData}
                                                        user={user}
                                                        handleApprovalClick={handleApprovalClick}
                                                        expertOrClientApproval={"client"}
                                                        accessList={accessList}
                                                    />
                                                </div>
                                                <NoteCell
                                                    projectId={projectId}
                                                    userId={user.id}
                                                    getPricingInfo={getPricingInfo}
                                                    row={row}
                                                />
                                                <ClientChargeCell
                                                    projectType={projectData.project_type}
                                                    row={row}
                                                    overrideType={"unit"}
                                                    numberWithCommas={numberWithCommas}
                                                />
                                                <ClientChargeCell
                                                    projectType={projectData.project_type}
                                                    row={row}
                                                    overrideType={"dollar"}
                                                    numberWithCommas={numberWithCommas}
                                                />
                                                {projectData.transaction_fee > 0 && (
                                                    <em>* Includes ${row.translatorCost} translator fee</em>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: (dense ? 10 : 5) * emptyRows }}>
                                    <TableCell colSpan={5} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Dense padding" />
        </div>
    );
}
