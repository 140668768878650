import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import MUIDataTable, { ExpandButton } from "mui-datatables";
import { authContext } from "../../context/auth";
import { Link } from "react-router-dom";
import VerifiedIcon from "@mui/icons-material/Verified";
import StatusIcon from "@mui/icons-material/EditAttributes";
import Warning from "@mui/icons-material/Warning";
import { basicDateTimeStamp, truncateString, formatPhoneNumber } from "../../Utils";
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Backdrop, CircularProgress, TableCell, TableRow } from "@mui/material";
import Button from "@mui/material/Button";
import SurveyAnswers from "./utils/SurveyAnswers";
import ChangeStatus from "./SetClientResponseStatus";
import ExpertPreviewWrapperNext from "../../components/wrappers/ExpertPreviewWrapperNext";
import GenerateIndividualTokens from "./GenerateIndividualTokens";
import AttachToProject from "../AttachToProject";

// ======
// STYLES
// ======

const linkStyle = css`
    color: #8966ad;
    font-size: 1rem;
    font-weight: bold;
    padding: 0;
    text-align: left;
    line-height: 1.25rem;
`;

const smallLinkStyle = css`
    color: #8966ad;
    font-size: 0.85rem;
    font-weight: normal;
    padding: 0;
    text-align: left;
    line-height: 1.25rem;
    text-transform: Capitalize;
`;

const backdropStyles = css`
    color: #fff;
    z-index: 1201;
`;

export default function Send(props) {
    // =========
    // CONSTANTS
    // =========

    const { project_id, history, projectScreeners } = props;

    // =====
    // STATE
    // =====

    const { auth } = useContext(authContext);
    const [[stableData, filteredData], setData] = useState([[], []]); // data is going to have original set of data as first element and the filtered data as the second
    const [open, setOpen] = useState(false);
    const [currentExpertId, setCurrentExpertId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [snippet, setSnippetModal] = useState(false);
    const [statusModal, setStatusModal] = useState(false);
    const [expertStatus, setExpertStatus] = useState({
        id: "",
        name: "",
        statuses: [],
    });
    const [tokenModal, setTokenModal] = useState(false);
    const [expert, setExpert] = useState({
        id: "",
        name: "",
        screener_id: "",
    });
    // =========
    // PAGE LOAD
    // =========

    async function loadSendLeads(projectId) {
        await axios
            .get(`/api/project/now/findSendExpertList/${projectId}`)
            .then((response) => {
                if (response?.data?.data?.data) {
                    const result = response?.data?.data?.data || [];
                    console.log("DID SEND TAB DATA LOAD? ", result);
                    setData([result, result]);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err, " :: err");
                setLoading(false);
            });
    }

    useEffect(() => {
        loadSendLeads(project_id);
    }, []);

    // ============
    // HANDLE FUNCS
    // ============

    const openExpertPreview = (expertId) => {
        setCurrentExpertId(expertId);
        setOpen(true);
    };
    const navigateToExpert = (expertId) => {
        history.push(`/expert/view/${expertId}`);
    };
    const reloadPageTagSearch = (tagId) => {
        history.push(`/search?added_by=0&and_tags=${tagId}`);
        window.location.reload();
    };

    const closeModal = () => {
        setStatusModal(false);
        setSnippetModal(false);
        setTokenModal(false);
        setExpert({
            id: "",
            name: "",
            screener_id: "",
        });
    };

    const handleExpertStatus = (eid, name, statuses) => {
        console.log("EXPERT ID: ", { eid, name });
        setStatusModal(true);
        setExpertStatus({
            id: eid,
            name,
            statuses,
        });
    };

    const handleExpertToken = (eid, name, sid) => {
        console.log("EXPERT ID: ", { eid, name });
        setTokenModal(true);
        setExpert({
            ...expert,
            id: eid,
            name,
            screener_id: sid ? sid : "",
        });
    };

    // 272788, 272805, 272807, 272774

    // =====
    // TABLE
    // =====

    const expandableOptions = {
        print: false,
        rowsPerPage: 100,
        viewColumns: false,
        selectableRows: "none",
        expandableRows: true,
        expandableRowsHeader: false,
        expandableRowsOnClick: true,
        download: false,
        renderExpandableRow: (rowData, { dataIndex, rowIndex }) => {
            const { surveys, expert_id } = filteredData?.[dataIndex];
            const colSpan = rowData.length + 1;
            return (
                <TableRow>
                    <TableCell colSpan={colSpan}>
                        <SurveyAnswers answerData={{ surveys, expert_id, project_id }} />
                    </TableCell>
                </TableRow>
            );
        },
    };

    const components = {
        ExpandButton: function (props) {
            return <ExpandButton {...props} />;
        },
    };

    const columns = [
        {
            name: "has_signed_terms",
            label: "In",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { has_signed_terms, disclosures } = filteredData?.[dataIndex];

                    return (
                        <div>
                            {has_signed_terms && disclosures ? (
                                <VerifiedIcon style={{ color: "#523197" }} />
                            ) : (
                                <strong style={{ color: "#ccc" }}>***</strong>
                            )}
                        </div>
                    );
                },
            },
        },
        {
            name: "expert_name",
            label: "Name",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { fname, lname, suffix, expert_id, empHistData, headline, number } =
                        filteredData?.[dataIndex];

                    return (
                        <div>
                            <Button css={linkStyle} onClick={() => openExpertPreview(expert_id)}>
                                {fname} {lname}
                                {suffix ? `, ${suffix}` : ""}
                            </Button>
                            {empHistData && empHistData.length > 0 ? (
                                <div>{`${truncateString(empHistData[0].position, 25)} at ${truncateString(
                                    empHistData[0].company,
                                    25
                                )}`}</div>
                            ) : (
                                <div>{truncateString(headline, 25)}</div>
                            )}
                            {number ? (
                                <div>
                                    <a href={`tel:${number}`}>
                                        {number.length === 10 ? formatPhoneNumber(number) : number}
                                    </a>
                                </div>
                            ) : (
                                <div>-- PHONE UNAVAILABLE --</div>
                            )}
                        </div>
                    );
                },
            },
        },
        {
            name: "calculated_consultation_rate",
            label: "Rate",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { calculated_consultation_rate } = filteredData?.[dataIndex];

                    return (
                        <div>
                            {parseInt(calculated_consultation_rate, 10) > 0
                                ? `$${calculated_consultation_rate}`
                                : "----"}
                        </div>
                    );
                },
            },
        },
        {
            name: "attached_on",
            label: "Attached",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { attached_on } = filteredData?.[dataIndex];

                    return <div>{basicDateTimeStamp(attached_on)}</div>;
                },
            },
        },
        {
            name: "attaching_rm_name",
            label: "By",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { attaching_rm_name } = filteredData?.[dataIndex];

                    return <div>{attaching_rm_name}</div>;
                },
            },
        },
        {
            name: "",
            label: "Screen",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    const { surveys, fname, lname, suffix, expert_id } = filteredData?.[dataIndex];
                    const fullName = `${fname} ${lname}${suffix ? `, ${suffix}` : ""}`;
                    return (
                        <>
                            <div style={{ marginTop: "12px" }}>
                                <strong>Screen {fname} again:</strong>
                                <div>
                                    <Button
                                        css={smallLinkStyle}
                                        onClick={() => handleExpertToken(expert_id, fullName, null)}
                                    >
                                        Select a Screener
                                    </Button>
                                </div>
                            </div>
                        </>
                    );
                },
            },
        },
        {
            name: "",
            label: "Status",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    const { expert_id, expert_name, expertStatuses } = filteredData?.[dataIndex];

                    return (
                        <>
                            <div>
                                <StatusIcon
                                    style={{ color: "#666", cursor: "pointer" }}
                                    onClick={() => handleExpertStatus(expert_id, expert_name, expertStatuses)}
                                />
                            </div>
                            {expertStatuses && (
                                <div style={{ marginTop: "8px" }}>
                                    {Object.entries(expertStatuses).map(([key, value], i) => (
                                        <strong key={`${key}_${i}`} style={{ color: "orange", fontSize: ".75rem" }}>
                                            {key === "8" ||
                                                (key === "30" && (
                                                    <span>
                                                        <Warning style={{ fontSize: "1rem" }} /> REJECTED BY CLIENT /
                                                        SCREENED OUT
                                                    </span>
                                                ))}
                                        </strong>
                                    ))}
                                </div>
                            )}
                        </>
                    );
                },
            },
        },
    ];

    if (loading) {
        return (
            <Backdrop
                css={backdropStyles}
                open={loading}
                onClick={() => {
                    setLoading(false);
                }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    } else {
        return (
            <>
                <h2 style={{ fontSize: "1.25rem" }}>Ready To Send: {filteredData?.length}</h2>
                <p>
                    <strong>FILTER:</strong> Anyone who has completed screeners for this project, but have not yet been
                    sent over, scheduled or selected by the client.
                    <br />
                    <strong>ACTION:</strong> View respondents & send to client.
                    <br />
                    <Button href={`/project/respondents/${project_id}`}>View Respondents</Button>
                    <AttachToProject
                        selected={filteredData.map((s) => s.expert_id)}
                        btnText="Add All To Another Project"
                        setFullWidth={false}
                        user={auth.data}
                        disabled={false}
                    />
                </p>
                <GenerateIndividualTokens
                    openDialog={tokenModal}
                    closeEvent={closeModal}
                    project_id={project_id}
                    expert={expert}
                    data={projectScreeners}
                />
                <ChangeStatus
                    reloadEvent={loadSendLeads}
                    user={auth}
                    openDialog={statusModal}
                    expert={expertStatus}
                    project_id={project_id}
                    closeEvent={closeModal}
                />
                <MUIDataTable
                    data={filteredData}
                    options={expandableOptions}
                    columns={columns}
                    components={components}
                />
                {open && (
                    <ExpertPreviewWrapperNext
                        open={open}
                        setOpen={setOpen}
                        expert_id={currentExpertId}
                        project_id={project_id}
                    />
                )}
            </>
        );
    }
}
