import React, { useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Typography, Checkbox, Slider, FormControlLabel } from "@mui/material";

const useStyles = makeStyles({
    root: {
        width: 300,
    },
});

export default function ExpertRateSlider(props) {
    // console.log(props, " :: props");
    const {
        search: {
            filters: {
                expertRateSlider: { active, min, currentMin, max, currentMax },
            },
        },
        setSearch,
    } = props;
    const { search } = props;
    const classes = useStyles();
    const [value, setValue] = useState([200, 800]);

    useEffect(() => {
        const minMaxArray = [currentMin, currentMax];
        setValue(minMaxArray);
    }, [props]);

    const handleChange = (event, newValue) => {
        // console.log(newValue, " :: newValue");
        const [currentMin, currentMax] = newValue;
        const newSearchObj = {
            ...search,
            filters: {
                ...search.filters,
                expertRateSlider: {
                    ...search.filters.expertRateSlider,
                    currentMin,
                    currentMax,
                },
            },
        };
        setSearch(newSearchObj);
    };

    const handleCheckbox = () => {
        // console.log(active, " :: handleCheckbox active");
        const newSearchObj = {
            ...search,
            filters: {
                ...search.filters,
                expertRateSlider: {
                    ...search.filters.expertRateSlider,
                    active: !active,
                },
            },
        };
        setSearch(newSearchObj);
    };

    return (
        <div className={classes.root}>
            <Typography gutterBottom>Expert Rate</Typography>
            <FormControlLabel
                control={<Checkbox checked={!active} onChange={handleCheckbox} value={active} />}
                label={"Disabled?"}
            />
            <Slider
                value={value}
                onChange={handleChange}
                valueLabelDisplay="auto"
                max={max}
                min={min}
                disabled={!active}
            />
        </div>
    );
}
