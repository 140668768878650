import React, { useState, useCallback, useEffect, useRef } from "react";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import "moment-duration-format";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import { green } from "@mui/material/colors";
import { formatPhoneNumber } from "../Utils";
// TODO: Remove eventually...
// import CustomAddTermsLink from "./CustomAddTermsLink";
// import CustomAddDisclosures from "./CustomAddDisclosures";
import CustomOnboardingLink from "./CustomOnboardingLink";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    typography: {
        padding: theme.spacing(2),
    },
    actions: {
        textAlign: "Center",
    },
    warningCopy: {
        color: "red",
        fontSize: "1.25rem",
        fontWeight: "bold",
        marginTop: "0",
    },
    datestamp: {
        color: "#666",
        fontSize: ".65rem",
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    emailBorder: {
        border: "1px solid #e5e5e5",
        backgroundColor: "#f8f8f8",
        borderRadius: "2px",
        padding: "12px",
        fontSize: ".75rem",
    },
    emailOutline: {
        border: "1px solid #eaeaea",
        backgroundColor: "#ffffff",
        borderRadius: "2px",
        padding: "8px",
    },
    editorWrapper: {
        border: "1px solid #EAEAEA",
        borderRadius: "2px",
        padding: "12px",
    },
    iconSmall: {
        fontSize: ".95rem",
    },
    wrapper: {
        margin: theme.spacing(1),
        position: "relative",
    },
    buttonSuccess: {
        backgroundColor: green[500],
        "&:hover": {
            backgroundColor: green[700],
        },
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: 22,
    },
}));

function TermsFollowupEmail(props) {
    let templateName = "";
    switch (props.previous.template_name) {
        case "outreach":
            templateName = "outreach_followup";
            break;
        case "outreach_again":
            templateName = "outreach_followup";
            break;
        case "outreach_followup":
            templateName = "outreach_followup2";
            break;
        case "tnc":
            templateName = "tnc_followup";
            break;
        case "tnc_followup":
            templateName = "tnc_followup2";
            break;
        case "tnc_followup2":
            templateName = "tnc_followup3";
            break;
        default:
            templateName = "followup";
    }
    // const sender_emailString = "<dan@firstthought.io>"
    const sender_emailString = props.previous.mail_from.replace(/</g, "").replace(/>/g, "").split(" ");
    console.log("EMAIL EXAMPLE: ", sender_emailString);
    const now = moment();
    const classes = useStyles();
    const timer = useRef();
    const [confirmButton, setConfirmButtonDisplay] = useState("block");
    const [sendButton, setButtonDisplay] = useState("none");
    const [preview, setPreviewArea] = useState("none");
    const [editableAreas, setEditableArea] = useState("block");
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [editor, setEditorState] = useState(EditorState.createEmpty());
    const [template, setEmailTemplate] = useState({
        expert_id: props.previous.expert_id,
        expert_fname: props.previous.mail_to_name.split(" ")[0],
        expert_lname: props.previous.mail_to_name.split(" ")[1],
        expert_email: props.previous.mail_to,
        project_id: props.previous.project_id,
        user_id: props.previous.user_id,
        user_fname: sender_emailString.length > 1 ? sender_emailString[0] : "",
        user_lname: sender_emailString.length > 1 ? sender_emailString[1] : "",
        from_email: sender_emailString.length > 1 ? sender_emailString[2] : sender_emailString[0],
        email_subject: `Follow-up: ${props.previous.mail_subject}`,
        email_body: props.previous.mail_body,
        email_template: templateName,
    });
    const [token, setToken] = useState("");
    const [onboarding, setOnboard] = useState("");
    //   const [version, setEmailVersion] = useState({
    //     id: "",
    //     template_name: "",
    //     project_id: "",
    //     expert_id: "",
    //     user_id: "",
    //     mail_subject: "",
    //     mail_body: "",
    //     mail_to_name: "",
    //     mail_to: "",
    //     mail_from: "",
    //     mail_cc: "",
    //     mail_bcc: "",
    //     created_on: "",
    //     is_sent: false,
    //     error_message: "",
    //     is_delivered: false,
    //     is_opened: false,
    //     is_dropped: false,
    //     is_deferred: false,
    //     is_bounced: false
    //   });
    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
    });
    const generateToken = useCallback(async (id) => {
        const url = "/api/expert/generateTokenById";
        const postData = {
            id,
            pid: props.project ? props.project.id : "",
            rmid: props.user.id,
            rmemail: props.user.mail,
            rmfname: props.user.fname,
            rmlname: props.user.lname,
        };
        console.log("POST TOKEN DATA: ", postData);
        await axios
            .post(url, postData, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    console.log("Token generated? ", result);
                    const tncLink = `https://firstthought.io/terms/${result.data.data}`;
                    const onboardLink = `https://firstthought.io/onboard/${result.data.data}`;
                    setToken(tncLink);
                    setOnboard(onboardLink);
                }
            })
            .catch((result) => {
                console.log("Something went wrong, API Error, 404 Error:", result);
            });
    }, []);
    function findReplaceTermsLink(body, onboard) {
        const onboardLink = `<a href="${onboard}" target="_blank">online terms and disclosures</a>`;
        let newBody = "";
        if (body.indexOf("{{ONBOARDING_LINK}}") !== -1) {
            newBody = body.replace("{{ONBOARDING_LINK}}", onboardLink);
            // Set template to TNC if link is found
            template.email_template = "tnc";
        } else {
            newBody = body;
            template.email_template = templateName;
        }
        return newBody;
    }

    function formatDateString(str) {
        return moment(str).format("dddd, MMMM. Do, YYYY, h:mma");
    }

    function followUpTemplate() {
        let html = "<p>";
        html += `Hi ${template.expert_fname} ${template.expert_lname},`;
        html += "</p>";
        html += `<p style='color:red;font-weight:bold;'>Hey ${props.user.fname}! PLEASE REMOVE: This is just suggested default email copy fully intended to be customized, edited and most importantly reviewed by you.</p>`;
        html += "<p>";
        html +=
            "I just wanted to follow-up on my message from yesterday pertaining to the consultation request below. Our client is very interested in hearing your insight into this matter, and we would be happy to pay you an honorarium rate of your choosing for your time. If you're interested, let me know and we can begin connecting you to our client.";
        html += "</p>";
        html += "<p>";
        html += "Thank you again, and feel free to give me a call at the number below if you have any questions.";
        html += "</p>";
        html += "<p>";
        html += `Best,<br />${template.user_fname}`;
        html += "</p>";
        html += "<br/><br/>";
        html +=
            "------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------";
        html += "<p>";
        html += `<strong>From:</strong> ${props.previous.mail_from}<br/>`;
        html += `<strong>Date:</strong> ${formatDateString(props.previous.created_on)}<br/>`;
        html += `<strong>To:</strong> ${props.previous.mail_to_name}<br/>`;
        html += `<strong>Subject:</strong> ${props.previous.mail_subject}`;
        html += "</p>";
        html += template.email_body;
        return html;
    }

    function handleChange(event) {
        const { target } = event;
        const { value, name } = target;
        setEmailTemplate({
            ...template,
            [name]: value,
        });
    }

    function onEditorStateChange(editor) {
        setEditorState(editor);
        const jsonHtml = convertToRaw(editor.getCurrentContent());
        const html = draftToHtml(jsonHtml);
        // const editorHtml = htmlToDraft(html);
        const newHtml = findReplaceTermsLink(html, onboarding);
        setEmailTemplate({
            ...template,
            email_body: newHtml,
        });
    }

    function setDefaultReferenceEditorState() {
        const html = followUpTemplate();
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            setEditorState(EditorState.createWithContent(contentState));
        }
        setEmailTemplate({
            ...template,
            email_body: html,
        });
    }

    function sendEmail() {
        const url = "/api/mail/tnc";
        if (!loading) {
            setSuccess(false);
            setLoading(true);
        }
        axios
            .post(url, template, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    console.log("Email Template Sent", template);
                    timer.current = setTimeout(() => {
                        setSuccess(true);
                        setLoading(false);
                        props.closeEvent();
                        // props.updateEvent(result.data.data);
                    }, 3000);
                    // Reload the page to show new TnC status
                } else {
                    console.log(`POSTED, but NOT Status 200: ${JSON.stringify(template)}`);
                    alert("Oops, Something went wrong. Your email did not send. Please contact someone in Tech.");
                    setLoading(false);
                    props.closeEvent();
                }
            })
            .catch((result) => {
                console.log("Something went wrong, API Error, 404 Error:", result);
                alert("Oops, Something went wrong. Your email did not send. Please contact someone in Tech.");
                setLoading(false);
                props.closeEvent();
            });
    }

    function showPreview() {
        if (template.user_fname !== "" && template.user_lname !== "") {
            setEditableArea("none");
            setPreviewArea("block");
            setConfirmButtonDisplay("block");
            setButtonDisplay("none");
            console.log("TEMPLATE: ", template);
        } else {
            alert("Please enter First and Last Name!");
        }
    }
    function editEmail() {
        setEditableArea("block");
        setPreviewArea("none");
        setConfirmButtonDisplay("block");
        setButtonDisplay("none");
    }
    function showSendEmailButton() {
        setButtonDisplay("block");
        setConfirmButtonDisplay("none");
    }

    useEffect(() => {
        setDefaultReferenceEditorState();
        generateToken(props.previous.expert_id);
    }, [props]);

    return (
        <div>
            <Box display={preview}>
                <Box display={confirmButton}>
                    <p className={classes.warningCopy}>
                        PREVIEW ONLY: Please review and confirm this is ready to send.
                    </p>
                </Box>
                <div className={classes.emailBorder}>
                    <p className={classes.datestamp}>{formatDateString(now)}</p>
                    <p>
                        <strong>FROM:</strong> {template.user_fname} {template.user_lname} ({template.from_email})
                    </p>
                    <p>
                        <strong>TO:</strong> {template.expert_fname} {template.expert_lname} ({template.expert_email})
                    </p>
                    <p className={classes.subjectLine}>
                        <strong>SUBJECT LINE:</strong> {template.email_subject}
                    </p>
                    <div
                        className={classes.emailOutline}
                        dangerouslySetInnerHTML={{
                            __html: template.email_body,
                        }}
                    />
                </div>
                <div className={classes.actions}>
                    <div className={classes.wrapper}>
                        <Box display={confirmButton}>
                            <Button onClick={editEmail}>Edit this Email</Button>
                            <Button onClick={showSendEmailButton}>
                                <WhatshotIcon /> Confirmed, This Looks Fire!
                            </Button>
                        </Box>
                        <Box display={sendButton}>
                            <Button onClick={editEmail}>Back to Edit</Button>
                            <Button
                                variant="contained"
                                color="primary"
                                className={buttonClassname}
                                disabled={loading}
                                onClick={sendEmail}
                            >
                                Send Email Now
                            </Button>
                            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </Box>
                    </div>
                </div>
            </Box>
            <Box display={editableAreas}>
                <TextField
                    // disabled
                    fullWidth
                    id="user_fname"
                    label="Sender First Name"
                    margin="normal"
                    onChange={handleChange}
                    name="user_fname"
                    value={template.user_fname}
                />
                <TextField
                    // disabled
                    fullWidth
                    id="user_lname"
                    label="Sender Last Name"
                    margin="normal"
                    onChange={handleChange}
                    name="user_lname"
                    value={template.user_lname}
                />
                <TextField
                    // disabled
                    fullWidth
                    id="from_email"
                    label="Sender Email"
                    margin="normal"
                    onChange={handleChange}
                    name="from_email"
                    value={template.from_email}
                />
                <TextField
                    fullWidth
                    id="email_subject"
                    label="Email Subject"
                    margin="normal"
                    onChange={handleChange}
                    name="email_subject"
                    value={template.email_subject}
                />
                <Editor
                    editorState={editor}
                    spellCheck
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName={classes.editorWrapper}
                    onEditorStateChange={onEditorStateChange}
                    toolbarCustomButtons={[<CustomOnboardingLink />]}
                />
                <div className={classes.actions}>
                    <div className={classes.wrapper}>
                        <Button onClick={props.closeEvent}>Cancel</Button>
                        <Button variant="contained" color="primary" className={buttonClassname} onClick={showPreview}>
                            Next
                        </Button>
                    </div>
                </div>
            </Box>
        </div>
    );
}
export default TermsFollowupEmail;
