/* eslint-disable react/prop-types */
import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import queryString from "query-string";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import EmailOutlined from "@mui/icons-material/EmailOutlined";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import { authContext } from "../context/auth";
import { useForm } from "react-hook-form";
import Input from "@mui/material/Input";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material";
import { Redirect } from "react-router-dom";

// mui styles
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Login(props) {
    const { setAuthData, auth } = useContext(authContext);
    if (auth && auth.data) {
        return <Redirect to="/" />;
    }
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();
    const theme = useTheme();

    const [open, setOpen] = React.useState(false);
    const [serverError, setServerError] = React.useState(false);
    const [serverErrorMessage, setServerErrorMessage] = React.useState("");

    useEffect(() => {
        async function fetchExpertByToken(token) {
            await axios
                .get(`/api/person/magicsignin?token=${token}`)
                .then((loginData) => {
                    if (loginData.status === 200 && loginData.data && loginData.data.data) {
                        if (loginData.data.message === "Failure") {
                            // setLoggedIn(false);
                        } else {
                            const user = loginData.data.data;
                            user.expiration = new Date(new Date().getTime() + 1000 * 60 * 60 * 12);
                            setAuthData(user);
                            return <Redirect to="/" />;
                        }
                    }
                })
                .catch((error) => {
                    if (error && error.response && error.response.data) {
                        setServerError(true);
                        setServerErrorMessage(error.response.data.message);
                    }
                });
        }
        const params = queryString.parse(location.search);
        if (params.token) {
            setServerError(false);
            setServerErrorMessage("");
            fetchExpertByToken(params.token);
        }
    }, []);
    const onSubmit = (data) => {
        setServerError(false);
        setServerErrorMessage("");
        axios
            .post("/api/person/getmagiclink", { email: data.email })
            .then((result) => {
                if (result.status === 200 && result.data && result.data.message && result.data.message === "Success") {
                    if (result.data.message === "Failure") {
                    } else {
                        setOpen(true);
                    }
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data) {
                    setServerError(true);
                    setServerErrorMessage(error.response.data.message);
                }
            });
    };
    const handleClose = (event, reason) => {
        setOpen(false);
    };

    return (
        <Container component="main" maxWidth="xs">
            <Paper
                elevation={4}
                sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "column" },
                    alignItems: "center",
                    justifyContent: "center",
                    height: 250,
                    width: 400,
                }}
            >
                <Snackbar
                    open={open}
                    autoHideDuration={10000}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                >
                    <Alert onClose={handleClose} severity="success">
                        Please check your work email!
                    </Alert>
                </Snackbar>
                <Avatar sx={{ bgcolor: theme.palette.secondary.main, marginBottom: "1rem" }}>
                    <EmailOutlined />
                </Avatar>
                <Typography component="h1" variant="h5" sx={{ marginBottom: "1rem" }}>
                    FirstThought email
                </Typography>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    css={css`
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        max-width: 90%;
                        width: 100%;
                    `}
                >
                    <Input
                        // fullWidth
                        sx={{ maxWidth: "90%", width: "100%" }}
                        type="text"
                        {...register("email", {
                            required: true,
                            validate: (value) =>
                                value.toLowerCase().includes("@firstthought.io") || "not a valid firstthought email",
                            pattern:
                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        })}
                    />
                    {errors.email && errors.email.type && errors.email.type === "required" && (
                        <Box sx={{ color: theme.palette.error.main }}>
                            <Typography variant="body2" gutterBottom>
                                email is required
                            </Typography>
                        </Box>
                    )}
                    {errors.email && errors.email.type && errors.email.type === "pattern" && (
                        <Box sx={{ color: theme.palette.error.main }}>
                            <Typography variant="body2" gutterBottom>
                                not a valid email
                            </Typography>
                        </Box>
                    )}
                    {errors.email && errors.email.type && errors.email.type === "validate" && (
                        <Box sx={{ color: theme.palette.error.main }}>
                            <Typography variant="body2" gutterBottom>
                                {errors.email.message}
                            </Typography>
                        </Box>
                    )}
                    {serverError && serverErrorMessage !== "" && (
                        <Box sx={{ color: theme.palette.error.main }}>
                            <Typography variant="body2" gutterBottom>
                                {serverErrorMessage}
                            </Typography>
                        </Box>
                    )}
                    <Button sx={{ marginTop: "2rem" }} type="submit" variant="contained" color="primary">
                        Get the link
                    </Button>
                </form>
            </Paper>
        </Container>
    );
}
export default Login;
