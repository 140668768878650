// react imports
import React, { useState, useEffect, useContext } from "react";

// mui-general
import makeStyles from "@mui/styles/makeStyles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Tab, Tabs } from "@mui/material";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

// custom components
import ProjectPricingTable from "../components/financing/payment-table/ProjectPricingTable";
import OneOffChargeTable from "../components/financing/payment-table/OneOffChargeTable";
import FlashMessagev3 from "../components/FlashMessagev3";
import ApprovalNoteModal from "../components/financing/payment-table/ApprovalNoteModal";
import AddClientContactModal from "../components/financing/payment-table/AddClientContactModal";
import InvoicePreviewModal from "../components/financing/payment-table/InvoicePreviewModal";

// auth info
import { authContext } from "../context/auth";

// utils
import { numberWithCommas } from "../Utils";

// npm imports
import axios from "axios";

// styles
const useStyles = makeStyles((theme) => ({
    // color: "#3d4a42", this is the green color
    header: {
        color: "#8966ad", // purple color
        fontSize: "1.5rem",
        marginBottom: ".5rem",
    },
    nameButton: {
        color: "#523197",
        padding: "0",
        textDecoration: "none",
        fontSize: "2rem",
        fontWeight: "normal",
        textTransform: "none",
        minWidth: "auto",
        marginBottom: ".5rem",
    },
    metadata: {
        color: "#444",
        textDecoration: "none",
        fontSize: "1.25rem",
        fontWeight: "normal",
        marginBottom: "10px",
    },
    accSumContainer: {
        width: "100%",
        borderBottom: "6px solid #CCC",
        // marginBottom: "2rem",
    },
    accSumItem: {
        marginRight: "1.5rem",
        marginBottom: "1rem",
    },
    accSumItemWarning: {
        marginRight: "1.5rem",
        marginBottom: "1rem",
        color: "green",
        fontWeight: "700",
    },
    boldedTxt: {
        fontWeight: "700",
        color: "#bbaed7",
    },
    muiTable: {
        maxWidth: "100%",
    },
    alert: {
        fontWeight: "700",
        color: "orange",
        textTransform: "uppercase",
    },
    good: {
        fontWeight: "700",
        color: "green",
    },
    bad: {
        fontWeight: "700",
        color: "red",
    },
    messageHeader: {
        fontSize: "1.35rem",
    },
    invoiceBtn: {
        width: "100%",
        margin: "1rem 0",
    },
}));

// const projectType = [
//     { value: "1", label: "Regular Phone Consultation" },
//     { value: "2", label: "Follow Up Phone Consultation" },
//     { value: "3", label: "Strategic Recruiting" },
//     { value: "4", label: "Vetting Call" },
//     { value: "5", label: "Follow Up Written Communication" },
//     { value: "6", label: "Group Teleconference" },
//     { value: "7", label: "Group In-person Meeting" },
//     { value: "8", label: "Survey" },
//     { value: "9", label: "Direct Engagement" },
//     { value: "10", label: "Advisory Board Placement" },
//     { value: "11", label: "1x1 In-Person Meeting" },
//     { value: "12", label: "Focus Group" },
// ];

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const ProjectPricing = (props) => {
    // console.log(props.match.params.id, " :: props.match.params.id");
    const { match, history } = props;

    // state vars
    const [projectData, setProjectData] = useState({});
    const [expertData, setExpertData] = useState([]);
    const [oneOffExpData, setOneOffExpData] = useState([]);
    const [invalidPID, setInvalidPID] = useState(false);
    const [loading, setLoading] = useState(true);
    const [totalCharge, setTotalCharge] = useState(0);
    const [totalPayment, setTotalPayment] = useState(0);
    const [margin, setMargin] = useState(0);
    const [messageModal, setMessageModal] = useState(false);
    const [postObject, setPostObjData] = useState({});
    const [disableInvoicingBtn, setDisableInvoicingBtn] = useState(false);
    const [openApprovalNoteModal, setOpenApprovalNoteModal] = useState(false);
    const [approvalData, setApprovalData] = useState({});
    const [openAddClientContactModal, setOpenAddClientContactModal] = useState(false);
    const [invoicePreviewData, setInvoicePreviewData] = useState({});
    const [loadingInvoicePreviewData, setLoadingInvoicePreviewData] = useState(true);
    const [additionalClientEmails, setAdditionalClientEmails] = useState("");
    const [openInvoicePreviewModal, setOpenInvoicePreviewModal] = useState(false);
    const [tabValue, setTabValue] = useState(0);

    // flashmessage
    const [flashMsg, setFlashMsg] = useState("");
    const [openFlashMessage, setOpenFlashMessage] = useState(false);
    const [severity, setSeverity] = useState("error");

    // auth info
    const { auth } = useContext(authContext);

    // style hook
    const classes = useStyles();

    // access list
    const APPROVAL_ACCESS_LIST = [3, 67291, 153383, 95647, 309841, 67281, 340269, 309842];
    // 3 - dan c., 67291 - michale f., 153383 - victoria, 95647 - felix b., 309841 - Anjali, 67281 - Joe Riggio, 340269 - Brandon Orr, 309842 - Jon Krais

    // incoming vars
    const projectId = props.match.params.id;

    // getting invoice preview data
    useEffect(() => {
        async function findInvoiceDataForPreview() {
            await axios
                .get(`/api/finance/findInvoiceDataForPreview?projectId=${projectId}&userId=${auth.data.id}`)
                .then((res) => {
                    // console.log(res, " :: res findInvoiceDataForPreview");
                    const [expertData, [projectData]] = res.data;

                    const invoicePreviewData = {
                        expertData,
                        projectData,
                    };

                    setInvoicePreviewData(invoicePreviewData);
                    setLoadingInvoicePreviewData(false);
                })
                .catch((err) => {
                    console.log(err, " :: err findInvoiceDataForPreview");
                });
        }

        findInvoiceDataForPreview();
    }, []);

    // on page load
    useEffect(() => {
        getPricingInfo(projectId);
    }, []);

    const marginCalculator = (revenue, costs) => {
        // FORMULA: margin = 100 * (revenue - costs) / revenue
        const margin = (100 * (parseInt(revenue, 10) - parseInt(costs, 10))) / parseInt(revenue, 10);
        return Math.ceil(margin);
    };

    // fetch project and expert level info for pricing page
    const getPricingInfo = async (projectId) => {
        await axios
            .get(`/api/finance/pricingInfo?projectId=${projectId}`)
            .then((res) => {
                // console.log("ACCESS ARRAY: ", APPROVAL_ACCESS_LIST);
                console.log("Response from getPricingInfo method", res);
                if (res.data) {
                    const [projectData, expertData] = res.data;
                    const charges = projectData?.total_charge_amount;
                    const payments = projectData?.total_payment_amount;
                    const margin = charges - payments;

                    const activeExpertRows = [];
                    const oneOffRows = [];

                    // filter the experts
                    for (const expert of expertData) {
                        if (expert.is_custom_charge) {
                            oneOffRows.push(expert);
                        } else if (expert.is_active === true) {
                            activeExpertRows.push(expert);
                        }
                    }

                    // We only want active scheduled calls to be displayed on the pricing page for approval.
                    // const activeExpertRows = expertData.filter((e) => e.is_active === true);
                    // console.log(projectData, activeExpertRows, " :: projectData, expertDataRows");
                    setProjectData(projectData);
                    setExpertData(activeExpertRows);
                    setOneOffExpData(oneOffRows);
                    setLoading(false);
                    setTotalCharge(charges);
                    setTotalPayment(payments);
                    setMargin(margin);
                    if (projectData?.price_per_unit < 1) {
                        alert("Please enter a price per unit. No calculations will be made until this is done.");
                    }
                } else {
                    setInvalidPID(true);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err.response.data.message, " :: err.response.data.message err");
                console.log(" :: right before flash message");
                setFlashMsg(err.response.data.message);
                setSeverity("error");
                setOpenFlashMessage(true);
            });
    };

    // handle funcs
    const navigateToProject = () => {
        history.push(`/project/view/${props.match.params.id}`);
    };

    function handleChange(event, newValue) {
        setTabValue(newValue);
    }

    // function handleApprovalClick() {
    //     console.log("====== handleApprovalClick ======");
    //     setOpenApprovalNoteModal(true);
    //     // console.log(approvalNote, " :: approvalNote");
    // }

    // function handleSaveNote(noteTxt) {
    //     confirmApproval({ ...approvalData, invoicing_notes: noteTxt });
    // }

    // async function confirmApproval(postObjData) {
    //     if (confirm("Do you 100%, definitely want to approve this charge??")) {
    //         await axios
    //             .post("/api/finance/upsertFinanceChargeData", { ...postObjData })
    //             .then((res) => {
    //                 console.log(res, " :: res");
    //                 getPricingInfo(projectId);
    //                 if (openApprovalNoteModal) {
    //                     setOpenApprovalNoteModal(false);
    //                 }
    //             })
    //             .catch((err) => {
    //                 console.log(err.response.data.message, " :: err.response.data.message err");
    //             });
    //     }
    // }

    function handleApprovalClick(event, postObjData = {}) {
        console.log("APPROVAL POST OBJECT: ", postObjData);
        if (postObjData.expertOrClientApproval === "expert") {
            setMessageModal(true);
            setPostObjData(postObjData);
        } else {
            setApprovalData(postObjData);
            setOpenApprovalNoteModal(true);
        }
    }

    function approvePaymentNow() {
        console.log("APPROVAL POST OBJECT FROM MODAL: ", postObject);
        axios
            .post("/api/finance/upsertFinanceChargeData", postObject)
            .then((res) => {
                console.log("SUCCESSFUL PAYMENT APPROVAL: ", res);
                getPricingInfo(projectId);
                handleClose();
            })
            .catch((err) => {
                console.log(err.response.data.message, " :: err.response.data.message err");
            });
    }

    function handleClose() {
        setMessageModal(false);
    }

    function handleOpenAdditionalClientEmail() {
        setOpenAddClientContactModal(true);
    }

    async function generateInvoicePreview() {
        setOpenInvoicePreviewModal(true);
    }

    async function generateInvoice(emailList) {
        await axios
            .post("/api/finance/insertInvoice", {
                project_id: projectId,
                user_id: auth.data.id,
                additional_emails: emailList,
            })
            .then((res) => {
                console.log(res, " :: insertInvoice API Response");
                const { success, message } = res?.data;
                if (!success) {
                    setFlashMsg(message);
                    setSeverity("error");
                    setOpenFlashMessage(true);
                    setDisableInvoicingBtn(true);
                    setOpenAddClientContactModal(false);
                } else {
                    setFlashMsg(message);
                    setSeverity("success");
                    setOpenFlashMessage(true);
                    setDisableInvoicingBtn(true);
                    setOpenAddClientContactModal(false);
                }
            })
            .catch((err) => {
                console.log(err, " :: err");
                setFlashMsg(err.response.data.message);
                setSeverity("error");
                setOpenFlashMessage(true);
                setDisableInvoicingBtn(true);
                setOpenAddClientContactModal(false);
            });
    }

    // different screens based on return data
    if (loading) {
        return <h1 className={classes.header}>Loading</h1>;
    } else if (invalidPID) {
        return (
            <>
                <h1 className={classes.header}>Invalid Project Id</h1>
                {openFlashMessage && (
                    <FlashMessagev3
                        openSnackbar={openFlashMessage}
                        setOpenSnackbar={setOpenFlashMessage}
                        severity={severity}
                        message={flashMsg}
                    />
                )}
            </>
        );
    } else {
        return (
            <>
                <Dialog fullWidth maxWidth="md" open={messageModal} onClose={handleClose}>
                    <DialogTitle>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" size="large">
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <p className={classes.messageHeader}>
                            <strong>WARNING:</strong> Before you approve any payment to an expert, please confirm the
                            call duration, hourly rate, minimum hourly or custom changes with the research associate in
                            contact with the expert. This is important because once an expert payment has been approved,
                            it can not be reversed.
                        </p>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={approvePaymentNow}>Information Confirmed, Approve Payment Now</Button>
                    </DialogActions>
                </Dialog>
                <div className={classes.accSumContainer}>
                    <Box display="flex" flexDirection="row" alignItems="center">
                        {projectData.fair_market_value > 0 && (
                            <div className={classes.accSumItemWarning}>
                                <span className={classes.boldedTxt}>{`Fair Market Value:`}</span>
                                {` * ${numberWithCommas(projectData.fair_market_value)}`}
                            </div>
                        )}
                        <div className={classes.accSumItem}>
                            <span className={classes.boldedTxt}>{`Price Per Unit:`}</span>
                            {` ${numberWithCommas(projectData.price_per_unit)}`}
                        </div>
                        {projectData.custom_charge > 0 && (
                            <div className={classes.accSumItem}>
                                <span className={classes.boldedTxt}>{`Cost Plus:`}</span>
                                {` ${numberWithCommas(projectData.custom_charge)} / ${projectData.custom_hourly_rate}`}
                            </div>
                        )}
                        {projectData.premium_pricing_model !== "Not Applicable" && (
                            <div className={classes.accSumItem}>
                                <span className={classes.boldedTxt}>{`Pricing Model:`}</span>
                                {` ${projectData.premium_pricing_model}`}
                            </div>
                        )}
                        {projectData.duration_model !== "Not Applicable" && (
                            <div className={classes.accSumItem}>
                                <span className={classes.boldedTxt}>{`Duration Model:`}</span>
                                {` ${projectData.duration_model}`}
                            </div>
                        )}
                        <div className={classes.accSumItem}>
                            <span className={classes.boldedTxt}>{`Grace Period:`}</span>
                            {` ${projectData.grace_period}`}
                        </div>
                        {projectData.unit_multiplier > 1 && (
                            <div className={classes.accSumItem}>
                                <span className={classes.boldedTxt}>{`Unit Multiplier:`}</span>
                                {` ${numberWithCommas(projectData.unit_multiplier)}`}
                            </div>
                        )}
                        {projectData.transaction_fee > 0 && (
                            <div className={classes.accSumItem}>
                                <span className={classes.boldedTxt}>{`Transaction Hourly Fee:`}</span>
                                {` ${numberWithCommas(projectData.transaction_fee)}`}
                            </div>
                        )}
                        {projectData.project_fee > 0 && (
                            <div className={classes.accSumItem}>
                                <span className={classes.alert}>{`Project Fee:`}</span>
                                {` ${numberWithCommas(projectData.project_fee)}`}
                            </div>
                        )}
                        {projectData.budget > 0 && (
                            <div className={classes.accSumItem}>
                                <span className={classes.alert}>{`* Budget:`}</span>
                                {` ${numberWithCommas(projectData.budget)}`}
                            </div>
                        )}
                    </Box>
                    <Button className={classes.nameButton} onClick={navigateToProject}>
                        {projectData.title}
                    </Button>
                    <Grid container spacing={3}>
                        <Grid item sm={6} xs={12}>
                            <h4 className={classes.metadata}>
                                Client: <strong>{projectData.client_name}</strong>
                            </h4>
                            {projectData.case_code && (
                                <h4 className={classes.metadata}>
                                    Case Code: <strong>{projectData.case_code}</strong>
                                </h4>
                            )}
                            <h4 className={classes.metadata}>
                                Primary Contact:{" "}
                                <strong>{`${projectData.client_contact_fname} ${projectData.client_contact_lname}`}</strong>
                            </h4>
                            <h4 className={classes.metadata}>
                                {projectData.project_type} - Calls Booked:{" "}
                                <strong>
                                    {expertData.length} out of {projectData.calls_planned}
                                </strong>
                            </h4>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <h4 className={classes.metadata}>
                                Total Client Charge Amount: <strong>{`$${numberWithCommas(totalCharge)}`}</strong>{" "}
                                {projectData.project_fee > 0 && <span className={classes.alert}>* Includes Fee</span>}
                                {projectData.budget > 0 && totalCharge > projectData.budget ? (
                                    <span className={classes.alert}>* Warning</span>
                                ) : (
                                    ""
                                )}
                            </h4>
                            <h4 className={classes.metadata}>
                                Total Expert Payment Amount: <strong>{`$${numberWithCommas(totalPayment)}`}</strong>
                            </h4>
                            {/* <h4 className={classes.metadata}>
                                Project Revenue/Margin: <strong>{`$${numberWithCommas(margin)}`}</strong> /{" "}
                                {marginCalculator(totalCharge, totalPayment) > 66 ? (
                                    <span className={classes.good}>
                                        {marginCalculator(totalCharge, totalPayment)}
                                        {"%"}
                                    </span>
                                ) : (
                                    <span className={classes.bad}>
                                        {marginCalculator(totalCharge, totalPayment)}
                                        {"%"}
                                    </span>
                                )}
                            </h4> */}
                        </Grid>
                    </Grid>
                </div>

                <Box sx={{ borderBottom: 1, borderColor: "divider", marginTop: "2rem" }}>
                    <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Transactional Charges" {...a11yProps(0)} />
                        <Tab label="One-Off Charges" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <TabPanel value={tabValue} index={0}>
                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                        <Button
                            style={{ marginRight: ".5rem" }}
                            className={classes.invoiceBtn}
                            variant="contained"
                            onClick={generateInvoicePreview}
                        >
                            Preview Invoice
                        </Button>
                        <Button
                            variant="contained"
                            disabled={disableInvoicingBtn || loadingInvoicePreviewData}
                            className={classes.invoiceBtn}
                            onClick={handleOpenAdditionalClientEmail}
                        >
                            Generate Invoice
                        </Button>
                    </Box>
                    <ProjectPricingTable
                        expertData={expertData}
                        projectData={projectData}
                        projectId={projectId}
                        user={auth.data}
                        getPricingInfo={getPricingInfo}
                        numberWithCommas={numberWithCommas}
                        handleApprovalClick={handleApprovalClick}
                        accessList={APPROVAL_ACCESS_LIST}
                    />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <OneOffChargeTable
                        oneOffExpData={oneOffExpData}
                    />
                </TabPanel>
                {openFlashMessage && (
                    <FlashMessagev3
                        openSnackbar={openFlashMessage}
                        setOpenSnackbar={setOpenFlashMessage}
                        severity={severity}
                        message={flashMsg}
                    />
                )}
                {openApprovalNoteModal && (
                    <ApprovalNoteModal
                        openApprovalNoteModal={openApprovalNoteModal}
                        setOpenApprovalNoteModal={setOpenApprovalNoteModal}
                        approvalData={approvalData}
                        setApprovalData={setApprovalData}
                        getPricingInfo={getPricingInfo}
                        projectId={projectId}
                    />
                )}
                {openAddClientContactModal && !loadingInvoicePreviewData && (
                    <AddClientContactModal
                        openAddClientContactModal={openAddClientContactModal}
                        setOpenAddClientContactModal={setOpenAddClientContactModal}
                        invoicePreviewData={invoicePreviewData}
                        generateInvoice={generateInvoice}
                    />
                )}
                {openInvoicePreviewModal && !loadingInvoicePreviewData && (
                    <InvoicePreviewModal
                        openInvoicePreviewModal={openInvoicePreviewModal}
                        setOpenInvoicePreviewModal={setOpenInvoicePreviewModal}
                        invoicePreviewData={invoicePreviewData}
                    />
                )}
            </>
        );
    }
};

export default ProjectPricing;
