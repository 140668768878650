import * as React from "react";
import axios from "axios";

// MUI components
import { Skeleton, Box } from "@mui/material";

// MUI - style imports
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";

const skeletonStyles = css`
    margin-bottom: 1rem;
`;

const countBoxContainerStyles = css`
    margin-bottom: 0.5rem;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    padding: 1rem 1rem;
`;

const countRowStyles = css`
    & > div {
        margin-right: 0.75rem;
        margin-bottom: 0.75rem;
        color: #444;
        font-size: 0.865rem;
    }
    & > div > b {
        color: orange;
        display: inline-block;
        margin-right: 0.5rem;
    }
`;

export default function AutoOutreachCounts(props) {
    // CONSTANTS
    const { project_id } = props;

    // STATE
    const [counts, setCounts] = React.useState({});
    const [loading, setLoading] = React.useState(true);

    // PAGE LOAD
    React.useEffect(() => {
        axios
            .get(`/api/project/now/findAutoOutreachCounts/${project_id}`)
            .then((res) => {
                if (res?.data?.data?.counts) {
                    setCounts(res?.data?.data?.counts);
                    setLoading(false);
                }
            })
            .catch((err) => console.log(err, " :: err"));
    }, []);

    if (loading) {
        return <Skeleton css={skeletonStyles} variant="rectangular" width={"100%"} height={"10rem"} />;
    } else {
        return (
            <div css={countBoxContainerStyles}>
                <h2 style={{ fontSize: "1.5rem" }}>Automation Snapshot</h2>
                <Box display="flex" flexDirection="row" css={countRowStyles} flexWrap="wrap" rowGap="1rem">
                    <div>
                        STILL ACTIVE: <b>{counts.all_active_count}</b>
                        {" | "}
                    </div>
                    <div>
                        INITIAL OUTREACH: <b>{counts.initial_outreach_count}</b>
                        {" | "}
                    </div>
                    <div>
                        FIRST FOLLOWUP: <b>{counts.followup_1_count}</b>
                        {" | "}
                    </div>
                    <div>
                        SECOND FOLLOWUP: <b>{counts.followup_2_count}</b>
                        {" | "}
                    </div>
                    <div>
                        FINAL FOLLOWUP: <b>{counts.followup_3_count}</b>
                    </div>
                </Box>
                <Box display="flex" flexDirection="row" css={countRowStyles} flexWrap="wrap" rowGap="1rem">
                    <div>
                        ACCEPTED: <b>{counts.onboarding_link_count}</b>
                        {" | "}
                    </div>
                    <div>
                        SCREENER COMPLETE: <b>{counts.survey_completed_count}</b>
                        {" | "}
                    </div>
                    <div>
                        CALLS COMPLETED: <b>{counts.calls_completed_count}</b>
                    </div>
                </Box>
                <Box display="flex" flexDirection="row" css={countRowStyles} flexWrap="wrap" rowGap="1rem">
                    <div>
                        NO RESPONSE: <b>{counts.not_responded_count}</b>
                        {" | "}
                    </div>
                    <div>
                        DECLINED: <b>{counts.decline_link_count}</b>
                        {" | "}
                    </div>
                    <div>
                        UNSUBSCRIBED: <b>{counts.unsubscribe_link_count}</b>
                    </div>
                </Box>
            </div>
        );
    }
}
