import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import makeStyles from "@mui/styles/makeStyles";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import theme from "../theme";
import { InputAdornment } from "@mui/material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import PersonIcon from "@mui/icons-material/Person";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginBottom: theme.spacing(2),
    },
    container: {
        position: "relative",
        borderRadius: "2px",
        backgroundColor: "#ffffff",
        width: 400,
    },
    suggestionsContainerOpen: {
        position: "absolute",
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: theme.spacing(1),
        right: theme.spacing(1),
    },
    suggestion: {
        display: "block",
    },
    suggestionHighlighted: {
        backgroundColor: "#ddd3ee",
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: "none",
    },
    sectionTitle: {
        fontSize: 16,
    },
    sectionContainer: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
    },
    input: {
        marginLeft: theme.spacing(1),
        minWidth: 250,
    },
    badgeMargin: {
        margin: theme.spacing(0),
    },
    badgePadding: {
        marginRight: theme.spacing(2),
    },
    iconStyles: {
        "&:hover": {
            cursor: "pointer",
            color: "#552397",
        },
    },
}));

function NavBar({ history }) {
    const classes = useStyles();

    const [inputValue, setInputValue] = React.useState("");
    const [stateSuggestions, setSuggestions] = useState([]);
    const [nameOrSurvey, setNameOrSurvey] = useState("name");

    // HANDLE FUNCS
    function handleIconClick() {
        if (nameOrSurvey === "name") {
            setNameOrSurvey("survey");
        } else if (nameOrSurvey === "survey") {
            setNameOrSurvey("name");
        }
    }

    function renderInputComponent(inputProps) {
        const { classes, inputRef = () => {}, ref, ...other } = inputProps;

        return (
            <TextField
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment className={classes.iconStyles} onClick={handleIconClick} position="end">
                            {nameOrSurvey === "name" ? <PersonIcon /> : <FormatListBulletedIcon />}
                        </InputAdornment>
                    ),
                    inputRef: (node) => {
                        ref(node);
                        inputRef(node);
                    },
                    classes: {
                        input: classes.input,
                    },
                }}
                {...other}
            />
        );
    }

    function getCurrentCompany(employment) {
        const employer = employment.find((i) => i.present === true);
        if (employer) {
            return employer.company;
        }
        return employment[0].company;
    }

    function renderNameSuggestion(suggestion, { query, isHighlighted }) {
        const matches = match(suggestion.name + " " + suggestion.suffix, query);
        const parts = parse(suggestion.name + " " + suggestion.suffix, matches);
        return (
            <MenuItem selected={isHighlighted} component="div">
                <div>
                    {parts.map((part, index) => (
                        <span
                            key={index}
                            style={{
                                fontWeight: part.highlight ? 500 : 400,
                                color: part.highlight ? theme.palette.secondary.main : "black",
                            }}
                        >
                            {part.text}
                        </span>
                    ))}
                    <br />
                    <span style={{ fontSize: "13px" }}>{suggestion.headline}</span>
                    {suggestion.emp_hist_list && suggestion.emp_hist_list.length > 0 && (
                        <span style={{ fontSize: "13px" }}>
                            <br />
                            {getCurrentCompany(suggestion.emp_hist_list)}
                        </span>
                    )}
                </div>
            </MenuItem>
        );
    }

    function renderSurveySuggestion(suggestion, { query, isHighlighted }) {
        const { question, survey_title, expert_array } = suggestion;

        const matches = match(question, query);
        const parts = parse(question, matches);

        return (
            <MenuItem selected={isHighlighted} component="div">
                <div>
                    <div style={{ fontSize: "13px" }}>
                        <b>Screener:</b> {survey_title}
                    </div>
                    <div style={{ fontSize: "13px" }}>
                        <b>Question:</b>{" "}
                        {parts.map((part, index) => (
                            <span
                                // key={part.text}
                                key={index}
                                style={{
                                    fontWeight: part.highlight ? 500 : 400,
                                    color: part.highlight ? theme.palette.secondary.main : "black",
                                }}
                            >
                                {part.text}
                            </span>
                        ))}
                    </div>
                    <div style={{ fontSize: "13px" }}>
                        <b>Completed Experts: </b>
                        {expert_array.length}
                    </div>
                </div>
            </MenuItem>
        );
    }

    function getNameSuggestionValue(suggestion) {
        return suggestion.name + " " + suggestion.suffix;
    }

    function getSurveySuggestionValue(suggestion) {
        return suggestion.survey_title;
    }

    const handleSuggestionsFetchRequested = async ({ value }) => {
        let url = `/api/expert/search?searchtext=${value}&nameOrSurvey=name`;

        if (nameOrSurvey === "name") {
            url = `/api/expert/search?searchtext=${value}&nameOrSurvey=name`;
        } else if (nameOrSurvey === "survey") {
            url = `/api/expert/search?searchtext=${value}&nameOrSurvey=survey`;
        }
        const searchedExperts = await axios.get(url);
        console.log("searchedExperts", searchedExperts.data.data);
        if (
            searchedExperts &&
            searchedExperts.data &&
            searchedExperts.data.data &&
            searchedExperts.data.data.length > 0
        ) {
            const suggestions = [];
            if (searchedExperts.data.data && searchedExperts.data.data.length > 0 && nameOrSurvey === "name") {
                suggestions.push({
                    title: "Experts",
                    items: searchedExperts.data.data,
                });
            } else if (searchedExperts.data.data && searchedExperts.data.data.length > 0 && nameOrSurvey === "survey") {
                // console.log(searchedExperts, " :: searchedExperts");
                suggestions.push({
                    title: "Surveys",
                    items: searchedExperts.data.data,
                });
            }
            // if (
            //   searchedExperts.data.data[1] &&
            //   searchedExperts.data.data[1].length > 0
            // ) {
            //   suggestions.push({
            //     title: "Client Contacts",
            //     items: searchedExperts.data.data[1]
            //   });
            // }
            setSuggestions(suggestions);
        } else {
            setSuggestions([]);
        }
    };

    function renderSectionTitle(section) {
        return (
            <Badge className={classes.badgeMargin} badgeContent={section.items.length} color="primary">
                <Typography className={classes.badgePadding} variant="subtitle2">
                    {section.title}
                </Typography>
            </Badge>
        );
    }

    function getSectionSuggestions(section) {
        return section.items;
    }

    const handleSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    function expertSelected(event, { suggestion }) {
        console.log("++++++", suggestion);
        // if (suggestion.type === "expert") {
        history.push(`/expert/view/${suggestion.id}`);
        // }
        // if (suggestion.type === "clientContact") {
        //   history.push(`/contact/view/${suggestion.id}`);
        // }

        setSuggestions([]);
        setInputValue("");
    }

    function surveySelected(event, { suggestion }) {
        const { expert_array, survey_id, project_id } = suggestion;

        history.push(
            `/typeahead/surveys?expert_array=${expert_array.join(",")}&project_id=${project_id}&survey_id=${survey_id}`
        );

        setSuggestions([]);
        setInputValue("");
    }

    function shouldRenderSuggestions(value) {
        return value.trim().length > 2;
    }

    const handleChange =
        () =>
        (event, { newValue }) => {
            setInputValue(newValue);
        };

    const autosuggestProps = {
        multiSection: true,
        renderInputComponent,
        suggestions: stateSuggestions,
        onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
        onSuggestionsClearRequested: handleSuggestionsClearRequested,
        getSuggestionValue: nameOrSurvey === "name" ? getNameSuggestionValue : getSurveySuggestionValue,
        renderSuggestion: nameOrSurvey === "name" ? renderNameSuggestion : renderSurveySuggestion,
        onSuggestionSelected: nameOrSurvey === "name" ? expertSelected : surveySelected,
        renderSectionTitle,
        getSectionSuggestions,
        shouldRenderSuggestions,
    };

    return (
        <div>
            <Autosuggest
                {...autosuggestProps}
                inputProps={{
                    classes,
                    id: "react-autosuggest-simple",
                    label: " ",
                    placeholder: nameOrSurvey === "name" ? "Search an Expert ..." : "Search a Screener ...",
                    value: inputValue,
                    onChange: handleChange(),
                }}
                theme={{
                    container: classes.container,
                    suggestionsContainerOpen: classes.suggestionsContainerOpen,
                    suggestionsList: classes.suggestionsList,
                    suggestion: classes.suggestion,
                    sectionTitle: classes.sectionTitle,
                    suggestionHighlighted: classes.suggestionHighlighted,
                    sectionContainer: classes.sectionContainer,
                }}
                renderSuggestionsContainer={(options) => (
                    <Paper {...options.containerProps} square style={{ maxHeight: 750, overflow: "auto" }}>
                        {options.children}
                    </Paper>
                )}
            />
        </div>
    );
}

export default withRouter(NavBar);
