import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import axios from "axios";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CancelIcon from "@mui/icons-material/Cancel";
// import CheckBoxOutlined from "@mui/icons-material/CheckBoxOutlined";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(2),
        overflowX: "auto",
    },
    table: {
        minWidth: 750,
    },
    button: {
        margin: theme.spacing(1),
    },
    link: {
        color: "#19857b",
        textDecoration: "none",
        fontSize: "1rem",
    },
}));

function Admin() {
    const [data, setData] = useState([]);
    const classes = useStyles();

    useEffect(() => {
        axios
            .get("/api/person/employees", {
                email: "aanand@email.com",
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    const users = result.data.data.filter((u) => u.isactive);

                    setData(users);
                }
            });
    }, []);

    function renderActiveIcon(rowData) {
        if (rowData.isactive) {
            return <CheckBoxIcon color="primary" />;
        } else {
            return <CancelIcon color="secondary" />;
        }
    }
    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Button variant="contained" color="secondary" component={Link} to={"/user/add"}>
                        Add New User
                    </Button>
                </Grid>
            </Grid>
            <br />
            <h4>* Administrator</h4>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Name</TableCell>
                        <TableCell align="left">Email</TableCell>
                        <TableCell align="left">Phone</TableCell>
                        <TableCell align="right">Active</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell align="left">
                                <Link className={classes.link} to={"/user/edit/" + row.id}>
                                    {row.name}
                                </Link>{" "}
                                {row.isadmin && "*"}
                            </TableCell>
                            <TableCell align="left">{row.email}</TableCell>
                            <TableCell align="left">{row.number}</TableCell>
                            <TableCell align="right">{renderActiveIcon(row)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
}
export default Admin;
