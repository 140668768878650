import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

// to use this component, you just need to pass in as props the openFlashMessage and setOpenFlash message states
// as well as the actual message you want to have pop up

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const FlashMessage = (props, ref) => {
    // console.log("Entered FLASH MESSAGE Component: ", { "PROPS" : props, "REF" : ref });
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            // console.log("FLASH MESSAGE OBJECT: ", {"PROPS": props, "REASON": reason});
            return;
        }
        props.setOpenFlashMessage(false);
    };

    return (
        <div>
            <Snackbar open={props.openFlashMessage} autoHideDuration={3000} onClose={handleClose}>
                <div>
                    <Alert onClose={handleClose} severity={props.severity}>
                        {props.message}
                    </Alert>
                </div>
            </Snackbar>
        </div>
    );
};

export default FlashMessage;
