import React, { useState, useEffect, useCallback, useContext } from "react";
// import update from "immutability-helper";
import queryString from "query-string";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import Select from "react-select";
import Snackbar from "@mui/material/Snackbar";
import Grid from "@mui/material/Grid";
import axios from "axios";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircle from "@mui/icons-material/CheckCircleOutline";
import Paper from "@mui/material/Paper";
import Slide from "@mui/material/Slide";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
// import moment from "moment";
import "moment-duration-format";
import PageTitle from "../components/PageTitle";
import { authContext } from "../context/auth";
import { extractNameString, extractIndex, basicDateTimeStamp } from "../Utils";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import AnglesCreationTool from "../components/project/AnglesCreationTool";

const dialogTitle = css`
    font-size: 1.5rem;
    text-transform: uppercase;
    color: #523197;
`;

const SurveyTypes = [
    { value: 1, label: "Regular Screening Questions" },
    { value: 2, label: "Follow Up Screening Questions" },
    { value: 3, label: "Compliance Questions" },
    { value: 4, label: "Written Follow Up" },
];

const QuestionTypes = [
    { value: 1, type: "freetext", label: "Free Text" },
    { value: 2, type: "multiple", label: "Multiple" },
    { value: 3, type: "likert", label: "Likert" },
];

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "left",
        color: theme.palette.text.secondary,
        clear: "both",
        marginBottom: "20px",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    likert: {
        display: "inline",
    },
    appBar: {
        position: "relative",
    },
    table: {
        width: "100%",
    },
    projectBox: {
        backgroundColor: "#f5f5f5",
        borderBottom: "4px solid #eaeaea",
        borderRadius: "3px",
        padding: "20px",
        marginBottom: "30px",
    },
    modalHeading: {
        color: "#325C55",
        fontSize: "1.25rem",
        fontWeight: "normal",
        marginTop: "20px",
        marginBottom: "15px",
    },
    heading: {
        color: "#325C55",
        fontSize: "1.5rem",
        fontWeight: "normal",
        textTransform: "uppercase",
    },
    subheading: {
        color: "#000",
        fontSize: ".75rem",
        fontWeight: "bold",
        textTransform: "uppercase",
    },
    instructions: {
        fontSize: ".75rem",
        color: "#325C55",
    },
    surveyTitle: {
        color: "#3c8864",
        fontSize: "1.25rem",
    },
    surveyType: {
        color: "#444",
        fontSize: ".75rem",
        textTransform: "uppercase",
    },
    link: {
        color: "#3c8864",
        textDecoration: "none",
    },
    projectMeta: {
        color: "#444",
        fontSize: ".65rem",
        marginBottom: "5px",
        textTransform: "uppercase",
    },
    type: {
        color: "#3c8864",
        fontSize: ".5rem",
        marginBottom: "5px",
        textTransform: "uppercase",
    },
    question: {
        color: "#000",
        fontSize: "1.25rem",
        marginBottom: "15px",
        display: "block",
        clear: "both",
    },
    edit: {
        marginRight: "20px",
    },
    submit: {
        backgroundColor: "#4CAB7D",
    },
    editBtn: {
        textAlign: "center",
        margin: "0 auto",
    },
    prev: {
        marginRight: "20px",
    },
    label: {
        color: "#000",
        fontWeight: "bold",
        fontSize: "1rem",
    },
    actions: {
        float: "right",
        marginBottom: "20px",
    },
    comments: {
        marginTop: "40px",
    },
    alertSuccess: {
        color: "#fff",
        fontWeight: "500",
        backgroundColor: "#4caf50",
        borderRadius: "3px",
        display: "flex",
        padding: "6px 16px",
    },
    publishBanner: {
        color: "#fff",
        fontWeight: "500",
        backgroundColor: "#4caf50",
        borderRadius: "3px",
        display: "flex",
        padding: "6px 16px",
        marginBottom: "15px",
    },
    bannerActions: {
        float: "right",
    },
    copyButton: {
        backgroundColor: "white",
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

let existingAngleTags = [];
let existingAnglesScreeners = [];

function SurveyShow(props) {
    const { match, history } = props;
    // const { id } = match.params;
    const pageUrl = props.location.search;
    const params = queryString.parse(pageUrl);
    const classes = useStyles();
    const { setAuthData, auth } = useContext(authContext);
    const [openLoader, setOpenLoader] = useState(true);
    const [outreachTemplatesModal, setOutreachTemplatesModal] = useState(false);
    const [publishButtonDisabled, setPublishButtonDisabled] = useState(true);
    const [questionModal, setQuestionModal] = useState(false);
    const [clone, setCloneModal] = useState(false);
    const [all, setAllProjects] = useState([]);
    const [users, setUsers] = useState([]);
    // TODO: Change default to disable = true if validation required
    const [disable, setDisable] = useState(false);
    const [previous, setPrevious] = useState(false);
    const [next, setNext] = useState(true);
    const [scale, setLikert] = useState([1, 2, 3, 4, 5]);
    const [success, setSuccess] = useState(false);
    const [question, setQuestion] = useState({
        order: "",
        id: "",
        question_id: "",
        survey_id: "",
        question_type_id: 0,
        question_type: "",
        text: "",
        scale: null,
        is_dirty: false,
        multiple: false,
        disqualifier: false,
        comments: "",
        answers: [],
    });
    const [project, setProject] = useState({
        id: "",
        title: "",
        description: "",
        primary_project_manager: "",
        client_id: "",
        client_name: "",
        status: "",
        type: "",
        case_code: "",
        tags: [],
        surveys: [], // All questions ordered within this survey
        angles: [],
        outreach_templates: [],
    });
    const [data, setData] = useState({
        created_by: "",
        created_on: "",
        updated_by: auth.data.id,
        updated_on: "",
        id: props.match.params.id,
        type: "1",
        title: "",
        published: false,
        lock: false,
        project_id: "",
        questions: [], // All questions ordered within this survey
        respondents: [], // All responses to this survey
        tags: [], // All models should have tags
    });
    const [answer, setAnswer] = useState({
        id: "",
        label: "",
        user_answer: "",
        parentquestion: "",
        followupquestion: "",
        disqualifier: false,
    });
    const [selectedProject, setSelectedProject] = useState({
        value: "",
        label: "",
    });

    async function fetchAllUsers() {
        axios
            .get("/api/rm/list", {
                email: "aanand@email.com",
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    const userData = result.data.data;
                    console.log("RESEARCH TEAM: ", userData);
                    setUsers(userData);
                }
            });
    }
    // LOAD PROJECT lIST
    const fetchProjectList = useCallback(async () => {
        const projectlist = await axios.get("/api/project/recentlist");
        if (projectlist.status === 200 && projectlist.data && projectlist.data.data) {
            // Set for Autocomplete
            const projectsObject = projectlist.data.data.map((item) => {
                return {
                    value: item.id,
                    label: item.title,
                };
            });
            setAllProjects(projectsObject);
            console.log("ALL PROJECTS LIST: ", projectsObject);
        }
    }, []);

    // LOAD PROJECT
    const fetchProjectById = useCallback(async (id) => {
        const pData = await axios.get(`/api/project/find?id=${id}&experts=false`);
        if (pData.status === 200 && pData.data && pData.data.data) {
            console.log("FETCHED PROJECT DATA: ", pData);

            const proj = pData.data.data.projectData[0];
            console.log("PROJ: ", proj);
            const tags = pData.data.data.tags.map((tag) => {
                return { name: tag.name, id: tag.tag_id };
            });
            const surveys = pData.data.data.surveys;
            const clean_templates = proj.outreach_templates;
            if (clean_templates && clean_templates.length > 0) {
                clean_templates.forEach((template) => {
                    template.updated = "";
                });
            }
            const outreach_templates = clean_templates;
            const angles = proj.angles;
            console.log("FETCHED PROJECT DATA: ", pData);
            setPublishButtonDisabled(false);
            setProject({
                ...project,
                id: proj.id,
                title: proj.title,
                description: proj.description,
                primary_project_manager: `${proj.project_manager_fname} ${proj.project_manager_lname}`,
                client_id: proj.client_id,
                client_name: proj.client_name,
                status: proj.project_status,
                type: proj.project_type,
                case_code: proj.case_code,
                tags,
                surveys,
                outreach_templates,
                angles,
            });
            setOpenLoader(false);
            // Set default project as this current one in autocomplete...
            setSelectedProject({
                ...selectedProject,
                value: parseInt(proj.id, 10),
                label: proj.title,
            });
            // Fetch Projects List for Autocomplete
            fetchProjectList();
            existingAngleTags = angles && angles.length > 0 && angles.map((item) => parseInt(item.tag_id, 10));
            existingAnglesScreeners =
                angles && angles.length > 0 && angles.map((item) => parseInt(item.screener_id, 10));
            console.log("CHECK FOR EXISTING ANGLE TAGS: ", { existingAngleTags, existingAnglesScreeners });
        }
    }, []);

    const fetchSurveyByID = useCallback(async (id) => {
        const response = await axios.get(`/api/survey/${id}`);
        if (response.status === 200 && response.data && response.data.data) {
            const survey = response.data.data;
            const sortedQuestions = survey.questions.sort((a, b) => a.order - b.order);
            console.log("FETCHED SURVEY RESPONSE: ", survey);
            console.log("FETCHED SURVEY QUESTION ORDER: ", sortedQuestions);
            fetchProjectById(survey.project_id);
            setData({
                ...data,
                created_by: parseInt(survey.created_by, 10),
                created_on: survey.created_on,
                updated_on: survey.updated_on,
                updated_by: parseInt(survey.updated_by, 10),
                id: parseInt(survey.id, 10),
                type: parseInt(survey.type, 10),
                title: survey.title,
                published: survey.published,
                project_id: parseInt(survey.project_id, 10),
                questions: sortedQuestions,
                lock: false,
                respondents: [],
                tags: [],
            });
            setOpenLoader(false);
            // Set the FIRST question on fetch...
            console.log("COMMENTS: ", survey.questions[0].comments);
            setQuestion({
                ...question,
                order: survey.questions[0] && survey.questions[0].order,
                id: survey.questions[0] && survey.questions[0].id,
                question_id: survey.questions[0] && survey.questions[0].question_id,
                survey_id: survey.questions[0] && survey.questions[0].survey_id,
                question_type_id: survey.questions[0] && survey.questions[0].question_type_id,
                question_type: survey.questions[0] && survey.questions[0].question_type,
                text: survey.questions[0] && survey.questions[0].text,
                scale: survey.questions[0] && survey.questions[0].scale,
                is_dirty: survey.questions[0] && survey.questions[0].is_dirty,
                multiple: survey.questions[0] && survey.questions[0].multiple,
                comments: survey.questions[0] && survey.questions[0].comments ? survey.questions[0].comments : "",
                disqualifier: survey.questions[0] && survey.questions[0].disqualifier,
                answers: survey.questions[0] && survey.questions[0].answers,
            });
        }
    }, []);

    const handleCloseMessage = () => {
        setSuccess(false);
    };
    const handleClose = () => {
        setCloneModal(false);
        setOutreachTemplatesModal(false);
        setQuestionModal(false);
    };

    const handleOpenOutreachTemplatesModal = () => {
        setQuestionModal(false);
        setOutreachTemplatesModal(true);
    };

    const handleOpenQuestionModal = () => {
        setQuestionModal(true);
    };

    function handleCheckboxChange(event) {
        const { target } = event;
        const { value, name } = target;
        // Index of the parent label question
        const idx = extractIndex(name);
        const nameString = extractNameString(name);
        const answerLabels = [...question.answers];
        console.log("INDEX: ", idx);
        console.log("CHECKBOX: ", name);
        console.log("VALUE: ", event.target.checked);
        console.log("answers: ", answerLabels);
        answerLabels[idx][nameString] = event.target.checked ? event.target.checked : null;
        // If checked: show/hide follow up question
        if (event.target.checked) {
            console.log("CHECKED: ", event.target.checked);
            answerLabels[idx].showfollowup = true;
        } else {
            answerLabels[idx].showfollowup = false;
        }
        setQuestion({
            ...question,
            answers: answerLabels,
        });
        const cbLabels = answerLabels.filter((l) => l.user_answer !== null && l.user_answer === true);
        if (cbLabels.length > 0) {
            setDisable(false);
        } else {
            setDisable(true);
        }
    }

    function handleRadioChange(event) {
        const { target } = event;
        const { value, name } = target;
        // Index of the parent label question
        const idx = extractIndex(name);
        const nameString = extractNameString(name);
        const answerLabels = [...question.answers];
        console.log("INDEX: ", idx);
        console.log("RADIO: ", name);
        console.log("VALUE: ", event.target.checked);
        console.log("answers: ", answerLabels);
        answerLabels.forEach(function (a) {
            a.user_answer = false;
        });
        answerLabels[idx][nameString] = event.target.checked;
        // If checked: show/hide follow up question, hide all others...
        if (event.target.checked) {
            console.log("CHECKED: ", event.target.checked);
            answerLabels.forEach(function (a) {
                a.showfollowup = false;
            });
            answerLabels[idx].showfollowup = true;
        }
        setQuestion({
            ...question,
            answers: answerLabels,
        });
        const radioLabels = answerLabels.filter((l) => l.user_answer !== null && l.user_answer === true);
        if (radioLabels.length > 0) {
            setDisable(false);
        }
    }

    function handleLikertChange(event) {
        const { target } = event;
        const { value, name } = target;
        // Index of the parent label question
        const idx = extractIndex(name);
        const nameString = extractNameString(name);
        const answerLabels = [...question.answers];
        // console.log("INDEX: ", idx);
        // console.log("NAME: ", name);
        // console.log("VALUE: ", value);
        console.log("likertAnswers: ", answerLabels);
        answerLabels[idx][nameString] = value;
        setQuestion({
            ...question,
            answers: answerLabels,
        });
        const validLabels = answerLabels.filter((l) => l.user_answer == null || l.user_answer === "");
        if (validLabels.length < 1) {
            setDisable(false);
        }
    }

    function handleCommentsChange(event) {
        const { target } = event;
        const { value, name } = target;
        const idx = extractIndex(name);
        const nameString = extractNameString(name);

        const allQuestions = [...data.questions];
        allQuestions[idx][nameString] = value;
        setData({
            ...data,
            questions: allQuestions,
        });
    }

    function handleChange(event) {
        const { target } = event;
        const { value, name } = target;
        const idx = extractIndex(name);
        const nameString = extractNameString(name);

        const answerLabels = [...question.answers];
        console.log("Free Text Answers: ", answerLabels);
        answerLabels[idx][nameString] = value;
        setQuestion({
            ...question,
            answers: answerLabels,
        });
        const emptyLabels = answerLabels.filter((l) => l.user_answer == null || l.user_answer === "");
        console.log("EMPTY: ", emptyLabels);
        if (value.length > 0 && emptyLabels.length < 1) {
            setDisable(false);
        } else {
            setDisable(true);
        }
    }

    function handleTextChange(event) {
        const { target } = event;
        const { value, name } = target;
        console.log("NAME: ", name);
        setAnswer({
            ...answer,
            [name]: value,
        });
        if (value.length > 0) {
            setDisable(false);
        } else {
            setDisable(true);
        }
    }

    function handleFollowupQuestion(event) {
        const { target } = event;
        const { value, name } = target;
        const idx = extractIndex(name);
        const nameString = extractNameString(name);
        const answerLabels = [...question.answers];
        // console.log("VALUE: ", value);
        // console.log("Followup Answers: ", answerLabels);
        answerLabels[idx][nameString][0].answers[0].user_answer = value;
        setQuestion({
            ...question,
            answers: answerLabels,
        });
    }

    function validateQuestion(currentQ, nextQ) {
        setDisable(true);
        console.log("Selected Question: ", currentQ);
        if (currentQ.question_type_id === 1 && currentQ.answers.length < 1) {
            // Free Text question needs an empty answer label to store an answer...
            // Is this a design flaw?
            // TODO: Amit to add value attribute on question?
            currentQ.answers.push(answer);
            console.log("NEW ANSWER", currentQ.answers);
            // Reset Answer useState for continued use for other similar type questions
            setAnswer({
                ...answer,
                user_answer: "",
            });
        }
        const emptyLabels = nextQ.answers.filter((l) => l.user_answer == null || l.user_answer === "");
        const radioLabels = nextQ.answers.filter((l) => l.user_answer !== null && l.user_answer === true);
        switch (nextQ.question_type_id) {
            case 1:
                if (emptyLabels.length < 1 && nextQ.answers.length > 0) {
                    setDisable(false);
                    console.log("FREE TEXT ", radioLabels);
                }
                break;
            case 2:
                if (radioLabels.length > 0) {
                    setDisable(false);
                    console.log("RADIO/CHECKBOX ", radioLabels);
                }
                break;
            case 3:
                if (emptyLabels.length < 1) {
                    setDisable(false);
                    console.log("LIKERT ", emptyLabels);
                }
                break;
            default:
                setDisable(true);
        }
    }

    function nextQuestion(selected) {
        // If next is clicked ever...that means a previous should appear
        setPrevious(true);
        console.log("ORDER: ", selected.order);
        const lastQuestion = data.questions[data.questions.length - 1].order;
        console.log("LAST: ", lastQuestion);
        // Get the next question and set questionuseState
        const nextQ = data.questions.find((q) => q.order === selected.order + 1);
        console.log("SHOW NEXT QUESTION: ", nextQ);
        // If question exists
        if (nextQ) {
            // Display the question...
            setQuestion(nextQ);
            // If NOT the last question to show
            if (nextQ.order < lastQuestion) {
                setNext(true);
            } else {
                setNext(false);
            }
            // Validate Question before moving on to next question
            // validateQuestion(selected, nextQ);
        }
    }
    function previousQuestion(order) {
        setDisable(false);
        // If previous is clicked ever...that means next should appear
        setNext(true);
        console.log("ORDER: ", order);
        const firstQuestion = data.questions[0].order;
        console.log("LAST: ", firstQuestion);
        // Get the next question and set questionuseState
        const prevQ = data.questions.find((q) => q.order === order - 1);
        console.log("SHOW PREVIOUS QUESTION: ", prevQ);
        if (prevQ) {
            setQuestion(prevQ);
            // If NOT the first question
            if (prevQ.order > firstQuestion) {
                setPrevious(true);
            } else {
                setPrevious(false);
            }
        }
    }

    function editSurvey() {
        history.push(`/survey/edit/${data.id}`);
    }

    function publishSurvey() {
        if (
            window.confirm(
                "By clicking OK, you are confirming that you have reviewed this survey. Please note that you can NOT reverse this action."
            )
        ) {
            const publishData = {
                id: data.id,
                published: true,
                updated_by: auth.data.id,
            };
            axios
                .post("/api/survey/publish", publishData, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                })
                .then((result) => {
                    if (result.status === 200 && result.data.message === "Success") {
                        console.log("SAVE PUBLISH RESPONSE: ", result.data.data);
                        // history.push(`/survey/view/${result.data.data}?save=true`);
                        setSuccess(true);
                        window.location.reload();
                    }
                })
                .catch((e) => {
                    console.log(e);
                    alert("Something went wrong trying to publish your survey. Please try again.");
                });
        }
    }

    function handleProjects(array) {
        if (typeof array === "object") {
            console.log(array);
            setSelectedProject(array);
        }
    }

    const cloneSurvey = useCallback(async (survey_id, project_id) => {
        const postData = {
            survey_id,
            project_id,
        };
        console.log("POST OBJECT: ", postData);
        // TODO: REMOVE const response = await axios.get(`/api/survey/clone/${id}`);
        axios
            .post("/api/survey/clone", postData, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200 && result.data && result.data.data) {
                    console.log("CLONE RESPONSE: ", result.data.data);
                    if (window.localStorage.getItem("screener_drafts") !== null) {
                        // Clear localStorage Drafts on page load...
                        window.localStorage.removeItem("screener_drafts");
                    }
                    history.push(`/survey/edit/${result.data.data}`);
                }
            })
            .catch((e) => {
                console.log(e);
                alert("Something went wrong trying to clone your screener. Please contact tech.");
            });
    }, []);

    function cloneSurveyModal() {
        // Fetch Projects List for Autocomplete
        fetchProjectList();

        setCloneModal(true);
    }

    // TODO: Remove this function after launch...testing purposes only
    function submitSurvey() {
        console.log("SUBMIT: ", `${JSON.stringify(data)}`);
        axios
            .post("/api/survey/saveAnswers", data, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200 && result.data.message === "Success") {
                    console.log("SAVE RESPONSES: ", result.data.data);
                    // history.push(`/survey/view/${result.data.data}?save=true`);
                    setSuccess(true);
                }
            })
            .catch((e) => {
                console.log(e);
                alert("Something went wrong trying to publish your survey. Please try again.");
            });
    }

    useEffect(() => {
        fetchAllUsers();
        if (props.match.params.id) {
            fetchSurveyByID(props.match.params.id);
            setOpenLoader(false);
        }
        if (params.save) {
            setSuccess(true);
        }
    }, [props.match.params.id]);

    return (
        <div>
            <AnglesCreationTool
                screener_id={data.id}
                screener_title={data.title}
                user={auth.data}
                project_id={data.project_id}
                open={outreachTemplatesModal}
                closeEvent={handleClose}
                tags={project.tags ? project.tags : []}
                screeners={project.surveys ? project.surveys : []}
                templates={project.outreach_templates ? project.outreach_templates : []}
                angles={project.angles ? project.angles : []}
                successEvent={publishSurvey}
            />
            <Dialog
                open={questionModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="md"
            >
                <DialogTitle id="alert-dialog-title" css={dialogTitle}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        sx={{ float: "right" }}
                        aria-label="close"
                        size="lg"
                    >
                        <CloseIcon />
                    </IconButton>
                    {data.title}
                </DialogTitle>
                <DialogContent>
                    <div style={{ fontSize: "1.5rem", color: "#523197", textAlign: "center", marginBottom: "20px" }}>
                        <strong>Customize outreach templates for this screener</strong>
                    </div>
                    <div style={{ fontSize: "1rem", color: "#444", textAlign: "center", marginBottom: "25px" }}>
                        <p>
                            <strong>HIGHLY RECOMMEND:</strong> Please create custom targeted email copy for the
                            automated outreach emails for this specific screener. If you publish without customizing
                            outreach, your automated outreach emails will use the generic default copy instead.
                            Disregard if this is a follow-up screener, edited or not intended for automated outreach.
                        </p>
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <Button
                            onClick={handleOpenOutreachTemplatesModal}
                            size="large"
                            variant="contained"
                            color="primary"
                            style={{ marginRight: "20px", backgroundColor: "#4CAB7D" }}
                        >
                            Yes, Continue...
                        </Button>
                        <Button onClick={publishSurvey} size="large" variant="contained" color="primary">
                            No, Publish This Screener Now
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog
                fullScreen
                TransitionComponent={Transition}
                open={clone}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" size="large">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Typography className={classes.modalHeading}>
                        Select a project to clone screener to: ({selectedProject.label})
                    </Typography>
                    <Select name="projects" onChange={handleProjects} options={all} value={selectedProject} />
                    <br />
                    <Button
                        onClick={() => cloneSurvey(parseInt(props.match.params.id, 10), selectedProject.value)}
                        color="inherit"
                    >
                        Clone Screener Now
                    </Button>
                </DialogContent>
            </Dialog>
            <PageTitle title={data.title} />
            <Backdrop
                // transitionDuration={500}
                className={classes.backdrop}
                open={openLoader}
                onClick={() => {
                    setOpenLoader(false);
                }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar open={success} autoHideDuration={5000} onClose={handleCloseMessage}>
                <Typography className={classes.alertSuccess}>
                    <CheckCircle /> Saved Successfully!
                </Typography>
            </Snackbar>
            <Grid container spacing={2}>
                <Grid item sm={12} xs={12}>
                    {!data.published && (
                        <div className={classes.editBtn}>
                            <Button
                                onClick={editSurvey}
                                className={classes.edit}
                                size="large"
                                color="secondary"
                                variant="contained"
                            >
                                Edit Survey
                            </Button>
                            <Button
                                onClick={cloneSurveyModal}
                                className={classes.edit}
                                size="large"
                                variant="contained"
                            >
                                Clone Survey
                            </Button>
                            <Button
                                onClick={handleOpenQuestionModal}
                                className={classes.submit}
                                size="large"
                                color="primary"
                                variant="contained"
                                disabled={publishButtonDisabled}
                            >
                                Publish Survey
                            </Button>
                        </div>
                    )}
                    {data.published && (
                        <div className={classes.editBtn}>
                            <div className={classes.publishBanner}>
                                <Typography className={classes.editBtn}>
                                    This survey has been published by{" "}
                                    {users.find((u) => u.id === data.updated_by)
                                        ? users.find((u) => u.id === data.updated_by).fname
                                        : `Administrator #${data.updated_by}`}
                                </Typography>
                            </div>
                            <Button
                                onClick={cloneSurveyModal}
                                className={classes.edit}
                                size="large"
                                variant="contained"
                            >
                                Clone Survey
                            </Button>
                        </div>
                    )}
                </Grid>
                <Grid item sm={12} xs={12}>
                    <div className={classes.projectBox}>
                        <Typography className={classes.projectMeta}>
                            {project.type} | {project.case_code} | <strong>{project.status}</strong>
                        </Typography>
                        <h1>
                            <Link to={`/project/view/${project.id}`} className={classes.link}>
                                {project.title}
                            </Link>
                        </h1>
                        <Typography className={classes.surveyType}>
                            CLIENT: <strong>{project.client_name}</strong> | PRIMARY MANAGER:{" "}
                            <strong>{project.primary_project_manager}</strong>
                        </Typography>
                    </div>
                </Grid>
                <Grid item sm={12} xs={12}>
                    <em>{SurveyTypes.find((s) => s.value === parseInt(data.type, 10)).label}</em>
                    <h3 className={classes.heading}>
                        SURVEY TITLE: <strong>{data.title}</strong>
                    </h3>
                    <h4 className={classes.subheading}>
                        {data.questions.length} total questions | {basicDateTimeStamp(data.created_on)} - by{" "}
                        {users.find((u) => u.id === data.created_by)
                            ? users.find((u) => u.id === data.created_by).fname
                            : `Administrator #${data.created_by}`}{" "}
                        {data.updated_on && (
                            <em>
                                | Last updated: {basicDateTimeStamp(data.updated_on)} - by{" "}
                                {users.find((u) => u.id === data.updated_by)
                                    ? users.find((u) => u.id === data.updated_by).fname
                                    : `Administrator #${data.updated_by}`}
                            </em>
                        )}
                    </h4>
                </Grid>
                <Grid item sm={12} xs={12}>
                    <div className={classes.actions}>
                        {previous && (
                            <Button onClick={() => previousQuestion(question.order)} className={classes.prev}>
                                Previous
                            </Button>
                        )}
                        {next && data.questions.length > 1 ? (
                            <Button
                                disabled={disable}
                                onClick={() => nextQuestion(question)}
                                size="large"
                                variant="contained"
                                color="primary"
                            >
                                Next
                            </Button>
                        ) : (
                            <Button
                                disabled={disable}
                                onClick={submitSurvey}
                                className={classes.submit}
                                size="large"
                                color="primary"
                                variant="contained"
                            >
                                Finish
                            </Button>
                        )}
                    </div>
                    <Paper className={classes.paper}>
                        <p>
                            Question #{question.order} of {data.questions.length}
                        </p>
                        <div
                            className={classes.question}
                            dangerouslySetInnerHTML={{
                                __html: question.text,
                            }}
                        />
                        {parseInt(question.question_type_id, 10) === 1 &&
                            question.answers.length > 0 &&
                            question.answers.map((l, i) => {
                                const k = `answer_${i}`;
                                return (
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={4}
                                        label={`${l.label}`}
                                        name={`user_answer[${i}]`}
                                        key={k}
                                        value={l.user_answer ? `${l.user_answer}` : ""}
                                        onChange={handleChange}
                                    />
                                );
                            })}
                        {parseInt(question.question_type_id, 10) === 1 && question.answers.length < 1 && (
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                name="user_answer"
                                value={answer.user_answer}
                                onChange={handleTextChange}
                            />
                        )}
                        {parseInt(question.question_type_id, 10) === 2 &&
                            question.answers.length > 0 &&
                            question.answers.map((q, i) => {
                                const k = `answer_${i}`;
                                return (
                                    <div key={k}>
                                        <FormControl component="fieldset" className={classes.formControl}>
                                            <RadioGroup>
                                                <FormControlLabel
                                                    control={question.multiple ? <Checkbox /> : <Radio />}
                                                    label={`${q.label}`}
                                                    name={`user_answer[${i}]`}
                                                    onChange={
                                                        question.multiple ? handleCheckboxChange : handleRadioChange
                                                    }
                                                    value={q.user_answer ? q.user_answer : ""}
                                                    checked={q.user_answer}
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                        {q.followupquestion !== null &&
                                            q.showfollowup === true &&
                                            q.followupquestion.length > 0 &&
                                            q.followupquestion[0].text !== "" && (
                                                <TextField
                                                    fullWidth
                                                    name={`followupquestion[${i}]`}
                                                    label={`${q.followupquestion[0].text}`}
                                                    value={q.followupquestion[0].answers[0].user_answer}
                                                    onChange={handleFollowupQuestion}
                                                />
                                            )}
                                    </div>
                                );
                            })}
                        {parseInt(question.question_type_id, 10) === 3 &&
                            question.answers.length > 0 &&
                            question.answers.map((l, i) => {
                                const k = `answer_${i}`;
                                return (
                                    <div key={k}>
                                        <Typography className={classes.label}>{l.label}</Typography>
                                        <FormControl component="fieldset" className={classes.formControl}>
                                            <RadioGroup className={classes.likert}>
                                                {question.scale &&
                                                question.scale.length > 1 &&
                                                question.scale.indexOf(",") !== -1
                                                    ? question.scale.split(",").map((s, j) => {
                                                          const key = `key_${j}`;
                                                          return (
                                                              <FormControlLabel
                                                                  key={key}
                                                                  value={s}
                                                                  control={<Radio />}
                                                                  label={`${s}`}
                                                              />
                                                          );
                                                      })
                                                    : scale.map((s, j) => {
                                                          const key = `key_${j}`;
                                                          return (
                                                              <FormControlLabel
                                                                  key={key}
                                                                  name={`user_answer[${i}]`}
                                                                  onChange={handleLikertChange}
                                                                  value={s}
                                                                  control={<Radio />}
                                                                  label={`${s}`}
                                                                  checked={s === parseInt(l.user_answer, 10) && true}
                                                              />
                                                          );
                                                      })}
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                );
                            })}
                        <div className={classes.comments}>
                            <Typography className={classes.label}>Comments:</Typography>
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                name={`comments[${question.order - 1}]`}
                                value={question.comments}
                                onChange={handleCommentsChange}
                            />
                        </div>
                    </Paper>
                    <div className={classes.actions}>
                        {previous && (
                            <Button onClick={() => previousQuestion(question.order)} className={classes.prev}>
                                Previous
                            </Button>
                        )}
                        {next && data.questions.length > 1 ? (
                            <Button
                                disabled={disable}
                                onClick={() => nextQuestion(question)}
                                size="large"
                                variant="contained"
                                color="primary"
                            >
                                Next
                            </Button>
                        ) : (
                            <Button
                                disabled={disable}
                                onClick={submitSurvey}
                                className={classes.submit}
                                size="large"
                                color="primary"
                                variant="contained"
                            >
                                Finish
                            </Button>
                        )}
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

SurveyShow.propTypes = {
    match: PropTypes.object,
};
SurveyShow.defaultProps = {
    match: null,
};

export default SurveyShow;
