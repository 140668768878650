import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Checkbox, FormControlLabel } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import { useTheme } from "@mui/material";

export default function SetClientResponseStatus(props) {
    const theme = useTheme();

    const { expert, user, project_id, openDialog, closeEvent, reloadEvent } = props;
    const [saveSuccess, setSaveStatusDisable] = useState(false);
    const [sent_to_client, setSentToClient] = useState({
        status: false,
        is_dirty: false,
    });
    const [selected_by_client, setSelectedByClient] = useState({
        status: false,
        is_dirty: false,
    });
    const [rejected_by_client, setRejectedByClient] = useState({
        status: false,
        is_dirty: false,
    });

    // Helpers
    function submitStatus(event) {
        event.preventDefault();
        const url = `/api/project/saveExpertStatus/${project_id}`;
        const status = expert.statuses;
        const formData = {
            id: expert.id,
            created_by: user.data.id,
            call_duration: 0,
            is_dirty: ["clientResponse"],
            clientResponse: [
                {
                    id: 6,
                    sent_to_client: sent_to_client.status,
                    is_dirty: sent_to_client.is_dirty,
                },
                {
                    id: 7,
                    selected_by_client: selected_by_client.status,
                    is_dirty: selected_by_client.is_dirty,
                },
                {
                    id: 8,
                    rejected_by_client: rejected_by_client.status,
                    is_dirty: rejected_by_client.is_dirty,
                },
            ],
        };
        console.log("STATUSES: ", status);
        console.log("Submit DATA: ", JSON.stringify(formData));
        axios
            .post(url, formData, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    const response = result.data.data;
                    console.log("RESULT: ", response);
                    reloadEvent(project_id);
                    closeEvent();
                } else {
                    console.log(`Good POST, but something went wrong: ${JSON.stringify(formData)}`);
                }
            })
            .catch((result) => {
                console.log(result);
                console.log("API not done yet, 404 Error:", result);
            });
    }

    const handleSentToClient = (event) => {
        const { target } = event;
        const { value } = target;

        // Let DB know which checkbox was touched and which array was modified
        setSentToClient({
            ...sent_to_client,
            status: value ? true : false,
            is_dirty: value ? true : false,
        });
        setSaveStatusDisable(false);
    };
    const handleSelectedByClient = (event) => {
        const { target } = event;
        const { name, value } = target;
        console.log("RADIO NAME: ", name);
        setSelectedByClient({
            ...selected_by_client,
            status: value ? true : false,
            is_dirty: value ? true : false,
        });
        setSaveStatusDisable(false);
    };
    const handleRejectedByClient = (event) => {
        const { target } = event;
        const { name, value } = target;
        console.log("RADIO NAME: ", name);
        setRejectedByClient({
            ...rejected_by_client,
            status: value ? true : false,
            is_dirty: value ? true : false,
        });
        // Any click of a checkbox enables save status button
        setSaveStatusDisable(false);
    };

    const handleDialogClose = () => {
        // Reset all checkboxes to false
        setSentToClient({
            status: false,
            is_dirty: false,
        });
        setSelectedByClient({
            status: false,
            is_dirty: false,
        });
        setRejectedByClient({
            status: false,
            is_dirty: false,
        });
        closeEvent();
    };

    return (
        <Dialog
            onClose={handleDialogClose}
            aria-labelledby="customized-dialog-title"
            fullWidth
            maxWidth="md"
            open={openDialog}
        >
            <DialogTitle id="customized-dialog-title" onClose={handleDialogClose}>
                {expert.name}
                <IconButton
                    aria-label="close"
                    sx={{
                        position: "absolute",
                        right: theme.spacing(1),
                        top: theme.spacing(1),
                        color: theme.palette.grey[500],
                    }}
                    onClick={handleDialogClose}
                    size="large"
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <div>
                    <p>
                        Manually set a status if the client responded outside the CRM workflows. (ie. Over the Phone or
                        Outlook Email)
                    </p>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id={`${6}`}
                                inputProps={{ "data-status-type": "clientResponse" }}
                                // checked={sent_to_client.status}
                                name="sent_to_client"
                                onChange={handleSentToClient}
                                value={sent_to_client.status}
                            />
                        }
                        label="Sent To Client"
                    />
                </div>
                <div>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id={`${7}`}
                                // checked={selected_by_client.status}
                                name="by_client"
                                onChange={handleSelectedByClient}
                                value={selected_by_client.status}
                            />
                        }
                        label="Selected By Client"
                    />
                </div>
                <div>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id={`${8}`}
                                // checked={rejected_by_client.status}
                                name="by_client"
                                onChange={handleRejectedByClient}
                                value={rejected_by_client.status}
                            />
                        }
                        label="Rejected By Client"
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={submitStatus} color="primary" disabled={saveSuccess}>
                    Save changes
                </Button>
            </DialogActions>
        </Dialog>
    );
}
