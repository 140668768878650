/* eslint-disable indent */
import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Winner from "@mui/icons-material/TrendingUp";
import TeamIcon from "@mui/icons-material/Poll";
import Manager from "@mui/icons-material/StarRate";
import moment from "moment";
import "moment-duration-format";
import PageTitle from "../components/PageTitle";
import loader from "../img/loader-simple.gif";
import UserMetricsNext from "../components/UserMetricsNext";
import OverallMetrics from "../components/OverallMetrics";
import ScreenedExperts from "../components/ScreenedExperts";
import WarmLeads from "../components/WarmLeads";
import AssociateCallLog from "../components/AssociateCallLog";
import { authContext } from "../context/auth";
import { calculatePercentage, calculateAverage, numberWithCommas, remainingNumber } from "../Utils";
import CompletedScreenerCount from "../components/CompletedScreenerCount";
import useClasses from "../custom-hooks/useClasses";

const styles = (theme) => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(2),
        overflowX: "auto",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    center: {
        display: "block",
        textAlign: "center",
    },
    h1: {
        color: "#000",
        fontSize: "2rem",
        textTransform: "uppercase",
        textAlign: "center",
        paddingBottom: "8px",
        borderBottom: "3px solid #f2f2f2",
    },
    h3: {
        color: "#523197",
        fontSize: ".5rem",
        textTransform: "uppercase",
    },
    metricPercentage: {
        fontSize: "2.5rem",
        fontWeight: "bold",
        color: "#523197",
        marginRight: "10px",
        lineHeight: "100%",
        padding: "0",
    },
    metricNumberAvg: {
        fontSize: "2rem",
        fontWeight: "bold",
        color: "#325C55",
        marginRight: "10px",
        lineHeight: "100%",
        padding: "0",
        marginBottom: "10px",
    },
    metricNumberWarning: {
        float: "left",
        fontSize: "4.4rem",
        fontWeight: "bold",
        color: theme.palette.secondary.main,
        marginRight: "10px",
        lineHeight: "100%",
        padding: "0",
    },
    metricNumberSmall: {
        fontSize: "1.5rem",
        fontWeight: "bold",
        color: "#385469",
        lineHeight: "100%",
        marginBottom: "20px",
        padding: "0",
    },
    metadataSmall: {
        fontSize: "2rem",
        fontWeight: "bold",
        color: "#CCCCCC",
        lineHeight: "100%",
        marginBottom: "20px",
        padding: "0",
    },
    remainingCalls: {
        color: theme.palette.secondary.main,
    },
    expert: {
        padding: "0",
        margin: "0",
    },
    users: {
        borderRight: "2px solid #f2f2f2",
    },
    selectedLink: {
        fontWeight: "bold",
        color: theme.palette.secondary.main,
    },
    alert: {
        color: "#CC0000",
        fontWeight: "bold",
    },
    iconManager: {
        color: "#CCC",
    },
    button: {
        margin: theme.spacing(1),
    },
    buttonLink: {
        color: "#3c8864",
        textDecoration: "none",
        fontSize: "1rem",
        padding: "0",
        margin: "0",
    },
    battingAvg: {
        background: "linear-gradient(45deg, #ffffff, #fafafa, #f5f5f5, #f2f2f2)",
        borderTop: "2px solid #f2f2f2",
        borderBottom: "2px solid #f2f2f2",
        paddingTop: "15px",
        paddingBottom: "10px",
        marginBottom: "15px",
        textAlign: "center",
    },
    refreshBtn: {
        border: "1px solid #CCC",
        color: "#3c8864",
        marginBottom: "20px",
    },
    stats: {
        clear: "both",
        overflow: "hidden",
        marginBottom: "20px",
    },
    statBox: {
        width: "18%",
        float: "left",
        marginRight: "10px",
        textAlign: "center",
        borderRight: "3px solid #eaeaea",
    },
    betaTable: {
        width: "94%",
        color: "#444",
        fontSize: ".85rem",
        marginBottom: "20px",
        border: "1px solid #eaeaea",
        borderRadius: "3px",
        padding: "15px",
        backgroundColor: "#f4e1cc",
        clear: "both",
    },
    tableFullWidth: {
        width: "94%",
        color: "#444",
        fontSize: ".85rem",
        marginBottom: "20px",
        border: "1px solid #eaeaea",
        borderRadius: "3px",
        padding: "15px",
        backgroundColor: "#fafafa",
        clear: "both",
    },
    table: {
        width: "46%",
        float: "left",
        color: "#444",
        fontSize: ".85rem",
        marginBottom: "20px",
        border: "1px solid #eaeaea",
        borderRadius: "3px",
        padding: "15px",
        backgroundColor: "#fafafa",
        marginRight: "20px",
    },
    tableStyles: {
        width: "100%",
    },
    th: {
        borderBottom: "2px solid #CCC",
        paddingTop: "15px",
        textTransform: "uppercase",
        fontSize: ".5rem",
        color: "#999",
    },
    thInteger: {
        borderBottom: "2px solid #CCC",
        paddingTop: "15px",
        textAlign: "right",
        textTransform: "uppercase",
        fontSize: ".5rem",
        color: "#999",
    },
    row: {
        borderBottom: "1px solid #eaeaea",
        padding: "5px",
        backgroundColor: "#fff",
    },
    winnerRow: {
        borderBottom: "1px solid #eaeaea",
        padding: "5px",
        backgroundColor: "#e1d7ec",
        fontWeight: "bold",
    },
    tdString: {
        textAlign: "left",
        padding: "8px",
    },
    tdInteger: {
        textAlign: "right",
        padding: "8px",
        fontWeight: "bold",
    },
    edibleCalls: {
        fontSize: "4rem",
        fontWeight: "bold",
        color: theme.palette.secondary.main,
        marginRight: "10px",
        marginBottom: "10px",
        lineHeight: "100%",
        padding: "0",
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
});

let loggedInUser;

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function TeamMetrics(props) {
    // CONSTANTS
    const DEFAULT_COMPONENT_STATES = {
        screeners: false,
        leads: false,
        callLogs: false,
    };

    const { history } = props;
    const [load, setLoad] = useState(DEFAULT_COMPONENT_STATES);
    const classes = useClasses(styles);
    const start = new Date();
    const today = moment(start.date);
    const [rmList, setRmList] = useState([]);
    const [rmListDailyLeads, setRmListDailyLeads] = useState([]);
    const [rmListMonthlyLeads, setRmListMonthlyLeads] = useState([]);
    const [rmListConvertedLeads, setRmListConvertedLeads] = useState([]);
    const [open, setOpen] = useState(false);
    const [daily, setDaily] = useState("block");
    const [metrics, setMetrics] = useState("none");
    const { auth } = useContext(authContext);
    const [tabValue, setTabValue] = useState(0);
    const [data, setData] = useState({
        id: "",
        fname: "",
        lname: "",
        title: "",
        leadsConverted: "",
        leadsAddedToday: "",
        leadsAdded: "",
        callsScheduled: [],
        scheduledVsPlanned: [],
        createdVsSentToClient: [],
        scheduledCallCredits: "",
        scheduledCallCreditUserInfo: [],
        convertedLeadsInfo: [],
    });
    const [aggregate, setAggregate] = useState({
        dailyTotal: 0,
        monthlyTotal: 0,
    });
    const [scheduled, setScheduled] = useState({
        dailyExternalScheduled: 0,
        dailyInternalScheduled: 0,
        monthlyExternal: 0,
        monthlyInternal: 0,
        nextMonthExternal: 0,
    });
    const [calllog, setCallLog] = useState([]);
    const [leads, setWarmLeads] = useState([]);
    const [screened, setScreenedExperts] = useState([]);
    const [newleads, setNewWarmLeadsCount] = useState(0);
    const [newscreened, setNewScreenedExpertsCount] = useState(0);
    const [selectedUser, setSelectedUser] = useState(0);

    const [loading, setLoadingGif] = useState({
        network: `<div style="text-align:left;"><img src=${loader} border="0" width="30" /></div>`,
    });
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("month");
    const [teamStats, setTeamStats] = useState({
        totalOpportunities: 0,
        totalCompleted: 0,
        totalOpportunitiesNoHold: 0,
        totalCompletedNoHold: 0,
    });

    if (auth) {
        loggedInUser = auth.data;
    }

    function handleTabChange(event, newValue) {
        setTabValue(newValue);
    }

    function handleTabLoading(componentName) {
        setLoad({ ...load, [componentName]: true });
    }

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === "desc"
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const headCellsConverted = [
        { id: "name", numeric: false, disablePadding: true, label: "TEAM" },
        {
            id: "leads",
            numeric: true,
            disablePadding: false,
            label: "LEADS",
        },
        {
            id: "conversion",
            numeric: true,
            disablePadding: false,
            label: "CONVERSION",
        },
        {
            id: "month",
            numeric: true,
            disablePadding: false,
            label: "THIS MONTH",
        },
        {
            id: "scheduledCredits",
            numeric: true,
            disablePadding: false,
            label: "SCHEDULED",
        },
    ];

    function EnhancedTableHead(props) {
        const { classes, order, orderBy, onRequestSort } = props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };
        return (
            <TableHead>
                <TableRow>
                    {headCellsConverted.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? "right" : "left"}
                            padding={headCell.disablePadding ? "none" : "normal"}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        classes: PropTypes.object.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        order: PropTypes.oneOf(["asc", "desc"]).isRequired,
        orderBy: PropTypes.string.isRequired,
        // rowCount: PropTypes.number.isRequired,
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    function createNewConvertedTableObject(arr) {
        const newArr = [];
        for (let i = 0; i < arr.length; i++) {
            const rate = calculatePercentage(arr[i].leadsConverted, arr[i].leadsAdded);
            const row = {
                name: `${arr[i].fname} ${arr[i].lname}`,
                leads: parseInt(arr[i].leadsAdded, 10),
                conversion: parseFloat(rate, 10),
                month: parseInt(arr[i].leadsConverted, 10),
                scheduledCredits: parseInt(arr[i].scheduledCredits),
            };
            newArr.push(row);
        }
        return newArr;
    }

    async function fetchScreenedExperts(uid, screenedcount) {
        axios
            .post("/api/rm/getScreenedExperts", {
                userId: uid,
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                },
            })
            .then((result) => {
                if (result.status === 200 && result.data && result.data.data) {
                    // console.log("SCREENED EXPERTS: ", result.data.data);
                    setScreenedExperts(result.data.data.data);
                    const recent = parseInt(result.data.data.recent, 10);
                    setNewScreenedExpertsCount(recent);
                    // console.log("SCREENED LEADS RESPONSE INCOMING: ", recent);
                    // console.log("SCREENED LEADS RESPONSE EXISTING: ", screenedcount);
                    setOpen(false);
                    setTimeout(() => {
                        // Poll every 12 minutes
                        fetchScreenedExperts(uid, recent);
                    }, 720000);
                }
            });
    }

    async function fetchWarmLeads(uid, warmcount) {
        axios
            .post("/api/rm/getWarmLeads", {
                userId: uid,
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                },
            })
            .then((result) => {
                if (result.status === 200 && result.data && result.data.data) {
                    // console.log("WARM LEADS: ", result.data.data);
                    setWarmLeads(result.data.data.data);
                    const recent = parseInt(result.data.data.recent, 10);
                    setNewWarmLeadsCount(recent);
                    // console.log("WARM LEADS INCOMING: ", recent);
                    // console.log("WARM LEADS EXISTING: ", warmcount);
                    setOpen(false);
                    setTimeout(() => {
                        // Poll every 12 minutes
                        fetchWarmLeads(uid, recent);
                    }, 720000);
                }
            });
    }

    async function fetchCallLogs(id) {
        axios
            .post("/api/rm/getCallLogs", {
                userId: id,
                // userId: 18152,
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                },
            })
            .then((result) => {
                // console.log("GET ASSOCIATE CALL LOG RESULT: ", result);
                if (result.status === 200 && result.data && result.data.data) {
                    const log = result.data.data.data;
                    setCallLog(log);
                    setOpen(false);
                }
            });
    }

    async function fetchTeamStats() {
        await axios
            .get("/api/rm/findTeamStats")
            .then((res) => {
                const result = res?.data?.data;
                // console.log(result, " :: result in findTeamStats");
                if (result) {
                    setTeamStats(result);
                }
            })
            .catch((err) => {
                console.log(err, " :: err in findTeamStats");
            });
    }

    async function fetchRmList() {
        axios.get("/api/rm/list").then((result) => {
            if (result.status === 200) {
                // console.log(result?.data?.data, " :: result?.data?.data");
                const results = result?.data?.data;

                setRmList(results);

                let dailyTotal = 0;
                let monthlyTotal = 0;

                for (const rm of results) {
                    dailyTotal += parseInt(rm.leadsAddedToday, 10);
                    monthlyTotal += parseInt(rm.leadsAdded, 10);
                }

                const sortedDailyLeads = [...results].sort(
                    (a, b) => parseInt(b.leadsAddedToday, 10) - parseInt(a.leadsAddedToday, 10)
                );

                const sortedMonthlyLeads = [...results].sort(
                    (a, b) => parseInt(b.leadsAdded, 10) - parseInt(a.leadsAdded, 10)
                );

                const sortedConvertedLeads = [...results].sort(
                    (a, b) => parseInt(b.leadsConverted, 10) - parseInt(a.leadsConverted, 10)
                );

                setRmListDailyLeads(sortedDailyLeads);
                setRmListMonthlyLeads(sortedMonthlyLeads);
                setRmListConvertedLeads(createNewConvertedTableObject(sortedConvertedLeads));

                setAggregate({
                    ...aggregate,
                    dailyTotal,
                    monthlyTotal,
                });
            }
        });
    }

    async function fetchAllExperts() {
        await axios.get("/api/expert/counts").then((response) => {
            if (response.status === 200 && response.data.data) {
                console.log("FETCH ALL EXPERTS", response.data.data);
                setOpen(false);
                setLoadingGif({
                    ...loading,
                    network: `${numberWithCommas(response.data.data.totalExperts)} / ${numberWithCommas(
                        response.data.data.totalExpertsSignedTermsCompletedDisclosures
                    )}`,
                });
                setScheduled({
                    dailyExternalScheduled: response.data.data.dailyExternalScheduled,
                    dailyInternalScheduled: response.data.data.dailyInternalScheduled,
                    monthlyExternal: response.data.data.monthlyExternalScheduled,
                    monthlyInternal: response.data.data.monthlyInternalScheduled,
                    nextMonthExternal: response.data.data.nextMonthExternalScheduled,
                    monthlyLeadsAdded: response.data.data.monthlyLeadsAdded,
                    convertedLeads: response.data.data.convertedLeads,
                    completedScreenersToday: response.data.data.completedScreenersToday,
                    completedScreenersYesterday: response.data.data.completedScreenersYesterday,
                });
            }
        });
    }

    function resetUserMetrics() {
        setData({
            ...data,
            id: "",
            fname: "",
            lname: "",
            title: "",
            leadsConverted: "",
            leadsAddedToday: "",
            leadsAdded: "",
            callsScheduled: [],
            scheduledVsPlanned: [],
            createdVsSentToClient: [],
            scheduledCallCredits: "",
            scheduledCallCreditUserInfo: [],
            convertedLeadsInfo: [],
        });
    }

    async function renderUsersStats(user) {
        // console.log(user, " :: user");
        setSelectedUser(user);
        resetUserMetrics();
        // setOpen(true);
        fetchCallLogs(user.id);
        fetchScreenedExperts(user.id, newscreened);
        fetchWarmLeads(user.id, newleads);
        setMetrics("block");
        setDaily("none");
        await axios.get(`/api/rm/getmonthlystats?id=${user.id}`).then((response) => {
            if (response.status === 200 && response.data.data) {
                const user = response.data.data;
                setData({
                    ...data,
                    id: user.id,
                    fname: user.fname,
                    lname: user.lname,
                    title: user.title,
                    leadsAdded: user.leadsAdded.length > 0 ? parseInt(user.leadsAdded[0].count, 10) : 0,
                    leadsAddedToday: user.leadsAddedToday,
                    leadsConverted: user.leadsConverted,
                    callsScheduled: user.callsScheduled,
                    scheduledVsPlanned: user.scheduledVsPlanned,
                    createdVsSentToClient: user.createdVsSentToClient,
                    scheduledCallCredits: user.scheduledCallCredits,
                    scheduledCallCreditUserInfo: user.scheduledCallCreditUserInfo,
                    convertedLeadsInfo: user.convertedLeadsInfo,
                });
                setOpen(false);
            }
        });
    }

    function longPoll() {
        fetchRmList();
        fetchTeamStats();
        fetchAllExperts();
        // console.log("LONG POLL SUCCESSFUL!");
        setTimeout(() => {
            // Poll every 6 minutes
            longPoll();
        }, 360000);
    }

    function reloadUsers() {
        // optimizedList();
        setDaily("block");
        setMetrics("none");
        fetchRmList();
        fetchTeamStats();
        fetchAllExperts();
    }

    useEffect(() => {
        longPoll();
        // fetchAllUsers();
        // fetchAllExperts();
    }, []);

    return (
        <div>
            <PageTitle title="User Stats" />
            <Backdrop
                className={classes.backdrop}
                open={open}
                onClick={() => {
                    setOpen(false);
                }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <h1 className={classes.h1}>{today.format("MMMM")} Metrics</h1>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <div className={classes.users}>
                        <h3 className={classes.h3}>Network Total / Onboarded:</h3>
                        <div
                            dangerouslySetInnerHTML={{ __html: loading.network }}
                            className={classes.metricNumberSmall}
                        />
                        <h3 className={classes.h3}>
                            <strong>*** ALL ACTIVE PROJECTS ***</strong>
                        </h3>
                        <h3 className={classes.h3}>Opportunity / Complete / Remaining</h3>
                        <div className={classes.metricNumberSmall}>
                            {teamStats?.totalOpportunities} / {teamStats?.totalCompleted} /{" "}
                            {remainingNumber(teamStats?.totalOpportunities, teamStats?.totalCompleted)}
                        </div>
                        <h3 className={classes.h3}>Open Available Calls</h3>
                        <div className={classes.edibleCalls}>
                            {remainingNumber(teamStats?.totalOpportunitiesNoHold, teamStats?.totalCompletedNoHold)}
                        </div>
                        <h3 className={classes.h3}>Completed Screeners Yesterday / Today</h3>
                        <div className={classes.metricNumberSmall}>
                            {scheduled?.completedScreenersYesterday} / {scheduled?.completedScreenersToday}
                        </div>
                        <div className={classes.battingAvg}>
                            <h3 className={classes.h3}>Open Project Batting Avg.</h3>
                            <strong className={classes.metricNumberAvg}>
                                {calculateAverage(teamStats?.totalOpportunities, teamStats?.totalCompleted)}
                            </strong>
                        </div>
                        <div>
                            <Button onClick={reloadUsers} className={classes.link}>
                                Team Activity <TeamIcon className={classes.iconManager} />
                            </Button>
                        </div>
                        {rmList.map(function (u, idx) {
                            return (
                                <div key={u.id}>
                                    <Button
                                        onClick={() => renderUsersStats(u)}
                                        className={u.id === selectedUser.id ? classes.selectedLink : classes.link}
                                    >
                                        {u.fname} {u.lname}{" "}
                                        {(u.isRmOrRa === "RM" || u.isRmOrRa === "HYBRID") && (
                                            <Manager className={classes.iconManager} />
                                        )}
                                    </Button>
                                </div>
                            );
                        })}
                    </div>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <Box display={metrics}>
                        <Tabs
                            value={tabValue}
                            onChange={handleTabChange}
                            indicatorColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            <Tab label="Project Metrics" value={0} />
                            <Tab onClick={() => handleTabLoading("screeners")} label="Screened Experts" value={1} />
                            <Tab onClick={() => handleTabLoading("leads")} label="Interested Leads" value={2} />
                            <Tab onClick={() => handleTabLoading("callLogs")} label="Call Log" value={3} />
                        </Tabs>
                        <TabPanel value={tabValue} index={0}>
                            {data.id ? (
                                <>
                                    <UserMetricsNext auth={auth} currentUser={selectedUser} />
                                </>
                            ) : (
                                <div className={classes.center}>
                                    <CircularProgress />
                                </div>
                            )}
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <>
                                {load.screeners && <CompletedScreenerCount userId={auth.data.id} />}
                                {screened.length > 0 && load.screeners && (
                                    <ScreenedExperts data={screened} user={auth.data} history={history} />
                                )}
                            </>
                        </TabPanel>
                        <TabPanel value={tabValue} index={2}>
                            {leads.length > 0 && load.leads && (
                                <WarmLeads data={leads} user={auth.data} history={history} />
                            )}
                        </TabPanel>
                        <TabPanel value={tabValue} index={3}>
                            {calllog.length > 0 && load.callLogs && (
                                <AssociateCallLog data={calllog} user={auth.data} />
                            )}
                        </TabPanel>
                    </Box>
                    <Box display={daily}>
                        <OverallMetrics monthly={scheduled} user={auth.data} />
                        <div className={classes.table}>
                            <Typography className={classes.meta}>
                                <strong>Today's Lead Activity</strong>
                            </Typography>
                            <table className={classes.tableStyles}>
                                <thead>
                                    <tr>
                                        <th className={classes.th}>Team</th>
                                        <th className={classes.thInteger}>Today</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {rmListDailyLeads.map((rm, index) => {
                                        return (
                                            <tr key={rm.id} className={index === 0 ? classes.winnerRow : classes.row}>
                                                <td className={classes.tdString}>
                                                    {rm.fname} {rm.lname} {index === 0 && <Winner />}
                                                </td>
                                                <td className={classes.tdInteger}>
                                                    {rm.leadsAddedToday ? rm.leadsAddedToday : 0}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    <tr className={classes.row}>
                                        <td className={classes.tdString}>
                                            <strong>TOTAL:</strong>
                                        </td>
                                        <td className={classes.tdInteger}>{aggregate.dailyTotal}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className={classes.table}>
                            <Typography className={classes.meta}>
                                <strong>Monthly Lead Activity</strong>
                            </Typography>
                            <table className={classes.tableStyles}>
                                <thead>
                                    <tr>
                                        <th className={classes.th}>Team</th>
                                        <th className={classes.thInteger}>This Month</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {rmListMonthlyLeads.map((rm, index) => {
                                        return (
                                            <tr key={rm.id} className={index === 0 ? classes.winnerRow : classes.row}>
                                                <td className={classes.tdString}>
                                                    {rm.fname} {rm.lname} {index === 0 && <Winner />}
                                                </td>
                                                <td className={classes.tdInteger}>
                                                    {rm.leadsAdded ? rm.leadsAdded : 0}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    <tr className={classes.row}>
                                        <td className={classes.tdString}>
                                            <strong>TOTAL:</strong>
                                        </td>
                                        <td className={classes.tdInteger}>{aggregate.monthlyTotal}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className={classes.tableFullWidth}>
                            <Typography className={classes.meta}>
                                <strong>Monthly Converted Leads</strong>
                            </Typography>
                            <TableContainer>
                                <Table>
                                    <EnhancedTableHead
                                        classes={classes}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                    />
                                    <TableBody>
                                        {stableSort(rmListConvertedLeads, getComparator(order, orderBy)).map(
                                            (row, idx) => {
                                                const key = `key_${idx}`;
                                                return (
                                                    <TableRow
                                                        key={key}
                                                        className={idx === 0 ? classes.winnerRow : classes.row}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {row.name} {idx === 0 && <Winner />}
                                                        </TableCell>
                                                        <TableCell align="right">{row.leads}</TableCell>
                                                        <TableCell align="right">{row.conversion}%</TableCell>
                                                        <TableCell align="right">{row.month}</TableCell>
                                                        <TableCell align="right">{row.scheduledCredits}</TableCell>
                                                    </TableRow>
                                                );
                                            }
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
}
export default TeamMetrics;
