import * as React from "react";

// MUI - general
import Button from "@mui/material/Button";

// MUI - dialog
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

// utils
import { basicDateTimeStamp } from "../../Utils";

export default function AlertDialog(props) {
    const { openAccountDetailsDialog, setOpenAccountDetailsDialog, currentExpert } = props;

    const {
        isdomesticaccount,
        account_name,
        account_type,
        ach_routing_number,
        ach_account_number,
        account_email,
        account_street_address,
        account_apt_unit,
        account_city,
        account_state,
        account_zip,
        created_on,
        // created_by, // TODO: might need this at some point
        account_country,
        swift_bic_number,
        iban_number,
        preferred_currency,
        conversion_fee,
        country,
        india_ifsc_code,
        canada_transit_number,
    } = currentExpert;

    const handleClose = () => {
        setOpenAccountDetailsDialog(false);
    };

    return (
        <div>
            <Dialog
                open={openAccountDetailsDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle id="alert-dialog-title">
                    Account Details {isdomesticaccount ? "" : "(International Account 🌎)"}
                </DialogTitle>
                <DialogContent sx={{ overflowWrap: "break-word" }}>
                    <div id="alert-dialog-description">
                        <div>
                            <b>Domestic Account: </b>
                            <span>{String(isdomesticaccount ?? "Not Specified")}</span>
                        </div>
                        <div>
                            <b>Account Name: </b>
                            <span>{account_name}</span>
                        </div>
                        <div>
                            <b>Account Type: </b>
                            <span>{account_type}</span>
                        </div>
                        <div>
                            <b>ACH Routing Number: </b>
                            <span>{ach_routing_number ? ach_routing_number : "N/A"}</span>
                        </div>
                        <div>
                            <b>ACH Account Number: </b>
                            <span>{ach_account_number ? ach_account_number : "N/A"}</span>
                        </div>
                        <div>
                            <b>Account Email: </b>
                            <span>{account_email ? account_email : "N/A"}</span>
                        </div>
                        <div>
                            <b>Account Street Address: </b>
                            <span>{account_street_address}</span>
                        </div>
                        <div>
                            <b>Account Apt Unit: </b>
                            <span>{account_apt_unit ? account_apt_unit : "N/A"}</span>
                        </div>
                        <div>
                            <b>Account City: </b>
                            <span>{account_city}</span>
                        </div>
                        <div>
                            <b>Account State: </b>
                            <span>{account_state}</span>
                        </div>
                        <div>
                            <b>Account Zip: </b>
                            <span>{account_zip}</span>
                        </div>
                        <div>
                            <b>Created On: </b>
                            <span>{basicDateTimeStamp(created_on)}</span>
                        </div>
                        {!isdomesticaccount && (
                            <>
                                <br />
                                <b>⚠️🌐🌎🌐⚠️ International Account Details ⚠️🌐🌎🌐⚠️</b>
                                <div>
                                    <b>Account Country: </b>
                                    <span>{account_country ? account_country : "N/A"}</span>
                                </div>
                                <div>
                                    <b>Swift Bic Number: </b>
                                    <span>{swift_bic_number ? swift_bic_number : "N/A"}</span>
                                </div>
                                <div>
                                    <b>IBAN Number: </b>
                                    <span>{iban_number ? iban_number : "N/A"}</span>
                                </div>
                                <div>
                                    <b>Preferred Currency: </b>
                                    <span>{preferred_currency ? preferred_currency : "N/A"}</span>
                                </div>
                                <div>
                                    <b>Conversion Fee: </b>
                                    <span>{conversion_fee ? conversion_fee : "N/A"}</span>
                                </div>
                                <div>
                                    <b>Country: </b>
                                    <span>{country ? country : "N/A"}</span>
                                </div>
                                <div>
                                    <b>India IFSC Code: </b>
                                    <span>{india_ifsc_code ? india_ifsc_code : "N/A"}</span>
                                </div>
                                <div>
                                    <b>Canada Transit Number: </b>
                                    <span>{canada_transit_number ? canada_transit_number : "N/A"}</span>
                                </div>
                            </>
                        )}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
