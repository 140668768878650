import * as React from "react";

// MUI - general
import Button from "@mui/material/Button";

// MUI - dialog
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

export default function AlertDialog(props) {
    const { largeText, openLargeTextBoxDialog, setOpenLargeTextBoxDialog } = props;

    const handleClose = () => {
        setOpenLargeTextBoxDialog(false);
    };

    return (
        <div>
            <Dialog
                open={openLargeTextBoxDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Notes</DialogTitle>
                <DialogContent sx={{ overflowWrap: "break-word" }}>
                    <DialogContentText id="alert-dialog-description">{largeText}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
