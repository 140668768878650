import React, { useState, useRef } from "react";

// MUI - general
import { TextField, MenuItem, Button, Box, Typography } from "@mui/material";

// MUI - styles
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

// custom components
import FlashMessagev3 from "../../FlashMessagev3";

// data / utils
import { cancelReasons } from "../utils/data";

const purpleText = css`
    color: #4d1b8f;
    font-weight: 700;
    font-style: italic;
    margin-bottom: 1rem;
`;

// CONSTANTS
const CUSTOM_REASON_NUM = 10;

const SchedulingReason = (props) => {
    // props
    const { callData, setCallData, status } = props;

    // init data
    const editReasonNumber = JSON.parse(callData.editReason).value;

    // state
    const [hasSubmitted, setHasSubmitted] = useState(false);
    // snackbar
    const [openSnackbar, setOpenSnackbar] = useState(false);
    // ref for textArea
    const inputRef = useRef(null);

    // ============
    // HANDLE FUNCS
    // ============
    function handleReasons(event) {
        if (hasSubmitted) {
            setHasSubmitted(false);
        }
        setCallData({ ...callData, customReason: "", editReason: event.target.value });
    }

    function handleUpdate(event) {
        setHasSubmitted(true);
        setCallData({ ...callData, customReason: inputRef.current.value });
        setOpenSnackbar(true);
    }

    return (
        <>
            <TextField
                sx={{ width: "100%", marginBottom: "1rem", backgroundColor: "#fff" }}
                autoComplete="type"
                fullWidth
                select
                label={`Select Reason For ${status === "deleting" ? "Deleting" : "Editing"} Call`}
                defaultValue=""
                onChange={handleReasons}
                value={callData.editReason}
                margin="normal"
            >
                {cancelReasons.map((reason) => (
                    <MenuItem key={reason.value} value={JSON.stringify(reason)}>
                        {reason.label}
                    </MenuItem>
                ))}
            </TextField>
            {editReasonNumber === CUSTOM_REASON_NUM && !hasSubmitted && (
                <>
                    <TextField
                        sx={{ width: "100%", backgroundColor: "#fff", marginBottom: ".5rem" }}
                        label="Reason For Rescheduling..."
                        rows={3}
                        inputRef={inputRef}
                        multiline
                    />
                    <Box display="flex" justifyContent="flex-end">
                        <Button onClick={handleUpdate} variant="contained" align="right">
                            Update
                        </Button>
                    </Box>
                </>
            )}
            {hasSubmitted && <Typography css={purpleText}>{`Reason: ${callData.customReason}`}</Typography>}
            {openSnackbar && (
                <FlashMessagev3
                    openSnackbar={openSnackbar}
                    setOpenSnackbar={setOpenSnackbar}
                    severity="success"
                    message="Successfully updated reason"
                    vertical="bottom"
                />
            )}
        </>
    );
};

export default SchedulingReason;
