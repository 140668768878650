// react
import * as React from "react";

// MUI - style imports
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

// npm
import MUIDataTable from "mui-datatables";

// utils
import { basicDateTimeStamp, truncateString } from "../../../Utils";

// custom
import LargeTextBoxDialog from "../../payment-request/LargeTextBoxDialog";

export default function OneOffChargeTable(props) {
    console.log(props, " :: props");
    // STATE
    const [largeText, setLargeText] = React.useState("");
    const [openLargeTextBoxDialog, setOpenLargeTextBoxDialog] = React.useState(false);

    // HANDLE FUNCS
    function handleLargeTextBoxDialog(str) {
        setLargeText(str);
        setOpenLargeTextBoxDialog(true);
    }

    // HELPER FUNCS
    function renderLargeTextBoxOnTable(str) {
        if (str.length > 35) {
            return (
                <>
                    <span>{truncateString(str, 35)}</span>
                    <button
                        css={css`
                            background-color: transparent;
                            border: none;
                            padding: 0;
                            margin: 0;
                            margin-top: 0.25rem;
                            font-size: 0.75rem;
                            font-weight: 300;
                            color: #542295;
                            &:hover {
                                color: blue;
                            }
                        `}
                        onClick={() => handleLargeTextBoxDialog(str)}
                    >
                        (See More)
                    </button>
                </>
            );
        } else {
            return <span>{str}</span>;
        }
    }

    // TABLE STUFF
    const options = {
        print: false,
        rowsPerPage: 100,
        viewColumns: false,
        download: false,
        selectableRowsHideCheckboxes: true,
    };

    const cols = [
        {
            name: "fname",
            label: "Name",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { fname, lname } = props.oneOffExpData?.[dataIndex];

                    return (
                        <div>
                            {fname} {lname}
                        </div>
                    );
                },
            },
        },
        {
            name: "payment_request_created_on",
            label: "History",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const {
                        payment_request_created_by_fname,
                        payment_request_created_by_lname,
                        payment_request_created_on,
                        payment_request_updated_by_fname,
                        payment_request_updated_by_lname,
                        payment_request_updated_on,
                    } = props.oneOffExpData?.[dataIndex];

                    return (
                        <div>
                            <div>
                                <strong>Created By:</strong> {payment_request_created_by_fname}{" "}
                                {payment_request_created_by_lname}
                            </div>
                            <div>{basicDateTimeStamp(payment_request_created_on)}</div>
                            {payment_request_updated_on && (
                                <div>
                                    <div>
                                        <strong>Updated By:</strong> {payment_request_updated_by_fname}{" "}
                                        {payment_request_updated_by_lname}
                                    </div>
                                    <div>{basicDateTimeStamp(payment_request_updated_on)}</div>
                                </div>
                            )}
                        </div>
                    );
                },
            },
        },
        {
            name: "custom_expert_charge_amount",
            label: "Custom Charge Details",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { custom_expert_charge_amount, custom_expert_charge_notes, custom_expert_charge_reason } =
                        props.oneOffExpData?.[dataIndex];

                    return (
                        <div>
                            <div>
                                <strong>Amount:</strong> {custom_expert_charge_amount}
                            </div>
                            <div>
                                <strong>Reason:</strong> {custom_expert_charge_reason}
                            </div>
                            <div>
                                <strong>Notes: </strong>
                                {renderLargeTextBoxOnTable(custom_expert_charge_notes)}
                            </div>
                        </div>
                    );
                },
            },
        },
        {
            name: "is_paid",
            label: "Payment Details",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { is_paid, paid_amount, payment_notes, paid_by_fname, paid_by_lname } =
                        props.oneOffExpData?.[dataIndex];

                    return (
                        <div>
                            <div>{is_paid ? "Paid💸💸" : "Not Paid 🥹🥹"}</div>
                            {is_paid && (
                                <div>
                                    <div>
                                        <strong>Paid By: </strong> {paid_by_fname} {paid_by_lname}
                                    </div>
                                    <div>
                                        <strong>Paid Amnt: </strong>
                                        {paid_amount}
                                    </div>
                                    <div>
                                        <strong>Notes: </strong>
                                        {renderLargeTextBoxOnTable(payment_notes)}
                                    </div>
                                </div>
                            )}
                        </div>
                    );
                },
            },
        },
    ];

    return (
        <>
            <MUIDataTable data={props.oneOffExpData} options={options} columns={cols} />
            {openLargeTextBoxDialog && (
                <LargeTextBoxDialog
                    largeText={largeText}
                    openLargeTextBoxDialog={openLargeTextBoxDialog}
                    setOpenLargeTextBoxDialog={setOpenLargeTextBoxDialog}
                />
            )}
        </>
    );
}
