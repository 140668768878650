import React, { useState, useEffect } from "react";

import makeStyles from "@mui/styles/makeStyles";

// npm imports
import axios from "axios";

// CONSTANTS
const ADDITIONAL_ACCESS_LIST = [2, 3, 4, 5, 67291];

// styles
const useStyles = makeStyles(() => ({
    completedScreeners: {
        fontSize: "1.25rem",
        marginTop: "1rem",
        marginBottom: "2.5rem",
    },
    highlightedScreenerText: {
        fontWeight: "700",
        color: "#523197",
        fontSize: "1.35rem",
        letterSpacing: "1.1px",
    },
}));

const CompletedScreenerCount = (props) => {
    // props
    const { userId } = props;

    // styles
    const classes = useStyles();

    // state
    const [loadingRmIds, setLoadingRmIds] = useState(true);
    const [rmAccessList, setRmAccessList] = useState([]);
    const [completedScreenerCount, setCompletedScreenerCount] = useState(0);

    // FETCH funcs
    const fetchListOfRmIds = async () => {
        await axios
            .get("/api/rm/getRmIdList")
            .then((result) => {
                const { data: rmIdList } = result.data;

                const updatedRmIdList = rmIdList.concat(ADDITIONAL_ACCESS_LIST);

                setLoadingRmIds(false);
                setRmAccessList(updatedRmIdList);
            })
            .catch((error) => {
                console.log(error, " :: there was an error getting RM list");
            });
    };

    const fetchTodaysCompletedScreeners = async () => {
        await axios
            .get("/api/rm/todaysCompletedScreeners")
            .then((result) => {
                const {
                    completedScreenerCount: [countObject],
                } = result.data;

                const screenerCount = countObject.count;

                setCompletedScreenerCount(screenerCount);
            })
            .catch((error) => {
                console.log(
                    error.response.data.message,
                    " :: there was an error fetching todays completed screener count"
                );
            });
    };

    // UTIL FUNCS
    const restrictRaAccessToScreener = () => {
        // console.log(userId, " :: userId");
        // console.log(rmAccessList, " :: rmAccessList");
        // console.log(rmAccessList.includes(userId), " :: does include?");
        if (rmAccessList.includes(userId)) {
            return (
                <div className={classes.completedScreeners}>
                    {`Completed `}
                    <span className={classes.highlightedScreenerText}>{`${completedScreenerCount}`}</span>
                    {` Screener(s) Across All Projects Today`}
                </div>
            );
        }
    };

    // PAGE LOAD
    useEffect(() => {
        (async () => {
            await fetchListOfRmIds();
            await fetchTodaysCompletedScreeners();
        })();
    }, []);

    return <>{!loadingRmIds && restrictRaAccessToScreener()}</>;
};

export default CompletedScreenerCount;
