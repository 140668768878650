import React from "react";

// utils
import { formatForConfirmation } from "../utils/utils";

// mui
import { Paper } from "@mui/material";

// styles
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

// CONSTANTS
const CUSTOM_REASON_NUM = 10;

const ScheduleConfirmationBox = (props) => {
    // props
    const { callData, status } = props;

    // init data
    const editReasonNumber = JSON.parse(callData.editReason).value;

    const {
        dateAndTime,
        callType,
        timezone,
        schedulingCredit,
        editReason,
        customReason,
        options: { moderator, record, compliance, translator },
    } = callData;

    const heavyAccentText = css`
        font-weight: 700;
        font-size: 1rem;
        color: #6d42ed;
    `;

    const mediumText = css`
        font-weight: 500;
    `;

    const header = css`
        margin-bottom: 0.65rem;
        font-weight: 700;
    `;

    const lineItemGap = css`
        margin-bottom: 0.15rem;
    `;

    return (
        <Paper sx={{ border: "3px solid #8c5429", padding: "1rem", backgroundColor: "#f7dfc1" }}>
            <div css={header}>{`Scheduling details... `}</div>
            <div css={lineItemGap}>
                <span css={mediumText}>{`Time`}</span>
                {`: `}
                <span css={heavyAccentText}>{`${formatForConfirmation(dateAndTime)}`}</span>
            </div>
            <div css={lineItemGap}>
                <span css={mediumText}>{`Timezone`}</span>
                {`: `}
                <span css={heavyAccentText}>{`${JSON.parse(timezone).label}`}</span>
            </div>
            <div css={lineItemGap}>
                <span css={mediumText}>{`Platform`}</span>
                {`: `}
                <span css={heavyAccentText}>{`${callType.label}`}</span>
            </div>
            <div css={lineItemGap}>
                <span css={mediumText}>{`Credit Going To`}</span>
                {`: `}
                <span css={heavyAccentText}>{`${JSON.parse(schedulingCredit).label}`}</span>
            </div>
            {status === "editing" && (
                <div css={lineItemGap}>
                    <span css={mediumText}>{`Reason For Editing`}</span>
                    {`: `}
                    <span css={heavyAccentText}>{`${
                        editReasonNumber === CUSTOM_REASON_NUM
                            ? `(Custom) ${customReason}`
                            : JSON.parse(editReason).label
                    }`}</span>
                </div>
            )}
            <br />
            <div css={header}>{`Additionally you have selected a need for...`}</div>
            <div css={lineItemGap}>
                <span css={mediumText}>{`Moderator? `}</span>
                <span css={heavyAccentText}>{`${moderator ? "Yes" : "No"}`}</span>
            </div>
            <div css={lineItemGap}>
                <span css={mediumText}>{`Recording? `}</span>
                <span css={heavyAccentText}>{`${record ? "Yes" : "No"}`}</span>
            </div>
            <div css={lineItemGap}>
                <span css={mediumText}>{`Compliance? `}</span>
                <span css={heavyAccentText}>{`${compliance ? "Yes" : "No"}`}</span>
            </div>
            <div css={lineItemGap}>
                <span css={mediumText}>{`Translator? `}</span>
                <span css={heavyAccentText}>{`${translator ? "Yes" : "No"}`}</span>
            </div>
        </Paper>
    );
};

export default ScheduleConfirmationBox;
