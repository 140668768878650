import React, { useState, useEffect, useRef } from "react";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import "moment-duration-format";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import { green } from "@mui/material/colors";
import { formatPhoneNumber, doctorNameFormat } from "../Utils";
import { useTheme } from "@mui/system";
// TODO: Remove eventually...
// import CustomAddTermsLink from "./CustomAddTermsLink";
// import CustomAddDisclosures from "./CustomAddDisclosures";
import CustomOnboardingLink from "./CustomOnboardingLink";
import CustomScreenerLink from "./CustomScreenerLink";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    typography: {
        padding: theme.spacing(2),
    },
    actions: {
        textAlign: "Center",
    },
    warningCopy: {
        color: "red",
        fontSize: "1.25rem",
        fontWeight: "bold",
        marginTop: "0",
    },
    uneditableHeader: {
        color: "red",
        fontSize: ".65rem",
        textTransform: "uppercase",
        textAlign: "center",
    },
    datestamp: {
        color: "#666",
        fontSize: ".65rem",
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    emailBorder: {
        border: "1px solid #e5e5e5",
        backgroundColor: "#f8f8f8",
        borderRadius: "2px",
        padding: "12px",
        fontSize: ".75rem",
    },
    emailOutline: {
        border: "1px solid #eaeaea",
        backgroundColor: "#ffffff",
        borderRadius: "2px",
        padding: "8px",
    },
    editorWrapper: {
        border: "1px solid #EAEAEA",
        borderRadius: "2px",
        padding: "12px",
    },
    iconSmall: {
        fontSize: ".95rem",
    },
    wrapper: {
        margin: theme.spacing(1),
        position: "relative",
    },
    buttonSuccess: {
        backgroundColor: green[500],
        "&:hover": {
            backgroundColor: green[700],
        },
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: 22,
    },
}));

function StrategicRecruitmentTemplate(props) {
    const _state = props.state_manager;
    const theme = useTheme();
    const now = moment();
    const classes = useStyles();
    const timer = useRef();
    const [confirmButton, setConfirmButtonDisplay] = useState("block");
    const [sendButton, setButtonDisplay] = useState("none");
    const [preview, setPreviewArea] = useState("none");
    const [editableAreas, setEditableArea] = useState("block");
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [editor, setEditorState] = useState(EditorState.createEmpty());
    const [template, setEmailTemplate] = useState({
        expert_id: props.expert.id,
        expert_prefix: props.expert.prefix,
        expert_fname: props.expert.fname,
        expert_lname: props.expert.lname,
        expert_suffix: props.expert.suffix,
        expert_email: props.expert.mail.trim(),
        project_id: _state.get.projectState.id,
        user_id: props.user.id,
        user_fname: props.user.fname,
        user_lname: props.user.lname,
        from_email: props.user.mail,
        email_subject: props.expert.tnc_sent
            ? `Follow-up: Expertise Requested in ${_state.get.projectState.title} (ID-${_state.get.projectState.id})`
            : `Expertise Requested in ${_state.get.projectState.title} (ID-${_state.get.projectState.id})`,
        email_body: "",
        email_template: props.type,
    });

    const buttonClassname = clsx({
        // TODO:
        [classes.buttonSuccess]: success,
    });

    function formatDateString(str) {
        return moment(str).format("dddd, MMMM. Do, YYYY, h:mma");
    }

    function handleChange(event) {
        const { target } = event;
        const { value, name } = target;
        setEmailTemplate({
            ...template,
            [name]: value,
        });
    }

    function findReplaceTermsLink(body) {
        console.log(props.privateToken);
        const onboardLink = `<a href="https://www.firstthought.io/onboard/${props.privateToken}" target="_blank">online terms and disclosures</a>`;
        const screenerLink = `<a href="https://survey.firstthought.io/${props.privateToken}" target="_blank">screener survey</a>`;
        let newBody = "";
        if (body.indexOf("{{ONBOARDING_LINK}}") !== -1) {
            newBody = body.replace("{{ONBOARDING_LINK}}", onboardLink);
            // Set template to TNC if link is found
            template.email_template = "tnc";
        } else if (body.indexOf("{{SCREENER_LINK}}") !== -1) {
            newBody = body.replace("{{SCREENER_LINK}}", screenerLink);
            template.email_template = "screener";
        } else {
            newBody = body;
        }
        return newBody;
    }

    function onEditorStateChange(editor) {
        setEditorState(editor);
        const jsonHtml = convertToRaw(editor.getCurrentContent());
        const html = draftToHtml(jsonHtml);
        // const editorHtml = htmlToDraft(html);
        const newHtml = findReplaceTermsLink(html);
        setEmailTemplate({
            ...template,
            email_body: newHtml,
        });
    }

    function strategicTemplate() {
        let html = "<p>";
        html += `${doctorNameFormat(
            template.expert_prefix,
            template.expert_fname,
            template.expert_lname,
            template.expert_suffix
        )},`;
        html += "</p>";
        html += "<p>";
        html += `My name is ${props.user.fname} ${props.user.lname} and I am a ${props.user.title} at FirstThought, a life sciences-focused expert network based in New York City that works with various clients in the healthcare consulting and investment industries to help them connect with experts for the purposes of completing their research.`;
        html += "</p>";
        html += "<p>";
        html +=
            "Many of our <strong>clients conducting research [*** FILL IN TOPIC***], are frequently looking to speak with experts like yourself</strong> to get your thoughts on the treatments in development and the current standard of care in this field. After reviewing some of your experiences and therapeutic expertise, I am confident that you would be able to provide us some important insights into the treatment and management of <strong>[*** FILL IN TOPIC***]</strong>.";
        html += "</p>";
        html += "<p>";
        html += `If you’re interested in participating in <strong>paid</strong> ad hoc phone consultations and surveys on this topic and others, please click on the link below to accept FirstThought’s Expert Agreement and join our network: <strong><a href="https://www.firstthought.io/onboard/${props.privateToken}">online terms and disclosures</a></strong>.`;
        html += "</p>";
        html += "<p>";
        html += "Thank you in advance for your consideration, and I look forward to hopefully hearing from you soon.";
        html += "</p>";
        html += "<p>";
        html += `Best regards,<br />${props.user.fname}`;
        html += "</p>";
        html += "<br />";
        html += "<p>";
        html += `<em>${props.user.fname} ${props.user.lname}</em>`;
        html += "<br />";
        html += `<em>${props.user.title}</em>`;
        html += "<br />";
        html += `<em>${formatPhoneNumber(props.user.number)}</em>`;
        html += "<br />";
        html += `<em>${props.user.mail}</em>`;
        html += "</p>";
        html += "<br />";
        html += "<p style='text-align:center;font-size:10px;'>";
        html += "www.firstthought.io | new york | london | tel aviv";
        html += "<br />";
        html += `You may <a href="https://www.firstthought.io/unsubscribe/${template.expert_id}/${template.expert_email}/tnc">unsubscribe</a> from future emails, but please be aware this would drastically limit you from receiving important opportunities to share your expertise.`;
        html += "</p>";
        return html;
    }

    function followUpTemplate() {
        let html = "<p>";
        html += `${doctorNameFormat(
            template.expert_prefix,
            template.expert_fname,
            template.expert_lname,
            template.expert_suffix
        )},`;
        html += "</p>";
        html += `<p style='color:red;font-weight:bold;'>Hey ${props.user.fname}! PLEASE NOTE: This is just suggested default email copy fully intended to be customized, edited and most importantly reviewed by you.</p>`;
        html += "<p>";
        html +=
            "I wanted to send you a quick follow-up note after my email and [voicemail that I left with your office] yesterday. We are happy to <strong>pay you an hourly honorarium of your choosing (most of the providers in private practices or community outpatient facilities in our network charge $200-400 per phone consultation)</strong> for your participation in ad hoc phone consultations on topics that are related to your work as a [community oncologist/ pathologist].";
        html += "</p>";
        html += "<p>";
        html +=
            "To recap my email (below), we work with many healthcare consulting firms and investors who are looking to better understand current standards of diagnosis and treatment in the healthcare sector. Based on your current position, we know you are already qualified to speak with our clients on a variety of topics related to your field.";
        html += "</p>";
        html += "<p>";
        html += `Here is an <strong><a href="https://www.firstthought.io/onboard/${props.privateToken}">onboarding link</a></strong> to join our network and get started. Please don’t hesitate to give me a call back if you have any questions.`;
        html += "</p>";
        html += "<p>";
        html += `Cheers,<br />${props.user.fname}`;
        html += "</p>";
        html += "<br />";
        html += "<p>";
        html += `<em>${props.user.fname} ${props.user.lname}</em>`;
        html += "<br />";
        html += `<em>${props.user.title}</em>`;
        html += "<br />";
        html += `<em>${formatPhoneNumber(props.user.number)}</em>`;
        html += "<br />";
        html += `<em>${props.user.mail}</em>`;
        html += "</p>";
        html += "<br />";
        html += "<p style='text-align:center;font-size:10px;'>";
        html += "www.firstthought.io | new york | london | tel aviv";
        html += "<br />";
        html += `You may <a href="https://www.firstthought.io/unsubscribe/${template.expert_id}/${template.expert_email}/tnc">unsubscribe</a> from future emails, but please be aware this would drastically limit you from receiving important opportunities to share your expertise.`;
        html += "</p>";
        return html;
    }

    function setDefaultReferenceEditorState() {
        let html = "";
        if (props.expert.tnc_sent) {
            html = followUpTemplate();
        } else {
            html = strategicTemplate();
        }

        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            setEditorState(EditorState.createWithContent(contentState));
        }
        setEmailTemplate({
            ...template,
            email_body: html,
        });
    }

    function sendEmail() {
        const url = "/api/mail/tnc";
        if (!loading) {
            setSuccess(false);
            setLoading(true);
        }
        axios
            .post(url, template, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    console.log("Email Template Sent", template);
                    timer.current = setTimeout(() => {
                        setSuccess(true);
                        setLoading(false);
                        props.closeEvent();
                        props.updateEvent(result.data.data);
                    }, 3000);
                    // Reload the page to show new TnC status
                } else {
                    console.log(`POSTED, but NOT Status 200: ${JSON.stringify(template)}`);
                    alert("Oops, Something went wrong. Your email did not send. Please contact someone in Tech.");
                }
            })
            .catch((result) => {
                console.log("Something went wrong, API Error, 404 Error:", result);
                alert("Oops, Something went wrong. Your email did not send. Please contact someone in Tech.");
            });
    }

    function showPreview() {
        setEditableArea("none");
        setPreviewArea("block");
        setConfirmButtonDisplay("block");
        setButtonDisplay("none");
    }
    function editEmail() {
        setEditableArea("block");
        setPreviewArea("none");
        setConfirmButtonDisplay("block");
        setButtonDisplay("none");
    }
    function showSendEmailButton() {
        if (props.user.number !== "" && props.user.number.length > 9) {
            setButtonDisplay("block");
            setConfirmButtonDisplay("none");
            console.log(template);
        } else {
            alert("Please provide a phone number for yourself before sending any outreach.");
        }
    }

    useEffect(() => {
        setDefaultReferenceEditorState();
    }, [props]);

    return (
        <div>
            <Box display={preview}>
                <Box display={confirmButton}>
                    <Typography sx={{ color: "red", fontSize: "1.25rem", fontWeight: "bold", marginTop: "0" }}>
                        PREVIEW ONLY: Please review and confirm this is ready to send.
                    </Typography>
                </Box>
                <Box
                    sx={{
                        border: "1px solid #e5e5e5",
                        backgroundColor: "#f8f8f8",
                        borderRadius: "2px",
                        padding: "12px",
                        fontSize: ".75rem",
                    }}
                >
                    <Typography sx={{ color: "#666", fontSize: ".65rem" }}>{formatDateString(now)}</Typography>
                    <p>
                        <strong>FROM:</strong> {template.user_fname} {template.user_lname} ({template.from_email})
                    </p>
                    <p>
                        <strong>TO:</strong> {template.expert_fname} {template.expert_lname} ({template.expert_email})
                    </p>
                    <Typography sx={{ borderBottom: "2px solid #444", paddingBottom: "5px", marginBottom: "10px" }}>
                        <strong>SUBJECT LINE:</strong> {template.email_subject}
                    </Typography>
                    <Box
                        sx={{
                            border: "1px solid #eaeaea",
                            backgroundColor: "#ffffff",
                            borderRadius: "2px",
                            padding: "8px",
                        }}
                        dangerouslySetInnerHTML={{
                            __html: template.email_body,
                        }}
                    />
                </Box>
                <Box sx={{ textAlign: "Center" }}>
                    <Box sx={{ margin: theme.spacing(1), position: "relative" }}>
                        <Box display={confirmButton}>
                            <Button onClick={editEmail}>Edit this Email</Button>
                            <Button onClick={showSendEmailButton}>
                                <WhatshotIcon /> Confirmed, This Looks Fire!
                            </Button>
                        </Box>
                        <Box display={sendButton}>
                            <Button onClick={editEmail}>Back to Edit</Button>
                            <Button
                                variant="contained"
                                color="primary"
                                className={buttonClassname}
                                disabled={loading}
                                onClick={sendEmail}
                            >
                                Send Email Now
                            </Button>
                            {loading && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        color: green[500],
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        marginTop: -12,
                                        marginLeft: 22,
                                    }}
                                />
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box display={editableAreas}>
                <TextField
                    fullWidth
                    id="email_subject"
                    label="Email Subject"
                    margin="normal"
                    onChange={handleChange}
                    name="email_subject"
                    value={template.email_subject}
                />
                <Editor
                    editorState={editor}
                    spellCheck
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName={{ border: "1px solid #EAEAEA", borderRadius: "2px", padding: "12px" }}
                    onEditorStateChange={onEditorStateChange}
                    toolbarCustomButtons={[<CustomOnboardingLink />]}
                />
                <Box sx={{ textAlign: "Center" }}>
                    <Box sx={{ margin: theme.spacing(1), position: "relative" }}>
                        <Button onClick={props.closeEvent}>Cancel</Button>
                        <Button variant="contained" color="primary" className={buttonClassname} onClick={showPreview}>
                            Next
                        </Button>
                    </Box>
                </Box>
            </Box>
        </div>
    );
}
export default StrategicRecruitmentTemplate;
