import React, { useState } from "react";

import { TextField, Box, Button } from "@mui/material";

const SchedulingRequestPaymentDialog = (props) => {
    const { paymentDetails, setPaymentDetails, error } = props;
    const [notice, setNoticeDisplay] = useState("block");
    const [details, setDetailsDisplay] = useState("none");

    function acknowledgementBtn() {
        setNoticeDisplay("none");
        setDetailsDisplay("block");
    }

    return (
        <>
            <Box display={notice}>
                <>
                    <p
                        sx={{
                            color: "#444444",
                            fontSize: "1.5rem",
                            width: "fit-content",
                        }}
                    >
                        <strong>WARNING:</strong> Before you request payment or update payment details for this expert,
                        please be sure to confirm the correct hourly rate as well as minimum hourly settings and note
                        any special requirements or changes related to their payment in the payment details.
                        <br />
                        All payment related disputes must be settled before continuing. This is important because once
                        you request payment and it gets approved, we can not make any additional edits.
                        <br />
                        Please acknowledge this notice before proceeding.
                    </p>
                    <Button variant="contained" onClick={acknowledgementBtn}>
                        Yes, I Understand
                    </Button>
                </>
            </Box>
            <Box display={details}>
                <TextField
                    sx={{ width: "50rem" }}
                    value={paymentDetails}
                    onChange={(event) => {
                        setPaymentDetails(event.target.value);
                    }}
                    margin="dense"
                    label="Payment Details"
                    name="paymentDetails"
                    error={error.error && error.element === "paymentDetails"}
                    helperText={error.element === "paymentDetails" ? error.msg : ""}
                    multiline
                    rows={20}
                />
            </Box>
        </>
    );
};

export default SchedulingRequestPaymentDialog;
