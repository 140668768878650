import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Unstable_Grid2";
// MUI - style imports
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Divider from "@mui/material/Divider";
import Select from "react-select";
import axios from "axios";

import { authContext } from "../context/auth";
import StateListData from "../data/us_states.json";
import CountriesListData from "../data/countries.json";
const usStates = StateListData.states;
const countriesList = CountriesListData.countries;

const header = css`
    font-size: 2rem;
    margin-bottom: 2rem;
`;

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

const PaymentAccount = (props) => {
    const { match, history } = props;
    const { auth } = useContext(authContext);
    const [value, setValue] = React.useState(0);
    const [incoming, setIncoming] = React.useState(false);

    const { control, handleSubmit } = useForm({
        defaultValues: {
            expertId: match.params.id,
            accountName: "",
            accountType: "checking",
            achRoutingNumber: "",
            achAccountNumber: "",
            email: "",
            accountStreetAddress: "",
            apt: "",
            city: "",
            state: "",
            zip: "",
            createdBy: auth.data.id,
        },
    });
    const onSubmit = (data) => {
        console.log("here");
        console.log("POSTED DATA", data);
        axios
            .post("/api/expert/saveDomesticPaymentAccount", data)
            .then((result) => {
                if (result.status === 200) {
                    setIncoming(true);
                    console.log("success 200");
                }
            })
            .catch((ex) => {
                console.log("Error occured saving the Payment information");
            });
    };

    const { control: controlCheck, handleSubmit: handleSubmitCheck } = useForm({
        defaultValues: {
            expertId: match.params.id,
            accountName: "",
            accountType: "check",
            accountStreetAddress: "",
            apt: "",
            city: "",
            state: "",
            zip: "",
            createdBy: auth.data.id,
        },
    });

    const onSubmitCheck = (data) => {
        console.log("here");
        console.log("POSTED DATA", data);
        axios
            .post("/api/expert/saveCheckPaymentAccount", data)
            .then((result) => {
                if (result.status === 200) {
                    setIncoming(true);
                    console.log("success 200");
                }
            })
            .catch((ex) => {
                console.log("Error occured saving the Payment information");
            });
    };

    const { control: controlInternational, handleSubmit: handleSubmitInternational } = useForm({
        defaultValues: {
            expertId: match.params.id,
            accountName: "",
            accountType: "checking",
            accountCountry: "",
            swiftBic: "",
            accountNumber: "",
            ibanNumber: "",
            preferredCurrency: "",
            conversionFee: "",
            accountHolderEmail: "",
            accountStreetAddress: "",
            apt: "",
            city: "",
            state: "",
            postalCode: "",
            country: "",
            createdBy: auth.data.id,
            indiaIfscCode: "",
            canadaTransitNumber: "",
        },
    });

    const onSubmitInternational = (data) => {
        console.log("here international");
        console.log("POSTED DATA for International", data);
        axios
            .post("/api/expert/saveIntlPaymentAccount", data)
            .then((result) => {
                if (result.status === 200) {
                    setIncoming(true);
                    console.log("success 200");
                }
            })
            .catch((ex) => {
                console.log("Error occured saving the Payment information");
            });
    };

    const handleTabsChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleCloseMessage = () => {
        setIncoming(false);
        console.log("props.match.params.id", match.params.id);
        if (match.params.id) {
            history.push(`/expert/view/${match.params.id}`);
        }
    };

    const statesObject = usStates.map((item) => {
        return {
            value: item.abbreviation,
            label: item.name,
        };
    });

    const countriesObject = countriesList.map((item) => {
        return {
            value: item.name,
            label: item.name,
        };
    });

    return (
        <>
            <h1 css={header}>Payment Details</h1>
            <Snackbar
                open={incoming}
                autoHideDuration={10000}
                onClose={handleCloseMessage}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <Alert onClose={handleCloseMessage} severity="success">
                    Payment details has been saved
                </Alert>
            </Snackbar>
            <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs value={value} onChange={handleTabsChange} aria-label="basic tabs example">
                        <Tab label="Direct Deposit" {...a11yProps(0)} />
                        <Tab label="Check" {...a11yProps(1)} />
                        <Tab label="International" {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            <Grid xs={12}>
                                <Controller
                                    name="accountName"
                                    control={control}
                                    rules={{
                                        required: "Name on the Account is required",
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            id="accountName"
                                            label="Name On The Account"
                                            variant="outlined"
                                            fullWidth
                                            {...field}
                                            error={!!error}
                                            helperText={error ? error.message : null}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid xs={12}>
                                <Controller
                                    rules={{ required: true }}
                                    control={control}
                                    name="accountType"
                                    defaultValue="checking"
                                    render={({ field, fieldState: { error } }) => (
                                        <RadioGroup row {...field}>
                                            <FormControlLabel value="checking" control={<Radio />} label="Checking" />
                                            <FormControlLabel value="savings" control={<Radio />} label="Savings" />
                                        </RadioGroup>
                                    )}
                                />
                            </Grid>
                            <Grid xs={12}>
                                <Controller
                                    name="achRoutingNumber"
                                    control={control}
                                    rules={{
                                        required: "ACH ROUTING NUMBER is required",
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            id="achRoutingNumber"
                                            label="ACH ROUTING NUMBER"
                                            variant="outlined"
                                            fullWidth
                                            {...field}
                                            error={!!error}
                                            helperText={error ? error.message : null}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid xs={12}>
                                <Controller
                                    name="achAccountNumber"
                                    control={control}
                                    rules={{
                                        required: "ACH ACCOUNT NUMBER is required",
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            id="achAccountNumber"
                                            label="ACH ACCOUNT NUMBER"
                                            variant="outlined"
                                            fullWidth
                                            {...field}
                                            error={!!error}
                                            helperText={error ? error.message : null}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid xs={12}>
                                <Controller
                                    name="email"
                                    control={control}
                                    rules={{
                                        required: "Email is required",
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            id="email"
                                            label="EMAIL OF THE ACCOUNT HOLDER"
                                            variant="outlined"
                                            fullWidth
                                            {...field}
                                            error={!!error}
                                            helperText={error ? error.message : null}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid xs={12}>
                                <Controller
                                    name="accountStreetAddress"
                                    control={control}
                                    rules={{
                                        required: "Account Holder's Street Adress is required",
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            id="accountStreetAddress"
                                            label="ACCOUNT HOLDER'S STREET ADDRESS"
                                            variant="outlined"
                                            fullWidth
                                            {...field}
                                            error={!!error}
                                            helperText={error ? error.message : null}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid xs={12}>
                                <Controller
                                    name="apt"
                                    control={control}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField id="apt" label="APT, UNIT" variant="outlined" fullWidth {...field} />
                                    )}
                                />
                            </Grid>

                            <Grid xs={4}>
                                <Controller
                                    name="city"
                                    control={control}
                                    rules={{
                                        required: "City is required",
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            id="accountStreetAddress"
                                            label="City"
                                            variant="outlined"
                                            fullWidth
                                            {...field}
                                            error={!!error}
                                            helperText={error ? error.message : null}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid xs={4}>
                                <Controller
                                    name="state"
                                    control={control}
                                    render={({ field }) => <Select {...field} options={statesObject} />}
                                />
                            </Grid>
                            <Grid xs={4}>
                                <Controller
                                    name="zip"
                                    control={control}
                                    rules={{
                                        required: "Zip is required",
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            id="zip"
                                            label="Zip"
                                            variant="outlined"
                                            fullWidth
                                            {...field}
                                            error={!!error}
                                            helperText={error ? error.message : null}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid xs={8}>
                                <Button size="large" fullWidth type="submit" variant="contained">
                                    SUBMIT
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <form onSubmit={handleSubmitCheck(onSubmitCheck)}>
                        <Grid container spacing={2}>
                            <Grid xs={12}>
                                <Controller
                                    name="accountName"
                                    control={controlCheck}
                                    rules={{
                                        required: "Name is required",
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            id="accountName"
                                            label="NAME CHECK ADDRESSED TO"
                                            variant="outlined"
                                            fullWidth
                                            {...field}
                                            error={!!error}
                                            helperText={error ? error.message : null}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid xs={12}>
                                <Controller
                                    name="accountStreetAddress"
                                    control={controlCheck}
                                    rules={{
                                        required: "MAILING ADDRESS is required",
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            id="accountStreetAddress"
                                            label="MAILING ADDRESS"
                                            variant="outlined"
                                            fullWidth
                                            {...field}
                                            error={!!error}
                                            helperText={error ? error.message : null}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid xs={12}>
                                <Controller
                                    name="apt"
                                    control={controlCheck}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField id="apt" label="APT, UNIT" variant="outlined" fullWidth {...field} />
                                    )}
                                />
                            </Grid>

                            <Grid xs={4}>
                                <Controller
                                    name="city"
                                    control={controlCheck}
                                    rules={{
                                        required: "City is required",
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            id="accountStreetAddress"
                                            label="City"
                                            variant="outlined"
                                            fullWidth
                                            {...field}
                                            error={!!error}
                                            helperText={error ? error.message : null}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid xs={4}>
                                <Controller
                                    name="state"
                                    control={controlCheck}
                                    render={({ field }) => <Select {...field} options={statesObject} />}
                                />
                            </Grid>
                            <Grid xs={4}>
                                <Controller
                                    name="zip"
                                    control={controlCheck}
                                    rules={{
                                        required: "Zip is required",
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            id="zip"
                                            label="Zip"
                                            variant="outlined"
                                            fullWidth
                                            {...field}
                                            error={!!error}
                                            helperText={error ? error.message : null}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid xs={8}>
                                <Button size="large" fullWidth type="submit" variant="contained">
                                    SUBMIT
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <form onSubmit={handleSubmitInternational(onSubmitInternational)}>
                        <Grid container spacing={2}>
                            <Grid xs={12}>
                                <Controller
                                    name="accountName"
                                    control={controlInternational}
                                    rules={{
                                        required: "Name on the Account is required",
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            id="accountName"
                                            label="Name On The Account"
                                            variant="outlined"
                                            fullWidth
                                            {...field}
                                            error={!!error}
                                            helperText={error ? error.message : null}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid xs={12}>
                                <Controller
                                    rules={{ required: true }}
                                    control={controlInternational}
                                    name="accountType"
                                    defaultValue="checking"
                                    render={({ field, fieldState: { error } }) => (
                                        <RadioGroup row {...field}>
                                            <FormControlLabel value="checking" control={<Radio />} label="Checking" />
                                            <FormControlLabel value="savings" control={<Radio />} label="Savings" />
                                        </RadioGroup>
                                    )}
                                />
                            </Grid>
                            <Grid xs={12}>
                                <Controller
                                    name="accountCountry"
                                    control={controlInternational}
                                    rules={{
                                        required: "COUNTRY OF THE ACCOUNT is required",
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            id="accountCountry"
                                            label="COUNTRY OF THE ACCOUNT"
                                            variant="outlined"
                                            fullWidth
                                            {...field}
                                            error={!!error}
                                            helperText={error ? error.message : null}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid xs={12}>
                                <Controller
                                    name="swiftBic"
                                    control={controlInternational}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            id="swiftBic"
                                            label="SWIFT/BIC NUMBER"
                                            variant="outlined"
                                            fullWidth
                                            {...field}
                                            error={!!error}
                                            helperText={error ? error.message : null}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid xs={6}>
                                <Controller
                                    name="accountNumber"
                                    control={controlInternational}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            id="accountNumber"
                                            label="ACCOUNT NUMBER"
                                            variant="outlined"
                                            fullWidth
                                            {...field}
                                            error={!!error}
                                            helperText={error ? error.message : null}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid xs={6}>
                                <Controller
                                    name="ibanNumber"
                                    control={controlInternational}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            id="ibanNumber"
                                            label="IBAN NUMBER"
                                            variant="outlined"
                                            fullWidth
                                            {...field}
                                            error={!!error}
                                            helperText={error ? error.message : null}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid xs={6}>
                                <Controller
                                    name="indiaIfscCode"
                                    control={controlInternational}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            id="indiaIfscCode"
                                            label="INDIA SPECIFIC IFSC CODE (IF APPLICABLE)"
                                            variant="outlined"
                                            fullWidth
                                            {...field}
                                            error={!!error}
                                            helperText={error ? error.message : null}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid xs={6}>
                                <Controller
                                    name="canadaTransitNumber"
                                    control={controlInternational}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            id="canadaTransitNumber"
                                            label="CANADA TRANSIT NUMBER (IF APPLICABLE)"
                                            variant="outlined"
                                            fullWidth
                                            {...field}
                                            error={!!error}
                                            helperText={error ? error.message : null}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid xs={4}>
                                <Controller
                                    name="preferredCurrency"
                                    control={controlInternational}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            id="preferredCurrency"
                                            label="PREFERRED CURRENCY(IF NOT USD)"
                                            variant="outlined"
                                            fullWidth
                                            {...field}
                                            error={!!error}
                                            helperText={error ? error.message : null}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid xs={4}>&nbsp;</Grid>
                            <Grid xs={4}>
                                <Controller
                                    name="conversionFee"
                                    control={controlInternational}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            id="conversionFee"
                                            label="CONVERSION FEE"
                                            variant="outlined"
                                            fullWidth
                                            {...field}
                                            error={!!error}
                                            helperText={error ? error.message : null}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid xs={12}>
                                <Divider>Address Information</Divider>
                            </Grid>
                            <Grid xs={12}>
                                <Controller
                                    name="accountHolderEmail"
                                    control={controlInternational}
                                    rules={{
                                        required: "Email is required",
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            id="accountHolderEmail"
                                            label="EMAIL OF THE ACCOUNT HOLDER"
                                            variant="outlined"
                                            fullWidth
                                            {...field}
                                            error={!!error}
                                            helperText={error ? error.message : null}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid xs={12}>
                                <Controller
                                    name="accountStreetAddress"
                                    control={controlInternational}
                                    rules={{
                                        required: "Account Holder's Street Adress is required",
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            id="accountStreetAddress"
                                            label="ACCOUNT HOLDER'S STREET ADDRESS"
                                            variant="outlined"
                                            fullWidth
                                            {...field}
                                            error={!!error}
                                            helperText={error ? error.message : null}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid xs={12}>
                                <Controller
                                    name="apt"
                                    control={controlInternational}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField id="apt" label="APT, UNIT" variant="outlined" fullWidth {...field} />
                                    )}
                                />
                            </Grid>

                            <Grid xs={6}>
                                <Controller
                                    name="city"
                                    control={controlInternational}
                                    rules={{
                                        required: "City is required",
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            id="accountStreetAddress"
                                            label="City"
                                            variant="outlined"
                                            fullWidth
                                            {...field}
                                            error={!!error}
                                            helperText={error ? error.message : null}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid xs={6}>
                                <Controller
                                    name="postalCode"
                                    control={controlInternational}
                                    rules={{
                                        required: "Postal Code is required",
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            id="postalCode"
                                            label="POSTAL CODE"
                                            variant="outlined"
                                            fullWidth
                                            {...field}
                                            error={!!error}
                                            helperText={error ? error.message : null}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid xs={6}>
                                <Controller
                                    name="state"
                                    control={controlInternational}
                                    rules={{
                                        required: "State,Provice or Region is required",
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            id="state"
                                            label="STATE, PROVINCE OR REGION"
                                            variant="outlined"
                                            fullWidth
                                            {...field}
                                            error={!!error}
                                            helperText={error ? error.message : null}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid xs={6}>
                                <Controller
                                    name="country"
                                    control={controlInternational}
                                    rules={{
                                        required: "Countryis required",
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <Select
                                            {...field}
                                            options={countriesObject}
                                            error={!!error}
                                            helperText={error ? error.message : null}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid xs={8}>
                                <Button size="large" fullWidth type="submit" variant="contained">
                                    SUBMIT
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </TabPanel>
            </Box>
        </>
    );
};
export default PaymentAccount;
