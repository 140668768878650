import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    section: {
        backgroundColor: "#add0b9",
        color: "#FFFFFF",
        padding: "8px",
        fontSize: "1.25rem",
        borderRadius: "2px",
    },
    label: {
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(0.5),
        fontSize: "1rem",
    },
    strong: {
        color: theme.palette.text.primary,
        marginBottom: theme.spacing(0.5),
        fontSize: "1rem",
    },
    margin: {
        margin: theme.spacing(1),
    },
    row: {
        marginTop: theme.spacing(2),
    },
    pushRight: {
        textAlign: "right",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    link: {
        color: "#19857b",
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function TwilioBridgeSettings(props) {
    const classes = useStyles();

    return (
        <Grid container spacing={3}>
            <Grid item sm={12} xs={12}>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend">Please select an option for Twilio:</FormLabel>
                    <RadioGroup
                        name="twilio_bridge_setting"
                        value={props.twilio_bridge_setting}
                        onChange={props.changeEvent}
                    >
                        <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Automatically dial out to Expert when Client Contact dials in to bridge (default)"
                        />
                        <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="Automatically dial out to Client Contact when Expert dials in to bridge"
                        />
                        <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="Automatically dial out to both Client Contact and Expert at scheduled call start time"
                        />
                        <FormControlLabel
                            value="4"
                            control={<Radio />}
                            label="Client Contact and Expert to manually dial in"
                        />
                        <FormControlLabel value="5" control={<Radio />} label="None" />
                    </RadioGroup>
                </FormControl>
            </Grid>
        </Grid>
    );
}

export default TwilioBridgeSettings;
