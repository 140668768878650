import React from "react";

// MUI - general
import { Typography } from "@mui/material";

// MUI - styles
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

// custom components
import SchedulingReason from "./SchedulingReason";

const SchedulingDelete = (props) => {
    const { callData, setCallData, status } = props;

    return (
        <>
            <Typography
                css={css`
                    margin-top: 0.75rem;
                    margin-bottom: 2rem;
                `}
            >
                🚨⚠️ Are you{" "}
                <span
                    css={css`
                        font-weight: 700;
                    `}
                >
                    ABSOLUTELY 100%
                </span>{" "}
                sure you want to delete this call?? ⚠️🚨
            </Typography>
            <SchedulingReason status={status} callData={callData} setCallData={setCallData} />
        </>
    );
};

export default SchedulingDelete;
