import React from "react";
import { Link, withRouter } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import ContactsIcon from "@mui/icons-material/Contacts";
import AssignmentIcon from "@mui/icons-material/Assignment";
import FingerPrintIcon from "@mui/icons-material/Fingerprint";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import CategoryIcon from "@mui/icons-material/Category";
import BusinessIcon from "@mui/icons-material/Business";
import SearchIcon from "@mui/icons-material/Search";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

export const mainListItems = (
    <div>
        <ListItem button title="Dashboard" component={Link} to={"/"}>
            <ListItemIcon>
                <DashboardIcon />
            </ListItemIcon>
            <ListItemText>Dashboard</ListItemText>
        </ListItem>
        <ListItem button title="Projects" component={Link} to={"/project"}>
            <ListItemIcon>
                <AssignmentIcon />
            </ListItemIcon>
            <ListItemText>Projects</ListItemText>
        </ListItem>
        <ListItem button title="Clients" component={Link} to={"/client"}>
            <ListItemIcon>
                <BusinessIcon />
            </ListItemIcon>
            <ListItemText>Clients</ListItemText>
        </ListItem>
        <ListItem button title="Contacts" component={Link} to={"/contact"}>
            <ListItemIcon>
                <ContactsIcon />
            </ListItemIcon>
            <ListItemText>Contacts</ListItemText>
        </ListItem>
        <ListItem button title="Experts" component={Link} to={"/expert"}>
            <ListItemIcon>
                <PeopleIcon />
            </ListItemIcon>
            <ListItemText>Experts</ListItemText>
        </ListItem>
        <ListItem button title="Searches" component={Link} to={"/searches"}>
            <ListItemIcon>
                <SearchIcon />
            </ListItemIcon>
            <ListItemText>Searches</ListItemText>
        </ListItem>
    </div>
);

export const secondaryListItems = (
    <div>
        <ListItem button title="Accounting" component={Link} to={"/accounting"}>
            <ListItemIcon>
                <AttachMoneyIcon />
            </ListItemIcon>
            <ListItemText primary="Accounting" />
        </ListItem>
        <ListItem button title="Admin" component={Link} to={"/admin"}>
            <ListItemIcon>
                <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText>Admin</ListItemText>
        </ListItem>
        <ListItem button title="Manage Tags" component={Link} to={"/tags"}>
            <ListItemIcon>
                <LocalOfferIcon />
            </ListItemIcon>
            <ListItemText primary="Manage Tags" />
        </ListItem>
        <ListItem button title="Manage Specialty" component={Link} to={"/specialty"}>
            <ListItemIcon>
                <CategoryIcon />
            </ListItemIcon>
            <ListItemText primary="Manage Specialty" />
        </ListItem>
        <ListItem button title="Verifier" component={Link} to={"/verifier"}>
            <ListItemIcon>
                <FingerPrintIcon />
            </ListItemIcon>
            <ListItemText primary="Verifier" />
        </ListItem>
    </div>
);

export const privateListItems = (
    <ListItem button title="Team Stats" component={Link} to={"/team"}>
        <ListItemIcon>
            <InsertChartIcon />
        </ListItemIcon>
        <ListItemText primary="Team Stats" />
    </ListItem>
);

export default withRouter(mainListItems, secondaryListItems);
