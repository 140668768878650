// react imports
import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
// npm imports
import moment from "moment";
// MUI general imports
import { Button, TextField, Box, Checkbox, FormControlLabel } from "@mui/material";
// dialog imports
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
// custom imports
import FlashMessagev2 from "../../FlashMessagev2";

// npm imports
import axios from "axios";

const useStyles = makeStyles((theme) => ({
    custom: {
        color: "#000",
        fontSize: ".95rem",
        marginBottom: "3px",
    },
    note: {
        color: "#666",
        fontSize: ".85rem",
        marginTop: "15px",
        marginBottom: "12px",
    },
    auditData: {
        color: "#444",
        fontSize: ".85rem",
        marginBottom: "0",
    },
    override: {
        backgroundColor: "#dfdae9",
        padding: "10px",
        marginBottom: "12px",
        borderRadius: "3px",
    },
    currentCharges: {
        color: "#000000",
        fontWeight: "bold",
        marginBottom: "0",
        fontSize: "1.25rem",
    },
    missingData: {
        color: "red",
        fontWeight: "bold",
        marginBottom: "0",
    },
    required: {
        color: "#444",
        fontSize: ".85rem",
        marginBottom: "20px",
    },
    override_note: {
        color: "#666",
        fontSize: ".85rem",
        marginTop: "15px",
        marginBottom: "12px",
    },
    textfield: {
        marginBottom: "15px",
    },
    adminBtn: {
        marginBottom: "15px",
    },
    reasonBtn: {
        marginBottom: "8px",
    },
    strike: {
        color: "#888",
        fontWeight: "normal",
        textDecoration: "line-through",
    },
}));

const regex = /^\d{0,8}(\.\d{0,2})?$/;

const ExpertPaymentCell = (props) => {
    const { projectId, userId, getPricingInfo, projectData, row } = props;

    // row data destructurecd
    const {
        id,
        project_participant_id,
        rate_cap_approved,
        expert_notes,
        expert_payment_override,
        expert_note_by_fname,
        expert_note_by_lname,
        expert_notes_created_on,
        expert_charge_approved,
    } = row;

    // payment and duration info
    const expert_duration = row.expert_duration === "" ? row.expert_duration : "0";
    const expertPayment = row.expertPayment && row.expertPayment !== "N/A" ? `${row.expertPayment} USD` : "0";

    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [noteState, setNoteState] = useState("");
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [helperText, setHelperText] = useState("");
    const [flashError, setFlashError] = useState(false);
    const [flashErrorMsg, setFlashErrorMsg] = useState("");
    const [reasonsDisplay, setReasonsDisplay] = useState("block");
    const [paymentDisplay, setPaymentDisplay] = useState("none");
    const [durationDisplay, setDurationDisplay] = useState("none");
    const [rateCapDisplay, setRateCapDisplay] = useState("none");
    const [nextBtn, setNextBtn] = useState(true);
    const [submitBtn, setSubmitBtn] = useState(true);
    const [override, setOverride] = useState({
        payment: expert_payment_override === null ? "" : expert_payment_override,
        duration: expert_duration,
        rateCap: rate_cap_approved,
    });

    useEffect(() => {
        setNoteState(expert_notes ? expert_notes : "");
    }, [props]);

    // handle funcs
    const handleNote = (event) => {
        const { target } = event;
        const { value } = target;
        setNoteState(value);
        if (value.length > 3) {
            setNextBtn(false);
        } else {
            setNextBtn(true);
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
        setRateCapDisplay("none");
        setDurationDisplay("none");
        setPaymentDisplay("none");
        setReasonsDisplay("block");
    };

    const handleClose = () => {
        setNoteState(expert_notes ? expert_notes : "");
        setOpen(false);
        setNextBtn(true);
        setSubmitBtn(true);
        setRateCapDisplay("none");
        setPaymentDisplay("none");
        setDurationDisplay("none");
        setReasonsDisplay("block");
        // Reload the data on the page when modal closes...
        getPricingInfo(projectId);
    };

    const handleOverrideChange = (event) => {
        const { target } = event;
        const { value, name } = target;
        setError(false);
        setHelperText("");
        if (regex.test(value)) {
            setOverride({
                ...override,
                [name]: value,
            });
            if (value.length > 0) {
                setSubmitBtn(false);
            } else {
                setSubmitBtn(true);
            }
        }
    };

    const handleRateCap = (event) => {
        const { target } = event;
        const { name } = target;
        setOverride({ ...override, [name]: event.target.checked });
        console.log("RATE CAP CHECKED? ", event.target.checked);
        setSubmitBtn(false);
    };

    const handleReason = (event) => {
        const { target } = event;
        const { innerText } = target;
        console.log("REASON: ", innerText);
        setNoteState(innerText);
        setNextBtn(false);
    };

    const saveNotes = async () => {
        const postObj = {
            note: noteState,
            callId: project_participant_id,
            person_id: userId,
        };
        console.log("Did this Work?", postObj);
        await axios
            .post("/api/finance/updateOverrideExpertNote", postObj)
            .then((result) => {
                setPaymentDisplay("block");
                setReasonsDisplay("none");
                setRateCapDisplay("none");
                setNextBtn(true);
                // getPricingInfo(projectId);
            })
            .catch((err) => {
                console.log(err, ":: there was an error updating the note");
                // setErrorMsg(err.response.data.message);
                // setError(true);
            });
    };

    const updateExpertCallDuration = async (durationNum) => {
        console.log("updateExpertCallDuration :: ", durationNum);
        await axios
            .post(
                "/api/project/call/updateExpertDuration",
                {
                    call_id: project_participant_id,
                    expert_call_duration: durationNum,
                    person_id: userId,
                    expert_id: id,
                    project_id: projectId,
                },
                {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            )
            .then((result) => {
                if (result.status === 200) {
                    handleClose();
                    console.log(result, " :: result coming back from duration update");
                    // getPricingInfo(projectId);
                }
            })
            .catch((error) => {
                alert("SOMETHING WENT WRONG UPDATING THE CALL DURATION :: ", error);
                console.log("SOMETHING WENT WRONG UPDATING CALL DURATION :: ", error);
            });
    };

    const updateRateCapApproval = async (rateCapApproved) => {
        const postObj = {
            rateCapApproved,
            projectId,
            expertId: id,
        };
        console.log(postObj, " :: Submit Override Rate Cap Approval");
        await axios
            .post("/api/project/updateRateCapApproval", postObj)
            .then((result) => {
                handleClose();
                console.log(result, " :: result coming back from override rate cap approval");
            })
            .catch((err) => {
                console.log(err.response.data.message, " :: err.response.data.message err");
                setFlashErrorMsg(err.response.data.message);
                setFlashError(true);
            });
    };

    const updatePaymentOverride = async (overrideNumber) => {
        const postObj = {
            userId,
            callId: project_participant_id,
            overrideNumber,
        };
        console.log(postObj, " :: Submit Override Payment Amount");
        await axios
            .post("/api/finance/updatePaymentOverride", postObj)
            .then((result) => {
                handleClose();
                console.log(result, " :: result coming back from override update");
                // getPricingInfo(projectId);
            })
            .catch((err) => {
                console.log(err.response.data.message, " :: err.response.data.message err");
                setFlashErrorMsg(err.response.data.message);
                setFlashError(true);
            });
    };

    const submitPaymentOverride = () => {
        if (override.payment !== "" && noteState !== "") {
            console.log("SUBMIT OVERRIDE PAYMENT AMOUNT: ", override.payment);
            updatePaymentOverride(override.payment);
        } else {
            setError(true);
            setHelperText("Cannot Be Empty");
        }
    };

    const submitDuration = () => {
        if (override.duration !== "" && noteState !== "") {
            console.log("SUBMIT DURATION: ", override.duration);
            updateExpertCallDuration(override.duration);
        } else {
            setError(true);
            setHelperText("Cannot Be Empty");
        }
    };

    const submitRateCapOverride = () => {
        if (noteState !== "") {
            console.log("SUBMIT RATE CAP OVERRIDE: ", override.rateCap);
            updateRateCapApproval(override.rateCap);
        } else {
            setError(true);
            setHelperText("Must give a reason to override this.");
        }
    };

    const showDurationDisplay = () => {
        setRateCapDisplay("none");
        setPaymentDisplay("none");
        setReasonsDisplay("none");
        setDurationDisplay("block");
    };

    const showPaymentDisplay = () => {
        setRateCapDisplay("none");
        setDurationDisplay("none");
        setReasonsDisplay("none");
        setPaymentDisplay("block");
    };

    const showRateCapDisplay = () => {
        setDurationDisplay("none");
        setReasonsDisplay("none");
        setPaymentDisplay("none");
        setRateCapDisplay("block");
    };

    return (
        <div>
            <Box>
                {!expert_notes ? (
                    <Button
                        fullWidth
                        className={classes.adminBtn}
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={handleClickOpen}
                        disabled={expert_charge_approved}
                    >
                        Admin Override
                    </Button>
                ) : (
                    <>
                        <Button
                            fullWidth
                            disabled={expert_charge_approved}
                            size="small"
                            variant="outlined"
                            color="primary"
                            onClick={handleClickOpen}
                        >
                            Modify Changes
                        </Button>
                        <br />
                        <p className={classes.note}>
                            <strong>REASON:</strong> {expert_notes}
                        </p>
                        {expert_notes_created_on && (
                            <div className={classes.override}>
                                <p
                                    className={classes.auditData}
                                >{`By: ${expert_note_by_fname} ${expert_note_by_lname}`}</p>
                                <p className={classes.auditData}>{`${moment(expert_notes_created_on).format(
                                    "dddd, MMMM Do, YYYY @ h:mma"
                                )}`}</p>
                            </div>
                        )}
                    </>
                )}
                {expertPayment !== "0" || expert_payment_override !== null ? (
                    <p className={classes.currentCharges}>
                        {expert_payment_override ? (
                            <strong>
                                <span className={classes.strike}>{expertPayment !== "0" ? expertPayment : ""}</span>{" "}
                                {`${expert_payment_override} USD`}
                            </strong>
                        ) : (
                            <strong>{expertPayment}</strong>
                        )}
                    </p>
                ) : (
                    <p className={classes.missingData}>
                        <em>{"UNABLE TO CALCULATE PAYMENT"}</em>
                    </p>
                )}
            </Box>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Edit Overrides</DialogTitle>
                <DialogContent>
                    <Box display={reasonsDisplay}>
                        <h2 className={classes.required}>
                            * Required - Explain why you need to change the payment/duration
                        </h2>
                        <Button
                            fullWidth
                            size="small"
                            variant="outlined"
                            color="primary"
                            className={classes.reasonBtn}
                            onClick={handleReason}
                        >
                            DURATION CHANGE: Expert stayed on too long...
                        </Button>
                        <Button
                            fullWidth
                            size="small"
                            variant="outlined"
                            color="primary"
                            className={classes.reasonBtn}
                            onClick={handleReason}
                        >
                            PAYMENT CHANGE: Settlement or dispute resolution...
                        </Button>
                        <Button
                            fullWidth
                            size="small"
                            variant="outlined"
                            color="primary"
                            className={classes.reasonBtn}
                            onClick={handleReason}
                        >
                            LEADERSHIP DECISION: Please explain...
                        </Button>
                        <Button
                            fullWidth
                            size="small"
                            variant="outlined"
                            color="primary"
                            className={classes.reasonBtn}
                            onClick={handleReason}
                        >
                            CORRECTION: Incorrect data along the way...
                        </Button>
                        <Button
                            fullWidth
                            size="small"
                            variant="outlined"
                            color="primary"
                            className={classes.reasonBtn}
                            onClick={handleReason}
                        >
                            APPROVE/DECLINE: Fair Market Value Rate Cap
                        </Button>
                        <TextField
                            className={classes.textfield}
                            autoFocus
                            margin="dense"
                            value={noteState}
                            onChange={handleNote}
                            fullWidth
                            maxRows={4}
                            label="Please enter your reason..."
                        />
                        <Button disabled={nextBtn} onClick={saveNotes} color="primary">
                            Next
                        </Button>
                    </Box>
                    <Box display={paymentDisplay}>
                        <h2 className={classes.required}>* Are you sure you want to edit the payment?</h2>
                        <TextField
                            className={classes.textfield}
                            value={override.payment}
                            fullWidth
                            name="payment"
                            error={error}
                            helperText={helperText}
                            onChange={handleOverrideChange}
                            label="Enter Payment Amount"
                        />
                        <Button disabled={submitBtn} onClick={submitPaymentOverride} color="primary">
                            Save Payment
                        </Button>
                        <Button onClick={showDurationDisplay} color="primary">
                            Duration
                        </Button>
                        {projectData.fair_market_value > 0 && (
                            <Button onClick={showRateCapDisplay} color="primary">
                                Rate Cap
                            </Button>
                        )}
                    </Box>
                    <Box display={durationDisplay}>
                        <h2 className={classes.required}>* Are you sure you want to edit the duration?</h2>
                        <TextField
                            className={classes.textfield}
                            value={override.duration}
                            fullWidth
                            name="duration"
                            error={error}
                            helperText={helperText}
                            onChange={handleOverrideChange}
                            label="Enter Duration (minutes)"
                        />
                        <Button disabled={submitBtn} onClick={submitDuration} color="primary">
                            Save Duration
                        </Button>
                        <Button onClick={showPaymentDisplay} color="primary">
                            Payment
                        </Button>
                        {projectData.fair_market_value > 0 && (
                            <Button onClick={showRateCapDisplay} color="primary">
                                Rate Cap
                            </Button>
                        )}
                    </Box>
                    <Box display={rateCapDisplay}>
                        <h2 className={classes.required}>* Are you sure you want to override the rate cap?</h2>
                        <div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={override.rateCap}
                                        inputProps={{
                                            "aria-label": "Approve FMV Rate Cap",
                                        }}
                                        name="rateCap"
                                        onChange={handleRateCap}
                                        value={override.rateCap}
                                    />
                                }
                                label="Approve FMV Rate Cap"
                            />
                        </div>
                        <Button disabled={submitBtn} onClick={submitRateCapOverride} color="primary">
                            Override Rate Cap
                        </Button>
                        <Button onClick={showPaymentDisplay} color="primary">
                            Payment
                        </Button>
                        <Button onClick={showDurationDisplay} color="primary">
                            Duration
                        </Button>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            {error && <FlashMessagev2 error={error} severity={"error"} message={errorMsg} />}
        </div>
    );
};

export default ExpertPaymentCell;
