import React, { useState } from "react";
import Button from "@mui/material/Button";
import axios from "axios";
import SnippetDialog from "./SnippetDialog";

/**
 *
 * How to use this component:
 *
 *  <ExpertSnippet
 *      size="small|medium|large"
 *      expertList={[array of expertId's]}
 *      fullWidth={true|false}
 *      styles={{style1: "style1", style2: "style2"...}}
 *  />
 *
 * - size controls the size of the button
 * - expertList is the list of expert id's in an array
 * - fullWidth allows full width of the button (material-ui prop)
 * - the custom styles for the button
 *
 */

const ExpertSnippet = (props) => {
    // console.log(props, " :: props ExpertSnippet");
    const [expertData, setExpertData] = useState([]);
    const [haveExpertsLoaded, setHaveExpertsLoaded] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    // console.log(props);
    const handleSnippet = () => {
        axios
            .post(
                "/api/expert/expertSnippet",
                { expertList: props.expertList },
                {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            )
            .then((result) => {
                if (result.data.data.length > 0) {
                    console.log(result.data.data, " :: result.data.data in expertsnippet");
                    setExpertData(result.data.data);
                    setOpenDialog(true);
                    setHaveExpertsLoaded(true);
                }
            })
            .catch((err) => {
                console.log(err, " :: something wrong with expertSnippet API call");
            });
    };

    const handleDialogClose = () => {
        setExpertData([]);
        setHaveExpertsLoaded(false);
        setOpenDialog(false);
    };

    return (
        <div>
            <Button
                sx={{ ...props.sx, fontSize: "0.65rem" }}
                onClick={handleSnippet}
                variant="contained"
                color="primary"
                fullWidth={props.fullWidth}
                size={props.size ? props.size : "regular"}
                style={{ ...props.styles }}
                // sx={{...props.sx}}
                // disabled={props.expertList.length > 0 && props.expertList.length < 101 ? false : true}
            >
                {"Generate Snippet"}
            </Button>
            {haveExpertsLoaded && (
                <SnippetDialog openDialog={openDialog} handleDialogClose={handleDialogClose} expertData={expertData} />
            )}
        </div>
    );
};

export default ExpertSnippet;
