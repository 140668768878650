import React, { useState, useEffect, useCallback, useContext } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import MarkAsSentToClientDialog from "../components/MarkAsSentToClientDialog";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import axios from "axios";
import Select from "react-select";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import "moment-duration-format";
import PageTitle from "../components/PageTitle";
import FlashMessage from "../components/FlashMessagev3";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import loader from "../img/loader-simple.gif";
import { authContext } from "../context/auth";
import { isEmptyOrSpaces, numberFormat, removeNewLines } from "../Utils";
const MONTH = {
    JANUARY: 0,
    FEBRUARY: 1,
    MARCH: 2,
    APRIL: 3,
    MAY: 4,
    JUNE: 5,
    JULY: 6,
    AUGUST: 7,
    SEPTEMBER: 8,
    OCTOBER: 9,
    NOVEMBER: 10,
    DECEMBER: 11,
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    backdrop: {
        zIndex: 9999,
        color: "#fff",
    },
    timestamp: {
        fontSize: ".5rem",
        color: "#555",
        textAlign: "left",
        marginBottom: "5px",
    },
    label: {
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(0.5),
        fontSize: "1rem",
    },
    text: {
        fontSize: ".75rem",
        color: "#333",
        textAlign: "left",
    },
    form: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "fit-content",
    },
    typography: {
        padding: theme.spacing(2),
    },
    nameButton: {
        color: "#3c8864",
        padding: "0",
        textDecoration: "none",
        fontSize: "1.25rem",
        fontWeight: "normal",
        textTransform: "none",
        minWidth: "auto",
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    warningCopy: {
        color: "red",
        fontSize: ".85rem",
        fontWeight: "bold",
        marginTop: "0",
    },
    datestamp: {
        color: "#666",
        fontSize: ".65rem",
    },
    emailBorder: {
        border: "1px solid #e5e5e5",
        backgroundColor: "#f8f8f8",
        borderRadius: "2px",
        padding: "12px",
        fontSize: ".75rem",
    },
    emailOutline: {
        border: "1px solid #eaeaea",
        backgroundColor: "#ffffff",
        borderRadius: "2px",
        padding: "8px",
    },
    editorWrapper: {
        border: "1px solid #EAEAEA",
        borderRadius: "2px",
        padding: "12px",
        minHeight: "500px",
    },
    recipients: {
        overflow: "hidden",
    },
    to_email: {
        float: "left",
        marginRight: "3px",
    },
    emails: {
        fontSize: ".55rem",
        backgroundColor: "#fff",
        borderRadius: "3px",
        border: "1px solid #e5e5e5",
        padding: "1px 2px",
        marginRight: "3px",
        marginBottom: "3px",
        float: "left",
    },
    iconSmall: {
        fontSize: ".95rem",
    },
    actions: {
        textAlign: "Center",
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function ProjectExperts(props) {
    const now = moment();
    const { match, history } = props;
    const pageUrl = props.location.search;
    const params = queryString.parse(pageUrl);
    const classes = useStyles();

    const { auth } = useContext(authContext);

    const [open, setOpen] = useState(false);
    const [activeBtn, setActiveBtn] = useState(true);
    const [disableDone, setDisableDone] = useState(false);
    // const [editor, setEditorState] = useState(EditorState.createEmpty());
    const [preview, setPreview] = useState("none");
    const [editable, setEditable] = useState("block");
    const [tabValue, setTabValue] = useState(0);
    // new editor stuff
    const [newTabValue, setNewTabValue] = useState(0);
    const [editorState, setEditorState] = useState({});
    const [loadingEditor, setLoadingEditor] = useState(true);
    const [expertState, setExpertState] = useState([]);
    const [surveyTemplateState, setSurveyTemplateState] = useState([]);
    const [templateType, setTemplateType] = useState("");
    // end new editor stuff
    const [openLoader, setOpenLoader] = useState(true);
    const [contacts, setClientsContacts] = useState([]);
    const [csvData, setCsvData] = useState({});
    const [loadingCsvData, setLoadingCsvData] = useState(true);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    // const [dialogData, setDialogData] = useState(new Map([]));
    const [sentToClientData, setSentToClientData] = useState({});
    const [loadingDialog, setLoadingDialog] = useState(true);
    const [openFlashMessage, setOpenFlashMessage] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    // const [screenerId, setScreenerId] = useState(null);
    const [data, setData] = useState({
        id: props.match.params.id,
        isdeleted: false,
        title: "",
        description: "",
        email_copy: "",
        project_not_received_by_email: true,
        client_id: "",
        client_name: "",
        primary_client_contact: "",
        additional_client_contacts: [],
        expert_ids: [],
        primary_project_manager: [],
        additional_project_managers: [],
        status: "1",
        type: "",
        priority: "4",
        case_code: "",
        calls_planned: "",
        industry: "",
        conference_bridge_setting: "1",
        twilio_bridge_setting: "1",
        external_bridge_numbers: [],
        notifications: [],
        specialty: [],
        tags: [],
        comments: [],
        responses: [],
        scheduled_calls: [],
        completed_calls: [],
        experts: [],
        custom_charge: 0,
        custom_hourly_rate: 1.0,
        enable_csv_download: false,
        blind_csv: false,
    });
    const [template, setEmailTemplate] = useState({
        project_title: data.title,
        body: `<div style="text-align:center;"><img src=${loader} border="0" width="70" /></div>`,
        prescreen: "",
        postscreen: "",
        prescreen_angles: "",
        postscreen_angles: "",
    });

    const [client, setClientEmail] = useState({
        survey_id: "",
        client_id: data.client_id,
        project_title: data.title,
        project_id: data.id,
        user_id: auth.data.id,
        user_fname: auth.data.fname,
        user_lname: auth.data.lname,
        from_email: auth.data.mail,
        email_subject: `Sending Over Experts for ${data.title} (ID-${data.id})`,
        to_email: [],
        cc_email: "adminresearch@firstthought.io",
        email_body: "",
        email_template: "prescreen",
        experts: [],
        unique_id: moment(now).format("x"),
    });

    // const [self, setSendToSelf] = useState(false);
    // const [fetchedExperts, setFetchedExperts] = useState([]);
    const fetchedExperts = [];

    function getClientOrgList(id) {
        axios
            .get(`/api/client/find?id=${id}`, {
                email: "aanand@email.com",
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    const items = result.data.data[3];
                    const contactEmails = items.filter((item) => item.email);
                    const options = contactEmails.map((item) => {
                        return {
                            value: item.email,
                            label: item.name,
                        };
                    });
                    // console.log("CONTACTS WITH EMAILS: ", options);
                    setClientsContacts(options);
                }
            });
    }

    const handleTemplateCopy = () => {
        const blobInput = new Blob([buildTemplate(newTabValue)], { type: "text/html" });
        const clipboardItemInput = new ClipboardItem({ "text/html": blobInput });

        // const blobInput = new Blob([buildTemplate(newTabValue)], { type: "text/plain" });
        // const clipboardItemInput = new ClipboardItem({ "text/plain": blobInput });

        navigator.clipboard.write([clipboardItemInput]);

        setSnackbarMessage("Successfully copied template!");
        setOpenSnackbar(true);
        // }
    };

    const handleCopyIconClick = () => {
        navigator.clipboard.writeText(csvData.downloadUrl);
        // handleOpenSentToClientDialog(true);
    };

    const handleOpenSentToClientDialog = () => {
        if (!loadingDialog && params.onRespondentsPage) {
            setIsDialogOpen(true);
        }
    };

    function handleClose() {
        setOpen(false);
    }

    function handleChange(event) {
        const { target } = event;
        const { value, name } = target;
        setClientEmail({
            ...client,
            [name]: value,
        });
    }

    function formatDateString(str) {
        return moment(str).format("dddd, MMMM. Do, YYYY, h:mma");
    }
    function formatPhoneNumber(str) {
        if (str !== "" && str.length > 9) {
            const cleaned = ("" + str).replace(/\D/g, "");
            const formatted = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
            return `(${formatted[1]}) ${formatted[2]}-${formatted[3]}`;
        }
        return "<span style='color:red;'>** MISSING PHONE NUMBER **</span>";
    }

    function handleTabChange(event, newValue) {
        setTabValue(newValue);
    }

    function handleNewTabChange(event, newValue) {
        setNewTabValue(newValue);
    }

    const createCSVDownloadBtn = (projectId, expertArray, isCsvEnabled, downloadUrl) => {
        let html = ``;

        if (projectId && expertArray.length > 0 && isCsvEnabled && params.onRespondentsPage) {
            html += `
                <br/>
                <br/>
                <a href="${downloadUrl}">
                <!--[if mso]>
                    <i style='letter-spacing: 25px; mso-font-width: -100%; mso-text-raise: 30pt;'>&nbsp;</i>
                <![endif]-->
                    <span style='mso-text-raise: 15pt;'>Download Workbook of All Experts Sent</span>
                <!--[if mso]>
                    <i style='letter-spacing: 25px; mso-font-width: -100%;'>&nbsp;</i>
                <![endif]-->
                </a>
            `;
            return html;
        } else {
            return html;
        }
    };

    const buildDownloadUrl = (btnObj) => {
        const { projectId, expertArray, expertSurveyHashArray, projectTitle, isCsvBlind, isCsvEnabled } = btnObj;

        const safeProjectTitle = projectTitle ? projectTitle : "N/A";

        const userId = auth.data.id;

        if (
            projectId &&
            expertArray.length > 0 &&
            expertSurveyHashArray.length > 0 &&
            isCsvEnabled &&
            params.onRespondentsPage
        ) {
            const expertArrayString = expertArray.join(",");
            const expertSurveyHashArrayString = expertSurveyHashArray.join(",");

            const urlString = `https://firstthought.io/clients?projectId=${encodeURIComponent(
                projectId
            )}&expertArray=${encodeURIComponent(expertArrayString)}&justSentHashArray=${encodeURIComponent(
                expertSurveyHashArrayString
            )}&userId=${encodeURIComponent(userId)}&isCsvBlind=${encodeURIComponent(
                isCsvBlind
            )}&projectTitle=${encodeURIComponent(safeProjectTitle)}`;

            // console.log(urlString, " :: urlString");

            return urlString;
        } else {
            return "N/A";
        }
    };

    // function selectText(containerid) {
    //     if (document.selection) {
    //         // IE
    //         const range = document.body.createTextRange();
    //         range.moveToElementText(document.getElementById(containerid));
    //         range.select();
    //     } else if (window.getSelection) {
    //         const range = document.createRange();
    //         range.selectNode(document.getElementById(containerid));
    //         window.getSelection().removeAllRanges();
    //         window.getSelection().addRange(range);
    //     }
    // }
    // function prescreenCopy(title, pcc) {
    //     let html = `<p>Hi ${pcc.firstname},</p>`;
    //     html += "<p>";
    //     html += `I hope all is well.  Below is a preview of experts who may be a good fit for your project on <strong>${title} (ID-${data.id})</strong> based on the profile information we’ve gathered thus far.  Please let us know if these profiles look to be on track with the type of expert you were looking to speak with, and if there are any specific experts from this list who you’d like us to screen to gather their availability to consult on this project.`;
    //     html += "<br />";
    //     html += "</p>";
    //     return html;
    // }

    // function postscreenCopy(title, pcc) {
    //     let html = `<p>Hi ${pcc.firstname},</p>`;
    //     html += "<p>";
    //     html += `<strong>[INSERT PROJECT UPDATES OR A QUICK DESCRIPTION OF ANYTHING NOTEWORTHY REGARDING THE EXPERTS BELOW]</strong>.`;
    //     html += "</p>";
    //     return html;
    // }

    // ====================
    // FORMATTING FUNCTIONS
    // ====================

    // format the city for the template
    const formatCity = (city, formattingArray) => {
        if (!!city) {
            // remove curlies if they exist
            const cityNoCurlies = city.replace(/[{}]/g, "");

            // make sure not empty at this point
            if (!!cityNoCurlies) {
                formattingArray.push(cityNoCurlies);
            }
        }
    };

    // format the location (city is special case)
    // join array to create the string based on
    // whether or not the location is empty
    const formatLocation = (city, state, country) => {
        const formattingArray = [];

        formatCity(city, formattingArray);

        !!state ? formattingArray.push(state) : null;
        !!country ? formattingArray.push(country) : null;

        if (formattingArray.length > 0) {
            return formattingArray.join(", ");
        } else {
            return "";
        }
    };

    // ================
    // HELPER FUNCTIONS
    // ================
    // grab all survey names passing in a string of survey id's
    const fetchSurveyNamesFromIds = async (surveyIdStr) => {
        const {
            data: { surveyNames: surveyData },
        } = await axios.get(`/api/survey/getSurveyNamesFromIds?surveyIds=${surveyIdStr}`);

        return surveyData;
    };

    // the angle has a different structure depending
    // on the template type
    const renderAngleText = (angle, templateType) => {
        if (templateType === "prescreen") {
            return angle;
        } else if (templateType === "postscreen") {
            return angle.title;
        }
    };

    const templateClassSwitch = (tabIndex, needMsoVersion) => {
        // PRESCREEN
        // =========
        // renderExpertBio()
        // renderSignature()
        // renderLandingPageLink()

        // PRESCREEN ANGLES
        // ================
        // renderPreScreenHeader()
        // renderExpertAngles("prescreen"|"postscreen")
        // renderSignature()
        // renderLandingPageLink()

        // POSTSCREEN
        // ==========
        // renderExpertInfoWithScreeners(true|false)
        // renderSignature()
        // renderLandingPageLink();

        // POSTSCREEN ANGLES
        // =================
        // renderExpertAngles("prescreen"|"postscreen")
        // renderSignature()
        // renderLandingPageLink()

        let html = "";

        switch (tabIndex) {
            case 0: {
                // PRESCREEN
                html += renderExpertBio(needMsoVersion);
                html += `<br />`;
                html += renderSignature();
                if (renderLandingPageLink()) {
                    html += renderLandingPageLink();
                }

                return html;
            }
            case 1: {
                // PRESCREEN ANGLES
                html += renderPrescreenHeader();
                html += `<br />`;
                html += renderExpertAngles("prescreen", needMsoVersion);
                html += `<br />`;
                html += renderSignature();
                if (renderLandingPageLink()) {
                    html += renderLandingPageLink();
                }

                return html;
            }
            case 2: {
                // POSTSCREEN
                html += renderExpertInfoWithScreeners(needMsoVersion);
                html += `<br />`;
                html += renderSignature();
                if (renderLandingPageLink()) {
                    html += renderLandingPageLink();
                }

                return html;
            }
            case 3: {
                // POSTSCREEN ANGLES
                html += renderExpertAngles("postscreen", needMsoVersion);
                html += `<br />`;
                html += renderSignature();
                if (renderLandingPageLink()) {
                    html += renderLandingPageLink();
                }

                return html;
            }
        }
    };

    const buildTemplate = (tabIndex) => {
        const templateMso = templateClassSwitch(tabIndex, true);
        const template = templateClassSwitch(tabIndex, false);

        const templateSkeleton = `
            <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
            <html xmlns="http://www.w3.org/1999/xhtml">
                <head>
                    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
                    <title></title>
                </head>

                <body>
                    <!--[if mso]>
                        <div style="font-size: 12pt; font-family: arial, sans-serif;">
                            ${templateMso}
                        </div>
                        <![endif]-->
                    <!--[if !mso]> <!---->
                        <div style="font-size: 12px; font-family: arial, sans-serif;">
                            ${template}
                        </div>
                    <![endif]-->
                </body>
            </html>
        `;

        return templateSkeleton;
    };

    // not sure what this does exactly
    // copied from previous email template
    function normalizeHeadline(str) {
        const at = "at";
        const regex = new RegExp(`\\b${at}\\b`);
        const headline = str.trim();
        let newheadline = "";
        if (headline !== "" && headline.search(regex) !== -1) {
            newheadline = headline.replace(regex, ", ");
        } else {
            newheadline = headline;
        }
        return newheadline;
    }

    // remove html from tags using the dom
    function stripHtml(html) {
        // Create a new div element
        const temporalDivElement = document.createElement("div");
        // Set the HTML content with the providen
        temporalDivElement.innerHTML = html;
        // Retrieve the text property of the element (cross-browser support)
        return temporalDivElement.textContent || temporalDivElement.innerText || "";
    }

    // =======================
    // HELPER RENDER FUNCTIONS
    // =======================

    // this returns the properly rendered employment history
    // which is mainly used for the expert screener data
    // most of the actual logic is copied from the old template data
    // i just made it more modular
    const renderEmploymentHistory = (headline, currentPosition, currentCompany, employmentDuration) => {
        let html = "";

        if (currentPosition && currentPosition !== "Unavailable") {
            html += `
                <div>
                    Current Title: <strong>${currentPosition}</strong>
                </div>
            `;
        } else {
            html += `
                <div>
                    Current Title: <strong>${headline.split(",")?.[0]}</strong>
                </div>
            `;
        }

        if (currentCompany && currentCompany !== "Unavailable") {
            html += `
                <div>
                    Current Employer: <strong>${currentCompany}</strong>
                </div>
            `;
        } else {
            html += `
                <div>
                    Current Employer: <strong>${headline.split(",")?.[1]}</strong>
                </div>
            `;
        }

        if (employmentDuration) {
            html += `
                <div>
                    Duration: <strong>${employmentDuration}</strong>
                </div>
            `;
        }

        return html;
    };

    const renderScreenerQuestions = (screenerAnswers) => {
        let html = "";
        let finalQuestionHtml = "";

        // loop through outer screeners
        screenerAnswers.forEach((surveyAnswer, index) => {
            const { text, answers, comments } = surveyAnswer;

            // format screener answer labels by removeing html and numbering
            // the reason for the if statement is
            // the last question is the one that asks about the experts schedule
            // that has to come first, so we're removing the numbering
            // and adding it to the front of the array
            if (index === screenerAnswers.length - 1) {
                finalQuestionHtml += `<div>${removeNewLines(stripHtml(text))}</div>`;
            } else {
                html += `
                    <br />
                    <div>
                        ${index + 1}.) ${removeNewLines(stripHtml(text))}
                    </div>
                `;
            }

            // i think here we're filtering out answers w/ bad
            // data
            const questionAnswers = answers.filter((question) => {
                const { user_answer: userAnswer } = question;

                return !!userAnswer;
            });

            // these filters select which type of question it is
            // and will determine formatting
            const singleFreeText = questionAnswers.filter((questionAnswer) => {
                const { label, user_answer: userAnswer } = questionAnswer;
                // console.log("WHAT TYPE? ", questionAnswer);
                return isEmptyOrSpaces(label) && userAnswer !== "";
            });

            // push the formatted question into the array
            singleFreeText.forEach((answer) => {
                const { user_answer: userAnswer } = answer;
                if (index === screenerAnswers.length - 1) {
                    finalQuestionHtml += `
                    <div>
                        <strong>${stripHtml(userAnswer)}</strong>
                    </div>
                `;
                } else {
                    html += `
                    <div>
                        <strong>${stripHtml(userAnswer)}</strong>
                    </div>
                `;
                }
            });

            // likeart quesiton
            const multiFreeTextLikert = questionAnswers.filter((questionAnswer) => {
                const { label, user_answer: userAnswer } = questionAnswer;

                return !isEmptyOrSpaces(label) && userAnswer !== "" && userAnswer !== "true";
            });

            // add likeart
            multiFreeTextLikert.forEach((answer, index) => {
                const { label, user_answer: userAnswer } = answer;

                html += `
                    <div>
                        <em>${stripHtml(label)} </em><strong>${stripHtml(userAnswer)}</strong>
                        <br />
                    </div>
                `;
            });

            // multiple choice question
            const multiChoice = questionAnswers.filter((questionAnswer) => {
                const { label, user_answer: userAnswer } = questionAnswer;

                return label !== "" && userAnswer !== "" && userAnswer === "true";
            });

            // add multiple choice
            multiChoice.forEach((answer) => {
                // console.log(answer, " :: answer");
                const { followupquestion: followupQuestion, label } = answer;

                html += `
                    <div>
                        <strong>${stripHtml(label)}</strong>
                    </div>
                `;

                // console.log(followupQuestion, " :: followupQuestion");

                // console.log(followupQuestion?.length > 0);
                // console.log(followupQuestion?.[0]?.text);
                // console.log(followupQuestion?.[0]?.answers?.[0]?.user_answer);
                // followup question data
                if (
                    followupQuestion?.length > 0 &&
                    !!followupQuestion?.[0]?.text &&
                    followupQuestion?.[0]?.answers?.[0]?.user_answer
                ) {
                    html += `
                        <br />
                        <div>
                            ${stripHtml(followupQuestion?.[0]?.text)}
                        </div>
                        <div>
                            <strong>
                                ${removeNewLines(stripHtml(followupQuestion?.[0]?.answers?.[0]?.user_answer))}
                            </strong>
                        </div>
                    `;
                }
            });

            // add comments if they exist
            if (!!comments) {
                html += `
                    <div>
                        <em>Comments: </em><strong>${comments}</strong>
                    </div>
                `;
            }
        });

        // reorganize string so that final question comes first
        return `${finalQuestionHtml}${html}`;
    };

    // ==========================
    // MAIN TEMPLATE RENDER FUNCS
    // ==========================

    // these are the main structural renders for the different templates
    // br is being used over margin, to keep the copy paste into outlook consistent
    function renderPrescreenHeader() {
        const {
            primary_client_contacts: [{ firstname }],
            project_title: title,
        } = editorState;

        return `
            <div>
                Hi ${firstname},
            </div>
            <br />
            <div>
                I hope all is well. Below is a preview of experts who may be a good fit for your project on <strong> ${title} (ID-${data.id}) </strong> based on the profile information we’ve gathered thus far. Please let us know if these profiles look to be on track with the type of expert you were looking to speak with, and if there are any specific experts from this list who you’d like us to screen to gather their availability to consult on this project.
            </div>
        `;
    }

    // this renders expert info if exists
    // or default no expert message
    function renderExpertInfoWithScreeners(needMsoVersion) {
        if (expertState.length > 0) {
            return renderExpertInfo(needMsoVersion);
        }
        return `
            <div>
                <em>-- No Experts Selected --</em>
            </div>
        `;
    }

    function renderExpertInfo(needMsoVersion, filteredExperts = null) {
        let html = "";
        let experts;

        // checking if we're in the angle template
        // or regular one
        if (filteredExperts) {
            experts = filteredExperts;
        } else {
            experts = expertState;
        }

        html += `<div>`;

        experts.map((expert, index) => {
            const {
                headline: headlineStr,
                fname,
                lname,
                suffix,
                pricingUnit,
                current_position: currentPosition,
                current_company: currentCompany,
                employment_duration: employmentDuration,
                city,
                state,
                country,
                client_id: clientId,
                consultation_rate: consultationRate,
                custom_hourly_rate: customHourlyRate,
                consultation_rate_currency: currencyType,
                relevant_notes: relevantNotes,
                survey_answers: surveyAnswers,
                biography,
            } = expert;

            const headline = normalizeHeadline(headlineStr);

            html += `<div>`; // expert top section

            if (needMsoVersion) {
                html += `
                    <div style="font-size: 16pt;">
                        <strong>
                            <span style="color: #6F4698;">${index + 1}. ${fname} ${lname}${
                    suffix ? `, ${suffix}` : ""
                }</span>
                `;
            } else {
                html += `
                    <div style="font-size: 16px;">
                        <strong>
                            <span style="color: #6F4698;">${fname} ${lname}${suffix ? `, ${suffix}` : ""}</span>
                `;
            }

            if (
                pricingUnit > 1 &&
                (isNaN(data.custom_hourly_rate) || parseFloat(data.custom_hourly_rate) === 1.0) &&
                (data.custom_charge === null || parseInt(data.custom_charge, 10) === 0)
            ) {
                html += `
                        <span style="color: #1155cc;">
                            (Premium Expert - ${pricingUnit}x)
                        </span>
                    `;
            } else if (data.custom_charge > 0 || data.custom_hourly_rate > 1.0) {
                // IF COST PLUS MODEL, SHOW THE RATE
                html += `
                    <span style="color: green;">(Expert Fee: $${numberFormat(consultationRate)}/hr)</span>
                `;
            }

            html += `
                    </strong>
                </div>`;

            if (!!headline) {
                html += renderEmploymentHistory(headline, currentPosition, currentCompany, employmentDuration);
            } else {
                html += `
                    <div style="color: red;">EMPLOYMENT MISSING</div>
                `;
            }
            if (city !== "NA" && formatLocation(city, state, country) !== "") {
                html += `
                    <div>Location: <strong>${formatLocation(city, state, country)}</strong></div>
                `;
            }

            html += `</div>`; // closing expert name div

            // SPACE BETWEEN LOCATION AND NOTES
            html += `<br />`;

            // START NOTES
            html += `
                <div>
                    <span><strong>Relevant Notes: </strong></span>
            `;

            if (relevantNotes) {
                html += `<strong style="background-color: yellow; color: #000000;">${relevantNotes}</strong>`;
            } else {
                html += `
                    <strong style="background-color: yellow; color: #000000;">Customize your response for ${fname} here...</strong>
                `;
            }

            // END OF NOTES & SPACE BEFORE SCREENING Q'S
            html += `
                </div>
                <br />
            `; // close relevant notes div

            if (surveyAnswers?.length > 0) {
                html += `
                <div>
                    <strong><u>Screening Questions</u></strong>
            `;

                html += renderScreenerQuestions(surveyAnswers);

                html += `
                </div>
                <br />
            `; // close screener questions
            }

            html += `
                <div>
                    <strong><u>About ${fname}: </u></strong>
                    <br />
            `;

            // remove html
            const strippedBio = stripHtml(biography);

            // check if bio is empty or filled w/ only whitespace
            if (!!strippedBio.replace(/\s/g, "")) {
                html += `${stripHtml(biography)}`;
            } else {
                html += `--[Enter Bio]--`;
            }

            html += `</div>`; // bio div

            if (expertState.length - 1 !== index) {
                html += `<br />`;
            }
        });

        html += `</div>`; // main div

        return html;
    }

    // expert bios
    function renderExpertBio(needMsoVersion) {
        if (expertState.length > 0) {
            return expertBio(false, needMsoVersion);
        }
        return `
            <div>
                <em>-- No Experts Selected --</em>
            </div>
        `;
    }

    function expertBio(inAngleTemplate, needMsoVersion, filteredExperts = null) {
        let html = "";
        let experts;

        // checking if we're in the angle template
        // or the regular template
        if (filteredExperts) {
            experts = filteredExperts;
        } else {
            experts = expertState;
        }

        // we need to add this line to the non angled template
        if (!inAngleTemplate) {
            html += `
                <div>
                    <em>For more information please find full biographies and employment history below.</em>
                </div>
                <br />
            `;
        }

        experts.forEach((expert, index) => {
            const {
                fname,
                lname,
                headline: expertHeadline,
                pricingUnit,
                suffix,
                city,
                state,
                country,
                employment_duration: employmentDuration,
                current_position: currentPosition,
                current_company: currentCompany,
                biography,
            } = expert;

            const headline = normalizeHeadline(expertHeadline);

            if (needMsoVersion) {
                html += `
                    <div style="font-size: 16pt;">
                        <strong><span style="color: #6F4698;">${index + 1}. ${fname} ${lname}${
                    suffix ? `, ${suffix}` : ""
                }</span>
                `;
            } else {
                html += `
                    <div style="font-size: 16px;">
                        <strong><span style="color: #6F4698;">${index + 1}. ${fname} ${lname}${
                    suffix ? `, ${suffix}` : ""
                }</span>
                `;
            }

            if (pricingUnit && pricingUnit > 1) {
                html += `
                    <span style="color: red;">
                        (Premium Expert - ${pricingUnit}x)
                    </span>
                `;
            }

            html += `
                    </strong>
                </div>
            `;

            if (!!headline) {
                // html += `
                //     <div>
                //         ${headline} ${employmentDuration ? ` - ${employmentDuration}` : ""}
                //     </div>
                // `;
                html += renderEmploymentHistory(headline, currentPosition, currentCompany, employmentDuration);
            } else {
                html += `
                    <div style="color: red;">EMPLOYMENT MISSING</div>
                `;
            }

            if (city !== "NA" && formatLocation(city, state, country) !== "") {
                html += `
                    <div>Location: <strong>${formatLocation(city, state, country)}</strong></div>
                `;
            }

            html += `
                <br />
                <div>
            `;

            if (biography) {
                html += `
                    <strong>About ${fname}:</strong> ${stripHtml(biography)}
                `;
            } else {
                html += `
                    <strong>About: ${fname}</strong>
                    <br />
                    --[Enter Bio]--
                `;
            }

            html += `</div>`;

            if (expertState.length - 1 !== index) {
                html += `<br />`;
            }
        });

        return html;
    }

    // this is where most of the complexity lives
    function renderExpertAngles(templateType, needMsoVersion) {
        let angles;
        let html = "";

        // this shows the surveyIds and an array of expertIds
        // associated with the survey
        const { surveys: surveyExpertObj } = sentToClientData;

        // we have to do a lot of different processes
        // depending on prescreen or postscreen now
        // becasue we're filtering by tags prescreen
        // and surveys on postscreen
        if (templateType === "prescreen") {
            const { tags } = editorState;

            // removing any "named tags"
            // which includes any tags with the pattern
            // of '- 1234', the regexp is saying
            // anything with one dash, one space,
            // then any number of digits at the
            // end of the string
            const filteredTags = tags.filter((tag) => {
                const { label } = tag;
                const testRegExp = new RegExp(/-{1}\s{1}\d+$/g);

                if (!testRegExp.test(label)) {
                    return tag;
                }
            });

            // create array of all the tags
            angles = filteredTags.map((tag) => tag.label);
        } else if (templateType === "postscreen") {
            // this is created earlier in the load
            // process, it's a list of survey id's
            // and the names of the surveys that
            // we iterate over
            angles = surveyTemplateState;
        }

        // iterate over each survey or tag depending
        // on if it's prescreen or postscreeen
        angles.forEach((angle, index) => {
            let filteredExperts;

            if (templateType === "prescreen") {
                // if the expert has the tag currently being iterated
                // over, they're added to filteredExperts
                filteredExperts = expertState.filter((expert) => {
                    const doesTagMatch = expert.tags.find((tag) => {
                        return tag.label === angle;
                    });

                    if (doesTagMatch) {
                        // console.log(`====== ${fname} ${lname} ======`);
                        return expert;
                    }
                });
                // if the expert has the survey currently being iterated
                // over, they're added to filteredExperts
            } else if (templateType === "postscreen") {
                filteredExperts = expertState.filter((expert) => {
                    const { id } = expert;

                    const expertIdArray = surveyExpertObj[angle.id];

                    if (expertIdArray.includes(id)) {
                        return expert;
                    }
                });

                // filter screener results
                // for each expert, we remove answers that are not
                // associated w/ the current survey being iterated over
                // in case there's multiple experts for different
                // surveys as the answers would all be in the same
                // array we're pulling answers from
                filteredExperts = filteredExperts.map((expert) => {
                    const filteredSurveyAnswers = expert.survey_answers.filter((answer) => {
                        return answer.survey_id === angle.id;
                    });

                    expert.survey_answers = filteredSurveyAnswers;

                    return expert;
                });
            }

            if (filteredExperts?.length > 0) {
                if (index === 0) {
                    html += `
                        <div>
                            <em>
                                For more information please find full biographies and employment history below.
                            </em>
                        </div>
                        <br />
                    `;
                }

                if (needMsoVersion) {
                    html += `
                        <div style="font-size: 16pt"><strong>Angle: ${renderAngleText(
                            angle,
                            templateType
                        )}</strong></div>
                    `;
                } else {
                    html += `
                        <div style="font-size: 16px"><strong>Angle: ${renderAngleText(
                            angle,
                            templateType
                        )}</strong></div>
                    `;
                }

                html += `<br />`;

                // use different screener depending on the scenario
                if (templateType === "prescreen") {
                    html += expertBio(true, needMsoVersion, filteredExperts);
                } else if (templateType === "postscreen") {
                    html += renderExpertInfo(needMsoVersion, filteredExperts);
                }
            }
        });

        return html;
    }

    // render the expert's signature
    function renderSignature() {
        const { fname, lname, number, title, mail } = auth.data;

        let html = "";

        html += `
            <div>
                Best regards,
                <br />
                ${fname}
            </div>
            <br />
            <div>
                <em>
                    ${fname} ${lname}
                </em>
                <br />
                <em>${title}</em>
                <br />
                <em>${formatPhoneNumber(number)}</em>
                <br />
                <em>${mail}</em>
                <br />
            </div>
            <br />
            <div style="text-align: center;">www.firstthought.io - new york</div>
        `;

        return html;
    }

    // renders the link for the CSV download
    const renderLandingPageLink = () => {
        const { projectId, expertArray, isCsvEnabled, downloadUrl } = csvData;

        let html = "";

        if (projectId && expertArray.length > 0 && isCsvEnabled && params.onRespondentsPage) {
            html += `
                <br />
                <div style="text-align: center;">
                    <a href=${downloadUrl}>Download Workbook of All Experts Sent</a>
                </div>
            `;

            return html;
        }
    };

    function handleClientContactEvent(contactsArray) {
        if (typeof contactsArray === "object" && contactsArray !== null) {
            // console.log("CLIENT CONTACT SELECTED: ", contactsArray);

            setClientEmail({
                ...client,
                to_email: contactsArray,
            });
            // setGreeting(`${contactsArray.map(c => c.label.split(" ")[0])}, `);
        }
    }

    const findProjectRelevantNote = (projects) => {
        const project = projects.filter((project) => {
            return project.project_id == props.match.params.id;
        });
        if (project.length > 0 && project[0].relevant_notes !== null) {
            return project[0].relevant_notes;
        } else {
            return "";
        }
    };

    const fetchExpertsById = useCallback(async (postData, obj, expertMap, csvData) => {
        const url = "/api/expert/findAllById";
        // console.log(postData, " :: postData");
        await axios
            .post(url, postData, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200 && result.data && result.data.data) {
                    if (result.data.data.experts.length === 0) return;
                    const response = result.data.data.experts;
                    console.log("EXPERTS DATA ARRAY: ", response);
                    // console.log("EXPERTS DATA ARRAY: ", `${JSON.stringify(response)}`);
                    // Loop through response array and create new simplified attributed Object for usage...
                    for (let i = 0; i < response.length; i++) {
                        const person = response[i];
                        const relevantNotes = findProjectRelevantNote(person.projects);
                        const tags = person.tags.map((tag) => {
                            return { label: tag.name, value: tag.id };
                        });
                        const current_employment = person.employment.filter((item) => item.present === true);
                        const past_employment = person.employment.filter((item) => item.present !== true);
                        const locationData =
                            person.address && person.address.length > 0
                                ? person.address[0]
                                : { city: "", state: "", country: "" };
                        const city = locationData.city;
                        const state = locationData.state;
                        const country = locationData.country;
                        // console.log("ADDRESS: ", locationData);
                        let mostRecentJob = "";
                        if (current_employment.length > 0) {
                            mostRecentJob = `${current_employment[0].position}, ${current_employment[0].company}`;
                        }
                        current_employment.sort(function (b, a) {
                            return a.from_year - b.from_year || MONTH[a.from_month] - MONTH[b.from_month];
                        });
                        past_employment.sort(function (b, a) {
                            return a.to_year - b.to_year || MONTH[a.to_month] - MONTH[b.to_month];
                        });
                        let consultationRate = person.consultation_rate;
                        // console.log(typeof person.id, " :: typeof person.id");
                        if (parseInt(obj.custom_charge, 10) > 0 || parseFloat(obj.custom_hourly_rate) > 1.0) {
                            consultationRate = person.consultation_rate + parseInt(obj.custom_charge, 10);
                            // person.consultation_rate is already multiplied by custom_hourly...
                            // ...we just need to add the flat custom charge
                            console.log("COST PLUS: ", {
                                flat_fee: obj.custom_charge,
                                multiplier: obj.custom_hourly_rate,
                                modified_rate: person.consultation_rate,
                                final_charge: consultationRate,
                            });
                        }
                        const exp = {
                            id: person.id,
                            prefix: person.prefix,
                            fname: person.fname,
                            mname: person.mname,
                            lname: person.lname,
                            headline: person.headline ? person.headline : mostRecentJob,
                            biography: person.biography,
                            blinded_bio: person.blinded_bio,
                            consultation_rate: consultationRate,
                            consultation_rate_currency: person.consultation_rate_currency,
                            suffix: person.suffix,
                            current_position:
                                current_employment.length > 0 ? current_employment[0].position : "Unavailable",
                            current_company:
                                current_employment.length > 0 ? current_employment[0].company : "Unavailable",
                            employment_duration:
                                current_employment.length > 0
                                    ? `${current_employment[0].from_month} ${current_employment[0].from_year} to Present`
                                    : "Dates Unavailable",
                            dnc: person.dnc,
                            opted_out: person.opted_out,
                            survey_answers: person.surveyAnswers ? person.surveyAnswers : [],
                            tags,
                            relevant_notes: relevantNotes,
                            client_id: obj.client_id,
                            custom_hourly_rate: parseFloat(obj.custom_hourly_rate),
                            city,
                            state,
                            country,
                            pricingUnit: expertMap.get(person.id),
                        };
                        fetchedExperts.push(exp);
                    }
                    fetchedExperts.forEach((expert) => {
                        if (expert.biography === "" || expert.biography === null) {
                            const expertName = expert?.fname + " " + expert?.lname;
                            alert(`${expertName} is missing a biography!`);
                        }
                    });

                    // console.log(fetchedExperts, " :: fetchedExpertsxz");

                    setExpertState(fetchedExperts);
                    setLoadingEditor(false);
                    setOpenLoader(false);
                    // setDefaultReferenceEditorState(
                    //     obj.project_title,
                    //     obj.primary_client_contacts[0],
                    //     obj.tags,
                    //     csvData
                    // );
                }
            })
            .catch((result) => {
                console.log("Something went wrong, API Error, 404 Error:", result);
            });
    }, []);

    async function fetchSelectedExperts(obj, csvData = { dataLoaded: false }) {
        // console.log("PARAM ARRAYS: ", params.send);
        if (params.send !== "" && params.send.length > 0) {
            // grab template type
            const templateType = params.templateType;

            setNewTabValue(templateType === "postscreen" ? 2 : 0);

            setTemplateType(templateType);

            const bracketlessParams = params.send.replace(/[\[\]']+/g, "");
            const selectedExpertObjects = bracketlessParams.split(",");
            // EXAMPLE URL TO PARSE: send=[eids=6696|sid=16],[eids=66286|sid=17]
            // Need to parse out the url params to grab expert_ids that are associated with survey...
            // console.log("ARRAY OF PARAM STRINGS: ", selectedExpertObjects);

            // this is used in the post obj to find all all experts
            const expertArray = [];
            // used for pricing unit info
            const expertMap = new Map([]);
            // used in dialog to mark as sent to client
            const expertProjectMap = new Map([]);
            let expertSurveyObj = { surveyAttached: false, projectId: props.match.params.id, experts: {}, surveys: {} };

            if (selectedExpertObjects.length > 0) {
                selectedExpertObjects.forEach(function (item) {
                    const [eidParam, sidParam, pricingUnitParam] = item.split("|");

                    // params split apart
                    const expertId = eidParam.split("=")[1];
                    const screenerId = sidParam.split("=")[1];
                    const pricingUnit = pricingUnitParam.split("=")[1];

                    expertArray.push({ expertId, screenerId, pricingUnit });

                    expertMap.set(parseInt(expertId, 10), pricingUnit === "null" ? null : parseFloat(pricingUnit));

                    expertProjectMap.set(parseInt(expertId), parseInt(props.match.params.id));

                    // we need a list of screener id / expert id's in order to properly generate CSV
                    // this updates in expert_status_mapping_history with the surveyId's attached to the sent to client event
                    if (screenerId != 0) {
                        const { surveyAttached } = expertSurveyObj;
                        const parsedExpertId = parseInt(expertId);
                        const parsedScreenerId = parseInt(screenerId);

                        // if attached flag hasn't been set, then set it
                        if (!surveyAttached) {
                            expertSurveyObj = {
                                ...expertSurveyObj,
                                surveyAttached: true,
                                updateRequired: true,
                                action: "SURVEYS_ATTACHED",
                                reason: "respondents page",
                            };
                        }

                        // here we're adding survey id's to array for each expertId
                        if (expertSurveyObj.experts.hasOwnProperty(parsedExpertId)) {
                            const surveyIdArray = expertSurveyObj.experts[parsedExpertId].push(parsedScreenerId);
                            // expertSurveyObj = { ...expertSurveyObj, ...experts, experts[parsedExpertId]: [surveyIdArray] };
                            expertSurveyObj.experts[parsedExpertId] = surveyIdArray;
                        } else {
                            // expertSurveyObj = { ...expertSurveyObj, [parsedExpertId]: [parsedScreenerId] };
                            expertSurveyObj.experts[parsedExpertId] = [parsedScreenerId];
                        }

                        // here we're doing the opposite of above for the STC template
                        // we need a list of surveys showing which experts need to be attached
                        if (expertSurveyObj.surveys.hasOwnProperty(parsedScreenerId)) {
                            const expertIdArray = expertSurveyObj.surveys[parsedScreenerId];
                            expertIdArray.push(parsedExpertId);

                            expertSurveyObj.surveys[parsedScreenerId] = expertIdArray;
                        } else {
                            expertSurveyObj.surveys[parsedScreenerId] = [parsedExpertId];
                        }
                    } else {
                        const parsedExpertId = parseInt(expertId);

                        expertSurveyObj = {
                            ...expertSurveyObj,
                            updateRequired: true,
                            action: "NO_SURVEYS_ATTACHED",
                            reason: "project page sent to client button",
                            // [parsedExpertId]: null,
                        };

                        expertSurveyObj.experts[parsedExpertId] = null;
                    }

                    // console.log("EXPERT IDS PARAM: ", eidsArray);
                    // console.log("SCREENER ID: ", screener_id);
                    // console.log("PRICING UNIT: ", pricingUnit);
                });

                // only do the screener name lookup if we're in postscreen aka respondent page
                if (templateType === "postscreen") {
                    const surveyIdArray = [];

                    for (const surveyId of Object.keys(expertSurveyObj.surveys)) {
                        surveyIdArray.push(surveyId);
                    }

                    const surveyIdStr = surveyIdArray.join(",");

                    const surveyData = await fetchSurveyNamesFromIds(surveyIdStr);

                    setSurveyTemplateState(surveyData);
                }

                setSentToClientData(expertSurveyObj);

                const postData = {
                    project_id: parseInt(props.match.params.id, 10),
                    experts: expertArray,
                };

                fetchExpertsById(postData, obj, expertMap, csvData);
            }
            // setDialogData(expertProjectMap);
            setLoadingDialog(false);
        }
    }

    const fetchProjectById = useCallback(async (id) => {
        const pData = await axios.get(`/api/project/find?id=${id}`);
        if (pData.status === 200 && pData.data && pData.data.data) {
            if (pData.data.data.projectData.length === 0) return;
            const project = pData.data.data.projectData[0];
            // console.log(project, " :: project");
            const tags = pData.data.data.tags.map((tag) => {
                return { label: tag.name, value: tag.tag_id };
            });
            const specialty = pData.data.data.specialty.map((s) => {
                return { label: s.name, value: s.specialty_id };
            });
            const clientContacts = pData.data.data.clientContacts;
            const pcc = clientContacts.filter((item) => item.isprimary == true);
            const acc = clientContacts.filter((item) => item.isprimary !== true);
            const apms = pData.data.data.additionalProjectManagers;
            const { comments } = pData.data.data;

            const settings = pData.data.data.confBridgeSettings;
            const primary = settings.filter((item) => item.isprimary !== false);
            let selectedTypeID = "1";
            let selectedTwilio = "1";
            let extNumbers = [];
            if (primary.length > 0) {
                const primaryData = primary[0];
                selectedTypeID = primaryData.type_id.toString();
                if (primaryData.data && primaryData.data.value !== undefined) {
                    selectedTwilio = primaryData.data.value;
                }
                if (Array.isArray(primaryData.data) && primaryData.data.length > 0) {
                    extNumbers = primaryData.data;
                }
            }
            // Create array for send to client email preset sender fields...
            const presetClientContacts = clientContacts.map((item) => {
                return {
                    value: item.email,
                    label: `${item.firstname} ${item.lastname}`,
                };
            });
            const custom_charge = project.custom_charge;
            const custom_hourly_rate = parseFloat(project.custom_hourly_rate);
            // console.log("FETCHED DATA: ", pData.data.data);
            const attachedExperts = pData.data.data.experts;

            setData({
                ...data,
                id: project.id,
                isdeleted: project.isdeleted,
                title: project.title,
                description: project.description,
                email_copy: project.email_copy,
                project_not_received_by_email: project.project_not_received_by_email,
                client_id: project.client_id,
                client_name: project.client_name,
                primary_client_contact: pcc[0],
                additional_client_contacts: acc,
                expert_ids: project.expert_ids,
                primary_project_manager: `${project.project_manager_fname} ${project.project_manager_lname}`,
                additional_project_managers: apms,
                status: project.project_status,
                type: project.project_type,
                priority: project.project_priority,
                case_code: project.case_code,
                calls_planned: project.calls_planned,
                industry: project.industry,
                conference_bridge_setting: selectedTypeID,
                twilio_bridge_setting: selectedTwilio,
                external_bridge_numbers: extNumbers,
                notifications: [],
                specialty,
                tags,
                comments,
                responses: project.responses,
                scheduled_calls: project.scheduled_calls,
                completed_calls: project.completed_calls,
                experts: attachedExperts,
                custom_charge,
                custom_hourly_rate,
                enable_csv_download: project.enable_csv_download,
                blind_csv: project.blind_csv,
            });
            const eidsArray = [];
            const expertSurveyHashArray = [];

            if (params.send !== "" && params.send.length > 0) {
                const bracketlessParams = params.send.replace(/[\[\]']+/g, "");
                // console.log(bracketlessParams, " :: bracketlessParams");
                const selectedExpertObjects = bracketlessParams.split(",");
                // EXAMPLE URL TO PARSE: send=[eids=6696|sid=16],[eids=66286|sid=17]
                // Need to parse out the url params to grab expert_ids that are associated with survey...
                // console.log("CREATE ARRAY OF PARAM STRINGS: ", selectedExpertObjects);
                if (selectedExpertObjects.length > 0) {
                    selectedExpertObjects.forEach(function (item) {
                        const params = item.split("|");
                        const eid_param = params?.[0];
                        const sid_param = params?.[1];

                        const eidString = eid_param.split("=")[1];
                        const sidString = sid_param.split("=")[1];

                        eidsArray.push(parseInt(eidString, 10));
                        expertSurveyHashArray.push(`${eidString}${sidString}`);
                    });
                }
            }

            const csvObj = {
                dataLoaded: true,
                projectId: props.match.params.id,
                expertArray: eidsArray.length > 0 ? eidsArray : [],
                expertSurveyHashArray,
                projectTitle: project.title,
                isCsvBlind: project.blind_csv,
                isCsvEnabled: project.enable_csv_download,
            };

            // console.log(csvObj, " :: csvObj");

            csvObj.downloadUrl = buildDownloadUrl(csvObj);

            setCsvData(csvObj);
            setLoadingCsvData(false);

            setClientEmail({
                ...client,
                to_email: presetClientContacts,
                cc_email: "adminresearch@firstthought.io," + apms.map((a) => `${a.mail}`),
                client_id: project.client_id,
                project_id: project.id,
                project_title: project.title,
                email_subject: `Sending Over Experts for ${project.title} (ID-${project.id})`,
                experts: eidsArray.length > 0 ? eidsArray : [],
                user_id: auth.data.id,
                user_fname: auth.data.fname,
                user_lname: auth.data.lname,
                from_email: auth.data.mail,
            });

            // console.log("EXPERT IDS ARRAY: ", eidsArray);
            // setOpenLoader(false);
            getClientOrgList(project.client_id);
            // Build object to pass into expert fetch and editor render functions...
            const editorObjectAttributes = {
                project_title: project.title,
                client_id: project.client_id,
                custom_charge,
                custom_hourly_rate,
                primary_client_contacts: pcc,
                additional_client_contacts: acc,
                tags,
            };

            setEditorState({ ...editorState, ...editorObjectAttributes });

            fetchSelectedExperts(editorObjectAttributes, csvObj);
        }
    }, []);

    function navigateBackToProject() {
        history.push(`/project/view/${data.id}`);
    }
    function sendEmail() {
        // console.log("SENDING: ", client);

        const postObj = { ...client, sentToClientData };

        console.log(postObj, " :: postObj");

        if (window.confirm("Are you sure you want to send this to the client?")) {
            setOpenLoader(true);
            const url = "/api/mail/sendtoclient";
            axios
                .post(url, postObj, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                })
                .then((result) => {
                    if (result.status === 200) {
                        setOpenLoader(false);
                        setOpen(true);
                    } else {
                        // console.log(`POSTED, but NOT Status 200: ${JSON.stringify(client)}`);
                        setOpenLoader(false);
                    }
                })
                .catch((result) => {
                    console.log(`ERROR: ${JSON.stringify(client)}`);
                    setOpenLoader(false);
                    // eventually remove from here ---
                    // setCsvPostData({ project_id: client.project_id, experts: client.experts });
                    // setOpenLoader(false);
                    // setOpen(true);
                    // to here ---
                });
        }
    }

    function viewEmail() {
        if (client.to_email.length > 0) {
            setEditable("none");
            setPreview("block");
            setActiveBtn(false);
            setDisableDone(true);
            const cc_emails = [];
            // console.log("CC EMAIL: ", client.cc_email);
            // Convert comma delimited string into an array
            if (client.cc_email !== "" && client.cc_email.indexOf(",") !== -1) {
                const ccArray = client.cc_email.split(",");
                ccArray.forEach((e) => cc_emails.push(e.trim()));
            } else {
                cc_emails.push(client.cc_email.toString().trim());
            }
            client.cc_email = cc_emails;

            // console.log(buildTemplate(newTabValue), " ;; template");

            setClientEmail({
                ...client,
                email_body: buildTemplate(newTabValue),
            });
        }
    }

    function navigateToProject() {
        history.push(`/project/view/${props.match.params.id}`);
    }

    function navigateToDeliveryStatus() {
        history.push(`/project/emails/${props.match.params.id}`);
    }

    const handleCsvBtn = async () => {
        // setOpenLoader(true);
        window.open(csvData.downloadUrl, "_blank").focus();
        // setOpenLoader(false);
    };

    // function handleSendToSelf(event) {
    //     const { target } = event;
    //     const { name } = target;
    //     const selfRecipient = [{ value: auth.data.mail, label: `${auth.data.fname} ${auth.data.lname}` }];
    //     setSendToSelf(event.target.checked);
    //     if (event.target.checked) {
    //         console.log("Recipient: ", selfRecipient);
    //         setClientEmail({
    //             ...client,
    //             to_email: selfRecipient,
    //         });
    //     } else {
    //         console.log("Recipient: ", selfRecipient);
    //         setClientEmail({
    //             ...client,
    //             to_email: [],
    //         });
    //     }
    // }

    function editEmailBody() {
        setEditable("block");
        setPreview("none");
        setActiveBtn(true);
        setDisableDone(false);
    }

    const copyTab = "Copy/Paste";
    const sendToTab = "Send to Client";

    useEffect(() => {
        if (props.match.params.id) {
            fetchProjectById(props.match.params.id);
        }
    }, [props.match.params.id]);

    // useEffect(() => {
    //     window.addEventListener("copy", () => {
    // setIsDialogOpen(true);
    //     });
    // });

    useEffect(() => {
        // console.log(params.onRespondentsPage, " :: params.onRespondentsPage");
        if (!loadingDialog) {
            // TODO: uncomment this
            handleOpenSentToClientDialog();
        }
    }, [loadingDialog]);

    return (
        <div>
            <PageTitle title={data.title} />
            <Backdrop
                // transitionDuration={500}
                className={classes.backdrop}
                open={openLoader}
                onClick={() => {
                    setOpenLoader(false);
                }}
            >
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                    <CircularProgress color="inherit" />
                    <Typography sx={{ marginTop: ".75rem" }}>Loading Templates...</Typography>
                </Box>
            </Backdrop>
            <Grid container spacing={2}>
                <Grid item sm={12} xs={12}>
                    <Button className={classes.nameButton} onClick={navigateBackToProject}>
                        {data.title}
                    </Button>
                    <Typography>
                        Send these experts to the client. Please select the default copy templates provided above the
                        editor for your convenience.
                    </Typography>
                    <Typography>
                        <strong>What is an Angle?</strong> For our purposes, they are project tags, but generally a
                        loose industry term used to segment experts into groups. Synonymous with vantage point or
                        perspective.
                    </Typography>
                    <br />
                    {/* <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
                        <Tab label={sendToTab} />
                        <Tab label={copyTab} />
                    </Tabs>
                    <TabPanel value={tabValue} index={0}> */}
                    {/* moved all the loading states higher up in the UI so that the templates and stuff are all loaded properly */}
                    {!loadingEditor && !!templateType && !loadingCsvData && (
                        <div>
                            {/* {console.log(client, " :: client")} */}
                            <Box display={editable} className={classes.text}>
                                <div className={classes.label}>
                                    Select recipients (Please Note: If you don't see your contact, their email is
                                    probably missing)
                                </div>
                                <Select
                                    isMulti
                                    name="to_email"
                                    onChange={handleClientContactEvent}
                                    options={contacts}
                                    value={client.to_email}
                                />
                                {/* <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={self}
                                        inputProps={{
                                            "aria-label": "Send to Self",
                                        }}
                                        name="current_employment"
                                        onChange={handleSendToSelf}
                                        value={self}
                                    />
                                }
                                label="Send to Self"
                            /> */}
                                <TextField
                                    autoFocus
                                    fullWidth
                                    autoComplete="cc_email"
                                    id="cc_email"
                                    label="CC (Comma delimited ex: research@firstthought.io, morgen@firstthought.io)"
                                    margin="normal"
                                    onChange={handleChange}
                                    name="cc_email"
                                    value={client.cc_email}
                                />
                                <TextField
                                    autoFocus
                                    fullWidth
                                    id="email_subject"
                                    label="Subject Line"
                                    margin="normal"
                                    onChange={handleChange}
                                    name="email_subject"
                                    value={client.email_subject}
                                />
                                <TextField
                                    fullWidth
                                    label="Workbook Link"
                                    margin="normal"
                                    value={csvData.downloadUrl}
                                    disabled
                                    InputProps={{
                                        endAdornment: (
                                            <ContentCopyIcon sx={{ cursor: "pointer" }} onClick={handleCopyIconClick} />
                                        ),
                                    }}
                                />
                                <Box sx={{ width: "100%" }}>
                                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                        <Tabs
                                            value={newTabValue}
                                            onChange={handleNewTabChange}
                                            aria-label="basic tabs example"
                                        >
                                            <Tab label="Pre Screen" disabled={templateType === "postscreen"} />
                                            <Tab label="Pre Screen Angles" disabled={templateType === "postscreen"} />
                                            <Tab label="Post Screen" disabled={templateType === "prescreen"} />
                                            <Tab label="Post Screen Angles" disabled={templateType === "prescreen"} />
                                        </Tabs>
                                    </Box>
                                    <TabPanel
                                        sx={{
                                            borderRight: 1,
                                            borderLeft: 1,
                                            borderBottom: 1,
                                            borderColor: "divider",
                                        }}
                                        value={newTabValue}
                                        index={0}
                                    >
                                        <div dangerouslySetInnerHTML={{ __html: buildTemplate(0) }} />
                                    </TabPanel>
                                    <TabPanel
                                        value={newTabValue}
                                        index={1}
                                        sx={{
                                            borderRight: 1,
                                            borderLeft: 1,
                                            borderBottom: 1,
                                            borderColor: "divider",
                                        }}
                                    >
                                        <div dangerouslySetInnerHTML={{ __html: buildTemplate(1) }} />
                                    </TabPanel>
                                    <TabPanel
                                        value={newTabValue}
                                        index={2}
                                        sx={{
                                            borderRight: 1,
                                            borderLeft: 1,
                                            borderBottom: 1,
                                            borderColor: "divider",
                                        }}
                                    >
                                        <div dangerouslySetInnerHTML={{ __html: buildTemplate(2) }} />
                                    </TabPanel>
                                    <TabPanel
                                        value={newTabValue}
                                        index={3}
                                        sx={{
                                            borderRight: 1,
                                            borderLeft: 1,
                                            borderBottom: 1,
                                            borderColor: "divider",
                                        }}
                                    >
                                        <div dangerouslySetInnerHTML={{ __html: buildTemplate(3) }} />
                                    </TabPanel>
                                </Box>
                            </Box>
                            <Box display={preview}>
                                <div className={classes.emailBorder}>
                                    <p className={classes.datestamp}>{formatDateString(client.created_on)}</p>
                                    <p>
                                        <strong>FROM:</strong> {client.from_email}
                                    </p>
                                    <p className={classes.recipients}>
                                        <strong className={classes.to_email}>TO:</strong>
                                        {client.to_email.length > 0 && (
                                            <span>
                                                {client.to_email.map((c) => (
                                                    <span key={c.value} className={classes.emails}>
                                                        {c.value}
                                                    </span>
                                                ))}
                                            </span>
                                        )}
                                    </p>
                                    <p>
                                        <strong>CC:</strong> {client.cc_email.toString()}
                                    </p>
                                    <p className={classes.subjectLine}>
                                        <strong>SUBJECT LINE:</strong> {client.email_subject}
                                    </p>
                                    {/* {console.log(client.email_body, " :: client.email_body in render")} */}
                                    <div
                                        className={classes.emailOutline}
                                        dangerouslySetInnerHTML={{
                                            __html: client.email_body,
                                        }}
                                    />
                                </div>
                            </Box>

                            <div className={classes.actions}>
                                <Button onClick={handleTemplateCopy} color="primary">
                                    Copy Template
                                </Button>
                                <Button color="inherit" onClick={editEmailBody} disabled={activeBtn}>
                                    Edit
                                </Button>
                                <Button onClick={viewEmail} color="primary" disabled={disableDone}>
                                    Done
                                </Button>
                                <Button onClick={sendEmail} color="primary" disabled={activeBtn}>
                                    Send
                                </Button>
                            </div>
                            <Typography align={"center"} sx={{ fontSize: ".75rem", marginTop: ".75rem" }}>
                                ( Please Note - To Work In All Email Clients, You Must Use the Copy Button )
                            </Typography>
                        </div>
                    )}
                </Grid>
            </Grid>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Your client email has been successfully sent by the system. Please check the delivery dashboard
                        for final confirmation on status.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Close
                    </Button>
                    <Button onClick={navigateToProject} className={classes.link}>
                        View Project
                    </Button>
                    <Button onClick={navigateToDeliveryStatus} className={classes.link}>
                        Delivery Status
                    </Button>
                    {!loadingCsvData && csvData.isCsvEnabled && params.onRespondentsPage && (
                        <Button onClick={handleCsvBtn} className={classes.link}>
                            CSV Download Page
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
            {!loadingDialog && (
                <MarkAsSentToClientDialog
                    isDialogOpen={isDialogOpen}
                    setIsDialogOpen={setIsDialogOpen}
                    dialogData={sentToClientData}
                    personId={auth.data.id}
                    setSnackbarMessage={setSnackbarMessage}
                    setOpenSnackbar={setOpenSnackbar}
                    setOpenFlashMessage={setOpenFlashMessage}
                />
            )}
            {openSnackbar && (
                <FlashMessage
                    openSnackbar={openSnackbar}
                    setOpenSnackbar={setOpenSnackbar}
                    message={snackbarMessage}
                    severity={"success"}
                />
            )}
        </div>
    );
}

ProjectExperts.propTypes = {
    match: PropTypes.object,
};
ProjectExperts.defaultProps = {
    match: null,
};

export default ProjectExperts;
