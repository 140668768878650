import React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Stack, Container, Paper, Avatar } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import useSWR from "swr";
import MUIDataTable from "mui-datatables";
import { useTheme } from "@mui/material";
import { Link } from "@mui/material";

const fetcher = (...args) => fetch(...args).then((res) => res.json());

export default function ContactList(props) {
    const theme = useTheme();
    const findAllContactsUrl = `/api/contact/findAll`;
    const { data, error } = useSWR(findAllContactsUrl, fetcher);

    if (error) return <div>failed to load</div>;
    if (!data) {
        return (
            <Stack sx={{ color: "grey.500" }} spacing={2} direction="row">
                <CircularProgress color="primary" />
            </Stack>
        );
    }

    const options = {
        filter: true,
        pagination: true,
        print: false,
        viewColumns: false,
        download: false,
        selectableRows: "none",
    };

    const columns = [
        {
            name: "clientname",
            label: "Client",
            options: {
                filter: true,
                filterType: "multiselect",
                display: false, // Hide Column, used for filtering only
            },
        },
        {
            name: "avatar",
            label: " ",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <Avatar
                            sx={{
                                color: theme.palette.primary.contrastText,
                                backgroundColor: "#999",
                            }}
                        >
                            {createInitialForAvatar(data.data[dataIndex].firstname, data.data[dataIndex].lastname)}
                        </Avatar>
                    );
                },
            },
        },
        {
            name: "name",
            label: "Name",
            options: {
                filter: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return (
                        <Container>
                            <Link
                                sx={{
                                    color: theme.palette.primary.main,
                                    textDecoration: "none",
                                }}
                                underline="none"
                                href={`/contact/view/${data.data[dataIndex].id}`}
                            >
                                {data.data[dataIndex].name}
                            </Link>

                            <p
                                sx={{
                                    color: "#666",
                                    marginTop: "5px",
                                    marginBottom: "0",
                                }}
                            >
                                <Link
                                    sx={{
                                        color: "#D1C0E2",
                                        marginTop: "0",
                                        marginBottom: "0",
                                        fontWeight: "bold",
                                        textDecoration: "none",
                                    }}
                                    href={"/client/view/" + data.data[dataIndex].client_id}
                                >
                                    {data.data[dataIndex].clientname}
                                </Link>
                            </p>
                            <p
                                sx={{
                                    color: "#666",
                                    marginTop: "5px",
                                    marginBottom: "0",
                                }}
                            >
                                Salesforce ID: {data.data[dataIndex].salesforceid}
                            </p>
                        </Container>
                    );
                },
            },
        },
        {
            name: "contact",
            label: "Contact",

            options: {
                filter: false,
                sort: false,

                customBodyRenderLite: (dataIndex) => {
                    return (
                        <Container>
                            Email: {data.data[dataIndex].email || "N/A"}
                            <br />
                            Phone: {data.data[dataIndex].phone || "N/A"}
                        </Container>
                    );
                },
            },
        },
        {
            name: "create_project",
            label: " ",
            options: {
                sort: false,

                filter: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return (
                        <Link
                            href={`/project/create?id=${data.data[dataIndex].id}`}
                            sx={{
                                color: theme.palette.primary.main,
                                textDecoration: "none",
                            }}
                        >
                            Create Project
                        </Link>
                    );
                },
            },
        },
    ];

    function createInitialForAvatar(fname, lname) {
        if (fname && lname) {
            return `${fname.charAt(0)}${lname.charAt(0)}`;
        } else {
            return `??`;
        }
    }
    const addContactEvent = () => {
        props.history.push(`/contact/add`);
    };
    return (
        <Container maxWidth={false} disableGutters>
            <Button
                variant="contained"
                size="small"
                sx={{ float: "right", display: "inline" }}
                color="primary"
                onClick={addContactEvent}
            >
                Add New Contact
            </Button>

            <Grid container spacing={3} sx={{ p: 1 }}>
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "1rem",
                        }}
                    >
                        <MUIDataTable title={"Contacts"} data={data.data} columns={columns} options={options} />
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}
