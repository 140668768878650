// date formatters
import { format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

// timezone data
import TimeZoneData from "../../../data/timezones.json";

// FORMAT DATA
const timeZones = TimeZoneData.zones;

// ===============
// DATA FORMATTING
// ===============

export const numberFormatToCurrencyType = (val, decimals) => {
    // Parse the value as a float value
    // Format the value w/ the specified number
    // of decimal places and return it.
    return parseFloat(val).toFixed(decimals);
};

// ========================
// DATE AND TIME FORMATTING
// ========================

export function legacyDateAndTime(dateObj) {
    return { scheduled_date: format(dateObj, "yyyy-MM-dd"), scheduled_time: format(dateObj, "HH:mm") };
}

// this changes the ISO string coming from the DB back into
// local time for client display
export function createDateTimeStringForDTPicker(dateObj, offset) {
    return formatInTimeZone(dateObj, offset, "yyyy-MM-dd'T'HH:mm:ss");
}

// creates the client date time string based on an offset
export function createDateTimeStringForClient(dateObj, timezoneId) {
    const timezones = getTimezones();
    const timezone = timezones.find((zone) => timezoneId === zone.value);
    return formatInTimeZone(dateObj, timezone.offset, "EEEE, MMMM do, yyyy '@' h:mmaaa");
}

// this takes in a string of a time and formats it
export function stringToFormatTimeOnly(string) {
    return format(new Date(string), "h:mma");
}

export function stringToDateOnly(string) {
    return format(new Date(string), "MMMM d, yyyy");
}

// this takes a string to format date and time and formats it
export function stringToFormatDateTime(string) {
    return format(new Date(string), "EEEE, MMMM do, yyyy");
}

export function convertSecondsToMinutes(seconds) {
    const minutes = Math.floor(seconds / 60) + " minutes";
    return minutes;
}

// tell if the scheduled datetime is 1 day away or not
export function isScheduledDateOneDayAway(scheduledDateTime) {
    const then = new Date(scheduledDateTime);
    const now = new Date();

    const msBetweenDates = Math.abs(then.getTime() - now.getTime());

    //   ️ convert ms to hours                  min  sec   ms
    const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);

    if (hoursBetweenDates < 24) {
        return true;
    } else {
        return false;
    }
}

// this converts date pick into the proper string we need
// so that we can adjust from the browser local time, to the
// timezone selected by the scheduler.  we then turn this
// string into UTC string use toISOstring() function
export function createDateTimeStringForDb(dateObj, offset) {
    function pad(number) {
        let r = String(number);
        if (r.length === 1) {
            r = "0" + r;
        }
        return r;
    }

    return (
        dateObj.getFullYear() +
        "-" +
        pad(dateObj.getMonth() + 1) +
        "-" +
        pad(dateObj.getDate()) +
        "T" +
        pad(dateObj.getHours()) +
        ":" +
        pad(dateObj.getMinutes()) +
        ":" +
        pad(dateObj.getSeconds()) +
        "." +
        String((dateObj.getMilliseconds() / 1000).toFixed(3)).slice(2, 5) +
        offset
    );
}

// format for confirmation box
export function formatForConfirmation(dateObj) {
    return format(typeof dateObj === "string" ? new Date(dateObj) : dateObj, "MMMM do, yyyy 'at' hh:mm aaa");
}

// ===============
// DATA GENERATION
// ===============

export function getTimezones() {
    // create arrays of the timezone and country stuff
    const zones = timeZones.map((item) => {
        return {
            value: item.id,
            label: item.text,
            abbr: item.abbr,
            offset: item.offset,
        };
    });

    return zones;
}

// this is for the component that handles which user gets credit for scheduling
export const generateDropdown = (projectData) => {
    const {
        primary_project_manager: ppmId,
        ppm_name: ppmName,
        additional_manager_info: addtlManagerInfo,
    } = projectData;

    // default value
    const creditDropdownOptionsArray = [{ value: 0, label: "Nobody Gets Credit" }];

    // add primary project manager
    creditDropdownOptionsArray.push({
        value: ppmId,
        label: ppmName,
    });

    // add additional managers
    addtlManagerInfo.forEach((manager) => {
        const { additional_manager_id: id, name } = manager;

        creditDropdownOptionsArray.push({
            value: id,
            label: name,
        });
    });

    // return and pass as props
    return creditDropdownOptionsArray;
};
