// react
import React from "react";

// npm
import axios from "axios";

// custom
import ImpersonationDialog from "./ImpersonationDialog";

// MUI
// style imports
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

// icons
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

// styles
const addButton = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: fit-content;
    &:hover {
        color: #6619ff;
        cursor: pointer;
    }
`;

// const header = css`
//     fontSize: "1rem",
//     color: "#523197",
//     margin-bottom: 1rem;
// `;

const impersonationUserRow = css`
    border: 1px solid #e5e0f3;
    margin-bottom: 0.5rem;
    color: #523197;
    background-color: #e5e0f3;
    padding: 0.5rem 2rem;
    width: 100%;
    border-radius: 4px;
    font-size: 0.9rem;
    letter-spacing: 1.1px;
    &:hover {
        border: 1px solid #8966ad;
        background-color: #8966ad;
        cursor: pointer;
        color: #ffffff;
    }
`;

const impersonationListContainer = css`
    margin-bottom: 1rem;
`;
const warningStyle = css`
    color: "orange";
`;

function Impersonator(props) {
    // =====
    // PROPS
    // =====

    const { projectId } = props;

    // =====
    // STATE
    // =====

    const [impersonationData, setImpersonationData] = React.useState({});
    const [openDialog, setOpenDialog] = React.useState(false);
    const [currentUser, setCurrentUser] = React.useState({});
    const [loading, setLoading] = React.useState(true);

    // ============
    // HANDLE FUNCS
    // ============

    function handleAddClick() {
        // reset current user to empty object
        setCurrentUser({ currentUser: {}, status: "add" });
        setOpenDialog(true);
    }

    function handlePersonClick(person) {
        console.log(person, " :: person");
        // pass in current person
        setCurrentUser({ currentUser: person, status: "view" });
        setOpenDialog(true);
    }

    // =========
    // PAGE LOAD
    // =========

    // get impersonators
    async function loadImpersonationList() {
        await axios
            .get(`/api/project/findImpersonationLists/${projectId}`)
            .then((res) => {
                const fetchedImpersonationData = res?.data?.data;
                // console.log(fetchedImpersonationData, " :: fetchedImpersonationData");

                setImpersonationData(fetchedImpersonationData);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err, " :: err");
            });
    }

    React.useEffect(() => {
        // load the list of impersonated users on load
        loadImpersonationList();
    }, []);

    // ============
    // RENDER FUNCS
    // ============

    // this func renders the actual list of impersonated users
    function renderImpersonators() {
        const jsxArray = [];

        for (const [index, pair] of impersonationData.impersonationPairs.entries()) {
            jsxArray.push(
                <div css={impersonationUserRow} key={index} onClick={() => handlePersonClick(pair)}>
                    <span style={{ fontWeight: "400" }}>{`${pair.impersonator_name}`}</span> <ArrowRightAltIcon />{" "}
                    <span style={{ fontWeight: "800" }}>{`${pair.impersonated_name}`}</span>
                    {"  ~  "}
                    <span>
                        {pair.survey_id ? `(${pair.survey_id}: ${pair.survey_name})` : `(No Screener Selected)`}
                    </span>
                </div>
            );
        }

        return jsxArray;
    }

    // ================
    // COMPONENT RENDER
    // ================

    if (loading) {
        return <h1>Loading Impersonation...</h1>;
    } else {
        return (
            <>
                {renderImpersonators().length > 0 ? (
                    <div css={impersonationListContainer}>{renderImpersonators()}</div>
                ) : (
                    <div style={{ marginBottom: ".75rem", color: "orange", fontWeight: "bold", fontSize: "1rem" }}>
                        *** No Pairings Set Up Yet! ***
                    </div>
                )}
                <div css={addButton} onClick={handleAddClick}>
                    <AddCircleOutlineIcon />{" "}
                    <span style={{ marginLeft: ".5rem", fontWeight: "700" }}>Add New Pairing</span>
                </div>
                {openDialog && (
                    <ImpersonationDialog
                        openDialog={openDialog}
                        setOpenDialog={setOpenDialog}
                        currentUser={currentUser}
                        impersonationData={impersonationData}
                        projectId={projectId}
                        loadImpersonationList={loadImpersonationList}
                    />
                )}
            </>
        );
    }
}

export default Impersonator;
