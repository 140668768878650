import React, { useState, useEffect, useRef, useContext } from "react";
import withStyles from "@mui/styles/withStyles";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import NotificationIcon from "@mui/icons-material/NotificationImportantOutlined";
import Snackbar from "@mui/material/Snackbar";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import "moment-duration-format";
import PageTitle from "../components/PageTitle";
import UserMetricsNext from "../components/UserMetricsNext";
import ScreenedExperts from "../components/ScreenedExperts";
import WarmLeads from "../components/WarmLeads";
import AssociateCallLog from "../components/AssociateCallLog";
import OverallMetrics from "../components/OverallMetrics";
import { authContext } from "../context/auth";
import CompletedScreenerCount from "../components/CompletedScreenerCount";

// custom
import useClasses from "../custom-hooks/useClasses";

let pollboolean = true;
const localStorageViewed =
    localStorage.getItem("viewedScreenedExperts") !== null
        ? localStorage.getItem("viewedScreenedExperts").split(",")
        : [];

const styles = (theme) => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(2),
        overflowX: "auto",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    appBar: {
        position: "relative",
    },
    center: {
        display: "block",
        textAlign: "center",
    },
    overallMetrics: {
        paddingTop: "20px",
        marginBottom: "20px",
        borderTop: "2px solid #444",
        borderBottom: "2px solid #444",
    },
    alertSuccess: {
        color: "#fff",
        fontWeight: "500",
        backgroundColor: "#4caf50",
        borderRadius: "3px",
        display: "flex",
        padding: "6px 16px",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    h3: {
        textTransform: "uppercase",
        fontSize: "1rem",
        color: "#523197",
        fontWeight: "bold",
        textAlign: "center",
        marginTop: "15px",
    },
    heading: {
        color: "#444",
        fontSize: ".85rem",
        marginTop: "0",
        marginBottom: "5px",
        borderBottom: "2px solid #eaeaea",
    },
    divider: {
        paddingBottom: "0px",
        borderBottom: "8px solid #eaeaea",
    },
    expert: {
        paddingBottom: "5px",
        margin: "0",
        marginBottom: "5px",
        borderBottom: "1px solid #f5f5f5",
    },
    headline: {
        color: "#666",
        fontSize: ".75rem",
    },
    added: {
        color: "#000",
        fontSize: ".85rem",
    },
    linkColor: {
        color: "#8966ad",
        textDecoration: "none",
    },
    table: {
        color: "#444",
        fontSize: ".85rem",
        marginTop: "20px",
        marginBottom: "20px",
        border: "1px solid #eaeaea",
        borderRadius: "3px",
        padding: "15px",
        backgroundColor: "#fff",
    },
    tableStyles: {
        width: "100%",
    },
    held: {
        color: theme.palette.secondary.main,
    },
    tableHeader: {
        textTransform: "uppercase",
        fontSize: ".75rem",
        color: "#666",
    },
    th: {
        borderBottom: "2px solid #CCC",
        paddingTop: "15px",
        textTransform: "uppercase",
        fontSize: ".5rem",
        color: "#999",
    },
    thInteger: {
        borderBottom: "2px solid #CCC",
        paddingTop: "15px",
        textAlign: "right",
        textTransform: "uppercase",
        fontSize: ".5rem",
        color: "#999",
    },
    row: {
        borderBottom: "1px solid #eaeaea",
        padding: "5px",
        backgroundColor: "#fff",
    },
    tdString: {
        textAlign: "left",
        padding: "5px",
    },
    tdInteger: {
        textAlign: "right",
        padding: "5px",
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
});

const StyledBadgeBetter = withStyles((theme) => ({
    badge: {
        backgroundColor: theme.palette.secondary.main,
    },
}))(Badge);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function DashboardNext(props) {
    // CONSTANTS
    const DEFAULT_COMPONENT_STATES = {
        screeners: false,
        leads: false,
        callLogs: false,
    };

    const start = new Date();
    const today = moment(start.date);
    const { history } = props;
    const classes = useClasses(styles);
    const [load, setLoad] = useState(DEFAULT_COMPONENT_STATES);
    const [open, setOpen] = useState(true);
    const { auth } = useContext(authContext);
    const [tabValue, setTabValue] = useState(0);
    const [currentUser, setCurrentUser] = useState({});
    const [loadingCurrentUser, setLoadingCurrentUser] = useState(true);

    const [leads, setWarmLeads] = useState([]);
    const [screened, setScreenedExperts] = useState([]);
    const [newleads, setNewWarmLeadsCount] = useState(0);
    const [newscreened, setNewScreenedExpertsCount] = useState(0);
    const [calllog, setCallLog] = useState([]);
    const [incoming, setIncoming] = useState(false);
    const [viewed, setViewedExperts] = useState(localStorageViewed);
    const [scheduled, setScheduled] = useState({
        dailyExternalScheduled: 0,
        dailyInternalScheduled: 0,
        monthlyExternal: 0,
        monthlyInternal: 0,
        nextMonthExternal: 0,
    });
    const pollScreened = useRef();
    const pollWarm = useRef();

    function addToViewedLocalStorage(id) {
        const viewedScreenedExperts = [...new Set(viewed)];
        if (!viewedScreenedExperts.includes(id)) {
            viewedScreenedExperts.push(id);
            localStorage.setItem("viewedScreenedExperts", viewedScreenedExperts);
            setViewedExperts(viewedScreenedExperts);
            const expertCount = screened.length - viewedScreenedExperts.length;
            if (expertCount > 0) {
                setNewScreenedExpertsCount(expertCount);
            } else {
                setNewScreenedExpertsCount(0);
            }
        }
    }

    function handleTabLoading(componentName) {
        setLoad({ ...load, [componentName]: true });
    }

    const handleCloseMessage = () => {
        setIncoming(false);
    };
    function handleTabChange(event, newValue) {
        setTabValue(newValue);
    }

    async function fetchDailyMonthlyCounts() {
        await axios.get("/api/expert/counts").then((response) => {
            if (response.status === 200 && response.data.data) {
                console.log(response?.data?.data, " :: response?.data?.data");
                setScheduled({
                    dailyExternalScheduled: response.data.data.dailyExternalScheduled,
                    dailyInternalScheduled: response.data.data.dailyInternalScheduled,
                    monthlyExternal: response.data.data.monthlyExternalScheduled,
                    monthlyInternal: response.data.data.monthlyInternalScheduled,
                    nextMonthExternal: response.data.data.nextMonthExternalScheduled,
                    monthlyLeadsAdded: response?.data?.data?.monthlyLeadsAdded,
                    convertedLeads: response.data.data.convertedLeads,
                });
            }
            setOpen(false);
        });
    }

    async function fetchAllUsers() {
        axios.get("/api/rm/list").then((result) => {
            if (result?.status === 200) {
                const userData = result?.data?.data;
                const currentUser = userData.find((u) => parseInt(u?.id) === parseInt(auth.data.id));
                currentUser ? setCurrentUser(currentUser) : setCurrentUser({ id: auth.data.id, isRmOrRa: "RA" });
                setLoadingCurrentUser(false);

                // CASCADING FETCHES
                fetchScreenedExperts(newscreened);
                fetchInterestedLeads(newleads);
                fetchCallLogs();
            }
        });
    }

    async function fetchCallLogs() {
        axios
            .post("/api/rm/getCallLogs", {
                userId: auth.data.id,
                // userId: 18152,
            })
            .then((result) => {
                // console.log("getCallLogs RESULT: ", result);
                if (result.status === 200 && result.data && result.data.data) {
                    const log = result.data.data.data;
                    setCallLog(log);
                    setOpen(false);
                }
            });
    }

    async function fetchScreenedExperts(screenedcount) {
        if (pollboolean) {
            axios
                .post("/api/rm/getScreenedExperts", {
                    userId: auth.data.id,
                    // userId: 18152,
                })
                .then((result) => {
                    if (result.status === 200 && result.data && result.data.data) {
                        // Filter out rejected screener candidates...
                        const screenedIn = result.data.data.data.filter((e) => e.current_status_id === 28);
                        setScreenedExperts(screenedIn);
                        // De-dupe already viewed array from localstorage
                        const totalViewed = [...new Set(localStorageViewed)].length;
                        const unread = screenedIn.length - totalViewed;
                        // console.log("BEFORE SCREENED EXPERTS: ", {
                        //     Viewed: totalViewed,
                        //     Unread: unread,
                        //     Current: screenedcount,
                        // });
                        if (unread > 0 && unread > screenedcount) {
                            setNewScreenedExpertsCount(unread);
                            setIncoming(true);
                            // console.log("NEW SCREENED EXPERTS: ", {
                            //     Viewed: totalViewed,
                            //     Unread: unread,
                            //     Current: screenedcount,
                            // });
                        }
                        setOpen(false);
                        pollScreened.current = setTimeout(() => {
                            // Poll every 10 minutes 600000
                            fetchScreenedExperts(unread);
                        }, 600000);
                        // console.log("POLLING SCREENED EXPERTS: ", pollboolean);
                    }
                });
        } else {
            clearTimeout(pollScreened.current);
            // console.log("POLLING SCREENED EXPERTS: ", pollboolean);
        }
    }

    async function fetchInterestedLeads(warmcount) {
        if (pollboolean) {
            axios
                .post("/api/rm/getWarmLeads", {
                    userId: auth.data.id,
                    // userId: 95654,
                })
                .then((result) => {
                    if (result.status === 200 && result.data && result.data.data) {
                        // console.log("ALL DIRECT LEADS: ", result.data.data.data);
                        setWarmLeads(result.data.data.data);
                        const recent = parseInt(result.data.data.recent, 10);
                        setNewWarmLeadsCount(recent);
                        // console.log("BEFORE WARM LEADS: ", { Incoming: recent, Current: warmcount });
                        if (recent > 0 && recent > parseInt(warmcount, 10)) {
                            setIncoming(true);
                            // console.log("NEW WARM LEADS: ", { Incoming: recent, Current: warmcount });
                        }
                        setOpen(false);
                        pollWarm.current = setTimeout(() => {
                            // Poll every 10 minutes 600000
                            fetchInterestedLeads(recent);
                        }, 600000);
                        // console.log("POLLING WARM LEADS: ", pollboolean);
                    }
                });
        } else {
            clearTimeout(pollWarm.current);
            // console.log("POLLING WARM LEADS: ", pollboolean);
        }
    }

    function keepPolling(bool) {
        pollboolean = bool;
        // console.log("KEEP POLLING? ", pollboolean);
        if (pollboolean) {
            fetchInterestedLeads(newleads);
            fetchScreenedExperts(newscreened);
        }
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    // =====
    // TABLE
    // =====

    useEffect(() => {
        fetchDailyMonthlyCounts();
        fetchAllUsers();
    }, [newscreened, newleads]);

    useEffect(() => {
        // clear both screened experts and warm leads when leaving the page
        return () => {
            clearTimeout(pollScreened.current);
            clearTimeout(pollWarm.current);
        };
    }, []);

    return (
        <div>
            <PageTitle title={`${auth.data.fname} ${auth.data.lname}`} />
            <Backdrop
                className={classes.backdrop}
                open={open}
                onClick={() => {
                    setOpen(false);
                }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar open={incoming} autoHideDuration={5000} onClose={handleCloseMessage}>
                <Typography className={classes.alertSuccess}>
                    <NotificationIcon /> You Have New Leads!
                </Typography>
            </Snackbar>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                    <h1>
                        Hello {auth.data.fname} {auth.data.lname}, {auth.data.title}
                    </h1>
                    <h2>{today.format("MMMM")} Metrics</h2>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <div className={classes.overallMetrics}>
                        <OverallMetrics monthly={scheduled} user={auth.data} />
                    </div>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab label="Track Projects" {...a11yProps(0)} />
                        <Tab
                            onClick={() => handleTabLoading("screeners")}
                            label={
                                <StyledBadgeBetter color="secondary" badgeContent={newscreened} showZero>
                                    Screened Experts
                                </StyledBadgeBetter>
                            }
                            {...a11yProps(1)}
                        />
                        <Tab
                            onClick={() => handleTabLoading("leads")}
                            label={
                                <StyledBadgeBetter color="secondary" badgeContent={newleads} showZero>
                                    Interested Leads
                                </StyledBadgeBetter>
                            }
                            {...a11yProps(2)}
                        />
                        <Tab onClick={() => handleTabLoading("callLogs")} label="Your Call Log" {...a11yProps(3)} />
                    </Tabs>
                    <TabPanel value={tabValue} index={0}>
                        <>{!loadingCurrentUser && <UserMetricsNext auth={auth} currentUser={currentUser} />}</>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <>
                            {load.screeners && <CompletedScreenerCount userId={auth.data.id} />}
                            {screened.length > 0 && load.screeners && (
                                <div className={classes.table}>
                                    <ScreenedExperts
                                        data={screened}
                                        user={auth.data}
                                        history={history}
                                        addToViewedLocalStorage={addToViewedLocalStorage}
                                        keepPolling={keepPolling}
                                    />
                                </div>
                            )}
                        </>
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                        {leads.length > 0 && load.leads && (
                            <div className={classes.table}>
                                <h3>
                                    <strong>RECOMMENDATION:</strong> Contact leads in this list immediately! They
                                    clicked "Interested" in the outreach email and stopped AND nobody has called them
                                    yet.
                                </h3>
                                <WarmLeads data={leads} user={auth.data} history={history} keepPolling={keepPolling} />
                            </div>
                        )}
                    </TabPanel>
                    <TabPanel value={tabValue} index={3}>
                        {calllog.length > 0 && load.callLogs && (
                            <>
                                <AssociateCallLog data={calllog} user={auth.data} />
                            </>
                        )}
                    </TabPanel>
                </Grid>
            </Grid>
        </div>
    );
}
DashboardNext.propTypes = {
    match: PropTypes.object,
};
DashboardNext.defaultProps = {
    match: null,
};
export default DashboardNext;
