import React, { useState } from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import { EditorState, Modifier } from "draft-js";
import MobileScreenShareIcon from "@mui/icons-material/MobileScreenShare";
import { Box } from "@mui/material";

function CustomOnboardingLink(props) {
    const { match, history } = props;

    function addTerms() {
        const { editorState, onChange } = props;
        const contentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            "{{ONBOARDING_LINK}}",
            editorState.getCurrentInlineStyle()
        );
        onChange(EditorState.push(editorState, contentState, "insert-characters"));
    }

    return (
        <Box
            onClick={addTerms}
            sx={{
                border: "1px solid #F1F1F1",
                padding: "5px",
                minWidth: "25px",
                height: "20px",
                borderRadius: "2px",
                margin: "0 4px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                background: "white",
                textTransform: "capitalize",
            }}
            title="Onboarding Link"
        >
            <MobileScreenShareIcon />
        </Box>
    );
}

CustomOnboardingLink.propTypes = {
    match: PropTypes.object,
    onChange: PropTypes.func,
    editorState: PropTypes.object,
};
CustomOnboardingLink.defaultProps = {
    match: null,
};

export default CustomOnboardingLink;
