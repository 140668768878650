import React, { useState, useRef, useEffect } from "react";
import { Grid, Typography, Box } from "@mui/material";
// import Button from "bootstrap/js/dist/button";
import { Button } from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import HighlightOffIcon from "@mui/icons-material/Close";
import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory";
import CheckBoxIcon from "@mui/icons-material/Done";
import WarningIcon from "@mui/icons-material/ErrorOutline";
import EventAvailableIcon from "@mui/icons-material/CalendarToday";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { useTheme } from "@mui/system";

export default function TagFilter(props) {
    const _state = props.state_manager;
    const theme = useTheme();

    const [filterHighlight, setFilterHighlight] = useState(localStorage.getItem("filter") || null);

    const [singleFilter, setSingleFilter] = useState({
        value: 0,
        label: "Filter by...",
        type: "",
    });

    // Helpers

    const removeLocalStorage = () => {
        localStorage.removeItem(_state.props.storageKey);
        localStorage.removeItem("filter");
    };

    function resetFilterExperts() {
        removeLocalStorage();
        _state.set.boolFilter("or");
        setFilterHighlight("or");
        _state.set.filterResults({
            ..._state.get.filter,
            id: 0,
            selected: [],
            experts: _state.get.expertsData,
        });
        setSingleFilter({
            value: 0,
            label: "Filter by...",
            type: "",
        });
        if (_state.get.preFilterExpertsData) _state.set.expertsData(_state.get.preFilterExpertsData);
        _state.set.preFilterExpertsData(false);

        _state.flash.success("Default removed");
    }

    const setFilterDefault = () => {
        if (_state.get.filter.selected.length > 0) {
            const filterString = _state.get.filter.selected.join(", ");
            console.log(filterString);
            localStorage.setItem(_state.props.storageKey, filterString);
            localStorage.setItem("filter", _state.get.boolFilter);
            _state.flash.success("Default set");
        } else {
            removeLocalStorage();
            _state.flash.success("Default removed");
        }
    };

    const handleBoolFilters = (event) => {
        const btnValue = event.currentTarget.value;
        console.log(btnValue);
        _state.set.boolFilter(btnValue);
        setFilterHighlight(btnValue);
        btnValue === "or"
            ? filterResultsByOr(null, _state.get.expertsData)
            : filterResultsByAnd(null, _state.get.expertsData);
    };

    const updateTagFilterList = (tid) => {
        if (!_state.get.filter.selected.includes(tid)) {
            console.log("add");
            _state.get.filter.selected.push(tid);
            return null;
        } else {
            console.log("remove");
            const original = _state.get.filter;

            const newArr = _state.get.filter.selected.filter(function (item) {
                return item !== tid;
            });
            console.log(newArr);
            _state.set.filterResults({ ...original, selected: [] });

            return newArr;
        }
    };

    function filterResultsByOr(tid, expertList) {
        let newArr = null;
        if (tid !== null) {
            newArr = updateTagFilterList(tid);
        }

        if (!newArr) newArr = _state.get.filter.selected;

        const filtered = expertList.filter((expert) => expert.tags.find((t) => newArr.includes(t.id)));
        _state.set.filterResults({
            ..._state.get.filter,
            id: tid,
            experts: newArr.length > 0 ? filtered : expertList,
            selected: newArr,
        });
        if (!_state.get.preFilterExpertsData) _state.set.preFilterExpertsData(_state.get.expertsData);

        _state.set.expertsData(newArr.length > 0 ? filtered : expertList);
    }

    const filterResultsByAnd = (tid, expertList) => {
        let newArr = null;

        if (tid !== null) {
            newArr = updateTagFilterList(tid);
        }

        if (!newArr) newArr = _state.get.filter.selected;

        const filtered = expertList.filter((expert) => {
            const expTagArr = expert.tags.map((tag) => {
                return tag.id;
            });
            return newArr.every((value) => {
                return expTagArr.includes(value);
            });
        });
        _state.set.filterResults({
            ..._state.get.filter,
            id: tid,
            experts: newArr.length > 0 ? filtered : expertList,
            selected: newArr,
        });
        if (!_state.get.preFilterExpertsData) _state.set.preFilterExpertsData(_state.get.expertsData);

        _state.set.expertsData(newArr.length > 0 ? filtered : expertList);
    };

    function filterResultsOnLoad(tagArr, localBoolFilter, expertList) {
        _state.get.filter.selected = tagArr;
        localBoolFilter === "or" ? filterResultsByOr(null, expertList) : filterResultsByAnd(null, expertList);
    }

    useEffect(() => {
        if (localStorage.getItem(_state.props.storageKey) !== null) {
            console.log("LocalStorage Valid? ", localStorage.getItem(_state.props.storageKey));
            const tagArray = localStorage
                .getItem(_state.props.storageKey)
                .split(", ")
                .map((tagId) => {
                    return parseInt(tagId);
                });
            const localBoolFilter = localStorage.getItem("filter");
            console.log(localBoolFilter);
            _state.set.boolFilter(localBoolFilter);
            filterResultsOnLoad(tagArray, localBoolFilter, _state.get.projectState.experts);
        }
    }, [_state.get.projectState]);
    return (
        <Grid container>
            <Grid item xs={6}>
                {_state.get.projectState.tags && _state.get.projectState.tags.length > 0 && (
                    <Box
                        sx={{
                            color: "#444",
                            marginBottom: theme.spacing(0.25),
                            fontSize: ".75rem",
                            textAlign: "left",
                            fontWeight: "normal",
                        }}
                    >
                        <Typography
                            sx={{
                                color: "#B1B1B1",
                                marginBottom: theme.spacing(0.25),
                                fontSize: ".75rem",
                                textAlign: "left",
                                fontWeight: "normal",
                                marginTop: ".5rem",
                            }}
                        >
                            Filter Experts By Tag Segment (Angle):{" "}
                            {_state.get.filter.selected.length > 0 && `Selected: ${_state.get.filter.experts.length}`}
                        </Typography>
                        {_state.get.projectState.tags.map(function (t, index) {
                            return (
                                <Button
                                    size="small"
                                    // variant="contained"
                                    onClick={() =>
                                        _state.get.boolFilter === "and"
                                            ? filterResultsByAnd(t.value, _state.get.projectState.experts)
                                            : filterResultsByOr(t.value, _state.get.projectState.experts)
                                    }
                                    sx={
                                        _state.get.filter.selected.includes(t.value)
                                            ? {
                                                  backgroundColor: "#162835",
                                                  color: "white",
                                                  borderRadius: "3px",
                                                  marginRight: "5px",
                                                  marginBottom: "5px",
                                                  fontSize: ".65rem",
                                              }
                                            : {
                                                  border: "1px solid #d5d5d5",
                                                  borderRadius: "3px",
                                                  marginRight: "5px",
                                                  marginBottom: "5px",
                                                  fontSize: ".65rem",
                                                  color: "#162835",
                                              }
                                    }
                                    key={t.value}
                                >
                                    {t.label}
                                </Button>
                            );
                        })}
                        <Button
                            size="small"
                            // variant="contained"
                            onClick={resetFilterExperts}
                            sx={{
                                border: "1px solid #d5d5d5",
                                borderRadius: "3px",
                                marginRight: "5px",
                                marginBottom: "5px",
                                fontSize: ".65rem",
                                color: "#162835",
                            }}
                        >
                            <ReplayIcon sx={{ fontSize: ".85rem" }} /> Reset
                        </Button>
                        <Button
                            size="small"
                            // variant="contained"
                            onClick={setFilterDefault}
                            sx={{
                                border: "1px solid #d5d5d5",
                                borderRadius: "3px",
                                marginRight: "5px",
                                marginBottom: "5px",
                                fontSize: ".65rem",
                                color: "#162835",
                            }}
                        >
                            Set Default
                        </Button>
                        <Button
                            size="small"
                            onClick={handleBoolFilters}
                            value="and"
                            sx={
                                filterHighlight === "and"
                                    ? {
                                          backgroundColor: "#162835",
                                          color: "white",
                                          borderRadius: "3px",
                                          marginRight: "5px",
                                          marginBottom: "5px",
                                          fontSize: ".65rem",
                                      }
                                    : {
                                          border: "1px solid #d5d5d5",
                                          borderRadius: "3px",
                                          marginRight: "5px",
                                          marginBottom: "5px",
                                          fontSize: ".65rem",
                                          color: "#162835",
                                      }
                            }
                        >
                            And Filter
                        </Button>
                        <Button
                            size="small"
                            onClick={handleBoolFilters}
                            value="or"
                            sx={
                                filterHighlight === "or"
                                    ? {
                                          backgroundColor: "#162835",
                                          color: "white",
                                          borderRadius: "3px",
                                          marginRight: "5px",
                                          marginBottom: "5px",
                                          fontSize: ".65rem",
                                      }
                                    : {
                                          border: "1px solid #d5d5d5",
                                          borderRadius: "3px",
                                          marginRight: "5px",
                                          marginBottom: "5px",
                                          fontSize: ".65rem",
                                          color: "#162835",
                                      }
                            }
                        >
                            Or Filter
                        </Button>
                        {/* <Button onClick={() => console.log(_state.get.filter)}>DEBUG</Button> */}
                    </Box>
                )}
            </Grid>
            <Grid item xs={4}>
                <Typography sx={{ fontSize: ".7rem", color: "#888" }}>
                    <MailOutlineIcon sx={{ fontSize: ".75rem", color: "#444" }} /> Sent
                    {"  "}
                    <CheckBoxIcon sx={{ fontSize: ".75rem" }} /> Accepted/Approved/Complete/Interested
                    {"  "}
                    <HighlightOffIcon sx={{ fontSize: ".75rem", color: "red" }} /> Rejected/Declined
                    {"  "}
                    <WarningIcon sx={{ fontSize: ".75rem", color: theme.palette.secondary.main }} /> Scheduling/Payment
                    Needed
                    {"  "}
                    <EventAvailableIcon sx={{ fontSize: ".75rem", color: "green" }} /> Scheduled (Call pending)
                    {"  "}
                    <br />
                    <ChangeHistoryIcon sx={{ fontSize: ".75rem", color: "#444" }} /> Payment Requested
                    {"  "}
                    <NotInterestedIcon sx={{ fontSize: ".75rem", color: "#444" }} /> Restricted
                    <span>😡 Permanently opted out</span>
                    <span>🤔 Opted out of project</span>
                    <span>😴 Snoozed for 30 days</span>
                </Typography>
            </Grid>
        </Grid>
    );
}
