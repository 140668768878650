import React from "react";
import useSWR from "swr";
import { DialogTitle, DialogContent, Button, DialogActions, Dialog, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LinearLoader from "./LinearLoader";
const fetcher = (...args) => fetch(...args).then((res) => res.json());

export default function EditSurveyModal(props) {
    const _state = props.state_manager;
    const data = props.data;
    const error = props.error;
    const { history } = props.props;
    // const data = props.data.data;
    // console.log(props.data);

    function goToSurveyEdit(id) {
        history.push(`/survey/view/${id}`);
    }

    // const data = (useSWR()) // TODO: set data locally
    // TODO - MOVE PARSEPROJECTDATA to backend

    function parseProjectData(pData) {
        if (pData.message && pData.data && pData.data.projectData) {
            // console.log("INside if");
            if (pData.data.projectData.length === 0) return;
            // console.log("FETCHED PROJECT DATA: ", pData.data.projectData);
            // console.log(pData.data.projectData.expertsAccepted, " :: pData.data.data.expertsAccepted");
            const expertCount =
                pData.data.projectData.expertCount.length > 0
                    ? pData.data.projectData.expertCount[0].experts_count
                    : "0";
            const expertsAccepted =
                pData.data.projectData.expertsAccepted.length > 0
                    ? pData.data.projectData.expertsAccepted[0].experts_accepted
                    : "0";
            const screenedCount =
                pData.data.projectData.screenedCount.length > 0
                    ? pData.data.projectData.screenedCount[0].num_experts_screened
                    : "0";
            const onHoldCount =
                pData.data.projectData.onHoldCount.length > 0
                    ? pData.data.projectData.onHoldCount[0].num_experts_on_hold
                    : "0";
            const expertsScheduled =
                pData.data.projectData.expertsScheduled.length > 0
                    ? pData.data.projectData.expertsScheduled[0].experts_scheduled
                    : "0";
            const expertsCompleted =
                pData.data.projectData.expertsCompleted.length > 0
                    ? pData.data.projectData.expertsCompleted[0].experts_completed
                    : "0";
            const sentToClient =
                pData.data.projectData.sentToClient.length > 0
                    ? pData.data.projectData.sentToClient[0].sent_to_client
                    : "0";
            const clientSelected =
                pData.data.projectData.clientSelected.length > 0
                    ? pData.data.projectData.clientSelected[0].client_selected
                    : "0";
            const sentToCompliance =
                pData.data.projectData.sentToCompliance.length > 0
                    ? pData.data.projectData.sentToCompliance[0].sent_to_compliance
                    : "0";
            const project = pData.data.projectData.projectData[0];
            const tags = pData.data.projectData.tags.map((tag) => {
                return { label: tag.name, value: tag.tag_id };
            });
            const specialty = pData.data.projectData.specialty.map((s) => {
                return { label: s.name, value: s.specialty_id };
            });
            const pcc = pData.data.projectData.clientContacts.filter((item) => item.isprimary == true);
            const acc = pData.data.projectData.clientContacts.filter((item) => item.isprimary !== true);
            const apms = pData.data.projectData.additionalProjectManagers;
            const { comments } = pData.data.projectData;

            const settings = pData.data.projectData.confBridgeSettings;
            const primary = settings.filter((item) => item.isprimary !== false);
            let selectedTypeID = "1";
            let selectedTwilio = "1";
            let extNumbers = [];
            if (primary.length > 0) {
                const primaryData = primary[0];
                selectedTypeID = primaryData.type_id.toString();
                if (primaryData.data && primaryData.data.value !== undefined) {
                    selectedTwilio = primaryData.data.value;
                }
                if (Array.isArray(primaryData.data) && primaryData.data.length > 0) {
                    extNumbers = primaryData.data;
                }
            }
            const allSurveys = pData.data.projectData.surveys;
            const surveys = allSurveys.filter((s) => s.published);
            const unpublishedSurveys = allSurveys.filter((s) => !s.published);
            const attachedExperts = pData.data.projectData.experts;
            // console.log("Project DATA: ", project);
            const custom_charge = project.custom_charge;
            const custom_hourly_rate = project.custom_hourly_rate;
            const minimum_bonus = project.minimum_bonus;
            const effort_index = project.effort_index;
            const premium_pricing_model_id = project.premium_pricing_model_id;
            const grace_period = project.grace_period;
            const duration_model_id = project.duration_model_id;
            return {
                ...data,
                id: project.id,
                isdeleted: project.isdeleted,
                title: project.title,
                description: project.description,
                email_copy: project.email_copy,
                project_not_received_by_email: project.project_not_received_by_email,
                client_id: project.client_id,
                client_name: project.client_name,
                primary_client_contact: pcc[0],
                additional_client_contacts: acc,
                expert_ids: project.expert_ids,
                primary_project_manager: `${project.project_manager_fname} ${project.project_manager_lname}`,
                additional_project_managers: apms,
                status: project.project_status,
                status_id: project.project_status_id,
                type: project.project_type,
                priority: project.project_priority,
                case_code: project.case_code,
                calls_planned: project.calls_planned,
                price_per_unit: project.price_per_unit,
                created_on: project.created_on,
                industry: project.industry,
                conference_bridge_setting: selectedTypeID,
                twilio_bridge_setting: selectedTwilio,
                external_bridge_numbers: extNumbers,
                notifications: [],
                specialty,
                tags,
                comments,
                responses: project.responses,
                scheduled_calls: project.scheduled_calls,
                completed_calls: project.completed_calls,
                experts: attachedExperts,
                unpublishedSurveys,
                surveys,
                expertCount,
                expertsAccepted,
                expertsScheduled,
                expertsCompleted,
                sentToClient,
                clientSelected,
                sentToCompliance,
                custom_charge,
                custom_hourly_rate,
                minimum_bonus,
                effort_index,
                premium_pricing_model_id,
                screenedCount,
                onHoldCount,
                grace_period,
                duration_model_id,
            };
        }
    }
    if (!props.data) {
        return <div />;
    } else {
        const parsedData = parseProjectData(props.data);
        // console.log(parsedData);
        return (
            <Dialog
                open={props.open}
                onClose={_state.switch.editSurveyModal}
                fullWidth
                maxWidth="sm"
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={_state.switch.editSurveyModal}
                        sx={{ float: "right" }}
                        aria-label="close"
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                    Unpublished screeners associated with this project
                </DialogTitle>
                <DialogContent>
                    {parsedData.unpublishedSurveys.length < 1 && (
                        <Typography>This project has no surveys associated with it.</Typography>
                    )}
                    {parsedData.unpublishedSurveys.map(function (s, idx) {
                        return (
                            <Button
                                fullWidth
                                key={s.id}
                                sx-={{
                                    color: "#523197",
                                    padding: "0",
                                    textDecoration: "none",
                                    fontSize: "1rem",
                                    fontWeight: "normal",
                                    textTransform: "none",
                                    textAlign: "left",
                                    display: "block",
                                    lineHeight: "120%",
                                    marginBottom: "15px",
                                }}
                                onClick={() => goToSurveyEdit(s.id)}
                            >
                                {s.title}
                            </Button>
                        );
                    })}
                </DialogContent>
                <DialogActions>
                    <Button onClick={_state.switch.editSurveyModal}>Close</Button>
                </DialogActions>
            </Dialog>
        );
    }
}
