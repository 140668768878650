import React, { useEffect, useState } from "react";

// npm imports
import queryString from "query-string";
import axios from "axios";

// custom import
import ExpertSurveyAnswers from "../components/typeahead/ExpertSurveyAnswers";

const TypeaheadLandingPage = (props) => {
    // PROPS
    const pageUrl = props.location.search;
    const { expert_array, survey_id: surveyId, project_id: projectId } = queryString.parse(pageUrl);
    const expertArray = expert_array.split(",");

    // STATE
    const [expertSurveyArray, setExpertSurveyArray] = useState([]);
    const [loadingSurveyData, setLoadingSurveyData] = useState(true);

    // FETCH FUNCS
    async function fetchSurveyData() {
        setLoadingSurveyData(true);
        let results;
        try {
            results = await axios.get(
                `/api/survey/getGroupOfExpertAnswers?survey_id=${surveyId}&project_id=${projectId}&expert_array=${expertArray.join(
                    ","
                )}`
            );
        } catch (error) {
            throw new Error(error);
        }

        // console.log(results?.data?.data, " :: results?.data?.data");
        setExpertSurveyArray(results?.data?.data);
        setLoadingSurveyData(false);
    }

    // PAGE LOAD
    useEffect(() => {
        // console.log("====== useEffect being called in TypeaheadLandingPage ======");
        fetchSurveyData();
    }, [pageUrl]);

    if (loadingSurveyData) {
        return <h1>Loading...</h1>;
    } else {
        return (
            <>
                {!loadingSurveyData && (
                    <ExpertSurveyAnswers projectId={projectId} expertSurveyArray={expertSurveyArray} />
                )}
            </>
        );
    }
};

export default TypeaheadLandingPage;
