import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import Autocomplete from "@mui/material/Autocomplete";
// import FlashMessage from "./FlashMessage";

const resetErrors = () => {
    return [
        {
            name: "clientInfo",
            msg: "",
            active: false,
        },
        {
            name: "fname",
            msg: "",
            active: false,
        },
        {
            name: "lname",
            msg: "",
            active: false,
        },
        {
            name: "email",
            regex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            msg: "",
            active: false,
        },
        {
            name: "phone",
            regex: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
            msg: "",
            active: false,
        },
    ];
};

const defaultContactObj = {
    isdeleted: false,
    accountid: "",
    salutation: "",
    mailingstreet: "",
    mailingcity: "",
    mailingstate: "",
    mailingpostalcode: "",
    mailingcountry: "",
    fax: "",
    mobilephone: "",
    homephone: "",
    otherphone: "",
    assistantphone: "",
    reportstoid: "",
    title: "",
    department: "",
    assistantname: "",
    leadsource: "",
    birthdate: "",
    description: "",
    ownerid: "",
    createddate: null,
    createdbyid: "",
    lastmodifiedbyid: "",
    systemmodstamp: "",
    lastactivitydate: null,
    lastvieweddate: null,
    emailbouncedreason: "",
    emailbounceddate: "",
    isemailbounced: false,
    photourl: "",
    include_in_org_chart__c: false,
    picture__c: "",
    referred_by_email_address__c: "",
    referred_by__c: "",
};

const AddClientDialog = (props) => {
    // console.log(props, " :: props in AddClientDialog");
    const { openDialog, setOpenDialog, generateContactList, clients } = props;
    // const { openDialog, setOpenDialog, contactInfo } = props;
    const [inputValues, setInputValues] = useState({});
    const [errors, setErrors] = useState(resetErrors());
    const [clientList, setClientList] = useState([]);
    const [loading, setLoading] = useState(false);
    // const [openFlashMessage, setOpenFlashMessage] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const clientObj = await axios.get("/api/client/findAll");
            const clientArr = clientObj.data.data;
            const clientList = clientArr.map((client) => {
                return { id: client.id, name: client.name };
            });
            setClientList(clientList);
            setLoading(true);
        };
        fetchData();
    }, []);

    const submitNewContact = () => {
        const isValid = validateInputs();
        if (isValid) {
            buildPostObj();
        }
    };

    const buildPostObj = () => {
        // console.log(inputValues, " :: inputValues");
        const postObj = {
            ...defaultContactObj,
            firstname: inputValues.fname,
            lastname: inputValues.lname,
            email: inputValues.email,
            phone: inputValues.phone,
            client_id: inputValues.clientInfo.id,
            name: `${inputValues.fname} ${inputValues.lname}`,
        };

        // console.log(postObj, " :: postObj");
        axios
            .post("/api/contact/create", postObj)
            .then((result) => {
                console.log(result, " :: result in create client");
                generateContactList();
                resetValues();
                setOpenDialog(false);
            })
            .catch((err) => {
                console.log(err, " :: error in create client");
            });
    };

    const handleInputValues = (event, eventValue) => {
        setErrors(resetErrors());
        if (eventValue === null) {
            const tempObj = { ...inputValues };
            delete tempObj.clientInfo;
            setInputValues(tempObj);
        } else if (eventValue && eventValue !== null) {
            setInputValues({
                ...inputValues,
                clientInfo: eventValue,
            });
        } else {
            const { name, value } = event.target;
            setInputValues({
                ...inputValues,
                [name]: value,
            });
        }
    };

    const resetValues = () => {
        setInputValues({});
        setErrors(resetErrors());
    };

    const handleDialog = (event) => {
        const { value } = event.currentTarget;
        // console.log(value, " :: value");
        if (value === "openClose") {
            if (openDialog) {
                resetValues();
                setOpenDialog(false);
            } else {
                setOpenDialog(true);
            }
        } else if (value === "submit") {
            submitNewContact();
        }
    };

    const validateInputs = () => {
        // console.log(errors, " :: addClientErrors");
        const updatedErrorArr = errors.map((inputField) => {
            const { name, regex } = inputField;
            if (inputValues[name]) {
                if (name === "email") {
                    let isDupe = false;
                    for (const client of clients) {
                        if (client.email == inputValues["email"]) {
                            isDupe = true;
                            break;
                        }
                    }
                    if (isDupe) {
                        return { ...inputField, active: true, msg: "Duplicate Email" };
                    }
                }
                if (regex) {
                    if (!regex.test(inputValues[name])) {
                        return { ...inputField, active: true, msg: `Invalid ${name}` };
                    }
                }
                return inputField;
            } else {
                if (inputField.name !== "phone") {
                    return { ...inputField, active: true, msg: "Cannot be empty" };
                } else {
                    return inputField;
                }
            }
        });
        setErrors(updatedErrorArr);
        let isValid = true;
        // console.log(updatedErrorArr, " :: updatedErrorArr");
        updatedErrorArr.forEach((error) => {
            if (error.active) {
                isValid = false;
            }
        });
        return isValid;
    };

    return (
        <div>
            <Dialog open={openDialog} onClose={handleDialog}>
                <DialogTitle>{`Add Contact`}</DialogTitle>
                <DialogContent>
                    {loading && (
                        <>
                            <Autocomplete
                                onChange={handleInputValues}
                                options={clientList}
                                name="clientInfo"
                                getOptionLabel={(client) => client.name}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        error={errors[0].active}
                                        helperText={errors[0].msg}
                                        label="Client List"
                                    />
                                )}
                            />
                        </>
                    )}
                    <TextField
                        autoFocus
                        margin="dense"
                        name="fname"
                        label="First Name"
                        type="name"
                        fullWidth
                        variant="standard"
                        error={errors[1].active}
                        helperText={errors[1].msg}
                        onChange={handleInputValues}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        name="lname"
                        label="Last Name"
                        type="name"
                        fullWidth
                        variant="standard"
                        error={errors[2].active}
                        helperText={errors[2].msg}
                        onChange={handleInputValues}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        name="email"
                        label="Email Address"
                        type="email"
                        fullWidth
                        variant="standard"
                        error={errors[3].active}
                        helperText={errors[3].msg}
                        onChange={handleInputValues}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        name="phone"
                        label="Phone Num (Not Required)"
                        type="phone"
                        fullWidth
                        variant="standard"
                        error={errors[4].active}
                        helperText={errors[4].msg}
                        onChange={handleInputValues}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialog} value={"openClose"}>
                        Cancel
                    </Button>
                    <Button onClick={handleDialog} value={"submit"}>
                        Create Contact
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AddClientDialog;
