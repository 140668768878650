import React, { useState } from "react";
import axios from "axios";
import SnippetDialog from "../SnippetDialog";

// MUI STYLES
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const ExpertSnippet = (props) => {
    // console.log(props, " :: props ExpertSnippet");
    const [expertData, setExpertData] = useState([]);
    const [haveExpertsLoaded, setHaveExpertsLoaded] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    // console.log(props);
    const handleSnippet = () => {
        axios
            .post("/api/expert/expertSnippet", { expertList: props.expertList })
            .then((result) => {
                if (result?.data?.data?.length > 0) {
                    setExpertData(result?.data?.data);
                    setOpenDialog(true);
                    setHaveExpertsLoaded(true);
                }
            })
            .catch((err) => {
                console.log(err, " :: something wrong with expertSnippet API call");
            });
    };

    const handleDialogClose = () => {
        setExpertData([]);
        setHaveExpertsLoaded(false);
        setOpenDialog(false);
    };

    return (
        <div style={{ display: "grid", placeItems: "center" }}>
            <button
                onClick={handleSnippet}
                css={css`
                    font-size: 0.75rem;
                    font-weight: 500;
                    color: #9a3412;
                    margin: 0;
                    padding: 0;
                    border: none;
                    text-transform: uppercase;
                    background-color: #ffedd5;
                    padding: 0.5rem 1rem;
                    border-radius: 0.2rem;
                    &:hover {
                        background-color: #fed7aa;
                    }
                `}
            >
                {"Generate Snippet"}
            </button>
            {haveExpertsLoaded && (
                <SnippetDialog openDialog={openDialog} handleDialogClose={handleDialogClose} expertData={expertData} />
            )}
        </div>
    );
};

export default ExpertSnippet;
