import React, { useState } from "react";

// Mui - general
import { Typography, Box } from "@mui/material";

// MUI - styles
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

// tabs
import { Tabs, Tab } from "@mui/material";

// npm imports
import PropTypes from "prop-types";

// custom imports
import CallSchedulingCard from "./CallSchedulingCard";

// styles
const columnHeading = css`
    font-size: 0.75rem;
    color: #000;
    text-align: left;
    margin-top: 0;
    margin-bottom: 6px;
`;

const importantDate = css`
    color: #cc0000;
    font-weight: bold;
`;

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const ScheduledCallsTabPanel = (props) => {
    // PROPS
    const { scheduledCalls, completedCalls, isUserAuthorized, handlerFuncs, timezones, projectData } = props;

    // STATE
    const [tabValue, setTabValue] = useState(0);

    // ============
    // HANDLE FUNCS
    // ============

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    // ============
    // RENDER FUNCS
    // ============

    return (
        <div>
            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
                <Tab label="Upcoming" />
                <Tab label="Past" />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
                {scheduledCalls.length > 0 ? (
                    <h5 css={columnHeading}>
                        <span css={importantDate}>* Within 24 hours</span>
                    </h5>
                ) : (
                    <h5 css={columnHeading}>No scheduled calls for this project</h5>
                )}
                {scheduledCalls &&
                    scheduledCalls.map(function (expert, index) {
                        // TODO: write actual code here for isCallUpcoming
                        expert.isCallUpcoming = true;

                        return (
                            <CallSchedulingCard
                                key={index}
                                expertInfo={expert}
                                isUserAuthed={isUserAuthorized}
                                handlerFuncs={handlerFuncs}
                                timezones={timezones}
                                projectData={projectData}
                            />
                        );
                    })}
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                {completedCalls &&
                    completedCalls.map(function (expert, index) {
                        // TODO: write actual code here for isCallUpcoming
                        expert.isCallUpcoming = false;

                        return (
                            <CallSchedulingCard
                                key={index}
                                expertInfo={expert}
                                isUserAuthorized={isUserAuthorized}
                                handlerFuncs={handlerFuncs}
                                timezones={timezones}
                                projectData={projectData}
                            />
                        );
                    })}
            </TabPanel>
        </div>
    );
};

export default ScheduledCallsTabPanel;
