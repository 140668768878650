/* eslint-disable react/prop-types */
import React, { useState, useContext } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import Container from "@mui/material/Container";
import { authContext } from "../context/auth";

const useStyles = makeStyles((theme) => ({
    "@global": {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function PricingTest(props) {
    const classes = useStyles();
    const [data, setData] = useState({
        premium_model: 2,
        duration_model: 1,
        hourly_rate: 100,
        grace_period: 3,
        project_type: 1,
        client_duration: 30,
        price_per_unit: 1000,
        premium_unit: 0.0,
        duration_unit: 0.0,
        final_charge: "",
    });

    const premiumPricingCalculations = (
        premium_pricing_model_id,
        hourly_rate,
        currency_type,
        price_per_unit,
        fair_market_value,
        rate_cap_approved
    ) => {
        if ((!fair_market_value && !hourly_rate) || currency_type !== "USD" || !price_per_unit) {
            return null;
        }
        // If Fair Market Value exists and greater then zero...
        // ...use that instead of the expert's hourly rate.
        // Always defaulted at 1 unit
        let unit = 1;
        let premium = 0;
        const priceIncrement = 200;
        const unitIncrement = 0.25;
        const FMV = fair_market_value && parseInt(fair_market_value, 10) > 0 ? parseInt(fair_market_value, 10) : 0;
        const hourly = hourly_rate && parseInt(hourly_rate, 10) > 0 ? parseInt(hourly_rate, 10) : 0;
        const rate = FMV > 0 && rate_cap_approved ? FMV : hourly;
        switch (parseInt(premium_pricing_model_id, 10)) {
            case 2:
                premium = 500;
                break;
            case 3:
                premium = 550;
                break;
            case 4:
                premium = 600;
                break;
            case 5:
                premium = 650;
                break;
            case 6:
                premium = 700;
                break;
            default:
                // Nothing or Not Applicable
                return unit;
        }
        // If the rate is greater than the selected Premium value...
        // Keep increasing the unit multiplier and premium cap until it's no longer true.
        while (rate > premium) {
            unit = unit + unitIncrement;
            premium = premium + priceIncrement;
        }
        setData({
            ...data,
            premium_unit: unit,
        });
    };

    const durationModelCalculations = (project_type, model, duration, grace_period) => {
        // Set defaults
        let unit = 0.75;
        let firstIncrementStart = 30;
        const baseDefaults = {
            start: 0,
            end: 30,
            minutesIncrement: 15,
            unitIncrement: 0.25,
        };
        const modelID = parseInt(model, 10);
        const projectType = parseInt(project_type, 10);
        const clientDuration = parseInt(duration, 10);
        const gracePeriod = parseInt(grace_period, 10);
        // Project Type and/or Duration Model determines which variables need to change if any
        switch (projectType) {
            case 2:
            case 5:
                // Follow-Up Phone Consultation
                // Follow-Up Written Communication
                // These project types supercede any duration model selected
                unit = 0.25;
                firstIncrementStart = 0;
                baseDefaults.end = 15;
                break;
            default:
                // Defaults to Regular Phone Consultation
                // Set the duration model
                switch (modelID) {
                    case 3:
                        // Serrado
                        unit = 0.5;
                        firstIncrementStart = 15;
                        break;
                    default:
                    // Standard Model or Not Applicable
                }
        }
        let endOfRange = baseDefaults.end + gracePeriod; // variable While Loop iterator
        console.log("CLIENT DURATION: ", clientDuration);
        console.log("BASE UNIT: ", unit);
        console.log("BASE END: ", endOfRange);
        // If duration is greater than the end of the range? Increment the end by variable and unit by .25
        while (clientDuration > endOfRange) {
            unit = unit + baseDefaults.unitIncrement;
            // Determine what the first increment will be...ie. after the first 30+ mins
            if (endOfRange < baseDefaults.end + firstIncrementStart + gracePeriod) {
                // First Increment should be 30 minutes...
                endOfRange = endOfRange + firstIncrementStart;
            } else {
                // Otherwise increment should be 15 minutes...
                endOfRange = endOfRange + baseDefaults.minutesIncrement;
            }
            console.log("END OF RANGE IN LOOP: ", endOfRange);
        }
        console.log("FINAL DURATION UNIT: ", unit);
        // return unit;
        setData({
            ...data,
            duration_unit: unit,
        });
    };

    const finalUnitCalculation = (pricingUnit, durationUnit, pricePerUnit) => {
        let finalCharge = "Unable to calculate";
        const PPU = pricePerUnit && parseInt(pricePerUnit, 10) > 0 ? parseInt(pricePerUnit, 10) : 0;
        const unitX = pricingUnit && parseFloat(pricingUnit, 10) > 0 ? parseFloat(pricingUnit, 10) : 0;
        const unitY = durationUnit && parseFloat(durationUnit, 10) > 0 ? parseFloat(durationUnit, 10) : 0;
        if (unitX > 0 && unitY > 0 && PPU > 0) {
            const finalUnitCharge = unitX - 1 + unitY;
            const finalClientDollarCharge = finalUnitCharge * PPU;
            finalCharge = `${finalUnitCharge} units/${finalClientDollarCharge} dollars`;
        }
        console.log("PRICING UNIT: ", unitX);
        console.log("DURATION UNIT", unitY);
        console.log("FINAL CALCULATION", finalCharge);
        // return finalCharge;
        setData({
            ...data,
            final_charge: finalCharge,
        });
    };

    function handleChange(event) {
        const { target } = event;
        const { value, name } = target;
        setData({
            ...data,
            [name]: value,
        });
    }

    return (
        <Container component="main">
            <Grid container spacing={3}>
                <Grid item sm={12} xs={12}>
                    <Typography component="h1" variant="h5">
                        Pricing/Duration Models Calculator
                    </Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <p>
                        <strong>Project Types</strong>
                    </p>
                    <p>1 = Regular Phone Consultations</p>
                    <p>2 = Follow Up Phone Consultations</p>
                    <p>
                        <strong>Duration Models</strong>
                    </p>
                    <p>2 = Standard</p>
                    <p>3 = Serrado</p>
                    <p>4 = Marwood (Just change grace period to 5)</p>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <p>
                        <strong>Premium Models</strong>
                    </p>
                    <p>2 = Premium 500</p>
                    <p>3 = Premium 550</p>
                    <p>4 = Premium 600</p>
                    <p>5 = Premium 650</p>
                    <p>6 = Premium 700</p>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="premium_model"
                        label="Premium Model"
                        name="premium_model"
                        autoComplete="premium_model"
                        autoFocus
                        value={data.premium_model}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="hourly_rate"
                        label="Hourly Rate"
                        name="hourly_rate"
                        autoComplete="hourly_rate"
                        autoFocus
                        value={data.hourly_rate}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item sm={12} xs={12}>
                    <TextField
                        disabled={true}
                        margin="normal"
                        required
                        fullWidth
                        id="premium_unit"
                        label="Premium Unit Charge"
                        name="premium_unit"
                        autoComplete="premium_unit"
                        autoFocus
                        value={data.premium_unit}
                        // onChange={handleChange}
                    />
                </Grid>
                <Grid item sm={12} xs={12}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={() => premiumPricingCalculations(data.premium_model, data.hourly_rate)}
                    >
                        Calculate Premium Unit
                    </Button>
                </Grid>
                <Grid item sm={3} xs={12}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="project_type"
                        label="Project Type"
                        name="project_type"
                        autoComplete="project_type"
                        autoFocus
                        value={data.project_type}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item sm={3} xs={12}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="duration_model"
                        label="Duration Model"
                        name="duration_model"
                        autoComplete="duration_model"
                        autoFocus
                        value={data.duration_model}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item sm={3} xs={12}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="client_duration"
                        label="Client Duration"
                        name="client_duration"
                        autoComplete="client_duration"
                        autoFocus
                        value={data.client_duration}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item sm={3} xs={12}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="grace_period"
                        label="Grace Period"
                        name="grace_period"
                        autoComplete="grace_period"
                        autoFocus
                        value={data.grace_period}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item sm={12} xs={12}>
                    <TextField
                        disabled={true}
                        margin="normal"
                        required
                        fullWidth
                        id="duration_unit"
                        label="Duration Unit Charge"
                        name="duration_unit"
                        autoComplete="duration_unit"
                        autoFocus
                        value={data.duration_unit}
                        // onChange={handleChange}
                    />
                </Grid>
                <Grid item sm={12} xs={12}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={() =>
                            durationModelCalculations(
                                data.project_type,
                                data.duration_model,
                                data.client_duration,
                                data.grace_period
                            )
                        }
                    >
                        Calculate Duration Unit
                    </Button>
                </Grid>
                <Grid item sm={4} xs={12}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="price_per_unit"
                        label="Price Per Unit"
                        name="price_per_unit"
                        autoComplete="price_per_unit"
                        autoFocus
                        value={data.price_per_unit}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <TextField
                        disabled={true}
                        margin="normal"
                        required
                        fullWidth
                        id="premium_unit"
                        label="Premium Unit Charge"
                        name="premium_unit"
                        autoComplete="premium_unit"
                        autoFocus
                        value={data.premium_unit}
                        // onChange={handleChange}
                    />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <TextField
                        disabled={true}
                        margin="normal"
                        required
                        fullWidth
                        id="duration_unit"
                        label="Duration Unit Charge"
                        name="duration_unit"
                        autoComplete="duration_unit"
                        autoFocus
                        value={data.duration_unit}
                        // onChange={handleChange}
                    />
                </Grid>
                <Grid item sm={12} xs={12}>
                    <TextField
                        disabled={true}
                        margin="normal"
                        required
                        fullWidth
                        id="final_charge"
                        label="Final Calculated Client Charge"
                        name="final_charge"
                        autoComplete="final_charge"
                        autoFocus
                        value={data.final_charge}
                        // onChange={handleChange}
                    />
                </Grid>
                <Grid item sm={12} xs={12}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={() => finalUnitCalculation(data.premium_unit, data.duration_unit, data.price_per_unit)}
                    >
                        Calculate Final Charge
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
}
export default PricingTest;
