import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import Badge from "@mui/material/Badge";
import Drawer from "@mui/material/Drawer";
import MailIcon from "@mui/icons-material/Mail";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import EmojiPeopleOutlinedIcon from "@mui/icons-material/EmojiPeopleOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import HourglassEmptyOutlinedIcon from "@mui/icons-material/HourglassEmptyOutlined";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { authContext } from "../context/auth";
import { basicDateTimeStamp, truncateString, numberFormat } from "../Utils";
import ExpertPreviewWrapperNext from "../components/wrappers/ExpertPreviewWrapperNext";
import RelevantNotes from "../components/RelevantNotes";

export default function ProjectRespondents(props) {
    const { history } = props;
    const [drawerState, setDrawerState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const [activeBtn, setActiveBtn] = useState(true);
    const [openLoader, setOpenLoader] = useState(true);
    const [open, setOpen] = useState(false);
    const [currentExpertId, setCurrentExpertId] = useState(null);
    const [selectedExperts, setSelectedExperts] = useState([]);
    const [screeners, setScreeners] = useState([]);
    const [boxVisible, setBoxVisible] = useState(false);
    const [displaySurveyAnswers, setDisplaySurveyAnswers] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [disqualified, setDisqualified] = useState(false); // t/f - is selected resp. disq.
    const [respondents, setRespondents] = useState({
        ready: [],
        maybe: [],
        senttoclient: [],
        waiting: [],
        declined: [],
    });
    const [surveyRespondent, setSurveyRespondents] = useState({
        expert_id: "",
        fname: "",
        lname: "",
        expert_status: "",
        sent_to_client: false,
        sent_to_client_on: "",
        sent_to_client_by: "",
        headline: "",
        biography: "",
        consultation_rate_currency: "",
        consultation_rate: "",
        mail: "",
        number: "",
        attached_to_survey_on: "",
        attached_to_survey_by: "",
        completed_on: "",
        survey_answers: [],
        pricingUnit: 0,
    });
    const [selectedScreener, setSelectedScreener] = useState("");
    const { auth } = useContext(authContext);
    const loggedInUser = auth.data;

    function navigateToProject() {
        setDisqualified(false);
        // setNoteDisplay("none");
        history.push(`/project/view/${props.match.params.id}`);
    }

    function navigateToSurvey(id) {
        setDisqualified(false);
        //  setNoteDisplay("none");
        history.push(`/survey/view/${id}`);
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setDrawerState({ ...drawerState, [anchor]: open });
    };

    function clearExperts() {
        setSelectedExperts([]);
        setDrawerState({
            ...drawerState,
            left: false,
        });
        setActiveBtn(true);
    }

    function sendToClient() {
        // EXAMPLE URL PARAMS: send=[eid=6696|sid=16],[eid=66286|sid=17]
        const paramsArray = [];
        if (selectedExperts.length > 0) {
            selectedExperts.forEach(function (item) {
                paramsArray.push(
                    `[eid=${item.expert_id}|sid=${item.survey_answers[0].survey_id}|pricing_unit=${item.pricingUnit}]`
                );
            });
        }
        history.push(
            `/project/experts/${
                props.match.params.id
            }?send=${paramsArray.toString()}&onRespondentsPage=true&templateType=postscreen`
        );
    }

    const setDisplayCsv = (checkboxStateToUpdate, surveyId) => {
        axios
            .post("/api/survey/setDisplayCsv", { checkboxStateToUpdate, surveyId })
            .then((result) => {
                setSelectedScreener({ ...selectedScreener, display_on_csv: checkboxStateToUpdate });
            })
            .catch((error) => {
                console.log(error, " :: error updating CSV display");
            });
    };

    const handleDisplayCsv = (event, surveyId) => {
        const { checked } = event.currentTarget;
        setDisplayCsv(checked, surveyId);
    };

    const expertList = (side) => (
        <Box sx={{ width: 400, padding: "10px", marginTop: "80px" }} role="presentation">
            <Grid container spacing={3}>
                <Grid item sm={3} xs={12}>
                    <Button size="small" fullWidth onClick={clearExperts} variant="contained">
                        Clear
                    </Button>
                </Grid>
                <Grid item sm={9} xs={12}>
                    <Button
                        disabled={activeBtn}
                        size="small"
                        color="primary"
                        fullWidth
                        onClick={sendToClient}
                        variant="contained"
                    >
                        Send
                    </Button>
                </Grid>
                <Grid item sm={12} xs={12}>
                    {selectedExperts.map((item) => (
                        <Button
                            variant="outlined"
                            key={item.expert_id}
                            sx={{
                                p: 1,
                                textDecoration: "none",
                                fontSize: ".9rem",
                                fontWeight: "bold",
                                textTransform: "none",
                                display: "block",
                            }}
                            onClick={() => removeFromList(item.expert_id)}
                        >
                            <DeleteForeverOutlinedIcon sx={{ color: "red" }} /> {item.fname} {item.lname}
                        </Button>
                    ))}
                </Grid>
            </Grid>
        </Box>
    );

    const displayExpertPreview = (expertId) => {
        setCurrentExpertId(expertId);
        setOpen(true);
    };

    async function fetchAllScreenersForProject(id) {
        const response = await axios.get(`/api/project/findProjectScreeners/${id}`);
        if (response.status === 200 && response.data && response.data.data) {
            setScreeners(response.data.data);
        }
    }

    async function displaySurveyDetails(survey_id) {
        const response = await axios.get(`/api/survey/getSurveyRespondentsById/${survey_id}`);
        if (response.status === 200 && response.data && response.data.data) {
            setRespondents(response.data.data);
            setBoxVisible(true);
            setDisqualified(false);
            setDisplaySurveyAnswers(false);
        }
    }
    const updateExpertDisplayOnCsv = async (displayExpert, respondent) => {
        const postObj = {
            displayExpert,
            surveyId: respondent.survey_id,
            expertId: respondent.expert_id,
            projectId: respondent.project_id,
        };
        axios.post("/api/survey/updateExpertDisplayOnCsv", postObj).catch((error) => {
            console.log(error?.data?.message, " :: error updateExpertDisplayOnCsv");
        });
    };

    const updateRespondentSurveyStatus = async (r, statusId) => {
        const postData = {
            survey_id: r.survey_id,
            expert_id: r.expert_id,
            person_id: loggedInUser.id,
            project_id: r.project_id,
            status: statusId,
        };
        axios
            .post("/api/survey/updatestatus", postData)
            .then((result) => {
                if (result.status === 200) {
                    fetchAllScreenersForProject(props.match.params.id);
                    displaySurveyDetails(r.survey_id); // reload the survey details to fetch updated data
                } else {
                    // handle unsucessful update
                    console.log(`Good POST, but something went wrong: ${JSON.stringify(data)}`);
                }
            })
            .catch(() => {
                // handle error
                console.log(`API not done yet, 404 Error: ${JSON.stringify(data)}`);
            });
    };

    function openLeftSendList() {
        setDrawerState({
            ...drawerState,
            left: true,
        });
    }

    async function handleMaybe(respondent) {
        if (respondent && !respondent.sent_to_client) {
            updateRespondentSurveyStatus(respondent, 29);
        }
    }

    async function handleNo(respondent) {
        updateExpertDisplayOnCsv(false, respondent);
        if (respondent) {
            updateRespondentSurveyStatus(respondent, 30);
        }
    }

    async function handleSendToClient(expert) {
        updateExpertDisplayOnCsv(true, expert);
        const experts = [...selectedExperts];
        experts.push(expert);
        const uniqList = experts.filter((v, i, a) => a.findIndex((t) => t.expert_id === v.expert_id) === i);
        setSelectedExperts(uniqList);
        if (uniqList.length > 0) {
            setActiveBtn(false);
        }
    }

    function removeFromList(id) {
        const copy = [...selectedExperts];
        const newList = copy.filter((e) => e.expert_id !== id);
        setSelectedExperts(newList);
        if (newList.length < 1) {
            setActiveBtn(true);
        }
    }

    const renderCurrentTabContent = () => {
        switch (tabValue) {
            case 0:
                return renderTabContent(respondents.ready, tabValue);
            case 1:
                return renderTabContent(respondents.maybe, tabValue);
            case 2:
                return renderTabContent(respondents.senttoclient, tabValue);
            case 3:
                return renderTabContent(respondents.waiting, tabValue);
            case 4:
                return renderTabContent(respondents.declined, tabValue);
            default:
                return null;
        }
    };

    const renderIcon = (tabSelected) => {
        switch (tabSelected) {
            case 0:
                return <EmojiPeopleOutlinedIcon sx={{ color: "green" }} />;
            case 1:
                return <EmojiPeopleOutlinedIcon sx={{ color: "red" }} />;
            case 2:
                return <CheckCircleOutlineOutlinedIcon sx={{ color: "green" }} />;
            case 3:
                return <HourglassEmptyOutlinedIcon sx={{ color: "orange" }} />;
            case 4:
                return <ThumbDownOutlinedIcon sx={{ color: "red" }} />;
            default:
                return null;
        }
    };

    const showScreenerAnswers = (respondent) => {
        setDisqualified(false);
        setDisplaySurveyAnswers(false);
        const postData = {
            survey_id: respondent.survey_id,
            expert_id: respondent.expert_id,
            project_id: respondent.project_id,
            consultation_rate_currency: respondent.consultation_rate_currency,
            consultation_rate: respondent.consultation_rate,
            price_per_unit: selectedScreener.price_per_unit,
            premium_pricing_model_id: selectedScreener.premium_pricing_model_id,
        };
        axios.post("/api/survey/getExpertAnswers", postData).then((result) => {
            if (result.status === 200 && result.data) {
                if (result.data.data && result.data.data.length > 0) {
                    const [respondentData, surveyResponses] = result.data.data;
                    const sortedQuestions = surveyResponses.sort((a, b) => a.order - b.order);
                    const pricingUnit = respondentData.pricingUnit;
                    // CHECK DISQUALIFIED
                    let disqualifiedAnswer = false;
                    const disqualifierQuestions = sortedQuestions.filter((q) => q.disqualifier);
                    disqualifierQuestions.length > 0 &&
                        (disqualifiedAnswer = disqualifierQuestions.find((q) =>
                            q.answers.find((a) => a.expert_id !== null && a.disqualifier)
                        ));
                    setDisqualified(disqualifiedAnswer);
                    setSurveyRespondents({
                        ...respondent,
                        survey_answers: sortedQuestions,
                        expert_id: respondent.expert_id,
                        fname: respondent.fname,
                        lname: respondent.lname,
                        expert_status: respondent.expert_status,
                        sent_to_client: respondent.sent_to_client,
                        sent_to_client_on: respondent.sent_to_client_on,
                        sent_to_client_by: respondent.sent_to_client_by,
                        // headline: r.headline,
                        // biography: r.biography,
                        consultation_rate_currency: respondent.consultation_rate_currency,
                        consultation_rate: respondent.consultation_rate,
                        mail: respondentData.email,
                        number: respondentData.phone_num,
                        completed_on: respondent.completed_on,
                        pricingUnit,
                    });

                    setDisplaySurveyAnswers(true);
                    setOpenLoader(false);
                }
            }
        });
    };

    const renderPricingUnit = (pricingUnit, custom_hourly_rate, custom_charge, consultationRate) => {
        if (
            pricingUnit > 1 &&
            (isNaN(custom_hourly_rate) || parseFloat(custom_hourly_rate) === 1.0) &&
            (custom_charge === null || parseInt(custom_charge, 10) === 0)
        ) {
            return (
                <Typography component={"span"} sx={{ color: "#1155cc;", fontSize: ".85rem", fontWeight: "normal" }}>
                    <strong>&nbsp;|&nbsp;Premium Expert - {pricingUnit}x</strong>
                </Typography>
            );
        } else if (custom_charge > 0 || custom_hourly_rate > 1.0) {
            // IF COST PLUS MODEL, SHOW THE RATE
            return (
                <Typography component={"span"} sx={{ color: "green", fontSize: ".85rem", fontWeight: "normal" }}>
                    |&nbsp;(Expert Fee: ${numberFormat(consultationRate)}/hr)
                </Typography>
            );
        }
    };

    const renderSurveyAnswers = (respondent, tabValue) => {
        return (
            <>
                {/* START - RESPONDANT INFO PROFILE  */}
                <Paper
                    elevation={8}
                    sx={{
                        backgroundColor: "#f5f5f5",
                        borderBottom: "4px solid #eaeaea",
                        borderRadius: "3px",
                        textAlign: "left",
                        padding: "20px",
                        marginBottom: "30px",
                    }}
                >
                    {disqualified && (
                        <Typography sx={{ fontSize: ".95rem", fontWeight: "bold", color: "red" }}>
                            <ErrorOutlineOutlinedIcon sx={{ color: "red", fontSize: "2rem", float: "right" }} />
                            DISQUALIFIED
                        </Typography>
                    )}
                    <Typography sx={{ color: "#000", fontSize: ".85rem", fontWeight: "normal", marginBottom: "15px" }}>
                        {respondent.screener_sent_by && `SCREENER SENT BY: ${respondent.screener_sent_by} `}
                        {respondent.screener_sent_on && `on ${basicDateTimeStamp(respondent.screener_sent_on)} | `}
                        {respondent.completed_on && `COMPLETED ON: ${basicDateTimeStamp(respondent.completed_on)}`}
                    </Typography>

                    <Button
                        key={respondent.expert_id}
                        sx={{ color: "#3c8864", fontSize: "1.75rem", fontWeight: "bold" }}
                        onClick={() => displayExpertPreview(respondent.expert_id)}
                    >
                        {respondent.fname} {respondent.lname}
                    </Button>
                    <Typography sx={{ color: "#444", fontSize: "1rem", fontWeight: "normal", fontStyle: "italic" }}>
                        {respondent.headline && `${respondent.headline}`}
                    </Typography>
                    <Typography sx={{ color: "#000", fontSize: ".85rem", fontWeight: "normal", marginBottom: "15px" }}>
                        {respondent.mail && `${respondent.mail} | `}
                        {respondent.number && `${respondent.number}`}
                        {respondent.consultation_rate && (
                            <strong>{` | PROJECT RATE: ${respondent.consultation_rate} ${respondent.consultation_rate_currency}`}</strong>
                        )}
                        {respondent.pricingUnit && <strong>{` | UNITS: ${respondent.pricingUnit}`}</strong>}
                        {renderPricingUnit(
                            respondent.pricingUnit,
                            selectedScreener.custom_hourly_rate,
                            selectedScreener.custom_charge,
                            respondent.consultation_rate
                        )}
                    </Typography>
                    {selectedScreener.custom_charge > 0 && (
                        <Typography
                            sx={{ color: "#000", fontSize: ".85rem", fontWeight: "normal", marginBottom: "15px" }}
                        >
                            {`* COST PLUS HOURLY CHARGE: ${respondent.consultation_rate} + ${
                                selectedScreener.custom_charge
                            } = $${numberFormat(respondent.consultation_rate + selectedScreener.custom_charge)}/hr`}
                        </Typography>
                    )}

                    {respondent.expert_id && (
                        <Grid container spacing={1}>
                            <Grid item sm={4} xs={4}>
                                <Button
                                    size="small"
                                    fullWidth
                                    onClick={() => handleSendToClient(respondent)}
                                    variant="contained"
                                    sx={{ marginTop: "20px", backgroundColor: "success.main" }}
                                    color="primary"
                                >
                                    Yes
                                </Button>
                            </Grid>
                            <Grid item sm={4} xs={4}>
                                <Button
                                    size="small"
                                    fullWidth
                                    onClick={() => handleNo(respondent)}
                                    variant="contained"
                                    color="primary"
                                    sx={{ marginTop: "20px", backgroundColor: "error.main" }}
                                    disabled={tabValue === 4}
                                >
                                    No
                                </Button>
                            </Grid>
                            <Grid item sm={4} xs={4}>
                                <Button
                                    size="small"
                                    fullWidth
                                    onClick={() => handleMaybe(respondent)}
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        marginTop: "20px",
                                        marginRight: "10px",
                                        backgroundColor: "secondary.main",
                                    }}
                                    disabled={tabValue === 1 || tabValue === 2}
                                >
                                    Maybe
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Paper>
                {/* END - RESPONDANT INFO PROFILE  */}
                {/* START - Survey questions and answers  */}
                {respondent.survey_answers.length > 0 &&
                    respondent.survey_answers.map((question, index) => (
                        <Box
                            sx={{ borderBottom: "1px solid #CCC", marginBottom: "20px", paddingBottom: "10px" }}
                            key={question.id}
                        >
                            <Paper
                                elevation={8}
                                sx={{
                                    backgroundColor: "#f5f5f5",
                                    padding: "2px",
                                }}
                            >
                                <Typography
                                    sx={{
                                        marginBottom: "1px",
                                        color: "#325C55",
                                        fontSize: ".75rem",
                                        fontWeight: "normal",
                                        textTransform: "uppercase",
                                    }}
                                >
                                    Question {question.order}
                                </Typography>
                                <Box
                                    sx={{ color: "#000", fontSize: "1rem", m: 0.5 }}
                                    dangerouslySetInnerHTML={{
                                        __html: question.text,
                                    }}
                                />
                            </Paper>
                            {question.answers.map(function (a, idx) {
                                return (
                                    <div key={a.id}>
                                        {a.user_answer &&
                                            (respondent.survey_answers.length === index + 1 ? (
                                                <Paper
                                                    elevation={4}
                                                    sx={{ backgroundColor: "primary.light", m: 1, p: 4 }}
                                                >
                                                    {a.user_answer && a.user_answer !== "true" && `${a.user_answer}`}{" "}
                                                </Paper>
                                            ) : (
                                                <Box
                                                    sx={{
                                                        color: "#325C55",
                                                        fontSize: "1.25rem",
                                                        fontWeight: "normal",
                                                        marginTop: "8px",
                                                        marginBottom: "8px",
                                                        marginLeft: "30px",
                                                        paddingBottom: "10px",
                                                    }}
                                                >
                                                    <strong>{a.label && a.label}</strong>{" "}
                                                    {a.user_answer && a.user_answer !== "true" && `${a.user_answer}`}{" "}
                                                    {question.disqualifier && a.disqualifier && (
                                                        <Typography
                                                            sx={{
                                                                fontSize: ".95rem",
                                                                fontWeight: "bold",
                                                                color: "red",
                                                            }}
                                                        >
                                                            <ErrorOutlineOutlinedIcon
                                                                sx={{ color: "red", fontSize: "2rem", float: "right" }}
                                                            />
                                                            DISQUALIFIED
                                                        </Typography>
                                                    )}
                                                </Box>
                                            ))}
                                        {a.user_answer &&
                                            a.followupquestion &&
                                            a.followupquestion.length > 0 &&
                                            a.followupquestion[0].text !== "" && (
                                                <Box
                                                    sx={{
                                                        fontSize: ".95rem",
                                                        fontWeight: "bold",
                                                        marginLeft: "30px",

                                                        backgroundColor: "#f5f5f5",

                                                        padding: "2px",
                                                        color: "black",
                                                    }}
                                                >
                                                    {a.followupquestion[0].text}{" "}
                                                    {a.followupquestion[0].answers.length > 0 && (
                                                        <Box
                                                            sx={{
                                                                fontSize: ".95rem",
                                                                fontWeight: "normal",
                                                                marginBottom: "8px",
                                                                marginLeft: "30px",
                                                                display: "block",
                                                            }}
                                                        >
                                                            {a.followupquestion[0].answers[0].user_answer}
                                                        </Box>
                                                    )}
                                                </Box>
                                            )}
                                    </div>
                                );
                            })}
                            {question.comments && question.comments !== "" && (
                                <Box
                                    sx={{
                                        fontSize: ".95rem",
                                        fontWeight: "normal",
                                        marginBottom: "8px",
                                        marginLeft: "45px",
                                        display: "block",
                                        color: "black",
                                        fontStyle: "italic",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: ".95rem",
                                            fontWeight: "bold",
                                            marginLeft: "-15px",
                                            color: "black",
                                            padding: "2px",
                                        }}
                                    >
                                        Comments:
                                    </Typography>{" "}
                                    {question.comments}
                                </Box>
                            )}
                        </Box>
                    ))}{" "}
                {open && (
                    <ExpertPreviewWrapperNext
                        open={open}
                        setOpen={setOpen}
                        expert_id={currentExpertId}
                        project_id={props.match.params.id}
                    />
                )}
                <RelevantNotes
                    expertId={respondent.expert_id}
                    projectId={props.match.params.id}
                    setOpenLoader={setOpenLoader}
                    authId={`${auth.data.id}`}
                />
            </>
        );
    };

    const renderTabContent = (respondentArray, tabValue) => {
        return (
            <Grid container spacing={2}>
                <Grid item sm={4} xs={12}>
                    <List
                        sx={{
                            bgcolor: "background.paper",
                        }}
                    >
                        {respondentArray.map((respondent, index) => (
                            <ListItem disablePadding key={`${respondent.expert_id}`}>
                                {tabValue !== 3 ? (
                                    <ListItemButton
                                        key={`Button-ID:${respondent.expert_id}`}
                                        onClick={() => showScreenerAnswers(respondent)}
                                    >
                                        <ListItemIcon>{renderIcon(tabValue)}</ListItemIcon>
                                        <ListItemText>
                                            {`${respondent.fname} ${respondent.lname}`} :{" "}
                                            <Typography
                                                sx={{ color: "secondary.main", fontSize: ".60rem", fontWeight: "bold" }}
                                            >
                                                {respondent.completed_on &&
                                                    `COMPLETED : ${basicDateTimeStamp(respondent.completed_on)}`}
                                            </Typography>
                                        </ListItemText>
                                    </ListItemButton>
                                ) : (
                                    <>
                                        <ListItemIcon>{renderIcon(tabValue)}</ListItemIcon>
                                        <ListItemText>{`${respondent.fname} ${respondent.lname}`}</ListItemText>
                                    </>
                                )}
                            </ListItem>
                        ))}
                    </List>
                </Grid>
                <Grid item sm={8} xs={12}>
                    {displaySurveyAnswers &&
                        surveyRespondent.survey_answers.length > 0 &&
                        renderSurveyAnswers(surveyRespondent, tabValue)}
                </Grid>
            </Grid>
        );
    };

    const handleTabChange = (event, newValue) => {
        setDisplaySurveyAnswers(false);
        setTabValue(newValue);
    };

    useEffect(() => {
        if (props.match.params.id) {
            fetchAllScreenersForProject(props.match.params.id);
        }
    }, []);
    return (
        <Box sx={{ width: "100%" }}>
            <Drawer open={drawerState.left} onClose={toggleDrawer("left", false)}>
                {expertList("left")}
            </Drawer>
            <Box>
                <Grid container spacing={2}>
                    <Grid item sm={12} xs={12}>
                        <Button
                            size="medium"
                            color="primary"
                            onClick={openLeftSendList}
                            variant="contained"
                            sx={{ float: "right" }}
                        >
                            Send to client
                            <Badge badgeContent={selectedExperts.length} color="secondary" />
                        </Button>
                        {screeners.length > 0 && (
                            <Button
                                sx={{
                                    color: "#523197",
                                    padding: "0",
                                    textDecoration: "none",
                                    fontSize: "1.75rem",
                                    fontWeight: "normal",
                                    textTransform: "none",
                                }}
                                onClick={navigateToProject}
                            >
                                {screeners[0].project_title}
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    m: 2,
                    width: "100%",
                }}
            >
                <Card sx={{ minWidth: 275, m: 2 }}>
                    <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            select a screener to see the respondents
                        </Typography>
                        <List
                            sx={{
                                bgcolor: "background.paper",
                            }}
                        >
                            {screeners.map((s) => (
                                <ListItem disablePadding key={`${s.survey_id}`}>
                                    <ListItemButton
                                        key={`Button-ID:${s.survey_id}`}
                                        onClick={() => {
                                            displaySurveyDetails(s.survey_id);
                                            setSelectedScreener(s);
                                        }}
                                    >
                                        <ListItemIcon>
                                            {s.ready_count > 0 ? (
                                                <Badge badgeContent={s.ready_count} color="secondary">
                                                    <MailIcon sx={{ color: "primary.main" }} />
                                                </Badge>
                                            ) : (
                                                <MailIcon />
                                            )}
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={`Screener Id:(${s.survey_id}) ${truncateString(s.title, 40)}`}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </CardContent>
                </Card>
            </Box>
            {boxVisible && (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        m: 2,
                        width: "100%",
                    }}
                >
                    <Card sx={{ width: "100%" }}>
                        <CardContent>
                            <FormGroup>
                                <FormControlLabel
                                    sx={{
                                        fontSize: "1.25rem",
                                        color: "#000",
                                        textAlign: "left",
                                        fontWeight: "normal",
                                        marginBottom: "0px",
                                        borderBottom: "1px solid #f5f5f5",
                                    }}
                                    control={
                                        <Checkbox
                                            sx={{ color: "primary.main" }}
                                            checked={selectedScreener.display_on_csv}
                                            onChange={(event) => handleDisplayCsv(event, selectedScreener.survey_id)}
                                        />
                                    }
                                    label="Display CSV"
                                />
                            </FormGroup>
                            <Typography
                                sx={{
                                    fontSize: "1.25rem",
                                    color: "#000",
                                    textAlign: "left",
                                    fontWeight: "normal",
                                    marginBottom: "0px",
                                    borderBottom: "1px solid #f5f5f5",
                                }}
                            >
                                <Button onClick={() => navigateToSurvey(selectedScreener.survey_id)} color="primary">
                                    {selectedScreener.title}
                                </Button>
                            </Typography>

                            <Tabs
                                value={tabValue}
                                onChange={handleTabChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example"
                            >
                                <Tab label="READY" />
                                <Tab label="MAYBE" />
                                <Tab label="SENT TO CLIENT" />
                                <Tab label="WAITING" />
                                <Tab label="DECLINED" />
                            </Tabs>
                            {renderCurrentTabContent()}
                        </CardContent>
                    </Card>
                </Box>
            )}
        </Box>
    );
}
