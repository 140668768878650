// React Imports
import React, { useState } from "react";

// styles
import makeStyles from "@mui/styles/makeStyles";

// npm imports
import PropTypes from "prop-types";

// MUI
// Table imports
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    FormControlLabel,
} from "@mui/material";

// general imports
import { Checkbox, Paper } from "@mui/material";

/**
 * TABLE DATA
 */

// headcells, that show structure (also need replacing)
const headCells = [
    { id: "expertName", numeric: false, disablePadding: false, label: "Name", isSortable: true },
    { id: "surveys", numeric: false, disablePadding: false, label: "Associated Surveys", isSortable: false },
];

// SORTING FUNCS
// sorting functions
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

// ENHANCED TABLE HEAD
function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    const renderTableHeadCell = (headCell) => {
        if (headCell.isSortable) {
            return (
                <TableCell
                    key={headCell.id}
                    align={"left"}
                    padding={"normal"}
                    sortDirection={orderBy === headCell.id ? order : false}
                >
                    <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={createSortHandler(headCell.id)}
                        hideSortIcon={headCell.sortable}
                    >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                            <span className={classes.visuallyHidden}>
                                {order === "desc" ? "sorted descending" : "sorted ascending"}
                            </span>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
            );
        } else {
            return (
                <TableCell key={headCell.id} align={"left"}>
                    {headCell.label}
                </TableCell>
            );
        }
    };

    return (
        <TableHead>
            <TableRow>
                {/* <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                    />
                </TableCell> */}
                <TableCell></TableCell>
                {headCells.map((headCell) =>
                    headCell.isSortable ? (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? "right" : "left"}
                            padding={headCell.disablePadding ? "none" : "normal"}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ) : (
                        <TableCell key={headCell.id} align={"left"}>
                            {headCell.label}
                        </TableCell>
                    )
                )}
                {/* NOTE::if you add non sorted table cells, be sure to add key */}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
}));

export default function EnhancedTable(props) {
    // props
    const { setExpertAndSurveyData, rows } = props;

    // state
    const classes = useStyles();
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("expertName");
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    // HANDLE FUNCS
    const handleExpertClick = (event, expertId, expertMarkedAsSent) => {
        // iterate through the rows array and find the expert id being clicked on
        // then set the markedAsSent flag to opposite of current value
        const newRowsArr = rows.map((expert) => {
            // if we find the right row based on expertId
            if (expert.expertId == expertId) {
                // check if the expert has surveys attached and if we're switching
                // the checkbox back to false
                // if so we're going to set all associated surveys to false
                // this is just for UI purposes and cleaning the data
                if (expert?.surveys?.length > 0 && expertMarkedAsSent) {
                    // surveys all switched to not being sent to client
                    const newSurveysArr = expert.surveys.map((survey) => {
                        return { ...survey, markedAsSent: false };
                    });

                    // return expert w/ surveys updated
                    return { ...expert, markedAsSent: !expertMarkedAsSent, surveys: newSurveysArr };
                } else {
                    // just return expert
                    return { ...expert, markedAsSent: !expertMarkedAsSent };
                }
            } else {
                return { ...expert };
            }
        });

        setExpertAndSurveyData(newRowsArr);
    };

    const handleSurveyClick = (event, surveyId, expertId, surveyMarkedAsSent) => {
        // iterate over the experts to get to the surveys
        const newRowsArr = rows.map((expert) => {
            if (expert.expertId == expertId) {
                const updatedSurveyArr = expert.surveys.map((survey) => {
                    // if we find the right survey, we switch the checkbox state
                    if (survey.surveyId == surveyId) {
                        return { ...survey, markedAsSent: !surveyMarkedAsSent };
                    }
                });

                // return updated surveys
                return { ...expert, surveys: updatedSurveyArr };
            } else {
                return { ...expert };
            }
        });

        setExpertAndSurveyData(newRowsArr);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? "small" : "medium"}
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const { expertId, expertName, surveys, markedAsSent: expertMarkedAsSent } = row;
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={expertMarkedAsSent}
                                                    inputProps={{ "aria-labelledby": labelId }}
                                                    onClick={(event) =>
                                                        handleExpertClick(event, expertId, expertMarkedAsSent)
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell component="th" id={labelId} scope="row" align="left">
                                                {expertName}
                                            </TableCell>
                                            <TableCell align="left">
                                                {surveys?.length > 0
                                                    ? surveys.map((survey, index) => {
                                                          const {
                                                              surveyName,
                                                              surveyId,
                                                              markedAsSent: surveyMarkedAsSent,
                                                          } = survey;
                                                          return (
                                                              <FormControlLabel
                                                                  key={index}
                                                                  disabled={!expertMarkedAsSent}
                                                                  control={
                                                                      <Checkbox
                                                                          checked={surveyMarkedAsSent}
                                                                          onClick={(event) =>
                                                                              handleSurveyClick(
                                                                                  event,
                                                                                  surveyId,
                                                                                  expertId,
                                                                                  surveyMarkedAsSent
                                                                              )
                                                                          }
                                                                      />
                                                                  }
                                                                  label={`${surveyName}`}
                                                              />
                                                          );
                                                      })
                                                    : "No Surveys Available"}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}
