import * as React from "react";
import MUIDataTable, { ExpandButton } from "mui-datatables";
import { Link } from "react-router-dom";
import { TableCell, TableRow } from "@mui/material";
import ExpertDataTable from "../../payment-request/ExpertDataTable";
import { basicDateTimeStamp, numberWithCommas } from "../../../Utils";
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

// ======
// STYLES
// ======

const nameStyle = css`
    font-size: 1rem;
    font-weight: 700;
    text-decoration: none;
    color: #523197;
    &:hover {
        color: #8966ad;
    }
`;

const approvedStyle = css`
    font-size: 0.85rem;
    font-weight: 600;
    text-decoration: none;
    color: #4fab6a;
`;

export default function InvoicePreviewTable({ invoicePreviewData }) {
    // ============
    // RENDER FUNCS
    // ============

    function renderExpertRow(row) {
        // console.log("====== are we rendering a row ======");
        const { expert_data } = row;
        if (expert_data) {
            const data = expert_data.map((expert) => {
                return {
                    expert_id: expert.expert_id,
                    expert_name: expert.expert_name,
                    price_per_unit: expert.price_per_unit,
                    unit_charge_amount: expert.unit_charge_amount,
                    expert_hourly_to_client: expert.expert_hourly_to_client,
                    client_charge_amount: expert.client_charge_amount,
                    client_duration: expert.client_duration,
                    approved_by_name: expert.approved_by_name,
                    approved_on: basicDateTimeStamp(expert.approved_on),
                    current_employment: expert.current_employment,
                    invoicing_notes: expert.invoicing_notes,
                };
            });

            return <ExpertDataTable rows={data} />;
        } else {
            return (
                <div>
                    <b>ERROR:</b> No Experts Associated With This Invoice ⚠️
                </div>
            );
        }
    }

    // =======
    // OPTIONS
    // =======

    const expandableOptions = {
        print: false,
        rowsPerPage: 100,
        viewColumns: false,
        selectableRows: "none",
        expandableRows: true,
        expandableRowsHeader: false,
        expandableRowsOnClick: true,
        download: false,
        renderExpandableRow: (rowData, { dataIndex, rowIndex }) => {
            const row = invoicePreviewData[dataIndex];
            const colSpan = rowData.length + 1;
            return (
                <TableRow>
                    <TableCell colSpan={colSpan}>{renderExpertRow(row)}</TableCell>
                </TableRow>
            );
        },
    };

    // ==========
    // COMPONENTS
    // ==========

    const components = {
        ExpandButton: function (props) {
            return <ExpandButton {...props} />;
        },
    };

    // =======
    // COLUMNS
    // =======

    const invoicePreviewCols = [
        {
            name: "invoice_created_on",
            label: "Invoice Date",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { invoice_created_on, invoice_created_by_name, invoice_updated_on, invoice_updated_by_name } =
                        invoicePreviewData?.[dataIndex];

                    return (
                        <div>
                            <strong>Invoice Created: </strong>
                            {basicDateTimeStamp(invoice_created_on)} <br />
                            <b>by</b> {invoice_created_by_name}
                            <br />
                            {invoice_updated_on ? (
                                <span css={approvedStyle}>
                                    <strong>Invoice Updated On: </strong>
                                    {basicDateTimeStamp(invoice_updated_on)} <br /> <b>by</b> {invoice_updated_by_name}
                                </span>
                            ) : (
                                ""
                            )}
                        </div>
                    );
                },
            },
        },
        {
            name: "project_title",
            label: "Project",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    const { project_title, project_id, project_type } = invoicePreviewData?.[dataIndex];

                    return (
                        <div>
                            <Link css={nameStyle} to={`/project/pricing/${project_id}`}>
                                {project_title}
                            </Link>
                            <br />
                            {project_type}
                        </div>
                    );
                },
            },
        },
        {
            name: "case_code",
            label: "Code",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { case_code } = invoicePreviewData?.[dataIndex];

                    return <div>{case_code}</div>;
                },
            },
        },
        {
            name: "client_name",
            label: "Client",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const {
                        client_name,
                        client_id,
                        primary_client_contact_name,
                        primary_client_contact_email,
                        additional_client_emails,
                    } = invoicePreviewData?.[dataIndex];

                    return (
                        <div>
                            <Link css={nameStyle} to={`/client/view/${client_id}`}>
                                {client_name}
                            </Link>
                            <br />
                            Contact: {primary_client_contact_name}
                            <br />
                            Email: {primary_client_contact_email}
                            {additional_client_emails &&
                                additional_client_emails.split(",").map((email, index) => {
                                    if (index === 0) {
                                        return <div key={index}>Additional Emails: {email}</div>;
                                    } else {
                                        return <div key={index}>{email}</div>;
                                    }
                                })}
                        </div>
                    );
                },
            },
        },
        {
            name: "amount_due",
            label: "Amount Due",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    const { amount_due } = invoicePreviewData?.[dataIndex];

                    return (
                        <div>
                            <strong>${numberWithCommas(amount_due)}</strong>
                        </div>
                    );
                },
            },
        },
    ];

    return (
        <MUIDataTable
            data={invoicePreviewData}
            columns={invoicePreviewCols}
            options={expandableOptions}
            components={components}
        />
    );
}
