import React, { useContext } from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import clsx from "clsx";
import Container from "@mui/material/Container";
import makeStyles from "@mui/styles/makeStyles";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import PeopleIcon from "@mui/icons-material/People";
import ContactsIcon from "@mui/icons-material/Contacts";
import AssignmentIcon from "@mui/icons-material/Assignment";
import Grid from "@mui/material/Grid";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { mainListItems, secondaryListItems, privateListItems } from "./MenuList";
import Home from "./pages/DashboardNext";
import Expert from "./pages/Expert";
import ExpertList from "./pages/ExpertList";
import Client from "./pages/Client";
import ClientList from "./pages/ClientList";
import ClientShow from "./pages/ClientShow";
import ContactList from "./pages/ContactList";
import ContactShow from "./pages/ContactShow";
import Contact from "./pages/Contact";
import Project from "./pages/Project";
import ProjectList from "./pages/ProjectList";
import ProjectShow from "./pages/ProjectShow";
import ProjectExperts from "./pages/ProjectExperts";
import ProjectEmails from "./pages/ProjectEmails";
import ProjectSchedule from "./pages/ProjectSchedule";
import ProjectCalls from "./pages/ProjectCalls";
import ProjectRespondents from "./pages/ProjectRespondents";
import SurveyShow from "./pages/SurveyShow";
import SurveyNew from "./pages/SurveyNew";
import Survey from "./pages/Survey";
import Admin from "./pages/Admin";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import User from "./pages/User";
import Tags from "./pages/Tags";
import Specialty from "./pages/Specialty";
import EmailVerifier from "./pages/EmailVerifier";
import AdvancedSearch from "./pages/AdvancedSearch-v2";
import PricingTest from "./pages/PricingTest";
import TeamMetrics from "./pages/TeamMetrics";
import SaveSearch from "./pages/SaveSearch";
import NavBar from "./components/NavBar";
import Banner from "./components/Banner";
import { authContext } from "./context/auth";
import PrivateRoute from "./PrivateRoute";
import logo from "./img/firstthought-logo.png";
import "./App.css";
import CallApprovalDashboard from "./pages/CallApprovalDashboard";
import Dialer from "./pages/Dialer";
import ProjectPricing from "./pages/ProjectPricing";
import TypeaheadLandingPage from "./pages/TypeaheadLandingPage";
import PaymentRequest from "./pages/PaymentRequest";
import PaymentAccount from "./pages/PaymentAccount";
import ProjectNext from "./pages/ProjectNext";
import { ErrorBoundary } from "react-error-boundary";
// TODO: fix up the fallback component
import FallbackComponent from "./components/utility-components/FallbackComponent";
import ExpertShowNext from "./pages/ExpertShowNext";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    username: {
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(0.5),
        fontSize: ".65rem",
    },
    usernameAvatar: {
        color: "#FFF",
        backgroundColor: "#523197",
    },
    avatarBtn: {
        paddingLeft: "7px",
        marginLeft: "0",
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 8px",
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        background: "linear-gradient(-45deg, #835cc8, #523197, #1e0668, #835cc8)",
        backgroundSize: "200% 200%",
        animation: "$gradientBG 15s ease infinite",
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    "@keyframes gradientBG": {
        "0%": {
            backgroundPosition: "0% 50%",
        },
        "50%": {
            backgroundPosition: "100% 50%",
        },
        "100%": {
            backgroundPosition: "0% 50%",
        },
    },
    appBarShift: {
        backgroundColor: "#385469",
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: "none",
    },
    title: {
        flexGrow: 1,
        marginTop: "15px",
    },
    iconLink: {
        fontSize: "1.25em",
        color: "#D1C0E2",
    },
    navLink: {
        fontSize: ".65em",
        textTransform: "uppercase",
    },
    drawerPaper: {
        backgroundColor: "#f8f8f8",
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    fixedHeight: {
        height: 240,
    },
}));
let loggedInUser;

function App() {
    const { setAuthData, auth } = useContext(authContext);
    if (auth) {
        loggedInUser = auth.data;
    }
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    function logOut() {
        setAuthData(null);
    }
    function createInitialForAvatar(loggedInUser) {
        return `${loggedInUser.fname.charAt(0)}${loggedInUser.lname.charAt(0)}`;
    }

    return (
        <div className={classes.root}>
            <Router>
                <CssBaseline />
                <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                    <Toolbar className={classes.toolbar}>
                        <IconButton
                            edge="start"
                            title="Expand"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                            size="large"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                            <img src={logo} height="35" alt="FirstThought Logo" />
                        </Typography>
                        <IconButton
                            color="inherit"
                            className={classes.navLink}
                            component={Link}
                            to="/search"
                            size="large"
                        >
                            <SearchIcon className={classes.iconLink} /> &nbsp; Advanced Search
                        </IconButton>
                        <IconButton
                            color="inherit"
                            className={classes.navLink}
                            component={Link}
                            to="/expert/add"
                            size="large"
                        >
                            <PeopleIcon className={classes.iconLink} /> &nbsp; Add Expert
                        </IconButton>
                        <IconButton
                            color="inherit"
                            className={classes.navLink}
                            component={Link}
                            to="/contact/add"
                            size="large"
                        >
                            <ContactsIcon className={classes.iconLink} /> &nbsp; Add Contact
                        </IconButton>
                        <IconButton
                            color="inherit"
                            className={classes.navLink}
                            component={Link}
                            to="/project/create"
                            size="large"
                        >
                            <AssignmentIcon className={classes.iconLink} /> &nbsp; Create Project
                        </IconButton>
                        {loggedInUser && <NavBar />}
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="permanent"
                    classes={{
                        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                    }}
                    open={open}
                >
                    <div className={classes.toolbarIcon}>
                        <IconButton onClick={handleDrawerClose} size="large">
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    {loggedInUser && (
                        <List>
                            <ListItem className={classes.avatarBtn}>
                                <ListItemIcon>
                                    <IconButton
                                        title="Your Profile (Edit)"
                                        color="inherit"
                                        component={Link}
                                        to={`/user/edit/${loggedInUser.id}`}
                                        size="large"
                                    >
                                        <Avatar className={classes.usernameAvatar}>
                                            {createInitialForAvatar(loggedInUser)}
                                        </Avatar>
                                    </IconButton>
                                </ListItemIcon>
                                <ListItemText>
                                    <span className={classes.username}>{loggedInUser.username}</span>
                                    <br />
                                    <a href="/" className={classes.username} onClick={logOut}>
                                        log out
                                    </a>
                                </ListItemText>
                            </ListItem>
                            <Divider />
                        </List>
                    )}
                    <List>{mainListItems}</List>
                    <List>{privateListItems}</List>
                    <Divider />
                    <List>{secondaryListItems}</List>
                </Drawer>
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    {loggedInUser && <Banner />}
                    <Container maxWidth={false} className={classes.container}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Container maxWidth={false}>
                                    <Switch>
                                        <PrivateRoute exact path="/" component={Home} />
                                        <Route exact path="/login" component={Login} />
                                        <Route exact path="/signup" component={Signup} />
                                        <PrivateRoute exact path="/admin" component={Admin} />
                                        <PrivateRoute exact path="/user/add" component={User} />
                                        <PrivateRoute exact path="/project" component={ProjectList} />
                                        <PrivateRoute exact path="/project/create" component={Project} />
                                        <PrivateRoute exact path="/project/edit/:id" component={Project} />
                                        <PrivateRoute exact path="/project/legacy/:id" component={ProjectShow} />
                                        <PrivateRoute exact path="/project/experts/:id" component={ProjectExperts} />
                                        <PrivateRoute exact path="/project/emails/:id" component={ProjectEmails} />
                                        <PrivateRoute exact path="/project/schedule/:id" component={ProjectSchedule} />
                                        <PrivateRoute exact path="/project/pricing/:id" component={ProjectPricing} />
                                        <PrivateRoute
                                            exact
                                            path="/project/respondents/:id"
                                            component={ProjectRespondents}
                                        />
                                        <PrivateRoute exact path="/project/calls/:id" component={ProjectCalls} />
                                        <PrivateRoute exact path="/project/survey/new/:id" component={SurveyNew} />
                                        <PrivateRoute exact path="/survey/view/:id" component={SurveyShow} />
                                        <PrivateRoute exact path="/survey/edit/:id" component={Survey} />
                                        <PrivateRoute exact path="/expert/add" component={Expert} />
                                        <PrivateRoute exact path="/contact/add" component={Contact} />
                                        <PrivateRoute exact path="/user/edit/:id" component={User} />
                                        <PrivateRoute exact path="/expert/edit/:id" component={Expert} />
                                        <PrivateRoute exact path="/expert" component={ExpertList} />
                                        <PrivateRoute exact path="/client/view/:id" component={ClientShow} />
                                        <PrivateRoute exact path="/expert/payment/add/:id" component={PaymentAccount} />
                                        <PrivateRoute exact path="/contact" component={ContactList} />
                                        <PrivateRoute exact path="/contact/view/:id" component={ContactShow} />
                                        <PrivateRoute exact path="/contact/edit/:id" component={Contact} />
                                        <PrivateRoute exact path="/client" component={ClientList} />
                                        <PrivateRoute exact path="/client/edit/:id" component={Client} />
                                        <PrivateRoute exact path="/tags" component={Tags} />
                                        <PrivateRoute exact path="/specialty" component={Specialty} />
                                        <PrivateRoute exact path="/verifier" component={EmailVerifier} />
                                        <PrivateRoute exact path="/search" component={AdvancedSearch} />
                                        <PrivateRoute
                                            exact
                                            path="/typeahead/surveys"
                                            component={TypeaheadLandingPage}
                                        />
                                        <PrivateRoute exact path="/team" component={TeamMetrics} />
                                        <PrivateRoute exact path="/searches" component={SaveSearch} />
                                        <PrivateRoute exact path="/callappproval" component={CallApprovalDashboard} />
                                        <PrivateRoute exact path="/dialer" component={Dialer} />
                                        <PrivateRoute exact path="/pricing" component={PricingTest} />
                                        <PrivateRoute exact path="/accounting" component={PaymentRequest} />
                                        {/* NEW PROJECT ROUTE W/ ERROR BOUNDARY */}
                                        <ErrorBoundary
                                            FallbackComponent={FallbackComponent}
                                            onReset={() => {
                                                // handle reset here
                                            }}
                                        >
                                            <PrivateRoute exact path="/expert/view/:id" component={ExpertShowNext} />
                                            <PrivateRoute exact path="/project/view/:id" component={ProjectNext} />
                                        </ErrorBoundary>
                                        <Route component={Login} />
                                    </Switch>
                                </Container>
                            </Grid>
                        </Grid>
                    </Container>
                </main>
            </Router>
        </div>
    );
}

export default App;
