import * as React from "react";
import axios from "axios";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Link } from "react-router-dom";

// STYLES
const questionStyle = css`
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
    padding-bottom: 10px;
`;

const questionWrapperStyle = css`
    border-bottom: 1px solid #325c55;
    background-color: #f5f5f5;
    border-radius: 3px;
    padding: 2px;
`;

const questionOrderStyle = css`
    margin-bottom: 1px;
    color: #325c55;
    font-size: 0.75rem;
    font-weight: normal;
    text-transform: uppercase;
`;

const questionTextStyle = css`
    color: #000;
    font-size: 1rem;
    margin-bottom: 1px;
`;

const questionAnswerStyle = css`
    color: #325c55;
    font-size: 1.25rem;
    font-weight: normal;
    margin-bottom: 8px;
    margin-left: 30px;
`;

const disqualifierStyle = css`
    font-size: 0.95rem;
    font-weight: bold;
    color: red;
`;

const followupTextStyle = css`
    font-size: 0.95rem;
    font-weight: bold;
    margin-left: 30px;
    background-color: #f5f5f5;
    padding: 2px;
    color: black;
`;

const followupAnswerStyle = css`
    font-size: 0.95rem;
    font-weight: normal;
    margin-bottom: 8px;
    margin-left: 30px;
    display: block;
`;

const commentTextStyle = css`
    font-size: 0.95rem;
    font-weight: bold;
    margin-left: -15px;
    color: black;
    padding: 2px;
`;

const commentStyle = css`
    font-size: 0.95rem;
    font-weight: normal;
    margin-bottom: 8px;
    margin-left: 45px;
    display: block;
    color: black;
`;

const surveyTitleStyle = css`
    margin-bottom: 0.75rem;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    letter-spacing: 1.1px;
    display: block;
    text-decoration: none;
    color: #8966ad;
`;

export default function SurveyAnswers(props) {
    // console.log(props.answerData, " :: props.answerData");
    // PROPS
    const { surveys, expert_id, project_id } = props.answerData;

    // STATE
    const [surveyData, setSurveyData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    // PAGE LOAD
    async function loadAnswers() {
        let surveyArr;
        if (Array.isArray(surveys)) {
            console.log("====== is Array ======");
            surveyArr = surveys;
        } else {
            console.log("====== not Array ======");
            surveyArr = [...Object.values(surveys)];
        }
        // console.log(surveyArr, " :: surveyArr");
        const responseAnswerArr = [];
        for (const survey of surveyArr) {
            if (survey?.survey_completed) {
                await axios
                    .post(`/api/survey/getExpertAnswers`, { project_id, survey_id: survey?.survey_id, expert_id })
                    .then((r) => {
                        // console.log(r, " :: r");
                        if (r?.data?.data?.length === 2) {
                            responseAnswerArr.push({
                                id: survey?.survey_id,
                                title: survey?.survey_name,
                                answers: r?.data?.data?.[1],
                            });
                        }
                    })
                    .catch((e) => {
                        console.log(e, " :: e");
                    });
            }
        }
        // console.log(responseAnswerArr, " :: responseAnswerArr");
        setSurveyData([...responseAnswerArr]);
        setLoading(false);
    }

    React.useEffect(() => {
        loadAnswers();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    } else if (surveyData.length === 0) {
        return <div>No Surveys...</div>;
    } else {
        return (
            <>
                {surveyData.map((s) => {
                    // console.log(s, " :: s");
                    return (
                        <Box key={s.id}>
                            <Link target="_blank" to={`/project/respondents/${project_id}`} css={surveyTitleStyle}>
                                {s.title}
                            </Link>
                            {s.answers &&
                                s.answers.length > 0 &&
                                s.answers.map((question) => {
                                    // console.log(question, " :: question");
                                    return (
                                        <div css={questionStyle} key={question.id}>
                                            <div css={questionWrapperStyle}>
                                                <Typography css={questionOrderStyle}>
                                                    Question {question.order}
                                                </Typography>
                                                <div
                                                    css={questionTextStyle}
                                                    dangerouslySetInnerHTML={{
                                                        __html: question.text,
                                                    }}
                                                />
                                            </div>
                                            {question.answers.map(function (a, idx) {
                                                // console.log(a, " :: a");
                                                return (
                                                    <div key={a.id}>
                                                        {a.user_answer && (
                                                            <div css={questionAnswerStyle}>
                                                                <strong>{a.label && a.label}</strong>{" "}
                                                                {a.user_answer &&
                                                                    a.user_answer !== "true" &&
                                                                    `${a.user_answer}`}{" "}
                                                                {question.disqualifier && a.disqualifier && (
                                                                    <span css={disqualifierStyle}>DISQUALIFIED</span>
                                                                )}
                                                            </div>
                                                        )}
                                                        {a.user_answer &&
                                                            a.followupquestion &&
                                                            a.followupquestion.length > 0 &&
                                                            a.followupquestion[0].text !== "" && (
                                                                <div css={followupTextStyle}>
                                                                    {a.followupquestion[0].text}{" "}
                                                                    {a.followupquestion[0].answers.length > 0 && (
                                                                        <span css={followupAnswerStyle}>
                                                                            {
                                                                                a.followupquestion[0].answers[0]
                                                                                    .user_answer
                                                                            }
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            )}
                                                    </div>
                                                );
                                            })}
                                            {question.comments && question.comments !== "" && (
                                                <div css={commentStyle}>
                                                    <div css={commentTextStyle}>Comments:</div> {question.comments}
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                        </Box>
                    );
                })}
            </>
        );
    }
}
