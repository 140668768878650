import React, { useState, useEffect, useRef } from "react";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import "moment-duration-format";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import { green } from "@mui/material/colors";
import { formatPhoneNumber, doctorNameFormat } from "../Utils";
// TODO: Remove eventually...
// import CustomAddTermsLink from "./CustomAddTermsLink";
// import CustomAddDisclosures from "./CustomAddDisclosures";
// import CustomOnboardingLink from "./CustomOnboardingLink";
import CustomScreenerLink from "./CustomScreenerLink";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    typography: {
        padding: theme.spacing(2),
    },
    actions: {
        textAlign: "Center",
    },
    warningCopy: {
        color: "red",
        fontSize: "1.25rem",
        fontWeight: "bold",
        marginTop: "0",
    },
    uneditableHeader: {
        color: "red",
        fontSize: ".65rem",
        textTransform: "uppercase",
        textAlign: "center",
    },
    datestamp: {
        color: "#666",
        fontSize: ".65rem",
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    emailBorder: {
        border: "1px solid #e5e5e5",
        backgroundColor: "#f8f8f8",
        borderRadius: "2px",
        padding: "12px",
        fontSize: ".75rem",
    },
    emailOutline: {
        border: "1px solid #eaeaea",
        backgroundColor: "#ffffff",
        borderRadius: "2px",
        padding: "8px",
    },
    editorWrapper: {
        border: "1px solid #EAEAEA",
        borderRadius: "2px",
        padding: "12px",
    },
    iconSmall: {
        fontSize: ".95rem",
    },
    wrapper: {
        margin: theme.spacing(1),
        position: "relative",
    },
    buttonSuccess: {
        backgroundColor: green[500],
        "&:hover": {
            backgroundColor: green[700],
        },
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: 22,
    },
}));

function SendScreenerTemplate(props) {
    const now = moment();
    const classes = useStyles();
    const timer = useRef();
    const [activeBtn, setActiveBtn] = useState(true);
    const [confirmButton, setConfirmButtonDisplay] = useState("block");
    const [sendButton, setButtonDisplay] = useState("none");
    const [preview, setPreviewArea] = useState("none");
    const [editableAreas, setEditableArea] = useState("block");
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [editor, setEditorState] = useState(EditorState.createEmpty());
    const [template, setEmailTemplate] = useState({
        survey_id: props.survey.id,
        expert_id: props.expert.id,
        expert_prefix: props.expert.prefix,
        expert_fname: props.expert.fname,
        expert_lname: props.expert.lname,
        expert_suffix: props.expert.suffix,
        expert_email: props.expert.mail.trim(),
        project_id: props.project.id,
        user_id: props.user.id,
        user_fname: props.user.fname,
        user_lname: props.user.lname,
        from_email: props.user.mail,
        email_subject: `Thanks for your interest in ${props.project.title} (ID-${props.project.id})`,
        email_body: props.emailContent,
        email_template: props.type,
    });
    const [screenerURL, setScreenerUrl] = useState(props.privateToken);

    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
    });

    function formatDateString(str) {
        return moment(str).format("dddd, MMMM. Do, YYYY, h:mma");
    }

    function handleChange(event) {
        const { target } = event;
        const { value, name } = target;
        setEmailTemplate({
            ...template,
            [name]: value,
        });
    }

    function findReplaceTermsLink(body) {
        const screenerLink = `<a href="${props.privateToken}" target="_blank">screener survey</a>`;
        let newBody = "";
        if (body.indexOf("{{SCREENER_LINK}}") !== -1) {
            newBody = body.replace("{{SCREENER_LINK}}", screenerLink);
            // Set template to screener if link is found
            template.email_template = "screener";
        } else {
            newBody = body;
        }
        return newBody;
    }

    function onEditorStateChange(editor) {
        setEditorState(editor);
        const jsonHtml = convertToRaw(editor.getCurrentContent());
        const html = draftToHtml(jsonHtml);
        // const editorHtml = htmlToDraft(html);
        const newHtml = findReplaceTermsLink(html);
        setEmailTemplate({
            ...template,
            email_body: newHtml,
        });
    }

    function strategicTemplate() {
        let html = "<p>";
        html += `${doctorNameFormat(
            template.expert_prefix,
            template.expert_fname,
            template.expert_lname,
            template.expert_suffix
        )},`;
        html += "</p>";
        html += `${props.emailContent}`;
        html += "<p>";
        html += `Best regards,<br />${props.user.fname}`;
        html += "</p>";
        html += "<br />";
        html += "<p>";
        html += `<em>${props.user.fname} ${props.user.lname}</em>`;
        html += "<br />";
        html += `<em>${props.user.title}</em>`;
        html += "<br />";
        html += `<em>${formatPhoneNumber(props.user.number)}</em>`;
        html += "<br />";
        html += `<em>${props.user.mail}</em>`;
        html += "</p>";
        html += "<br />";
        html += "<p style='text-align:center;font-size:10px;'>";
        html += "www.firstthought.io | new york | london | tel aviv";
        html += "<br />";
        html += `You may <a href="https://www.firstthought.io/unsubscribe/${template.expert_id}/${template.expert_email}/tnc">unsubscribe</a> from future emails, but please be aware this would drastically limit you from receiving important opportunities to share your expertise.`;
        html += "</p>";
        return html;
    }

    function setDefaultReferenceEditorState() {
        const html = strategicTemplate();

        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            setEditorState(EditorState.createWithContent(contentState));
        }
        setEmailTemplate({
            ...template,
            email_body: html,
        });
    }

    function sendEmail() {
        const url = "/api/mail/tnc";
        if (!loading) {
            setSuccess(false);
            setLoading(true);
        }
        axios
            .post(url, template, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    console.log("Email Template Sent", template);
                    timer.current = setTimeout(() => {
                        setSuccess(true);
                        setLoading(false);
                        props.closeEvent();
                        props.updateEvent(result.data.data);
                    }, 3000);
                    // Reload the page to show new TnC status
                } else {
                    console.log(`POSTED, but NOT Status 200: ${JSON.stringify(template)}`);
                    alert("Oops, Something went wrong. Your email did not send. Please contact someone in Tech.");
                }
            })
            .catch((result) => {
                console.log("Something went wrong, API Error, 404 Error:", result);
                alert("Oops, Something went wrong. Your email did not send. Please contact someone in Tech.");
            });
    }

    function showPreview() {
        // Set survey ID
        template.survey_id = props.survey.id;
        setEditableArea("none");
        setPreviewArea("block");
        setConfirmButtonDisplay("block");
        setButtonDisplay("none");
        onEditorStateChange(editor);
    }
    function editEmail() {
        setEditableArea("block");
        setPreviewArea("none");
        setConfirmButtonDisplay("block");
        setButtonDisplay("none");
    }
    function showSendEmailButton() {
        if (props.user.number !== "" && props.user.number.length > 9) {
            setButtonDisplay("block");
            setConfirmButtonDisplay("none");
            console.log(template);
        } else {
            alert("Please provide a phone number for yourself before sending any outreach.");
        }
    }

    function copyLink(e) {
        const link = e.target;
        link.select();
        document.execCommand("copy", false);
        alert(`SUCCESSFULLY COPIED!" ${link.value}`);
    }

    useEffect(() => {
        setDefaultReferenceEditorState();
        editEmail();
        if (props.privateToken) {
            setActiveBtn(false);
        }
    }, [props]);

    return (
        <div>
            <Box display={preview}>
                <Box display={confirmButton}>
                    <p className={classes.warningCopy}>
                        THIS IS JUST A PREVIEW ONLY: Please review and confirm this is ready to send.
                    </p>
                </Box>
                <TextField
                    fullWidth
                    label="Copy/Paste Screener URL"
                    margin="normal"
                    onClick={copyLink}
                    name="screenerURL"
                    value={props.privateToken}
                />
                <div className={classes.emailBorder}>
                    <p className={classes.datestamp}>{formatDateString(now)}</p>
                    <p>
                        <strong>FROM:</strong> {template.user_fname} {template.user_lname} ({template.from_email})
                    </p>
                    <p>
                        <strong>TO:</strong> {template.expert_fname} {template.expert_lname} ({template.expert_email})
                    </p>
                    <p className={classes.subjectLine}>
                        <strong>SUBJECT LINE:</strong> {template.email_subject}
                    </p>
                    <div
                        className={classes.emailOutline}
                        dangerouslySetInnerHTML={{
                            __html: template.email_body,
                        }}
                    />
                </div>
                <div className={classes.actions}>
                    <div className={classes.wrapper}>
                        <Box display={confirmButton}>
                            <Button onClick={editEmail}>Edit this Email</Button>
                            <Button onClick={showSendEmailButton}>
                                <WhatshotIcon /> Confirmed, This Looks Fire!
                            </Button>
                        </Box>
                        <Box display={sendButton}>
                            <Button onClick={editEmail}>Back to Edit</Button>
                            <Button
                                variant="contained"
                                color="primary"
                                className={buttonClassname}
                                disabled={loading}
                                onClick={sendEmail}
                            >
                                Send Email Now
                            </Button>
                            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </Box>
                    </div>
                </div>
            </Box>
            <Box display={editableAreas}>
                <TextField
                    fullWidth
                    // id="email_subject"
                    label="Email Subject"
                    margin="normal"
                    onChange={handleChange}
                    name="email_subject"
                    value={template.email_subject}
                />
                <Editor
                    editorState={editor}
                    spellCheck
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName={classes.editorWrapper}
                    onEditorStateChange={onEditorStateChange}
                    toolbarCustomButtons={[<CustomScreenerLink />]}
                />
                <div className={classes.actions}>
                    <div className={classes.wrapper}>
                        <Button onClick={props.closeEvent}>Cancel</Button>
                        <Button
                            disabled={activeBtn}
                            variant="contained"
                            color="primary"
                            className={buttonClassname}
                            onClick={showPreview}
                        >
                            Next
                        </Button>
                    </div>
                </div>
            </Box>
        </div>
    );
}
export default SendScreenerTemplate;
