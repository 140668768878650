// react imports
import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
// MUI general imports
import { Button, TextField, Box } from "@mui/material";

// dialog imports
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

// custom imports
import FlashMessagev2 from "../../FlashMessagev2";

// npm imports
import axios from "axios";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    required: {
        color: "#444",
        fontSize: ".85rem",
        marginBottom: "20px",
    },
    textfield: {
        marginBottom: "15px",
    },
    adminBtn: {
        marginBottom: "15px",
    },
    reasonBtn: {
        marginBottom: "8px",
    },
    custom: {
        color: "#000",
        fontSize: ".95rem",
        marginBottom: "3px",
    },
    reason: {
        color: "#666",
        fontSize: ".85rem",
        marginTop: "15px",
        marginBottom: "15px",
    },
    note: {
        color: "#444",
        fontSize: ".85rem",
        marginBottom: "0",
    },
    override: {
        backgroundColor: "#dfdae9",
        padding: "10px",
        marginBottom: "12px",
        borderRadius: "3px",
    },
}));

const regex = /^\d{0,8}(\.\d{0,2})?$/;

export default function NoteCell(props) {
    const { projectId, userId, getPricingInfo, row } = props;

    // row destructured
    const {
        client_notes,
        client_note_by_fname,
        client_note_by_lname,
        client_notes_created_on,
        project_participant_id,
        unit_price_override,
        dollar_price_override,
        dollarClientChargeString,
        unitClientChargeString,
        client_charge_approved,
    } = row;

    // client duration logic
    const client_duration = row.client_duration === "" ? row.client_duration : "0";

    const [open, setOpen] = useState(false);
    const [noteState, setNoteState] = useState("");
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [helperText, setHelperText] = useState("");
    const [flashError, setFlashError] = useState(false);
    const [flashErrorMsg, setFlashErrorMsg] = useState("");
    const classes = useStyles();
    const [submitBtn, setSubmitBtn] = useState(true);
    const [nextBtn, setNextBtn] = useState(client_notes_created_on ? false : true);
    const [override, setOverride] = useState({
        unit: unit_price_override === null ? unitClientChargeString : unit_price_override,
        dollar: dollar_price_override === null ? dollarClientChargeString : dollar_price_override,
        duration: client_duration,
    });
    const [reasonsDisplay, setReasonsDisplay] = useState("block");
    const [unitChargeDisplay, setUnitChargeDisplay] = useState("none");
    const [dollarChargesDisplay, setDollarChargeDisplay] = useState("none");
    const [durationDisplay, setDurationDisplay] = useState("none");

    useEffect(() => {
        setNoteState(client_notes ? client_notes : "");
    }, [props]);

    // change override if necessary
    const insertOverridePricing = async (overrideAmount, overrideType) => {
        const postObj = {
            userId,
            callId: project_participant_id,
            overrideAmount,
            overrideType,
        };
        console.log(postObj, " :: Submit Override Pricing");
        await axios
            .post("/api/finance/insertPricingOverride", postObj)
            .then((result) => {
                handleClose();
                console.log(result, " :: result coming back from override update");
                // getPricingInfo(projectId);
                // TODO :: send email to admin saying override happened
                // sendAdminEmail(overrideAmount, overrideType);
            })
            .catch((err) => {
                console.log(err.response.data.message, " :: err.response.data.message err");
                setFlashErrorMsg(err.response.data.message);
                setFlashError(true);
            });
    };

    const updateClientCallDuration = async (durationNum) => {
        console.log("updateClientCallDuration :: ", durationNum);
        await axios
            .post(
                "/api/project/call/updateClientDuration",
                {
                    call_id: project_participant_id,
                    client_call_duration: durationNum,
                    person_id: userId,
                    project_id: projectId,
                },
                {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            )
            .then((result) => {
                if (result.status === 200) {
                    handleClose();
                    console.log(result, " :: result coming back from duration update");
                    // getPricingInfo(projectId);
                }
            })
            .catch((error) => {
                alert("SOMETHING WENT WRONG UPDATING THE CALL DURATION :: ", error);
                console.log("SOMETHING WENT WRONG UPDATING CALL DURATION :: ", error);
            });
    };

    // handle funcs
    const handleNote = (event) => {
        const { target } = event;
        const { value, name } = target;
        setNoteState(value);
        if (value.length > 3) {
            setNextBtn(false);
        } else {
            setNextBtn(true);
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
        setDurationDisplay("none");
        setDollarChargeDisplay("none");
        setUnitChargeDisplay("none");
        setReasonsDisplay("block");
        setNextBtn(client_notes_created_on ? false : true);
    };

    const handleClose = () => {
        setNoteState(client_notes ? client_notes : "");
        setOpen(false);
        setNextBtn(true);
        setSubmitBtn(true);
        setDurationDisplay("none");
        setDollarChargeDisplay("none");
        setUnitChargeDisplay("none");
        setReasonsDisplay("block");
        // Reload the data on the page when modal closes...
        getPricingInfo(projectId);
    };

    const handleChange = (event) => {
        const { target } = event;
        const { value, name } = target;
        setError(false);
        setHelperText("");
        if (regex.test(value)) {
            setOverride({
                ...override,
                [name]: value,
            });
            if (value.length > 0) {
                setSubmitBtn(false);
            } else {
                setSubmitBtn(true);
            }
        }
    };

    const handleReason = (event) => {
        const { target } = event;
        const { innerText } = target;
        console.log("REASON: ", innerText);
        setNoteState(innerText);
        setNextBtn(false);
    };

    const saveNotes = async () => {
        const postObj = {
            note: noteState,
            callId: project_participant_id,
            person_id: userId,
        };
        await axios
            .post("/api/finance/updateOverrideClientNote", postObj)
            .then((result) => {
                setDollarChargeDisplay("block");
                setReasonsDisplay("none");
                setNextBtn(true);
                // getPricingInfo(projectId);
            })
            .catch((err) => {
                console.log(err, ":: there was an error updating the note");
                console.log(err.response.data.message, " :: err.response.data.message err");
                setErrorMsg(err.response.data.message);
                setError(true);
            });
    };
    const submitDollarCharge = () => {
        console.log("OVERRIDE DOLLAR CHARGE: ", override);
        if (override.dollar !== "" && noteState !== "") {
            const overrideType = "dollar";
            const overrideAmount = override.dollar;
            console.log("SUBMIT OVERRIDES: ", { overrideAmount: parseFloat(overrideAmount), overrideType });
            insertOverridePricing(overrideAmount, overrideType);
        } else {
            setError(true);
            setHelperText("Cannot Be Empty");
        }
    };

    const submitUnitCharge = () => {
        console.log("OVERRIDE UNIT CHARGE: ", override);
        if (override.unit !== "" && noteState !== "") {
            const overrideType = "unit";
            const overrideAmount = override.unit;
            console.log("SUBMIT OVERRIDES: ", { overrideAmount: parseFloat(overrideAmount), overrideType });
            insertOverridePricing(overrideAmount, overrideType);
        } else {
            setError(true);
            setHelperText("Cannot Be Empty");
        }
    };

    const submitDuration = () => {
        if (override.duration !== "" && noteState !== "") {
            console.log("SUBMIT DURATION: ", override.duration);
            updateClientCallDuration(override.duration);
        } else {
            setError(true);
            setHelperText("Cannot Be Empty");
        }
    };

    const showDurationDisplay = () => {
        setDollarChargeDisplay("none");
        setUnitChargeDisplay("none");
        setReasonsDisplay("none");
        setDurationDisplay("block");
    };

    const showUnitChargeDisplay = () => {
        setDurationDisplay("none");
        setReasonsDisplay("none");
        setDollarChargeDisplay("none");
        setUnitChargeDisplay("block");
    };

    const showDollarChargeDisplay = () => {
        setDurationDisplay("none");
        setReasonsDisplay("none");
        setUnitChargeDisplay("none");
        setDollarChargeDisplay("block");
    };

    return (
        <div>
            <Box>
                {!client_notes ? (
                    <Button
                        fullWidth
                        className={classes.adminBtn}
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={handleClickOpen}
                        disabled={client_charge_approved}
                    >
                        Admin Override
                    </Button>
                ) : (
                    <>
                        <Button
                            fullWidth
                            disabled={client_charge_approved}
                            size="small"
                            variant="outlined"
                            color="primary"
                            onClick={handleClickOpen}
                        >
                            Modify Changes
                        </Button>
                        <br />
                        <p className={classes.reason}>
                            <strong>REASON:</strong> {client_notes}
                        </p>
                    </>
                )}
                {client_notes_created_on && (
                    <div className={classes.override}>
                        <p className={classes.note}>{`By: ${client_note_by_fname} ${client_note_by_lname}`}</p>
                        <p className={classes.note}>{`${moment(client_notes_created_on).format(
                            "dddd, MMMM Do, YYYY @ h:mma"
                        )}`}</p>
                    </div>
                )}
            </Box>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Edit Overrides</DialogTitle>
                <DialogContent>
                    <Box display={reasonsDisplay}>
                        <h2 className={classes.required}>
                            * Required - Explain why you changed the client charges/duration
                        </h2>
                        <Button
                            fullWidth
                            size="small"
                            variant="outlined"
                            color="primary"
                            className={classes.reasonBtn}
                            onClick={handleReason}
                        >
                            Duration Change: Dispute resolution
                        </Button>
                        <Button
                            fullWidth
                            size="small"
                            variant="outlined"
                            color="primary"
                            className={classes.reasonBtn}
                            onClick={handleReason}
                        >
                            Expert: Bad fit, Misrepresented background
                        </Button>
                        <Button
                            fullWidth
                            size="small"
                            variant="outlined"
                            color="primary"
                            className={classes.reasonBtn}
                            onClick={handleReason}
                        >
                            Technical: Issue with bridge or connectivity
                        </Button>
                        <Button
                            fullWidth
                            size="small"
                            variant="outlined"
                            color="primary"
                            className={classes.reasonBtn}
                            onClick={handleReason}
                        >
                            Client: Duration dispute
                        </Button>
                        <Button
                            fullWidth
                            size="small"
                            variant="outlined"
                            color="primary"
                            className={classes.reasonBtn}
                            onClick={handleReason}
                        >
                            Special: Promotion
                        </Button>
                        <TextField
                            className={classes.textfield}
                            autoFocus
                            margin="dense"
                            value={noteState}
                            onChange={handleNote}
                            fullWidth
                            maxRows={4}
                            label="Please enter your reason..."
                        />
                        <Button disabled={nextBtn} onClick={saveNotes} color="primary">
                            Next
                        </Button>
                    </Box>
                    <Box display={unitChargeDisplay}>
                        <h2 className={classes.required}>* Are you sure you want to change the unit?</h2>
                        <TextField
                            className={classes.textfield}
                            value={override.unit}
                            fullWidth
                            name="unit"
                            error={error}
                            helperText={helperText}
                            onChange={handleChange}
                            id="unit"
                            label="Enter Unit Amount"
                        />
                        <Button disabled={submitBtn} onClick={submitUnitCharge} color="primary">
                            Save
                        </Button>
                        <Button onClick={showDollarChargeDisplay} color="primary">
                            Dollar
                        </Button>
                        <Button onClick={showDurationDisplay} color="primary">
                            Duration
                        </Button>
                    </Box>
                    <Box display={dollarChargesDisplay}>
                        <h2 className={classes.required}>* Are you sure you want to change the dollar amount?</h2>
                        <TextField
                            className={classes.textfield}
                            value={override.dollar}
                            fullWidth
                            name="dollar"
                            error={error}
                            helperText={helperText}
                            onChange={handleChange}
                            id="dollar"
                            label="Enter USD Amount"
                        />
                        <Button disabled={submitBtn} onClick={submitDollarCharge} color="primary">
                            Save
                        </Button>
                        <Button onClick={showUnitChargeDisplay} color="primary">
                            Unit
                        </Button>
                        <Button onClick={showDurationDisplay} color="primary">
                            Duration
                        </Button>
                    </Box>
                    <Box display={durationDisplay}>
                        <h2 className={classes.required}>* Are you sure you want to change the duration?</h2>
                        <TextField
                            className={classes.textfield}
                            value={override.duration}
                            fullWidth
                            name="duration"
                            error={error}
                            helperText={helperText}
                            onChange={handleChange}
                            id="duration"
                            label="Enter Duration (minutes)"
                        />
                        <Button disabled={submitBtn} onClick={submitDuration} color="primary">
                            Save
                        </Button>
                        <Button onClick={showDollarChargeDisplay} color="primary">
                            Dollar
                        </Button>
                        <Button onClick={showUnitChargeDisplay} color="primary">
                            Unit
                        </Button>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            {error && <FlashMessagev2 error={error} severity={"error"} message={errorMsg} />}
        </div>
    );
}
