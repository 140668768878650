// REACT
import * as React from "react";

// MUI STYLES
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

// NPM
import axios from "axios";
import MUIDataTable, { ExpandButton } from "mui-datatables";
import { Grid, TableCell, TableRow } from "@mui/material";

// CUSTOM
import { basicDateTimeStamp, moreBasicDateTimeStamp } from "../../Utils";
import SurveyAnswers from "../project/utils/SurveyAnswers";
import StatusCarousel from "./StatusCarousel";
import CallCarousel from "./CallCarousel";

/**
 * STYLES
 */

export default function ExpertProjectDisplay({ data }) {
    /**
     * STATE
     */

    const [loading, setLoading] = React.useState(true);
    const [expertProjectData, setExpertProjectData] = React.useState([]);
    const [expertProjectMetadata, setExpertProjectMetadata] = React.useState({});

    /**
     * RENDER FUNCS
     */

    function renderAccentBarColor(maxRelevantStatusId) {
        switch (maxRelevantStatusId) {
            case 4: {
                return "#38bdf8";
            }
            case 28: {
                return "#a78bfa";
            }
            case 13: {
                return "#fbbf24";
            }
            case 20: {
                return "#fb923c";
            }
            case 18: {
                return "#94A3B8";
            }
            case 19: {
                return "#2dd4bf";
            }
            default: {
                return "#f87171";
            }
        }
    }

    function renderMostImportantStatus(statuses, { maxId, maxRelevantId }, project_id) {
        switch (maxRelevantId) {
            case 4: {
                return (
                    <StatusCarousel
                        statuses={statuses}
                        project_id={project_id}
                        currentStatus={4}
                        styleObj={{
                            top: {
                                backgroundColor: "#e0f2fe",
                                border: "1px solid #38bdf8",
                            },
                            name: {
                                color: "#075985",
                            },
                            date: {
                                color: "#0369a1",
                            },
                        }}
                    />
                );
            }
            case 28: {
                return (
                    <StatusCarousel
                        statuses={statuses}
                        project_id={project_id}
                        currentStatus={28}
                        styleObj={{
                            top: {
                                backgroundColor: "#ede9fe",
                                border: "1px solid #a78bfa",
                            },
                            name: {
                                color: "#5b21b6",
                            },
                            date: {
                                color: "#6d28d9",
                            },
                        }}
                    />
                );
            }

            case 13: {
                return (
                    <StatusCarousel
                        statuses={statuses}
                        project_id={project_id}
                        currentStatus={13}
                        styleObj={{
                            top: {
                                backgroundColor: "#f2f3c7",
                                border: "1px solid #fbbf24",
                            },
                            name: {
                                color: "#92400e",
                            },
                            date: {
                                color: "#b45309",
                            },
                        }}
                    />
                );
            }
            case 20: {
                return (
                    <StatusCarousel
                        statuses={statuses}
                        project_id={project_id}
                        currentStatus={20}
                        styleObj={{
                            top: {
                                backgroundColor: "#ffedd5",
                                border: "1px solid #fb923c",
                            },
                            name: {
                                color: "#9a3412",
                            },
                            date: {
                                color: "#c2410c",
                            },
                        }}
                    />
                );
            }
            case 18: {
                return (
                    <StatusCarousel
                        statuses={statuses}
                        project_id={project_id}
                        currentStatus={18}
                        styleObj={{
                            top: {
                                backgroundColor: "#E2E8F0",
                                border: "1px solid #94A3B8",
                            },
                            name: {
                                color: "#1E293B",
                            },
                            date: {
                                color: "#334155",
                            },
                        }}
                    />
                );
            }
            case 19: {
                return (
                    <StatusCarousel
                        statuses={statuses}
                        project_id={project_id}
                        currentStatus={19}
                        styleObj={{
                            top: {
                                backgroundColor: "#ccfbf1",
                                border: "1px solid #2dd4bf",
                            },
                            name: {
                                color: "#115e59",
                            },
                            date: {
                                color: "#0f766e",
                            },
                        }}
                    />
                );
            }
            default: {
                return (
                    <StatusCarousel
                        statuses={statuses}
                        project_id={project_id}
                        currentStatus={maxId}
                        styleObj={{
                            top: {
                                backgroundColor: "#fee2e2",
                                border: "1px solid #f87171",
                            },
                            name: {
                                color: "#991b1b",
                            },
                            date: {
                                color: "#b91c1c",
                            },
                        }}
                    />
                );
            }
        }
    }

    /**
     * PAGE LOAD
     */

    async function getExpertProjectData() {
        await axios
            .get(`/api/expert/next/findExpertProjectData/${data.expert.expert_id}`)
            .then((r) => {
                const result = r?.data?.data?.data;
                console.log(result, " :: result in ExpertProjectDisplay.jsx");
                if (result) {
                    let positiveProjects = 0;
                    let negativeProjects = 0;

                    for (const project of result) {
                        const { statuses, statusMetadata } = project;
                        if (
                            parseInt(statusMetadata.count) === 0 ||
                            parseInt(statusMetadata.maxId) < 5 || // this means they haven't really done much
                            "5" in statuses ||
                            data.optoutData.length > 0
                        ) {
                            negativeProjects += 1;
                        } else {
                            positiveProjects += 1;
                        }
                    }

                    setExpertProjectData(result);
                    setExpertProjectMetadata({ positiveProjects, negativeProjects });

                    setLoading(false);
                }
            })
            .catch((e) => console.log(e, " :: e in getExpertProjectData"));
    }

    React.useEffect(() => {
        getExpertProjectData();
    }, []);

    const expandableOptions = {
        print: false,
        rowsPerPage: 100,
        viewColumns: false,
        selectableRows: "none",
        expandableRows: true,
        expandableRowsHeader: false,
        expandableRowsOnClick: false,
        isRowExpandable: (dataIndex) => {
            const { statuses } = expertProjectData?.[dataIndex];
            return "28" in statuses;
        },
        download: false,
        renderExpandableRow: (rowData, { dataIndex, rowIndex }) => {
            const { statuses, expert_id, project_id } = expertProjectData?.[dataIndex];
            // console.log(expert_id, " :: expert_id");
            // console.log(project_id, " :: project_id");
            // console.log(statuses, " :: statuses");
            const surveys = statuses?.[28];
            const colSpan = rowData.length + 1;
            return (
                <TableRow>
                    <TableCell colSpan={colSpan}>
                        <SurveyAnswers answerData={{ surveys, expert_id, project_id }} />
                    </TableCell>
                </TableRow>
            );
        },
    };

    const components = {
        ExpandButton: function (props) {
            return <ExpandButton {...props} />;
        },
    };

    const cols = [
        {
            name: "project_title",
            label: "Project",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const {
                        project_title,
                        attached_by_name,
                        attached_on,
                        project_status_name,
                        project_status_id,
                        statusMetadata,
                        project_id,
                    } = expertProjectData?.[dataIndex];

                    return (
                        <div
                            css={css`
                                & > div:not(:first-of-type) {
                                    color: hsl(0, 0%, 58%);
                                }
                                & > div > span {
                                    color: hsl(0, 0%, 43%);
                                    font-weight: 500;
                                }
                                border-left: 4px solid ${renderAccentBarColor(statusMetadata.maxRelevantId)};
                                padding-left: 1rem;
                            `}
                        >
                            <div
                                css={css`
                                    font-weight: 700;
                                    font-size: 1rem;
                                    color: hsl(270, 40%, 50%);
                                    margin-bottom: 0.45rem;
                                `}
                            >
                                <a
                                    href={`/project/view/${project_id}`}
                                    css={css`
                                        color: hsl(270, 40%, 50%);
                                        text-decoration: none;
                                        &:hover {
                                            color: hsl(270, 90%, 50%);
                                        }
                                    `}
                                >
                                    {project_title}
                                </a>{" "}
                                -{" "}
                                <span
                                    css={css`
                                        color: #ddd6fe !important;
                                        font-weight: 500 !important;
                                    `}
                                >
                                    {project_status_id === 6 ? "On Hold" : project_status_name}
                                </span>
                            </div>
                            <div>
                                Attached by <span>{attached_by_name}</span>{" "}
                            </div>
                            <div>
                                On <span>{basicDateTimeStamp(attached_on)}</span>
                            </div>
                        </div>
                    );
                },
            },
        },
        {
            name: "statuses",
            label: "Last Status",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    const { statuses, statusMetadata, project_id } = expertProjectData?.[dataIndex];

                    return (
                        <div
                            css={css`
                                padding-inline: 1rem;
                            `}
                        >
                            {renderMostImportantStatus(statuses, statusMetadata, project_id)}
                        </div>
                    );
                },
            },
        },
        {
            name: "details",
            label: "Call/Payment Details",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    const { calls } = expertProjectData?.[dataIndex];

                    return (
                        <div
                            css={css`
                                padding-inline: 1rem;
                            `}
                        >
                            <CallCarousel calls={calls} />
                        </div>
                    );
                },
            },
        },
    ];

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div
                        css={css`
                            display: flex;
                            align-items: center;
                        `}
                    >
                        <h1
                            css={css`
                                font-size: 1.5rem;
                                letter-spacing: 1.1px;
                            `}
                        >
                            <span
                                css={css`
                                    font-weight: 700;
                                `}
                            >
                                {expertProjectData.length}
                            </span>{" "}
                            Projects
                        </h1>
                        <div
                            css={css`
                                margin-left: 1rem;
                                border-left: 1px solid hsl(0, 0%, 80%);
                                padding-left: 1rem;
                                color: #a3a3a3;
                            `}
                        >
                            <div>
                                Participated in{" "}
                                <span
                                    css={css`
                                        font-weight: 500;
                                        color: #737373;
                                    `}
                                >
                                    {expertProjectMetadata.positiveProjects}
                                </span>{" "}
                                projects
                            </div>
                            <div>
                                Ignored or rejected from{" "}
                                <span
                                    css={css`
                                        font-weight: 500;
                                        color: #737373;
                                    `}
                                >
                                    {expertProjectMetadata.negativeProjects}
                                </span>{" "}
                                projects
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <MUIDataTable
                        data={expertProjectData}
                        options={expandableOptions}
                        columns={cols}
                        components={components}
                    />
                </Grid>
            </Grid>
        </>
    );
}
