import React, { useState, useEffect, useContext } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { authContext } from "../context/auth";

function Dialer(props) {
    const [number, setNumber] = useState("");
    const { auth } = useContext(authContext);
    // console.log('this is the auth data',auth.data)
    // useEffect(() => {
    //     const fetchData = async () => {
    //       const result = await axios(
    //         'https://hn.algolia.com/api/v1/search?query=redux',
    //       );

    //       setData(result.data);
    //     };

    //     fetchData();
    //   }, []);

    const dialExpert = () => {
        const data = auth.data;
        data.dialTo = number;
        console.log(number);
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        };
        fetch("/api/rm/dial", requestOptions)
            .then((response) => response.json())
            .then((json) => {
                console.log(json);
            });
    };

    return (
        <div>
            <h1>Enter the phone number you wish to call</h1>
            <p>The call will first be placed to the logged in RM's phone and only then will it call the given number</p>
            <p>When the RM hangs up and ends the call all participants will be disconnected and the bridge will end.</p>

            <PhoneInput defaultCountry="US" placeholder="Enter phone number" value={number} onChange={setNumber} />

            {/* <input onChange={event => setNumber(event.target.value)} /> */}
            <button onClick={dialExpert}>Dial Expert and Me</button>
        </div>
    );
}

export default Dialer;
