import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, DialogContentText } from "@mui/material";
import * as React from "react";
import axios from "axios";

export default function ApprovalNoteModal({
    openApprovalNoteModal,
    setOpenApprovalNoteModal,
    approvalData,
    setApprovalData,
    getPricingInfo,
    projectId,
}) {
    // STATES
    const textRef = React.useRef();
    const [error, setError] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState("Please enter a note.");

    // different modal states
    const Statuses = {
        noteConfirmation: "note-confirmation",
        noteCreate: "note-page",
        approvalConfirmation: "approval-confirmation",
    };

    const [status, setStatus] = React.useState(Statuses.noteConfirmation);

    // HANDLE FUNCS
    const handleClose = () => {
        setOpenApprovalNoteModal(false);
    };

    function handleNavigateToApproveModal() {
        setStatus(Statuses.approvalConfirmation);
    }

    function handleNavigateToAddNoteModal() {
        setStatus(Statuses.noteCreate);
    }

    async function handleConfirmApproval() {
        // console.log("====== in handleConfirmAPproval ======");
        // console.log(approvalData, " :: approvalData");
        await axios
            .post("/api/finance/upsertFinanceChargeData", { ...approvalData })
            .then((res) => {
                // console.log(res, " :: res");
                getPricingInfo(projectId);
                if (openApprovalNoteModal) {
                    setOpenApprovalNoteModal(false);
                }
            })
            .catch((err) => {
                console.log(err.response.data.message, " :: err.response.data.message err");
                setErrorMsg("Error saving approval: ", err.response.data.message);
                setError(true);
            });
    }

    function handleSaveNote() {
        const currentText = textRef.current.value;
        if (currentText.length === 0) {
            setError(true);
            return;
        }
        setApprovalData({ ...approvalData, invoicing_notes: currentText });
        setStatus(Statuses.approvalConfirmation);
    }

    if (status === Statuses.noteConfirmation) {
        return (
            <div>
                <Dialog
                    open={openApprovalNoteModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth
                    maxWidth="sm"
                >
                    <DialogTitle id="alert-dialog-title">Need Notes?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Please add any relevant notes to the invoice before approving.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                        <Button onClick={handleNavigateToApproveModal}>Skip to Approval</Button>
                        <Button onClick={handleNavigateToAddNoteModal} autoFocus>
                            Add Note
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    } else if (status === Statuses.noteCreate) {
        return (
            <div>
                <Dialog
                    open={openApprovalNoteModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth
                    maxWidth="sm"
                >
                    <DialogTitle id="alert-dialog-title">Approval Notes:</DialogTitle>
                    <DialogContent>
                        <div>
                            <TextField
                                sx={{ width: "100%", "& legend": { display: "none" }, "& fieldset": { top: 0 } }}
                                variant="outlined"
                                rows={5}
                                margin="dense"
                                multiline
                                inputRef={textRef}
                                error={error}
                                helperText={error ? errorMsg : ""}
                                onFocus={() => setError(false)}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                        <Button onClick={handleSaveNote} autoFocus>
                            Save Note
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    } else if (status === Statuses.approvalConfirmation) {
        return (
            <div>
                <Dialog
                    open={openApprovalNoteModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth
                    maxWidth="sm"
                >
                    <DialogTitle id="alert-dialog-title">Approve Transaction?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you <span style={{ fontWeight: "700" }}>SURE</span> you want to approve this charge?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                        <Button onClick={handleConfirmApproval}>Approve</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}
