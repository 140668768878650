import React, { useState } from "react";
import Box from "@mui/material/Box";
import useSWR from "swr";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import MUIDataTable from "mui-datatables";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material";
import { basicDateTimeStamp } from "../Utils";

const fetcher = (...args) => fetch(...args).then((res) => res.json());

const projectDurations = [
    {
        value: "2",
        label: "2 Months",
    },
    {
        value: "3",
        label: "3 Months",
    },
    {
        value: "6",
        label: "6 months",
    },
    {
        value: "0",
        label: "Show All",
    },
];

const projectStatuses = [
    {
        value: "open",
        label: "Open",
    },
    {
        value: "closed",
        label: "Closed",
    },
    {
        value: "requested",
        label: "Requested",
    },
    {
        value: "strategic",
        label: "Strategic Recruiting",
    },
    {
        value: "onHold",
        label: "On Hold",
    },
];

export default function ProjectList(props) {
    const [duration, setDuration] = useState("2");
    const [projectStatus, setProjectStatus] = useState("open");

    const findAllProjectsUrl = `/api/project/findAll?duration=${duration}&projectStatus=${projectStatus}`;
    const { data, error } = useSWR(findAllProjectsUrl, fetcher);
    const theme = useTheme();

    const options = {
        filter: true,
        pagination: true,
        print: false,
        viewColumns: false,
        rowsPerPage: 100,
        selectableRows: "none",
        setRowProps: (row, dataIndex, rowIndex) => {
            return {
                sx: {
                    bgcolor:
                        data.data[dataIndex].experts_scheduled > 0 &&
                        data.data[dataIndex].calls_planned > 0 &&
                        data.data[dataIndex].experts_scheduled >= data.data[dataIndex].calls_planned
                            ? theme.palette.primary.light
                            : null,
                    cursor: "pointer",
                },
            };
        },
        downloadOptions: {
            filename: "projectdata.csv",
            seperator: ",",
            filterOptions: {
                useDisplayedRowsOnly: true,
                useDisplayedColumnsOnly: true,
            },
        },
    };

    if (error) return <div>failed to load</div>;
    if (!data)
        return (
            <Stack sx={{ color: "grey.500" }} spacing={2} direction="row">
                <CircularProgress color="primary" />
            </Stack>
        );
    const handleStatusChange = (event) => {
        setProjectStatus(event.target.value);
    };

    const handleDurationChange = (event) => {
        setDuration(event.target.value);
    };

    const columns = [
        {
            name: "created_on_date",
            label: "created",
            options: {
                filter: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return (
                        <Typography variant="caption" display="block">
                            {data.data[dataIndex].created_on_date}
                        </Typography>
                    );
                },
            },
        },
        {
            name: "deadline",
            label: "deadline",
            options: {
                filter: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return (
                        <Typography variant="caption" display="block">
                            {data.data[dataIndex].deadline && basicDateTimeStamp(data.data[dataIndex].deadline)}
                        </Typography>
                    );
                },
            },
        },
        {
            name: "project_type",
            label: "type",
            options: {
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return (
                        <Typography variant="caption" display="block">
                            {data.data[dataIndex].project_type}
                        </Typography>
                    );
                },
            },
        },
        {
            name: "title",
            label: "title",
            options: {
                filter: false,
                sorting: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    if (data.data[dataIndex].project_status_id === 6) {
                        return (
                            <Link href={"/project/view/" + data.data[dataIndex].id} underline="none" target="_blank">
                                {data.data[dataIndex].title}{" "}
                                <Typography
                                    variant="caption"
                                    display="block"
                                    sx={{ color: theme.palette.secondary.main }}
                                >
                                    (On Hold)
                                </Typography>
                            </Link>
                        );
                    } else {
                        return (
                            <Link href={"/project/view/" + data.data[dataIndex].id} underline="none" target="_blank">
                                {data.data[dataIndex].title}
                            </Link>
                        );
                    }
                },
            },
        },
        { name: "rm", label: "rm" },
        {
            name: "client_name",
            label: "org",
            options: {
                filter: true,
                sorting: true,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    const url = "/client/view/" + data.data[dataIndex].client_id;
                    return (
                        <Link href={url} underline="none" target="_blank">
                            {data.data[dataIndex].client_name}
                        </Link>
                    );
                },
            },
        },
        {
            name: "client_contact_name",
            label: "client",
            options: {
                filter: true,
                sorting: true,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    const url = "/contact/view/" + data.data[dataIndex].client_contact_id;
                    return (
                        <Link href={url} underline="none" target="_blank">
                            {data.data[dataIndex].client_contact_name}
                        </Link>
                    );
                },
            },
        },
        {
            name: "experts_count",
            label: "experts",
            options: {
                filter: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    const value = data.data[dataIndex].experts_count ? data.data[dataIndex].experts_count : 0;
                    return value;
                },
            },
        },
        {
            name: "calls_planned",
            label: "planned",
            options: {
                filter: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    const value = data.data[dataIndex].calls_planned ? data.data[dataIndex].calls_planned : 0;
                    return value;
                },
            },
        },
        {
            name: "experts_accepted",
            label: "accepted",
            options: {
                filter: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    const value = data.data[dataIndex].experts_accepted ? data.data[dataIndex].experts_accepted : 0;
                    return value;
                },
            },
        },
        {
            name: "sent_to_client",
            label: "sent",
            options: {
                filter: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    const value = data.data[dataIndex].sent_to_client ? data.data[dataIndex].sent_to_client : 0;
                    return value;
                },
            },
        },
        {
            name: "experts_scheduled",
            label: "scheduled",
            options: {
                filter: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    const value = data.data[dataIndex].experts_scheduled ? data.data[dataIndex].experts_scheduled : 0;
                    return value;
                },
            },
        },
        {
            name: "experts_completed",
            label: "completed",
            options: {
                filter: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    const value = data.data[dataIndex].experts_completed ? data.data[dataIndex].experts_completed : 0;
                    return value;
                },
            },
        },
    ];
    return (
        <Grid container>
            <Box>
                <Paper sx={{ overflow: "hidden", padding: "10px" }}>
                    <Button
                        variant="contained"
                        href="/project/create"
                        sx={{ float: "right", display: "inline" }}
                        size="small"
                    >
                        Create New Project
                    </Button>
                    <Box alignItems="center">
                        <TextField
                            sx={{ width: "15vw" }}
                            select
                            label="Project Duration"
                            value={duration}
                            onChange={handleDurationChange}
                        >
                            {projectDurations.map((option) => {
                                return (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                );
                            })}
                        </TextField>
                        <TextField
                            sx={{ width: "15vw", marginLeft: "1rem" }}
                            select
                            label="Project Status"
                            value={projectStatus}
                            onChange={handleStatusChange}
                        >
                            {projectStatuses.map((option) => {
                                return (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                );
                            })}
                        </TextField>
                    </Box>
                </Paper>
                <Paper
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                    }}
                >
                    {data.data && (
                        <MUIDataTable title={"Projects List"} data={data.data} columns={columns} options={options} />
                    )}
                </Paper>
            </Box>
        </Grid>
    );
}
