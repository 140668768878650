import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function NoteDisplayModal({ openNoteModal, setOpenNoteModal, currentExpert }) {
    const handleClose = () => {
        setOpenNoteModal(false);
    };

    return (
        <div>
            <Dialog
                open={openNoteModal}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle id="alert-dialog-title">Expert Notes</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{currentExpert.invoicing_notes}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
