import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import MUIDataTable from "mui-datatables";
import Button from "@mui/material/Button";
import ExpertPreviewWrapperNext from "../components/wrappers/ExpertPreviewWrapperNext";
import { basicDateTimeStamp, formatPhoneNumber } from "../Utils";
import moment from "moment";
import "moment-duration-format";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(2),
        overflowX: "auto",
    },
    projectLink: {
        color: "#8966ad",
    },
    nameButton: {
        color: "#8966ad",
        padding: "0",
        textDecoration: "none",
        fontSize: ".95rem",
        fontWeight: "normal",
        textTransform: "none",
        minWidth: "auto",
    },
    headline: {
        color: "#666",
        fontSize: ".75rem",
    },
    linkColor: {
        color: "#8966ad",
        textDecoration: "none",
    },
}));

function AssociateCallLog(props) {
    const classes = useStyles();
    const [expertPreview, setOpenExpertPreview] = useState(false);
    const [currentExpertId, setCurrentExpertId] = useState(null);
    // =========
    // CONSTANTS
    // =========

    const { data, user } = props;

    // =========
    // FUNCTIONS
    // =========
    const openExpertPreview = (expertId) => {
        setCurrentExpertId(expertId);
        setOpenExpertPreview(true);
    };

    // =====
    // TABLE
    // =====

    const options = {
        print: false,
        rowsPerPage: 100,
        viewColumns: false,
        download: true,
        selectableRows: "none",
    };

    const columns = [
        {
            name: "expert_name",
            label: "Expert",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { expert_id, expert_name } = data?.[dataIndex];

                    return (
                        <Button className={classes.linkColor} onClick={() => openExpertPreview(expert_id)}>
                            {expert_name}
                        </Button>
                    );
                },
            },
        },
        {
            name: "number_called",
            label: "Number",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    const { number_called } = data?.[dataIndex];

                    return (
                        <>
                            <a style={{ display: "inline-block" }} href={`tel:${number_called}`}>
                                {number_called ? formatPhoneNumber(number_called) : number_called}
                            </a>
                        </>
                    );
                },
            },
        },
        {
            name: "call_status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { call_status, call_details, project_title, project_id } = data?.[dataIndex];

                    return (
                        <>
                            <a className={classes.linkColor} href={`/project/view/${project_id}`}>
                                {project_title}
                            </a>
                            <p>{call_status}</p>
                            <strong>Details:</strong>
                            <p>{call_details}</p>
                        </>
                    );
                },
            },
        },
        {
            name: "created_on",
            label: "Date",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { created_on } = data?.[dataIndex];

                    return <>{basicDateTimeStamp(created_on)}</>;
                },
            },
        },
    ];

    return (
        <>
            <h5>Total: {data.length} (Past 90 Days)</h5>
            <MUIDataTable data={data} options={options} columns={columns} />
            {expertPreview && (
                <ExpertPreviewWrapperNext
                    open={expertPreview}
                    setOpen={setOpenExpertPreview}
                    expert_id={currentExpertId}
                    // project_id={project_id}
                />
            )}
        </>
    );
}

export default AssociateCallLog;
