import React, { useState, useContext, useCallback } from "react";
import { authContext } from "../../context/auth";
import axios from "axios";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    Box,
    Typography,
    Button,
    TextField,
    IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { formatPhoneNumber } from "../../Utils";

export default function GenerateIndividualTokens(props) {
    const { project_id, expert, data, openDialog, closeEvent } = props;
    const { auth } = useContext(authContext);

    const [selectedSurvey, setSelectedSurvey] = useState({
        id: "",
        token: "",
    });
    const [onboardLink, setOnboardLink] = useState("");
    const [token, setToken] = useState("");

    if (!data) return <div></div>;

    // console.log(data)

    function handleDialogClose() {
        setSelectedSurvey({
            ...selectedSurvey,
            id: "",
            token: "",
        });
        setOnboardLink("");
        closeEvent();
    }

    const generateToken = useCallback(async (id, sid) => {
        const url = "/api/expert/generateTokenById";
        const postData = {
            id,
            survey_id: sid ? sid : "",
            pid: project_id ? project_id : "",
            rmid: auth.data ? auth.data.id : "",
            rmemail: auth.data ? auth.data.mail : "",
            rmfname: auth.data ? auth.data.fname : "",
            rmlname: auth.data ? auth.data.lname : "",
            rmphone: auth.data ? formatPhoneNumber(auth.data.number) : "",
        };
        console.log("POST INDIVIDUAL TOKEN DATA: ", postData);
        await axios
            .post(url, postData, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    console.log("Token generated? ", result.data.data);
                    const tncLink = `https://firstthought.io/onboard/${result.data.data}`;
                    setOnboardLink(tncLink);
                    setToken(result.data.data);
                    console.log("Token generated: result.data.data");
                }
            })
            .catch((result) => {
                console.log("Something went wrong, API Error, 404 Error:", result);
            });
    }, []);
    const generateSurveyToken = useCallback(async (expert_id, survey_id) => {
        const url = "/api/survey/generatetokenurl";
        const postData = {
            expert_id,
            survey_id,
            project_id: project_id ? parseInt(project_id, 10) : "",
            rmid: auth.data ? auth.data.id : "",
            rmemail: auth.data ? auth.data.mail : "",
            rmfname: auth.data ? auth.data.fname : "",
            rmlname: auth.data ? auth.data.lname : "",
            rmphone: auth.data ? formatPhoneNumber(auth.data.number) : "",
        };
        console.log("POST INDIVIDUAL TOKEN DATA: ", postData);
        await axios
            .post(url, postData, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    console.log("Survey Token Generated? ", result.data.data);
                    const surveyLink = `${result.data.data}`;
                    setSelectedSurvey({
                        id: survey_id,
                        token: surveyLink,
                    });
                }
            })
            .catch((result) => {
                console.log("Something went wrong, API Error, 404 Error:", result);
            });
    }, []);

    function addExpertToSurvey(eid, sid) {
        const surveyObject = {
            project_id: parseInt(project_id, 10),
            survey_id: sid,
            created_by: auth.data.id,
            experts: [eid],
        };
        console.log("Add Expert to Survey POST: ", `${JSON.stringify(surveyObject)}`);
        const url = "/api/survey/addExperts";
        axios
            .post(url, surveyObject, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((result) => {
                if (result.status === 200 && result.data) {
                    console.log("Expert ADDED? ", result.statusText);
                    generateSurveyToken(eid, sid);
                    // Generate Onboard Token as well
                    generateToken(eid, sid);
                } else {
                    console.log(`Good POST, but something went wrong: ${JSON.stringify(surveyObject)}`);
                }
            })
            .catch((result) => {
                console.log("API not done yet, 404 Error:", result);
            });
    }

    function copyLink(e) {
        const copied = e.target;
        copied.select();
        document.execCommand("copy", false);
        alert(`SUCCESSFULLY COPIED!" ${copied.value}`);
    }

    return (
        <Dialog
            open={openDialog}
            onClose={handleDialogClose}
            fullWidth
            maxWidth="lg"
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleDialogClose}
                    sx={{ float: "right" }}
                    aria-label="close"
                    size="large"
                >
                    <CloseIcon />
                </IconButton>
                Generate Onboarding and Screener Links for {expert.name}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item sm={4} xs={12}>
                        <Typography sx={{ fontSize: "1.25rem", flexBasis: "75%", flexShrink: 0 }}>
                            <strong>Screeners:</strong>
                        </Typography>
                        <Typography sx={{ fontSize: ".75rem", color: "#333", textAlign: "left" }}>
                            By selecting a screener, you are saving this expert to the screener. This also creates a
                            unique screener url for {expert.name} ONLY
                        </Typography>
                        <br />
                        {data.length < 1 && (
                            <Typography sx={{ color: "red", fontSize: ".75rem", marginTop: "0" }}>
                                This project has no screeners associated with it.
                            </Typography>
                        )}
                        {data.length > 0 && (
                            <>
                                {data
                                    .filter((survey) => survey.published === true)
                                    .map(function (s, idx) {
                                        return (
                                            <Button
                                                key={s.id}
                                                sx={
                                                    selectedSurvey.id === s.id
                                                        ? {
                                                              color: "#523197",
                                                              padding: "0",
                                                              textDecoration: "none",
                                                              fontSize: "1.25rem",
                                                              fontWeight: "bold",
                                                              textTransform: "none",
                                                              minWidth: "auto",
                                                              textAlign: "left",
                                                              display: "block",
                                                              lineHeight: "120%",
                                                              marginBottom: "15px",
                                                          }
                                                        : {
                                                              color: "#523197",
                                                              padding: "0",
                                                              textDecoration: "none",
                                                              fontSize: "1rem",
                                                              fontWeight: "normal",
                                                              textTransform: "none",
                                                              minWidth: "auto",
                                                              textAlign: "left",
                                                              display: "block",
                                                              lineHeight: "120%",
                                                              marginBottom: "15px",
                                                          }
                                                }
                                                onClick={() => addExpertToSurvey(expert.id, s.id)}
                                            >
                                                {s.title} {s.id === expert.screener_id && "(Recent)"}
                                            </Button>
                                        );
                                    })}
                            </>
                        )}
                    </Grid>
                    <Grid item sm={8} xs={12}>
                        <TextField
                            fullWidth
                            onClick={copyLink}
                            id="individual_onboarding_url"
                            name="individual_onboarding_url"
                            label="Onboarding AND Screener Link"
                            margin="normal"
                            value={onboardLink}
                        />
                        <TextField
                            fullWidth
                            onClick={copyLink}
                            id="individual_screener_url"
                            name="individual_screener_url"
                            label="Screener Only Link"
                            margin="normal"
                            value={selectedSurvey.token}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
