/* eslint-disable indent */
import React, { useContext } from "react";
import { Link } from "@mui/material";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DoneIcon from "@mui/icons-material/Done";
import NotDoneIcon from "@mui/icons-material/NotInterested";
import Button from "@mui/material/Button";
import "moment-duration-format";
import premiumOptions from "../data/premiumOptions";
import durationOptions from "../data/durationOptions";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import { authContext } from "../context/auth";
import moment from "moment";
import { Typography } from "@mui/material";
import "moment-duration-format";
import LinearLoader from "./LinearLoader";
import { Tooltip } from "@mui/material";
// import { classNames } from "react-select/dist/declarations/src/utils";

function ProjectDescription(props) {
    const data = props.data;
    const error = props.error;
    props = props.props;
    const { history } = props;
    const theme = useTheme();

    const twilioSettings = {
        1: "Automatically dial out to Expert when Client Contact dials in to bridge",
        2: "Automatically dial out to Client Contact when Expert dials in to bridge",
        3: "Automatically dial out to both Client Contact and Expert at scheduled call start time",
        4: "Client Contact and Expert to manually dial in",
        5: "None",
    };

    function tagSearchEvent(tid) {
        history.push(`/search?type=expert&and_tags=${tid}`);
    }

    if (error) return <div>failed to load</div>;
    if (!data) {
        return <LinearLoader />;
    } else {
        const parsedData = parseProjectData(data);
        return (
            // TODO - Style area, refactor to MUI, Create dynamic content
            <Accordion defaultExpanded>
                <AccordionSummary aria-controls="panel1bh-content" expandIcon={<ExpandMoreIcon />} id="panel1bh-header">
                    <Box sx={{ fontSize: "1.45rem", flexBasis: "75%", flexShrink: 0 }}>
                        <Box
                            sx={{
                                color: "#B1B1B1",
                                marginBottom: theme.spacing(0.25),
                                fontSize: ".75rem",
                                textAlign: "left",
                                fontWeight: "normal",
                                marginTop: ".5rem",
                            }}
                        >
                            Priority:{" "}
                            <Box
                                component={"strong"}
                                sx={{
                                    color: "#888",
                                    marginBottom: theme.spacing(0.5),
                                    fontSize: "inherit",
                                    marginRight: "1rem",
                                }}
                            >
                                {parsedData.priority}
                            </Box>{" "}
                            Code:{" "}
                            <Box
                                component={"strong"}
                                sx={{
                                    color: "#888",
                                    marginBottom: theme.spacing(0.5),
                                    fontSize: "inherit",
                                    marginRight: "1rem",
                                }}
                            >
                                {parsedData.case_code}
                            </Box>{" "}
                            Status:{" "}
                            <Box
                                component={"strong"}
                                sx={{
                                    color: "#888",
                                    marginBottom: theme.spacing(0.5),
                                    fontSize: "inherit",
                                    marginRight: "1rem",
                                }}
                            >
                                {parsedData.status}
                            </Box>{" "}
                            Industry:{" "}
                            <Box
                                component={"strong"}
                                sx={{
                                    color: "#888",
                                    marginBottom: theme.spacing(0.5),
                                    fontSize: "inherit",
                                    marginRight: "1rem",
                                }}
                            >
                                {parsedData.industry}
                            </Box>{" "}
                            Type:{" "}
                            <Box
                                component={"strong"}
                                sx={{
                                    color: "#888",
                                    marginBottom: theme.spacing(0.5),
                                    fontSize: "inherit",
                                    marginRight: "1rem",
                                }}
                            >
                                {parsedData.type}
                            </Box>{" "}
                            Created:{" "}
                            <Box
                                component={"strong"}
                                sx={{
                                    color: "#888",
                                    marginBottom: theme.spacing(0.5),
                                    fontSize: "inherit",
                                    marginRight: "1rem",
                                }}
                            >
                                {formatDateString(parsedData.created_on)}
                            </Box>
                        </Box>
                        {parsedData.status_id === 6 && (
                            <Box component={"span"} sx={{ color: theme.palette.secondary.main }}>
                                [On Hold]
                            </Box>
                        )}{" "}
                        {parsedData.title}
                        {" - "}
                        <Link
                            sx={{ color: "#8966ad", textDecoration: "none", fontSize: "1rem" }}
                            href={`/project/edit/${props.match.params.id}`}
                        >
                            Edit
                        </Link>
                        <Box
                            sx={{
                                color: "#B1B1B1",
                                marginBottom: theme.spacing(0.25),
                                fontSize: ".75rem",
                                textAlign: "left",
                                fontWeight: "normal",
                                marginTop: ".5rem",
                            }}
                        >
                            {"Attached: "}
                            <Box
                                component={"strong"}
                                sx={{
                                    color: "#888",
                                    marginBottom: theme.spacing(0.5),
                                    fontSize: "inherit",
                                    marginRight: "1rem",
                                }}
                            >{`${parsedData.expertCount} `}</Box>
                            {"Screened: "}
                            <Box
                                component={"strong"}
                                sx={{
                                    color: "#888",
                                    marginBottom: theme.spacing(0.5),
                                    fontSize: "inherit",
                                    marginRight: "1rem",
                                }}
                            >{`${parsedData.screenedCount} `}</Box>
                            {"On Hold: "}
                            <Box
                                component={"strong"}
                                sx={{
                                    color: "#888",
                                    marginBottom: theme.spacing(0.5),
                                    fontSize: "inherit",
                                    marginRight: "1rem",
                                }}
                            >{`${parsedData.onHoldCount} `}</Box>
                            {"Interested: "}
                            <Box
                                component={"strong"}
                                sx={{
                                    color: "#888",
                                    marginBottom: theme.spacing(0.5),
                                    fontSize: "inherit",
                                    marginRight: "1rem",
                                }}
                            >{`${parsedData.expertsAccepted} `}</Box>
                            &#9742;&nbsp;
                            {"Planned: "}
                            <Box
                                component={"strong"}
                                sx={{
                                    color: "#888",
                                    marginBottom: theme.spacing(0.5),
                                    fontSize: "inherit",
                                    marginRight: "1rem",
                                }}
                            >{`${parsedData.calls_planned} `}</Box>
                            &#9993;
                            {"  To Client: "}
                            <Box
                                component={"strong"}
                                sx={{
                                    color: "#888",
                                    marginBottom: theme.spacing(0.5),
                                    fontSize: "inherit",
                                    marginRight: "1rem",
                                }}
                            >{`${parsedData.sentToClient} `}</Box>
                            &#9993;
                            {"  To Compliance: "}
                            <Box
                                component={"strong"}
                                sx={{
                                    color: "#888",
                                    marginBottom: theme.spacing(0.5),
                                    fontSize: "inherit",
                                    marginRight: "1rem",
                                }}
                            >{`${parsedData.sentToCompliance} `}</Box>
                            {"Client Selected: "}
                            <Box
                                component={"strong"}
                                sx={{
                                    color: "#888",
                                    marginBottom: theme.spacing(0.5),
                                    fontSize: "inherit",
                                    marginRight: "1rem",
                                }}
                            >{`${parsedData.clientSelected} `}</Box>
                            {"Scheduled: "}
                            <Box
                                component={"strong"}
                                sx={{
                                    color: "#888",
                                    marginBottom: theme.spacing(0.5),
                                    fontSize: "inherit",
                                    marginRight: "1rem",
                                }}
                            >{`${parsedData.expertsScheduled} `}</Box>
                            &#9742;&nbsp;
                            {"Completed: "}
                            <Box
                                component={"strong"}
                                sx={{
                                    color: "#888",
                                    marginBottom: theme.spacing(0.5),
                                    fontSize: "inherit",
                                    marginRight: "1rem",
                                }}
                            >{`${parsedData.expertsCompleted} `}</Box>
                            &#9742;&nbsp;
                            {"Remaining: "}
                            <Box
                                component={"strong"}
                                sx={{
                                    color: "#888",
                                    marginBottom: theme.spacing(0.5),
                                    fontSize: "inherit",
                                    marginRight: "1rem",
                                }}
                            >{`${parsedData.calls_planned - parsedData.expertsScheduled} `}</Box>
                        </Box>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={1}>
                        <Grid item sm={5} xs={12}>
                            <Box sx={{ paddingTop: "8px", borderTop: "2px solid #444" }}>
                                <Typography
                                    sx={{
                                        color: "#444",
                                        marginBottom: theme.spacing(0.25),
                                        fontSize: ".85rem",
                                        textAlign: "left",
                                        fontWeight: "normal",
                                    }}
                                >
                                    <Box
                                        component={"label"}
                                        sx={{
                                            color: "#B1B1B1",
                                            marginBottom: theme.spacing(0.25),
                                            fontSize: ".75rem",
                                            textAlign: "left",
                                            fontWeight: "normal",
                                            marginTop: ".5rem",
                                        }}
                                    >
                                        Project Manager(s): * = primary
                                    </Box>
                                    <br />
                                    {parsedData.primary_project_manager} *
                                    <br />
                                    {parsedData.additional_project_managers.map(function (c, index) {
                                        return (
                                            <span key={c.id}>
                                                {c.fname} {c.lname}
                                                {index < parsedData.additional_project_managers.length - 1 && ", "}
                                            </span>
                                        );
                                    })}
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#444",
                                        marginBottom: theme.spacing(0.25),
                                        fontSize: ".85rem",
                                        textAlign: "left",
                                        fontWeight: "normal",
                                    }}
                                >
                                    <Box
                                        component={"label"}
                                        sx={{
                                            color: "#B1B1B1",
                                            marginBottom: theme.spacing(0.25),
                                            fontSize: ".75rem",
                                            textAlign: "left",
                                            fontWeight: "normal",
                                            marginTop: ".5rem",
                                        }}
                                    >
                                        Primary Client Contact:
                                    </Box>
                                    <br />
                                    <Tooltip arrow title={"Contact view"}>
                                        <Link
                                            sx={{ color: "#8966ad", textDecoration: "none", fontSize: "inherit" }}
                                            href={`/contact/view/${parsedData.primary_client_contact.client_contact_id}`}
                                        >
                                            {parsedData.primary_client_contact.firstname}{" "}
                                            {parsedData.primary_client_contact.lastname}
                                        </Link>
                                    </Tooltip>
                                    ,{" "}
                                    <Tooltip arrow title={"Client view"}>
                                        <Link
                                            sx={{ color: "#8966ad", textDecoration: "none", fontSize: "inherit" }}
                                            href={`/client/view/${parsedData.client_id}`}
                                        >
                                            {parsedData.client_name}
                                        </Link>
                                    </Tooltip>
                                </Typography>
                                {parsedData.additional_client_contacts.length > 0 && (
                                    <Typography
                                        sx={{
                                            color: "#444",
                                            marginBottom: theme.spacing(0.25),
                                            fontSize: ".85rem",
                                            textAlign: "left",
                                            fontWeight: "normal",
                                        }}
                                    >
                                        <Box
                                            component={"label"}
                                            sx={{
                                                color: "#B1B1B1",
                                                marginBottom: theme.spacing(0.25),
                                                fontSize: ".75rem",
                                                textAlign: "left",
                                                fontWeight: "normal",
                                                marginTop: ".5rem",
                                            }}
                                        >
                                            Additional Client Contacts:
                                        </Box>
                                        <br />
                                        {parsedData.additional_client_contacts.map(function (c, index) {
                                            return (
                                                <Link
                                                    sx={{
                                                        color: "#8966ad",
                                                        textDecoration: "none",
                                                        fontSize: "inherit",
                                                    }}
                                                    key={c.client_contact_id}
                                                    href={`/contact/view/${c.client_contact_id}`}
                                                >
                                                    {c.firstname} {c.lastname}
                                                    {index < parsedData.additional_client_contacts.length - 1 && ", "}
                                                </Link>
                                            );
                                        })}
                                    </Typography>
                                )}
                                <Typography
                                    sx={{
                                        color: "#444",
                                        marginBottom: theme.spacing(0.25),
                                        fontSize: ".85rem",
                                        textAlign: "left",
                                        fontWeight: "normal",
                                    }}
                                >
                                    <Box
                                        component={"label"}
                                        sx={{
                                            color: "#B1B1B1",
                                            marginBottom: theme.spacing(0.25),
                                            fontSize: ".75rem",
                                            textAlign: "left",
                                            fontWeight: "normal",
                                            marginTop: ".5rem",
                                        }}
                                    >
                                        Conference Bridge Settings:
                                    </Box>
                                    <br />
                                    {parsedData.conference_bridge_setting == "2" ? (
                                        <span>
                                            {parsedData.external_bridge_numbers.map((c) => (
                                                <Box
                                                    component={"span"}
                                                    sx={{
                                                        color: "#666",
                                                        fontSize: ".75rem",
                                                        display: "inline-block",
                                                        marginRight: "15px",
                                                    }}
                                                    key={c.pin}
                                                >
                                                    <strong>{c.type}:</strong> {formatPhoneNumber(c.number)}
                                                    {" - pin: "}
                                                    {c.pin}
                                                    {" - "}
                                                    {"("}
                                                    {c.country}
                                                    {")"}
                                                </Box>
                                            ))}
                                        </span>
                                    ) : (
                                        <Box
                                            component={"span"}
                                            sx={{
                                                color: "#444",
                                                marginBottom: theme.spacing(0.25),
                                                fontSize: ".75rem",
                                                textAlign: "left",
                                                fontWeight: "normal",
                                            }}
                                        >
                                            {twilioSettings[parsedData.twilio_bridge_setting]}
                                        </Box>
                                    )}
                                </Typography>
                            </Box>
                            {parsedData.specialty && parsedData.specialty.length > 0 && (
                                <Box
                                    sx={{
                                        color: "#444",
                                        marginBottom: theme.spacing(0.25),
                                        fontSize: ".75rem",
                                        textAlign: "left",
                                        fontWeight: "normal",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: "#B1B1B1",
                                            marginBottom: theme.spacing(0.25),
                                            fontSize: ".75rem",
                                            textAlign: "left",
                                            fontWeight: "normal",
                                            marginTop: ".5rem",
                                        }}
                                    >
                                        Specialties:
                                    </Typography>
                                    {parsedData.specialty.map(function (s, index) {
                                        return (
                                            <Box
                                                component={"strong"}
                                                sx={{
                                                    display: "inline",
                                                    fontSize: ".75rem",
                                                    color: "#8966ad",
                                                    padding: "0",
                                                    margin: "0",
                                                    textTransform: "capitalize",
                                                    fontWeight: "normal",
                                                    lineHeight: "130%",
                                                    minWidth: "auto",
                                                    textDecoration: "none",
                                                }}
                                                key={index}
                                            >
                                                {" "}
                                                {s.label}
                                                {index < parsedData.specialty.length - 1 && ", "}
                                            </Box>
                                        );
                                    })}
                                </Box>
                            )}
                            {parsedData.calls_planned > 0 && (
                                <Box
                                    sx={{
                                        color: "#444",
                                        marginBottom: theme.spacing(0.25),
                                        fontSize: ".75rem",
                                        textAlign: "left",
                                        fontWeight: "normal",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: "#B1B1B1",
                                            marginBottom: theme.spacing(0.25),
                                            fontSize: ".75rem",
                                            textAlign: "left",
                                            fontWeight: "normal",
                                            marginTop: ".5rem",
                                        }}
                                    >
                                        Calls Planned: <strong>{parsedData.calls_planned}</strong>
                                    </Typography>
                                </Box>
                            )}
                            {parsedData.minimum_bonus && parsedData.minimum_bonus > 0 && (
                                <Box
                                    sx={{
                                        color: "#444",
                                        marginBottom: theme.spacing(0.25),
                                        fontSize: ".75rem",
                                        textAlign: "left",
                                        fontWeight: "normal",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: "#B1B1B1",
                                            marginBottom: theme.spacing(0.25),
                                            fontSize: ".75rem",
                                            textAlign: "left",
                                            fontWeight: "normal",
                                            marginTop: ".5rem",
                                        }}
                                    >
                                        Minimum Bonus (N): <strong>{parsedData.minimum_bonus}</strong>
                                    </Typography>
                                    {parsedData.effort_index && parsedData.effort_index > 0 && (
                                        <Box
                                            sx={{
                                                color: "#444",
                                                marginBottom: theme.spacing(0.25),
                                                fontSize: ".75rem",
                                                textAlign: "left",
                                                fontWeight: "normal",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: "#B1B1B1",
                                                    marginBottom: theme.spacing(0.25),
                                                    fontSize: ".75rem",
                                                    textAlign: "left",
                                                    fontWeight: "normal",
                                                    marginTop: ".5rem",
                                                }}
                                            >
                                                Effort Index: <strong>{parsedData.effort_index}</strong>
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>
                            )}
                            {parsedData.tags && parsedData.tags.length > 0 && (
                                <Box
                                    sx={{
                                        color: "#444",
                                        marginBottom: theme.spacing(0.25),
                                        fontSize: ".75rem",
                                        textAlign: "left",
                                        fontWeight: "normal",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: "#B1B1B1",
                                            marginBottom: theme.spacing(0.25),
                                            fontSize: ".75rem",
                                            textAlign: "left",
                                            fontWeight: "normal",
                                            marginTop: ".5rem",
                                        }}
                                    >
                                        Tags:
                                    </Typography>
                                    {parsedData.tags.map(function (t, idx) {
                                        return (
                                            <Tooltip key={t.value + "tagtooltip"} arrow title={"Search tag"}>
                                                <Button
                                                    sx={{
                                                        display: "inline",
                                                        fontSize: ".75rem",
                                                        color: "#8966ad",
                                                        padding: "0",
                                                        margin: "0",
                                                        textTransform: "capitalize",
                                                        fontWeight: "normal",
                                                        lineHeight: "130%",
                                                        minWidth: "auto",
                                                        textDecoration: "none",
                                                    }}
                                                    onClick={() => tagSearchEvent(t.value)}
                                                    key={t.value}
                                                >
                                                    {" "}
                                                    {t.label}
                                                    {idx < parsedData.tags.length - 1 && ","}
                                                    <span>&nbsp;</span>
                                                </Button>
                                            </Tooltip>
                                        );
                                    })}
                                </Box>
                            )}
                        </Grid>
                        <Grid item sm={7} xs={12}>
                            <Box
                                sx={{
                                    paddingTop: "8px",
                                    borderTop: "2px solid #444",
                                }}
                            >
                                {parsedData.outreach_templates && parsedData.outreach_templates.length > 0 && (
                                    <Box
                                        sx={{
                                            color: "#444",
                                            marginBottom: theme.spacing(0.25),
                                            fontSize: ".75rem",
                                            textAlign: "left",
                                            fontWeight: "normal",
                                        }}
                                    >
                                        <div
                                            sx={{
                                                color: "#B1B1B1",
                                                marginBottom: theme.spacing(0.25),
                                                fontSize: ".75rem",
                                                textAlign: "left",
                                                fontWeight: "normal",
                                                marginTop: ".5rem",
                                            }}
                                        >
                                            Automated Outreach Templates:
                                            {parsedData.outreach_templates.map((t) => {
                                                return (
                                                    <div key={t.index}>
                                                        {`${t.type} ${t.index}:`}{" "}
                                                        {t.updated !== "" ? (
                                                            <span>
                                                                <DoneIcon
                                                                    sx={{
                                                                        fontSize: "1rem",
                                                                        color: "green",
                                                                    }}
                                                                />
                                                            </span>
                                                        ) : (
                                                            <strong>
                                                                <NotDoneIcon
                                                                    sx={{
                                                                        fontSize: "1rem",
                                                                        color: "red",
                                                                    }}
                                                                />
                                                            </strong>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </Box>
                                )}
                                {parsedData.custom_charge > 0 && (
                                    <Box
                                        sx={{
                                            color: "#444",
                                            marginBottom: theme.spacing(0.25),
                                            fontSize: ".75rem",
                                            textAlign: "left",
                                            fontWeight: "normal",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: "#B1B1B1",
                                                marginBottom: theme.spacing(0.25),
                                                fontSize: ".75rem",
                                                textAlign: "left",
                                                fontWeight: "normal",
                                                marginTop: ".5rem",
                                            }}
                                        >
                                            Custom Charge (Cost Plus):{" "}
                                            <strong>
                                                {parsedData.custom_charge} USD / {parsedData.custom_hourly_rate}
                                            </strong>
                                        </Typography>
                                    </Box>
                                )}
                                {parsedData.custom_hourly_rate > 1.0 && (
                                    <Box
                                        sx={{
                                            color: "#444",
                                            marginBottom: theme.spacing(0.25),
                                            fontSize: ".75rem",
                                            textAlign: "left",
                                            fontWeight: "normal",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: "#B1B1B1",
                                                marginBottom: theme.spacing(0.25),
                                                fontSize: ".75rem",
                                                textAlign: "left",
                                                fontWeight: "normal",
                                                marginTop: ".5rem",
                                            }}
                                        >
                                            Custom Hourly Multiplier: <strong>{parsedData.custom_hourly_rate}</strong>
                                        </Typography>
                                    </Box>
                                )}
                                {parsedData.grace_period && parsedData.grace_period > 0 && (
                                    <Box
                                        sx={{
                                            color: "#444",
                                            marginBottom: theme.spacing(0.25),
                                            fontSize: ".75rem",
                                            textAlign: "left",
                                            fontWeight: "normal",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: "#B1B1B1",
                                                marginBottom: theme.spacing(0.25),
                                                fontSize: ".75rem",
                                                textAlign: "left",
                                                fontWeight: "normal",
                                                marginTop: ".5rem",
                                            }}
                                        >
                                            Grace Period: <strong>{parsedData.grace_period} minutes</strong>
                                        </Typography>
                                    </Box>
                                )}
                                {parsedData.price_per_unit > 0 && (
                                    <Box
                                        sx={{
                                            color: "#444",
                                            marginBottom: theme.spacing(0.25),
                                            fontSize: ".75rem",
                                            textAlign: "left",
                                            fontWeight: "normal",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: "#B1B1B1",
                                                marginBottom: theme.spacing(0.25),
                                                fontSize: ".75rem",
                                                textAlign: "left",
                                                fontWeight: "normal",
                                                marginTop: ".5rem",
                                            }}
                                        >
                                            Price Per Unit: <strong>{parsedData.price_per_unit}</strong>
                                        </Typography>
                                    </Box>
                                )}
                                {parsedData.premium_pricing_model_id && parsedData.premium_pricing_model_id > 0 && (
                                    <Box
                                        sx={{
                                            color: "#444",
                                            marginBottom: theme.spacing(0.25),
                                            fontSize: ".75rem",
                                            textAlign: "left",
                                            fontWeight: "normal",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: "#B1B1B1",
                                                marginBottom: theme.spacing(0.25),
                                                fontSize: ".75rem",
                                                textAlign: "left",
                                                fontWeight: "normal",
                                                marginTop: ".5rem",
                                            }}
                                        >
                                            Premium Model:{" "}
                                            <strong>
                                                {
                                                    premiumOptions.find(
                                                        (p) => p.value === parsedData.premium_pricing_model_id
                                                    ).label
                                                }
                                            </strong>
                                        </Typography>
                                    </Box>
                                )}
                                {parsedData.duration_model_id && parsedData.duration_model_id > 0 && (
                                    <Box
                                        sx={{
                                            color: "#444",
                                            marginBottom: theme.spacing(0.25),
                                            fontSize: ".75rem",
                                            textAlign: "left",
                                            fontWeight: "normal",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: "#B1B1B1",
                                                marginBottom: theme.spacing(0.25),
                                                fontSize: ".75rem",
                                                textAlign: "left",
                                                fontWeight: "normal",
                                                marginTop: ".5rem",
                                            }}
                                        >
                                            Duration Model:{" "}
                                            <strong>
                                                {
                                                    durationOptions.find(
                                                        (p) => p.value === parsedData.duration_model_id
                                                    ).label
                                                }
                                            </strong>
                                        </Typography>
                                    </Box>
                                )}
                                {parsedData.fair_market_value > 0 && (
                                    <Box
                                        sx={{
                                            color: "#444",
                                            marginBottom: theme.spacing(0.25),
                                            fontSize: ".75rem",
                                            textAlign: "left",
                                            fontWeight: "normal",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: "#B1B1B1",
                                                marginBottom: theme.spacing(0.25),
                                                fontSize: ".75rem",
                                                textAlign: "left",
                                                fontWeight: "normal",
                                                marginTop: ".5rem",
                                            }}
                                        >
                                            Fair Market Value Cap: <strong>{parsedData.fair_market_value}</strong>
                                        </Typography>
                                    </Box>
                                )}
                                {parsedData.budget > 0 && (
                                    <Box
                                        sx={{
                                            color: "#444",
                                            marginBottom: theme.spacing(0.25),
                                            fontSize: ".75rem",
                                            textAlign: "left",
                                            fontWeight: "normal",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: "#B1B1B1",
                                                marginBottom: theme.spacing(0.25),
                                                fontSize: ".75rem",
                                                textAlign: "left",
                                                fontWeight: "normal",
                                                marginTop: ".5rem",
                                            }}
                                        >
                                            Budget: <strong>{parsedData.budget}</strong>
                                        </Typography>
                                    </Box>
                                )}
                                {parsedData.email_copy && (
                                    <Box
                                        sx={{
                                            color: "#666",
                                            fontSize: ".75rem",
                                            padding: "8px",
                                            border: "2px solid #F1F1F1",
                                            borderRadius: "2px",
                                            marginTop: "12px",
                                            marginBottom: "12px",
                                        }}
                                    >
                                        <Box
                                            component={"label"}
                                            sx={{
                                                color: "#B1B1B1",
                                                marginBottom: theme.spacing(0.25),
                                                fontSize: ".75rem",
                                                textAlign: "left",
                                                fontWeight: "normal",
                                                marginTop: ".5rem",
                                            }}
                                        >
                                            Private Description/Email Copy
                                        </Box>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: parsedData.email_copy,
                                            }}
                                        />
                                    </Box>
                                )}
                                {parsedData.description && (
                                    <Box
                                        sx={{
                                            padding: "8px",
                                            border: "2px solid #F1F1F1",
                                            borderRadius: "2px",
                                            marginTop: "12px",
                                            marginBottom: "12px",
                                        }}
                                    >
                                        <Box
                                            component={"label"}
                                            sx={{
                                                color: "#B1B1B1",
                                                marginBottom: theme.spacing(0.25),
                                                fontSize: ".75rem",
                                                textAlign: "left",
                                                fontWeight: "normal",
                                                marginTop: ".5rem",
                                            }}
                                        >
                                            Description (HTML Formatted/Customer facing)
                                        </Box>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: parsedData.description,
                                            }}
                                        />
                                    </Box>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        );
    }

    // Helper Functions
    function formatPhoneNumber(str) {
        const cleaned = `${str}`.replace(/\D/g, "");
        const formatted = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (formatted) {
            return `(${formatted[1]}) ${formatted[2]}-${formatted[3]}`;
        }
        return str;
    }

    function formatDateString(str) {
        return moment(str).format("MMM. Do, YYYY, h:mma");
    }

    // TODO - MOVE PARSEPROJECTDATA to backend
    function parseProjectData(pData) {
        if (pData.message && pData.data && pData.data.projectData) {
            // console.log("INside if");
            if (pData.data.projectData.length === 0) return;
            console.log("FETCHED PROJECT DATA IN DESCRIPTION COMPONENT: ", pData.data.projectData);
            // console.log(pData.data.projectData.expertsAccepted, " :: pData.data.data.expertsAccepted");
            const expertCount =
                pData.data.projectData.expertCount.length > 0
                    ? pData.data.projectData.expertCount[0].experts_count
                    : "0";
            const expertsAccepted =
                pData.data.projectData.expertsAccepted.length > 0
                    ? pData.data.projectData.expertsAccepted[0].experts_accepted
                    : "0";
            const screenedCount =
                pData.data.projectData.screenedCount.length > 0
                    ? pData.data.projectData.screenedCount[0].num_experts_screened
                    : "0";
            const onHoldCount =
                pData.data.projectData.onHoldCount.length > 0
                    ? pData.data.projectData.onHoldCount[0].num_experts_on_hold
                    : "0";
            const expertsScheduled =
                pData.data.projectData.expertsScheduled.length > 0
                    ? pData.data.projectData.expertsScheduled[0].experts_scheduled
                    : "0";
            const expertsCompleted =
                pData.data.projectData.expertsCompleted.length > 0
                    ? pData.data.projectData.expertsCompleted[0].experts_completed
                    : "0";
            const sentToClient =
                pData.data.projectData.sentToClient.length > 0
                    ? pData.data.projectData.sentToClient[0].sent_to_client
                    : "0";
            const clientSelected =
                pData.data.projectData.clientSelected.length > 0
                    ? pData.data.projectData.clientSelected[0].client_selected
                    : "0";
            const sentToCompliance =
                pData.data.projectData.sentToCompliance.length > 0
                    ? pData.data.projectData.sentToCompliance[0].sent_to_compliance
                    : "0";
            const project = pData.data.projectData.projectData[0];
            const tags = pData.data.projectData.tags.map((tag) => {
                return { label: tag.name, value: tag.tag_id };
            });
            const specialty = pData.data.projectData.specialty.map((s) => {
                return { label: s.name, value: s.specialty_id };
            });
            const pcc = pData.data.projectData.clientContacts.filter((item) => item.isprimary == true);
            const acc = pData.data.projectData.clientContacts.filter((item) => item.isprimary !== true);
            const apms = pData.data.projectData.additionalProjectManagers;
            const { comments } = pData.data.projectData;

            const settings = pData.data.projectData.confBridgeSettings;
            const primary = settings.filter((item) => item.isprimary !== false);
            let selectedTypeID = "1";
            let selectedTwilio = "1";
            let extNumbers = [];
            if (primary.length > 0) {
                const primaryData = primary[0];
                selectedTypeID = primaryData.type_id.toString();
                if (primaryData.data && primaryData.data.value !== undefined) {
                    selectedTwilio = primaryData.data.value;
                }
                if (Array.isArray(primaryData.data) && primaryData.data.length > 0) {
                    extNumbers = primaryData.data;
                }
            }
            const allSurveys = pData.data.projectData.surveys;
            const surveys = allSurveys.filter((s) => s.published);
            const unpublishedSurveys = allSurveys.filter((s) => !s.published);
            const attachedExperts = pData.data.projectData.experts;
            // console.log("Project DATA: ", project);
            const custom_charge = project.custom_charge;
            const custom_hourly_rate = project.custom_hourly_rate;
            const minimum_bonus = project.minimum_bonus;
            const effort_index = project.effort_index;
            const premium_pricing_model_id = project.premium_pricing_model_id;
            const grace_period = project.grace_period;
            const duration_model_id = project.duration_model_id;
            const fair_market_value = project.fair_market_value;
            const budget = project.budget;
            const outreach_templates = project.outreach_templates;
            return {
                ...data,
                id: project.id,
                isdeleted: project.isdeleted,
                title: project.title,
                description: project.description,
                email_copy: project.email_copy,
                project_not_received_by_email: project.project_not_received_by_email,
                client_id: project.client_id,
                client_name: project.client_name,
                primary_client_contact: pcc[0],
                additional_client_contacts: acc,
                expert_ids: project.expert_ids,
                primary_project_manager: `${project.project_manager_fname} ${project.project_manager_lname}`,
                additional_project_managers: apms,
                status: project.project_status,
                status_id: project.project_status_id,
                type: project.project_type,
                priority: project.project_priority,
                case_code: project.case_code,
                calls_planned: project.calls_planned,
                price_per_unit: project.price_per_unit,
                created_on: project.created_on,
                industry: project.industry,
                conference_bridge_setting: selectedTypeID,
                twilio_bridge_setting: selectedTwilio,
                external_bridge_numbers: extNumbers,
                notifications: [],
                specialty,
                tags,
                comments,
                responses: project.responses,
                scheduled_calls: project.scheduled_calls,
                completed_calls: project.completed_calls,
                experts: attachedExperts,
                unpublishedSurveys,
                surveys,
                expertCount,
                expertsAccepted,
                expertsScheduled,
                expertsCompleted,
                sentToClient,
                clientSelected,
                sentToCompliance,
                custom_charge,
                custom_hourly_rate,
                minimum_bonus,
                effort_index,
                premium_pricing_model_id,
                screenedCount,
                onHoldCount,
                grace_period,
                duration_model_id,
                fair_market_value,
                budget,
                outreach_templates,
            };
        }
    }
}

export default React.memo(ProjectDescription, (prevProps, nextProps) => {
    if (prevProps.state_manager.get.projectState === nextProps.state_manager.get.projectState) {
        return true; // props are equal
    }
    return false; // props are not equal -> update the component
});
