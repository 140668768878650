import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-typeahead/css/Typeahead.css";

import React, { useContext, useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Badge } from "@mui/material";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import { Grid } from "@mui/material";
import { authContext } from "../context/auth";
import axios from "axios";
import useSWR from "swr";
// assets
import mcHammer from "./../img/hammertime.gif";
const fetcher = (...args) => fetch(...args).then((res) => res.json());

// Frequently used styles
const toolbarButtonStyles = {
    float: "left",
    marginRight: "10px",
    marginBottom: "10px",
    marginLeft: "10px",
    width: "14%",
    height: "3vh",
    fontSize: ".65rem",
};
const useStyles = makeStyles((theme) => ({
    hammerTime: {
        transition: "1.5s",
        opacity: "0",
        width: "auto",
        height: "2rem",
        cursor: "pointer",
    },
    hammerTimeHover: {
        transition: "1.5s",
        width: "auto",
        height: "2rem",
        opacity: "1",
        cursor: "pointer",
    },
    nestedButtonStyles: {
        width: "100%",
        height: "3vh",
        fontSize: ".65rem",
    },
}));

function ProjectDescriptionHeader(props) {
    const _state = props.state_manager;
    const classes = useStyles();
    const [hammerTime, setHammerTime] = useState(false);
    const [hammerTimeHover, setHammerTimeHover] = useState(false);
    const [automatedRecording, setAutomatedRecording] = useState("");

    const { history, match } = props.props;

    // Helper Functions
    function searchExperts() {
        history.push(`/search?pid=${match.params.id}`);
    }
    function duplicateProject() {
        history.push(`/project/create?pid=${match.params.id}`);
    }
    function goToEmailDashboard() {
        history.push(`/project/emails/${match.params.id}`);
    }
    function goToRespondentView() {
        history.push(`/project/respondents/${match.params.id}`);
    }
    function goToSurveyCreate() {
        history.push(`/project/survey/new/${match.params.id}`);
    }
    function goToPricing() {
        history.push(`/project/pricing/${match.params.id}`);
    }

    function goToNewPage() {
        history.push(`/project/view/${match.params.id}`);
    }

    function goToScheduling() {
        history.push(`/project/schedule/${match.params.id}`);
    }
    // BETA FEATURE
    const handleMouseEnter = () => {
        setHammerTimeHover(true);
    };

    const handleMouseLeave = () => {
        setHammerTimeHover(false);
    };

    const handleHammerTime = () => {
        const newHammerTime = !hammerTime;
        setHammerTime(newHammerTime);
    };
    // TODO: DC BETA FEATURE
    function findExpertsToBeOutreached(arr) {
        const experts = [];
        arr.forEach(function (exp) {
            // Outreach experts who have not declined or screened yet
            const screenedExperts = exp.screened.length > 0 ? exp.screened[0].filter((e) => e.id === 28) : [];
            const alreadyRespondedExperts =
                exp.expertResponse.length > 0 ? exp.expertResponse[0].filter((e) => e.id === 4 || e.id === 5) : [];
            if (screenedExperts.length === 0 && alreadyRespondedExperts.length === 0) {
                // console.log("ALREADY SCREENED", screenedExperts);
                // console.log("ALREADY DECLINED", alreadyRespondedExperts);
                experts.push(exp);
            }
        });
        console.log("How many experts to be outreached? ", experts.length);
        _state.set.selected(experts);
        _state.switch.sendScreenerModal();
    }

    useEffect(() => {
        (async () => {
            const url = `/api/project/getSendRecordingStatus?id=${props.props.match.params.id}`;
            const status = await axios.get(url);
            if (status.data.status.send_recording_to_client) {
                setAutomatedRecording("On");
            } else {
                setAutomatedRecording("Off");
            }
        })();
    });

    return (
        <Box sx={{ width: "100%", paddingLeft: "10px" }}>
            <Button
                sx={{ float: "right" }}
                onClick={() => {
                    _state.switch.commentsModal();
                }}
            >
                <Box component={"span"} color="secondary">
                    <Badge badgeContent={_state.get.projectState.comments.length} color="secondary" />{" "}
                </Box>
                {_state.get.projectState.comments.length > 0 ? "--" : ""} Add/View Comments
            </Button>
            <img
                src={mcHammer}
                onClick={handleHammerTime}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className={hammerTimeHover || hammerTime ? classes.hammerTimeHover : classes.hammerTime}
            />
            <Grid sx={{ justifyContent: "left" }} container spacing={2}>
                <Button
                    sx={toolbarButtonStyles}
                    onClick={searchExperts}
                    fullWidth
                    size="small"
                    variant="contained"
                    color="primary"
                >
                    Find Experts
                </Button>

                <Button
                    sx={toolbarButtonStyles}
                    onClick={duplicateProject}
                    fullWidth
                    size="small"
                    variant="contained"
                    color="primary"
                >
                    Copy Project
                </Button>

                <Button
                    sx={toolbarButtonStyles}
                    onClick={goToScheduling}
                    fullWidth
                    size="small"
                    variant="contained"
                    color="primary"
                >
                    Scheduling
                </Button>

                <Button
                    sx={toolbarButtonStyles}
                    onClick={goToEmailDashboard}
                    fullWidth
                    size="small"
                    variant="contained"
                    color="primary"
                >
                    Delivery Statuses
                </Button>

                <Button
                    sx={toolbarButtonStyles}
                    onClick={goToRespondentView}
                    fullWidth
                    size="small"
                    variant="contained"
                    color="primary"
                >
                    Respondents
                </Button>

                <Button
                    sx={toolbarButtonStyles}
                    onClick={goToSurveyCreate}
                    fullWidth
                    size="small"
                    variant="contained"
                    color="primary"
                >
                    Build Survey
                </Button>

                <Button
                    sx={toolbarButtonStyles}
                    onClick={_state.switch.editSurveyModal}
                    fullWidth
                    size="small"
                    variant="contained"
                    color="primary"
                >
                    Edit Surveys
                </Button>

                <Button
                    sx={toolbarButtonStyles}
                    onClick={goToPricing}
                    fullWidth
                    size="small"
                    variant="contained"
                    color="primary"
                >
                    Calls Booked
                </Button>

                <Button
                    sx={toolbarButtonStyles}
                    onClick={async () => {
                        const toggle = automatedRecording == "On" ? false : true;
                        const url = `/api/project/toggleSendRecordings?id=${props.props.match.params.id}&toggle=${toggle}`;
                        await axios
                            .get(url)
                            .then((res) => {
                                const msg = toggle
                                    ? "Client will recieve transcription automatically after calls"
                                    : "Client will not recieve transcription after calls";
                                setAutomatedRecording(toggle ? "On" : "Off");
                                _state.flash.success(msg);
                            })
                            .catch((err) => {
                                _state.flash.error(
                                    "There was an error changing the send recording status of the project. Please contact engineering"
                                );

                                console.log(err, ":: toggleSendRecordings Error");
                            });
                    }}
                    fullWidth
                    size="small"
                    variant="contained"
                    color="primary"
                >
                    Send Transcript: &nbsp;
                    {automatedRecording == "On" ? (
                        <Box sx={{ color: "#00C9AB" }}>{automatedRecording}</Box>
                    ) : (
                        <Box sx={{ color: "#FF9774" }}>{automatedRecording}</Box>
                    )}
                </Button>
                <Button
                    sx={toolbarButtonStyles}
                    onClick={goToNewPage}
                    fullWidth
                    size="small"
                    variant="contained"
                    color="primary"
                >
                    New Page
                </Button>
                <Box display={hammerTime ? "block" : "none"} sx={toolbarButtonStyles}>
                    <Button
                        className={classes.nestedButtonStyles}
                        onClick={() => findExpertsToBeOutreached(props.experts)}
                        fullWidth
                        size="small"
                        variant="contained"
                        color="primary"
                    >
                        Outreach (IGNORE)
                    </Button>
                </Box>

                {/* <Button
                    sx={toolbarButtonStyles}
                    onClick={() => {
                        console.log(_state.get.selected);
                    }}
                    fullWidth
                    size="small"
                    variant="contained"
                    color="primary"
                >
                    debug
                </Button> */}
            </Grid>
        </Box>
    );
}

export default ProjectDescriptionHeader;

// export default React.memo(ProjectDescriptionHeader, (prevProps, nextProps) => {
//     if (prevProps.state_manager.get.projectState === nextProps.state_manager.get.projectState) {
//         return true; // props are equal
//     }
//     return false; // props are not equal -> update the component
// });
