import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import MUIDataTable from "mui-datatables";
import { authContext } from "../../context/auth";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { basicDateTimeStamp, truncateString, formatPhoneNumber } from "../../Utils";
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
// date-fns stuff
import { add, endOfDay, subHours } from "date-fns";
import { css } from "@emotion/react";
import SnippetDialog from "../SnippetDialog";
import ExpertPreviewWrapperNext from "../../components/wrappers/ExpertPreviewWrapperNext";
import { Backdrop, CircularProgress } from "@mui/material";
import GenerateIndividualTokens from "./GenerateIndividualTokens";
import ContactSearchModal from "./ContactSearchModal";
import PhoneLogsModal from "./PhoneLogsModal";
// import { SignalCellularNullTwoTone } from "@mui/icons-material";

// ======
// STYLES
// ======

const linkStyle = css`
    color: #8966ad;
    font-size: 1rem;
    font-weight: bold;
    padding: 0;
    text-align: left;
    line-height: 1.25rem;
`;

const smallLinkStyle = css`
    color: #8966ad;
    font-size: 0.85rem;
    font-weight: normal;
    padding: 0;
    text-align: left;
    line-height: 1.25rem;
    text-transform: Capitalize;
`;

const expiredTimeStyle = css`
    background-color: green;
    border-radius: 4px;
    color: #fff;
    font-size: 0.75rem;
    font-weight: bold;
    padding: 3px 5px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
`;

const resetButtonStyle = css`
    border-radius: 4px;
    color: #444;
    font-size: 0.85rem;
    background-color: #f1f1f1;
    border: 0;
    padding: 5px 12px;
    margin-right: 8px;
    margin-bottom: 12px;
`;

const angleButtonStyle = css`
    border-radius: 4px;
    color: #8966ad;
    font-size: 0.85rem;
    font-weight: bold;
    background-color: #e5e0f3;
    border: 0;
    padding: 5px 12px;
    margin-right: 8px;
    margin-bottom: 12px;
`;

const highlightAngleButtonStyle = css`
    border-radius: 4px;
    color: #fff;
    font-size: 0.85rem;
    font-weight: bold;
    background-color: #444;
    border: 0;
    padding: 5px 12px;
    margin-right: 8px;
    margin-bottom: 12px;
`;

const highlightTag = css`
    font-size: 0.9rem;
    font-weight: bold;
    background-color: #fcfcd4;
`;

const backdropStyles = css`
    color: #fff;
    z-index: 1201;
`;

export default function Interested(props) {
    // =========
    // CONSTANTS
    // =========

    const { project_id, projectScreeners, generateSnippetEvent, handleReminder, history } = props;

    // =====
    // STATE
    // =====
    const { auth } = useContext(authContext);
    const DEFAULT_EXPERT_PREVIEW = { display: false, expertId: null, propHistory: null };
    const [expertPreviewData, setExpertPreviewData] = useState(DEFAULT_EXPERT_PREVIEW);
    const [[stableData, filteredData], setData] = useState([[], []]); // data is going to have original set of data as first element and the filtered data as the second
    const [loading, setLoading] = useState(true);
    const [expertData, setExpertData] = useState([]);
    const [snippet, setSnippetModal] = useState(false);
    const [selected, setSelectedRows] = useState([]);
    const [tokenModal, setTokenModal] = useState(false);
    const [openPhoneLogs, setOpenPhoneLogs] = useState(false);
    const [open, setOpen] = useState(false);
    const [currentExpertId, setCurrentExpertId] = useState(null);
    const [currentExpertIdForLogs, setCurrentExpertIdForLogs] = useState(null);
    const [expert, setExpert] = useState({
        id: "",
        name: "",
        screener_id: "",
    });
    const [phoneModal, setPhoneModal] = useState(false);
    const [phone, setPhoneNumber] = useState("");
    const [person, setPerson] = useState({
        id: "",
        name: "",
        fname: "",
        lname: "",
        company: "",
        linkedin_url: "",
        contact_search: false,
    });
    // =========
    // PAGE LOAD
    // =========

    async function loadInterestedLeads(projectId) {
        await axios
            .post(`/api/project/now/findInterestedExpertList`, { project_id: projectId, auth: auth.data })
            .then((response) => {
                const result = response?.data?.data?.data || [];
                console.log("DID INTERESTED TAB DATA LOAD? ", result);
                setData([result, result]);
                setLoading(false);
                // Find out total open opportunities...
                const openOppsArray = result.filter((r) =>
                    isOpenOpportunity(r.stopped_time, r.number, r.phoneLogsPresent)
                );
                handleReminder(openOppsArray.length);
            })
            .catch((err) => {
                console.log(err, " :: err");
                setLoading(false);
            });
    }

    useEffect(() => {
        loadInterestedLeads(project_id);
    }, []);

    // ============
    // HANDLE FUNCS
    // ============

    const openExpertPreview = (expertId) => {
        setCurrentExpertId(expertId);
        setOpen(true);
    };

    const onRowSelectionChange = (currentSelect, allSelected) => {
        const result = allSelected.map((item) => {
            return filteredData?.at(item.dataIndex);
        });
        const selectedExperts = result.map((item) => {
            // console.log("SELECTED ITEM: ", item);
            const expert = {
                id: item.expert_id,
                fname: item.fname,
                lname: item.lname,
                prefix: item.prefix,
                suffix: item.suffix,
                mail: item.expert_email,
            };
            return expert;
        });
        console.log("WHICH ROWS SELECTED? ", selectedExperts);
        setSelectedRows(selectedExperts);
    };

    const closeModal = () => {
        setSnippetModal(false);
        setTokenModal(false);
        setPhoneModal(false);
        setPerson({
            id: "",
            name: "",
            fname: "",
            lname: "",
            company: "",
            linkedin_url: "",
            contact_search: false,
        });
        setExpert({
            id: "",
            name: "",
            screener_id: "",
        });
    };

    const handleExpertSnippet = (response) => {
        console.log("RESPONSE FROM API CALL: ", response);
        setExpertData(response);
        setSnippetModal(true);
    };

    const handleExpertToken = (eid, name, sid) => {
        console.log("EXPERT ID: ", { eid, name });
        setTokenModal(true);
        setExpert({
            ...expert,
            id: eid,
            name,
            screener_id: sid ? sid : "",
        });
    };

    function handlePhoneLogs(expertId, phoneNum) {
        console.log("handlePhoneLogs: ", { expertId, phoneNum });
        setCurrentExpertIdForLogs(expertId);
        setPhoneNumber(phoneNum);
        setOpenPhoneLogs(true);
    }

    const searchPhoneViaApi = (linkedin_url, fname, lname, name, id, company) => {
        setPerson({
            ...person,
            id,
            fname,
            lname,
            name,
            linkedin_url,
            company,
            contact_search: true,
        });
        if (linkedin_url) {
            setPhoneModal(true);
        } else {
            alert("No linkedin url found for this person. We can't run a search without it.");
        }
    };

    const isOpenOpportunity = (stopped_time, number, phoneLogsPresent) => {
        // Only eligible if stopped_time is present, and phone is available and no phone logs exist yet.
        if (stopped_time && number && !phoneLogsPresent) {
            const now = new Date();
            const lastSentDate = new Date(stopped_time);
            let expiryTime = now;

            const end_of_day = endOfDay(lastSentDate); // 11:59:59pm
            const end_work_day = subHours(end_of_day, 3); // 8:59:59pm
            const morning_after = add(end_of_day, { hours: 9 }); // 9am
            // console.log("DAY/TIME RANGES: ", {
            //     end_of_day: basicDateTimeStamp(end_of_day),
            //     end_work_day: basicDateTimeStamp(end_work_day),
            //     morning_after: basicDateTimeStamp(morning_after),
            // });
            if (lastSentDate < end_work_day) {
                // If current time is BEFORE 9pm of the stopped_time day,
                // then set the expiry time so the opportunity is open after 3 hours of the stopped_time day
                // TESTING ONLY: expiryTime = add(lastSentDate, { minutes: 20 });
                expiryTime = add(lastSentDate, { hours: 3 });
            } else {
                // If current time is AFTER 9pm of the stopped_time day,
                // then set the expiry time so the opportunity is open after 9am the next morning.
                expiryTime = morning_after;
            }
            // console.log("WHEN DOES THIS BECOME AN OPEN OPPORTUNITY? ", basicDateTimeStamp(expiryTime));
            if (expiryTime && now > expiryTime) {
                return true;
            }
            return false;
        }
        return false;
    };

    // =====
    // TABLE
    // =====

    const options = {
        print: false,
        rowsPerPage: 100,
        viewColumns: false,
        download: true,
        // selectableRows: "none",
        onRowSelectionChange,
    };

    const columns = [
        {
            name: "has_signed_terms",
            label: "In",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { has_signed_terms, disclosures } = filteredData?.[dataIndex];

                    return (
                        <div>
                            {has_signed_terms && disclosures ? (
                                <VerifiedIcon style={{ color: "#523197" }} />
                            ) : (
                                <strong style={{ color: "#ccc" }}>***</strong>
                            )}
                        </div>
                    );
                },
            },
        },
        {
            name: "expert_name",
            label: "Name",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const {
                        fname,
                        lname,
                        suffix,
                        expert_id,
                        expert_email,
                        empHistData,
                        headline,
                        has_signed_terms,
                        disclosures,
                    } = filteredData?.[dataIndex];

                    return (
                        <div>
                            <Button css={linkStyle} onClick={() => openExpertPreview(expert_id)}>
                                {fname} {lname}
                                {suffix ? `, ${suffix}` : ""}
                            </Button>
                            {empHistData && empHistData?.length > 0 ? (
                                <div>{`${truncateString(empHistData[0].position, 25)} at ${truncateString(
                                    empHistData[0].company,
                                    25
                                )}`}</div>
                            ) : (
                                <div>{truncateString(headline, 25)}</div>
                            )}
                            {expert_email && (
                                <div>
                                    <a href={`mailto:${expert_email}`}>{expert_email}</a>
                                </div>
                            )}
                        </div>
                    );
                },
            },
        },
        {
            name: "",
            label: "",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    const {
                        expert_id,
                        person_id,
                        linkedin_url,
                        fname,
                        lname,
                        expert_name,
                        empHistData,
                        number,
                        phoneLogsPresent,
                    } = filteredData?.[dataIndex];

                    return (
                        <div>
                            {number ? (
                                <div>
                                    <a style={{ display: "inline-block" }} href={`tel:${number}`}>
                                        {number.length === 10 ? formatPhoneNumber(number) : number}
                                    </a>
                                    <Button
                                        style={{
                                            display: "block",
                                            marginTop: "10px",
                                            padding: "0",
                                            fontSize: ".75rem",
                                        }}
                                        onClick={() => handlePhoneLogs(expert_id, number)}
                                    >
                                        {phoneLogsPresent ? "View Call Log" : "Log Status"}
                                    </Button>
                                </div>
                            ) : (
                                <>
                                    {linkedin_url && (
                                        <Button
                                            style={{ padding: "0", fontSize: ".75rem" }}
                                            onClick={() =>
                                                searchPhoneViaApi(
                                                    linkedin_url,
                                                    fname,
                                                    lname,
                                                    expert_name,
                                                    person_id,
                                                    empHistData && empHistData.length > 0 ? empHistData[0].company : ""
                                                )
                                            }
                                        >
                                            Find Number
                                        </Button>
                                    )}
                                </>
                            )}
                        </div>
                    );
                },
            },
        },
        {
            name: "calculated_consultation_rate",
            label: "Rate",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { calculated_consultation_rate } = filteredData?.[dataIndex];

                    return (
                        <div>
                            {parseInt(calculated_consultation_rate, 10) > 0
                                ? `$${calculated_consultation_rate}`
                                : "----"}
                        </div>
                    );
                },
            },
        },
        {
            name: "stopped_time",
            label: "Interested",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { stopped_time, phoneLogsPresent, number } = filteredData?.[dataIndex];
                    return (
                        <>
                            {isOpenOpportunity(stopped_time, number, phoneLogsPresent) && (
                                <div css={expiredTimeStyle}>Open Opportunity</div>
                            )}
                            <div>{stopped_time ? basicDateTimeStamp(stopped_time) : "NA"}</div>
                        </>
                    );
                },
            },
        },
        {
            name: "attaching_rm_name",
            label: "By",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const { attaching_rm_name, attached_on } = filteredData?.[dataIndex];

                    return (
                        <>
                            <div>{attaching_rm_name}</div>
                            <div>{basicDateTimeStamp(attached_on)}</div>
                        </>
                    );
                },
            },
        },
        {
            name: "",
            label: "",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    const { surveys, fname, lname, suffix, expert_id } = filteredData?.[dataIndex];
                    const fullName = `${fname} ${lname}${suffix ? `, ${suffix}` : ""}`;
                    return (
                        <>
                            <div style={{ marginTop: "12px" }}>
                                <strong>Help screen on behalf of {fname}:</strong>
                                {Object.entries(surveys).map(([key, value], i) => (
                                    <p key={`${key}_${i}`} style={{ margin: 0 }}>
                                        {
                                            <Button
                                                css={smallLinkStyle}
                                                onClick={() => handleExpertToken(expert_id, fullName, value.survey_id)}
                                            >
                                                {value.survey_name}
                                            </Button>
                                        }
                                    </p>
                                ))}
                                {Object.entries(surveys).length === 0 && (
                                    <div>
                                        <Button
                                            css={smallLinkStyle}
                                            onClick={() => handleExpertToken(expert_id, fullName, null)}
                                        >
                                            Select a Screener
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </>
                    );
                },
            },
        },
    ];

    if (loading) {
        return (
            <Backdrop
                css={backdropStyles}
                open={loading}
                onClick={() => {
                    setLoading(false);
                }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    } else {
        return (
            <>
                <h2 style={{ fontSize: "1.25rem" }}>Total Interested: {filteredData?.length}</h2>
                <div style={{ marginBottom: "15px" }}>
                    <strong>FILTER:</strong> Anyone who has been sent outreach already AND clicked interested, but
                    stopped and did nothing else.
                    <br />
                    <strong>RECOMMENDATION:</strong> If phone number exists, call down this list to see if you can walk
                    them through the next steps. If no phone number is available, email them individually for better
                    chances of a response.
                    <br />
                    <span css={expiredTimeStyle}>Open Opportunity</span> Any lead that has been attached through
                    auto-outreach that has clicked interested, but no further action has been taken for{" "}
                    <strong>more than 3 hours</strong>. This expert is now an open opportunity for{" "}
                    <strong>anyone to call and receive credit</strong> for onboarding or screening them. To claim this
                    lead, simply log your call status/details.
                    <br />
                    {selected.length > 0 && (
                        <div
                            style={{
                                zIndex: "999",
                                position: "fixed",
                                bottom: "0",
                                height: "10vh",
                                width: "100%",
                                backgroundColor: "#FAFAFA",
                                left: "72px",
                                borderTop: "1px solid #ccc",
                                padding: "30px 0 0 60px",
                            }}
                        >
                            <Button onClick={() => generateSnippetEvent(selected, handleExpertSnippet)}>Snippet</Button>
                        </div>
                    )}
                </div>
                {person.contact_search && (
                    <ContactSearchModal
                        project_id={project_id}
                        person={person}
                        modal={phoneModal}
                        reloadEvent={loadInterestedLeads}
                        closeEvent={closeModal}
                    />
                )}
                <GenerateIndividualTokens
                    openDialog={tokenModal}
                    closeEvent={closeModal}
                    project_id={project_id}
                    expert={expert}
                    data={projectScreeners}
                />
                <SnippetDialog openDialog={snippet} handleDialogClose={closeModal} expertData={expertData} />
                <MUIDataTable data={filteredData} options={options} columns={columns} />
                {open && (
                    <ExpertPreviewWrapperNext
                        open={open}
                        setOpen={setOpen}
                        expert_id={currentExpertId}
                        project_id={project_id}
                    />
                )}
                {openPhoneLogs && (
                    <PhoneLogsModal
                        openPhoneLogs={openPhoneLogs}
                        setOpenPhoneLogs={setOpenPhoneLogs}
                        userInfo={auth.data}
                        project_id={project_id}
                        expert_id={currentExpertIdForLogs}
                        number={phone}
                        reloadEvent={loadInterestedLeads}
                    />
                )}
            </>
        );
    }
}
