import * as React from "react";
import axios from "axios";

// mui
import Autocomplete from "@mui/material/Autocomplete";

// MUI - style imports
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Chip, TextField } from "@mui/material";

// ======
// STYLES
// ======

const addTagStyles = css`
    background-color: #dec9ff;
`;

// ===========
// MUI HELPERS
// ===========

//     // HELPER FUNCS

//     // HANDLE FUNCS
//     async function handleCreate(tagArr) {
//         console.log(tagArr, " :: tagArr");
//         if (tagArr.length > selectedTags.length) {
//             console.log("====== added ======");
//         } else {
//             console.log("====== removed ======");
//         }
//         setSelectedTags(tagArr);
//     }

export default function TagsManager(props) {
    // PROPS
    const { expertTags: tags, person_id } = props;
    const expertTags = tags.map((t) => {
        return { id: t.tag_id, name: t.tag_name, verified_by_user: t.verified_by_user };
    });

    // STATE
    const [value, setValue] = React.useState([...expertTags]);
    const [inputValue, setInputValue] = React.useState("");
    const [options, setOptions] = React.useState([]);

    // HELPERS
    // function renderHighlight(tagName, query) {
    //     return tagName.replace(new RegExp(query, "gi"), (match) => `<mark>${match}</mark>`);
    // }

    async function loadTags() {
        // console.log(inputValue, " :: inputValue");
        if (!inputValue || inputValue.length < 3) return;

        await axios
            .get(`/api/expert/next/findTags?searchText=${inputValue}`)
            .then((response) => {
                const [items, [{ does_tag_already_exist }]] = response?.data?.data;
                // console.log(items, " :: items");
                if (items) {
                    const newOptions = items.map((item) => {
                        return {
                            id: item.id,
                            name: item.name,
                        };
                    });
                    // console.log("TYPEAHEAD RESPONSE: ", newOptions);
                    if (does_tag_already_exist) {
                        setOptions([...value, ...newOptions]);
                    } else {
                        setOptions([...value, ...newOptions, { id: 0, name: `${inputValue}` }]);
                    }
                }
            })
            .catch((e) => {
                console.log(e, " :: e in loadTags");
            });
    }

    async function removeTagFromExpert(person_id, tag) {
        await axios
            .post("/api/expert/next/removeTagFromExpert", { person_id, tag })
            .then((r) => {
                console.log(r, " :: r in removeTagFromExpert");
            })
            .catch((e) => {
                console.log(e, " :: e in removeTagFromExpert");
            });
    }

    async function addTagToExpert(person_id, tag) {
        await axios
            .post("/api/expert/next/addTagToExpert", { person_id, tag })
            .then((r) => {
                console.log(r, " :: r in addTagToExpert");
            })
            .catch((e) => {
                console.log(e, " :: e in addTagToExpert");
            });
    }

    async function createTagAndAddToExpert(person_id, tagName) {
        await axios
            .post("/api/expert/next/createTagAndAddToExpert", { person_id, tagName })
            .then((r) => {
                console.log(r, " :: r in createTagAndAddToExpert");
            })
            .catch((e) => {
                console.log(e, " :: e in createTagAndAddToExpert");
            });
    }

    // PAGE LOAD
    React.useEffect(() => {
        // console.log("====== in useEffect ======");
        loadTags();
    }, [value, inputValue]);

    return (
        <div
            css={css`
                background-color: #fafafa;
                border-radius: 0.2rem;
                padding: 1rem;
            `}
        >
            <div
                css={css`
                    display: flex;
                    align-items: center;
                    gap: 2rem;
                    padding: 0.5rem 1rem;
                `}
            >
                <div
                    css={css`
                        display: flex;
                        align-items: center;
                        gap: 0.4rem;
                    `}
                >
                    <div
                        css={css`
                            width: 1rem;
                            height: 1rem;
                            background-color: #ddd6fe;
                            border: 1px solid #7c3aed;
                        `}
                    />
                    <div>- Verified by expert</div>
                </div>
                <div
                    css={css`
                        display: flex;
                        align-items: center;
                        gap: 0.4rem;
                    `}
                >
                    <div
                        css={css`
                            width: 1rem;
                            height: 1rem;
                            background-color: #e5e5e5;
                            border: 1px solid #525252;
                        `}
                    />
                    <div>- Not verified</div>
                </div>
            </div>
            <Autocomplete
                multiple
                css={css`
                    max-height: 30vh;
                    overflow-y: scroll;
                `}
                id="multiple-limit-tags"
                options={options}
                getOptionLabel={(option) => option.name}
                filterOptions={(x) => x}
                filterSelectedOptions
                includeInputInList
                autoComplete
                disableClearable
                value={value}
                clearOnBlur={false}
                inputValue={inputValue}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                noOptionsText="Enter at least 3 characters to search for tags."
                onChange={(event, newArr, actionStatus, actionItem) => {
                    // console.log(newArr, " :: newValue onChange");
                    // console.log(actionStatus, " :: action");
                    // console.log(actionItem, " :: actionItem");

                    // removeOption | selectOption
                    const addOrRemove = actionStatus === "removeOption" ? "remove" : "add";

                    // if add && id = 0 then it's a new tag
                    if (addOrRemove === "add" && actionItem.option.id === 0) {
                        // const tagName = actionItem.option.name.slice(actionItem.option.name.indexOf(":") + 1).trim();

                        if (
                            confirm(
                                `Are you sure you want to create a new tag and add to the expert named: ${actionItem.option.name}`
                            )
                        ) {
                            createTagAndAddToExpert(person_id, actionItem.option.name);
                            setValue(newArr);
                        }
                        // otherwise its an existing tag needing to be added to project
                    } else if (addOrRemove === "add") {
                        if (
                            confirm(`Are you sure you want to add a new tag to the expert: ${actionItem.option.name}`)
                        ) {
                            addTagToExpert(person_id, actionItem.option);
                            setValue(newArr);
                        }
                        // here we're removing from the project
                    } else {
                        if (confirm(`Are you sure you want to remove tag from the expert: ${actionItem.option.name}`)) {
                            removeTagFromExpert(person_id, actionItem.option);
                            setValue(newArr);
                        }
                    }
                }}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                renderTags={(values, getTagProps) =>
                    values.map((option, index) => (
                        <Chip
                            css={css`
                                background-color: ${option.verified_by_user ? "#ddd6fe" : "#e5e5e5"};
                            `}
                            size="small"
                            key={option.id}
                            label={option.name}
                            variant="outlined"
                            {...getTagProps({ index })}
                        />
                    ))
                }
                renderInput={(params) => <TextField {...params} margin="dense" placeholder="Search Tags..." />}
                renderOption={(props, option) => {
                    // const innerHtml =
                    //     option.id === 0 ? `Create new tag: ${option.name}` : renderHighlight(option.name, inputValue);
                    return (
                        <li
                            {...props}
                            key={props.id}
                            css={option.id === 0 ? addTagStyles : null}
                            // dangerouslySetInnerHTML={{ __html: innerHtml }}
                        >
                            {option.id === 0 ? `Create new tag: ${option.name}` : option.name}
                        </li>
                    );
                }}
            />
        </div>
    );
}
